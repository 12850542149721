import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { MerchantEvent } from '../components/merchantevents/merchantevent';
import { apiPathConstants } from '../core/api/apipathconstants';
import { oDataConstants } from '../core/api/odata/odataconstants';
import { ApiParameter } from '../core/api/apiparameter';

@Injectable()
export class MerchantEventService {

  constructor(private apiService: ApiService, private apiClient: ApiClient) {}

  saveMerchantEventState(merchantEvent: MerchantEvent) {
    let apiParameter: ApiParameter = {name: 'state', value: merchantEvent.State.toString()};

    return this.apiClient.put<MerchantEvent>(this.apiService.buildUrlWithParameters(
      oDataConstants.oDataApiPrefix, `${apiPathConstants.merchantEvent}(${merchantEvent.MerchantEventId})`,
      apiParameter));
  }
}
