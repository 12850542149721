import { Injectable } from '@angular/core';
import { InternationalChainDto } from '../../models/internationalchaindto';
import { InternationalChainUpdate } from './internationalchainupdate';

@Injectable()
export class ManageInternationalChainsDataService {
    private editData: InternationalChainDto;
    private chainUpdate: InternationalChainUpdate[] = [];
    private _isDirty = false;
    get isDirty() {
         return this._isDirty;
    }

    chains: InternationalChainDto[] = [];

    getEditData() {
        return this.editData;
    }

    setEditData(internationalChainDto: InternationalChainDto) {
        this.editData = internationalChainDto;
    }

    clearEditData() {
        this.editData = null;
    }

    pushToChainUpdate(value: InternationalChainUpdate) {
        this.chainUpdate.push(value);
        this._isDirty = true;
    }

    getChainUpdate() {
        return this.chainUpdate;
    }

    clearChainUpdate() {
        this.chainUpdate = [];
        this._isDirty = false;
    }
}
