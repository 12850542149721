import { ValueCheckerService } from './../core/services/valuechecker.service';
import { StringService } from './../core/formatting/string.service';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { CalItemsResponse, AllocateItemsToIndexerResponse, NumberOfSearchesResponse } from "../components/cardacceptorlocators/cardacceptorlocator.component";
import { filteredCalsQuery, allocateCalsToIndexerQuery, groupOfSimilarCalsQuery, hotChocolate, numberOfSearchesQuery } from "../gqlconstants/gqlconstants";
import { emptyString, commaSpace } from '../core/constants/constants';
import { GroupOfSimilarCalsResponse } from '../components/cardacceptorlocators/groupofsimilarcals/groupofsimilarcals.component';
import { LoadingService } from '../core/uiservices/loading.service';

@Injectable({
  providedIn: "root"
})
export class CardAcceptorLocatorService {
  constructor(private apollo: Apollo, private stringService: StringService, private valueCheckerService: ValueCheckerService,
    private loadingService: LoadingService) { }

  getCALs(
    currentStatusId: number[],
    idIndexer: string,
    prefix: string,
    cardAcceptorLocator: string,
    numberOfSearches: number[],
    endDate: string,
    startDate: string,
    calType: number[],
    skip: number,
    take: number) {
    this.loadingService.setLoading();

    let filtersForAndOperator: string = emptyString;

    let currentStatusIdFilters: string = emptyString;
    let currentStatusIds: string = emptyString;

    let calTypeFilters: string = emptyString;
    let calTypeIds: string = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(idIndexer)) {
      filtersForAndOperator = filtersForAndOperator + this.stringService.format(`{ iD_Indexer: {eq: {0}}}${commaSpace}`, idIndexer);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(cardAcceptorLocator)) {
      filtersForAndOperator = filtersForAndOperator + this.stringService.format(`{ transactionDescription: { {0}: "{1}" }}${commaSpace}`, prefix, cardAcceptorLocator);
    }
    filtersForAndOperator = filtersForAndOperator + this.stringService.format(`{ numberOfSearches: { lt: {0} }}${commaSpace} { numberOfSearches: { gt: {1} }}${commaSpace}`, numberOfSearches[1].toString(), numberOfSearches[0].toString());

    if (!this.valueCheckerService.isEmptyNullOrUndefined(endDate)) {
      filtersForAndOperator = filtersForAndOperator + this.stringService.format(`{ createDateTime: { lte: "{0}" }}${commaSpace}`, endDate);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(startDate)) {
      filtersForAndOperator = filtersForAndOperator + this.stringService.format('{ createDateTime: { gte: "{0}" }}', startDate);
    }
    if (calType.length > 0) {
      for (let i = 0; i < calType.length; i++) {
        let id: number = calType[i];
        calTypeIds = calTypeIds + this.stringService.format(`{ cALType: { eq: {0} }}${calType.length > i + 1 ? commaSpace : emptyString}`, id.toString());
      }
      calTypeFilters = this.stringService.format('{or: [{0}]}', calTypeIds);
    }
    if (currentStatusId.length > 0) {
      for (let i = 0; i < currentStatusId.length; i++) {
        let id: number = currentStatusId[i];
        currentStatusIds = currentStatusIds + this.stringService.format(`{currentStatusID: {eq: {0} }}${currentStatusId.length > i + 1 ? commaSpace : emptyString}`, id.toString());
      }
      currentStatusIdFilters = this.stringService.format('{or: [{0}]}', currentStatusIds);
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<CalItemsResponse>({
        query: gql`${this.stringService.format(filteredCalsQuery, filtersForAndOperator, currentStatusIdFilters, calTypeFilters, skip.toString(), take.toString())}`
      })
      .valueChanges;
  }

  getAllocateCalsToIndexer(indexId: string, calIds: number[]) {
    this.loadingService.setLoading();
    return this.apollo
      .use(hotChocolate)
      .mutate<AllocateItemsToIndexerResponse>({
        mutation: gql`${this.stringService.format(allocateCalsToIndexerQuery, indexId, calIds.toString())}`
      });
  }

  getGroupOfSimilarCals(skip: number, transactionDescription: string) {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<GroupOfSimilarCalsResponse>({
        query: gql`${this.stringService.format(groupOfSimilarCalsQuery, transactionDescription, skip.toString())}`
      })
      .valueChanges;
  }

  getNumberOfSearches(calStatus: number) {
    this.loadingService.setLoading();
    return this.apollo
      .use(hotChocolate)
      .watchQuery<NumberOfSearchesResponse>({
        query: gql`${this.stringService.format(numberOfSearchesQuery, calStatus.toString())}`
      })
      .valueChanges;
  }
}

