import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { quarantinedCalsResponse } from '../mockjsonresponses/quarantinedcalsresponse';

export function quarantinedCalsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];
  if (cleanUrl.includes(apiPathConstants.quarantinedCals) && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...quarantinedCalsResponse]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.quarantinedcals) && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200
      }));
      resp.complete();
    });
  }
}