import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { catchError, Observable, throwError } from 'rxjs';
import { ApiService } from '../core/api/api.service';
import { ApiClient } from '../core/api/apiclient';
import { apiPathConstants } from '../core/api/apipathconstants';
import { emptyString } from '../core/constants/constants';
import { StringService } from '../core/formatting/string.service';
import { ValueCheckerService } from '../core/services/valuechecker.service';
import { LoadingService } from '../core/uiservices/loading.service';
import { apiClientAllPoliciesQuery, clientApiKey, cloneClientApikey, GetApiClientWithApiKey, getClientNames, hotChocolate, syncAll, syncClientApiKey, syncClientApiKeyFromDB, syncUsagePlan, testapikey, usgaePlan } from '../gqlconstants/gqlconstants';
import { ApiClientPolicy, ClientAPI, ClientOnboardingApiClient, CloneApiKey, Data, syncClientApiKeyFromDbResponse, SyncClientApiKeyResponse, SyncUsagePlan, TestApi, UpsertClientResponseData, UsagePlan } from '../models/manageapikeys.models';

@Injectable({
  providedIn: 'root'
})
export class ManageapikeysService {

  constructor(private apollo: Apollo, private loadingService: LoadingService, private stringService: StringService,
    private valueCheckerService: ValueCheckerService, private apiClient: ApiClient, private apiService: ApiService) { }

  getClientData(isAdmin: boolean, clientName: string, filterApiName: string, isActive: boolean, skip: number, take: number, orderBy: string, orderDirection: string) {
    this.loadingService.setLoading();
    let query = GetApiClientWithApiKey

    let optionalFilters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(clientName)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ clientName: {contains: "{0}" } }`, clientName);
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(filterApiName)) {
      optionalFilters = optionalFilters + this.stringService.format(`\{ clientAPIKeys:{ some:{apikeyName:{contains: "{0}" } }}}`, filterApiName);
    }
    if (isActive != false) {
      if (!this.valueCheckerService.isEmptyNullOrUndefined(isActive)) {
        optionalFilters = optionalFilters + this.stringService.format(`{isActive:{eq: {0} } }`, isActive.toString());
      }
    }

    if (optionalFilters === emptyString) {
      query = query.replace("where: { and: [ {0} ] },", emptyString);
    }

    return this.apollo.use(hotChocolate).watchQuery<ClientOnboardingApiClient>({
      query: gql`${this.stringService.format(query, isAdmin.toString(), optionalFilters, skip.toString(), take.toString(), orderBy, orderDirection.toUpperCase(), isActive.toString(), filterApiName.toLowerCase())}`
    }).valueChanges;
  }

  addClientsApiKey(values: ClientAPI): Observable<UpsertClientResponseData> {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<ClientAPI>({
      mutation: gql`${this.stringService.format(clientApiKey)}`,
      variables: { clientApi: values }
    }).pipe(catchError(this.errorHandler))
  }

  getClientName(skip: number, take: number) {
    this.loadingService.setLoading();
    let query = getClientNames;
    return this.apollo.use(hotChocolate).watchQuery<Data>({
      query: gql`${this.stringService.format(query, skip.toString(), take.toString())}`
    }).valueChanges;
  }

  getUsagePlan() {
    this.loadingService.setLoading();
    let query = usgaePlan;
    return this.apollo.use(hotChocolate).watchQuery<UsagePlan>({
      query: gql`${this.stringService.format(query)}`
    }).valueChanges;
  }

  getApiClientAllPolicies() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<ApiClientPolicy[]>({
        query: apiClientAllPoliciesQuery
      })
      .valueChanges;
  }

  syncUsage(plan: string): Observable<SyncUsagePlan> {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<SyncUsagePlan>({
      mutation: gql`${this.stringService.format(syncUsagePlan, plan)}`

    }).pipe(catchError(this.errorHandler))
  }

  syncClientApiKey(apikey: string) {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<SyncClientApiKeyResponse>({
      mutation: gql`${this.stringService.format(syncClientApiKey, apikey)}`
    }).pipe(catchError(this.errorHandler))
  }

  syncClientApiKeyDb(apikey: string) {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<syncClientApiKeyFromDbResponse>({
      mutation: gql`${this.stringService.format(syncClientApiKeyFromDB, apikey)}`
    }).pipe(catchError(this.errorHandler))
  }

  syncAll(value: string) {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<SyncClientApiKeyResponse>({
      mutation: gql`${this.stringService.format(syncAll, value)}`
    }).pipe(catchError(this.errorHandler))
  }

  testapikey(apikeyId: number, env: string): Observable<TestApi> {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<TestApi>({
      mutation: gql`${this.stringService.format(testapikey, apikeyId.toString(), env)}`
    }).pipe(catchError(this.errorHandler))
  }

  cloneClientApiKey(apikeyId: number, createdBy: number): Observable<CloneApiKey> {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<CloneApiKey>({
      mutation: gql`${this.stringService.format(cloneClientApikey, apikeyId.toString(), createdBy.toString())}`
    }).pipe(catchError(this.errorHandler))
  }

  getStatus() {
    return this.apiClient.post<string>(this.apiService.buildUrl(apiPathConstants.refresh), null);
  }

  errorHandler(error: HttpErrorResponse): Observable<any> {
    return throwError(() => error)
  }
}
