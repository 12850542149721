import { ValueCheckerService } from './../../core/services/valuechecker.service';
import { chartDefaultColorScheme, emptyString } from './../../core/constants/constants';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoadingService } from './../../core/uiservices/loading.service';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ManageApiUsageState } from './manageapiusage.state';
import { BaseStateComponent } from '../basestate/basestate.component';
import { ManageApiUsageService } from '../../services/manageapiusage.service';
import { ApiUsagesStatsForUsagePlanId, ApiUsagesStatsForUsagePlanIdDisplay, ManageApiUsage, ManageApiUsageDisplay } from './manageapiusage.models';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { PageInfo } from '../../shared/models/pageinfo';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { propertyOf } from '../../core/services/reflection.service';
import { NumberService } from '../../core/uiservices/number.service';
import { CriticalLevel } from '../../enums/enums';
import { NameSerie } from '../../core/models/nameseries';
import { ActivatedRoute } from '@angular/router';

export type ManageApiUsagesResponse = {
  items: ManageApiUsage[];
  totalCount: number;
};

export type ApiUsagesStatsForUsagePlanIdResponse = {
  items: ApiUsagesStatsForUsagePlanId[];
}

@Component({
  selector: 'app-manageapiusage',
  templateUrl: './manageapiusage.component.html',
  styleUrls: ['./manageapiusage.component.scss']
})
export class ManageApiUsageComponent extends BaseStateComponent<ManageApiUsageState> implements OnInit {
  columnMode = ColumnMode;
  totalCount: number = 0;
  pageIndex: number = 0;
  take: number = 50;
  orderBy: string = "calculateUsedQuotaPercentange";
  orderDirection: string = "DESC";
  @ViewChild('csvExport') csvExportComponent: CsvExportComponent;
  @ViewChild('manageApiUsagesTable') manageApiUsagesTable: DatatableComponent;
  datatableHeaders = {
    aPIKeyName: "API Key Name",
    criticalLevel: "Critical Level",
    calculateUsedQuotaPercentange: "Calculate Used Quota Percentange",
    planDescription: "Plan Description",
    planName: "Plan Name",
    quotaLimitDisplay: "Quota Limit",
    quotaPeriod: "Quota Period",
    remainingQuotaDisplay: "Remaining Quota",
    usedQuotaDisplay: "Used Quota",
    description: "Description",
    modifiedDateDisplay: "Modified Date",
  };
  datatableKeys = {
    aPIKeyName: propertyOf<ManageApiUsageDisplay>('apiKeyName'),
    criticalLevel: propertyOf<ManageApiUsageDisplay>('criticalLevel'),
    calculateUsedQuotaPercentange: propertyOf<ManageApiUsageDisplay>('calculateUsedQuotaPercentange'),
    planDescription: propertyOf<ManageApiUsageDisplay>('planDescription'),
    planName: propertyOf<ManageApiUsageDisplay>('planName'),
    quotaLimitDisplay: propertyOf<ManageApiUsageDisplay>('quotaLimitDisplay'),
    quotaPeriod: propertyOf<ManageApiUsageDisplay>('quotaPeriod'),
    remainingQuotaDisplay: propertyOf<ManageApiUsageDisplay>('remainingQuotaDisplay'),
    usedQuotaDisplay: propertyOf<ManageApiUsageDisplay>('usedQuotaDisplay'),
    description: propertyOf<ManageApiUsageDisplay>('description'),
    modifiedDateDisplay: propertyOf<ManageApiUsageDisplay>('modifiedDateDisplay')
  };
  manageApiUsages: ManageApiUsageDisplay[] = [];
  chartColorScheme = {
    domain: chartDefaultColorScheme
  };
  chartViewHeight: number = 200;
  chartViewWidth: number = 0;
  chartData: NameSerie[] = [];
  chartXAxisLabel: string = 'Date';
  chartYAxisLabel: string = 'Used Quota';
  datatableRowDetailHeight: number = 370;

  private optionDatatableHeadersAndKeys = {
    headers: [
      this.datatableHeaders.aPIKeyName,
      this.datatableHeaders.criticalLevel,
      this.datatableHeaders.calculateUsedQuotaPercentange,
      this.datatableHeaders.planDescription,
      this.datatableHeaders.planName,
      this.datatableHeaders.quotaLimitDisplay,
      this.datatableHeaders.quotaPeriod,
      this.datatableHeaders.remainingQuotaDisplay,
      this.datatableHeaders.usedQuotaDisplay,
      this.datatableHeaders.description,
      this.datatableHeaders.modifiedDateDisplay
    ],
    keys: [
      this.datatableKeys.aPIKeyName,
      this.datatableKeys.criticalLevel,
      this.datatableKeys.calculateUsedQuotaPercentange,
      this.datatableKeys.planDescription,
      this.datatableKeys.planName,
      this.datatableKeys.quotaLimitDisplay,
      this.datatableKeys.quotaPeriod,
      this.datatableKeys.remainingQuotaDisplay,
      this.datatableKeys.usedQuotaDisplay,
      this.datatableKeys.description,
      this.datatableKeys.modifiedDateDisplay
    ]
  };
  private csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionDatatableHeadersAndKeys };
  private manageApiUsageForCsvFile: ManageApiUsageDisplay[];
  private skip: number = 0;
  private tooltipWhenUsageSummaryHided: string = 'Show usage summary';
  private tooltipWhenUsageSummaryShowed: string = 'Hide usage details';
  apiUsagesStatsForUsagePlanId: ApiUsagesStatsForUsagePlanIdDisplay[] = [];

  constructor(injector: Injector, private loadingService: LoadingService, private manageApiUsageService: ManageApiUsageService,
    private dateTimeService: DateTimeService, private csvComponentService: CsvComponentService,
    private valueCheckerService: ValueCheckerService, private numberService: NumberService, private route: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      filterByPlanName: emptyString,
      filterByApiKeyName: emptyString,
      filterByUsedQuota: null
    };
    this.restoreState();
    this.setApiUsages();
    if (this.route.params != null) {
      this.route.queryParams.subscribe((params: any) => {
        this.state.filterByApiKeyName = params.data;
      });
      this.restoreState();
      this.setApiUsages();
    }
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    this.pageIndex = pageInfo.offset;
    this.skip = pageInfo.offset * this.take;
    this.setApiUsages();
  }

  setApiUsages() {
    this.manageApiUsageService.getApiUsages(this.state.filterByPlanName, this.state.filterByApiKeyName, this.state.filterByUsedQuota, this.take, this.skip, this.orderBy, this.orderDirection)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.totalCount) {
          this.manageApiUsages = response.data.items;
          this.setDisplayValues(this.manageApiUsages);
          this.filterManageApiUsages();
          this.totalCount = response.data.totalCount;
        }
        else {
          this.manageApiUsages = [];
          this.totalCount = 0;
        }
      });
  }

  downloadCsvFile() {
    this.manageApiUsageService.getApiUsages(this.state.filterByPlanName, this.state.filterByApiKeyName, this.state.filterByUsedQuota, this.totalCount, this.skip, this.orderBy, this.orderDirection)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items) {
          this.manageApiUsageForCsvFile = response.data.items;
          this.setDisplayValues(this.manageApiUsageForCsvFile);
          this.csvExportComponent.data = this.manageApiUsageForCsvFile;
          this.csvExportComponent.options = this.csvOptions;
          this.csvExportComponent.onDownload();
        }
      });
  }

  isCsvButtonDisabled() {
    return this.manageApiUsages.length > 0;
  }

  getCellClass(data: { value: string }) {
    return {
      "datatable-cell-danger-background-color": data.value.toLowerCase() === CriticalLevel.High.toLowerCase(),
      "datatable-cell-warning-background-color": data.value.toLowerCase() === CriticalLevel.Mid.toLowerCase(),
    }
  }

  showOrHideUsageSummary(row: ManageApiUsageDisplay) {
    if (row.usageSummaryTooltip === this.tooltipWhenUsageSummaryHided) {
      this.loadingService.setLoading();
      this.manageApiUsageService.getApiUsagesStatsForUsagePlanId(row.apiClientUsageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.loadingService.clearLoading();
          if (response && response.data && response.data.items.length > 0) {
            this.apiUsagesStatsForUsagePlanId = response.data.items;

            for (let manageApiUsage of this.manageApiUsages) {
              manageApiUsage.usageSummaryTooltip = this.tooltipWhenUsageSummaryHided;
            }

            this.manageApiUsagesTable.rowDetail.collapseAllRows();
            this.datatableRowDetailHeight = 370;
            this.datatableRowDetailHeight += response.data.items.length > 10 ? 400 : response.data.items.length * 40;
            this.manageApiUsagesTable.rowDetail.toggleExpandRow(row);
            row.usageSummaryTooltip = this.tooltipWhenUsageSummaryShowed;

            for (let item of this.apiUsagesStatsForUsagePlanId) {
              item.date = this.dateTimeService.format(item.date);
              item.remainingQuotaDisplay = this.numberService.format(item.remainingQuota);
              item.usedQuotaDisplay = this.numberService.format(item.usedQuota);
            }

            this.apiUsagesStatsForUsagePlanId = response.data.items.sort((a, b) => {
              return this.dateTimeService.compareDatesAsc(a.date, b.date);
            });

            let series = this.apiUsagesStatsForUsagePlanId.map((t) => {
              return {
                name: t.date,
                value: t.usedQuota
              }
            })
            this.chartData = [{ name: "Used Quota", series: series }];
          }
        });
    }
    else {
      row.usageSummaryTooltip = this.tooltipWhenUsageSummaryHided;
      this.manageApiUsagesTable.rowDetail.toggleExpandRow(row);
    }
  }

  private setDisplayValues(manageApiUsages: ManageApiUsageDisplay[]) {
    for (let manageApiUsage of manageApiUsages) {
      manageApiUsage.modifiedDateDisplay = manageApiUsage.modifiedDate
        ? this.dateTimeService.format(manageApiUsage.modifiedDate.toString())
        : null;
      manageApiUsage.quotaLimitDisplay = manageApiUsage.quotaLimit
        ? this.numberService.format(manageApiUsage.quotaLimit)
        : null;
      manageApiUsage.remainingQuotaDisplay = manageApiUsage.remainingQuota
        ? this.numberService.format(manageApiUsage.remainingQuota)
        : null;
      manageApiUsage.usedQuotaDisplay = manageApiUsage.usedQuota
        ? this.numberService.format(manageApiUsage.usedQuota)
        : null;
      manageApiUsage.usageSummaryTooltip = this.tooltipWhenUsageSummaryHided;
    }
  }

  private filterManageApiUsages() {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByUsedQuota)) {
      this.manageApiUsages = this.manageApiUsages.filter(t => t.usedQuota >= this.state.filterByUsedQuota);
    }
  }

  onSort(event: any) {
    this.orderBy = event.sorts[0].prop;
    this.orderDirection = event.sorts[0].dir;
    this.restoreState();
    this.setApiUsages();
  }
}
