import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NotificationService } from '../../core/uiservices/notification.service';
import { UserService } from '../applicationuser.service';
import { ApplicationUser } from '../applicationuser';
import { ChangePasswordModel } from './changepasswordmodel';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  emailId = '';
  password: string;
  confirmPassword: string;
  isPasswordConfirmed = true;

  constructor(private activatedRoute: ActivatedRoute, private loadingService: LoadingService, private location: Location,
    private notificationService: NotificationService, private userService: UserService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.emailId = params['emailId'];
      this.userService.getById(this.emailId).subscribe(applicationUser => {
        this.setUserParams(applicationUser);
      });
    });
  }

  private validatePassword() {
    if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
      this.isPasswordConfirmed = true;
    } else {
      this.isPasswordConfirmed = false;
    }

    return this.isPasswordConfirmed;
  }

  submit() {
      let isPasswordConfirmed = this.validatePassword();

      if (!isPasswordConfirmed) {
        return;
      }

      this.changeUsersPassword();
    }

  private changeUsersPassword() {
      let changePasswordModel: ChangePasswordModel = {
        EmailId: this.emailId,
        Password: this.password,
        Token: ''
      };
      this.loadingService.setLoading();
      this.userService.changePassword(changePasswordModel)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(() => {
          this.notificationService.notifySuccess('Password changed successfully.');
          this.location.back();
        });
    }

    private setUserParams(applicationUser: ApplicationUser) {
      this.emailId = applicationUser.EmailId;
    }

}
