import { BaseComponent } from './../../../shared/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Injector } from '@angular/core';
import { LoadingService } from '../../../core/uiservices/loading.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { UnmatchedMerchant } from '../datascraping';
import { DataScrapingService } from '../../../services/datascraping.service';

export type UnmatchedMerchantResponse = {
  unmatchedMerchants: UnmatchedMerchant[];
}

@Component({
  selector: 'app-unmatchedmerchants',
  templateUrl: './unmatchedmerchants.component.html',
  styleUrls: ['./unmatchedmerchants.component.scss']
})
export class UnmatchedMerchantsComponent extends BaseComponent implements OnInit {
  unmatchedMerchants: UnmatchedMerchant[] = [];

  constructor(injector: Injector, private activatedRoute: ActivatedRoute, private loadingService: LoadingService,
    private dataScrapingService: DataScrapingService, private router: Router) {
    super(injector)
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      if (param['chainId']) {
        this.loadingService.setLoading();
        this.dataScrapingService.getUnmatchedMerchants(param['chainId'])
          .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
          .subscribe((response) => {
            this.loadingService.clearLoading();
            if (response && response.data.unmatchedMerchants && response.data.unmatchedMerchants.length > 0) {
              this.unmatchedMerchants = response.data.unmatchedMerchants;
            }
            else {
              this.router.navigate(['managedatascraping']);
            }
          });
      }
      else {
        this.router.navigate(['managedatascraping']);
      }
    })
  }

  backToManageDataScraping() {
    this.router.navigate(['managedatascraping']);
  }
}
