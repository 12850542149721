import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { BusinessNameModel } from '../models/businessname';
import { Injectable } from '@angular/core';
import { BusinessAddressModel } from '../models/businessaddress';
import { MerchantByAbnAcn } from '../models/merchantlist';


@Injectable()
export class AbnAcnLookupService {
  private API_ABNACNLOOKUP = 'abnacnlookup';

  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  getBusinessNamesByAbn(abn: number) {
    return this.apiClient.get<BusinessNameModel[]>(this.apiService
      .buildUrl(this.API_ABNACNLOOKUP + '/getnamesbyabn', abn.toString()));
  }

  getBusinessAddressesByAbn(abn: number) {
    return this.apiClient.get<BusinessAddressModel[]>(this.apiService
      .buildUrl(this.API_ABNACNLOOKUP + '/getaddressbyabn', abn.toString()));
  }

  getBusinessNamesByAcn(acn: string) {
    return this.apiClient.get<BusinessNameModel[]>(this.apiService
      .buildUrl(this.API_ABNACNLOOKUP + '/getnamesbyacn', acn));
  }

  getBusinessAddressesByAcn(acn: string) {
    return this.apiClient.get<BusinessAddressModel[]>(this.apiService
      .buildUrl(this.API_ABNACNLOOKUP + '/getaddressbyacn', acn));
  }

  getMerchantListByAbn(abn: string) {
    return this.apiClient.get<MerchantByAbnAcn[]>(this.apiService.buildUrl(apiPathConstants.getMerchantsByAbn + abn));
  }

  getMerchantListByAcn(acn: string) {
    return this.apiClient.get<MerchantByAbnAcn[]>(this.apiService.buildUrl(apiPathConstants.getMerchantsByAcn + acn));
  }

  refreshABR(abn: number) {
    return this.apiClient.get<BusinessNameModel[]>(this.apiService.buildUrl(this.API_ABNACNLOOKUP + '/refreshabr',
      abn.toString()));
  }

  purgeBusinessName(id: number) {
    return this.apiClient.delete<BusinessNameModel[]>(this.apiService.buildUrl('purge/businessNameId', id.toString()));
  }

  purgeBusinessAddress(id: number) {
    return this.apiClient.delete<BusinessAddressModel[]>(this.apiService.buildUrl('purge/businessAddressId', id.toString()));
  }
}
