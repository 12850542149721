import { ODataOrderBy } from './odataorderby';

export class ODataOrderByDesc implements ODataOrderBy {
  constructor(private orderBy: string) {
  }

  getValue() {
    return `${this.orderBy} desc`;
  }
}
