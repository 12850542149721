import { HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { HttpMethod } from "../../core/coreenums/coreenums";
import { bankAccountTransactionTypesResponse } from "../mockjsonresponses/bankaccounttransactiontype.response";

export function bankAccountTransactionTypeBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesDataBankAccountTransactionTypes = false;
  if (request.body && request.body.query) {
    includesDataBankAccountTransactionTypes = request.body.query.includes(`data: bankAccountTransactionTypes`);
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesDataBankAccountTransactionTypes) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...bankAccountTransactionTypesResponse }
      }));
      resp.complete();
    })
  }
}
