import { Component, OnInit, Injector } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { ChangeCalAllocationState } from './changecalallocationstate';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { MatSelectionList } from '@angular/material/list';
import { NotificationService } from '../../core/uiservices/notification.service';
import { MovableCal } from './movablecal';
import { CalRellocationRequest } from './calrellocationrequest';
import { CALRellocationResultStatus } from './calrellocationresultstatus';
import { CALRellocationResult } from './calrellocationresult';
import { MerchantSummary } from '../mergemerchants/merchantsummary';

@Component({
  selector: 'app-changecalallocation',
  templateUrl: './changecalallocation.component.html',
  styleUrls: ['./changecalallocation.component.scss']
})
export class ChangeCalAllocationComponent extends BaseStateComponent<ChangeCalAllocationState> implements OnInit {
  sameLwcIds = false;
  constructor(private loadingService: LoadingService, private merchantLookupService: MerchantlookupService, injector: Injector,
    private notificationService: NotificationService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      leftLwcId: <number>null,
      rightLwcId: <number>null,
      leftCals: [],
      rightCals: [],
      leftPrimaryName: '',
      rightPrimaryName: '',
      retrievedLeftLwcId: <number>null,
      retrievedRightLwcId: <number>null,
      isMoved: false,
      calRellocationResult: []
    };

    this.restoreState();
  }

  loadCals() {
    this.loadingService.setLoading();

    this.state.calRellocationResult = [];
    this.state.isMoved = false;
    this.state.isResultLoaded = false;
    this.state.retrievedLeftLwcId = <number>null;
    this.state.retrievedRightLwcId = <number>null;

    this.merchantLookupService.getCals(this.state.leftLwcId).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(result => {
        this.state.leftCals = this.mapToMovableCals(result);
        this.state.leftPrimaryName = result.PrimaryName;
        this.state.retrievedLeftLwcId = this.state.leftLwcId;
      });

    this.merchantLookupService.getCals(this.state.rightLwcId).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(result => {
        this.state.rightCals = this.mapToMovableCals(result);
        this.state.rightPrimaryName = result.PrimaryName;
        this.state.retrievedRightLwcId = this.state.rightLwcId;
      });
  }

  isLoadButtonDisabled() {
    return !(this.state.leftLwcId && this.state.rightLwcId && this.areLwcIdsSame());
  }

  areLwcIdsSame() {
    return this.state.leftLwcId !== this.state.rightLwcId;
  }

  moveSelectedCals(fromList: MatSelectionList, fromState: MovableCal[], toState: MovableCal[]) {
    let selectedCals = fromList.selectedOptions.selected.map<MovableCal>(o => o.value);
    if (selectedCals.length <= 0) {
      return;
    }

    for (let movableCal of selectedCals) {
      toState.push(movableCal);
      fromState.splice(fromState.findIndex(c => c.cal === movableCal.cal), 1);
    }

    this.state.isMoved = true;
  }

  moveAllCals(fromList: MatSelectionList, fromState: MovableCal[], toState: MovableCal[]) {
    fromList.selectAll();
    this.moveSelectedCals(fromList, fromState, toState);
  }

  shouldShowCalsLists() {
    return (this.state.retrievedLeftLwcId !== null && this.state.retrievedRightLwcId !== null);
  }

  saveCals() {
    let leftMovedCals = this.state.leftCals.filter(c => c.originalLwcId !== this.state.retrievedLeftLwcId).map(c => c.cal);
    let rightMovedCals = this.state.rightCals.filter(c => c.originalLwcId !== this.state.retrievedRightLwcId).map(c => c.cal);

    if (leftMovedCals.length === 0 && rightMovedCals.length === 0) {
      return;
    }

    if (leftMovedCals.length > 0) {
      this.loadingService.setLoading();
      this.merchantLookupService.associateCals(this.prepareCalsForAssociation(this.state.retrievedRightLwcId,
        this.state.retrievedLeftLwcId, leftMovedCals))
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((leftResult) => {
          this.state.calRellocationResult = this.state.calRellocationResult.concat(leftResult);
          this.state.isResultLoaded = true;
          this.updateStatusesInList(this.state.leftCals, leftResult);
          this.state.isMoved = false;
          this.notificationService.notifySuccess('Saved successfully');
        });
    }

    if (rightMovedCals.length > 0) {
      this.loadingService.setLoading();
      this.merchantLookupService.associateCals(this.prepareCalsForAssociation(this.state.retrievedLeftLwcId,
        this.state.retrievedRightLwcId, rightMovedCals))
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((rightResult) => {
          this.state.calRellocationResult = this.state.calRellocationResult.concat(rightResult);
          this.state.isResultLoaded = true;
          this.updateStatusesInList(this.state.rightCals, rightResult);
          this.state.isMoved = false;
          this.notificationService.notifySuccess('Saved successfully');
        });
    }
  }

  getResultClass(status: CALRellocationResultStatus) {
    switch (status) {
      case CALRellocationResultStatus.Success:
        return 'success';
      case CALRellocationResultStatus.Error:
        return 'danger';
      case CALRellocationResultStatus.Warning:
        return 'yellow-warning';
    }
  }

  getStatusRowText(status: CALRellocationResultStatus) {
    switch (status) {
      case CALRellocationResultStatus.Success:
        return 'Success';
      case CALRellocationResultStatus.Error:
        return 'Error';
      case CALRellocationResultStatus.Warning:
        return 'Warning';
    }
  }

  getStatusIconBasedOnResult(cal: MovableCal) {
    switch (cal.status) {
      case CALRellocationResultStatus.Success:
        return 'done_all';
      case CALRellocationResultStatus.Error:
        return 'error';
      case CALRellocationResultStatus.Warning:
        return 'warning';
    }
  }

  private updateStatusesInList(cals: MovableCal[], results: CALRellocationResult[]) {
    for (let result of results) {
      let movableCal = cals.find(c => c.cal === result.TransactionDescription );
      if (movableCal) {
        movableCal.status = result.Status;
      }
    }
  }

  private mapToMovableCals(result: MerchantSummary) {
    let movableCals = result.TransactionDescriptions.map<MovableCal>(resultCal => {
      let newObj = {
        cal: resultCal,
        originalLwcId: result.LWC_ID
      };
      return newObj;
    });
    return movableCals;
  }

  private prepareCalsForAssociation(sourceLwcId: number, targetLwcId: number, cals: string[]) {
    let request: CalRellocationRequest = {
      SourceLWC_ID: sourceLwcId,
      TargetLWC_ID: targetLwcId,
      TransactionDescriptions: cals
    };
    return request;
  }
}
