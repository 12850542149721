import { Injectable } from "@angular/core";
import { ApiClient } from "../core/api/apiclient";
import { ApiService } from "../core/api/api.service";
import { CategoryAllocationPattern } from "../categories/categoryallocationpattern";
import { SetCategoryAllocationPatternsRequest } from "../categories/setcategoryallocationpatternsrequest";
import { TestCategoryAllocationPatternsRequest } from "../categories/testcategoryallocationpatternsrequest";
import { CategoryAllocationPatternMatch } from "../categories/categoryallocationpatternmatch";

@Injectable()
export class CategoryAllocationPatternService {
  constructor(private apiClient: ApiClient, private apiService: ApiService) { }

  getMatchPatterns() {
    return this.apiClient.get<CategoryAllocationPattern[]>(this.apiService.buildUrl("categoryallocationpattern"));
  }

  testMatchPattern(testCategoryAllocationPatternsRequest: TestCategoryAllocationPatternsRequest) {
    return this.apiClient.post<CategoryAllocationPatternMatch[]>(this.apiService.buildUrl("categoryallocationpattern/testpatternrules"), testCategoryAllocationPatternsRequest);
  }

  saveMatchPatterns(matchpattern: SetCategoryAllocationPatternsRequest) {
    return this.apiClient.put<SetCategoryAllocationPatternsRequest>(this.apiService.buildUrl("categoryallocationpattern"), matchpattern);
  }
}
