import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  constructor() { }

  @Input() numberPerPage = 15;
  @Input() dataForUpdate: unknown[];
  @Output() changePage = new EventEmitter<unknown[]>(true);
  currentPage = 1;
  numberOfPages = 0;

  ngOnInit() {
    this.getNumberOfPages();
  }

  getNumberOfPages() {
    this.numberOfPages = Math.ceil(this.dataForUpdate.length / this.numberPerPage);
    this.loadNewData();
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.loadNewData();
  }

  loadNewData() {
    var begin = ((this.currentPage - 1) * this.numberPerPage);
    var end = begin + this.numberPerPage;
    let newData = this.dataForUpdate.slice(begin, end);
    this.changePage.emit(newData);
  }

}
