import { IndexMerchantResult, IndexMerchantTask } from '../../models/indexmerchantresult';
import { IndexMerchantantResultStatus } from '../../enums/enums';


let indexMerchantTasks: IndexMerchantTask[] = [
  {
    Comments:'Comments1',
    TaskName: 'Execute merchant',
    Execute: true,
    Result: true,
    TimeMilliseconds: 1399,
  },
  {
    Comments:'Comments2',
    TaskName: 'Execute merchant2',
    Execute: true,
    Result: false,
    TimeMilliseconds: 399,
  }
];

export let getIndexMerchantResultResponse: IndexMerchantResult = {
  LwcId: 1000649284,
  Message: 'IndexMerchant done',
  Status: IndexMerchantantResultStatus.Success,
  IndexMerchantTasks: indexMerchantTasks
};

