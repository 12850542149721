import { Directive, ElementRef, HostListener, OnInit, OnDestroy, Optional, Output, EventEmitter, Input, AfterViewChecked } from '@angular/core';
import { NgModel, NgControl, FormControlDirective } from '@angular/forms';

@Directive({
    selector: '[appClearInput]'
})
export class ClearInputDirective implements OnInit, OnDestroy, AfterViewChecked {
    private clearInputIcon: HTMLDivElement = null;
    private searchSelectNodeName = 'SEARCH-SELECT';
    @Input() appClearInputReadonly = false;
    @Output() clearInput = new EventEmitter();

    constructor(private hostElement: ElementRef, @Optional() public ngModel: NgModel,
        @Optional() public ngControl: NgControl) {
    }

    ngOnInit() {
        this.addClearInputIcon();
    }

    ngAfterViewChecked() {
        this.showHideIcon(this.appClearInputReadonly);
    }

    ngOnDestroy() {
        if (this.isSearchSelect()) {
            this.getSearchSelectInputContainer().removeChild(this.clearInputIcon);
        } else {
            this.hostElement.nativeElement.parentNode.removeChild(this.clearInputIcon);
        }
    }

    @HostListener('keyup')
    private onHostKeyUp() {
        this.showHideIcon();
    }

    private showHideIcon(hide: boolean = false) {
        if(hide) {
            this.clearInputIcon.classList.add('hidden');
        } else {
            if (!this.getInputValue()) {
                this.clearInputIcon.classList.add('hidden');
            } else {
                this.clearInputIcon.classList.remove('hidden');
            }
        }       
    }

    private getInputValue() {
        if (this.isSearchSelect()) {
            return this.getSearchSelectInputContainer().children[0].value;
        }
        return this.hostElement.nativeElement.value;
    }

    private clearOutInput = () => {
        if (this.isSearchSelect()) {
            this.getSearchSelectInputContainer().children[0].value = '';

            if (this.ngControl) {
                (<any>this.ngControl.valueAccessor).searchControl.setValue('');
                this.markNgControlAsDirty();
            }
        } else {
            this.hostElement.nativeElement.value = '';
        }
        if (this.ngModel) {
            this.ngModel.update.emit('');
            this.ngModel.control.markAsDirty();
        }
        if (this.ngControl) {
            this.ngControl.valueAccessor.writeValue('');

            if ((<FormControlDirective>this.ngControl).form) {
                (<FormControlDirective>this.ngControl).form.setValue('');
            }

            this.markNgControlAsDirty();
        }
        this.clearInput.emit();
    }

  private markNgControlAsDirty() {
    this.ngControl.control.markAsDirty();
  }

    private isSearchSelect() {
        return this.hostElement.nativeElement.nodeName === this.searchSelectNodeName;
    }

    private getSearchSelectInputContainer() {
        return this.hostElement.nativeElement.children[0].children[0].children[0].children[0];
    }

    private addClearInputIcon() {
        let icon = document.createElement<'div'>('div');
        icon.addEventListener('click', this.clearOutInput);
        icon.innerHTML = 'X';
        icon.classList.add('clear-input-icon');
        if (this.hostElement.nativeElement.type === 'number') {
            icon.classList.add('number-clear-input-icon');
        }

        if (this.isSearchSelect()) {
            this.addIconToSearchSelect(icon);
        } else {
            this.clearInputIcon = this.hostElement.nativeElement.parentNode.insertBefore(icon, this.hostElement.nativeElement.nextSibling);
        }
        this.showHideIcon();
    }

    private addIconToSearchSelect(icon: HTMLDivElement) {
        this.clearInputIcon = this.getSearchSelectInputContainer().appendChild(icon);
    }
}
