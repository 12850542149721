import { apiPathConstants } from './../../core/api/apipathconstants';
import { getPurgeResultResponse } from '../mockjsonresponses/getpurgeresultresponse';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export function purgeMerchantBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];
  const urlParameter= url.split('?')[1];

  if (cleanUrl.includes('purge/url/') && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: getPurgeResultResponse
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('purge/email') && urlParameter.includes('LWC_ID=') && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('purge/phone') && urlParameter.includes('LWC_ID=') && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    })
  }

  if (cleanUrl.includes(apiPathConstants.darkLogo) && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    })
  }

  if (cleanUrl.includes('purge') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: getPurgeResultResponse
    }));
      resp.complete();
    });
  }
}


