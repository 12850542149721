import { finalize } from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { LoadingService } from "../../core/uiservices/loading.service";
import { BPayLookupService } from "./bpaylookup.service";
import { SearchBPayInternal, MerchantSummaryDisplay } from './bpaylookup.models';
import { commaSpace } from '../../core/constants/constants';
import { ValueCheckerService } from '../../core/services/valuechecker.service';

@Component({
  selector: 'app-bpaylookup',
  templateUrl: './bpaylookup.component.html',
  styleUrls: ['./bpaylookup.component.scss']
})
export class BPayLookup implements OnInit {
  columnMode = ColumnMode;
  selectionType: SelectionType;
  searchBPayInternal: SearchBPayInternal[];
  merchantSummaryDisplay: MerchantSummaryDisplay[] = [];

  constructor(private bPaylookupService: BPayLookupService, private loadingService: LoadingService,
    private valueCheckerService: ValueCheckerService) { }

  ngOnInit() {
  }

  searchButtonClicked(bPayCode: string) {
    this.loadingService.setLoading();
    this.bPaylookupService.getBPayInternal(bPayCode)
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response) {
          this.searchBPayInternal = response.data;
          this.setMerchantSummary(response.data);
        }
      })
  }

  getDataFromBPayButtonClicked(bPayCode: string) {
    this.loadingService.setLoading();
    this.bPaylookupService.getEnrichBPay(bPayCode)
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response) {
          this.searchBPayInternal = response.data;
          this.setMerchantSummary(response.data);
        }
      })
  }

  private setMerchantSummary(data: SearchBPayInternal[]) {
    let lwcId: number[] = [];

    for (let lwcIdOfBPay of data) {
      if (!this.valueCheckerService.isNullOrUndefined(lwcIdOfBPay.lwcId)) {
        lwcId.push(lwcIdOfBPay.lwcId);
      }
    }

    this.merchantSummaryDisplay = [];
    if (lwcId.length !== 0) {
      this.loadingService.setLoading();
      this.bPaylookupService.getMerchantsummary(lwcId)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          if (response) {
            for(let merchantSummary of response) {
              let merchantSummaryDisplay: MerchantSummaryDisplay = {
                LWC_ID: merchantSummary.LWC_ID,
                MerchantType: merchantSummary.MerchantType,
                ABN: merchantSummary.ABN,
                MerchantPresence: merchantSummary.MerchantPresence,
                ChainName: merchantSummary.ChainName,
                DataCompletnessScore: merchantSummary.DataCompletnessScore,
                PrimaryName: merchantSummary.PrimaryName,
                PrimaryCategory: merchantSummary.PrimaryCategory,
                PrimaryAddress: merchantSummary.PrimaryAddress,
                Phone: merchantSummary.PhoneNumber,
                URL: merchantSummary.Url,
                Email: merchantSummary.Email,
                RecordLocked: merchantSummary.RecordLocked ? 'Yes' : 'No',
                IsQuarantined: merchantSummary.IsQuarantined ? 'Yes' : 'No',
                Tags: merchantSummary.Tags.toString().replace(/,/g, commaSpace)
              }
              this.merchantSummaryDisplay.push(merchantSummaryDisplay);
            }
          }
        });
    }
  }
}
