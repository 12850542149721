import { BaseStateComponent } from './../basestate/basestate.component';
import { LoadingService } from './../../core/uiservices/loading.service';
import { Component, OnInit, Injector } from '@angular/core';
import { CategoryMappingRulesService } from '../../services/categorymappingrules.service';
import { finalize } from 'rxjs/operators';
import { CategoryMappingRulesState } from './categorymappingrules.state';
import { TagsService } from '../../services/tags.service';
import { CategoryMappingRule, CategoryMappingRuleDisplay, ExternalCategory, GetCategoryByMappingRuleRequest, GetCategoryByMappingRuleResponseDisplay } from './categorymapping.models';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NotificationService } from '../../core/uiservices/notification.service';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { RouteConstants, commaSpace, emptyString } from '../../core/constants/constants';
import { getCategoryByMappingRuleResponseDisplayValues, GetCategoryByMappingMatchMethod } from '../../enums/enums';
import { CategoryService } from '../../services/category.service';
import { Tag } from '../tags/tags.models';
import { FlatCategory } from '../../categories/flatcategory';
import { CategoryMapperService } from '../../categories/categorymapper.service';
import { BankAccountTransactionTypeService } from '../../services/bankaccounttransactiontype.service';

@Component({
  selector: 'app-categorymappingrules',
  templateUrl: './categorymappingrules.component.html',
  styleUrls: ['./categorymappingrules.component.scss']
})
export class CategoryMappingRulesComponent extends BaseStateComponent<CategoryMappingRulesState> implements OnInit {
  categoryDataSources: string[] = [];
  tags: Tag[] = [];
  categoryMappingRules: CategoryMappingRuleDisplay[] = [];
  externalCategories: ExternalCategory[] = [];
  categoryByMappingRuleResponseDisplay: GetCategoryByMappingRuleResponseDisplay[] = [];

  private flatCategories: FlatCategory[] = [];


  constructor(injector: Injector, private loadingService: LoadingService, private categoryMappingRuleService: CategoryMappingRulesService,
    private tagsService: TagsService, private valueCheckerService: ValueCheckerService, private notificationService: NotificationService,
    private router: Router, private categoryService: CategoryService, private categoryMapperService: CategoryMapperService,
    private bankAccountTransactionTypeService: BankAccountTransactionTypeService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      externalCategorySource: "",
      filteredCategoryMappingRules: [],
      categoryMappingRules: [],
      filterForLwcCategoryId: "",
      filterForLwcCategory: "",
      filterForIsRecurring: null,
      filterForExternalCategoryId: "",
      filterForExternalCategoryName: "",
      filterForTagLables: [],
      tagLabels: [],
      testTransactionCal: "",
      testTransactionAmount: null,
      testTransactionBankAccountTransactionType: "",
      testTransactionBankAccountTransactionTypes: []
    };
    this.restoreState();
    this.setCategoryDataSources();
    this.setTransactionTypes();

    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.externalCategorySource)) {
      this.fetchRules();
    }
  }

  removeTagLabelFromListOfSelectedTagLables(tagLabel: string) {
    let indexOfTagLabel = this.state.filterForTagLables.indexOf(tagLabel);
    this.state.filterForTagLables.splice(indexOfTagLabel, 1);
    this.filterCategories();
  }

  selectTagLabel(event: MatAutocompleteSelectedEvent) {
    let tagLabel = event.option.value;
    if (!this.state.filterForTagLables.includes(tagLabel)) {
      this.state.filterForTagLables.push(tagLabel);
      this.filterCategories();
    }
  }

  handleClearInputForExternalCategoryName() {
    this.state.filterForExternalCategoryName = '';
    this.filterCategories();

  }

  handleClearInputForExternalCategoryId() {
    this.state.filterForExternalCategoryId = '';
    this.filterCategories();
  }

  handleClearInputForLwcCategoryId() {
    this.state.filterForLwcCategoryId = '';
    this.filterCategories();
  }

  handleClearInputForLwcCategory() {
    this.state.filterForLwcCategory = '';
    this.filterCategories();
  }

  fetchRules() {
    this.loadingService.setLoading();
    this.clearStatePartial();
    this.categoryService.getFlatCategories()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(categories => {
        this.flatCategories = categories;
        this.setExternalCategoriesAndTagsAndCategoryMappingRules();
    });
  }

  filterCategories() {
    this.state.filteredCategoryMappingRules = this.state.categoryMappingRules.filter((category: CategoryMappingRuleDisplay) => {
      let isMatch = true;

      if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterForLwcCategoryId)) {
        if (category.LwcCategoryId) {
          isMatch = category.LwcCategoryId.toString().includes(this.state.filterForLwcCategoryId);
        }
        else {
          isMatch = false;
        }
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterForLwcCategory)) {
        if (category.LwcCategory) {
          isMatch = category.LwcCategory.toLowerCase().includes(this.state.filterForLwcCategory.toLowerCase());
        }
        else {
          isMatch = false;
        }
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterForIsRecurring)) {
        if (this.valueCheckerService.isNullOrUndefined(category.IsRecurring)) {
            isMatch = this.state.filterForIsRecurring === null ? true : false;
        } else {
            isMatch = category.IsRecurring === this.state.filterForIsRecurring;
        }
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterForExternalCategoryId)) {
        isMatch = category.NewCategoryId.includes(this.state.filterForExternalCategoryId);
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterForExternalCategoryName)) {
        if (category.NewCategoryName) {
          isMatch = category.NewCategoryName.toLowerCase().includes(this.state.filterForExternalCategoryName.toLowerCase());
        }
        else {
          isMatch = false;
        }
      }
      if (isMatch && this.state.filterForTagLables.length > 0) {
        if (category.Tags) {
          for (let selectedTagLabel of this.state.filterForTagLables) {
            isMatch = category.Tags.includes(selectedTagLabel);
            if (isMatch) {
              break;
            }
          }
        }
        else {
          isMatch = false;
        }
      }

      return isMatch;
    });
  }

  addCategoryMappingRule() {
   let newCategoryMappingRule: CategoryMappingRuleDisplay = {
    Id: 0,
    LwcCategoryId: null,
    IsRecurring: null,
    TagIds: [],
    MinAmountInclusive: null,
    MaxAmountInclusive: null,
    NewCategoryId: '',
    CategoryDataSource: this.state.externalCategorySource,
    OutputTagIds: [],
    };

    this.categoryMappingRuleService.setEditCategoryMappingRule(newCategoryMappingRule);
    this.router.navigate([RouteConstants.editCategoryMappingRule]);
  }

  editCategoryMappingRule(category: CategoryMappingRuleDisplay) {
    let editingCategory = {...category};
    editingCategory.CategoryDataSource = this.state.externalCategorySource;
    let externalCategory = this.externalCategories.find(t => t.CategoryName === editingCategory.NewCategoryName);
    editingCategory.NewCategoryName = externalCategory !== undefined ? externalCategory.FullCategoryPath : "";

    this.categoryMappingRuleService.setEditCategoryMappingRule(cloneDeep(editingCategory));
    this.router.navigate([RouteConstants.editCategoryMappingRule]);
  }

  deleteCategoryMappingRule(category: CategoryMappingRuleDisplay, indexOfCategory: number) {
    this.loadingService.setLoading();
    this.categoryMappingRuleService.deleteCategoryMappingRule(category.Id)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.state.filteredCategoryMappingRules.splice(indexOfCategory, 1);
        this.state.filteredCategoryMappingRules = [...this.state.filteredCategoryMappingRules];
        this.notificationService.notifySuccess();
      });
  }

  isTestButtonDisabled() {
    return !this.state.testTransactionAmount || !this.state.testTransactionCal;
  }

  testTransaction() {
    this.loadingService.setLoading();
    let getCategoryByMappingRuleRequest: GetCategoryByMappingRuleRequest = {
      Cal: this.state.testTransactionCal,
      Amount: this.state.testTransactionAmount,
      BankAccountTransactionType: this.state.testTransactionBankAccountTransactionType,
      CategoryDataSourceName: this.state.externalCategorySource
    };

    this.categoryMappingRuleService.getCategoryByMappingRule(getCategoryByMappingRuleRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (!this.valueCheckerService.isNullOrUndefined(response)) {
          this.categoryByMappingRuleResponseDisplay = [];
          if (Array.isArray(response)) {
            this.categoryByMappingRuleResponseDisplay = response;
          } else {
            this.categoryByMappingRuleResponseDisplay.push(response);
          }
          this.setDisplayColumnsForCategoryByMappingRule();
        }
      });
  }

  private clearStatePartial() {
    this.state.categoryMappingRules = this.state.filteredCategoryMappingRules = [];
    this.state.tagLabels = [];
    this.state.filterForTagLables = [];
  }

  private setCategoryMappingRulesAndDisplayColumns() {
    this.loadingService.setLoading();
    this.state.categoryMappingRules = this.state.filteredCategoryMappingRules = [];
    this.categoryMappingRuleService.getCategoryMappingRules(this.state.externalCategorySource)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
          this.state.categoryMappingRules = this.state.filteredCategoryMappingRules = response;
          this.state.tagLabels = [];
          this.state.filterForTagLables = [];
          this.setDisplayColumnsForCategoryMappingRules(response);
      });
  }

  private setDisplayColumnsForCategoryMappingRules(response: CategoryMappingRule[]) {
    for (let i = 0; i < response.length; i++) {
      let categoryMappingRules = this.state.categoryMappingRules[i];

      if (categoryMappingRules.LwcCategoryId) {
        let fullPathCategories = this.categoryMapperService.mapToFullCategoryPath(this.flatCategories);
        let category = fullPathCategories.find(t => t.id === categoryMappingRules.LwcCategoryId);

        if (category) {
          categoryMappingRules.LwcCategory = category.name;
        }
      }

      let externalCategory = this.externalCategories.find(t => t.CategoryId === categoryMappingRules.NewCategoryId);
      if (externalCategory) {
        categoryMappingRules.NewCategoryName = externalCategory.CategoryName;
      }

      if (response[i].IsRecurring) {
        categoryMappingRules.IsRecurringDisplay = response[i].IsRecurring === true ? "✓" : "";
      }

      categoryMappingRules.Tags = emptyString;
      categoryMappingRules.OutputTags = emptyString;
      for (let k = 0; k < response[i].TagIds.length; k++) {
        let tagId = response[i].TagIds[k];
        let tag = this.tags.find(t => t.id === tagId);
        if (tag) {
          categoryMappingRules.Tags = response[i].TagIds.length > k + 1
            ? categoryMappingRules.Tags + tag.tagLabel + commaSpace
            : categoryMappingRules.Tags + tag.tagLabel;
        }
      }

      if (!this.valueCheckerService.isNullOrUndefined(response[i]?.OutputTagIds)) {
        for (let k = 0; k < response[i].OutputTagIds.length; k++) {
          let tagId = response[i].OutputTagIds[k];
          let tag = this.tags.find(t => t.id === tagId);
          if (tag) {
            categoryMappingRules.OutputTags = response[i].OutputTagIds.length > k + 1
              ? categoryMappingRules.OutputTags + tag.tagLabel + commaSpace
              : categoryMappingRules.OutputTags + tag.tagLabel;
          }
        }

        if (categoryMappingRules.OutputTags.endsWith(commaSpace)) {
          categoryMappingRules.OutputTags = categoryMappingRules.OutputTags.slice(0, categoryMappingRules.OutputTags.length - commaSpace.length);
        }
      }

      if (this.state.tagLabels.length == 0) {
        this.state.tagLabels = categoryMappingRules.Tags.split(commaSpace);
      }
      else {
        this.state.tagLabels = [...this.state.tagLabels, ...categoryMappingRules.Tags.split(commaSpace)];
      }

      if (categoryMappingRules.Tags.endsWith(commaSpace)) {
        categoryMappingRules.Tags = categoryMappingRules.Tags.slice(0, categoryMappingRules.Tags.length - commaSpace.length);
      }
    }
    this.state.tagLabels = this.state.tagLabels.filter((value, index) => this.state.tagLabels.indexOf(value) === index && value !== '');
    this.filterCategories();
  }



  private setExternalCategoriesAndTagsAndCategoryMappingRules() {
    this.loadingService.setLoading();
    this.categoryMappingRuleService.getExternalCategories(this.state.externalCategorySource)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.length > 0) {
          this.externalCategories = response;
          this.setTagsAndCategoryMappingRules();
        }
      });
  }

  private setTagsAndCategoryMappingRules() {
    this.loadingService.setLoading();
    this.tagsService.getTags()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.tags = response;
        this.setCategoryMappingRulesAndDisplayColumns();
      });
  }

  private setCategoryDataSources() {
    this.loadingService.setLoading();
    this.categoryMappingRuleService.getCategoryDataSources()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.length > 0) {
          this.categoryDataSources = response;
          this.categoryDataSources.sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
          });
        }
      });
  }

  private setTransactionTypes() {
    this.loadingService.setLoading();
    this.bankAccountTransactionTypeService.getBankAccountTransactionTypes()
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.length > 0) {
          this.state.testTransactionBankAccountTransactionTypes = response.data;
          this.state.testTransactionBankAccountTransactionTypes.sort((a, b) => {
            let firstType = a.name.toUpperCase();
            let secondType = b.name.toUpperCase();

            if (firstType < secondType) {
              return -1;
            }
            if (firstType > secondType) {
              return 1;
            }
            return 0;
          });
        }
      });
  }

  private setDisplayColumnsForCategoryByMappingRule() {
    for (let categoryByMappingRule of this.categoryByMappingRuleResponseDisplay) {
      categoryByMappingRule.MatchMethodDisplay = getCategoryByMappingRuleResponseDisplayValues.get(categoryByMappingRule.MatchMethod);
      let categoryMappingRule = this.state.categoryMappingRules.find(t => t.Id === categoryByMappingRule.RuleId);

      let externalCategory = this.externalCategories.find(t => t.CategoryId === categoryByMappingRule.ExternalCategoryId);
      categoryByMappingRule.ExternalCategoryName  = externalCategory ? externalCategory.CategoryName : null;

      if (categoryByMappingRule.MatchMethod === GetCategoryByMappingMatchMethod.CategoryMappingRule && categoryMappingRule) {
        categoryByMappingRule.LwcCategoryId = categoryMappingRule.LwcCategoryId ? categoryMappingRule.LwcCategoryId : null;
        categoryByMappingRule.IsRecurring = categoryMappingRule.IsRecurringDisplay ? categoryMappingRule.IsRecurringDisplay : null;
        categoryByMappingRule.Tags = categoryMappingRule.Tags ? categoryMappingRule.Tags : null;
        categoryByMappingRule.MinAmountInclusive = categoryMappingRule.MinAmountInclusive ? categoryMappingRule.MinAmountInclusive : null;
        categoryByMappingRule.MaxAmountInclusive = categoryMappingRule.MaxAmountInclusive ? categoryMappingRule.MaxAmountInclusive : null;

        if(categoryMappingRule.LwcCategoryId) {
          this.categoryService.getFlatCategories().subscribe(cat => {
            let category = cat.find(t => t.CategoryID === categoryMappingRule.LwcCategoryId);
            categoryByMappingRule.LwcCategoryName  = category ? category.CategoryName : null;
          });
        };
        }
    }
  }
}
