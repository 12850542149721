import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { updateMerchantChainLogoResponse } from "../mockjsonresponses/merchantchainlogosresponse";

export function merchantChainLogosBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  
    const cleanUrl = url.split('?')[0];
    if (cleanUrl.includes("merchant/updatelogos") && method === 'POST') {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: updateMerchantChainLogoResponse
        }));
        resp.complete();
      });
    }
  }
  