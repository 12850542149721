import { retailerCenterBasedOnCenterId, updatedRetailerCenterWithLocationIdResponse } from '../mockjsonresponses/westfieldlocation.response';
import { Observable } from 'rxjs';
import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { westfieldLocationsResponse } from '../mockjsonresponses/westfieldlocation.response';

export function westfieldLocationBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesRetailerCenters = false;
  let includesCentresId = false;

  if (request.body && request.body.query) {
    includesRetailerCenters = request.body.query.includes('retailerCenters');
    includesCentresId = request.body.query.includes('where: {and: [{centresId: {eq');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesRetailerCenters && !includesCentresId) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...westfieldLocationsResponse }
      }));
      resp.complete();
    });
  }

  let includesUpdateRetailerCenterWithLocationId = false;
  if (request.body && request.body.query) {
    includesUpdateRetailerCenterWithLocationId = request.body.query.includes('updateRetailerCenterWithLocationId');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesUpdateRetailerCenterWithLocationId) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...updatedRetailerCenterWithLocationIdResponse }
      }));
      resp.complete();
    });
  }

  includesCentresId = false;
  if (request.body && request.body.query) {
    includesCentresId = request.body.query.includes('where: {and: [{centresId: {eq');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesCentresId) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...retailerCenterBasedOnCenterId }
      }));
      resp.complete();
    });
  }
}
