import { apiPathConstants } from './../core/api/apipathconstants';
import { Injectable } from "@angular/core";
import { ApiClient } from "../core/api/apiclient";
import { ApiService } from "../core/api/api.service";
import { Observable } from 'rxjs';
import { Tag } from '../components/tags/tags.models';

@Injectable({
  providedIn: "root"
})

export class TagsService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) {}

  getTags(): Observable<Tag[]> {
    return this.apiClient.get<Tag[]>(this.apiService.buildUrl(apiPathConstants.tag));
  }

  tagUpdatedOfNewTagCreated(tag: Object) {
    return this.apiClient.put<void>(this.apiService.buildUrl(apiPathConstants.tag), tag);
  }

  deleteTag(tagId: number) {
    return this.apiClient.delete<void>(this.apiService.buildUrl(apiPathConstants.tag, tagId.toString()));
  }
}
