import { Component, Input } from "@angular/core";
import { PurgeResult, PurgeTaskResult } from "../../../models/purgeresult";

@Component({
  selector: 'app-merchantpurgeresult',
  templateUrl: './merchantpurgeresult.component.html',
  styleUrls: ['./merchantpurgeresult.component.scss']
})
export class MerchantPurgeResultComponent {
  @Input() purgeResult: PurgeResult;

  getPurgeResultClass(purgeTaskResult: PurgeTaskResult): string {
    let className = '';

    switch (purgeTaskResult) {
        case PurgeTaskResult.Success: {
            className = 'success';
            break;
        }
        case PurgeTaskResult.Failure: {
            className = 'danger';
            break;
        }
    }

    return className;
  }

  getPurgeResultText(purgeTaskResult: PurgeTaskResult): string {
    let purgeState = '';

    switch (purgeTaskResult) {
      case PurgeTaskResult.Success: {
        purgeState = 'Success';
        break;
      }
      case PurgeTaskResult.Failure: {
        purgeState = 'Fail';
        break;
      }
      case PurgeTaskResult.NotRequired: {
        purgeState = 'Not required';
        break;
      }
    }

    return purgeState;
  }
}
