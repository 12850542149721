import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HotkeyInfoMessage } from '../../shared/models/hotkeyinfomessage';

@Injectable()
export class HotkeyInfoService {
    private hotkeyInfo = new Subject<HotkeyInfoMessage>();
    hotkeyInfo$ = this.hotkeyInfo.asObservable();

    setHotkeyInfo(hotkeyInfoMessage: HotkeyInfoMessage) {
        this.hotkeyInfo.next(hotkeyInfoMessage);
    }
}
