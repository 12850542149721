import { ApiClient } from '../core/api/apiclient';
import { Injectable } from "@angular/core";
import { ApiService } from '../core/api/api.service';
import { apiPathConstants } from '../core/api/apipathconstants';
import { ExternalCategory, CategoryMappingRule, CategoryMappingRuleDisplay, GetCategoryByMappingRuleResponse, GetCategoryByMappingRuleRequest } from '../components/categorymappingrules/categorymapping.models';

@Injectable({
  providedIn: "root"
})

export class CategoryMappingRulesService {
  private editCategoryMappingRule: CategoryMappingRuleDisplay;

  constructor(private apiClient: ApiClient, private apiService: ApiService) {
  }

  getCategoryDataSources() {
    return this.apiClient.get<string[]>(this.apiService.buildUrl(apiPathConstants.allCategoryDataSources));
  }

  getExternalCategories(categoryDataSourceName: string) {
    return this.apiClient.get<ExternalCategory[]>(this.apiService.buildUrl(apiPathConstants.allExternalCategories, categoryDataSourceName));
  }

  getCategoryMappingRules(categoryDataSourceName: string) {
    return this.apiClient.get<CategoryMappingRule[]>(this.apiService.buildUrl(apiPathConstants.allCategoryMappingRules, categoryDataSourceName));
  }

  setEditCategoryMappingRule(categoryMappingRule: CategoryMappingRuleDisplay) {
    this.editCategoryMappingRule = categoryMappingRule;
  }

  getEditCategoryMappingRule() {
    return this.editCategoryMappingRule;
  }

  deleteCategoryMappingRule(id: number) {
    return this.apiClient.delete<void>(this.apiService.buildUrl(apiPathConstants.deleteRule, id.toString()));
  }

  saveCategoryMappingRule(categoryMappingRule: CategoryMappingRule) {
    return this.apiClient.post<CategoryMappingRule>(this.apiService.buildUrl(apiPathConstants.upsertrule), categoryMappingRule);
  }

  getCategoryByMappingRule(categoryByMappingRuleRequest: GetCategoryByMappingRuleRequest) {
    return this.apiClient.post<GetCategoryByMappingRuleResponse[]>(this.apiService.buildUrl(apiPathConstants.getcategorybymappingrule), categoryByMappingRuleRequest);
  }
}
