import { ExternalCategory } from "../../components/manageexternalcategories/manageexternalcategories.models";

export let manageExternalCategoriesResponse: ExternalCategory[] = [
  {
    Id : 1419,
    CategoryDataSource : "HEM",
    CategoryId : "01",
    CategoryName : "CURRENT HOUSING COSTS (SELECTED DWELLING)",
    FullCategoryPath : "CURRENT HOUSING COSTS (SELECTED DWELLING)",
    Children : [
			{
        Id : 1420,
        CategoryDataSource : "HEM",
        CategoryId : "0101",
        CategoryName : "Current housing costs (selected dwelling)",
        ParentId : "01",
        FullCategoryPath : "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling)",
        Children : [
					{
            Id : 1421,
            CategoryDataSource : "HEM",
            CategoryId : "010101",
            CategoryName : "Rent payments",
            ParentId : "0101",
            FullCategoryPath : "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
            Children : [
							{
                Id : 1422,
                CategoryDataSource : "HEM",
                CategoryId : "01010101",
                CategoryName : "Rent payments",
                ParentId : "010101",
                FullCategoryPath : "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                Children : [
									{
										 Id : 1423,
										 CategoryDataSource : "HEM",
										 CategoryId : "0101010101",
										 CategoryName : "Rent payments",
										 ParentId : "01010101",
										 FullCategoryPath : "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
										 Children : [
                       {
                         Id: 1423,
                         CategoryDataSource: "HEM",
                         CategoryId: "1111111111",
                         CategoryName: "Rent payments",
                         ParentId: "0101010101",
                         FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
                         Children: [{
                           Id: 1423,
                           CategoryDataSource: "HEM",
                           CategoryId: "111101111111",
                           CategoryName: "Rent payments",
                           ParentId: "1111111111",
                           FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
                           Children: [],
                           IsLWC: false,
                           Standardisers: [],
                           StandardiserStatus: 1
                         }],
                         IsLWC: false,
                         Standardisers: [],
                         StandardiserStatus: 1
                       }
                     ],
										 IsLWC : false,
										 Standardisers : [],
										 StandardiserStatus : 1
									}
								],
								 IsLWC : false,
								 Standardisers : [],
								 StandardiserStatus : 1
							}],
						 IsLWC : false,
						 Standardisers : [],
						 StandardiserStatus : 1
					},
          {
            Id: 1424,
            CategoryDataSource: "HEM",
            CategoryId: "010102",
            CategoryName: "Rent payments",
            ParentId: "0101",
            FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
            Children: [
              {
                Id: 1425,
                CategoryDataSource: "HEM",
                CategoryId: "01010102",
                CategoryName: "Rent payments",
                ParentId: "010102",
                FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                Children: [
                  {
                    Id: 1426,
                    CategoryDataSource: "HEM",
                    CategoryId: "0101010102",
                    CategoryName: "Rent payments",
                    ParentId: "01010102",
                    FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  }
                ],
                IsLWC: false,
                Standardisers: [],
                StandardiserStatus: 1
              }],
            IsLWC: false,
            Standardisers: [],
            StandardiserStatus: 1
          },
          {
            Id: 1427,
            CategoryDataSource: "HEM",
            CategoryId: "010103",
            CategoryName: "Rent payments",
            ParentId: "0101",
            FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
            Children: [],
            IsLWC: false,
            Standardisers: [],
            StandardiserStatus: 1
          }],
      },
      {
        Id: 1430,
        CategoryDataSource: "HEM",
        CategoryId: "0104",
        CategoryName: "Current housing costs (selected dwelling)",
        ParentId: "01",
        FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling)",
        Children: [
          {
            Id: 1431,
            CategoryDataSource: "HEM",
            CategoryId: "010104",
            CategoryName: "Rent payments",
            ParentId: "0104",
            FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
            Children: [
              {
                Id: 1432,
                CategoryDataSource: "HEM",
                CategoryId: "01010104",
                CategoryName: "Rent payments",
                ParentId: "010104",
                FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                Children: [
                  {
                    Id: 1433,
                    CategoryDataSource: "HEM",
                    CategoryId: "0101010104",
                    CategoryName: "Rent payments",
                    ParentId: "01010104",
                    FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  }
                ],
                IsLWC: false,
                Standardisers: [],
                StandardiserStatus: 1
              }],
            IsLWC: false,
            Standardisers: [],
            StandardiserStatus: 1
          }],
      },
      {
        Id: 1434,
        CategoryDataSource: "HEM",
        CategoryId: "0105",
        CategoryName: "Current housing costs (selected dwelling)",
        ParentId: "01",
        FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling)",
        Children: [
          {
            Id: 1435,
            CategoryDataSource: "HEM",
            CategoryId: "010105",
            CategoryName: "Rent payments",
            ParentId: "0105",
            FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
            Children: [
              {
                Id: 1436,
                CategoryDataSource: "HEM",
                CategoryId: "01010105",
                CategoryName: "Rent payments",
                ParentId: "010105",
                FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                Children: [
                  {
                    Id: 1437,
                    CategoryDataSource: "HEM",
                    CategoryId: "0101010105",
                    CategoryName: "Rent payments",
                    ParentId: "01010105",
                    FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  }
                ],
                IsLWC: false,
                Standardisers: [],
                StandardiserStatus: 1
              }],
            IsLWC: false,
            Standardisers: [],
            StandardiserStatus: 1
          }],
      }],
  },
  {
    Id: 1438,
    CategoryDataSource: "HEM",
    CategoryId: "11",
    CategoryName: "CURRENT HOUSING COSTS",
    FullCategoryPath: "CURRENT HOUSING COSTS",
    Children: [],
    IsLWC: false,
    Standardisers: [],
    StandardiserStatus: 1
  },
  {
    Id: 1438,
    CategoryDataSource: "HEM",
    CategoryId: "60",
    CategoryName: "CURRENT HOUSING COSTS",
    FullCategoryPath: "CURRENT HOUSING COSTS",
    Children: [
      {
        Id: 1439,
        CategoryDataSource: "HEM",
        CategoryId: "6060",
        CategoryName: "Current housing costs (selected dwelling)",
        ParentId: "60",
        FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling)",
        Children: [
          {
            Id: 1440,
            CategoryDataSource: "HEM",
            CategoryId: "606060",
            CategoryName: "Rent payments",
            ParentId: "6060",
            FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
            Children: [
              {
                Id: 1441,
                CategoryDataSource: "HEM",
                CategoryId: "60606060",
                CategoryName: "Rent payments",
                ParentId: "606060",
                FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                Children: [
                  {
                    Id: 1442,
                    CategoryDataSource: "HEM",
                    CategoryId: "6060606060",
                    CategoryName: "Rent payments",
                    ParentId: "60606060",
                    FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  }
                ],
                IsLWC: false,
                Standardisers: [],
                StandardiserStatus: 1
              }],
            IsLWC: false,
            Standardisers: [],
            StandardiserStatus: 1
          },
          {
            Id: 1443,
            CategoryDataSource: "HEM",
            CategoryId: "707070",
            CategoryName: "Current housing costs (selected dwelling)",
            ParentId: "6060",
            FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling)",
            Children: [
              {
                Id: 1444,
                CategoryDataSource: "HEM",
                CategoryId: "70707070",
                CategoryName: "Rent payments",
                ParentId: "707070",
                FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
                Children: [
                  {
                    Id: 1445,
                    CategoryDataSource: "HEM",
                    CategoryId: "7070707070",
                    CategoryName: "Rent payments",
                    ParentId: "70707070",
                    FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  },
                  {
                    Id: 1446,
                    CategoryDataSource: "HEM",
                    CategoryId: "707070777",
                    CategoryName: "Rent payments",
                    ParentId: "70707070",
                    FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  }
                ],
                IsLWC: false,
                Standardisers: [],
                StandardiserStatus: 1
              },
              {
                Id: 1447,
                CategoryDataSource: "HEM",
                CategoryId: "80808080",
                CategoryName: "Rent payments",
                ParentId: "707070",
                FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
                Children: [
                  {
                    Id: 1448,
                    CategoryDataSource: "HEM",
                    CategoryId: "8080808080",
                    CategoryName: "Rent payments",
                    ParentId: "80808080",
                    FullCategoryPath: "CURRENT HOUSING COSTS (SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  }],
                IsLWC: false,
                Standardisers: [],
                StandardiserStatus: 1
              }],
          }],
      }],
  },
  {
    Id: 1438,
    CategoryDataSource: "HEM",
    CategoryId: "90",
    CategoryName: "CURRENT HOUSING COSTS",
    FullCategoryPath: "CURRENT HOUSING COSTS",
    Children: [],
    IsLWC: false,
    Standardisers: [],
    StandardiserStatus: 1
  },
]


export let updatedManageExternalCategoriesResponse: ExternalCategory[] = [
  {
    Id: 1467,
    CategoryDataSource: "HEM",
    CategoryId: "02",
    CategoryName: "(SELECTED DWELLING)",
    FullCategoryPath: "(SELECTED DWELLING)",
    Children: [
      {
        Id: 1486,
        CategoryDataSource: "HEM",
        CategoryId: "0202",
        CategoryName: "Current housing costs (selected dwelling)",
        ParentId: "02",
        FullCategoryPath: "(SELECTED DWELLING) >> Current housing costs (selected dwelling)",
        Children: [
          {
            Id: 1498,
            CategoryDataSource: "HEM",
            CategoryId: "020202",
            CategoryName: "Rent payments",
            ParentId: "0202",
            FullCategoryPath: "(SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments",
            Children: [
              {
                Id: 1474,
                CategoryDataSource: "HEM",
                CategoryId: "02020202",
                CategoryName: "Rent payments",
                ParentId: "020202",
                FullCategoryPath: "(SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments",
                Children: [
                  {
                    Id: 1436,
                    CategoryDataSource: "HEM",
                    CategoryId: "0202020202",
                    CategoryName: "Rent payments",
                    ParentId: "02020202",
                    FullCategoryPath: "(SELECTED DWELLING) >> Current housing costs (selected dwelling) >> Rent payments >> Rent payments >> Rent payments",
                    Children: [],
                    IsLWC: false,
                    Standardisers: [],
                    StandardiserStatus: 1
                  }
                ],
                IsLWC: false,
                Standardisers: [],
                StandardiserStatus: 1
              }],
            IsLWC: false,
            Standardisers: [],
            StandardiserStatus: 1
          }],
      }],
  },
]
