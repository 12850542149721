import { propertyOf } from "../../core/services/reflection.service";

export interface MerchantEvent {
    TradingName: string;
    LegalName: string;
    Abn: string;
    EventType: string;
    EventSource: string;
    EventSourceId: string;
    EventDate: string;
    Modified: string;
    State: number;
    ModifiedBy: number;
    MerchantEventId: number;
    ModifiedIndexerName: string
}

export const merchantEventProps = {
  TradingName: propertyOf<MerchantEvent>('TradingName'),
  LegalName: propertyOf<MerchantEvent>('LegalName'),
  Abn: propertyOf<MerchantEvent>('Abn'),
  EventType: propertyOf<MerchantEvent>('EventType'),
  EventSource: propertyOf<MerchantEvent>('EventSource'),
  EventSourceId: propertyOf<MerchantEvent>('EventSourceId'),
  State: propertyOf<MerchantEvent>('State'),
  EventDate: propertyOf<MerchantEvent>('EventDate'),
  MerchantEventId: propertyOf<MerchantEvent>('MerchantEventId')
};
