import { OverallResult, ValidateMerchantRecordResult } from '../../enums/enums';

export let getEnrichMerchantResponse = {
  LWC_ID: 1000274686,
  BuildMerchantRecordOutcome: OverallResult.Warning,
  OverallResult: OverallResult.Success,
  Comment: 'Test comment',
  GoogleSearch: {
    Outcome: true,
    Result: 'GoogleDataLoadedIntoIndexedMerchant',
    Comments: 'Google comments'
  },
  LoadABR: {
    Outcome: true,
    Comments: 'ABN comments'
  },
  SensisSearch: {
    Outcome: true,
    Comments: 'Sensis comments'
  },
  ClearbitSearch: {
    Outcome: true,
    Comments: 'Clearbit comments'
  },
  RitekitSearch: {
    Outcome: true,
    Comments: 'Ritekit comments'
  },
  NoSQLDBsLoad: {
    Outcome: true,
    Comments: 'No SQL DBs comments'
  },
  PredictedRecords: {
    Outcome: true,
    Comments: 'Predicted Records Comments'
  },
  CardAcceptorLocatorSet: {
    Outcome: true,
    Comments: 'Card Acceptor Locator Set Comments'
  },
  LoadAssociatesAndAKAs: {
    Outcome: true,
    Comments: 'Load Associates And AKAs Comments '
  },
  MastercardSearch: {
    MastercardSearchOutcome: 0,
    Comments: 'Mastercard Search Comments',
    OverrideMastercardExistanceCheck: true
  },
  HereMapsSearch: {
    Outcome: true,
    Result: 'HereMapsDataLoadedIntoIndexedMerchant',
    Comments: 'HereMaps Comments'
  },
  MerchantWebsiteCapture: {
    Outcome: true,
    Comments: 'Merchants Website Capture Comments'
  },
  ZomatoSearch: {
    Outcome: true,
    Comments: 'Zomato Search Comments'
  },
  LoadChainInformation: {
    Outcome: true,
    Comments: 'Load Chain Information Comments'
  },
  NewPrimaryName: {
    Outcome: true,
    Comments: 'New Primary Name Comments'
  },
  ASICScreenScrape: {
    Outcome: true,
    Comments: 'ASIC Screen Scrape Comments'
  },
  PrimaryWebAddress: {
    Outcome: true,
    Comments: 'New Primary Web Address Comments'
  },
  OnlineMobile: {
    Outcome: true,
    Comments: 'Online Mobile Comments'
  },
  PrimaryPhoneNumber: {
    Outcome: true,
    Comments: 'Primary Phone Number Comments'
  },
  PrimaryEmailAddress: {
    Outcome: true,
    Comments: 'Primary Email Address Comments'
  },
  AddDescription: {
    Outcome: true,
    Comments: 'Description Comments'
  },
  Category: {
    Outcome: true,
    Comments: 'Category Comments'
  },
  PrimaryAddress: {
    Outcome: true,
    Comments: 'PrimaryAddress Comments'
  },
  QueryTransactionProcess: {
    Outcome: true,
    Comments: 'Query Transaction Process is already set as Sample process (to be deleted)',
    TaskExecuted: true,
    ExecutionTimeMS: 0
  },
  URLToDisputeProcessPage: {
    Outcome: true,
    Comments: 'URL To Dispute Process Page is already set as Sample URL (to be deleted)',
    TaskExecuted: false,
    ExecutionTimeMS: 0
  },
  CurrentBusinessStatus: {
    Outcome: true,
    Comments: 'Invalid Business Status Healthy provided',
    TaskExecuted: true,
    ExecutionTimeMSv: 0
  },
  ValidationResult: {
    Result: ValidateMerchantRecordResult.Error,
    Errors: ['Test error1', 'Test errors2']
  },
  Tags: {
    Outcome: true,
    Comments: 'Tags Comments',
    TaskExecuted: true,
  },
  Bsbs: {
    Outcome: true,
    Comments: 'Bsbs Comments',
    TaskExecuted: true,
  },
  DirectEntryCode: {
    Outcome: true,
    Comments: "Direct Entry Code Comments",
    TaskExecuted: true,
  },
  BPayBillerCode: {
    Outcome: true,
    Comments: "BPay Biller Code Comments",
    TaskExecuted: true,
  },
  TomTomSearch : {
    Outcome: true,
    Comments: "TomTom Comments",
    TaskExecuted: true,
  },
  DataRichnessScore: 2.3,
  ExecutionTimeInSeconds: 23
};
