import { MerchantLogoResponse } from "../../components/merchantlogo/merchantlogo.response";

export let merchantLogoResponse: MerchantLogoResponse = {
  logos: [
    {
      logoPath: "https://images.lookwhoscharging.com/0b393ced-836d-446b-a3bf-2589662b63db/Logo_Scraped_1.png",
      source: "Scraped"
    },
    {
      logoPath: "https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png",
      source: "Sprout Social"
    },
    {
      logoPath: "https://www.russie.campusfrance.org/sites/pays/files/russie/2018-04/index_1.png",
      source: "Campus France"
    },
    {
      logoPath: "https://www.gmc.com/content/dam/gmc/na/us/english/primary-nav-icons/gmc-logo-rgb-vert-neg-wapg-19PGCB00932.png?imwidth=960",
      source: "GMS"
    }
  ],
  screenShot: 'https://logomaster.ai/static/media/gallery015.0961dcb3.png',
  screenShotUpdated: '2020-10-12T05:45:00',
  comment: 'Screenshot Updated.',
}
