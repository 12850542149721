import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BankAccountTransactionType, NameDescSearchTypesDto } from '../../models/ausindexingresult';
import { matchConfidenceNames, lookupTypeMap } from '../../core/constants/constants';
import { DialogService } from '../../core/uiservices/dialog.service';
import { LookupType, MatchConfidence } from '../../enums/enums';
import { CollectionTransformerService } from '../../core/collections/collectiontransformer.service';
import { BankAccountTransactionTypeService } from '../../services/bankaccounttransactiontype.service';
import { LoadingService } from '../../core/uiservices/loading.service';

@Component({
  selector: 'app-lookup-options',
  templateUrl: './lookupoptions.component.html',
  styleUrls: ['./lookupoptions.component.scss']
})
export class LookupOptionsComponent implements OnInit {
  @Input() lookupOptions: NameDescSearchTypesDto[];
  @Input() heading: string;
  @Input() showBankAccountTransactionTypes: boolean = false;

  @Output() change = new EventEmitter();

  arrayForDisplay: NameDescSearchTypesDto[];
  matchConfidenceNames = matchConfidenceNames;
  lookupTypeNames = this.collectionTransformerService.mapToArray(lookupTypeMap).filter(l => l.key !== LookupType.Custom);
  bankAccountTransactionTypes: BankAccountTransactionType[] = [];

  constructor(private dialogService: DialogService, private collectionTransformerService: CollectionTransformerService,
    private bankAccountTransactionTypeService: BankAccountTransactionTypeService, private loadingService: LoadingService) { }

  ngOnInit() {
    this.setTransactionTypes();
    this.arrayForDisplay = this.lookupOptions;
  }

  isLookupTypeCustom(row: NameDescSearchTypesDto) {
    if (row.LookupType === LookupType.Custom) {
      return true;
    }
    return false;
  }

  createLookupOption() {
    let newRecord: NameDescSearchTypesDto;

    if (this.showBankAccountTransactionTypes) {
      newRecord = {
        CategoryMatchPatternId: null,
        SearchString: '',
        LookupType: LookupType.StartsWith,
        MatchConfidence: MatchConfidence.High,
        LastUpdated: new Date().toISOString(),
        BankAccountTransactionTypes: [],
        SearchStringNoSpaces: null,
				SearchStringsNoSpaces: [],
				SearchStrings: [],
      };
    }
    else {
      newRecord = {
        CategoryMatchPatternId: null,
        SearchString: '',
        LookupType: LookupType.StartsWith,
        MatchConfidence: MatchConfidence.High,
        LastUpdated: new Date().toISOString(),
      };
    }

    this.lookupOptions.unshift(newRecord);
    this.arrayForDisplay = [...this.lookupOptions];

    this.onLookupOptionChange();
  }

  deleteLookupOption(value: NameDescSearchTypesDto) {
    const dialogRef = this.dialogService.openDeleteConfirmDialog();

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.lookupOptions.splice(this.lookupOptions.indexOf(value), 1);

        this.arrayForDisplay = [...this.lookupOptions];

        this.onLookupOptionChange();
      }
    });
  }

  onLookupOptionChange() {
    this.change.emit();
  }

  private setTransactionTypes() {
    this.loadingService.setLoading();
    this.bankAccountTransactionTypeService.getBankAccountTransactionTypes().subscribe(response => {
        this.loadingService.clearLoading();
        this.bankAccountTransactionTypes = response.data;
      });
  }
}
