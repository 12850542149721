import { ExecutionResult } from "graphql";
import { PersonNameType } from "../../enums/enums";
import { PersonName } from "../../components/managepersonnames/managepersonnamesstate";

export let manageNamesResponse: ExecutionResult<PersonName[]> = {
  data: [
    {
      name: 'Mason',
      nameType: PersonNameType.FirstName
    },
    {
      name: 'Harper',
      nameType: PersonNameType.FirstName
    }
  ]
};

export let deletePersonName: ExecutionResult<unknown> = { data: { deletePersonName: {} } };

export let addPersonName: ExecutionResult<unknown> = { data: { addPersonName: {} } };
