import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { allValidationStatsDetailResponse, validationStatsByIndexerResponse, validationStatsByValidationIdResponse, validationStatsByValidationResponse } from '../mockjsonresponses/merchantvalidationstats.response';

export function merchantValidationStatsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesFetchValidationStatsByValidation = false;
  if (request.body && request.body.query) {
    includesFetchValidationStatsByValidation = request.body.query.includes('fetchValidationStatsByValidation');
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesFetchValidationStatsByValidation) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...validationStatsByValidationResponse }
      }));
      resp.complete();
    });
  }


  let includesFetchValidationStatsDetail = false;
  if (request.body && request.body.query) {
    includesFetchValidationStatsDetail = request.body.query.includes('{\n  data:fetchValidationStatsDetail(where: { and: [ { validationId: {eq: {0}} },');
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesFetchValidationStatsDetail) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...validationStatsByValidationIdResponse }
      }));
      resp.complete();
    });
  }

  let includesFetchValidationStatsByUser = false;
  if (request.body && request.body.query) {
    includesFetchValidationStatsByUser = request.body.query.includes('fetchValidationStatsByUser');
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesFetchValidationStatsByUser) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...validationStatsByIndexerResponse }
      }));
      resp.complete();
    });
  }

  let includesFetchValidationStatsByAllPages = false;
  if(request.body && request.body.query) {
    includesFetchValidationStatsByAllPages = request.body.query.includes('fetchValidationStatsDetail');
  }
  if(cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesFetchValidationStatsByAllPages) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...allValidationStatsDetailResponse }
      }));
      resp.complete();
    });
  }
}
