import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';


@Injectable()
export class CALService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  getCALs(oDataQueryPart: string) {
    return this.apiClient.get<any>(this.apiService
      .buildUrl('cal' + oDataQueryPart).replace('/api/', '/odata/'));
  }
}
