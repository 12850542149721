import { PurgeTaskResult, PurgeResult } from '../../models/purgeresult';

export let getPurgeResultResponse: PurgeResult = {
  LwcId: 123456,
  RemovedFromIndexDB: PurgeTaskResult.Success,
  RemovedFromPredictedSearchDB: PurgeTaskResult.Success,
  RemovedFromSuggestionSearchDB: PurgeTaskResult.Success,
  RemovedFromNoSQLDBs: PurgeTaskResult.NotRequired,
  RemovedFromImageRepository: PurgeTaskResult.Failure,
  RemovedFromElasticSearch: PurgeTaskResult.Success,
  RemovedFromIndexSourceDB: PurgeTaskResult.Success,
  Message: 'tets error message',
  Success: true
};
