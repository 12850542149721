import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { finalize, map, Observable, startWith } from 'rxjs';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { clientNames, Item } from '../../models/upcomingexpiry';
import { UpcomingExpiryService } from '../../services/upcomingexpiry.service';
import { PageInfo } from '../../shared/models/pageinfo';

@Component({
  selector: 'app-upcomingexpiry',
  templateUrl: './upcomingexpiry.component.html',
  styleUrls: ['./upcomingexpiry.component.scss']
})
export class UpcomingexpiryComponent implements OnInit {

  selectedClientValue: any;
  clientNames: clientNames[];
  formControl = new FormControl();
  filteredClientName: Observable<clientNames[]>;
  orderBy: string;
  orderDirection: string;
  totalCount: number;
  pageIndex: number;
  take: number;
  skip: number;
  items: Item[]
  isActive: boolean;
  lte: number;
  constructor(private loadingService: LoadingService, private upcomingExpiry: UpcomingExpiryService,
    private dateTimeService: DateTimeService) {
    this.selectedClientValue = '';
    this.orderBy = 'expiryDate';
    this.orderDirection = 'asc';
    this.clientNames = [];
    this.totalCount = 0;
    this.pageIndex = 0;
    this.take = 5000;
    this.skip = 0;
    this.lte = 0;
    this.getClientsByName();
  }

  ngOnInit(): void {
    this.getUpcomingExpiryDetails();
    this.filteredClientName = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }
  getClientsByName() {
    this.loadingService.setLoading();
    this.upcomingExpiry.getClientName(this.skip, this.take).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.clientNames = response.data.items;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  getUpcomingExpiryDetails() {
    if (this.lte == 0) {
      this.lte = 60;
    }
    this.loadingService.setLoading();
    this.upcomingExpiry.getUpcomingExpiryDetails(this.selectedClientValue.trim(), this.isActive, this.lte, this.skip, this.take, this.orderBy, this.orderDirection).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.items = response.data.items;
        for (let i of this.items) {
          i.expiryDate = this.dateTimeService.format(i.expiryDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes)
        }
      }
    });
  }
  checkCheckBoxvalue(event: MatCheckboxChange) {
    if (event.checked === true) {
      this.isActive = true;
      this.getUpcomingExpiryDetails();
    }
    else {
      this.isActive = false;
      this.getUpcomingExpiryDetails();
    }
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    this.pageIndex = pageInfo.offset;
    this.skip = pageInfo.offset * this.take;
    this.getClientsByName();
  }

  changeClientName(value: string) {
    if (value) {
      this.selectedClientValue = value;
    }
  }

  onSort(event: any) {
    this.orderBy = event.sorts[0].prop;
    this.orderDirection = event.sorts[0].dir;
    this.getClientsByName();
  }

  private _filter(value: string): clientNames[] {
    const filterValue = value.toLowerCase();
    return this.clientNames.filter(option => option.clientName.toLowerCase().includes(filterValue));
  }
}
