import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from "../core/api/apiclient";
import { ApiService } from '../core/api/api.service';
import { apiPathConstants } from '../core/api/apipathconstants';
import { Theme, Theme1, dark, defaultTheme } from '../components/toolbar/theme.models';


@Injectable({
  providedIn: 'root'
})

export class ThemeManagerService {   
  private active: Theme1;

  getUser(_email:string): Observable<Theme> {
     return this.apiClient.get<Theme>(this.apiService.buildUrl(apiPathConstants.theme,_email))
     .pipe(result=>result);
  }

  updateUserTheme(theme:object){
    return this.apiClient.put<any>(this.apiService.buildUrl(apiPathConstants.theme),theme)
    .pipe(result=>result)
  }
 
  // Theme Logic
  toggleDarkTheme(){
    this.setDarkTheme()
  }
  toggleDefaultTheme(){
    this.setDefaultTheme()
  }
  
  setDefaultTheme() {
    this.setActiveTheme(defaultTheme);
  }

  setDarkTheme(): void {
    this.setActiveTheme(dark);
  }

  
  setActiveTheme(theme: Theme1): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }

  constructor(private apiService: ApiService,private apiClient: ApiClient) { }
  
}