import { ApiClientConfig } from '../api/apiclientconfig';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationService } from '../uiservices/notification.service';
import { Observable } from 'rxjs';
import { HttpStatusCode } from '../../enums/enums';
import { DialogService } from '../uiservices/dialog.service';
import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class ErrorHandler {
  private unauthorizedHandled = false;
  constructor(private notificationService: NotificationService, private dialogService: DialogService,
   private authService: AuthService) { }

  getApiErrorHandler = <T>(apiClientConfig?: ApiClientConfig) => {
    let errorMessage = apiClientConfig ? apiClientConfig.errorMessage : '';

    return (error: any): Observable<T> => {
      this.notificationService.notifyError(error, errorMessage);
      return throwError(error);
    };
  }

  handleUnauthorized = (err: any, caught: Observable<HttpEvent<any>>) => {
    let user = this.authService.getCurrentUser();
    if (err instanceof HttpErrorResponse && err.status == HttpStatusCode.Unauthorized && !this.unauthorizedHandled) {
      this.dialogService.openCustomDialog(DialogComponent, {
        width: '400px',
        data: {
          message: `User ${user.userName} is not Registered or activated in Index
          Manager App. Press Ok to login as a different user`
        }
      } ).afterClosed().subscribe(() => {
        this.authService.logOut();
      });
      this.unauthorizedHandled = true;
    }
    throw err;
  }
}
