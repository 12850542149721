import { PurgeResult, PurgeTaskResult } from "../../models/purgeresult";

export const deleteAssociatedWithResponse: PurgeResult = {
  LwcId: 123456,
  RemovedFromIndexDB: PurgeTaskResult.Success,
  RemovedFromPredictedSearchDB: PurgeTaskResult.Success,
  RemovedFromSuggestionSearchDB: PurgeTaskResult.Success,
  RemovedFromNoSQLDBs: PurgeTaskResult.NotRequired,
  RemovedFromImageRepository: PurgeTaskResult.Failure,
  RemovedFromElasticSearch: PurgeTaskResult.Success,
  RemovedFromIndexSourceDB: PurgeTaskResult.Success,
  Message: 'Associated With message',
  Success: true
}
