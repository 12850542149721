import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { cacheTypesResponse, redisCacheLookupResponse } from '../mockjsonresponses/rediscachelookupresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function redisCacheLookupBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes("redis/lookup") && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...redisCacheLookupResponse}
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.redisDelete) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.deleteSharedCals) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    })
  } 

  let includeCacheType = false;
  if(request.body && request.body.query) {
    includeCacheType = request.body.query.includes('cacheTypes');
  }
  
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includeCacheType) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...cacheTypesResponse}
      }));
      resp.complete();
    });
  }
}
