import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'app-jsonviewerbutton',
    templateUrl: './jsonviewerbutton.component.html'
})

export class JsonViewerButtonComponent implements OnInit {
    @Input()
    text: string = '';
    @Input()
    hidden = false;

    @Output()
    click = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onClick() {
        this.click.emit();
    }
}