import { propertyOf } from "../../core/services/reflection.service";

export interface CALsSearcherCSVSchema
{
    CAL: string;
    TID: string;
    MID: string;
    TypeOfMatch: string;
    Result_Code?: number;
    NumberOfResults?: number;
    TopScore?: number;
    LastVerified?: string;
    MerchantRichnessScore?: number;
    LWC_ID?: number;
    LWC_Guid?: string;
    TradingName: string;
    Category: string;
    Address: string;
    Suburb: string;
    URL: string;
    PhoneNumber: string;
    HasLogo?: boolean;
    ABN: string;
    ChainName: string;
    LocatedIn: string;
    OpeningTimes: string;
    Email: string;
    IsClosed?: boolean;
    merchantDetailsExist?: boolean;
    resultCode?: number;
    tagLabels?: string;
}

export const calsSearcherCsvSchemaProps = {
    CAL: propertyOf<CALsSearcherCSVSchema>('CAL'),
    TID: propertyOf<CALsSearcherCSVSchema>('TID'),
    MID: propertyOf<CALsSearcherCSVSchema>('MID'),
    TypeOfMatch: propertyOf<CALsSearcherCSVSchema>('TypeOfMatch'),
    Result_Code: propertyOf<CALsSearcherCSVSchema>('Result_Code'),
    NumberOfResults: propertyOf<CALsSearcherCSVSchema>('NumberOfResults'),
    TopScore: propertyOf<CALsSearcherCSVSchema>('TopScore'),
    LastVerified: propertyOf<CALsSearcherCSVSchema>('LastVerified'),
    MerchantRichnessScore: propertyOf<CALsSearcherCSVSchema>('MerchantRichnessScore'),
    LWC_ID: propertyOf<CALsSearcherCSVSchema>('LWC_ID'),
    LWC_Guid: propertyOf<CALsSearcherCSVSchema>('LWC_Guid'),
    TradingName: propertyOf<CALsSearcherCSVSchema>('TradingName'),
    Category: propertyOf<CALsSearcherCSVSchema>('Category'),
    Address: propertyOf<CALsSearcherCSVSchema>('Address'),
    Suburb: propertyOf<CALsSearcherCSVSchema>('Suburb'),
    URL: propertyOf<CALsSearcherCSVSchema>('URL'),
    PhoneNumber: propertyOf<CALsSearcherCSVSchema>('PhoneNumber'),
    HasLogo: propertyOf<CALsSearcherCSVSchema>('HasLogo'),
    ABN: propertyOf<CALsSearcherCSVSchema>('ABN'),
    ChainName: propertyOf<CALsSearcherCSVSchema>('ChainName'),
    LocatedIn: propertyOf<CALsSearcherCSVSchema>('LocatedIn'),
    OpeningTimes: propertyOf<CALsSearcherCSVSchema>('OpeningTimes'),
    Email: propertyOf<CALsSearcherCSVSchema>('Email'),
    IsClosed: propertyOf<CALsSearcherCSVSchema>('IsClosed'),
    tagLabels: propertyOf<CALsSearcherCSVSchema>('tagLabels')
  };
