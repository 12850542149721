import { ValueCheckerService } from './../core/services/valuechecker.service';
import { Observable } from 'rxjs';
import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiService } from "../core/api/api.service";
import { Injectable } from "@angular/core";
import { ApiClient } from "../core/api/apiclient";
import { ApiClientConfig } from "../core/api/apiclientconfig";
import { AUS_Indexing_Result } from "../models/ausindexingresult";
import { MerchantLogoResponse } from '../components/merchantlogo/merchantlogo.response';
import { DialogService } from '../core/uiservices/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantLogoService {
  constructor(private apiService: ApiService, private apiClient: ApiClient, private dialogService: DialogService,
    private valueCheckerService: ValueCheckerService) { }

  uploadLogos(lwcId: number, squareLogo: File, circularLogo: File, threeToOneLogo: File, darkSquareLogo: File, darkCircularLogo: File, darkThreeToOneLogo: File) {
    let formData = new FormData();
    let config: ApiClientConfig = {
      skipContentType: true
    }
    if (squareLogo) {
      formData.append('SquareLogo', squareLogo, squareLogo.name);
    }
    if (circularLogo) {
      formData.append('CircularLogo', circularLogo, circularLogo.name);
    }
    if (threeToOneLogo) {
      formData.append('ThreeToOneLogo', threeToOneLogo, threeToOneLogo.name);
    }
    if (darkSquareLogo) {
      formData.append('DarkSquareLogo', darkSquareLogo, darkSquareLogo.name);
    }
    if (darkCircularLogo) {
      formData.append('DarkCircularLogo', darkCircularLogo, darkCircularLogo.name);
    }
    if (darkThreeToOneLogo) {
      formData.append('DarkThreeToOneLogo', darkThreeToOneLogo, darkThreeToOneLogo.name);
    }
    formData.append('LWC_ID', lwcId.toString());
    return this.apiClient.post<AUS_Indexing_Result>(this.apiService
      .buildUrl(apiPathConstants.updateMerchantLogos), formData, config)
   
  }

  loadLogo(lwcId: number, ignoreCache: boolean): Observable<MerchantLogoResponse> {
    return this.apiClient.get<MerchantLogoResponse>(this.apiService.buildApiUrlWithParameters(
      apiPathConstants.logo + '/' + lwcId.toString(),
      { name: 'forceSearch', value: ignoreCache.toString() }
    ));
  }

  setLogo(lwcId: number, imageName: string) {
    return this.apiClient.put<void>(this.apiService.buildUrl(apiPathConstants.logo, lwcId.toString(), imageName));
  }

  uploadLogo(lwcId: number, base64Image: string) {
    return this.apiClient.post<void>(this.apiService.buildUrl(apiPathConstants.logo, lwcId.toString()), { imgBase64: base64Image });
  }

  setLogoFromManualUpload(data: FormData, config: ApiClientConfig) {

      return this.apiClient.post<void>(this.apiService.buildUrl(apiPathConstants.setLogoFromManualUpload), data, config);
  
    
  }

  logoTypeValid(file: File, logoTypes?: string[]) {
    let fileType = file.type;
    let validLogoTypes = this.valueCheckerService.isNullOrUndefined(logoTypes) ? ['image/gif', 'image/jpeg', 'image/png'] : logoTypes;

    if (!validLogoTypes.includes(fileType)) {
      this.dialogService.openDialog(`Selected file is not a supported image. Supported formats: ${validLogoTypes.join(', ').replace(/image\//g, '')}.`);
      return false;
    }
    return true;
  }
}
