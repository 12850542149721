import { Injectable } from '@angular/core';

@Injectable()
export class ValueCheckerService {
	isNullOrUndefined(value: unknown) {
		return value === null || value === undefined;
	}

	isNumber(value: unknown) {
		if (this.isEmptyNullOrUndefined(value)) {
			return false;
		}

		let valueToNumber = Number(value);

		return !isNaN(valueToNumber);
	}
	isEmptyNullOrUndefined(value: unknown) {
		return value === "" || this.isNullOrUndefined(value);
	}
}

