let chains = 'chains';
let merchantlookup = 'merchantlookup';
let merchantIndex = 'merchantindex';
let redis = 'redis';
let merchant = 'merchant';
let searchResults = 'searchresults';
let pastSearches = 'pastsearches';
let eventing = 'eventing';
let calhints = 'calhints';
let logo = 'logo';
let tag = 'tag';
let bsb = 'bsb';
let merchantSummary = 'merchantsummary';
let merchantSummaryByBsb = 'merchantsummarybybsb';
let slash = '/';
let merchantName = 'merchantname';
let suggest = 'suggest';
let search = 'search';
let purge = 'purge';
let darkLogo = 'darklogo';
let categoryMappingRules = 'categorymappingrules';
let calSplitter = 'calsplitter';
let tagAllocationPattern = 'tagallocationpattern';
let graphQl = 'graphql';
let abnaCnLookup = 'abnacnlookup';
let categoryStandardiser = 'categorystandardiser';
let nzl = 'nzl';

export let apiPathConstants = {
  chains: chains,
  merchantlookup: merchantlookup,
  merchantIndex: merchantIndex,
  merchantEvent: 'merchantevent',
  testChainRules: chains + '/testchainrules',
  reviewChainRules: chains + '/reviewchainrules',
  merchantdetailsbylwcid: merchantlookup + '/merchantdetailsbylwcid',
  merchantIndexOtherNames: merchantIndex + '/othernames',
  merchantIndexAssociatedWith: merchantIndex + '/associatedwith',
  merchantIndexContactMethods: merchantIndex + '/contactmethods',
  merchantIndexMerchantValidations: merchantIndex + '/MerchantValidations',
  quarantinedMerchants: merchantlookup + '/qurantinedmerchants',
  quarantinedCals: merchantlookup + '/qurantinedcals',
  graphql: slash + graphQl,
  internalApiVersion: 'version/internalapi',
  redisDelete: redis + '/delete',
  chainPatternMatcher: chains + '/caltochainmatch',
  quarantinedcals: merchant + '/allocatecals',
  similarcals: merchant + '/similarcals',
  moneytrackerEnrich: 'moneytracker/enrich',
  pastSearchesByBatch: pastSearches + '/bybatch',
  eventingChains: eventing + '/chains',
  categoryDataSources: 'category/datasources',
  mercantIndexV4: merchantIndex + '/v4/merchant',
  merchantDataBaseDetails: merchantIndex + '/merchantdatabasedetails',
  missedCals: searchResults + '/v4/missedcals',
  pastSearches: pastSearches + '/v4',
  pastSearchesByCal: pastSearches + '/bycal',
  eventing: eventing + slash + graphQl,
  screapeStatus: eventing + '/scrapestatus',
  startNewScrape: eventing + '/startnew',
  startScrape: eventing + '/begin',
  calhints: calhints,
  eventingEnrichMerchant: eventing + '/enrich',
  dataSources: eventing + '/datasources',
  sitesData: eventing + '/sites/data/',
  getCalDetails: calhints + '/getCalDetails',
  calSplitter: calSplitter,
  openingHours: merchant + '/openinghours',
  indexingAudit: eventing + '/audit/indexing',
  matchWithIndexedMerchants: eventing + '/match',
  eventingCreateNew: eventing + '/createnew',
  eventingVerify: eventing + '/verify',
  logo: logo,
  missingLogoList: logo + '/missinglogolist',
  tag: tag,
  bsb: bsb,
  merchantLookupMerchantSummary: merchantlookup + slash + merchantSummary,
  merchantLookupMerchantSummaryByBsb: merchantlookup + slash + merchantSummaryByBsb,
  merchantName: pastSearches + slash + merchantName,
  suggest: pastSearches + slash + merchantName + slash + suggest,
  search: pastSearches + slash + merchantName + slash + search,
  darkLogo: purge + slash + darkLogo,
  allCategoryDataSources: categoryMappingRules + slash + 'getallcategorydatasources',
  allExternalCategories: categoryMappingRules + slash + 'getallexternalcategories',
  allCategoryMappingRules: categoryMappingRules + slash + 'getallcategorymappingrules',
  deleteRule: categoryMappingRules + slash + 'deleterule',
  upsertrule: categoryMappingRules + slash + 'upsertrule',
  getcategorybymappingrule: categoryMappingRules + slash + 'getcategorybymappingrule',
  splitCal: calSplitter + slash + 'splitcal',
  getByNewTag: tagAllocationPattern + slash + 'getbynewtag',
  batchsplitcal: calSplitter + slash + 'batchsplitcal',
  tagAllocationPattern: tagAllocationPattern,
  testNewPattern: tagAllocationPattern + slash + 'testnewpattern',
  setLogoFromManualUpload: logo + slash + 'setlogofrommanualupload',
  updateMerchantLogos: merchant + slash + 'updatelogos',
  merchantDetails: 'merchantchain' + slash + 'merchantdetails',
  merchantDetailsByLwcIds: merchant + slash + 'merchantdetailsbylwcids',
  merchantDetailsByBsbs: merchant + slash + 'merchantdetailsbybsbs',
  hotChocolate: 'hotchocolate' + slash + graphQl,
  indexingComment: 'indexingcomment',
  merchantDetailsByShoppingCentre: 'merchant/merchantdetailsbyshoppingcentre',
  merchantDetailsByChainNameAndShoppingCentres: merchantlookup + slash + 'merchantdetailsbychainnameandshoppingcentre',
  getMerchantsByAbn: abnaCnLookup + slash + 'getmerchantsbyabn/',
  getMerchantsByAcn: abnaCnLookup + slash + 'getmerchantsbyacn/',
  blobCache: 'blobcache' + slash + 'refresh',
  getActivedDrafts: categoryStandardiser + slash + 'getactivedrafts',
  updateCategoryStandardiser: categoryStandardiser + slash + 'update',
  rejectCategoryStandardiser: categoryStandardiser + slash + 'reject',
  externalCategoriesDataSource: "externalcategory" + slash + "externalcategoriesdatasource",
  getCategoryStandardiserTree: "categorystandardiser" + slash + "getCategorystandardisertree",
  getExternalCategoriesTree: 'externalcategory' + slash + 'getexternalcategoriestree',
  updateExternalCategories: 'externalcategory' + slash + 'updateexternalcategories',
  deleteExternalCategories: 'externalcategory' + slash + 'deleteexternalcategories',
  createCategoryStandardiserUpdateToDraft: "categorystandardiser" + slash + "updatetodraft",
  deleteCategoryStandardiserDeleteToDraft: "categorystandardiser" + slash + "deletetodraft",
  deleteSharedCals: redis + slash + 'DeleteSharedCals',
  getManageClientTag: 'Tag',
  getManageClientExternalCategory: 'externalcategoriesdatasource' + slash + 'getall',
  getCategoryDraft: 'categorydraft',
  upsertCategoryDraft: 'categorydraft' + slash + 'upsert',
  approveCategoryDraft: 'categorydraft' + slash + 'approve',
  rejectCategoryDraft: 'categorydraft' + slash + 'reject',
  approveStandardizer: categoryStandardiser + slash + 'update',
  rejectStandardizer: categoryStandardiser + slash + 'reject',
  refresh: 'blobcache' + slash + 'refresh' + slash + 11,
  externalCategoryDataSource: 'externalcategoriesdatasource' + slash + 'getall',
  getAllExternalCategories: 'categorymappingrules' + slash + 'getallexternalcategories' + slash,
  addExternalMapping: 'categorystandardiser' + slash + 'updatetodraft',
  deleteExternalCategoryMapping: 'categorystandardiser' + slash + 'deletetodraft',
  getSearchCount: 'pastsearches' + slash + 'batch',
  elasticSearch: 'elasticsearch' + slash + 'updatecal',
  theme: 'authentication' + slash + 'user',
}