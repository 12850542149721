import { BaseStateComponent } from './../basestate/basestate.component';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { PurgeResult, PurgeTaskResult } from '../../models/purgeresult';
import { NotificationService } from '../../core/uiservices/notification.service';
import { StringService } from '../../core/formatting/string.service';
import { ConfirmDialogComponent } from '../../shared/confirmdialog/confirmdialog.component';
import { PurgeMerchantService } from '../../services/purgemerchant.service';
import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { PurgeMerchantState } from './purgemerchantstate';
import { LoginStateService } from '../../core/auth/loginstate.service';
import { RoleType } from '../../users/userenums';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { DisplayMerchantDetails } from './displaymerchantdetails';
import {
  MerchantDetails,
  MerchantName,
  ContactMethod,
  WithIsActive,
  WithIsPrimaryAndIsActive
} from '../merchantextract/merchantdetails';
import { DeleteMerchantNamesRequest } from '../../models/deletemerchantnamesrequest';
import { DeleteAssociatedWithRequest } from '../../models/deleteassociatedwithrequest';
import { DeleteContactMethodRequest } from '../../models/deletecontactmethodrequest';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { IndexMerchantService } from '../../services/indexmerchant.service';

@Component({
  selector: 'app-purgemerchant',
  templateUrl: './purgemerchant.component.html',
  styleUrls: ['./purgemerchant.component.scss']
})
export class PurgeMerchantComponent extends BaseStateComponent<PurgeMerchantState> implements OnInit {
  isInRoleAdministratorOrIndexerWithPurge: boolean;
  purgeResult: PurgeResult;
  purgeTaskResult: PurgeTaskResult;
  isError = false;
  merchantDetails: MerchantDetails;
  displayMerchantDetails: DisplayMerchantDetails;
  merchantNamesPurgeResult: PurgeResult = null;
  associatedWithPurgeResult: PurgeResult = null;
  contactMethodPurgeResult: PurgeResult = null;
  lockButtonLabel: string = 'Unlock';

  private deletedMerchantNameIDs: number[] = [];
  private deletedAUS_Indexed_AssociatedWithIDs: number[] = [];
  private deletedAUS_Indexed_ContactMethodIDs: number[] = [];

  constructor(private dialog: MatDialog, private valueCheckerService: ValueCheckerService,
    private purgemerchantService: PurgeMerchantService, private stringService: StringService,
    private notificationService: NotificationService, private loadingService: LoadingService,
    private loginStateService: LoginStateService, private merchantLookupService: MerchantlookupService,
    private merchantlookupService: MerchantlookupService, private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService,
    private indexMerchantService: IndexMerchantService,
    injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      lwcIDForPurgeIndex: <number>null,
      lwcIDForPurgeCal: <number>null,
      cal: '',
      purgeResult: null,
      purgeTaskResult: null,
      lwcIdForMerchantDetails: ''
    };

    this.isInRoleAdministratorOrIndexerWithPurge =
      this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin, RoleType.IndexManagerIndexerWithPurge]);
    this.restoreState();
  }

  purgeIndex() {
    this.isError = false;

    const confirmationMessage = this.createConfirmationMessage('the merchant with an', this.state.lwcIDForPurgeIndex.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeIndex(this.state.lwcIDForPurgeIndex)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = p;
            this.notificationService.notifySuccess('Purge Index success');
          }, this.handleError);
      }
    });
  }

  purgeSensisData() {
    this.isError = false;

    const confirmationMessage = this.createConfirmationMessage('sensis data from', this.state.lwcIDForPurgeIndex.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeSensis(this.state.lwcIDForPurgeIndex)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = p;
            this.notificationService.notifySuccess('Purge Sensis success');
          }, this.handleError);
      }
    });
  }

  purgeGooglePlacesData() {
    this.isError = false;

    const confirmationMessage = this.createConfirmationMessage('Google Places data from', this.state.lwcIDForPurgeIndex.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeGooglePlacesData(this.state.lwcIDForPurgeIndex)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = p;
            this.notificationService.notifySuccess('Purge Google Places');
          }, this.handleError);
      }
    });
  }

  purgeClearBitData() {
    this.isError = false;

    const confirmationMessage = this.createConfirmationMessage('Clear bit data from', this.state.lwcIDForPurgeIndex.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeClearbitData(this.state.lwcIDForPurgeIndex)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = p;
            this.notificationService.notifySuccess('Purge Clearbit success');
          }, this.handleError);
      }
    });
  }

  purgeLogoData() {
    this.isError = false;

    const confirmationMessage = this.createConfirmationMessage('Logo data from', this.state.lwcIDForPurgeIndex.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeLogoData(this.state.lwcIDForPurgeIndex)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = p;
            this.notificationService.notifySuccess('Purge Logo Data success');
          }, this.handleError);
      }
    });
  }

  purgeDarkModeLogos() {
    this.isError = false;

    const confirmationMessage = this.createConfirmationMessage('Dark Mode Logos from', this.state.lwcIDForPurgeIndex.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeDarkModeLogos(this.state.lwcIDForPurgeIndex)
          .pipe(finalize(this.stopLoading))
          .subscribe(response => {
            this.state.purgeResult = response;
            this.notificationService.notifySuccess('Purge Dark Mode Logos success');
          }, this.handleError);
      }
    });
  }

  purgeUrl() {
    this.isError = false;

    const confirmationMessage = this.createConfirmationMessage('Url from', this.state.lwcIDForPurgeIndex.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();
        this.purgemerchantService.purgeUrl(this.state.lwcIDForPurgeIndex)
          .pipe(finalize(this.stopLoading))
          .subscribe(response => {
            this.state.purgeResult = response;
            this.notificationService.notifySuccess('Purge Url success');
          }, this.handleError);
      }
    })
  }

  purgeCal() {
    this.isError = false;

    const confirmationMessage =
      this.createConfirmationMessage(`the CAL ${this.state.cal} from`, this.state.lwcIDForPurgeCal.toString());
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeCal(this.state.lwcIDForPurgeCal, this.state.cal)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = p;
            if (this.state.purgeResult.Success === true) {
              this.notificationService.notifySuccess();
            }
            else {
              this.notificationService.notifyError(`Purge Failed, ${this.state.purgeResult.Message}`)
            }
          }, this.handleError);
      }
    });
  }

  purgeInternationalCal() {
    this.isError = false;

    const confirmationMessage = `You are about to purge the International CAL ${this.state.cal}. Are you sure?`;
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeInternationalCal(this.state.cal)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = null;
            this.notificationService.notifySuccess();
          }, this.handleError);
      }
    });
  }

  purgeSharedCal() {
    this.isError = false;

    const confirmationMessage = `You are about to purge the Shared CAL ${this.state.cal}. Are you sure?`;
    const dialogRef = this.openDialog(confirmationMessage);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();

        this.purgemerchantService.purgeSharedCal(this.state.cal)
          .pipe(finalize(this.stopLoading))
          .subscribe(p => {
            this.state.purgeResult = null;
            this.notificationService.notifySuccess();
          }, this.handleError);
      }
    });
  }

  purgeEmail() {
    this.loadingService.setLoading();
    this.purgemerchantService.purgeEmail(this.state.lwcIDForPurgeIndex)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('Purge Email success');
      });
  }

  purgePhone() {
    this.loadingService.setLoading();
    this.purgemerchantService.purgePhone(this.state.lwcIDForPurgeIndex)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('Purge Phone success');
      });
  }

  isPurgeButtonDisabled(): boolean {
    return !this.state.lwcIDForPurgeIndex || this.state.lwcIDForPurgeIndex <= 0;
  }

  isPurgeCalButtonDisabled(): boolean {
    return !this.state.lwcIDForPurgeCal || this.state.cal.length < 5;
  }

  isPurgeInternationalOrSharedCalButtonDisabled(): boolean {
    return this.state.cal.length < 5;
  }

  isLoadMerchantDetailsButtonDisabled(): boolean {
    return this.state.lwcIdForMerchantDetails.length < 5;
  }

  loadMerchantDetails() {
    this.loadingService.setLoading();

    this.merchantNamesPurgeResult = null;
    this.associatedWithPurgeResult = null;
    this.contactMethodPurgeResult = null;

    this.merchantLookupService.searchIndexByLwcId(parseInt(this.state.lwcIdForMerchantDetails)).pipe(finalize(this.stopLoading)).subscribe(merchantDetails => {
      this.merchantDetails = merchantDetails;

      this.initMerchantDetailsForDisplay(merchantDetails);

      this.loadingService.clearLoading();
    });
  }

  saveMerchantNames() {
    this.loadingService.setLoading();

    let deleteMerchantNamesRequest: DeleteMerchantNamesRequest = {
      LwcId: parseInt(this.state.lwcIdForMerchantDetails),
      MerchantNamesIDs: this.deletedMerchantNameIDs
    }

    this.merchantLookupService.deleteMerchantNames(deleteMerchantNamesRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(purgeResult => {
        if (purgeResult.Success) {
          this.updateMerchantDetailsMerchantNames(this.merchantDetails);
        }

        this.merchantNamesPurgeResult = purgeResult;
      });
  }

  saveAssociatedWith() {
    this.loadingService.setLoading();

    let deleteAssociatedWithRequest: DeleteAssociatedWithRequest = {
      LwcId: parseInt(this.state.lwcIdForMerchantDetails),
      AUS_Indexed_AssociatedWithID: this.deletedAUS_Indexed_AssociatedWithIDs
    }

    this.merchantLookupService.deleteAssociatedWithRequest(deleteAssociatedWithRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(purgeResult => {
        if (purgeResult.Success) {
          this.updateMerchantDetailsAssociatedWith(this.merchantDetails);
        }

        this.associatedWithPurgeResult = purgeResult;
      });
  }

  saveContactMethod() {
    this.loadingService.setLoading();

    let deleteContactMethodRequest: DeleteContactMethodRequest = {
      LwcId: parseInt(this.state.lwcIdForMerchantDetails),
      AUS_Indexed_ContactMethodID: this.deletedAUS_Indexed_ContactMethodIDs
    }

    this.merchantLookupService.deleteMerchantContactMethod(deleteContactMethodRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(purgeResult => {
        if (purgeResult.Success) {
          this.updateMerchantDetailsContactMethods(this.merchantDetails);
        }

        this.contactMethodPurgeResult = purgeResult;
      });
  }

  toggleForDeleteMerchantName(merchantNameID: number) {
    if (this.isMerchantNameIDDeleted(merchantNameID)) {
      this.deletedMerchantNameIDs.splice(this.deletedMerchantNameIDs.indexOf(merchantNameID), 1);
    } else {
      this.deletedMerchantNameIDs.push(merchantNameID);
    }
  }

  toggleForDeleteAssociatedWith(associatedWithID: number) {
    if (this.isAssociatedWithIDDeleted(associatedWithID)) {
      this.deletedAUS_Indexed_AssociatedWithIDs.splice(this.deletedAUS_Indexed_AssociatedWithIDs.indexOf(associatedWithID), 1);
    } else {
      this.deletedAUS_Indexed_AssociatedWithIDs.push(associatedWithID);
    }
  }

  toggleForDeleteContactMethod(contactMethodID: number) {
    if (this.isContactMethodIDDeleted(contactMethodID)) {
      this.deletedAUS_Indexed_ContactMethodIDs.splice(this.deletedAUS_Indexed_ContactMethodIDs.indexOf(contactMethodID), 1);
    } else {
      this.deletedAUS_Indexed_ContactMethodIDs.push(contactMethodID);
    }
  }

  isMerchantNameIDDeleted(merchantNamesID: number) {
    return this.deletedMerchantNameIDs.includes(merchantNamesID);
  }

  isAssociatedWithIDDeleted(associatedWithID: number) {
    return this.deletedAUS_Indexed_AssociatedWithIDs.includes(associatedWithID);
  }

  isContactMethodIDDeleted(contactMethodID: number) {
    return this.deletedAUS_Indexed_ContactMethodIDs.includes(contactMethodID);
  }

  isNullOrUndefined(value: unknown) {
    return this.valueCheckerService.isNullOrUndefined(value);
  }

  loadMerchantSummary(event: Event) {
    this.getMerchantDetails((<any>event.target).value);
  }

  isLockButtonDisabled() {
    return !this.state.merchantLookupResponseFirstPart;
  }

  changeLockState() {

    this.loadingService.setLoading();
    this.indexMerchantService.changeLockState(
      this.state.merchantLookupResponseFirstPart.LWC_ID,
      this.state.merchantLookupResponseFirstPart.RecordLocked ? 0 : 1)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.getMerchantDetails(this.state.merchantLookupResponseFirstPart.LWC_ID);
      });
  }

  private getMerchantDetails(lwcId: number) {
    this.merchantlookupService.getCals(lwcId)
      .subscribe(response => {
        this.state.merchantLookupResponseFirstPart = null;
        this.state.merchantLookupResponseSecondPart = null;
        this.lockButtonLabel = response.RecordLocked ? 'Unlock' : 'Lock';
        this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
        this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
      });
  }

  private initMerchantDetailsForDisplay(merchantDetails: MerchantDetails) {
    this.clearTabs();

    this.displayMerchantDetails = {
      MerchantNames: this.filterByActiveAndNonPrimary<MerchantName>(merchantDetails.AUS_Indexed_MerchantNames),
      AssociatedWith: this.filterByActive(merchantDetails.AUS_Indexed_AssociatedWith),
      ContactMethods: this.filterByActiveAndNonPrimary<ContactMethod>(merchantDetails.AUS_Indexed_ContactMethods)
    }
  }

  private clearTabs() {
    this.deletedMerchantNameIDs = [];
    this.deletedAUS_Indexed_AssociatedWithIDs = [];
    this.deletedAUS_Indexed_ContactMethodIDs = [];
  }

  private updateMerchantDetailsMerchantNames(merchantDetails: MerchantDetails) {
    this.merchantDetails.AUS_Indexed_MerchantNames = this.merchantDetails.AUS_Indexed_MerchantNames
      .filter(merchantNames => !this.deletedMerchantNameIDs.includes(merchantNames.MerchantNamesID));

    this.displayMerchantDetails.MerchantNames = this.filterByActiveAndNonPrimary<MerchantName>(merchantDetails.AUS_Indexed_MerchantNames);

    this.deletedMerchantNameIDs = [];
  }

  private updateMerchantDetailsAssociatedWith(merchantDetails: MerchantDetails) {
    this.merchantDetails.AUS_Indexed_AssociatedWith = this.merchantDetails.AUS_Indexed_AssociatedWith
      .filter(associatedWith => !this.deletedAUS_Indexed_AssociatedWithIDs.includes(associatedWith.AUS_Indexed_AssociatedWithID));

    this.displayMerchantDetails.AssociatedWith = this.filterByActive(merchantDetails.AUS_Indexed_AssociatedWith);

    this.deletedAUS_Indexed_AssociatedWithIDs = [];
  }

  private updateMerchantDetailsContactMethods(merchantDetails: MerchantDetails) {
    this.merchantDetails.AUS_Indexed_ContactMethods = this.merchantDetails.AUS_Indexed_ContactMethods
      .filter(contactMethods => !this.deletedAUS_Indexed_ContactMethodIDs.includes(contactMethods.AUS_Indexed_ContactMethodID));

    this.displayMerchantDetails.ContactMethods = this.filterByActiveAndNonPrimary<ContactMethod>(merchantDetails.AUS_Indexed_ContactMethods);

    this.deletedAUS_Indexed_ContactMethodIDs = [];
  }

  private filterByActiveAndNonPrimary<T extends WithIsPrimaryAndIsActive>(withIsPrimaries: T[]) {
    return withIsPrimaries.filter(withIsPrimaryAndIsActive => !withIsPrimaryAndIsActive.IsPrimary &&
      withIsPrimaryAndIsActive.IsActive);
  }

  private filterByActive<T extends WithIsActive>(withIsActives: T[]) {
    return withIsActives.filter(withIsActive => withIsActive.IsActive);
  }

  private handleError = (error: any) => {
    this.isError = true;
    return throwError(error);
  }

  private openDialog(message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {
        message: message
      }
    });

    return dialogRef;
  }

  private createConfirmationMessage(message: string, lwcID: string): string {
    return this.stringService.format('You are about to purge {0} LWCID = {1}. Are you sure?', message, lwcID);
  }


  private stopLoading = () => this.loadingService.clearLoading();
}
