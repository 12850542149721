import { Injectable } from '@angular/core';
import { CollectionTransformerService } from './collectiontransformer.service';

@Injectable()
export class MapService {

  constructor(private collectionTransformerService: CollectionTransformerService){
  }

  getKyeByValue<K, V>(map: Map<K, V>, value: V) {
    let arr = this.collectionTransformerService.mapToArray(map);
    let result;

    for(let entry of arr) {
      if(entry.value === value) {
        result = entry.key;
        break;
      }

    }
      return result;
  }
}