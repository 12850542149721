import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { LoadingService } from '../../core/uiservices/loading.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { ElasticSearchService } from '../../services/elastic-search.service';
import { ElasticSearchRequest, ElasticSearchResponse } from './elastic-search';

@Component({
  selector: 'app-elastic-search',
  templateUrl: './elastic-search.component.html',
  styleUrls: ['./elastic-search.component.scss']
})
export class ElasticSearchComponent implements OnInit, OnDestroy {

  cals;
  results: ElasticSearchResponse[];

  constructor(private elasticSearchService: ElasticSearchService, private loadingService: LoadingService,
    private notificationService: NotificationService) {
    this.cals = '';
    this.results = [];
  }

  ngOnInit(): void {
  }

  search() {
    this.loadingService.setLoading();
    let objects = [];
    let cals = this.cals.toUpperCase().split('\n');
    if (cals.length > 5000) {
      cals = cals.slice(0, 5000)
    }
    for (let i of cals) {
      objects.push(Object.assign({ 'cal': i }));
    }

    let elasticSearchesRequest: ElasticSearchRequest = {
      'cals': objects
    }
    this.elasticSearchService.getCalsForElasticSearches(elasticSearchesRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        if (response) {
          let data: any = response;
          this.results = data['cals'];
          this.notificationService.notifySuccess();
        } else {
          this.results = [];
          this.notificationService.notifyError();
        }
      });
  }

  searchButtonDisabled() {
    return this.cals.length === 0;
  }

  ngOnDestroy(): void {
    this.cals = '';
    this.results = [];
  }
}
