import { propertyOf } from "../../core/services/reflection.service";

export interface ValidationStatsByValidation {
  validationId?: number;
  validation?: string;
  count?: number;
}

export interface ValidationStatsDetail {
  validationId: number;
  validation: string;
  comment: string;
  overriddenComment: string;
  isOverridden: boolean;
  nameOfIndexer: string;
  createdDate: string;
  updatedDate: string;
  warningError: string;
}

export interface ValidationStatsDetailDisplay extends ValidationStatsDetail {
  createdDateDisplay?: string;
  updatedDateDisplay?: string;
}

export interface ValidationStatsByIndexer {
  indexerName?: string;
  indexerId?: number;
  count?: number;
}

export interface ValidationStats extends ValidationStatsByValidation, ValidationStatsByIndexer {
  tooltipValidationStatsDetail?: string;
}

export const validationStatsDetailDisplayProps = {
  validationId: propertyOf<ValidationStatsDetailDisplay>('validationId'),
  validation: propertyOf<ValidationStatsDetailDisplay>('validation'),
  comment: propertyOf<ValidationStatsDetailDisplay>('comment'),
  overriddenComment: propertyOf<ValidationStatsDetailDisplay>('overriddenComment'),
  isOverridden: propertyOf<ValidationStatsDetailDisplay>('isOverridden'),
  nameOfIndexer: propertyOf<ValidationStatsDetailDisplay>('nameOfIndexer'),
  createdDateDisplay: propertyOf<ValidationStatsDetailDisplay>('createdDateDisplay'),
  updatedDateDisplay: propertyOf<ValidationStatsDetailDisplay>('updatedDateDisplay'),
  warningError: propertyOf<ValidationStatsDetailDisplay>('warningError'),
}

export const validationStatsProps = {
  validationId: propertyOf<ValidationStats>('validationId'),
  validation: propertyOf<ValidationStats>('validation'),
  count: propertyOf<ValidationStats>('count'),
  indexerName: propertyOf<ValidationStats>('indexerName'),
  indexerId: propertyOf<ValidationStats>('indexerId'),
}
