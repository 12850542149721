import { getPastSearchesByCalResponse } from '../mockjsonresponses/getpastsearchresponse';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiPathConstants } from '../../core/api/apipathconstants';

export function pastSearchBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if(cleanUrl.includes(apiPathConstants.pastSearchesByCal) && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...getPastSearchesByCalResponse]
      }));
      resp.complete();
    });
  }
}
