import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { manageNamesResponse, deletePersonName, addPersonName } from "../mockjsonresponses/managenamesresponse";
import { HttpMethod } from "../../core/coreenums/coreenums";

export function manageNamesService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];
    let includesPersonNames = false;
    let includesTransactionDescription = false;

    if (request.body && request.body.query) {
        includesPersonNames = request.body.query.includes('{\n  data: searchPersonName(searchString');
        includesTransactionDescription = request.body.query.includes('personNames(findInText');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesPersonNames || includesTransactionDescription) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: {...manageNamesResponse}
            }));

            resp.complete();
        })
    }
    let includesDeletePersonName = false;
    if (request.body && request.body.query) {
        includesDeletePersonName = request.body.query.includes('deletePersonName');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesDeletePersonName) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: {...deletePersonName}
            }));

            resp.complete();
        })
    }
    let includesAddPersonName = false;
    if (request.body && request.body.query) {
        includesAddPersonName = request.body.query.includes('addPersonName');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesAddPersonName) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: {...addPersonName}
            }));

            resp.complete();
        })
    }
}
