export let masterCardResponse: any = [
  {
    MastercardTransactionDescMerchantNamesID: 123456,
    ReformattedTransactionDesc: 'test ReformattedTransactionDesc',
    MerchantDBA: 'test MerchantDBA',
    MCC: 'testMCC',
    TransactionDesc: 'testTransactionDesc',
    MASTERCARD_ID: '',
    DateLastSeen: null
  },
  {
    MastercardTransactionDescMerchantNamesID: 123456,
    ReformattedTransactionDesc: 'test ReformattedTransactionDesc',
    MerchantDBA: 'test MerchantDBA',
    MCC: 'testMCC',
    TransactionDesc: 'testTransactionDesc',
    MASTERCARD_ID: 123456789,
    DateLastSeen: 'testDateLastSeen'
  }
];
