import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { MergeMerchantsState } from './mergemerchantsstate';
import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DialogService } from '../../core/uiservices/dialog.service';
import { MergeMerchantsService } from '../../services/mergemerchants.service';
import { MergeMerchantsRequest } from './mergemerchantsrequest';
import { NotificationService } from '../../core/uiservices/notification.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { JsonViewerCustomComponent } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewercustomcomponent';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { SegmentConfig } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/lwc-ngx-json-viewer.component';
import { MergeMerchantsResultStatus } from '../../enums/enums';
import { ActivatedRoute } from '@angular/router';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { IndexMerchantService } from '../../services/indexmerchant.service';
import { JsonViewerIconComponent } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewericon/jsonviewericon.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mergemerchants',
  templateUrl: './mergemerchants.component.html',
  styleUrls: ['./mergemerchants.component.scss']
})
export class MergeMerchantsComponent extends BaseStateComponent<MergeMerchantsState> implements OnInit {
  isPrimaryMerchantLoading = false;
  isDuplicateMerchantLoading = false;
  jsonViewerCustomComponents: JsonViewerCustomComponent[];
  ignoredKeys: string[];
  jsonViewerCssClasses: {};
  segmentConfigs: SegmentConfig[];
  @ViewChild('primaryMerchantId', {static: true}) primaryMerchantIdInput: NgModel;
  @ViewChild('duplicateMerchantId', {static: true}) duplicateMerchantIdInput: NgModel;
  primaryMerchantButtonLabel: string = 'Unlock';
  duplicateMerchantButtonLabel: string = 'Unlock';

  constructor(injector: Injector, private merchantlookupService: MerchantlookupService, private dialogService: DialogService,
    private mergeMerchantService: MergeMerchantsService, private notificationService: NotificationService,
    private loadingService: LoadingService, private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService,
    private activatedRoute: ActivatedRoute, private valueCheckerService: ValueCheckerService, private indexMerchantService: IndexMerchantService,
    private router: Router) {
    super(injector);
  }

  ngOnInit() {
    this.setupState();

    this.restoreState();
    this.setRouteParams();
    this.configureDebouncedValueChanges();

    this.ignoredKeys = this.merchantSummaryJsonViewerService.ignoredKeys;
    this.jsonViewerCustomComponents = this.merchantSummaryJsonViewerService.getjsonViewerCustomComponents();
    this.jsonViewerCustomComponents.push({
      key: 'LWC_ID',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, object: any) => {
        if (object && object.value) {
          c.icon = 'add';
          c.click.subscribe(() => {
            (this.router.navigate(['/enrichmerchant', { lwcId: object.value }]));
          });
        }
      }
    });
    this.segmentConfigs = this.merchantSummaryJsonViewerService.getMerchantSummarySegmentConfigs();
  }

  areMerchantIdsSame() {
    return this.state.primaryMerchantResponse && this.state.duplicateMerchantResponse
      && this.state.primaryMerchantResponse.LWC_ID === this.state.duplicateMerchantResponse.LWC_ID;
  }

  isResultMatCardDisplayed() {
    if ((this.state.primaryMerchantResponse && this.state.primaryMerchantResponse.LWC_ID != null) ||
      (this.state.duplicateMerchantResponse && this.state.duplicateMerchantResponse.LWC_ID != null)) {
      return true;
    }
    return false;
  }

  getOutcomeIcon(result: MergeMerchantsResultStatus) {
    switch (result) {
      case MergeMerchantsResultStatus.Success: {
        return 'done_all';
      }
      case MergeMerchantsResultStatus.Warning: {
        return 'warning';
      }
      case MergeMerchantsResultStatus.Error: {
        return 'error';
      }
    }
  }

  getOutcomeCssClass(result: MergeMerchantsResultStatus) {
    let resultStatus: string = "mat-icon material-icons ";
    switch (result) {
      case MergeMerchantsResultStatus.Success: {
        return resultStatus += 'success';
      }
      case MergeMerchantsResultStatus.Warning: {
        return resultStatus += 'warning';
      }
      case MergeMerchantsResultStatus.Error: {
        return resultStatus += 'danger';
      }
    }
  }

  isMergeButtonDisabled() {
    if (this.state.primaryMerchantResponse && this.state.primaryMerchantResponse.TransactionDescriptions.length > 0
      && this.state.duplicateMerchantResponse && this.state.duplicateMerchantResponse.TransactionDescriptions.length > 0
      && !this.areMerchantIdsSame()) {
      return false;
    }
    return true;
  }

  mergeCals() {
    let dialogRef = this.dialogService.openConfirmDialog(`Are you sure, you want to move all CALs from
    ${this.state.duplicateMerchantResponse.LWC_ID} to ${this.state.primaryMerchantResponse.LWC_ID}?`);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.setLoading();
        let mergeMerchantsRequest: MergeMerchantsRequest = {
          PrimaryMerchantLwcId: this.state.primaryMerchantResponse.LWC_ID,
          DuplicateMerchantLwcId: this.state.duplicateMerchantResponse.LWC_ID
        }

        this.mergeMerchantService.mergeMerchants(mergeMerchantsRequest)
          .pipe(finalize(() => this.loadingService.clearLoading())).subscribe((response) => {
            this.state.mergeMerchantsResult = response;
            this.state.primaryMerchantResponse = null;
            this.state.duplicateMerchantResponse = null;
            this.notificationService.notifySuccess();
          });
      }
    })
  }

  resetForm() {
    this.setupState();
    this.isPrimaryMerchantLoading = false;
    this.isDuplicateMerchantLoading = false;
  }

  isUnlockPrimaryMerchantButtonDisabled() {
    if (this.state.primaryMerchantResponse && this.state.primaryMerchantResponse.RecordLocked) {
      return false;
    }
    return true;
  }

  isUnlockDuplicateMerchantButtonDisabled() {
    if (this.state.duplicateMerchantResponse && this.state.duplicateMerchantResponse.RecordLocked) {
      return false;
    }
    return true;
  }

  changeLockStatePrimaryMerchant() {
    this.loadingService.setLoading();
    let lwcId = this.valueCheckerService.isEmptyNullOrUndefined(this.state.primaryMerchantId)
      ? this.state.primaryMerchantResponse.LWC_ID
      : this.state.primaryMerchantId;

    this.indexMerchantService.changeLockState(lwcId, 0)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.getPrimaryMerchant();
      });
  }

  changeLockStateDuplicateMerchant() {
    this.loadingService.setLoading();
    let lwcId = this.valueCheckerService.isEmptyNullOrUndefined(this.state.duplicateMerchantId)
      ? this.state.duplicateMerchantResponse.LWC_ID
      : this.state.duplicateMerchantId;

    this.indexMerchantService.changeLockState(lwcId, 0)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.getDuplicateMerchant();
      });
  }

  private setupState() {
    this.state = {
      primaryMerchantId: null,
      duplicateMerchantId: null,
      primaryMerchantResponse: null,
      duplicateMerchantResponse: null,
      mergeMerchantsResult: null,
      primaryNotFound: false,
      duplicateNotFound: false
    };
  }

  private configureDebouncedValueChanges() {
    this.primaryMerchantIdInput.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      ).subscribe((value) => {
        if (value) {
          this.getPrimaryMerchant();
        }
      });

    this.duplicateMerchantIdInput.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      ).subscribe((value) => {
        if (value) {
          this.getDuplicateMerchant();
        }
      });
  }

  private getPrimaryMerchant() {
    this.isPrimaryMerchantLoading = true;
    this.state.primaryMerchantResponse = null;
    this.merchantlookupService.getCals(this.state.primaryMerchantId).pipe(
      finalize(() => this.isPrimaryMerchantLoading = false)).subscribe((response) => {
        this.state.primaryMerchantResponse = response;
        this.primaryMerchantButtonLabel = response.RecordLocked ? 'Unlock' : 'Lock';
        this.jsonViewerCssClasses = this.merchantSummaryJsonViewerService.getQuarantinedOrLockedCssClasses(response);
        this.state.primaryNotFound = (response.LWC_ID == null);
    })
  }

  private getDuplicateMerchant() {
    this.isDuplicateMerchantLoading = true;
    this.state.duplicateMerchantResponse = null;
    this.merchantlookupService.getCals(this.state.duplicateMerchantId).pipe(
      finalize(() => this.isDuplicateMerchantLoading = false)).subscribe((response) => {
      this.state.duplicateMerchantResponse = response;
      this.duplicateMerchantButtonLabel = response.RecordLocked ? 'Unlock' : 'Lock';
      this.jsonViewerCssClasses = this.merchantSummaryJsonViewerService.getQuarantinedOrLockedCssClasses(response);
      this.state.duplicateNotFound = (response.LWC_ID == null);
    })
  }

  private setRouteParams() {
    let primaryLwcIdRouteParam: string = this.activatedRoute.snapshot.params['primaryMerchantId'];
    let duplicateLwcIdRouteParam: string = this.activatedRoute.snapshot.params['duplicateMerchantId'];

    if (primaryLwcIdRouteParam) {
      this.state.primaryMerchantId = parseInt(primaryLwcIdRouteParam);
    }
    if (duplicateLwcIdRouteParam) {
      this.state.duplicateMerchantId = parseInt(duplicateLwcIdRouteParam);
    }
  }
}
