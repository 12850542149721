import { MergeMerchantsResult, MergeMerchantsActivityResult } from "../../components/mergemerchants/mergemerchantsresult";
import { MergeMerchantsResultStatus } from "../../enums/enums";

let mergeMerchantsActivityResult1: MergeMerchantsActivityResult = {
  Result: MergeMerchantsResultStatus.Warning,
  Comments: 'Some random comment',
}

let mergeMerchantsActivityResult2: MergeMerchantsActivityResult = {
  Result: MergeMerchantsResultStatus.Error,
  Comments: 'Some random comment1'
}

let mergeMerchantsActivityResult3: MergeMerchantsActivityResult = {
  Result: MergeMerchantsResultStatus.Success,
  Comments: 'Some random comment2'
}

export let mergeMerchantResponse: MergeMerchantsResult = {
  ActivityResults: [mergeMerchantsActivityResult1, mergeMerchantsActivityResult2, mergeMerchantsActivityResult3],
  OverallResult: MergeMerchantsResultStatus.Error
}
