import { hotChocolate, merchantShoppingCenteQuery } from './../gqlconstants/gqlconstants';
import { apiPathConstants } from './../core/api/apipathconstants';
import { Injectable } from '@angular/core';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { MerchantDetails, MerchantDetailsByChainNameAndShoppingCentre, MerchantDetailsByShoppingCentre, merchantDetailsSchemaProps, MerchantShoppingCentre } from '../components/merchantextract/merchantdetails';
import { Apollo, gql } from 'apollo-angular';

@Injectable()
export class MerchantExtractService {
  merchantDetailsSchemaFields = [
    merchantDetailsSchemaProps.LWC_ID,
    merchantDetailsSchemaProps.ChainName,
    merchantDetailsSchemaProps.Phone,
    merchantDetailsSchemaProps.URL,
    merchantDetailsSchemaProps.DataCompletnessScore,
    merchantDetailsSchemaProps.UpdatedDate,
    merchantDetailsSchemaProps.PrimaryName,
    merchantDetailsSchemaProps.PrimaryCategory,
    merchantDetailsSchemaProps.PrimaryAddress,
    merchantDetailsSchemaProps.StreetViewAvailable,
    merchantDetailsSchemaProps.CALs,
    merchantDetailsSchemaProps.MerchantPresence,
    merchantDetailsSchemaProps.MerchantType,
    merchantDetailsSchemaProps.RecordLocked,
    merchantDetailsSchemaProps.IsQuarantined,
    merchantDetailsSchemaProps.ABN,
    merchantDetailsSchemaProps.CurrentBusinessStatus,
    merchantDetailsSchemaProps.Tags,
    merchantDetailsSchemaProps.BSBs,
    merchantDetailsSchemaProps.PrimaryEmail,
    merchantDetailsSchemaProps.LocatedIn
  ];

  constructor(private apiClient: ApiClient, private apiService: ApiService, private apollo: Apollo) { }

  getMerchantDetails(chainName: string) {
    return this.apiClient.get<MerchantDetails[]>(this.apiService.buildUrl(apiPathConstants.merchantDetails, chainName));
  }

  getMerchantDetailsByLwcIds(lwcIds: number[]) {
    return this.apiClient.post<MerchantDetails[]>(this.apiService.buildUrl(apiPathConstants.merchantDetailsByLwcIds), lwcIds);
  }

  getMerchantDetailsByBsbs(bsbs: string[]) {
    return this.apiClient.post<MerchantDetails[]>(this.apiService.buildUrl(apiPathConstants.merchantDetailsByBsbs), bsbs);
  }

  getMerchantShoppingCenters() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<MerchantShoppingCentre[]>({
        query: gql`${merchantShoppingCenteQuery}`
      })
      .valueChanges;
  }

  getMerchantDetailsByShoppingCentres(locatedIn_ID: string) {
    return this.apiClient.get<MerchantDetailsByShoppingCentre[]>(this.apiService.buildUrl(apiPathConstants.merchantDetailsByShoppingCentre + '/' + locatedIn_ID));
  }

  getMerchantDetailsByChainNameAndShoppingCentres(chainName: string, locatedInID: string) {
    return this.apiClient.get<MerchantDetailsByChainNameAndShoppingCentre[]>(this.apiService.buildUrl(
      apiPathConstants.merchantDetailsByChainNameAndShoppingCentres + '/' + chainName + '/' + locatedInID));
  }
}
