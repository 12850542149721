import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormControl } from '@angular/forms';
import { ManageLocationService } from '../../services/managelocations.service';
import { PageInfo } from '../../shared/models/pageinfo';
import { UserService } from '../../users/applicationuser.service';
import { takeUntil, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { BaseStateComponent } from '../basestate/basestate.component';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ManageWestfieldRetailerState } from './managewestfieldretailer.state';
import { emptyString } from '../../core/constants/constants';
import { SuburbAndStateCode } from '../suburbstatecode/suburbstatecode.models';
import { WestfieldRetailer, WestfieldRetailerDisplay } from './managewestfieldretailer.models';
import { propertyOf } from '../../core/services/reflection.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { WestfieldRetailerService } from '../../services/westfieldretailer.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { AuthService } from '../../services/auth.service';
import { LocatedInDto } from '../managelocations/managelocationsdto';
import { ApplicationUser } from '../../users/applicationuser';

export type WestfieldRetailesResponse = {
  items: WestfieldRetailer[];
  totalCount: number;
};

@Component({
  selector: 'app-managewestfieldretailer',
  templateUrl: './managewestfieldretailer.component.html',
  styleUrls: ['./managewestfieldretailer.component.scss']
})
export class ManageWestfieldRetailerComponent extends BaseStateComponent<ManageWestfieldRetailerState> implements OnInit {
  suburbAndStateCode: SuburbAndStateCode = {
    stateCode: emptyString,
    suburb: emptyString
  };
  @ViewChild('csvExport') csvExportComponent: CsvExportComponent;
  @ViewChild('datatableComponent') datatableComponent: DatatableComponent;
  datatableHeaders = {
    locatedInDisplay: "Located In",
    lwcId: "Lwc Id",
    chainId: "Chain Id",
    storesTitle: "Store Title",
    storesShopNumber: "Stores Shop Number",
    storesLevel: "Stores Level",
    storesTelephoneNumber: "Stores Telephone Number",
    storesShortDescription: "Stores Short Description",
    createDateDisplay: "Create Date",
    modifiedByDisplay: "Modified By",
    closedForNoOfDaysPotentially: "Closed for no of days potentially",
    comment: "Comment",
    sourceModifiedDateDisplay: "Modified Date"
  };
  datatableKeys = {
    locatedInDisplay: propertyOf<WestfieldRetailerDisplay>('locatedInDisplay'),
    lwcId: propertyOf<WestfieldRetailerDisplay>('lwcId'),
    chainId: propertyOf<WestfieldRetailerDisplay>('chainId'),
    storesTitle: propertyOf<WestfieldRetailerDisplay>('storesTitle'),
    storesShopNumber: propertyOf<WestfieldRetailerDisplay>('storesShopNumber'),
    storesLevel: propertyOf<WestfieldRetailerDisplay>('storesLevel'),
    storesTelephoneNumber: propertyOf<WestfieldRetailerDisplay>('storesTelephoneNumber'),
    storesShortDescription: propertyOf<WestfieldRetailerDisplay>('storesShortDescription'),
    createDateDisplay: propertyOf<WestfieldRetailerDisplay>('createDateDisplay'),
    modifiedByDisplay: propertyOf<WestfieldRetailerDisplay>('modifiedByDisplay'),
    closedForNoOfDaysPotentially: propertyOf<WestfieldRetailerDisplay>('closedForNoOfDaysPotentially'),
    comment: propertyOf<WestfieldRetailerDisplay>('comment'),
    sourceModifiedDateDisplay: propertyOf<WestfieldRetailerDisplay>('sourceModifiedDateDisplay'),
  };
  editingRowIndex: number = -1;
  commentColumnWidth: number = 150;
  retailerTitleControl = new FormControl();
  showChangesInLastDaysControl = new FormControl();
  showRecordsForMatchingAuditCriteriaControl = new FormControl();
  showRecordsNotLinkedLWCIdControl = new FormControl();
  retailerAuditColumnNames: string[] = [
    'Field',
    'Field Old Value',
    'Filed New Value',
    'Calculated No Of Days',
    'Create Date'
  ];

  private optionalDatatableHeadersAndKeys = {
    headers: [
      this.datatableHeaders.locatedInDisplay,
      this.datatableHeaders.lwcId,
      this.datatableHeaders.chainId,
      this.datatableHeaders.storesTitle,
      this.datatableHeaders.storesShopNumber,
      this.datatableHeaders.storesLevel,
      this.datatableHeaders.storesTelephoneNumber,
      this.datatableHeaders.storesShortDescription,
      this.datatableHeaders.createDateDisplay,
      this.datatableHeaders.modifiedByDisplay,
      this.datatableHeaders.closedForNoOfDaysPotentially,
      this.datatableHeaders.comment,
      this.datatableHeaders.sourceModifiedDateDisplay
    ],
    keys: [
      this.datatableKeys.locatedInDisplay,
      this.datatableKeys.lwcId,
      this.datatableKeys.chainId,
      this.datatableKeys.storesTitle,
      this.datatableKeys.storesShopNumber,
      this.datatableKeys.storesLevel,
      this.datatableKeys.storesTelephoneNumber,
      this.datatableKeys.storesShortDescription,
      this.datatableKeys.createDateDisplay,
      this.datatableKeys.modifiedByDisplay,
      this.datatableKeys.closedForNoOfDaysPotentially,
      this.datatableKeys.comment,
      this.datatableKeys.sourceModifiedDateDisplay
    ]
  };
  private csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionalDatatableHeadersAndKeys };
  private copyOfEditingRow: WestfieldRetailerDisplay;
  private currentUserId: number;
  private locationsForCsv: WestfieldRetailerDisplay[] = [];
  private manageLocations: LocatedInDto[] = [];
  private applicationUsers: ApplicationUser[] = [];
  private skipLocationsForCsv: number = 0;
  private takeLocationsForCsv: number = 5000;

  constructor(injector: Injector, private loadingService: LoadingService, private valueCheckerService: ValueCheckerService,
    private csvComponentService: CsvComponentService, private westfieldRetailerService: WestfieldRetailerService,
    private userService: UserService, private dateTimeService: DateTimeService, private manageLocationService: ManageLocationService,
    private authService: AuthService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      westfieldRetailers: [],
      filteredWestfieldRetailers: [],
      retailerTitle: emptyString,
      stateFilter: emptyString,
      suburbFilter: emptyString,
      storeDeactivatedFilter: null,
      notLinkedLWCId: false,
      take: 50,
      skip: 0,
      pageOffset: 0,
      totalCount: 0,
      showChangesInLastDays: 30,
      auditCreateDate: this.dateTimeService.getSubtractDate(30, this.dateTimeService.dateTimeFormatYYYYMMDDDashes),
      showRecordsForMatchingAuditCriteria: false
    };
    this.restoreState();
    this.suburbAndStateCode = {
      stateCode: this.state.stateFilter,
      suburb: this.state.suburbFilter
    };
    this.setWestfieldRetailers();
  }

  ngAfterViewInit() {
    this.retailerTitleControl.setValue(this.state.retailerTitle);
    this.showChangesInLastDaysControl.setValue(this.state.showChangesInLastDays);
    this.showRecordsForMatchingAuditCriteriaControl.setValue(this.state.showRecordsForMatchingAuditCriteria);
    this.showRecordsNotLinkedLWCIdControl.setValue(this.state.notLinkedLWCId);

    this.configureDebouncedValueChanges();
  }

  setSuburbAndStateCode(event: SuburbAndStateCode) {
    this.state.stateFilter = event.stateCode;
    this.state.suburbFilter = event.suburb;
    this.resetToFirstPage();
    this.setWestfieldRetailers();
  }

  downloadCsvFile() {
    if (this.locationsForCsv.length < this.state.totalCount) {
      if (this.state.totalCount >= 5000) {
        this.setCsvFile(this.skipLocationsForCsv, this.takeLocationsForCsv);
      }
      else {
        this.setCsvFile(0, this.state.totalCount);
      }
    };
    if (this.locationsForCsv.length === this.state.totalCount) {
      this.csvExportComponent.data = this.locationsForCsv;
      this.csvExportComponent.options = this.csvOptions;
      this.skipLocationsForCsv = 0;
      this.csvExportComponent.onDownload();
      this.locationsForCsv = [];
    }
  }

  applyButtonClicked() {
    this.resetToFirstPage();
    this.setWestfieldRetailers();
  }


  numberOfPageChanged(pageInfo: PageInfo) {
    this.state.skip = pageInfo.offset * this.state.take;
    this.state.pageOffset = pageInfo.offset;
    this.setWestfieldRetailers();
  }

  editRow(row: WestfieldRetailerDisplay, rowIndex: number) {
    this.commentColumnWidth = 264;
    this.editingRowIndex = rowIndex;
    this.copyOfEditingRow = { ...row };
  }

  cancel(rowIndex: number) {
    this.commentColumnWidth = 150;
    this.state.filteredWestfieldRetailers[rowIndex] = { ...this.copyOfEditingRow };
    this.state.filteredWestfieldRetailers = [...this.state.filteredWestfieldRetailers];
    this.editingRowIndex = -1;
  }

  save(row: WestfieldRetailerDisplay) {
    this.loadingService.setLoading();
    row.comment = row.comment ? row.comment.replace(/\n/g, " ") : emptyString;
    this.westfieldRetailerService.updateRetailerWithLwcId(row.retailersId, row.comment, row.lwcId, row.chainId, this.currentUserId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.editingRowIndex = -1;
        this.loadingService.clearLoading();
        this.setWestfieldRetailers();
      });
  }

  rowSelected(event: { type: string, row: WestfieldRetailerDisplay }) {
    if (event.type === "click" && event.row.retailerAudits && event.row.retailerAudits.length > 0) {
      this.datatableComponent.rowDetail.toggleExpandRow(event.row);
    }
  }

  getCellClass(data: { column: { prop: string }, row: WestfieldRetailerDisplay }) {
    return {
      'datatable-cell-yellow-background-color': data.row.retailerAudits && data.row.retailerAudits.length > 0
        && data.row.retailerAudits.find(t => t.fieldName.toLowerCase() === data.column.prop.toLowerCase()) !== undefined
    }
  }

  filterByStoreDeactivated() {
    this.resetToFirstPage();
    this.setWestfieldRetailers();
  }

  private setDisplayValues() {
    for (let westfieldRetailer of this.state.westfieldRetailers) {
      westfieldRetailer.createDateDisplay = this.dateTimeService.formatWithHours(westfieldRetailer.createDate);
      westfieldRetailer.sourceModifiedDateDisplay = this.dateTimeService.formatWithHours(westfieldRetailer.sourceModifiedDate);

      if (westfieldRetailer.retailerAudits && westfieldRetailer.retailerAudits.length > 0) {
        for (let retailerAudit of westfieldRetailer.retailerAudits) {
          retailerAudit.createDateDisplay = retailerAudit.createDate
            ? this.dateTimeService.formatWithHours(retailerAudit.createDate)
            : emptyString;
        }
      }
    }
  }

  private setLocatedInName() {
    this.loadingService.setLoading();
    this.manageLocationService.getManageLocations()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.manageLocations = response.data;
        this.loadingService.clearLoading();
        for (let location of response.data) {
          let westfieldRetailer = this.state.filteredWestfieldRetailers.find(t => t.locatedIn === location.locatedIn_ID);
          if (westfieldRetailer) {
            westfieldRetailer.locatedInDisplay = location.locatedIn_Name;
          }
        }
        this.setModifiedBy();
      });
  }

  private setModifiedBy() {
    this.userService.getApplicationUsersCached()
      .subscribe((applicationUsers) => {
        this.applicationUsers = applicationUsers;
        this.currentUserId = applicationUsers.find(t => t.EmailId === this.authService.getCurrentUser().userId).Id;
        for (let applicationUser of applicationUsers) {
          let westfieldRetailer = this.state.filteredWestfieldRetailers.find(t => t.modifiedBy === applicationUser.Id);
          if (westfieldRetailer) {
            westfieldRetailer.modifiedByDisplay = applicationUser.FirstName + " " + applicationUser.LastName;
          }
        }
      });
  }

  private configureDebouncedValueChanges() {
    this.retailerTitleControl.valueChanges.pipe(
      debounceTime(1200),
      distinctUntilChanged()
    )
      .subscribe(() => {
        this.resetToFirstPage();
        this.setWestfieldRetailers();
      });

    this.showChangesInLastDaysControl.valueChanges.pipe(
      debounceTime(1200),
      distinctUntilChanged()
    )
      .subscribe((days: number) => {
        this.resetToFirstPage();
        this.state.auditCreateDate = this.dateTimeService.getSubtractDate(days, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
        this.setWestfieldRetailers();
      });

    this.showRecordsForMatchingAuditCriteriaControl.valueChanges.pipe(
      debounceTime(1200),
      distinctUntilChanged()
    )
      .subscribe(() => {
        this.resetToFirstPage();
        this.setWestfieldRetailers();
      });

    this.showRecordsNotLinkedLWCIdControl.valueChanges.pipe(
      debounceTime(1200),
      distinctUntilChanged()
    )
      .subscribe(() => {
        this.resetToFirstPage();
        this.setWestfieldRetailers();
      });
  }

  private resetToFirstPage() {
    this.state.skip = 0;
    this.state.pageOffset = 0;
  }

  private setWestfieldRetailers() {
    this.loadingService.setLoading();
    this.westfieldRetailerService.getWestfieldRetailers(
      this.state.retailerTitle,
      this.state.suburbFilter,
      this.state.stateFilter,
      this.state.skip,
      this.state.take,
      this.state.auditCreateDate,
      !this.valueCheckerService.isEmptyNullOrUndefined(this.state.storeDeactivatedFilter)
        ? this.dateTimeService.getSubtractDate(this.state.storeDeactivatedFilter, this.dateTimeService.dateTimeFormatYYYYMMDDDashes)
        : null,
      this.state.showRecordsForMatchingAuditCriteria,
      this.state.notLinkedLWCId
     )
      .pipe(takeUntil(this.destroy$), finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items.length > 0) {
          this.state.westfieldRetailers = [... this.state.filteredWestfieldRetailers] = response.data.items;
          this.state.totalCount = response.data.totalCount;
          this.setDisplayValues();
          this.setLocatedInName();
        }
        else {
          this.state.westfieldRetailers = this.state.filteredWestfieldRetailers = [];
          this.state.totalCount = 0;
        }
      });
  }

  private setCsvFile(skip: number, take: number) {
    let westfieldRetailers: WestfieldRetailerDisplay[] = [];
    this.loadingService.setLoading();
    this.westfieldRetailerService.getWestfieldRetailers(
      this.state.retailerTitle,
      this.state.suburbFilter,
      this.state.stateFilter,
      skip,
      take,
      this.state.auditCreateDate,
      !this.valueCheckerService.isEmptyNullOrUndefined(this.state.storeDeactivatedFilter)
        ? this.dateTimeService.getSubtractDate(this.state.storeDeactivatedFilter, this.dateTimeService.dateTimeFormatYYYYMMDDDashes)
        : null,
      this.state.showRecordsForMatchingAuditCriteria,
      this.state.notLinkedLWCId
    )
      .pipe(takeUntil(this.destroy$), finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items.length > 0) {
          westfieldRetailers = response.data.items;
          for (let westfieldRetailer of westfieldRetailers) {
            westfieldRetailer.createDateDisplay = this.dateTimeService.formatWithHours(westfieldRetailer.createDate);
            westfieldRetailer.sourceModifiedDateDisplay = this.dateTimeService.formatWithHours(westfieldRetailer.sourceModifiedDate);
          }
          for (let location of this.manageLocations) {
            let westfieldRetailer = westfieldRetailers.find(t => t.locatedIn === location.locatedIn_ID);
            if (westfieldRetailer) {
              westfieldRetailer.locatedInDisplay = location.locatedIn_Name;
            }
          }
          for (let applicationUser of this.applicationUsers) {
            let westfieldRetailer = westfieldRetailers.find(t => t.modifiedBy === applicationUser.Id);
            if (westfieldRetailer) {
              westfieldRetailer.modifiedByDisplay = applicationUser.FirstName + " " + applicationUser.LastName;
            }
          }
          this.locationsForCsv = [...this.locationsForCsv, ...westfieldRetailers];
          this.skipLocationsForCsv += 5000;
          this.downloadCsvFile();
        }
      });
  }
}


