import { Component } from '@angular/core';
import { MissedCalsService } from '../../services/missedcals.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { BatchRequestService } from '../../services/batchsearchrequest.service';
import { MerchantBatchSearchRequest, APISearchCalMidTidDto } from '../batchmerchantsearch/merchantbatchsearchrequest';
import { calsSearcherCsvSchemaProps } from '../batchmerchantsearch/calssearchercsvschema';
import { MerchantSearchExporterService } from '../../services/merchantsearchexporter.service';
import { MissedCalFullView } from './missedcalfullview';
import * as moment from 'moment';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { Options } from 'angular2-csv';
import { APIClients } from '../../enums/enums';
import { apiClientNames } from '../../core/constants/constants';
import { APISearchResultsV4 } from '../../models/searchmodelsv4';

@Component({
  selector: 'app-missedcals',
  templateUrl: './missedcals.component.html',
  styleUrls: ['./missedcals.component.scss']
})
export class MissedCalsComponent {
  missedCals: number;
  hours: number;
  apiClientFilter: APIClients;
  apiClientNames = apiClientNames;
  result: MissedCalFullView[] = [];
  fields: string[] = [calsSearcherCsvSchemaProps.CAL, calsSearcherCsvSchemaProps.TopScore, calsSearcherCsvSchemaProps.TypeOfMatch, calsSearcherCsvSchemaProps.Result_Code,
    calsSearcherCsvSchemaProps.LWC_ID, calsSearcherCsvSchemaProps.NumberOfResults];
  isIndexed = false;
  isDays: boolean = false;

  constructor(private missedCalsService: MissedCalsService, private loadingService: LoadingService, private searchService: BatchRequestService,
    private merchantSearchExporterService: MerchantSearchExporterService, private dateTimeService: DateTimeService,
    private csvComponentService: CsvComponentService) { }

  isSearchButtonDisabled() {
    return this.missedCals > 0 && this.hours > 0;
  }

  search() {
    this.loadingService.setLoading();
    let convertedToHours = this.isDays === false ? this.hours : this.hours * 24;
    this.missedCalsService.searchMissedCals(this.missedCals, convertedToHours,
        this.apiClientFilter)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(calCount => {
        this.result = calCount;
        this.isIndexed = false;
      });
  }

  checkIndexingStatus() {
    this.loadingService.setLoading();
    let searchModel: MerchantBatchSearchRequest = this.prepareCalsForRequest();
    this.searchService.searchBatchMerchant<MerchantBatchSearchRequest, APISearchResultsV4>(searchModel).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((result) => {
        let searchBatchMerchantResult = this.merchantSearchExporterService.getExportObjects(result, this.fields);
        let fullResult = this.result.map((res)=> {
          let objForMerge = searchBatchMerchantResult.find((cal) => cal.CAL == res.CAL)
          return {...res,...objForMerge};
        })
        this.result = fullResult;
        this.isIndexed = true;
      });
  }

  getResultClass(topScore: number) {
    if(topScore >= 82) {
      return 'success';
    }
  }

  getCsvFileName() {
    return 'TopMissedCals-' + this.dateTimeService.formatForFileName(moment());
  }

  getCsvOptions(): Options {
    let columnNames = Object.keys(this.result[0]);
    let additionalCsvOptions = {
      headers: columnNames,
      keys: columnNames,
      showTitle: false,
      title: ''
    };

    let csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...additionalCsvOptions };
    return csvOptions;
  }

  getHoursOrDaysText() {
   return this.isDays === false ? 'hours for' : 'days for';
  }

  private prepareCalsForRequest() {
    let cals = this.result.map(c => {
      let cal: APISearchCalMidTidDto = {
        cal: c.CAL
      }
      return cal;
    });
    let searchModel: MerchantBatchSearchRequest = {
      bank_transactions: cals,
      fields: this.fields
    };
    return searchModel;
  }
}
