import { ExecutionResult } from "graphql/execution";
import { SearchBPayInternal } from "../../components/bpaylookup/bpaylookup.models";

export let getBPayLookupResponse: ExecutionResult<SearchBPayInternal[]> = {
  data: [
    {
      billerCode: "383216",
      billerLongName: "DR GILES MILLER ANAESTHETIC SERVICES",
      billerShortName: "DR GILES MILLER",
      paymentMethodsAccepted: "Debit,Visa,MasterCard,OtherCreditCard",
      lwcId: 1001181350
    },
    {
      billerCode: "414631",
      billerLongName: "MILLER AND PARTNERS ACCOUNTANTS PTY LTD",
      billerShortName: "MILLER AND PARTNERS",
      paymentMethodsAccepted: "Debit,Visa,MasterCard,OtherCreditCard",
      lwcId: 0
    },
    {
      billerCode: "544783",
      billerLongName: "St Therese Primary School Miller/Sadleir",
      billerShortName: "ST THERESE PRIMARY",
      paymentMethodsAccepted: "Debit,Visa,MasterCard,OtherCreditCard",
      lwcId: 0
    },
    {
      billerCode: "636746",
      billerLongName: "Dr James I Miller",
      billerShortName: "Dr James Miller",
      paymentMethodsAccepted: "Debit,Visa,MasterCard,OtherCreditCard",
      lwcId: 1001302466
    },
    {
      billerCode: "879171",
      billerLongName: "MILLERS SELF STORAGE",
      billerShortName: "MILLERS STORAGE",
      paymentMethodsAccepted: "Debit,Visa,MasterCard,OtherCreditCard",
      lwcId: 0
    }
  ]
};

export let getEnrichBPayResponse: ExecutionResult<SearchBPayInternal[]> = {
  data: [
    {
      billerCode: "150342",
      billerLongName: "EAGLEMONT CONSULTANTS",
      billerShortName: "EAGLEMONT CONSULT",
      paymentMethodsAccepted: "Debit,Visa,MasterCard,OtherCreditCard",
      lwcId: 0
    },
  ]
};
