import { emptyString, personalDetailsRemovedMessage, tickIcon, percentIcon, redCloseIcon, limitOnTheNumberOfExportedRecords } from './../../core/constants/constants';
import { SelectionType, ColumnMode } from '@swimlane/ngx-datatable';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseStateComponent } from '../basestate/basestate.component';
import { SearchFeedBackState } from './searchfeedback.state';
import { FeedbackDTODisplay } from './searchfeedbackdto';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { NotificationService } from '../../core/uiservices/notification.service';
import { DateRangeOption, UnspecifiedTrueFalse } from '../../enums/enums';
import { MerchantBatchSearchRequest, ConcurrentRequest } from '../batchmerchantsearch/merchantbatchsearchrequest';
import { BatchRequestStatus, BatchRequestService } from '../../services/batchsearchrequest.service';
import { BatchSearchResult } from '../batchmerchantsearch/batchsearchresult';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { APISearchResultV4 } from '../../models/searchmodelsv4';
import { NumberService } from '../../core/uiservices/number.service';
import { cloneDeep } from 'lodash';
import { timePickerTheme } from '../../core/constants/constants';
import { SearchFeedbackService } from '../../services/searchfeedback.service';
import { FormControl } from '@angular/forms';
import { FeedbackDTO } from '../../shared/models/feedbackdto';
import { PageInfo } from '../../shared/models/pageinfo';
import { propertyOf } from '../../core/services/reflection.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';

@Component({
  selector: 'app-searchfeedback',
  templateUrl: './searchfeedback.component.html'
})
export class SearchFeedBackComponet extends BaseStateComponent<SearchFeedBackState> implements OnInit {
  dateRangeOption = DateRangeOption;
  showHide = UnspecifiedTrueFalse;
  primaryTheme: NgxMaterialTimepickerTheme = timePickerTheme;
  searchWasPerformed = false;
  batchSearchModels: MerchantBatchSearchRequest[] = [];
  failedRequests: ConcurrentRequest<MerchantBatchSearchRequest>[]= [];
  private batchSizeProvider = (r: MerchantBatchSearchRequest) => r.bank_transactions.length;
  batchSearhResults: BatchSearchResult<APISearchResultV4[]>[] = [];
  batchRequestStatus: BatchRequestStatus<APISearchResultV4[]> = {
    areMerchantsLoading: false,
    searchInProgressCount: 0,
    searchCompletedCount: 0,
    searchFailedCount: 0,
    batchSearhResults: []
  };
  editingRowIndex: number = -1;
  originalEditRow: FeedbackDTODisplay;
  clientNames: string[];
  offset: number = 0;
  take: number = 50;
  columnMode = ColumnMode;
  selectionType = SelectionType;
  totalElements: number;
  selectedRecords: FeedbackDTODisplay[] = [];
  filterForFixedCalControl = new FormControl();
  filterByMinimunSuggestionIdControl = new FormControl();
  @ViewChild("csvExport") csvExportComponent: CsvExportComponent;
  datatableHeaders = {
    SearchFeedbackID: "Search Feedback ID",
    CreateDate: "Create Date",
    Email: "Email",
    UserComment: "User Comment",
    ML_Useful: "ML_Useful",
    IsIndexed: "Is Indexed",
    ClientName: "Client Name",
    SearchCount: "Search Count",
    calFeedbackCount: "CAL Feedback Count",
    FixedCal: "Fixed Cal",
    FeedbackType: "Feedback Type",
    SearchFeedbackHumanClassification: "Search Feedback Human Classification",
    LWCComment: "LWC Comment",
    LWC_IDCurrnetSearch: "LWC_ID (Currnet Search)",
    TradingName: "Trading Name",
    PrimaryCategory: "Primary Category"
  };
  datatableKeys = {
    searchFeedbackId: propertyOf<FeedbackDTODisplay>('searchFeedbackId'),
    createDateDisplay: propertyOf<FeedbackDTODisplay>('createDateDisplay'),
    email: propertyOf<FeedbackDTODisplay>('email'),
    userComment: propertyOf<FeedbackDTODisplay>('userComment'),
    mlUsefulDisplay: propertyOf<FeedbackDTODisplay>('mlUsefulDisplay'),
    isIndexedDisplay: propertyOf<FeedbackDTODisplay>('isIndexedDisplay'),
    clientName: propertyOf<FeedbackDTODisplay>('clientName'),
    searchCountDisplay: propertyOf<FeedbackDTODisplay>('searchCountDisplay'),
    calFeedbackCountDisplay: propertyOf<FeedbackDTODisplay>('calFeedbackCountDisplay'),
    cal: propertyOf<FeedbackDTODisplay>('cal'),
    feedbackType: propertyOf<FeedbackDTODisplay>('feedbackType'),
    searchFeedbackHumanClassification: propertyOf<FeedbackDTODisplay>('searchFeedbackHumanClassification'),
    lWCComment: propertyOf<FeedbackDTODisplay>('lwcComment'),
    LWC_IDCurrentSearch: propertyOf<FeedbackDTODisplay>('LWC_IDCurrentSearch'),
    merchantPrimaryNameCurrentSearch: propertyOf<FeedbackDTODisplay>('merchantPrimaryNameCurrentSearch'),
    primaryCategory: propertyOf<FeedbackDTODisplay>('primaryCategory'),
  };

  private optionDatatableHeadersAndKeys = {
    headers: [
      this.datatableHeaders.SearchFeedbackID,
      this.datatableHeaders.CreateDate,
      this.datatableHeaders.Email,
      this.datatableHeaders.UserComment,
      this.datatableHeaders.ML_Useful,
      this.datatableHeaders.IsIndexed,
      this.datatableHeaders.ClientName,
      this.datatableHeaders.SearchCount,
      this.datatableHeaders.calFeedbackCount,
      this.datatableHeaders.FixedCal,
      this.datatableHeaders.FeedbackType,
      this.datatableHeaders.SearchFeedbackHumanClassification,
      this.datatableHeaders.LWCComment,
      this.datatableHeaders.LWC_IDCurrnetSearch,
      this.datatableHeaders.TradingName,
      this.datatableHeaders.PrimaryCategory,
    ],
    keys: [
      this.datatableKeys.searchFeedbackId,
      this.datatableKeys.createDateDisplay,
      this.datatableKeys.email,
      this.datatableKeys.userComment,
      this.datatableKeys.mlUsefulDisplay,
      this.datatableKeys.isIndexedDisplay,
      this.datatableKeys.clientName,
      this.datatableKeys.searchCountDisplay,
      this.datatableKeys.calFeedbackCountDisplay,
      this.datatableKeys.cal,
      this.datatableKeys.feedbackType,
      this.datatableKeys.searchFeedbackHumanClassification,
      this.datatableKeys.lWCComment,
      this.datatableKeys.LWC_IDCurrentSearch,
      this.datatableKeys.merchantPrimaryNameCurrentSearch,
      this.datatableKeys.primaryCategory,
    ]
  };
  private skip: number = 0;
  private copyOfSelectedRecords: FeedbackDTODisplay[] = [];
  private feedbackTypeForQuery: string = "MatchFeedback";

  constructor(injector: Injector, private loadingService: LoadingService,
    private dateTimeService: DateTimeService, private batchRequestService: BatchRequestService,
    private csvComponentService: CsvComponentService, private notificationService: NotificationService, private numberService: NumberService,
    private searchFeedbackService: SearchFeedbackService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      displayResults: [],
      selectedDate: DateRangeOption.Yesterday ,
      customStartDate: null,
      customStartTime: null,
      customEndDate: null,
      customEndTime: null,
      startDate: emptyString,
      endDate: emptyString,
      showHideRejectedRows: UnspecifiedTrueFalse.Unspecified,
      filterForFixedCal: emptyString,
      filterForMlUseful: UnspecifiedTrueFalse.Unspecified,
      filterForClientName: emptyString,
      suggestionId: 0
    };

    this.restoreState();
    this.setSearchFeedback();
  }

  ngAfterViewInit() {
    this.filterForFixedCalControl.setValue(this.state.filterForFixedCal);
    this.filterByMinimunSuggestionIdControl.setValue(this.state.suggestionId);
    this.configureDebouncedValueChanges();
  }

  clearFilterForFixedCal() {
    this.state.filterForFixedCal = '';
  }

  batchSearch() {
    this.searchWasPerformed = true;
    this.batchSearchModels = this.prepareCalsForRequest(this.state.displayResults);

    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestStatus.searchInProgressCount = 0;
    this.batchRequestStatus.searchCompletedCount = 0;
    this.batchRequestStatus.searchFailedCount = 0;

    let retryRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    let successRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    this.batchRequestService.doBatchRequest<MerchantBatchSearchRequest, APISearchResultV4[]>(this.batchSearchModels, this.batchSearchModels,
      this.batchSearchModels,retryRequests, successRequests, this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
      .subscribe((batchRequetStatus) => {
        this.refreshSearchResults(batchRequetStatus);
      });
  }

  proccesBatchSearchResults() {
    for (let merchant of this.state.displayResults) {
      for (let batchMerchant of this.batchSearhResults) {
        let foundMerchant = batchMerchant.response.find(m => m.cal == merchant.cal);

        if (!foundMerchant) {
          continue;
        }

        merchant.LWC_IDCurrentSearch = foundMerchant.merchant_search_results[0].LWC_ID;
        merchant.primaryCategory = foundMerchant.merchant_search_results[0].merchant_details.primary_category.category_name;
        merchant.merchantPrimaryNameCurrentSearch = foundMerchant.merchant_search_results[0].merchant_details.merchant_primary_name;
      }
    }
  }

  retryFailedSearches() {
    let requests = this.failedRequests.map(d => d.data);
    this.failedRequests = [];
    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestService.doBatchRequest(requests, this.batchSearchModels, requests, [],
      [], this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
    .subscribe((batchREquestStatus) => {
      this.refreshSearchResults(batchREquestStatus);
    });
  }

  getCsvOptions(suggestions: FeedbackDTODisplay[]) {
    let obj = suggestions[0];
    let extendedObject = suggestions.find(r => r.hasOwnProperty('LWC_IDCurrentSearch'));
    if(extendedObject) {
      obj = extendedObject;
    }
    obj = {...obj};
    delete obj.mlConfidence;
    delete obj.mlUseful;
    let options = this.csvComponentService.getCsvOptions(obj, 'SearchFeedback.csv');

    return { ...options, ...this.optionDatatableHeadersAndKeys };
  }

  prepareCalsForRequest(merchants: FeedbackDTODisplay[]) {
    let cals = [];
    for (let mer of merchants) {
      cals.push(mer.cal);
    }

    return this.batchRequestService.prepareCalsForRequest(cals);
  }

  setSearchFeedback() {
    this.state.displayResults = [];
    this.clientNames = [];
    this.setStartEndDate();
    this.loadingService.setLoading();
    this.searchFeedbackService.getSuggestions(this.state.endDate, this.state.startDate, this.state.filterForMlUseful, this.state.filterForClientName, this.state.filterForFixedCal, this.state.suggestionId, this.state.showHideRejectedRows, this.skip, this.take, this.feedbackTypeForQuery)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.totalCount) {
          this.state.displayResults = response.data.items;
          this.setDisplayValues(response.data.items);
        for (let searchFeedback of response.data.items) {
          if (!this.clientNames.includes(searchFeedback.clientName)) {
            this.clientNames.push(searchFeedback.clientName);
          }
        }
          this.totalElements = response.data.totalCount;
        }
        else {
          this.state.displayResults = [];
          this.totalElements = 0;
        }
      });
  }

  setStartEndDate(){
    switch(this.state.selectedDate) {
      case DateRangeOption.Today:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().add(1,'days').startOf('day'));
        break;
      case DateRangeOption.Yesterday:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().add(-1, 'days').startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
        break;
      case DateRangeOption.ThisWeek:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('isoWeek'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().endOf('isoWeek').add(1,'days'));
        break;
      case DateRangeOption.LastWeek:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(-6).startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(0).add(1,'days').endOf('day'));
        break;
      case DateRangeOption.Custom:
        if(this.state.customStartDate && this.state.customEndDate) {
          this.state.startDate = this.dateTimeService.formatWith24Hours(this.state.customStartDate);
          this.state.endDate = this.dateTimeService.formatWith24Hours(this.state.customEndDate);
        } else {
          this.state.customStartDate = moment().add(-1, 'days');
          this.state.customEndDate = moment().endOf('day');
          this.state.customStartTime = this.dateTimeService.twentyFourHours;
          this.state.customEndTime = this.dateTimeService.twentyFourHours;
        }
        break;
    }
  }

  checkCustomDateValidity() {
    let start = moment(this.state.customStartDate);
    let end = moment(this.state.customEndDate);

    let duration = moment.duration(end.diff(start));
    let days = duration.asDays();

    if (days > 29) {
      this.notificationService.notifyError('Can not set date range greater than 30 days');
      return;
    }

    if (this.state.customStartTime === this.dateTimeService.twentyFourHours) {
      this.state.customStartTime = this.dateTimeService.zeroTime;
    }
    if (this.state.customEndTime === this.dateTimeService.twentyFourHours) {
      this.state.customEndTime = this.dateTimeService.zeroTime;
    }

    this.state.customStartDate = this.dateTimeService.setTime(this.state.customStartDate , this.state.customStartTime);
    this.state.customEndDate = this.dateTimeService.setTime(this.state.customEndDate , this.state.customEndTime);
    this.state.startDate = this.dateTimeService.formatWith24Hours(this.state.customStartDate);
    this.state.endDate = this.dateTimeService.formatWith24Hours(this.state.customEndDate);

    this.setSearchFeedback();

  }

  formatBooleanToCheckrows(value: boolean) {
    return value ? '✓' : '';
  }

  edit(row: FeedbackDTODisplay, rowIndex: number) {
    this.originalEditRow = cloneDeep(row);
    this.editingRowIndex = rowIndex;
  }

  cancel(rowIndex: number) {
    this.state.displayResults[rowIndex] = this.originalEditRow;
    this.state.displayResults = [...this.state.displayResults];
    this.editingRowIndex = -1;
  }

  isStartDateIncorrect() {
    if (this.state.customStartDate && this.state.customEndDate && this.state.customStartTime && this.state.customEndTime) {
      return this.dateTimeService.isFirstDateBiggerOrEqual(this.state.customStartDate, this.state.customEndDate, this.state.customStartTime, this.state.customEndTime);
    }
    return false;
  }

  clearSuggestionInput() {
    this.state.suggestionId = 0;
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    this.offset = pageInfo.offset;
    this.skip = pageInfo.offset * this.take;
    this.setSearchFeedback();
  }

  onSelect(selected: { selected: FeedbackDTODisplay[] }) {
    this.searchFeedbackService.restorePersonalDetailsIfNeeded(this.copyOfSelectedRecords, selected.selected, this.state.displayResults);
    this.state.displayResults = [...this.state.displayResults];
    this.selectedRecords = [...selected.selected];
  }

  removePersonalDetails() {
    for (let record of this.selectedRecords) {
      this.copyOfSelectedRecords.push({ ...record });
      record.userComment = personalDetailsRemovedMessage;
      record.searchFeedbackHumanClassification = emptyString;
      record.lwcComment = emptyString;
    }
    this.state.displayResults = [...this.state.displayResults];
  }

  disabledRemovePersonalDetailsButton() {
    return this.selectedRecords.length === 0;
  }

  disabledSaveButton() {
    return !(this.selectedRecords.length > 0 && this.selectedRecords.find(t => t.userComment === personalDetailsRemovedMessage));
  }

  setUpdateSearchFeedbacks(idOfRecordThatEdited?: number, lwcComment: string = emptyString, humanClassification: string = emptyString) {
    let idsOfSelectedRecords: number[] = this.selectedRecords.map(t => t.searchFeedbackId);
    let ids: number[] = idsOfSelectedRecords.length > 0 ? idsOfSelectedRecords : [idOfRecordThatEdited];

    this.loadingService.setLoading();
    this.searchFeedbackService.getUpdateSearchFeedbacks(ids, this.editingRowIndex !== -1 ? null : personalDetailsRemovedMessage, lwcComment, humanClassification)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.updateSearchFeedback) {
          for (let selectedRecord of this.selectedRecords) {
            let record: FeedbackDTO = this.state.displayResults.find(t => t === selectedRecord);
            let indexOfRecord = this.state.displayResults.indexOf(record);
            let newRecord = response.data.updateSearchFeedback.find(t => t.searchFeedbackId === record.searchFeedbackId);

            if (newRecord !== undefined) {
              this.state.displayResults[indexOfRecord] = newRecord;
              this.formatToString(this.state.displayResults[indexOfRecord]);
            }
          }
          this.state.displayResults = [...this.state.displayResults];
        }
        this.copyOfSelectedRecords = [];
        this.selectedRecords = [];
        this.editingRowIndex = -1;
      });
  }

  downloadCsvFile() {
    if (this.totalElements > 5000) {
      this.notificationService.notifyError(limitOnTheNumberOfExportedRecords);
      return;
    }

    let suggestionsForCsvFile: FeedbackDTODisplay[] = [];
    let skip: number = 0;
    this.setStartEndDate();
    this.loadingService.setLoading();
    this.searchFeedbackService.getSuggestions(this.state.endDate, this.state.startDate, this.state.filterForMlUseful, this.state.filterForClientName, this.state.filterForFixedCal, this.state.suggestionId, this.state.showHideRejectedRows, skip, this.totalElements, this.feedbackTypeForQuery)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.totalCount) {
          suggestionsForCsvFile = response.data.items;
          this.setDisplayValues(suggestionsForCsvFile);
          this.csvExportComponent.data = suggestionsForCsvFile;
          this.csvExportComponent.options = this.getCsvOptions(suggestionsForCsvFile);
          this.csvExportComponent.onDownload();
        }
      });
  }

  private refreshSearchResults (batchREquestStatus: BatchRequestStatus<APISearchResultV4[]>) {
    this.batchRequestStatus = batchREquestStatus;
    if (this.batchRequestStatus.searchInProgressCount === 0) {
      this.batchSearhResults = this.batchRequestStatus.batchSearhResults.concat(batchREquestStatus.batchSearhResults);
      this.proccesBatchSearchResults();
    }
  }

  private setDisplayValues(searchFeedbacks: FeedbackDTO[]) {
    for (let searchFeedback of searchFeedbacks) {
      this.formatToString(searchFeedback);
    }
  }

  private formatToString(searchFeedback: FeedbackDTO) {
    let display: FeedbackDTODisplay = <FeedbackDTODisplay>searchFeedback;
    display.searchCountDisplay = this.numberService.format(display.searchCount);
    display.calFeedbackCountDisplay = this.numberService.format(display.calFeedbackCount);
    display.isIndexedDisplay = display.isIndexed == true ? tickIcon : emptyString;
    display.mlUsefulDisplay = display.mlUseful === true ? `${tickIcon} (${Math.round(display.mlConfidence * 100)}) ${percentIcon}` : `${redCloseIcon} (${Math.round(display.mlConfidence * 100)}) ${percentIcon}`;
    display.createDateDisplay = this.dateTimeService.formatWithHours(display.createDate);
  }

  private configureDebouncedValueChanges() {
    this.filterForFixedCalControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.setSearchFeedback();
      });

    this.filterByMinimunSuggestionIdControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.setSearchFeedback();
      });
  }
}
