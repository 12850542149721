import { LoadingService } from '../../core/uiservices/loading.service';
import { ContainsODataFilter } from './../../core/api/odata/containsodatafilter';
import { BaseODataFilter } from './../../core/api/odata/baseodatafilter';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Component, OnInit, Injector } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { CalHistoryFormatted, CalHistory, calHistoryFormattedProps } from './calhistory';
import { ODataService } from '../../core/api/odata/odata.service';
import { ODataConfig } from '../../core/api/odata/odataconfig';
import { FormControl } from '@angular/forms';
import { ODataFilter } from '../../core/api/odata/odatafilter';
import { calStatusesType, equalsStartsContainsFilterTypes } from '../../core/constants/constants';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { ODataOrderByDesc } from '../../core/api/odata/odataorderbydesc';
import { ODataFilterType, CALStatuses } from '../../enums/enums';
import { StartsWithODataFilter } from '../../core/api/odata/startswithodatafilter';
import { CalHistoryState } from './calhistorystate';
import { StringODataFilter } from '../../core/api/odata/stringodatafilter';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { NameValue } from '../../core/models/namevalue';

@Component({
  selector: 'app-calhistory',
  templateUrl: './calhistory.component.html',
  styleUrls: ['./calhistory.component.scss']
})
export class CalHistoryComponent extends BaseStateComponent<CalHistoryState> implements OnInit {
  rows: CalHistoryFormatted[] = [];
  calStatusesArray: NameValue<CALStatuses>[] = [];
  calFilterTypes: NameValue<ODataFilterType>[] = [];
  indexerCtrl = new FormControl();
  cardAcceptorLocatorCtrl = new FormControl();
  pageIndex = 0;
  pageSize = 20;
  totalElements = 0;
  headers = {
    CAL: 'Card Acceptor/Locator',
    Status: 'CAL Status',
    Indexer: 'Indexer',
    LWC_ID: 'LWC ID',
    StatusDateTimeFormatted: 'Date/Time'
  };

  constructor(injector: Injector, private oDataService: ODataService, private dateTimeService: DateTimeService,
    private loadingService: LoadingService, private csvComponentService: CsvComponentService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      calNotTracked: '',
      calStatus: '',
      indexerNotTracked: '',
      calFilterType: ODataFilterType.Contains
    };

    this.restoreState();

    this.indexerCtrl.setValue(this.state.indexerNotTracked);
    this.cardAcceptorLocatorCtrl.setValue(this.state.calNotTracked);

    this.getCalHistory();

    this.calStatusesArray = calStatusesType;
    this.calFilterTypes = equalsStartsContainsFilterTypes;

    this.setupDynamicFilters();
  }

  getCalHistory(pageInfo?: { offset: number }) {
    this.pageIndex = pageInfo ? pageInfo.offset : 0;

    let oDataFilters: BaseODataFilter[] = [];

    if (this.state.calStatus) {
      oDataFilters.push(new ODataFilter(calHistoryFormattedProps.StatusID, this.state.calStatus));
    }

    if (this.indexerCtrl.value) {
      oDataFilters.push(new ContainsODataFilter(calHistoryFormattedProps.IndexerName, this.indexerCtrl.value));
    }

    if (this.cardAcceptorLocatorCtrl.value) {
      if (this.state.calFilterType === ODataFilterType.Contains) {
        oDataFilters.push(new ContainsODataFilter(calHistoryFormattedProps.TransactionDescription, this.cardAcceptorLocatorCtrl.value));
      } else if (this.state.calFilterType === ODataFilterType.StartsWith) {
        oDataFilters.push(new StartsWithODataFilter(calHistoryFormattedProps.TransactionDescription, this.cardAcceptorLocatorCtrl.value));
      } else if (this.state.calFilterType === ODataFilterType.Equals) {
        oDataFilters.push(new StringODataFilter(calHistoryFormattedProps.TransactionDescription, this.cardAcceptorLocatorCtrl.value));
      }
    }

    let oDataConfig: ODataConfig = {
      query: 'calhistory',
      top: 20,
      skip: this.pageSize * this.pageIndex,
      count: true,
      orderBy: new ODataOrderByDesc(calHistoryFormattedProps.StatusDateTime),
      filters: oDataFilters
    };

    this.loadingService.setLoading();

    this.oDataService.get<CalHistory[]>(oDataConfig)
      .pipe(finalize(() => {
        this.loadingService.clearLoading();
      }))
      .subscribe(calhistory => {
        this.rows = this.formatCalHistory(calhistory.value);
        this.totalElements = calhistory['@odata.count'];
      });
  }

  getCalHistoryByCalFilter() {
    if (this.cardAcceptorLocatorCtrl.value) {
      this.getCalHistory();
    }
  }

  getCsvOptions() {
    return this.csvComponentService.getCsvOptions(this.rows[0], 'CALHistory');
  }

  private formatCalHistory(calHistory: CalHistory[]) {
    let calHistoryFormatted = calHistory as CalHistoryFormatted[];

    for (let calFormatted of calHistoryFormatted) {
      calFormatted.StatusDateTimeFormatted = this.dateTimeService.formatWithHours(calFormatted.StatusDateTime.toString());
    }

    return calHistoryFormatted;
  }

  private setupDynamicFilters() {
    this.indexerCtrl.valueChanges
      .pipe(debounceTime(1200), distinctUntilChanged())
      .subscribe(() => {
        this.state.indexerNotTracked = this.indexerCtrl.value;
        this.getCalHistory();
      });

    this.cardAcceptorLocatorCtrl.valueChanges
      .pipe(debounceTime(1200), distinctUntilChanged())
      .subscribe(() => {
        this.state.calNotTracked = this.cardAcceptorLocatorCtrl.value;
        this.getCalHistoryByCalFilter();
      });
  }
}
