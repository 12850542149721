import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { quarantinedMerchantsResponse } from '../mockjsonresponses/quarantinedmerchantsresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';

export function quarantinedMerchantsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];
  if (cleanUrl.includes(apiPathConstants.quarantinedMerchants) && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...quarantinedMerchantsResponse]
      }));
      resp.complete();
    });
  }
}