import { ExecutionResult } from "graphql";
import { EnumValuesResponse, MissingMerchantsResponse, UpdateMissingMerchantResponse } from "../../components/managemissingmerchants/managemissingmerchants.component";
import { MerchantPresence } from "../../enums/enums";

export let enumValuesResponse: ExecutionResult<EnumValuesResponse> = {
  data: {
      enumValues: [
        {
          name: "ALL"
        },
        {
          name: "OPEN"
        },
        {
          name: "CLOSE"
        },
        {
          name: "DELETED"
        },
      ]
  }
};

export let missingMerchantsResponse: ExecutionResult<MissingMerchantsResponse> = {
  data: {
      items: [
        {
          merchantFeedbackID: 10598978,
          apiKeyName: '\r\nlwc-dev-01',
          number: '154',
          sellerName: 'ONLY DINING CHAIRS',
          aBN: '54058330911',
          aCN: '121451723',
          address: '22 Rundle Mall, Adelaide, South Australia, 8000',
          suburb: 'Kiama Downs',
          state: 'WA',
          postcode: '4509',
          storeName: 'Store Name1',
          storeJoinDate: '2017-02-10T18:16:22.303Z',
          industry: 'Sensis',
          storeAddress: 'div.storeAddress > h3',
          storeSuburb: 'LEOPOLD',
          storeState: 'VIC',
          storePostcode: '4509',
          bTLT: 'LT',
          onlineInStore: MerchantPresence.MobileMerchant,
          merchantLookupRequestGuid: "2048a544e7bb0596c3c556b6db8",
          batchGuid: "1007bb0596c00458",
          comment: 'Success: 1000947863 enriched with a score of 85 in 9s',
          createdDate: '2021-05-18T15:13:59.303Z',
          modifiedDate: '2001-05-15T09:19:59.303Z',
          modifiedBy: '2',
          status: "OPEN",
          lWC_Guid: "890596c89848a9",
          lWC_ID: 3597,
          isDeleted: false,
        },
        {
          merchantFeedbackID: 784848,
          apiKeyName: 'false api key',
          number: '8784',
          sellerName: 'Chairs',
          aBN: '31800454708',
          aCN: '357951456',
          address: '2/36 Albert Street, ST PETERS NSW 2044 AUS',
          suburb: 'Kiama Highs',
          state: 'TAS',
          postcode: '7000',
          storeName: 'Store Name2',
          storeJoinDate: '2008-03-03T02:02:59.303Z',
          industry: 'ClearbitIndustry',
          storeAddress: 'div.storeAddress > p > span',
          storeSuburb: 'LEOPOLD2',
          storeState: 'VIC2',
          storePostcode: '6000',
          bTLT: 'LT2',
          onlineInStore: MerchantPresence.Unspecified,
          merchantLookupRequestGuid: "2048a544e7bb0596598c55ffd6db8",
          batchGuid: "8812048a5984555ffd6db8818",
          comment: 'Warning: 1000947863 enriched with a score of 85 in 8s',
          createdDate: '2004-08-21T19:13:59.303Z',
          modifiedDate: '2005-09-12T22:09:59.303Z',
          modifiedBy: '3',
          status: "CLOSED",
          lWC_Guid: "281fdeffd6db8818818",
          lWC_ID: 91298484,
          isDeleted: true,
        },
      ],
      totalCount: 500
  }
};

export let updateMissingMerchantResponse: ExecutionResult<UpdateMissingMerchantResponse> = {
  data: {
    updateMissingMerchant: {
      merchantFeedbackID: 159,
      comment: "testtest",
      modifiedBy: "1",
      lWC_ID: 164545070,
      status: "DELETED",
      apiKeyName: 'false api key',
      number: '846',
      sellerName: 'Chairs',
      aBN: '318094208',
      aCN: '89257951456',
      address: '2/36 Albert Street',
      suburb: 'Highs',
      state: 'TAS3',
      postcode: '9594',
      storeName: 'Store Name2',
      storeJoinDate: '2018-06-03T02:02:22.303Z',
      industry: 'ClearbitIndustry',
      storeAddress: 'div.storeAddress',
      storeSuburb: 'LEOPOLD3',
      storeState: 'VIC3',
      storePostcode: '2250',
      bTLT: 'LT3',
      onlineInStore: MerchantPresence.Unspecified,
      merchantLookupRequestGuid: "20788484sdsdsd98c55ffd6db8",
      batchGuid: "8812048zzzzfd6db8818",
      createdDate: '2014-08-21T19:13:59.303Z',
      modifiedDate: '2025-09-12T22:10:12.303Z',
      lWC_Guid: "281fdeffd6db8818818",
      isDeleted: false,
    }
  }
};
