import { ScrapedData } from "../../components/scrapingdatasources/scrapingdatadto"

export let scrapingDataSources: string[] = [
    "bpay",
    "nswfoodauthority"
];

export let scrapingBpayResponse = {
    Property1: 'Jacob',
    Property2: 'Baker',
    Date: '2020-07-03'
}

export let scrapingNSWFoodAuthrityResponse = [
    {
        Property1: 'Avery1',
        Property2: 'Ball1',
        Date: '2005-10-05',
        MatchedLwcId: null
    },
    {
        Property1: 'Avery2',
        Property2: 'Ball2',
        Date: '2010-17-05',
        MatchedLwcId: 1000934794
    },
    {
        Property1: 'Avery3',
        Property2: 'Ball4',
        Date: '2018-10-02',
        MatchedLwcId: 1000900000
    },
    {
        Property1: 'Avery4',
        Property2: 'Ball4',
        Date: '20015-10-05'
    },
]

export let viewScrapedData: ScrapedData[] = [
    {
        tradingName: "EATON GARDENS CHINESE RESTAURANT",
        legalName: "LI",
        fullAddress: "5/29-31 WINDSOR ROAD, KELLYVILLE 2155",
        sourceID: "3152865330",
        sourceDateTime: "2018-03-18T02:00:00",
        matchConfidence: "High",
        matchedLwcId: 1000554794
    },
    {
        tradingName: "EATON GARDENS CHINESE RESTAURANT",
        legalName: "LI",
        fullAddress: "5/29-31 WINDSOR ROAD, KELLYVILLE 2155",
        sourceID: "3152865330",
        sourceDateTime: "2010-01-11T12:00:00",
        matchConfidence: "High",
        matchedLwcId: 1000994794
    },
    {
        tradingName: "EATON GARDENS CHINESE RESTAURANT",
        legalName: "LI",
        fullAddress: "5/29-31 WINDSOR ROAD, KELLYVILLE 2155",
        sourceID: "3152865330",
        sourceDateTime: "2018-03-18T02:00:00",
        matchConfidence: "High"
    },
    {
        tradingName: "EATON GARDENS CHINESE RESTAURANT",
        legalName: "LI",
        fullAddress: "5/29-31 WINDSOR ROAD, KELLYVILLE 2155",
        sourceID: "3152865330",
        sourceDateTime: "2020-05-27T00:00:00",
        matchConfidence: "High",
        matchedLwcId: 1000899794
    }
]