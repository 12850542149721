import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tagsResponse } from '../mockjsonresponses/tagsresponse';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function tagsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {

  if (url.includes(apiPathConstants.tag) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...tagsResponse]
      }));
      resp.complete();
    });
  }

  if (url.includes(apiPathConstants.tag) && method === HttpMethod.Put) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  }

  if (url.includes(apiPathConstants.tag) && method === HttpMethod.Delete) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    })
  }
}
