import { FormControl } from '@angular/forms';
import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { MerchantExtractState } from './merchantextractstate';
import { MerchantExtractService } from '../../services/merchantextract.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, takeUntil, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Options } from 'angular2-csv';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { ChainSelectComponent } from '../../shared/chainselect/chainselect.component';
import { FormatService } from '../../core/formatting/format.service';
import { merchantDetailsSchemaProps, MerchantDetails, MerchantShoppingCentre, MerchantDetailsByShoppingCentreDisplay } from './merchantdetails';
import { emptyOptionEntry } from '../../core/constants/constants';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { PickChainService } from '../../services/pickchain.service';
import { StringService } from '../../core/formatting/string.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-merchant-extract',
  templateUrl: './merchantextract.component.html',
  styleUrls: ['./merchantextract.component.scss']
})
export class MerchantExtractComponent extends BaseStateComponent<MerchantExtractState> implements OnInit, OnDestroy {
  @ViewChild('chainSelect', { static: true }) chainSelect: ChainSelectComponent;

  chainTypesToInclude: Array<string | 0>;
  allTableFields: string[] = [...this.merchantExtractService.merchantDetailsSchemaFields];
  selectableTableFields: string[] = [...this.merchantExtractService.merchantDetailsSchemaFields];
  merchantDetailsSchemaProps = merchantDetailsSchemaProps;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  merchantShoppingCentres: MerchantShoppingCentre[] = [];
  filteredMerchantShoppingCentres: MerchantShoppingCentre[] = [];
  merchantDetailsByShoppingCentres: MerchantDetailsByShoppingCentreDisplay[] = [];
  merchantShoppingCentresControl = new FormControl();

  constructor(injector: Injector,
    private merchantExtractService: MerchantExtractService, private loadingService: LoadingService,
    private router: Router, private dateTimeService: DateTimeService, private csvComponentService: CsvComponentService,
    private formatService: FormatService, private valueCheckerService: ValueCheckerService,
    private pickChainService: PickChainService, private stringService: StringService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      chainValue: emptyOptionEntry,
      lwcIds: '',
      merchantDetails: [],
      chosenTableFields: [],
      bsbs: '',
      locatedInName: null
    }

    this.restoreState();

    this.chainTypesToInclude = this.pickChainService.getUsualChainTypesForDisplay();
    this.setMerchantShoppingCenters();
  }

  ngOnDestroy() {
    this.state.chainValue = this.chainSelect.getValue();
    super.ngOnDestroy();
  }

  isTableFieldSelected(field: string) {
    return this.state.chosenTableFields.includes(field);
  }

  addTableFieldFromAutoCompleteToList(event: MatAutocompleteSelectedEvent) {
    let value = event.option.viewValue;
    if (!this.selectableTableFields.includes(value)) {
      this.selectableTableFields.push(value);
    }
  }

  addTableFieldToList(event: MatChipInputEvent) {
    let input = event.input;
    let value = event.value;

    if (this.stringService.isNonEmptyTrimmedStringExistInArray(value, this.selectableTableFields)) {
      this.selectableTableFields.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  removeTableFieldFromList(field: string) {
    let index = this.selectableTableFields.indexOf(field);
    if (index >= 0) {
      this.selectableTableFields.splice(index, 1);
    }
  }

  load() {
    if (this.chainSelect.getValue().value) {
      this.loadingService.setLoading();
      this.merchantExtractService.getMerchantDetails(this.chainSelect.getValue().value)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((resp) => {
          this.state.chosenTableFields = [...this.selectableTableFields];
          this.state.merchantDetails = this.formatService.formatDateWithHours(resp, merchantDetailsSchemaProps.UpdatedDate);
        });
    } else if (this.state.lwcIds) {
      this.loadingService.setLoading();
      let lwcIds = this.prepareLwcIds();
      this.merchantExtractService.getMerchantDetailsByLwcIds(lwcIds)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((resp) => {
          this.state.chosenTableFields = [...this.selectableTableFields];
          this.state.merchantDetails = this.orderyBySearchedLwcIds(lwcIds, resp);
        });
    } else if (this.state.bsbs) {
      this.loadingService.setLoading();
      let bsbs = this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.bsbs);
      this.merchantExtractService.getMerchantDetailsByBsbs(bsbs)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((resp) => {
          this.state.chosenTableFields = [...this.selectableTableFields];
          this.state.merchantDetails = resp;
          this.dublicateMerchantsBasedOnBsbs(this.state.merchantDetails);
        });
    }
    if (this.filteredMerchantShoppingCentres[0].locatedIn_Name === this.state.locatedInName) {
      this.setMerchantDetailsByShoppingCentres();
    }
  }

  goToEnrichMerchant(LWCID: number) {
    this.router.navigate(['/enrichmerchant', { lwcId: LWCID, cleanState: true }]);
  }

  getCsvFileName() {
    return 'MerchantExtract-' + this.dateTimeService.formatForFileName(moment());
  }

  getCsvOptions(): Options {
    let additionalCsvOptions = {
      headers: this.state.chosenTableFields,
      keys: this.state.chosenTableFields,
      showTitle: false,
      title: ''
    };
    let csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...additionalCsvOptions };

    return csvOptions;
  }

  areLwcIdNumbersValid() {
    let numbers = this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.lwcIds);

    return numbers.every(s => this.valueCheckerService.isNumber(s));
  }

  isLoadButtonDisabled() {
    if (!this.chainSelect.getValue().value && !this.state.lwcIds && !this.state.bsbs
      && (this.filteredMerchantShoppingCentres.length > 0 && this.filteredMerchantShoppingCentres[0].locatedIn_Name !== this.state.locatedInName)) {
      return true;
    } else if (!this.chainSelect.getValue().value && !this.state.bsbs && !this.areLwcIdNumbersValid()
      && (this.filteredMerchantShoppingCentres.length > 0 && this.filteredMerchantShoppingCentres[0].locatedIn_Name !== this.state.locatedInName)) {
      return true;
    }
    return false;
  }

  setMerchantDetailsByShoppingCentres() {
    this.loadingService.setLoading();
    this.merchantExtractService.getMerchantDetailsByShoppingCentres(this.filteredMerchantShoppingCentres[0].locatedIn_ID.toString())
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.length > 0) {
          this.merchantDetailsByShoppingCentres = response;
          this.handleMerchantDetailsByShoppingCentres();
        }
        else {
          this.merchantDetailsByShoppingCentres = [];
        }
      });
  }

  private prepareLwcIds() {
    return this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.lwcIds).map(id => parseInt(id, 10));
  }

  private orderyBySearchedLwcIds(lwcIds: number[], merchantDetails: MerchantDetails[]) {
    let result: Partial<MerchantDetails>[] = [];
    for (let id of lwcIds) {
      let found = merchantDetails.find(d => d.LWC_ID === id);
      if (found) {
        found.UpdatedDate = this.dateTimeService.formatWithHours(merchantDetailsSchemaProps.UpdatedDate)
        result.push(found);
      } else {
        let notFound: Partial<MerchantDetails> = {
          LWC_ID: id,
          PrimaryName: "[NOT FOUND]"
        };
        result.push(notFound);
      }
    }

    return result;
  }

  private dublicateMerchantsBasedOnBsbs(merchantDetails: Partial<MerchantDetails>[]) {
    let bsbsThatUserInput: string[] = this.state.bsbs.split("\n");
    let newMerchantDetails: Partial<MerchantDetails>[] = [];

    for (let i = 0; i < merchantDetails.length; i++) {
      let merchantDetail = merchantDetails[i];
      if (!this.valueCheckerService.isEmptyNullOrUndefined(merchantDetail.BSBs)) {
        let bsbs: string[] = merchantDetail.BSBs.split(",");

        for (let k = 0; k < bsbs.length; k++) {
          let bsb = bsbs[k];
          if (bsbsThatUserInput.find(t => t.trim() === bsb.trim())) {
            let newMercantDetail = { ...merchantDetail };
            newMercantDetail.BSBs = bsb.trim();
            newMerchantDetails.push(newMercantDetail);
          }
        }
      }
      this.state.merchantDetails = [...newMerchantDetails];
    }
  }

  private setMerchantShoppingCenters() {
    this.loadingService.setLoading();
    this.merchantExtractService.getMerchantShoppingCenters()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data.length > 0) {
          this.merchantShoppingCentres = [...this.filteredMerchantShoppingCentres] = response.data;
          this.configureMerchantShoppingCentresValueChanges();
        }
      });
  }

  private handleMerchantDetailsByShoppingCentres() {
    for (let merchantDetails of this.merchantDetailsByShoppingCentres) {
      merchantDetails.UpdatedDateDisplay = merchantDetails.UpdatedDate
        ? this.dateTimeService.formatWithHours(merchantDetails.UpdatedDate)
        : null;
      merchantDetails.StreetViewAvailableDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchantDetails.StreetViewAvailable);
      merchantDetails.RecordLockedDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchantDetails.RecordLocked);
      merchantDetails.IsQuarantinedDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchantDetails.IsQuarantined);
    }
  }

  private configureMerchantShoppingCentresValueChanges() {
    this.merchantShoppingCentresControl.valueChanges.pipe(
      map((shoppingCentre: string) => shoppingCentre
        ? this.merchantShoppingCentres.filter(t => t.locatedIn_Name.toLowerCase().includes(shoppingCentre.toLowerCase()))
        : this.merchantShoppingCentres.slice())
    )
      .subscribe((result) => {
        this.filteredMerchantShoppingCentres = result;
      });
  }
}
