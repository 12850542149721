import { emptyString, commaSpace } from './../core/constants/constants';
import { hotChocolate, bsbsCleansedQuery, assignBsbToMerchantQuery } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { StringService } from "../core/formatting/string.service";
import { Apollo, gql } from 'apollo-angular';
import { BsbsCleansedResponse, AssignBsbToMerchantResponse } from '../components/managebsbs/managebsbs.component';
import { MerchantStatus, BsbStatus } from '../enums/enums';

@Injectable({
  providedIn: "root"
})
export class ManageBsbsService {
  constructor(private apollo: Apollo, private stringService: StringService) { }

  getBsbsCleansed(status: number, bsbStatus: number, skip: number, take: number) {
    let filters = emptyString;
    if (status !== MerchantStatus.All) {
      filters = this.stringService.format(`{lwc_ID: { {0}: 0} }${commaSpace}`, status === MerchantStatus.Unassigned ? "eq" : "neq");
    }
    filters = filters + this.stringService.format("{bsbStatus: {eq: {0} } }", bsbStatus === BsbStatus.Active
      ? null
      : bsbStatus === BsbStatus.Closed
        ? '"Closed"'
        : '"Merged"');

    return this.apollo
      .use(hotChocolate)
      .watchQuery<BsbsCleansedResponse>({
        query: gql`${this.stringService.format(bsbsCleansedQuery, filters, skip.toString(), take.toString())}`
      })
      .valueChanges;
  }

  assignBsbToMerchant(lwcId: string, bsbNumber: string, defaultDataSourceName: string, indexerId: string, indexerName: string) {
    return this.apollo
      .use(hotChocolate)
      .mutate<Partial<AssignBsbToMerchantResponse>>({
        mutation: gql`${this.stringService.format(assignBsbToMerchantQuery, lwcId, bsbNumber, defaultDataSourceName, indexerId, indexerName)}`
      });
  }
}
