import { hotChocolate } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { LocatedInDto } from "../components/managelocations/managelocationsdto";
import { Apollo } from "apollo-angular";
import { LoadingService } from "../core/uiservices/loading.service";
import { editLocationUpsertQuery, editLocationDeleteQuery, manageLocationsQuery } from "../gqlconstants/gqlconstants";

@Injectable()
export class ManageLocationService {
  private locatedIn: LocatedInDto;

  constructor(private apollo: Apollo, private loadingService: LoadingService) {
  }

  getManageLocations() {
    this.loadingService.setLoading();
    return this.apollo
      .use(hotChocolate)
      .watchQuery<LocatedInDto[]>({
        query: manageLocationsQuery,
      })
      .valueChanges;
  }

  getLocatedIn() {
    return this.locatedIn;
  };

  setLocatedIn(locatedIn: LocatedInDto) {
    this.locatedIn = locatedIn;
  };

  save(locatedIn: LocatedInDto) {
    return this.apollo
      .use(hotChocolate)
      .mutate({
        mutation: editLocationUpsertQuery,
        variables: {
          locatedIn: locatedIn
        }
      });
  }

  delete(locatedInId: number) {
    return this.apollo
      .use(hotChocolate)
      .mutate({
        mutation: editLocationDeleteQuery,
        variables: {
          locatedInId: locatedInId
        }
      });
  }
}
