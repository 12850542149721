import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { eventingChainsResponse, manageDataScrapingResponse, webScapingDetailResponse, indexingAuditResponse } from "../mockjsonresponses/managedatascrapingresponce";
import { unmatchedMerchantsResponse } from '../mockjsonresponses/unmatchedmerchantsresponse';

export function eventingBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];

    if (cleanUrl.includes(apiPathConstants.eventingChains) && cleanUrl.endsWith('chains') && method === 'GET') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: eventingChainsResponse
            }));
            resp.complete();
        });
    }
    if (cleanUrl.includes(apiPathConstants.eventingChains) && method === 'GET') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: { ...manageDataScrapingResponse }
            }));
            resp.complete();
        })
    }
    if (cleanUrl.includes(apiPathConstants.eventingChains) && method === 'POST') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200
            }));
            resp.complete();
        })
    }

    let includeWebScrapingDetail = false;
    if (request.body && request.body.query) {
        includeWebScrapingDetail = request.body.query.includes('stores');
    }
    if (cleanUrl.includes(apiPathConstants.eventing) && method === 'POST' && includeWebScrapingDetail) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: {...webScapingDetailResponse}
            }));
            resp.complete();
        })
    }

    if (cleanUrl.includes(apiPathConstants.startScrape) && method === 'POST') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200
            }));
            resp.complete();
        })
    }
    if (url.includes(apiPathConstants.indexingAudit + '?itemId=') && method === 'GET') {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: [...indexingAuditResponse]
        }));
        resp.complete();
      })
    }

    let includeUnmatchedMerchants = false;
    if (request.body && request.body.query) {
      includeUnmatchedMerchants = request.body.query.includes('unmatchedMerchants')
      && request.body.query.includes('scrapeCode')
      && Object.keys(request.body.variables).includes('chainCode');
    }

    if (cleanUrl.includes(apiPathConstants.eventing) && method === 'POST' && includeUnmatchedMerchants) {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: {...unmatchedMerchantsResponse}
        }));
        resp.complete();
      })
    }
}
