import { Injectable } from '@angular/core';
import { RoleType } from '../../users/userenums';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoginStateService {

	constructor(private authService: AuthService) { }

	isInAnyRole(roles: RoleType[]) {
		if (!roles) {
			return false;
		}
		for (let role of roles) {
			let user = this.authService.getCurrentUser();
			switch (role) {
				case RoleType.IndexManagerAdmin:
					if (!environment.production && user.name == 'indexmanager-authtest@lookwhoscharging.com') {
						this.authService.addRole(RoleType.IndexManagerAdmin);
					}
					if (this.authService.hasRole('IndexManagerAdmin')) {
						return true;
					}
					break;
				case RoleType.IndexManagerTeamLead:
					if (this.authService.hasRole('IndexManagerTeamLead')) {
						return true;
					}
					break;
				case RoleType.IndexManagerIndexerWithPurge:
					if (this.authService.hasRole('IndexManagerIndexerWithPurge')) {
						return true;
					}
					break;
			}
		}

		return false;
	}
}
