import { Injectable } from '@angular/core';
import { CacheItem } from '../core/cache/cacheitem';
import * as moment from 'moment';
import { ValueCheckerService } from '../core/services/valuechecker.service';

@Injectable({
    providedIn: 'root'
})
export class InMemoryCacheService {

    private inMemoryCache: Map<string, CacheItem<unknown>> = new Map<string, CacheItem<unknown>>();
    private cacheDuration = 1 * 60 * 60;
    private cacheKeyPrefix = 'cache_';

    constructor(private valueCheckerService: ValueCheckerService) { }

    set(key: string, data: unknown) {
        let item: CacheItem<unknown> = {
            data: data,
            cacheDate: moment().format()
        };
        this.inMemoryCache.set(this.getFullCacheKey(key), item);
    }
    get<T>(key: string, includeStale: boolean = false) {
        let value = this.inMemoryCache.get(this.getFullCacheKey(key));

        if (this.valueCheckerService.isNullOrUndefined(value)) {
            return null;
        }

        if (includeStale) {
            return <T>value.data;
        }

        let isStale = this.processStale(this.getFullCacheKey(key), value);

        if (isStale) {
            return null;
        }

        return <T>value.data;
    }

    clear(key: string) {
        this.inMemoryCache.delete(this.getFullCacheKey(key));
    }

    processStale(key: string, cacheItem: CacheItem<unknown>) {
        if (moment().diff(moment(cacheItem.cacheDate), 'seconds') > this.cacheDuration) {
            this.clear(this.getFullCacheKey(key));

            return true;
        }

        return false;
    }

    private getFullCacheKey(key: string) {
        return this.cacheKeyPrefix + key;
    }
}
