import { ExecutionResult } from "graphql";
import { LocatedInDto } from "../../components/managelocations/managelocationsdto";

export let manageLocationsResponce: ExecutionResult<LocatedInDto[]> = {
    data: [
            {
                locatedIn_ID: 1,
                locatedIn_Name: "Altona Gate Shopping Centre",
                addressLine1: '134 Millers Rd',
                addressLine2: 'NULL',
                addressSuburb: 'Altona North',
                addressStateCd: 'VIC',
                addressPostcode: '3025',
                addressCountryCd: 'AUS',
                latitude: -37.827870,
                longitude: 144.847510,
                geoCodeGranularity: 'STREET_ADDRESS',
                mapable: true,
                streetViewAvailable: false,
                formattedAddressString: '134 Millers Rd, Altona North VIC 3025',
                latLonPrecision: 8,
                centreURL: 'http://www.altonagate.com.au',
                isTrainStation: false,
                isAirport: false,
                isHospital: false,
                isUni: false,
                isShoppingCentre: true,
                isStadium: false,
                googlePlaceID: 'ChIJ21GqsOFg1moRsRE1NBEYeiM',
                openingTimes: '{\"sunday\":{\"status\":\"open\",\"times\":[{\"open\":\"10:00\",\"close\":\"17:00\"}]},\"tuesday\":{\"status\":\"open\",\"times\":[{\"open\":\"09:00\",\"close\":\"17:30\"}]},\"wednesday\":{\"status\":\"open\",\"times\":[{\"open\":\"09:00\",\"close\":\"17:30\"}]},\"monday\":{\"status\":\"open\",\"times\":[{\"open\":\"09:00\",\"close\":\"17:30\"}]},\"friday\":{\"status\":\"open\",\"times\":[{\"open\":\"09:00\",\"close\":\"21:00\"}]},\"thursday\":{\"status\":\"open\",\"times\":[{\"open\":\"09:00\",\"close\":\"21:00\"}]},\"saturday\":{\"status\":\"open\",\"times\":[{\"open\":\"09:00\",\"close\":\"17:00\"}]}}',
                formatted_phone_number: '(03) 9316 2222',
            },
            {
                locatedIn_ID: 3,
                locatedIn_Name: "Spencer Outlet Centre",
                addressLine1: '201 Spencer St',
                addressLine2: 'NULL',
                addressSuburb: 'North Lakes',
                addressStateCd: 'NSW',
                addressPostcode: '4509',
                addressCountryCd: 'AUS',
                latitude: -37.816219,
                longitude: 144.951994,
                geoCodeGranularity: 'STREET_ADDRESS',
                mapable: true,
                streetViewAvailable: true,
                formattedAddressString: '201 Spencer St, Docklands VIC 3008',
                latLonPrecision: 8,
                centreURL: 'http://spenceroutletcentre.com.au',
                isTrainStation: false,
                isAirport: false,
                isHospital: false,
                isUni: false,
                isShoppingCentre: true,
                isStadium: false,
                googlePlaceID: 'ChIJHyAtFk9d1moRAqBQ86vRKPU',
                openingTimes: '{"sunday":{"status":"open","times":[{"open":"10:00","close":"16:00"}]},"tuesday":{"status":"open","times":[{"open":"09:00","close":"17:30"}]},"wednesday":{"status":"open","times":[{"open":"09:00","close":"17:30"}]},"monday":{"status":"open","times":[{"open":"09:00","close":"17:30"}]},"friday":{"status":"open","times":[{"open":"09:00","close":"17:30"}]},"thursday":{"status":"open","times":[{"open":"09:00","close":"17:30"}]},"saturday":{"status":"open","times":[{"open":"09:00","close":"17:30"}]}}',
                formatted_phone_number: '(03) 8689 7577',
            },
            {
                locatedIn_ID: 6,
                locatedIn_Name: "Westpoint Blacktown",
                addressLine1: '17 Patrick St',
                addressLine2: 'NULL',
                addressSuburb: 'Rosny Park',
                addressStateCd: 'TAS',
                addressPostcode: '7000',
                addressCountryCd: 'AUS',
                latitude: -33.770352,
                longitude: 150.905943,
                geoCodeGranularity: 'STREET_ADDRESS',
                mapable: true,
                streetViewAvailable: false,
                formattedAddressString: null,
                latLonPrecision: 8,
                centreURL: 'https://www.westpoint.com.au/',
                isTrainStation: false,
                isAirport: false,
                isHospital: false,
                isUni: false,
                isShoppingCentre: true,
                isStadium: false,
                googlePlaceID: 'ChIJuc-7HIV1bqoRyqHLtsF7KfQ',
                openingTimes: '{"sunday":{"status":"24Hours","times":null},"tuesday":{"status":"24Hours","times":null},"wednesday":{"status":"24Hours","times":null},"monday":{"status":"24Hours","times":null},"friday":{"status":"24Hours","times":null},"thursday":{"status":"24Hours","times":null},"saturday":{"status":"24Hours","times":null}}',
                formatted_phone_number: '(03) 6231 6665',
            }
        ]
};

export let manageLocationEditResponce: ExecutionResult<unknown> = { data: { upsertManageLocation: {} } };
