import { ExecutionResult } from 'graphql';
import { TransactionBank } from '../../models/ausindexingresult';

export let transactionBankListResponse: ExecutionResult<TransactionBank[]> = {
  data: [
    {
      id: 0,
      value: "Unknown",
      name: "Unknown"
    },
    {
      id: 57,
      value: "ODF",
      name: "ODF(1derful)"
    },
    {
      id: 54,
      value: "E64",
      name: "E64(86400)"
    },
    {
      id: 2,
      value: "ANZ",
      name: "ANZ(ANZ Bank)"
    },
    {
      id: 48,
      value: "ARC",
      name: "ARC(Archa)"
    },
    {
      id: 9,
      value: "BOQ",
      name: "BOQ(Bank of Queensland)"
    },
    {
      id: 6,
      value: "BWA",
      name: "BWA(Bank West)"
    },
    {
      id: 3,
      value: "CBA",
      name: "CBA(Common Wealth Bank)"
    },
    {
      id: 14,
      value: "CIT",
      name: "CIT(Citibank)"
    },
    {
      id: 11,
      value: "CUA",
      name: "CUA(Credit Union Australia)"
    },
    {
      id: 13,
      value: "CUS",
      name: "CUS(Cuscal)"
    },
    {
      id: 8,
      value: "DAT",
      name: "DAT(DataAction)"
    },
    {
      id: 16,
      value: "DIV",
      name: "DIV(Divpay)"
    },
    {
      id: 17,
      value: "DOU",
      name: "DOU(Douugh)"
    },
    {
      id: 23,
      value: "FLX",
      name: "FLX(Flexigroup)"
    },
    {
      id: 24,
      value: "GFU",
      name: "GFU(Getfu)"
    },
    {
      id: 10,
      value: "HAY",
      name: "HAY(Haybank)"
    },
    {
      id: 25,
      value: "HSB",
      name: "HSB(HSBC)"
    },
    {
      id: 26,
      value: "IMP",
      name: "IMP(ImpactPay)"
    },
    {
      id: 28,
      value: "ING",
      name: "ING(ING)"
    },
    {
      id: 30,
      value: "LAT",
      name: "LAT(Latitude)"
    },
    {
      id: 31,
      value: "MQG",
      name: "MQG(Macquarie Bank)"
    },
    {
      id: 33,
      value: "MEB",
      name: "MEB(MeBank)"
    },
    {
      id: 5,
      value: "MIL",
      name: "MIL(Military Bank)"
    },
    {
      id: 1,
      value: "NAB",
      name: "NAB(NAB)"
    },
    {
      id: 34,
      value: "NWP",
      name: "NWP(Newcastle Permanent)"
    },
    {
      id: 39,
      value: "QAN",
      name: "QAN(Qantas)"
    },
    {
      id: 41,
      value: "SPR",
      name: "SPR(Spriggy)"
    },
    {
      id: 42,
      value: "SUN",
      name: "SUN(Suncorp)"
    },
    {
      id: 52,
      value: "VIR",
      name: "VIR(Virgin)"
    },
    {
      id: 4,
      value: "WBC",
      name: "WBC(Westpac)"
    },
    {
      id: 50,
      value: "XIN",
      name: "XIN(Xinja)"
    },
    {
      id: 75,
      value: "I0X",
      name: "I0X(10x Banking)"
    }
  ]
};
