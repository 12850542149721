import { Observable } from 'rxjs';
import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { updateRetailerWithLwcIdResponse, westfieldRetailersResponse } from '../mockjsonresponses/westfieldretailer.response';

export function westfieldRetailerBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesFetchRetailers = false;
  if (request.body && request.body.query) {
    includesFetchRetailers = request.body.query.includes('fetchRetailers');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesFetchRetailers) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...westfieldRetailersResponse }
      }));
      resp.complete();
    });
  }

  let includesUpdateRetailerWithLwcId = false;
  if (request.body && request.body.query) {
    includesUpdateRetailerWithLwcId = request.body.query.includes('updateRetailerWithLwcId');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesUpdateRetailerWithLwcId) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...updateRetailerWithLwcIdResponse }
      }));
      resp.complete();
    });
  }
}
