import { OpeningHours } from "../../models/searchmodelsv4";

export let merchantOpeningHoursResponse: OpeningHours = {
    is_always_open: true,
    monday: {
        status: 'closed',
    },
    tuesday: {
        status: 'byAppointment',
    },
    wednesday: {
        status: '24hours',
    },
    thursday: {
        status: '24hours',
    },
    friday: {
        status: '24hours',
        times: [
            {
                open: "11:30",
                close: "15:00"
            },
            {
                open: "8:00",
                close: "19:00"
            }
        ]
    },
    saturday: {
        status: '24hours',
        times: [
            {
                open: "9:30",
                close: "16:00"
            }
        ]
    }
}
