import { HttpMethod } from './../../enums/enums';
import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { itemsResponse, allocateItemsToIndexerResponse, groupOfSimilarCalsResponse, numberOfSearchesResponse } from '../mockjsonresponses/cardacceptorlocator.response';

export function cardAcceptorLocatorBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesDataCals = false;
  if (request.body && request.body.query) {
    includesDataCals = request.body.query.includes("order: {numberOfSearches: DESC, createDateTime: DESC}");
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesDataCals) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...itemsResponse }
      }));
      resp.complete();
    })
  };

  let includesAllocateCalsToIndexer = false;
  if (request.body && request.body.query) {
    includesAllocateCalsToIndexer = request.body.query.includes("allocateCalsToIndexer(indexId");
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesAllocateCalsToIndexer) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...allocateItemsToIndexerResponse }
      }));
      resp.complete();
    })
  }

  let includesGroupCals = false;
  if (request.body && request.body.query) {
    includesGroupCals = request.body.query.includes("groupCals(transactionDescription");
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesGroupCals) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...groupOfSimilarCalsResponse }
      }));
      resp.complete();
    })
  }

  let includesNumberOfSearches = false;
  if (request.body && request.body.query) {
    includesNumberOfSearches = request.body.query.includes("numberOfSearches");
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesNumberOfSearches) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...numberOfSearchesResponse }
      }));
      resp.complete();
    });
  }
}
