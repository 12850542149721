import { slash } from './../core/constants/constants';
import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiClient } from './../core/api/apiclient';
import { ApiService } from './../core/api/api.service';
import { Injectable } from '@angular/core';
import { ExternalCategory } from '../components/manageexternalcategories/manageexternalcategories.models';

@Injectable({
  providedIn: "root"
})
export class ManageExternalCategoriesService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) {}

  getAllCategoriesDataSources() {
    return this.apiClient.get<string[]>(this.apiService.buildUrl(apiPathConstants.allCategoryDataSources));
  }

  getExternalCategoriesTree(categoryName: string) {
    return this.apiClient.get<ExternalCategory[]>(this.apiService.buildUrl(apiPathConstants.getExternalCategoriesTree) + slash + categoryName);
  }

  updateExternalCategories(externalCategory: Partial<ExternalCategory>) {
    return this.apiClient.post<ExternalCategory[]>(this.apiService.buildUrl(apiPathConstants.updateExternalCategories), externalCategory);
  }

  deleteExternalCategories(id: number) {
    return this.apiClient.delete<ExternalCategory[]>(this.apiService.buildUrl(apiPathConstants.deleteExternalCategories + slash + id.toString()));
  }
}
