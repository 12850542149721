import { OverallResult } from "../../enums/enums";

export let updateMerchantChainLogoResponse = {
  BuildMerchantRecordOutcome: OverallResult.Success,
  DataRichnessScore: 2.0,
  ExecutionTimeInSeconds: 12,
  CircularLogoUpload: {
    Comments: 'Circular Logo Upload',
    Outcome: true,
    TaskExecuted: true,
  },
  SquareLogoUpload: {
    Comments: 'Square Logo Upload',
    Outcome: true,
    TaskExecuted: true,
  },
  ThreeToOneLogoUpload: {
    Comments: 'Three To One Logo Upload',
    Outcome: false,
    TaskExecuted: true,
  },
  DarkCircularLogoUpload: {
    Comments: 'Dark Circular Logo Upload',
    Outcome: false,
    TaskExecuted: true,
  },
  DarkSquareLogoUpload: {
    Comments: 'Dark Square Logo Upload',
    Outcome: false,
    TaskExecuted: true,
  },
  DarkThreeToOneLogoUpload: {
    Comments: 'Dark Three To One Logo Upload',
    Outcome: true,
    TaskExecuted: true,
  }
}
