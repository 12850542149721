import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginStateService } from './loginstate.service';
import { RoleType } from '../../users/userenums';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private loginStateService: LoginStateService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    let roles = route.data.roles as RoleType[];

    if (this.loginStateService.isInAnyRole(roles)) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
