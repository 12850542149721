import { CategoryMappingRule, ExternalCategory, GetCategoryByMappingRuleResponse } from "../../components/categorymappingrules/categorymapping.models";
import { GetCategoryByMappingMatchMethod } from "../../enums/enums";

export let categoryDataSourcesResponse: string[] = [
  "AMEX",
  "ClearbitSubIndustry",
  "ClearbitIndustry",
  "ClearbitIndustryGroup",
  "ClearbitSector",
  "FacebookPlace",
  "FourSquare",
  "GooglePlaces",
  "MastercardIndustry",
  "MastercardSuperIndustry",
  "MerchantCategoryCode",
  "Sensis",
  "ZomatoCuisine",
  "ZomatoEstablishmentTypes",
  "HEM",
  "ANZSIC",
  "Apple",
  "ANZDigitalDrop2",
  "ANZx",
  "ANZDigitalForSearch",
  "Here",
  "TomTom"
];

export let externalCategoriesResponse: ExternalCategory[] = [
  {
    CategoryDataSource: "NotificationTest",
    CategoryId: "1",
    CategoryName: "Parent Category 1",
    FullCategoryPath: "Parent Category 1",
    ID: 10490,
    ParentId: null
  },
  {
    CategoryDataSource: "NotificationTest",
    CategoryId: "1001",
    CategoryName: "Sub category 1",
    FullCategoryPath: "Parent Category 1 >> Sub Category 1",
    ID: 10491,
    ParentId: "1"
  },
  {
    CategoryDataSource: "NotificationTest",
    CategoryId: "1002",
    CategoryName: "Sub category 2",
    FullCategoryPath: "Parent Category 1 >> Sub Category 2",
    ID: 10492,
    ParentId: "1"
  },
  {
    CategoryDataSource: "NotificationTest",
    CategoryId: "1003",
    CategoryName: "Sub category 3",
    FullCategoryPath: "Parent Category 1 >> Sub category 3",
    ID: 10498,
    ParentId: "1"
  }
];

export let categoryMappingRulesResponse: CategoryMappingRule[] = [
  {
    Id: 1289,
    LwcCategoryId: 5000,
    IsRecurring: true,
    TagIds: [1000605190, 1000603090, 1001102450, 1000005190, 1000603040],
    MinAmountInclusive: 100,
    MaxAmountInclusive: 600,
    NewCategoryId: "5000",
    CategoryDataSource: "MerchantCategoryCode",
    OutputTagIds: [1000605190, 1000603090, 1001102450, 1000005190,],
  },
  {
    Id: 456,
    LwcCategoryId: 4000,
    IsRecurring: false,
    TagIds: [1000603010, 1001105450, 1000603040],
    MinAmountInclusive: 50,
    MaxAmountInclusive: 120,
    NewCategoryId: "1050",
    CategoryDataSource: "ClearbitSector",
    OutputTagIds: [1000603010, 1001105450, 1000603040],
  },
  {
    Id: 895,
    TagIds: [1000000190],
    NewCategoryId: "1030",
    CategoryDataSource: "ClearbitSector",
    OutputTagIds: [1000603010, 1001105450],
  },
  {
    Id: 1880,
    LwcCategoryId: 2999999,
    TagIds: [1456400190, 1000603090],
    MinAmountInclusive: 200,
    MaxAmountInclusive: 400,
    NewCategoryId: "1024001",
    CategoryDataSource: "ClearbitSector",
    OutputTagIds: [1456400190, 1000603090],
  },
  {
    Id: 1089,
    IsRecurring: false,
    TagIds: [1000603540],
    NewCategoryId: "4000",
    CategoryDataSource: "AMEX",
    OutputTagIds: [1000603540],
  },
  {
    Id: 1048,
    LwcCategoryId: 3000,
    IsRecurring: undefined,
    TagIds: [1000003640, 1001102450],
    NewCategoryId: "3000",
    CategoryDataSource: "GooglePlaces",
    OutputTagIds: [1000003640, 1001102450],
  },
  {
    Id: 1500,
    LwcCategoryId: 2999999,
    IsRecurring: undefined,
    TagIds: [1001102450],
    NewCategoryId: "1064800",
    CategoryDataSource: "FacebookPlace",
    OutputTagIds: [1001102450],
  },
  {
    Id: 456,
    LwcCategoryId: 5001,
    IsRecurring: false,
    TagIds: [1000603010, 1001105450, 1000603040],
    MinAmountInclusive: 50,
    MaxAmountInclusive: 120,
    NewCategoryId: "1004001",
    CategoryDataSource: "ClearbitSector",
    OutputTagIds: [1000603010, 1001105450, 1000603040],
  },
  {
    Id: 4856,
    LwcCategoryId: 6001,
    IsRecurring: undefined,
    TagIds: [1000003640, 1001102450],
    NewCategoryId: "1064801",
    CategoryDataSource: "MerchantCategoryCode",
    OutputTagIds: [1000003640, 1001102450],
  },
]

export let categoryByMappingRuleResponse: GetCategoryByMappingRuleResponse[] = [
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryMappingRule,
    ExternalCategoryId: "6001",
    RuleId: 1289
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryStandardiser,
    ExternalCategoryId: "1050",
    RuleId: 3256
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryMappingRule,
    ExternalCategoryId: "1050",
    RuleId: 1880
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryMappingRule,
    ExternalCategoryId: "1030",
    RuleId: 1089
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryMappingRule,
    ExternalCategoryId: "478",
    RuleId: 456
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryMappingRule,
    ExternalCategoryId: "4000",
    RuleId: 1341
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryMappingRule,
    ExternalCategoryId: "7000",
    RuleId: 1500
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryMappingRule,
    ExternalCategoryId: "4500",
    RuleId: 4856
  },
  {
    MatchMethod: GetCategoryByMappingMatchMethod.CategoryStandardiser,
    ExternalCategoryId: "100050542",
    RuleId: 198
  },
];
