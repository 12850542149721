import { Injectable } from '@angular/core';
import { ElasticSearchRequest, ElasticSearchResponse } from '../components/elastic-search/elastic-search';
import { ApiService } from '../core/api/api.service';
import { ApiClient } from '../core/api/apiclient';
import { apiPathConstants } from '../core/api/apipathconstants';

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {

  constructor(private apiClient: ApiClient, private apiService: ApiService) { }

  getCalsForElasticSearches(elasticSearchRequest: ElasticSearchRequest) {
    return this.apiClient.post<ElasticSearchResponse[]>(this.apiService.buildUrl(apiPathConstants.elasticSearch), elasticSearchRequest);
  }
}
