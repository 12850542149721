import { TransactionDescriptionSummary } from './../components/mergemerchants/merchantsummary';
import { Injectable } from '@angular/core';
import { Segment, SegmentConfig } from '../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/lwc-ngx-json-viewer.component';
import { MerchantSummary, MerchantSummaryFirstPart, MerchantSummarySecondPart } from '../components/mergemerchants/merchantsummary';
import { JsonViewerCustomComponent } from '../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewercustomcomponent';
import { JsonViewerIconComponent } from '../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewericon/jsonviewericon.component';
import { PaymentMethod } from '../models/calparserresult';
import { paymentMethodDisplayValues } from '../enums/enums';


@Injectable()
export class MerchantSummaryJsonViewerService {

  ignoredKeys = ['RecordLocked', 'IsQuarantined', 'SquareLogo', 'ThreeToOneLogo', 'CircularLogo',
    'DataCompletnessScore', 'SquareLogoDark', 'CircularLogoDark', 'ThreeToOneLogoDark', 'TransactionDescriptions'];

  getMerchantSummarySegmentConfigs() {
    let segmentConfigs: SegmentConfig[] = [];
    segmentConfigs.push({
      key: 'QuarantinedCALs',
      modifySegment: (segment: Segment, wholeObject: any) => {
        if (segment.key === 'QuarantinedCALs') {
          if (wholeObject['TransactionDescriptionSummaries'] && wholeObject['TransactionDescriptionSummaries'].length > 0) {
            let transactionDescriptionSummary: TransactionDescriptionSummary[] = wholeObject['TransactionDescriptionSummaries'];
            let listOfCals: string[] = transactionDescriptionSummary.filter(m => m.IsQuaratined === true).map(m => m.CAL);
            if (listOfCals && listOfCals.length > 0) {
              segment.value = listOfCals;
              segment.description = `Array[${listOfCals.length}] ${JSON.stringify(Object.values(listOfCals))}`;
              segment.type = 'array';
              segment.expanded = true;
            }
            else {
              segment.hide = true;
            }
          }
          else {
            segment.hide = true;
          }

          if (!wholeObject["IsQuarantined"]) {
            if (!segment.cssClasses) {
              segment.cssClasses = [];
            }
            if (!segment.cssClasses.includes('quarantined')) {
              segment.cssClasses.push('quarantined');
            }
          }
        }
      }
    },
      {
        key: 'BSBs',
        modifySegment: (segment: Segment, wholeObject: any) => {
          if (segment.key === 'BSBs') {
            if (wholeObject["MerchantType"] && wholeObject["MerchantType"] === "BankBranch") {
              segment.hide = false;
            }
            else {
              segment.hide = true;
            }
          }
        }
      },
      {
        key: 'TransactionDescriptionSummaries',
        modifySegment: (segment: Segment, wholeObject: any) => {
          if (wholeObject['TransactionDescriptionSummaries'] && wholeObject['TransactionDescriptionSummaries'].length > 0) {
            let transactionDescriptionSummary: TransactionDescriptionSummary[] = segment.value;
            segment.value = transactionDescriptionSummary = transactionDescriptionSummary.filter(m => m.IsQuaratined === false);
            segment.description = `Array[${transactionDescriptionSummary.length}] ${JSON.stringify(transactionDescriptionSummary.map(m => ({ CAL: m.CAL })))}`;
          }
          else {
            segment.hide = true;
          }
        }
      });
    return segmentConfigs;
  }

  getjsonViewerCustomComponents(): JsonViewerCustomComponent[] {
    return [{
      key: 'LWC_ID',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, object: any) => {
        if (object && object['RecordLocked'] === true) {
          c.hidden = false;
          c.tooltip = 'Locked';
          c.icon = 'lock';
        } else {
          c.hidden = true;
        }
      },
    },
    {
      key: 'LWC_ID',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, object: any) => {
        if (object && object['IsQuarantined'] === true) {
          c.hidden = false;
          c.tooltip = 'Quarantined';
          c.icon = 'block';
        } else {
          c.hidden = true;
        }
      }
    },
    {
      parentSegmentKey: 'TransactionDescriptionSummaries',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, segment: Segment) => {
        let transactionDescriptionSummary: TransactionDescriptionSummary = segment.value;
        if (transactionDescriptionSummary.TypeOfCAL === paymentMethodDisplayValues.get(PaymentMethod.ATM)) {
          segment.frontIcon = 'atm';
        } else if (transactionDescriptionSummary.TypeOfCAL === paymentMethodDisplayValues.get(PaymentMethod.Card)) {
          segment.frontIcon = 'credit_card';
        } else {
          segment.frontIcon = null;
        }

        if (!segment.frontIcon) {
          segment.frontImage = this.getImagePath(transactionDescriptionSummary.TypeOfCAL);
        }

        segment.frontImageIconTooltip = transactionDescriptionSummary.TypeOfCAL;
        segment.hasCustomComponent = true;
        segment.description = transactionDescriptionSummary.CAL;
        segment.expanded = true;
        c.hidden = true;
      }
    },
    {
      key: 'CAL',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, segment: Segment) => {
        segment.hasCustomComponent = true;
        c.hidden = false;
        c.tooltip = 'Search';
        c.icon = 'search';
        c.click.subscribe(() => {
          window.open('https://search.lookwhoscharging.com/Home/SearchForModel?SearchQuery=' + encodeURI(segment.value) + '&ResultRank=0', "searchTab");
        });
      }
    },
    {
      key: 'TypeOfCAL',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, segment: Segment) => {
        segment.hide = true;
      }
    },
    {
      key: 'IsQuaratined',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, segment: Segment) => {
        segment.hide = true;
      }
    },
    ];
  }

  getQuarantinedOrLockedCssClasses(merchant: MerchantSummary) {
    let currentClasses = {
      'locked': merchant.RecordLocked && !merchant.IsQuarantined,
      'quarantined': merchant.IsQuarantined
    }

    return currentClasses;
  }

  getStateMerchantLookupResponseFirstPart(merchantSummary: MerchantSummaryFirstPart) {
    let merchantLookupResponseFirstPart = {
      LWC_ID: merchantSummary.LWC_ID,
      QuarantinedCALs: merchantSummary.QuarantinedCALs,
      PrimaryName: merchantSummary.PrimaryName,
      PrimaryCategory: merchantSummary.PrimaryCategory,
      PrimaryAddress: merchantSummary.PrimaryAddress,
      ChainName: merchantSummary.ChainName,
      TransactionDescriptions: merchantSummary.TransactionDescriptions,
      LocatedIn: merchantSummary.LocatedIn,
      RecordLocked: merchantSummary.RecordLocked,
      IsQuarantined: merchantSummary.IsQuarantined,
      SquareLogo: merchantSummary.SquareLogo,
      CircularLogo: merchantSummary.CircularLogo,
      ThreeToOneLogo: merchantSummary.ThreeToOneLogo,
      SquareLogoDark: merchantSummary.SquareLogoDark,
      CircularLogoDark: merchantSummary.CircularLogoDark,
      ThreeToOneLogoDark: merchantSummary.ThreeToOneLogoDark,
      TransactionDescriptionSummaries: merchantSummary.TransactionDescriptionSummaries,
      CurrentBusinessStatus: merchantSummary.CurrentBusinessStatus
    };

    return merchantLookupResponseFirstPart
  }

  getStateMerchantLookupResponseSecondPart(merchantSummary: MerchantSummarySecondPart) {
    let merchantLookupResponseSecondPart = {
      MerchantType: merchantSummary.MerchantType,
      MerchantPresence: merchantSummary.MerchantPresence,
      PhoneNumber: merchantSummary.PhoneNumber,
      Email: merchantSummary.Email,
      Url: merchantSummary.Url,
      ABN: merchantSummary.ABN,
      NZBN: merchantSummary.LegalBusinessID1,
      DataCompletnessScore: merchantSummary.DataCompletnessScore,
      Tags: merchantSummary.Tags,
      BSBs: merchantSummary.BSBs
    };

    return merchantLookupResponseSecondPart;
  }

  private getImagePath(typeOfCal: string) {
    if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.AMEX)) {
      return 'assets/img/amex-type-of-cal.png';
    }
    else if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.BPay)) {
      return 'assets/img/bpay-type-of-cal.png';
    }
    else if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.DirectCredit)) {
      return 'assets/img/direct-credit-type-of-cal.png';
    }
    else if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.DirectDebit)) {
      return 'assets/img/direct-debit-type-of-cal.png';
    }
    else if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.Dividend)) {
      return 'assets/img/dividend-type-of-cal.png';
    }
    else if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.Salary)) {
      return 'assets/img/money-bag.png';
    }
    else if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.ATM)) {
      return 'assets/img/atm.png'
    }
    else if (typeOfCal === paymentMethodDisplayValues.get(PaymentMethod.Card)) {
      return 'assets/img/card.png'
    }

    return 'assets/img/placeholder-image-all-other-types.png';
  }

}
