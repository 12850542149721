import { Component, OnInit, Injector } from "@angular/core";
import { BaseStateComponent } from "../basestate/basestate.component";
import { BatchPastSearchesState } from "./batchpastsearches.state";
import { LoadingService } from "../../core/uiservices/loading.service";
import { finalize } from "rxjs/operators";
import { CsvComponentService } from "../../core/export/csvcomponent.service";
import { NotificationService } from "../../core/uiservices/notification.service";
import { BatchPastSearchesService } from "../../services/batchpastsearches.service";
import { MerchantBatchSearchRequest, ConcurrentRequest } from "../batchmerchantsearch/merchantbatchsearchrequest";
import { BatchRequestService, BatchRequestStatus } from "../../services/batchsearchrequest.service";
import { APISearchResultV4 } from "../../models/searchmodelsv4";
import { PastSearchesRequest } from "./batchpastsearches.request";
import { PastSearchesByBatchResponse } from "./batchpastsearches.response";
import { BankAccountTransactionType } from "../../models/ausindexingresult";
import { BankAccountTransactionTypeService } from "../../services/bankaccounttransactiontype.service";
import { StringService } from "../../core/formatting/string.service";
import { LoginStateService } from "../../core/auth/loginstate.service";
import { RoleType } from "../../users/userenums";
import { Router } from "@angular/router";

@Component({
    selector: 'app-batchpastsearches.component.ts',
    templateUrl: './batchpastsearches.component.html',
    styleUrls: ['./batchpastsearches.component.scss']
})

export class BatchPastSearchesComponent extends BaseStateComponent<BatchPastSearchesState> implements OnInit {
    bankAccountTransactionTypes: BankAccountTransactionType[] = [];
    isExport: boolean;

    constructor(injector: Injector, private loadingService: LoadingService, private batchPastSearchesService: BatchPastSearchesService,
        private csvComponentService: CsvComponentService, private notificationService: NotificationService, private stringService: StringService,
        private batchRequestService: BatchRequestService, private bankAccountTransactionTypeService: BankAccountTransactionTypeService,
        private loginStateService: LoginStateService, private router: Router) {
        super(injector)
        this.isExport = false;
    }

    searchWasPerfomed = false;
    failedRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    private batchSizeProvider = (r: MerchantBatchSearchRequest) => r.bank_transactions.length;
    batchSearchModels: MerchantBatchSearchRequest[] = [];
    batchRequestStatus: BatchRequestStatus<APISearchResultV4[]> = {
        areMerchantsLoading: false,
        searchInProgressCount: 0,
        searchCompletedCount: 0,
        searchFailedCount: 0,
        batchSearhResults: []
    }

    pastSearchGraphFor = [
        { key: 0, value: '0 Days' },
        { key: 30, value: '30 Days' },
        { key: 90, value: '90 Days' }
    ]

    ngOnInit() {
        this.state = {
            cals: '',
            displayData: [],
            getSearchCountInDays: 0,
            bankAccountTransactionType: 'Card'
        };
        this.restoreState();
        this.setTransactionTypes();
        this.getApplicationUsers();
    }

    searchButtonDisabled(): boolean {
        return this.state.cals.length == 0;
    }

    search() {
        this.loadingService.setLoading();

        let cals: string[] = this.state.cals.split('\n');
        if (cals.length > 5000) {
            cals = cals.slice(0, 5000);
        }
        let pastSearchesRequest: PastSearchesRequest = {
            Cals: cals,
            GetSearchCountInDays: this.state.getSearchCountInDays,
            BankAccountTransactionType: this.state.bankAccountTransactionType
        }
        this.batchPastSearchesService.getCalsForBatchPastSearches(pastSearchesRequest)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe((response) => {
                if (response && response.length > 0) {
                    this.state.displayData = response;
                    this.notificationService.notifySuccess();
                } else {
                    this.state.displayData = [];
                    this.notificationService.notify('No results found');
                }
            });
    }

    getCsvOptions() {
        let obj = this.state.displayData[0];
        obj = { ...obj };
        delete obj.SearchCountByDay;
        let options = this.csvComponentService.getCsvOptions(obj, 'BatchPastSearches.csv');
        return options;
    }

    prepareCalsForRequest(merchants: PastSearchesByBatchResponse[]) {
        let cals = [];
        for (let merch of merchants) {
            cals.push(merch.Cal);
        }
        return this.batchRequestService.prepareCalsForRequest(cals);
    }

    batchSearch() {
        this.searchWasPerfomed = true;
        this.batchSearchModels = this.prepareCalsForRequest(this.state.displayData);
        this.batchRequestStatus.areMerchantsLoading = true;
        this.batchRequestStatus.searchInProgressCount = 0;
        this.batchRequestStatus.searchCompletedCount = 0;
        this.batchRequestStatus.searchFailedCount = 0;

        let retryRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
        let successRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];

        this.batchRequestService.doBatchRequest<MerchantBatchSearchRequest, APISearchResultV4[]>(this.batchSearchModels, this.batchSearchModels,
            this.batchSearchModels, retryRequests, successRequests, this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
            .subscribe((batchRequetStatus) => {
                if (this.batchRequestStatus.searchInProgressCount === 0) {
                    this.proccesBatchSearchResults(batchRequetStatus);
                }
            });
    }

    proccesBatchSearchResults(batchREquestStatus: BatchRequestStatus<APISearchResultV4[]>) {
        let data, lwcId, tradingName, category, result_code;
        this.batchRequestStatus = batchREquestStatus;
        for (let result of this.batchRequestStatus.batchSearhResults) {
            data = result.response;
        }

        for (let i of data) {
            result_code = i.result_code;
            if (i.merchant_search_results) {
                for (let k of i.merchant_search_results) {
                    lwcId = k.LWC_ID;
                    tradingName = k.merchant_details.merchant_primary_name;
                    category = k.merchant_details.primary_category.category_name;
                }
            }
        }

        for (let j of this.state.displayData) {
            j.lwcId = lwcId;
            j.tradingName = tradingName;
            j.category = category;
            j.result_code = result_code;
        }
    }

    private setTransactionTypes() {
        this.loadingService.setLoading();
        this.bankAccountTransactionTypeService.getBankAccountTransactionTypes()
            .subscribe(response => {
                this.loadingService.clearLoading();
                if (response && response.data && response.data.length > 0) {
                    this.bankAccountTransactionTypes = response.data;
                    this.bankAccountTransactionTypes.sort((a, b) => {
                        return this.stringService.sortAlphabetically(a.name, b.name);
                    });
                }
            });
    }

    getApplicationUsers() {
        let user = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin, RoleType.IndexManagerGlobalAdministrator, RoleType.IndexManagerTeamLead]);
        if (user === true) {
            this.isExport = true;
        } else {
            this.isExport = false;
        }
    }

    goToEnrichMerchant(LwcId: string) {
        this.router.navigate(['/enrichmerchant', { lwcId: LwcId, cleanState: true }]);
    }
}
