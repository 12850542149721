import { hotChocolate, eventing } from './gqlconstants/gqlconstants';
import { NgModule } from '@angular/core';
import {InMemoryCache, DefaultOptions} from '@apollo/client/core';
import { HttpLink} from 'apollo-angular/http';
import { Apollo} from 'apollo-angular';
import { ApiService } from '../app/core/api/api.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  exports: [HttpClientModule],
})
export class GraphQLModule {

  constructor(apollo: Apollo, httpLink: HttpLink, apiService: ApiService) {

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache'
      }
    };

    const options1: any = { uri:  apiService.getGraphQlBaseUrl() };
    apollo.createDefault({
      link: httpLink.create(options1),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions: defaultOptions
    });

    let eventingGraphql = apiService.getApiBaseUrl() + '/eventing/graphql';
    const eventingOptions: any = { uri: eventingGraphql };
    apollo.createNamed(eventing, {
      link: httpLink.create(eventingOptions),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions: defaultOptions
    });

    let hotChocolateGraphQl = apiService.getApiBaseUrl() + '/hotchocolate/graphql';
    const hotChocolateOptions: any = { uri: hotChocolateGraphQl };
    apollo.createNamed(hotChocolate, {
      link: httpLink.create(hotChocolateOptions),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions: defaultOptions
    });
  }
}
