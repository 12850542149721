import { Merchant_Details_DocumentV4, ATM_Indexed_DocumentV4, BankMessage_Indexed_DocumentV4, CategoryParent, CategoryIcon, ReCategorisationInfo } from "../../models/searchmodelsv4";
import { MoneyTrackerSearchTelemetry } from "./moneytrackersearchtelemetry";

export interface MoneyTrackerV4BankTransaction {
    customer_correlation_id?: string;
    account_correlation_id?: string;
    date_time?: string;
    transaction_desc?: string;
    amount?: number;
    lwc_id?: number;
    category_id?: string;
    result_code?: number;
    lwc_guid?: string;
    chain_name?: string;
    category_full_path?: string;
    merchant_name?: string;
    merchant_category?: string;
    transaction_correlation_guid?: string;
    recurrence_frequency?: string;
}

export interface MoneyTrackerV4ResponseInternal {
    enrichment_statistics: EnrichmentV4StatisticsInternal;
    enriched_transactions: MoneyTrackerV4TransactionLookupResultInternal[];
    transaction_aggregations: TransactionAggregationV4[];
    merchant_details: Merchant_Details_DocumentV4[];
    atm_details: ATM_Indexed_DocumentV4[];
    bank_fee_details: BankMessage_Indexed_DocumentV4[];
    category_details: MoneyTrackerCategory[];
    search_telemetry?: MoneyTrackerSearchTelemetry;
}

export interface MoneyTrackerV4TransactionLookupResultInternal {
    match_source: string;
    search_results_merchant_type?: SearchResultMerchantType;
    merchant_chain_name?: string;
    is_chain?: boolean;
    merchant_chain_lwc_guid?: string;
    fixed_cal: string;
    categorised_transactions?: CategorisedTransaction[];
    bank_fee_lwc_guid?: string;
    atm_lwc_guid?: string;
    transaction_correlation_guid: string;
    merchant_lwc_guid?: string;
    recurrence_frequency?: string;
    customer_correlation_id?: string;
    account_correlation_id?: string;
    categorised_transactions_internal: CategorisedTransactionInternal[];
    result_code: number;
    search_results_guid?: string;
    tid?: string;
    mid?: string;
    search_guid: string;
    transaction_desc: string;
    amount: number;
    date_time: string;
    transaction_type: string;
    score: number;
}

export interface CategorisedTransactionInternal {
    original_category_id?: number;
    category_id?: string;
    amount: number;
    customer_category?: string;
}

export interface MoneyTrackerCategory {
    category_id?: string;
    full_category_path: string;
    category_type?: LWCCategoryType;
    customer_category?: string;
    category_name: string;
    parent?: CategoryParent;
    is_sensitive?: boolean;
    is_lwc_category: boolean;
    is_substituted_category?: boolean;
    lwc_category_icon: CategoryIcon;
    lwc_category_icon_circular: CategoryIcon;
    category_emoji?: string;
    recategorisation_info?: ReCategorisationInfo;
}

export interface EnrichmentV4StatisticsInternal {
    search_telemetry?: string;
    number_of_transactions:number;
    matched_transactions: number;
    ignored_transactions?: number;
    search_time_ms: number;
}

export enum SearchResultMerchantType {
    AusMerchant = 0,
    InternationalMerchant = 1,
    ATM = 2,
    BankMessage = 3
}

export interface CategorisedTransaction {
    lwc_category_id?: number;
    amount: number;
    customer_category: string;
}

export interface TransactionAggregationV4 {
    period?: AggregationPeriod;
    period_start_date?: string;
    period_end_date?: string;
    percentage?: number;
    amount?: number;
    category_aggregations?: CategoryLevelAggregation[];
    merchant_chain_aggregations?: MerchantAggregation[];
    transaction_correlation_guids?: string[];
}

export enum AggregationPeriod {
    Week = 1,
    Month = 2,
    Quarter = 3,
    CalendarYear = 4,
    FiscalYear = 5,
    AllTime = 6
}

export interface CategoryLevelAggregation {
    percentage: number;
    amount: number;
    lwc_category_id: number;
    customer_category: string;
    transaction_correlation_ids: string[];
    category_aggregations: CategoryLevelAggregation[];
}

export interface MerchantAggregation {
    percentage: number;
    amount: number;
    merchant_lwc_guid?: string;
    atm_lwc_guid?: string;
    bank_fee_lwc_guid?: string;
    is_chain: boolean;
    transaction_correlation_guids: string[];
    merchant_aggregations: MerchantAggregation[];
}

export enum LWCCategoryType {
    Expense = 0,
    Income = 1,
    OtherExpense = 2,
    OtherIncome = 3
}