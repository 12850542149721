import { Injectable } from "@angular/core";
import { IndexingAuditResponse } from "../components/scrapingdatasources/scrapingdatadto";
import { apiPathConstants } from "../core/api/apipathconstants";
import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";

@Injectable({
  providedIn: 'root'
})
export class IndexingAuditService {

  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  getIndexingAudit(itemId: number) {
    return this.apiClient.get<IndexingAuditResponse[]>(this.apiService.buildApiUrlWithParameters(apiPathConstants.indexingAudit, { name: 'itemId', value: itemId.toString() }));
  }
}

