import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ChainAudit } from "../components/managechains/chainaudit/chainauditdto";
import { chainAuditQuery, hotChocolate } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: 'root'
})
export class ChainAuditService {
  constructor(private apollo: Apollo) {
  }

  getChainAudit(chainId: number) {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<ChainAudit[]>({
        query: chainAuditQuery(chainId),
      }).valueChanges;
  }
}
