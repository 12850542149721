import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMerchantResponse} from '../mockjsonresponses/mergemerchantresponse';

export function mergeMerchantsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes('/merchant/merge') && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: mergeMerchantResponse
      }));
      resp.complete();
    });
  }
}
