import { propertyOf } from '../../core/services/reflection.service';

export interface Tidmid {
    TIDMID_id: number;
    LWC_ID: number;
    MID: string;
    TID: string;
    CreateDateTime: string;
    Source: string;
    Comments: string;
    UpdateDateTime: string;
}

export const tidmidProps = {
    TIDMID_id: propertyOf<Tidmid>('TIDMID_id'),
    LWC_ID: propertyOf<Tidmid>('LWC_ID'),
    MID: propertyOf<Tidmid>('MID'),
    TID: propertyOf<Tidmid>('TID'),
    Comments: propertyOf<Tidmid>('Comments')
};
