import { Injectable } from '@angular/core';
import { OptionEntry, DataSource } from '@oasisdigital/angular-material-search-select';
import { of, Observable } from 'rxjs';

@Injectable()
export class SearchSelectService {
  getDataSource<T>(items: T[], filterFn: (item: T, term: string) => boolean, mapFn: (item: T) => OptionEntry): DataSource {
    const dataSource: DataSource = {
      displayValue(): Observable<OptionEntry | null> {
        return of();
      },

      search(term: string): Observable<OptionEntry[]> {
        const lowerTerm = typeof term === 'string' ? term.toLowerCase() : '';

        const result = items
          .filter((item) => filterFn(item, lowerTerm))
          .slice(0, 200)
          .map(mapFn);
        return of(result);
      }
    };

    return dataSource;
  }

  getEmptyDataSource(): DataSource {
    const dataSource: DataSource = {
      displayValue(): Observable<OptionEntry | null> {
        return of(null);
      },

      search(term: string): Observable<OptionEntry[]> {
        return of([]);
      }
    };

    return dataSource;
  }
}
