import { Injectable } from "@angular/core";
import { MenuItemType } from "../../../enums/enums";
import { MenuItem } from "./menuitem";
import { LoginStateService } from "../../../core/auth/loginstate.service";
import { RoleType } from "../../../users/userenums";

@Injectable()
export class MenuItemService {

  constructor(private loginStateService: LoginStateService) { }

  getMenuItems(): MenuItem[] {
    let isAdministratorOrTeamLead = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead]);
    let isAdministrator = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin]);
    return [
      {
        type: MenuItemType.Section,
        subItems: this.getLookupSubItems(),
      },
      {
        type: MenuItemType.Section,
        subItems: this.getMerchantSubItems(isAdministratorOrTeamLead),
      },
      {
        type: MenuItemType.Section,
        subItems: this.getAdministratorOrTeamLeadExpansionSubItems(),
        isHidden: !isAdministratorOrTeamLead
      },
      {
        type: MenuItemType.Section,
        subItems: this.getAdministratorExpansionSubItems(),
        isHidden: !isAdministrator
      },
      {
        type: MenuItemType.Section,
        subItems: this.getDataSyncItems(isAdministratorOrTeamLead),
      }
    ];
  }

  private getAdministratorExpansionSubItems(): MenuItem[] {
    return [
      {
        type: MenuItemType.ExpansionPanel,
        name: 'Administration',
        subItems: this.getAdministrationSubItems(),
        id: 'administration'
      }
    ]
  }

  private getAdministrationSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/manageusers',
        appHotkey: 'alt+g',
        name: 'Manage Users',
        displayName: 'Manage Users',
        type: MenuItemType.Item,
        id: 'manageUsers'
      },
      {
        routerLink: '/managetidmid',
        appHotkey: 'alt+d',
        name: 'Manage TIDMID',
        displayName: 'Manage TIDMID',
        type: MenuItemType.Item,
        isHidden: true
      }
    ]
  }

  private getDataSyncItems(isAdministratorOrTeamLead: boolean): MenuItem[] {
    return [
      {
        type: MenuItemType.ExpansionPanel,
        name: 'Data Sync',
        subItems: this.getDataSyncSubItems(),
        id: 'dataSync',
        isHidden: !isAdministratorOrTeamLead
      }
    ]
  }

  private getDataSyncSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/elasticsearchcalsrefresh',
        appHotkey: 'alt+e+l',
        name: 'ElasticSearch CALs refresh',
        displayName: 'ElasticSearch CALs refresh',
        type: MenuItemType.Item,
        id: 'ElasticSearch CALs refresh'
      },
      {
        routerLink: '/rediscachelookup',
        appHotkey: 'alt+5',
        name: 'Redis Cache Lookup',
        displayName: 'Redis Cache Lookup',
        type: MenuItemType.Item,
        id: 'rediscachelookup'
      },
      {
        routerLink: '/referencedatacacherefresh',
        appHotkey: 'alt+r+r',
        name: 'Reference Data Cache Refresh',
        displayName: 'Reference Data Cache Refresh',
        type: MenuItemType.Item,
        id: 'referencedatacacherefresh'
      }
    ]
  }

  private getAdministratorOrTeamLeadExpansionSubItems(): MenuItem[] {
    return [
      {
        type: MenuItemType.ExpansionPanel,
        name: 'Categories',
        subItems: this.getCategoriesSubItems(),
        id: 'categories'
      },
      {
        routerLink: '/categorymappingrules',
        appHotkey: 'alt+z+x',
        name: 'Category Mapping Rules',
        displayName: 'Category Mapping Rules',
        type: MenuItemType.Item,
        id: 'categoryMappingRules'

      },
      {
        routerLink: '/categorystandardiser',
        appHotkey: 'alt+m',
        name: 'Category Standardiser',
        displayName: 'Category Standardiser',
        type: MenuItemType.Item,
        id: 'categoryStandardiser'

      },
      // {
      //   type: MenuItemType.ExpansionPanel,
      //   name: 'Monitoring',
      //   subItems: this.getMonitoringSubItems(),
      //   id: 'monitoring'
      // },
      {
        type: MenuItemType.ExpansionPanel,
        name: 'CALs',
        subItems: this.getCALsSubItems(),
        id: 'cals'
      },
      {
        type: MenuItemType.ExpansionPanel,
        name: 'Chains',
        subItems: this.getChainsSubItems(),
        id: 'chains'
      },
      {
        routerLink: '/merchantextract',
        appHotkey: 'alt+y',
        name: 'Merchant Extract',
        displayName: 'Merchant Extract',
        type: MenuItemType.Item,
        id: 'merchantExtract'
      },
      /*{
        routerLink: '/merchantevents',
        appHotkey: 'alt+x',
        name: 'Merchant Events',
        displayName: 'Merchant Events',
        type: MenuItemType.Item,
        id: 'merchantEvents'
      }*/
      {
        routerLink: '/managewestfieldlocation',
        appHotkey: 'alt+o+p',
        name: "Manage Westfield Location",
        displayName: "Manage Westfield Location",
        type: MenuItemType.Item,
        id: 'managewestfieldlocation'
      },
      {
        routerLink: '/managewestfieldretailer',
        appHotkey: 'alt+v+1',
        name: 'Manage Westfield Retailer',
        displayName: 'Manage Westfield Retailer',
        type: MenuItemType.Item,
        id: 'managewestfieldretailer'
      },
      {
        routerLink: '/managelocations',
        appHotkey: 'alt+u',
        name: 'Manage Locations',
        displayName: 'Manage Locations',
        type: MenuItemType.Item,
        id: 'manageLocations'
      },
      // {
      //   type: MenuItemType.ExpansionPanel,
      //   name: 'Data Scraping',
      //   subItems: this.getDataScrapingSubItems(),
      //   id: 'managedatascraping',
      // },
      // {
      //   type: MenuItemType.ExpansionPanel,
      //   name: 'Subscriptions',
      //   subItems: this.getSubscriptionsSubItems(),
      //   id: 'subscriptions'
      // },
      {
        routerLink: '/managepersonnames',
        appHotkey: 'alt+0',
        name: 'Manage Person Names',
        displayName: 'Manage Person Names',
        type: MenuItemType.Item,
        id: 'manageNames',
        isHidden: false
      },
      {
        routerLink: '/merchantvalidationstats',
        appHotkey: 'alt+c+p',
        name: 'Merchant Validation Stats',
        displayName: 'Merchant Validation Stats',
        type: MenuItemType.Item,
        id: 'merchantvalidationstats',
      }
    ]
  }

  private getChainsSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/managechains',
        appHotkey: 'alt+f',
        name: 'Manage Chains',
        id: 'manageChains',
        displayName: 'Manage Chains',
        type: MenuItemType.Item
      },
      {
        routerLink: '/chainpatternmatcher',
        appHotkey: 'alt+7',
        name: 'Chain Pattern Matcher',
        id: 'chainPatternMatcher',
        displayName: 'Chain Pattern Matcher',
        type: MenuItemType.Item
      }
    ]
  }

  private getCALsSubItems(): MenuItem[] {
    return [
      // {
      //   routerLink: '/cardacceptorlocators',
      //   appHotkey: 'alt+r',
      //   name: 'Card Acceptor/Locators',
      //   displayName: 'Card Acceptor/Locators',
      //   type: MenuItemType.Item,
      //   id: 'cardAcceptorLocators'
      // },
      // {
      //   routerLink: '/calhistory',
      //   appHotkey: 'alt+l',
      //   name: 'CAL History',
      //   displayName: 'CAL History',
      //   type: MenuItemType.Item,
      //   id: 'calHistory'
      // },
      {
        routerLink: '/batchmerchantsearch',
        appHotkey: 'alt+b',
        name: 'Batch Merchant Search',
        displayName: 'Batch Merchant Search',
        type: MenuItemType.Item,
        id: 'batchMerchantSearch'
      },
      {
        routerLink: '/merchantnamesearch',
        appHotkey: 'alt+f+d',
        name: 'Merchant Name Search',
        displayName: 'Merchant Name Search',
        type: MenuItemType.Item,
        id: 'merchantnamesearch'
      },
      {
        routerLink: '/batchpastsearches',
        appHotkey: 'alt+8',
        name: 'Batch Past Searches',
        displayName: 'Batch Past Searches',
        type: MenuItemType.Item,
        id: 'batchpastsearches'
      }
    ]
  }

  private getMonitoringSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/missedcals',
        appHotkey: 'alt+u+7',
        name: 'Missed CALs',
        displayName: 'Missed CALs',
        type: MenuItemType.Item,
        id: 'missedCals'
      }
    ]
  }

  private getCategoriesSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/managecategories',
        appHotkey: 'alt+o',
        name: 'Manage Categories',
        displayName: 'Manage Categories',
        type: MenuItemType.Item,
        id: 'manageCategories'
      }
      //TO be enabled after full testing
      ,
      {
        routerLink: '/categoryallocationpatterns',
        appHotkey: 'alt+h',
        name: 'Category Allocation Patterns',
        displayName: 'Category Allocation Patterns',
        type: MenuItemType.Item
      }
    ]
  }

  private getLookupSubItems(): MenuItem[] {
    return [
      {
        type: MenuItemType.ExpansionPanel,
        name: 'Merchant Lookup',
        subItems: this.getMerchantLookupSubItems(),
        id: 'merchantLookup'
      },
      {
        routerLink: '/whoislookup',
        appHotkey: 'alt+w',
        name: 'Whois Lookup',
        displayName: 'Whois Lookup',
        type: MenuItemType.Item,
        id: 'whoIsLookup'
      },
      {
        routerLink: '/abnacnlookup',
        appHotkey: 'alt+a',
        name: 'ABN/ACN Lookup',
        displayName: 'ABN/ACN Lookup',
        type: MenuItemType.Item,
        id: 'ABNACNLookup'
      },
      {
        routerLink: '/mastercardlookup',
        appHotkey: 'alt+v',
        name: 'Mastercard Lookup',
        displayName: 'Mastercard Lookup',
        type: MenuItemType.Item,
        id: 'mastercardLookup'
      },
      // {
      //   type: MenuItemType.ExpansionPanel,
      //   name: 'Data Quality',
      //   subItems: this.getDataQualitySubItems(),
      //   id: 'dataquality'
      // },
      {
        type: MenuItemType.ExpansionPanel,
        name: 'Tags',
        subItems: this.getTagsSubItems(),
        id: 'tagsmenu'
      },
      {
        routerLink: '/calparser',
        appHotkey: 'alt+p+o',
        name: 'Cal Parser',
        displayName: 'Cal Parser',
        type: MenuItemType.Item,
        id: 'calparser'
      },
      {
        routerLink: '/bpaylookup',
        appHotkey: 'alt+b+n',
        name: 'BPay Lookup',
        displayName: 'BPay Lookup',
        type: MenuItemType.Item,
        id: 'bpaylookup'
      },
      {
        routerLink: '/manageexternalcategories',
        appHotkey: 'alt+p+l',
        name: 'Manage External Categories',
        displayName: 'Manage External Categories',
        type: MenuItemType.Item,
        id: 'manageexternalcategories'
      }
    ]
  }

  private getMerchantSubItems(isAdministratorOrTeamLead: boolean): MenuItem[] {
    return [
      {
        routerLink: '/indexmerchant',
        appHotkey: 'alt+i',
        name: 'Index Merchant',
        displayName: 'Index Merchant',
        type: MenuItemType.Item,
        id: 'indexMerchant'
      },
      {
        routerLink: '/enrichmerchant',
        appHotkey: 'alt+e',
        name: 'Enrich Merchant',
        displayName: 'Enrich Merchant',
        type: MenuItemType.Item,
        id: 'enrichMerchant'
      },
      {
        type: MenuItemType.ExpansionPanel,
        name: 'Merchant Logos',
        subItems: this.getMerchantLogosSubItems(),
        id: 'merchantLogos'
      },
      {
        routerLink: '/merchantvalidation',
        appHotkey: 'alt+q',
        name: 'Merchant Validation',
        displayName: 'Merchant Validation',
        type: MenuItemType.Item,
        id: 'merchantValidation'
      },
      {
        routerLink: '/purgemerchant',
        appHotkey: 'alt+p',
        name: 'Purge Merchant',
        displayName: 'Purge Merchant',
        type: MenuItemType.Item,
        id: 'purgeMerchant'
      },

      {
        routerLink: '/mergemerchants',
        appHotkey: 'alt+k',
        name: 'Merge Merchants',
        displayName: 'Merge Merchants',
        type: MenuItemType.Item,
        id: 'mergeMerchants',
      },
      {
        routerLink: '/quarantinedmerchants',
        appHotkey: 'alt+s',
        name: 'Quarantined Merchants',
        displayName: 'Quarantined Merchants',
        type: MenuItemType.Item,
        id: 'quarantinedMerchants',
      },
      {
        routerLink: '/quarantinedcals',
        appHotkey: 'alt+1',
        name: 'Quarantined CALs',
        displayName: 'Quarantined CALs',
        type: MenuItemType.Item,
        id: 'quarantinedCals',
      },
      {
        routerLink: '/calsplitter',
        appHotkey: 'alt+c',
        name: 'CAL Splitter',
        displayName: 'CAL Splitter',
        type: MenuItemType.Item,
        id: 'calSplitter'
      },
      {
        routerLink: '/batchcalsplitter',
        appHotkey: 'alt+c+d',
        name: 'Batch Cal Splitter',
        displayName: 'Batch Cal Splitter',
        type: MenuItemType.Item,
        id: 'batchcalsplitter'
      },
      {
        routerLink: '/pastsearches',
        appHotkey: 'alt+t',
        name: 'Past Searches',
        displayName: 'Past Searches',
        type: MenuItemType.Item,
        id: 'pastSearches'
      },
      {
        routerLink: '/namecatalogue',
        appHotkey: 'alt+n',
        name: 'Names Catalogue',
        displayName: 'Names Catalogue',
        type: MenuItemType.Item,
        id: 'namesCatalogue'
      },
      {
        routerLink: '/changecalallocations',
        appHotkey: 'alt+z',
        name: 'Change CAL Allocations',
        displayName: 'Change CAL Allocations',
        type: MenuItemType.Item,
        isHidden: true
      },
      {
        routerLink: '/moneytrackersearch',
        appHotkey: 'alt+9',
        name: 'Money Tracker Search',
        displayName: 'Money Tracker Search',
        type: MenuItemType.Item,
        id: 'moneyTrackerSearch',
        isHidden: !isAdministratorOrTeamLead
      },
      {
        name: 'API Clients',
        displayName: 'API Clients',
        type: MenuItemType.ExpansionPanel,
        id: 'apiClients',
        subItems: this.getClientSubItems(),
        isHidden: !isAdministratorOrTeamLead,
      },
      {
        routerLink: '/suggestions',
        appHotkey: 'alt+3',
        name: 'Suggestions',
        displayName: 'Suggestions',
        type: MenuItemType.Item,
        id: 'suggestions',
        isHidden: !isAdministratorOrTeamLead,
      },
      {
        routerLink: '/searchfeedback',
        appHotkey: 'alt+4',
        name: 'Search Feedback',
        displayName: 'Search Feedback',
        type: MenuItemType.Item,
        id: 'searchfeedback',
        isHidden: !isAdministratorOrTeamLead,
      },
      {
        routerLink: '/merchantchainlogos',
        appHotkey: 'alt+6',
        name: 'Merchant/Chain Logos',
        displayName: 'Merchant/Chain Logos',
        type: MenuItemType.Item,
        id: 'merchantchainlogos'
      },
      {
        routerLink: '/managemissingmerchants',
        appHotkey: 'alt+c+f',
        name: 'Manage Missing Merchants',
        displayName: 'Manage Missing Merchants',
        type: MenuItemType.Item,
        id: 'managemissingmerchant'
      },
      {
        routerLink: '/managebsbs',
        appHotkey: 'alt+c+c',
        name: 'Manage BSBs',
        displayName: 'Manage BSBs',
        type: MenuItemType.Item,
        id: 'managebsbs'
      }
    ]
  }

  private getDataScrapingSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/managedatascraping',
        appHotkey: 'alt+m+5',
        name: 'Manage Data Scraping',
        displayName: 'Manage Data Scraping',
        type: MenuItemType.Item,
        id: 'managedatascraping'
      },
      {
        routerLink: '/scrapingdatasources',
        appHotkey: 'alt+e+w',
        name: 'Data Sources',
        displayName: 'Data Sources',
        type: MenuItemType.Item,
        id: 'scrapingdatasources'
      }
    ]
  }

  private getMerchantLookupSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/merchantlookup',
        appHotkey: 'alt+m+8',
        name: 'Merchant Lookup',
        displayName: 'Merchant Lookup',
        type: MenuItemType.Item,
        id: 'merchantLookup2'
      },
      {
        routerLink: '/merchantopeninghours',
        appHotkey: 'alt+m+9',
        name: 'Merchant Opening Hours',
        displayName: 'Merchant Opening Hours',
        type: MenuItemType.Item,
        id: 'merchantopeninghours'
      },
    ]
  }

  private getDataQualitySubItems(): MenuItem[] {
    return [
      {
        routerLink: '/brokenlinks',
        appHotkey: 'alt+m+4',
        name: 'Broken Links',
        displayName: 'Broken Links',
        type: MenuItemType.Item,
        id: 'brokenlinks'
      },
      {
        routerLink: '/chainstores',
        appHotkey: 'alt+c+v',
        name: 'Chain Stores',
        displayName: 'Chain Stores',
        type: MenuItemType.Item,
        id: 'chainstores'
      },
      {
        routerLink: '/operationalchecks',
        appHotkey: 'alt+x+z',
        name: 'Operational Checks',
        displayName: 'Operational Checks',
        type: MenuItemType.Item,
        id: 'operationalchecks'
      },
      {
        routerLink: '/addresscheck',
        appHotkey: 'alt+d+f',
        name: 'Address Check',
        displayName: 'Address Check',
        type: MenuItemType.Item,
        id: 'addresscheck'
      }
    ]
  }

  private getMerchantLogosSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/merchantlogo',
        appHotkey: 'alt+r+e',
        name: 'Merchant Logo',
        displayName: 'Merchant Logo',
        type: MenuItemType.Item,
        id: 'merchantlogo'
      },
      {
        routerLink: '/bulklogoedit',
        appHotkey: 'alt+r+a',
        name: 'Bulk Logo Edit',
        displayName: 'Bulk Logo Edit',
        type: MenuItemType.Item,
        id: 'bulkLogo'
      },
    ]
  }

  private getSubscriptionsSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/subscriptionsbymerchant',
        appHotkey: 'alt+f+v',
        name: 'Subscriptions By Merchant',
        displayName: 'Subscriptions By Merchant',
        type: MenuItemType.Item,
        id: 'subscriptionsbymerchant'
      }
    ]
  }

  private getTagsSubItems(): MenuItem[] {
    return [
      {
        routerLink: '/tags',
        appHotkey: 'alt+e+d',
        name: 'Tags',
        displayName: 'Tags',
        type: MenuItemType.Item,
        id: 'tags'
      },
      {
        routerLink: '/tagallocationpatterns',
        appHotkey: 'alt+e+e',
        name: 'Tag Allocation Patterns',
        displayName: 'Tag Allocation Patterns',
        type: MenuItemType.Item,
        id: 'tagallocationpatterns'
      }
    ]
  }


  private getClientSubItems(): MenuItem[] {
    let isAdministratorOrTeamLead = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead]);
    return [
      {
        routerLink: '/manageclient',
        appHotkey: 'alt+m+3',
        name: 'Manage Client',
        displayName: 'Manage Client',
        type: MenuItemType.Item,
        id: 'manageClient'
      },
      {
        routerLink: '/manageapikeys',
        appHotkey: 'alt+c+k',
        name: 'Manage API Keys',
        displayName: 'Manage API Keys',
        type: MenuItemType.Item,
        id: 'manageapikeys'
      },
      {
        routerLink: '/manageapiusage',
        appHotkey: 'alt+c+r',
        name: 'Manage API Usage',
        displayName: 'Manage API Usage',
        type: MenuItemType.Item,
        id: 'manageapiusage'
      },
      {
        routerLink: '/upcomingexpiry',
        appHotkey: 'alt+u+e',
        name: 'Upcoming Expiry',
        displayName: 'Upcoming Expiry',
        type: MenuItemType.Item,
        id: 'upcomingexpiry'
      },
    ]
  }



}
