import { Injectable } from "@angular/core";
import { NameValue } from "../core/models/namevalue";
import { CategoryType } from "../enums/enums";
import { CategoryLevelNumber } from "./categorylevelnumber";
import { CategoryConfig } from "./categoryconfig";
import { FormControl } from "@angular/forms";
import { CategoryState } from "./categorystate";
import { FlatCategory } from "./flatcategory";

@Injectable()
export class CategoriesComponentService {
  categoryTypes: NameValue<CategoryType>[] = [{ name: 'Expense', value: CategoryType.Expense },
  { name: 'Income', value: CategoryType.Income }, { name: 'Other Expense', value: CategoryType.OtherExpense },
  { name: 'Other Income', value: CategoryType.OtherIncome }];

  getDefaultFlatCategoryConfig(): CategoryConfig {
    return {
      categories: [],
      categoriesCtrls: new Map<CategoryLevelNumber, FormControl>([
        [CategoryLevelNumber.One, new FormControl('')],
        [CategoryLevelNumber.Two, new FormControl('')],
        [CategoryLevelNumber.Three, new FormControl('')]
      ]),
      categoriesPerLevel: new Map<CategoryLevelNumber, FlatCategory[]>([
        [CategoryLevelNumber.One, []],
        [CategoryLevelNumber.Two, []],
        [CategoryLevelNumber.Three, []]
      ]),
      filteredCategoriesPerLevel: new Map<CategoryLevelNumber, FlatCategory[]>([
        [CategoryLevelNumber.One, []],
        [CategoryLevelNumber.Two, []],
        [CategoryLevelNumber.Three, []]
      ]),
      shouldDisplayCategories: new Map<CategoryLevelNumber, boolean>([
        [CategoryLevelNumber.One, true],
        [CategoryLevelNumber.Two, false],
        [CategoryLevelNumber.Three, false]
      ]),
      selectedCategoryPerLevel: new Map<CategoryLevelNumber, FlatCategory>([
        [CategoryLevelNumber.One, null],
        [CategoryLevelNumber.Two, null],
        [CategoryLevelNumber.Three, null]
      ])
    };
  }

  getFlatSubcategories(categoryId: number, categories: FlatCategory[]) {
    const subcategories = categories.filter(c => {
      if (c.ParentCategoryID) {
        return c.ParentCategoryID === categoryId;
      } else {
        return false;
      }
    });

    return subcategories;
  }

  setCategoryControls(categoryConfig: CategoryConfig, categoryState: CategoryState) {
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.One).setValue(categoryState.level1CategoryName);
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Two).setValue(categoryState.level2CategoryName);
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Three).setValue(categoryState.level3CategoryName);
  }

  resetCategories(categoryConfig: CategoryConfig) {
    let categoryState: CategoryState = {
      level1CategoryName: '',
      level2CategoryName: '',
      level3CategoryName: ''
    }
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.One, null);
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Two, null);
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, null);
    categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Two, false);
    categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Three, false);
    this.setCategoryControls(categoryConfig, categoryState);
    return categoryState;
  }

  resetAll(categoryConfig: CategoryConfig) {
    categoryConfig.selectedCategoryCloned = null;
    this.resetCategories(categoryConfig);
  }

  getLevelOneCategories(categories: FlatCategory[], categoryTypes?: CategoryType[]) {
    let categoriesResult: FlatCategory[];

    if (categoryTypes && categoryTypes.length > 0) {
      categoriesResult = categories.filter(c => c.CategoryLevel === 1 && categoryTypes.includes(c.CategoryTypeId));
    } else {
      categoriesResult = categories.filter(c => c.CategoryLevel === 1);
    }

    return categoriesResult;
  }

  handleFlatCategorySelect(categoryConfig: CategoryConfig, nextCategoryLevelNumber: CategoryLevelNumber, option: FlatCategory) {
    categoryConfig.selectedCategoryCloned = { ...option };
    categoryConfig.categoriesCtrls.get(nextCategoryLevelNumber).reset();
    let subcategories = this.getFlatSubcategories(option.CategoryID, categoryConfig.categories);
    categoryConfig.categoriesPerLevel.set(nextCategoryLevelNumber, subcategories);
    categoryConfig.filteredCategoriesPerLevel.set(nextCategoryLevelNumber, subcategories);
    categoryConfig.shouldDisplayCategories.set(nextCategoryLevelNumber,
      categoryConfig.categoriesPerLevel.get(nextCategoryLevelNumber).length > 0);
    if (nextCategoryLevelNumber == CategoryLevelNumber.Two) {
      categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.Three, []);
      categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Three,
        categoryConfig.categoriesPerLevel.get(CategoryLevelNumber.Three).length > 0);
    }
  }

  findParentCategories(categories: FlatCategory[], level: CategoryLevelNumber) {
    let parentLevel = level - 1;
    let levelCategories = categories.filter(c => c.CategoryLevel === parentLevel);

    return levelCategories;
  }

  setCategory(categoryConfig: CategoryConfig, categoryID: number) {
    let category: FlatCategory = this.getCategory(categoryConfig, categoryID);
    if (!category) {
      return;
    }

    categoryConfig.selectedCategoryCloned = { ...category };
    switch (category.CategoryLevel) {
      case CategoryLevelNumber.One:
        categoryConfig.categoriesCtrls.get(CategoryLevelNumber.One).setValue(category.CategoryName);
        let subcategories = this.getFlatSubcategories(categoryID, categoryConfig.categories);
        categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.Two, subcategories);
        categoryConfig.filteredCategoriesPerLevel.set(CategoryLevelNumber.Two, subcategories);
        categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.One, category);
        if (categoryConfig.categoriesPerLevel.get(CategoryLevelNumber.Two).length > 0) {
          categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Two, true);
        }
        break;
      case CategoryLevelNumber.Two:
        this.setLevel2Category(categoryConfig, category, categoryID);
        break;
      case CategoryLevelNumber.Three:
        this.setLevel3Category(categoryConfig, category);
        break;
    }
  }

  getCategoryEmoji(category: FlatCategory) {
    if (category &&
      category.EmojiUnicode) {
      let code = parseInt(category.EmojiUnicode.substring(2), 16);
      if (category.EmojiUnicode.length >= 6
        && category.EmojiUnicode.startsWith('U+')) {
        if (!isNaN(code)) {
          return String.fromCodePoint(code);
        }
      }
    }
    return '';
  }

  getCategory(categoryConfig: CategoryConfig, categoryId: number) {
    return categoryConfig.categories.find(c => c.CategoryID == categoryId);
  }

  private setLevel3Category(categoryConfig: CategoryConfig, category: FlatCategory) {
    categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Two, true);
    categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Three, true);
    let secondLevelCategory = this.getCategory(categoryConfig, category.ParentCategoryID);
    let firstLevelCategory = this.getCategory(categoryConfig, secondLevelCategory.ParentCategoryID);
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.One).setValue(firstLevelCategory.CategoryName);
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Two).setValue(secondLevelCategory.CategoryName);
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Three).setValue(category.CategoryName);
    let firstLevelSubcategories = this.getFlatSubcategories(firstLevelCategory.CategoryID, categoryConfig.categories);
    let secondLevelSubcategories = this.getFlatSubcategories(secondLevelCategory.CategoryID, categoryConfig.categories);
    categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.Two, firstLevelSubcategories);
    categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.Three, secondLevelSubcategories);
    categoryConfig.filteredCategoriesPerLevel.set(CategoryLevelNumber.Two, firstLevelSubcategories);
    categoryConfig.filteredCategoriesPerLevel.set(CategoryLevelNumber.Three, secondLevelSubcategories);
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.One, firstLevelCategory);
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Two, secondLevelCategory);
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, category);
  }

  private setLevel2Category(categoryConfig: CategoryConfig, category: FlatCategory, categoryID: number) {
    categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Two, true);
    let firstLevelCategory = this.getCategory(categoryConfig, category.ParentCategoryID);
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.One).setValue(firstLevelCategory.CategoryName);
    categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Two).setValue(category.CategoryName);
    let firstLevelSubcategories = this.getFlatSubcategories(firstLevelCategory.CategoryID, categoryConfig.categories);
    let secondLevelSubcategories = this.getFlatSubcategories(categoryID, categoryConfig.categories);
    categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.Two, firstLevelSubcategories);
    categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.Three, secondLevelSubcategories);
    categoryConfig.filteredCategoriesPerLevel.set(CategoryLevelNumber.Two, firstLevelSubcategories);
    categoryConfig.filteredCategoriesPerLevel.set(CategoryLevelNumber.Three, secondLevelSubcategories);
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.One, firstLevelCategory);
    categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Two, category);
    if (categoryConfig.categoriesPerLevel.get(CategoryLevelNumber.Three).length > 0) {
      categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Three, true);
    }
  }
}
