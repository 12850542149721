import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {
  private loadingState = new Subject<boolean>();
  loadingState$ = this.loadingState.asObservable();

  constructor() { }

  setLoading() {
    this.loadingState.next(true);
  }

  clearLoading() {
    this.loadingState.next(false);
  }
}
