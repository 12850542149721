import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CacheItem } from './cacheitem';

@Injectable()
export class CacheService {
  storage: Storage = window.localStorage;
  private cacheDuration = 3 * 60;
  private cacheKeyPrefix = 'cache_';

  constructor() { }

  set<T>(key: string, data: T, cacheDurationSeconds?: number) {
    let item: CacheItem<T> = {
      data: data,
      cacheDate: moment().format(),
      cacheDurationSeconds: cacheDurationSeconds
    };
    this.storage.setItem(this.getFullCacheKey(key), JSON.stringify(item));
  }

  get<T>(key: string) {
    let value = this.storage.getItem(this.getFullCacheKey(key));

    if (value === null) {
      return null;
    }

    let cacheItem = <CacheItem<T>>JSON.parse(value);
    let isStale = this.processStale(this.getFullCacheKey(key), cacheItem);

    if (isStale) {
      return null;
    }

    return cacheItem.data;
  }

  clear(key: string) {
    this.storage.removeItem(this.cacheKeyPrefix + key);
  }

  processStale<T>(key: string, cacheItem: CacheItem<T>) {
    let duration = cacheItem.cacheDurationSeconds ? cacheItem.cacheDurationSeconds : this.cacheDuration
    if (moment().diff(moment(cacheItem.cacheDate), 'seconds') > duration) {
      this.storage.removeItem(this.getFullCacheKey(key));
      return true;
    }
    return false;
  }

  private getFullCacheKey(key: string) {
    return this.cacheKeyPrefix + key;
  }
}
