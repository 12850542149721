import { FormControl } from '@angular/forms';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { BaseStateComponent } from '../basestate/basestate.component';
import { Component, OnInit, Injector } from '@angular/core';
import { ManageMissingMerchantState } from './managemissingmerchants.state';
import { DateRangeOption } from '../../enums/enums';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import * as moment from 'moment';
import { EnumValuesService } from '../../services/enumvalues.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { MissingMerchantItemsResponse, EnumValueResponse, MissingMerchantItemsResponseDisplay, UpdateMissingMerchantVariables } from './managemissingmerchants.models';
import { timePickerTheme } from '../../core/constants/constants';
import { NotificationService } from '../../core/uiservices/notification.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { KeyValue } from '../../models/whoisresponse';
import { ManageMissingMerchantService } from '../../services/managemissingmerchants.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../users/applicationuser.service';
import { ApplicationUser } from '../../users/applicationuser';

export type EnumValuesResponse = {
  enumValues: EnumValueResponse[];
};

export type MissingMerchantsResponse = {
  items: MissingMerchantItemsResponse[];
  totalCount: number;
};

export type UpdateMissingMerchantResponse = {
  updateMissingMerchant: MissingMerchantItemsResponse;
}

@Component({
  selector: 'app-managemissingmerchants',
  templateUrl: './managemissingmerchants.component.html',
  styleUrls: ['./managemissingmerchants.component.scss']
})
export class ManageMissingMerchantsComponent extends BaseStateComponent<ManageMissingMerchantState> implements OnInit {
  dateRangeOption = DateRangeOption;
  primaryTheme: NgxMaterialTimepickerTheme = timePickerTheme;
  statusFilterDisplay: KeyValue[] = [];

  missingMerchantsItems: MissingMerchantItemsResponseDisplay[] = [];
  editingRowIndex: number = -1;
  originalRow: MissingMerchantItemsResponseDisplay;
  matSelectOfStatusField: KeyValue[] = [];
  pageSize: number = 1000;
  totalCount: number;
  startDateIncorrect: string = "Start date should be earlier then end date";
  startDateControl = new FormControl();
  endDateControl = new FormControl();

  private currentUserId: string;
  private apllicationUsers: ApplicationUser[] = [];

  constructor(injector: Injector, private dateTimeService: DateTimeService, private enumValuesService: EnumValuesService,
    private loadingService: LoadingService, private notificationService: NotificationService, private csvComponentService: CsvComponentService,
    private valueCheckerService: ValueCheckerService, private manageMissingMerchantService: ManageMissingMerchantService,
    private authService: AuthService, private userService: UserService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      merchantLookupGuid: '',
      notificationId: '',
      customNotificationDate: null,
      selectedDate: DateRangeOption.ThisWeek,
      customStartDate: null,
      customStartTime: null,
      customEndDate: null,
      customEndTime: null,
      startDate: '',
      endDate: '',
      statusFilter: '',
      enumValuesResponse: [],
      notificationDate: ''
    };
    this.restoreState();
    this.setStartEndDate();
    this.setStatusEnum();
    this.setCurrentUser();
  }

  isStartDateIncorrect() {
    if (this.state.customStartDate && this.state.customEndDate && this.state.customStartTime && this.state.customEndTime) {
      return this.dateTimeService.isFirstDateBiggerOrEqual(this.state.customStartDate, this.state.customEndDate, this.state.customStartTime, this.state.customEndTime);
    }
    return false;
  }

  disableApplyButton() {
    return this.valueCheckerService.isEmptyNullOrUndefined(this.state.startDate)
      || this.valueCheckerService.isEmptyNullOrUndefined(this.state.endDate)
      || this.valueCheckerService.isEmptyNullOrUndefined(this.state.statusFilter)
      || this.isStartDateIncorrect();
  }

  setMissingMerchants() {
    this.manageMissingMerchantService.getMissingMerchants(this.state.merchantLookupGuid, this.state.notificationDate, this.state.notificationId, this.state.statusFilter, this.state.endDate, this.state.startDate)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.items.length > 0) {
          this.totalCount = response.data.totalCount;
          this.missingMerchantsItems = response.data.items;
          for (let missingMerchant of this.missingMerchantsItems) {
            this.setDisplayValues(missingMerchant);
          }
        }
        else {
          this.missingMerchantsItems = [];
          this.totalCount = 0;
        }
      });
  }

  checkCustomDateValidity() {
    if (this.state.selectedDate === this.dateRangeOption.Custom && this.isStartDateIncorrect()) {
      this.notificationService.notifyError(this.startDateIncorrect);
      return;
    }
    if (this.state.selectedDate === DateRangeOption.Custom && !this.startDateControl.valid || !this.endDateControl.valid) {
      this.notificationService.notifyError("Please provide required Dates");
      return;
    }
    this.setStartAndEndDateForCustomOption();

    this.state.notificationDate = !this.valueCheckerService.isEmptyNullOrUndefined(this.state.customNotificationDate)
      ? this.dateTimeService.formatWith24Hours(this.state.customNotificationDate)
      : "";
    this.setMissingMerchants();
  }

  getCsvOptions() {
    let obj = this.missingMerchantsItems[0];
    obj = { ...obj };
    let options = this.csvComponentService.getCsvOptions(obj, 'ManageMissingMerchant.csv');
    return options;
  }

  editRow(row: MissingMerchantItemsResponseDisplay, rowIndex: number) {
    this.editingRowIndex = rowIndex;
    this.originalRow = { ...row };
  }

  save(row: MissingMerchantItemsResponseDisplay, rowIndex: number) {
    this.loadingService.setLoading();
    let variables: UpdateMissingMerchantVariables = {
      missingMerchantFeedbackId: row.merchantFeedbackID,
      comment: row.comment,
      modifiedBy: this.currentUserId,
      lwcId: row.lWC_ID,
      status: row.status
    };

    this.manageMissingMerchantService.updateMissingMerchant(variables)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.updateMissingMerchant) {
          this.missingMerchantsItems[rowIndex] = response.data.updateMissingMerchant;
          this.setDisplayValues(response.data.updateMissingMerchant);
          this.missingMerchantsItems = [...this.missingMerchantsItems];
          this.notificationService.notifySuccess();
        }
        this.editingRowIndex = -1;
      });
  }

  cancel(rowIndex: number) {
    this.missingMerchantsItems[rowIndex] = this.originalRow;
    this.missingMerchantsItems = [...this.missingMerchantsItems];
    this.editingRowIndex = -1;
  }

  setStartEndDate() {
    switch (this.state.selectedDate) {
      case DateRangeOption.Today:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().add(1,'days').startOf('day'));
        break;
      case DateRangeOption.Yesterday:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().add(-1, 'days').startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
        break;
      case DateRangeOption.ThisWeek:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('isoWeek'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().endOf('isoWeek').add(1,'days'));
        break;
      case DateRangeOption.LastWeek:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(-6).startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(0).add(1,'days').endOf('day'));
        break;
      case DateRangeOption.Custom:
        if (this.state.customStartDate && this.state.customEndDate) {
          this.state.startDate = this.dateTimeService.formatWith24Hours(this.state.customStartDate);
          this.state.endDate = this.dateTimeService.formatWith24Hours(this.state.customEndDate);
        } else {
          this.state.customStartDate = moment().add(-1, 'days');
          this.state.customEndDate = moment().endOf('day');
          this.state.customStartTime = this.dateTimeService.twentyFourHours;
          this.state.customEndTime = this.dateTimeService.twentyFourHours;
          this.setStartAndEndDateForCustomOption();
        }
        break;
    }
  }

  private setStatusEnum() {
    this.enumValuesService.getEnumValues()
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        this.state.enumValuesResponse = response.data.enumValues;
        if (this.valueCheckerService.isEmptyNullOrUndefined(this.state.statusFilter)) {
          this.state.statusFilter = response.data.enumValues[0].name;
        }
        for (let status of this.state.enumValuesResponse) {
          this.statusFilterDisplay.push({
            key: status.name.charAt(0).toUpperCase() + status.name.substr(1).toLowerCase(),
            value: status.name
          });
        }
        this.matSelectOfStatusField = this.statusFilterDisplay.filter(t => t.value !== "ALL");
      });
  }

  private setDisplayValues(missingMerchantItem: MissingMerchantItemsResponseDisplay) {
    missingMerchantItem.createdDateDisplay = this.dateTimeService.format(missingMerchantItem.createdDate, this.dateTimeService.dateTimeFormatYYYYMMDDhmmaDashes);
    missingMerchantItem.modifiedDateDisplay = this.dateTimeService.format(missingMerchantItem.modifiedDate, this.dateTimeService.dateTimeFormatYYYYMMDDhmmaDashes);
    missingMerchantItem.storeJoinDateDisplay = this.dateTimeService.format(missingMerchantItem.storeJoinDate, this.dateTimeService.dateTimeFormatYYYYMMDDhmmaDashes);
    let user = this.apllicationUsers.find((s) => s.Id.toString() === missingMerchantItem.modifiedBy);
    if (!this.valueCheckerService.isNullOrUndefined(user)) {
      missingMerchantItem.modifiedBy = `${user.FirstName + ' ' + user.LastName}`;
    }
  }

  private setCurrentUser() {
    let currentUser = this.authService.getCurrentUser();
    this.userService.getApplicationUsersCached()
      .subscribe((response) => {
        if (response) {
          this.apllicationUsers = response;
          let user = response.find(t => t.EmailId === currentUser.userId);
          if (user !== undefined) {
            this.currentUserId = user.Id.toString();
          }
        }
      });
  }

  private setStartAndEndDateForCustomOption() {
    if (this.state.customStartTime === this.dateTimeService.twentyFourHours) {
      this.state.customStartTime = this.dateTimeService.zeroTime;
    }
    if (this.state.customEndTime === this.dateTimeService.twentyFourHours) {
      this.state.customEndTime = this.dateTimeService.zeroTime;
    }
    if (this.state.selectedDate === DateRangeOption.Custom) {
      this.state.customStartDate = this.dateTimeService.setTime(this.state.customStartDate, this.state.customStartTime);
      this.state.customEndDate = this.dateTimeService.setTime(this.state.customEndDate, this.state.customEndTime);
      this.state.startDate = this.dateTimeService.formatWith24Hours(this.state.customStartDate);
      this.state.endDate = this.dateTimeService.formatWith24Hours(this.state.customEndDate);
    }
  }

}
