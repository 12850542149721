import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class NumberService {

  constructor() { }

  format(value: number) {
    return value.toLocaleString('en');
  }

  sortDescending(firstNumber: number, secondNumber: number) {
    if (firstNumber < secondNumber) return 1;
    if (firstNumber > secondNumber) return -1;
  }

  sortAscending(firstNumber: number, secondNumber: number) {
    if (firstNumber < secondNumber) return -1;
    if (firstNumber > secondNumber) return 1;
  }
}
