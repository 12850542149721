import { BaseStateComponent } from './../basestate/basestate.component';
import { DialogService } from '../../core/uiservices/dialog.service';
import { NameCatalogueService } from '../../services/namecatalogue.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { Component, OnInit, Injector } from '@angular/core';
import { NamesCatalogueState } from './namescataloguestate';
import { Router } from '@angular/router';

@Component({
  selector: 'app-namecatalogue',
  templateUrl: './namecatalogue.component.html',
  styleUrls: ['./namecatalogue.component.scss']
})
export class NameCatalogueComponent extends BaseStateComponent<NamesCatalogueState> implements OnInit {
  addNewNameResult: [number, string];

  constructor(private nameCatalogueService: NameCatalogueService,
    private notificationService: NotificationService, private loadingService: LoadingService,
    private dialogService: DialogService, private injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      nameAddress: '',
      match: '',
      name: '',
      abnacn: <number>null,
      catalogueSearchResult: null
    };

    this.restoreState();
  }

  matchNameAddress() {
    this.loadingService.setLoading();

    this.nameCatalogueService.getCatalogueSearchResult(this.state.nameAddress, this.state.match)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(data => {
        this.state.catalogueSearchResult = data;
        this.notificationService.notifySuccess();
      });
  }

  addNewName() {
    const dialogRef = this.dialogService
      .openConfirmDialog(`Are you sure you wish to add ${this.state.name} for ABN ${this.state.abnacn} to catalog?`);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();
        this.nameCatalogueService.getCatalogAddNewNameResult(this.state.name, this.state.abnacn)
          .pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(data => {
            this.addNewNameResult = data;
            if (this.addNewNameResult) {
              setTimeout(() => this.router.navigate(['/indexmerchant', { businessNameID: this.addNewNameResult }]), 2000);
              this.notificationService.notifySuccess();
            }
          });
      }
    });
  }

  isMatchNameAddressButtonDisabled(): boolean {
    return !this.state.nameAddress || !this.state.match;
  }

  isAddNewNameButtonDisabled(): boolean {
    return !this.state.name || this.state.abnacn <= 0;
  }
}
