import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiService } from './../core/api/api.service';
import { Injectable } from "@angular/core";
import { ApiClient } from '../core/api/apiclient';
import { TagAllocationPattern, TestTagAllocationPatternsRequest, TagAllocationPatternMatch } from '../components/tagallocationpatterns/tagallocationpatterns.models';

@Injectable({
  providedIn: "root"
})
export class TagAllocationPatternsService {

  constructor(private apiClient: ApiClient, private ApiService: ApiService) {}

  getTagPatterns(newTagId?: number) {
   return this.apiClient.get<TagAllocationPattern[]>(this.ApiService.buildUrl(apiPathConstants.getByNewTag, newTagId ? newTagId.toString() : null));
  }

  deleteRecord(tagAllocationPatternId: number) {
    return this.apiClient.delete<void>(this.ApiService.buildUrl(apiPathConstants.tagAllocationPattern, tagAllocationPatternId.toString()));
  }

  saveChanges(upsertTagAllocationPatternRequest: TagAllocationPattern) {
    return this.apiClient.post<TagAllocationPattern>(this.ApiService.buildUrl(apiPathConstants.tagAllocationPattern), upsertTagAllocationPatternRequest);
  }

  getTagAllocationPatternMatch(testTagAllocationPatternsRequest: TestTagAllocationPatternsRequest) {
    return this.apiClient.post<TagAllocationPatternMatch>(this.ApiService.buildUrl(apiPathConstants.testNewPattern), testTagAllocationPatternsRequest);
  }

}
