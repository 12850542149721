import { ExecutionResult } from 'graphql';
import { CalItemsResponse, AllocateItemsToIndexerResponse, NumberOfSearchesResponse } from '../../components/cardacceptorlocators/cardacceptorlocator.component';
import { GroupOfSimilarCalsResponse } from '../../components/cardacceptorlocators/groupofsimilarcals/groupofsimilarcals.component';

export let itemsResponse: ExecutionResult<CalItemsResponse> = {
  data: {
    items: [
      {
        iD_no: 2561421,
        transactionDescription: "LINKED ACC TRNS",
        currentStatusID: 6,
        numberOfSearches: 1771667,
        cALType: 5,
        iD_Indexer: 0,
        chain: null,
        link: "",
        lWC_ID: null,
        createDateTime: "2019-05-19T03:41:00Z",
        currentStatusDateTime: "2021-06-17T20:17:54.963Z",
        indexerName: "LWC System 1"
      },
      {
        iD_no: 3697466,
        transactionDescription: "BIG W",
        currentStatusID: 6,
        numberOfSearches: 2737823,
        cALType: 3,
        iD_Indexer: 0,
        chain: "BIG W",
        link: "",
        lWC_ID: null,
        createDateTime: "2021-04-19T07:24:00Z",
        currentStatusDateTime: "2021-06-18T02:12:25.86Z",
        indexerName: "LWC System 2"
      },
      {
        iD_no: 4306707,
        transactionDescription: "BEEM IT",
        currentStatusID: 6,
        numberOfSearches: 5769048,
        cALType: 5,
        iD_Indexer: 0,
        chain: null,
        link: "",
        lWC_ID: null,
        createDateTime: "2021-05-13T09:45:00Z",
        currentStatusDateTime: "2021-06-18T02:18:26.54Z",
        indexerName: "Daisy"
      },
      {
        iD_no: 3980302,
        transactionDescription: "CHEMISTWORKS GLE",
        currentStatusID: 6,
        numberOfSearches: 1802966,
        cALType: 3,
        iD_Indexer: 0,
        chain: "Chemistworks",
        link: "",
        lWC_ID: null,
        createDateTime: "2021-04-23T09:49:00Z",
        currentStatusDateTime: "2021-06-18T05:10:04.563Z",
        indexerName: "Glenn"
      },
    ],
    totalCount: 500
  }
};

export let allocateItemsToIndexerResponse: ExecutionResult<AllocateItemsToIndexerResponse> = {
  data: {
    items: [
      {
        iD_no: 3980302,
        transactionDescription: "Z79 RBTBAL SOUTH MELBOUR",
        currentStatusID: 1,
        numberOfSearches: 9858989,
        cALType: 5,
        iD_Indexer: 0,
        chain: "Chemistworks",
        link: "",
        lWC_ID: null,
        createDateTime: "2001-02-20T09:49:00Z",
        currentStatusDateTime: "2011-06-18T05:10:04.563Z",
        indexerName: "LWC System 6"
      },
    ]
  }
};

export let groupOfSimilarCalsResponse: ExecutionResult<GroupOfSimilarCalsResponse> = {
  data: {
    items: [
      {
        transactionDescription: "V73 RBTBAL SOUTH MELBOUR",
        chain: null,
        group: "S59 RBTMRL SOUTH MELBOUR",
        count: 715
      },
      {
        transactionDescription: "Z79 RBTBAL SOUTH MELBOUR",
        chain: null,
        group: "S59 RBTMRL SOUTH MELBOUR",
        count: 715
      },
      {
        transactionDescription: "V22 RBTCVA SOUTH MELBOUR",
        chain: null,
        group: "S59 RBTMRL SOUTH MELBOUR",
        count: 715
      },
      {
        transactionDescription: "H11 RBTMRL SOUTH MELBOUR",
        chain: null,
        group: "S59 RBTMRL SOUTH MELBOUR",
        count: 715
      }
    ],
    totalCount: 500
  }
};

export let numberOfSearchesResponse: ExecutionResult<NumberOfSearchesResponse> = {
  data: {
    items: [
      {
        numberOfSearches: 6656560
      }
    ]
  }
};
