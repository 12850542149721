import { ApiParameter } from './apiparameter';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RegionStateService } from '../cache/regionstate.service';
import { RegionType } from '../../users/userenums';
import { ValueCheckerService } from '../services/valuechecker.service';
import { apiPathConstants } from './apipathconstants';

@Injectable()
export class ApiService {
  private apiPrefix = 'api';

  constructor(private regionStateService: RegionStateService, private valueCheckerService: ValueCheckerService) {

  }

  buildUrl(route: string, ...keys: string[]): string {
    let uri = encodeURI(`${this.getBaseUrl(this.apiPrefix)}/${route}`);
    for (let key of keys) {
      if(!this.valueCheckerService.isEmptyNullOrUndefined(key)){
        uri = this.appendKeyToUri(uri, key);
      }
    }
    return uri;
  }

  getGraphQlBaseUrl() {
    let url = this.getBaseUrl(this.apiPrefix) + apiPathConstants.graphql;

    return url;
  }

  buildApiUrlWithParameters(route: string, ...apiParameters: ApiParameter[]): string {
    return this.buildUrlWithParameters(this.apiPrefix, route, ...apiParameters);
  }

  buildUrlWithParameters(urlPrefix: string, route: string, ...apiParameters: ApiParameter[]): string {
    let uri = encodeURI(`${this.getBaseUrl(urlPrefix)}/${route}?`);

    if (apiParameters && apiParameters.length > 0) {
      for (let i = 0; i <= apiParameters.length - 1; i++) {
        if (i !== 0) {
          uri += '&';
        }
        uri += this.getUriParameter(apiParameters[i]);
      }
    }
    return uri;
  }

  getApiBaseUrl() {
    return this.getBaseUrl(this.apiPrefix);
  }

  private getBaseUrl(urlPrefix: string) {
    let currentRegion = this.regionStateService.getCurrentRegion();
    let baseUrl = `${environment.apiUrl}/${urlPrefix}`;
    if (currentRegion === RegionType.AUS) {
       baseUrl += '/aus';
    } else if (currentRegion === RegionType.NZL) {
      baseUrl += '/nzl';
    }
    else baseUrl += '/aus'

    return baseUrl;
  }

  private appendKeyToUri(uri: string, key: string): string {
    uri = `${uri}/${encodeURIComponent(key)}`;
    return uri;
  }

  private getUriParameter(apiParameter: ApiParameter): string {
    return `${apiParameter.name}=${encodeURIComponent(apiParameter.value)}`;
  }
}
