import { Chain } from '../models/merchantreference';
import { Injectable } from '@angular/core';
import { DataSource } from '@oasisdigital/angular-material-search-select';
import { SearchSelectService } from './searchselect.service';
import { chainTypeStringValues } from '../core/constants/chains';
import { ChainType } from '../components/indexmerchant/chaintype';

@Injectable()
export class PickChainService {
  constructor(private searchSelectService: SearchSelectService) {
  }

  getChainsDataSource(referenceData: Chain[]): DataSource {
    let filterFn = (c: Chain, term: string) => c.ChainName.toLowerCase().includes(term);
    let mapFn = (chain: Chain) => ({
      value: chain.ChainName,
      display: chain.ChainName + ` [${chain.ChainType}]`,
      details: ''
    });

    return this.searchSelectService.getDataSource(referenceData, filterFn, mapFn);
  }

  getUsualChainTypesForDisplay(): Array<string | 0> {
    return [
      chainTypeStringValues.get(ChainType.Branch),
      chainTypeStringValues.get(ChainType.Franchise),
      chainTypeStringValues.get(ChainType.Both),
      chainTypeStringValues.get(ChainType.StandardResponse),
      chainTypeStringValues.get(ChainType.BankBranch),
      chainTypeStringValues.get(ChainType.MerchantPattern)
    ];
  }

  getChainTypesForSharedCal() {
    return [
      chainTypeStringValues.get(ChainType.HeadOffice),
      chainTypeStringValues.get(ChainType.Branch),
      chainTypeStringValues.get(ChainType.Franchise),
      chainTypeStringValues.get(ChainType.Product),
      chainTypeStringValues.get(ChainType.Both),
    ];
  }
}
