import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-jsonviewer-image',
  templateUrl: './jsonviewerimage.component.html',
  styleUrls: ['./jsonviewerimage.component.scss']
})
export class JsonViewerImageComponent implements OnInit {
  @Input()
  imageUrl: string;
  @Input()
  height: number;
  @Input()
  width: number;

  constructor() { }

  ngOnInit() {
  }

  
}
