import { Injectable } from '@angular/core';

@Injectable()
export class ArrayService {
    getWithoutDuplicates<T>(array: T[]) {
      return Array.from(new Set(array));
    }

    moveElement<T>(array: T[], fromIndex: number, toIndex:number) {
      let element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(toIndex, 0, element);
  }
}
