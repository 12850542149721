import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { categoryDataSourcesResponse, externalCategoriesResponse, categoryMappingRulesResponse, categoryByMappingRuleResponse } from '../mockjsonresponses/categorymappingrulesresponse';
import { HttpMethod } from '../../enums/enums';

export function categoryMappingRulesService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes(apiPathConstants.allCategoryDataSources) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...categoryDataSourcesResponse]
      }));
      resp.complete();
    })
  };

  if (cleanUrl.includes(apiPathConstants.allExternalCategories) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...externalCategoriesResponse]
      }));
      resp.complete();
    })
  };

  if (cleanUrl.includes(apiPathConstants.allCategoryMappingRules) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...categoryMappingRulesResponse]
      }));
      resp.complete();
    })
  };

  if (cleanUrl.includes(apiPathConstants.upsertrule) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: []
      }));
      resp.complete();
    })
  };

  if (cleanUrl.includes(apiPathConstants.getcategorybymappingrule) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...categoryByMappingRuleResponse]
      }));
      resp.complete();
    })
  }
}
