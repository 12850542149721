import { Component, OnInit, HostListener } from '@angular/core';
import { HotkeyInfoService } from './core/uiservices/hotkeyinfo.service';
import { HotkeyInfoMessage } from './shared/models/hotkeyinfomessage';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private hotkeyInfoService: HotkeyInfoService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.init();
  }

  @HostListener('window:keydown', ['$event'])
  onAltKeyDown(event: KeyboardEvent) {
    if (event.altKey || event.ctrlKey) {
      let hotkeyInfoMessage: HotkeyInfoMessage = {
        altPressed: event.altKey,
        controlPressed: event.ctrlKey
      };
      this.hotkeyInfoService.setHotkeyInfo(hotkeyInfoMessage);
    }
  }

  @HostListener('window:keyup', ['$event'])
  onAltKeyUp(event: KeyboardEvent) {
    if (!event.altKey || !event.ctrlKey) {
      let hotkeyInfoMessage: HotkeyInfoMessage = {
        altPressed: event.altKey,
        controlPressed: event.ctrlKey
      };
      this.hotkeyInfoService.setHotkeyInfo(hotkeyInfoMessage);
    }
  }

  @HostListener('window:focus')
  onWindowFocus() {
    let hotkeyInfoMessage: HotkeyInfoMessage = {};
    this.hotkeyInfoService.setHotkeyInfo(hotkeyInfoMessage);
  }
}
