import { Tag } from "./tag";

export interface APISearchResultsV4 {
    search_telemetry: string;
    transactions_count: number;
    search_time_ms: number;
    total_credits_used: number;
    fields: string;
    search_results: APISearchResultV4[];
}

export interface APISearchResultV4 {
    is_quarantined?: boolean;
    credits_used: number;
    transaction_search_time_ms: number;
    lwc_attempting_to_index?: boolean;
    matched_on_id?: number;
    result_code: number;
    system_message: string;
    user_message: string;
    atm_search_results: ATMSearchResultV4[];
    shared_cal_search_result: SharedCAL_Indexed_DocumentV3;
    bank_message_search_result: BankMessage_Indexed_DocumentV4;
    merchant_search_results: MerchantSearchResultV4[];
    utc_expiry_time?: string;
    highest_score?: number;
    number_of_results: number;
    transaction_correlation_guid?: string;
    customer_correlation_id: string;
    terminal_country_code: string;
    mid: string;
    tid: string;
    aid: string;
    fixedcal: string;
    cal: string;
    search_guid: string;
    is_recategorised?: boolean;
    transaction_category: Category;
    transaction_tags?: string;
}

export interface ATMSearchResultV4 {
    search_results_guid: string;
    ATM_ID?: number;
    lwc_guid: string;
    rank: number;
    score: number;
    match_feedback_requested: boolean;
    type_of_match: string;
    atm_details: ATM_Indexed_DocumentV4;
}

export interface ATM_Indexed_DocumentV4 {
    id?: number;
    lwc_guid?: string;
    primary_category?: Category;
    atm_operator?: AssociatedWith;
    primary_address?: Address;
    primary_contacts?: Contact[];
    is_mobile?: boolean;
    atm_name?: string;
    verification?: Verification;
    last_updated?: string;
    atm_logo?: MerchantLogo;
    atm_logo_3x1?: MerchantLogo;
    atm_logo_circular?: MerchantLogo;
    record_is_quarantined?: boolean;
}

export interface BankMessage_Indexed_DocumentV4 {
    lwc_guid: string;
    associated_with: AssociatedWith;
    short_bank_message: string;
    long_bank_message: string;
    primary_category: Category;
    BankMessage_LWC_ID: number;
}

export interface AssociatedWith {
    associates_name: string;
    associates_id: string;
    type_of_association: string;
}

export interface Category {
    category_name: string;
    id?: number;
    full_category_path: string;
    parent?: CategoryParent;
    is_sensitive?: boolean;
    is_lwc_category?: boolean;
    is_substituted_category?: boolean;
    lwc_category_icon?: CategoryIcon;
    lwc_category_icon_circular?: CategoryIcon;
    category_emoji?: string;
    recategorisation_info?: ReCategorisationInfo;
}

export interface ReCategorisationInfo {
    rule_id: number;
    rule_date_time: string;
}

export interface CategoryParent {
    category_name: string;
    id: number;
    full_category_path: string;
    parent?: CategoryParent;
}

export interface CategoryIcon {
    Black_white_url: string;
    Coloured_url: string;
    height: number;
    width: number;
}

export interface MerchantSearchResultV4 {
    search_results_guid: string;
    LWC_ID?: number;
    lwc_guid: string;
    rank: number;
    score: number;
    match_feedback_requested: boolean;
    type_of_match: string;
    merchant_details: Merchant_Details_DocumentV4;
}

export interface Merchant_Details_DocumentV4 {
    merchant_logo_circular?: MerchantLogo;
    website_screen_shot: WebsiteScreenShot;
    also_known_as: string[];
    associated_with?: AssociatedWith[];
    last_updated?: string;
    opening_hours: OpeningHours;
    merchant_logo_3x1?: MerchantLogo;
    legal_business_info: GenericBusinessInfo;
    ratings: Ratings[];
    payment_options: string[];
    transaction_query_process: TransactionQueryProcess;
    transaction_query_process_html?: string;
    transaction_query_process_json?: TransactionQueryProcessAsJson;
    receipt_data_available?: boolean;
    overall_rating: OverallRating;
    record_is_quarantined?: boolean;
    merchant_logo: MerchantLogo;
    merchant_descriptors: string[];
    id?: number;
    lwc_guid: string;
    data_enrichment_score: number;
    merchant_primary_name: string;
    primary_address: Address;
    verification: Verification;
    is_permanently_closed?: boolean;
    primary_contacts: Contact[];
    secondary_contacts: Contact[];
    secondary_addresses?: Address[];
    image_gallery: ImageGallery[];
    primary_category: Category;
    other_categories_list: Category[];
    primary_merchant_description: string;
    suspicious_merchant_score?: number;
    additional_details?: AdditionalDetails;
    merchant_tags?: Tag[];
}

export interface MerchantLogo {
    url: string;
    height?: number;
    width?: number;
}

export interface WebsiteScreenShot {
    thumbnail: ScreenShot;
    banner: ScreenShot;
    main: ScreenShot;
}

export interface ScreenShot {
    url: string;
    height?: number;
    width?: number;
}

export interface OpeningHours {
    is_always_open?: boolean;
    sunday?: OpeningTimes;
    monday?: OpeningTimes;
    tuesday?: OpeningTimes;
    wednesday?: OpeningTimes;
    thursday?: OpeningTimes;
    friday?: OpeningTimes;
    saturday?: OpeningTimes;
}

export interface OpeningTimes {
    status?: string;
    times?: Time[];
}

export interface Time {
    open: string;
    close: string;
}

export interface GenericBusinessInfo {
    date_established: string;
    entity_type: string;
    current_merchant_status: string;
    merchant_type: string;
    merchant_presence: string;
    chain_name?: string;
    legal_registrations: LegalNumber[];
    registered_for_sales_tax?: boolean;
    chain_lwc_guid?: string;
}

export interface LegalNumber {
    legal_number: string;
    legal_number_label: string;
}

export interface Ratings {
    reviewer: string;
    score: number;
    number_of_reviews?: number;
}

export interface TransactionQueryProcess {
    transaction_query_process_markdown?: string;
    transaction_query_process_link?: string;
}

export interface TransactionQueryProcessAsJson {
    paragraphs: TransactionQueryProcessParagraph[];
}

export interface TransactionQueryProcessParagraph {
    items: TransactionQueryProcessTextItem[];
}

export interface TransactionQueryProcessTextItem {
    value: string;
    type: TransactionQueryProcessTextItemType;
    display_value: string;
}

export enum TransactionQueryProcessTextItemType {
    text = 0,
    link = 1,
    phone = 2,
    email = 3
}

export interface OverallRating {
    overall_rating_score: number;
    total_number_of_reviews?: number;
}

export interface Address {
    single_line_address: string;
    address_line_1?: string;
    address_line_2?: string;
    state: string;
    postcode: string;
    suburb: string;
    country_name?: string;
    longitude?: number;
    latitude?: number;
    lat_lon_precision?: number;
    mapable: boolean;
    street_view_available?: boolean;
    address_located_in?: LocatedIn;
    address_identifiers?: AddressIdentifiers;
}

export interface LocatedIn {
    located_in_name: string;
    located_in_address: Address;
    located_in_website: string;
    located_in_phone_number: string;
}

export interface AddressIdentifiers {
    address_identifier_name: string;
    address_identifier_value: string;
}

export interface Verification {
    is_verified: boolean;
    last_verified_on?: string;
}

export interface Contact {
    type_of_contact: string;
    value: string;
    display_value: string;
    label: string;
}

export interface ImageGallery {
    thumbnail_url: string;
    large_url: string;
    image_title: string;
    image_height?: number;
    image_width?: number;
}

export interface AdditionalDetails {
    bpay_biller_codes: number[];
}

export interface SharedCAL_Indexed_DocumentV3 {
    shared_cal_comment: string;
    shared_name: string;
    shared_category: Category;
}