import { BaseODataFilter } from './baseodatafilter';
import { ODataOperator } from './odataoperator';

export class ODataFilter implements BaseODataFilter {
  constructor(private leftHand: string, private rightHand: string, private operator?: ODataOperator) {
  }

  getFilter(): string {
    let operatorToUse = ODataOperator.Equal;

    if (this.operator) {
      operatorToUse = this.operator;
    }

    return `${this.leftHand} ${operatorToUse} ${this.rightHand}`;
  }
}
