import { CategoryStandardiserTree, CategoryUpdateToDraft } from "./categorystandardiserdto";
import { Injectable } from "@angular/core";
import { ApiClient } from "../../core/api/apiclient";
import { ApiService } from "../../core/api/api.service";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { slash } from '../../core/constants/constants';

@Injectable()
export class CategoryStandardiserService {

  constructor(private apiClient: ApiClient, private apiService: ApiService) {
  }

  getExternalCategoriesDataSources() {
    return this.apiClient.get<string[]>(this.apiService.buildUrl(apiPathConstants.externalCategoriesDataSource));
  }

  getCategoryStandardiserTree(dataSource: string, loadFromDraft: boolean) {
    return this.apiClient.get<CategoryStandardiserTree>(this.apiService.buildUrl(apiPathConstants.getCategoryStandardiserTree + slash + dataSource + slash + loadFromDraft));
  }

  createCategoryStandardiserUpdateToDraft(updateToDraft: CategoryUpdateToDraft) {
    return this.apiClient.post<string>(this.apiService.buildUrl(apiPathConstants.createCategoryStandardiserUpdateToDraft), updateToDraft);
  }

  deleteCategoryStandardiserDeleteToDraft(deleteToDraft: CategoryUpdateToDraft) {
    return this.apiClient.post<string>(this.apiService.buildUrl(apiPathConstants.deleteCategoryStandardiserDeleteToDraft), deleteToDraft);
  }
}
