import { ExecutionResult } from 'graphql';
import { MerchantDetails, MerchantDetailsByChainNameAndShoppingCentre, MerchantDetailsByShoppingCentre, MerchantShoppingCentre } from "../../components/merchantextract/merchantdetails";

export let merchantDetails: MerchantDetails[] = [
  {
    LWC_ID: 1000000017,
    IsHeadOffice: false,
    IsBranchOffice: false,
    IsFranchise: false,
    ABN: 19153329616,
    OnlineOnly: false,
    ChainName: null,
    DataCompletnessScore: 88,
    UpdatedDate: '2018-05-04T08:05:00+11:00',
    PrimaryName: 'Asiga Pty Ltd',
    PrimaryCategory: 'Shopping >> Hardware >> Trade Only',
    PrimaryAddress: '2/36 Albert Street, ST PETERS NSW 2044 AUS',
    StreetViewAvailable: true,
    CALs: 'ASIGA PTY LTD ST PETERS AUS',
    Phone: '88005553535',
    URL: 'test@example.com',
    MerchantPresence: 'BricksMortar',
    MerchantType: 'Branch',
    RecordLocked: true,
    IsQuarantined: true,
    CurrentBusinessStatus: 'Active',
    Tags: "Pay Day Loans",
    BSBs: "019-696, 012-999",
    LocatedIn: null
  },
  {
    LWC_ID: 1000000042,
    IsHeadOffice: false,
    IsBranchOffice: false,
    IsFranchise: true,
    ABN: 54058330911,
    OnlineOnly: false,
    ChainName: 'Caltex',
    DataCompletnessScore: 93,
    UpdatedDate: '2018-11-28T19:45:00+11:00',
    PrimaryName: 'Caltex (St Ives)',
    PrimaryCategory: 'Travel >> Petrol Station',
    PrimaryAddress: '363 MONAVALE RD, ST IVES NSW 2075 AUS',
    StreetViewAvailable: true,
    CALs: 'CALTEX ST IVES NORTH ST IVES | CALTEX ST IVES NORTH',
    Phone: '88005553535',
    URL: 'test@example.com',
    MerchantPresence: 'MobileMerchant',
    MerchantType: 'Branch',
    RecordLocked: true,
    IsQuarantined: false,
    CurrentBusinessStatus: 'Closed',
    Tags: "Fridaysio",
    BSBs: "812-784",
    LocatedIn: null
  },
  {
    LWC_ID: 1000000058,
    IsHeadOffice: false,
    IsBranchOffice: false,
    IsFranchise: false,
    ABN: 76075609184,
    OnlineOnly: false,
    ChainName: null,
    DataCompletnessScore: 86,
    UpdatedDate: '2018-05-04T08:14:00+11:00',
    PrimaryName: 'ESSENSUALS PTY LTD',
    PrimaryCategory: 'Shopping >> Home >> Gift',
    PrimaryAddress: 'Shp 175/ Queen Street, Queen Street Mall, Brisbane QLD 4000 AUS',
    StreetViewAvailable: false,
    CALs: 'ESSENSUALS PTY LTD BRISBANE AUS',
    Phone: '88005553535',
    URL: 'test@example.com',
    MerchantPresence: 'OnlineOnly',
    MerchantType: 'Branch',
    RecordLocked: true,
    IsQuarantined: false,
    CurrentBusinessStatus: 'Active',
    Tags: "Light Coding",
    LocatedIn: null
  },
  {
    LWC_ID: 1000000062,
    IsHeadOffice: false,
    IsBranchOffice: false,
    IsFranchise: false,
    ABN: 31800454708,
    OnlineOnly: false,
    ChainName: null,
    DataCompletnessScore: 100,
    UpdatedDate: '2018-05-04T08:15:00+11:00',
    PrimaryName: 'Favourite Cycles. Since 1993',
    PrimaryCategory: 'Health & Fitness >> Specialist Sports Store >> Bike',
    PrimaryAddress: '2/ 22 Darley Rd, 22 DARLEY RD, Manly NSW 2095 AUS',
    StreetViewAvailable: true,
    CALs: 'FAVOURITE CYCLES MANLY',
    Phone: '88005553535',
    URL: 'test@example.com',
    MerchantPresence: 'BricksMortar',
    MerchantType: 'Branch',
    RecordLocked: true,
    IsQuarantined: false,
    CurrentBusinessStatus: 'Active',
    LocatedIn: null
  },
  {
    LWC_ID: 1000000086,
    IsHeadOffice: false,
    IsBranchOffice: false,
    IsFranchise: false,
    ABN: 97097673759,
    OnlineOnly: false,
    ChainName: null,
    DataCompletnessScore: 100,
    UpdatedDate: '2018-06-18T08:12:00+11:00',
    PrimaryName: 'King Tutts Putt Putt',
    PrimaryCategory: 'Health & Fitness >> Sporting Clubs >> Golf',
    PrimaryAddress: 'Surfersparadiseave Cnr Pandanusave, Surfers Paradise QLD 4217 AUS',
    StreetViewAvailable: true,
    CALs: 'KING TUTTS PUTT PUTT SURFERS PARAD',
    Phone: '88005553535',
    URL: 'test@example.com',
    MerchantPresence: 'BricksMortar',
    MerchantType: 'Branch',
    RecordLocked: true,
    IsQuarantined: false,
    CurrentBusinessStatus: 'Active',
    BSBs: "612-828",
    LocatedIn: null
  },
  {
    LWC_ID: 1000000108,
    IsHeadOffice: false,
    IsBranchOffice: false,
    IsFranchise: false,
    ABN: 61340837871,
    OnlineOnly: false,
    ChainName: null,
    DataCompletnessScore: 100,
    UpdatedDate: '2018-06-07T22:24:00+11:00',
    PrimaryName: 'Pittwater Council',
    PrimaryCategory: 'Service >> Government',
    PrimaryAddress: 'UNIT 13 / 5 VUKO PL, WARRIEWOOD NSW 2102 AUS',
    StreetViewAvailable: true,
    CALs: 'PITTWATER COUNCI WARRIEWOOD | PITTWATER COUNCIL WARRIEWOOD NSW',
    Phone: '88005553535',
    URL: 'test@example.com',
    MerchantPresence: 'OnlineOnly',
    MerchantType: 'Branch',
    RecordLocked: true,
    IsQuarantined: false,
    CurrentBusinessStatus: 'Inactive',
    BSBs: "",
    LocatedIn: null
  }
];

export let merchantShoppingCentersResponse: ExecutionResult<MerchantShoppingCentre[]> = {
  data: [
    {
      locatedIn_ID: 1,
      locatedIn_Name: "Altona Gate Shopping Centre"
    },
    {
      locatedIn_ID: 3,
      locatedIn_Name: "Spencer Outlet Centre"
    },
    {
      locatedIn_ID: 5,
      locatedIn_Name: "Westpoint Blacktown"
    },
    {
      locatedIn_ID: 6,
      locatedIn_Name: "Westfield North Lakes"
    },
    {
      locatedIn_ID: 8,
      locatedIn_Name: "Dianella Plaza"
    },
    {
      locatedIn_ID: 9,
      locatedIn_Name: "Kingston Town Shopping Centre"
    },
    {
      locatedIn_ID: 10,
      locatedIn_Name: "Channel Court Shopping Centre"
    },
    {
      locatedIn_ID: 11,
      locatedIn_Name: "Centrepoint Shopping Centre"
    },
    {
      locatedIn_ID: 12,
      locatedIn_Name: "The Cat & Fiddle Arcade"
    },
    {
      locatedIn_ID: 13,
      locatedIn_Name: "Trafalgar Shopping Centre"
    },
    {
      locatedIn_ID: 14,
      locatedIn_Name: "Elizabeth Street Mall"
    },
    {
      locatedIn_ID: 15,
      locatedIn_Name: "Elizabeth Plaza"
    },
    {
      locatedIn_ID: 16,
      locatedIn_Name: "Shoreline Shopping Centre"
    },
    {
      locatedIn_ID: 18,
      locatedIn_Name: "Wellington Centre"
    },
    {
      locatedIn_ID: 19,
      locatedIn_Name: "Eastlands Shopping Centre"
    },
    {
      locatedIn_ID: 20,
      locatedIn_Name: "New Town Plaza"
    },
    {
      locatedIn_ID: 21,
      locatedIn_Name: "Harvey Norman Centre Cambridge"
    },
    {
      locatedIn_ID: 22,
      locatedIn_Name: "Glenorchy Plaza"
    },
    {
      locatedIn_ID: 23,
      locatedIn_Name: "Northgate Shopping Centre"
    },
    {
      locatedIn_ID: 24,
      locatedIn_Name: "Claremont Plaza"
    },
    {
      locatedIn_ID: 25,
      locatedIn_Name: "Sorell Plaza Shopping Centre"
    },
    {
      locatedIn_ID: 27,
      locatedIn_Name: "Prospect Vale Marketplace"
    },
    {
      locatedIn_ID: 29,
      locatedIn_Name: "Meadow Mews"
    }
  ]
};

export let merchantDetailsByShoppingCentresResponse: MerchantDetailsByShoppingCentre[] = [
  {
    LWC_ID: 1000594657,
    MerchantType: "Stand-Alone",
    ABN: "16530665851",
    MerchantPresence: "Bricks & Mortar",
    ChainName: null,
    DataCompletnessScore: 75,
    UpdatedDate: "2020-10-14T08:30:00",
    PrimaryName: "Fresh Break",
    PrimaryCategory: "Food/Drink >> Other >> Smoothie/Juice",
    PrimaryAddress: "17 Patrick St, Blacktown NSW 2148, Australia",
    LocatedIn_Name: "Westpoint Blacktown",
    LocatedIn_Id: 5,
    StreetViewAvailable: true,
    Phone: "(02) 9671 4033",
    URL: "https://www.facebook.com/fresh-break-blacktown-154164107933562",
    CALs: " FRESH BREAK BLACKTOWN NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000594705,
    MerchantType: "Branch",
    ABN: "88000014675",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "BIG W",
    DataCompletnessScore: 100,
    UpdatedDate: "2021-03-22T06:52:00",
    PrimaryName: "BIG W (Westpoint Blacktown)",
    PrimaryCategory: "Shopping >> Department Store",
    PrimaryAddress: "17 Patrick St, Blacktown NSW 2148, Australia",
    LocatedIn_Name: "Westpoint Blacktown",
    LocatedIn_Id: 5,
    StreetViewAvailable: false,
    Phone: "(02) 9677 6404",
    URL: "https://www.bigw.com.au/store/0161/BIGW-Blacktown",
    CALs: " BIG W 0161 BLACKTOWN 0161 NSW || BIG W 0161 BLACKTOWN NSW || BIG W W0161 BLACKTOWN NSW || BIG W 0161BLACKTOWN NSW AU || BIG W 0161 BLACLACKTOWN NSW || BIG W 0161 B KTOWN NSW AU || BIG W 0161 BLACKTOWN NSW BLACKTOWN NSW || BIG W/CNR ALPHA & PATR BLACKTOWN AU || BIG W/CNR ALPHA & PATRICK BLACKTOWN NSW || BIG W/CNR ALPHA & PA BLACKTOWN NSW || BIG W/CNR ALPHA & PATRI || BIG W/CNR ALPHA & PATRIBLACKTOWN NSW",
    Email: "bigwcontactus@bigw.com.au",
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  }
];

export let merchantDetailsByChainNameAndShoppingCentresResponse: MerchantDetailsByChainNameAndShoppingCentre[] = [
  {
    LWC_ID: 1000613947,
    MerchantType: "Branch",
    ABN: "31006082551",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "HOYTS",
    DataCompletnessScore: 100,
    UpdatedDate: "2021-07-13T07:32:00",
    PrimaryName: "Hoyts (Westpoint Shopping Centre)",
    PrimaryCategory: "Entertainment >> Cinema",
    PrimaryAddress: "4/17 Patrick St, Blacktown NSW 2148, Australia",
    LocatedIn_Name: "Westpoint Blacktown",
    LocatedIn_Id: 5,
    StreetViewAvailable: null,
    Phone: "(02) 9003 3810",
    URL: "http://www.hoyts.com.au/cinemas/locations/blacktown.aspx",
    CALs: " HOYTS CORPORATION BLACKTOWN NSW || THE HOYTS CORPORATIO BLACKTOWN NSW || HOYTS BLACKTOWN BLACKTOWN NSW || HOYTS CORP PTY LTD BLACKTOWN NSW || THE HOYTS CORPORATION BLACKTOWN NSW",
    PrimaryEmail: "noreply@hoyts.com.au",
    RecordLocked: false,
    IsQuarantined: false,
    SquareLogo: "https://images.lookwhoscharging.com/c04041df-9dc7-4b44-b844-90cb2c4a5557/Hoyts-Westpoint-logo-image.png",
    CircularLogo: "https://images.lookwhoscharging.com/c04041df-9dc7-4b44-b844-90cb2c4a5557/Hoyts-Westpoint-cirl-image.png",
    ThreeToOneLogo: "https://images.lookwhoscharging.com/c04041df-9dc7-4b44-b844-90cb2c4a5557/Hoyts-Westpoint-3x1l-image.png",
    SquareLogoDark: "https://images.lookwhoscharging.com/c04041df-9dc7-4b44-b844-90cb2c4a5557/Hoyts-Westpoint-Shopping-Centre-dmlo-image.png",
    CircularLogoDark: "https://images.lookwhoscharging.com/c04041df-9dc7-4b44-b844-90cb2c4a5557/Hoyts-Westpoint-Shopping-Centre-dmci-image.png",
    ThreeToOneLogoDark: "https://images.lookwhoscharging.com/c04041df-9dc7-4b44-b844-90cb2c4a5557/Hoyts-Westpoint-Shopping-Centre-dm31-image.png",
    QuaratinedCALs: null,
    Tags: "Light Coding",
    BSBs: "019-696, 012-999",
    CurrentBusinessStatus: "Active"
  }
];
