import { propertyOf } from "../../core/services/reflection.service";

export interface MerchantDetails {
  LWC_ID: number;
  LWC_GUID?: string;
  IsHeadOffice: boolean;
  IsBranchOffice: boolean;
  IsFranchise: boolean;
  ABN: number;
  OnlineOnly: boolean;
  ChainName: string;
  DataCompletnessScore: number;
  UpdatedDate: string;
  PrimaryName: string;
  PrimaryCategory: string;
  PrimaryAddress: string;
  StreetViewAvailable: boolean;
  CALs: string;
  Phone: string;
  URL: string;
  AUS_Indexed_MerchantNames?: MerchantName[];
  AUS_Indexed_AssociatedWith?: AssociatedWithID[];
  AUS_Indexed_ContactMethods?: ContactMethod[];
  AUS_Indexed_CardAcceptorLocators_Known?: CardAcceptorLocator_Known[];
  MerchantPresence?: string;
  MerchantType?: string;
  RecordLocked?: boolean;
  IsQuarantined?: boolean;
  CurrentBusinessStatus?: string;
  Tags?: string;
  BSBs?: string;
  PrimaryEmail?: string
  LocatedIn?: string
}

export interface MerchantName extends WithIsPrimaryAndIsActive {
  MerchantNamesID: number;
  MerchantName: string;
}

export interface AssociatedWithID extends WithIsActive {
  AUS_Indexed_AssociatedWithID: number;
  Source: string;
}

export interface ContactMethod extends WithIsPrimaryAndIsActive {
  AUS_Indexed_ContactMethodID: number;
  DisplayValue: string;
}

export interface WithIsPrimary {
  IsPrimary: boolean;
}

export interface WithIsPrimaryAndIsActive extends WithIsPrimary, WithIsActive {
}

export interface WithIsActive {
  IsActive: boolean;
}

export interface CardAcceptorLocator_Known {
  OriginalSearchString: string;
}

export const merchantDetailsSchemaProps = {
  LWC_ID: propertyOf<MerchantDetails>('LWC_ID'),
  ChainName: propertyOf<MerchantDetails>('ChainName'),
  Phone: propertyOf<MerchantDetails>('Phone'),
  URL: propertyOf<MerchantDetails>('URL'),
  DataCompletnessScore: propertyOf<MerchantDetails>('DataCompletnessScore'),
  UpdatedDate: propertyOf<MerchantDetails>('UpdatedDate'),
  PrimaryName: propertyOf<MerchantDetails>('PrimaryName'),
  PrimaryCategory: propertyOf<MerchantDetails>('PrimaryCategory'),
  PrimaryAddress: propertyOf<MerchantDetails>('PrimaryAddress'),
  StreetViewAvailable: propertyOf<MerchantDetails>('StreetViewAvailable'),
  CALs: propertyOf<MerchantDetails>('CALs'),
  MerchantPresence: propertyOf<MerchantDetails>('MerchantPresence'),
  MerchantType: propertyOf<MerchantDetails>('MerchantType'),
  RecordLocked: propertyOf<MerchantDetails>('RecordLocked'),
  IsQuarantined: propertyOf<MerchantDetails>('IsQuarantined'),
  ABN: propertyOf<MerchantDetails>('ABN'),
  CurrentBusinessStatus: propertyOf<MerchantDetails>('CurrentBusinessStatus'),
  Tags: propertyOf<MerchantDetails>('Tags'),
  BSBs: propertyOf<MerchantDetails>('BSBs'),
  PrimaryEmail: propertyOf<MerchantDetails>('PrimaryEmail'),
  LocatedIn: propertyOf<MerchantDetails>('LocatedIn'),
}

export interface MerchantShoppingCentre {
  locatedIn_ID: number;
  locatedIn_Name: string;
}

export interface MerchantDetailsByShoppingCentre {
  LWC_ID: number;
  MerchantType: string;
  ABN: string;
  MerchantPresence: string;
  ChainName: string;
  DataCompletnessScore: number;
  UpdatedDate: string;
  PrimaryName: string;
  PrimaryCategory: string;
  PrimaryAddress: string;
  LocatedIn_Name: string;
  LocatedIn_Id: number;
  StreetViewAvailable: boolean;
  Phone: string;
  URL: string;
  CALs: string;
  Email: string;
  RecordLocked: boolean;
  IsQuarantined: boolean;
  QuaratinedCALs: null,
  CurrentBusinessStatus: string;
  Tags: string;
}

export interface MerchantDetailsByShoppingCentreDisplay extends MerchantDetailsByShoppingCentre {
  UpdatedDateDisplay?: string;
  StreetViewAvailableDisplay?: string;
  RecordLockedDisplay?: string;
  IsQuarantinedDisplay?: string;
}

export interface MerchantDetailsByChainNameAndShoppingCentre {
  LWC_ID: number;
  MerchantType: string;
  ABN: string;
  MerchantPresence: string;
  ChainName: string;
  DataCompletnessScore: number;
  UpdatedDate: string;
  PrimaryName: string;
  PrimaryCategory: string;
  PrimaryAddress: string;
  LocatedIn_Name: string;
  LocatedIn_Id: number;
  StreetViewAvailable: boolean;
  Phone: string;
  URL: string;
  CALs: string;
  PrimaryEmail: string;
  RecordLocked: boolean;
  IsQuarantined: boolean;
  SquareLogo: string;
  CircularLogo: string;
  ThreeToOneLogo: string;
  SquareLogoDark: string;
  CircularLogoDark: string;
  ThreeToOneLogoDark: string;
  QuaratinedCALs: string;
  Tags: string;
  BSBs: string;
  CurrentBusinessStatus: string;
}

export interface MerchantDetailsByChainNameAndShoppingCentreDisplay extends MerchantDetailsByChainNameAndShoppingCentre {
  UpdatedDateDisplay?: string;
  StreetViewAvailableDisplay?: string;
  RecordLockedDisplay?: string;
  IsQuarantinedDisplay?: string;
}

export interface ChainNameAndLocatedInId {
  chainName: string;
  locatedIn_ID: string;
}

export interface SuburbList {
  value: string;
  viewValue: string;
}
