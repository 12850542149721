import { Component, Input } from '@angular/core';
import { MenuItem } from './menuitem';
import { MenuItemType } from '../../../enums/enums';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menuitem.component.html',
  styleUrls: ['./menuitem.component.scss']
})
export class MenuItemComponent {
  menuItemType = MenuItemType;
  @Input() menuItem: MenuItem;

  constructor() { }
}
