export enum PurgeTaskResult {
  Success,

  Failure,

  NotRequired
}
export interface PurgeResult {
  LwcId: number;
  RemovedFromIndexDB: PurgeTaskResult;
  RemovedFromPredictedSearchDB: PurgeTaskResult;
  RemovedFromSuggestionSearchDB: PurgeTaskResult;
  RemovedFromNoSQLDBs: PurgeTaskResult;
  RemovedFromImageRepository: PurgeTaskResult;
  RemovedFromElasticSearch: PurgeTaskResult;
  RemovedFromIndexSourceDB: PurgeTaskResult;
  Message: string;
  Success: boolean;
}
