import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, Injector, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ManageChainsDataService } from '../managechainsdata.service';
import {
  matchConfidenceNames, chainDensityNames, RouteConstants, lookupTypeMap,
  lookupOptionTypeStringValues
} from '../../../core/constants/constants';
import { SelectedCategory } from '../../../shared/category/selectedcategory';
import { ChainDto, TransactionBank } from '../../../models/ausindexingresult';
import { ENTER, COMMA, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router, ActivatedRoute } from '@angular/router';
import { UpsertChainRequest } from './upsertchainrequest';
import { MerchantlookupService } from '../../../services/merchantlookup.service';
import { LoadingService } from '../../../core/uiservices/loading.service';
import { finalize, distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { chainTypeStringValues } from '../../../core/constants/chains';
import { MatchConfidence, CategoryType } from '../../../enums/enums';
import { NgForm, NgModel, FormControl } from '@angular/forms';
import { NotificationService } from '../../../core/uiservices/notification.service';
import { DialogService } from '../../../core/uiservices/dialog.service';
import { LookupOptionService } from '../../../services/lookupoption.service';
import { ValueCheckerService } from '../../../core/services/valuechecker.service';
import { ChainType } from '../../indexmerchant/chaintype';
import { TestChainPatternsRequest } from '../../../chains/testchainpatternsrequest';
import { ChainPatternMatch } from '../../../chains/chainpatternmatch';
import { TestChainResultToDisplay, ReviewPatternKnownMatchToDisplay, ChainReviewOrTestResult } from './testresulttodisplay';
import { CollectionTransformerService } from '../../../core/collections/collectiontransformer.service';
import { ReviewChainPatternsRequest } from '../../../chains/reviewchainpatternsrequest';
import { ChainPatternKnownMatch } from '../../../chains/chainpatternknownmatch';
import { WithChainId } from '../../../chains/withchainid';
import { countryCodes } from '../../../core/i18n/countrycodes';
import { merchantPresenceDisplayStringValues } from '../../../enums/enumdisplayvalues';
import { MatSelectChange } from '@angular/material/select';
import { BaseComponent } from '../../../shared/base/base.component';
import { MerchantSummaryJsonViewerService } from '../../../services/merchantsummaryjsonviewer.service';
import { DataSource, SearchSelectBase } from '@oasisdigital/angular-material-search-select';
import { SearchSelectService } from '../../../services/searchselect.service';
import { StringNameValue } from '../../../core/types/stringnamevalue';
import { EnrichMerchantService } from '../../../services/enrichmerchant.service';
import { StringService } from '../../../core/formatting/string.service';
import { QuillEditorService } from '../../../core/formatting/quilleditor.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Tag } from '../../../models/tag';
import { TagsService } from '../../../services/tags.service';
import { TransactionService } from '../../../services/transaction.service';
import { RegionStateService } from '../../../core/cache/regionstate.service';
import { RegionType } from '../../../users/userenums';

@Component({
  selector: 'app-editchain',
  templateUrl: './editchain.component.html',
  styleUrls: ['./editchain.component.scss']
})
export class EditChainComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

  chainDto: ChainDto;
  matchConfidenceNames = matchConfidenceNames.filter(c => c.value !== MatchConfidence.Medium);
  chainDensityNames = chainDensityNames;
  categoryTypes: CategoryType[] = [CategoryType.Expense];
  textToMatch: string;
  testChainResultsToDisplay: TestChainResultToDisplay[] = [];
  reviewPatternMatches: ReviewPatternKnownMatchToDisplay[] = [];
  reviewPatternRequired = false;
  noTestResultsFound = false;
  isMerchantLoading = false;
  merchantLookupResponseFirstPart: any = null;
  merchantLookupResponseSecondPart: any = null;
  merchantNotFound: boolean = false;
  countryCodes = this.manageChainsDataService.moveAustraliaAndNewZealandCountriesToTop(countryCodes);
  queryTransactionProcess : string;
  urlToDisputeProcessPage : string;
  quillEditorStyle = { height: '110px' };
  showHistory: boolean = false;
  templatesCtrl: FormControl;
  templatesDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  selectedTemplate: StringNameValue;
  qtpTemplates = this.enrichMerchantService.getQueryTransactionProcessTemplates();
  headingForChainNameLookupOptions = 'Name Lookup Options';
  headingForTransactionDescriptionLookupOptions = 'Transactions Lookup Options';
  isQueryTransactionProcessEmpty: boolean = true;
  chainTypeNameArray = this.manageChainsDataService.getFilteredChainTypeStringValueArray(this.regionStateService.getCurrentRegion());
  merchantPresenceTypesArray = this.collectionTransformerService.mapToArray(merchantPresenceDisplayStringValues);
  listOfSelectedTags: Tag[] = [];
  allTags: Tag[] = [];
  transactionBankLists: TransactionBank[] = [];
  private australiaCountryCode = 'AUS';
  private newzealandCountryCode = 'NZL';
  private nonInternationalCountryList = [this.australiaCountryCode, this.newzealandCountryCode];

  @ViewChild('templatesQueryTransactionSelect') templatesQueryTransactionSelect: SearchSelectBase;
  @ViewChild('editChainForm') editChainForm: NgForm;
  @ViewChildren('hqLwcIdInput') hqLwcIdInputQueryList: QueryList<NgModel>;

  constructor(private manageChainsDataService: ManageChainsDataService, private router: Router,
    private merchantLookupService: MerchantlookupService, private loadingService: LoadingService,
    private notificationService: NotificationService, private dialogService: DialogService,
    private lookupOptionService: LookupOptionService, private valueCheckerService: ValueCheckerService,
    private collectionTransformerService: CollectionTransformerService, private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService,
    private searchSelectService: SearchSelectService, private enrichMerchantService: EnrichMerchantService, private activatedRoute: ActivatedRoute,
    private stringService: StringService, private quillEditorService: QuillEditorService, private changeDetectorRef: ChangeDetectorRef,
    private tagsService: TagsService, private transactionService: TransactionService, private regionStateService: RegionStateService,
    injector: Injector) {
      super(injector)
    }

  ngOnInit() {
    this.activatedRoute.params.subscribe(chainId => {
      if (chainId['chainId']) {
        this.merchantLookupService.getChains().subscribe(result => {
          let lwcChainId = parseInt(this.activatedRoute.snapshot.params['chainId']);
          let chain = result.find(p => p.ChainId === lwcChainId);
          if (chain) {
            this.chainDto = chain;
            this.afterChainDtoIsSet();
          } else {
            this.router.navigate([RouteConstants.manageChains]);
          }
        });
      } else {
        this.chainDto = this.manageChainsDataService.getEditData();
        if (this.chainDto && this.chainDto.Tags) {
          this.tagsService.getTags().subscribe((tags) => {
              if (tags && tags.length > 0) {
                for (let tag of tags) {
                  this.allTags.push({ id: tag.id, label: tag.tagLabel });
                }
              }
          });
        }

        if (!this.chainDto) {
          this.router.navigate([RouteConstants.manageChains]);
        }

        this.afterChainDtoIsSet();
      }
      if (this.chainDto && this.chainDto.Tags && this.chainDto.Tags.length > 0) {
        this.listOfSelectedTags = this.chainDto.Tags;
      }
      if (this.isChainTypeBankMessagePattern()) {
        this.setTransactionBankList();
      }
    });
  }

  ngAfterViewInit(){
    this.hqLwcIdInputQueryList.changes.subscribe(i => {
      if(i.first) {
        this.setupHqLwcIdValueChanges(i.first);
      }
    });

    if(this.hqLwcIdInputQueryList.first) {
      this.setupHqLwcIdValueChanges(this.hqLwcIdInputQueryList.first);
    }
    this.afterChainDtoIsSet();
    this.changeDetectorRef.detectChanges();
  }

  queryTransactionProcessChanged(event: any) {
    if (this.quillEditorService.isEmpty(event.text, event.html)) {
       this.isQueryTransactionProcessEmpty = true;

       return;
    }
    this.isQueryTransactionProcessEmpty = false;
  }

  private afterChainDtoIsSet() {
    this.onChangeCountryCode(this.chainDto.CountryCodeAlpha3);

    if (this.chainDto && this.chainDto.HQ_LWC_ID) {
      this.lookupMerchant(this.chainDto.HQ_LWC_ID);
    }
    this.configureQtpTemplates();
  }

  private setupHqLwcIdValueChanges(hqLwcIdInput: NgModel) {
    hqLwcIdInput.valueChanges
      .pipe(debounceTime(1200), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((value: number) => {
        if (value) {
          this.lookupMerchant(value);
        }
      });
  }

  private lookupMerchant(cal: number){
    this.isMerchantLoading = true;
    this.merchantLookupResponseFirstPart = null;

    this.merchantLookupService.getCals(cal)
      .pipe(finalize(() => this.isMerchantLoading = false))
      .subscribe((response) => {
        this.merchantNotFound = (response.LWC_ID == null);
        this.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
        this.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
      });
  }

  onCountryChanged(event: MatSelectChange) {
    this.onChangeCountryCode(event.value);
  }

  private onChangeCountryCode(countryCode: string) {
    if (this.nonInternationalCountryList.includes(countryCode)) {
      this.chainDto.Location = null;
      let nzlCountryCode: string = RegionType[RegionType.NZL];
      let regionCode = RegionType.AUS;
      if (countryCode === nzlCountryCode) {
        regionCode = RegionType.NZL;
      }

      this.regionStateService.setCurrentRegion(regionCode);
      this.chainTypeNameArray = this.manageChainsDataService.getFilteredChainTypeStringValueArray(this.regionStateService.getCurrentRegion());
    }
    else {
      this.chainDto.ChainType = chainTypeStringValues.get(ChainType.MerchantPattern);
    }
  }

  ngOnDestroy() {
    this.manageChainsDataService.clearEditData();
  }

  addState(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if (this.stringService.isNonEmptyTrimmedStringExistInArray(value, this.chainDto.OperatesInStates)) {
      this.chainDto.OperatesInStates.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  removeState(state: string) {
    const index = this.chainDto.OperatesInStates.indexOf(state);

    if (index >= 0) {
      this.chainDto.OperatesInStates.splice(index, 1);
    }
  }

  addMcc(event: MatChipInputEvent) {
    const input = event.input;
    const value = parseInt(event.value);

    if ((value && !this.chainDto.AcceptedMCCs.includes(value))) {
      this.chainDto.AcceptedMCCs.push(value);
    }

    if (input) {
      input.value = '';
    }
  }

  removeMcc(mcc: number) {
    const index = this.chainDto.AcceptedMCCs.indexOf(mcc);

    if (index >= 0) {
      this.chainDto.AcceptedMCCs.splice(index, 1);
    }
  }

  handleCategorySelect(category: SelectedCategory) {
    if (category.isSelected) {
      this.chainDto.CategoryId = category.category.CategoryID;
    } else {
      this.chainDto.CategoryId = null;
    }
    this.changeDetectorRef.detectChanges();
  }

  cancel() {
    this.router.navigate([RouteConstants.manageChains]);
  }

  save() {
    if (this.editChainForm.invalid) {
      this.notificationService.notifyError('Please fill in required fields');

      return;
    }

    if (!this.lookupOptionService.validateLookupOptionsAndShowDialogIfItIsInvalid(this.chainDto.ChainNameLookupOptions)
      || !this.lookupOptionService.validateLookupOptionsAndShowDialogIfItIsInvalid(this.chainDto.TransactionDescriptionLookupOptions)) {
      return;
    }

    if (!this.checkIfLookupOptionsAreValid()) {
      return;
    }

    if (this.isChainNameLookupOptionsMandatory()
      && this.chainDto.ChainNameLookupOptions.length < 1) {
        this.notificationService.notifyError('At least one Name Lookup Option should be present');
        return;
    }

    if(!this.isNonInternationalCountry()) {
     this.chainDto.HQ_LWC_ID = 0;
    }

    if (!this.chainDto.HQ_LWC_ID) {
      this.chainDto.HQ_LWC_ID = 0;
    }

    if (this.valueCheckerService.isEmptyNullOrUndefined(this.chainDto.DefaultBusinessDescription)) {
      this.chainDto.DefaultBusinessDescription = this.chainDto.ShortUserMessage;
    }

    if (this.isQueryTransactionProcessEmpty) {
      this.chainDto.QueryTransactionProcess = null;
    }

    if (this.listOfSelectedTags.length > 0 ) {
      this.chainDto.Tags = this.listOfSelectedTags;
    }

    this.chainDto.ChainName = this.chainDto.ChainName.trim().replace(/[’]/g, "'");

    if (!this.ifChainTypeEqualBranchOrHeadofficeOrFranchiseOrBoth()) {
      this.chainDto.distinctPhoneNoForEachBranch = false;
      this.chainDto.distinctUrlForEachBranch = false;
    }
    else {
      this.chainDto.distinctPhoneNoForEachBranch = this.chainDto.distinctPhoneNoForEachBranch === undefined
        ? false
        : this.chainDto.distinctPhoneNoForEachBranch;
      this.chainDto.distinctUrlForEachBranch = this.chainDto.distinctUrlForEachBranch === undefined
        ? false
        : this.chainDto.distinctUrlForEachBranch;
    }

    let upsertChainRequest: UpsertChainRequest = {
      OperationTrigger: 'IndexManagerClient',
      Chain: this.chainDto
    };
    this.loadingService.setLoading();
    this.merchantLookupService.saveChain(upsertChainRequest).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((chain) => {
        if (this.chainDto.ChainId) {
          let editedChainIndex = this.manageChainsDataService.chains.findIndex(c => c.ChainId == this.chainDto.ChainId);

          this.manageChainsDataService.filterOutEmptyOperatesInStates(chain);

          this.manageChainsDataService.chains[editedChainIndex] = chain;
        } else {
          this.manageChainsDataService.chains.unshift(chain);
        }
        this.router.navigate([RouteConstants.manageChains]);
      })
  }

  ifChainTypeEqualSpecialPattern() {
    return this.chainDto.ChainType === chainTypeStringValues.get(ChainType.ATMPattern)
      || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.MerchantPattern)
      || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.Salary)
      || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.BankMessagePattern)
      || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.InvalidCalPattern);
  }

  ifChainTypeEqualBranchOrHeadofficeOrFranchiseOrBoth() {
    return this.chainDto.ChainType === chainTypeStringValues.get(ChainType.Branch)
      || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.HeadOffice)
      || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.Franchise)
      || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.Both);
  }

  isChainTypeBankMessagePattern() {
    return this.chainDto?.ChainType === chainTypeStringValues.get(ChainType.BankMessagePattern);
  }

  isChainTypeInvalidCalPattern() {
    return this.chainDto?.ChainType === chainTypeStringValues.get(ChainType.InvalidCalPattern);
  }


  isChainTypeMerchantPatternAndCountryCodeIsNotAustralia() {
    return this.chainDto.ChainType === chainTypeStringValues.get(ChainType.MerchantPattern) && this.chainDto.CountryCodeAlpha3 !== this.australiaCountryCode;
  }

  isChainTypeMerchantPatternAndCountryCodeIsNotNonInternationalCountry() {
    return this.chainDto.ChainType === chainTypeStringValues.get(ChainType.MerchantPattern) && !this.nonInternationalCountryList.includes(this.chainDto.CountryCodeAlpha3);
  }

  isAustralia() {
    return this.chainDto.CountryCodeAlpha3 == this.australiaCountryCode;
  }

  isNonInternationalCountry()
  {
    return (this.nonInternationalCountryList.includes(this.chainDto.CountryCodeAlpha3))
  }

  testMatchPattern() {
    let testChainPatternsRequest: TestChainPatternsRequest = {
      ChainId: 0,
      TextToMatch: this.textToMatch,
      ChainType: this.collectionTransformerService.mapToArray(chainTypeStringValues)
        .find(chainType => chainType.value === this.chainDto.ChainType).key,
      TransactionDescriptionLookupOptions: this.chainDto.TransactionDescriptionLookupOptions,
      ChainNameLookupOptions: this.chainDto.ChainNameLookupOptions
    }

    this.setChainIdForWithChainIdIfNeeded(testChainPatternsRequest);

    this.loadingService.setLoading();

    this.testChainResultsToDisplay = [];

    this.merchantLookupService.testChainMatchPattern(testChainPatternsRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(testResults => {
        if (testResults.length === 0) {
          this.noTestResultsFound = true;
          return;
        }
        this.noTestResultsFound = false;

        this.createTestResultsToDisplay(testResults, this.testChainResultsToDisplay);
      });
  }

  isTestButtonDisabled() {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.textToMatch)
      && !this.valueCheckerService.isEmptyNullOrUndefined(this.textToMatch.trim())) {
      return false;
    }

    return true;
  }

  isSaveButtonDisabled() {
    return this.reviewPatternRequired
    || (this.editChainForm && this.editChainForm.invalid)
    || (this.valueCheckerService.isNullOrUndefined(this.chainDto.TransactionDescriptionLookupOptions)
    || this.chainDto.TransactionDescriptionLookupOptions.length === 0);
  }

  onLookupOptionsUpdated() {
    this.reviewPatternRequired = true;
  }

  reviewPatterns() {
    if (!this.checkIfLookupOptionsAreValid()) {
      return;
    }

    let reviewChainPatternsRequest: ReviewChainPatternsRequest = {
      ChainId: 0,
      ChainType: this.collectionTransformerService.mapToArray(chainTypeStringValues)
        .find(chainType => chainType.value === this.chainDto.ChainType).key,
      AcceptedMCCs: this.chainDto.AcceptedMCCs,
      TransactionDescriptionLookupOptions: this.chainDto.TransactionDescriptionLookupOptions,
      ChainNameLookupOptions: this.chainDto.ChainNameLookupOptions
    }

    this.setChainIdForWithChainIdIfNeeded(reviewChainPatternsRequest);

    this.reviewPatternMatches = [];

    this.loadingService.setLoading();

    this.merchantLookupService.reviewChainRules(reviewChainPatternsRequest)
      .pipe(finalize(() => {
        this.reviewPatternRequired = false;
        this.loadingService.clearLoading()
      }))
      .subscribe(chainPatternMatches => {
        if (chainPatternMatches.length === 0) {
          this.notificationService.notifyError('No matches found');
        }

        this.createReviewPatternMatches(chainPatternMatches, this.reviewPatternMatches);
      });
  }

  previewQTPTemplate(template: string) {
    return this.enrichMerchantService.previewQTPTempate(this.merchantLookupResponseSecondPart, template);
  }

  generateQTP(template: StringNameValue) {
    let value = this.previewQTPTemplate(template.value);
    this.chainDto.QueryTransactionProcess = value;
  }

  getDistinctBranchMerchantLabel() {
    let distinctMerchants = 'Distinct Merchants';
    if (this.chainDto.ChainType === chainTypeStringValues.get(ChainType.MerchantPattern) || chainTypeStringValues.get(ChainType.InvalidCalPattern)) {
      return distinctMerchants;
    }
    else {
      return distinctMerchants = 'Distinct/Branch';
    }
  }

  isDistinctBranchMerchantVisible() {
    return !this.ifChainTypeEqualSpecialPattern() || this.chainDto.ChainType === chainTypeStringValues.get(ChainType.MerchantPattern) ||
      this.chainDto.ChainType === chainTypeStringValues.get(ChainType.InvalidCalPattern);
  }

  selectTag(event: MatAutocompleteSelectedEvent) {
    if (!this.listOfSelectedTags.includes(event.option.value)) {
      this.listOfSelectedTags.push(event.option.value);
    }
  }

  removeTag(tag: Tag) {
    if (this.listOfSelectedTags.includes(tag)) {
      let indexOfTheTagToRemove = this.listOfSelectedTags.indexOf(tag);
      this.listOfSelectedTags.splice(indexOfTheTagToRemove, 1);
    }
  }

  chainTypeSelected() {
    if (this.isChainTypeBankMessagePattern()) {
      this.setTransactionBankList();
    }
  }

  shouldDisplayMerchantSummary() {
    return this.isNonInternationalCountry() && this.merchantLookupResponseFirstPart;
  }

  private isChainNameLookupOptionsMandatory() {
    return this.chainDto.ChainType !== chainTypeStringValues.get(ChainType.ATMPattern)
    && this.chainDto.ChainType !== chainTypeStringValues.get(ChainType.MerchantPattern)
    && this.chainDto.ChainType !== chainTypeStringValues.get(ChainType.InvalidCalPattern)
    && this.chainDto.ChainType !== chainTypeStringValues.get(ChainType.Salary)
    && this.chainDto.ChainType !== chainTypeStringValues.get(ChainType.BankMessagePattern)
    && this.chainDto.ChainType !== chainTypeStringValues.get(ChainType.Both);
  }

  private setChainIdForWithChainIdIfNeeded(withChainId: WithChainId) {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.chainDto.ChainId)) {
      withChainId.ChainId = this.chainDto.ChainId;
    }
  }

  private createReviewPatternMatches(chainPatternKnownMatches: ChainPatternKnownMatch[],
    reviewPatternMatches: ReviewPatternKnownMatchToDisplay[]) {

    for (let chainPatternKnownMatch of chainPatternKnownMatches) {
      let reviewPatternKnownMatchToDisplay = <ReviewPatternKnownMatchToDisplay>{};

      this.fillReviewPatternToDisplay(chainPatternKnownMatch, reviewPatternKnownMatchToDisplay);

      reviewPatternMatches.push(reviewPatternKnownMatchToDisplay);
    }
  }

  private createTestResultsToDisplay(chainPatternMatches: ChainPatternMatch[], testChainResultsToDisplay: TestChainResultToDisplay[]) {
    for (let chainPatternMatch of chainPatternMatches) {
      let testChainResultToDisplay = <TestChainResultToDisplay>{}

      this.fillTestResultToDisplay(chainPatternMatch, testChainResultToDisplay);

      testChainResultsToDisplay.push(testChainResultToDisplay);
    }
  }

  private fillReviewPatternToDisplay(chainPatternKnownMatch: ChainPatternKnownMatch,
    chainPatternKnownMatchToDisplay: ReviewPatternKnownMatchToDisplay) {

    chainPatternKnownMatchToDisplay.ChainName = '';
    chainPatternKnownMatchToDisplay.ChainType = '';
    chainPatternKnownMatchToDisplay.MatchedText = chainPatternKnownMatch.MatchedText;
    chainPatternKnownMatchToDisplay.SearchString = chainPatternKnownMatch.MatchedPattern.SearchString;
    chainPatternKnownMatchToDisplay.LookupType = lookupTypeMap.get(chainPatternKnownMatch.MatchedPattern.LookupType);
    chainPatternKnownMatchToDisplay.MatchConfidence = matchConfidenceNames
      .find(m => m.value === chainPatternKnownMatch.MatchConfidence).name;
    chainPatternKnownMatchToDisplay.LookupOptionType = lookupOptionTypeStringValues
      .get(chainPatternKnownMatch.LookupOptionType);

    this.setChainNameAndTypeForObjectToDisplay(chainPatternKnownMatch, chainPatternKnownMatchToDisplay);
  }

  private fillTestResultToDisplay(chainPatternMatch: ChainPatternMatch, testChainResultToDisplay: TestChainResultToDisplay) {
    testChainResultToDisplay.ChainName = '';
    testChainResultToDisplay.ChainType = '';
    testChainResultToDisplay.SearchString = chainPatternMatch.MatchedPattern.SearchString;
    testChainResultToDisplay.LookupType = lookupTypeMap.get(chainPatternMatch.MatchedPattern.LookupType);
    testChainResultToDisplay.LookupOptionType = lookupOptionTypeStringValues.get(chainPatternMatch.LookupOptionType);
    testChainResultToDisplay.MatchConfidence = matchConfidenceNames
      .find(m => m.value === chainPatternMatch.MatchConfidence).name;

    this.setChainNameAndTypeForObjectToDisplay(chainPatternMatch, testChainResultToDisplay);
  }

  private setChainNameAndTypeForObjectToDisplay(withChainId: WithChainId, objectToDisplay: ChainReviewOrTestResult) {
    let chainName = "";
    let chainType: string | 0 = "";

    if (withChainId.ChainId === 0) {
      chainName = this.chainDto.ChainName;
      chainType = this.chainDto.ChainType;
    } else {
      let chain = this.manageChainsDataService.chains.find(chain => chain.ChainId === withChainId.ChainId);

      if (this.valueCheckerService.isNullOrUndefined(chain)) {
        chainName = 'unknown';
        chainType = 'unknown';
      } else {
        chainName = chain.ChainName;
        chainType = chain.ChainType;
      }
    }

    objectToDisplay.ChainName = chainName;
    objectToDisplay.ChainType = chainType;
  }

  private checkIfLookupOptionsAreValid() {
    if (this.valueCheckerService.isNullOrUndefined(this.chainDto.TransactionDescriptionLookupOptions)
      || this.chainDto.TransactionDescriptionLookupOptions.length === 0) {
      this.dialogService.openDialog('Required at least one Transaction Lookup Option');

      return false;
    }

    if (!this.valueCheckerService.isNullOrUndefined(this.chainDto.ChainNameLookupOptions) && this.chainDto.ChainNameLookupOptions.length > 0) {
      for (let lookupOption of this.chainDto.ChainNameLookupOptions) {
        if (!lookupOption.SearchString) {
          this.dialogService.openDialog('Search String in Name Lookup Option is required');

          return false;
        }
      }
    }

    for (let lookupOption of this.chainDto.TransactionDescriptionLookupOptions) {
      if (!lookupOption.SearchString) {
        this.dialogService.openDialog('Search String in Transaction Lookup Option is required');

        return false
      }
    }

    return true;
  }

  private configureQtpTemplates() {
    this.templatesCtrl = new FormControl();
    this.templatesDataSource = this.enrichMerchantService.getTeplatesDatasource(this.qtpTemplates);
    this.templatesCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(template => {
      if (template) {
        this.selectedTemplate = { name: template.display, value: template.value };
      } else {
        this.selectedTemplate = null;
      }
    });

    if (!this.valueCheckerService.isNullOrUndefined(this.templatesQueryTransactionSelect)) {
      this.templatesQueryTransactionSelect.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(template => {
        if (!this.valueCheckerService.isNullOrUndefined(template) && typeof template === 'object') {
          this.selectedTemplate = { name: template.value.name, value: template.value.value };
        } else {
          this.selectedTemplate = null;
        }
      });
    }
  }

  private setTransactionBankList() {
    if (this.transactionBankLists.length === 0) {
      this.loadingService.setLoading();
      this.transactionService.getTransactionBankList()
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          this.loadingService.clearLoading();
          this.transactionBankLists = response.data;
        });
    }
  }
}
