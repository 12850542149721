import { Injectable } from '@angular/core';

@Injectable()
export class BaseFormatService {

  constructor() { }

  formatPropertyInArray<T>(array: T[], propName: string, formatFunc: (input: string) => string) {
    let newArray = [...array];

    for (let i = 0; i < newArray.length; i++) {
      newArray[i] = this.formatProperty(newArray[i], propName, formatFunc);
    }

    return newArray;
  }

  private formatProperty<T>(object: T, propName: string, formatFunc: (input: string) => string): T {
    return { ...object, [propName]: formatFunc(<string>(<any>object)[propName]) };
  }
}
