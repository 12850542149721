import { apiPathConstants } from './../../core/api/apipathconstants';
import { Observable } from 'rxjs';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { unverifiedMerchantsResponse } from '../mockjsonresponses/operationalchecksresponse';

export function operationalChecksBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includeUnverifiedMerchants = false;
  if (request.body && request.body.query) {
    includeUnverifiedMerchants = request.body.query.includes('unverifiedMerchants') &&
      Object.keys(request.body.variables).includes('chainCode') &&
      request.body.variables.chainCode === null;
  }

  if (cleanUrl.includes(apiPathConstants.graphql) && method === 'POST' && includeUnverifiedMerchants) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...unverifiedMerchantsResponse }
      }));
      resp.complete();
    })
  }
}
