import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiClientDataService } from '../apiclients/apiclientsdata.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { ApiClientDTO } from '../apiclients/apiclientdto';
import { MerchantSubscriptionLookup } from './subscriptionsbymerchant.models';
import { SubscriptionsByMerchantService } from '../../services/subscriptionsbymerchant.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

export type MerchantSubscriptionLookupResponse = {
  merchantsubscriptionlookup: MerchantSubscriptionLookup[];
}

@Component({
  selector: 'app-subscriptionsbymerchant',
  templateUrl: './subscriptionsbymerchant.component.html',
  styleUrls: ['./subscriptionsbymerchant.component.scss']
})
export class SubscriptionsByMerchantComponent implements OnInit {
  lwcGuid: number;
  apiClients: ApiClientDTO[] = [];
  apiKeyNamesFormGroup: FormGroup;
  merchantSubscriptionLookup: MerchantSubscriptionLookup[] = [];
  filteredMerchantSubscriptionLookup: MerchantSubscriptionLookup[] = [];
  filterForCal: string;
  numberOfApiClients: number = 0;
  firstGroupOfApiClients: ApiClientDTO[] = [];
  secondGroupOfApiClients: ApiClientDTO[] = [];

  constructor(private apiClientDataService: ApiClientDataService, private loadingService: LoadingService,
    private fb: FormBuilder, private subscriptionsByMerchantService: SubscriptionsByMerchantService,
    private valueCheckerService: ValueCheckerService) { }

  ngOnInit() {
    this.setApiClients();
  }

  setMerchantSubscriptions() {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.lwcGuid) && this.apiKeyNamesFormGroup.value.clientIds.length > 0) {
      this.subscriptionsByMerchantService.getMerchantSubscriptions(this.lwcGuid, this.apiKeyNamesFormGroup.value.clientIds)
        .pipe(finalize(() => this.loadingService.clearLoading))
        .subscribe(response => {
          if (response && response.data && response.data.merchantsubscriptionlookup) {
            this.merchantSubscriptionLookup = this.filteredMerchantSubscriptionLookup = response.data.merchantsubscriptionlookup;
          }
        });
    }
  }

  onCheckBoxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.clientIds.push(new FormControl(event.source.value));
    }
    else {
      for (let i = 0; i < this.clientIds.controls.length; i++) {
        if (this.clientIds.controls[i].value == event.source.value) {
          this.clientIds.removeAt(i);
        }
      }
    }
  }

  get clientIds() {
    return this.apiKeyNamesFormGroup.get("clientIds") as FormArray;
  }

  handleClearInputForCal() {
    this.filterForCal = '';
    this.filterMerchantSubscriptions();
  }

  filterByCal(event: KeyboardEvent) {
    this.filterForCal = (<HTMLInputElement>event.target).value;
    this.filterMerchantSubscriptions();
  }

  private setApiClients() {
    this.apiClientDataService.getApiClients(true)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.data) {
          this.apiClients = response.data.filter(t => t.isSubscriber === true);

          this.numberOfApiClients = this.apiClients.length;
          let splitIndex = Math.round(this.numberOfApiClients / 2);
          this.firstGroupOfApiClients = this.apiClients.slice(0, splitIndex);
          this.secondGroupOfApiClients = this.apiClients.slice(splitIndex);

          this.apiKeyNamesFormGroup = this.fb.group({clientIds: this.fb.array([])});
        }
      });
  }

  private filterMerchantSubscriptions() {
    this.filteredMerchantSubscriptionLookup = this.merchantSubscriptionLookup.filter((merchantSubscribtion: MerchantSubscriptionLookup) => {
      let isMatch = true;

      if (!this.valueCheckerService.isEmptyNullOrUndefined(this.filterForCal)) {
        isMatch = merchantSubscribtion.Cal.toLowerCase().includes(this.filterForCal.toLowerCase());
      }
      return isMatch;
    });
  }

}
