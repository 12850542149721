import { Component, OnInit } from '@angular/core';
import { SelectedCategory } from '../../../shared/category/selectedcategory';
import { InternationalChainDto } from '../../../models/internationalchaindto';
import { ManageInternationalChainsDataService } from '../manageinternationalchainsdata.service';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../core/constants/constants';
import { ChainUpdateType } from '../../../enums/enums';
import { InternationalChainUpdate } from '../internationalchainupdate';

@Component({
  selector: 'app-edit-international-chain',
  templateUrl: './editinternationalchain.component.html',
  styleUrls: ['./editinternationalchain.component.scss']
})
export class EditInternationalChainComponent implements OnInit {
  internationalChainDto: InternationalChainDto;
  constructor(private manageInternationalChainsDataService: ManageInternationalChainsDataService, private router: Router) { }

  ngOnInit() {
    this.internationalChainDto = this.manageInternationalChainsDataService.getEditData();
  }

  ngOnDestroy() {
    this.manageInternationalChainsDataService.clearEditData();
  }

  handleCategorySelect(category: SelectedCategory) {
    if (category.isSelected) {
      this.internationalChainDto.StandardCategory = category.category;
    } else {
      this.internationalChainDto.StandardCategory = null;
    }
  }

  cancel() {
    this.router.navigate([RouteConstants.manageInternationalChains]);
  }

  save() {
    let chainUpdate: InternationalChainUpdate = {Chain: this.internationalChainDto, updatedType: null};

    if(this.internationalChainDto.LWC_Guid) {
      chainUpdate.updatedType = ChainUpdateType.Updated;
      let editedChainIndex = this.manageInternationalChainsDataService.chains.findIndex(c => c.LWC_Guid == this.internationalChainDto.LWC_Guid);
      this.manageInternationalChainsDataService.chains[editedChainIndex] = this.internationalChainDto;
    } else {
      chainUpdate.updatedType = ChainUpdateType.Added;
      this.manageInternationalChainsDataService.chains.unshift(this.internationalChainDto);
    }

    this.manageInternationalChainsDataService.pushToChainUpdate(chainUpdate);
    this.router.navigate([RouteConstants.manageInternationalChains]);
  }
}
