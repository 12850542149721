import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { Component, OnInit, Injector } from '@angular/core';
import { PastSearchService } from '../../services/pastsearch.service';
import { PastSearchState } from './pastsearch.state';
import { BaseStateComponent } from '../basestate/basestate.component';
import { BatchRequestService, BatchRequestStatus } from '../../services/batchsearchrequest.service';
import { MerchantBatchSearchRequest, ConcurrentRequest } from '../batchmerchantsearch/merchantbatchsearchrequest';
import { BatchSearchResult } from '../batchmerchantsearch/batchsearchresult';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { APISearchResultV4 } from '../../models/searchmodelsv4';
import { PastSearchesByCalRequest } from './pastsearch.request';
import { PastSearchesByFixedCalTransactionType } from './pastsearch.response';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { BankAccountTransactionTypeService } from '../../services/bankaccounttransactiontype.service';
import { BankAccountTransactionType } from '../../models/ausindexingresult';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LoginStateService } from '../../core/auth/loginstate.service';
import { RoleType } from '../../users/userenums';
import { Router } from '@angular/router';
import { PastSearchResultDisplay } from "./pastsearch.dto";
@Component({
  selector: 'app-pastsearch',
  templateUrl: './pastsearch.component.html',
  styleUrls: ['./pastsearch.component.scss']
})
export class PastSearchComponent extends BaseStateComponent<PastSearchState> implements OnInit {

  bankAccountTransactionTypes: BankAccountTransactionType[] = [];
  searchWasPerfomed = false;
  batchSearchModels: MerchantBatchSearchRequest[] = [];
  failedRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
  private batchSizeProvider = (r: MerchantBatchSearchRequest) => r.bank_transactions.length;
  batchSearhResults: BatchSearchResult<APISearchResultV4[]>[] = [];
  batchRequestStatus: BatchRequestStatus<APISearchResultV4[]> = {
    areMerchantsLoading: false,
    searchInProgressCount: 0,
    searchCompletedCount: 0,
    searchFailedCount: 0,
    batchSearhResults: []
  }
  includePaymentsCheckbox: boolean;
  isExportFlag: boolean;

  constructor(private notificationService: NotificationService, private loadingService: LoadingService,
    private pastSearchService: PastSearchService, injector: Injector, private batchRequestService: BatchRequestService,
    private csvComponentService: CsvComponentService, private dateTimeService: DateTimeService,
    private bankAccountTransactionTypeService: BankAccountTransactionTypeService, private loginStateService: LoginStateService,
    private router: Router) {
    super(injector);
    this.includePaymentsCheckbox = false;
    this.isExportFlag = false;
  }


  pastSearchGraphFor = [
    { key: 0, value: '0 Days' },
    { key: 30, value: '30 Days' },
    { key: 90, value: '90 Days' }
  ];

  ngOnInit() {
    this.state = {
      cal: '',
      noToSearch: 100,
      typeOfSearch: 0,
      allSearches: false,
      pastSearchResultDisplay: [],
      getSearchCountInDays: 30,
      bankAccountTransactionType: 'Unknown'
    };

    this.restoreState();
    this.setTransactionTypes();
    this.getApplicationUsers();
  }

  search() {
    this.loadingService.setLoading();
    let pastSearchesRequest: PastSearchesByCalRequest = {
      Cal: this.state.cal,
      NumberOfCandidatesToSearch: this.state.noToSearch,
      WithInText: true,
      AllSearchOrigins: true,
      GetSearchCountInDays: 0,
      BankAccountTransactionType: this.state.bankAccountTransactionType,
      IncludePaymentGateways: this.includePaymentsCheckbox
    }

    this.pastSearchService.getPastSearches(pastSearchesRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(data => {
        if (data) {
          if (data.length > 0) {
            this.state.pastSearchResultDisplay = data;
            for (let data of this.state.pastSearchResultDisplay) {
              if (data.SearchVolumeLog) {
                data.SearchVolumeLog = parseFloat(data.SearchVolumeLog.toFixed(2));
              }
              if (data.MinutesSinceLastTransaction) {
                data.MinutesSinceLastTransaction = parseFloat(data.MinutesSinceLastTransaction.toFixed(0));
              }
              if (data.LastSearched) {
                data.LastSearched = this.dateTimeService.formatWithHours(data.LastSearched);
              }
            }
            this.notificationService.notifySuccess();
          } else {
            this.state.pastSearchResultDisplay = null;
            this.notificationService.notify('No results found');
          }
        }
        else {
          this.state.pastSearchResultDisplay = null;
          this.notificationService.notify('No results found');
        }
      });
  }

  checkIncludePayment(event: MatCheckboxChange) {
    this.includePaymentsCheckbox = event.checked;
  }

  isSearchButtonDisabled() {
    return this.state.cal.length < 5 || this.state.noToSearch <= 0 || this.state.noToSearch > 100;
  }

  batchSearch() {
    this.searchWasPerfomed = true;
    this.batchSearchModels = this.prepareCalsForRequest(this.state.pastSearchResultDisplay);
    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestStatus.searchInProgressCount = 0;
    this.batchRequestStatus.searchCompletedCount = 0;
    this.batchRequestStatus.searchFailedCount = 0;

    let retryRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    let successRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];

    this.batchRequestService.doBatchRequest<MerchantBatchSearchRequest, APISearchResultV4[]>(this.batchSearchModels, this.batchSearchModels,
      this.batchSearchModels, retryRequests, successRequests, this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
      .subscribe((batchRequetStatus) => {
        this.refreshSearchResults(batchRequetStatus);
      });
  }

  prepareCalsForRequest(merchants: PastSearchesByFixedCalTransactionType[]) {
    let cals = [];
    for (let merch of merchants) {
      cals.push(merch.FixedCal);
    }
    return this.batchRequestService.prepareCalsForRequest(cals);
  }

  refreshSearchResults(batchREquestStatus: BatchRequestStatus<APISearchResultV4[]>) {
    this.batchRequestStatus = batchREquestStatus;
    if (this.batchRequestStatus.searchInProgressCount === 0) {
      this.batchSearhResults = this.batchRequestStatus.batchSearhResults.concat(batchREquestStatus.batchSearhResults);
      this.proccesBatchSearchResults();
    }
  }

  proccesBatchSearchResults() {
    let data: any = [];
    this.loadingService.setLoading();
    for (let result of this.batchRequestStatus.batchSearhResults) {
      data.push(...result.response);
    }
    for (let i of data) {
      for (let j of this.state.pastSearchResultDisplay) {
        if (i.merchant_search_results != null) {
          if (i.cal === j.FixedCal) {
            for (let k of i.merchant_search_results) {
              setTimeout(() => {
                if (k.merchant_details !== null) {
                  if (k.merchant_details.length >= 1) {
                    j.lwcId = k[0].LWC_ID;
                    j.tradingName = k.merchant_details[0].merchant_primary_name;
                    j.category = k.merchant_details[0].primary_category.category_name;
                  } else {
                    j.lwcId = k.LWC_ID;
                    j.tradingName = k.merchant_details?.merchant_primary_name;
                    j.category = k.merchant_details?.primary_category.category_name;
                  }
                }

              }, 3000);
            }
          }
        }
        j.result_code = data.filter((b: any) => b.cal === j.FixedCal).map((c: any) => c.result_code);
      }
    }
    this.loadingService.clearLoading();
  }

  getCsvOptions() {
    let obj = this.state.pastSearchResultDisplay[0];
    let extendedObject = this.state.pastSearchResultDisplay.find(r => r.hasOwnProperty('lwcId'));
    if (extendedObject) {
      obj = extendedObject;
    }
    obj = { ...obj };
    let options = this.csvComponentService.getCsvOptions(obj, 'PastsSearches.csv');

    return options;
  }

  retryFailedSearches() {
    let requests = this.failedRequests.map(d => d.data);
    this.failedRequests = [];
    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestService.doBatchRequest(requests, this.batchSearchModels, requests, [],
      [], this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
      .subscribe((batchREquestStatus) => {
        this.refreshSearchResults(batchREquestStatus);
      });
  }

  private setTransactionTypes() {
    this.loadingService.setLoading();
    this.bankAccountTransactionTypeService.getBankAccountTransactionTypes()
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response && response.data.length > 0) {
          this.bankAccountTransactionTypes = response.data;
          this.bankAccountTransactionTypes.sort((a, b) => {
            let firstType = a.name.toUpperCase();
            let secondType = b.name.toUpperCase();
            if (firstType < secondType) {
              return -1;
            }
            if (firstType > secondType) {
              return 1;
            }
            return 0;
          });
        }
      });
  }

  getApplicationUsers() {
    let user = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin, RoleType.IndexManagerGlobalAdministrator, RoleType.IndexManagerTeamLead]);
    if (user === true) {
      this.isExportFlag = true;
    } else {
      this.isExportFlag = false;
    }
  }

  goToEnrichMerchant(LwcId: string) {
    this.router.navigate(['/enrichmerchant', { lwcId: LwcId, cleanState: true }]);
  }
}
