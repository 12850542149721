import { SearchType } from '../../enums/enums';
import { CatalogSearchResult } from '../../models/cataloguesearchresult';

export let getCatalogueSearchResponse: CatalogSearchResult = {
  ABN: '885626362525',
  ACN: '998595151',
  MatchedName: 'Alex',
  IsMatch: true,
  BusinessNameID: 25466123651,
  NameMatchScore: 46.4,
  BusinessAddressID: 7454587,
  AddressDistanceMeters: 123.04,
  AddressToUse: 'Kiev str',
  Comment: 'some test comment',
  IsMLMatch: false,
  MLScore: 654.234,
  MatchType: SearchType.Exact,
  IsActiveNameRecord: true
};
