import { apiPathConstants } from './../../core/api/apipathconstants';
import { Observable } from "rxjs";
import { HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { HttpMethod } from '../../core/coreenums/coreenums';
import { tagAllocationPatternsResponse, tagAllocationPatternMatchResponse, tagAllocationPatternResponse } from '../mockjsonresponses/tagallocationpatterns.response';

export function tagAllocationPatternsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes(apiPathConstants.getByNewTag) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...tagAllocationPatternsResponse]
      }));
      resp.complete();
    })
  }

  if (cleanUrl.includes(apiPathConstants.tagAllocationPattern) && method === HttpMethod.Delete) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    })
  }

  let includeTextToMatch = false;
  if (request.body) {
    let keys = Object.keys(request.body);
    includeTextToMatch = keys.includes("TextToMatch");
  }

  if (cleanUrl.includes(apiPathConstants.tagAllocationPattern) && includeTextToMatch === false && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...tagAllocationPatternResponse}
      }));
      resp.complete();
    })
  }

  if (cleanUrl.includes(apiPathConstants.testNewPattern) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...tagAllocationPatternMatchResponse}
      }));
      resp.complete();
    })
  }
}
