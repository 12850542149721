import { apiClientAllPoliciesQuery, apiClientsOldQuery, hotChocolate } from './../../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { ApiClientDTOFormatted, ApiClientDTO, ApiClientPolicy } from "./apiclientdto";
import { Apollo } from "apollo-angular";
import { HmacKeysResponse } from "./apiclients.component";
import { apiClientsQuery, hmacKeysQuery, upsertAPIClientQuery } from "../../gqlconstants/gqlconstants";

@Injectable()
export class ApiClientDataService {
  private editApiClient: ApiClientDTOFormatted;

  constructor(private apollo: Apollo) { }

  saveApiClient(apiClient: Partial<ApiClientDTO>) {
    return this.apollo
      .use(hotChocolate)
      .mutate({
        mutation: upsertAPIClientQuery,
        variables: {
          aPIClientDto: apiClient
        }
      });
  }

  setEditData(apiClient: ApiClientDTOFormatted) {
    this.editApiClient = apiClient;
  }

  getEditData() {
    return this.editApiClient;
  }

  getApiClients(value: boolean) {
    if (value == true) {
      return this.apollo
        .use(hotChocolate)
        .watchQuery<ApiClientDTO[]>({
          query: apiClientsQuery
        })
        .valueChanges;
    } else {
      return this.apollo
        .use(hotChocolate)
        .watchQuery<ApiClientDTO[]>({
          query: apiClientsOldQuery
        })
        .valueChanges;
    }
  }

  getHmacKeys() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<HmacKeysResponse>({
        query: hmacKeysQuery
      })
      .valueChanges;
  }

  getApiClientAllPolicies() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<ApiClientPolicy[]>({
        query: apiClientAllPoliciesQuery
      })
      .valueChanges;
  }
}
