import { ExecutionResult } from 'graphql';
import { BrokenLinksResponse } from '../../components/brokenlinks/brokenlinks.response';

export let brokenLinksResponse: ExecutionResult<BrokenLinksResponse> = {
  data:
  {
    urlVerification:
      [
        {
          lwcId: 15978450001200,
          retryAttempts: 1,
          verifiedDate: '2020-09-20',
          comments: 'Indexed Merchant LWC_ID: 9001100000',
          url: 'http://brokenlink1.com'
        },
        {
          lwcId: 55978950001780,
          retryAttempts: 1,
          verifiedDate: '2020-09-20',
          comments: 'Website is not reachable.',
          url: 'http://brokenlink2.com'
        }
      ]
  }
};
