import { emptyString, personalDetailsRemovedMessage, tickIcon, percentIcon, redCloseIcon, limitOnTheNumberOfExportedRecords } from './../../core/constants/constants';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SuggestionDTODisplay } from './suggestiondto';
import { BaseStateComponent } from '../basestate/basestate.component';
import { SuggestionsState } from './suggestions.state';
import * as moment from 'moment';
import { NotificationService } from '../../core/uiservices/notification.service';
import { DateRangeOption, UnspecifiedTrueFalse } from '../../enums/enums';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { suggestedTestConstants } from '../../../../e2e/specs/suggestions/suggestionstestconstants';
import { BatchRequestService, BatchRequestStatus } from '../../services/batchsearchrequest.service';
import { MerchantBatchSearchRequest, ConcurrentRequest } from '../batchmerchantsearch/merchantbatchsearchrequest';
import { BatchSearchResult } from '../batchmerchantsearch/batchsearchresult';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { APISearchResultV4 } from '../../models/searchmodelsv4';
import { NumberService } from '../../core/uiservices/number.service';
import { cloneDeep }  from 'lodash';
import { timePickerTheme } from '../../core/constants/constants';
import { SearchFeedbackService } from '../../services/searchfeedback.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { FormControl } from '@angular/forms';
import { PageInfo } from '../../shared/models/pageinfo';
import { FeedbackDTO } from '../../shared/models/feedbackdto';
import { propertyOf } from '../../core/services/reflection.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html'
})
export class SuggestionsComponent extends BaseStateComponent<SuggestionsState> implements OnInit {
  loading = true;
  error: any;
  dateRangeOption = DateRangeOption;
  showHide = UnspecifiedTrueFalse;
  searchWasPerformed = false;
  failedRequests: ConcurrentRequest<MerchantBatchSearchRequest>[]= [];
  searchCalsSplitted: string[] =[];
  batchSearchModels: MerchantBatchSearchRequest[] = [];
  batchSearhResults: BatchSearchResult<APISearchResultV4[]>[] = [];

  primaryTheme: NgxMaterialTimepickerTheme = timePickerTheme;

  batchRequestStatus: BatchRequestStatus<APISearchResultV4[]> = {
    areMerchantsLoading: false,
    searchInProgressCount: 0,
    searchCompletedCount: 0,
    searchFailedCount: 0,
    batchSearhResults: []
  };

  editingRowIndex: number = -1;
  originalEditRow: FeedbackDTO;
  clientNames: string[];
  offset: number = 0;
  skip: number = 0;
  take: number = 50;
  totalElements: number;
  selectionType = SelectionType;
  selectedRecords: FeedbackDTO[] = [];
  transactionDescriptionControl = new FormControl();
  filterByMinimunSuggestionIdControl = new FormControl();
  @ViewChild("csvExport") csvExportComponent: CsvExportComponent;
  datatableHeaders = {
    SuggestionID: "Suggestion ID",
    TransactionName: "Transaction Name",
    CreationDate: "Creation Date",
    IsIndexed: "Is Indexed",
    Comments: "Comments",
    Email: "Email",
    ML_Useful: "ML_Useful",
    ClientName: "Client Name",
    SearchCount: "Search Count",
    CALFeedbackCount: "CAL Feedback Count",
    SearchFeedbackHumanClassification: "Search Feedback Human Classification",
    lwcComment: "LWC Comment"
  };
  datatableKeys = {
    searchFeedbackId: propertyOf<SuggestionDTODisplay>('searchFeedbackId'),
    cal: propertyOf<SuggestionDTODisplay>('cal'),
    createDateDisplay: propertyOf<SuggestionDTODisplay>('createDateDisplay'),
    isIndexedDisplay: propertyOf<SuggestionDTODisplay>('isIndexedDisplay'),
    userComment: propertyOf<SuggestionDTODisplay>('userComment'),
    email: propertyOf<SuggestionDTODisplay>('email'),
    mlUsefulDisplay: propertyOf<SuggestionDTODisplay>('mlUsefulDisplay'),
    clientName: propertyOf<SuggestionDTODisplay>('clientName'),
    searchCountDisplay: propertyOf<SuggestionDTODisplay>('searchCountDisplay'),
    calFeedbackCountDisplay: propertyOf<SuggestionDTODisplay>('calFeedbackCountDisplay'),
    searchFeedbackHumanClassification: propertyOf<SuggestionDTODisplay>('searchFeedbackHumanClassification'),
    lwcComment: propertyOf<SuggestionDTODisplay>('lwcComment'),
  };

  private optionDatatableHeadersAndKeys = {
    headers: [
      this.datatableHeaders.SuggestionID,
      this.datatableHeaders.TransactionName,
      this.datatableHeaders.CreationDate,
      this.datatableHeaders.IsIndexed,
      this.datatableHeaders.Comments,
      this.datatableHeaders.Email,
      this.datatableHeaders.ML_Useful,
      this.datatableHeaders.ClientName,
      this.datatableHeaders.SearchCount,
      this.datatableHeaders.CALFeedbackCount,
      this.datatableHeaders.SearchFeedbackHumanClassification,
      this.datatableHeaders.lwcComment,
    ],
    keys: [
      this.datatableKeys.searchFeedbackId,
      this.datatableKeys.cal,
      this.datatableKeys.createDateDisplay,
      this.datatableKeys.isIndexedDisplay,
      this.datatableKeys.userComment,
      this.datatableKeys.email,
      this.datatableKeys.mlUsefulDisplay,
      this.datatableKeys.clientName,
      this.datatableKeys.searchCountDisplay,
      this.datatableKeys.calFeedbackCountDisplay,
      this.datatableKeys.searchFeedbackHumanClassification,
      this.datatableKeys.lwcComment,
    ]
  };
  private copyOfSelectedRecords: SuggestionDTODisplay[] = [];
  private feedbackTypeForQuery: string = "Suggestion";
  private batchSizeProvider = (r: MerchantBatchSearchRequest) => r.bank_transactions.length;

  constructor(injector: Injector, private loadingService: LoadingService,
              private notificationService: NotificationService, private dateTimeService: DateTimeService,
              private csvComponentService: CsvComponentService, private batchRequestService: BatchRequestService,
              private numberService: NumberService, private searchFeedbackService: SearchFeedbackService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      displayResults: [],
      selectedDate: DateRangeOption.Yesterday ,
      showHideIndexed: UnspecifiedTrueFalse.Unspecified,
      customStartDate: null,
      customStartTime: null,
      customEndDate: null,
      customEndTime: null,
      startDate: emptyString,
      endDate: emptyString,
      suggestionId: 0,
      filterForTransactionDescription: emptyString,
      filterForMlUseful: UnspecifiedTrueFalse.Unspecified,
      filterForClientName: emptyString
    };

    this.restoreState();
    this.setSuggestions();
  }

  ngAfterViewInit() {
    this.transactionDescriptionControl.setValue(this.state.filterForTransactionDescription);
    this.filterByMinimunSuggestionIdControl.setValue(this.state.suggestionId);
    this.configureDebouncedValueChanges();
  }

  batchSearch() {
    this.searchWasPerformed = true;
    this.batchSearchModels = this.prepareCalsForRequest(this.state.displayResults);

    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestStatus.searchInProgressCount = 0;
    this.batchRequestStatus.searchCompletedCount = 0;
    this.batchRequestStatus.searchFailedCount = 0;


    let retryRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    let successRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    this.batchRequestService.doBatchRequest<MerchantBatchSearchRequest, APISearchResultV4[]>(this.batchSearchModels, this.batchSearchModels,
      this.batchSearchModels,retryRequests, successRequests, this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
      .subscribe((batchRequetStatus) => {
        this.refreshSearchResults(batchRequetStatus);
      });
  }

  retryFailedSearches() {
    let requests = this.failedRequests.map(d => d.data);
    this.failedRequests = [];
    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestService.doBatchRequest<MerchantBatchSearchRequest, APISearchResultV4[]>(requests, this.batchSearchModels, requests, [],
      [], this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
      .subscribe((batchREquestStatus) => {
        this.refreshSearchResults(batchREquestStatus);
      });
  }

  prepareCalsForRequest(merchants: FeedbackDTO[]) {
    let cals = [];
    for (let mer of merchants) {
      cals.push(mer.cal);
    }

    return this.batchRequestService.prepareCalsForRequest(cals);
  }

  setStartEndDate() {
    switch(this.state.selectedDate) {
      case DateRangeOption.Today:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().add(1,'days').startOf('day'));
        break;
        case DateRangeOption.Yesterday:
          this.state.startDate = this.dateTimeService.formatWith24Hours(moment().add(-1, 'days').startOf('day'));
          this.state.endDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
          break;
          case DateRangeOption.ThisWeek:
            this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('isoWeek'));
            this.state.endDate = this.dateTimeService.formatWith24Hours(moment().endOf('isoWeek').add(1,'days'));
            break;
            case DateRangeOption.LastWeek:
              this.state.startDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(-6).startOf('day'));
              this.state.endDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(0).add(1,'days').endOf('day'));
              break;
              case DateRangeOption.Custom:
        if(this.state.customStartDate && this.state.customEndDate) {
          this.state.startDate = this.dateTimeService.formatWith24Hours(this.state.customStartDate);
          this.state.endDate = this.dateTimeService.formatWith24Hours(this.state.customEndDate);
        } else {
          this.state.customStartDate = moment().add(-1, 'days');
          this.state.customEndDate = moment().endOf('day');
          this.state.customStartTime = this.dateTimeService.twentyFourHours;
          this.state.customEndTime = this.dateTimeService.twentyFourHours;
        }
        break;
    }
  }

  checkCustomDateValidity() {

    let start = moment(this.state.customStartDate);
    let end = moment(this.state.customEndDate);

    let duration = moment.duration(end.diff(start));
    let days = duration.asDays();

    if (days > 29) {
      this.notificationService.notifyError('Can not set date range greater than 30 days');
      return;
    }

    if (this.state.customStartTime === this.dateTimeService.twentyFourHours) {
      this.state.customStartTime = this.dateTimeService.zeroTime;
    }
    if (this.state.customEndTime === this.dateTimeService.twentyFourHours) {
      this.state.customEndTime = this.dateTimeService.zeroTime;
    }

    this.state.customStartDate = this.dateTimeService.setTime(this.state.customStartDate , this.state.customStartTime);
    this.state.customEndDate = this.dateTimeService.setTime(this.state.customEndDate , this.state.customEndTime);
    this.state.startDate = this.dateTimeService.formatWith24Hours(this.state.customStartDate);
    this.state.endDate = this.dateTimeService.formatWith24Hours(this.state.customEndDate);

    this.setSuggestions();

  }

  clearFilterForTransactionDescription() {
    this.state.filterForTransactionDescription = '';
  }

  clearSuggestionInput() {
    this.state.suggestionId = 0;
  }

  getCsvOptions(suggestions: SuggestionDTODisplay[]) {
    let obj = suggestions[0];
    let extendedObject = suggestions.find(r => r.hasOwnProperty('LWC_ID'));
    if(extendedObject) {
      obj = extendedObject;
    }
    obj = {...obj};
    delete obj.mlConfidence;
    delete obj.mlUseful;
    let options = this.csvComponentService.getCsvOptions(obj, 'Suggestions.csv');

    return { ...options, ...this.optionDatatableHeadersAndKeys };
  }

  getFilterByDateId() {
    return suggestedTestConstants.filterByDateId;
  }

  getFilterByTransactionDescriptionId() {
    return suggestedTestConstants.filterByTransactionDescriptionId;
  }

  getFilterByCanByIndexedId() {
    return suggestedTestConstants.filterByCanBeIndexedId;
  }

  getFilterByIsIndexedId() {
    return suggestedTestConstants.filterByIsIndexedId;
  }

  getFilterIncludePotentialSpam() {
    return suggestedTestConstants.filterByPotentialSpam;
  }

  edit(row: FeedbackDTO, rowIndex: number) {
    this.originalEditRow = cloneDeep(row);
    this.editingRowIndex = rowIndex;
  }

  cancel(rowIndex: number) {
    this.state.displayResults[rowIndex] = this.originalEditRow;
    this.state.displayResults = [...this.state.displayResults];
    this.editingRowIndex = -1;
  }

  isStartDateIncorrect() {
    if (this.state.customStartDate && this.state.customEndDate && this.state.customStartTime && this.state.customEndTime) {
      return this.dateTimeService.isFirstDateBiggerOrEqual(this.state.customStartDate, this.state.customEndDate, this.state.customStartTime, this.state.customEndTime);
    }
    return false;
  }

  setSuggestions() {
    this.clientNames = [];
    this.setStartEndDate();
    this.loadingService.setLoading();
    this.searchFeedbackService.getSuggestions(this.state.endDate, this.state.startDate, this.state.filterForMlUseful, this.state.filterForClientName, this.state.filterForTransactionDescription, this.state.suggestionId, this.state.showHideIndexed, this.skip, this.take, this.feedbackTypeForQuery)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data && response.data.items && response.data.totalCount) {
          for (let value of response.data.items) {
            if (!this.clientNames.includes(value.clientName)) {
              this.clientNames.push(value.clientName);
            }
          }
          this.state.displayResults = response.data.items;
          this.setDisplayValues(this.state.displayResults);
          this.totalElements = response.data.totalCount;
        }
        else {
          this.state.displayResults = [];
          this.totalElements = 0;
        }
      });
  }

  onSelect(selected: { selected: SuggestionDTODisplay[] }) {
    this.searchFeedbackService.restorePersonalDetailsIfNeeded(this.copyOfSelectedRecords, selected.selected, this.state.displayResults);
    this.state.displayResults = [...this.state.displayResults];
    this.selectedRecords = [...selected.selected];
  }

  removePersonalDetails() {
    for (let record of this.selectedRecords) {
      this.copyOfSelectedRecords.push({ ...record });
      record.userComment = personalDetailsRemovedMessage;
      record.searchFeedbackHumanClassification = emptyString;
      record.lwcComment = emptyString;
    }
    this.state.displayResults = [...this.state.displayResults];
  }

  setUpdateSearchFeedbacks(idOfRecordThatEdited?: number, lwcComment: string = emptyString, humanClassification: string = emptyString) {
    let idsOfSelectedRecords: number[] = this.selectedRecords.map(t => t.searchFeedbackId);
    let ids: number[] = idsOfSelectedRecords.length > 0 ? idsOfSelectedRecords : [idOfRecordThatEdited];

    this.loadingService.setLoading();
    this.searchFeedbackService.getUpdateSearchFeedbacks(ids, this.editingRowIndex !== -1 ? null : personalDetailsRemovedMessage, lwcComment, humanClassification)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.updateSearchFeedback) {
          for (let selectedRecord of this.selectedRecords) {
            let record: FeedbackDTO = this.state.displayResults.find(t => t === selectedRecord);
            let indexOfRecord = this.state.displayResults.indexOf(record);
            let newRecord = response.data.updateSearchFeedback.find(t => t.searchFeedbackId === record.searchFeedbackId);

            if (newRecord !== undefined) {
              this.state.displayResults[indexOfRecord] = newRecord;
              this.formatToString(this.state.displayResults[indexOfRecord]);
            }
          }
          this.state.displayResults = [...this.state.displayResults];
        }
        this.selectedRecords = [];
        this.copyOfSelectedRecords = [];
        this.editingRowIndex = -1;
      });
  }

  disabledRemovePersonalDetailsButton() {
    return this.selectedRecords.length === 0;
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    this.offset = pageInfo.offset;
    this.skip = pageInfo.offset * this.take;
    this.setSuggestions();
  }

  disabledSaveButton() {
    return !(this.selectedRecords.length > 0 && this.selectedRecords.find(t => t.userComment === personalDetailsRemovedMessage));
  }

  downloadCsvFile() {
    if (this.totalElements > 5000) {
      this.notificationService.notifyError(limitOnTheNumberOfExportedRecords);
      return;
    }
    let suggestionsForCsvFile: SuggestionDTODisplay[] = [];
    let skip: number = 0;
    this.setStartEndDate();
    this.loadingService.setLoading();
    this.searchFeedbackService.getSuggestions(this.state.endDate, this.state.startDate, this.state.filterForMlUseful, this.state.filterForClientName, this.state.filterForTransactionDescription, this.state.suggestionId, this.state.showHideIndexed, skip, this.totalElements, this.feedbackTypeForQuery)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.totalCount) {
          suggestionsForCsvFile = response.data.items;
          this.setDisplayValues(suggestionsForCsvFile);
          this.csvExportComponent.data = suggestionsForCsvFile;
          this.csvExportComponent.options = this.getCsvOptions(suggestionsForCsvFile);
          this.csvExportComponent.onDownload();
        }
      });
  }

  private refreshSearchResults (batchREquestStatus: BatchRequestStatus<APISearchResultV4[]>) {
    this.batchRequestStatus = batchREquestStatus;

    if (this.batchRequestStatus.searchInProgressCount === 0) {
      this.batchSearhResults = this.batchRequestStatus.batchSearhResults.concat(batchREquestStatus.batchSearhResults);
    }
  }

  private setDisplayValues(displayResults: SuggestionDTODisplay[]) {
    for (let value of displayResults) {
      this.formatToString(value);
    }
  }

  private formatToString(displayResults: SuggestionDTODisplay) {
    displayResults.mlUsefulDisplay = displayResults.mlUseful === true ? `${tickIcon} (${Math.round(displayResults.mlConfidence * 100)}) ${percentIcon}` : `${redCloseIcon} (${Math.round(displayResults.mlConfidence * 100)}) ${percentIcon}`;
    displayResults.searchCountDisplay = this.numberService.format(displayResults.searchCount);
    displayResults.calFeedbackCountDisplay = this.numberService.format(displayResults.calFeedbackCount);
    displayResults.createDateDisplay = this.dateTimeService.formatForSydney(displayResults.createDate);
    displayResults.isIndexedDisplay = displayResults.isIndexed === true ? tickIcon : emptyString;
  }

  private configureDebouncedValueChanges() {
    this.transactionDescriptionControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      ).subscribe(() => {
        this.setSuggestions();
      });

    this.filterByMinimunSuggestionIdControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      ).subscribe(() => {
        this.setSuggestions();
      });
  }
}
