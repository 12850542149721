import { ExecutionResult } from "graphql";
import { StateCodesReponse, SuburbsResponse } from "../../components/suburbstatecode/suburbstatecode.component";

export let suburbStateCodesResponse: ExecutionResult<StateCodesReponse> = {
  data: {
      items: [
        {
          stateCode: "NSW"
        },
        {
          stateCode: "NSW"
        },
        {
          stateCode: "WA"
        },
        {
          stateCode: "WA"
        },
        {
          stateCode: "VIC"
        },
        {
          stateCode: "QLD"
        },
        {
          stateCode: "NSW"
        }
      ]
  }
};

export let suburbsReponse: ExecutionResult<SuburbsResponse> = {
  data: {
      items: [
        {
          suburb: "ARABLE",
          stateCode: "NSW"
        },
        {
          suburb: "ARAKOON",
          stateCode: "NSW"
        },
        {
          suburb: "ARALUEN",
          stateCode: "NSW"
        },
        {
          suburb: "ARALUEN VIA BRAIDWOOD",
          stateCode: "NSW"
        },
        {
          suburb: "ARATULA",
          stateCode: "NSW"
        }
      ],
    totalCount: 5
  }
};
