import { getCatalogueSearchResponse } from '../mockjsonresponses/getcataloguesearchresponse';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export function namesCatalogueBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes('catalog/search') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: getCatalogueSearchResponse
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('catalog/addname') && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [ 17675643456, '']
      }));
      resp.complete();
    });
  }
}
