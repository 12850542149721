import { FlatCategory } from "../categories/flatcategory";
import { NameDescSearchTypesDto } from "./ausindexingresult";
import { propertyOf } from "../core/services/reflection.service";

export interface InternationalChainDto {
    InternationalChainName: string;
    Country: string;
    Location: string;
    Description: string;
    DefaultUrl: string;
    StandardCategory: FlatCategory;
    TransactionDescriptionLookupOptions: NameDescSearchTypesDto[];
    ChainNameLookupOptions: NameDescSearchTypesDto[];
    LastUpdated?: string;
    LWC_Guid: string;
}

export const internationalChainDtoProps = {
  InternationalChainName: propertyOf<InternationalChainDto>('InternationalChainName'),
  DefaultUrl: propertyOf<InternationalChainDto>('DefaultUrl'),
  StandardCategory: propertyOf<InternationalChainDto>('StandardCategory'),
  Country: propertyOf<InternationalChainDto>('Country'),
  Location: propertyOf<InternationalChainDto>('Location'),
  LWC_Guid: propertyOf<InternationalChainDto>('LWC_Guid'),
};
