import { Injectable } from '@angular/core';
import { FlatCategory } from './flatcategory';
import { FullCategoryPath } from './fullcategorypath';
import { CategoriesComponentService } from './categoriescomponent.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryMapperService {

  constructor(private categoriesComponentService: CategoriesComponentService) { }

  mapToFullCategoryPath(categories: FlatCategory[]): FullCategoryPath[] {
    let categoriesLevelOne = this.categoriesComponentService.getLevelOneCategories(categories);
    let fullCategoriesPath: FullCategoryPath[] = [];

    for (let category of categoriesLevelOne) {
      let fullPath: FullCategoryPath = {
        name: category.CategoryName,
        id: category.CategoryID
      }

      fullCategoriesPath.push(fullPath);
      let categoriesLevelTwo = this.categoriesComponentService.getFlatSubcategories(category.CategoryID, categories);

      for (let categoryLevelTwo of categoriesLevelTwo) {
        let fullPath: FullCategoryPath = {
          name: category.CategoryName + ' >> ' + categoryLevelTwo.CategoryName,
          id: categoryLevelTwo.CategoryID
        }

        fullCategoriesPath.push(fullPath);
        let categoriesLevelThree = this.categoriesComponentService.getFlatSubcategories(categoryLevelTwo.CategoryID, categories);

        for (let categoryLevelThree of categoriesLevelThree) {
          let fullPath: FullCategoryPath = {
            name: category.CategoryName + ' >> ' + categoryLevelTwo.CategoryName + ' >> ' + categoryLevelThree.CategoryName,
            id: categoryLevelThree.CategoryID
          }

          fullCategoriesPath.push(fullPath);
        }
      }
    }

    return fullCategoriesPath.sort((a, b) => a.name < b.name ? -1 : 1);
  }
}
