import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { paymentMethodsResponse } from '../mockjsonresponses/paymentmethods.response';

export function paymentBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesDataPaymentMethods = false;
  if (request.body && request.body.query) {
    includesDataPaymentMethods = request.body.query.includes('data: paymentMethods');
  }
  if (cleanUrl.endsWith(apiPathConstants.hotChocolate) && includesDataPaymentMethods && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...paymentMethodsResponse }
      }));
      resp.complete();
    })
  }
}
