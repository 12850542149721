import { ExecutionResult } from 'graphql';
import { BlobCacheSourceType } from '../../components/cacherefresh/cacherefresh.models';

export let blobCacheSourceTypesResponse: ExecutionResult<BlobCacheSourceType[]> = {
  data: [
    {
      name: "Chain",
      value: 1
    },
    {
      name: "Category",
      value: 2
    },
    {
      name: "CategoryAllocationPattern",
      value: 3
    },
    {
      name: "APIGatewayKey",
      value: 4
    },
    {
      name: "CategoryStandardiser",
      value: 5
    },
    {
      name: "ExternalCategory",
      value: 6
    },
    {
      name: "CategoryMappingRule",
      value: 7
    },
    {
      name: "PersonName",
      value: 8
    },
    {
      name: "TagAllocationPattern",
      value: 9
    }
  ]
};

export let blobCacheStatusResponse: string = 'Problem refresh cache for ....';
