import { LoadingService } from './../../core/uiservices/loading.service';
import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector, HostListener } from '@angular/core';
import { BulkLogoState, MerchantsDetail, LogoResponse } from './bulklogoedit.state';
import { BulkLogoEditServie } from '../../services/bulklogoedit.service';
import { finalize } from 'rxjs/operators';
import { MerchantLogoService } from '../../services/merchantlogoservice.service';
import { Observable } from 'rxjs';
import { MerchantLogoResponse } from '../merchantlogo/merchantlogo.response';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageComponent } from '../image/image.component';
import * as _ from 'lodash';
import { StringService } from '../../core/formatting/string.service';
import { MerchantExtractService } from '../../services/merchantextract.service';
import { MerchantDetails, merchantDetailsSchemaProps } from '../merchantextract/merchantdetails';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { Router } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-bulklogoedit',
  templateUrl: './bulklogoedit.component.html',
  styleUrls: ['./bulklogoedit.component.scss']
})
export class BulkLogoEditComponent extends BaseStateComponent<BulkLogoState> implements OnInit {
  private lastScrollPosition: number = 0;
  private indexOfLastLoadedMerchantLwcId: number = 10;
  private loadNextTenLogos = _.debounce(this.loadTenNextLogos, 250);
  selectableTableFields: string[] = [...this.merchantExtractService.merchantDetailsSchemaFields];
  selectedLogo: any[];

  @HostListener('window: scroll', ['$event'])

  onScroll() {
    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPosition > 0 && scrollPosition > this.lastScrollPosition) {
      this.lastScrollPosition = scrollPosition;
      this.loadNextTenLogos();
    }
  }


  constructor(injector: Injector, private bulkLogoEditServie: BulkLogoEditServie, private loadingService: LoadingService,
    private merchantLogoService: MerchantLogoService, private merchantlookupService: MerchantlookupService,
    private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService, private valueCheckerService: ValueCheckerService,
    public dialog: MatDialog, private stringService: StringService, private merchantExtractService: MerchantExtractService,
    private dateTimeService: DateTimeService, private router: Router) {
    super(injector);
    this.selectedLogo = [];
  }

  ngOnInit() {
    this.state = {
      batchSize: 50,
      dataScore: 70,
      merchantsDetail: [],
      chosenTableFields: [],
      logoResponse: {},
      lwcIds: '',
      merchantDetails: [],
    }
    this.restoreState();
  }

  loadMerchants() {
    if (this.state.lwcIds) {
      this.loadingService.setLoading();
      let lwcIds = this.prepareLwcIds();
      for (let i of lwcIds) {
        this.state.merchantsDetail.push({
          lwcId: i,
          showMerchantDetails: false
        });
      }
      let merchantLwcIds: number[] = this.state.merchantsDetail.slice(0, 10).map(t => t.lwcId);
      this.loadLogo(merchantLwcIds);
      this.loadingService.clearLoading();
    }
    else {
      this.loadingService.setLoading();
      this.state.merchantsDetail = [];
      this.bulkLogoEditServie.loadMerchants(this.state.batchSize, this.state.dataScore)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          if (response && response.length > 0) {
            for (let id of response) {
              this.state.merchantsDetail.push({
                lwcId: id,
                showMerchantDetails: false,
              });
            }
          }
          let merchantLwcIds: number[] = this.state.merchantsDetail.slice(0, 10).map(t => t.lwcId);
          this.loadLogo(merchantLwcIds);
        });
    }
  }

  loadLogo(lwcId: number[] | number) {
    let merchantLogoResponse: Observable<MerchantLogoResponse>;
    if (typeof lwcId !== 'number') {
      for (let i = 0; i < lwcId.length; i++) {
        merchantLogoResponse = this.getMerchantLogo(lwcId[i]);
        merchantLogoResponse.subscribe(response => {
          if (response && response.logos && response.logos.length > 0) {
            this.state.logoResponse[lwcId[i]] = {
              logos: response.logos,
              screenShot: response.screenShot,
              id: lwcId[i],
            };
          }
        });
      }
    }
    else {
      merchantLogoResponse = this.getMerchantLogo(lwcId);
      merchantLogoResponse.subscribe(response => {
        if (this.state.logoResponse[lwcId]) {
          this.state.logoResponse[lwcId].logos = response.logos;
          this.state.logoResponse[lwcId].screenShot = response.screenShot;
        }
        else {
          this.state.logoResponse[lwcId] = {
            logos: response.logos,
            screenShot: response.screenShot,
            id: lwcId,
          };
        }
      });
    }
  }

  setLogo() {
    for (let md of this.state.merchantsDetail) {
      if (md.logoToSet) {
        let indexBeforeImageName = md.logoToSet.lastIndexOf('/');
        let imageName = md.logoToSet.substring(indexBeforeImageName + 1);
        md.logoToSet = null;
        this.merchantLogoService.setLogo(md.lwcId, imageName)
          .subscribe(
            () => {
              md.showIcon = true;
              md.haveError = false;
            },
            () => {
              md.showIcon = true;
              md.haveError = true;
            });
      }
    }
  }

  hideOrShowMerchantDetails(row: MerchantsDetail) {
    row.showMerchantDetails = !row.showMerchantDetails;

    if (row.showMerchantDetails) {
      this.loadingService.setLoading();
      this.merchantlookupService.getCals(row.lwcId)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          if (response) {
            row.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
            row.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
          }
        });
    }
  }

  getMerchantDetailsButtonText(row: MerchantsDetail) {
    return row.showMerchantDetails === true ? 'Hide Merchant Details' : 'Merchant Details';
  }

  disableSetLogoButton() {
    return this.state.merchantsDetail.some(t => !this.valueCheckerService.isEmptyNullOrUndefined(t.logoToSet));
  }

  showModalImage(logoResponse: LogoResponse) {
    this.dialog.open(ImageComponent, {
      width: 'auto',
      maxWidth: '1200px',
      height: 'auto',
      maxHeight: '600px',
      data: logoResponse.screenShot ? logoResponse.screenShot : 'assets/img/EmptyLogo.png'
    });
  }

  private loadTenNextLogos() {
    if (this.state.merchantsDetail.length > 0 && this.indexOfLastLoadedMerchantLwcId < this.state.merchantsDetail.length) {
      let merchantLwcIds: number[] = this.state.merchantsDetail
        .slice(this.indexOfLastLoadedMerchantLwcId, this.indexOfLastLoadedMerchantLwcId + 10)
        .map(t => t.lwcId);
      this.loadLogo(merchantLwcIds);
      this.indexOfLastLoadedMerchantLwcId += 10;
    }
  }

  private getMerchantLogo(lwcId: number) {
    return this.merchantLogoService.loadLogo(lwcId, false);
  }

  areLwcIdNumbersValid() {
    let numbers = this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.lwcIds);

    return numbers.every(s => this.valueCheckerService.isNumber(s));
  }

  private prepareLwcIds() {
    return this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.lwcIds).map(id => parseInt(id, 10));
  }

  private orderyBySearchedLwcIds(lwcIds: number[], merchantDetails: MerchantDetails[]) {
    let result: Partial<MerchantDetails>[] = [];
    for (let id of lwcIds) {
      let found = merchantDetails.find(d => d.LWC_ID === id);
      if (found) {
        found.UpdatedDate = this.dateTimeService.formatWithHours(merchantDetailsSchemaProps.UpdatedDate)
        result.push(found);
      } else {
        let notFound: Partial<MerchantDetails> = {
          LWC_ID: id,
          PrimaryName: "[NOT FOUND]"
        };
        result.push(notFound);
      }
    }

    return result;
  }

  isTableFieldSelected(field: string) {
    return this.state.chosenTableFields.includes(field);
  }

  goToEnrichMerchant(LWCID: number) {
    this.router.navigate(['/enrichmerchant', { lwcId: LWCID, cleanState: true }]);
  }

  radioChange(row: MerchantsDetail) {
    this.hideOrShowMerchantDetails(row);
  }
}


