import { Component, OnInit, Injector } from "@angular/core";
import { BaseStateComponent } from "../basestate/basestate.component";
import { ChainPatternMatchcerState, ChainPatternMatchDisplay, MatchCalsToChainsResponse } from "./chainpatternmatcherstate.component";
import { ChainPatternMatcherService } from "../../services/chainpatternmatcher.service";
import { finalize } from "rxjs/operators";
import { LoadingService } from "../../core/uiservices/loading.service";
import { CsvComponentService } from "../../core/export/csvcomponent.service";
import { matchConfidenceNames, lookupOptionTypeStringValues, lookupTypeMap } from "../../core/constants/constants";
import { DateTimeService } from "../../core/uiservices/datetime.service";
import { MerchantlookupService } from "../../services/merchantlookup.service";
import { ChainDto } from "../../models/ausindexingresult";

@Component({
    selector: "app-chainpatternmatcher",
    templateUrl: './chainpatternmatcher.component.html',
    styleUrls: ['./chainpatternmatcher.component.scss'],
})
export class ChainPatternMatcherComponent extends BaseStateComponent<ChainPatternMatchcerState> implements OnInit {
    private chains: ChainDto[];

    constructor(private chainPatternMatcherService: ChainPatternMatcherService, private loadingService: LoadingService,
        private csvComponentService: CsvComponentService, private dateTimeService: DateTimeService, private merchantlookupService: MerchantlookupService,
        injector: Injector) {
        super(injector)
    }

    ngOnInit() {
        this.state = {
            cals: '',
            displayData: []
        };
        this.restoreState();

        this.merchantlookupService.getChains().subscribe(r => {
            this.chains = r;
        });
    }

    searchButtonDisabled(): boolean {
        return this.state.cals.length == 0;
    }

    getCsvOptions() {
        let object = this.state.displayData[0];
        let fullObject = this.state.displayData.find(r => r.chainId);
        if (fullObject) {
            object = fullObject;
        }
        let options = this.csvComponentService.getCsvOptions(object, 'ChainPatternMatcher.csv');
        return options;
    }


    search() {
        this.loadingService.setLoading();
        let cals: string[] = this.state.cals.split('\n');
        this.chainPatternMatcherService.getCalToChainMatch(cals)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe((response) => {
                this.prepareDisplayData(response);
            });
    }

    private prepareDisplayData(response: MatchCalsToChainsResponse) {
        this.state.displayData = [];
        for (let match of response.Matches) {
            let displayValue: ChainPatternMatchDisplay = null;
            
            if (!match.Match) {
                displayValue = {
                    cal: match.CAL
                }
            } else {
                displayValue = {
                    cal: match.CAL,
                    chainId: match.Match.ChainId,
                    matchConfidence: matchConfidenceNames.find(m => m.value === match.Match.MatchConfidence).name,
                    lookupOptionType: lookupOptionTypeStringValues.get(match.Match.LookupOptionType),
                    searchString: match.Match.MatchedPattern.SearchString,
                    searchStringsNoSpaces: match.Match.MatchedPattern.SearchStringsNoSpaces,
                    searchStrings: match.Match.MatchedPattern.SearchStrings,
                    lookupType: lookupTypeMap.get(match.Match.MatchedPattern.LookupType),
                    matchedPatternMatchConfidence: matchConfidenceNames.find(m => m.value === match.Match.MatchConfidence).name,
                    lastUpdate: this.dateTimeService.formatWithHours(match.Match.MatchedPattern.LastUpdated),
                    chainName: this.getChainName(match.Match.ChainId)
                }
            }
            
            this.state.displayData.push(displayValue);
        }
    }

    private getChainName(id: number) {
        let name = '';
       
        let chain = this.chains.find(r => r.ChainId === id);
        if (chain) {
            name = chain.ChainName;
        }
        return name;
    }
}