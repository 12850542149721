import { MoneyTrackerV4ResponseInternal, LWCCategoryType } from "../../components/moneytrackersearch/moneytrackersearchdisplayresult";

export let moneyTrackerV4ResponseInternal: MoneyTrackerV4ResponseInternal = {
    enrichment_statistics: {
        number_of_transactions: 43,
        matched_transactions: 43,
        search_time_ms: 4005
    },
    enriched_transactions: [
        {
            transaction_correlation_guid: "42f33a75-c4cb-40d6-867b-d682536482f7",
            merchant_lwc_guid: '42f33a75-c4cb-40d6-867b-d682536482f7',
            categorised_transactions_internal: [{
                category_id: "0",
                amount: -321.01
            }],
            search_guid: "233ea2c8-d717-47ad-a977-dab05b3aef08",
            transaction_desc: "COOP DR PK  GROCERY AB Visa Cash Withdrawal - Receipt 179606 Foreign Currency Amount: CAD 302.95 In RED DEER Date 26 Apr 2019 Card xxxxxxxxxxxx0686",
            result_code: 50010,
            amount: -321.01,
            date_time: "2019-04-28T00:00:00Z",
            match_source: "DefaultOtherExpense",
            fixed_cal: "PENNINGTONS #5183 RED DEER",
            transaction_type: "Expense",
            merchant_chain_name: "Chain name",
            score: 99,
            recurrence_frequency: "Monthly"
        },
        {
            match_source: "DefaultOtherExpense",
            fixed_cal: "PENNINGTONS #5183 RED DEER",
            categorised_transactions_internal: [
                {
                    category_id: "35",
                    amount: -300.9
                }
            ],
            search_guid: "67d1bbff-3d85-4119-a6ed-4b990034bf4d",
            transaction_desc: "PENNINGTONS #5183 Visa Purchase - Receipt 145966 Foreign Currency Amount: CAD 284.34 In RED DEER Date 29 Apr 2019 Card xxxxxxxxxxxx0686",
            amount: -300.9,
            date_time: "2019-05-01T00:00:00Z",
            transaction_correlation_guid: "57dcbed2-8214-4821-b225-020ef87d52e5",
            transaction_type: "Expense",
            result_code: 50010,
            score: 99,
            recurrence_frequency: "Quarterly"
        },
        {
            match_source: "DefaultOtherExpense",
            fixed_cal: "ELEPHANT & CASTLE RED DEE RED DEER",
            categorised_transactions_internal: [
                {
                    category_id: "35",
                    amount: -58.82
                }
            ],
            search_guid: "8282abe2-24f9-46d8-aba3-324feb0db802",
            transaction_desc: "ELEPHANT & CASTLE RED DEE Visa Purchase - Receipt 166902 Foreign Currency Amount: CAD 55.39 In RED DEER Date 07 May 2019 Card xxxxxxxxxxxx0686",
            amount: -58.82,
            date_time: "2019-05-10T00:00:00Z",
            transaction_correlation_guid: "02bc2702-62ba-41b8-a13a-7e22531e69d6",
            transaction_type: "Expense",
            result_code: 50010,
            score: 99
        }
    ],
    transaction_aggregations: [],
    merchant_details: [
        {
            id: 1001035565,
            lwc_guid: "dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a",
            data_enrichment_score: 96,
            merchant_primary_name: "Supa Express Kaleen",
            primary_address: {
                single_line_address: "Unit 8 Gwydir Square, Maribyrnong Ave, Kaleen ACT 2617, Australia",
                state: "ACT",
                postcode: "2617",
                suburb: "Kaleen",
                longitude: 149.102931,
                latitude: -35.233886,
                lat_lon_precision: 7,
                mapable: true,
                street_view_available: false
            },
            verification: {
                is_verified: true,
                last_verified_on: "2018-02-06T12:32:00Z"
            },
            primary_contacts: [
                {
                    type_of_contact: "PHONE",
                    value: "(02) 6241 1734",
                    display_value: "(02) 6241 1734",
                    label: "Phone"
                },
                {
                    type_of_contact: "URL",
                    value: "http://supabarn.com.au",
                    display_value: "supabarn.com.au",
                    label: "Web"
                },
                {
                    type_of_contact: "EMAIL",
                    value: "fundraising@supabarn.com.au",
                    display_value: "fundraising@supabarn.com.au",
                    label: "Email"
                }
            ],
            suspicious_merchant_score: 50,
            secondary_contacts: [
                {
                    type_of_contact: "INTERNATIONALPHONE",
                    value: "+61 2 6241 1734",
                    display_value: "+61 2 6241 1734",
                    label: "Phone"
                },
                {
                    type_of_contact: "INTERNATIONALPHONE",
                    value: "+61 2 6257 1777",
                    display_value: "+61 2 6257 1777",
                    label: "Phone"
                },
                {
                    type_of_contact: "INTERNATIONALPHONE",
                    value: "+61 2 6257 6446",
                    display_value: "+61 2 6257 6446",
                    label: "Phone"
                },
                {
                    type_of_contact: "FACEBOOK",
                    value: "http://facebook.com/470816779660454",
                    display_value: "facebook.com/pages/Supa Express...",
                    label: "Facebook"
                }
            ],
            image_gallery: [
                {
                    thumbnail_url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-e_7w-thumbnail.jpg",
                    large_url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-e_7w-image.jpg",
                    image_title: "Supa Express Kaleen",
                    image_height: 1080,
                    image_width: 1920
                },
                {
                    thumbnail_url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-snvq-thumbnail.jpg",
                    large_url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-snvq-image.jpg",
                    image_title: "Supa Express Kaleen",
                    image_height: 1920,
                    image_width: 2560
                },
                {
                    thumbnail_url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-y4vw-thumbnail.jpg",
                    large_url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-y4vw-image.jpg",
                    image_title: "Supa Express Kaleen",
                    image_height: 2988,
                    image_width: 5312
                }
            ],
            primary_category: {
                category_name: "Supermarket",
                id: 2001,
                full_category_path: "Groceries >> Supermarket",
                parent: {
                    category_name: "Groceries",
                    id: 2,
                    full_category_path: "Groceries"
                },
                is_lwc_category: true,
                lwc_category_icon: {
                    Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_2001.png",
                    Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_2001.png",
                    height: 128,
                    width: 128
                },
                lwc_category_icon_circular: {
                    Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_2001.c.png",
                    Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_2001.c.png",
                    height: 128,
                    width: 128
                },
                category_emoji: "U+1F6D2"
            },
            other_categories_list: [
                {
                    category_name: "GROCERY STORES SUPERMARKETS",
                    id: 2001,
                    full_category_path: "GROCERY STORES SUPERMARKETS"
                },
                {
                    category_name: "Supermarket",
                    id: 0,
                    full_category_path: "Supermarket"
                },
                {
                    category_name: "Grocery Or Supermarket",
                    id: 2001,
                    full_category_path: "Grocery Or Supermarket"
                },
                {
                    category_name: "Food",
                    id: 3,
                    full_category_path: "Food"
                },
                {
                    category_name: "Retailing",
                    id: 0,
                    full_category_path: "Retailing"
                },
                {
                    category_name: "Grocery And Food Stores",
                    id: 2,
                    full_category_path: "Grocery And Food Stores"
                }
            ],
            primary_merchant_description: "As a local family owned & operated supermarket we pride ourselves on supplying local food that tastes great. We work with producers who support local farmers to bring you products you can trust.",
            merchant_descriptors: [
                "Supabarn - Family owned focussed on quality price and service"
            ],
            is_permanently_closed: false,
            merchant_logo: {
                url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-logo-image.png"
            },
            website_screen_shot: {
                thumbnail: {
                    url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-site-thumbnail.jpg",
                    height: 65,
                    width: 100
                },
                banner: {
                    url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-site-banner.jpg",
                    height: 300,
                    width: 100
                },
                main: {
                    url: "https://images.lookwhoscharging.com/dc1c68da-8d8c-4e5a-b0a5-5dc4e88d819a/Supa-Express-Kaleen-site-image.jpg",
                    height: 6765,
                    width: 1480
                }
            },
            also_known_as: [
                "Supabarn Express Kaleen",
                "Cavo Pty Ltd",
                "Demos Family Trust",
                "Supaexpress"
            ],
            last_updated: "2018-02-06T12:32:00Z",
            opening_hours: {
                sunday: {
                    status: "open",
                    times: [
                        {
                            open: "08:00",
                            close: "20:00"
                        }
                    ]
                },
                monday: {
                    status: "open",
                    times: [
                        {
                            open: "07:30",
                            close: "20:30"
                        }
                    ]
                },
                tuesday: {
                    status: "open",
                    times: [
                        {
                            open: "07:30",
                            close: "20:30"
                        }
                    ]
                },
                wednesday: {
                    status: "open",
                    times: [
                        {
                            open: "07:30",
                            close: "20:30"
                        }
                    ]
                },
                thursday: {
                    status: "open",
                    times: [
                        {
                            open: "07:30",
                            close: "20:30"
                        }
                    ]
                },
                friday: {
                    status: "open",
                    times: [
                        {
                            open: "07:30",
                            close: "20:30"
                        }
                    ]
                },
                saturday: {
                    status: "open",
                    times: [
                        {
                            open: "07:30",
                            close: "20:30"
                        }
                    ]
                }
            },
            legal_business_info: {
                date_established: "2001-01-01T00:00:00Z",
                entity_type: "PRV",
                current_merchant_status: "Active",
                merchant_type: "Stand-Alone",
                merchant_presence: "Bricks & Mortar",
                legal_registrations: [
                    {
                        legal_number: "096897862",
                        legal_number_label: "ACN"
                    },
                    {
                        legal_number: "24096897862",
                        legal_number_label: "ABN"
                    }
                ],
                registered_for_sales_tax: true
            },
            overall_rating: {
                overall_rating_score: 4.3,
                total_number_of_reviews: 5
            },
            ratings: [
                {
                    reviewer: "GooglePlaces",
                    score: 4.3,
                    number_of_reviews: 5
                }
            ],
            payment_options: [
                "Visa",
                "Mastercard",
                "EFTPOS"
            ],
            transaction_query_process: {}
        }
    ],
    atm_details: [],
    bank_fee_details: [
        {
            BankMessage_LWC_ID: 145,
            lwc_guid: "f0375653-90ca-4dfd-bf34-af618db4e74d",
            associated_with: {
                associates_name: "ING Australia",
                associates_id: "f0375653-90ca-4dfd-bf34-af618db4e74d",
                type_of_association: "Bank"
            },
            short_bank_message: "Bank Fees charged and reimbursed by ING Australia",
            long_bank_message: "ING Bank fee relating to an International Transaction Fee charged when an international card-based transaction is made or use of a non-ING ATM or an Annual Fee for Orange Advantage or another product",
            primary_category: {
                category_name: "Bank Fee",
                id: 5015001,
                full_category_path: "Services >> Financial Services >> Bank Fee",
                parent: {
                    category_name: "Financial Services",
                    id: 5015,
                    full_category_path: "Services >> Financial Services",
                    parent: {
                        category_name: "Services",
                        id: 5,
                        full_category_path: "Services"
                    }
                },
                is_lwc_category: true,
                lwc_category_icon: {
                    Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5015001.png",
                    Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5015001.png",
                    height: 128,
                    width: 128
                },
                lwc_category_icon_circular: {
                    Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5015001.c.png",
                    Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5015001.c.png",
                    height: 128,
                    width: 128
                },
                category_emoji: "U+1F4B0"
            },
        }
    ],
    category_details: [
        {
            category_type: LWCCategoryType.Expense,
            category_name: "Other Expense",
            category_id: "35",
            full_category_path: "Other Expense",
            is_lwc_category: true,
            lwc_category_icon: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_35.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_35.png",
                height: 128,
                width: 128
            },
            lwc_category_icon_circular: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_35.c.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_35.c.png",
                height: 128,
                width: 128
            }
        },
        {
            category_type: LWCCategoryType.Expense,
            category_name: "Supermarket",
            category_id: "2001",
            full_category_path: "Groceries >> Supermarket",
            parent: {
                category_name: "Groceries",
                id: 2,
                full_category_path: "Groceries"
            },
            is_lwc_category: true,
            lwc_category_icon: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_2001.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_2001.png",
                height: 128,
                width: 128
            },
            lwc_category_icon_circular: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_2001.c.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_2001.c.png",
                height: 128,
                width: 128
            },
            category_emoji: "U+1F6D2"
        },
        {
            category_type: LWCCategoryType.Expense,
            category_name: "Power",
            category_id: "5006002",
            full_category_path: "Services >> Utilities >> Power",
            parent: {
                category_name: "Utilities",
                id: 5006,
                full_category_path: "Services >> Utilities",
                parent: {
                    category_name: "Services",
                    id: 5,
                    full_category_path: "Services"
                }
            },
            is_lwc_category: true,
            lwc_category_icon: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5006002.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5006002.png",
                height: 128,
                width: 128
            },
            lwc_category_icon_circular: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5006002.c.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5006002.c.png",
                height: 128,
                width: 128
            },
            category_emoji: "U+26A1"
        },
        {
            category_type: LWCCategoryType.Expense,
            category_name: "Water",
            category_id: "5006001",
            full_category_path: "Services >> Utilities >> Water",
            parent: {
                category_name: "Utilities",
                id: 5006,
                full_category_path: "Services >> Utilities",
                parent: {
                    category_name: "Services",
                    id: 5,
                    full_category_path: "Services"
                }
            },
            is_lwc_category: true,
            lwc_category_icon: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5006001.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5006001.png",
                height: 128,
                width: 128
            },
            lwc_category_icon_circular: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5006001.c.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5006001.c.png",
                height: 128,
                width: 128
            },
            category_emoji: "U+1F4A7"
        },
        {
            category_type: LWCCategoryType.Expense,
            category_name: "Cafe",
            category_id: "3999002",
            full_category_path: "Food/Drink >> Other >> Cafe",
            parent: {
                category_name: "Other",
                id: 3999,
                full_category_path: "Food/Drink >> Other",
                parent: {
                    category_name: "Food/Drink",
                    id: 3,
                    full_category_path: "Food/Drink"
                }
            },
            is_lwc_category: true,
            lwc_category_icon: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_3999002.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_3999002.png",
                height: 128,
                width: 128
            },
            lwc_category_icon_circular: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_3999002.c.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_3999002.c.png",
                height: 128,
                width: 128
            },
            category_emoji: "U+2615"
        },
        {
            category_type: LWCCategoryType.Expense,
            category_name: "Hotel/Motel",
            category_id: "7003001",
            full_category_path: "Travel >> Accommodation >> Hotel/Motel",
            parent: {
                category_name: "Accommodation",
                id: 7003,
                full_category_path: "Travel >> Accommodation",
                parent: {
                    category_name: "Travel",
                    id: 7,
                    full_category_path: "Travel"
                }
            },
            is_lwc_category: true,
            lwc_category_icon: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_7003001.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_7003001.png",
                height: 128,
                width: 128
            },
            lwc_category_icon_circular: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_7003001.c.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_7003001.c.png",
                height: 128,
                width: 128
            },
            category_emoji: "U+1F3E8"
        },
        {
            category_type: LWCCategoryType.Expense,
            category_name: "Bank Fee",
            category_id: "5015001",
            full_category_path: "Services >> Financial Services >> Bank Fee",
            parent: {
                category_name: "Financial Services",
                id: 5015,
                full_category_path: "Services >> Financial Services",
                parent: {
                    category_name: "Services",
                    id: 5,
                    full_category_path: "Services"
                }
            },
            is_lwc_category: true,
            lwc_category_icon: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5015001.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5015001.png",
                height: 128,
                width: 128
            },
            lwc_category_icon_circular: {
                Black_white_url: "https://images.lookwhoscharging.com/categoryicons/bw_5015001.c.png",
                Coloured_url: "https://images.lookwhoscharging.com/categoryicons/clr_5015001.c.png",
                height: 128,
                width: 128
            },
            category_emoji: "U+1F4B0"
        }
    ]
}
