import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector } from '@angular/core';
import { OperationalChecksState, UnverifiedMerchant } from './operationalchecks.state';
import { LoadingService } from '../../core/uiservices/loading.service';
import { OperationalChecksService } from '../../services/operationalchecks.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ValueCheckerService } from '../../core/services/valuechecker.service';

export type UnverifiedMerchantsResponse = {
  unverifiedMerchants: UnverifiedMerchant[];
}

@Component({
  selector: 'app-operationalchecks',
  templateUrl: './operationalchecks.component.html',
  styleUrls: ['./operationalchecks.component.scss']
})
export class OperationalChecksComponent extends BaseStateComponent<OperationalChecksState> implements OnInit {
  unverifiedMerchants: UnverifiedMerchant[] = [];

  constructor(injector: Injector, private loadingService: LoadingService, private operationalChecksService: OperationalChecksService,
    private valueCheckerService: ValueCheckerService) {
    super(injector)
  }

  ngOnInit() {
    this.state = {
      filteredUnverifiedMerchants: [],
      filterByLwcId: '',
      filterByStatus: '',
      filterByClosedConfidenceLevel: '',
    };
    this.restoreState();
    this.getUnverifiedMerchants();
  }

  setFilterByLwcId(event: KeyboardEvent) {
    this.state.filterByLwcId = (<HTMLInputElement>event.target).value.toLowerCase();
    this.filterUnverifiedMerchants();
  }

  setFilterByStatus(event: KeyboardEvent) {
    this.state.filterByStatus = (<HTMLInputElement>event.target).value.toLowerCase();
    this.filterUnverifiedMerchants();
  }

  setFilterByClosedConfidenceLevel(event: KeyboardEvent) {
    this.state.filterByClosedConfidenceLevel = (<HTMLInputElement>event.target).value.toLowerCase();
    this.filterUnverifiedMerchants();
  }

  handleClearInputForLwcId() {
    this.state.filterByLwcId = '';
    this.filterUnverifiedMerchants();
  }

  handleClearInputForStatus() {
    this.state.filterByStatus = '';
    this.filterUnverifiedMerchants();
  }

  handleClearInputForClosedConfidenceLevel() {
    this.state.filterByClosedConfidenceLevel = '';
    this.filterUnverifiedMerchants();
  }

  private filterUnverifiedMerchants() {
    this.state.filteredUnverifiedMerchants = this.unverifiedMerchants.filter((unverifiedMerchant: UnverifiedMerchant) => {
      let isMatch = true;

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByLwcId)) {
        isMatch = unverifiedMerchant.lwcId.toString().includes(this.state.filterByLwcId);
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByStatus)) {
        isMatch = unverifiedMerchant.status.toLowerCase().includes(this.state.filterByStatus);
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByClosedConfidenceLevel)) {
        isMatch = unverifiedMerchant.closedConfidenceLevel.toLowerCase().includes(this.state.filterByClosedConfidenceLevel);
      }

      return isMatch;
    });
  }

  private getUnverifiedMerchants() {
    this.loadingService.setLoading();
    this.operationalChecksService.getUnverifiedMerchants()
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response) {
          this.state.filteredUnverifiedMerchants = this.unverifiedMerchants = response.data.unverifiedMerchants;
        }
      })
  }

}
