import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiVersion, internalApiVersion } from '../mockjsonresponses/getversionresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';

export function apiInfoBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];
    
    if (cleanUrl.includes(apiPathConstants.internalApiVersion) && method === 'GET') {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: internalApiVersion
      }));
        resp.complete();
      });
    }

    if (cleanUrl.includes('version') && method === 'GET') {
        return new Observable(resp => {
          resp.next(new HttpResponse({
            status: 200,
            body: apiVersion
        }));
          resp.complete();
        });
      }
}
