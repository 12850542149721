import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../services/errorhandler';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private errorHandler: ErrorHandler, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.getToken();
    let resp: Observable<HttpEvent<any>>;

    if (token) {
      const cloned = request.clone({
        headers: request.headers.set('Authorization',
          'Bearer ' + token)
      });

      resp = next.handle(cloned);
    } else {
      resp = next.handle(request);
    }

    return resp.pipe(catchError(this.errorHandler.handleUnauthorized));
  }
}
