import { Directive } from "@angular/core";
import { NG_VALIDATORS, AbstractControl, Validator } from "@angular/forms";

@Directive({
  selector: '[appStringEnumRequired]',
  providers: [{provide: NG_VALIDATORS, useExisting: StringEnumRequiredDirective, multi: true}]
})
export class StringEnumRequiredDirective implements Validator {
  validate(control: AbstractControl): {[key: string]: any} | null {
    return control.value === 0 ? {'stringEnumNullValue': {value: control.value}} : null;
  }
}
