import { StringResponse } from "../mockjsonresponses/stringresponse";
import { Observable } from "rxjs";
import { HttpResponse, HttpRequest, HttpEvent } from "@angular/common/http";
import { merchantValidationResponse } from "../mockjsonresponses/merchantvalidation";
import { apiPathConstants } from '../../core/api/apipathconstants';
import { cloneDeep } from 'lodash';

export function merchantValidationBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    let cleanUrl = url.split('?')[0];
    const stringResponse = new StringResponse(cleanUrl);
    stringResponse.response = stringResponse.buildResponse();
    if (cleanUrl.includes(apiPathConstants.merchantIndexMerchantValidations) && method === 'GET') {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: cloneDeep(merchantValidationResponse)
        }));
        resp.complete();
      });
    }

    if (cleanUrl.includes(apiPathConstants.merchantIndexMerchantValidations) && method === 'POST') {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200
        }));
        resp.complete();
      });
    }
}
