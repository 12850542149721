import { CALRellocationResult } from '../../components/changecalallocation/calrellocationresult';
import { CALRellocationResultStatus } from '../../components/changecalallocation/calrellocationresultstatus';
import { MerchantSummary } from '../../components/mergemerchants/merchantsummary';
import { paymentMethodDisplayValues } from '../../enums/enums';
import { PaymentMethod } from '../../models/calparserresult';

export let getCalsResponse: MerchantSummary[] = [
  {
    LWC_ID: 1000649284,
    SquareLogo: 'https://images.lookwhoscharging.com/chainlogos/universityofwesternaustralia.png',
    CircularLogo: null,
    ThreeToOneLogo: 'https://images.lookwhoscharging.com/chainlogos/sushisushi.3x1.png',
    SquareLogoDark: 'https://odiethemes.com/wp-content/uploads/2019/12/Square-Logo-Dark.png',
    CircularLogoDark: 'https://vectorverse.com/wp-content/uploads/2017/05/free-logo-badge-template-download-770x578.jpg',
    ThreeToOneLogoDark: 'https://www.logolynx.com/images/logolynx/bf/bf43f5f4dc360d32ee05a871e1c31bfa.jpeg',
    PrimaryName: 'Antonio',
    ChainName: 'ChainName',
    PrimaryAddress: 'PrimaryAddress',
    PrimaryCategory: 'PrimaryCategory',
    TransactionDescriptions: ['99 BIKES PTY LTD BROOKVALE', 'ADAIRS BALGOWLAH', '#641 WAIKIKI RETAIL HONOLULU', '0402 WWW.USABOX.COM 305-406-1696', 'NABINTNL TRN FEE REV -MC', 'MULLALOO 6202 MULLALOO WA ', '99 BIKES PTY LTD BROOKVALE', 'ADAIRS BALGOWLAH', '#641 WAIKIKI RETAIL HONOLULU', '0402 WWW.USABOX.COM 305-406-1696', 'NABINTNL TRN FEE REV -MC', 'INTERNET PAYMENT CREDIT ARD'],
    TransactionDescriptionSummaries: [
      {
        CAL: "INTERNODE PTY LTD ADELAIDE SA",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.AMEX),
        IsQuaratined: true
      },
      {
        CAL: "INTERNET BPAY INTERNODE",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.ATM),
        IsQuaratined: false
      },
      {
        CAL: "INTERNET BPAY INTERNO",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.BPay),
        IsQuaratined: false
      },
      {
        CAL: "INTERNODE N MACQUARIE PARK NSW",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Card),
        IsQuaratined: false
      },
      {
        CAL: "DIRECT DEBIT INTERNODE SYS",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectDebit),
        IsQuaratined: false
      },
      {
        CAL: "INTERNODE N MACQUARIE NSW",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectCredit),
        IsQuaratined: false
      },
      {
        CAL: "INTERNET BPAY INTERNODE-N",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Dividend),
        IsQuaratined: true
      },
      {
        CAL: "DIRECT DEBIT INTERNODE-N",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Salary),
        IsQuaratined: true
      },
      {
        CAL: "INTERNODE N MACQUARIE P AUS",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Unknown),
        IsQuaratined: false
      },
      {
        CAL: "DIRECT DEBIT INTERNODE SYS DD",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectDebit),
        IsQuaratined: false
      }
  ],
    LocatedIn: 'TestLocation',
    MerchantType: 'BankBranch',
    ABN: 123456,
    MerchantPresence: 'BricksMortar' ,
    PhoneNumber: 'PhoneNumber',
    Email: 'Email',
    Url: 'Url',
    IsQuarantined: false,
    RecordLocked: false,
    QuarantinedCALs: [],
    DataCompletnessScore: 95,
    Tags: ['Warning', 'Error', 'Success', 'Empty Field', 'Empty Array', 'Object', 'Array'],
    BSBs: ['081508', '079901', '064409', '056205', '044806'],
    CurrentBusinessStatus: 'Active'
  },
  {
    LWC_ID: 1023000284,
    SquareLogo: 'https://images.lookwhoscharging.com/chainlogos/universityofwesternaustralia.png',
    CircularLogo: null,
    ThreeToOneLogo: 'https://images.lookwhoscharging.com/chainlogos/sushisushi.3x1.png',
    SquareLogoDark: 'https://odiethemes.com/wp-content/uploads/2019/12/Square-Logo-Dark.png',
    CircularLogoDark: 'https://vectorverse.com/wp-content/uploads/2017/05/free-logo-badge-template-download-770x578.jpg',
    ThreeToOneLogoDark: 'https://www.logolynx.com/images/logolynx/bf/bf43f5f4dc360d32ee05a871e1c31bfa.jpeg',
    PrimaryName: 'Antonio',
    ChainName: 'Name Name',
    PrimaryAddress: 'streat 24',
    PrimaryCategory: 'Cold',
    CurrentBusinessStatus: 'Active',
    TransactionDescriptions: ['99 BIKES PTY LTD BROOKVALE', 'ADAIRS BALGOWLAH', '#641 WAIKIKI RETAIL HONOLULU', '0402 WWW.USABOX.COM 305-406-1696', 'NABINTNL TRN FEE REV -MC', 'MULLALOO 6202 MULLALOO WA ', '99 BIKES PTY LTD BROOKVALE', 'ADAIRS BALGOWLAH', '#641 WAIKIKI RETAIL HONOLULU', '0402 WWW.USABOX.COM 305-406-1696', 'NABINTNL TRN FEE REV -MC', 'INTERNET PAYMENT CREDIT ARD'],
    TransactionDescriptionSummaries: [
      {
        CAL: "INTERNODE PTY LTD ADELAIDE SA",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.AMEX),
        IsQuaratined: true
      },
      {
        CAL: "INTERNET BPAY INTERNODE",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.ATM),
        IsQuaratined: false
      },
      {
        CAL: "INTERNET BPAY INTERNO",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.BPay),
        IsQuaratined: false
      },
      {
        CAL: "INTERNODE N MACQUARIE PARK NSW",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Card),
        IsQuaratined: false
      },
      {
        CAL: "DIRECT DEBIT INTERNODE SYS",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectDebit),
        IsQuaratined: false
      },
      {
        CAL: "INTERNODE N MACQUARIE NSW",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectCredit),
        IsQuaratined: false
      },
      {
        CAL: "INTERNET BPAY INTERNODE-N",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Dividend),
        IsQuaratined: true
      },
      {
        CAL: "DIRECT DEBIT INTERNODE-N",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Salary),
        IsQuaratined: true
      },
      {
        CAL: "INTERNODE N MACQUARIE P AUS",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Unknown),
        IsQuaratined: false
      },
      {
        CAL: "DIRECT DEBIT INTERNODE SYS DD",
        TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectDebit),
        IsQuaratined: false
      }
  ],
    LocatedIn: '',
    MerchantType: 'BankBranch',
    ABN: 321321312532,
    MerchantPresence: 'BricksMortar' ,
    PhoneNumber: '3532153213',
    Email: 'beb@gmail.com',
    Url: 'www.gladwa21.com',
    IsQuarantined: true,
    RecordLocked: false,
    QuarantinedCALs: [],
    DataCompletnessScore: 95,
    Tags: ['Warning', 'Error', 'Success'],
    BSBs: ['081508', '079901','064409','056205', '044806'],
  }
]

export let getCalsResponse1: MerchantSummary = {
  LWC_ID: 1000649284,
  SquareLogo: 'https://images.lookwhoscharging.com/chainlogos/universityofwesternaustralia.png',
  CircularLogo: null,
  ThreeToOneLogo: 'https://images.lookwhoscharging.com/chainlogos/sushisushi.3x1.png',
  SquareLogoDark: 'https://odiethemes.com/wp-content/uploads/2019/12/Square-Logo-Dark.png',
  CircularLogoDark: 'https://vectorverse.com/wp-content/uploads/2017/05/free-logo-badge-template-download-770x578.jpg',
  ThreeToOneLogoDark: 'https://www.logolynx.com/images/logolynx/bf/bf43f5f4dc360d32ee05a871e1c31bfa.jpeg',
  PrimaryName: 'Antonio',
  ChainName: 'ChainName',
  PrimaryAddress: 'PrimaryAddress',
  PrimaryCategory: 'PrimaryCategory',
  CurrentBusinessStatus: 'Active',
  TransactionDescriptions: ['99 BIKES PTY LTD BROOKVALE', 'ADAIRS BALGOWLAH', '#641 WAIKIKI RETAIL HONOLULU', '0402 WWW.USABOX.COM 305-406-1696', 'NABINTNL TRN FEE REV -MC', 'MULLALOO 6202 MULLALOO WA ', '99 BIKES PTY LTD BROOKVALE', 'ADAIRS BALGOWLAH', '#641 WAIKIKI RETAIL HONOLULU', '0402 WWW.USABOX.COM 305-406-1696', 'NABINTNL TRN FEE REV -MC', 'INTERNET PAYMENT CREDIT ARD'],
  TransactionDescriptionSummaries: [
    {
      CAL: "INTERNODE PTY LTD ADELAIDE SA",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.AMEX),
      IsQuaratined: true
    },
    {
      CAL: "INTERNET BPAY INTERNODE",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.ATM),
      IsQuaratined: false
    },
    {
      CAL: "INTERNET BPAY INTERNO",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.BPay),
      IsQuaratined: false
    },
    {
      CAL: "INTERNODE N MACQUARIE PARK NSW",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Card),
      IsQuaratined: false
    },
    {
      CAL: "DIRECT DEBIT INTERNODE SYS",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectDebit),
      IsQuaratined: false
    },
    {
      CAL: "INTERNODE N MACQUARIE NSW",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectCredit),
      IsQuaratined: false
    },
    {
      CAL: "INTERNET BPAY INTERNODE-N",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Dividend),
      IsQuaratined: true
    },
    {
      CAL: "DIRECT DEBIT INTERNODE-N",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Salary),
      IsQuaratined: true
    },
    {
      CAL: "INTERNODE N MACQUARIE P AUS",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.Unknown),
      IsQuaratined: false
    },
    {
      CAL: "DIRECT DEBIT INTERNODE SYS DD",
      TypeOfCAL: paymentMethodDisplayValues.get(PaymentMethod.DirectDebit),
      IsQuaratined: false
    }
],
  LocatedIn: 'TestLocation',
  MerchantType: 'BankBranch',
  ABN: 123456,
  MerchantPresence: 'BricksMortar' ,
  PhoneNumber: 'PhoneNumber',
  Email: 'Email',
  Url: 'Url',
  IsQuarantined: false,
  RecordLocked: false,
  QuarantinedCALs: [],
  DataCompletnessScore: 95,
  Tags: ['Warning', 'Error', 'Success', 'Empty Field', 'Empty Array', 'Object', 'Array'],
  BSBs: ['081508', '079901','064409','056205', '044806'],
};

export let getCalsResponse2: MerchantSummary = {
  LWC_ID: 1001015223,
  PrimaryName: 'Mishel',
  ChainName: 'ChainName',
  PrimaryAddress: 'PrimaryAddress',
  PrimaryCategory: 'PrimaryCategory',
  TransactionDescriptions: ['ALDI STORES BELMONT', 'CBA ATM CAMP\'TOWN ML 1 NSW 251793 AUS', 'MARINI\'S ON 57 KUALA LUMPUR', 'BLS*PREZI INC 415-3988012', 'GVB EXPLOITATIE B.V. AMSTERDAM', 'HIT CAFE DEE WHY GRA DEE WHY NSW'],
  LocatedIn: 'TestLocation 2',
  MerchantType: 'Branch',
  ABN: 123456,
  MerchantPresence: 'MobileMerchant',
  PhoneNumber: 'PhoneNumber',
  QuarantinedCALs: ['Cal3', 'Сal4'],
  Email: 'Email',
  Url: 'Url',
  IsQuarantined: true,
  RecordLocked: true,
  CurrentBusinessStatus: 'Active'
};

export let getCalsResponse3: MerchantSummary = {
  LWC_ID: null,
  QuarantinedCALs: null,
  PrimaryName: null,
  ChainName: null,
  PrimaryAddress: null,
  PrimaryCategory: null,
  TransactionDescriptions: [],
  LocatedIn: 'TestLocation 3',
  CurrentBusinessStatus: null
};

export let getSimilarCalsResponse: string[] = [
  'Cal1', 'Cal2', 'Cal3', 'Cal4'
]

export let getCalsResultResponse: CALRellocationResult[] = [
    {
        SourceLWC_ID: 456789,
        TargetLWC_ID: 497832,
        TransactionDescription : 'one',
        Status: CALRellocationResultStatus.Success,
        Message: 'Test message'
    },
    {
        SourceLWC_ID: 839487,
        TargetLWC_ID: 921637,
        TransactionDescription : 'four',
        Status: CALRellocationResultStatus.Warning,
        Message: 'Message 2'
    },
    {
        SourceLWC_ID: 287397,
        TargetLWC_ID: 728096,
        TransactionDescription : 'ten',
        Status: CALRellocationResultStatus.Error,
        Message: 'Some result'
    }
];
