import { FlatCategory } from "../../categories/flatcategory";
import { merchantPresenceStringValuesWithSpaces, MerchantPresence } from "../../enums/enums";

export let categories: FlatCategory[] = [
  {
    "CategoryID": 0,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Unclassified",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+2753",
    'PluralisedCategoryName': "Pluralised Category",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Shopping",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F6CD",
    'PluralisedCategoryName': "Pluralised Category",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.BricksMortar)
  },
  {
    "CategoryID": 2,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Grocery",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F34E",
    'PluralisedCategoryName': "Pluralised Category",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.OnlineOnly)
  },
  {
    "CategoryID": 3,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Food/Drink",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F37D",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Service",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+2699",
    'PluralisedCategoryName': "Pluralised Category",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.MobileMerchant)
  },
  {
    "CategoryID": 6,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Entertainment",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F39F",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.BricksMortar)
  },
  {
    "CategoryID": 7,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Travel",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F680",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.OnlineOnly)
  },
  {
    "CategoryID": 8,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Health & Fitness",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F4AA",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.MobileMerchant)
  },
  {
    "CategoryID": 9,
    "CategoryType": 0,
    "CategoryLevel": 1,
    "CategoryName": "Restricted",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F51E",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 10,
    "CategoryType": 1,
    "CategoryLevel": 1,
    "CategoryName": "Salary",
    "MatchPriority": 0,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F3B2",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 11,
    "CategoryType": 1,
    "CategoryLevel": 1,
    "CategoryName": "Bonus",
    "MatchPriority": 0,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F3B2",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 12,
    "CategoryType": 1,
    "CategoryLevel": 1,
    "CategoryName": "Cash Deposit",
    "MatchPriority": 0,
    "IsSensitive": false,
    "ParentCategoryID": null,
    "EmojiUnicode": "U+1F3B2",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.MobileMerchant)
  },
  {
    "CategoryID": 1001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Clothing",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F456",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.BricksMortar)
  },
  {
    "CategoryID": 1002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Toys/Hobby",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F3B2",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.MobileMerchant)
  },
  {
    "CategoryID": 1003,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Home",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F3E0",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1004,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Outdoor",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F3DE",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.MobileMerchant)
  },
  {
    "CategoryID": 1005,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Furniture",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F6CB",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1006,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Consumer Electronics",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F5B1",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.OnlineOnly)
  },
  {
    "CategoryID": 1007,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Department Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F3EC",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1008,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Hardware",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F528",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.BricksMortar)
  },
  {
    "CategoryID": 1009,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Office",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F4BC",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1010,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Jewellery",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F48D"
  },
  {
    "CategoryID": 1011,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Pet Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F415"
  },
  {
    "CategoryID": 1012,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Educational/Technology",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+2328",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1013,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Bookshop/News",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F4F0",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1014,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Second-hand",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+0032",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1015,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Software",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F4BD"
  },
  {
    "CategoryID": 1999,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Other",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1,
    "EmojiUnicode": "U+1F6CD"
  },
  {
    "CategoryID": 2001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Supermarket",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 2,
    "EmojiUnicode": "U+1F6D2"
  },
  {
    "CategoryID": 2002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Food Market",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 2,
    "EmojiUnicode": "U+1F349",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 2003,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Convenience Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 2,
    "EmojiUnicode": "U+1F3EA",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 2999,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Other",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 2,
    "EmojiUnicode": "U+1F34E"
  },
  {
    "CategoryID": 2999999,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "SomeOtherCategory",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 2999,
    "EmojiUnicode": "U+1F34E"
  },
  {
    "CategoryID": 3001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Fast Food",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 3,
    "EmojiUnicode": "U+1F354",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 3002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Asian",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 3,
    "EmojiUnicode": "U+1F962",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 3003,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "European",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 3,
    "EmojiUnicode": "U+1F1FA",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 3004,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Dessert",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 3,
    "EmojiUnicode": "U+1F370",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 3999,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Other",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 3,
    "EmojiUnicode": "U+1F37D",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Automotive",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F697"
  },
  {
    "CategoryID": 5002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Manufacturing/Engineering",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F3ED",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5003,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Telecommunication",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F4F1"
  },
  {
    "CategoryID": 5004,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Government",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F3DB",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5005,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Educational",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F4D6",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5006,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Utilities",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F4A1",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5007,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Professional Service",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F4BC",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5008,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Trade",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F527",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5009,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Technical",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F4BB",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5010,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Payment Gateway",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F4B3",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5011,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Freight/Postage",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F4EB"
  },
  {
    "CategoryID": 5012,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Religious",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F6D0",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5013,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Charity",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F397",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5014,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Pet",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+1F43E",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 5999,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Other",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 5,
    "EmojiUnicode": "U+2699"
  },
  {
    "CategoryID": 6001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Venue",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 6,
    "EmojiUnicode": "U+1F3DF"
  },
  {
    "CategoryID": 6002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Cultural",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 6,
    "EmojiUnicode": "U+1F5BC"
  },
  {
    "CategoryID": 6003,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Cinema",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 6,
    "EmojiUnicode": "U+1F37F"
  },
  {
    "CategoryID": 6004,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Ticketing Agency",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 6,
    "EmojiUnicode": "U+1F3AB",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 6005,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Hire Company",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 6,
    "EmojiUnicode": "U+1F3AA",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 6999,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Other",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 6,
    "EmojiUnicode": "U+1F39F",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 7001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Transport",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 7,
    "EmojiUnicode": "U+1F68C"
  },
  {
    "CategoryID": 7002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Travel Agent",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 7,
    "EmojiUnicode": "U+1F5FA",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 7003,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Accommodation",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 7,
    "EmojiUnicode": "U+1F3F0",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 7004,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Car Park",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 7,
    "EmojiUnicode": "U+1F17F",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 7005,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Petrol Station",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 7,
    "EmojiUnicode": "U+26FD",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 7006,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Car Hire",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 7,
    "EmojiUnicode": "U+1F699"
  },
  {
    "CategoryID": 7999,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Other",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 7,
    "EmojiUnicode": "U+1F30F",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 8001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Medical",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 8,
    "EmojiUnicode": "U+1F468",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 8002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Beauty",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 8,
    "EmojiUnicode": "U+1F9D6",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 8003,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Fitness Club",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 8,
    "EmojiUnicode": "U+1F938",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 8004,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Specialist Sports Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 8,
    "EmojiUnicode": "U+1F45F"
  },
  {
    "CategoryID": 8005,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Sporting Clubs",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 8,
    "EmojiUnicode": "U+26BD",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 9001,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Gambling",
    "MatchPriority": 1,
    "IsSensitive": true,
    "ParentCategoryID": 9,
    "EmojiUnicode": "U+1F3B0",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 9002,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Liquor Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 9,
    "EmojiUnicode": "U+1F37B"
  },
  {
    "CategoryID": 9004,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Bar/Club",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 9,
    "EmojiUnicode": "U+1F942",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified),
  },
  {
    "CategoryID": 9005,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Tobacconist",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 9,
    "EmojiUnicode": "U+1F51E"
  },
  {
    "CategoryID": 9006,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Sensitive",
    "MatchPriority": 1,
    "IsSensitive": true,
    "ParentCategoryID": 9,
    "EmojiUnicode": "U+1F51E",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 9007,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Sensitive bonus",
    "MatchPriority": 1,
    "IsSensitive": true,
    "ParentCategoryID": 11,
    "EmojiUnicode": "U+1F51E",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 9999,
    "CategoryType": 0,
    "CategoryLevel": 2,
    "CategoryName": "Other",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 9,
    "EmojiUnicode": "U+1F51E",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001001,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Accesories",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F576",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001002,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Sleepwear/Underwear",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F9E6",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001004,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Surf",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F3C4"
  },
  {
    "CategoryID": 1001005,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Sport",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+26F9",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001006,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Shoes",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F460"
  },
  {
    "CategoryID": 1001007,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Tailor",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F935",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001008,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Rental",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F3A9",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001009,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Costume",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F9DB",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001010,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Dry Cleaning/Laundry",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F9FA",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001011,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Uniform",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F454",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001012,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Bridal/Formal",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F470"
  },
  {
    "CategoryID": 1001013,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Maternity",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F930"
  },
  {
    "CategoryID": 1001014,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Childrenswear",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F9D2",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001015,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Womens Clothing",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F45A",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1001016,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Mens Clothing",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1001,
    "EmojiUnicode": "U+1F455",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1002001,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Hobby Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1002,
    "EmojiUnicode": "U+1F9E9",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1002002,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Toy Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1002,
    "EmojiUnicode": "U+1F9F8"
  },
  {
    "CategoryID": 1002004,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Art/Craft",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1002,
    "EmojiUnicode": "U+1F3A8",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1003001,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Gift",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1003,
    "EmojiUnicode": "U+1F381",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1003002,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Home Store",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1003,
    "EmojiUnicode": "U+1F3E1",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1003003,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Haberdashery",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1003,
    "EmojiUnicode": "U+1F9F5",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1003004,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Souvenirs",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 1003,
    "EmojiUnicode": "U+1F428",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  },
  {
    "CategoryID": 1003005,
    "CategoryType": 0,
    "CategoryLevel": 3,
    "CategoryName": "Souvenirs bonus",
    "MatchPriority": 1,
    "IsSensitive": false,
    "ParentCategoryID": 9007,
    "EmojiUnicode": "U+1F428",
    'PluralisedCategoryName': "",
    'DefaultMerchantPresence': merchantPresenceStringValuesWithSpaces.get(MerchantPresence.Unspecified)
  }
]
