import { hotChocolate, paymentMethodsQuery } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { NumberNameValue } from '../core/types/numbernamevalue';

@Injectable({
  providedIn: "root"
})
export class PaymentService {
  constructor(private apollo: Apollo) { }

  getPaymentMethods() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<NumberNameValue[]>({
        query: paymentMethodsQuery
      })
      .valueChanges;
  }
}
