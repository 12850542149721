import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { questionMark } from '../../core/constants/constants';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { transactionBankListResponse } from '../mockjsonresponses/transaction.response';

export function transactionBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split(questionMark)[0];

  let includesTransactionBankList = false;
  if (request.body && request.body.query) {
    includesTransactionBankList = request.body.query.includes('{\n  data: transactionBankList');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesTransactionBankList) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...transactionBankListResponse }
      }));
      resp.complete();
    })
  }
}
