import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { MerchantSubscriptionLookupResponse } from "../components/subscriptionsbymerchant/subscriptionsbymerchant.component";
import { merchantSubscriptionLookupQuery } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: "root"
})
export class SubscriptionsByMerchantService {
  constructor(private apollo: Apollo) { }

  getMerchantSubscriptions(lwcGuid: number, clientIds: number[]) {
    return this.apollo.watchQuery<MerchantSubscriptionLookupResponse>({
      query: merchantSubscriptionLookupQuery(lwcGuid, clientIds)
    }).valueChanges;
  }

}
