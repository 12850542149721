import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { searchFeedbackResponse, updateSearchFeedbacksResponse } from "../mockjsonresponses/searchfeedbackresponse";
import { HttpMethod } from "../../core/coreenums/coreenums";

export function searchfeedbackBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];
    let includesMatchFeedback = false;

    if(request.body && request.body.query) {
     includesMatchFeedback = request.body.query.includes('{feedbackType: {eq: "MatchFeedback"}}');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesMatchFeedback) {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: {...searchFeedbackResponse}
        }));
        resp.complete();
      });
    }

    let includesUpdateSearchFeedback = false;
    if (request.body && request.body.query) {
      includesUpdateSearchFeedback = request.body.query.includes("{\n  updateSearchFeedback");
    }
    if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesUpdateSearchFeedback) {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: {...updateSearchFeedbacksResponse}
        }));
        resp.complete();
      })
    }
}
