import { BaseStateComponent } from '../basestate/basestate.component';
import { Component, Injector, Input } from '@angular/core';
import { LoadingService } from '../../core/uiservices/loading.service';
import { MerchantAuditService } from '../../services/merchantaudit.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { MerchantAuditState } from './merchantaudit.state';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { MerchantAudit } from './merchantaudit.dto';

@Component({
  selector: 'app-merchantaudit',
  templateUrl: './merchantaudit.component.html',
  styleUrls: ['./merchantaudit.component.scss']
})
export class MerchantAuditComponent extends BaseStateComponent<MerchantAuditState> {
  @Input() lwcId: number;

  constructor(injector: Injector, private loadingService: LoadingService, private merchantAuditService: MerchantAuditService,
    private dateTimeService: DateTimeService) {
    super(injector)
  }

  ngOnInit() {
    this.state = {
      merchantAudit: [],
      loadedLwcId: null
    };
    this.restoreState();
    this.getMerchantAudit();
  }


  sortByDateAfterClickedColumnName(firstDate: string, secondDate: string) {
    return Date.parse(firstDate) - Date.parse(secondDate);
  }

  getMerchantAudit() {
    if (this.lwcId && (this.state.merchantAudit.length === 0 || (this.state && this.state.loadedLwcId !== this.lwcId))) {
      this.loadingService.setLoading();
      this.state.loadedLwcId = this.lwcId;
      this.merchantAuditService.getMerchantAudit(this.state.loadedLwcId)
        .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
        .subscribe(response => {
          this.loadingService.clearLoading();
          if (response && response.data) {
            this.state.merchantAudit = response.data;
            for (let merchant of this.state.merchantAudit) {
              merchant.createDateTime = this.dateTimeService.formatWithHours(merchant.createDateTime);
            }
            this.state.merchantAudit.sort(this.defaultSortByDate);
          }
        });
    }
  }

  private defaultSortByDate(firstObject: MerchantAudit, secondObject: MerchantAudit) {
    return Date.parse(firstObject.createDateTime) - Date.parse(secondObject.createDateTime);
  }
}
