export enum UserStatusType {
  Active = 1,
  InActive = 2
}

export enum RoleType {
  IndexManagerGlobalAdministrator = 1,
  IndexManagerAdmin = 2,
  IndexManagerTeamLead = 3,
  IndexManagerIndexer = 4,
  IndexManagerIndexerWithPurge = 5
}

export enum RegionType {
  AUS = 1,
  NZL = 2
}
