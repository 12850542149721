import { AusIndexedComments } from "../../components/enrichmerchant/enrichmerchant.models";

export let ausIndexedComments: AusIndexedComments[] = [
  {
    AUS_Indexed_CommentID: 10000284,
    LWC_ID: 110000284,
    Comment: "Warning: 1000947863 enriched with a score of 85 in 8s",
    NameOfIndexer: "LWC System",
    IDOfIndexer: 45,
    lastUpdated: '2002-10-02 04:18:25.100',
    IsActive: false,
    IsActiveComment: "Default category set to Food/Drink >> Other",
  },
  {
    AUS_Indexed_CommentID: 15200284,
    LWC_ID: 1200999284,
    Comment: "Warning: 7895959 enriched with a score of 78 in 3s",
    NameOfIndexer: "LWC System 2",
    IDOfIndexer: 26,
    lastUpdated: '2009-10-02 06:40:15.100',
    IsActive: false,
    IsActiveComment: "Default category set to Entertainment >> Company",
  },
  {
    AUS_Indexed_CommentID: 19959284,
    LWC_ID: 178205455,
    Comment: "Warning: 8596 enriched with a score of 54 in 30s",
    NameOfIndexer: "LWC System 3",
    IsActiveComment: "Success:  100098963 enriched with a score of 155",
  }
];

export let updatedAusIndexedComment: AusIndexedComments = {
  AUS_Indexed_CommentID: 13249595,
  LWC_ID: 150986328,
  Comment: "Warning: 8596 enriched with a score of 15 in 32s",
  NameOfIndexer: "LWC System 4",
  lastUpdated: "2022-08-17 08:43:10.297",
  IsActiveComment: "Success:  10009563 enriched with a score of 100",
  IDOfIndexer: 58,
  IsActive: true
};
