import { apiPathConstants } from './../../core/api/apipathconstants';
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { hotChocolate, searchBPayInternalQuery, searchEnrichBPayQuery } from '../../gqlconstants/gqlconstants';
import { SearchBPayInternal } from './bpaylookup.models';
import { StringService } from '../../core/formatting/string.service';
import { ApiClient } from '../../core/api/apiclient';
import { ApiService } from '../../core/api/api.service';
import { MerchantSummary } from '../mergemerchants/merchantsummary';

@Injectable({
  providedIn: 'root'
})
export class BPayLookupService {

  constructor(
    private apollo: Apollo, private stringService: StringService,
    private apiClient: ApiClient, private apiService: ApiService,) { }

  getBPayInternal(bPayCode: string) {
    let graphQLQuery = searchBPayInternalQuery;

    return this.apollo
      .use(hotChocolate)
      .watchQuery<SearchBPayInternal[]>({
        query: gql`${this.stringService.format(graphQLQuery, bPayCode)}`
      })
      .valueChanges;
  }

  getEnrichBPay(bPayCode: string) {
    let graphQLQuery = searchEnrichBPayQuery;

    return this.apollo
      .use(hotChocolate)
      .watchQuery<SearchBPayInternal[]>({
        query: gql`${this.stringService.format(graphQLQuery, bPayCode)}`
      })
      .valueChanges;
  }

  getMerchantsummary(lwcId: number[]) {
    let data = {
      LWCIds: lwcId
    }
    return this.apiClient.post<MerchantSummary[]>(this.apiService.buildUrl(apiPathConstants.merchantLookupMerchantSummary), data);
  }
}
