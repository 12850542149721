import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpStatusCode } from '../../enums/enums';
import { syncClientApiKeyFromDbResponse, SyncClientApiKeyResponse, UpsertClientResponseData } from '../../models/manageapikeys.models';

@Injectable()
export class NotificationService {
  constructor(private matSnackBar: MatSnackBar) { }

  hasMessageProperty(error: any): boolean {
    if (error.hasOwnProperty("error") && error.error != null && error.error.hasOwnProperty("error")) {
      try {
        var msg = JSON.parse(error.error.error);
        return (msg.Message != null);
      }
      catch {
        return false;
      }
    }
  }

  notifyError(error: any = null, customErrorMessage?: string) {
    let errorMessage = this.buildErrorMessage(error, customErrorMessage);
    this.notifyInternal(errorMessage, true);
  }

  buildErrorMessage(error: any = null, customErrorMessage?: string) {
    let errorMessage = 'Error';

    if (error.status === HttpStatusCode.Forbidden) {
      errorMessage += ': You are not authorised for this operation';
      return errorMessage;
    }

    if (customErrorMessage) {
      errorMessage += `: ${customErrorMessage}`;
      return errorMessage;
    }

    if (error) {
      if (this.hasMessageProperty(error)) {

        var errorObject = JSON.parse(error.error.error);

        errorMessage += `: ${errorObject.Message}`;
        if (errorObject.ExceptionMessage != null) {
          errorMessage += `: ${errorObject.ExceptionMessage}`;
        }
      } else if (typeof error === 'string') {
        errorMessage += `: ${error}`;
      } else if (error.status === HttpStatusCode.Forbidden) {
        errorMessage += ': You are not authorised for this operation';
      } else if (typeof error.error === 'string') {
        errorMessage += `: ${error.error}`;
      } else if (error.error && typeof error.error.error === 'string') {
        errorMessage += `: ${error.error.error}`;
      } else if (error.error && error.error.error && typeof error.error.error.message === 'string') {
        errorMessage += `: ${error.error.error.message}`;
      }
    }

    return errorMessage;
  }

  notifySuccess(notificationMessage?: string) {
    let message = 'Action success';

    if (notificationMessage) {
      message = notificationMessage;
    }

    this.notifyInternal(message);
  }

  notifySyncResponse(notificationMessage?: SyncClientApiKeyResponse) {
    console.log(notificationMessage);

    let message = 'Action success';

    if (notificationMessage.syncClientApiKey != null) {
      message = notificationMessage.syncClientApiKey;
    }
    this.notifyInternal(message);
  }

  notifySyncDbResponse(notificationMessage?: syncClientApiKeyFromDbResponse) {
    console.log(notificationMessage);

    let message = 'Action success';

    if (notificationMessage.syncClientApiKeyFromDB != null) {
      message = notificationMessage.syncClientApiKeyFromDB;
    }
    this.notifyInternal(message);
  }

  checkSystemMessage(notificationMessage: UpsertClientResponseData) {

    let message = "Action success";
    if (notificationMessage.upsertClientApiKey.systemMessage != null) {
      message = notificationMessage.upsertClientApiKey.systemMessage;
    }

    this.notifyInternal(message);
  }

  notify(notificationMessage: string) {
    this.notifyInternal(notificationMessage);
  }

  private notifyInternal(notificationMessage: string, isError?: boolean) {
    this.matSnackBar.open(notificationMessage, 'Ok', { duration: isError ? null : 4000 });
  }
}
