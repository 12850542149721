import { CalCount } from "../../components/missedcals/calcount";

export let calCount: CalCount[] = [
    {
        CAL: 'BURLEIGH WATERS MIAMI QLD',
        Count: 1
    },
    {
        CAL: 'HIT CAFE DEE WHY GRA DEE WHY NSW',
        Count: 2
    },
    {
        CAL: '99 BIKES PTY LTD BROOKVALE',
        Count: 3
    },
    {
        CAL: 'ADAIRS BALGOWLAH',
        Count: 4
    },
    {
        CAL: '0RANDWICK PS 4250 RANDWICK',
        Count: 5
    },
    {
        CAL: '0402 WWW.USABOX.COM 305-406-1696',
        Count: 6
    },
    {
        CAL: 'NABINTNL TRN FEE REV -MC',
        Count: 7
    },
    {
        CAL: 'VFS SERVICES AUSTRAL MELBOURNE',
        Count: 8
    },
    {
        CAL: 'SUBWAY ST LUCIA 0001 ST LUCIA',
        Count: 9
    },
    {
        CAL: 'CROWNE PLAZA QUEENSTOWNQUEEN',
        Count: 10
    }
];