import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { scrapingBpayResponse, scrapingNSWFoodAuthrityResponse, scrapingDataSources, viewScrapedData } from "../mockjsonresponses/scrapingdatasourcesresponse";

export function scrapingDataSourcesService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url;
    const scraperName = '?scraperName=';
    const scraperBpay = scraperName + 'bpay';
    const scraperNSWFoodAuthrity = scraperName + 'nswfoodauthority';

    if (cleanUrl.includes(apiPathConstants.dataSources) && method === "GET") {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: [...scrapingDataSources]
            }));
            resp.complete();
        })
    }

    if (cleanUrl.includes(apiPathConstants.screapeStatus + scraperBpay) && method === 'GET') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: { ...scrapingBpayResponse }
            }));
            resp.complete();
        })
    }
    if (cleanUrl.includes(apiPathConstants.startNewScrape + scraperBpay) && method === 'POST') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200
            }));
            resp.complete();
        })
    }
    if (cleanUrl.includes(apiPathConstants.screapeStatus + scraperNSWFoodAuthrity) && method === 'GET') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: scrapingNSWFoodAuthrityResponse
            }));
            resp.complete();
        })
    }
    if (cleanUrl.includes(apiPathConstants.startNewScrape + scraperNSWFoodAuthrity) && method === 'POST') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200
            }));
            resp.complete();
        })
    }
    if (cleanUrl.includes(apiPathConstants.sitesData) && method === 'GET') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: [...viewScrapedData]
            }));
            resp.complete();
        })
    }
    if (cleanUrl.includes(apiPathConstants.eventingEnrichMerchant) && method === 'POST') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200
            }));
            resp.complete();
        })
    }
}