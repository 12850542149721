import { TransactionBank } from './../../models/ausindexingresult';
import { LoadingService } from './../../core/uiservices/loading.service';
import { Component, OnInit, ElementRef, ViewChild, Injector } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { BaseStateComponent } from '../basestate/basestate.component';
import { BatchMerchantSearchState } from './batchmerchantsearchstate';
import { Observable } from 'rxjs';
import { BatchRequestService, BatchRequestStatus } from '../../services/batchsearchrequest.service';
import { MerchantBatchSearchRequest, ConcurrentRequest } from './merchantbatchsearchrequest';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import * as moment from 'moment';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { MerchantSearchExporterService } from '../../services/merchantsearchexporter.service';
import { Options } from 'angular2-csv';
import { ArrayService } from '../../core/collections/array.service';
import { calsSearcherCsvSchemaProps, CALsSearcherCSVSchema } from './calssearchercsvschema';
import { BatchSearchResult } from './batchsearchresult';
import { APISearchResultV4 } from '../../models/searchmodelsv4';
import { StringService } from '../../core/formatting/string.service';
import { SearchResultsCodes } from '../../enums/enums';
import { TransactionService } from '../../services/transaction.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { BankAccountTransactionTypeService } from "../../services/bankaccounttransactiontype.service";
import { BankAccountTransactionType } from "../../models/ausindexingresult";
import { SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-batchmerchantsearch',
  templateUrl: './batchmerchantsearch.component.html',
  styleUrls: ['./batchmerchantsearch.component.scss']
})
export class BatchMerchantSearchComponent extends BaseStateComponent<BatchMerchantSearchState> implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  optionalFieldsCtrl = new FormControl();
  filteredFields: Observable<string[]>;
  selectableFields: string[];
  fields: string[];
  csvSchemaProps = calsSearcherCsvSchemaProps;
  searchWasPerformed = false;
  failedRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
  searchCalsSplitted: string[] = [];
  batchSearchModels: MerchantBatchSearchRequest[] = [];
  batchSearhResults: BatchSearchResult<APISearchResultV4[]>[] = [];
  batchRequestStatus: BatchRequestStatus<APISearchResultV4[]> = {
    areMerchantsLoading: false,
    searchInProgressCount: 0,
    searchCompletedCount: 0,
    searchFailedCount: 0,
    batchSearhResults: []
  };
  transactionBankLists: TransactionBank[] = [];

  bankAccountTransactionTypes: BankAccountTransactionType[] = [];

  private batchSizeProvider = (r: MerchantBatchSearchRequest) => r.bank_transactions.length;

  @ViewChild('optionalFieldsInput') optionalFieldsInput: ElementRef<HTMLInputElement>;

  @ViewChild('chipList', { static: true }) chipList: MatChipList;

  selectionType = SelectionType;

  selected: any[];
  calRecords: string[];

  isFullSearch: boolean = false;
  disabledUpdateCacheBtn: boolean = true

  constructor(private merchantSearchExporterService: MerchantSearchExporterService,
    private csvComponentService: CsvComponentService, private batchRequestService: BatchRequestService, private arrayService: ArrayService,
    private dateTimeService: DateTimeService, private stringService: StringService, injector: Injector, private loadingService: LoadingService,
    private transactionService: TransactionService, private valueCheckerService: ValueCheckerService, private bankAccountTransactionTypeService: BankAccountTransactionTypeService) {
    super(injector);
    this.selected = [];
    this.calRecords = [];
  }

  ngOnInit() {
    this.state = {
      searchCals: '',
      chosenFields: [],
      searchResult: [],
      fields: [...this.merchantSearchExporterService.calsSearcherCsvSchemaFields],
      transactionBankId: null,
      bankAccountTransactionType: null
    };
    this.selectableFields = [...this.merchantSearchExporterService.calsSearcherCsvSchemaFields];

    this.filteredFields = this.optionalFieldsCtrl.valueChanges.pipe(
      startWith(null),
      map((field: string | null) => field ? this.filter(field) : this.selectableFields.slice()));

    this.restoreState();
    this.setTransactionBankList();
    this.setTransactionTypes();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  resetForm() {
    this.state.fields = [...this.merchantSearchExporterService.calsSearcherCsvSchemaFields];
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.stringService.isNonEmptyTrimmedStringExistInArray(value, this.state.fields)) {
      this.state.fields.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.optionalFieldsCtrl.setValue(null);
  }

  remove(field: string): void {
    const index = this.state.fields.indexOf(field);

    if (index >= 0) {
      this.state.fields.splice(index, 1);
    }
  }

  onSelect(value: any) {
    const rowIndex = this.state.searchResult.indexOf(value.selected[0])
    this.selected = []
    if (rowIndex != -1) {
      for (let i of value.selected) {
        this.selected.push(i);
      }
    } else {
      for (let i = 0; i < this.selected.length; i++) {
        this.selected.splice(i);
      }
    }

    if (this.selected.length == 0) {
      this.disabledUpdateCacheBtn = true
    } else {
      this.disabledUpdateCacheBtn = false
    }
  }

  sendCals() {
    if (this.selected != null) {
      for (let i of this.selected) {
        this.calRecords.push(i.CAL);
      }
      this.loadingService.setLoading();
      this.batchRequestService.sendCalsToCache(this.calRecords).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
        this.loadingService.clearLoading();
        this.calRecords = [];
        this.selected = [];
      });
    }
  }

  matSelected(event: MatAutocompleteSelectedEvent): void {
    if (!this.state.fields.includes(event.option.viewValue)) {
      this.state.fields.push(event.option.viewValue);
    }
    this.optionalFieldsInput.nativeElement.value = '';
    this.optionalFieldsCtrl.setValue(null);
  }

  fullSearchCals() {
    this.isFullSearch = true;
    this.searchCalsSplitted = this.state.searchCals.split('\n')
      .filter(cal => /\S/.test(cal));

    this.searchWasPerformed = true;
    this.batchSearchModels = this.batchRequestService.prepareCalsForRequest(this.searchCalsSplitted);
    this.batchSearchModels[0].transactionBank = this.state.transactionBankId;
    this.batchSearchModels[0].bank_account_transaction_type = this.state.bankAccountTransactionType;
    this.batchSearchModels[0].force_full_search = true;
    this.batchRequestStatus.areMerchantsLoading = true;
    this.state.chosenFields = this.arrayService.getWithoutDuplicates(this.state.fields);
    this.state.searchResult = [];
    this.batchRequestStatus.batchSearhResults = [];
    this.batchRequestStatus.searchInProgressCount = 0;
    this.batchRequestStatus.searchCompletedCount = 0;
    this.batchRequestStatus.searchFailedCount = 0;
    this.searchBatch();
  }

  searchCals() {
    this.isFullSearch = false;
    this.searchCalsSplitted = this.state.searchCals.split('\n')
      .filter(cal => /\S/.test(cal));
    this.searchWasPerformed = true;
    this.batchSearchModels = this.batchRequestService.prepareCalsForRequest(this.searchCalsSplitted);
    this.batchSearchModels[0].transactionBank = this.state.transactionBankId;
    this.batchSearchModels[0].bank_account_transaction_type = this.state.bankAccountTransactionType;
    this.batchSearchModels[0].force_full_search = false;
    this.batchRequestStatus.areMerchantsLoading = true;
    this.state.chosenFields = this.arrayService.getWithoutDuplicates(this.state.fields);
    this.state.searchResult = [];
    this.batchRequestStatus.batchSearhResults = [];
    this.batchRequestStatus.searchInProgressCount = 0;
    this.batchRequestStatus.searchCompletedCount = 0;
    this.batchRequestStatus.searchFailedCount = 0;
    this.searchBatch();
  }

  searchBatch() {
    let retryRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    let successRequests: ConcurrentRequest<MerchantBatchSearchRequest>[] = [];
    this.batchRequestStatus.batchSearhResults = [];
    this.batchRequestService.doBatchRequest<MerchantBatchSearchRequest, APISearchResultV4[]>(this.batchSearchModels, this.batchSearchModels,
      this.batchSearchModels, retryRequests, successRequests, this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
      .subscribe((batchREquestStatus) => {

        this.refreshSearchResults(batchREquestStatus);
      });
  }

  retryFailedSearches() {
    let requests = this.failedRequests.map(d => d.data);
    this.failedRequests = [];
    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestService.doBatchRequest(requests, this.batchSearchModels, requests, [], [], this.failedRequests, this.batchSizeProvider, this.batchRequestStatus)
      .subscribe((batchREquestStatus) => {
        this.refreshSearchResults(batchREquestStatus);
      });
  }

  getCsvOptions(headers: string[]): Options {
    let additionalCsvOptions = {
      headers: headers,
      keys: headers,
      showTitle: false,
      title: ''
    };

    let csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...additionalCsvOptions };
    return csvOptions;
  }

  getCsvFileName() {
    return 'MerchantSearch-' + this.dateTimeService.formatForFileName(moment());
  }

  isFieldSelected(field: string) {
    return this.state.chosenFields.includes(field);
  }

  isClearButttonDisabled() {
    let isDisabled = this.state.fields.length == 0;
    return isDisabled;
  }

  clearSelectedFields() {
    this.state.fields = [];
    this.chipList.writeValue([]);
  }

  private processSearchResults() {
    let displayResults = [];

    for (let i = 0; i < this.batchSearchModels.length; i++) {
      let searchResultBatch = this.batchSearhResults.find(r => r.requestIndex === i);

      if (!searchResultBatch) {
        continue;
      }


      for (let bankTransaction of this.batchSearchModels[i].bank_transactions) {

        let item = searchResultBatch.response.find(r => r.cal === bankTransaction.cal);

        if (item) {
          let exportObj = this.merchantSearchExporterService.getExportObjectsFromSingleSearchResultV4(item, this.state.chosenFields);
          displayResults.push(exportObj);
        }
      }
    }
    this.state.searchResult = displayResults;
  }

  rowWithError(row: Partial<CALsSearcherCSVSchema>) {
    if (row.resultCode && (row.resultCode === SearchResultsCodes.HighConfidenceMatch
      || row.resultCode === SearchResultsCodes.InternationalCAL)) {
      if (row.merchantDetailsExist === false) {
        return true;
      }
      return false;
    }
    return false;
  }

  isInternationalCal(resultCode: number) {
    resultCode !== SearchResultsCodes.InternationalCAL;
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.selectableFields.filter(field => field.toLowerCase().indexOf(filterValue) === 0);
  }

  private refreshSearchResults(batchREquestStatus: BatchRequestStatus<APISearchResultV4[]>) {
    this.batchRequestStatus = batchREquestStatus;

    if (this.batchRequestStatus.searchInProgressCount === 0) {
      this.batchSearhResults = this.batchRequestStatus.batchSearhResults.concat(batchREquestStatus.batchSearhResults)
      this.processSearchResults();
    }
  }

  private setTransactionBankList() {
    if (this.transactionBankLists.length === 0) {
      this.loadingService.setLoading();
      this.transactionService.getTransactionBankList()
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          this.loadingService.clearLoading();
          this.transactionBankLists = response.data;
          if (this.valueCheckerService.isEmptyNullOrUndefined(this.state.transactionBankId)) {
            this.state.transactionBankId = this.transactionBankLists.find(t => t.name === "Unknown").id;
          }
        });
    }
  }


  private setTransactionTypes() {
    this.loadingService.setLoading();
    this.bankAccountTransactionTypeService.getBankAccountTransactionTypes()
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.length > 0) {
          this.bankAccountTransactionTypes = response.data;
          this.bankAccountTransactionTypes.sort((a, b) => {
            return this.stringService.sortAlphabetically(a.name, b.name);
          });
        }
      });
  }
}
