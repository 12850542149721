import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { missingLogoListResponse } from '../mockjsonresponses/bulklogoedit.response';

export function bulkLogoEditBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
const cleanUrl = url.split('?');

  if (cleanUrl[0].endsWith(apiPathConstants.missingLogoList) && cleanUrl[1].startsWith('max=') && method === "GET") {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: missingLogoListResponse
      }));

      resp.complete();
    })
  }
}
