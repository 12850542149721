import { Injectable } from "@angular/core";
import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";
import { apiPathConstants } from "../core/api/apipathconstants";
import { Observable } from "rxjs";
import { OpeningHours } from "../models/searchmodelsv4";

@Injectable()
export class MerchantOpeningHoursService {

    constructor(private apiService: ApiService, private apiClient: ApiClient) {
    }

    getOpeningHours(cal: number): Observable<OpeningHours> {
        return this.apiClient.get<OpeningHours>(this.apiService.buildUrl(apiPathConstants.openingHours, cal.toString()));
    }

    updateOpeningHours(cal: number, updatedOpeningHours: OpeningHours) {
        return this.apiClient.post<void>(this.apiService.buildUrl(apiPathConstants.openingHours, cal.toString()), updatedOpeningHours);
    }
}
