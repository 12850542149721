import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { ApiClient } from '../core/api/apiclient';
import { Observable } from 'rxjs';
import { Tidmid } from './managetidmid/tidmid';

@Injectable()
export class TidmidService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  get(): Observable<Tidmid[]> {
    return this.apiClient.get<Tidmid[]>(this.apiService
        .buildUrl('tidmid'));
  }

  create(tidmid: Partial<Tidmid>) {
    return this.apiClient.post<Tidmid>(this.apiService
      .buildUrl('tidmid'), tidmid);
  }

  update(id: number, tidmid: Partial<Tidmid>) {
    return this.apiClient.put<Tidmid>(this.apiService
        .buildUrl('tidmid', id.toString()), tidmid);
  }

  delete(tidmid: Tidmid) {
    return this.apiClient.delete<void>(this.apiService
        .buildUrl('tidmid', tidmid.TIDMID_id.toString()));
  }
}
