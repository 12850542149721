import { UnmatchedMerchantResponse } from './../../components/managedatascraping/unmatchedmerchants/unmatchedmerchants.component';
import { ExecutionResult } from 'graphql';

export let unmatchedMerchantsResponse: ExecutionResult<UnmatchedMerchantResponse> = {
  data: {
    unmatchedMerchants: [
      {
        scrapeCode: 'http%3a%2f%2fwww.mobile-experts.com.au',
        lwcId: 1001105457,
        status: 'Completed',
        closedConfidenceLevel: 'Monitor',
        message: 'LastSearchResult: StoreOperational'
      },
      {
        scrapeCode: '"https%3a%2f%2fwww.myer.com.au',
        lwcId: 1000000042,
        status: 'Completed',
        closedConfidenceLevel: 'Monitor',
        message: 'LastSearchResult: StoreOperational'
      },
      {
        scrapeCode: 'http%3a%2f%2fwww.mobile-experts.com.au',
        lwcId: 1000621674,
        status: 'In Process',
        closedConfidenceLevel: 'Monitor',
        message: 'StoreOperational'
      }
    ]
  }
}
