import { propertyOf } from "../core/services/reflection.service";
import { BatchSplitCalResponseDisplay } from "../components/batchcalsplitter/batchcalsplittermodels";
import { BankAccountTransactionType } from "./ausindexingresult";

export interface CALParserResult {
  TransactionDescription: string;
  DespacedTransactionDescription: string;
  StandardisedTransactionDescription: string;
  CardAcceptor: string;
  FormattedCardAcceptor: string;
  CardAcceptorPredictor: string;
  IsPtyLtd: boolean;
  IsCompany: boolean;
  Branch: string;
  Suburb: string;
  SuburbExt: Suburb;
  SuburbMatch: SuburbMatch;
  State: string;
  StateConfidence: ConfidenceLevel;
  Country: string;
  WebAddress: string;
  PhoneNumber: string;
  TransactionReference: string;
  TransactionAmounts: Amount[];
  BankKeywords: string;
  PaymentGateway: string;
  IsRestaurant: boolean;
  IsPharmacy: boolean;
  IsHotel: boolean;
  IsCarPark: boolean;
  OtherKeywords: string;
  WhatIsTheLocator: LocatorType;
  ExecutionTime: number;
  CandidateSuburbs: string[];
  CandidateStates: string[];
  CandidateLatLon: [number, number][];
  DebitCardAdditionalInformation: AdditionalDebitInformation;
  CALPaymentMethod: number;
  CashOutInformation: CashOut;
  BPayAdditionalInformation: BPayAdditionalInformation;
  TransactionType: number;
}

export interface CALParserResultDisplay extends CALParserResult {
  TransactionTypeDisplay?: string;
}

export interface SuburbExt {
  Name: string;
  State: string;
  Postcode: string;
  Country: string;
  Lat: number;
  Lon: number;
  Area_Sq_KM: number;
}

export interface Suburb {
  Name: string;
  State: string;
  Postcode: string;
  Country: string;
  Lat: number;
  Lon: number;
  Area_Sq_KM: number;
}

export interface SuburbMatch {
  Suburb: Suburb;
  Confidence: number;
  OtherPossibleSuburbs: string[];
  StateCandidates: string[];
}
export enum ConfidenceLevel {
  Certain = 10,

  Uncertain = 5,

  Conflicting = 1
}
export interface Amount {
  Value: number;
  Currency: string;
}

export enum LocatorType {
  WebAddress,

  PhoneNumber,

  Suburb,

  State,

  Country,

  None
}
export interface AdditionalDebitInformation {
  OriginalDebitCAL: string;
  TypeOfDebitCard: string;
  TransactionDate: Date;
  MerchantReferenceNumber: string;
  Last4DigitsOfCard: string;
  IsWordSplit: boolean;
}
export enum PaymentMethod {
  Unknown,
  BPay,
  EFTPOS,
  NABVisaDebit,
  ANZVisaDebit,
  NABVisaPlatinumDebit,
  DEFT,
  NABVisaMiscDebit,
  ATMUsingANZCard,
  CBAPTag,
  OtherExpense,
  OtherIncome,
  TapAndGo,
  CUAVisa,
  QTMBVisa,
  INGVisa,
  DirectDebit,
  STGeorgeVisa,
  AUSTBankVisa,
  BOQVisaDebit,
  DirectCredit,
  InternetTransfer,
  AMEX,
  Osko,
  HandyWay,
  Cirrus,
  Maestro,
  Giro,
  Visa,
  Mastercard,
  Card,
  ATM,
  /// <summary>
  /// e.g. PAY/SALARY FROM BARWON HEALTH xxxx1084
  /// </summary>
  Salary,
  Dividend
}

export interface CashOut {
  CashOutAmount: number;
  CashOutCurrencyCode: string;
}

export interface BPayAdditionalInformation {
  BPayBillerCode: number;
  BPayCustomerReferenceNumber: number;
}

export const batchSplitCalResponseDisplayProps = {
  TransactionDescription: propertyOf<BatchSplitCalResponseDisplay>('TransactionDescription'),
  StandardisedTransactionDescription: propertyOf<BatchSplitCalResponseDisplay>('StandardisedTransactionDescription'),
  CardAcceptor: propertyOf<BatchSplitCalResponseDisplay>('CardAcceptor'),
  FormattedCardAcceptor: propertyOf<BatchSplitCalResponseDisplay>('FormattedCardAcceptor'),
  CardAcceptorPredictor: propertyOf<BatchSplitCalResponseDisplay>('CardAcceptorPredictor'),
  IsPtyLtd: propertyOf<BatchSplitCalResponseDisplay>('IsPtyLtd'),
  IsCompany: propertyOf<BatchSplitCalResponseDisplay>('IsCompany'),
  Branch: propertyOf<BatchSplitCalResponseDisplay>('Branch'),
  Country: propertyOf<BatchSplitCalResponseDisplay>('Country'),
  WebAddress: propertyOf<BatchSplitCalResponseDisplay>('WebAddress'),
  PhoneNumber: propertyOf<BatchSplitCalResponseDisplay>('PhoneNumber'),
  TransactionReference: propertyOf<BatchSplitCalResponseDisplay>('TransactionReference'),
  BankKeywords: propertyOf<BatchSplitCalResponseDisplay>('BankKeywords'),
  PaymentGateway: propertyOf<BatchSplitCalResponseDisplay>('PaymentGateway'),
  IsRestaurant: propertyOf<BatchSplitCalResponseDisplay>('IsRestaurant'),
  IsPharmacy: propertyOf<BatchSplitCalResponseDisplay>('IsPharmacy'),
  IsHotel: propertyOf<BatchSplitCalResponseDisplay>('IsHotel'),
  IsCarPark: propertyOf<BatchSplitCalResponseDisplay>('IsCarPark'),
  OtherKeywords: propertyOf<BatchSplitCalResponseDisplay>('OtherKeywords'),
  FormattedTransactionAmountsDisplay: propertyOf<BatchSplitCalResponseDisplay>('formattedTransactionAmountsDisplay'),
  LonDisplay: propertyOf<BatchSplitCalResponseDisplay>('lonDisplay'),
  LatDisplay: propertyOf<BatchSplitCalResponseDisplay>('latDisplay'),
  StateDisplay: propertyOf<BatchSplitCalResponseDisplay>('stateDisplay'),
  SuburbConfidenceDisplay: propertyOf<BatchSplitCalResponseDisplay>('suburbConfidenceDisplay'),
  StateConfidenceDisplay: propertyOf<BatchSplitCalResponseDisplay>('stateConfidenceDisplay'),
  BPayBillerCodeDisplay: propertyOf<BatchSplitCalResponseDisplay>('bPayBillerCodeDisplay'),
  BPayCustomerReferenceNumberDisplay: propertyOf<BatchSplitCalResponseDisplay>('bPayCustomerReferenceNumberDisplay'),
  WhatIsTheLocatorDisplay: propertyOf<BatchSplitCalResponseDisplay>('whatIsTheLocatorDisplay'),
  Suburb: propertyOf<BatchSplitCalResponseDisplay>('Suburb'),
  TransactionTypeDisplay: propertyOf<BatchSplitCalResponseDisplay>('transactionTypeDisplay')
}
