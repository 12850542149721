import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector } from '@angular/core';
import { AddressCheckState, AddressVerification } from './addresscheck.state';
import { LoadingService } from '../../core/uiservices/loading.service';
import { AddressVerificationService } from '../../services/addressverification.service';
import { finalize } from 'rxjs/operators';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { NotificationService } from '../../core/uiservices/notification.service';

export type AddressVerificationResponse = {
  addressVerifications: AddressVerification[];
}

@Component({
  selector: 'app-addresscheck',
  templateUrl: './addresscheck.component.html',
  styleUrls: ['./addresscheck.component.scss']
})
export class AddressCheckComponent extends BaseStateComponent<AddressCheckState> implements OnInit {

  constructor(injector: Injector, private loadingService: LoadingService, private addressVerificationService: AddressVerificationService,
    private csvComponentService: CsvComponentService, private notificationService: NotificationService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      addressVerifications: []
    };
    this.restoreState();
    this.getAddressVerifications();
  }

  getAddressVerifications() {
    this.loadingService.setLoading();
    this.addressVerificationService.getAddressVerifications()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data) {
          this.state.addressVerifications = response.data.addressVerifications;
        }
      });
  }

  startAddressCheck() {
    this.loadingService.clearLoading();
    this.addressVerificationService.startAddressCheck()
    .pipe(finalize(() => this.loadingService.clearLoading()))
    .subscribe(() => {
      this.notificationService.notifySuccess();
    })
  }

  downloadAsCsv() {
    return this.csvComponentService.getCsvOptions(this.state.addressVerifications[0], 'AddressCheck.csv');
  }

}
