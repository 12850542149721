import { ValueCheckerService } from './../core/services/valuechecker.service';
import { comma, emptyString } from './../core/constants/constants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { StateCodesReponse, SuburbsResponse } from "../components/suburbstatecode/suburbstatecode.component";
import { StringService } from "../core/formatting/string.service";
import { hotChocolate, stateCodesQuery, suburbQuery } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: "root"
})
export class SuburbStateCodeService {
  private countryCode = "AUS";

  constructor(private apollo: Apollo, private stringService: StringService, private valueCheckerService: ValueCheckerService) {
  }

  getStateCodes() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<StateCodesReponse>({
        query: gql`${this.stringService.format(stateCodesQuery, this.countryCode)}`
      }).valueChanges;
  }

  getSuburbs(suburb: string, stateCode: string) {
    let query = suburbQuery;
    let filters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(suburb)) {
      filters = `${this.stringService.format(`{ suburb: {startsWith: "{0}"} }${comma}`, suburb)}`;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(stateCode)) {
      filters = filters + `${this.stringService.format(`{ stateCode: {eq: "{0}"} }`, stateCode)}`;
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<SuburbsResponse>({
        query: gql `${this.stringService.format(query, filters)}`
      }).valueChanges;
  }
}
