import { MatchConfidence, LookupType } from "../../enums/enums";
import { TagAllocationPattern, TagAllocationPatternMatch } from "../../components/tagallocationpatterns/tagallocationpatterns.models";

export let tagAllocationPatternsResponse: TagAllocationPattern[] = [
  {
    TagAllocationPatternID: 30253081,
    ExistingCategoryID: 2,
    ExistingTagID: 1000603540,
    ExistingCategoryDataSource: "GooglePlaces",
    NewTagID: 1000603540,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.ContainsAll,
    MatchConfidence: MatchConfidence.High,
    Priority: 1,
    MinAmountInclusive: 13855945,
    MaxAmountInclusive: 456809190,
  },
  {
    TagAllocationPatternID: 1,
    ExistingCategoryID: 1,
    ExistingTagID: 1001102450,
    ExistingCategoryDataSource: "FacebookPlace",
    NewTagID: 1001102450,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.Custom,
    MatchConfidence: MatchConfidence.High,
    Priority: 2,
  },
  {
    TagAllocationPatternID: 11921270,
    ExistingCategoryID: 3,
    ExistingTagID: 1000603090,
    ExistingCategoryDataSource: "Clearbit",
    NewTagID: 1000603090,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.WholeText,
    MatchConfidence: MatchConfidence.Medium,
    Priority: 3,
    MinAmountInclusive: 1962520000,
    MaxAmountInclusive: 500,
  },
  {
    TagAllocationPatternID: 37655830,
    ExistingCategoryID: 4,
    ExistingTagID: 1001105450,
    ExistingCategoryDataSource: "GooglePlaces",
    NewTagID: 1000603540,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.MustInclude,
    MatchConfidence: MatchConfidence.Low,
    Priority: 4,
    MinAmountInclusive: 150000,
  },
  {
    TagAllocationPatternID: 210828434,
    ExistingCategoryID: 1007,
    ExistingTagID: 1000605190,
    ExistingCategoryDataSource: "MerchantWebsite",
    NewTagID: 1000605190,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.StartsWithEndsWith,
    MatchConfidence: MatchConfidence.Medium,
    Priority: 1,
    MaxAmountInclusive: 46500,
  },
  {
    TagAllocationPatternID: 6312828434,
    ExistingCategoryID: 1007,
    ExistingTagID: 1000605190,
    ExistingCategoryDataSource: "MerchantWebsite",
    NewTagID: 1000005000,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.StartsWithEndsWith,
    MatchConfidence: MatchConfidence.Medium,
    Priority: 2,
    MaxAmountInclusive: 46500,
  },
  {
    TagAllocationPatternID: 2108777,
    ExistingCategoryID: 23871130,
    ExistingTagID: 1000005000,
    ExistingCategoryDataSource: "MerchantWebsite",
    NewTagID: 1242,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.StartsWithEndsWith,
    MatchConfidence: MatchConfidence.Medium,
    Priority: 7,
    MaxAmountInclusive: 500,
  },
  {
    TagAllocationPatternID: 2108284410,
    ExistingCategoryID: 2371130,
    ExistingTagID: 1026595100,
    ExistingCategoryDataSource: "Amazon",
    NewTagID: 1000003230,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.StartsWithEndsWith,
    MatchConfidence: MatchConfidence.Medium,
    Priority: 8,
    MaxAmountInclusive: 100,
  },
  {
    TagAllocationPatternID: 21082844,
    ExistingCategoryID: 23971130,
    ExistingTagID: 102655100,
    ExistingCategoryDataSource: "Amazon",
    NewTagID: 7000,
    SearchString: 'REGIONALEXP',
    LookupType: LookupType.StartsWithEndsWith,
    MatchConfidence: MatchConfidence.Medium,
    Priority: 9,
    MaxAmountInclusive: 40,
  },
]

export let tagAllocationPatternMatchResponse: TagAllocationPatternMatch = {
  ExistingCategoryId: 1007,
  ExistingTagId: 1000005000,
  ExistingCategoryDataSource: "Amazon",
  NewTagId: 1003855000,
  Amount: 13855945,
  SearchString: "REGIONAL EXPRE ZL",
  LookupType: LookupType.StartsWithEndsWithAllowNoSpaces,
};

export let tagAllocationPatternResponse: TagAllocationPattern = {
  TagAllocationPatternID: 108284410,
  ExistingCategoryID: 105948400,
  ExistingTagID: 9755000,
  ExistingCategoryDataSource: "Zomato",
  NewTagID: 249488884,
  SearchString: 'REGIONALEXP',
  LookupType: LookupType.MustInclude,
  MatchConfidence: MatchConfidence.Low,
  Priority: 100,
};
