import { DialogComponent } from '../../shared/dialog/dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirmdialog/confirmdialog.component';
import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { EditDescriptionDialogComponent } from '../../components/categoryallocationpatterns/edit-description-dialog/edit-description-dialog.component';

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog) { }

  openDialog(message: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '280px',
      data: {
        message: message
      }
    });

    return dialogRef;
  }

  openConfirmDialog(message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '280px',
      data: {
        message: message
      }
    });

    return dialogRef;
  }

  openDeleteConfirmDialog() {
    return this.openConfirmDialog('Are you sure you want to delete this?');
  }

  openCustomDialog<T, D>(dialog: ComponentType<T>, config: MatDialogConfig<D>) {
    const dialogRef = this.dialog.open(dialog, config);

    return dialogRef;
  }

  createMultilineTextInput(message: string, desc: string) {
    const dialogRef = this.dialog.open(EditDescriptionDialogComponent, {
      width: '520px',
      data: {
        message: message,
        description: desc
      }
    });
    return dialogRef;
  }

  openEditDialog(desc: string) {
    return this.createMultilineTextInput('Add or Update Description', desc);
  }
}
