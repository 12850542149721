import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, map, Observable, startWith } from 'rxjs';
import { LoadingService } from '../../../core/uiservices/loading.service';
import { NotificationService } from '../../../core/uiservices/notification.service';
import { AuthService } from '../../../services/auth.service';
import { ManageclientService } from '../../../services/manageclient.service';
import { ApplicationUser } from '../../../users/applicationuser';
import { UserService } from '../../../users/applicationuser.service';
import { ExternalSource, ManageClient, ManageClientTag, tag } from '../../../models/manageclient.models';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-manageclient-dialog',
  templateUrl: './manageclient-dialog.component.html',
  styleUrls: ['./manageclient-dialog.component.scss']
})
export class ManageclientDialogComponent implements OnInit {

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: ManageClientTag[];
  externalSources: ExternalSource[];
  selectedTag: string[];
  selectedCategory: string[];
  applicationUsers: ApplicationUser[];
  tagid: any;
  id: number;
  createdBy: number;
  addClientForm!: FormGroup;
  selectedCountryCode: any;

  submitted = false;
  update: boolean;

  clientCode: Number;
  formData: ManageClient[];

  tagObject: tag[] = [];

  dialogConfig = new MatDialogConfig();
  uniqueTag: tag[];
  uniqueCategory: number[];
  filteredTags: Observable<ManageClientTag[]>;
  filteredCategory: Observable<ExternalSource[]>;
  defaultCountry: string;
  countryList: string[];
  allAvailableCountryList: string[];
  selectedAvailableCountryCodes: string[];

  constructor(private loadingService: LoadingService, private manageClientService: ManageclientService,
    private notificationService: NotificationService, public dialogRef: MatDialogRef<ManageclientDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ManageClient,
    private authService: AuthService, private userService: UserService
  ) {
    this.tags = [];
    this.externalSources = [];
    this.selectedTag = [];
    this.selectedCategory = [];
    this.formData = [];
    this.update = false;
    this.defaultCountry = ''
    this.countryList = [];
    this.allAvailableCountryList = ["AUS", "NZL"];
    this.selectedAvailableCountryCodes = [];
  }

  ngOnInit(): void {
    this.getApplicationUsers();
    this.createAddClientForm();
    this.getTags();
    this.getExternalSources();

    if (this.data) {
      if (this.data.id) {
        this.id = this.data.id;
      }
      if (this.data.createdBy >= 0) {
        this.createdBy = this.data.createdBy;
      }
      if (this.data.defaultCountry != '') {
        this.defaultCountry = this.data.defaultCountry
      } else {
        this.defaultCountry = ''
      }

      if (this.data.allAvailableCountries != null) {
        this.selectedAvailableCountryCodes = this.data.allAvailableCountries
        //this.allAvailableCountryList = this.data.allAvailableCountries
        //let selectedCountryIndex = this.data.allAvailableCountries.findIndex(item => item == this.data.defaultCountry)
       // this.countryList = [this.data.allAvailableCountries[selectedCountryIndex]]
        this.countryList = this.data.allAvailableCountries
      } else {
        this.selectedAvailableCountryCodes = []
        //this.allAvailableCountryList = []
      }
      this.updateForm();
    }
  }

  createAddClientForm() {
    this.addClientForm = new FormGroup({
      clientName: new FormControl('', [Validators.required,
      Validators.minLength(1),
      Validators.maxLength(200)]),
      description: new FormControl('', [Validators.maxLength(200)]),
      clientCode: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      fourCharCode: new FormControl('', [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(4)]),
      isActive: new FormControl(true),
      maxTransactionPerSearch: new FormControl(null, [Validators.maxLength(8)]),
      maxLimitPerPageForMerchantLookup: new FormControl(null, [Validators.maxLength(8)]),
      maxRecordsPagedForMerchantLookup: new FormControl(null, [Validators.maxLength(8)]),
      maxBpayLookupTransactionsPerRequest: new FormControl(null, [Validators.maxLength(8)]),
      maxMoneyTrackerTransactionsLimit: new FormControl(null, [Validators.maxLength(8)]),
      defaultCountry: new FormControl(null, [Validators.required]),
      allAvailableCountries: new FormControl(''),
      tags: new FormControl(''),
      categories: new FormControl(''),
      id: new FormControl(0),
      createdBy: new FormControl(0)
    })
  }

  updateForm() {
    this.update = true;
    this.addClientForm.controls['clientName'].setValue(this.data.clientName);
    this.addClientForm.controls['description'].setValue(this.data.description);
    this.addClientForm.controls['clientCode'].setValue(this.data.clientCode);
    this.addClientForm.controls['fourCharCode'].setValue(this.data.fourCharCode);
    this.addClientForm.controls['isActive'].setValue(this.data.isActive);
    this.addClientForm.controls['maxTransactionPerSearch'].setValue(this.data.maxTransactionPerSearch);
    this.addClientForm.controls['maxLimitPerPageForMerchantLookup'].setValue(this.data.maxLimitPerPageForMerchantLookup);
    this.addClientForm.controls['maxRecordsPagedForMerchantLookup'].setValue(this.data.maxRecordsPagedForMerchantLookup);
    this.addClientForm.controls['maxBpayLookupTransactionsPerRequest'].setValue(this.data.maxBpayLookupTransactionsPerRequest);
    this.addClientForm.controls['maxMoneyTrackerTransactionsLimit'].setValue(this.data.maxMoneyTrackerTransactionsLimit);
    this.addClientForm.controls['defaultCountry'].setValue(this.data.defaultCountry);
    this.addClientForm.controls['allAvailableCountries'].setValue(this.data.allAvailableCountries);
    this.addClientForm.controls['id'].setValue(this.id);
    this.addClientForm.controls['createdBy'].setValue(this.createdBy);
    this.splitTagsandCategories();
  }

  splitTagsandCategories() {
    let tagsValues = this.data.apiClientTags;
    let tagSplit = tagsValues.split(',');
    if (tagSplit != '') {
      this.selectedTag = tagSplit;
    } else {
      this.selectedTag = [];
    }

    let categoryValues = this.data.apiClientCategory;
    let categorySplit = categoryValues.split(',');

    if (categorySplit != '') {
      this.selectedCategory = categorySplit;
    } else {
      this.selectedCategory = [];
    }
  }

  get f() {
    return this.addClientForm.controls!;
  }

  getApplicationUsers() {
    this.userService.getApplicationUsers().subscribe(response => {
      this.applicationUsers = response;
      let user = this.authService.getCurrentUser();
      let u = this.applicationUsers.find(u => u.EmailId.toLowerCase() == user.userId);
      this.id = u.Id;
    });
  }

  getTags() {
    this.loadingService.setLoading();
    this.manageClientService.getTags().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.tags = response;
        this.filteredTags = this.addClientForm.controls['tags'].valueChanges.pipe(
          startWith(''),
          map(value => this.tagFilter(value || '')),
        );
      }
    });
  }

  getExternalSources() {
    this.loadingService.setLoading();
    this.manageClientService.getExternalCategories().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.externalSources = response;
        this.filteredCategory = this.addClientForm.controls['categories'].valueChanges.pipe(
          startWith(''),
          map(value => this.categoryFilter(value || '')),
        );
      }
    });
  }

  private tagFilter(value: string): ManageClientTag[] {
    const filterValue = value;
    return this.tags.filter(option => option.tagLabel.toLowerCase().includes(filterValue));
  }

  removeTag(tag: string): void {
    const index = this.selectedTag.indexOf(tag);
    if (index >= 0) {
      this.selectedTag.splice(index, 1);
    }
  }

  selectedTags(event: MatAutocompleteSelectedEvent): void {
    const value = (event.option.viewValue || '').trim();
    if (!this.selectedTag.includes(value)) {
      this.selectedTag.push(value);
    }
    this.tagInput.nativeElement.value = '';
    this.addClientForm.controls['tags'].setValue(null);
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (!this.selectedTag.includes(value)) {
      this.selectedTag.push(value);
    }
    event.chipInput!.clear();

    this.addClientForm.controls['tags'].setValue(null);
  }

  private categoryFilter(value: string): ExternalSource[] {
    const filterValue = value;
    return this.externalSources.filter(option => option.CategoryDataSource.toLowerCase().includes(filterValue));
  }

  removeCategory(category: string): void {
    const index = this.selectedCategory.indexOf(category);
    if (index >= 0) {
      this.selectedCategory.splice(index, 1);
    }
  }
  selectedCategories(event: MatAutocompleteSelectedEvent): void {
    const value = (event.option.viewValue || '').trim();
    if (!this.selectedCategory.includes(value)) {
      this.selectedCategory.push(value);
    }
    this.categoryInput.nativeElement.value = '';
    this.addClientForm.controls['categories'].setValue(null);
  }

  addCategory(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (!this.selectedCategory.includes(value)) {
      this.selectedCategory.push(value);
    }
    event.chipInput!.clear();

    this.addClientForm.controls['categories'].setValue(null);
  }

  getTagsandCategoriesById() {
    let tags = new Set();

    const tagValue = this.tags.filter(x => {
      for (let i of this.selectedTag) {
        if (x.tagLabel == i) {
          return x
        }
      }
    }).map(x => x.id)

    for (let i of tagValue) {
      this.tagObject.push({ id: i })
    }

    this.uniqueTag = this.tagObject.filter(ele => {
      let i = tags.has(ele.id);
      tags.add(ele.id);
      if (!i) {
        return true;
      } else {
        return false;
      }
    })

    let categories = new Set();
    const categoryValue = this.externalSources.filter(x => {
      for (let i of this.selectedCategory) {
        if (x.CategoryDataSource == i) {
          return x
        }
      }
    }).map(x => x.Id)

    this.uniqueCategory = categoryValue.filter(ele => {
      let i = categories.has(ele);
      categories.add(ele);
      if (!i) {
        return true;
      } else {
        return false;
      }
    })
  }

  getTagsandCategoryByName() {
    let tags = this.addClientForm.get('tags').value;
    let categories = this.addClientForm.get('categories').value;

    this.selectedTag = this.tags.filter(x => {
      for (let i of tags) {
        if (x.id == i.id) {
          return x
        }
      }
    }).map(x => x.tagLabel)

    this.selectedCategory = this.externalSources.filter(x => {
      for (let i of categories) {
        if (x.Id == i) {
          return x
        }
      }
    }).map(x => x.CategoryDataSource)
  }

  onSubmit() {
    this.getTagsandCategoriesById();

    this.addClientForm.controls['tags'].setValue(this.uniqueTag);
    this.addClientForm.controls['categories'].setValue(this.uniqueCategory);
    if (!this.data.id) {
      this.addClientForm.controls['createdBy'].setValue(this.id);
    } else {
      this.addClientForm.controls['createdBy'].setValue(this.id);
    }
    this.addClientForm.controls['allAvailableCountries'].setValue(this.selectedAvailableCountryCodes);
    this.formData = this.addClientForm.value;
    this.getTagsandCategoryByName();
    this.loadingService.setLoading();
    this.manageClientService.addClients(this.formData).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.notificationService.notifySuccess();
        this.dialogRef.close();
      }
    }, (error) => {
      let result = this.getErrorMessage(error.networkError.error);
      this.bindErrorMessage(result);
      this.selectedTag;
      this.selectedCategory;
      this.notificationService.notifyError(result);
    });
  }

  getErrorMessage(error: any = null) {
    let errorMessage = error.error;
    let result = errorMessage.split(',')[1].trim();
    return result
  }

  bindErrorMessage(error: any = null) {
    if (error.includes('ClientName')) {
      this.addClientForm.controls['clientName'].setErrors(error);
    }
    if (error.includes('ClientCode')) {
      this.addClientForm.controls['clientCode'].setErrors(error);
    }
    if (error.includes('FourCharCode') || error.includes('length')) {
      this.addClientForm.controls['fourCharCode'].setErrors(error);
    }
  }

  changeCountry(value: string) {
    if (value) {
      this.selectedCountryCode = value;
    }
  }

  selectedCountries(country: MatSelectChange) {
    if (country.value.length > 0) {
      this.selectedAvailableCountryCodes = country.value
      this.countryList = country.value
      this.addClientForm.controls['allAvailableCountries'].setValue(this.countryList);
      for (let i = 0; i < country.value.length; i++) {
        if (country.value[i].includes(this.defaultCountry)) {
          this.addClientForm.controls['defaultCountry'].setValue(this.defaultCountry);
        }
        else {
          this.addClientForm.controls['defaultCountry'].setValue('');
        }
      }
    } else {
      this.selectedAvailableCountryCodes = []
      this.countryList = []
      this.defaultCountry = null
      this.addClientForm.controls['defaultCountry'].setValue(this.defaultCountry);
    }
  }
  selectedDefaultCountry(country: MatSelectChange) {
    if (country.value != '') {
      this.defaultCountry = country.value
      this.addClientForm.controls['defaultCountry'].setValue(country.value);
      this.selectedAvailableCountryCodes = this.addClientForm.controls['allAvailableCountries'].value
    } else {
      this.defaultCountry = null;
      this.selectedAvailableCountryCodes = this.addClientForm.controls['allAvailableCountries'].value
      this.addClientForm.controls['defaultCountry'].setValue(this.defaultCountry);
    }
  }


}


