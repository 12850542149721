import { ExecutionResult } from 'graphql';
import { ManageApiUsagesResponse, ApiUsagesStatsForUsagePlanIdResponse } from '../../components/manageapiusage/manageapiusage.component';

export let manageApiUsagesResponse: ExecutionResult<ManageApiUsagesResponse> = {
  data: {
    items: [
      {
        apiClientUsageId: "1009403540",
        criticalLevel: "HIGH",
        apiKeyName: "false api key",
        description: "Outdoor sports store",
        keyId: "118232202",
        planId: "196441556",
        planName: "5d5a3010-7a44-4baf-9622",
        planDescription: "As a local family owned & operated supermarket we pride ourselves on supplying local food that tastes great.",
        quotaPeriod: "Daily",
        quotaLimit: 1000,
        throttleBurstLimit: "982",
        throttleRateLimit: "150",
        usedQuota: 69,
        remainingQuota: 600,
        calculateUsedQuotaPercentange: 90,
        isActive: true,
        createDate: "2012-03-26T18:09:14.667Z",
        modifiedDate: "2015-08-26T22:29:14.667Z"
      },
      {
        apiClientUsageId: "1001365450",
        criticalLevel: "HIGH",
        apiKeyName: "lwc-test-01",
        description: "Randwick Public School provides a broad high quality education for a diverse...",
        keyId: "18214950",
        planId: "15221980",
        planName: "bdf9f3b8-90df-4b3e-b253",
        planDescription: "We work with producers who support local farmers to bring you products you can trust.",
        quotaPeriod: "Monthly",
        quotaLimit: 1500848,
        throttleBurstLimit: "89",
        throttleRateLimit: "1000",
        usedQuota: 14301,
        remainingQuota: 1669285 ,
        calculateUsedQuotaPercentange: 50,
        isActive: false,
        createDate: "2018-01-15T02:29:12.723Z",
        modifiedDate: "2020-02-26T06:29:14.723Z"
      },
      {
        apiClientUsageId: "1009403540",
        criticalLevel: "MID",
        apiKeyName: "api keys13",
        description: "VFS Global is a commercial entity working in p...",
        keyId: "157195821",
        planId: "13718144",
        planName: "db4ac693-bad3-4753-9800",
        planDescription: "We work with producers who support local farmers to bring you products you can trust.",
        quotaPeriod: "Weekly",
        quotaLimit: 8944878,
        throttleBurstLimit: "250",
        throttleRateLimit: "450",
        usedQuota: 8802222,
        remainingQuota: 2008949494,
        calculateUsedQuotaPercentange: 18,
        isActive: false,
        createDate: "2008-05-06T02:12:14.77Z",
        modifiedDate: "2010-11-26T02:12:14.77Z"
      },
      {
        apiClientUsageId: "1009403540",
        criticalLevel: "LOWEST",
        apiKeyName: "api keys15",
        description: "VFS Global is a commercial entity working in p...",
        keyId: "107195821",
        planId: "10018140",
        planName: "ft9ac693-bad3-4753-9800",
        planDescription: "We work with producers who support local farmers to bring you products you can trust.",
        quotaPeriod: "Weekly",
        quotaLimit: 456,
        throttleBurstLimit: "189",
        throttleRateLimit: "342",
        usedQuota: 100,
        remainingQuota: 850,
        calculateUsedQuotaPercentange: 150,
        isActive: false,
        createDate: "2001-09-06T02:12:14.77Z",
        modifiedDate: "2004-10-26T21:12:14.77Z"
      }
    ],
    totalCount: 500
  }
};

export let apiUsagesStatsForUsagePlanIdResponse: ExecutionResult<ApiUsagesStatsForUsagePlanIdResponse> = {
  data: {
    items: [
      {
        date: "2021-09-05T05:03:00.220Z",
        remainingQuota: 86,
        usedQuota: 14125
      },
      {
        date: "2011-09-06T05:03:00.220Z",
        remainingQuota: 61,
        usedQuota: 25448
      },
      {
        date: "2019-09-07T05:03:00.220Z",
        remainingQuota: 49,
        usedQuota: 12598
      },
      {
        date: "2021-08-05T05:03:00.220Z",
        remainingQuota: 86,
        usedQuota: 1458
      },
      {
        date: "2011-07-06T05:03:00.220Z",
        remainingQuota: 61,
        usedQuota: 25
      },
      {
        date: "2018-05-07T05:03:00.220Z",
        remainingQuota: 49,
        usedQuota: 1244
      },
      {
        date: "2016-04-05T01:03:00.220Z",
        remainingQuota: 86,
        usedQuota: 14974
      },
      {
        date: "2016-03-06T02:03:00.220Z",
        remainingQuota: 61,
        usedQuota: 25125
      },
      {
        date: "2015-09-07T03:03:00.220Z",
        remainingQuota: 49,
        usedQuota: 12515
      }
    ]
  }
};
