export enum ChainType {
  Unknown = 0,
  HeadOffice = 1,
  Branch = 2,
  Franchise = 3,
  Both = 4,
  StandardResponse = 5,
  NotAChain = 6,
  MerchantPattern = 7,
  ATMPattern = 8,
  Product = 9,
  BankMessagePattern = 10,
  BankBranch = 11,
  Salary = 12,
  InvalidCalPattern = 13
}
