import { Component, OnDestroy, Injector } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(injector: Injector) { }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
