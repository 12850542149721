import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Inject, Injector } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NotificationService } from '../../core/uiservices/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCropperState } from './image-cropper.state';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent extends BaseStateComponent<ImageCropperState> implements OnInit {

  constructor(injector: Injector, private notificationService: NotificationService, public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      croppedImage: "",
      showCropper: false
    };
  }

  setCroppedImage(event: ImageCroppedEvent) {
    this.state.croppedImage = event.base64;
  }

  imageForCropLoaded() {
    this.state.showCropper = true;
  }

  loadImageForCropFailed() {
    this.notificationService.notifySuccess('Load image for crop failed');
  }

  uploadCroppedImage() {
    this.dialogRef.close(this.state.croppedImage);
  }

  cancel() {
    this.dialogRef.close('');
  }
}
