import gql from "graphql-tag";

export let hotChocolate = 'hotchocolate';
export let eventing = 'eventing';

export let editLocationUpsertQuery = gql`mutation ($locatedIn: LocatedInInput!) {
  upsertLocatedIn(locatedInDto: $locatedIn) {
    locatedIn_ID
    formatted_phone_number
    openingTimes
    isStadium
    isHospital
    isUni
    isShoppingCentre
    isAirport
    isTrainStation
    centreURL
    locatedIn_ID
    formattedAddressString
    streetViewAvailable
    geoCodeGranularity
    addressLine2
    locatedIn_Name
  }
}
`;

export let editLocationDeleteQuery = gql`mutation ($locatedInId: Int!) {
  deleteLocatedIn(locatedInID: $locatedInId)
}
`;

export let manageLocationsQuery = gql`{
  data: locatedIn {
    locatedIn_ID
    locatedIn_Name
    addressLine1
    addressLine2
    addressSuburb
    addressStateCd
    addressPostcode
    addressCountryCd
    latitude
    longitude
    geoCodeGranularity
    mapable
    streetViewAvailable
    formattedAddressString
    latLonPrecision
    centreURL
    isTrainStation
    isAirport
    isHospital
    isUni
    isShoppingCentre
    isStadium
    googlePlaceID
    openingTimes
    formatted_phone_number
  }
}
`;

export let unmatchedMerchantQuery = gql
  `query ($chainCode: String!) {
  unmatchedMerchants(chainCode: $chainCode) {
    lwcId
    status
    closedConfidenceLevel
    message
  }
}
`;

export let unverifiedMerchantQuery = gql
  `{
  unverifiedMerchants {
    lwcId
    status
    closedConfidenceLevel
    message
  }
}
`;

export let urlVerificationQuery = gql`
  {
    urlVerification
      {
        lwcId,
        retryAttempts,
        comments,
        verifiedDate,
        url
      }
  }`;

export let addressVerificationQuery = gql`
  {
    addressVerification
      {
        lwcId,
        retryAttempts,
        comments,
        verifiedDate
      }
  }`;

export let unmatchedMerchantsQuery = gql`query ($chainCode: String!) {
  unmatchedMerchants(chainCode: $chainCode) {
    scrapeCode
    lwcId
    status
    closedConfidenceLevel
    message
  }
}
`;

export function chainAuditQuery(chainId: number) {
  return gql`{
    data:chainAudit(chainId:${chainId}) {
      chainName
      operationType
      operationTrigger
      createDateTime
      nameOfIndexer
      patternsAudit {
        patternType
        searchString
        lookupType
      }
  }
}`
}

export function merchantAuditQuery(lwcId: number) {
  return gql` {
    data:merchantAudit(lwcId: ${lwcId}) {
    lwc_ID
    indexingType
    indexingTrigger
    createDateTime
    nameOfIndexer
    indexingResult
    indexingResultComment
    indexingScore
    indexingTimeMS
    }
  }
  `;
}

export let apiClientsQuery = gql`{
  data: apiClients {
    apikey
    apikeyName
    clientId
    clientName
    createDate
    createdBy
    isActive
    isSubscriber
    isReliableKey
    modifiedBy
    modifiedDate
    clientCode
    defaultSearchEngine
    useTheseEngines
    maxTransactionPerSearch
    apiClientGroup {
      clientName
    }
    threeCharCode
    apiClientPolicies {
      policyId
      policy
    }
  }
}
`;

export let apiClientsOldQuery = gql`{
  data: apiClientsOld {
    apikey
    apikeyName
    clientId
    clientName
    createDate
    createdBy
    isActive
    isSubscriber
    isReliableKey
    modifiedBy
    modifiedDate
    clientCode
    defaultSearchEngine
    useTheseEngines
    maxTransactionPerSearch
    aPIClientGroup {
      clientName
    }
    threeCharCode
    apiClientPolicies {
      policyId
      policy
    }
  }
}
`;

export function searchPersonNameQuery(searchString: string) {
  return gql`{
  data: searchPersonName(searchString: "${searchString}") {
    name
    nameType
  }
}
`
};

export let addPersonNameQuery = gql`mutation ($person: PersonNameInput!) {
  addPersonName(personNameInput: $person) {
    name
    nameType
  }
}
`;

export let deletePersonNameQuery = gql`mutation ($person: PersonNameInput!) {
  deletePersonName(personNameInput: $person)
}
`;


export let hmacKeysQuery = gql`
  query {
    data:hmackeys {
      apiKey
      appId
    }
  }`;


export function merchantSubscriptionLookupQuery(lwcGuid: number, clientIds: number[]) {
  return gql`{
  merchantsubscriptionlookup(
    lwcGuid: ${lwcGuid}
    clientIds: [${clientIds}]
  ) {
    cal
    lWC_Guid
  }
}
`
};

export let getEnumValuesQuery =
  `{data:__type(name:"{0}") {
      enumValues {
        name
      }
    }
}`;

export let getMissingMerchantsQuery =
  `{
  data:missingMerchants
  (
        where: {
            and: [
            {0}
            { createdDate: {lte:"{1}"}},
            { createdDate: {gte: "{2}" }}
            ]
      },
      order: {
        storeName : ASC
      },
      skip: 0,
      take: 50000
  )
  {items
    {
      merchantFeedbackID
      apiKeyName
      number
      sellerName
      aBN
      aCN
      address
      suburb
      state
      postcode
      storeName
      storeJoinDate
      industry
      storeAddress
      storeSuburb
      storeState
      storePostcode
      bTLT
      onlineInStore
      merchantLookupRequestGuid
      batchGuid
      comment
      createdDate
      modifiedDate
      modifiedBy
      status
      lWC_Guid
      lWC_ID
      isDeleted
    },
    totalCount
  }
}`;

export let getUpdateMissingMerchantQuery = gql`
mutation($missingMerchantFeedbackId: Int!, $comment: String, $lwcId: Int, $modifiedBy: String, $status: RecordStatus) {
    updateMissingMerchant(merchantFeedbackID:$missingMerchantFeedbackId ,comment : $comment, lwcId:$lwcId, modifiedBy: $modifiedBy, status: $status) {
        merchantFeedbackID
        comment
        modifiedBy
        lWC_ID
        status
        apiKeyName
        number
        sellerName
        aBN
        aCN
        address
        suburb
        state
        postcode
        storeName
        storeJoinDate
        industry
        storeAddress
        storeSuburb
        storeState
        storePostcode
        bTLT
        onlineInStore
        merchantLookupRequestGuid
        batchGuid
        createdDate
        modifiedDate
        lWC_Guid
        isDeleted
    }
}`;
export let numberOfSearchesQuery = `
{
 data:cals
  (where: { currentStatusID: {eq: {0}} },
    order: {numberOfSearches: DESC}, skip: 0, take: 1)
    { items {
        numberOfSearches
      }
    }
}`;

export let filteredCalsQuery = `
  {data:cals
    (where: { and: [ {0} {1} {2}]},
      order: {numberOfSearches: DESC, createDateTime: DESC},
      skip:{3},
      take:{4}
    )
    {
      items {
        iD_no
        transactionDescription
        currentStatusID
        numberOfSearches
        cALType
        iD_Indexer
        chain
        link
        lWC_ID
        createDateTime
        currentStatusDateTime
        indexerName
      },
      totalCount
    }
  }`;


export let allocateCalsToIndexerQuery = `
mutation{ allocateCalsToIndexer (indexId: {0},calIds: [{1}]) {
      iD_no
      transactionDescription
      currentStatusID
      numberOfSearches
      cALType
      iD_Indexer
      chain
      link
      lWC_ID
      createDateTime
      currentStatusDateTime
      indexerName
      }
  }`;


export let groupOfSimilarCalsQuery = `
{data: groupCals(transactionDescription: "{0}", skip:{1}, take: 50) {
    items {
          transactionDescription
          chain
          group
          count
          },
          totalCount
     }
}`;

export let searchFeedbacksQuery = `
{data: searchFeedbacks( where:
  { and:[
    {0}
    { feedbackType:{ eq: "{1}"}}]},
      order: {createDate:DESC},
      skip: {2},
      take: {3})
      {
        items {
          cal,
          searchFeedbackId,
          isIndexed,
          createDate,
          userComment,
          email,
          mlConfidence,
          mlUseful,
          clientName,
          searchCount,
          calFeedbackCount,
          searchFeedbackHumanClassification,
          lwcComment,
          feedbackType
        },
        totalCount
      }
    }
`;

export let updateSearchFeedbackQuery = `
mutation{ updateSearchFeedback (ids:[ {0} ], {1} lwcComment: "{2}",  humanClassification: "{3}")
    {   cal
        searchFeedbackId
        isIndexed
        createDate
        userComment
        email
        mlConfidence
        mlUseful
        clientName
        searchCount
        calFeedbackCount
        searchFeedbackHumanClassification
        lwcComment
    }
}`;

export let retrieveExperianAddressQuery =
  `{data:retrieveExperianAddress(address: "{0}") }`;

export let upsertAPIClientQuery = gql`
mutation($aPIClientDto: aPIClientInput!)
  { upsertAPIClient(aPIClientDto: $aPIClientDto)
    {
      apikey
    }
  }`;

export let refreshAPIKeysQuery = `mutation {refreshAPIKeys}`;

export let findPersonNamesQuery = ` {
  data:personNames(findInText: "{0}") {
      name
      nameType
    }
}`;

export let bsbsCleansedQuery = `
{  data: bsbCleansed
      (
        where: { and: [ {0} ]},
        order: {
          bsb: ASC
        },
        skip: {1},
        take: {2}
      )
        {
          items
          {
            bsb
            lwc_ID
            merchantName
            nameOfIndexer
            mnemonic
            name
            street
            suburb
            state
            postcode
            paymentsFlags
            mergedBsb
            bsbStatus
            institutionFullName
            updateDate
          },
          totalCount
        }
}`;

export let assignBsbToMerchantQuery = `
mutation
{
  assignBsbToMerchant
  (
    lwcId: {0},
    bsbNumber: "{1}",
    defaultDataSourceName: "{2}",
    indexerId: {3},
    indexerName: "{4}")
  {
    lWC_ID
    bsb
    merchantName
    nameOfIndexer
    result
    {
      outcome
      comments
    }
  }
}`;

export let apiUsagesQuery = `
{
  data:apiUsages
  (
    where: { and: [ {0} ] },
      skip: {1},
      take: {2},
      order: { {3}: {4} }
  )
  {items
    {
      apiClientUsageId
      criticalLevel
      apiKeyName
      description
      keyId
      planId
      planName
      planDescription
      quotaPeriod
      quotaLimit
      throttleBurstLimit
      throttleRateLimit
      usedQuota
      remainingQuota
      calculateUsedQuotaPercentange
      isActive
      createDate
      modifiedDate
    },
    totalCount
  }
}`;


export let clientOnboardingApiClient = `
{
  data:clientOnboardingApiClient
  ( 
    where: { clientName: {contains :"{0}"} },
    skip:{1}
    take: {2},
    order: {
      {3}: {4}
    }
  )
  {
    items
    {  
      clientName
      description
      clientCode
      fourCharCode
      isActive
      id
      createdBy
      apiClientTags,
      apiClientCategory
      createdDate
      modifiedBy
      modifiedDate
      maxTransactionPerSearch
      maxLimitPerPageForMerchantLookup
      maxRecordsPagedForMerchantLookup
      maxBpayLookupTransactionsPerRequest
      maxMoneyTrackerTransactionsLimit
      defaultCountry
      allAvailableCountries
    },
    totalCount
  }
}
`;

export let clientOnboarding = `
mutation ($apiclient: ClientOnboardingAPIClientInput)
{  upsertClientOnboardingApiClient(apiClientV2Dto: $apiclient)
  {   
    clientName 
    description
    clientCode
    fourCharCode
    isActive
    id
    createdBy
    categories
    tags{id}
    defaultCountry
    allAvailableCountries
  }
}
`;

export let getClientNames = `
{
  data:clientOnboardingApiClient
  (
    skip: {0},
    take: {1},
  )
    {
      items
      { 
      clientName
      clientCode
      fourCharCode
      id
      defaultCountry
      allAvailableCountries
      },      
      totalCount
    }
  }
   `;


export let GetApiClientWithApiKey = `
{
  data:clientOnboardingApiClient
  (
    eagerLoading:true,
    isAdmin : {0},
    where: { and: [ {1} ] },
    skip: {2},
    take: {3},
    order: {
      {4}: {5}
    },
    showOnlyActive :{6},
    apiKeyName:"{7}"
  )
    {
      items
      { 
      clientName
      description
      clientCode
      fourCharCode
      isActive
      id
      createdBy
      apiClientTags,
      apiClientCategory
      createdDate,
      modifiedDate,      
      clientAPIKeys
      {
        apikeyName
        usagePlanEnv
        apikey
        expiryDate
        isActive
        id
        fourCharCode
        createdBy
        apiPolicy,
        isReliableKey,
        isRotating,
        createdDate,
        modifiedDate
        isSubscription
        isApiGatewayClient
        note
        usagePlanId
        description
        modifiedBy
        isDeleted
        maxTransactionPerSearch
        maxLimitPerPageForMerchantLookup
        maxRecordsPagedForMerchantLookup
        maxBpayLookupTransactionsPerRequest
        maxMoneyTrackerTransactionsLimit
        defaultCountry
        allAvailableCountries
      }
      },
      totalCount
    }
  }
`;

export let usgaePlan = `
{
            data: usagePlan(
                   skip: 0,
                   take: 5000,  
                   where:{isActive: {eq: true}},
                   order: {
                        createDate: DESC
                   }
             )
             {
                        items
            {
                id,
                name,
                environment
                environmentArr
                apiGatewayHost
                usagePlanKey,
                description,
                quotaPeriod,
                throttleBurstLimit,
                quotaLimit,
                status
            }
             }
                            
}`;

export let clientApiKey = `
mutation ($clientApi: ClientAPIKeyInput!)
{  upsertClientApiKey(clientAPIKeyInput: $clientApi)
  {   id
      apiClientId
      apikey
      isReliableKey
      expiryDate
      isRotating
      isActive
      createdBy
      isSubscription
      policy{id}
      systemMessage
      maxTransactionPerSearch
      maxLimitPerPageForMerchantLookup
      maxRecordsPagedForMerchantLookup
      maxBpayLookupTransactionsPerRequest
      maxMoneyTrackerTransactionsLimit
      defaultCountry
      allAvailableCountries
  }
}`;

export let syncUsagePlan = `
mutation
{  syncUsagePlan(hint:"{0}")
}
`;

export let syncClientApiKey = `mutation{syncClientApiKey(hint:"{0}")}`;

export let syncAll = `mutation{{0}(hint:"")}`;

export let syncClientApiKeyFromDB = `mutation{syncClientApiKeyFromDB(hint:"{0}")}`;

export let testapikey = `query{  testApiKey(endpointType:"search",clientApiKeyId:{0},env:"{1}") }`;

export let cloneClientApikey = `mutation{  cloneClientApiKey(clientApiKeyId: {0},createdBy: {1}) }`;

export let upcomingExpiry = `
  { 
    data : upcomingExpiryClientApiKeys
    (                        
      where:{daysToKeyExpiry: {lte: {1}},
      and: [ {0} ]
    },
      skip: {2},
      take: {3},
      order: {
        {4}: {5}
      }
    )
      {
        items
        {  
        clientName
        apikeyName
        expiryDate
        isActive
        id
        createdBy
        apiPolicy,
        isReliableKey,
        isRotating,
        createdDate,
        modifiedDate
        daysToKeyExpiry
        },
        totalCount
      }
  }
   `;


export let apiClientAllPoliciesQuery = gql`
{
  data:apiClientAllPoliciesQuery
    {
      policyId
      policy
    }
}`;

export let transactionBankListQuery = gql`
{
  data:transactionBankList
  {
    id
    value
    name
  }
}`;

export let retailerCentersQuery = `
{
  data:retailerCenters
  (
    {0}
     where: { and: [ {1} ] },
      skip:{2},
      take: {3},
      {4}
  )
  {items
    {
      centresTitle
      centresCountry
      centresId
      centresSlug
      centresPhysicalAddress
      centresState
      centresSuburb
      createDate
      dataSource
      closedForNoOfDaysPotentially
      isActive
      locatedIn
      modifiedDate
      noOfTryToMatch
      distance
      latitude
      longitude
      sourceModifiedDate
      status
      modifiedBy
      postCode
      comment
    },
    totalCount
  }
}`;

export let stateCodesQuery = `
{
  data:fetchSharedData( where: { and: [ { countryCode: {eq: "{0}"} } ] },
      skip:0,
      take: 50000
  )
  { items
    {
      stateCode
    },
    totalCount
  }
}`;


export let suburbQuery = `
{
  data:fetchSharedData ( where: { and: [ {0} ] },
      skip:0,
      take: 5000
  )
  { items
    {
      suburb
      stateCode
    },
    totalCount
  }
}`;

export let updateRetailerCenterWithLocationIdQuery = `
mutation {
  data:updateRetailerCenterWithLocationId(centerId: {0}, comment: "{1}", locationId: {2}, modifiedBy: {3}){
      centresId
      comment
      locatedIn
  }
}`;

export let westfieldRetailerQuery = `
{
  data:fetchRetailers
  (
    auditCreateDate: "{0}",
    {1}
     where: { and: [ {2} ] },
      skip:{3},
      take: {4},
      {5}
  )
  {items
    {
      locatedIn
      retailersId
      createDate
      lwcId
      chainId
      storesTitle
      storesShortDescription
      storesPhysicalAddress
      storesShopNumber
      storesLevel
      closedForNoOfDaysPotentially
      sourceModifiedDate
      modifiedBy
      storesTelephoneNumber
      comment
      retailerAudits {
        calculatedNoOfDays
        createDate
        fieldName
        fieldOldValue
        fieldNewValue
      }
    },
    totalCount
  }
}`;

export let fetchRetailersQuery = `
{
  data:fetchRetailers
  (
     auditCreateDate: "{0}",
     where: { and: [ { lwcId: { eq : {1}} } ] },
      skip:0,
      take: 50
  )
  {items
    {
      locatedIn
      retailersId
      createDate
      lwcId
      chainId
      storesTitle
      storesShortDescription
      storesPhysicalAddress
      storesShopNumber
      storesLevel
      closedForNoOfDaysPotentially
      sourceModifiedDate
      modifiedBy
      storesTelephoneNumber
      comment
      retailerAudits {
        calculatedNoOfDays
        createDate
        fieldName
        fieldOldValue
        fieldNewValue
      }
    },
    totalCount
  }
}`;

export let updateRetailerWithLwcIdQuery = `
mutation {
  data:updateRetailerWithLwcId({0}){
    retailersId
    comment
    chainId
    lwcId
  }
}`;

export let merchantShoppingCenteQuery = `
{  data:locatedIn
  {
   locatedIn_ID
   locatedIn_Name
  }
}`;

export let bankAccountTransactionTypesQuery = gql`
{ data:bankAccountTransactionTypes
  {
    name
    value
  }
}`;

export let paymentMethodsQuery = gql`
{ data:paymentMethods
  {
    name
    value
  }
}`;

export let apiUsagesStatsForUsagePlanIdQuery = `
{
  data:apiUsagesStatsForUsagePlanId(usagePlanId: {0}) {
    items {
      date
      remainingQuota
      usedQuota
     }
  }
}`;

export let retailerCentersBasedOnCenterIdQuery = `
{
  data:retailerCenters
  (
     where: { and: [ {0} ] },
      skip:{1},
      take: {2}
  )
  {items
    {
      centresTitle
      centresCountry
      centresId
      centresSlug
      centresPhysicalAddress
      centresState
      centresSuburb
      createDate
      dataSource
      closedForNoOfDaysPotentially
      isActive
      locatedIn
      modifiedDate
      noOfTryToMatch
      distance
      latitude
      longitude
      sourceModifiedDate
      status
      modifiedBy
      postCode
      comment
    },
    totalCount
  }
}`;

export let fetchValidationStatsByValidationQuery = `
{
  data:fetchValidationStatsByValidation
  (
     where: { and: [ {0} ] },
     startDate: "{1}",
     endDate: "{2}",
     skip:{3},
     take: {4},
     order: {
      count: DESC
     }
  )
  {items
    {
      validationId
      validation
      count
    },
    totalCount
  }
}
`;

export let fetchValidationStatsByValidationIdQuery = `
{
  data:fetchValidationStatsDetail
  (
     where: { and: [ { validationId: {eq: {0}} }, { createdDate: {gte: "{1}"} }, { createdDate: {lte: "{2}"} } ] },
      skip: {3},
      take: {4},
      order: { createdDate: DESC }
  )
  {items
    {
      validationId
      validation
      comment
      overriddenComment
      isOverridden
      nameOfIndexer
      createdDate
      updateddDate
      warningError
    },
    totalCount
  }
}
`;

export let fetchValidationStatsByIndexerQuery = `
{
 data:fetchValidationStatsByUser
  (
     where: { and: [ {0} ] },
     startDate: "{1}",
     endDate: "{2}",
     skip: {3},
     take: {4},
     order: { count: DESC }
  )
  {items
    {
      indexerName
      indexerId
      count

    },
    totalCount
  }
}`;

export let fetchAllValidationStatsDetailQuery = `
{
 data:fetchValidationStatsDetail
  (
    where: {
      and: [
        {0}
        { createdDate: { gte: "{1}" } },
        { createdDate: { lte: "{2}" } }
      ]
    },
    skip: {3},
    take: {4},
    order: { createdDate: DESC }
  )
  {items
    {
      validationId
      validation
      comment
      overriddenComment
      isOverridden
      nameOfIndexer
      createdDate
      updateddDate
      warningError
    },
    totalCount
  }
}`;

export let blobCacheSourceTypesQuery = gql`
{
  data:blobCacheSourceTypes
  {
   name
   value
  }
}`;

export let searchBPayInternalQuery = `
query {
  data:searchBPayInternal(searchString:"{0}")
  {
    billerCode,
    billerLongName,
    billerShortName,
    paymentMethodsAccepted,
    lwcId
  }
}
`;

export let searchEnrichBPayQuery = `
query {
  data:searchErichBPay(searchString:"{0}")
  {
    billerCode,
    billerLongName,
    billerShortName,
    paymentMethodsAccepted,
    lwcId
  }
}
`;

export let cacheType = gql`{
  data: cacheTypes {
    name
  }
}`

export let suburbList = `
query {
  data: suburbsLatLonSize(
    # where: {countryCode: {eq: "AUS"}}
    order: {suburb: ASC}
  ){
      id
      suburb
      postCode
      stateCode
  }
}`