import { LoadingService } from '../../core/uiservices/loading.service';
import { LwcMerchantSearchState, MerchantDetailsResponse, MerchantSummaryForCsvProps } from './lwcmerchantsearchstate';
import { Component, OnInit, Output, EventEmitter, Injector, ViewChild, Input } from '@angular/core';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { ControlContainer, FormControl, NgForm, Validators } from '@angular/forms';
import { BaseStateComponent } from '../basestate/basestate.component';
import { finalize } from 'rxjs/operators';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import moment from 'moment';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-lwcmerchantsearch',
  templateUrl: './lwcmerchantsearch.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./lwcmerchantsearch.component.scss']
})
export class LwcmerchantsearchComponent extends BaseStateComponent<LwcMerchantSearchState> implements OnInit {
  @ViewChild('csvExportMerchantSummary') csvExportMerchantSummary: CsvExportComponent;
  @Output() searchComplete: EventEmitter<any> = new EventEmitter();
  @Output() searchError: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Input() isSummary: boolean = false;
  csvData: any[];

  private csvHeaders = {
    LWC_ID: 'LWC_ID',
    ChainName: 'ChainName',
    PhoneNumber: 'PhoneNumber',
    Url: 'Url',
    DataCompletnessScore: 'DataCompletnessScore',
    PrimaryName: 'PrimaryName',
    PrimaryCategory: 'PrimaryCategory',
    PrimaryAddress: 'PrimaryAddress',
    MerchantPresence: 'MerchantPresence',
    MerchantType: 'MerchantType',
    RecordLocked: 'RecordLocked',
    IsQuarantined: 'IsQuarantined',
    CurrentBusinessStatus: 'CurrentBusinessStatus',
    ABN: 'ABN',
    Tags: 'Tags',
    BSBs: 'BSBs'
  };

  private csvOptionHeadersAndKeys = {
    headers: [
      this.csvHeaders.LWC_ID,
      this.csvHeaders.ChainName,
      this.csvHeaders.PhoneNumber,
      this.csvHeaders.Url,
      this.csvHeaders.DataCompletnessScore,
      this.csvHeaders.PrimaryName,
      this.csvHeaders.PrimaryCategory,
      this.csvHeaders.PrimaryAddress,
      this.csvHeaders.MerchantPresence,
      this.csvHeaders.MerchantType,
      this.csvHeaders.RecordLocked,
      this.csvHeaders.IsQuarantined,
      this.csvHeaders.ABN,
      this.csvHeaders.Tags,
      this.csvHeaders.BSBs
    ],
    keys: [
      MerchantSummaryForCsvProps.LWC_ID,
      MerchantSummaryForCsvProps.ChainName,
      MerchantSummaryForCsvProps.PhoneNumber,
      MerchantSummaryForCsvProps.Url,
      MerchantSummaryForCsvProps.DataCompletnessScore,
      MerchantSummaryForCsvProps.PrimaryName,
      MerchantSummaryForCsvProps.PrimaryCategory,
      MerchantSummaryForCsvProps.PrimaryAddress,
      MerchantSummaryForCsvProps.MerchantPresence,
      MerchantSummaryForCsvProps.MerchantType,
      MerchantSummaryForCsvProps.RecordLocked,
      MerchantSummaryForCsvProps.IsQuarantined,
      MerchantSummaryForCsvProps.ABN,
      MerchantSummaryForCsvProps.Tags,
      MerchantSummaryForCsvProps.BSBs,

    ],
  };

  csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.csvOptionHeadersAndKeys };

  searchItems = ['LWC ID', 'LWC Guid', 'ABN', 'Primary Name', 'Website'];
  searchByItem: string;
  checkInput = new FormControl('');

  constructor(private service: MerchantlookupService, private injector: Injector, private loadingService: LoadingService,
    private csvComponentService: CsvComponentService, private dateTimeService: DateTimeService) {
    super(injector);
    this.csvData = [];
    this.searchByItem = 'LWC ID';
  }

  ngOnInit() {
    this.state = {
      cal: '',
      selectedlwcsource: false,
      selectedchain: '',
      chainsuburb: '',
      chainid: null,
      inputType: ''
    };
    this.restoreState();
  }

  trimHttpOnWebsite() {
    let http = "http://";
    let https = "https://";
    let isnum = /^\d+$/.test(this.state.inputType);
    if (this.state.inputType.startsWith(http)) {
      this.state.inputType = this.state.inputType.replace(http, "");
    }
    if (this.state.inputType.startsWith(https)) {
      this.state.inputType = this.state.inputType.replace(https, "");
    }
    if (this.searchByItem === 'LWC ID') {
      if (isnum !== true) {
        this.checkInput.setErrors([Validators.pattern("^[0-9]*$")]);
      }
    }
    if (this.searchByItem === 'ABN') {
      let abn = this.state.inputType.split(' ').join('');
      this.state.inputType = abn;
      if (this.state.inputType.length > 11) {
        this.checkInput.setErrors([Validators.maxLength(11)]);
      } else if (this.state.inputType.length < 11) {
        this.checkInput.setErrors([Validators.minLength(11)]);
      } else if (isnum !== true) {
        this.checkInput.setErrors([Validators.pattern("^[0-9]*$")]);
      }
    }
  }

  disableSearchButton(): boolean {
    return (!this.state.inputType);
  }

  onLwcSearch() {
    if (!this.state.inputType) {
      return;
    }
    else {
      if (this.searchByItem === 'LWC ID') {
        let isnum = /^\d+$/.test(this.state.inputType);
        if (isnum === true) {
          this.searchIndexLwcId();
          return;
        } else {
          this.checkInput.setErrors([Validators.required, Validators.pattern("^[0-9]*$")]);
        }
      }
      if (this.searchByItem === 'LWC Guid') {
        this.checkInput.clearValidators();
        this.checkInput.updateValueAndValidity();
        this.searchDbGuid();
        return;
      }
      if (this.searchByItem === 'ABN') {
        let isnum = /^\d+$/.test(this.state.inputType);
        if (isnum === true) {
          this.searchDbAbn();
          return;
        } else {
          this.checkInput.setErrors([Validators.required, Validators.pattern("^[0-9]*$")]);
        }
      }
      if (this.searchByItem === 'Primary Name') {
        this.checkInput.clearValidators();
        this.checkInput.updateValueAndValidity();
        this.searchByPrimaryName();
        return;
      }
      if (this.searchByItem === 'Website') {
        this.checkInput.clearValidators();
        this.checkInput.updateValueAndValidity()
        this.searchByWebsite();
        return;
      }
    }
  }

  searchIndexLwcId(): void {
    if (this.isSummary === false) {
      this.service.searchIndexByLwcId(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    } else {
      this.service.getCals(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    }
  }

  searchDbAbn(): void {
    if (this.isSummary === false) {
      this.service.searchDatabaseByAbn(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    }
    else {
      this.service.searchMerchantSummaryByAbn(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    }
  }

  searchDbGuid(): void {
    if (this.isSummary === false) {
      this.service.searchDatabaseByGuid(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    } else {
      this.service.searchMerchantSummaryByGuid(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    }
  }

  searchByPrimaryName() {
    if (this.isSummary === false) {
      this.service.searchByPrimaryName(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    } else {
      this.service.searchMerchantSummaryByPrimaryName(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    }
  }

  searchByWebsite() {
    this.trimHttpOnWebsite();
    if (this.isSummary === false) {
      this.service.searchByWebsite(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    } else {
      this.service.searchMerchantSummaryByWebsite(this.state.inputType)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(this.onSearchComplete, this.onSearchError);
    }
  }


  private onSearchComplete = (result: any) => {
    this.searchComplete.emit(result);
  }

  private onSearchError = (error: any) => {
    this.searchError.emit(error);
  }

  downloadCsvFile() {

    if (this.searchByItem === 'LWC ID') {
      let lwcids: any[] = [];
      let data: MerchantDetailsResponse[] = [];
      lwcids.push(this.state.inputType);
      this.loadingService.setLoading();
      this.service.getMerchantDetailsByLwcId(lwcids).pipe(finalize(() => this.loadingService.clearLoading())).subscribe((response) => {
        this.loadingService.clearLoading();
        if (response) {
          data = response;
          for (let i of data) {
            this.csvData.push({
              'LWC_ID': i.LWC_ID,
              'ChainName': i.ChainName,
              'PhoneNumber': i.PhoneNumber,
              'Url': i.URL,
              'DataCompletnessScore': i.DataCompletnessScore,
              'PrimaryName': i.PrimaryName,
              'PrimaryCategory': i.PrimaryCategory,
              'PrimaryAddress': i.PrimaryAddress,
              'MerchantPresence': i.MerchantPresence,
              'MerchantType': i.MerchantType,
              'RecordLocked': i.RecordLocked,
              'IsQuarantined': i.IsQuarantined,
              'ABN': i.ABN,
              'Tags': i.Tags,
              'BSBs': i.BSBs
            })
          }
          let currentDate = this.dateTimeService.formatForODataFilter(moment(Date.now()));
          this.csvExportMerchantSummary.data = this.csvData;
          this.csvExportMerchantSummary.options = this.csvOptions;
          this.csvExportMerchantSummary.filename = `MerchantLookup-${this.state.inputType}-${currentDate}`;
          this.csvExportMerchantSummary.onDownload();
          this.csvData = [];
          data: [];
        }
      })
    }
    if (this.searchByItem === 'LWC Guid') {
      this.loadingService.setLoading();
      this.service.searchMerchantSummaryByGuid(this.state.inputType).pipe(finalize(() => this.loadingService.clearLoading())).subscribe((response) => {
        this.loadingService.clearLoading();
        if (response) {
          this.csvData.push(response);
          let currentDate = this.dateTimeService.formatForODataFilter(moment(Date.now()));
          this.csvExportMerchantSummary.data = this.csvData;
          this.csvExportMerchantSummary.options = this.csvOptions;
          this.csvExportMerchantSummary.filename = `MerchantLookup-${this.state.inputType}-${currentDate}`;
          this.csvExportMerchantSummary.onDownload();
          this.csvData = [];
        }
      })
    }
    if (this.searchByItem === 'ABN') {
      let data: any = [];
      this.loadingService.setLoading();
      this.service.searchMerchantSummaryByAbn(this.searchByItem).pipe(finalize(() => this.loadingService.clearLoading())).subscribe((response) => {
        this.loadingService.clearLoading();
        if (response) {
          data = response;
          for (let i of data) {
            this.csvData.push({
              'LWC_ID': i.LWC_ID,
              'PrimaryName': i.PrimaryName,
              'PhoneNumber': i.PhoneNumber,
              'PrimaryAddress': i.PrimaryAddress,
              'PrimaryCategory': i.PrimaryCategory,
              'ABN': i.ABN,
              'MerchantType': i.MerchantType,
              'MerchantPresence': i.MerchantPresence,
              'Tags': i.Tags,
              'ChainName': i.ChainName,
              'Url': i.Url,
              'DataCompletnessScore': i.DataCompletnessScore,
              'RecordLocked': i.RecordLocked,
              'IsQuarantined': i.IsQuarantined,
              'BSBs': i.BSBs
            })
          }
          let currentDate = this.dateTimeService.formatForODataFilter(moment(Date.now()));
          this.csvExportMerchantSummary.data = this.csvData;
          this.csvExportMerchantSummary.options = this.csvOptions;
          this.csvExportMerchantSummary.filename = `MerchantLookup-${this.searchByItem}-${currentDate}`;
          this.csvExportMerchantSummary.onDownload();
          this.csvData = [];
        }
      })
    }
    if (this.searchByItem === 'Primary Name') {
      let data: any = [];
      this.loadingService.setLoading();
      this.service.searchMerchantSummaryByPrimaryName(this.state.inputType).pipe(finalize(() => this.loadingService.clearLoading())).subscribe((response) => {
        this.loadingService.clearLoading();
        if (response) {
          data = response;
          for (let i of data) {
            this.csvData.push({
              'LWC_ID': i.LWC_ID,
              'PrimaryName': i.PrimaryName,
              'PhoneNumber': i.PhoneNumber,
              'PrimaryAddress': i.PrimaryAddress,
              'PrimaryCategory': i.PrimaryCategory,
              'ABN': i.ABN,
              'MerchantType': i.MerchantType,
              'MerchantPresence': i.MerchantPresence,
              'Tags': i.Tags,
              'ChainName': i.ChainName,
              'Url': i.Url,
              'DataCompletnessScore': i.DataCompletnessScore,
              'RecordLocked': i.RecordLocked,
              'IsQuarantined': i.IsQuarantined,
              'BSBs': i.BSBs
            })
          }
          let currentDate = this.dateTimeService.formatForODataFilter(moment(Date.now()));
          this.csvExportMerchantSummary.data = this.csvData;
          this.csvExportMerchantSummary.options = this.csvOptions;
          this.csvExportMerchantSummary.filename = `MerchantLookup-${this.state.inputType}-${currentDate}`;
          this.csvExportMerchantSummary.onDownload();
          this.csvData = [];
        }
      })
    }
    if (this.searchByItem === 'Website') {
      let data: any = [];
      this.trimHttpOnWebsite();
      this.loadingService.setLoading();
      this.service.searchMerchantSummaryByWebsite(this.state.inputType).pipe(finalize(() => this.loadingService.clearLoading())).subscribe((response) => {
        this.loadingService.clearLoading();
        if (response) {
          data = response;
          for (let i of data) {
            this.csvData.push({
              'LWC_ID': i.LWC_ID,
              'PrimaryName': i.PrimaryName,
              'PhoneNumber': i.PhoneNumber,
              'PrimaryAddress': i.PrimaryAddress,
              'PrimaryCategory': i.PrimaryCategory,
              'ABN': i.ABN,
              'MerchantType': i.MerchantType,
              'MerchantPresence': i.MerchantPresence,
              'Tags': i.Tags,
              'ChainName': i.ChainName,
              'Url': i.Url,
              'DataCompletnessScore': i.DataCompletnessScore,
              'RecordLocked': i.RecordLocked,
              'IsQuarantined': i.IsQuarantined,
              'BSBs': i.BSBs
            })
          }
          let currentDate = this.dateTimeService.formatForODataFilter(moment(Date.now()));
          this.csvExportMerchantSummary.data = this.csvData;
          this.csvExportMerchantSummary.options = this.csvOptions;
          this.csvExportMerchantSummary.filename = `MerchantLookup-${this.state.inputType}-${currentDate}`;
          this.csvExportMerchantSummary.onDownload();
          this.csvData = [];
        }
      })
    }
  }

  onChangeSearchItems(e: MatSelectChange) {
    this.state.inputType = '';
    this.clear.emit(true);
  }
}

