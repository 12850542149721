import { emptyString } from './../../core/constants/constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BaseStateComponent } from './../basestate/basestate.component';
import { ReferenceService } from '../../services/reference.service';
import { LocatedIn } from '../../models/locatedin';
import { OverallResult, ValidateMerchantRecordResult, CategoryType, MerchantPresence } from '../../enums/enums';
import { FormControl, NgForm, NgModel } from '@angular/forms';
import { NotificationService } from '../../core/uiservices/notification.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, debounceTime, distinctUntilChanged, takeUntil, map, startWith } from 'rxjs/operators';
import { EnrichMerchantRequest } from '../../models/enrichmerchantrequest';
import { EnrichMerchantService } from '../../services/enrichmerchant.service';
import { Component, OnInit, Injector, OnDestroy, ViewChild, ChangeDetectorRef, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { DataSource, SearchSelectBase } from '@oasisdigital/angular-material-search-select';
import { EnrichMerchantState, EnrichMerchantSimilarCal } from './enrichmerchantstate';
import { AUS_Indexing_Result } from '../../models/ausindexingresult';
import { SearchSelectService } from '../../services/searchselect.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessStatus } from './businessstatus';
import { CategoryComponent } from '../../shared/category/category.component';
import { SelectedCategory } from '../../shared/category/selectedcategory';
import { ChainSelectComponent } from '../../shared/chainselect/chainselect.component';
import { emptyOptionEntry } from '../../core/constants/constants';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { CategoryLevelNumber } from '../../categories/categorylevelnumber';
import { DialogService } from '../../core/uiservices/dialog.service';
import { IconResultService } from '../../core/uiservices/iconresult.service';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { CollectionTransformerService } from '../../core/collections/collectiontransformer.service';
import { merchantPresenceDisplayValues } from '../../enums/enumdisplayvalues';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { LoginStateService } from '../../core/auth/loginstate.service';
import { RoleType } from '../../users/userenums';
import { IndexMerchantService } from '../../services/indexmerchant.service';
import { PickChainService } from '../../services/pickchain.service';
import { StringNameValue } from '../../core/types/stringnamevalue';
import { BatchRequestService, BatchRequestStatus, BatchRequestConfig } from '../../services/batchsearchrequest.service';
import { ConcurrentRequest } from '../batchmerchantsearch/merchantbatchsearchrequest';
import { BatchSearchResult } from '../batchmerchantsearch/batchsearchresult';
import { TagsService } from '../../services/tags.service';
import { BsbService } from '../../services/bsb.service';
import { QuillEditorService } from '../../core/formatting/quilleditor.service';
import { LocationService } from '../../core/services/location.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { MerchantDetailedResponse, MerchantSummary } from '../mergemerchants/merchantsummary';
import { MerchantAuditComponent } from '../merchantaudit/merchantaudit.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AusIndexedCommentsDisplay, AusIndexedComments } from './enrichmerchant.models';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { RetrieveExperianAddressesService } from '../../services/retrieveexperianaddress.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RetailerAuditService } from '../../services/retaileraudit.service';
import { RetailerAuditDisplay } from '../../retaileraudit/retaileraudit';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-enrichmerchant',
  templateUrl: './enrichmerchant.component.html',
  styleUrls: ['./enrichmerchant.component.scss']
})
export class EnrichMerchantComponent extends BaseStateComponent<EnrichMerchantState> implements OnInit, OnDestroy {
  businessStatuses = [
    { value: BusinessStatus.Active, text: 'Active' },
    { value: BusinessStatus.Inactive, text: 'Inactive' },
    { value: BusinessStatus.Closed, text: 'Closed' }
  ];

  merchantPresenceTypesArray = this.collectionTransformerService.mapToArray(merchantPresenceDisplayValues);

  locations: LocatedIn[] = [];
  locationsCtrl: FormControl;
  templatesCtrl: FormControl;
  notALocationCheckbox: FormControl;
  validationResult: boolean = undefined;
  overalScoreTime = '';
  isGoogleSearchResultSuccess: boolean = undefined;
  isHereMapsSearchResultSuccess: boolean = undefined;
  isMastercardLoadResultSuccess: boolean = undefined;
  validationReportText = '';
  chainsDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  locationsDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  selectedLocation: LocatedIn;
  quillEditorStyle = { height: '110px' };
  categoryTypes: CategoryType[] = [CategoryType.Expense];
  isMerchantLoading = false;
  lockButtonLabel: string;
  isInRoleAdministratorOrTeamlead: boolean;
  chainTypesToInclude: Array<string | 0>;
  templates = this.enrichMerchantService.getQueryTransactionProcessTemplates();
  templatesDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  selectedTemplate: StringNameValue;
  selected: EnrichMerchantSimilarCal[] = [];
  enrichMerchantRequest: Partial<EnrichMerchantRequest>[] = [];
  enrichBatchRequestStatus: BatchRequestStatus<AUS_Indexing_Result> = {
    areMerchantsLoading: false,
    searchInProgressCount: 0,
    searchCompletedCount: 0,
    searchFailedCount: 0,
    batchSearhResults: []
  };
  searchWasPerformed = false;
  enrichmentIsInProgress: boolean = false;
  allTags: string[] = [];
  filteredTags: string[] = [];
  allBsbs: string[] = [];
  isQueryTransactionProcessEmpty: boolean = true;
  showHistory: boolean = false;
  showHistoryToggle: boolean = false;
  showCommentsToggle: boolean = false;
  showComments: boolean = false;
  bsbChipIsIncorrect: boolean = false;
  lwcIdInputIsIncorrect: boolean = false;
  bsbInputIsValid: boolean = false;
  ausIndexingCommentsResponse: AusIndexedCommentsDisplay[] = [];
  indexOfEditComment: number = -1;
  originalComment: AusIndexedCommentsDisplay;
  experianAddresses: string[] = [];

  allowPredictedMatch: boolean;
  allow_predicted_match: boolean;
  commentChecked: boolean;

  @ViewChild('categoryComponent', { static: true }) categoryComponent: CategoryComponent;
  @ViewChildren('chainSelect') chainSelectQueryList: QueryList<ChainSelectComponent>;
  @ViewChild('locationSearchSelect', { static: true }) locationSearchSelect: SearchSelectBase;
  @ViewChild('lwcId', { static: true }) lwcIdInput: NgModel;
  @ViewChild('bsb', { static: true }) bsbInputValue: NgModel;
  @ViewChild('merchantAddress') merchantAddress: NgModel;
  @ViewChildren('merchantAudit') merchantAuditComponent: QueryList<MerchantAuditComponent>;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('templatesQueryTransactionSelect', { static: true }) templatesQueryTransactionSelect: SearchSelectBase;

  chainSelect: ChainSelectComponent;
  tagControl = new FormControl();
  bsbCtrl = new FormControl();
  bpayCodeCtrl = new FormControl();
  retailerAuditsDatatable: RetailerAuditDisplay[] = [];

  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;

  private enrcihmerchantSizeProvider = (r: EnrichMerchantRequest) => 1;
  primaryName: string;
  primaryAddress: string;
  primaryNumber: string;

  constructor(private enrichMerchantService: EnrichMerchantService, private loadingService: LoadingService,
    private notificationService: NotificationService, private referenceService: ReferenceService,
    injector: Injector, private searchSelectService: SearchSelectService, private route: ActivatedRoute,
    private valueCheckerService: ValueCheckerService, private dialogService: DialogService,
    private iconResultService: IconResultService, private merchantlookupService: MerchantlookupService,
    private collectionTransformerService: CollectionTransformerService, private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService,
    private loginStateService: LoginStateService, private indexMerchantService: IndexMerchantService,
    private pickChainService: PickChainService, private batchRequestService: BatchRequestService,
    private tagsService: TagsService, private bsbService: BsbService, private quillEditorService: QuillEditorService,
    private changeDetectorRef: ChangeDetectorRef, private functionService: LocationService,
    private dateTimeService: DateTimeService, private retrieveExperianAddressesService: RetrieveExperianAddressesService,
    private retailerAuditService: RetailerAuditService, private router: Router) {
    super(injector);
    this.locationsCtrl = new FormControl();
    this.allowPredictedMatch = false;
    this.allow_predicted_match = false;
    this.commentChecked = false;
  }

  ngOnInit() {
    this.configureQtpTemplates();

    this.notALocationCheckbox = new FormControl();

    this.lockButtonLabel = 'Unlock';

    this.handleState();

    this.setResultsFields();

    this.locationSearchSelect.searchControl.setValue(this.state.locationNotTracked);

    this.notALocationCheckbox.valueChanges.subscribe(value =>
      value ? this.locationsCtrl.disable() : this.locationsCtrl.enable());

    this.getLocations();
    this.configurelwcIdAndBsbValueChange();
    this.isInRoleAdministratorOrTeamlead =
      this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead]);

    this.chainTypesToInclude = this.pickChainService.getUsualChainTypesForDisplay();
    this.allTags = this.getTags();
    this.allBsbs = this.getBsbs();

    if (this.state.AllowPredictedMatch == true) {
      this.allowPredictedMatch = true;
    } else {
      this.allowPredictedMatch = false;
    }
  }

  ngAfterViewInit() {
    this.merchantAuditComponent.changes
      .pipe(debounceTime(1200))
      .subscribe(merchantAudit => {
        if (merchantAudit.last) {
          this.merchantAuditChange();
        }
      });

    this.chainSelect = this.chainSelectQueryList?.last;
    this.chainSelectQueryList.changes.subscribe((component) => {
      if (component.last) {
        this.disableChainSelectComponentIfNeeded();
      }
    });

    this.retrieveExperianAddressesService.retrieveExperianAddresses(this.merchantAddress, () => this.experianAddresses)
      .subscribe(response => {
        if (response && response.data.length > 0) {
          this.experianAddresses = response.data;
        }
        else {
          this.experianAddresses = [];
        }
      });

    this.tagControl.valueChanges.pipe(
      startWith(null),
      map((tag: string) => tag ? this.filterTags(tag) : this.allTags.slice())
    )
      .subscribe((tags) => {
        this.filteredTags = tags;
      });
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  private filterTags(tag: string) {
    return this.allTags.filter(t => t.toLowerCase().includes(tag.toLowerCase()));
  }

  private configureQtpTemplates() {
    this.templatesCtrl = new FormControl();
    this.templatesDataSource = this.enrichMerchantService.getTeplatesDatasource(this.templates);
    this.templatesCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(template => {
      if (template) {
        this.selectedTemplate = { name: template.display, value: template.value };
      } else {
        this.selectedTemplate = null;
      }
    });

    this.templatesQueryTransactionSelect.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((template) => {
      if (!this.valueCheckerService.isNullOrUndefined(template) && typeof template === 'object') {
        this.selectedTemplate = { name: template.value.name, value: template.value.value };
      } else {
        this.selectedTemplate = null;
      }
    });
  }

  getCalUrlEncoded() {
    return encodeURI(this.state.TransactionDescription);
  }

  ngOnDestroy() {
    let chainValue = this.chainSelect ? this.chainSelect.getValue() : null;

    if (chainValue) {
      this.state.chainValue = chainValue;
    } else {
      this.state.chainValue = emptyOptionEntry;
    }

    this.state.locationNotTracked = this.locationSearchSelect.searchControl.value;
    super.ngOnDestroy();
  }

  onChainValueChange(value: string) {
    if (value) {
      this.state.chainValue = {
        value: value,
        display: value,
        details: ''
      }
    } else {
      this.state.chainValue = emptyOptionEntry;
    }
  }

  getEnrichDataResultClass(overallResult: OverallResult): string {
    let className = 'small-spacer inline-block ';

    switch (overallResult) {
      case OverallResult.Success: {
        className += 'success';
        break;
      }
      case OverallResult.Error: {
        className += 'danger';
        break;
      }
      default: {
        className += 'warning';
        break;
      }
    }

    return className;
  }

  getBuildMerchantRecordResultText() {
    if (this.state.enrichMerchantResult.BuildMerchantRecordOutcome === null
      || this.state.enrichMerchantResult.BuildMerchantRecordOutcome === undefined) {
      return '';
    }

    let buildMerchantRecordResult = 'Result: ';

    switch (this.state.enrichMerchantResult.BuildMerchantRecordOutcome) {
      case OverallResult.Success: {
        buildMerchantRecordResult += 'Success; ';
        break;
      }
      case OverallResult.Error: {
        buildMerchantRecordResult += 'Error; ';
        break;
      }
      case OverallResult.Incomplete: {
        buildMerchantRecordResult += 'Incomplete; ';
        break;
      }
      case OverallResult.IndexingFailed: {
        buildMerchantRecordResult += 'Indexing Failed; ';
        break;
      }
      case OverallResult.AlreadyIndexed: {
        buildMerchantRecordResult += 'Already Indexed; ';
        break;
      }
    }

    if (this.state.enrichMerchantResult.DataRichnessScore) {
      buildMerchantRecordResult += `Score: ${this.state.enrichMerchantResult.DataRichnessScore}; `;
    }

    if (this.state.enrichMerchantResult.ExecutionTimeInSeconds) {
      buildMerchantRecordResult += `Time: ${Math.round(this.state.enrichMerchantResult.ExecutionTimeInSeconds)} sec.`;
    }

    return buildMerchantRecordResult;
  }

  toggleOverrideMastercardIfNeeded() {
    if (!this.state.MastercardData) {
      this.state.OverrideMastercardExistanceCheck = false;
    }
  }

  enrichData() {
    if (!this.validateOtherCategory()) {
      return;
    }

    let enrichMerchantRequest = this.getEnrichMerchantRequest();
    enrichMerchantRequest.setBsbs = true;
    enrichMerchantRequest.SetBPayBillerCodes = true;
    this.loadingService.setLoading();
    this.clearResultsFields();
    this.enrichMerchantService.enrich(enrichMerchantRequest).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(result => {
        // only needed until latest api prod is deployed
        result = this.setEmptyResultsForNulls(result);
        this.state.enrichMerchantResult = result;
        if (this.state.enrichMerchantResult.ExistanceCheck.CALExistanceCheckDetails.CALMatchDetailsResult === 5) {
          this.state.AllowPredictedMatch = true;
          this.allowPredictedMatch = true;
        }
        else {
          this.state.AllowPredictedMatch = false;
          this.allowPredictedMatch = false;
        }
        this.checkErrorMessage(this.state.enrichMerchantResult);
        this.clearToRunFields();
        this.setResultsFields();
        this.lookupMerchant();
        this.notificationService.notifySuccess('Action completed');

        if ((result.ValidationResult.Errors && result.ValidationResult.Errors.length > 0) ||
          (result.ValidationResult.Warnings && result.ValidationResult.Warnings.length > 0)) {
          setTimeout(() => this.router.navigate(['/merchantvalidation', { lwcId: result.LWC_ID }]), 2000);
        }
      });
  }
  checkErrorMessage(data: AUS_Indexing_Result) {
    if (data.PrimaryPhoneNumber.Outcome === true) {
      this.state.enrichMerchantResult.PrimaryPhoneNumber.Success = data.PrimaryPhoneNumber.Comments;
    } else {
      if (data.PrimaryPhoneNumber.Comments != null) {
        if (data.PrimaryPhoneNumber.Comments.includes('Error')) {
          this.state.enrichMerchantResult.PrimaryPhoneNumber.Error = data.PrimaryPhoneNumber.Comments;
        } else {
          this.state.enrichMerchantResult.PrimaryPhoneNumber.Comments = data.PrimaryPhoneNumber.Comments;
        }
      }
    }
    if (data.NewPrimaryName.Outcome === true) {
      this.state.enrichMerchantResult.NewPrimaryName.Success = data.NewPrimaryName.Comments;
    } else {
      if (data.NewPrimaryName.Comments != null) {
        if (data.NewPrimaryName.Comments.includes('Error')) {
          this.state.enrichMerchantResult.NewPrimaryName.Error = data.NewPrimaryName.Comments;
        } else {
          this.state.enrichMerchantResult.NewPrimaryName.Comments = data.NewPrimaryName.Comments;
        }
      }
    }
    if (data.PrimaryAddress.Outcome === true) {
      this.state.enrichMerchantResult.PrimaryAddress.Success = data.PrimaryAddress.Comments;
    } else {
      if (data.PrimaryAddress.Comments != null) {
        if (data.PrimaryAddress.Comments.includes('Error')) {
          this.state.enrichMerchantResult.PrimaryAddress.Error = data.PrimaryAddress.Comments;
        } else {
          this.state.enrichMerchantResult.PrimaryAddress.Comments = data.PrimaryAddress.Comments;
        }
      }
    }
    if (data.LoadChainInformation.Outcome === true) {
      this.state.enrichMerchantResult.LoadChainInformation.Success = data.LoadChainInformation.Comments;
    } else {
      if (data.LoadChainInformation.Comments != null) {
        if (data.LoadChainInformation.Comments.includes('Error')) {
          this.state.enrichMerchantResult.LoadChainInformation.Error = data.LoadChainInformation.Comments;
        } else {
          this.state.enrichMerchantResult.LoadChainInformation.Comments = data.LoadChainInformation.Comments;
        }
      }
    }
    if (data.OnlineMobile.Outcome === true) {
      this.state.enrichMerchantResult.OnlineMobile.Success = data.OnlineMobile.Comments;
    } else {
      if (data.OnlineMobile.Comments != null) {
        if (data.OnlineMobile.Comments.includes('Error')) {
          this.state.enrichMerchantResult.OnlineMobile.Error = data.OnlineMobile.Comments;
        } else {
          this.state.enrichMerchantResult.OnlineMobile.Comments = data.OnlineMobile.Comments;
        }
      }
    }
    if (data.PrimaryWebAddress.Outcome === true) {
      this.state.enrichMerchantResult.PrimaryWebAddress.Success = data.PrimaryWebAddress.Comments;
    } else {
      if (data.PrimaryWebAddress.Comments != null) {
        if (data.PrimaryWebAddress.Comments.includes('Error')) {
          this.state.enrichMerchantResult.PrimaryWebAddress.Error = data.PrimaryWebAddress.Comments;
        } else {
          this.state.enrichMerchantResult.PrimaryWebAddress.Comments = data.PrimaryWebAddress.Comments;
        }
      }
    }
    if (data.PrimaryEmailAddress.Outcome === true) {
      this.state.enrichMerchantResult.PrimaryEmailAddress.Success = data.PrimaryEmailAddress.Comments;
    } else {
      if (data.PrimaryEmailAddress.Comments != null) {
        if (data.PrimaryEmailAddress.Comments.includes('Error')) {
          this.state.enrichMerchantResult.PrimaryEmailAddress.Error = data.PrimaryEmailAddress.Comments;
        } else {
          this.state.enrichMerchantResult.PrimaryEmailAddress.Comments = data.PrimaryEmailAddress.Comments;
        }
      }
    }

    if (data.LoadABR.Outcome === true) {
      this.state.enrichMerchantResult.LoadABR.Success = data.LoadABR.Comments;
    } else {
      if (data.LoadABR.Comments != null) {
        if (data.LoadABR.Comments.includes('Error')) {
          this.state.enrichMerchantResult.LoadABR.Error = data.LoadABR.Comments;
        } else {
          this.state.enrichMerchantResult.LoadABR.Comments = data.LoadABR.Comments;
        }
      }
    }
    if (data.URLToDisputeProcessPage.Outcome === true) {
      this.state.enrichMerchantResult.URLToDisputeProcessPage.Success = data.URLToDisputeProcessPage.Comments;
    } else {
      if (data.URLToDisputeProcessPage.Comments != null) {
        if (data.URLToDisputeProcessPage.Comments.includes('Error')) {
          this.state.enrichMerchantResult.URLToDisputeProcessPage.Error = data.URLToDisputeProcessPage.Comments;
        } else {
          this.state.enrichMerchantResult.URLToDisputeProcessPage.Comments = data.URLToDisputeProcessPage.Comments;
        }
      }
    }
    if (data.AddDescription.Outcome === true) {
      this.state.enrichMerchantResult.AddDescription.Success = data.AddDescription.Comments;
    } else {
      if (data.AddDescription.Comments != null) {
        if (data.AddDescription.Comments.includes('Error')) {
          this.state.enrichMerchantResult.AddDescription.Error = data.AddDescription.Comments;
        } else {
          this.state.enrichMerchantResult.AddDescription.Comments = data.AddDescription.Comments;
        }
      }
    }
  }

  sendPredictedMatch(event: MatCheckboxChange) {
    if (event.checked) {
      this.allow_predicted_match = true;
    } else {
      this.allow_predicted_match = false;
    }
  }

  setAusIndexedComments(event: MatSlideToggleChange) {
    if (event.checked) {
      this.loadingService.setLoading();
      this.enrichMerchantService.getAusIndexedComments(this.state.LWC_ID)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          if (response) {
            for (let i of response) {
              if (i.IsActive === true) {
                this.ausIndexingCommentsResponse = response;
              }
              if (i.lastUpdated !== null) {
                i.lastUpdated = i.lastUpdated;
              }
              else {
                i.lastUpdated = i.lastUpdated;
              }
            }
            for (let comment of this.ausIndexingCommentsResponse) {
              this.setDisplayValuesForAusIndexedComments(comment);
            }
          }
          else {
            this.ausIndexingCommentsResponse = [];
          }
        });
    }
    else if (!event.checked && this.indexOfEditComment !== -1) {
      if (!this.ausIndexingCommentsResponse[this.indexOfEditComment].isNewRecord) {
        this.ausIndexingCommentsResponse[this.indexOfEditComment] = this.originalComment;
        this.ausIndexingCommentsResponse = [...this.ausIndexingCommentsResponse];
      }
      else {
        this.ausIndexingCommentsResponse.splice(this.indexOfEditComment, 1);
        this.ausIndexingCommentsResponse = [...this.ausIndexingCommentsResponse];
      }
      this.indexOfEditComment = -1;
    }
  }

  addNewComment() {
    this.ausIndexingCommentsResponse.push({
      LWC_ID: this.state.LWC_ID,
      Comment: emptyString,
      isNewRecord: true,
      AUS_Indexed_CommentID: null,
      NameOfIndexer: emptyString,
      IDOfIndexer: null,
      lastUpdated: emptyString,
      IsActive: null,
      IsActiveComment: emptyString,
    });
    this.ausIndexingCommentsResponse = [...this.ausIndexingCommentsResponse];
    this.indexOfEditComment = this.ausIndexingCommentsResponse.length - 1;
  }

  editComment(row: AusIndexedCommentsDisplay, rowIndex: number) {
    this.originalComment = { ...row };
    this.indexOfEditComment = rowIndex;
  }

  deleteComment(row: AusIndexedCommentsDisplay, rowIndex: number) {
    this.loadingService.setLoading();
    this.enrichMerchantService.deleteAusIndexedComment(this.state.LWC_ID, row.AUS_Indexed_CommentID)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.ausIndexingCommentsResponse.splice(rowIndex, 1);
        this.ausIndexingCommentsResponse = [...this.ausIndexingCommentsResponse];
        this.notificationService.notifySuccess("Record deleted successfully");
      });
  }

  saveComment(row: AusIndexedCommentsDisplay, rowIndex: number) {
    this.loadingService.setLoading();
    let ausIndexedComment: Partial<AusIndexedComments> = {
      LWC_ID: row.LWC_ID,
      Comment: row.Comment,
    };
    if (!row.isNewRecord) {
      ausIndexedComment.AUS_Indexed_CommentID = row.AUS_Indexed_CommentID;
      ausIndexedComment.IsActive = true;
      ausIndexedComment.NameOfIndexer = row.NameOfIndexer;
      ausIndexedComment.IDOfIndexer = row.IDOfIndexer;
      ausIndexedComment.lastUpdated = row.lastUpdated;
      ausIndexedComment.IsActiveComment = row.IsActiveComment;
    }
    this.enrichMerchantService.saveAusIndexedComment(ausIndexedComment, this.state.LWC_ID)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.ausIndexingCommentsResponse[rowIndex] = response;
        this.ausIndexingCommentsResponse = [...this.ausIndexingCommentsResponse];
        this.setDisplayValuesForAusIndexedComments(this.ausIndexingCommentsResponse[rowIndex]);
        this.indexOfEditComment = -1;
        this.notificationService.notifySuccess();
      });

  }

  cancelComment(row: AusIndexedCommentsDisplay, rowIndex: number) {
    if (row.isNewRecord === true) {
      this.ausIndexingCommentsResponse.splice(rowIndex, 1);
      this.ausIndexingCommentsResponse = [...this.ausIndexingCommentsResponse];
    }
    else {
      this.ausIndexingCommentsResponse[rowIndex] = this.originalComment;
      this.ausIndexingCommentsResponse = [...this.ausIndexingCommentsResponse];
    }
    this.indexOfEditComment = -1;
  }

  private getEnrichMerchantRequest() {
    let enrichMerchantRequest = this.getEnrichMerchantRequestBasedOnCheckboxes();
    enrichMerchantRequest.LWC_ID = this.state.LWC_ID;
    enrichMerchantRequest.ABRData = !this.valueCheckerService.isEmptyNullOrUndefined(this.state.ABN);
    enrichMerchantRequest.ASICData = this.state.ASICData;
    enrichMerchantRequest.TomTomData = this.state.TomTomData;
    enrichMerchantRequest.GoogleData = this.state.GoogleData;
    enrichMerchantRequest.SensisData = this.state.SensisData;
    enrichMerchantRequest.ClearbitData = this.state.ClearbitData;
    enrichMerchantRequest.MastercardData = this.state.MastercardData;
    enrichMerchantRequest.SetCAL = this.enrichmentIsInProgress ? true : !this.valueCheckerService.isEmptyNullOrUndefined(this.state.TransactionDescription);
    enrichMerchantRequest.MerchantScreenshot = this.state.MerchantScreenshot;
    if (!this.isBsbChipListDisabled()) {
      enrichMerchantRequest.Bsbs = this.state.Bsbs;
    }

    if (this.state.notAChain) {
      enrichMerchantRequest.ChainInformation = true;
    }
    else {
      enrichMerchantRequest.ChainInformation = !this.valueCheckerService.isEmptyNullOrUndefined(this.chainSelect.getValue().value);
    }
    enrichMerchantRequest.SetAssociatesAndAKAs = this.state.SetAssociatesAndAKAs;
    enrichMerchantRequest.HereMapsData = this.state.HereMapsData;
    enrichMerchantRequest.RitekitLogo = this.state.RitekitLogo;
    enrichMerchantRequest.OverrideMastercardExistanceCheck = this.state.OverrideMastercardExistanceCheck;
    enrichMerchantRequest.MerchantPresence = this.state.MerchantPresence;
    if (this.state.isStreetViewNotAvailable) {
      enrichMerchantRequest.FlagStreetViewNotAvailable = this.state.isStreetViewNotAvailable;
    }
    else {
      enrichMerchantRequest.FlagStreetViewNotAvailable = null;
    }
    if (this.state.selectedCategory.isSelected) {
      enrichMerchantRequest.StandardCategory = this.state.selectedCategory.category.CategoryID;
    }
    let locationSearch = this.locationSearchSelect.searchControl.value;
    if (this.state.MerchantAddress || (this.locationsCtrl.value || locationSearch)) {
      if (this.locationsCtrl.value) {
        enrichMerchantRequest.LocatedInID = this.locationsCtrl.value.LocatedIn_ID;
      } else if (!this.valueCheckerService.isNullOrUndefined(locationSearch) && typeof locationSearch === 'object') {
        enrichMerchantRequest.LocatedInID = this.locationSearchSelect.searchControl.value.value.LocatedIn_ID;
      }
      enrichMerchantRequest.MerchantAddress = this.state.MerchantAddress;
    }
    if (this.state.isNotALocation) {
      enrichMerchantRequest.LocatedInID = -1;
    }
    if (this.allowPredictedMatch == true) {
      enrichMerchantRequest.AllowPredictedMatch = this.allow_predicted_match;
    }
    if (this.state.BPayBillerCodes) {
      enrichMerchantRequest.BPayBillerCodes = this.state.BPayBillerCodes;
    }
    enrichMerchantRequest.setBsbs = true;
    enrichMerchantRequest.SetBPayBillerCodes = true;
    return enrichMerchantRequest;
  }

  clearToRunFields() {
    this.state.ABRData = false;
    this.state.ASICData = false;
    this.state.GoogleData = false;
    this.state.SensisData = false;
    this.state.ClearbitData = false;
    this.state.MastercardData = false;
    this.state.MerchantScreenshot = false;
    this.state.SetAssociatesAndAKAs = false;
    this.state.HereMapsData = false;
    this.state.RitekitLogo = false;
    this.state.OverrideMastercardExistanceCheck = false;
    this.state.TomTomData = false;
  }

  clearResultsFields() {
    this.state.enrichMerchantResult = this.getEmptyEnrichDataResult();
    this.validationReportText = '';
    this.validationResult = undefined;
  }

  setResultsFields() {
    if (!this.state.enrichMerchantResult || !this.state.enrichMerchantResult.ValidationResult) {
      this.validationResult = undefined;
      return;
    }

    this.setValidationReportText();

    if (this.state.enrichMerchantResult.ValidationResult.Result === ValidateMerchantRecordResult.Passed) {
      this.validationResult = true;
    }
  }

  isEnrichDataButtonDisabled() {
    return this.state.LWC_ID <= 0
      && (this.valueCheckerService.isEmptyNullOrUndefined(this.state.BSB) || this.bsbInputIsValid === false)
      || this.state.LockState;
  }

  resetForm(form: NgForm) {
    form.resetForm();
    this.state.merchantLookupResponseFirstPart = null;
    this.state.merchantLookupResponseSecondPart = null;
    this.isMerchantLoading = false;
    this.state.merchantNotFound = false;
    this.chainSelect.setValue('');
    this.state.chainValue = emptyOptionEntry;
    this.locationSearchSelect.searchControl.setValue('');
    this.state.LocatedInID = null;

    this.locationsCtrl.reset();
    this.templatesCtrl.reset();
    this.state.selectedCategory = {
      category: null,
      isSelected: false
    }
    this.state.enrichMerchantResult = this.getEmptyEnrichDataResult();
    this.validationReportText = '';
    this.validationResult = undefined;
    this.state.isStreetViewNotAvailable = false;
    this.state.isNotALocation = false;
    this.state.MerchantPresence = MerchantPresence.Unspecified;
    this.isGoogleSearchResultSuccess = undefined;
    this.isHereMapsSearchResultSuccess = undefined;
    this.isMastercardLoadResultSuccess = undefined;
    this.validationResult = undefined;
    this.state.notAChain = false;
    this.state.noPhoneNumber = false;
    this.categoryComponent.clear();
    this.searchWasPerformed = false;
    this.selected = [];
    this.state.similarCalResponse = [];
    this.state.TagsUpdated = false;
    this.state.Tags = [];
    this.state.DirectEntryCode = null;
    this.state.Bsbs = [];
    this.showHistory = false;
    this.showHistoryToggle = false;
    this.showCommentsToggle = false;
    this.showComments = false;
    this.bsbChipIsIncorrect = false;
    this.lwcIdInputIsIncorrect = false;
    this.bsbInputIsValid = false;
    this.state.BPayBillerCodes = [];
    this.chainSelect.chainCtrl.enable();
    this.allowPredictedMatch = false;
    this.bpayCodeCtrl.reset('');
  }

  taskExecuted(value: any) {
    return this.notNullOrUndefined(value) && value.taskExecuted;
  }

  notNullOrUndefined(value: {}) {
    return value !== null && value !== undefined;
  }

  getValidationResultIcon(): string {
    if (!this.state.enrichMerchantResult || !this.state.enrichMerchantResult.ValidationResult) {
      return null;
    }
    switch (this.state.enrichMerchantResult.ValidationResult.Result) {
      case ValidateMerchantRecordResult.Passed:
        return 'done_all';
      case ValidateMerchantRecordResult.Warning:
        return 'warning';
      case ValidateMerchantRecordResult.Error:
        return 'error';
      default:
        return null;
    }
  }

  getValidationResultCssClass(): string {
    let classes = 'mat-icon material-icons';

    if (!this.state.enrichMerchantResult || !this.state.enrichMerchantResult.ValidationResult) {
      return classes;
    }
    switch (this.state.enrichMerchantResult.ValidationResult.Result) {
      case ValidateMerchantRecordResult.Passed:
        return classes + ' success';
      case ValidateMerchantRecordResult.Warning:
        return classes + 'warning';
      case ValidateMerchantRecordResult.Error:
        return classes + 'danger';
      default:
        return null;
    }
  }

  getOutcomeIcon(result: boolean) {
    return this.iconResultService.getOutcomeIcon(result);
  }

  getOutcomeCssClass(result: boolean) {
    return this.iconResultService.getOutcomeCssClass(result);
  }

  handleCategorySelect(selectedCategory: SelectedCategory) {
    this.state.selectedCategory = selectedCategory;
  }

  shouldShowMerchantPresenceForwardArrow() {
    return this.state.MerchantPresence !== MerchantPresence.Unspecified;
  }

  checkMerchantLookupResponse() {
    if (this.state.merchantLookupResponseFirstPart && !this.state.merchantNotFound) {
      return true;
    }
    return false;
  }

  isLockButtonDisabled() {
    if (this.state.LockState === false) {
      return !this.state.merchantLookupResponseFirstPart || this.isEnrichDataButtonDisabled();
    }
    return !this.state.merchantLookupResponseFirstPart;
  }

  changeLockState() {
    this.loadingService.setLoading();
    let lwcId = this.valueCheckerService.isEmptyNullOrUndefined(this.state.LWC_ID)
      ? this.state.merchantLookupResponseFirstPart.LWC_ID
      : this.state.LWC_ID;

    this.indexMerchantService.changeLockState(lwcId, this.state.LockState ? 0 : 1)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.lookupMerchant();
      });
  }

  onSelect(selected: any) {
    this.selected = selected.selected;
  }

  enrichSelectedCals() {
    if (this.selected.length === 0) {
      this.searchWasPerformed = false;
      return;
    }
    this.enrichmentIsInProgress = true;
    this.searchWasPerformed = true;
    let merchantRequest = this.getEnrichMerchantRequest();
    delete merchantRequest.QueryTransactionProcess;
    let selectedCals = this.enrichMerchantService.prepareEnrichMerchantRequest(this.selected.map(s => s.cal), this.state.LWC_ID);
    for (let i = 0; i < selectedCals.length; i++) {
      this.enrichMerchantRequest.push({ ...merchantRequest, ...selectedCals[i] });
    }
    let retryRequests: ConcurrentRequest<EnrichMerchantRequest>[] = [];
    let successRequests: ConcurrentRequest<EnrichMerchantRequest>[] = [];
    let failedRequests: ConcurrentRequest<EnrichMerchantRequest>[] = [];
    this.enrichBatchRequestStatus.batchSearhResults = [];
    let batchRequests = <EnrichMerchantRequest[]>this.enrichMerchantRequest;
    const config: BatchRequestConfig<EnrichMerchantRequest, AUS_Indexing_Result> = {
      searchBatchRequest: this.enrichMerchantService.enrich,
      searchBatchSize: 1,
      concurrentRequests: 1
    };
    this.batchRequestService.doBatchRequest<EnrichMerchantRequest, AUS_Indexing_Result>(batchRequests, batchRequests, batchRequests, retryRequests,
      successRequests, failedRequests, this.enrcihmerchantSizeProvider, this.enrichBatchRequestStatus, config)
      .subscribe((batchRequestStatus) => {
        if (this.enrichBatchRequestStatus.searchInProgressCount === 0) {
          this.enrichBatchRequestStatus = { ...batchRequestStatus };
          this.setResultColumn(batchRequestStatus.batchSearhResults);
          this.selected = [];
          this.enrichmentIsInProgress = false;
          this.enrichMerchantRequest = [];
        }

        // Enforce ngOnChange
        this.enrichBatchRequestStatus.batchSearhResults = batchRequestStatus.batchSearhResults.slice(0);
      });
  }

  setResultColumn(batchSearchResults: BatchSearchResult<AUS_Indexing_Result>[]) {
    for (let i = 0; i < this.selected.length; i++) {
      let result = batchSearchResults.find(r => r.requestIndex === i);
      if (!result) {
        continue;
      }
      this.selected[i].result = this.enrichMerchantService.getResultText(result.response);
    }
  }

  checkForwardSlash(value: any) {
    let url = value.trim();
    if (url.endsWith("/")) {
      this.state.WebAddress = url.substring(0, url.length - 1);
    } else {
      this.state.WebAddress = url;
    }
  }

  previewQTPTemplate(template: string) {
    let preview = template;
    if (this.state.merchantLookupResponseSecondPart) {
      if (this.state.merchantLookupResponseSecondPart.PhoneNumber) {
        preview = preview.replace('[phone]', this.state.merchantLookupResponseSecondPart.PhoneNumber);
      }
      if (this.state.merchantLookupResponseSecondPart.Email) {
        preview = preview.replace('[email]', this.state.merchantLookupResponseSecondPart.Email);
      }
    }


    return preview;
  }

  generateQTP(template: StringNameValue) {
    let value = this.previewQTPTemplate(template.value);
    this.state.QueryTransactionProcess = value;
  }

  removeTag(tag: string) {
    this.state.TagsUpdated = true;
    let indexOfTag = this.state.Tags.indexOf(tag);
    this.state.Tags.splice(indexOfTag, 1);
  }

  removeBPayCode(bpay: number) {
    let indexofBpay = this.state.BPayBillerCodes.indexOf(bpay);
    this.state.BPayBillerCodes.splice(indexofBpay, 1);
  }

  addBpayCode(event: MatChipInputEvent) {
    if (event.value.length <= 7) {
      const value = (event.value || '').trim();
      if (value) {
        this.state.BPayBillerCodes.push(Number(value));
      }
      event.chipInput!.clear();
    }
  }

  selectTag(event: MatAutocompleteSelectedEvent) {
    if (!this.state.Tags.includes(event.option.viewValue)) {
      this.state.TagsUpdated = true;
      this.state.Tags.push(event.option.viewValue);
    }
    this.tagInput.nativeElement.value = emptyString;
    this.tagControl.setValue(null);
  }

  selectBsb(event: MatAutocompleteSelectedEvent) {
    if (!this.state.Bsbs.includes(event.option.value)) {
      this.state.Bsbs.push(event.option.value);
    }
  }

  removeBsb(bsb: string) {
    let indexOfBsb = this.state.Bsbs.indexOf(bsb);
    this.state.Bsbs.splice(indexOfBsb, 1);
  }

  shouldShowDirectEntryCodeInvalid(code: NgModel) {
    if (this.state.DirectEntryCode) {
      if (code.touched && this.directEntryCodeInvalid()
        || !code.touched && this.directEntryCodeInvalid()) {
        return true;
      }
    }
    return false;
  }

  isBsbChipListDisabled() {
    return !this.state.merchantLookupResponseSecondPart ||
      !this.state.merchantLookupResponseSecondPart.MerchantType
  }

  getBsbs() {
    this.loadingService.setLoading();
    let bsbs: string[] = [];
    this.bsbService.getBsbs()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          bsbs = response;
        };
      });
    return bsbs;
  }

  queryTransactionChanged(event: any) {
    if (this.quillEditorService.isEmpty(event.text, event.html)) {
      this.isQueryTransactionProcessEmpty = true;

      return;
    }
    this.isQueryTransactionProcessEmpty = false;
  }

  setBsbChip(event: MatChipInputEvent) {
    let input = event.input;
    let value = input.value;

    if ((this.bsbService.bsbValidFormat).test(value) && value.length === 7) {
      this.state.Bsbs.push(value);
      input.value = "";
      this.bsbChipIsIncorrect = false;
    }
    else if (value === "") {
      this.bsbChipIsIncorrect = false;
    }
    else {
      this.bsbChipIsIncorrect = true;
    }
  }

  merchantAuditChange() {
    this.merchantAuditComponent.last.lwcId = this.state.LWC_ID;
    this.merchantAuditComponent.last.getMerchantAudit();
  }

  showAllCategories(event: MatCheckboxChange) {
    if (event.checked) {
      this.categoryComponent.showAllCategories = true;
      this.categoryComponent.filterCategories();
    }
    else {
      this.categoryComponent.showAllCategories = false;
      this.categoryComponent.filterCategories();
    }
  }

  disableChainSelectComponentIfNeeded() {
    this.chainSelect = this.chainSelectQueryList.last;
    if (this.state.merchantLookupResponseFirstPart && this.state.merchantLookupResponseFirstPart.ChainName) {
      this.chainSelect?.chainCtrl.disable({ emitEvent: false });
      this.chainSelect.tooltip = "This merchant is currently associated with a chain, if changing to a different chain, please purge the merchant and re-index it.";
    }
    else {
      this.chainSelect.chainCtrl.enable({ emitEvent: false });
      if (!this.chainSelect.getValue().value) {
        this.chainSelect.setValue('');
      }
      this.chainSelect.tooltip = null;
    }
  }

  private directEntryCodeInvalid() {
    if (this.state.DirectEntryCode.toString().length > 6) {
      return true;
    }
    return false;
  }

  private getTags() {
    this.loadingService.setLoading();
    let tags: string[] = [];
    this.tagsService.getTags()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          for (let tag of response) {
            tags.push(tag.tagLabel);
          }
        }
      });
    return tags;
  }

  private getLocations() {
    this.referenceService.getLocations()
      .subscribe(locations => {
        this.locationsDataSource = this.searchSelectService.getDataSource(locations, this.functionService.filterFn, this.functionService.mapFn);
      });
  }

  private getEnrichMerchantRequestBasedOnCheckboxes(): Partial<EnrichMerchantRequest> {
    let enrichMerchantRequest: Partial<EnrichMerchantRequest> = {};

    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.TransactionDescription)) {
      enrichMerchantRequest.TransactionDescription = this.state.TransactionDescription;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.NewPrimaryName)) {
      enrichMerchantRequest.NewPrimaryName = this.state.NewPrimaryName;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.WebAddress)) {
      enrichMerchantRequest.WebAddress = this.state.WebAddress;
    }
    if (!this.state.noPhoneNumber && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.PhoneNumber)) {
      enrichMerchantRequest.PhoneNumber = this.state.PhoneNumber;
    }
    if (this.state.noPhoneNumber) {
      enrichMerchantRequest.NoPhoneNumber = true;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.PrimaryEmailAddress)) {
      enrichMerchantRequest.PrimaryEmailAddress = this.state.PrimaryEmailAddress;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.ABN)) {
      enrichMerchantRequest.ABN = this.state.ABN.toString();
    }
    if (this.chainSelect && !this.valueCheckerService.isEmptyNullOrUndefined(this.chainSelect.getValue().value)) {
      enrichMerchantRequest.ChainName = <string>this.chainSelect.getValue().value;
    } else if (this.state.notAChain) {
      enrichMerchantRequest.ChainName = 'NotAChain';
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.BusinessDescription)) {
      enrichMerchantRequest.BusinessDescription = this.state.BusinessDescription;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.CurrentBusinessStatus)) {
      enrichMerchantRequest.CurrentBusinessStatus = this.state.CurrentBusinessStatus;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.QueryTransactionProcess) && !this.isQueryTransactionProcessEmpty) {
      enrichMerchantRequest.QueryTransactionProcess = this.state.QueryTransactionProcess;
    }
    else {
      enrichMerchantRequest.QueryTransactionProcess = null;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.URLToDisputeProcessPage)) {
      enrichMerchantRequest.URLToDisputeProcessPage = this.state.URLToDisputeProcessPage;
    }
    if (this.state.forceCardAcceptorLocator) {
      enrichMerchantRequest.forceCardAcceptorLocator = this.state.forceCardAcceptorLocator;
    }
    if (this.state.forceSetNewPrimaryName) {
      enrichMerchantRequest.forceSetNewPrimaryName = this.state.forceSetNewPrimaryName;
    }
    if (this.state.forceSetOnlineMobile) {
      enrichMerchantRequest.forceSetOnlineMobile = this.state.forceSetOnlineMobile;
    }
    if (this.state.forceSetPrimaryURL) {
      enrichMerchantRequest.forceSetPrimaryURL = this.state.forceSetPrimaryURL;
    }
    if (this.state.forceSetPrimaryPhoneNumber) {
      enrichMerchantRequest.forceSetPrimaryPhoneNumber = this.state.forceSetPrimaryPhoneNumber;
    }
    if (this.state.forceSetPrimaryEmailAddress) {
      enrichMerchantRequest.forceSetPrimaryEmailAddress = this.state.forceSetPrimaryEmailAddress;
    }
    if (this.state.forceUpdateABN) {
      enrichMerchantRequest.forceUpdateABN = this.state.forceUpdateABN;
    }
    if (this.state.forceQueryTransactionProcess) {
      enrichMerchantRequest.forceQueryTransactionProcess = this.state.forceQueryTransactionProcess;
    }
    if (this.state.forceURLToDisputeProcessPage) {
      enrichMerchantRequest.forceURLToDisputeProcessPage = this.state.forceURLToDisputeProcessPage;
    }
    if (this.state.forceCurrentBusinessStatus) {
      enrichMerchantRequest.forceCurrentBusinessStatus = this.state.forceCurrentBusinessStatus;
    }
    if (this.state.forceChainInformation) {
      enrichMerchantRequest.forceChainInformation = this.state.forceChainInformation;
    }
    if (this.state.forceSetNewAddress) {
      enrichMerchantRequest.forceSetNewAddress = this.state.forceSetNewAddress;
    }
    if (this.state.forceAddDescription) {
      enrichMerchantRequest.forceAddDescription = this.state.forceAddDescription;
    }
    if (this.state.forceSetCategory) {
      enrichMerchantRequest.forceSetCategory = this.state.forceSetCategory;
    }
    if (this.state.TagsUpdated) {
      enrichMerchantRequest.Tags = this.state.Tags;
      enrichMerchantRequest.TagsUpdated = this.state.TagsUpdated;
    }
    else {
      enrichMerchantRequest.TagsUpdated = false;
    }
    if (this.state.DirectEntryCode && !this.directEntryCodeInvalid()) {
      enrichMerchantRequest.DirectEntryCode = this.state.DirectEntryCode;
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.state.BPayBillerCodes)) {
      enrichMerchantRequest.BPayBillerCodes = this.state.BPayBillerCodes;
    }

    return enrichMerchantRequest;
  }

  private getEmptyEnrichDataResult(): any {
    return {
      OverallResult: OverallResult.Success,
      Comment: '',
      GoogleSearch: {
        Result: ''
      },
      ASICScreenScrape: this.getEmptyResult(),
      LoadABR: this.getEmptyResult(),
      SensisSearch: this.getEmptyResult(),
      ClearbitSearch: this.getEmptyResult(),
      RitekitSearch: this.getEmptyResult(),
      NoSQLDBsLoad: this.getEmptyResult(),
      PredictedRecords: this.getEmptyResult(),
      CardAcceptorLocatorSet: this.getEmptyResult(),
      LoadAssociatesAndAKAs: this.getEmptyResult(),
      MastercardSearch: {
        OverrideMastercardExistanceCheck: false
      },
      HereMapsSearch: this.getEmptyResult(),
      MerchantWebsiteCapture: this.getEmptyResult(),
      LoadChainInformation: this.getEmptyResult(),
      ChainInformation: null,
      ValidationResult: undefined,
      NewPrimaryName: this.getEmptyResult(),
      OnlineMobile: this.getEmptyResult(),
      PrimaryWebAddress: this.getEmptyResult(),
      PrimaryPhoneNumber: this.getEmptyResult(),
      PrimaryEmailAddress: this.getEmptyResult(),
      PrimaryAddress: this.getEmptyResult(),
      AddDescription: this.getEmptyResult(),
      Category: this.getEmptyResult(),
      QueryTransactionProcess: this.getEmptyResult(),
      URLToDisputeProcessPage: this.getEmptyResult(),
      CurrentBusinessStatus: this.getEmptyResult(),
      BPayBillerCode: this.getEmptyResult()
    };
  }

  private setValidationReportText() {
    if (!this.state.enrichMerchantResult || !this.state.enrichMerchantResult.ValidationResult) {
      return;
    }
    switch (this.state.enrichMerchantResult.ValidationResult.Result) {
      case ValidateMerchantRecordResult.Error:
        this.validationReportText = this.state.enrichMerchantResult.ValidationResult.Errors.join(' ');
        break;
      case ValidateMerchantRecordResult.Warning:
        this.validationReportText = this.state.enrichMerchantResult.ValidationResult.Warnings.join(' ');
        break;
      case ValidateMerchantRecordResult.Passed:
        this.validationReportText = 'Passed';
        break;
      case ValidateMerchantRecordResult.NotExecuted:
        this.validationReportText = 'Not Executed';
        break;
    }
  }

  private getEmptyResult() {
    return {
      Result: '',
      Comments: ''
    };
  }

  private setEmptyResultsForNulls(results: AUS_Indexing_Result): AUS_Indexing_Result {
    if (!results.NewPrimaryName) {
      results.NewPrimaryName = this.getEmptyResult();
    }
    if (!results.OnlineMobile) {
      results.OnlineMobile = this.getEmptyResult();
    }
    if (!results.PrimaryWebAddress) {
      results.PrimaryWebAddress = this.getEmptyResult();
    }
    if (!results.PrimaryPhoneNumber) {
      results.PrimaryPhoneNumber = this.getEmptyResult();
    }
    if (!results.PrimaryEmailAddress) {
      results.PrimaryEmailAddress = this.getEmptyResult();
    }
    if (!results.PrimaryAddress) {
      results.PrimaryAddress = this.getEmptyResult();
    }
    if (!results.AddDescription) {
      results.AddDescription = this.getEmptyResult();
    }
    if (!results.Category) {
      results.Category = this.getEmptyResult();
    }
    if (!results.CurrentBusinessStatus) {
      results.CurrentBusinessStatus = this.getEmptyResult();
    }
    if (!results.QueryTransactionProcess) {
      results.QueryTransactionProcess = this.getEmptyResult();
    }
    if (!results.URLToDisputeProcessPage) {
      results.URLToDisputeProcessPage = this.getEmptyResult();
    }
    if (!results.Tags) {
      results.Tags = this.getEmptyResult();
    }
    if (!results.DirectEntryCode) {
      results.DirectEntryCode = this.getEmptyResult();
    }
    if (!results.Bsbs) {
      results.Bsbs = this.getEmptyResult();
    }
    if (!results.BPayBillerCodes) {
      results.BPayBillerCodes = this.getEmptyResult();
    }

    return results;
  }

  private validateOtherCategory() {
    if (this.state.selectedCategory.isSelected && this.state.selectedCategory.category.CategoryLevel === CategoryLevelNumber.Two
      && this.state.selectedCategory.category.CategoryName === 'Other' &&
      this.categoryComponent.categoryConfig.shouldDisplayCategories.get(CategoryLevelNumber.Three)) {
      let categoryLevelOne = this.categoryComponent.categoryConfig.selectedCategoryPerLevel.get(CategoryLevelNumber.One).CategoryName;

      this.dialogService.openDialog(`You must select a sub-category of ${categoryLevelOne} >>
        ${this.categoryComponent.categoryConfig.selectedCategoryPerLevel.get(CategoryLevelNumber.Two).CategoryName}
          or choose the Parent category ${categoryLevelOne}`);

      return false;
    }

    return true;
  }

  private configurelwcIdAndBsbValueChange() {
    this.lwcIdInput.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      ).subscribe((value) => {
        if (value) {
          if (this.merchantAuditComponent.last) {
            this.merchantAuditChange();
          }
          this.lookupMerchant();
          this.setRetailerAudits();
        }
      });

    this.bsbInputValue.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      ).subscribe((value) => {
        if (value) {
          if (this.bsbService.isValidValueForBsb(value)) {
            this.bsbInputIsValid = true;
            this.lookupMerchant();
          }
          else {
            this.bsbInputIsValid = false;
          }
        }
      });
  }

  private lookupMerchant() {
    this.isMerchantLoading = true;
    this.state.merchantLookupResponseFirstPart = null;
    this.state.merchantLookupResponseSecondPart = null;
    this.state.currentValue = {
      pName: null,
      address: null,
      url: null,
      aBN: null,
      number: null,
      email: null,
      URLToDisputeProcess: null,
      MerchantPresence: null,
      CurrentBusinessStatus: null,
      Chain: null,
      desc: null
    }
    this.lwcIdInputIsIncorrect = this.valueCheckerService.isEmptyNullOrUndefined(this.state.LWC_ID);
    let observable: Observable<MerchantSummary> = this.lwcIdInputIsIncorrect
      ? this.merchantlookupService.getCalsBasedOnBsb(this.state.BSB)
      : this.merchantlookupService.getCals(this.state.LWC_ID);

    let observable2: Observable<MerchantDetailedResponse> = this.merchantlookupService.getCalsByMerchantDetails(this.state.LWC_ID);

    observable.pipe(finalize(() => this.isMerchantLoading = false))
      .subscribe((response) => {
        this.state.merchantNotFound = (response.LWC_ID == null);
        this.lockButtonLabel = response.RecordLocked ? 'Unlock' : 'Lock';
        this.state.LockState = response.RecordLocked;
        this.assignSummaryData(response);
        this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
        this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
        this.disableChainSelectComponentIfNeeded();
        this.showHistoryToggle = true;
        this.showCommentsToggle = true;
        this.state.BPayBillerCodes = response.BPayBillerCodes;
        this.state.Tags = response.Tags;
        this.state.Bsbs = response.BSBs;
        this.state.TagsUpdated = false;
        this.fillSimilarCals(this.valueCheckerService.isEmptyNullOrUndefined(this.state.LWC_ID)
          ? this.state.merchantLookupResponseFirstPart.LWC_ID
          : this.state.LWC_ID);
      });

    observable2.pipe(finalize(() => this.isMerchantLoading = false)).subscribe((response) => {
      this.assignDetailsData(response);
    })

    this.enrichMerchantService.getAusIndexedComments(this.state.LWC_ID)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          for (let i of response) {
            if (i.IsActive === true) {
              this.ausIndexingCommentsResponse = response;
              if (this.ausIndexingCommentsResponse.length > 1) {
                this.showComments = true;
              } else {
                this.showComments = false;
              }
            }
          }
          for (let comment of this.ausIndexingCommentsResponse) {
            this.setDisplayValuesForAusIndexedComments(comment);
          }
        }
        else {
          this.ausIndexingCommentsResponse = [];
        }
      });


  }

  private fillSimilarCals(lwcId: number) {
    this.isMerchantLoading = true;
    this.merchantlookupService.getSimilarCals(lwcId).pipe(finalize(() => this.isMerchantLoading = false))
      .subscribe((responce) => {
        this.state.similarCalResponse = [];
        for (let value of responce) {
          let cal: EnrichMerchantSimilarCal = {
            cal: value
          }
          this.state.similarCalResponse.push(cal);
        }
      });
  }

  assignSummaryData(response: MerchantSummary) {
    this.primaryName = response.PrimaryName;
    this.primaryAddress = response.PrimaryAddress;
    this.primaryNumber = response.PhoneNumber;
  }

  assignDetailsData(response: MerchantDetailedResponse) {
    let name, address, number, email, url, description, chain, bpaycodes = [];
    for (let i of response.AUS_Indexed_MerchantNames) {
      if (i.IsActive === true && i.IsPrimary === true) {
        name = i.MerchantName;
      }
    }

    for (let i of response.AUS_Indexed_Addresses) {
      if (i.IsActive === true && i.IsPrimary === true) {
        address = i.FormattedAddressString;
      }
    }

    for (let i of response.AUS_Indexed_ContactMethods) {
      if (i.ContactType === 'URL' && i.IsActive === true && i.IsPrimary === true) {
        url = i.URL;
      }
      if (i.ContactType === 'EMAIL' && i.IsActive === true && i.IsPrimary === true) {
        email = i.DisplayValue;
      }

      if (i.ContactType === 'PHONE' && i.IsActive === true && i.IsPrimary === true) {
        number = i.DisplayValue;
      }
    }

    for (let i of response.AUS_Indexed_BusinessDescriptions) {
      if (i.IsActive === true && i.IsDefault === true) {
        description = i.Description;
      }
    }

    if (response.Chain !== null) {
      chain = response.Chain.ChainName;
    }

    for (let i of response.AUS_Indexed_BPAY_Biller_Codes) {
      if (i.BPayBillerCode != null && i.IsActive === true) {
        bpaycodes.push(i.BPayBillerCode);
      }
    }

    this.state.currentValue = {
      pName: name,
      address: address,
      number: number,
      email: email,
      url: url,
      aBN: response.ABN,
      URLToDisputeProcess: response.URLToDisputeProcessPage,
      MerchantPresence: response.MerchantPresence,
      CurrentBusinessStatus: response.CurrentBusinessStatus,
      Chain: chain,
      desc: description
    }
    this.state.BPayBillerCodes = bpaycodes;
  }

  private handleState() {
    this.state = {
      LWC_ID: <number>null,
      BSB: null,
      ABRData: false,
      ASICData: false,
      GoogleData: false,
      SensisData: false,
      SetCAL: false,
      ClearbitData: false,
      MastercardData: false,
      MerchantScreenshot: false,
      SetAssociatesAndAKAs: false,
      HereMapsData: false,
      RitekitLogo: false,
      OverrideMastercardExistanceCheck: false,
      TomTomData: false,
      NewPrimaryName: '',
      TransactionDescription: '',
      DefaultDataSourceName: '',
      IndexingTrigger: '',
      IndexerName: '',
      IndexerID: 543234,
      ChainName: '',
      WebAddress: '',
      MerchantAddress: '',
      PhoneNumber: '',
      ABN: null,
      QueryTransactionProcess: '',
      URLToDisputeProcessPage: '',
      CurrentBusinessStatus: '',
      BusinessDescription: '',
      PrimaryEmailAddress: '',
      isStreetViewNotAvailable: false,
      isNotALocation: false,
      MerchantPresence: MerchantPresence.Unspecified,
      chainValue: emptyOptionEntry,
      NoPhoneNumber: false,
      enrichMerchantResult: this.getEmptyEnrichDataResult(),
      locationNotTracked: null,
      notAChain: false,
      selectedCategory: {
        category: null,
        isSelected: false
      },
      merchantLookupResponseFirstPart: null,
      merchantLookupResponseSecondPart: null,
      merchantNotFound: false,
      LockState: false,
      similarCalResponse: null,
      TagsUpdated: false,
      Tags: [],
      DirectEntryCode: null,
      Bsbs: [],
      BPayBillerCodes: [],
      showAllCategories: false,
      auditCreateDate: this.dateTimeService.getSubtractDate(30, this.dateTimeService.dateTimeFormatYYYYMMDDDashes),
      currentValue: {
        pName: '',
        address: '',
        number: '',
        email: '',
        url: '',
        aBN: '',
        URLToDisputeProcess: '',
        MerchantPresence: '',
        CurrentBusinessStatus: '',
        Chain: '',
        desc: ''
      }
    };
    let cleanState = this.route.snapshot.params['cleanState'];
    if (!cleanState) {
      this.restoreState();
    }
    let lwcIdRouteParam = this.route.snapshot.params['lwcId'];
    if (lwcIdRouteParam) {
      this.state.LWC_ID = lwcIdRouteParam;
    }
  }

  private setDisplayValuesForAusIndexedComments(comment: AusIndexedCommentsDisplay) {
    comment.lastUpdated = this.dateTimeService.format(comment.lastUpdated, this.dateTimeService.dateTimeFormatYYYYMMDDhmmaDashes);
  }

  private setRetailerAudits() {
    this.retailerAuditService.getRetailerAudits(this.state.LWC_ID, this.state.auditCreateDate)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response && response.data && response.data.items.length > 0 && response.data.items[0].retailerAudits
          && response.data.items[0].retailerAudits.length > 0) {
          this.retailerAuditsDatatable = response.data.items[0].retailerAudits;
          for (let retailerAudit of this.retailerAuditsDatatable) {
            retailerAudit.createDateDisplay = this.dateTimeService.formatWithHours(retailerAudit.createDate);
          }
        }
        else {
          this.retailerAuditsDatatable = [];
        }
      });
  }
}
