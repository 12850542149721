import { ExecutionResult } from 'graphql';
import { NumberNameValue } from '../../core/types/numbernamevalue';

export let paymentMethodsResponse: ExecutionResult<NumberNameValue[]> = {
  data: [
    {
      name: "Unknown",
      value: 0
    },
    {
      name: "BPay",
      value: 1
    },
    {
      name: "EFTPOS",
      value: 2
    },
    {
      name: "NABVisaDebit",
      value: 3
    },
    {
      name: "ANZVisaDebit",
      value: 4
    },
    {
      name: "NABVisaPlatinumDebit",
      value: 5
    },
    {
      name: "DEFT",
      value: 6
    },
    {
      name: "NABVisaMiscDebit",
      value: 7
    },
    {
      name: "ATMUsingANZCard",
      value: 8
    },
    {
      name: "CBAPTag",
      value: 9
    },
    {
      name: "OtherExpense",
      value: 10
    },
    {
      name: "OtherIncome",
      value: 11
    },
    {
      name: "TapAndGo",
      value: 12
    },
    {
      name: "CUAVisa",
      value: 13
    },
    {
      name: "QTMBVisa",
      value: 14
    },
    {
      name: "INGVisa",
      value: 15
    },
    {
      name: "DirectDebit",
      value: 16
    },
    {
      name: "STGeorgeVisa",
      value: 17
    },
    {
      name: "AUSTBankVisa",
      value: 18
    },
    {
      name: "BOQVisaDebit",
      value: 19
    },
    {
      name: "DirectCredit",
      value: 20
    },
    {
      name: "InternetTransfer",
      value: 21
    },
    {
      name: "AMEX",
      value: 22
    },
    {
      name: "Osko",
      value: 23
    },
    {
      name: "HandyWay",
      value: 24
    },
    {
      name: "Cirrus",
      value: 25
    },
    {
      name: "Maestro",
      value: 26
    },
    {
      name: "Giro",
      value: 27
    },
    {
      name: "Visa",
      value: 28
    },
    {
      name: "Mastercard",
      value: 29
    },
    {
      name: "Card",
      value: 30
    },
    {
      name: "ATM",
      value: 31
    },
    {
      name: "Salary",
      value: 32
    },
    {
      name: "Dividend",
      value: 33
    }
  ]
};
