import { AusQuarantinedCal } from "../../components/quarantinedcals/ausquarantinedcals";

export let quarantinedCalsResponse: AusQuarantinedCal[] = [
    {
        OriginalSearchString: "SHIRE OF ESPERANCE DEP ESPERANCE WA",
        LWC_ID: 1000274686,
        ABN: "60034434085",
        ChainName: "Shire of Esperance",
        DataCompletnessScore: 100,
        IsQuarantinedDateTime: null,
        MerchantPresence: "Bricks & Mortar",
        MerchantType: "Head Office",
        IsQuarantinedComment: null,
        PrimaryName: "Shire of Esperance",
        PrimaryCategory: "Services >> Government >> Local",
        PrimaryAddress: "77 Windich St, Esperance WA 6450, Australia",
        LocatedIn_Name: null,
        LocatedIn_Id: null,
        StreetViewAvailable: false,
        Phone: "(08) 9071 0666",
        URL: "https://www.esperance.wa.gov.au/esperance-seafront-caravan-park",
        AllocatedToIndexerId: 3,
        CardAcceptorLocatorsID: 350
    },
    {
        OriginalSearchString: "REVIEW AUSTRALI CHADSTONE VIC",
        LWC_ID: 1000274686,
        ABN: "50122295836",
        ChainName: "Review Australia",
        DataCompletnessScore: 85,
        IsQuarantinedDateTime: null,
        MerchantPresence: "Bricks & Mortar",
        MerchantType: "Branch",
        IsQuarantinedComment: null,
        PrimaryName: "Review Australia (Chadstone)",
        PrimaryCategory: "Shopping >> Clothing",
        PrimaryAddress: "1341 Dandenong Rd, Chadstone VIC 3148, Australia",
        LocatedIn_Name: null,
        LocatedIn_Id: null,
        StreetViewAvailable: true,
        Phone: null,
        URL: "https://review-australia.com",
        AllocatedToIndexerId:2,
        CardAcceptorLocatorsID: 527
    },
    {
        OriginalSearchString: "BWT MITCHELL DARWIN NT",
        LWC_ID: 1000674837,
        ABN: "52142427521",
        ChainName: "Backpackers World Travel",
        DataCompletnessScore: 90,
        IsQuarantinedDateTime: null,
        MerchantPresence: "Bricks & Mortar",
        MerchantType: "Branch",
        IsQuarantinedComment: null,
        PrimaryName: "Backpackers World Travel (Darwin City)",
        PrimaryCategory: "Travel >> Travel Agent",
        PrimaryAddress: "Unit 23, 55 Mitchell St, Darwin City NT 0800, Australia",
        LocatedIn_Name: null,
        LocatedIn_Id: null,
        StreetViewAvailable: false,
        Phone: "(08) 8941 5100",
        URL: "http://www.backpackersworld.com",
        AllocatedToIndexerId: 145,
        CardAcceptorLocatorsID: 289
    },
    {
        OriginalSearchString: "KOALA AND TREE ESSEN ESSENDON VIC",
        LWC_ID: 1001151160,
        ABN: "54142365160",
        ChainName: "Koala Living",
        DataCompletnessScore: 98,
        IsQuarantinedDateTime: null,
        MerchantPresence: "Bricks & Mortar",
        MerchantType: "Branch",
        IsQuarantinedComment: null,
        PrimaryName: "Koala Living (Essendon Fields)",
        PrimaryCategory: "Shopping >> Furniture",
        PrimaryAddress: "100 Bulla Rd, Essendon Fields VIC 3041, Australia",
        LocatedIn_Name: null,
        LocatedIn_Id: null,
        StreetViewAvailable: true,
        Phone: "(03) 9988 2175",
        URL: "https://www.koalaliving.com.au",
        AllocatedToIndexerId: null,
        CardAcceptorLocatorsID: 357
    }
]
