import { DialogService } from './uiservices/dialog.service';
import { ApiClient } from './api/apiclient';
import { ApiService } from './api/api.service';
import { StringService } from './formatting/string.service';
import { NotificationService } from './uiservices/notification.service';
import { ErrorHandler } from './services/errorhandler';
import { LoadingService } from './uiservices/loading.service';
import { NgModule } from '@angular/core';
import { CalService } from './formatting/cal.service';
import { ODataService } from './api/odata/odata.service';
import { CollectionTransformerService } from './collections/collectiontransformer.service';
import { LoginStateService } from './auth/loginstate.service';
import { HotkeyInfoService } from './uiservices/hotkeyinfo.service';
import { CsvComponentService } from './export/csvcomponent.service';
import { ArrayService } from './collections/array.service';
import { ValueCheckerService } from './services/valuechecker.service';
import { BaseFormatService } from './formatting/baseformat.service';
import { FormatService } from './formatting/format.service';
import { MapService } from './collections/map.service';
import { QuillEditorService } from './formatting/quilleditor.service';

@NgModule({
  providers: [LoadingService, ErrorHandler, NotificationService,
    StringService, ApiService, ApiClient, DialogService, CalService,
    ODataService, CollectionTransformerService, LoginStateService,
    HotkeyInfoService, CsvComponentService, ArrayService, ValueCheckerService, BaseFormatService,
    FormatService, MapService, QuillEditorService],
})
export class CoreModule { }
