export interface Theme {
    EmailId?:string;
    Regions?:[];
    Id?: number;
    FirstName?: string;
    LastName?: string;
    Theme?:string;
    Status?:number;
    DateModified:Date;
    DateCreated?:Date;
  }

export interface Theme1 {
    name: string;
    properties: any;
}
export const defaultTheme: Theme1 = {
    name: "defaultTheme",
    properties: {
      "--primary-color" : "#3f51b5",
      "--danger-color" : "#f44336",
      "--refresh-icon-color" : "#3f51b5",
      "--mat-toolbar-color":"#3f51b5",
      "--mat-toolbar-text-color":"#ffff",
      "--mat-drawer-bg-color":"#fafafa",
      "--mat-drawer-color":"black",
      "--body-bg-color":"#FAFAFA",
      "--mat-btn-bg-color":"#3f51b5",
      "--mat-btn-disabled-bg-color":'#0000001f',
      "--mat-btn-color":"#ffff",
      "--mat-btn-disabled-color":"#00000042",
      "--mat-card-bg-color":"#ffff",
      "--mat-card-text-color":"#3d3d3d",
      "--mat-hint-color":"#00000099",
      "--mat-form-field-color" :"#888888",
      "--mat-form-field-color-focused" :"#3f51b5",
      "--mat-form-field-ripple-color-focused" :"#3f51b5",
      "--mat-form-field-invalid-text-color":"#9f2e2e",
      "--mat-header-li-bg-color":"#ffff", 
      "--mat-header-li-text-color":"#0000EE",  
      "--mat-extention-text-color":"#3d3d3d",
      "--mat-extention-panel-color":"#ffff",  
      "--sidenav-section-bg-color":"#ffff",
      "--sidenav-section-text-color":"#0000EE",
      "--clear-input-icon-bg-color":"#ffff",
      "--p-description-color" : "#00000099",
      "--mat-select-text-color" : "#888888",
      "--json-segment-key-color":"#4E187C",
      "--json-segment-value-color" : "#FF6B6B",
      "--datatable-bg-color" : "#ffff",
      "--default-text-color" :"black",
      "--snow-text-editor-icon-color":"#888888",
      "--checkbox-bg-color" : "#ffff",
      "--checkbox-label-color" :"#000000de",
      "--checkbox-border-color" : "#ff4081",
      "--mat-radio-inner-circle-color" : "#ff4081",
      "--mat-radio-outer-circle-color" : "#8787878a",
      "--mat-radio-btn-outer-border-color" :"#00000099",
      "--mat-radio-btn-disabled-color" : "#00000061",
      "--mat-icon-color" : "#8d8d8d",
      "--mat-dropdown-arrow-color" :"#0000008a",
      "--text-highlight-bg-color" : "#ffff00",
      "--search-text-color" : "#000000",
      "--card-shadow-color" : "#dbd8d8",
      "--expansion-panel-header-title-color" : "#000000de",
      "--mat-legacy-underline-color" : "#8d8d8d6b",
      "--input-form-field-outline-color" : "#a2a2a2",
      "--input-form-field-focused-outline-color" : "#3f51b5",
      "--mat-input-disabled-text-color" : "#00000061"
    }
};
export const dark: Theme1 = {
    name: "dark",
    properties: {
      "--primary-color" : "#3f51b5",
      "--danger-color" : "#f44336",
      "--refresh-icon-color" : "#ffff",
      "--mat-toolbar-color":"#272727",
      "--mat-toolbar-text-color":"#ffff",
      "--mat-drawer-bg-color":"#525252",
      "--mat-drawer-color":"#ffff",
      "--body-bg-color":"#FAFAFA",
      "--mat-btn-bg-color":"#9c27b0",
      "--mat-btn-color":"#ffff",
      "--mat-btn-disabled-bg-color":"#606060",
      "--mat-btn-disabled-color":"#cccccc42",
      "--mat-card-bg-color":"#272727",
      "--mat-card-text-color":"#ffff",
      "--mat-hint-color":"#d7d0d0",
      "--mat-form-field-color" :"#bababa",
      "--mat-form-field-color-focused" :"#d4cfcf",
      "--mat-form-field-ripple-color-focused" :"#3f51b5",
      "--mat-form-field-invalid-text-color":"#9f2e2e", 
      "--mat-header-li-bg-color":"#656565",
      "--mat-header-li-text-color":"#ffff",
      "--mat-extention-text-color":"#ffff",
      "--mat-extention-panel-color":"#7f7f7f",
      "--sidenav-section-bg-color":"#656565",
      "--sidenav-section-text-color":"#ffff",
      "--clear-input-icon-bg-color":"#424242",
      "--p-description-color" : "#ffff",
      "--mat-select-text-color" : "#ffff",
      "--json-segment-key-color":"#f5f5f5",
      "--json-segment-value-color" : "#ff4a92",
      "--datatable-bg-color" : "#ffff",      
      "--default-text-color" :"#888888",
      "--snow-text-editor-icon-color":"#888888",
      "--checkbox-bg-color" : "#c9c9c954",
      "--checkbox-label-color" :"#ffff",
      "--checkbox-border-color" : "#ff4081",
      "--mat-radio-inner-circle-color" : "#ff4081",
      "--mat-radio-outer-circle-color" : "#ffffff91",
      "--mat-radio-btn-outer-border-color" :"#00000099",
      "--mat-radio-btn-disabled-color" : "#dadada61",
      "--mat-icon-color" : "#ffff",
      "--mat-dropdown-arrow-color" :"#ffff",
      "--text-highlight-bg-color" : "#919191",
      "--search-text-color" : "#ffff",
      "--card-shadow-color" : "#5e5e5e",
      "--expansion-panel-header-title-color" : "#ffff",
      "--mat-legacy-underline-color" : "#ffff",
      "--input-form-field-outline-color" : "#d7d0d0",
      "--input-form-field-focused-outline-color" : "#d7d0d0",
      "--mat-input-disabled-text-color" : "#ffffff61"
    }
};
