import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { MerchantChainLogosState } from './merchantchainlogosstate';
import { ChainDto, AUS_Indexing_Result } from '../../models/ausindexingresult';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { MerchantLogoService } from '../../services/merchantlogoservice.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { MerchantSummaryFirstPart, MerchantSummarySecondPart } from '../mergemerchants/merchantsummary';
import { OverallResult } from '../../enums/enums';
import { IconResultService } from '../../core/uiservices/iconresult.service';
import { ChainSelectComponent } from '../../shared/chainselect/chainselect.component';
import { emptyString } from '../../core/constants/constants';

@Component({
  selector: 'app-merchantchainlogos',
  templateUrl: './merchantchainlogos.component.html',
  styleUrls: ['./merchantchainlogos.component.scss']
})

export class MerchantChainLogosComponent extends BaseStateComponent<MerchantChainLogosState> implements OnInit {
  chains: ChainDto[];
  squareLogo: File;
  circularLogo: File;
  threeToOneLogo: File;
  darkSquareLogo: File;
  darkCircularLogo: File;
  darkThreeToOneLogo: File;
  squareLogoInvalid: boolean = false;
  circularLogoInvalid: boolean = false;
  threeToOneLogoInvalid: boolean = false;
  darkSquareLogoInvalid: boolean = false;
  darkCircularLogoInvalid: boolean = false;
  darkThreeToOneLogoInvalid: boolean = false;
  merchantLookupResponseFirstPart: MerchantSummaryFirstPart = null;
  merchantLookupResponseSecondPart: MerchantSummarySecondPart = null;
  merchantChainLogoResult: Partial<AUS_Indexing_Result> = this.getEmptyMerchantChainLogosResult();
  imgURLSquareLogo: string;
  imgURLCircularLogo: string;
  imgURLThreeToOneLogo: string;
  imgURLDarkSquareLogo: string;
  imgURLDarkCircularLogo: string;
  imgURLDarkThreeToOneLogo: string;
  squareLogoWidth: number = 0;
  circularLogoWidth: number = 0;
  threeToOneLogoWidth: number = 0;
  darkSquareLogoWidth: number = 0;
  darkCircularLogoWidth: number = 0;
  darkThreeToOneLogoWidth: number = 0;

  @ViewChild('chainSelect') chainSelect: ChainSelectComponent;

  constructor(injector: Injector, private merchantlookupService: MerchantlookupService, private loadingService: LoadingService,
    private merchantService: MerchantLogoService, private notificationService: NotificationService,
    private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService,
    private iconResultService: IconResultService) {
    super(injector)
  }

  ngOnInit() {
    this.state = this.getEmptyState();
    this.getChains();
    this.restoreState();
  }

  onChainSelect(chainName: string) {
    if (!chainName) {
      this.state.chainLWCID = null;
      return;
    }

    let chain = this.chains.find((chain) => chain.ChainName == chainName);

    if (chain && chain.HQ_LWC_ID) {
      this.state.chainLWCID = chain.HQ_LWC_ID;
      this.state.LWC_ID = chain.HQ_LWC_ID;
    }
    this.onLwcId(this.state.LWC_ID);
  }

  isDisabledUpload() {
    if ((this.state.LWC_ID || this.state.chainLWCID)
    && (this.squareLogo || this.threeToOneLogo || this.circularLogo || this.darkSquareLogo || this.darkCircularLogo || this.darkThreeToOneLogo)
      && (!this.squareLogoInvalid && !this.circularLogoInvalid && !this.threeToOneLogoInvalid
        && !this.darkSquareLogoInvalid && !this.darkCircularLogoInvalid && !this.darkThreeToOneLogoInvalid)) {
      return false;
    }
    else {
      return true;
    }
  }

  onSquareLogoSelect(files: FileList) {
    this.squareLogoInvalid = false;
    this.squareLogo = files[0];
    let file = files[0];
    if (!file) {
      return;
    }
    if (!this.merchantService.logoTypeValid(file)) {
      this.squareLogoInvalid = true;
      return;
    }
    this.checkImageDimensions(this.squareLogo, (height, width) => {
      if (height > 128 || width > 128) {
        this.notificationService.notifyError("Image size should not exceed heigth 128 and width 128.");
        this.squareLogoInvalid = true;
      }
      this.squareLogoWidth = width;
      this.getImageDataUrl(file, (imageDataUrl) => {
        this.imgURLSquareLogo = imageDataUrl;
      });
    });
  }

  onCircularLogoSelect(files: FileList) {
    this.circularLogoInvalid = false;
    this.circularLogo = files[0];
    let file = files[0];

    if (!file) {
      return;
    }
    if (!this.merchantService.logoTypeValid(file)) {
      this.circularLogoInvalid = true;
      return;
    }
    this.checkImageDimensions(this.circularLogo, (height, width) => {
      if (height > 128 || width > 128) {
        this.notificationService.notifyError("Image size should not exceed heigth 128 and width 128.");
        this.circularLogoInvalid = true;
      }
      this.circularLogoWidth = width;
      this.getImageDataUrl(file, (imageDataUrl) => {
        this.imgURLCircularLogo = imageDataUrl;
      });
    });
  }

  onThreeToOneLogoSelect(files: FileList) {
    this.threeToOneLogoInvalid = false;
    this.threeToOneLogo = files[0];
    let file = files[0];

    if (!file) {
      return;
    }
    if (!this.merchantService.logoTypeValid(file)) {
      this.threeToOneLogoInvalid = true;
      return;
    }
    this.checkImageDimensions(this.threeToOneLogo, (height, width) => {
      if (height > 128 || width > 384) {
        this.notificationService.notifyError("Image size should not exceed heigth 128 and width 384.");
        this.threeToOneLogoInvalid = true;
      }
      this.threeToOneLogoWidth = width;
      this.getImageDataUrl(file, (imageDataUrl) => {
        this.imgURLThreeToOneLogo = imageDataUrl;
      });
    });
  }

  onDarkSquareLogoSelect(files: FileList) {
    this.darkSquareLogoInvalid = false;
    this.darkSquareLogo = files[0];
    let file = files[0];
    if (!file) {
      return;
    }
    if (!this.merchantService.logoTypeValid(file)) {
      this.darkSquareLogoInvalid = true;
      return;
    }
    this.checkImageDimensions(this.darkSquareLogo, (height, width) => {
      if (height > 128 || width > 128) {
        this.notificationService.notifyError("Image size should not exceed heigth 128 and width 128.");
        this.darkSquareLogoInvalid = true;
      }
      this.darkSquareLogoWidth = width;
      this.getImageDataUrl(file, (imageDataUrl) => {
        this.imgURLDarkSquareLogo = imageDataUrl;
      });
    });
  }

  onDarkCircularLogoSelect(files: FileList) {
    this.darkCircularLogoInvalid = false;
    this.darkCircularLogo = files[0];
    let file = files[0];
    if (!file) {
      return;
    }
    if (!this.merchantService.logoTypeValid(file)) {
      this.darkCircularLogoInvalid = true;
      return;
    }
    this.checkImageDimensions(this.darkCircularLogo, (height, width) => {
      if (height > 128 || width > 128) {
        this.notificationService.notifyError("Image size should not exceed heigth 128 and width 128.");
        this.darkCircularLogoInvalid = true;
      }
      this.darkCircularLogoWidth = width;
      this.getImageDataUrl(file, (imageDataUrl) => {
        this.imgURLDarkCircularLogo = imageDataUrl;
      });
    });
  }

  onDarkThreeToOneLogoSelect(files: FileList) {
    this.darkThreeToOneLogoInvalid = false;
    this.darkThreeToOneLogo = files[0];
    let file = files[0];
    if (!file) {
      return;
    }
    if (!this.merchantService.logoTypeValid(file)) {
      this.darkThreeToOneLogoInvalid = true;
      return;
    }
    this.checkImageDimensions(this.darkThreeToOneLogo, (height, width) => {
      if (height > 128 || width > 384) {
        this.notificationService.notifyError("Image size should not exceed heigth 128 and width 384.");
        this.darkThreeToOneLogoInvalid = true;
      }
      this.darkThreeToOneLogoWidth = width;
      this.getImageDataUrl(file, (imageDataUrl) => {
        this.imgURLDarkThreeToOneLogo = imageDataUrl;
      });
    });
  }

  uploadForm() {
    let lwcId = this.state.LWC_ID;
    if (!lwcId) {
      lwcId = this.state.chainLWCID;
    }
    this.loadingService.setLoading();
    this.merchantService.uploadLogos(lwcId, this.squareLogo, this.circularLogo, this.threeToOneLogo, this.darkSquareLogo, this.darkCircularLogo, this.darkThreeToOneLogo)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((result) => {
        this.setEmptyMerchantChainLogosResult(result);
        this.merchantChainLogoResult = result;
        this.onLwcId(this.state.LWC_ID);
        this.notificationService.notifySuccess();
      });
  }

  checkImageDimensions(file: File, validationFunc: (height: number, width: number) => void) {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      var image = new Image();
      (<any>image).src = e.target.result;
      image.onload = () => {
        const height = image.height;
        const width = image.width;
        validationFunc(height, width);
      }
    }
  }

  onLwcId(id: number) {
    if (id) {
      this.merchantlookupService.getCals(id)
        .subscribe((response) => {
          this.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
          this.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
        });
    }
  }

  getMerchantChainLogosResult() {
    if (this.merchantChainLogoResult.BuildMerchantRecordOutcome === null ||
      this.merchantChainLogoResult.BuildMerchantRecordOutcome === undefined) {
      return '';
    }

    let buildMerchantChainLogosResult = ' ';

    switch (this.merchantChainLogoResult.BuildMerchantRecordOutcome) {
      case OverallResult.Success: {
        buildMerchantChainLogosResult += 'Success; ';
        break;
      }
      case OverallResult.Error: {
        buildMerchantChainLogosResult += 'Error; ';
        break;
      }
      case OverallResult.Incomplete: {
        buildMerchantChainLogosResult += 'Incomplete; ';
        break;
      }
      case OverallResult.IndexingFailed: {
        buildMerchantChainLogosResult += 'Indexing Failed; ';
        break;
      }
      case OverallResult.AlreadyIndexed: {
        buildMerchantChainLogosResult += 'Already Indexed; ';
        break;
      }
    }

    if (this.merchantChainLogoResult.DataRichnessScore) {
      buildMerchantChainLogosResult += `Score: ${this.merchantChainLogoResult.DataRichnessScore}; `;
    }

    if (this.merchantChainLogoResult.ExecutionTimeInSeconds) {
      buildMerchantChainLogosResult += `Time: ${Math.round(this.merchantChainLogoResult.ExecutionTimeInSeconds)} sec.`;
    }
    return buildMerchantChainLogosResult;
  }

  getMerchantChainLogosResultClass(overallResult: OverallResult): string {
    let className = 'small-spacer inline-block ';

    switch (overallResult) {
      case OverallResult.Success: {
        className += 'success';
        break;
      }
      case OverallResult.Error: {
        className += 'danger';
        break;
      }
      default: {
        className += 'warning';
        break;
      }
    }
    return className;
  }

  getOutcomeIcon(result: boolean) {
    return this.iconResultService.getOutcomeIcon(result);
  }

  getOutcomeCssClass(result: boolean) {
    return this.iconResultService.getOutcomeCssClass(result);
  }

  private getEmptyResult() {
    return {
      Result: '',
      Comments: ''
    };
  }

  private getEmptyMerchantChainLogosResult() {
    return {
      DataRichnessScore: 0,
      ExecutionTimeInSeconds: 0,
      CircularLogoUpload: this.getEmptyResult(),
      SquareLogoUpload: this.getEmptyResult(),
      ThreeToOneLogoUpload: this.getEmptyResult(),
      DarkCircularLogoUpload: this.getEmptyResult(),
      DarkSquareLogoUpload: this.getEmptyResult(),
      DarkThreeToOneLogoUpload: this.getEmptyResult()
    }
  }

  private setEmptyMerchantChainLogosResult(results: AUS_Indexing_Result) {
    if (!results.CircularLogoUpload) {
      results.CircularLogoUpload = this.getEmptyResult();
    }
    if (!results.ThreeToOneLogoUpload) {
      results.ThreeToOneLogoUpload = this.getEmptyResult();
    }
    if (!results.SquareLogoUpload) {
      results.SquareLogoUpload = this.getEmptyResult();
    }
    if (!results.DarkCircularLogoUpload) {
      results.DarkCircularLogoUpload = this.getEmptyResult();
    }
    if (!results.DarkSquareLogoUpload) {
      results.DarkSquareLogoUpload = this.getEmptyResult();
    }
    if (!results.DarkThreeToOneLogoUpload) {
      results.DarkThreeToOneLogoUpload = this.getEmptyResult();
    }
  }

  private getImageDataUrl(file: File, callbakFunc: (imageDataUrl: string) => void) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      callbakFunc(<string>reader.result);
    }
  }

  resetForm(squareFile: any, circularFile: any, threeToOneFile: any, darkSquareFile: any, darkCircularFile: any, darkThreeToOneFile: any) {
    this.state = this.getEmptyState();
    this.merchantLookupResponseFirstPart = null;
    this.merchantLookupResponseSecondPart = null;
    this.merchantChainLogoResult = null;
    this.imgURLCircularLogo = null;
    this.imgURLSquareLogo = null;
    this.imgURLThreeToOneLogo = null;
    this.imgURLDarkSquareLogo = null;
    this.imgURLDarkCircularLogo = null;
    this.imgURLDarkThreeToOneLogo = null;
    squareFile.value = emptyString;
    circularFile.value = emptyString;
    threeToOneFile.value = emptyString;
    darkSquareFile.value = emptyString;
    darkCircularFile.value = emptyString;
    darkThreeToOneFile.value = emptyString;

    if (this.chainSelect) {
      this.chainSelect.setValue('');
    }
  }

  private getEmptyState(): MerchantChainLogosState {
    return {
      LWC_ID: null,
      chainLWCID: <number>null
    };
  }

  private getChains() {
    this.merchantlookupService.getChains()
      .subscribe(r => this.chains = r);
  }

}
