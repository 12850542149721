import { LoadingService } from './../../core/uiservices/loading.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { BrokenLinksService } from './../../services/brokenlinks.service';
import { Component, OnInit, Injector } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { NotificationService } from '../../core/uiservices/notification.service';
import { BrokenLinksState } from './brokenlinks.state';
import { CsvComponentService } from '../../core/export/csvcomponent.service';

@Component({
  selector: 'app-brokenlinks',
  templateUrl: './brokenlinks.component.html',
  styleUrls: ['./brokenlinks.component.scss']
})
export class BrokenLinksComponent extends BaseStateComponent<BrokenLinksState> implements OnInit {

  constructor(injector: Injector, private brokenLinksService: BrokenLinksService, private loadingService: LoadingService,
    private notificationService: NotificationService, private csvComponentService: CsvComponentService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      brokenLinks: []
    }
    this.getBrokenLinks();
  }

  getBrokenLinks() {
    this.loadingService.setLoading();
    this.brokenLinksService.getBrokenLinks()
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data) {
          this.state.brokenLinks = response.data.urlVerification;
        }
      });
  }

  startUrlScanner() {
    this.loadingService.setLoading();
    this.brokenLinksService.startUrlScanner().pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess();
      })
  }

  downloadAsCsv() {
    return this.csvComponentService.getCsvOptions(this.state.brokenLinks[0], 'BrokenLinks.csv');
  }
}
