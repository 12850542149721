import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { refreshApiKeysResponse } from "../mockjsonresponses/refreshapikeysresponse";
import { HttpMethod } from "../../core/coreenums/coreenums";

export function apiClientsRefreshBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];
    let includesrefreshAPIKeys = false;

    if(request.body && request.body.query) {
      includesrefreshAPIKeys = request.body.query.includes('refreshAPIKeys');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesrefreshAPIKeys) {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: {...refreshApiKeysResponse}
        }));

        resp.complete();
      });
    }
  }
