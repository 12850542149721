import { ApiClient } from "../core/api/apiclient";
import { Injectable } from "@angular/core";
import { ApiService } from "../core/api/api.service";
import { DataScraping as DataScraping, DataScrapingDetails } from "../components/managedatascraping/datascraping";
import { apiPathConstants } from "../core/api/apipathconstants";
import { Observable } from "rxjs";
import { Apollo, gql } from 'apollo-angular';
import { WebScrapingDetailResponse } from "../components/managedatascraping/viewdata/webscrapingdetail.component";
import { UnmatchedMerchantResponse } from "../components/managedatascraping/unmatchedmerchants/unmatchedmerchants.component";
import { unmatchedMerchantsQuery, eventing } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: "root"
})
export class DataScrapingService {
  private viewData = gql`
        query($chainCode: String!)
        {
            stores(chainCode: $chainCode)
            {
                id, chainCode, title, address, openingHours,
                suburb, phoneNumber, emailAddress, previousUpdateDate,
                lastUpdateDate, geoLat, geoLong, geoCodingResult, lwcId, matchedOn,
                catalogSearchResult
            }
        }`;

  private unmatchedMerchantsQuery = unmatchedMerchantsQuery;

  constructor(private apiClient: ApiClient, private apiService: ApiService, private apollo: Apollo) {
  }

  getManageDataScraping(): Observable<DataScraping[]> {
    return this.apiClient.get<DataScraping[]>(this.apiService.buildUrl(apiPathConstants.eventingChains));
  }

  getDataScrapingDetails(chainId: string): Observable<DataScrapingDetails> {
    return this.apiClient.get<DataScrapingDetails>(this.apiService.buildUrl(apiPathConstants.eventingChains, chainId));
  }

  saveDataScrapingDetails(editDataScraping: DataScrapingDetails) {
    return this.apiClient.post<DataScrapingDetails>(this.apiService.buildUrl(apiPathConstants.eventingChains), editDataScraping);
  }

  getViewData(chainId: string) {
    return this.apollo
      .use(eventing)
      .watchQuery<WebScrapingDetailResponse>({
        query: this.viewData,
        variables: {
          chainCode: chainId
        }
      })
      .valueChanges;

  }

  startScrape(chainId: string) {
    return this.apiClient.post(this.apiService.buildUrl(apiPathConstants.startScrape), { ChainId: chainId });
  }

  getUnmatchedMerchants(chainId: string) {
    return this.apollo
      .use(eventing)
      .watchQuery<UnmatchedMerchantResponse>({
        query: this.unmatchedMerchantsQuery,
        variables: {
          chainCode: chainId
        }
      })
      .valueChanges;
  }
}
