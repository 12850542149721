import { hotChocolate, updateRetailerWithLwcIdQuery } from './../gqlconstants/gqlconstants';
import { StringService } from './../core/formatting/string.service';
import { ValueCheckerService } from './../core/services/valuechecker.service';
import { emptyString, commaSpace } from './../core/constants/constants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { westfieldRetailerQuery } from "../gqlconstants/gqlconstants";
import { WestfieldRetailesResponse } from '../components/managewestfiledretailer/managewestfieldretailer.component';
import { UpdateRetailerWithLwcIdResponse } from '../components/managewestfiledretailer/managewestfieldretailer.models';

@Injectable({
  providedIn: "root"
})
export class WestfieldRetailerService {
  constructor(private apollo: Apollo, private valueCheckerService: ValueCheckerService, private stringService: StringService) {
  }

  getWestfieldRetailers(
    storesTitle: string,
    centresSuburb: string,
    centresState: string,
    skip: number,
    take: number,
    auditCreateDate: string,
    closeDate: string,
    showRecordsForMatchingAuditCriteria: boolean,
    showRecordsNotLinkedLWCIdControl: boolean
  ) {

    let query = westfieldRetailerQuery;
    let filters = emptyString;
    let closeDateString = emptyString;
    let orderByForCloseDate = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(storesTitle)) {
      filters = this.stringService.format(`{ storesTitle: { contains : "{0}"} }${commaSpace}`, storesTitle);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(centresSuburb)) {
      filters = filters + this.stringService.format(`{ centresSuburb: {eq: "{0}"} }${commaSpace}`, centresSuburb);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(centresState)) {
      filters = filters + this.stringService.format('{ centresState: {eq: "{0}"} }', centresState);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(showRecordsNotLinkedLWCIdControl) && showRecordsNotLinkedLWCIdControl === true) {
      filters = filters + this.stringService.format('{ lwcId: {eq: {0}} }', "null");
    }
    filters = filters + this.stringService.format('{ centresCountry: {eq: "{0}"} }', "Australia");
    if (!this.valueCheckerService.isEmptyNullOrUndefined(showRecordsForMatchingAuditCriteria) && showRecordsForMatchingAuditCriteria === true) {
      filters = filters + this.stringService.format('{retailerAudits : { some : { createDate: { gte: "{0}" } } }}', auditCreateDate);
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(closeDate)) {
      closeDateString = this.stringService.format('closeDate: "{0}",', closeDate);
      orderByForCloseDate = 'order: {sourceModifiedDate: ASC}';
    }
    if (filters === emptyString) {
      query = query.replace('where: { and: [ {2} ] },', emptyString);
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<WestfieldRetailesResponse>({
        query: gql`${this.stringService.format(query, auditCreateDate, closeDateString, filters, skip.toString(), take.toString(), orderByForCloseDate)}`
      })
      .valueChanges;
  }

  updateRetailerWithLwcId(retailerId: number, comment: string, lwcId: number, chainId: number, modifiedBy: number) {
    let query = updateRetailerWithLwcIdQuery;
    let variables = emptyString;

    variables = `${this.stringService.format('retailerId: {0}, ', retailerId.toString())}`;

    variables = variables + `${this.stringService.format('comment: "{0}", ', comment)}`;

    if (!this.valueCheckerService.isNullOrUndefined(lwcId)) {
      variables = variables + `${this.stringService.format('lwcId: {0}, ', lwcId.toString())}`;
    }
    if (!this.valueCheckerService.isNullOrUndefined(chainId)) {
      variables = variables + `${this.stringService.format('chainId: {0}, ', chainId.toString())}`;
    }

    variables = variables + `${this.stringService.format('modifiedBy: {0}', modifiedBy.toString())}`;

    return this.apollo
      .use(hotChocolate)
      .mutate<UpdateRetailerWithLwcIdResponse>({
        mutation: gql`${this.stringService.format(query, variables)}`
      });
  }
}
