import { Component, OnInit, Input, AfterViewInit, ElementRef, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-hotkeytooltip',
  templateUrl: './hotkeytooltip.component.html',
  styleUrls: ['./hotkeytooltip.component.scss']
})
export class HotkeyTooltipComponent implements OnInit, AfterViewInit {
  @Input()
  hotkeyLetter = '';

  @Input()
  hostElement: HTMLElement;

  @ViewChildren('tooltip') tooltipQueryList: QueryList<ElementRef>;

  isShown = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.tooltipQueryList.changes.subscribe(t => {
      if (t.first) {
        t.first.nativeElement.style.left = this.hostElement.offsetLeft + document.body.scrollLeft + 'px';
        t.first.nativeElement.style.top = this.hostElement.offsetTop + document.body.scrollTop - 20 + 'px';
      }
    });
  }

  show() {
    this.isShown = true;
  }

  hide() {
    this.isShown = false;
  }
}
