import { NgForm, FormControl } from '@angular/forms';
import { BaseStateComponent } from './../basestate/basestate.component';
import { LoadingService } from './../../core/uiservices/loading.service';
import { Component, OnInit, Injector, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TagsService } from '../../services/tags.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { TagAllocationPatternsService } from '../../services/tagallocationpatterns.service';
import { TagAllocationPatternsState } from './tagallocationpaterns.state';
import { FlatCategory } from '../../categories/flatcategory';
import { CategoryService } from '../../services/category.service';
import { TagAllocationPatternDisplay, TagAllocationPattern, TestTagAllocationPatternsRequest, TagAllocationPatternMatch } from './tagallocationpatterns.models';
import { ApiClientName, Tag } from '../tags/tags.models';
import { lookupTypeStringValuesWithSpaces, matchConfidenceStringValues } from '../../enums/enums';
import { NotificationService } from '../../core/uiservices/notification.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { CollectionTransformerService } from '../../core/collections/collectiontransformer.service';
import { SelectedCategory } from '../../shared/category/selectedcategory';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Observable } from 'rxjs';
import { CategoryMappingRulesService } from '../../services/categorymappingrules.service';
import { ApiClientDTO } from '../apiclients/apiclientdto';
import { ApiClientDataService } from '../apiclients/apiclientsdata.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-tagallocationpatterns',
  templateUrl: './tagallocationpatterns.component.html',
  styleUrls: ['./tagallocationpatterns.component.scss']
})
export class TagAllocationPatternsComponent extends BaseStateComponent<TagAllocationPatternsState> implements OnInit {
  originalTagAllocationPatterns: TagAllocationPattern[] = [];
  tagAllocationPatternsDisplay: TagAllocationPatternDisplay[] = [];
  tags: Tag[] = [];
  categories: FlatCategory[] = [];
  minAndMaxAmountTooltip = "The associated amount must be equal or more than this value for this rule to match. Leave blank to ignore";
  editingRowIndex: number = -1;
  lookupTypeStringValuesWithSpaces = this.collectionTransformerService.mapToArray(lookupTypeStringValuesWithSpaces);
  matchConfidenceStringValues = this.collectionTransformerService.mapToArray(matchConfidenceStringValues);
  originalRow: TagAllocationPatternDisplay;
  testTagAllocationPatternsRequest: TestTagAllocationPatternsRequest = {
    Amount: null,
    TextToMatch: "",
    ExistingCategoryId: -1,
    ExistingTagId: -1,
    ExistingCategoryDataSource: "",
    TagAllocationPatterns: [],
  };
  tagAllocationPatternMatch: TagAllocationPatternMatch;
  noMatchesMessage: string = "No Matches found!";
  tagAllocationPatternMessage: string = "";
  searchTagAllocationPatternsWasPerformed = false;
  defaultCategoryNameWidth = 150;
  categoryNameColumnWidth = this.defaultCategoryNameWidth;
  hidePriorityIcons: boolean = true;
  currentExistingTagID: number = null;
  currentExistingCategoryID: number = null;

  targetTagControl = new FormControl();
  filteredTargetTags: Observable<Tag[]>;
  categoryDataSources: string[] = [];
  filterByTagLabel: string;
  
  isTagMatchTheFilterArray: boolean[] = [];
  apiClientNames: ApiClientName[] = [];
  apiClients: ApiClientDTO[] = [];
  filteredApiClients: ApiClientDTO[] = [];
  filterByApiClientName: string;
  filteredApiClientNames: ApiClientName[] = [];
  listOfApiClientNames: string[] = [];
  isAdminRole: boolean;

  @ViewChild('tagAllocationPatternsRequestForm') tagAllocationPatternsRequestForm: NgForm;
  selectedClientName: any;
  filteredTagByClientName: string[]=[]

  constructor(injector: Injector, private tagsService: TagsService, private loadingService: LoadingService,
    private tagAllocationPatternsService: TagAllocationPatternsService, private categoryService: CategoryService,
    private notificationService: NotificationService, private valueCheckerService: ValueCheckerService,
    private collectionTransformerService: CollectionTransformerService, private cdRef: ChangeDetectorRef,
    private categoryMappingRuleService: CategoryMappingRulesService,
    private apiClientDataService: ApiClientDataService,
    private dateTimeService: DateTimeService) {
    super(injector);
  }

  ngOnInit() {   
    this.state = {
      tagId: null,
      tagLabel: ""
    };
    this.restoreState();
    this.setTags();
    this.setApiClients();
    this.setCategoryDataSources();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  handleTestTagLabelChanges(matOptionSelectionChange: MatOptionSelectionChange) {
    if (!matOptionSelectionChange.source.value) {
      this.testTagAllocationPatternsRequest.ExistingTagId = -1;
    }
  }

  handleTagAllocationPatternCategorySelect(selectedCategory: SelectedCategory, rowIndex: number) {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(selectedCategory.category)) {
      this.tagAllocationPatternsDisplay[rowIndex].CategoryName = selectedCategory.category.CategoryName;
      this.tagAllocationPatternsDisplay[rowIndex].ExistingCategoryID = selectedCategory.category.CategoryID;
    }
    else {
      this.tagAllocationPatternsDisplay[rowIndex].CategoryName = "";
      this.tagAllocationPatternsDisplay[rowIndex].ExistingCategoryID = null;
    }
    this.setValueForHidePriorityIcons(this.tagAllocationPatternsDisplay[rowIndex]);
  }

  handleTestCategorySelect(selectedCategory: SelectedCategory) {
    this.testTagAllocationPatternsRequest.ExistingCategoryId = !this.valueCheckerService.isEmptyNullOrUndefined(selectedCategory.category)
      ? selectedCategory.category.CategoryID
      : -1;
  }

  setTagAllocationPatternMatch() {
    this.loadingService.setLoading();
    for (let tagAllocationPattern of this.tagAllocationPatternsDisplay) {
      let tagAllocationPatterns: TagAllocationPattern = {
        TagAllocationPatternID: tagAllocationPattern.TagAllocationPatternID,
        ExistingCategoryID: tagAllocationPattern.ExistingCategoryID,
        ExistingTagID: tagAllocationPattern.ExistingTagID,
        ExistingCategoryDataSource: tagAllocationPattern.ExistingCategoryDataSource,
        NewTagID: tagAllocationPattern.NewTagID,
        SearchString: tagAllocationPattern.SearchString,
        LookupType: tagAllocationPattern.LookupType,
        MatchConfidence: tagAllocationPattern.MatchConfidence,
        Priority: tagAllocationPattern.Priority,
        MinAmountInclusive: tagAllocationPattern.MinAmountInclusive,
        MaxAmountInclusive: tagAllocationPattern.MaxAmountInclusive,
      };
      this.testTagAllocationPatternsRequest.TagAllocationPatterns.push(tagAllocationPatterns);
    }
    this.tagAllocationPatternsService.getTagAllocationPatternMatch(this.testTagAllocationPatternsRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && !this.valueCheckerService.isEmptyNullOrUndefined(response.NewTagId)) {
          this.tagAllocationPatternMatch = response;

          let existingCategory = this.categories.find(t => t.CategoryID === response.ExistingCategoryId);
          let existingCategoryName = existingCategory !== undefined ? existingCategory.CategoryName : response.ExistingCategoryId.toString() + " (can't find name)";

          let existingTag = this.tags.find(t => t.id === response.ExistingTagId);
          let existingTagLabel = existingTag !== undefined ? existingTag.tagLabel : response.ExistingTagId.toString() + " (can't find label)";

          let newTag = this.tags.find(t => t.id === response.NewTagId);
          let newTagLabel: string = newTag !== undefined ? newTag.tagLabel : response.NewTagId.toString() + " (can't find label)";

          this.tagAllocationPatternMessage = `Transaction "${response.SearchString}" with Category "${existingCategoryName}" with Tag "${existingTagLabel}" will match to a new tag "${newTagLabel}"`;
        }
        else {
          this.tagAllocationPatternMessage = this.noMatchesMessage;
        }
      });
  }

  isTestButtonDisabled() {
    return !this.tagAllocationPatternsRequestForm || this.tagAllocationPatternsRequestForm.invalid;
  }

  editRow(rowIndex: number) {
    this.editingRowIndex = rowIndex;
    this.originalRow = {...this.tagAllocationPatternsDisplay[rowIndex]};
    this.categoryNameColumnWidth = 191;
  }

  saveChanges(row: TagAllocationPatternDisplay, rowIndex: number) {
    this.categoryNameColumnWidth = this.defaultCategoryNameWidth;
    let upsertTagAllocationPatternRequest = this.getUpsertTagAllocationPatternRequest(row);
    let tagAllocationPatternWithSameParameters = this.tagAllocationPatternsDisplay
      .filter(t =>
        t.ExistingCategoryID === upsertTagAllocationPatternRequest.ExistingCategoryID
        && t.ExistingTagID === upsertTagAllocationPatternRequest.ExistingTagID
        && t.NewTagID === upsertTagAllocationPatternRequest.NewTagID
        && t.SearchString === upsertTagAllocationPatternRequest.SearchString
        && t.LookupType === upsertTagAllocationPatternRequest.LookupType
        && t.MinAmountInclusive === upsertTagAllocationPatternRequest.MinAmountInclusive
        && t.MaxAmountInclusive === upsertTagAllocationPatternRequest.MaxAmountInclusive);

    if (tagAllocationPatternWithSameParameters.length > 1) {
      this.notificationService.notifyError("Tag allocation pattern with same Category Name, Tag Label, Target Tag Label, Search String, Lookup Type, Min Amount Inclusive, Max Amount Inclusive already exists.");
      return;
    }

    this.loadingService.setLoading();
    let tagAllocationPatterns = this.tagAllocationPatternsDisplay.filter(t => t.TagAllocationPatternID !== row.TagAllocationPatternID);

    if (tagAllocationPatterns.length > 0) {
      for (let pattern of tagAllocationPatterns) {
        let original = this.originalTagAllocationPatterns.find(t => t.TagAllocationPatternID === pattern.TagAllocationPatternID);

        if (original !== undefined && pattern.Priority !== original.Priority) {
          this.tagAllocationPatternsService.saveChanges(pattern)
            .subscribe((result) => {
              let indexOfOriginalRow = this.originalTagAllocationPatterns.indexOf(original);
              this.originalTagAllocationPatterns[indexOfOriginalRow] = result;
            });
        }
      }
    }

    this.upsertTagAllocationPattern(upsertTagAllocationPatternRequest, rowIndex, row);
  }

  cancel(rowIndex: number) {
    if (this.tagAllocationPatternsDisplay[rowIndex].NewRecord === true) {
      this.tagAllocationPatternsDisplay.splice(rowIndex, 1);
      this.tagAllocationPatternsDisplay = [...this.tagAllocationPatternsDisplay];
    }
    else if (!this.tagAllocationPatternsDisplay[rowIndex].NewRecord || this.tagAllocationPatternsDisplay[rowIndex].NewRecord === false) {
      this.tagAllocationPatternsDisplay[rowIndex] = this.originalRow;
      this.tagAllocationPatternsDisplay = [...this.tagAllocationPatternsDisplay];
    }
    this.editingRowIndex = -1;
    this.categoryNameColumnWidth = this.defaultCategoryNameWidth;
    this.currentExistingTagID = null;
    this.currentExistingCategoryID = null;

    for (let originalRow of this.originalTagAllocationPatterns) {
      let tagAllocationPattern = this.tagAllocationPatternsDisplay.find(t => t.TagAllocationPatternID == originalRow.TagAllocationPatternID);
      if (tagAllocationPattern !== undefined) {
        tagAllocationPattern.Priority = originalRow.Priority;
      }
    }
  }

  changePriority(oldIndex: number, isUp: boolean) {
    let currentRow: TagAllocationPatternDisplay = this.tagAllocationPatternsDisplay[oldIndex];
    let identicalRecords: TagAllocationPatternDisplay[] = this.tagAllocationPatternsDisplay.filter(t =>
      t.ExistingCategoryID === currentRow.ExistingCategoryID && t.ExistingTagID === currentRow.ExistingTagID);

    let maxPriority = identicalRecords.length;

    if ((currentRow.Priority < maxPriority && isUp) || (currentRow.Priority > 1 && !isUp)) {
      let nextItem = isUp
        ? identicalRecords.sort((a, b) => a.Priority - b.Priority).find(r => r.Priority > currentRow.Priority)
        : identicalRecords.sort((a, b) => b.Priority - a.Priority).find(r => r.Priority < currentRow.Priority);

      if (nextItem) {
        let tempPriority = nextItem.Priority;
        nextItem.Priority = currentRow.Priority;
        currentRow.Priority = tempPriority;
        this.sortTagAllocationPatterns();
        this.editingRowIndex = this.tagAllocationPatternsDisplay.indexOf(currentRow);
      }
    }
  }

  addNewRecord() {
    let newRecord: TagAllocationPatternDisplay = {
      CategoryName: "",
      TagAllocationPatternID: null,
      ExistingCategoryID: -1,
      ExistingTagID: -1,
      ExistingCategoryDataSource: "",
      NewTagID: this.valueCheckerService.isNullOrUndefined(this.state.tagId) ? null : this.tags.find(t => t.id === this.state.tagId).id,
      SearchString: "",
      LookupType: null,
      MatchConfidence: null,
      Priority: 1,
      MinAmountInclusive: null,
      MaxAmountInclusive: null,
      NewRecord: true,
      TagLabel: "",
    };
    this.tagAllocationPatternsDisplay.unshift(newRecord);
    this.tagAllocationPatternsDisplay = [...this.tagAllocationPatternsDisplay];
    this.editingRowIndex = 0;
    this.categoryNameColumnWidth = 191;
    this.setValueForHidePriorityIcons(newRecord);
  }

  deleteRecord(tagAllocationPatternDisplay: TagAllocationPatternDisplay, rowIndex: number) {
    if (tagAllocationPatternDisplay.TagAllocationPatternID) {
      this.loadingService.setLoading();
      this.tagAllocationPatternsService.deleteRecord(tagAllocationPatternDisplay.TagAllocationPatternID)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(() => {
          this.tagAllocationPatternsDisplay.splice(rowIndex, 1);
          this.tagAllocationPatternsDisplay = [...this.tagAllocationPatternsDisplay];
          this.notificationService.notifySuccess("Record deleted successfully");
        });
    }
  }

  setTagPatterns() {
    this.loadingService.setLoading();
    this.tagAllocationPatternsService.getTagPatterns(this.state.tagId)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          if (response.length > 0) {
            for (let tagAllocationPattern of response) {
              this.originalTagAllocationPatterns.push({...tagAllocationPattern});
              this.tagAllocationPatternsDisplay.push({...tagAllocationPattern});
            }
          }
          else {
            this.originalTagAllocationPatterns = [];
            this.tagAllocationPatternsDisplay = [];
          }
          this.setLabelsAndLookupTypesAndMatchConfidences();
          this.sortTagAllocationPatterns();
        }
        this.searchTagAllocationPatternsWasPerformed = true;
      });
  }

  setValueForHidePriorityIcons(row: TagAllocationPatternDisplay) {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.currentExistingTagID) &&
      !this.valueCheckerService.isEmptyNullOrUndefined(this.currentExistingCategoryID) &&
      this.currentExistingTagID === row.ExistingTagID &&
      this.currentExistingCategoryID === row.ExistingCategoryID) {
      return;
    }

    let tagAllocationPatternsWithSameProperties = this.originalTagAllocationPatterns.filter(t => t.TagAllocationPatternID !== row.TagAllocationPatternID && t.ExistingTagID === row.ExistingTagID && t.ExistingCategoryID === row.ExistingCategoryID);
    let originalTagAllocationPattern = this.originalTagAllocationPatterns.find(t => t.TagAllocationPatternID === row.TagAllocationPatternID && t.ExistingTagID === row.ExistingTagID && t.ExistingCategoryID === row.ExistingCategoryID);
    if (tagAllocationPatternsWithSameProperties.length > 0 && originalTagAllocationPattern !== undefined) {
      this.hidePriorityIcons = false;
      return;
    }

    if (this.tagAllocationPatternsDisplay.length === 0) {
      this.hidePriorityIcons = true;
      return;
    }

    if (this.tagAllocationPatternsDisplay.length > 0) {
      let tagAllocationPattern = this.originalTagAllocationPatterns
        .filter(t => t.TagAllocationPatternID !== row.TagAllocationPatternID
          && t.ExistingCategoryID === row.ExistingCategoryID
          && t.ExistingTagID === row.ExistingTagID);
      if (tagAllocationPattern.length > 0) {
        this.hidePriorityIcons = false;
        row.Priority = Math.max(...tagAllocationPattern.map(t => t.Priority)) + 1;
        this.sortTagAllocationPatterns();
        this.editingRowIndex = this.tagAllocationPatternsDisplay.indexOf(row);
      }
      else {
        this.hidePriorityIcons = true;
        row.Priority = 1;
      }
    }

    this.currentExistingTagID = row.ExistingTagID;
    this.currentExistingCategoryID = row.ExistingCategoryID;
  }

  handleTagAllocationPatternTagLabelSelect(row: TagAllocationPatternDisplay, rowIndex: number) {
    let tag = this.tags.find(t => t.id === row.ExistingTagID);
    this.tagAllocationPatternsDisplay[rowIndex].TagLabel = tag != undefined ? tag.tagLabel : "";
    this.setValueForHidePriorityIcons(row);
  }

  handleClearInputForClient(){
    this.getDefaultTagList()
  }
  handleClearInputForTargetTag() {
    this.state.tagId = null;     
  }
  getDefaultTagList(){
    for (let tag of this.tags) {   
      this.filteredTagByClientName.push(tag.tagLabel)            
    }
  }

  private setCategoryDataSources() {
    this.loadingService.setLoading();
    this.categoryMappingRuleService.getCategoryDataSources()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.length > 0) {
          this.categoryDataSources = response;
          this.categoryDataSources.sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
          });
        }
      });
  }

  private setTags() {
    this.loadingService.setLoading();
    this.tagsService.getTags()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.tags = response;  
          this.getDefaultTagList()
          // this.filteredTargetTags = this.targetTagControl.valueChanges.pipe(
          //   startWith(null),
          //   map((value: string) => value ? this.filterTargetTagLabels(value) : this.tags.slice()));
        }
      });
  }

  private setApiClients() {
    this.loadingService.setLoading();
    this.apiClients = [];
    this.filteredApiClients = [];
    this.apiClientDataService.getApiClients(true)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(responce => {
        this.loadingService.clearLoading();
        if (responce && responce.data && responce.data && responce.data.length > 0) {
          this.apiClients = responce.data;
          this.filteredApiClients = this.filterDublicates(responce.data);
        }
      });
  }
  selectedClient(event:any){
    this.selectedClientName= event.option.value
    let apiClients= this.apiClients.find(client => client.clientName === this.selectedClientName);
    let clientID = apiClients.clientId
    this.filteredTagByClientName = []
    for (let tag of this.tags) {            
      for (let i = 0; i < tag.apiClientTag.length; i++) {
        if(tag.apiClientTag[i].apiClientId===clientID){
          this.filteredTagByClientName.push(tag.tagLabel)
        }        
      }
      
    }
  }

  private sortTagAllocationPatterns() {
    this.tagAllocationPatternsDisplay.sort((a, b) => {
      if (a.CategoryName < b.CategoryName) return -1;
      if (a.CategoryName > b.CategoryName) return 1;

      if (a.TagLabel < b.TagLabel) return -1;
      if (a.TagLabel > b.TagLabel) return 1;

      if (a.Priority < b.Priority) return 1;
      if (a.Priority > b.Priority) return -1;
    });
    this.tagAllocationPatternsDisplay = [...this.tagAllocationPatternsDisplay];
  }

  private setLabelsAndLookupTypesAndMatchConfidences() {
    this.categoryService.getFlatCategories().subscribe(categories => {
      this.categories = [];
      this.categories = categories;
      for (let tagPattern of this.tagAllocationPatternsDisplay) {
        let category = this.categories.find(t => t.CategoryID === tagPattern.ExistingCategoryID);
        tagPattern.CategoryName = category != undefined ? category.CategoryName : "";

        let tag = this.tags.find(t => t.id === tagPattern.ExistingTagID);
        tagPattern.TagLabel = tag != undefined ? tag.tagLabel : "";

        let newTag = this.tags.find(t => t.id === tagPattern.NewTagID);
        tagPattern.NewTagLabel = newTag != undefined ? newTag.tagLabel : "";

        tagPattern.LookupTypeDisplay = lookupTypeStringValuesWithSpaces.get(tagPattern.LookupType);
        tagPattern.MatchConfidenceDisplay = matchConfidenceStringValues.get(tagPattern.MatchConfidence);
      }
    });
  }

  private upsertTagAllocationPattern(upsertTagAllocationPatternRequest: TagAllocationPattern, rowIndex: number, row: TagAllocationPatternDisplay) {
    this.tagAllocationPatternsService.saveChanges(upsertTagAllocationPatternRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((result) => {
        if (this.tagAllocationPatternsDisplay[rowIndex].NewRecord) {
          this.tagAllocationPatternsDisplay[rowIndex].NewRecord = false;
          this.tagAllocationPatternsDisplay[rowIndex] = result;
          this.setLabelsAndLookupTypesAndMatchConfidences();
          this.originalTagAllocationPatterns.push({...this.tagAllocationPatternsDisplay[rowIndex]});
        }
        else {
          let originalTagAllocationPattern = this.originalTagAllocationPatterns.find(t => t.TagAllocationPatternID === row.TagAllocationPatternID);
          let indexOfOriginalTagAllocationPattern = this.originalTagAllocationPatterns.indexOf(originalTagAllocationPattern);
          this.originalTagAllocationPatterns[indexOfOriginalTagAllocationPattern] = row;
        }
        let newTag = this.tags.find(t => t.id === row.NewTagID);
        this.tagAllocationPatternsDisplay[rowIndex].NewTagLabel = newTag != undefined ? newTag.tagLabel : "";

        this.tagAllocationPatternsDisplay[rowIndex].LookupTypeDisplay = lookupTypeStringValuesWithSpaces.get(row.LookupType);
        this.tagAllocationPatternsDisplay[rowIndex].MatchConfidenceDisplay = matchConfidenceStringValues.get(row.MatchConfidence);
        this.tagAllocationPatternsDisplay = [...this.tagAllocationPatternsDisplay];
        this.editingRowIndex = -1;
        this.currentExistingTagID = null;
        this.currentExistingCategoryID = null;
        this.notificationService.notifySuccess("Saved");
      });
  }

  private getUpsertTagAllocationPatternRequest(row: TagAllocationPatternDisplay): TagAllocationPatternDisplay {
    return {
      TagAllocationPatternID: row.TagAllocationPatternID ? row.TagAllocationPatternID : null,
      ExistingCategoryID: row.ExistingCategoryID ? row.ExistingCategoryID : -1,
      ExistingTagID: row.ExistingTagID ? row.ExistingTagID : -1,
      ExistingCategoryDataSource: row.ExistingCategoryDataSource,
      NewTagID: row.NewTagID,
      SearchString: row.SearchString,
      LookupType: row.LookupType,
      MatchConfidence: row.MatchConfidence,
      Priority: row.Priority,
      MinAmountInclusive: row.MinAmountInclusive,
      MaxAmountInclusive: row.MaxAmountInclusive
    };
  }

  handleClearInputForTagLabel() {
    this.filterByTagLabel = '';
  }

  setFilterByTagLabel(event: KeyboardEvent) {
    this.filterByTagLabel = (<HTMLInputElement>event.target).value;
  }
 
  setFilterByApiClientName(event: MatSelectChange) {
    this.filterByApiClientName = event.value;    
  }

  targetTagSelected(event: any) {
    this.filterByTagLabel= event.option.value    
    this.state.tagId = this.tags.find(t => t.tagLabel === this.filterByTagLabel).id;
  }
 
  private filterApiClientsWithSameGroupClientName(apiClients: ApiClientDTO[]) {
    let filteredApiClients: ApiClientDTO[] = [];
    for (let apiClient of apiClients) {
      if (apiClient.apiClientGroup && apiClient.apiClientGroup.clientName) {
        let apiClientsWithSameClientName = apiClients.filter(t => !this.valueCheckerService.isEmptyNullOrUndefined(t.apiClientGroup)
          && t.apiClientGroup.clientName === apiClient.apiClientGroup.clientName);

        apiClients = apiClients.filter(t => !this.valueCheckerService.isEmptyNullOrUndefined(t.apiClientGroup)
          && t.apiClientGroup.clientName != apiClient.apiClientGroup.clientName);

        if (apiClientsWithSameClientName.length > 1) {
          apiClientsWithSameClientName.sort((a, b) => {
            return this.dateTimeService.compareDatesAsc(a.createDate.trim(), b.createDate.trim());
          });
          apiClientsWithSameClientName.splice(1);
        };
        if (apiClientsWithSameClientName.length > 0) {
          filteredApiClients.push(apiClientsWithSameClientName[0]);
        };
      }
    }
    return filteredApiClients;
  }

  private filterApiClientsWithSameClientCode(apiClients: ApiClientDTO[]) {
    let filteredApiClients: ApiClientDTO[] = [];
    for (let apiClient of apiClients) {
      let apiClientsWithSameClientCode = apiClients.filter(t => t.clientCode === apiClient.clientCode);

      apiClients = apiClients.filter(t => t.clientCode != apiClient.clientCode);
      if (apiClientsWithSameClientCode.length > 1) {
        apiClientsWithSameClientCode.sort((a, b) => {
          return this.dateTimeService.compareDatesAsc(a.createDate.trim(), b.createDate.trim());
        });
        apiClientsWithSameClientCode.splice(1);
      };
      if (apiClientsWithSameClientCode.length > 0) {
        filteredApiClients.push(apiClientsWithSameClientCode[0]);
      };
    }

    return filteredApiClients;
  }
  private filterDublicates(apiClients: ApiClientDTO[]) {
    let filteredApiClients: ApiClientDTO[] = this.filterApiClientsWithSameClientCode(apiClients);
    filteredApiClients = this.filterApiClientsWithSameGroupClientName([...filteredApiClients]);
    return filteredApiClients;
  }
 
}
