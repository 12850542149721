import { Component, EventEmitter, Output, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { DialogService } from '../../core/uiservices/dialog.service';
import { ApiInfoService } from '../../services/apiinfo.service';
import { AboutDialogComponent } from '../../aboutdialog/aboutdialog.component';
import { environment } from '../../../environments/environment';
import { AboutDialogData } from '../../aboutdialog/aboutdialogdata';
import { NotificationService } from '../../core/uiservices/notification.service';
import { UserService } from '../../users/applicationuser.service';
import { RegionType } from '../../users/userenums';
import { RegionStateService } from '../../core/cache/regionstate.service';
import { NameValue } from '../../core/models/namevalue';
import { CategoryService } from '../../services/category.service';
import { distinctUntilChanged, forkJoin } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { ThemeManagerService } from '../../services/theme-manager.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit ,AfterViewInit{
  @Output() toggleSidenav = new EventEmitter<void>();
  themeLabel:string=''
  currentRegion: NameValue<RegionType>;
  regionOptions: NameValue<RegionType>[] = [];
  userGreeting = '';
  showChainsIcon: boolean;
  chainsRefreshing: boolean;
  chainsIcon = '\u26D3';
  disabled = false;

  @Output() userTheme:string;
  email:any=''
  firstName:any=''
  lastName:any=''
  updatedTheme:any=''
  updatedDate:Date= new Date()
  regions:[]=[]
  status:number=null
  userId:number=null
  constructor(private dialogService: DialogService, private apiInfoService: ApiInfoService,
    private categoryService: CategoryService, private notificationService: NotificationService,
    private userService: UserService, private regionService: RegionStateService,
    private authService: AuthService, private merchantlookupService: MerchantlookupService,
    private themeService: ThemeManagerService, private renderer: Renderer2
    ) { }

  @ViewChild('darkModeSwitch', { read: ElementRef }) element: ElementRef | undefined;

  signOut() {
    this.authService.logOut();
  }
  
  isThemeDark:boolean=false
  toggleTheme(checked:any) {
    if(checked.checked){
      this.isThemeDark=true
      sessionStorage.setItem('theme','Dark');
      this.themeLabel='Dark'
      this.setIcon();
      this.darkTheme()    
      this.setUserTheme()  
    }else{
      this.isThemeDark=false
      sessionStorage.setItem('theme','Default');
      this.themeLabel='Default'
      this.setIcon();
      this.defaultTheme()
      this.setUserTheme()
    }
    
  }
  
  themeObj:any={}
  setUserTheme(){
    this.updatedTheme = sessionStorage.getItem('theme')
    this.themeObj={
      "Status": this.status,
      "EmailId": this.email,
      "DateModified": this.updatedDate,
      "Regions": this.regions,
      "Id": this.userId,
      "FirstName": this.firstName,
      "LastName": this.lastName,
      "Theme": this.updatedTheme
    }
  
    this.themeService.updateUserTheme(this.themeObj).subscribe(result=>{})
  }
  
  ngOnInit() {
   
    this.merchantlookupService.chainsRefreshing.subscribe(value => {
      this.showChainsIcon = true;
      this.chainsRefreshing = value;
    });
    
    this.regionService.regionValueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      this.changeCurrentRegion(this.regionService.getRegionNameValue(value));
    });

    let user = this.authService.getCurrentUser();    
    this.email = user.email    
    this.userGreeting = `Hello, ${user.name}`;
    if (user.userId) {
      this.getUserRegion(user.userId);
    }
    
    this.themeService.getUser(this.email).subscribe(result=>{
      this.firstName=result.FirstName
      this.lastName=result.LastName
      this.userTheme= result.Theme
      this.themeLabel=this.userTheme
      this.status= result.Status      
      this.userId= result.Id
      this.regions=result.Regions

      if(this.userTheme=='Default'){
        sessionStorage.setItem('theme','Default');
        this.isThemeDark=false
        this.setIcon();
        this.defaultTheme();
        
      }else if(this.userTheme=='Dark'){
        sessionStorage.setItem('theme','Dark');
        this.isThemeDark=true
        this.setIcon();
        this.darkTheme()
        
      }
    })    
  }

  ngAfterViewInit() {
    this.setIcon();
    
  }
  setIcon() {    
    if (this.element) {
      const targetSpan: HTMLElement = this.element.nativeElement.querySelector('.mat-slide-toggle-thumb');
      while (targetSpan.firstChild) {
        targetSpan.firstChild.remove();
      }
      const elem = this.renderer.createElement('mat-icon');
      const icon = this.isThemeDark ?'dark_mode': 'light_mode';
      elem.setAttribute('class', 'mat-icon notranslate material-icons mat-icon-no-color light-mode-switch-icon');
      elem.textContent = icon
      targetSpan.appendChild(elem);
    }
  }


  defaultTheme() {
    this.themeService.setDefaultTheme();
  }

  darkTheme() {
    this.themeService.setDarkTheme();
  }

  openAboutDialog() {
    let apiVersion$ = this.apiInfoService.getApiVersion();
    let internalApiVersion$ = this.apiInfoService.getInternalApiVersion();

    forkJoin(apiVersion$, internalApiVersion$).subscribe(apiVersions => {
      this.dialogService.openCustomDialog<AboutDialogComponent, AboutDialogData>(AboutDialogComponent, {
        width: '400px',
        data: {
          apiVersion: apiVersions[0],
          about: environment.about,
          internalApiVersion: apiVersions[1]
        }
      });
    });
  }

  refreshCategories() {
    this.categoryService.refreshCategories().subscribe(() => this.notificationService.notifySuccess('Categories refreshed'));
  }

  changeCurrentRegion(region: NameValue<RegionType>) {
    this.currentRegion = region;
    this.regionService.setCurrentRegion(region.value);
  }

  private fillRegionMenu(regionTypes: RegionType[]) {
    this.regionOptions = [];
    regionTypes.forEach(r => {
      this.regionOptions.push(this.regionService.getRegionNameValue(r));
    });

    let region = this.regionService.getCurrentRegion();

    if (region) {
      this.currentRegion = this.regionService.getRegionNameValue(region);
    } else {
      this.currentRegion = this.regionOptions[0];
      this.regionService.setCurrentRegion(this.regionOptions[0].value);
    }
  }

  private getUserRegion(userId: string) {
    this.userService.getById(userId).subscribe(applicationUser => {
      this.fillRegionMenu(applicationUser.Regions);
    });
  }
}
