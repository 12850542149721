import { Component, OnInit, HostListener } from '@angular/core';
import { ManageInternationalChainsDataService } from './manageinternationalchainsdata.service';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { InternationalChainDto, internationalChainDtoProps } from '../../models/internationalchaindto';
import { cloneDeep } from 'lodash';
import { RouteConstants } from '../../core/constants/constants';
import { Router } from '@angular/router';
import { NotificationService } from '../../core/uiservices/notification.service';
import { DialogService } from '../../core/uiservices/dialog.service';
import { ChainUpdateType } from '../../enums/enums';
import { FlatCategory } from '../../categories/flatcategory';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { InternationalChainUpdate } from './internationalchainupdate';

@Component({
  selector: 'app-manage-international-chains',
  templateUrl: './manageinternationalchains.component.html',
  styleUrls: ['./manageinternationalchains.component.scss']
})
export class ManageInternationalChainsComponent implements OnInit {
  isEditingOrCreating = false;
  filteredChains: InternationalChainDto[];
  private currentFilter = '';

  constructor(private manageInternationalChainsDataService: ManageInternationalChainsDataService, private dialogService: DialogService,
    private merchantLookupService: MerchantlookupService, private router: Router, private notificationService: NotificationService,
    private loadingService: LoadingService) { }

  ngOnInit() {
    this.filteredChains = this.manageInternationalChainsDataService.chains;
    if (this.manageInternationalChainsDataService.chains.length == 0) {
      this.merchantLookupService.getInternationalChains().subscribe(chains => {
        this.manageInternationalChainsDataService.chains = chains;
        this.filteredChains = chains;
      })
    }
  }

  @HostListener('window:beforeunload')
  openSaveConfirmDialog() {
    if (this.manageInternationalChainsDataService.isDirty) {
      return false;
    }
    return null;
  }

  getEditOrDeleteButtonsCssClass(internationalChainDto: InternationalChainDto, isDanger: boolean) {
    if (internationalChainDto.LWC_Guid && isDanger) {
      return 'danger';
    } else if (internationalChainDto.LWC_Guid) {
      return 'primary';
    } else {
      return 'grey-500';
    }
  }

  isDirty() {
    return this.manageInternationalChainsDataService.isDirty;
  }

  handleClearInput() {
    this.currentFilter = '';
    this.filteredChains = this.manageInternationalChainsDataService.chains;
  }

  edit(internationalChainDto: InternationalChainDto) {
    if (internationalChainDto.LWC_Guid) {
      this.manageInternationalChainsDataService.setEditData(cloneDeep(internationalChainDto));
      this.isEditingOrCreating = true;
      this.router.navigate([RouteConstants.editInternationalChain]);
    } else {
      this.notificationService.notify('Please save before editing');
    }
  }

  deleteRecord(internationalChainDto: InternationalChainDto) {
    if (internationalChainDto.LWC_Guid) {
      const dialogRef = this.dialogService.openDeleteConfirmDialog();
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let chainUpdate: InternationalChainUpdate = {
            Chain: internationalChainDto,
            updatedType: ChainUpdateType.Deleted
          }
          this.manageInternationalChainsDataService.pushToChainUpdate(chainUpdate);
          this.manageInternationalChainsDataService.chains.splice(
            this.manageInternationalChainsDataService.chains.indexOf(internationalChainDto), 1);
          this.filterChains();
        }
      });
    } else {
      this.notificationService.notify('Please save before deleting');
    }
  }

  createNewRecord() {
    let newRecord: InternationalChainDto = {
      InternationalChainName: '',
      Country: '',
      Location: '',
      Description: '',
      DefaultUrl: '',
      StandardCategory: null,
      TransactionDescriptionLookupOptions: [],
      ChainNameLookupOptions: [],
      LWC_Guid: ''
    };
    this.manageInternationalChainsDataService.setEditData(newRecord);
    this.isEditingOrCreating = true;
    this.router.navigate([RouteConstants.editInternationalChain]);
  }

  save() {
    this.loadingService.setLoading();
    this.merchantLookupService.saveInternationalChains(this.manageInternationalChainsDataService.getChainUpdate()).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((data) => {
        this.manageInternationalChainsDataService.chains = data;
        this.filterChains();
        this.manageInternationalChainsDataService.clearChainUpdate();
        this.notificationService.notifySuccess();
      });
  }

  filterDatatable(event: KeyboardEvent) {
    this.currentFilter = (<HTMLInputElement>event.target).value.toLowerCase();
    this.filterChains();
  }

  private filterChains() {
    let keys = [internationalChainDtoProps.InternationalChainName, internationalChainDtoProps.DefaultUrl, internationalChainDtoProps.StandardCategory,
    internationalChainDtoProps.Country, internationalChainDtoProps.Location, internationalChainDtoProps.LWC_Guid];

    this.filteredChains = this.manageInternationalChainsDataService.chains.filter(item => {
      for (let i = 0; i < keys.length; i++) {
        if (item[keys[i]].toString().toLowerCase().indexOf(this.currentFilter) !== -1 || this.currentFilter === '') {
          return true;
        } else if (keys[i] == internationalChainDtoProps.StandardCategory) {
          if ((<FlatCategory>item[keys[i]]).CategoryName.toLowerCase().indexOf(this.currentFilter) !== -1 || this.currentFilter === '') {
            return true;
          }
        }
      }
    });
  }
}
