import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { merchantResponse, merchantResponse1 } from '../mockjsonresponses/merchantresponse.json';
import { StringResponse } from '../mockjsonresponses/stringresponse';
import { getCalsResponse1, getCalsResponse2, getCalsResultResponse, getCalsResponse3, getSimilarCalsResponse } from '../mockjsonresponses/getcalsresponse';
import { chainResponse } from '../mockjsonresponses/getchainresponse';
import { internationalChainResponse } from '../mockjsonresponses/internationalchainresponse';
import { chainTestResult, chainPatternKnownMatch } from '../mockjsonresponses/chainpatternresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { deleteMerchantNamesResponse } from '../mockjsonresponses/deletemerchantnamesresponse';
import { deleteContactMethodResponse } from '../mockjsonresponses/deletemerchantcontactmethodresponse';
import { deleteAssociatedWithResponse } from '../mockjsonresponses/deletemerchantassociatedwithresponse';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function merchantlookupBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  // tslint:disable-next-line:prefer-const
  let cleanUrl = url.split('?')[0];
  const stringResponse = new StringResponse(cleanUrl);
  stringResponse.response = stringResponse.buildResponse();

  if ((cleanUrl.includes(apiPathConstants.mercantIndexV4) ||
   cleanUrl.includes(apiPathConstants.merchantDataBaseDetails)) && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: merchantResponse
      }));
      resp.complete();
    });
  }
  if ((cleanUrl.includes('merchantlookup/merchantdetailsbyname/APRIL%20GALLAGHER')) && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: []
      }));
      resp.complete();
    });
  }
  if ((cleanUrl.includes('merchantindex/merchantdetailsbylwcid') ||
    cleanUrl.includes('merchantlookup/merchantdetailsbylwcid') ||
    cleanUrl.includes('merchantlookup/merchantdetailsbyabn') ||
    cleanUrl.includes('merchantlookup/merchantdetailsbylwcguid') ||
    cleanUrl.includes('merchantlookup/merchantdetailsbyname')) ||
    cleanUrl.includes('merchantlookup/merchantdetailsbywebsite') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: merchantResponse1
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('merchantlookup/databasedetailsbylwcid') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: JSON.stringify(stringResponse)
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('merchantlookup/databasedetailsbyabn') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: JSON.stringify(stringResponse)
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('merchantlookup/databasedetailsbylwcguid') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: JSON.stringify(stringResponse)
      }));
      resp.complete();
    });
  }
  if ((cleanUrl.includes('merchantchain/bylwcid') ||
    cleanUrl.includes('merchantchain/bybranchid') ||
    cleanUrl.includes('merchantchain/chainhq')) && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: JSON.stringify(stringResponse)
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('merchantchain/bysuburb') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: JSON.stringify(stringResponse)
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('merchantchain/refreshchains') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...chainResponse]
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('/merchantlookup/merchantsummary/1001015223') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...getCalsResponse2}
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('/merchantlookup/merchantsummary/3') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...getCalsResponse3}
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes(apiPathConstants.similarcals) && method === "GET") {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...getSimilarCalsResponse]
      }));
      resp.complete();
    });
  }

  if ((cleanUrl.includes(apiPathConstants.merchantLookupMerchantSummary)
    || cleanUrl.includes(apiPathConstants.merchantLookupMerchantSummaryByBsb))
    && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...getCalsResponse1 }
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('/merchant/reallocatecals') && method === 'PUT') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...getCalsResultResponse]
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('/chains') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...chainResponse]
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('/chains') && method === 'PUT') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: chainResponse[3]
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('/chains') && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('/internationalchains') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...internationalChainResponse]
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes('/internationalchains') && method === 'PUT') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...internationalChainResponse]
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes(apiPathConstants.testChainRules) && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...chainTestResult]
      }));

      resp.complete();
    });
  }
  if (cleanUrl.includes(apiPathConstants.reviewChainRules) && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...chainPatternKnownMatch]
      }));

      resp.complete();
    });
  }
  if (cleanUrl.includes(apiPathConstants.merchantIndexOtherNames) && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...deleteMerchantNamesResponse }
      }));

      resp.complete();
    });
  }
  if (cleanUrl.includes(apiPathConstants.merchantIndexAssociatedWith) && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...deleteAssociatedWithResponse }
      }));

      resp.complete();
    });
  }
  if (cleanUrl.includes(apiPathConstants.merchantIndexContactMethods) && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...deleteContactMethodResponse }
      }));

      resp.complete();
    });
  }
}
