import { UserStatusType, RegionType } from '../userenums';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { DialogService } from '../../core/uiservices/dialog.service';
import { UserService } from '../applicationuser.service';
import { ApplicationUser, UpdateUser, CreateUser, ApplicationUserBase } from '../applicationuser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../core/uiservices/notification.service';
import { NgModel } from '@angular/forms';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '../../enums/enums';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})

export class EditUserComponent implements OnInit {
  isCreateMode = false;
  emailId = '';
  status: UserStatusType;
  regions: RegionType[];
  isUserActive: boolean;
  isAusRegion: boolean;
  isNzRegion: boolean;
  password: string;
  confirmPassword: string;
  isPasswordConfirmed = true;
  isEmailInvalid = false;
  firstName: string = '';
  lastName: string = '';
  @ViewChild('emailIdElement') emailIdElement: NgModel;

  constructor(private activeRoute: ActivatedRoute, private userService: UserService,
    private dialogService: DialogService, private loadingService: LoadingService,
    private notificationService: NotificationService, private location: Location,
    private router: Router) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.emailId = params['emailId'];

      if (!this.emailId) {
        this.isCreateMode = true;

        return;
      }

      this.userService.getById(this.emailId).subscribe(applicationUser => {
        this.setUserParams(applicationUser);
      });
    });
  }

  submit() {
    if (this.isCreateMode) {
      let isEmailValid = this.validateEmail();
      let isPasswordConfirmed = this.validatePassword();

      if (!isEmailValid || !isPasswordConfirmed) {
        return;
      }
    }

    this.prepareUsersParams();

    let applicationUserBase: ApplicationUserBase = {
      EmailId: this.emailId,
      Regions: this.regions,
      FirstName: this.firstName,
      LastName: this.lastName
    };

    this.loadingService.setLoading();

    if (this.isCreateMode) {
      this.createUserIfNotExist(applicationUserBase);
    } else {
      this.updateUser(applicationUserBase);
    }
  }

  deleteUser() {
    const dialogRef = this.dialogService
      .openDeleteConfirmDialog();

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();
        this.userService.deleteUser(this.emailId)
          .pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(() => {
            this.notificationService.notifySuccess('User deleted successfully.');
            this.location.back();
          });
      }
    });
  }

  getSubmitButtonText(): string {
    let submitButtonText = 'Update';

    if (this.isCreateMode) {
      submitButtonText = 'Create';
    }

    return submitButtonText;
  }

  getPageName(): string {
    let submitButtonText = 'Edit User';

    if (this.isCreateMode) {
      submitButtonText = 'Create User';
    }

    return submitButtonText;
  }

  private validatePassword() {
    if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
      this.isPasswordConfirmed = true;
    } else {
      this.isPasswordConfirmed = false;
    }

    return this.isPasswordConfirmed;
  }

  private validateEmail() {
    if (this.emailIdElement.valid) {
      this.isEmailInvalid = false;
    } else {
      this.isEmailInvalid = true;
    }

    return !this.isEmailInvalid;
  }

  private updateUser(applicationUserBase: ApplicationUserBase) {
    let updateUser = applicationUserBase as UpdateUser;
    updateUser.Status = this.status;
    this.userService.editUser(updateUser)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('User updated successfully.');
        this.location.back();
      });
  }

  private createUserIfNotExist(applicationUserBase: ApplicationUserBase) {
    this.userService.getById(this.emailId, true)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(user => {
        // TODO remove after we get access to real api
        if (user.EmailId === 'robert1987@example.com') {
          this.createUser(applicationUserBase);
        } else {
          this.notificationService.notifyError('Email already exists.');
        }
      }, err => {
        let error = err as HttpErrorResponse;

        if (error.status === HttpStatusCode.NotFound) {
          this.createUser(applicationUserBase);
        } else {
          this.notificationService.notifyError(err);
        }
      });
  }

  private createUser(applicationUserBase: ApplicationUserBase) {
    let createUser = applicationUserBase as CreateUser;
    createUser.Password = this.password;
    this.userService.createUser(createUser)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('User created successfully.');
        this.router.navigate(['/edituser', this.emailId]);
      });
  }

  private setUserParams(applicationUser: ApplicationUser) {
    this.emailId = applicationUser.EmailId;
    this.status = applicationUser.Status;
    this.regions = applicationUser.Regions;
    this.isAusRegion = applicationUser.Regions.includes(RegionType.AUS);
    this.isNzRegion = applicationUser.Regions.includes(RegionType.NZL);
    this.isUserActive = this.userService.isUserActive(applicationUser.Status);
    this.firstName = applicationUser.FirstName;
    this.lastName = applicationUser.LastName;
  }

  private prepareUsersParams() {

    if (this.isUserActive) {
      this.status = UserStatusType.Active;
    } else {
      this.status = UserStatusType.InActive;
    }

    this.prepareUsersRegions();
  }

  private prepareUsersRegions() {
    this.regions = [];

    if (this.isAusRegion) {
      this.regions.push(RegionType.AUS);
    }
    if (this.isNzRegion) {
      this.regions.push(RegionType.NZL);
    }
  }
}
