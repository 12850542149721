import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-auth-callback',
  template: ''
})
export class AuthCallbackComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    let url: string = '';
    let substringUrl, lwcid;
    this.authService.handleWindowCallback();
    if (localStorage.getItem('url') != null) {
      url = localStorage.getItem('url');
    }
    let queryParams = url.split(';');
    if (queryParams) {
      substringUrl = queryParams[0];
    }

    if (substringUrl) {
      if (substringUrl === '/enrichmerchant') {
        if (queryParams[1]) {
          lwcid = queryParams[1].split('=');
          this.router.navigate([substringUrl, { lwcId: lwcid[1] }]);
        } else {
          this.router.navigate([substringUrl]);
        }
      } else
        this.router.navigate([substringUrl]);
    } else if (!substringUrl) {
      this.router.navigate(['merchantlookup']);
    }
    else {
      this.router.navigate(['merchantlookup']);
    }
  }

  ngOnDestroy(): void {
    localStorage.clear();
  }
}
