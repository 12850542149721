import { CategoryAllocationPattern } from "../../categories/categoryallocationpattern";
import { CategoryAllocationPatternMatch } from "../../categories/categoryallocationpatternmatch";
import { LookupType } from "../../enums/enums";

export let categoryAllocationPatterns: CategoryAllocationPattern[] = [
  {
    CategoryAllocationPatternID: 3,
    NewCategoryID: 1001,
    SearchString: "WAGES",
    LookupType: 2,
    MatchConfidence: 0,
    LastUpdated: "2018-11-15T22:59:21.623",
    Priority: 15,
    MinAmountInclusive: 10.5,
    MaxAmountInclusive: 20.8,
    MinPersonNames: 5
  },
  {
    CategoryAllocationPatternID: 4,
    ExistingCategoryID: 1001006,
    NewCategoryID: 1001,
    SearchString: "WAGE",
    LookupType: 2,
    MatchConfidence: 0,
    LastUpdated: "2018-11-15T22:59:21.637",
    Priority: 20
  },
  {
    CategoryAllocationPatternID: 7,
    NewCategoryID: 1001001,
    SearchString: "BONUS",
    LookupType: 12,
    MatchConfidence: 0,
    LastUpdated: "2018-11-16T18:43:22.9",
    Priority: 7
  },
  {
    CategoryAllocationPatternID: 10,
    NewCategoryID: 1001001,
    SearchString: "SOMETHING",
    LookupType: 2,
    MatchConfidence: 0,
    LastUpdated: "2018-11-16T18:43:22.9",
    Priority: 8
  }
];

export let categoryAllocationTestResult: CategoryAllocationPatternMatch[] = [
  {
    CategoryId: 1002002,
    LookupType: LookupType.Contains,
    SearchString: 'Test string'
  },
  {
    CategoryId: 1002004,
    LookupType: LookupType.StartsWithEndsWithAllowNoSpaces,
    SearchString: 'Some search string'
  },
  {
    CategoryId: 1003001,
    LookupType: LookupType.WithinTextMultiple,
    SearchString: 'Search string'
  }
];
