import { ODataOrderBy } from './odataorderby';

export class ODataOrderByAsc implements ODataOrderBy {
  constructor(private orderBy: string) {
  }

  getValue() {
    return `${this.orderBy} asc`;
  }
}
