import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { unmatchedMerchantQuery, eventing } from '../gqlconstants/gqlconstants';
import { UnmatchedMerchantsResponse } from "../components/chainstores/chainstores.component";

@Injectable({
  providedIn: "root"
})

export class ChainStoresService {
  private unmatchedMerchantQuery = unmatchedMerchantQuery;

  constructor(private apollo: Apollo) {
  }

  getUnmatchedMerchants(chainCode: string) {
    return this.apollo
      .use(eventing)
      .watchQuery<UnmatchedMerchantsResponse>({
        variables: { "chainCode": chainCode },
        query: this.unmatchedMerchantQuery,
      }).valueChanges;
  }
}
