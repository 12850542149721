import { Component, OnInit } from "@angular/core";
import { SrapingDatasourcesService } from "../../services/scrapingdatasources.service";
import { LoadingService } from "../../core/uiservices/loading.service";
import { finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { RouteConstants } from "../../core/constants/constants";
import { JsonViewerCustomComponent } from "../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewercustomcomponent";
import { JsonViewerButtonComponent } from "../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewerbutton/jsonviewerbutton.component";
import { EnrichMerchantEventingRequest } from "./scrapingdatadto";
import { EnrichMerchantService } from "../../services/enrichmerchant.service";
import { ScrapingDataSource } from "./scrapingdatadto";

@Component({
    selector: 'app-scrapingdatasources',
    templateUrl: './scrapingdatasources.component.html',
    styleUrls: ['./scrapingdatasources.component.scss']
})

export class ScrapingDataSourcesComponent implements OnInit {
    dataSources: ScrapingDataSource[] = [];

    constructor(private scrapingDataSources: SrapingDatasourcesService, private loadingService: LoadingService,
        private router: Router, private enrichMerchantService: EnrichMerchantService) {
    }

    ngOnInit() {
        this.getDataSources();
    }

    private getDataSources() {
        this.loadingService.setLoading();
        this.dataSources = [];
        this.scrapingDataSources.getDataSources()
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe((dataSources) => {
                if (dataSources && dataSources.length > 0) {
                    for (let dataSource of dataSources) {
                        this.loadingService.setLoading();
                        this.scrapingDataSources.getScrapingData(dataSource).pipe(finalize(() => this.loadingService.clearLoading()))
                            .subscribe((response) => {
                                if (response) {
                                    let ds: ScrapingDataSource = { dataSource: dataSource, data: response };
                                    if (dataSource === "nswfoodauthority") {
                                        ds.jsonViewerCustomComponents = [];
                                        ds.jsonViewerCustomComponents.push(this.nswFoodAuthorityComponent());
                                    }
                                    this.dataSources.push(ds);
                                }
                            });
                    }
                }
            });
    }

    startNewScrape(apiParamValue: string) {
        this.loadingService.setLoading();
        this.scrapingDataSources.startNewScrape(apiParamValue)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe((response) => { });
    }

    refreshStatus() {
        this.getDataSources();
    }

    viewScrapedData(source: string) {
        this.router.navigate([RouteConstants.scrapedData, source]);
    }

    private nswFoodAuthorityComponent() {
        let nswFoodAuthority: JsonViewerCustomComponent = {
            key: 'MatchedLwcId',
            component: JsonViewerButtonComponent,
            componentConfigurator: (c: JsonViewerButtonComponent, object: any) => {
                if (object.key === "MatchedLwcId" && object.value) {
                    c.text = 'Enrich Merchant';
                    c.click.subscribe(() => {
                        this.loadingService.setLoading();
                        let enrichedMercant: EnrichMerchantEventingRequest = { lwcId: object.value, scrapedItemId: object.id, processType: "nswfoodauthority" };
                        this.enrichMerchantService.enrichMerchant(enrichedMercant)
                            .pipe(finalize(() => this.loadingService.clearLoading()))
                            .subscribe((response) => { });
                    });
                }
                else {
                    c.hidden = true;
                }
            }
        };
        return nswFoodAuthority;
    }
}