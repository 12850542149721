import { HttpEvent, HttpResponse, HttpRequest } from "@angular/common/http";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { chainpatternmatcherResponse } from "../mockjsonresponses/chainpatternmatcherresponse";
import { Observable } from "rxjs";

export function chainPatternMatcherBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];
    if (cleanUrl.includes(apiPathConstants.chainPatternMatcher) && method === "POST") {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: chainpatternmatcherResponse
            }));
            resp.complete();
        });
    }
}