import { propertyOf } from "../../core/services/reflection.service";

export class ExternalCategory {
  Id: number;
  CategoryDataSource: string;
  CategoryId: string;
  CategoryName: string;
  Parent?: string;
  ParentId?: string;
  FullCategoryPath: string;
  Children?: ExternalCategory[];
  IsLWC?: boolean;
  Standardisers?: string[];
  StandardiserStatus?: number;
}

export class FlatNode {
  expandable: boolean;
  level: number;
}

export interface ExternalCategoryForCsv {
  L1CategoryId?: string;
  L1CategoryName?: string;
  L2CategoryId?: string;
  L2CategoryName?: string;
  L3CategoryId?: string;
  L3CategoryName?: string;
  L4CategoryId?: string;
  L4CategoryName?: string;
  L5CategoryId?: string;
  L5CategoryName?: string;
  CategoryId?: string;
};

export const externalCategoryForCsvProps = {
  L1CategoryId: propertyOf<ExternalCategoryForCsv>('L1CategoryId'),
  L1CategoryName: propertyOf<ExternalCategoryForCsv>('L1CategoryName'),
  L2CategoryId: propertyOf<ExternalCategoryForCsv>('L2CategoryId'),
  L2CategoryName: propertyOf<ExternalCategoryForCsv>('L2CategoryName'),
  L3CategoryId: propertyOf<ExternalCategoryForCsv>('L3CategoryId'),
  L3CategoryName: propertyOf<ExternalCategoryForCsv>('L3CategoryName'),
  L4CategoryId: propertyOf<ExternalCategoryForCsv>('L4CategoryId'),
  L4CategoryName: propertyOf<ExternalCategoryForCsv>('L4CategoryName'),
  L5CategoryId: propertyOf<ExternalCategoryForCsv>('L5CategoryId'),
  L5CategoryName: propertyOf<ExternalCategoryForCsv>('L5CategoryName'),
  CategoryId: propertyOf<ExternalCategoryForCsv>('CategoryId'),
}
