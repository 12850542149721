import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { MerchantValidationState } from './merchantvalidationstate';
import { MerchantValidationService } from '../../services/merchantvalidation.service';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { MerchantValidationRecordSeverity } from '../../enums/enums';
import { MerchantValidationRecordModifiable } from './merchantvalidationrecord';
import { LoadingService } from '../../core/uiservices/loading.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteConstants } from '../../core/constants/constants';

@Component({
  selector: 'app-merchantvalidation',
  templateUrl: './merchantvalidation.component.html',
  styleUrls: ['./merchantvalidation.component.scss']
})
export class MerchantvalidationComponent extends BaseStateComponent<MerchantValidationState> implements OnInit {
  isSaveButtonDisabled = true;
  isLoadValidationButtonDisabled = true;
  isMerchantLoading = false;
  @ViewChild('lwcId', {static: true}) lwcIdInput: NgModel;
  duplicateValidationId: number = 67;

  constructor(injector: Injector, private merchantlookupService: MerchantlookupService,
    private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService, private merchantValidationService: MerchantValidationService,
    private loadingService: LoadingService, private dateTimeService: DateTimeService,
    private activatedRoute: ActivatedRoute, private router: Router) {
    super(injector)
   }

  ngOnInit() {
    this.configurelwcIdValueChange();

    this.state = {
      LWC_ID: <number>null,
      merchantLookupResponseFirstPart: null,
      merchantLookupResponseSecondPart: null,
      loadValidationsResult: []
    };

    let lwcIdRouteParam = this.activatedRoute.snapshot.params['lwcId'];

    if (lwcIdRouteParam) {
      this.state.LWC_ID = lwcIdRouteParam;
      this.getLookupMerchantObservable().subscribe(()=> this.loadValidations());
    } else {
      this.restoreState();
    }
  }

  loadValidations() {
    this.loadingService.setLoading();
    this.merchantValidationService.loadValidations(this.state.LWC_ID)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((result) => {
        this.state.loadValidationsResult = this.prepareValidationResults(result);
      });
  }

  saveValidations() {
    this.loadingService.setLoading();
    this.merchantValidationService.saveValidations(this.state.loadValidationsResult.filter((el) => el.isModified))
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.loadValidations();
        this.isSaveButtonDisabled = true;
      });
  }

  modifyRow(row: MerchantValidationRecordModifiable) {
    row.isModified = true;
    this.isSaveButtonDisabled = false;
  }

  formatDate(date: string) {
    return this.dateTimeService.formatWithHours(date);
  }

  navigateToMergeMerchantsScreen(row: MerchantValidationRecordModifiable) {
    this.router.navigate([RouteConstants.mergeMerchants, { primaryMerchantId: row.Comment.match(/[^LWC_ID=]\d+/)[0], duplicateMerchantId: row.LWC_ID }]);
  }

  private configurelwcIdValueChange() {
    this.lwcIdInput.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged()
      ).subscribe((value) => {
        if (value) {
          this.lookupMerchant();
        }
      });
  }

  private lookupMerchant() {
    this.isMerchantLoading = true;

    this.getLookupMerchantObservable().pipe(finalize(() => this.isMerchantLoading = false)).subscribe();
  }

  private getLookupMerchantObservable() {
    this.state.merchantLookupResponseFirstPart = null;
    this.state.merchantLookupResponseSecondPart = null;

    return this.merchantlookupService.getCals(this.state.LWC_ID)
      .pipe(tap((response) => {
        this.isLoadValidationButtonDisabled = false;
        this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
        this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
      }));
  }

  private prepareValidationResults(result: MerchantValidationRecordModifiable[]) {
    let arr;
    arr = result.filter((el) => el.IsActive);
    arr = arr.sort((a, b) => {
      if(!a.IsOverridden) {
        return -1;
      }

      if(!b.IsOverridden) {
        return 1;
      }

      if(a.Severity === MerchantValidationRecordSeverity.Error) {
        return -1;
      }

      if(b.Severity === MerchantValidationRecordSeverity.Error) {
        return 1;
      }

      return 0;
    });

    return arr;
  }
}
