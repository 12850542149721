import { AuthService } from "../../services/auth.service";
import { RoleType } from "../../users/userenums";
import { AdalService } from "adal-angular4";
import { User } from "../../models/user";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class AuthServiceMock extends AuthService {
    constructor(private router: Router, adalService: AdalService) {
        super(adalService);
    }

    getCurrentUser(): User {
        return {
            userId: "12345",
            name: "Peter",
            email: "12@example.com",
            userName: "peter"
        }
    };

    getToken(): string {
        return "token";
    }

    login() {
    }

    addRole(roleName: RoleType) {
    }

    hasRole(roleName: string): boolean {
        return true;
    }

    isAuthenticated() {
        return true;
    }

    canActivate(): boolean {
        return true;
    }

    init() {
    }

    handleWindowCallback() {
    }

    logOut() {
    }
}