import { StringService } from './../core/formatting/string.service';
import { hotChocolate, findPersonNamesQuery } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { PersonName } from "../components/managepersonnames/managepersonnamesstate";
import { PersonNameType } from "../enums/enums";
import { searchPersonNameQuery, addPersonNameQuery, deletePersonNameQuery } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: 'root'
})
export class PersonNameService {

  constructor(private apollo: Apollo, private stringService: StringService) {
  }

  getPersonName(searchString: string) {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<PersonName[]>({
        query: searchPersonNameQuery(searchString),
      }).valueChanges;
  };

  deletePersonName(row: PersonName) {
    if (!row.name) {
      return;
    }
    return this.apollo
      .use(hotChocolate)
      .mutate({
        mutation: deletePersonNameQuery,
        variables: {
          person: row
        }
      })
  };

  addPersonName(name: string, nameType: PersonNameType) {
    return this.apollo
      .use(hotChocolate)
      .mutate({
        mutation: addPersonNameQuery,
        variables: {
          person: { name: name, nameType: nameType }
        }
      });
  };

  testTransactionDescription(transactionDescription: string) {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<PersonName[]>({
        query: gql`${this.stringService.format(findPersonNamesQuery, transactionDescription)}`
      }).valueChanges;
  }
}
