import { Injectable } from "@angular/core";
import { StringNameValue } from "../types/stringnamevalue";

@Injectable({
  providedIn: 'root',
})
export class StringEnumService {
  getDisplayValue(value: string | 0) {
    return value === 0 ? '' : value;
  }

  getStringValueAndDisplayValueArray<TEnum>(displayValues: Map<TEnum, string>, stringValues: Map<TEnum, string>) {
    let result: StringNameValue[] = [];

    displayValues.forEach((value, key) => {
      result.push({ name: stringValues.get(key), value: value });
    });

    return result;
  }
}
