import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { LoadingService } from "../../core/uiservices/loading.service";
import { MerchantOpeningHoursService } from "../../services/merchantopeninghours.service";
import { finalize, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { NotificationService } from "../../core/uiservices/notification.service";
import { OpeningHours, OpeningTimes, Time } from "../../models/searchmodelsv4";
import { BaseStateComponent } from "../basestate/basestate.component";
import { MerchantOpeningHoursState } from "./merchantopeninghoursstate";
import { FormControl, NgModel } from "@angular/forms";
import { MerchantlookupService } from "../../services/merchantlookup.service";
import { MerchantSummaryJsonViewerService } from "../../services/merchantsummaryjsonviewer.service";
import { MatSelectChange } from "@angular/material/select";

@Component({
    selector: 'app-merchantopeninghours',
    templateUrl: './merchantopeninghours.component.html',
    styleUrls: ['./merchantopeninghours.component.scss']
})

export class MerchantOpeningHoursComponent extends BaseStateComponent<MerchantOpeningHoursState> implements OnInit {
    openingHours: OpeningHours[] = [];
    statusOfOpeningHoursArray = ['open', 'closed', 'byAppointment', '24hours'];
    @ViewChild('lwcId', { static: true }) lwcIdInput: NgModel;

    openPanel: boolean;
    daysFormControl = new FormControl('');

    listOfDays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    initialObject: Object;
    selectedDays: string[];
    open: string;

    constructor(injector: Injector, private loadingService: LoadingService, private merchantopeninghoursService: MerchantOpeningHoursService,
        private notificationService: NotificationService, private merchantlookupService: MerchantlookupService,
        private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService) {
        super(injector)
        this.openPanel = true;
        this.initialObject = {};
        this.selectedDays = [];
        this.open = '';
    }

    ngOnInit() {
        this.state = {
            cal: null,
            merchantLookupResponseFirstPart: null,
            merchantLookupResponseSecondPart: null
        };
        this.restoreState();
        this.configurelwcIdValueChange();
    }

    search(cal: number) {
        this.loadingService.setLoading();
        this.openingHours = [];
        this.merchantopeninghoursService.getOpeningHours(cal)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(response => {
                if (response) {
                    this.openingHours.push(response);
                    for (let value of Object.values(response)) {
                        let typeOfValue = typeof (value);
                        if (typeOfValue !== 'boolean') {
                            if (!value.times) value.times = [{ open: '', close: '' }];
                        }
                    }
                    this.addLostDays();
                }
                else {
                    this.openingHours.push(
                        {
                            is_always_open: false,
                            sunday: {
                                status: 'closed',
                                times: [{ open: '', close: '' }]
                            },
                            monday: {
                                status: 'closed',
                                times: [{ open: '', close: '' }]
                            },
                            tuesday: {
                                status: 'closed',
                                times: [{ open: '', close: '' }]
                            },
                            wednesday: {
                                status: 'closed',
                                times: [{ open: '', close: '' }]
                            },
                            thursday: {
                                status: 'closed',
                                times: [{ open: '', close: '' }]
                            },
                            friday: {
                                status: 'closed',
                                times: [{ open: '', close: '' }]
                            },
                            saturday: {
                                status: 'closed',
                                times: [{ open: '', close: '' }]
                            }
                        }
                    );
                }
            });
    }

    update() {
        this.loadingService.setLoading();
        this.merchantopeninghoursService.updateOpeningHours(this.state.cal, this.openingHours[0])
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(() => {
                this.notificationService.notifySuccess('Opening hours update was successful');
            });
    }

    private configurelwcIdValueChange() {
        this.lwcIdInput.valueChanges
            .pipe(
                debounceTime(1200),
                distinctUntilChanged()
            ).subscribe(value => {
                if (value) {
                    this.lookupMerchant();
                }
            });
    }

    private lookupMerchant() {
        this.state.merchantLookupResponseFirstPart = null;
        this.state.merchantLookupResponseSecondPart = null;
        this.merchantlookupService.getCals(this.state.cal)
            .subscribe((response) => {
                if (response) {
                    this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
                    this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
                }
            });
    }

    private addLostDays() {
        let daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        let availableDays = this.openingHours.map(t => Object.keys(t))[0];
        for (let day of daysOfWeek) {
            if (!availableDays.includes(day)) {
                let openingTimes: OpeningTimes = { status: 'closed', times: [{ open: '', close: '' }] };
                Object.assign(this.openingHours[0], { [day]: openingTimes });
            }
        }
    }
    apply() {
        if (this.selectedDays.length > 1) {
            for (let i of this.selectedDays) {
                if (i.includes('Sunday')) {
                    this.openingHours[0].sunday = Object.assign({}, this.initialObject);
                }
                if (i.includes('Monday')) {
                    this.openingHours[0].monday = Object.assign({}, this.initialObject);
                }
                if (i.includes('Tuesday')) {
                    this.openingHours[0].tuesday = Object.assign({}, this.initialObject);
                }
                if (i.includes('Wednesday')) {
                    this.openingHours[0].wednesday = Object.assign({}, this.initialObject);
                }
                if (i.includes('Thursday')) {
                    this.openingHours[0].thursday = Object.assign({}, this.initialObject);
                }
                if (i.includes('Friday')) {
                    this.openingHours[0].friday = Object.assign({}, this.initialObject);
                }
                if (i.includes('Saturday')) {
                    this.openingHours[0].saturday = Object.assign({}, this.initialObject);
                }
            }
        } else {
            this.selectedDays = [];
        }
    }

    detectChanges(day: string, obj: OpeningTimes) {
        if (obj.status == 'closed') {
            if (this.selectedDays.includes(day)) {
                let i: any;
                let array = this.selectedDays;
                let indexofday = array.indexOf(day);
                array.splice(indexofday, 1);
                this.selectedDays = [];
                for (i of array) {
                    this.selectedDays.push(i)
                }
                let openingTimes: OpeningTimes = { status: 'closed', times: [{ open: '', close: '' }] };
                Object.assign(this.openingHours[0], { [day]: openingTimes });
            } else {
                this.selectedDays.push(day);
            }
        }
        this.initialObject = obj;

    }

    SelectedDays(day: MatSelectChange) {
        if (!this.selectedDays.includes(day.value)) {
            this.selectedDays.push(day.value);
        } else {
            let indexofDay = this.selectedDays.indexOf(day.value);
            this.selectedDays.splice(indexofDay, 1);
        }
    }

    checkDisableForSelectedDays() {
        if (this.selectedDays.length >= 1) {
            return false;
        } else {
            return true;
        }
    }

    getOpenTime(event: any) {
        this.open = event;
    }

    getCloseTime(event: any) {
        let openingTimes: OpeningTimes = { status: 'open', times: [{ open: this.open, close: event }] };
        this.initialObject = openingTimes;
    }

    resetForm() {
        this.state = {
            cal: null,
            merchantLookupResponseFirstPart: null,
            merchantLookupResponseSecondPart: null
        };
        this.openingHours = [];
        this.initialObject = [];
        this.selectedDays = [];
    }
}
