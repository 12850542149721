export let getBsbsResponse = [
  '082008',
  '072401',
  '062209',
  '052605',
  '042506',
  '032703',
  '022806',
  '012700',
];

