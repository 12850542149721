import { NotificationService } from './../../core/uiservices/notification.service';
import { ConfirmDialogComponent } from './../../shared/confirmdialog/confirmdialog.component';
import { emptyString } from './../../core/constants/constants';
import { StringService } from './../../core/formatting/string.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { finalize, map, startWith } from "rxjs/operators";
import { LoadingService } from "../../core/uiservices/loading.service";
import { ManageExternalCategoriesService } from "../../services/manageexternalcategories.service";
import { FormControl } from '@angular/forms';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { whiteSpace } from '../../core/constants/constants';
import { FlatNode, ExternalCategory, externalCategoryForCsvProps, ExternalCategoryForCsv } from './manageexternalcategories.models';
import { AuthService } from '../../services/auth.service';
import { RoleType } from '../../users/userenums';
import { DialogService } from '../../core/uiservices/dialog.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import * as moment from 'moment';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { NumberService } from '../../core/uiservices/number.service';

@Component({
  selector: 'app-manageexternalcategories',
  templateUrl: './manageexternalcategories.component.html',
  styleUrls: ['./manageexternalcategories.component.scss']
})
export class ManageExternalCategories implements OnInit {
  @ViewChild('csvExportExternalCategories') csvExportExternalCategoriesComponent: CsvExportComponent;

  isAdminRole = this.authService.hasRole(RoleType[RoleType.IndexManagerAdmin]);

  private externalCategoriesForCsv: ExternalCategoryForCsv[] = [];
  private isEditCategory: boolean = false;
  private isAddCategory: boolean = false;
  private editCategoryByCategoryId: string = null;
  private addCategoryByCategoryId: string = null;
  private categoriesDataSources: string[];
  private csvHeaders = {
    L1CategoryId: 'L1CategoryID',
    L1CategoryName: 'L1CategoryName',
    L2CategoryId: 'L2CategoryID',
    L2CategoryName: 'L2CategoryName',
    L3CategoryId: 'L3CategoryID',
    L3CategoryName: 'L3CategoryName',
    L4CategoryId: 'L4CategoryId',
    L4CategoryName: 'L4CategoryName',
    L5CategoryId: 'L5CategoryId',
    L5CategoryName: 'L5CategoryName',
    CategoryId: 'CategoryID',
  };
  private csvOptionHeadersAndKeys = {
    headers: [
      this.csvHeaders.L1CategoryId,
      this.csvHeaders.L1CategoryName,
      this.csvHeaders.L2CategoryId,
      this.csvHeaders.L2CategoryName,
      this.csvHeaders.L3CategoryId,
      this.csvHeaders.L3CategoryName,
      this.csvHeaders.L4CategoryId,
      this.csvHeaders.L4CategoryName,
      this.csvHeaders.L5CategoryId,
      this.csvHeaders.L5CategoryName,
      this.csvHeaders.CategoryId,
    ],
    keys: [
      externalCategoryForCsvProps.L1CategoryId,
      externalCategoryForCsvProps.L1CategoryName,
      externalCategoryForCsvProps.L2CategoryId,
      externalCategoryForCsvProps.L2CategoryName,
      externalCategoryForCsvProps.L3CategoryId,
      externalCategoryForCsvProps.L3CategoryName,
      externalCategoryForCsvProps.L4CategoryId,
      externalCategoryForCsvProps.L4CategoryName,
      externalCategoryForCsvProps.L5CategoryId,
      externalCategoryForCsvProps.L5CategoryName,
      externalCategoryForCsvProps.CategoryId,
    ],
  };

  private transformerExternalCategories = (node: ExternalCategory, level: number) => {
    return {
      expandable: node.Children && node.Children.length > 0,
      level: level,
      CategoryId: node.CategoryId,
      CategoryName: node.CategoryName,
      Id: node.Id,
      ParentId: node.ParentId,
      CategoryDataSource: node.CategoryDataSource,
      Children: node.Children,
    };
  }
  private treeFlattenerExternalCategories = new MatTreeFlattener(
    this.transformerExternalCategories, node => node.level, node => node.expandable, node => node.Children);

  formControlExternalCategories = new FormControl();
  filteredExternalCategories: string[];
  showExternalCategories = false;

  treeControlExternalCategories = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);
  dataSourceExternalCategories = new MatTreeFlatDataSource(this.treeControlExternalCategories, this.treeFlattenerExternalCategories);
  externalCategoryHasChild = (_: number, node: FlatNode) => node.expandable;

  constructor(private stringService: StringService, private loadingService: LoadingService,
    private manageExternalCategoriesService: ManageExternalCategoriesService, private authService: AuthService,
    private dialogService: DialogService, private csvComponentService: CsvComponentService,
    private dateTimeService: DateTimeService, private numberService: NumberService,
    private notificationService: NotificationService) {}

  ngOnInit() {
    this.setAllCategoriesDataSources();
  }

  setExternalCategorySelected(externalCategory: string) {
    this.loadingService.setLoading();
    this.manageExternalCategoriesService.getExternalCategoriesTree(externalCategory)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.dataSourceExternalCategories.data = response;
        this.showExternalCategories = true;
      });
  }

  getFullCategoryName(externalCategory: Partial<ExternalCategory>) {
    if (this.editCategoryByCategoryId === externalCategory.CategoryId && this.isEditCategory) {
      return emptyString;
    }
    return externalCategory.CategoryId + whiteSpace + externalCategory.CategoryName;
  }

  deleteCategory(externalCategory: ExternalCategory) {
    let message = this.stringService.format(
      'Are you sure to delete Category {0} and all its children records?',
       externalCategory.CategoryName
    );
    let dialogRef = this.dialogService.openCustomDialog(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: message,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // @ts-ignore
        let externalCategories = <ExternalCategory[]><unknown>this.dataSourceExternalCategories._flattenedData.value;
        let parentExternalCategory = externalCategories.find((m) => m.CategoryId === externalCategory.ParentId);

        this.loadingService.setLoading();
        this.manageExternalCategoriesService.deleteExternalCategories(externalCategory.Id)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          this.dataSourceExternalCategories.data = response;
        });
      }
    });
  }

  updateCategory(newCategoryName: string, externalCategory: ExternalCategory) {
    let updatedExternalCategory: Partial<ExternalCategory> = {
      Id: externalCategory.Id,
      CategoryDataSource: externalCategory.CategoryDataSource,
      CategoryId: externalCategory.CategoryId,
      CategoryName: newCategoryName,
      ParentId: externalCategory.ParentId,
    };

    this.loadingService.setLoading();
    this.manageExternalCategoriesService.updateExternalCategories(updatedExternalCategory)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.dataSourceExternalCategories.data = response;
        this.isEditCategory = false;
    });
  }

  createCategory(categoryName: string, newCategoryId: string,  externalCategory: ExternalCategory) {
    let categoryId = externalCategory.CategoryId.concat(newCategoryId);
    let isCategoryIdDuplicate = this.isDuplicateCategoryId(categoryId, this.dataSourceExternalCategories.data);

    if(isCategoryIdDuplicate) {
      this.notificationService.notifyError("Id is already taken, enter a new one");
      return;
    }

    let newExternalCategory: Partial<ExternalCategory> = {
      Id: 0,
      CategoryDataSource: externalCategory.CategoryDataSource,
      CategoryId: categoryId,
      CategoryName: categoryName,
      ParentId: externalCategory.CategoryId,
    };

    this.loadingService.setLoading();
    this.manageExternalCategoriesService.updateExternalCategories(newExternalCategory)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.dataSourceExternalCategories.data = response;
        this.isAddCategory = false;
      });
  }

  getCategoryId(externalCategory: ExternalCategory) {
    let categoryId = externalCategory.Children.length + 1;
    if (categoryId < 10) {
      return "00" + categoryId;
    } else if (categoryId < 100 && 10 <= categoryId ) {
      return "0" + categoryId;
    } else {
      return categoryId.toString();
    }
  }

  editIconClicked(categoryId: string) {
    this.isEditCategory = true;
    this.editCategoryByCategoryId = categoryId;
  }

  addIconClicked(categoryId: string) {
    this.isAddCategory = true;
    this.addCategoryByCategoryId = categoryId;
  }

  showEditingCategory(categoryId: string) {
    return this.editCategoryByCategoryId === categoryId && this.isEditCategory;
  }

  showAddedCategory(categoryId: string) {
    return this.addCategoryByCategoryId === categoryId && this.isAddCategory;
  }

  hideCancelIcon() {
    this.isEditCategory = false;
    this.isAddCategory = false;
  }

  getCssClassForIcon() {
    return this.isEditCategory || this.isAddCategory ? 'visibility-hidden' : 'material-icons pointer';
  }

  downloadCsvFileForExternalCategories(dataSourceExternalCategoryName: string) {
    for (let externalCategory of this.dataSourceExternalCategories.data) {
      this.setTableRowsForExportToExcel(externalCategory);
    }
    let currentDate = this.dateTimeService.formatForODataFilter(moment(Date.now()));
    this.csvExportExternalCategoriesComponent.options = this.getCsvOptions();
    this.csvExportExternalCategoriesComponent.data = this.externalCategoriesForCsv;
    this.csvExportExternalCategoriesComponent.filename = `ExternalCategories-${dataSourceExternalCategoryName}-${currentDate}`;
    this.csvExportExternalCategoriesComponent.onDownload();
    this.externalCategoriesForCsv = [];
  }

  disableExportToExcelButton(dataSourceExternalCategoryName: string) {
    return !this.dataSourceExternalCategories.data || dataSourceExternalCategoryName === emptyString || this.dataSourceExternalCategories.data.length === 0;
  }

  private isDuplicateCategoryId(categoryId: string, externalCategories: ExternalCategory[]) {
    for(let category of externalCategories){
      if(category.CategoryId === categoryId) {
        return true;
      } else if (category.Children && category.Children.length > 0) {
        if(this.isDuplicateCategoryId(categoryId, category.Children))
        {
          return true;
        }
      }
    }

    return false;
  }

  private getCsvOptions() {
    let options = this.csvComponentService.getCsvOptions(this.externalCategoriesForCsv[0], emptyString);
    return { ...options, ...this.csvOptionHeadersAndKeys };
  }

  private setTableRowsForExportToExcel(externalCategory: ExternalCategory) {
    let externalCategoriesForCsv: ExternalCategoryForCsv = {
      L1CategoryId: externalCategory.CategoryId,
      L1CategoryName: externalCategory.CategoryName,
      L2CategoryId: emptyString,
      L2CategoryName: emptyString,
      L3CategoryId: emptyString,
      L3CategoryName: emptyString,
      L4CategoryId: emptyString,
      L4CategoryName: emptyString,
      L5CategoryId: emptyString,
      L5CategoryName: emptyString,
      CategoryId: externalCategory.CategoryId,
    }

    if (externalCategory.Children && externalCategory.Children.length === 0) {
      this.externalCategoriesForCsv.unshift({ ...externalCategoriesForCsv });
      this.externalCategoriesForCsv.sort((a, b) => {
        if (+a.L1CategoryId >= 0 && +b.L1CategoryId >= 0) {
          return this.numberService.sortAscending(+a.L1CategoryId, +b.L1CategoryId)
        }
        else if (+a.L1CategoryId < 0) {
          return 1;
        }
        else if (+b.L1CategoryId < 0) {
          return -1;
        }
      })
    } else {
      this.setDataExternalCategoriesForCsv(externalCategory, externalCategoriesForCsv);
    }
  }

  private setDataExternalCategoriesForCsv(externalCategory: ExternalCategory, externalCategoriesForCsv: ExternalCategoryForCsv) {
    let stackExternalCategories: ExternalCategory[] = [];
    let allExternalCategories: ExternalCategory[] = [];
    let lastExternalCategories: ExternalCategory[] = [];
    stackExternalCategories.push(externalCategory);

    if (externalCategory.Children && externalCategory.Children.length > 0) {
      while (stackExternalCategories.length !== 0) {
        let node = stackExternalCategories.shift();
        if (node.Children.length === 0) {
          lastExternalCategories.push(node);
        }
        else {
          for (let i = node.Children.length - 1; i >= 0; i--) {
            stackExternalCategories.push(node.Children[i]);
            allExternalCategories.push(node.Children[i]);
          }
        }
      }
    }

    this.groupingExternalCategoriestForExportToExcel(externalCategoriesForCsv, allExternalCategories, lastExternalCategories, externalCategory);
  }

  private groupingExternalCategoriestForExportToExcel(externalCategoriesForCsv: ExternalCategoryForCsv,
    allExternalCategories: ExternalCategory[], lastExternalCategories: ExternalCategory[], externalCategory: ExternalCategory) {
    let externalCategoryByParentId = { ...externalCategory };
    let groupExternalCategories = [];
    let result: ExternalCategory[][] = [];
    allExternalCategories.push(externalCategory);

    for (let i = 0; i < lastExternalCategories.length; i++) {
      let index = 5;
      externalCategoryByParentId = lastExternalCategories[i];
      while (externalCategoryByParentId) {
        groupExternalCategories.push(externalCategoryByParentId);
        externalCategoryByParentId = allExternalCategories.find(m => m.CategoryId === externalCategoryByParentId.ParentId);
        --index;
        if (index === 0) {
          index = 5;
        }
      }
      result[i] = groupExternalCategories;
      groupExternalCategories = [];
    }

    this.setExternalCategoriesForCsv(result, externalCategoriesForCsv);
  }

  private setExternalCategoriesForCsv(groupExternalCategories: ExternalCategory[][], externalCategoriesForCsv: ExternalCategoryForCsv) {
    if (groupExternalCategories && groupExternalCategories.length > 0) {
      for (let externalCategories of groupExternalCategories) {
        externalCategories.sort((a, b) => this.numberService.sortAscending(+a.CategoryId, +b.CategoryId));
        let index = 1;
        for (let externalCategory of externalCategories) {
          let categoryNameByIndex = `L${index}CategoryName`;
          let categoryIdByIndex = `L${index}CategoryId`;

          externalCategoriesForCsv[`CategoryId`] = externalCategory.CategoryId;
          externalCategoriesForCsv[categoryNameByIndex as keyof ExternalCategoryForCsv] = externalCategory.CategoryName;
          externalCategoriesForCsv[categoryIdByIndex as keyof ExternalCategoryForCsv] = externalCategory.CategoryId;
          index++;
        }

        this.externalCategoriesForCsv.unshift({ ...externalCategoriesForCsv });
        this.externalCategoriesForCsv.sort((a, b) => this.numberService.sortAscending(+a.L2CategoryId, +b.L2CategoryId));
      }
    }
  }

  private getFilteredCategoriesDataSources(value: string) {
    return this.categoriesDataSources.filter(categoryName => categoryName.toLowerCase().includes(value.toLowerCase()));
  }

  private setAllCategoriesDataSources() {
    this.loadingService.setLoading();
    this.manageExternalCategoriesService.getAllCategoriesDataSources()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.categoriesDataSources = response.sort((a, b) => {
          return this.stringService.sortAlphabetically(a, b);
        });
        this.configureValueChanges();
      });
  }

  private configureValueChanges() {
    this.formControlExternalCategories.valueChanges
      .pipe(
        startWith(emptyString),
        map(value => this.getFilteredCategoriesDataSources(value))
      )
      .subscribe(response => {
        if (this.showExternalCategories) {
          this.showExternalCategories = false;
        }
        this.filteredExternalCategories = response;
      });
  }
}
