import { cloneDeep } from 'lodash';
import { Injectable } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatTreeFlatDataSource } from "@angular/material/tree";
import { NodeStandardiserStatus } from "../../enums/enums";
import { CategoryFlatNode, CategoryStandardiser, CategoryTreeModel } from "./categorytrees.models";

@Injectable({
  providedIn: "root"
})
export class CategoryTreeService {
   getTypeOfIconForStandardiserCategory = (categoryNode: CategoryFlatNode) => {
    let categoryStandardiser = <CategoryStandardiser>categoryNode.category;
    if(categoryStandardiser.StandardiserStatus === NodeStandardiserStatus.NotMapped) {
      return "radio_button_unchecked";
    } else if (categoryStandardiser.StandardiserStatus === NodeStandardiserStatus.PartiallyMapped) {
      return "radio_button_checked";
    } else if (categoryStandardiser.StandardiserStatus === NodeStandardiserStatus.FullyMapped) {
      return "circle";
    }
  }

  filterStandardiserCategories = (
    event: MatCheckboxChange,
    categories: CategoryTreeModel[],
    dataSourceData: MatTreeFlatDataSource<CategoryTreeModel, CategoryFlatNode, CategoryFlatNode>) => {
    if(event.checked) {
      let copyOfCategories: CategoryStandardiser[] = cloneDeep(categories);
      dataSourceData.data = <CategoryTreeModel[]>this.getFilteredCategories(copyOfCategories);
    } else {
      dataSourceData.data = categories;
    }
  }

  private getFilteredCategories = (categories: CategoryStandardiser[]) => {
    for(let category of categories) {
      if(category.StandardiserStatus === NodeStandardiserStatus.FullyMapped) {
        categories.splice(categories.indexOf(category), 1);
        this.getFilteredCategories(categories);
      } else if (category.Children && category.Children.length > 0) {
        this.getFilteredCategories(category.Children);
      }
    }
    return categories;
  }
}
