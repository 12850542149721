import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { merchantEventResponse } from '../mockjsonresponses/merchanteventresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';

export function merchantEventBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes(apiPathConstants.merchantEvent) && method === 'GET') {

    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: merchantEventResponse
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.merchantEvent) && method === 'PUT') {

    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200
      }));
      resp.complete();
    });
  }
}
