export class StringResponse {
  private _url: string = null;

  constructor(url: string) {
    this._url = url;
  }

  public response: string;

  public buildResponse(): string {
    return `{"response": "search in ${this._url} success"}`;
  }
}
