import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { ExternalSource, ManageClient, ManageClientResponse, ManageClientTag } from '../models/manageclient.models';
import { ApiService } from '../core/api/api.service';
import { ApiClient } from '../core/api/apiclient';
import { apiPathConstants } from '../core/api/apipathconstants';
import { emptyString } from '../core/constants/constants';
import { StringService } from '../core/formatting/string.service';
import { ValueCheckerService } from '../core/services/valuechecker.service';
import { LoadingService } from '../core/uiservices/loading.service';
import { clientOnboarding, clientOnboardingApiClient, getClientNames, hotChocolate } from '../gqlconstants/gqlconstants';
import { Data } from '../models/manageapikeys.models';

@Injectable({
  providedIn: 'root'
})
export class ManageclientService {

  public clientAdded: Subject<boolean>;

  constructor(private apollo: Apollo, private loadingService: LoadingService, private stringService: StringService,
    private valueCheckerService: ValueCheckerService, private apiService: ApiService, private apiClient: ApiClient) {
    this.clientAdded = new Subject<boolean>();
  }

  getOnBoardingData(clientName: string, skip: number, take: number, orderBy: string, orderDirection: string) {
    this.loadingService.setLoading();
    let query = clientOnboardingApiClient

    let optionalFilters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(clientName)) {
      optionalFilters = optionalFilters + this.stringService.format(`{0}`, clientName);
    }

    return this.apollo.use(hotChocolate).watchQuery<ManageClientResponse>({
      query: gql`${this.stringService.format(query, optionalFilters, skip.toString(), take.toString(), orderBy, orderDirection.toUpperCase())}`
    }).valueChanges;
  }

  getClientName(skip: number, take: number) {
    this.loadingService.setLoading();
    let query = getClientNames;
    return this.apollo.use(hotChocolate).watchQuery<Data>({
      query: gql`${this.stringService.format(query, skip.toString(), take.toString())}`
    }).valueChanges;
  }

  addClients(values: ManageClient[]): Observable<ManageClient[]> {
    this.loadingService.setLoading();
    return this.apollo.use(hotChocolate).mutate<ManageClientResponse>({
      mutation: gql`${this.stringService.format(clientOnboarding)}`,
      variables: { apiclient: values }
    }).pipe(catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse): Observable<any> {
    return throwError(() => error)
  }

  getTags() {
    return this.apiClient.get<ManageClientTag[]>(this.apiService.buildUrl(apiPathConstants.getManageClientTag));
  }

  getExternalCategories() {
    return this.apiClient.get<ExternalSource[]>(this.apiService.buildUrl(apiPathConstants.getManageClientExternalCategory));
  }
}
