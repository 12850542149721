import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpMethod } from '../../core/coreenums/coreenums';
import { enumValuesResponse, missingMerchantsResponse, updateMissingMerchantResponse } from '../mockjsonresponses/managemissingmerchants.response';

export function enumValuesBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesRecordStatus = false;
  if (request.body && request.body.query) {
    includesRecordStatus = request.body.query.includes(`__type(name: "RecordStatus")`);
  }

  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesRecordStatus) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        body: { ...enumValuesResponse },
        status: 200
      }));
      resp.complete();
    });
  }

  let includesMissingMerchants = false;
  if (request.body && request.body.query) {
    includesMissingMerchants = request.body.query.includes(`missingMerchants`);
  }

  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesMissingMerchants) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        body: {...missingMerchantsResponse},
        status: 200
      }));
      resp.complete();
    });
  }

  let includesUpdateMissingMerchant = false;
  if (request.body && request.body.query) {
    includesUpdateMissingMerchant = request.body.query.includes("updateMissingMerchant");
  }

  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesUpdateMissingMerchant) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...updateMissingMerchantResponse}
      }));
      resp.complete();
    });
  }
}
