import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiClientsResponse, apiClientsEditResponse, apiClientsHmacKeysResponse, apiClientAllPoliciesResponse } from '../mockjsonresponses/apiclientsresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function apiClientsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesUpsertApiClient = false;
  if(request.body && request.body.query) {
    includesUpsertApiClient = request.body.query.includes('upsertAPIClient');
  }

  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesUpsertApiClient) {
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: apiClientsEditResponse
        }));
        resp.complete();
      });
     }

  let includesApiClients = false;
  if(request.body && request.body.query) {
     includesApiClients = request.body.query.includes('{\n  data: apiClients');
  }

  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesApiClients) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...apiClientsResponse}
      }));
      resp.complete();
    });
  }

  let includesHmacKeys = false;
  if (request.body && request.body.query) {
    includesHmacKeys = request.body.query.includes("{\n  data: hmackeys");
  }

  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesHmacKeys) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...apiClientsHmacKeysResponse}
      }));
      resp.complete();
    });
  }

  let includesApiClientAllPolicies = false;
  if (request.body && request.body.query) {
    includesApiClientAllPolicies = request.body.query.includes("{\n  data: apiClientAllPoliciesQuery");
  }

  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesApiClientAllPolicies) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...apiClientAllPoliciesResponse}
      }));
      resp.complete();
    });
  }
}
