import { hotChocolate, refreshAPIKeysQuery } from './../../gqlconstants/gqlconstants';
import { emptyString, commaSpace } from './../../core/constants/constants';
import { ValueCheckerService } from './../../core/services/valuechecker.service';
import { Component, OnInit, Injector } from '@angular/core';
import { ApiClientsState } from './apiclients.state';
import { BaseStateComponent } from '../basestate/basestate.component';
import { Apollo, gql } from 'apollo-angular';
import { ApiClientDTO, ApiClientDTOFormatted } from './apiclientdto';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { RouteConstants } from '../../core/constants/constants';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ApiClientDataService } from './apiclientsdata.service';
import { UserService } from '../../users/applicationuser.service';
import { ApplicationUser } from '../../users/applicationuser';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { MatSelectChange } from '@angular/material/select';
import { HmacKey } from './apiclients.models';

export type HmacKeysResponse = {
  hmackeys: HmacKey;
}

@Component({
  selector: 'app-apiclients',
  templateUrl: './apiclients.component.html',
  styleUrls: ['./apiclients.component.scss']
})
export class ApiClientsComponent extends BaseStateComponent<ApiClientsState> implements OnInit {
  loading = true;
  error: any;
  applicationUsers: ApplicationUser[];
  apiClientNames: string[] = [];
  hmacKeys: HmacKey;
  hideHmacKeys: boolean = true;
  apiClientPolicies: string[] = [];

  constructor(private apollo: Apollo, injector: Injector, private loadingService: LoadingService,
    private router: Router, private apiClientDataService: ApiClientDataService, private userService: UserService,
    private valueCheckerService: ValueCheckerService, private dateTimeService: DateTimeService) {
    super(injector)
  }

  ngOnInit() {
    this.state = {
      displayResults: [],
      filteredDisplayResults: [],
      filterByKeyName: emptyString,
      filterByApiKeyDescription: emptyString,
      filterByClientName: emptyString,
      filterByPolicy: []
    };

    this.restoreState();
    this.userService.getApplicationUsers().subscribe(users => {
      this.applicationUsers = users;
      this.getApiClients();
    });
  }

  getApiClients() {
    this.apiClientDataService.getApiClients(false)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(result => {
        this.formatApiClients(result.data);
        this.loading = result.loading;
        this.error = result.errors;
        this.loadingService.clearLoading();
        this.filterClients();
      });
  }

  private formatApiClients(apiClients: ApiClientDTO[]) {
    this.state.filteredDisplayResults = this.state.displayResults = <ApiClientDTOFormatted[]>apiClients;
    this.apiClientNames = [];
    for (let apiClient of this.state.displayResults) {
      apiClient.createdByDescription = this.getUserDescription(apiClient.createdBy);
      apiClient.createDateDisplay = this.dateTimeService.format(apiClient.createDate, this.dateTimeService.dateTimeFormatYYYYMMDDhmmaDashes);
      apiClient.modifiedDateDisplay = this.dateTimeService.format(apiClient.modifiedDate, this.dateTimeService.dateTimeFormatYYYYMMDDhmmaDashes);

      if (apiClient.apiClientPolicies && apiClient.apiClientPolicies.length > 0) {
        apiClient.policies = apiClient.apiClientPolicies.map(t => t.policy).toString().replace(/,/g, commaSpace);
        this.apiClientPolicies = [...this.apiClientPolicies, ...apiClient.apiClientPolicies.map(t => t.policy)];
      }
      if (apiClient.apiClientGroup) {
        apiClient.displayApiClientGroup = apiClient.apiClientGroup.clientName;
      }
      if (apiClient.displayApiClientGroup && !this.apiClientNames.includes(apiClient.displayApiClientGroup)) {
        this.apiClientNames.push(apiClient.displayApiClientGroup);
      }
      if (apiClient.createdBy !== apiClient.modifiedBy) {
        apiClient.modifiedByDescription = this.getUserDescription(apiClient.modifiedBy);
      }
    }
    this.apiClientPolicies = this.apiClientPolicies.filter((item, index) => this.apiClientPolicies.indexOf(item) === index);
  }

  private getUserDescription(userId: number) {
    let result;
    let user = this.applicationUsers.find(u => u.Id == userId);
    if (user) {
      result = user.FirstName + " " + user.LastName;
    }
    else if (userId == 0) {
      result = 'LWC System';
    }
    else {
      result = userId.toString();
    }

    return result;
  }

  refreshApiClients() {
    this.apollo
      .use(hotChocolate)
      .mutate({
        mutation: gql`${refreshAPIKeysQuery}`
      }).subscribe(() => {
        this.getApiClients();
      })
  }

  edit(apiClient: ApiClientDTOFormatted) {
    this.apiClientDataService.setEditData(cloneDeep(apiClient));
    this.router.navigate([RouteConstants.editApiClient]);
  }

  formatBooleanToCheckmark(value: boolean) {
    return value ? '✓' : emptyString;
  }

  handleClearInputForKeyName() {
    this.state.filterByKeyName = emptyString;
    this.filterClients();
  }

  handleClearInputForApiKeyDescription() {
    this.state.filterByApiKeyDescription = emptyString;
    this.filterClients();
  }

  setFilterByKeyName(event: KeyboardEvent) {
    this.state.filterByKeyName = (<HTMLInputElement>event.target).value;
    this.filterClients();
  }

  setFilterByApiKeyDescription(event: KeyboardEvent) {
    this.state.filterByApiKeyDescription = (<HTMLInputElement>event.target).value;
    this.filterClients();
  }

  filterBySelectedApiClientName(event: MatSelectChange) {
    this.state.filterByClientName = event.value;
    this.filterClients();
  }

  filterBySelectedPolicy(event: MatSelectChange) {
    this.state.filterByPolicy = event.value;
    this.filterClients();
  }

  generateHmacKeys() {
    this.apiClientDataService.getHmacKeys()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.data && response.data.hmackeys) {
          this.hmacKeys = response.data.hmackeys;
          this.hideHmacKeys = false;
        }
      });
  }

  private filterClients() {
    this.state.filteredDisplayResults = this.state.displayResults.filter((apiClient: ApiClientDTOFormatted) => {
      let isMatch = true;

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByKeyName)) {
        isMatch = apiClient.apikeyName.toLowerCase().includes(this.state.filterByKeyName.toLowerCase());
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByClientName)) {
        isMatch = apiClient.displayApiClientGroup === this.state.filterByClientName;
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByApiKeyDescription)) {
        isMatch = apiClient.clientName.toLowerCase().includes(this.state.filterByApiKeyDescription.toLowerCase());
      }

      if (isMatch && this.state.filterByPolicy && this.state.filterByPolicy.length > 0) {
        if (!this.valueCheckerService.isEmptyNullOrUndefined(apiClient.policies)) {
          for (let police of this.state.filterByPolicy) {
            isMatch = apiClient.policies.toLowerCase().includes(police.toLowerCase());
            if (isMatch) {
              break;
            }
          }
        }
        else {
          isMatch = false;
        }
      }

      return isMatch;
    });
  }
}
