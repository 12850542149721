import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ODataService } from '../../../core/api/odata/odata.service';
import { ODataConfig } from '../../../core/api/odata/odataconfig';
import { merchantEventProps, MerchantEvent } from '../merchantevent';
import { ODataFilter } from '../../../core/api/odata/odatafilter';
import { LoadingService } from '../../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { RouteConstants } from '../../../core/constants/constants';
import { MerchantEventService } from '../../../services/merchantevent.service';
import { apiPathConstants } from '../../../core/api/apipathconstants';
import { merchantEventStateNames } from '../state';
import { CollectionTransformerService } from '../../../core/collections/collectiontransformer.service';

@Component({
  selector: 'app-editmerchantevent',
  templateUrl: './editmerchantevent.component.html',
  styleUrls: ['./editmerchantevent.component.scss']
})
export class EditMerchantEventComponent implements OnInit {
  merchantEvent: MerchantEvent;
  merchantEventStateNames = this.collectionTransformerService.mapToArray(merchantEventStateNames);

  constructor(private activatedRoute: ActivatedRoute, private oDataService: ODataService,
    private loadingService: LoadingService, private merchantEventService: MerchantEventService,
    private router: Router, private collectionTransformerService: CollectionTransformerService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let merchantEventId = params['merchantEventId'];
      this.getMerchantEvent(merchantEventId);
    })
  }

  save() {
    this.loadingService.setLoading();
    this.merchantEventService.saveMerchantEventState(this.merchantEvent)
      .pipe(finalize(() => this.loadingService.clearLoading())).subscribe(() => {
        this.goToMerchantEvents();
      });
  }

  goToMerchantEvents() {
    this.router.navigate([RouteConstants.merchantEvents]);
  }

  private getMerchantEvent(merchantEventId: number) {
    let oDataConfig: ODataConfig = {
      query: apiPathConstants.merchantEvent,
      filters: [new ODataFilter(merchantEventProps.MerchantEventId, merchantEventId.toString())]
    };

    this.loadingService.setLoading();

    this.oDataService.get<MerchantEvent[]>(oDataConfig)
      .pipe(finalize(() => {
        this.loadingService.clearLoading();
      }))
      .subscribe(data => {
        this.merchantEvent = data.value[0];
      });
  }
}
