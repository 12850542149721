import { ExecutionResult } from 'graphql';
import { AddressVerificationResponse } from '../../components/addresscheck/addresscheck.component';

export let addressVerificationResponse: ExecutionResult<AddressVerificationResponse> = {
  data: {
    addressVerifications:
      [
        {
          lwcId: 14978450001900,
          retryAttempts: 1,
          comments: 'Indexed Merchant LWC_ID: 2001100000',
          verifiedDate: '2020-09-10',
        },
        {
          lwcId: 15078450001400,
          retryAttempts: 15,
          comments: 'Indexed Merchant LWC_ID: 4001100000',
          verifiedDate: '2000-05-12',
        },
        {
          lwcId: 18978450000000,
          retryAttempts: 8,
          comments: 'Indexed Merchant LWC_ID: 9001100000',
          verifiedDate: '2010-06-20',
        }
      ]
  }
}
