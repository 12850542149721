import { AusQuarantinedMerchantDetails } from "../../components/quarantinedmerchants/ausquarantinedmerchantdetails";

export let quarantinedMerchantsResponse: AusQuarantinedMerchantDetails[] = [
    {
        LWC_ID: 1001201065,
        ABN: "54051283900",
        ChainName: "Beme",
        DataCompletnessScore: 100,
        IsQuarantinedDateTime: "2019-06-18T02:13:00",
        MerchantPresence: "Unspecified",
        MerchantType: "Branch",
        IsQuarantinedComment: "Quarantined as this is an Auto Indexed record",
        PrimaryName: "Beme (Warnbro)",
        PrimaryCategory: "Shopping >> Clothing",
        PrimaryAddress: "Rattan Cv, Warnbro, Western Australia, Australia 6169",
        LocatedIn_Name: "Warnbro Centre",
        LocatedIn_Id: 1016,
        StreetViewAvailable: false,
        Phone: "(08) 9594 0298",
        URL: "https://www.beme.com.au",
        CALs: "BEME WARNBRO WA",
        SearchCount: 1200064848
    },
    {
        LWC_ID: 1001201063,
        ABN: "48009669836",
        ChainName: "CNW Electrical",
        DataCompletnessScore: 95,
        IsQuarantinedDateTime: "2019-06-18T02:12:00",
        MerchantPresence: "Unspecified",
        MerchantType: "Branch",
        IsQuarantinedComment: "Quarantined as this is an Auto Indexed record",
        PrimaryName: "CNW Electrical (Derwent Park)",
        PrimaryCategory: "Shopping >> Consumer Electronics >> Electronics",
        PrimaryAddress: "15a Lampton Ave, Derwent Park TAS 7009, Australia",
        LocatedIn_Name: null,
        LocatedIn_Id: null,
        StreetViewAvailable: true,
        Phone: null,
        URL: "https://shop.cnw.com.au",
        CALs: "CNW ELECTRICAL AND D DERWENT PARK TAS",
        SearchCount: 454864848
    },
    {
        LWC_ID: 1001201061,
        ABN: "21520143590",
        ChainName: "Barry Plant",
        DataCompletnessScore: 100,
        IsQuarantinedDateTime: "2019-06-18T02:09:00",
        MerchantPresence: "Unspecified",
        MerchantType: "Branch",
        IsQuarantinedComment: "Quarantined as this is an Auto Indexed record",
        PrimaryName: "Barry Plant (Berwick)",
        PrimaryCategory: "Services >> Real Estate",
        PrimaryAddress: "100 High St, Berwick VIC 3806 AUS",
        LocatedIn_Name: "Berwick Marketplace",
        LocatedIn_Id: 124,
        StreetViewAvailable: false,
        Phone: "(03) 9707 1400",
        URL: "http://www.barryplant.com.au/berwick",
        CALs: "BARRY PLANT BERWICK R BERWICK VIC",
        SearchCount: 895184184184
    }
]
