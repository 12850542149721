import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { manageLocationsResponce, manageLocationEditResponce } from "../mockjsonresponses/managelocationsresponce";
import { Observable } from "rxjs";
import { HttpMethod } from "../../core/coreenums/coreenums";

export function manageLocationsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];
    let includeManageLocations = false;
    let includesFormattedPhoneNumber = false;
    if (request.body && request.body.query) {
        includeManageLocations = request.body.query.includes('{\n  data: locatedIn {');
        includesFormattedPhoneNumber = request.body.query.includes('formatted_phone_number');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includeManageLocations && includesFormattedPhoneNumber) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: {...manageLocationsResponce}
            }));
            resp.complete();
        });
    }

    let includeUpsertManageLocation = false;
    if (request.body && request.body.query) {
        includeUpsertManageLocation = request.body.query.includes('upsertLocatedIn');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includeUpsertManageLocation) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: {...manageLocationEditResponce}
            }));
            resp.complete();
        });
    }

    let includeDeleteManageLocation = false;
    if (request.body && request.body.query) {
        includeDeleteManageLocation = request.body.query.includes('deleteLocatedIn');
    }
    if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includeDeleteManageLocation) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: { data: {deleteLocatedIn: {}} }
            }));
            resp.complete();
        });
    }
}
