import { finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Injector } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { TagsState } from './tags.state';
import { TagsService } from '../../services/tags.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { ApiClientDataService } from '../apiclients/apiclientsdata.service';
import { ApiClientDTO } from '../apiclients/apiclientdto';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { commaSpace } from '../../core/constants/constants';
import { TagDisplay, Tag, ApiClientName } from './tags.models';
import { MatSelectChange } from '@angular/material/select';
import { DialogService } from '../../core/uiservices/dialog.service';
import { LoginStateService } from '../../core/auth/loginstate.service';
import { RoleType } from '../../users/userenums';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends BaseStateComponent<TagsState> implements OnInit {
  apiClients: ApiClientDTO[] = [];
  filteredApiClients: ApiClientDTO[] = [];
  editingRowIndex: number = -1;
  apiClientNames: ApiClientName[] = [];
  filterByTagLabel: string;
  tags: TagDisplay[] = [];
  filteredApiClientNames: ApiClientName[] = [];
  listOfApiClientNames: string[] = [];
  filterByApiClientName: string;
  isTagMatchTheFilterArray: boolean[] = [];
  isAdminRole: boolean;
  maxDescriptionLength: number = 4000;
  private warningOfDeleteTag: string = "Are you sure? Deleting a tag will remove its reference from all associated merchants, chains, mapping rules, and api clients.";

  constructor(injector: Injector, private tagsService: TagsService, private loadingService: LoadingService,
    private notificationService: NotificationService, private apiClientDataService: ApiClientDataService,
    private dateTimeService: DateTimeService, private valueCheckerService: ValueCheckerService,
    private dialogService: DialogService, private loginStateService: LoginStateService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      tagsDisplay: []
    };
    this.restoreState();
    this.setApiClients();
  }

  addNewRow() {
    this.state.tagsDisplay.push({
      addNewTagLabel: true,
      apiClientCodes: []
    });
    this.state.tagsDisplay = [...this.state.tagsDisplay];
    this.editingRowIndex = this.state.tagsDisplay.length - 1;
  }

  deleteNewTag(row: Tag) {
    this.editingRowIndex = -1;
    this.state.tagsDisplay = this.state.tagsDisplay.filter(t => t != row);
  }

  tagUpdatedOrNewTagCreated(row: TagDisplay) {
    this.loadingService.setLoading();
    let apiClientTag = {
      ID: !this.valueCheckerService.isNullOrUndefined(row.addNewTagLabel) ? 0 : row.id,
      TagLabel: row.tagLabel,
      ApiClientTag: row.apiClientCodes.length === 0 ? [] : row.apiClientCodes,
      description: row.description
    };
    this.tagsService.tagUpdatedOfNewTagCreated(apiClientTag)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.setApiClients();
        this.editingRowIndex = -1;
        if (!this.valueCheckerService.isNullOrUndefined(row.addNewTagLabel)) {
          this.notificationService.notifySuccess('Tag created successfully');
          delete row.addNewTagLabel;
        }
        else {
          this.notificationService.notifySuccess('Tag is updated');
        }
      });
  }

  handleClearInputForTagLabel() {
    this.filterByTagLabel = '';
    this.filterTags();
  }

  setFilterByTagLabel(event: KeyboardEvent) {
    this.filterByTagLabel = (<HTMLInputElement>event.target).value;
    this.filterTags();
  }

  setFilterByApiClientName(event: MatSelectChange) {
    this.filterByApiClientName = event.value;
    this.filterTags();
  }

  deleteTag(tag: Tag, tagIndex: number) {
    this.dialogService.openConfirmDialog(this.warningOfDeleteTag)
      .afterClosed()
      .subscribe(value => {
        if (value) {
          this.tagsService.deleteTag(tag.id)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(() => {
              this.state.tagsDisplay.splice(tagIndex, 1);
              this.state.tagsDisplay = this.tags = [...this.state.tagsDisplay];

              this.apiClientNames.splice(tagIndex, 1);
              this.apiClientNames = this.filteredApiClientNames = [...this.apiClientNames];

              this.notificationService.notifySuccess(`Tag deleted successfully`);
            });
        }
      });
  }

  private setTags() {
    this.loadingService.setLoading();
    this.tagsService.getTags()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.state.tagsDisplay = this.tags = response;
          this.setApiClientIds();
        }
      });
  }

  private setApiClientIds() {
    for (let tag of this.state.tagsDisplay) {
      tag.apiClientCodes = [];
      for (let i = 0; i < tag.apiClientTag.length; i++) {
        let apiClient = this.apiClients.find(t => t.clientId === tag.apiClientTag[i].apiClientId);
        if (apiClient && !tag.apiClientCodes.includes(apiClient.clientCode)) {
          tag.apiClientCodes.push(apiClient.clientCode);
        }
      }
    }
    this.setClientNames();
  }

  private setApiClients() {
    this.loadingService.setLoading();
    this.apiClients = [];
    this.filteredApiClients = [];
    this.apiClientDataService.getApiClients(true)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(responce => {
        this.loadingService.clearLoading();
        if (responce && responce.data && responce.data && responce.data.length > 0) {
          this.apiClients = responce.data;
          this.filteredApiClients = this.filterDublicates(responce.data);
          this.setTags();
        }
      });
  }

  private filterDublicates(apiClients: ApiClientDTO[]) {
    let filteredApiClients: ApiClientDTO[] = this.filterApiClientsWithSameClientCode(apiClients);
    filteredApiClients = this.filterApiClientsWithSameGroupClientName([...filteredApiClients]);
    return filteredApiClients;
  }

  private filterApiClientsWithSameGroupClientName(apiClients: ApiClientDTO[]) {
    let filteredApiClients: ApiClientDTO[] = [];
    for (let apiClient of apiClients) {
      if (apiClient.apiClientGroup && apiClient.apiClientGroup.clientName) {
        let apiClientsWithSameClientName = apiClients.filter(t => !this.valueCheckerService.isEmptyNullOrUndefined(t.apiClientGroup)
          && t.apiClientGroup.clientName === apiClient.apiClientGroup.clientName);

        apiClients = apiClients.filter(t => !this.valueCheckerService.isEmptyNullOrUndefined(t.apiClientGroup)
          && t.apiClientGroup.clientName != apiClient.apiClientGroup.clientName);

        if (apiClientsWithSameClientName.length > 1) {
          apiClientsWithSameClientName.sort((a, b) => {
            return this.dateTimeService.compareDatesAsc(a.createDate.trim(), b.createDate.trim());
          });
          apiClientsWithSameClientName.splice(1);
        };
        if (apiClientsWithSameClientName.length > 0) {
          filteredApiClients.push(apiClientsWithSameClientName[0]);
        };
      }
    }
    return filteredApiClients;
  }

  private filterApiClientsWithSameClientCode(apiClients: ApiClientDTO[]) {
    let filteredApiClients: ApiClientDTO[] = [];
    for (let apiClient of apiClients) {
      let apiClientsWithSameClientCode = apiClients.filter(t => t.clientCode === apiClient.clientCode);

      apiClients = apiClients.filter(t => t.clientCode != apiClient.clientCode);
      if (apiClientsWithSameClientCode.length > 1) {
        apiClientsWithSameClientCode.sort((a, b) => {
          return this.dateTimeService.compareDatesAsc(a.createDate.trim(), b.createDate.trim());
        });
        apiClientsWithSameClientCode.splice(1);
      };
      if (apiClientsWithSameClientCode.length > 0) {
        filteredApiClients.push(apiClientsWithSameClientCode[0]);
      };
    }

    return filteredApiClients;
  }

  private setClientNames() {
    for (let k = 0; k < this.state.tagsDisplay.length; k++) {
      let tag = this.state.tagsDisplay[k];
      this.apiClientNames[k] = null;

      for (let i = 0; i < tag.apiClientCodes.length; i++) {
        let code = tag.apiClientCodes[i];
        let apiClient = this.filteredApiClients.find(t => t.clientCode === code);

        if (apiClient && apiClient.apiClientGroup && apiClient.apiClientGroup.clientName) {
          if (!this.apiClientNames[k]) {
            this.apiClientNames[k] = {
              clientNames: apiClient.apiClientGroup.clientName,
              rowIndex: this.state.tagsDisplay.indexOf(tag)
            };
          };
          if (!this.apiClientNames[k].clientNames.includes(apiClient.apiClientGroup.clientName)) {
            this.apiClientNames[k].clientNames = this.apiClientNames[k].clientNames +
              commaSpace + apiClient.apiClientGroup.clientName;
          }
          if ((!this.listOfApiClientNames.includes(apiClient.apiClientGroup.clientName))) {
            this.listOfApiClientNames.push(apiClient.apiClientGroup.clientName);
          }
        };
      }
    };
    this.filteredApiClientNames = [...this.apiClientNames];
    this.isAdminRole = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin]);
  }

  private filterTags() {
    this.isTagMatchTheFilterArray = [];
    this.state.tagsDisplay = this.tags.filter((tag: TagDisplay) => {
      let isMatch = true;

      if (!this.valueCheckerService.isEmptyNullOrUndefined(this.filterByTagLabel)) {
        isMatch = tag.tagLabel.toLowerCase().includes(this.filterByTagLabel.toLowerCase());
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.filterByApiClientName)) {
        let indexOfTag = this.tags.indexOf(tag);
        let apiClientNames = this.apiClientNames[indexOfTag];

        if (apiClientNames && apiClientNames.clientNames) {
          isMatch = apiClientNames.clientNames.toLowerCase().includes(this.filterByApiClientName.toLowerCase());
        }
        else {
          isMatch = false;
        }
      }
      if (isMatch && this.filterByApiClientName === undefined) {
        this.filteredApiClientNames = this.apiClientNames;
      }
      this.isTagMatchTheFilterArray.push(isMatch);

      return isMatch;
    });
    this.filterApiClientNames();
  }

  private filterApiClientNames() {
    if (this.isTagMatchTheFilterArray.includes(false)) {
      let copyOfApiClientNames = [...this.apiClientNames];

      for (let i = 0; i < copyOfApiClientNames.length; i++) {
        if (this.isTagMatchTheFilterArray[i] === false) {
          delete copyOfApiClientNames[i];
        }
      }

      this.filteredApiClientNames = Object.values(copyOfApiClientNames);

      for (let i = 0; i < this.filteredApiClientNames.length; i++) {
        if (!this.valueCheckerService.isNullOrUndefined(this.filteredApiClientNames[i])) {
          this.filteredApiClientNames[i].rowIndex = i;
        }
      }
    }
  }
}
