import { CALParserResult } from '../models/calparserresult';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { apiPathConstants } from '../core/api/apipathconstants';
import { SplitCalRequest } from '../models/splitcal';

@Injectable()
export class CalSplitterService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) {}

  getCalParserResult(splitCalRequest: SplitCalRequest) {
    return this.apiClient.post<CALParserResult>(this.apiService
      .buildUrl(apiPathConstants.splitCal), splitCalRequest);
  }
}
