import { ExecutionResult } from "graphql";
import { UpdateSearchFeedbacksResponse, SearchFeedbackResponse } from "../../services/searchfeedback.service";

export let searchFeedbackResponse: ExecutionResult<SearchFeedbackResponse> = {
  data: {
    items: [
       {
         searchFeedbackId: 2762,
         createDate:"2019-11-02 05:21:33.100",
         userComment:"Aldi, South Melbourne, VIC ",
         mlConfidence: 0.727773606777191,
         mlUseful: true,
         isIndexed: true,
         email: 'den@example.com',
         clientName: 'Oliver',
         searchCount: 1.33322,
         calFeedbackCount: 1.33322,
         searchFeedbackHumanClassification: 'classification',
         lwcComment: 'comment1',
         cal: "Eftpos 25 04 15 46 Aldi Stores South"
        },
        {
          searchFeedbackId: 2761,
          createDate:"2019-11-01 01:27:04.347",
          userComment:"Aldi, South Melbourne, VIC 1",
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          isIndexed: false,
          email: 'lyck@example.com',
          clientName: 'George',
          searchCount: 1.33322,
          calFeedbackCount:1.33322,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2',
          cal: "Eftpos 25 04 15 46 Aldi Stores South"
        },
        {
          searchFeedbackId: 2760,
          createDate:"2019-10-31 19:53:20.297",
          userComment:"Aldi, South Melbourne, VIC 2",
          mlConfidence: 0.663061022758484,
          mlUseful: false,
          isIndexed: false,
          email: 'lyck@example.com',
          clientName: 'George',
          searchCount: 133322,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment3',
          cal: "Eftpos 25 04 15 46 Aldi Stores South"
        },
      ],
      totalCount: 500
    }
  }

  export let searchfeedbackEditResponse:  ExecutionResult<unknown> = { data: {upsertsearchFeedback: {}}};

  export let updateSearchFeedbacksResponse: ExecutionResult<UpdateSearchFeedbacksResponse> = {
    data: {
      updateSearchFeedback: [
        {
          cal: "Jane Store for unittest",
          searchFeedbackId: 38779,
          isIndexed: false,
          createDate: "2021-06-02T09:20:31.217+10:00",
          userComment: "New Comment",
          email: "jane@lookwhoscharging.com",
          mlConfidence: 0.05,
          mlUseful: false,
          clientName: "LWC internal API",
          searchCount: 0,
          calFeedbackCount: 1,
          searchFeedbackHumanClassification: null,
          lwcComment: "Local Test"
        },
        {
          cal: "Store 2",
          searchFeedbackId: 38778,
          isIndexed: false,
          createDate: "2001-06-02T09:20:31.217+10:00",
          userComment: "New Comment 1",
          email: "sam@lookwhoscharging.com",
          mlConfidence: 0.55,
          mlUseful: false,
          clientName: "LWC internal API 1",
          searchCount: 12,
          calFeedbackCount: 1,
          searchFeedbackHumanClassification: null,
          lwcComment: "Local Test 1"
        },
        {
          cal: "Store",
          searchFeedbackId: 38777,
          isIndexed: true,
          createDate: "2011-06-02T09:20:31.217+10:00",
          userComment: "New Comment 2",
          email: "victor@lookwhoscharging.com",
          mlConfidence: 0.15,
          mlUseful: false,
          clientName: "LWC internal API 2",
          searchCount: 15,
          calFeedbackCount: 1,
          searchFeedbackHumanClassification: null,
          lwcComment: "Local Test 2"
        }
      ]
    }
  };
