import { Tidmid } from '../../tidmid/managetidmid/tidmid';

export let tidmidrecordresponse: Partial<Tidmid> = {
        TIDMID_id: 1,
        LWC_ID: 4234234,
        TID: 'XAEWW',
        CreateDateTime: '2018-07-02T02:40:00',
        Source: 'LWC_User_Data',
        Comments: 'Updated Row'
};
