import { UserStatusType, RegionType } from '../userenums';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { UserService } from '../applicationuser.service';
import { ApplicationUser } from '../applicationuser';
import { Component, OnInit } from '@angular/core';
import { RegionStateService } from '../../core/cache/regionstate.service';
import { NameValue } from '../../core/models/namevalue';

@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.scss']
})

export class ManageUsersComponent implements OnInit {
  applicationUsers: ApplicationUser[];
  filteredApplicationUsers: ApplicationUser[];
  regionNames: NameValue<RegionType>[] = [];
  selectedRegion: number;
  emailIdFilter: string = '';
  showInactiveUsers: boolean = false;

  constructor(private applicationUserService: UserService, private notificationService: NotificationService,
    private regionStateService: RegionStateService, private loadingService: LoadingService, private datetimeService: DateTimeService) {
  }

  ngOnInit() {

    this.regionNames = this.regionStateService.getAllRegionsNameValue();
    this.applicationUserService.getApplicationUsers().subscribe(applicationUsers => {
      this.applicationUsers = this.filteredApplicationUsers = applicationUsers;
    });

    this.applyFilters();
  }

  manageUserStatus(applicationUser: ApplicationUser) {
    this.toggleUserStatus(applicationUser);

    this.loadingService.setLoading();

    this.applicationUserService.editUser(applicationUser)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('User status changed successfully.');
      });
  }

  getUserStatusText(applicationUserStatus: UserStatusType): string {
    return this.isUserActive(applicationUserStatus) ? 'Active' : 'Inactive';
  }

  getUserRegionsText(applicationUserRegions: RegionType[]): string {
    let userRegions: string[] = [];

    applicationUserRegions.forEach(applicationUserRegion => {
      userRegions.push(this.regionStateService.getRegionName(applicationUserRegion));
    });

    return userRegions.join(', ');
  }

  formatDatetime(datetime: string) {
    return this.datetimeService.format(datetime);
  }

  isUserActive(applicationUserStatus: UserStatusType): boolean {
    return this.applicationUserService.isUserActive(applicationUserStatus);
  }

  onEmailInputClear() {
    this.emailIdFilter = '';
    this.applyFilters();
  }

  onFilterChange(event: KeyboardEvent) {
    let filter: string = (<HTMLInputElement>event.srcElement).value.toLowerCase();
    this.emailIdFilter = filter;
    this.applyFilters();
  }

  applyFilters() {
    if(!this.applicationUsers){
      return;
    }

    this.filteredApplicationUsers = this.applicationUsers.filter(user => {
      let regionResult = user.Regions.indexOf(this.selectedRegion) !== -1 || !this.selectedRegion;

      let emailId = user.EmailId.toLowerCase().indexOf(this.emailIdFilter) !== -1;

      let userStatus = this.showInactiveUsers || user.Status === UserStatusType.Active;

      return emailId && regionResult && userStatus;
    });
  }

  private toggleUserStatus(applicationUser: ApplicationUser) {
    if (Number(applicationUser.Status) === UserStatusType.Active) {
      applicationUser.Status = UserStatusType.InActive;
    } else {
      applicationUser.Status = UserStatusType.Active;
    }
  }
}

