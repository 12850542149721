import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { calCount } from '../mockjsonresponses/calcountresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';

export function missedCalsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes(apiPathConstants.missedCals) && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...calCount]
      }));
      resp.complete();
    });
  }
}
