import { Component, OnInit, Injector} from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { RedisCacheLookupState } from './rediscachelookup.state';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { RedisService } from '../../services/redis.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { StringService } from '../../core/formatting/string.service';
import { RadisDeleteSharedCalsRequest, RedisDeleteRequest } from './rediscachelookup.models';
import { CacheType } from './rediscachelookupdto';
import { emptyString } from '../../core/constants/constants';

@Component({
    selector: 'app-rediscachelookup',
    templateUrl: './rediscachelookup.component.html'
})

export class RedisCacheLookupComponet  extends BaseStateComponent<RedisCacheLookupState> implements OnInit {
    cacheTypes: CacheType[];

    constructor(injector: Injector, private loadingService: LoadingService, private redisService: RedisService,
        private notificationService: NotificationService, private valueCheckerService: ValueCheckerService,
        private stringService: StringService){
        super(injector);
    }

    ngOnInit() {
      this.redisService.getCacheType()
      .subscribe((resp) => {
        this.cacheTypes = resp.data;
      });

      this.state = {
        redisLookupRequest: {
          CAL: null,
          key: null,
        },
        redisCacheLookupResponse: null,
        deleteLwcIds: emptyString,
        deleteCals: emptyString,
        cacheType: emptyString
      }
      this.restoreState();
    }

    isLookupDisabled() {
        if(this.state.redisLookupRequest.key || this.state.redisLookupRequest.CAL) {
            return false;
        }
        else {
            return true;
        }
    }

    submitForm() {
        this.loadingService.setLoading();
        this.redisService.lookup(this.state.redisLookupRequest)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((res) => {
            this.state.redisCacheLookupResponse = null;
            if(res && res.Result) {
                this.state.redisCacheLookupResponse = res.Result;// JSON.parse(res.Result);
            }
        });

    }

    isValidLwcIdsForDelete() {
        if(this.state.deleteLwcIds && this.lwcIdsAreNumbers() && this.lwcIdsLengthAreValid()) {
           return false;
        }
        return true;
    }

    isValidCalForDelete() {
      if ((this.state.deleteCals.trim() && this.state.cacheType.trim()) !== emptyString) {
        return false;
      }

      return true;
    }

    deleteLwcIds() {
        this.loadingService.setLoading();

        let lwcIdsForDeleteConvertedToNumbers = this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.deleteLwcIds).map(t => +t);
        let redisDeleteRequest: RedisDeleteRequest = {
          LwcIds: lwcIdsForDeleteConvertedToNumbers
        };

        this.redisService.delete(redisDeleteRequest)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(() => {
          this.state.deleteLwcIds = emptyString;
          this.notificationService.notifySuccess();
        });
    }

    deleteCals() {
      this.loadingService.setLoading();

      let calForDeleteConvertedToNumbers = this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.deleteCals);
      let radisDeleteSharedCalsRequest: RadisDeleteSharedCalsRequest = {
        SharedCals: calForDeleteConvertedToNumbers,
        TypeOfCaches: this.state.cacheType
      }

      this.redisService.deleteSharedCals(radisDeleteSharedCalsRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.state.deleteCals = emptyString;
        this.state.cacheType = emptyString;
        this.notificationService.notifySuccess();
      });
    }

    lwcIdsAreNumbers() {
      let lwcIdsForDelete = this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.deleteLwcIds);

      return lwcIdsForDelete.every(s => this.valueCheckerService.isNumber(s));
    }

    lwcIdsLengthAreValid() {
      let lwcIdsForDelete = this.stringService.getListOfSubstringWithoutEmptyLastLine(this.state.deleteLwcIds);

      return lwcIdsForDelete.every(s => s.length <= 10);
    }
}
