import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tidmidrecordsresponse } from '../mockjsonresponses/gettidmidsresponse';
import { tidmidrecordresponse } from '../mockjsonresponses/tidmidrecordresponse';
export function tidmidBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if ((cleanUrl.includes('tidmid'))
    && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: tidmidrecordsresponse
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('tidmid/')) && method === 'PUT') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...tidmidrecordresponse}
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('tidmid/')) && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('tidmid')) && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...tidmidrecordresponse}
      }));
      resp.complete();
    });
  }
}
