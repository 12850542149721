import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { whoisResponse } from '../mockjsonresponses/whoisresponse.json';

export function whoislookupBackendService (url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  // tslint:disable-next-line:prefer-const
  let cleanUrl = url.split('?')[0];

  if (cleanUrl.endsWith('whois') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: whoisResponse
      }));
      resp.complete();
    });
  }
}
