import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { brokenLinksResponse } from '../mockjsonresponses/brokenlinks.response';

export function brokenLinksBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?');
  let includeUrlVerification = false;

  if (request.body && request.body.query) {
    includeUrlVerification = request.body.query.includes('urlVerification');
  }
  if (cleanUrl[0].includes(apiPathConstants.graphql) && method === 'POST' && includeUrlVerification) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: brokenLinksResponse
      }));

      resp.complete();
    });
  }
  if (cleanUrl[0].includes(apiPathConstants.eventingVerify) && cleanUrl[1] == 'property=url' && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));

      resp.complete();
    })
  }
}
