import { Injectable } from '@angular/core';
import { ValueCheckerService } from './valuechecker.service';
import * as moment from 'moment';

@Injectable()
export class ComparerService {
  constructor(private valueCheckerService: ValueCheckerService) { }

  compareString(leftValue: string, rightValue: string) {
    if (this.areBothValuesNullOrUndefined(leftValue, rightValue)) {
      return 0;
    }

    if (leftValue === rightValue) {
      return 0;
    }

    if (this.valueCheckerService.isNullOrUndefined(leftValue)) {
      return -1;
    }

    if (this.valueCheckerService.isNullOrUndefined(rightValue)) {
      return 1;
    }

    return this.compare<string>(leftValue.toLowerCase(), rightValue.toLowerCase());
  }

  compareNumber(leftValue: number, rightValue: number) {
    if (this.areBothValuesNullOrUndefined(leftValue, rightValue)) {
      return 0;
    }

    if (leftValue === rightValue) {
      return 0;
    }

    if (this.valueCheckerService.isNullOrUndefined(leftValue)) {
      return -1;
    }

    if (this.valueCheckerService.isNullOrUndefined(rightValue)) {
      return 1;
    }

    return this.compare<number>(leftValue, rightValue);
  }

  compareBoolean(leftValue: boolean, rightValue: boolean) {
    if (this.areBothValuesNullOrUndefined(leftValue, rightValue)) {
      return 0;
    }

    if (leftValue === rightValue) {
      return 0;
    }

    if (this.valueCheckerService.isNullOrUndefined(leftValue)) {
      return -1;
    }

    if (this.valueCheckerService.isNullOrUndefined(rightValue)) {
      return 1;
    }

    if (rightValue === true) {
      return -1;
    }

    if (leftValue === true) {
      return 1;
    }

    return 0;
  }

  compareDate(firstDate: string, secondDate: string) {
    if (moment(firstDate).isBefore(secondDate)) {
      return 1;
    }
    if (moment(firstDate).isAfter(secondDate)) {
      return -1;
    }
    return 0;
  }

  private compare<T>(leftValue: T, rightValue: T) {
    if (leftValue < rightValue) {
      return -1;
    }

    if (leftValue > rightValue) {
      return 1;
    }

    return 0;
  }

  private areBothValuesNullOrUndefined(leftValue: unknown, rightValue: unknown) {
    return this.valueCheckerService.isNullOrUndefined(leftValue) && this.valueCheckerService.isNullOrUndefined(rightValue);
  }
}
