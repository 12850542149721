import { DateTimeService } from './../../core/uiservices/datetime.service';
import { Component, OnInit, Injector } from '@angular/core';
import { DataScrapingService } from '../../services/datascraping.service';
import { ManageDataScrapingState } from './managedatascrapingstate';
import { BaseStateComponent } from '../basestate/basestate.component';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { DataScraping } from './datascraping';

@Component({
    selector: 'app-managedatascraping',
    templateUrl: './managedatascraping.component.html',
    styleUrls: ['./managedatascraping.component.scss']
})
export class ManageDataScrapingComponent extends BaseStateComponent<ManageDataScrapingState> implements OnInit {
    recordsAreBeingLoaded: boolean = true;

    constructor(injector: Injector, private manageDataScrapingService: DataScrapingService,
        private loadingService: LoadingService, private notificationService: NotificationService, private DateTimeService: DateTimeService) {
        super(injector);
    }

    ngOnInit() {
        this.state = {
            displayResults: []
        }
        this.refresh();
    }

    refresh() {
        this.recordsAreBeingLoaded = true;
        this.manageDataScrapingService.getManageDataScraping()
            .subscribe(response => {
                if (response) {
                  for(let value of response) {
                    value.scrapingCompletedDate = this.DateTimeService.formatWithHours(value.scrapingCompletedDate);
                    value.scrapingStartedDate = this.DateTimeService.formatWithHours(value.scrapingStartedDate);
                  }
                    this.state.displayResults = response;
                    this.recordsAreBeingLoaded = false;
                }
            });
    }

    startScrape(dataScraping: DataScraping) {
        this.loadingService.setLoading();
        this.manageDataScrapingService.startScrape(dataScraping.chainId).pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(() => {
                this.notificationService.notifySuccess('Scrape Started');
            });
    }
}
