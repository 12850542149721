import { Component, ViewChild } from "@angular/core";
import { LocatedInDto } from "../managelocationsdto";
import { ManageLocationService } from "../../../services/managelocations.service";
import { Router } from "@angular/router";
import { RouteConstants } from "../../../core/constants/constants";
import { LoadingService } from "../../../core/uiservices/loading.service";
import { finalize } from "rxjs/operators";
import { NgForm, NgModel } from "@angular/forms";
import { NotificationService } from "../../../core/uiservices/notification.service";
import { FormatService } from "../../../core/formatting/format.service";
import { OpeningHours } from "../../../models/searchmodelsv4";
import { RetrieveExperianAddressesService } from "../../../services/retrieveexperianaddress.service";

@Component({
    selector: 'app-editlocation.component',
    templateUrl: './editlocation.component.html',
    styleUrls: ['./editlocation.component.scss']
})
export class EditLocationComponent {
    editManageLocation: LocatedInDto;
    editManageLocationOpeningTimes: OpeningHours[] = [];
    @ViewChild('editEditLocationForm') editEditLocationForm: NgForm;

    @ViewChild('merchantAddress') merchantAddress: NgModel;

    experianAddresses: string[] = [];

    constructor(private manageLocationsService: ManageLocationService, private router: Router, private loadingServcice: LoadingService,
        private notificationService: NotificationService, private formatService: FormatService,
        private retrieveExperianAddressesService: RetrieveExperianAddressesService) { }

    ngOnInit() {
        this.editManageLocation = this.manageLocationsService.getLocatedIn();
        if (!this.editManageLocation) {
            this.router.navigate([RouteConstants.manageLocations]);
        } else {
            this.editManageLocationOpeningTimes = JSON.parse(this.editManageLocation.openingTimes);
        }
    }

    ngAfterViewInit() {
        this.retrieveExperianAddressesService.retrieveExperianAddresses(this.merchantAddress, () => this.experianAddresses)
            .subscribe(response => {
                if (response && response.data.length > 0) {
                    this.experianAddresses = response.data;
                }
                else {
                    this.experianAddresses = [];
                }
            });
    }

    cancel() {
        this.router.navigate([RouteConstants.manageLocations]);
    }

    save() {
        if (this.editEditLocationForm.invalid) {
            this.notificationService.notifyError('Please fill in required field');
            return;
        }
        this.deleteUnnecessaryProperties();
        this.manageLocationsService.save(this.editManageLocation)
            .pipe(finalize(() => this.loadingServcice.clearLoading())).subscribe(() => {
                this.router.navigate([RouteConstants.manageLocations]);
            });
    }

    delete() {
        this.manageLocationsService.delete(this.editManageLocation.locatedIn_ID)
            .pipe(finalize(() => this.loadingServcice.clearLoading())).subscribe(() => {
                this.router.navigate([RouteConstants.manageLocations]);
            });
    }

    getYesNo(val: boolean) {
        return this.formatService.formatTrueOrFalseToYesOrNo(val);
    }

    private deleteUnnecessaryProperties() {
        delete this.editManageLocation.addressLine1;
        delete this.editManageLocation.addressSuburb;
        delete this.editManageLocation.addressStateCd;
        delete this.editManageLocation.addressPostcode;
        delete this.editManageLocation.addressCountryCd;
        delete this.editManageLocation.latitude;
        delete this.editManageLocation.longitude;
        delete this.editManageLocation.mapable;
        delete this.editManageLocation.latLonPrecision;
        delete this.editManageLocation.googlePlaceID;
    }
}
