import { DataScraping, DataScrapingDetails } from "../../components/managedatascraping/datascraping";
import { WebScrapingDetailResponse } from "../../components/managedatascraping/viewdata/webscrapingdetail.component";
import { ExecutionResult } from "graphql";
import { IndexingAuditResponse } from "../../components/scrapingdatasources/scrapingdatadto";

export let eventingChainsResponse: DataScraping[] =
  [
    {
      chainId: "https%3a%2f%2fwww.kikki-k.com%2f",
      lwcChainId: 992,
      disabled: true,
      checkedForRemoveDate: null,
      daysBetweenScrapingSessions: 0,
      scrapingCompletedDate: null,
      scrapingStartedDate: null,
      lastScrapedLocality: null,
      chainName: "Shire of Esperance"
    },
    {
      chainId: "https%1t%7f%2fwww.westfield.com.au",
      lwcChainId: 653,
      disabled: false,
      checkedForRemoveDate: true,
      daysBetweenScrapingSessions: 2,
      scrapingCompletedDate: null,
      scrapingStartedDate: null,
      lastScrapedLocality: null,
      chainName: "Caltex"
    },
    {
      chainId: "https%8a%1f%1fwww.colesexpress.com.au",
      lwcChainId: 178,
      disabled: false,
      checkedForRemoveDate: false,
      daysBetweenScrapingSessions: 12,
      scrapingCompletedDate: null,
      scrapingStartedDate: null,
      lastScrapedLocality: null,
      chainName: "JayJays"
    }
  ];

export let manageDataScrapingResponse: DataScrapingDetails = {
  chainInfo: {
    lastScrapedLocality: null,
    scrapingStartedDate: null,
    scrapingCompletedDate: null,
    daysBetweenScrapingSessions: 0,
    checkedForRemoveDate: null,
    disabled: true,
    lwcChainId: 18,
    chainId: "https%3a%2f%2fwww.kikki-k.com%2f",
    chainName: "JayJays",
  },
  configuration: {
    settingsTypeClass: "StoreLocatorSettings",
    storeLocatorUrl: "https://www.coles.com.au/store-locator",
    timeoutBetweenInputAndDropdown: null,
    timeoutAfterDropdown: null,
    timeoutAfterFindButton: null,
    needSimulateInput: null,
    concurrentLocalities: null,
    deleteCookies: null,
    timeoutBeforeInitLoading: 4000,
    scrapingPerSession: null,
    parserTypeClass: "LocationParserService",
    timeoutBetweenRequests: null,
    openingHoursTemplate: null,
    alwaysOpenTemplate: null,
    centreSitePath: null,
    locatedInNameSearchPattern: null,
    logoValueAttribute: null,
    openingHoursStartDay: null
  },
  pathConfigurations: [
    {
      chainId: "https://www.coles.com.au",
      accessTypeClass: "XPathToNode",
      path: "div[1]/p[1]/text()",
      contentGetterClass: "ContentGetterInnerText",
      dataNode: "Address"
    },
    {
      chainId: "https://www.coles.com.au",
      accessTypeClass: "CssPathToNode",
      path: "ul.storeList > li",
      contentGetterClass: "ContentGetterInnerText",
      dataNode: "Common"
    },
    {
      chainId: "https://www.coles.com.au",
      accessTypeClass: "CssPathToNode",
      path: "div.storeHours > ul > li",
      contentGetterClass: "ContentGetterInnerText",
      dataNode: "OpeningHours"
    },
    {
      chainId: "https://www.coles.com.au",
      accessTypeClass: "CssPathToNode",
      path: "div.storeAddress > p > span",
      contentGetterClass: "ContentGetterInnerText",
      dataNode: "PhoneNumber"
    },
    {
      chainId: "https://www.coles.com.au",
      accessTypeClass: "CssPathToNode",
      path: "div.storeAddress > h3",
      contentGetterClass: "ContentGetterInnerText",
      dataNode: "Title"
    }
  ],
  errors: [
    {
      timestamp: "2010-11-12 00:08:52Z",
      message: "Failed to create connection",
      sessionId: "2g10pa9-09ae-430d-94e9-b4d1a665d642"
    },
    {
      timestamp: "2050-11-12 00:08:52Z",
      message: "Connection created successfully",
      sessionId: "59g10pe-0eae-430d-94e9-b4d1a665d142"
    },
    {
      timestamp: "2000-11-12 00:08:52Z",
      message: "Error",
      sessionId: "20s10pa9-09ae-430d-94e9-b4d1s605s600"
    }
  ]
}

export let webScapingDetailResponse: ExecutionResult<WebScrapingDetailResponse> = {
  data: {
    stores: [
      {
        id: 100,
        chainCode: "https%3a%2f%2fwww.myer.com.au",
        title: "Vienna",
        address: "22 Rundle Mall, Adelaide, South Australia, 4000",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "1990-02-15",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: null,
        lwcId: null,
        matchedOn: null
      },
      {
        id: 101,
        chainCode: "https%1a%1f%1fwww.myer.com.au",
        title: "Adelaide",
        address: "22 Rundle Mall, Adelaide, South Australia, 5000",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "2001-05-05",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: null,
        lwcId: null,
        matchedOn: null
      },
      {
        id: 102,
        chainCode: "https%1a%1f%1fwww.myer.com.au",
        title: "Brisbane",
        address: "22 Rundle Mall, Adelaide, South Australia, 8000",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "2020-02-10",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: null,
        lwcId: 1001092100,
        matchedOn: 'Unmatched'
      },
      {
        id: 103,
        chainCode: "https%1a%1f%1fwww.myer.com.au",
        title: "Brisbane",
        address: "22 Rundle Mall, Adelaide, South Australia, 8000",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "2020-02-10",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: null,
        lwcId: 1001092100,
        matchedOn: 'PhoneNumber'
      },
      {
        id: 104,
        chainCode: "https%1a%1f%1fwww.myer.com.au",
        title: "Brisbane",
        address: "22 Rundle Mall, Adelaide, South Australia, 8000",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "2020-02-10",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: null,
        lwcId: 1001092100,
        matchedOn: 'PhoneNumber'
      }, {
        id: 105,
        chainCode: "https%1a%1f%1fwww.myer.com.au",
        title: "Brisbane",
        address: "22 Rundle Mall, Adelaide, South Australia, 8000",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "2020-02-10",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: null,
        lwcId: 1001092100,
        matchedOn: 'Proximity'
      },
      {
        id: 106,
        chainCode: "https%1a%1f%1fwww.myer.com.au",
        title: "Brisbane",
        address: "22 Rundle Mall, Adelaide, South Australia, 8000",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "2020-02-10",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: null,
        lwcId: 1001092100,
        matchedOn: 'Proximity'
      },
      {
        id: 107,
        chainCode: "https%1a%1f%1fwww.myer.com.au",
        title: "Brisbane",
        address: "Rundle Mall, Adelaide, South Australia, 1500",
        openingHours: "[[\"Sunday\",\"11:00 AM - 5:00 PM\"],[\"Monday\",\"9:00 AM - 7:00 PM\"],[\"Tuesday\",\"9:00 AM - 7:00 PM\"],[\"Wednesday\",\"9:00 AM - 7:00 PM\"],[\"Thursday\",\"9:00 AM - 7:00 PM\"],[\"Friday\",\"9:00 AM - 9:00 PM\"],[\"Saturday\",\"9:00 AM - 5:00 PM\"]]",
        suburb: null,
        phoneNumber: null,
        emailAddress: null,
        previousUpdateDate: null,
        lastUpdateDate: "2003-01-15",
        geoLat: null,
        geoLong: null,
        geoCodingResult: null,
        catalogSearchResult: '{"ABN": "89156985046656", "ACN": "null", "MatchedName": "ALDI", "IsMatch": "false"}',
        lwcId: 1001092100,
        matchedOn: null
      }
    ]
  }
}

export let indexingAuditResponse: IndexingAuditResponse[] = [
  {
    message: "Creating a new merchant with a new business name using scraped item 10874. A matching business name (31491589) already exists.",
    createDateTime: "2020-08-13T15:15:54.5172375",
    operationType: "CreateWithNewBusinessName"
  }
]
