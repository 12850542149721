import { OnInit, Injector, Component, Input } from "@angular/core";
import { ChainAuditState } from "./chainauditstate";
import { BaseStateComponent } from "../../basestate/basestate.component";
import { LoadingService } from "../../../core/uiservices/loading.service";
import { ChainAuditService } from "../../../services/chainaudit.service";
import { ChainAudit, ChainAuditDisplay } from "./chainauditdto";
import { finalize, takeUntil } from "rxjs/operators";
import { DateTimeService } from "../../../core/uiservices/datetime.service";
import { ComparerService } from "../../../core/services/comparer.service";

@Component({
  selector: 'app-chainaudit',
  templateUrl: './chainaudit.component.html',
  styleUrls: ['./chainaudit.component.scss']
})

export class ChainAuditComponent extends BaseStateComponent<ChainAuditState> implements OnInit {
  @Input() chainId: number;

  constructor(private loadingService: LoadingService, private chainAuditService: ChainAuditService,
     private dateTimeService: DateTimeService, private comparerService: ComparerService,
    injector: Injector) {
    super(injector)
  }

  ngOnInit() {
    this.state = {
      chainAuditDisplay: []
    };

    this.setChainAudit();
  }

  dateComparator = (firstDate: string, secondDate: string) => {
      return this.comparerService.compareDate(firstDate, secondDate);
  }

  private setChainAudit() {
    this.loadingService.setLoading();
    this.chainAuditService.getChainAudit(this.chainId)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$)).subscribe(result => {
        this.loadingService.clearLoading();
        let chainAuditDisplay: ChainAuditDisplay[] = [];
        for (let chainAudit of result.data) {
          if (chainAudit.patternsAudit && chainAudit.patternsAudit.length > 0) {
            for (let chainPattern of chainAudit.patternsAudit) {
              let auditDisplay: ChainAuditDisplay = this.getBaseChainAuditDisplay(chainAudit);
              auditDisplay.patternType = chainPattern.patternType;
              auditDisplay.searchString = chainPattern.searchString;
              auditDisplay.lookupType = chainPattern.lookupType;
              chainAuditDisplay.push(auditDisplay);
            }
          } else {
            let auditDisplay: ChainAuditDisplay = this.getBaseChainAuditDisplay(chainAudit);
            chainAuditDisplay.push(auditDisplay);
          }
        }
        this.state.chainAuditDisplay = chainAuditDisplay;
        this.state.chainAuditDisplay = this.state.chainAuditDisplay.sort((a, b) => {
          return this.comparerService.compareDate(a.createDateTime.trim(), b.createDateTime.trim());
        });
      });
  }

  private getBaseChainAuditDisplay(chainAudit: ChainAudit): ChainAuditDisplay {
    return {
      chainName: chainAudit.chainName,
      operationType: chainAudit.operationType,
      operationTrigger: chainAudit.operationTrigger,
      createDateTime: this.dateTimeService.formatWithHours(chainAudit.createDateTime),
      nameOfIndexer: chainAudit.nameOfIndexer,
    };
  }
}
