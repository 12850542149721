export let getCalHistoryResponse = {
  "@odata.count": 100,
  value: [
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T09:58:00+11:00",
      "LWC_ID": 1000649284,
      "StatusID": 1,
      "TransactionDescription": "FOUR POINTS BY PERTH",
      "IndexerName": "Stu"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T11:59:00+11:00",
      "LWC_ID": 1001015223,
      "StatusID": 1,
      "TransactionDescription": "CURNUCK INVESTMENTS PT BUSSELTON WA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:00:00+11:00",
      "LWC_ID": 1000713512,
      "StatusID": 1,
      "TransactionDescription": "FENCING AND ROOFING MINCHINBURY NSW",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:01:00+11:00",
      "LWC_ID": 1000798987,
      "StatusID": 1,
      "TransactionDescription": "FENCING AND GATE CENTR GREGORY HILLS NSW",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:01:00+11:00",
      "LWC_ID": 1001015225,
      "StatusID": 1,
      "TransactionDescription": "THE DANCNG GOAT CAFE MUDGEE NSW",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:01:00+11:00",
      "LWC_ID": 1001015221,
      "StatusID": 1,
      "TransactionDescription": "HUSTLE LIVING MANSFIELD PARK SA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:02:00+11:00",
      "LWC_ID": 1001015220,
      "StatusID": 1,
      "TransactionDescription": "FENG FOODS PTY LTD DRYSDALE",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:02:00+11:00",
      "LWC_ID": 1001015223,
      "StatusID": 1,
      "TransactionDescription": "CURNUCK INVESTMENTS PT BUSSELTON WA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:05:00+11:00",
      "LWC_ID": 1001015226,
      "StatusID": 1,
      "TransactionDescription": "DCO NORTHERN BEACHES RURAL VIEW",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:13:00+11:00",
      "LWC_ID": 1000980312,
      "StatusID": 1,
      "TransactionDescription": "FERGUSON GROUP PTY L CRANBOURNE",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:19:00+11:00",
      "LWC_ID": 1000980312,
      "StatusID": 1,
      "TransactionDescription": "FERGUSON GROUP PTY LTD ROWVILLE VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:19:00+11:00",
      "LWC_ID": 1001015228,
      "StatusID": 1,
      "TransactionDescription": "FERGUSON GROUP PTY L NARRE WARREN",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:20:00+11:00",
      "LWC_ID": 1000980312,
      "StatusID": 1,
      "TransactionDescription": "FERGUSON GROUP PTY LTD CRANBOURNE",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:24:00+11:00",
      "LWC_ID": 1000774757,
      "StatusID": 1,
      "TransactionDescription": "MAD MEX FLINDERS LANE MELBOURNE",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:35:00+11:00",
      "LWC_ID": 1001005757,
      "StatusID": 1,
      "TransactionDescription": "FERNWOOD MOONEE POND ASCOT VALE",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:38:00+11:00",
      "LWC_ID": 1001005757,
      "StatusID": 1,
      "TransactionDescription": "FERNWOODMOONEEPOND ASCOT",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:53:00+11:00",
      "LWC_ID": 1000766413,
      "StatusID": 1,
      "TransactionDescription": "DOMAYNE HOMEWARES & NORTH RYDE NSW",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:54:00+11:00",
      "LWC_ID": 1000766413,
      "StatusID": 1,
      "TransactionDescription": "DOMAYNE FURNITURE NO NORTH RYDE",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T12:54:00+11:00",
      "LWC_ID": 1001015233,
      "StatusID": 1,
      "TransactionDescription": "FERTILITY2F 61404606669",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:09:00+11:00",
      "LWC_ID": 1001010175,
      "StatusID": 1,
      "TransactionDescription": "VILLEROYBOC BROOKVALE NSW",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:17:00+11:00",
      "LWC_ID": 1001015238,
      "StatusID": 1,
      "TransactionDescription": "FIELDSANDS CORPORATION MALVERN",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:20:00+11:00",
      "LWC_ID": 1001015167,
      "StatusID": 1,
      "TransactionDescription": "CALTEX FOREST HILL FOREST HILL",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:20:00+11:00",
      "LWC_ID": 1001015239,
      "StatusID": 1,
      "TransactionDescription": "FIFER GROUP PTY LTD BUNDOORA",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:20:00+11:00",
      "LWC_ID": 1001015167,
      "StatusID": 1,
      "TransactionDescription": "CALTEX FOREST HILL FOREST HILL",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:34:00+11:00",
      "LWC_ID": 1000744316,
      "StatusID": 1,
      "TransactionDescription": "FIELDSANDS CORPORATI MALVERN VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:35:00+11:00",
      "LWC_ID": 1000714707,
      "StatusID": 1,
      "TransactionDescription": "BARDOT ROCKHAMP PARK AVENUE",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:35:00+11:00",
      "LWC_ID": 1000955599,
      "StatusID": 1,
      "TransactionDescription": "UTAS - SCH 3 SANDY BAY",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:35:00+11:00",
      "LWC_ID": 1001015226,
      "StatusID": 1,
      "TransactionDescription": "DCO NORTHERN BEACHES RURAL VIEW QLD",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:36:00+11:00",
      "LWC_ID": 1000218988,
      "StatusID": 1,
      "TransactionDescription": "FIG TREE POCKET ECC FIG TREE POCK QLD",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:36:00+11:00",
      "LWC_ID": 1000218988,
      "StatusID": 1,
      "TransactionDescription": "FIG TREE POCKET ECC FIG TREE POCK",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:36:00+11:00",
      "LWC_ID": 1000955599,
      "StatusID": 1,
      "TransactionDescription": "UTAS - SCH 3 SANDY BAY TAS",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:36:00+11:00",
      "LWC_ID": 1000714707,
      "StatusID": 1,
      "TransactionDescription": "BARDOT ROCKHAMP PARK AVENUE",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:37:00+11:00",
      "LWC_ID": 1001015240,
      "StatusID": 1,
      "TransactionDescription": "KILLA SWEET TREATS MORLEY",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:37:00+11:00",
      "LWC_ID": 1001015240,
      "StatusID": 1,
      "TransactionDescription": "KILLA SWEET TREATS MORLEY WA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:37:00+11:00",
      "LWC_ID": 1000684808,
      "StatusID": 1,
      "TransactionDescription": "FIITINTERNATIONAL REEDY CREEK",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:41:00+11:00",
      "LWC_ID": 1001015127,
      "StatusID": 1,
      "TransactionDescription": "HILTON PLAZA NEWSGCY BEACONSFIELD",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:43:00+11:00",
      "LWC_ID": 1001015241,
      "StatusID": 1,
      "TransactionDescription": "FILIPPONE COUNTER CA FAIRFIELD",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:43:00+11:00",
      "LWC_ID": 1001015241,
      "StatusID": 1,
      "TransactionDescription": "FILIPPONE COUNTER CA FAIRFIELD VIC",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:45:00+11:00",
      "LWC_ID": 1001015131,
      "StatusID": 1,
      "TransactionDescription": "IGA FRESH GUILDFORD GUILDFORD",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:49:00+11:00",
      "LWC_ID": 1001015140,
      "StatusID": 1,
      "TransactionDescription": "K MART CABOOLTURE 1122 CABOOLTURE QLD",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:50:00+11:00",
      "LWC_ID": 1001015143,
      "StatusID": 1,
      "TransactionDescription": "KENSINGTON IGA X-PRESS KENSINGTON",
      "IndexerName": "Daisy"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1001015244,
      "StatusID": 1,
      "TransactionDescription": "DUNSBOROUGH BAMBOO PTY DUNSBOROUGH",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000636674,
      "StatusID": 1,
      "TransactionDescription": "NATURALIZER SOUTH YARRA VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000663534,
      "StatusID": 1,
      "TransactionDescription": "NATURALIZER DOCKLANDS VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000733301,
      "StatusID": 1,
      "TransactionDescription": "HUSHPUPPIES FOUNTAIN G FOUNTAIN GATE VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1001015244,
      "StatusID": 1,
      "TransactionDescription": "DUNSBOROUGH BAMBOO PTY DUNSBOROUGH WA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000721856,
      "StatusID": 1,
      "TransactionDescription": "HUSHPUPPIES DONCASTER DONCASTER VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1001015243,
      "StatusID": 1,
      "TransactionDescription": "FINAL DAYS HT GOLDCOA BIGGERA WATE",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000755409,
      "StatusID": 1,
      "TransactionDescription": "FINAL DAYS MOORABBIN MOORABBIN VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000842343,
      "StatusID": 1,
      "TransactionDescription": "HUSH PUPPIES ONLINE PORT MELBOURNE VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000878901,
      "StatusID": 1,
      "TransactionDescription": "JULIUS MARLOW KNOX WANTIRNA SOUTH VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000714881,
      "StatusID": 1,
      "TransactionDescription": "FINAL DAYS ESSENDON ESSENDON NORTH VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000803003,
      "StatusID": 1,
      "TransactionDescription": "FINAL DAYS UNI HILL BUNDOORA VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000810188,
      "StatusID": 1,
      "TransactionDescription": "HUSH PUPPIES MIRANDA MIRANDA NSW",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000758001,
      "StatusID": 1,
      "TransactionDescription": "HUSH PUPPIES KNOX WANTIRNA SOUTH VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000847938,
      "StatusID": 1,
      "TransactionDescription": "NATURALIZER PRESTON VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000826358,
      "StatusID": 1,
      "TransactionDescription": "HUSHPUPPIES EASTLAND RINGWOOD VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000853175,
      "StatusID": 1,
      "TransactionDescription": "HUSH PUPPIES NUNAWADI NUNAWADING VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:57:00+11:00",
      "LWC_ID": 1000873827,
      "StatusID": 1,
      "TransactionDescription": "MOSSIMO ONLINE PORT MELBOURNE VIC",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T13:58:00+11:00",
      "LWC_ID": 1001015243,
      "StatusID": 1,
      "TransactionDescription": "FINAL DAYS HT GOLDCOA BIGGERA WATER",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:15:00+11:00",
      "LWC_ID": 1000993651,
      "StatusID": 1,
      "TransactionDescription": "FINCIL ACCRD ACCG PL HORNSBY",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:16:00+11:00",
      "LWC_ID": 1000993651,
      "StatusID": 1,
      "TransactionDescription": "FINCIL ACCRD ACCG PL HORNSBY NSW",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:17:00+11:00",
      "LWC_ID": 1000993651,
      "StatusID": 1,
      "TransactionDescription": "FINCIL ACCRD ACCG PL HORNSBY NSW",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:22:00+11:00",
      "LWC_ID": 1001015246,
      "StatusID": 1,
      "TransactionDescription": "FINE FRUIT ON JAMES FORTITUDE VAL",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1000931838,
      "StatusID": 1,
      "TransactionDescription": "NT POLICE FIRE EMERG BERRIMAH NT",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1000741673,
      "StatusID": 1,
      "TransactionDescription": "TRADE BUSINESS CASUARINA NT",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1000842313,
      "StatusID": 1,
      "TransactionDescription": "CORNUCOPIA FANNIE BAY NT",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1000787109,
      "StatusID": 1,
      "TransactionDescription": "NT CORRECTIONS DARWIN NT",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1000678046,
      "StatusID": 1,
      "TransactionDescription": "DEPARTMENT OF HEALTH CASUARINA NT",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1000900509,
      "StatusID": 1,
      "TransactionDescription": "NT ELECTORAL COMMISS DARWIN NT",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1000903423,
      "StatusID": 1,
      "TransactionDescription": "TOURISM CULTURE CASUARINA NT",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:33:00+11:00",
      "LWC_ID": 1001000683,
      "StatusID": 1,
      "TransactionDescription": "INTERNET BPAY NT FINES RECOVERY UN",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:37:00+11:00",
      "LWC_ID": 1001015250,
      "StatusID": 1,
      "TransactionDescription": "FINNAIR SYDNEY",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:41:00+11:00",
      "LWC_ID": 1000578117,
      "StatusID": 1,
      "TransactionDescription": "BARRON RIVER HOTEL ST STRATFORD QLD",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:41:00+11:00",
      "LWC_ID": 1001015251,
      "StatusID": 1,
      "TransactionDescription": "FINNIGANS CHIN KEPERRA",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:47:00+11:00",
      "LWC_ID": 1001008171,
      "StatusID": 1,
      "TransactionDescription": "COBBLER PLUS KALAMUNDA WA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T14:47:00+11:00",
      "LWC_ID": 1001015252,
      "StatusID": 1,
      "TransactionDescription": "FIREBIRD MARINE PTY FORSTER",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T15:03:00+11:00",
      "LWC_ID": 1000637337,
      "StatusID": 1,
      "TransactionDescription": "FIREFLY MACKAY",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T15:08:00+11:00",
      "LWC_ID": 1001015254,
      "StatusID": 1,
      "TransactionDescription": "FIRST RESPONSE PHA RMST ALBANS",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T15:15:00+11:00",
      "LWC_ID": 1000789247,
      "StatusID": 1,
      "TransactionDescription": "KAMBOS HMEMKR SUP WANGARA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T15:15:00+11:00",
      "LWC_ID": 1000789247,
      "StatusID": 1,
      "TransactionDescription": "KAMBOS HMEMKR SUP WANGARA WA",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T15:15:00+11:00",
      "LWC_ID": 1000789717,
      "StatusID": 1,
      "TransactionDescription": "FIT MISS HEALTH CLUB CANTERBURY",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T15:24:00+11:00",
      "LWC_ID": 1000816845,
      "StatusID": 1,
      "TransactionDescription": "FITBIZ CITY EAST SYDNEY",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T15:25:00+11:00",
      "LWC_ID": 1000816845,
      "StatusID": 1,
      "TransactionDescription": "FITBIZ ST MARYS ST MARYS NSW",
      "IndexerName": "Glenn"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:10:00+11:00",
      "LWC_ID": 1000839220,
      "StatusID": 1,
      "TransactionDescription": "BCS ENTERPRISES PTY. HOPPERS CROSS",
      "IndexerName": "Anka"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:15:00+11:00",
      "LWC_ID": null,
      "StatusID": 1,
      "TransactionDescription": "RECOLETA GRAND HOTEL CAP.FEDERAL",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:16:00+11:00",
      "LWC_ID": 1001003203,
      "StatusID": 1,
      "TransactionDescription": "D.FORTUNE PTY LTD BURWOOD",
      "IndexerName": "Anka"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:37:00+11:00",
      "LWC_ID": 1001003330,
      "StatusID": 1,
      "TransactionDescription": "ST THERESES SCHOOL SOUTH YARRA",
      "IndexerName": "Anka"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:40:00+11:00",
      "LWC_ID": 1000096327,
      "StatusID": 1,
      "TransactionDescription": "STIX CATERING PTY LIMI MARRICKVILLE",
      "IndexerName": "Anka"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:43:00+11:00",
      "LWC_ID": 1001003354,
      "StatusID": 1,
      "TransactionDescription": "STORYVILLE MELBOURNE",
      "IndexerName": "Anka"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:47:00+11:00",
      "LWC_ID": 1001003331,
      "StatusID": 1,
      "TransactionDescription": "STREETSIDE BAR BRISBANE",
      "IndexerName": "Anka"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T16:49:00+11:00",
      "LWC_ID": 1001003332,
      "StatusID": 1,
      "TransactionDescription": "STURT ST IGA PLUS LIQUOR SOUTH MELBOUR",
      "IndexerName": "Anka"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T17:14:00+11:00",
      "LWC_ID": null,
      "StatusID": 1,
      "TransactionDescription": "VUE*ISC2 800-511-3478",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T18:45:00+11:00",
      "LWC_ID": 1000860210,
      "StatusID": 1,
      "TransactionDescription": "BABYLON SAUNA SPA SYDNEY",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T18:45:00+11:00",
      "LWC_ID": 1000860210,
      "StatusID": 1,
      "TransactionDescription": "BABYLON SAUNA SPA SYDNEY",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T19:19:00+11:00",
      "LWC_ID": 1001015257,
      "StatusID": 1,
      "TransactionDescription": "LUX BMX WEST END",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T19:19:00+11:00",
      "LWC_ID": 1000685849,
      "StatusID": 1,
      "TransactionDescription": "LUX BMX FORTITUDE VALLEY QLD",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T19:19:00+11:00",
      "LWC_ID": 1001015257,
      "StatusID": 1,
      "TransactionDescription": "LUX BMX WEST END QLD",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T19:23:00+11:00",
      "LWC_ID": 1001015258,
      "StatusID": 1,
      "TransactionDescription": "DARWIN SHOP N GO DARWIN",
      "IndexerName": "LWCSystem"
    },
    {
      "Status": "NeedsVerifying",
      "StatusDateTime": "2017-12-17T19:23:00+11:00",
      "LWC_ID": 1001015258,
      "StatusID": 1,
      "TransactionDescription": "DARWIN SHOP N GO DARWIN NT",
      "IndexerName": "LWCSystem"
    }
  ]
}
