import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { LwcNgxJsonViewerComponent } from './lwc-ngx-json-viewer/lwc-ngx-json-viewer.component';
import { JsonViewerIconComponent } from './lwc-ngx-json-viewer/jsonviewericon/jsonviewericon.component';
import { JsonViewerImageComponent } from './lwc-ngx-json-viewer/jsonviewerimage/jsonviewerimage.component';
import { JsonViewerButtonComponent } from './lwc-ngx-json-viewer/jsonviewerbutton/jsonviewerbutton.component';

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    MatIconModule,
    MatTooltipModule
  ],
  declarations: [
    LwcNgxJsonViewerComponent,
    JsonViewerIconComponent,
    JsonViewerImageComponent,
    JsonViewerButtonComponent
  ],
  exports: [
    LwcNgxJsonViewerComponent,
    JsonViewerIconComponent,
    JsonViewerImageComponent,
    JsonViewerButtonComponent
  ],
  entryComponents: [JsonViewerIconComponent , JsonViewerImageComponent, JsonViewerButtonComponent]
})
export class LwcNgxJsonViewerModule { }
