import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { merchantDetails, merchantDetailsByChainNameAndShoppingCentresResponse, merchantDetailsByShoppingCentresResponse, merchantShoppingCentersResponse } from '../mockjsonresponses/merchantextractresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function merchantExtractBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes('merchantchain/merchantdetails') && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...merchantDetails]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('merchant/merchantdetailsbylwcids') && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...merchantDetails]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.merchantDetailsByBsbs) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...merchantDetails]
      }));
      resp.complete();
    });
  }

  let includesDataLocatedIn = false;
  let includesFormattedPhoneNumber = false;
  if (request.body && request.body.query) {
    includesDataLocatedIn = request.body.query.includes('data: locatedIn');
    includesFormattedPhoneNumber = request.body.query.includes('formatted_phone_number');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesDataLocatedIn && !includesFormattedPhoneNumber) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...merchantShoppingCentersResponse }
      }));
      resp.complete();
    })
  }

  if (cleanUrl.includes(apiPathConstants.merchantDetailsByShoppingCentre) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [ ...merchantDetailsByShoppingCentresResponse ]
      }));
      resp.complete();
    })
  }

  if (cleanUrl.includes(apiPathConstants.merchantDetailsByChainNameAndShoppingCentres) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [ ...merchantDetailsByChainNameAndShoppingCentresResponse ]
      }));
      resp.complete();
    })
  }
}
