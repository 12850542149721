import { Observable } from 'rxjs';
import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { merchantNamesResponse, merchantWordResponse, merchantNameSearchResponse } from '../mockjsonresponses/merchantname.response';

export function merchantNameSearchBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes(apiPathConstants.merchantName) && method === "GET") {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...merchantNamesResponse]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.suggest) && method === "POST") {
    let value = { ...merchantWordResponse };
    value.MerchantWordOperations = [...merchantWordResponse.MerchantWordOperations];
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...merchantWordResponse }
      }));
      resp.complete();
    })
  }

  if (cleanUrl.includes(apiPathConstants.search) && method === "POST") {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...merchantNameSearchResponse]
      }));
      resp.complete();
    })
  }
}
