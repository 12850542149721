import { BaseODataFilter } from './baseodatafilter';

export class StartsWithODataFilter implements BaseODataFilter {
  constructor(private propertyPath: string, private value: string) {
  }

  getFilter(): string {
    return `startswith(${this.propertyPath}, '${this.value}')`;
  }
}
