import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { MerchantBatchSearchRequest, APISearchCalMidTidDto, ConcurrentRequest } from '../components/batchmerchantsearch/merchantbatchsearchrequest';
import { from, of, forkJoin, Observable } from 'rxjs';
import { mergeMap, catchError, map, switchMap } from 'rxjs/operators';
import { BatchRequestErrorResponse } from '../components/batchmerchantsearch/batchrequesterrorresponse';
import { BatchSearchResult } from '../components/batchmerchantsearch/batchsearchresult';

export interface BatchRequestStatus<T> {
  areMerchantsLoading: boolean;
  searchInProgressCount: number;
  searchCompletedCount: number;
  searchFailedCount: number;
  batchSearhResults: BatchSearchResult<T>[];
}

export interface BatchRequestConfig<T, R> {
  searchBatchRequest?: (request: T, disableDefaultErrorHandling?: boolean) => Observable<R>;
  searchBatchSize?: number;
  concurrentRequests?: number;
}

@Injectable()
export class BatchRequestService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  searchBatchMerchant = <T, R>(request: T, disableDefaultErrorHandling?: boolean): Observable<R> => {
    return this.apiClient.post<R>(this.apiService
      .buildUrl('searchv4/getmerchantbatchbycal'), <any>request,
      { disableDefaultErrorHandling: disableDefaultErrorHandling });
  }

  doBatchRequest<T, R>(requestsToDo: T[], allData: T[], currentRunRequests: T[],
    retryRequests: ConcurrentRequest<T>[],
    successRequests: ConcurrentRequest<T>[],
    failedRequests: ConcurrentRequest<T>[],
    batchSizeProvider: (t: T) => number,
    batchRequestStatus: BatchRequestStatus<R>,
    batchRequestConfig?: BatchRequestConfig<T, R>): Observable<BatchRequestStatus<R>> {
    let requestCount = 0;
    batchRequestConfig = this.prepareConfig<T, R>(batchRequestConfig);

    return from(requestsToDo)
      .pipe(mergeMap((m: T) => {
        let requestIndex = allData.indexOf(m);

        let searchObservable = batchRequestConfig.searchBatchRequest(m, true)
          .pipe(catchError(error => of({ isError: true, error: error })));

        return forkJoin(
          of(requestIndex), searchObservable);
      }, undefined, batchRequestConfig.concurrentRequests),
        map((result) => {
          requestCount++;
          let requestIndex = result[0];

          let retryRequestIndex = retryRequests.findIndex(r => r.requestIndex === requestIndex);

          if ((<BatchRequestErrorResponse>result[1]).isError) {
            this.handleRequestFailure(retryRequests, retryRequestIndex, failedRequests, requestIndex, allData);
          } else {
            if (retryRequestIndex > -1) {
              retryRequests.splice(retryRequestIndex, 1);
            }
            successRequests.push({
              requestIndex: requestIndex,
              retryAttempt: 0,
              data: allData[requestIndex]
            });

            if ((<any>result[1]).search_results) {
              batchRequestStatus.batchSearhResults.push({
                requestIndex: requestIndex,
                response: (<any>result[1]).search_results
              });
            } else {
              batchRequestStatus.batchSearhResults.push({
                requestIndex: requestIndex,
                response: <R>result[1]
              });
            }
          }

          batchRequestStatus.searchCompletedCount = this.calculateTotalCalsForResult(successRequests, batchSizeProvider);
          batchRequestStatus.searchFailedCount = this.calculateTotalCalsForResult(failedRequests, batchSizeProvider);
          let inProgress = currentRunRequests.length - successRequests.length - failedRequests.length;

          batchRequestStatus.searchInProgressCount = inProgress * batchRequestConfig.searchBatchSize;

          if (inProgress === 0) {
            batchRequestStatus.areMerchantsLoading = false;
          }

          if (inProgress > 0 && requestCount == requestsToDo.length) {
            return this.doBatchRequest<T, R>(retryRequests.map(d => d.data), allData, currentRunRequests, retryRequests,
              successRequests, failedRequests, batchSizeProvider, batchRequestStatus, batchRequestConfig);
          } else {
            return of(batchRequestStatus);
          }
        }),
        switchMap(r => r));
  }

  private prepareConfig<T, R>(batchRequestConfig: BatchRequestConfig<T, R>) {
    let config = batchRequestConfig;
    if (!config) {
      config = {};
    }

    if (!config.searchBatchRequest) {
      config.searchBatchRequest = this.searchBatchMerchant;
    }
    if (!config.searchBatchSize) {
      config.searchBatchSize = 50;
    }
    if (!config.concurrentRequests) {
      config.concurrentRequests = 4;
    }

    return config;
  }

  private handleRequestFailure<T>(retryRequests: ConcurrentRequest<T>[],
    retryRequestIndex: number, failedRequests: ConcurrentRequest<T>[],
    requestIndex: number, allRequests: T[]) {

    let retryRequest = retryRequests[retryRequestIndex];
    if (retryRequest && retryRequest.retryAttempt === 3) {
      failedRequests.push(retryRequest);
      retryRequests.splice(retryRequestIndex, 1);
    } else if (retryRequest) {
      retryRequest.retryAttempt += 1;
    }
    else {
      retryRequests.push({
        requestIndex: requestIndex,
        retryAttempt: 1,
        data: allRequests[requestIndex]
      });
    }
  }

  private calculateTotalCalsForResult<T>(requests: ConcurrentRequest<T>[],
    batchSizeProvider: (t: T) => number) {
    let result = 0;
    for (let request of requests) {
      result += batchSizeProvider(request.data);
    }
    return result;
  }

  prepareCalsForRequest(cals: string[], searchBatchSize = 50) {
    let searchModels: MerchantBatchSearchRequest[] = [];

    for (let i = 0, j = cals.length; i < j; i += searchBatchSize) {
      let slicedCals = cals.slice(i, i + searchBatchSize).map(cal => {
        let calObj: APISearchCalMidTidDto = { cal: cal };
        return calObj;
      });
      let searchModel: MerchantBatchSearchRequest = {
        bank_transactions: slicedCals,
        fields: null
      };
      searchModels.push(searchModel);
    }

    return searchModels;
  }

  sendCalsToCache(values: string[]) {
    return this.apiClient.post<any>(this.apiService.buildUrl('redis/SendCalsToCache'), values);
  }
}
