import { ElementRef } from '@angular/core';
import { Component, OnChanges, Input, OnInit, ComponentFactoryResolver,
   ViewContainerRef, QueryList, ViewChildren, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { JsonViewerCustomComponent } from './jsonviewercustomcomponent';
import { ValueCheckerService } from '../../../core/services/valuechecker.service';
import { ArrayService } from '../../../core/collections/array.service';

export interface SegmentConfig {
  key: string;
  order?: number;
  hideValue?: boolean;
  modifySegment?: (s: Segment, wholeObject: any) => void;
}

export interface Segment {
  key: string;
  value: any;
  type: undefined | string;
  description: string;
  expanded: boolean;
  hasCustomComponent?: boolean;
  hideValue?: boolean;
  order?: number;
  cssClasses?: string[]
  hide?: boolean;
  frontIcon?: string;
  frontImage?: string;
  frontImageIconTooltip?: string;
}

@Component({
  selector: 'lwc-ngx-json-viewer',
  templateUrl: './lwc-ngx-json-viewer.component.html',
  styleUrls: ['./lwc-ngx-json-viewer.component.scss']
})
export class LwcNgxJsonViewerComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() json: any;
  @Input() expanded = true;
  /**
   * @deprecated It will be always true and deleted in version 3.0.0
   */
  @Input() cleanOnChange = true;
  @Input() displayCopyIcon = false;
  @Input() customComponents: JsonViewerCustomComponent[] = [];
  @Input() hideEmptyValues = false;
  @Input() arrayElementsToDisplayByDefault = 5;
  @Input() useArrayShortening = false;
  @Input() ignoredKeys: string[] = [];
  @Input() segmentConfigs: SegmentConfig[] = [];
  @Input() segments: Segment[] = [];
  @Input() parentSegmentKey?: string;

  @ViewChildren('frontImage') set frontImageSize(frontImages: QueryList<ElementRef<HTMLImageElement>>) {
    let imageArray = frontImages.toArray();
    if (imageArray && imageArray.length > 0) {
      let images = frontImages.map((m) => m.nativeElement);
      this.setImageSize(images);
    }
  };

  @ViewChildren('customComponentContainer', {read: ViewContainerRef})
  private componentContainers: QueryList<ViewContainerRef>;
  private customComponentsRendered = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private valueCheckerService: ValueCheckerService, private arrayService: ArrayService,
    private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
  }

  ngAfterViewChecked() {
    if(this.customComponentsRendered) {
      return;
    }

    this.handleCustomComponents();
    this.changeDetectorRef.detectChanges();
  }

  private handleCustomComponents() {
    if (!this.componentContainers) {
      return;
    }

    let containers = this.componentContainers.toArray();

    for(let container of containers) {
      container.clear();
    }
    let customComponentSegments = this.segments.filter(s => s.hasCustomComponent);
    if (this.customComponents && this.customComponents.length > 0) {
      for (let customComponent of this.customComponents) {
        let containerIndex = customComponentSegments.findIndex(s => s.key === customComponent.key);
        if (containerIndex > -1) {
          let segment = this.segments.find(s => s.key === customComponent.key);

          this.createCustomComponent(containers, containerIndex, customComponent, segment);
        }
      }

      let parentCustomComponent = this.customComponents.find( c => c.parentSegmentKey !== undefined &&
        this.parentSegmentKey === c.parentSegmentKey);
      if(parentCustomComponent) {
        for(let i = 0; i < customComponentSegments.length; i++) {
          this.createCustomComponent(containers, i, parentCustomComponent, customComponentSegments[i]);
        }
      }

      this.customComponentsRendered = true;
    }
  }

  ngOnChanges() {
    if (this.cleanOnChange) {
      this.segments = [];
    }
    if (typeof this.json === 'object') {
      let configsWithModify = this.segmentConfigs.filter(s => s.modifySegment);
      for (let key of Object.keys(this.json)) {
        if(this.ignoredKeys.includes(key)) {
          continue;
        }
        const segment = this.parseKeyValue(key, this.json[key]);
        for(let segmentConfig of configsWithModify) {
          if(segmentConfig.key === segment.key) {
            segmentConfig.modifySegment(segment, this.json);
          }
        }

        this.segments.push(segment);
      }

      for(let segmentConfig of this.segmentConfigs) {
        if(!this.valueCheckerService.isNullOrUndefined(segmentConfig.order)) {
          let fromIndex = this.segments.findIndex(a => a.key == segmentConfig.key);
          if(fromIndex > -1) {
            this.arrayService.moveElement(this.segments, fromIndex, segmentConfig.order);
          }
        }
      }

      this.customComponentsRendered = false;
    }
  }

  expandArray() {
    this.useArrayShortening = false;
  }

  shouldShowSegment(segment: Segment, i: number) {
    if(this.useArrayShortening && i >= this.arrayElementsToDisplayByDefault) {
      return false;
    }

    if (this.hideEmptyValues && this.valueCheckerService.isEmptyNullOrUndefined(segment.value)) {
      return false;
    }

    return true;
  }

  shouldShowExpandButton(i: number) {
    return this.useArrayShortening && i + 1 == this.arrayElementsToDisplayByDefault;
  }

  isExpandable(segment: Segment) {
    return segment.type === 'object' || segment.type === 'array';
  }

  isArray(segment: Segment) {
    return  segment.type === 'array';
  }

  shouldDisplayCopyIcon(segment: Segment) {
    return this.displayCopyIcon && segment != null && segment.value != null && segment.value !== '';
  }


  stripBoundaryQuotes(str: String) {
    if (str != null && str.startsWith('"')) {
      str = str.slice(1, -1);
    }

      return str;
  }
  toggle(segment: Segment) {
    if (this.isExpandable(segment)) {
      segment.expanded = !segment.expanded;
    }
  }

  getSegmentSectionCssClasses(segment: Segment, i: number) {
    let classes = ['segment', 'segment-type-' + segment.type];
    if(segment.cssClasses) {
      classes = classes.concat(segment.cssClasses);
    }

    classes.push(this.shouldShowSegment(segment, i) ? 'block' : 'display-none');

    return classes;
  }

  private parseKeyValue(key: any, value: any): Segment {
    if (Array.isArray(this.json) && this.json.length  >= 5) {
      this.expanded = false;
    }
    const segment: Segment = {
      key: key,
      value: value,
      type: undefined,
      description: '' + value,
      expanded: this.expanded
    };
    let segmentConfig = this.segmentConfigs.find(s => s.key === key);

    if(segmentConfig) {
      segment.hideValue = segmentConfig.hideValue;
    }
    if (this.customComponents && this.customComponents.length > 0) {
      let component = this.customComponents.find(c => c.key === segment.key || c.parentSegmentKey === this.parentSegmentKey);

      if (component) {
        segment.hasCustomComponent = true;
      }
    }

    switch (typeof segment.value) {
      case 'number': {
        segment.type = 'number';
        break;
      }
      case 'boolean': {
        segment.type = 'boolean';
        break;
      }
      case 'function': {
        segment.type = 'function';
        break;
      }
      case 'string': {
        segment.type = 'string';
        segment.description = '"' + segment.value + '"';
        break;
      }
      case 'undefined': {
        segment.type = 'undefined';
        segment.description = 'undefined';
        break;
      }
      case 'object': {
        // yea, null is object
        if (segment.value === null) {
          segment.type = 'null';
          segment.description = 'null';
        } else if (Array.isArray(segment.value)) {
          segment.type = 'array';
          segment.description = 'Array[' + segment.value.length + '] ' + JSON.stringify(segment.value);
        } else if (segment.value instanceof Date) {
          segment.type = 'date';
        } else {
          segment.type = 'object';
          segment.description = 'Object ' + JSON.stringify(segment.value);
        }
        break;
      }
    }

    return segment;
  }

  private createCustomComponent(containers: ViewContainerRef[], containerIndex: number,
    customComponent: JsonViewerCustomComponent, segment: Segment) {
    let container = containers[containerIndex];
    if(container) {
      let factory = this.componentFactoryResolver.resolveComponentFactory(customComponent.component);
    let component = container.createComponent(factory);
    customComponent.componentConfigurator(component.instance, segment, this.json);
    component.changeDetectorRef.detectChanges();
    }
  }

  private setImageSize(images: HTMLImageElement[]) {
    for (let image of images) {
      if (image.naturalHeight === 16 && image.naturalWidth === 32) {
        return;
      } else {
        image.width = 16;
        image.height = 16;
      }
    }
  }
}
