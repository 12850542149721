import { ExecutionResult } from "graphql";
import {  HmacKeysResponse } from "../../components/apiclients/apiclients.component";
import { ApiClientDTO, ApiClientPolicy } from "../../components/apiclients/apiclientdto";

export let apiClientsResponse: ExecutionResult<ApiClientDTO[]> = {
  data:  [
      {
        apikey: "d27aXXXXXXXXXX",
        apikeyName: "false api key",
        clientId: 1,
        clientName: "Amelia",
        createDate: "2019-07-20T17:49:19.05",
        createdBy: 3,
        isActive: false,
        isSubscriber: false,
        isReliableKey: false,
        modifiedBy: 2,
        modifiedDate: "2019-07-25T02:28:10.012",
        clientCode: 12,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS", "NZL"],
        apiClientGroup: {
          clientName: "apiClient2"
        },
        maxTransactionPerSearch: 14,
        threeCharCode: null,
        apiClientPolicies: [
          {
            policyId: 9,
            policy: "cashflow"
          }
        ]
      },
      {
        apikey: "oUcCXXXXXXXXXX",
        apikeyName: "lwc-test-01",
        clientId: 3,
        clientName: "Ava",
        createDate: "2019-08-09T04:05:14.013",
        createdBy: 0,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 0,
        modifiedDate: "2019-08-09T09:15:42.012",
        clientCode: 12,
        defaultSearchEngine: null,
        useTheseEngines: null,
        apiClientGroup: {
          clientName: "apiClient2"
        },
        maxTransactionPerSearch: 1,
        threeCharCode: "CUA",
        apiClientPolicies: []
      },
      {
        apikey: "D3GhXXXXXXXXXX",
        apikeyName: "lwc-dev-01",
        clientId: 4,
        clientName: "Poppy",
        createDate: "2019-08-09T20:00:45.045",
        createdBy: 0,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 0,
        modifiedDate: "2019-08-09T23:45:10.016",
        clientCode: 4,
        defaultSearchEngine: null,
        useTheseEngines: null,
        apiClientGroup: {
          clientName: "apiClient10"
        },
        maxTransactionPerSearch: 8,
        threeCharCode: "CUA",
        apiClientPolicies: []
      },
      {
        apikey: "f925XXXXXXXXXX",
        apikeyName: "name",
        clientId: 5,
        clientName: "Leo",
        createDate: "2019-08-18T12:25:15.025",
        createdBy: 1,
        isActive: false,
        isSubscriber: false,
        isReliableKey: false,
        modifiedBy: 0,
        modifiedDate: null,
        clientCode: 8,
        defaultSearchEngine: null,
        useTheseEngines: null,
        apiClientGroup: {
          clientName: "apiClient13"
        },
        maxTransactionPerSearch: 17,
        threeCharCode: "ANZ",
        apiClientPolicies: [
          {
            policyId: 4,
            policy: "lwconly"
          }
        ]
      },
      {
        apikey: "f625XXXXXXXXXX",
        apikeyName: "name 5",
        clientId: 556,
        clientName: "Charlie",
        createDate: "2020-10-12T15:33:57.037",
        createdBy: 1,
        isActive: false,
        isSubscriber: false,
        isReliableKey: false,
        modifiedBy: 0,
        modifiedDate: null,
        clientCode: 8,
        defaultSearchEngine: null,
        useTheseEngines: null,
        apiClientGroup: {
          clientName: "apiClient10"
        },
        maxTransactionPerSearch: 17,
        threeCharCode: "PAY",
        apiClientPolicies: [
          {
            policyId: 8,
            policy: "bpay"
          },
          {
            policyId: 2,
            policy: "merchantlookup"
          },
          {
          policyId: 4,
          policy: "lwconly"
          }
        ]
      },
      {
        apikey: "d27aXXXXXXXXXX",
        apikeyName: "api keys",
        clientId: 15,
        clientName: "Riley",
        createDate: "2019-07-20T17:49:19.05",
        createdBy: 2,
        isActive: false,
        isSubscriber: false,
        isReliableKey: false,
        modifiedBy: 12,
        modifiedDate: "",
        clientCode: 4,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 56,
        threeCharCode: "PAR",
        apiClientPolicies: []
      },
      {
        apikey: "d57aXXXXXXXXXX",
        apikeyName: "api keys1",
        clientId: 65,
        clientName: "Will",
        createDate: "2009-07-20T17:49:19.05",
        createdBy: 3,
        isActive: true,
        isSubscriber: true,
        isReliableKey: false,
        modifiedBy: 12,
        modifiedDate: "",
        clientCode: 4,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 78,
        threeCharCode: "CUA",
        apiClientPolicies: []
      },
      {
        apikey: "d7aXXXXXXXXXX",
        apikeyName: "api keys13",
        clientId: 920,
        clientName: "Oliver",
        createDate: "2005-04-20T17:49:19.05",
        createdBy: 1,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "2009-07-25T02:28:10.012",
        clientCode: 42,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 36,
        threeCharCode: "CUA",
        apiClientPolicies: [
          {
            policyId: 1,
            policy: "search"
          }
        ]
      },
      {
        apikey: "d7aXXXXXXXXXX",
        apikeyName: "api keys3",
        clientId: 595,
        clientName: "Leo",
        createDate: "2004-04-20T17:29:19.05",
        createdBy: 5,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "2010-03-15T02:20:08.012",
        clientCode: 40,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 20,
        threeCharCode: null,
        apiClientPolicies: []
      },
      {
        apikey: "d8aXXXXXXXXXX",
        apikeyName: "api keys12",
        clientId: 25,
        clientName: "Jacob",
        createDate: "1995-04-20T17:49:10.05",
        createdBy: 5,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "1994-07-25T02:28:10.012",
        clientCode: 23,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 13,
        threeCharCode: "CBA",
        apiClientPolicies: [
          {
            policyId: 5,
            policy: "all"
          },
          {
            policyId: 1,
            policy: "search"
          }
        ]
      },
      {
        apikey: "d9aXXXXXXXXXX",
        apikeyName: "api keys43",
        clientId: 455,
        clientName: "Noah",
        createDate: "2000-04-20T17:49:19.05",
        createdBy: 5,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "1986-07-25T02:05:10.012",
        clientCode: 145,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 60,
        threeCharCode: "HSB",
        apiClientPolicies: []
      },
      {
        apikey: "dfaXXXXXXXXXX",
        apikeyName: "api keys14",
        clientId: 523,
        clientName: "George",
        createDate: "1992-04-20T17:49:19.05",
        createdBy: 5,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "1992-02-25T05:08:10.012",
        clientCode: 258,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 45,
        threeCharCode: null,
        apiClientPolicies: []
      },
      {
        apikey: "d6aXXXXXXXXXX",
        apikeyName: "api keys33",
        clientId: 145,
        clientName: "Isla",
        createDate: "1994-04-20T10:49:19.05",
        createdBy: 2,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "1959-03-08T09:15:05.012",
        clientCode: 658,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 15,
        threeCharCode: "AAI",
        apiClientPolicies: []
      },
      {
        apikey: "d15aXXXXXXXXXX",
        apikeyName: "api keys20",
        clientId: 95,
        clientName: "Wyatt",
        createDate: "",
        createdBy: 1,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "1929-07-12T02:08:10.012",
        clientCode: 10,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 8,
        threeCharCode: null,
        apiClientPolicies: []
      },
      {
        apikey: "d6aXXXXXXXXXX",
        apikeyName: "api keys19",
        clientId: 532,
        clientName: "Harper",
        createDate: "",
        createdBy: 2,
        isActive: true,
        isSubscriber: true,
        isReliableKey: true,
        modifiedBy: 2,
        modifiedDate: "1959-07-15T02:28:10.012",
        clientCode: 15,
        defaultSearchEngine: "AUS",
        useTheseEngines: ["AUS"],
        apiClientGroup: null,
        maxTransactionPerSearch: 45,
        threeCharCode: null,
        apiClientPolicies: []
      },
    ]
};

export let apiClientsEditResponse: ExecutionResult<unknown> = {data: {upsertAPIClient: {}}};

export let apiClientsHmacKeysResponse: ExecutionResult<HmacKeysResponse> = {
  data: {
    hmackeys: {
        apiKey: "8pHb8cq12VO/u4S7F3mYqiyhBueqUTIYzzGFrHUCxFk=",
        appId: "dc6546e9-4719-4c67-aacc-f896092a7ee2"
      }
  }
};

export let apiClientAllPoliciesResponse: ExecutionResult<ApiClientPolicy[]> = {
  data: [
    {
      policyId: 1,
      policy: "search"
    },
    {
      policyId: 2,
      policy: "merchantlookup"
    },
    {
      policyId: 3,
      policy: "moneytracker"
    },
    {
      policyId: 4,
      policy: "lwconly"
    },
    {
      policyId: 5,
      policy: "all"
    },
    {
      policyId: 6,
      policy: "allclient"
    },
    {
      policyId: 7,
      policy: "bsb"
    },
    {
      policyId: 8,
      policy: "bpay"
    },
    {
      policyId: 9,
      policy: "cashflow"
    }
  ]
};
