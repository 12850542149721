import { hotChocolate } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { MerchantAudit } from "../components/merchantaudit/merchantaudit.dto";
import { merchantAuditQuery } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: "root"
})

export class MerchantAuditService {
  constructor(private apollo: Apollo) {
  }

  getMerchantAudit(lwcId: number) {
    return this.apollo
    .use(hotChocolate)
    .watchQuery<MerchantAudit[]>({
      query: merchantAuditQuery(lwcId),
    }).valueChanges;
  }
}
