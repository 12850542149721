import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { UnverifiedMerchantsResponse } from "../components/operationalchecks/operationalchecks.component";
import { unverifiedMerchantQuery, eventing } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: "root"
})

export class OperationalChecksService {
  private unverifiedMerchantQuery = unverifiedMerchantQuery;

  constructor(private apollo: Apollo) {
  }

  getUnverifiedMerchants() {
    return this.apollo
      .use(eventing)
      .watchQuery<UnverifiedMerchantsResponse>({
        query: this.unverifiedMerchantQuery,
        variables: { "chainCode": null }
      })
      .valueChanges;
  }
}
