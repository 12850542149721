import { MerchantByAbnAcn } from "../../models/merchantlist";

export let merchantsByAbnOrAcnResponse: MerchantByAbnAcn[] = [
  {
    LWC_ID: 1000603643,
    MerchantType: "Head Office",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Online Only",
    ChainName: "SGIO Insurance",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-02-01T05:56:00",
    PrimaryName: "SGIO Insurance",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "46 Colin St, West Perth WA 6005, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "13 32 33",
    URL: "https://www.sgio.com.au",
    CALs: " SGIO WEB PERTH || SGIO IVR PERTH WA || SGIO IVR WEST PERTH WA || SGIO PER WEST PERTH WA || SGIO PER WE PERTH WA || SGIO WEB SYDNEY NSW || SGIO WEST PERTH WA || INTERNET BPAY SGIO INSURANCE || SGIO DCM SYDNEY NSW || SGIO DCO SYDNEY NSW || SGIO GMI SYDNEY NSW || SGIO POT PARRAMATT A SY NSW || SGIO POT PARRAMATT SYDNEY NSW || SGIO POT PARRAMATTA SYDNEY NSW || SGIO SYDNEY NSW || FLEXIPHONE BPAY - SGIO SURANCE || SGIO WAT WEST PERTH || SGIO CLAIMS BURNLEY VIC || DIRECT DEBIT SGIO HOM || INTERNET BPAY SGIO || DIRECT DEBIT SGIO",
    Email: null,
    RecordLocked: true,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000605427,
    MerchantType: "Head Office",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Online Only",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-25T01:59:00",
    PrimaryName: "NRMA",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "Darling Park, Shop 2 201 Sussex Street, Sydney NSW 2000, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: true,
    Phone: "13 21 32",
    URL: "https://www.nrma.com.au",
    CALs: " TELECLAIM ESS NRMA ESS FIELDS VIC || TELECLAIM ESS NRMA MELBOURNE VIC || NRMA-SYDNEY || INTERNET BPAY NRMA MS MEMBERSHIP || DIRECT DEBIT THE NRMA || LANDLORD INSURANCE NRMA . AU || INTERNET BPAY NRMA MOTORING & SE || INTERNET BPAY NRMA CLAIMS || DIRECT DEBIT NRMA INSURANCE || DIRECT DEBIT NRMA LIFE || NRMA PBTM SYDNEY NSW || NRMA SALES SUPPORT NEWCASTLE NSW || NRMA SYDNEY SYDNEY NSW || NRMA WEBSYDNEY NSW || NRMA WNS SYDNEY NSW || NRMA IVR SYDNEY NSW || NRMA LTD SYDNEY OLYMPIC PARK NSW || PAYPAL *NRMA MEMBER 4029357733 || NRMA MOTORING LTD SYDNEY OLYMPI NSW || NRMA SERVICE CENTR NEWCASTLE NSW || NRMA MOTORING & SERVICES MEMBERSHIP || INTERNET BPAY NRMA MOTORING & SERVICES MEMBERSHIP || NRMA MOTORING LIMITE SYDNEY OLYMPI NSW || NRMA BRANCH SYDNEY NSW || PAYPAL *NRMA MEMBER 0287416633 || PAYPAL *NRMA MEMBER NORTH STRATHFAU || PAY/SALARY FROM NRMA LTD || NRMA RBI CLAIMS SYDNEY NSW || NRMA CLAIMS SEVEN HILLS || INTERNET BPAY NRMA BUSINESS INSURE || NRMA LOWER MCDONAL NSW || NRMA ESS ESS FIELDS",
    Email: null,
    RecordLocked: true,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000605808,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T03:52:00",
    PrimaryName: "NRMA (Evans Head)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "12 Canberra Rd, Evans Head NSW 2473, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 6682 5442",
    URL: "http://www.nrma.com.au",
    CALs: " NRMA 339 EVANS HEAD NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Closed",
    Tags: null
  },
  {
    LWC_ID: 1000605839,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T03:54:00",
    PrimaryName: "NRMA (Narooma)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "shop 3b/185 Princes Hwy, Narooma NSW 2546, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 4476 4707",
    URL: "http://nrma.com.au",
    CALs: " NRMA 832 NAROOMA NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000605972,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 80,
    UpdatedDate: "2021-08-04T03:56:00",
    PrimaryName: "NRMA (Dunedoo)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "Dunedoo NSW 2844, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 6375 1254",
    URL: "http://nrma.com.au",
    CALs: " NRMA 130 DUNEDOO NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Closed",
    Tags: null
  },
  {
    LWC_ID: 1000615255,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T03:58:00",
    PrimaryName: "NRMA (Westfield Chatswood)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "1 Spring Street, Chatswood NSW 2067, Australia",
    LocatedIn_Name: "Westfield Chatswood",
    LocatedIn_Id: 808,
    StreetViewAvailable: false,
    Phone: "13 21 32",
    URL: "http://www.nrma.com.au",
    CALs: " N R M A LIMITED CHATSWOOD NSW || NRMA CHA CHATSWOOD NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000615259,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:00:00",
    PrimaryName: "NRMA (Grafton)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "1/2 King Street, Grafton NSW 2460, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: true,
    Phone: "(02) 6643 5999",
    URL: "http://nrma.com.au",
    CALs: " N R M A LIMITED GRAFTON NSW || NRMA 351 GRAFTON NSW || NRMA GRAFTON NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000615260,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:01:00",
    PrimaryName: "NRMA (Lismore)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "2/218-234 Molesworth Street, Lismore NSW 2480, Australia",
    LocatedIn_Name: "Lismore Central Shopping Centre",
    LocatedIn_Id: 1331,
    StreetViewAvailable: false,
    Phone: "(02) 6622 7600",
    URL: "http://www.nrma.com.au",
    CALs: " N R M A LIMITED LISMORE NSW || NRMA LIS LISMORE NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000615264,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:03:00",
    PrimaryName: "NRMA (Riverside Plaza)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "131 Monaro St, Queanbeyan NSW 2620, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 6297 4666",
    URL: "http://www.nrma.com.au",
    CALs: " N R M A QUEANBEYAN NSW || NRMA QUN QUEANBEYAN NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  },
  {
    LWC_ID: 1000615265,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:05:00",
    PrimaryName: "NRMA (Stafford)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "5/87 Webster Road, Stafford QLD 4053, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(07) 3352 3581",
    URL: "http://nrma.com.au",
    CALs: " NRMA / STF STAFFORD QLD",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Closed",
    Tags: null
  },
  {
    LWC_ID: 1000615269,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:07:00",
    PrimaryName: "NRMA (Walgett)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "81 Wee Waa Street, Walgett NSW 2832, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 6828 1088",
    URL: "http://www.nrma.com.au",
    CALs: " NRMA 113 WALGETT NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Closed",
    Tags: null
  },
  {
    LWC_ID: 1000615270,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:09:00",
    PrimaryName: "NRMA (Narromine)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "14/18 Nymagee Street, Narromine NSW 2821, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: true,
    Phone: "(02) 6889 1011",
    URL: "http://www.nrma.com.au",
    CALs: " NRMA 150 NARROMINE NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Closed",
    Tags: null
  },
  {
    LWC_ID: 1000615272,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:10:00",
    PrimaryName: "NRMA (Warren)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "83/85 Dubbo Street, Warren NSW 2824, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 6847 4978",
    URL: "http://www.nrma.com.au",
    CALs: " NRMA 151 WARREN NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Closed",
    Tags: null
  },
  {
    LWC_ID: 1000615274,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:12:00",
    PrimaryName: "NRMA (Cobar)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "33 Marshall Street, Cobar NSW 2835, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 6836 2440",
    URL: "http://www.nrma.com.au",
    CALs: " NRMA 160 COBAR NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Closed",
    Tags: null
  },
  {
    LWC_ID: 1000615276,
    MerchantType: "Branch",
    ABN: "11000016722",
    ACN: "000016722",
    MerchantPresence: "Bricks & Mortar",
    ChainName: "NRMA",
    DataCompletnessScore: 90,
    UpdatedDate: "2021-08-04T04:13:00",
    PrimaryName: "NRMA (Nyngan)",
    PrimaryCategory: "Services >> Insurance",
    PrimaryAddress: "103 Pangee Lane, Nyngan NSW 2825, Australia",
    LocatedIn_Name: null,
    LocatedIn_Id: null,
    StreetViewAvailable: false,
    Phone: "(02) 6832 1164",
    URL: "http://nrma.com.au",
    CALs: " NRMA 161 NYNGAN NSW || NRMA NYNGAN NSW",
    Email: null,
    RecordLocked: false,
    IsQuarantined: false,
    QuaratinedCALs: null,
    CurrentBusinessStatus: "Active",
    Tags: null
  }
];
