import { FormControl } from '@angular/forms';
import { ReferenceService } from './../../services/reference.service';
import { ValueCheckerService } from './../../core/services/valuechecker.service';
import { PageInfo } from '../../shared/models/pageinfo';
import { WestfieldLocationService } from '../../services/westfieldlocation.service';
import { emptyString, RouteConstants } from '../../core/constants/constants';
import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { finalize, map, takeUntil } from "rxjs/operators";
import { LoadingService } from "../../core/uiservices/loading.service";
import { BaseStateComponent } from "../basestate/basestate.component";
import { ManageWestfieldLocationState } from "./managewestfieldlocation.state";
import { LocationDataSource, WestfieldLocation, WestfieldLocationDisplay } from './managewestfieldlocation.models';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { SuburbAndStateCode } from '../suburbstatecode/suburbstatecode.models';
import { ManageLocationService } from '../../services/managelocations.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { propertyOf } from '../../core/services/reflection.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { UserService } from '../../users/applicationuser.service';
import { countryCodes } from '../../core/i18n/countrycodes';
import { LocatedInDto } from '../managelocations/managelocationsdto';
import { Router } from '@angular/router';
import { ApplicationUser } from '../../users/applicationuser';

export type WestfieldLocationsResponse = {
  items: WestfieldLocation[];
  totalCount: number;
};

@Component({
  selector: 'app-managewestfieldlocation',
  templateUrl: './managewestfieldlocation.component.html',
  styleUrls: ['./managewestfieldlocation.component.scss']
})
export class ManageWestfieldLocation extends BaseStateComponent<ManageWestfieldLocationState> implements OnInit {
  @ViewChild('csvExport') csvExportComponent: CsvExportComponent;
  suburbAndStateCode: SuburbAndStateCode = {
    suburb: emptyString,
    stateCode: emptyString
  };
  indexOfEditingRow: number = -1;
  locationsDataSource: LocationDataSource[] = [];
  filteredLocationsDataSource: LocationDataSource[] = [];
  locationsDataSourceControl = new FormControl();
  locatedInDisplayColumnWidth: number = 150;

  datatableHeaders = {
    centresTitle: "Centres Title",
    locatedInDisplay: "Located In",
    centresCountry: "Centres Country",
    centresPhysicalAddress: "Centres Physical Address",
    centresState: "Centres Sate",
    centresSuburb: "Centres Suburb",
    createDateDisplay: "Create Date",
    modifiedByDisplay: "Modified By",
    closedForNumberOfDaysPotentially: "Closed for number of days potentially",
    distance: "Distance",
    comment: "Comment",
    sourceModifiedDateDisplay: "Modified Date"
  };
  datatableKeys = {
    centresTitle: propertyOf<WestfieldLocationDisplay>('centresTitle'),
    locatedInDisplay: propertyOf<WestfieldLocationDisplay>('locatedInDisplay'),
    centresCountry: propertyOf<WestfieldLocationDisplay>('centresCountry'),
    centresPhysicalAddress: propertyOf<WestfieldLocationDisplay>('centresPhysicalAddress'),
    centresState: propertyOf<WestfieldLocationDisplay>('centresState'),
    centresSuburb: propertyOf<WestfieldLocationDisplay>('centresSuburb'),
    createDateDisplay: propertyOf<WestfieldLocationDisplay>('createDateDisplay'),
    modifiedByDisplay: propertyOf<WestfieldLocationDisplay>('modifiedByDisplay'),
    closedForNoOfDaysPotentially: propertyOf<WestfieldLocationDisplay>('closedForNoOfDaysPotentially'),
    distance: propertyOf<WestfieldLocationDisplay>('distance'),
    comment: propertyOf<WestfieldLocationDisplay>('comment'),
    sourceModifiedDateDisplay: propertyOf<WestfieldLocationDisplay>('sourceModifiedDateDisplay')
  };

  private optionDatatableHeadersAndKeys = {
    headers: [
      this.datatableHeaders.centresTitle,
      this.datatableHeaders.locatedInDisplay,
      this.datatableHeaders.centresCountry,
      this.datatableHeaders.centresPhysicalAddress,
      this.datatableHeaders.centresState,
      this.datatableHeaders.centresSuburb,
      this.datatableHeaders.createDateDisplay,
      this.datatableHeaders.modifiedByDisplay,
      this.datatableHeaders.closedForNumberOfDaysPotentially,
      this.datatableHeaders.distance,
      this.datatableHeaders.comment,
      this.datatableHeaders.sourceModifiedDateDisplay
    ],
    keys: [
      this.datatableKeys.centresTitle,
      this.datatableKeys.locatedInDisplay,
      this.datatableKeys.centresCountry,
      this.datatableKeys.centresPhysicalAddress,
      this.datatableKeys.centresState,
      this.datatableKeys.centresSuburb,
      this.datatableKeys.createDateDisplay,
      this.datatableKeys.modifiedByDisplay,
      this.datatableKeys.closedForNoOfDaysPotentially,
      this.datatableKeys.distance,
      this.datatableKeys.comment,
      this.datatableKeys.sourceModifiedDateDisplay
    ]
  };
  private csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionDatatableHeadersAndKeys };
  private copyOfEditingRetailerCenter: WestfieldLocationDisplay;
  private locationsForCsv: WestfieldLocationDisplay[] = [];
  private manageLocations: LocatedInDto[] = [];
  private applicationUsers: ApplicationUser[] = [];
  private skipLocationsForCsv: number = 0;
  private takeLocationsForCsv: number = 5000;

  constructor(private loadingService: LoadingService, injector: Injector, private retailerCenterService: WestfieldLocationService,
    private valueCheckerService: ValueCheckerService, private dateTimeService: DateTimeService, private manageLocationService: ManageLocationService,
    private csvComponentService: CsvComponentService, private userService: UserService, private referenceService: ReferenceService,
    private router: Router) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      westfieldLocations: [],
      filteredWestfieldLocations: [],
      centerTitleFilter: emptyString,
      suburbFilter: emptyString,
      stateFilter: emptyString,
      storeDeactivatedFilter: null,
      notLinkedlWCLocationFilter: false,
      pageOffset: 0,
      take: 50,
      totalCount: 0,
      skip: 0
    };
    this.restoreState();
    this.suburbAndStateCode = {
      suburb: this.state.suburbFilter,
      stateCode: this.state.stateFilter
    };
    this.locationChanged();
    this.setWestfieldShoppingCenters();
  }

  setWestfieldShoppingCenters() {
    this.loadingService.setLoading();
    this.retailerCenterService.getWestfieldShoppingCenters(
      this.state.suburbFilter,
      this.state.stateFilter,
      this.state.skip,
      this.state.take,
      !this.valueCheckerService.isEmptyNullOrUndefined(this.state.storeDeactivatedFilter)
        ? this.dateTimeService.getSubtractDate(this.state.storeDeactivatedFilter, this.dateTimeService.dateTimeFormatYYYYMMDDhmmDashes)
        : null)
      .pipe(takeUntil(this.destroy$), finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items.length > 0) {
          this.state.westfieldLocations = [...this.state.filteredWestfieldLocations] = [...response.data.items];
          this.setDisplayValues(this.state.westfieldLocations);
          this.state.totalCount = response.data.totalCount;
          this.setLocatedInName();
          this.setModifiedBy();
        }
        else {
          this.state.westfieldLocations = this.state.filteredWestfieldLocations = [];
          this.state.totalCount = 0;
        }
      });
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    this.state.skip = pageInfo.offset * this.state.take;
    this.state.pageOffset = pageInfo.offset;
    this.setWestfieldShoppingCenters();
  }

  setSuburbAndStateCode(suburbAndStateCode: SuburbAndStateCode) {
    this.state.suburbFilter = suburbAndStateCode.suburb;
    this.state.stateFilter = suburbAndStateCode.stateCode;
    this.resetToFirstPage();
    this.setWestfieldShoppingCenters();
  }

  filterByCenterTitle() {
    this.state.filteredWestfieldLocations = this.filterWestfieldShoppingCenters(this.state.westfieldLocations);
  }

  handleClearInputForCenterTitleFilter() {
    this.state.centerTitleFilter = emptyString;
    this.state.filteredWestfieldLocations = this.filterWestfieldShoppingCenters(this.state.westfieldLocations);
  }

  filterWestfieldShoppingCenters(westfieldLocations: WestfieldLocationDisplay[]) {
    let filteredWestfieldLocations = westfieldLocations.filter((retailerCenter: WestfieldLocationDisplay) => {
      let isMatch = true;

      if (isMatch && this.state.notLinkedlWCLocationFilter === true) {
        isMatch = this.valueCheckerService.isEmptyNullOrUndefined(retailerCenter.locatedIn);
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.centerTitleFilter)) {
        isMatch = retailerCenter.centresTitle.toLowerCase().includes(this.state.centerTitleFilter.toLowerCase());
      }
      return isMatch;
    });
    return filteredWestfieldLocations;
  }

  downloadCsvFile() {
    if (this.locationsForCsv.length < this.state.totalCount) {
      if (this.state.totalCount >= 5000) {
        this.setCsvFile(this.skipLocationsForCsv, this.takeLocationsForCsv);
      }
      else {
        this.setCsvFile(0, this.state.totalCount);
      }
    };
    if (this.locationsForCsv.length === this.state.totalCount) {
      this.csvExportComponent.data = this.filterWestfieldShoppingCenters(this.locationsForCsv);
      this.csvExportComponent.options = this.csvOptions;
      this.skipLocationsForCsv = 0;
      this.csvExportComponent.onDownload();
      this.locationsForCsv = [];
    }
  }

  editRetailerCenter(row: WestfieldLocationDisplay, rowIndex: number) {
    this.copyOfEditingRetailerCenter = { ...row };
    this.indexOfEditingRow = rowIndex;
    this.setLocations();
    this.locatedInDisplayColumnWidth = 363;
  }

  save(row: WestfieldLocationDisplay) {
    this.loadingService.setLoading();
    this.retailerCenterService.updateRetailerCenterWithLocationId(row.centresId, row.comment, this.filteredLocationsDataSource[0].locationId, row.modifiedBy)
      .subscribe(() => {
        this.locationsDataSourceControl.setValue(emptyString);
        this.indexOfEditingRow = -1;
        this.setWestfieldShoppingCenters();
      });
  }

  cancel(rowIndex: number) {
    this.state.filteredWestfieldLocations[rowIndex] = this.copyOfEditingRetailerCenter;
    this.state.filteredWestfieldLocations = [...this.state.filteredWestfieldLocations];
    this.indexOfEditingRow = -1;
    this.locatedInDisplayColumnWidth = 150;
  }

  isSaveButtonDisabled() {
    return this.valueCheckerService.isNullOrUndefined(this.locationsDataSourceControl.value);
  }

  createWestFieldLocation(row: WestfieldLocationDisplay) {
    this.loadingService.setLoading();
    this.retailerCenterService.getRetailerCenterBasedOnCenterId(row.centresId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();

        if (response && response.data && response.data.items.length > 0) {
          let westfieldLocationsResponse = response.data.items[0];
          let locatedInDto: LocatedInDto = {
            formatted_phone_number: emptyString,
            openingTimes: emptyString,
            googlePlaceID: emptyString,
            isStadium: false,
            isHospital: false,
            isUni: false,
            isShoppingCentre: false,
            isAirport: false,
            isTrainStation: false,
            centreURL: emptyString,
            latLonPrecision: null,
            formattedAddressString: emptyString,
            streetViewAvailable: true,
            mapable: null,
            geoCodeGranularity: emptyString,
            latitude: westfieldLocationsResponse.latitude,
            addressCountryCd: countryCodes.find(t => t.name === westfieldLocationsResponse.centresCountry)?.alpha3,
            addressPostcode: westfieldLocationsResponse.postCode ? westfieldLocationsResponse.postCode : emptyString,
            addressStateCd: westfieldLocationsResponse.centresState,
            addressSuburb: westfieldLocationsResponse.centresSuburb,
            addressLine2: emptyString,
            addressLine1: westfieldLocationsResponse.centresPhysicalAddress,
            locatedIn_Name: westfieldLocationsResponse.centresTitle,
            locatedIn_ID: westfieldLocationsResponse.locatedIn,
            longitude: westfieldLocationsResponse.longitude
          };

          this.manageLocationService.setLocatedIn(locatedInDto);
          this.router.navigate([RouteConstants.editLocation]);
        }
      });
  }

  filterByStoreDeactivated() {
    this.resetToFirstPage();
    this.setWestfieldShoppingCenters();
  }

  filterByNotLinkedLwcLocation() {
    this.state.filteredWestfieldLocations = this.filterWestfieldShoppingCenters(this.state.westfieldLocations);
  }

  private setDisplayValues(retailerCenters: WestfieldLocationDisplay[]) {
    for (let retailerCenter of retailerCenters) {
      retailerCenter.createDateDisplay = this.dateTimeService.formatWithHours(retailerCenter.createDate);
      retailerCenter.sourceModifiedDateDisplay = this.dateTimeService.formatWithHours(retailerCenter.sourceModifiedDate);
    }
  }

  private setLocatedInName() {
    this.loadingService.setLoading();
    this.manageLocationService.getManageLocations()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        this.manageLocations = response.data;
        for (let location of response.data) {
          let retailerCenter = this.state.westfieldLocations.find(t => t.locatedIn === location.locatedIn_ID);
          if (retailerCenter) {
            retailerCenter.locatedInDisplay = location.locatedIn_Name;
          }
        }
      });
  }

  private setModifiedBy() {
    this.userService.getApplicationUsersCached()
      .subscribe((applicationUsers) => {
        this.applicationUsers = applicationUsers;
        for (let applicationUser of applicationUsers) {
          let retailerCenter = this.state.filteredWestfieldLocations.find(t => t.modifiedBy === applicationUser.Id);
          if (retailerCenter) {
            retailerCenter.modifiedByDisplay = applicationUser.FirstName + " " + applicationUser.LastName;
          }
        }
        this.state.filteredWestfieldLocations = this.filterWestfieldShoppingCenters(this.state.westfieldLocations);
      });
  }

  private setLocations() {
    if (this.locationsDataSource.length === 0) {
      this.referenceService.getLocations()
        .subscribe(locations => {
          for (let location of locations) {
            this.locationsDataSource.push({
              address: location.AddressPostcode + " " + location.LocatedIn_Name,
              locationId: location.LocatedIn_ID
            })
          }
        });
    }
  }

  private locationChanged() {
    this.locationsDataSourceControl.valueChanges.pipe(
      map((address: string) =>
        !this.valueCheckerService.isEmptyNullOrUndefined(address)
         ? this.locationsDataSource.filter(t => t.address.toLowerCase().includes(address.toLowerCase()))
         : this.locationsDataSource
      ))
      .subscribe(result => {
        this.filteredLocationsDataSource = result.slice(0, 200);
      });
  }

  private resetToFirstPage() {
    this.state.skip = 0;
    this.state.pageOffset = 0;
  }

  private setCsvFile(skip: number, take: number) {
    let westfieldLocations: WestfieldLocationDisplay[] = [];
    this.loadingService.setLoading();
    this.retailerCenterService.getWestfieldShoppingCenters(
      this.state.suburbFilter,
      this.state.stateFilter,
      skip,
      take,
      !this.valueCheckerService.isEmptyNullOrUndefined(this.state.storeDeactivatedFilter)
        ? this.dateTimeService.getSubtractDate(this.state.storeDeactivatedFilter, this.dateTimeService.dateTimeFormatYYYYMMDDhmmDashes)
        : null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items.length > 0) {
          westfieldLocations = response.data.items;
          this.setDisplayValues(westfieldLocations);
          for (let location of this.manageLocations) {
            let retailerCenter = westfieldLocations.find(t => t.locatedIn === location.locatedIn_ID);
            if (retailerCenter) {
              retailerCenter.locatedInDisplay = location.locatedIn_Name;
            }
          }
          for (let applicationUser of this.applicationUsers) {
            let retailerCenter = westfieldLocations.find(t => t.modifiedBy === applicationUser.Id);
            if (retailerCenter) {
              retailerCenter.modifiedByDisplay = applicationUser.FirstName + " " + applicationUser.LastName;
            }
          }
          this.locationsForCsv = [...this.locationsForCsv, ...westfieldLocations];
          this.skipLocationsForCsv += 5000;
          this.downloadCsvFile();
        }
      });
  }
}
