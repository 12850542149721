import { propertyOf } from "../../core/services/reflection.service";

export interface LwcMerchantSearchState {
  cal: string;
  // lwcid: number;
  // lwcguid: string;
  // abn: number;
  selectedlwcsource: boolean;
  selectedchain: string;
  chainsuburb: string;
  chainid: number;
  // primaryName: string;
  // website: string;
  inputType: string;
}

export interface MerchantSummaryForCsv {
  LWC_ID: number,
  PrimaryName: string,
  PhoneNumber: string,
  PrimaryAddress: string
  PrimaryCategory: string
  Email: string
  ABN: string
  MerchantType: string
  MerchantPresence: string
  Tags: string[]
  ChainName: string
  Url: string
  DataCompletnessScore: number
  RecordLocked: boolean
  IsQuarantined: boolean
  BSBs: string[]
};

export const MerchantSummaryForCsvProps = {
  LWC_ID: propertyOf<MerchantSummaryForCsv>('LWC_ID'),
  PrimaryName: propertyOf<MerchantSummaryForCsv>('PrimaryName'),
  PhoneNumber: propertyOf<MerchantSummaryForCsv>('PhoneNumber'),
  PrimaryAddress: propertyOf<MerchantSummaryForCsv>('PrimaryAddress'),
  PrimaryCategory: propertyOf<MerchantSummaryForCsv>('PrimaryCategory'),
  ABN: propertyOf<MerchantSummaryForCsv>('ABN'),
  MerchantType: propertyOf<MerchantSummaryForCsv>('MerchantType'),
  MerchantPresence: propertyOf<MerchantSummaryForCsv>('MerchantPresence'),
  Tags: propertyOf<MerchantSummaryForCsv>('Tags'),
  ChainName: propertyOf<MerchantSummaryForCsv>('ChainName'),
  Url: propertyOf<MerchantSummaryForCsv>('Url'),
  DataCompletnessScore: propertyOf<MerchantSummaryForCsv>('DataCompletnessScore'),
  RecordLocked: propertyOf<MerchantSummaryForCsv>('RecordLocked'),
  IsQuarantined: propertyOf<MerchantSummaryForCsv>('IsQuarantined'),
  BSBs: propertyOf<MerchantSummaryForCsv>('BSBs'),
}

export interface MerchantDetailsResponse {
  LWC_ID: number
  MerchantType: string
  ABN: string
  MerchantPresence: string
  ChainName: any
  DataCompletnessScore: number
  UpdatedDate: string
  PrimaryName: string
  PrimaryCategory: string
  PrimaryAddress: string
  LocatedIn_Name: any
  LocatedIn_Id: any
  StreetViewAvailable: boolean
  PhoneNumber: string
  URL: string
  CALs: string
  CALsWithType: string
  Email: string
  RecordLocked: boolean
  IsQuarantined: boolean
  SquareLogo: string
  CircularLogo: string
  ThreeToOneLogo: string
  SquareLogoDark: string
  CircularLogoDark: string
  ThreeToOneLogoDark: string
  QuaratinedCALs: string
  Tags: string
  BSBs: any
  CurrentBusinessStatus: string
  BPayBillerCode: any
}



