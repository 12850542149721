import { Directive, ElementRef, Input, OnInit, OnDestroy, ViewContainerRef,
     ComponentFactoryResolver, AfterViewInit, ComponentFactory, ComponentRef } from '@angular/core';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { HotkeyTooltipComponent } from '../hotkeytooltip/hotkeytooltip.component';
import { HotkeyInfoService } from '../../core/uiservices/hotkeyinfo.service';
import { Router } from '@angular/router';
import { HotkeyInfoMessage } from '../models/hotkeyinfomessage';

@Directive({
    selector: '[appHotkey]'
})
export class HotkeyDirective implements OnInit, OnDestroy, AfterViewInit {
    private hotkey: Hotkey | Hotkey[];
    private hotkeyLetter: string;
    private factory: ComponentFactory<HotkeyTooltipComponent>;
    private tooltip: ComponentRef<HotkeyTooltipComponent>;
    private hotkeyModifiers: string[];
    @Input('appHotkey') appHotkey: string;

    constructor(private viewContainerRef: ViewContainerRef, private hostElement: ElementRef, private hotkeysService: HotkeysService,
        private componentFactoryResolver: ComponentFactoryResolver, private hotkeyInfoService: HotkeyInfoService, private router: Router) {
    }

    ngOnInit() {
        this.hotkeyLetter = this.appHotkey.slice(-1).toUpperCase();
        this.hotkeyModifiers = this.getHotkeyModifiers();
        this.hotkey = this.hotkeysService.add(new Hotkey(this.appHotkey, () => {
            this.activateHotkey();
            return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
        this.factory = this.componentFactoryResolver.resolveComponentFactory(HotkeyTooltipComponent);
        this.tooltip = this.viewContainerRef.createComponent<HotkeyTooltipComponent>(this.factory);
        this.hotkeyInfoService.hotkeyInfo$.subscribe(hotkeyInfoMessage => {
            this.showHideHotkeyInfo(hotkeyInfoMessage);
        });
    }

    ngAfterViewInit() {
        this.tooltip.instance.hostElement = this.hostElement.nativeElement;
        this.tooltip.instance.hotkeyLetter = this.hotkeyLetter;
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.hotkey);
        this.tooltip.destroy();
    }

    private showHideHotkeyInfo(hotkeyInfoMessage: HotkeyInfoMessage) {
        let shouldShow = true;
        if (hotkeyInfoMessage.altPressed) {
            shouldShow = this.hotkeyModifiers.includes('alt');
        } else if (this.hotkeyModifiers.includes('alt')) {
            shouldShow = false;
        }

        if (shouldShow && hotkeyInfoMessage.controlPressed) {
            shouldShow = this.hotkeyModifiers.includes('ctrl');
        } else if (shouldShow && this.hotkeyModifiers.includes('ctrl')) {
            shouldShow = false;
        }

        if (shouldShow) {
            this.tooltip.instance.show();
        } else {
            this.tooltip.instance.hide();
        }
    }

    private activateHotkey() {
        if (this.hostElement.nativeElement.nodeName === 'BUTTON') {
            this.hostElement.nativeElement.click();
        } else if (this.hostElement.nativeElement.nodeName === 'INPUT') {
            this.hostElement.nativeElement.focus();
        } else if (this.hostElement.nativeElement.pathname) {
            this.router.navigate([this.hostElement.nativeElement.pathname]);
        } else if (this.hostElement.nativeElement.nodeName === 'APP-CSV-EXPORT') {
            this.hostElement.nativeElement.children[0].click();
        }
    }

    private getHotkeyModifiers() {
        let hotkeyPartsWithLetter = this.appHotkey.split('+');
        let hotkeyModifiers = hotkeyPartsWithLetter.slice(0, hotkeyPartsWithLetter.length - 1);
        return hotkeyModifiers;
    }
}
