import { Injectable } from '@angular/core';

@Injectable()
export class IconResultService {
  getOutcomeIcon(result: boolean) {
    return result ? 'done_all' : 'error';
  }

  getOutcomeCssClass(result: boolean) {
    return 'mat-icon material-icons ' + (result ? 'success' : 'danger');
  }
}
