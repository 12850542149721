// `.env.json` is generated by the `npm run build` command
// import env from './.env.json';
export const environment = {
  production: true,
  // version: env.npm_package_version,
  serverUrl: '/api',
  useMockBackend: false,
  apiUrl: 'https://lwcindexmanagementserviceaeprod02.azurewebsites.net/',
  about: 'Index Manager Client - v20240412.1',
  adalConfig: {
    tenant: '2cadd3f4-9f56-4060-a6ed-5b84be621aa5',
    clientId: '0f51588e-d4dd-40b6-91e6-2dbad8f8b621',
    redirectUri: 'https://indexmanager.lookwhoscharging.com/auth-callback',
    endpoints: {
//      "https://indexmanager-api.lookwhoscharging.com/": "3b0aad0c-a95f-4743-bb81-054ea5c535e9"
      "https://lwcindexmanagementserviceaeprod02.azurewebsites.net/": "3b0aad0c-a95f-4743-bb81-054ea5c535e9"
    },
    navigateToLoginRequestUrl: false,
    extraQueryParameter: ''
  },
  instrumentationKey: 'c090da68-2447-4037-8a21-f246b29ab45f'
};
