import { ExecutionResult } from "graphql";
import { SearchFeedbackResponse } from "../../services/searchfeedback.service";

export let suggestionsResponse: ExecutionResult<SearchFeedbackResponse> = {
  data: {
      items: [
        {
          cal: "V026205/08movie-tonight.comINTERNET24021219217",
          searchFeedbackId: 38829,
          isIndexed: true,
          userComment: null,
          createDate: '17/Sep/2019 01:27 PM',
          email: 'v@example.com',
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification2',
          lwcComment: 'comment3'
        },
        {
          cal: "EFTPOS COLES 0554 CAROLINE SPRSAU",
          searchFeedbackId: 38827,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: 'first@example.com',
          mlConfidence: 0.527773606777191,
          mlUseful: true,
          clientName: 'Davies',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "Paypal *Xianggangji 4029357733",
          searchFeedbackId: 38828,
          isIndexed: true,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.327773606777191,
          mlUseful: false,
          clientName: 'Taylor',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification4',
          lwcComment: 'comment3'
        },
        {
          cal: "BURLEIGH WATERS MIAMI QLD",
          searchFeedbackId: 38826,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Brown',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "Eftpos Software Pro World London",
          searchFeedbackId: 38825,
          isIndexed: true,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.627773606777191,
          mlUseful: false,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "Sandro Paris 12 Fr Pos Authorisation",
          searchFeedbackId: 38824,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: 'tod@example.com',
          mlConfidence: 0.427773606777191,
          mlUseful: false,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "Topmember 18665993580 Gb Pos Authorisation",
          searchFeedbackId: 38823,
          isIndexed: true,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.927773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "LINDT ",
          searchFeedbackId: 38822,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.227773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "QANTAS AIRWAYS LTD (MO MASCOT",
          searchFeedbackId: 38821,
          isIndexed: true,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.127773606777191,
          mlUseful: true,
          clientName: 'Wilson',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "News Limited Surry Hills AU",
          searchFeedbackId: 38820,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: 'peet@example.com',
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "Sp * Lelime Co Warrensburg Mo Pos Authorisation",
          searchFeedbackId: 38819,
          isIndexed: true,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.827773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification3',
          lwcComment: 'comment3'
        },
        {
          cal: "V239204/08AHMKPTYLTDWOLLONGONG74940529216",
          searchFeedbackId: 38818,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.927773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification4',
          lwcComment: 'comment3'
        },
        {
          cal: "D AGOSTINO RP AND RC SHEPPARTON VI",
          searchFeedbackId: 38817,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification4',
          lwcComment: 'comment1'
        },
        {
          cal: "CANUNGRA PIZZA CANUNGRA AU",
          searchFeedbackId: 38816,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification4',
          lwcComment: 'comment1'
        },
        {
          cal: "Fwhelpnet.Com 8773290284 Cy Pos Authorisation",
          searchFeedbackId: 38815,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.427773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification4',
          lwcComment: 'comment1'
        },
        {
          cal: "NETFLIX.COM MELBOURNE",
          searchFeedbackId: 38814,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification4',
          lwcComment: 'comment1'
        },
        {
          cal: "TVSHOP 611800222684 AU",
          searchFeedbackId: 38813,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment1'
        },
        {
          cal: "ELTHAM PHARMACY ELTHAM",
          searchFeedbackId: 38810,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment1'
        },
        {
          cal: "APPLE ITUNES STORE SYDNEY AU",
          searchFeedbackId: 38811,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment1'
        },
        {
          cal: "GOOGLE*HANGZHOU KANYI G.CO HELPPAY",
          searchFeedbackId: 38812,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment1'
        },
        {
          cal: "APPLE ITUNES STORE SYDNEY AU",
          searchFeedbackId: 38809,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment1'
        },
        {
          cal: "APPLE ITUNES STORE SYDNEY AU",
          searchFeedbackId: 38808,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment1'
        },
        {
          cal: "Hertz Uk Limited Middlesex Gb Pos Authorisation",
          searchFeedbackId: 38807,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "Eftpos Nakd.Com Goteborg",
          searchFeedbackId: 38806,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "CIRCUMTEC MENTONE AU",
          searchFeedbackId: 38805,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "APPLE ITUNES STORE SYDNEY",
          searchFeedbackId: 38804,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "Cttnhh Dia Oc-Dl Dong Haikhanh Hoa Vn Pos Authorisation",
          searchFeedbackId: 38802,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "Playstationnetwork London Gb Pos Authorisation",
          searchFeedbackId: 38803,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "THE LOCL HTL STH FMT SOUTH FREMANT",
          searchFeedbackId: 38801,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "Brojake NarreW AU",
          searchFeedbackId: 38800,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "Playstationnetwork London Gb Pos Authorisation",
          searchFeedbackId: 38799,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Amelia',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification5',
          lwcComment: 'comment3'
        },
        {
          cal: "Eftpos Playzpop.Com 18889966394",
          searchFeedbackId: 38798,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification6',
          lwcComment: 'comment3'
        },
        {
          cal: "Microsoft*XboxLiveGoldmsbill.info",
          searchFeedbackId: 38797,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification6',
          lwcComment: 'comment3'
        },
        {
          cal: "PAYPAL *ZOOS VIC 039340 AU",
          searchFeedbackId: 38795,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification6',
          lwcComment: 'comment3'
        },
        {
          cal: "CHBD INST YOUTH ST KILDA EAST",
          searchFeedbackId: 38796,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification6',
          lwcComment: 'comment3'
        },
        {
          cal: "Visa purchase HUMM AUSTRALIA ADELAIDE",
          searchFeedbackId: 38794,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification6',
          lwcComment: 'comment3'
        },
        {
          cal: "Bookpall.com",
          searchFeedbackId: 38793,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification6',
          lwcComment: 'comment3'
        },
        {
          cal: "Menulog Pty Ltd Sydney AU",
          searchFeedbackId: 38792,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification7',
          lwcComment: 'comment3'
        },
        {
          cal: "APPLE ITUNES STORE SYDNEY",
          searchFeedbackId: 38791,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification7',
          lwcComment: 'comment3'
        },
        {
          cal: "Dctfve.com 8003019212 Gb Pos Authorisation",
          searchFeedbackId: 38790,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification7',
          lwcComment: 'comment3'
        },
        {
          cal: "APPLE ITUNES STORE SYDNEY AU",
          searchFeedbackId: 38789,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Lily',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification7',
          lwcComment: 'comment3'
        },
        {
          cal: "APPLE ITUNES STORE SYDNEY AU",
          searchFeedbackId: 38788,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification7',
          lwcComment: 'comment3'
        },
        {
          cal: "Epoch.Com *mgbilling Epoch.Com Ca Pos Authorisation",
          searchFeedbackId: 38787,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification7',
          lwcComment: 'comment3'
        },
        {
          cal: "PAYPAL *ELISHAHOWEL Sydney AU",
          searchFeedbackId: 38786,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: false,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification7',
          lwcComment: 'comment3'
        },
        {
          cal: "PAYPAL *SHELBYLUCIS 4029357733 AU",
          searchFeedbackId: 38785,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment3'
        },
        {
          cal: "PORT CITY BOWL PRT MACQUAR AU",
          searchFeedbackId: 38784,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "PAYPAL *MASONONLINE Sydney AU",
          searchFeedbackId: 38783,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "Ndvehh.com 877-811-9180 Gb Pos Authorisation",
          searchFeedbackId: 38782,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "Total Mkt Fr Nanterre Fr Pos Authorisation",
          searchFeedbackId: 38781,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "Core Chiropractic ",
          searchFeedbackId: 38780,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "DOWNUNDER TOURS BUNGALOW",
          searchFeedbackId: 38779,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "Smv Ecom Pune in Pos Authorisation",
          searchFeedbackId: 38778,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Jessica',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "MORNINGTON PENINSULA MORNINGTON",
          searchFeedbackId: 38777,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "XCSEFV.COM8665685912",
          searchFeedbackId: 38776,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "PAYPAL *DOMINOS Hamilton AU",
          searchFeedbackId: 38774,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "RACV IDP MELBOU AU",
          searchFeedbackId: 38775,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "Cardpaymenttoimpact Bournemouth Gb Pos Authorisation",
          searchFeedbackId: 38773,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification',
          lwcComment: 'comment2'
        },
        {
          cal: "Visa Debit Purchase Md Global Pty. Ltd. Melton West",
          searchFeedbackId: 38772,
          isIndexed: false,
          userComment: null,
          createDate: '11/Mar/2019 10:20 AM',
          email: null,
          mlConfidence: 0.727773606777191,
          mlUseful: true,
          clientName: 'Poopy',
          searchCount: 159922.68,
          calFeedbackCount: 133322.43,
          searchFeedbackHumanClassification: 'classification2',
          lwcComment: 'comment2'
        }
      ], totalCount: 500
  }
}

export let suggestionEditResponse: ExecutionResult<unknown> = { data: { upsertsuggestion: {} } };
