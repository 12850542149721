import { ExecutionResult } from "graphql";
import { CategoryDtoStandardiserDto, CategoryStandardiserDraft } from "../../components/categorystandardiser/categorystandardiserdto";
import { CategoryStandardiserTree } from "../../components/categorystandardiser/categorystandardiserdto";

export let categoryDataSourceNamesResponse: string[] = [
    "AMEX",
    "ClearbitIndustry",
    "ClearbitIndustryGroup",
    "ClearbitSector",
    "ClearbitSubIndustry",
    "FacebookPlace",
    "FourSquare",
    "GooglePlaces",
    "MastercardIndustry",
    "MastercardSuperIndustry",
    "MerchantCategoryCode",
    "Sensis",
    "ZomatoCuisine",
    "ZomatoEstablishmentTypes",
    "FacebookPlace",
    "Clearbit",
    "MerchantWebsite",
    "Amazon"
];

export let categoryStandardiserDraftResponse: CategoryStandardiserDraft[] = [
    {
        Id: 1,
        CategoryStandardiserID: 0,
        CategoryDataSource: "AMEX",
        CategoryDataSourceType: "INCOME TAX",
        LWCCategoryID: 21,
        LWCCategoryName: "Tax",
        ExternalCategoryType: "16",
        OperationType: "Insert",
        UpdatedBy: 2,
        LastUpdated: "2021-09-28T01:31:00",
        IsActive: true
    },
    {
        Id: 2,
        CategoryStandardiserID: 0,
        CategoryDataSource: "AMEX",
        CategoryDataSourceType: "Sports equipment",
        LWCCategoryID: 1016,
        LWCCategoryName: "Sports",
        ExternalCategoryType: "12010511",
        OperationType: "Insert",
        UpdatedBy: 3,
        LastUpdated: "2021-09-28T01:32:00",
        IsActive: true
    },
    {
        Id: 5,
        CategoryStandardiserID: 0,
        CategoryDataSource: "AMEX",
        CategoryDataSourceType: "HOUSEHOLD FURNISHINGS",
        LWCCategoryID: 1003,
        LWCCategoryName: "Home",
        ExternalCategoryType: "07",
        OperationType: "Insert",
        UpdatedBy: 1,
        LastUpdated: "2021-09-28T10:11:00",
        IsActive: true
    },
    {
        Id: 6,
        CategoryStandardiserID: 21690,
        CategoryDataSource: "AMEX",
        CategoryDataSourceType: "Miscellaneous services",
        LWCCategoryID: 5002,
        LWCCategoryName: "Manufacturing/Engineering",
        ExternalCategoryType: "1502",
        OperationType: "Delete",
        UpdatedBy: 3,
        LastUpdated: "2021-09-28T11:00:00",
        IsActive: true
    }
];


export let updateOrRejectCategoryStandardiserDraftResponse: string[] = [
    "Reject Category Standardiser successfully for 5.",
    "Reject Category Standardiser successfully for 6.",
    "Reject Category Standardiser successfully for 1.",
    "Reject Category Standardiser successfully for 2.",
]

export let categoryStandardiserTreeResponse: CategoryStandardiserTree = {
	LWCCategories: [
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "0",
		  CategoryName: "Unclassified",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "1",
		  CategoryName: "Shopping",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1001",
			  CategoryName: "Clothing",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001001",
				  CategoryName: "Accessories",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23023,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Sub category 1",
					  LWCCategoryID: 1001001,
					  LWCCategoryName: "Accessories",
					  ExternalCategoryType: "1001",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23025,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Sub category 2",
					  LWCCategoryID: 1001001,
					  LWCCategoryName: "Accessories",
					  ExternalCategoryType: "1002",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001002",
				  CategoryName: "Sleepwear/Underwear",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001002,
					  LWCCategoryName: "Sleepwear/Underwear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001002,
					  LWCCategoryName: "Sleepwear/Underwear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001002,
					  LWCCategoryName: "Sleepwear/Underwear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001002,
					  LWCCategoryName: "Sleepwear/Underwear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001002,
					  LWCCategoryName: "Sleepwear/Underwear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001002,
					  LWCCategoryName: "Sleepwear/Underwear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001004",
				  CategoryName: "Surf",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001004,
					  LWCCategoryName: "Surf",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001004,
					  LWCCategoryName: "Surf",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001004,
					  LWCCategoryName: "Surf",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001004,
					  LWCCategoryName: "Surf",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001004,
					  LWCCategoryName: "Surf",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001004,
					  LWCCategoryName: "Surf",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001005",
				  CategoryName: "Sport",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001005,
					  LWCCategoryName: "Sport",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001005,
					  LWCCategoryName: "Sport",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001005,
					  LWCCategoryName: "Sport",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001005,
					  LWCCategoryName: "Sport",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001005,
					  LWCCategoryName: "Sport",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001005,
					  LWCCategoryName: "Sport",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001006",
				  CategoryName: "Shoes",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001006,
					  LWCCategoryName: "Shoes",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001006,
					  LWCCategoryName: "Shoes",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001006,
					  LWCCategoryName: "Shoes",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001006,
					  LWCCategoryName: "Shoes",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001006,
					  LWCCategoryName: "Shoes",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001006,
					  LWCCategoryName: "Shoes",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001007",
				  CategoryName: "Tailor",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001007,
					  LWCCategoryName: "Tailor",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001007,
					  LWCCategoryName: "Tailor",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001007,
					  LWCCategoryName: "Tailor",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001007,
					  LWCCategoryName: "Tailor",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001007,
					  LWCCategoryName: "Tailor",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001007,
					  LWCCategoryName: "Tailor",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001008",
				  CategoryName: "Rental",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001008,
					  LWCCategoryName: "Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001008,
					  LWCCategoryName: "Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001008,
					  LWCCategoryName: "Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001008,
					  LWCCategoryName: "Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001008,
					  LWCCategoryName: "Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001008,
					  LWCCategoryName: "Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001009",
				  CategoryName: "Costume",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23026,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Sub category 3",
					  LWCCategoryID: 1001009,
					  LWCCategoryName: "Costume",
					  ExternalCategoryType: "1003",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001011",
				  CategoryName: "Uniform",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001011,
					  LWCCategoryName: "Uniform",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001011,
					  LWCCategoryName: "Uniform",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001011,
					  LWCCategoryName: "Uniform",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001011,
					  LWCCategoryName: "Uniform",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001011,
					  LWCCategoryName: "Uniform",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001011,
					  LWCCategoryName: "Uniform",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001012",
				  CategoryName: "Bridal/Formal",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001012,
					  LWCCategoryName: "Bridal/Formal",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001012,
					  LWCCategoryName: "Bridal/Formal",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001012,
					  LWCCategoryName: "Bridal/Formal",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001012,
					  LWCCategoryName: "Bridal/Formal",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001012,
					  LWCCategoryName: "Bridal/Formal",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001012,
					  LWCCategoryName: "Bridal/Formal",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001013",
				  CategoryName: "Maternity",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001013,
					  LWCCategoryName: "Maternity",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001013,
					  LWCCategoryName: "Maternity",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001013,
					  LWCCategoryName: "Maternity",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001013,
					  LWCCategoryName: "Maternity",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001013,
					  LWCCategoryName: "Maternity",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001013,
					  LWCCategoryName: "Maternity",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001014",
				  CategoryName: "Childrenswear",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001014,
					  LWCCategoryName: "Childrenswear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001014,
					  LWCCategoryName: "Childrenswear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001014,
					  LWCCategoryName: "Childrenswear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001014,
					  LWCCategoryName: "Childrenswear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001014,
					  LWCCategoryName: "Childrenswear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001014,
					  LWCCategoryName: "Childrenswear",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001015",
				  CategoryName: "Womens Clothing",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001015,
					  LWCCategoryName: "Womens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001015,
					  LWCCategoryName: "Womens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001015,
					  LWCCategoryName: "Womens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001015,
					  LWCCategoryName: "Womens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001015,
					  LWCCategoryName: "Womens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001015,
					  LWCCategoryName: "Womens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1001016",
				  CategoryName: "Mens Clothing",
				  ParentId: "1001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1001016,
					  LWCCategoryName: "Mens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001016,
					  LWCCategoryName: "Mens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001016,
					  LWCCategoryName: "Mens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1001016,
					  LWCCategoryName: "Mens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001016,
					  LWCCategoryName: "Mens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1001016,
					  LWCCategoryName: "Mens Clothing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1001,
				  LWCCategoryName: "Clothing",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1001,
				  LWCCategoryName: "Clothing",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1001,
				  LWCCategoryName: "Clothing",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1001,
				  LWCCategoryName: "Clothing",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1001,
				  LWCCategoryName: "Clothing",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1001,
				  LWCCategoryName: "Clothing",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1002",
			  CategoryName: "Toys/Hobby",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1002001",
				  CategoryName: "Hobby Store",
				  ParentId: "1002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1002001,
					  LWCCategoryName: "Hobby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1002001,
					  LWCCategoryName: "Hobby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002001,
					  LWCCategoryName: "Hobby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1002001,
					  LWCCategoryName: "Hobby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002001,
					  LWCCategoryName: "Hobby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002001,
					  LWCCategoryName: "Hobby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1002002",
				  CategoryName: "Toy Store",
				  ParentId: "1002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1002002,
					  LWCCategoryName: "Toy Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1002002,
					  LWCCategoryName: "Toy Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002002,
					  LWCCategoryName: "Toy Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1002002,
					  LWCCategoryName: "Toy Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002002,
					  LWCCategoryName: "Toy Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002002,
					  LWCCategoryName: "Toy Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1002004",
				  CategoryName: "Art/Craft",
				  ParentId: "1002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1002004,
					  LWCCategoryName: "Art/Craft",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1002004,
					  LWCCategoryName: "Art/Craft",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002004,
					  LWCCategoryName: "Art/Craft",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1002004,
					  LWCCategoryName: "Art/Craft",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002004,
					  LWCCategoryName: "Art/Craft",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1002004,
					  LWCCategoryName: "Art/Craft",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1002,
				  LWCCategoryName: "Toys/Hobby",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1002,
				  LWCCategoryName: "Toys/Hobby",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1002,
				  LWCCategoryName: "Toys/Hobby",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1002,
				  LWCCategoryName: "Toys/Hobby",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1002,
				  LWCCategoryName: "Toys/Hobby",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1002,
				  LWCCategoryName: "Toys/Hobby",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1003",
			  CategoryName: "Home",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1003001",
				  CategoryName: "Gift",
				  ParentId: "1003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1003001,
					  LWCCategoryName: "Gift",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1003001,
					  LWCCategoryName: "Gift",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003001,
					  LWCCategoryName: "Gift",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1003001,
					  LWCCategoryName: "Gift",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003001,
					  LWCCategoryName: "Gift",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003001,
					  LWCCategoryName: "Gift",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1003002",
				  CategoryName: "Home Store",
				  ParentId: "1003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1003002,
					  LWCCategoryName: "Home Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1003002,
					  LWCCategoryName: "Home Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003002,
					  LWCCategoryName: "Home Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1003002,
					  LWCCategoryName: "Home Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003002,
					  LWCCategoryName: "Home Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003002,
					  LWCCategoryName: "Home Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1003003",
				  CategoryName: "Haberdashery",
				  ParentId: "1003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1003003,
					  LWCCategoryName: "Haberdashery",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1003003,
					  LWCCategoryName: "Haberdashery",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003003,
					  LWCCategoryName: "Haberdashery",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1003003,
					  LWCCategoryName: "Haberdashery",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003003,
					  LWCCategoryName: "Haberdashery",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1003003,
					  LWCCategoryName: "Haberdashery",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1003,
				  LWCCategoryName: "Home",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1003,
				  LWCCategoryName: "Home",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1003,
				  LWCCategoryName: "Home",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1003,
				  LWCCategoryName: "Home",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1003,
				  LWCCategoryName: "Home",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1003,
				  LWCCategoryName: "Home",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1004",
			  CategoryName: "Outdoor",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1004001",
				  CategoryName: "Fishing",
				  ParentId: "1004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1004001,
					  LWCCategoryName: "Fishing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004001,
					  LWCCategoryName: "Fishing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004001,
					  LWCCategoryName: "Fishing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004001,
					  LWCCategoryName: "Fishing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004001,
					  LWCCategoryName: "Fishing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004001,
					  LWCCategoryName: "Fishing",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1004002",
				  CategoryName: "Camping",
				  ParentId: "1004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1004002,
					  LWCCategoryName: "Camping",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004002,
					  LWCCategoryName: "Camping",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004002,
					  LWCCategoryName: "Camping",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004002,
					  LWCCategoryName: "Camping",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004002,
					  LWCCategoryName: "Camping",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004002,
					  LWCCategoryName: "Camping",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1004003",
				  CategoryName: "Garden",
				  ParentId: "1004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1004003,
					  LWCCategoryName: "Garden",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004003,
					  LWCCategoryName: "Garden",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004003,
					  LWCCategoryName: "Garden",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004003,
					  LWCCategoryName: "Garden",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004003,
					  LWCCategoryName: "Garden",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004003,
					  LWCCategoryName: "Garden",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1004004",
				  CategoryName: "Florist",
				  ParentId: "1004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1004004,
					  LWCCategoryName: "Florist",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004004,
					  LWCCategoryName: "Florist",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004004,
					  LWCCategoryName: "Florist",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004004,
					  LWCCategoryName: "Florist",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004004,
					  LWCCategoryName: "Florist",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004004,
					  LWCCategoryName: "Florist",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1004006",
				  CategoryName: "Equipment",
				  ParentId: "1004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1004006,
					  LWCCategoryName: "Equipment",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004006,
					  LWCCategoryName: "Equipment",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004006,
					  LWCCategoryName: "Equipment",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1004006,
					  LWCCategoryName: "Equipment",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004006,
					  LWCCategoryName: "Equipment",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1004006,
					  LWCCategoryName: "Equipment",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1004,
				  LWCCategoryName: "Outdoor",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1004,
				  LWCCategoryName: "Outdoor",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1004,
				  LWCCategoryName: "Outdoor",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1004,
				  LWCCategoryName: "Outdoor",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1004,
				  LWCCategoryName: "Outdoor",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1004,
				  LWCCategoryName: "Outdoor",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1005",
			  CategoryName: "Furniture",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1005,
				  LWCCategoryName: "Furniture",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1005,
				  LWCCategoryName: "Furniture",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1005,
				  LWCCategoryName: "Furniture",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1005,
				  LWCCategoryName: "Furniture",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1005,
				  LWCCategoryName: "Furniture",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1005,
				  LWCCategoryName: "Furniture",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1006",
			  CategoryName: "Consumer Electronics",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1006001",
				  CategoryName: "Computers",
				  ParentId: "1006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1006001,
					  LWCCategoryName: "Computers",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006001,
					  LWCCategoryName: "Computers",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006001,
					  LWCCategoryName: "Computers",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006001,
					  LWCCategoryName: "Computers",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006001,
					  LWCCategoryName: "Computers",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006001,
					  LWCCategoryName: "Computers",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1006002",
				  CategoryName: "Gaming",
				  ParentId: "1006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1006002,
					  LWCCategoryName: "Gaming",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006002,
					  LWCCategoryName: "Gaming",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006002,
					  LWCCategoryName: "Gaming",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006002,
					  LWCCategoryName: "Gaming",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006002,
					  LWCCategoryName: "Gaming",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006002,
					  LWCCategoryName: "Gaming",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1006003",
				  CategoryName: "Electronics",
				  ParentId: "1006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1006003,
					  LWCCategoryName: "Electronics",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006003,
					  LWCCategoryName: "Electronics",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006003,
					  LWCCategoryName: "Electronics",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006003,
					  LWCCategoryName: "Electronics",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006003,
					  LWCCategoryName: "Electronics",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006003,
					  LWCCategoryName: "Electronics",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1006004",
				  CategoryName: "Whitegoods",
				  ParentId: "1006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1006004,
					  LWCCategoryName: "Whitegoods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006004,
					  LWCCategoryName: "Whitegoods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006004,
					  LWCCategoryName: "Whitegoods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006004,
					  LWCCategoryName: "Whitegoods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006004,
					  LWCCategoryName: "Whitegoods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006004,
					  LWCCategoryName: "Whitegoods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1006005",
				  CategoryName: "Music",
				  ParentId: "1006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1006005,
					  LWCCategoryName: "Music",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006005,
					  LWCCategoryName: "Music",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006005,
					  LWCCategoryName: "Music",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1006005,
					  LWCCategoryName: "Music",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006005,
					  LWCCategoryName: "Music",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1006005,
					  LWCCategoryName: "Music",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1006,
				  LWCCategoryName: "Consumer Electronics",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1006,
				  LWCCategoryName: "Consumer Electronics",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1006,
				  LWCCategoryName: "Consumer Electronics",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1006,
				  LWCCategoryName: "Consumer Electronics",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1006,
				  LWCCategoryName: "Consumer Electronics",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1006,
				  LWCCategoryName: "Consumer Electronics",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1007",
			  CategoryName: "Department Store",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1007001",
				  CategoryName: "Discount Store",
				  ParentId: "1007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1007001,
					  LWCCategoryName: "Discount Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1007001,
					  LWCCategoryName: "Discount Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1007001,
					  LWCCategoryName: "Discount Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1007001,
					  LWCCategoryName: "Discount Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1007001,
					  LWCCategoryName: "Discount Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1007001,
					  LWCCategoryName: "Discount Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1007,
				  LWCCategoryName: "Department Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1007,
				  LWCCategoryName: "Department Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1007,
				  LWCCategoryName: "Department Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1007,
				  LWCCategoryName: "Department Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1007,
				  LWCCategoryName: "Department Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1007,
				  LWCCategoryName: "Department Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1008",
			  CategoryName: "Hardware",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1008001",
				  CategoryName: "General",
				  ParentId: "1008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1008001,
					  LWCCategoryName: "General",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008001,
					  LWCCategoryName: "General",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008001,
					  LWCCategoryName: "General",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008001,
					  LWCCategoryName: "General",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008001,
					  LWCCategoryName: "General",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008001,
					  LWCCategoryName: "General",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1008002",
				  CategoryName: "Trade Only",
				  ParentId: "1008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1008002,
					  LWCCategoryName: "Trade Only",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008002,
					  LWCCategoryName: "Trade Only",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008002,
					  LWCCategoryName: "Trade Only",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008002,
					  LWCCategoryName: "Trade Only",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008002,
					  LWCCategoryName: "Trade Only",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008002,
					  LWCCategoryName: "Trade Only",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1008003",
				  CategoryName: "Equipment Rental",
				  ParentId: "1008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1008003,
					  LWCCategoryName: "Equipment Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008003,
					  LWCCategoryName: "Equipment Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008003,
					  LWCCategoryName: "Equipment Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008003,
					  LWCCategoryName: "Equipment Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008003,
					  LWCCategoryName: "Equipment Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008003,
					  LWCCategoryName: "Equipment Rental",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1008004",
				  CategoryName: "Paint",
				  ParentId: "1008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1008004,
					  LWCCategoryName: "Paint",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008004,
					  LWCCategoryName: "Paint",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008004,
					  LWCCategoryName: "Paint",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008004,
					  LWCCategoryName: "Paint",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008004,
					  LWCCategoryName: "Paint",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008004,
					  LWCCategoryName: "Paint",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1008005",
				  CategoryName: "Materials",
				  ParentId: "1008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1008005,
					  LWCCategoryName: "Materials",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008005,
					  LWCCategoryName: "Materials",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008005,
					  LWCCategoryName: "Materials",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008005,
					  LWCCategoryName: "Materials",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008005,
					  LWCCategoryName: "Materials",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008005,
					  LWCCategoryName: "Materials",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1008006",
				  CategoryName: "Electrical",
				  ParentId: "1008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1008006,
					  LWCCategoryName: "Electrical",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008006,
					  LWCCategoryName: "Electrical",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008006,
					  LWCCategoryName: "Electrical",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008006,
					  LWCCategoryName: "Electrical",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008006,
					  LWCCategoryName: "Electrical",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008006,
					  LWCCategoryName: "Electrical",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1008007",
				  CategoryName: "Doors/Windows",
				  ParentId: "1008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1008007,
					  LWCCategoryName: "Doors/Windows",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008007,
					  LWCCategoryName: "Doors/Windows",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008007,
					  LWCCategoryName: "Doors/Windows",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1008007,
					  LWCCategoryName: "Doors/Windows",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008007,
					  LWCCategoryName: "Doors/Windows",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1008007,
					  LWCCategoryName: "Doors/Windows",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1008,
				  LWCCategoryName: "Hardware",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1008,
				  LWCCategoryName: "Hardware",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1008,
				  LWCCategoryName: "Hardware",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1008,
				  LWCCategoryName: "Hardware",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1008,
				  LWCCategoryName: "Hardware",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1008,
				  LWCCategoryName: "Hardware",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1009",
			  CategoryName: "Office/Education",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1009,
				  LWCCategoryName: "Office/Education",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1009,
				  LWCCategoryName: "Office/Education",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1009,
				  LWCCategoryName: "Office/Education",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1009,
				  LWCCategoryName: "Office/Education",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1009,
				  LWCCategoryName: "Office/Education",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1009,
				  LWCCategoryName: "Office/Education",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1010",
			  CategoryName: "Jewellery",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1010,
				  LWCCategoryName: "Jewellery",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1010,
				  LWCCategoryName: "Jewellery",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1010,
				  LWCCategoryName: "Jewellery",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1010,
				  LWCCategoryName: "Jewellery",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1010,
				  LWCCategoryName: "Jewellery",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1010,
				  LWCCategoryName: "Jewellery",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1011",
			  CategoryName: "Pet Store",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1011,
				  LWCCategoryName: "Pet Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1011,
				  LWCCategoryName: "Pet Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1011,
				  LWCCategoryName: "Pet Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1011,
				  LWCCategoryName: "Pet Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1011,
				  LWCCategoryName: "Pet Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1011,
				  LWCCategoryName: "Pet Store",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1013",
			  CategoryName: "Bookshop/News",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1013001",
				  CategoryName: "Bookshop",
				  ParentId: "1013",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1013001,
					  LWCCategoryName: "Bookshop",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1013001,
					  LWCCategoryName: "Bookshop",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1013001,
					  LWCCategoryName: "Bookshop",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1013001,
					  LWCCategoryName: "Bookshop",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1013001,
					  LWCCategoryName: "Bookshop",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1013001,
					  LWCCategoryName: "Bookshop",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1013002",
				  CategoryName: "Newsagent",
				  ParentId: "1013",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1013002,
					  LWCCategoryName: "Newsagent",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1013002,
					  LWCCategoryName: "Newsagent",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1013002,
					  LWCCategoryName: "Newsagent",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1013002,
					  LWCCategoryName: "Newsagent",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1013002,
					  LWCCategoryName: "Newsagent",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1013002,
					  LWCCategoryName: "Newsagent",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1013,
				  LWCCategoryName: "Bookshop/News",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1013,
				  LWCCategoryName: "Bookshop/News",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1013,
				  LWCCategoryName: "Bookshop/News",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1013,
				  LWCCategoryName: "Bookshop/News",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1013,
				  LWCCategoryName: "Bookshop/News",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1013,
				  LWCCategoryName: "Bookshop/News",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1014",
			  CategoryName: "Second-hand",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1014001",
				  CategoryName: "Charity Store",
				  ParentId: "1014",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1014001,
					  LWCCategoryName: "Charity Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1014001,
					  LWCCategoryName: "Charity Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1014001,
					  LWCCategoryName: "Charity Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1014001,
					  LWCCategoryName: "Charity Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1014001,
					  LWCCategoryName: "Charity Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1014001,
					  LWCCategoryName: "Charity Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1014002",
				  CategoryName: "Mixed Goods",
				  ParentId: "1014",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1014002,
					  LWCCategoryName: "Mixed Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1014002,
					  LWCCategoryName: "Mixed Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1014002,
					  LWCCategoryName: "Mixed Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1014002,
					  LWCCategoryName: "Mixed Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1014002,
					  LWCCategoryName: "Mixed Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1014002,
					  LWCCategoryName: "Mixed Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1014,
				  LWCCategoryName: "Second-hand",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1014,
				  LWCCategoryName: "Second-hand",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1014,
				  LWCCategoryName: "Second-hand",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1014,
				  LWCCategoryName: "Second-hand",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1014,
				  LWCCategoryName: "Second-hand",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1014,
				  LWCCategoryName: "Second-hand",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1015",
			  CategoryName: "Software",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1015,
				  LWCCategoryName: "Software",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1015,
				  LWCCategoryName: "Software",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1015,
				  LWCCategoryName: "Software",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1015,
				  LWCCategoryName: "Software",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1015,
				  LWCCategoryName: "Software",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1015,
				  LWCCategoryName: "Software",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1016",
			  CategoryName: "Sports",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1016,
				  LWCCategoryName: "Sports",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1016,
				  LWCCategoryName: "Sports",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1016,
				  LWCCategoryName: "Sports",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1016,
				  LWCCategoryName: "Sports",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1016,
				  LWCCategoryName: "Sports",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1016,
				  LWCCategoryName: "Sports",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1999",
			  CategoryName: "Other",
			  ParentId: "1",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1999001",
				  CategoryName: "Travel Goods",
				  ParentId: "1999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1999001,
					  LWCCategoryName: "Travel Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1999001,
					  LWCCategoryName: "Travel Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1999001,
					  LWCCategoryName: "Travel Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1999001,
					  LWCCategoryName: "Travel Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1999001,
					  LWCCategoryName: "Travel Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1999001,
					  LWCCategoryName: "Travel Goods",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "1999002",
				  CategoryName: "Baby Store",
				  ParentId: "1999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [
					{
					  Id: 0,
					  CategoryStandardiserID: 23027,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Parent Category 1",
					  LWCCategoryID: 1999002,
					  LWCCategoryName: "Baby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23056,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1999002,
					  LWCCategoryName: "Baby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23058,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1999002,
					  LWCCategoryName: "Baby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23059,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Delete Integeration Test",
					  LWCCategoryID: 1999002,
					  LWCCategoryName: "Baby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23061,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1999002,
					  LWCCategoryName: "Baby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					},
					{
					  Id: 0,
					  CategoryStandardiserID: 23063,
					  CategoryDataSource: "NotificationTest",
					  CategoryDataSourceType: "Standardiser Insert Integeration Test",
					  LWCCategoryID: 1999002,
					  LWCCategoryName: "Baby Store",
					  ExternalCategoryType: "1",
					  OperationType: null,
					  UpdatedBy: 0,
					  LastUpdated: "0001-01-01T00:00:00",
					  IsActive: false
					}
				  ],
				  StandardiserStatus: 3
				}
			  ],
			  IsLWC: true,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23027,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Parent Category 1",
				  LWCCategoryID: 1999,
				  LWCCategoryName: "Other",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23056,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1999,
				  LWCCategoryName: "Other",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23058,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1999,
				  LWCCategoryName: "Other",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23059,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Delete Integeration Test",
				  LWCCategoryID: 1999,
				  LWCCategoryName: "Other",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23061,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1999,
				  LWCCategoryName: "Other",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				},
				{
				  Id: 0,
				  CategoryStandardiserID: 23063,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Standardiser Insert Integeration Test",
				  LWCCategoryID: 1999,
				  LWCCategoryName: "Other",
				  ExternalCategoryType: "1",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			}
		  ],
		  IsLWC: true,
		  Standardisers: [
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			}
		  ],
		  StandardiserStatus: 3
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "2",
		  CategoryName: "Groceries",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2001",
			  CategoryName: "Supermarket",
			  ParentId: "2",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2001002",
				  CategoryName: "Wholesale Club",
				  ParentId: "2001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2002",
			  CategoryName: "Food Market",
			  ParentId: "2",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2002001",
				  CategoryName: "Butcher",
				  ParentId: "2002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2002002",
				  CategoryName: "Bakery",
				  ParentId: "2002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2002003",
				  CategoryName: "Fish Monger",
				  ParentId: "2002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2002005",
				  CategoryName: "Fruit/Veg Market",
				  ParentId: "2002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2002006",
				  CategoryName: "Deli",
				  ParentId: "2002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2003",
			  CategoryName: "Convenience Store",
			  ParentId: "2",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2999",
			  CategoryName: "Other",
			  ParentId: "2",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2999001",
				  CategoryName: "Speciality Food",
				  ParentId: "2999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "2999002",
				  CategoryName: "Food Manufacturer",
				  ParentId: "2999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "3",
		  CategoryName: "Food/Drink",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "3001",
			  CategoryName: "Fast Food",
			  ParentId: "3",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3001001",
				  CategoryName: "Chicken",
				  ParentId: "3001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3001002",
				  CategoryName: "Beef",
				  ParentId: "3001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3001003",
				  CategoryName: "Pizza",
				  ParentId: "3001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3001004",
				  CategoryName: "Fish & Chips",
				  ParentId: "3001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "3002",
			  CategoryName: "Asian",
			  ParentId: "3",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3002001",
				  CategoryName: "Indian",
				  ParentId: "3002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3002002",
				  CategoryName: "Japanese/Sushi",
				  ParentId: "3002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3002003",
				  CategoryName: "Chinese",
				  ParentId: "3002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3002004",
				  CategoryName: "Thai",
				  ParentId: "3002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3002005",
				  CategoryName: "Malaysian",
				  ParentId: "3002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "3003",
			  CategoryName: "European",
			  ParentId: "3",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3003001",
				  CategoryName: "Italian",
				  ParentId: "3003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3003002",
				  CategoryName: "Spanish",
				  ParentId: "3003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3003003",
				  CategoryName: "French",
				  ParentId: "3003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "3004",
			  CategoryName: "Dessert",
			  ParentId: "3",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3004001",
				  CategoryName: "Ice Cream/Yoghurt",
				  ParentId: "3004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3004003",
				  CategoryName: "Pastry",
				  ParentId: "3004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "3005",
			  CategoryName: "Bar/Club",
			  ParentId: "3",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "3999",
			  CategoryName: "Other",
			  ParentId: "3",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3999002",
				  CategoryName: "Cafe",
				  ParentId: "3999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3999003",
				  CategoryName: "Caterers",
				  ParentId: "3999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3999005",
				  CategoryName: "South American",
				  ParentId: "3999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3999006",
				  CategoryName: "Seafood",
				  ParentId: "3999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3999007",
				  CategoryName: "Steakhouse",
				  ParentId: "3999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "3999008",
				  CategoryName: "Smoothie/Juice",
				  ParentId: "3999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "5",
		  CategoryName: "Services",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5001",
			  CategoryName: "Automotive",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5001001",
				  CategoryName: "Wheels/Tyres",
				  ParentId: "5001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5001002",
				  CategoryName: "Auto Sales",
				  ParentId: "5001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5001003",
				  CategoryName: "Mechanic/Bodywork",
				  ParentId: "5001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5001004",
				  CategoryName: "Auto Parts",
				  ParentId: "5001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5001005",
				  CategoryName: "Car Wash",
				  ParentId: "5001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5001006",
				  CategoryName: "Auto Electrical",
				  ParentId: "5001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5001007",
				  CategoryName: "Towing",
				  ParentId: "5001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5002",
			  CategoryName: "Manufacturing/Engineering",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5002001",
				  CategoryName: "Farm Equipment",
				  ParentId: "5002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5002002",
				  CategoryName: "Plastics",
				  ParentId: "5002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5002003",
				  CategoryName: "Air conditioning",
				  ParentId: "5002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5003",
			  CategoryName: "Telecommunication",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5003001",
				  CategoryName: "Line Providers",
				  ParentId: "5003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5004",
			  CategoryName: "Government",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5004004",
				  CategoryName: "Federal",
				  ParentId: "5004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5004005",
				  CategoryName: "State",
				  ParentId: "5004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5004006",
				  CategoryName: "Local",
				  ParentId: "5004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5005",
			  CategoryName: "Educational",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005001",
				  CategoryName: "University",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005002",
				  CategoryName: "Private School",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005003",
				  CategoryName: "TAFE",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005004",
				  CategoryName: "Library",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005005",
				  CategoryName: "Child Care",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005006",
				  CategoryName: "Tutors",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005007",
				  CategoryName: "Driving",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005008",
				  CategoryName: "Training",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005009",
				  CategoryName: "High School",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005010",
				  CategoryName: "Public/Primary",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5005011",
				  CategoryName: "Religious",
				  ParentId: "5005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5006",
			  CategoryName: "Utilities",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5006001",
				  CategoryName: "Water",
				  ParentId: "5006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5006002",
				  CategoryName: "Power",
				  ParentId: "5006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5006003",
				  CategoryName: "Gas",
				  ParentId: "5006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5006004",
				  CategoryName: "Waste Disposal",
				  ParentId: "5006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5007",
			  CategoryName: "Professional Service",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007001",
				  CategoryName: "Legal/Courts",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007002",
				  CategoryName: "Accountant/Bookkeeper",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007005",
				  CategoryName: "Graphic Design",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007007",
				  CategoryName: "Photographer",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007008",
				  CategoryName: "Celebrant",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007009",
				  CategoryName: "Credit Agency",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007010",
				  CategoryName: "Employment/HR",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007011",
				  CategoryName: "Consulting Agency",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007014",
				  CategoryName: "Advertising",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007015",
				  CategoryName: "Publishing",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007016",
				  CategoryName: "Security Service",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5007017",
				  CategoryName: "Architect",
				  ParentId: "5007",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5008",
			  CategoryName: "Trade",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008001",
				  CategoryName: "Plumbing",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008002",
				  CategoryName: "Electrician",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008003",
				  CategoryName: "Painter/Decorator",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008004",
				  CategoryName: "Interior Design",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008006",
				  CategoryName: "Exterminator",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008007",
				  CategoryName: "Repair Services",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008008",
				  CategoryName: "Builder",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008010",
				  CategoryName: "Tiler",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008011",
				  CategoryName: "Cleaning",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008012",
				  CategoryName: "Locksmith",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008013",
				  CategoryName: "Outdoor Maintenance",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008015",
				  CategoryName: "Glass",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008016",
				  CategoryName: "Kitchens/Bathrooms",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008017",
				  CategoryName: "Removalist",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008018",
				  CategoryName: "Printing/Signage",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5008019",
				  CategoryName: "Dry Cleaning/Laundry",
				  ParentId: "5008",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5009",
			  CategoryName: "Technical",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5009001",
				  CategoryName: "Security Services",
				  ParentId: "5009",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5009002",
				  CategoryName: "Cable",
				  ParentId: "5009",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5009003",
				  CategoryName: "Information/Data",
				  ParentId: "5009",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5009004",
				  CategoryName: "Network",
				  ParentId: "5009",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5010",
			  CategoryName: "Payment Gateway",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5011",
			  CategoryName: "Freight/Postage",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5012",
			  CategoryName: "Religious",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5013",
			  CategoryName: "Charity",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5014",
			  CategoryName: "Pet",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5014001",
				  CategoryName: "Pet Care",
				  ParentId: "5014",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5014002",
				  CategoryName: "Veterinary",
				  ParentId: "5014",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5015",
			  CategoryName: "Financial Services",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015001",
				  CategoryName: "Bank Fee/Charge",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015002",
				  CategoryName: "Mortgage",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015003",
				  CategoryName: "Loan",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015004",
				  CategoryName: "Credit Card",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015006",
				  CategoryName: "Currency Exchange",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015007",
				  CategoryName: "Shares",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015008",
				  CategoryName: "Bank",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015009",
				  CategoryName: "ATM",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015010",
				  CategoryName: "Superannuation",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5015011",
				  CategoryName: "Leasing",
				  ParentId: "5015",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5017",
			  CategoryName: "Insurance",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5017001",
				  CategoryName: "Health Insurance",
				  ParentId: "5017",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5017002",
				  CategoryName: "Car Insurance",
				  ParentId: "5017",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5017003",
				  CategoryName: "Home/Contents Insurance",
				  ParentId: "5017",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5017004",
				  CategoryName: "Life Insurance",
				  ParentId: "5017",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5018",
			  CategoryName: "Real Estate",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5018001",
				  CategoryName: "Property Rental",
				  ParentId: "5018",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5018002",
				  CategoryName: "Body Corporate",
				  ParentId: "5018",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5018003",
				  CategoryName: "Council Rates",
				  ParentId: "5018",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5018004",
				  CategoryName: "Aged Care",
				  ParentId: "5018",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "5999",
			  CategoryName: "Other",
			  ParentId: "5",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5999001",
				  CategoryName: "Storage",
				  ParentId: "5999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5999002",
				  CategoryName: "Association/Club",
				  ParentId: "5999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5999003",
				  CategoryName: "ATM",
				  ParentId: "5999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "5999004",
				  CategoryName: "Stocks/Supplies",
				  ParentId: "5999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "6",
		  CategoryName: "Entertainment",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "6001",
			  CategoryName: "Venue",
			  ParentId: "6",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "6001001",
				  CategoryName: "Sport",
				  ParentId: "6001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "6001002",
				  CategoryName: "Amusement Park/Zoo/Theme Park",
				  ParentId: "6001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "6001003",
				  CategoryName: "Entertainment Centre",
				  ParentId: "6001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "6002",
			  CategoryName: "Cultural",
			  ParentId: "6",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "6002001",
				  CategoryName: "Museum",
				  ParentId: "6002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "6002002",
				  CategoryName: "Art Gallery",
				  ParentId: "6002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "6003",
			  CategoryName: "Cinema",
			  ParentId: "6",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "6004",
			  CategoryName: "Ticketing Agency",
			  ParentId: "6",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "6005",
			  CategoryName: "Hire Company",
			  ParentId: "6",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "6005001",
				  CategoryName: "Special Occasion",
				  ParentId: "6005",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "6006",
			  CategoryName: "Festivals/Events",
			  ParentId: "6",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "6999",
			  CategoryName: "Other",
			  ParentId: "6",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "6999002",
				  CategoryName: "Digital Media",
				  ParentId: "6999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "7",
		  CategoryName: "Travel",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "7001",
			  CategoryName: "Transport",
			  ParentId: "7",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7001001",
				  CategoryName: "Public Transport",
				  ParentId: "7001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7001002",
				  CategoryName: "Private Bus",
				  ParentId: "7001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7001003",
				  CategoryName: "Airline",
				  ParentId: "7001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7001004",
				  CategoryName: "Taxi",
				  ParentId: "7001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "7002",
			  CategoryName: "Travel Agent",
			  ParentId: "7",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7002001",
				  CategoryName: "Tour Operator",
				  ParentId: "7002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "7003",
			  CategoryName: "Accommodation",
			  ParentId: "7",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7003001",
				  CategoryName: "Hotel/Motel",
				  ParentId: "7003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7003002",
				  CategoryName: "Holiday Park",
				  ParentId: "7003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7003003",
				  CategoryName: "Home Stay",
				  ParentId: "7003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7003004",
				  CategoryName: "Backpackers",
				  ParentId: "7003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "7004",
			  CategoryName: "Car Park",
			  ParentId: "7",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7004001",
				  CategoryName: "Council",
				  ParentId: "7004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "7004002",
				  CategoryName: "Private",
				  ParentId: "7004",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "7005",
			  CategoryName: "Petrol Station",
			  ParentId: "7",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "7006",
			  CategoryName: "Car Hire",
			  ParentId: "7",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "8",
		  CategoryName: "Health & Fitness",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "8001",
			  CategoryName: "Medical",
			  ParentId: "8",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001001",
				  CategoryName: "Pharmacy",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001002",
				  CategoryName: "Medical Practice/Hospital",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001003",
				  CategoryName: "Specialist Doctor",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001004",
				  CategoryName: "Optometrist",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001005",
				  CategoryName: "Dentist",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001006",
				  CategoryName: "Alternative Therapy",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001007",
				  CategoryName: "Hearing/Speech",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001008",
				  CategoryName: "Skin/Cosmetic Surgery/Laser",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001009",
				  CategoryName: "Counselling",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8001010",
				  CategoryName: "Radiology/Imaging",
				  ParentId: "8001",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "8002",
			  CategoryName: "Beauty",
			  ParentId: "8",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8002001",
				  CategoryName: "Hair",
				  ParentId: "8002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8002002",
				  CategoryName: "Nails",
				  ParentId: "8002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8002003",
				  CategoryName: "Massage",
				  ParentId: "8002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8002004",
				  CategoryName: "Makeup",
				  ParentId: "8002",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "8003",
			  CategoryName: "Club",
			  ParentId: "8",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003003",
				  CategoryName: "Martial Arts",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003004",
				  CategoryName: "Swimming Pool",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003005",
				  CategoryName: "Gym",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003007",
				  CategoryName: "Yoga/Pilates",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003008",
				  CategoryName: "Racquet",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003009",
				  CategoryName: "Football",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003010",
				  CategoryName: "Golf",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "8003011",
				  CategoryName: "Water Sports",
				  ParentId: "8003",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "9",
		  CategoryName: "Restricted",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "9001",
			  CategoryName: "Gambling",
			  ParentId: "9",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "9002",
			  CategoryName: "Liquor Store",
			  ParentId: "9",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "9005",
			  CategoryName: "Tobacconist",
			  ParentId: "9",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "9006",
			  CategoryName: "Sensitive",
			  ParentId: "9",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "9006001",
				  CategoryName: "Adult Store",
				  ParentId: "9006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "9006002",
				  CategoryName: "Gentlemen's Club",
				  ParentId: "9006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "9006003",
				  CategoryName: "Brothel",
				  ParentId: "9006",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "9999",
			  CategoryName: "Other",
			  ParentId: "9",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "9999001",
				  CategoryName: "Dating Agency",
				  ParentId: "9999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				},
				{
				  Id: 0,
				  CategoryDataSource: null,
				  CategoryId: "9999002",
				  CategoryName: "Funeral Service",
				  ParentId: "9999",
				  FullCategoryPath: null,
				  Parent: null,
				  Children: [],
				  IsLWC: true,
				  Standardisers: [],
				  StandardiserStatus: 1
				}
			  ],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "11",
		  CategoryName: "Employer Payment",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1101",
			  CategoryName: "Salary",
			  ParentId: "11",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1102",
			  CategoryName: "Annual Bonus",
			  ParentId: "11",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1103",
			  CategoryName: "Other Bonus/Commission",
			  ParentId: "11",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1104",
			  CategoryName: "Expense Reimbursement",
			  ParentId: "11",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "13",
		  CategoryName: "Interest",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "14",
		  CategoryName: "Shares",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "1402",
			  CategoryName: "Dividend",
			  ParentId: "14",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "15",
		  CategoryName: "Social Security",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "21",
		  CategoryName: "Tax",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "22",
		  CategoryName: "Child Support",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "23",
		  CategoryName: "Alimony",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "24",
		  CategoryName: "Transfer",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2401",
			  CategoryName: "Between Accounts",
			  ParentId: "24",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2402",
			  CategoryName: "Other Personal",
			  ParentId: "24",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2403",
			  CategoryName: "From a Business",
			  ParentId: "24",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "25",
		  CategoryName: "Other Income",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "27",
		  CategoryName: "Investment",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2702",
			  CategoryName: "Property",
			  ParentId: "27",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "28",
		  CategoryName: "Transfer",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2801",
			  CategoryName: "Between Accounts",
			  ParentId: "28",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2802",
			  CategoryName: "Other Personal",
			  ParentId: "28",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			},
			{
			  Id: 0,
			  CategoryDataSource: null,
			  CategoryId: "2803",
			  CategoryName: "To a Business",
			  ParentId: "28",
			  FullCategoryPath: null,
			  Parent: null,
			  Children: [],
			  IsLWC: true,
			  Standardisers: [],
			  StandardiserStatus: 1
			}
		  ],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "34",
		  CategoryName: "Cash",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		},
		{
		  Id: 0,
		  CategoryDataSource: null,
		  CategoryId: "35",
		  CategoryName: "Other Expense",
		  ParentId: null,
		  FullCategoryPath: null,
		  Parent: null,
		  Children: [],
		  IsLWC: true,
		  Standardisers: [],
		  StandardiserStatus: 1
		}
	  ],
	  ExternalCategories: [
		{
		  Id: 10490,
		  CategoryDataSource: "NotificationTest",
		  CategoryId: "1",
		  CategoryName: "Parent Category 1",
		  ParentId: null,
		  FullCategoryPath: "Parent Category 1",
		  Parent: null,
		  Children: [
			{
			  Id: 10491,
			  CategoryDataSource: "NotificationTest",
			  CategoryId: "1001",
			  CategoryName: "Sub category 1",
			  ParentId: "1",
			  FullCategoryPath: "Parent Category 1 >> Sub Category 1",
			  Parent: null,
			  Children: [],
			  IsLWC: false,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23023,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Sub category 1",
				  LWCCategoryID: 1001001,
				  LWCCategoryName: "Accessories",
				  ExternalCategoryType: "1001",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 10492,
			  CategoryDataSource: "NotificationTest",
			  CategoryId: "1002",
			  CategoryName: "Sub category 2",
			  ParentId: "1",
			  FullCategoryPath: "Parent Category 1 >> Sub Category 2",
			  Parent: null,
			  Children: [],
			  IsLWC: false,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23025,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Sub category 2",
				  LWCCategoryID: 1001001,
				  LWCCategoryName: "Accessories",
				  ExternalCategoryType: "1002",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			},
			{
			  Id: 10498,
			  CategoryDataSource: "NotificationTest",
			  CategoryId: "1003",
			  CategoryName: "Sub category 3",
			  ParentId: "1",
			  FullCategoryPath: "Parent Category 1 >> Sub category 3",
			  Parent: null,
			  Children: [],
			  IsLWC: false,
			  Standardisers: [
				{
				  Id: 0,
				  CategoryStandardiserID: 23026,
				  CategoryDataSource: "NotificationTest",
				  CategoryDataSourceType: "Sub category 3",
				  LWCCategoryID: 1001009,
				  LWCCategoryName: "Costume",
				  ExternalCategoryType: "1003",
				  OperationType: null,
				  UpdatedBy: 0,
				  LastUpdated: "0001-01-01T00:00:00",
				  IsActive: false
				}
			  ],
			  StandardiserStatus: 3
			}
		  ],
		  IsLWC: false,
		  Standardisers: [
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1,
			  LWCCategoryName: "Shopping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1010,
			  LWCCategoryName: "Jewellery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1010,
			  LWCCategoryName: "Jewellery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1010,
			  LWCCategoryName: "Jewellery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1010,
			  LWCCategoryName: "Jewellery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1010,
			  LWCCategoryName: "Jewellery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1010,
			  LWCCategoryName: "Jewellery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1005,
			  LWCCategoryName: "Furniture",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1005,
			  LWCCategoryName: "Furniture",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1005,
			  LWCCategoryName: "Furniture",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1005,
			  LWCCategoryName: "Furniture",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1005,
			  LWCCategoryName: "Furniture",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1005,
			  LWCCategoryName: "Furniture",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1999,
			  LWCCategoryName: "Other",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1999,
			  LWCCategoryName: "Other",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999,
			  LWCCategoryName: "Other",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1999,
			  LWCCategoryName: "Other",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999,
			  LWCCategoryName: "Other",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999,
			  LWCCategoryName: "Other",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1014,
			  LWCCategoryName: "Second-hand",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1014,
			  LWCCategoryName: "Second-hand",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014,
			  LWCCategoryName: "Second-hand",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1014,
			  LWCCategoryName: "Second-hand",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014,
			  LWCCategoryName: "Second-hand",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014,
			  LWCCategoryName: "Second-hand",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1009,
			  LWCCategoryName: "Office/Education",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1009,
			  LWCCategoryName: "Office/Education",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1009,
			  LWCCategoryName: "Office/Education",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1009,
			  LWCCategoryName: "Office/Education",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1009,
			  LWCCategoryName: "Office/Education",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1009,
			  LWCCategoryName: "Office/Education",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1007,
			  LWCCategoryName: "Department Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1007,
			  LWCCategoryName: "Department Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1007,
			  LWCCategoryName: "Department Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1007,
			  LWCCategoryName: "Department Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1007,
			  LWCCategoryName: "Department Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1007,
			  LWCCategoryName: "Department Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1002,
			  LWCCategoryName: "Toys/Hobby",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002,
			  LWCCategoryName: "Toys/Hobby",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002,
			  LWCCategoryName: "Toys/Hobby",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002,
			  LWCCategoryName: "Toys/Hobby",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002,
			  LWCCategoryName: "Toys/Hobby",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002,
			  LWCCategoryName: "Toys/Hobby",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1016,
			  LWCCategoryName: "Sports",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1016,
			  LWCCategoryName: "Sports",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1016,
			  LWCCategoryName: "Sports",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1016,
			  LWCCategoryName: "Sports",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1016,
			  LWCCategoryName: "Sports",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1016,
			  LWCCategoryName: "Sports",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1011,
			  LWCCategoryName: "Pet Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1011,
			  LWCCategoryName: "Pet Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1011,
			  LWCCategoryName: "Pet Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1011,
			  LWCCategoryName: "Pet Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1011,
			  LWCCategoryName: "Pet Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1011,
			  LWCCategoryName: "Pet Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1006,
			  LWCCategoryName: "Consumer Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006,
			  LWCCategoryName: "Consumer Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006,
			  LWCCategoryName: "Consumer Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006,
			  LWCCategoryName: "Consumer Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006,
			  LWCCategoryName: "Consumer Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006,
			  LWCCategoryName: "Consumer Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1004,
			  LWCCategoryName: "Outdoor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004,
			  LWCCategoryName: "Outdoor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004,
			  LWCCategoryName: "Outdoor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004,
			  LWCCategoryName: "Outdoor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004,
			  LWCCategoryName: "Outdoor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004,
			  LWCCategoryName: "Outdoor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1013,
			  LWCCategoryName: "Bookshop/News",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1013,
			  LWCCategoryName: "Bookshop/News",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013,
			  LWCCategoryName: "Bookshop/News",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1013,
			  LWCCategoryName: "Bookshop/News",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013,
			  LWCCategoryName: "Bookshop/News",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013,
			  LWCCategoryName: "Bookshop/News",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008,
			  LWCCategoryName: "Hardware",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008,
			  LWCCategoryName: "Hardware",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008,
			  LWCCategoryName: "Hardware",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008,
			  LWCCategoryName: "Hardware",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008,
			  LWCCategoryName: "Hardware",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008,
			  LWCCategoryName: "Hardware",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1015,
			  LWCCategoryName: "Software",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1015,
			  LWCCategoryName: "Software",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1015,
			  LWCCategoryName: "Software",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1015,
			  LWCCategoryName: "Software",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1015,
			  LWCCategoryName: "Software",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1015,
			  LWCCategoryName: "Software",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1003,
			  LWCCategoryName: "Home",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003,
			  LWCCategoryName: "Home",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003,
			  LWCCategoryName: "Home",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003,
			  LWCCategoryName: "Home",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003,
			  LWCCategoryName: "Home",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003,
			  LWCCategoryName: "Home",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001,
			  LWCCategoryName: "Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001,
			  LWCCategoryName: "Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001,
			  LWCCategoryName: "Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001,
			  LWCCategoryName: "Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001,
			  LWCCategoryName: "Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001,
			  LWCCategoryName: "Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001002,
			  LWCCategoryName: "Sleepwear/Underwear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001004,
			  LWCCategoryName: "Surf",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001005,
			  LWCCategoryName: "Sport",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001006,
			  LWCCategoryName: "Shoes",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001007,
			  LWCCategoryName: "Tailor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001015,
			  LWCCategoryName: "Womens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001016,
			  LWCCategoryName: "Mens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001008,
			  LWCCategoryName: "Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001011,
			  LWCCategoryName: "Uniform",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001012,
			  LWCCategoryName: "Bridal/Formal",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001013,
			  LWCCategoryName: "Maternity",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1001014,
			  LWCCategoryName: "Childrenswear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001002,
			  LWCCategoryName: "Sleepwear/Underwear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001004,
			  LWCCategoryName: "Surf",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001005,
			  LWCCategoryName: "Sport",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001006,
			  LWCCategoryName: "Shoes",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001007,
			  LWCCategoryName: "Tailor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001015,
			  LWCCategoryName: "Womens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001016,
			  LWCCategoryName: "Mens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001008,
			  LWCCategoryName: "Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001011,
			  LWCCategoryName: "Uniform",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001012,
			  LWCCategoryName: "Bridal/Formal",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001013,
			  LWCCategoryName: "Maternity",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001014,
			  LWCCategoryName: "Childrenswear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001002,
			  LWCCategoryName: "Sleepwear/Underwear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001004,
			  LWCCategoryName: "Surf",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001005,
			  LWCCategoryName: "Sport",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001006,
			  LWCCategoryName: "Shoes",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001007,
			  LWCCategoryName: "Tailor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001015,
			  LWCCategoryName: "Womens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001016,
			  LWCCategoryName: "Mens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001008,
			  LWCCategoryName: "Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001011,
			  LWCCategoryName: "Uniform",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001012,
			  LWCCategoryName: "Bridal/Formal",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001013,
			  LWCCategoryName: "Maternity",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001014,
			  LWCCategoryName: "Childrenswear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001002,
			  LWCCategoryName: "Sleepwear/Underwear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001004,
			  LWCCategoryName: "Surf",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001005,
			  LWCCategoryName: "Sport",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001006,
			  LWCCategoryName: "Shoes",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001007,
			  LWCCategoryName: "Tailor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001015,
			  LWCCategoryName: "Womens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001016,
			  LWCCategoryName: "Mens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001008,
			  LWCCategoryName: "Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001011,
			  LWCCategoryName: "Uniform",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001012,
			  LWCCategoryName: "Bridal/Formal",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001013,
			  LWCCategoryName: "Maternity",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1001014,
			  LWCCategoryName: "Childrenswear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001002,
			  LWCCategoryName: "Sleepwear/Underwear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001004,
			  LWCCategoryName: "Surf",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001005,
			  LWCCategoryName: "Sport",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001006,
			  LWCCategoryName: "Shoes",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001007,
			  LWCCategoryName: "Tailor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001015,
			  LWCCategoryName: "Womens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001016,
			  LWCCategoryName: "Mens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001008,
			  LWCCategoryName: "Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001011,
			  LWCCategoryName: "Uniform",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001012,
			  LWCCategoryName: "Bridal/Formal",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001013,
			  LWCCategoryName: "Maternity",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001014,
			  LWCCategoryName: "Childrenswear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001002,
			  LWCCategoryName: "Sleepwear/Underwear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001004,
			  LWCCategoryName: "Surf",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001005,
			  LWCCategoryName: "Sport",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001006,
			  LWCCategoryName: "Shoes",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001007,
			  LWCCategoryName: "Tailor",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001015,
			  LWCCategoryName: "Womens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001016,
			  LWCCategoryName: "Mens Clothing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001008,
			  LWCCategoryName: "Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001011,
			  LWCCategoryName: "Uniform",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001012,
			  LWCCategoryName: "Bridal/Formal",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001013,
			  LWCCategoryName: "Maternity",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1001014,
			  LWCCategoryName: "Childrenswear",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1002001,
			  LWCCategoryName: "Hobby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1002002,
			  LWCCategoryName: "Toy Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1002004,
			  LWCCategoryName: "Art/Craft",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002001,
			  LWCCategoryName: "Hobby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002002,
			  LWCCategoryName: "Toy Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002004,
			  LWCCategoryName: "Art/Craft",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002001,
			  LWCCategoryName: "Hobby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002002,
			  LWCCategoryName: "Toy Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002004,
			  LWCCategoryName: "Art/Craft",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002001,
			  LWCCategoryName: "Hobby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002002,
			  LWCCategoryName: "Toy Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1002004,
			  LWCCategoryName: "Art/Craft",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002001,
			  LWCCategoryName: "Hobby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002002,
			  LWCCategoryName: "Toy Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002004,
			  LWCCategoryName: "Art/Craft",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002001,
			  LWCCategoryName: "Hobby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002002,
			  LWCCategoryName: "Toy Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1002004,
			  LWCCategoryName: "Art/Craft",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1003001,
			  LWCCategoryName: "Gift",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1003002,
			  LWCCategoryName: "Home Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1003003,
			  LWCCategoryName: "Haberdashery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003001,
			  LWCCategoryName: "Gift",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003002,
			  LWCCategoryName: "Home Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003003,
			  LWCCategoryName: "Haberdashery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003001,
			  LWCCategoryName: "Gift",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003002,
			  LWCCategoryName: "Home Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003003,
			  LWCCategoryName: "Haberdashery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003001,
			  LWCCategoryName: "Gift",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003002,
			  LWCCategoryName: "Home Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1003003,
			  LWCCategoryName: "Haberdashery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003001,
			  LWCCategoryName: "Gift",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003002,
			  LWCCategoryName: "Home Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003003,
			  LWCCategoryName: "Haberdashery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003001,
			  LWCCategoryName: "Gift",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003002,
			  LWCCategoryName: "Home Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1003003,
			  LWCCategoryName: "Haberdashery",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1004001,
			  LWCCategoryName: "Fishing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1004002,
			  LWCCategoryName: "Camping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1004003,
			  LWCCategoryName: "Garden",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1004004,
			  LWCCategoryName: "Florist",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1004006,
			  LWCCategoryName: "Equipment",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004001,
			  LWCCategoryName: "Fishing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004002,
			  LWCCategoryName: "Camping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004003,
			  LWCCategoryName: "Garden",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004004,
			  LWCCategoryName: "Florist",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004006,
			  LWCCategoryName: "Equipment",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004001,
			  LWCCategoryName: "Fishing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004002,
			  LWCCategoryName: "Camping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004003,
			  LWCCategoryName: "Garden",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004004,
			  LWCCategoryName: "Florist",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004006,
			  LWCCategoryName: "Equipment",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004001,
			  LWCCategoryName: "Fishing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004002,
			  LWCCategoryName: "Camping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004003,
			  LWCCategoryName: "Garden",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004004,
			  LWCCategoryName: "Florist",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1004006,
			  LWCCategoryName: "Equipment",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004001,
			  LWCCategoryName: "Fishing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004002,
			  LWCCategoryName: "Camping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004003,
			  LWCCategoryName: "Garden",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004004,
			  LWCCategoryName: "Florist",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004006,
			  LWCCategoryName: "Equipment",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004001,
			  LWCCategoryName: "Fishing",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004002,
			  LWCCategoryName: "Camping",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004003,
			  LWCCategoryName: "Garden",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004004,
			  LWCCategoryName: "Florist",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1004006,
			  LWCCategoryName: "Equipment",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1006001,
			  LWCCategoryName: "Computers",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1006002,
			  LWCCategoryName: "Gaming",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1006003,
			  LWCCategoryName: "Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1006004,
			  LWCCategoryName: "Whitegoods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1006005,
			  LWCCategoryName: "Music",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006001,
			  LWCCategoryName: "Computers",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006002,
			  LWCCategoryName: "Gaming",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006003,
			  LWCCategoryName: "Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006004,
			  LWCCategoryName: "Whitegoods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006005,
			  LWCCategoryName: "Music",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006001,
			  LWCCategoryName: "Computers",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006002,
			  LWCCategoryName: "Gaming",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006003,
			  LWCCategoryName: "Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006004,
			  LWCCategoryName: "Whitegoods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006005,
			  LWCCategoryName: "Music",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006001,
			  LWCCategoryName: "Computers",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006002,
			  LWCCategoryName: "Gaming",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006003,
			  LWCCategoryName: "Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006004,
			  LWCCategoryName: "Whitegoods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1006005,
			  LWCCategoryName: "Music",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006001,
			  LWCCategoryName: "Computers",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006002,
			  LWCCategoryName: "Gaming",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006003,
			  LWCCategoryName: "Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006004,
			  LWCCategoryName: "Whitegoods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006005,
			  LWCCategoryName: "Music",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006001,
			  LWCCategoryName: "Computers",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006002,
			  LWCCategoryName: "Gaming",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006003,
			  LWCCategoryName: "Electronics",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006004,
			  LWCCategoryName: "Whitegoods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1006005,
			  LWCCategoryName: "Music",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1007001,
			  LWCCategoryName: "Discount Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1007001,
			  LWCCategoryName: "Discount Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1007001,
			  LWCCategoryName: "Discount Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1007001,
			  LWCCategoryName: "Discount Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1007001,
			  LWCCategoryName: "Discount Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1007001,
			  LWCCategoryName: "Discount Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008001,
			  LWCCategoryName: "General",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008002,
			  LWCCategoryName: "Trade Only",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008003,
			  LWCCategoryName: "Equipment Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008004,
			  LWCCategoryName: "Paint",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008005,
			  LWCCategoryName: "Materials",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008006,
			  LWCCategoryName: "Electrical",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1008007,
			  LWCCategoryName: "Doors/Windows",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008001,
			  LWCCategoryName: "General",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008002,
			  LWCCategoryName: "Trade Only",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008003,
			  LWCCategoryName: "Equipment Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008004,
			  LWCCategoryName: "Paint",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008005,
			  LWCCategoryName: "Materials",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008006,
			  LWCCategoryName: "Electrical",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008007,
			  LWCCategoryName: "Doors/Windows",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008001,
			  LWCCategoryName: "General",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008002,
			  LWCCategoryName: "Trade Only",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008003,
			  LWCCategoryName: "Equipment Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008004,
			  LWCCategoryName: "Paint",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008005,
			  LWCCategoryName: "Materials",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008006,
			  LWCCategoryName: "Electrical",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008007,
			  LWCCategoryName: "Doors/Windows",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008001,
			  LWCCategoryName: "General",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008002,
			  LWCCategoryName: "Trade Only",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008003,
			  LWCCategoryName: "Equipment Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008004,
			  LWCCategoryName: "Paint",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008005,
			  LWCCategoryName: "Materials",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008006,
			  LWCCategoryName: "Electrical",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1008007,
			  LWCCategoryName: "Doors/Windows",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008001,
			  LWCCategoryName: "General",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008002,
			  LWCCategoryName: "Trade Only",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008003,
			  LWCCategoryName: "Equipment Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008004,
			  LWCCategoryName: "Paint",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008005,
			  LWCCategoryName: "Materials",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008006,
			  LWCCategoryName: "Electrical",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008007,
			  LWCCategoryName: "Doors/Windows",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008001,
			  LWCCategoryName: "General",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008002,
			  LWCCategoryName: "Trade Only",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008003,
			  LWCCategoryName: "Equipment Rental",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008004,
			  LWCCategoryName: "Paint",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008005,
			  LWCCategoryName: "Materials",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008006,
			  LWCCategoryName: "Electrical",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1008007,
			  LWCCategoryName: "Doors/Windows",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1013001,
			  LWCCategoryName: "Bookshop",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1013002,
			  LWCCategoryName: "Newsagent",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1013001,
			  LWCCategoryName: "Bookshop",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1013002,
			  LWCCategoryName: "Newsagent",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013001,
			  LWCCategoryName: "Bookshop",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013002,
			  LWCCategoryName: "Newsagent",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1013001,
			  LWCCategoryName: "Bookshop",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1013002,
			  LWCCategoryName: "Newsagent",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013001,
			  LWCCategoryName: "Bookshop",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013002,
			  LWCCategoryName: "Newsagent",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013001,
			  LWCCategoryName: "Bookshop",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1013002,
			  LWCCategoryName: "Newsagent",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1014001,
			  LWCCategoryName: "Charity Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1014002,
			  LWCCategoryName: "Mixed Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1014001,
			  LWCCategoryName: "Charity Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1014002,
			  LWCCategoryName: "Mixed Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014001,
			  LWCCategoryName: "Charity Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014002,
			  LWCCategoryName: "Mixed Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1014001,
			  LWCCategoryName: "Charity Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1014002,
			  LWCCategoryName: "Mixed Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014001,
			  LWCCategoryName: "Charity Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014002,
			  LWCCategoryName: "Mixed Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014001,
			  LWCCategoryName: "Charity Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1014002,
			  LWCCategoryName: "Mixed Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1999001,
			  LWCCategoryName: "Travel Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23027,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Parent Category 1",
			  LWCCategoryID: 1999002,
			  LWCCategoryName: "Baby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1999001,
			  LWCCategoryName: "Travel Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23056,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1999002,
			  LWCCategoryName: "Baby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999001,
			  LWCCategoryName: "Travel Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23058,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999002,
			  LWCCategoryName: "Baby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1999001,
			  LWCCategoryName: "Travel Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23059,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Delete Integeration Test",
			  LWCCategoryID: 1999002,
			  LWCCategoryName: "Baby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999001,
			  LWCCategoryName: "Travel Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23061,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999002,
			  LWCCategoryName: "Baby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999001,
			  LWCCategoryName: "Travel Goods",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			},
			{
			  Id: 0,
			  CategoryStandardiserID: 23063,
			  CategoryDataSource: "NotificationTest",
			  CategoryDataSourceType: "Standardiser Insert Integeration Test",
			  LWCCategoryID: 1999002,
			  LWCCategoryName: "Baby Store",
			  ExternalCategoryType: "1",
			  OperationType: null,
			  UpdatedBy: 0,
			  LastUpdated: "0001-01-01T00:00:00",
			  IsActive: false
			}
		  ],
		  StandardiserStatus: 3
		}
	  ]
}

export let updateToDraftResponse: string = "The update of category standardiser will be sent to Admin to review and complete";

export let deleteToDraftResponse: string = "The delete of existing category standardiser for LWC Category 1003 will be sent to Admin to review and complete";
