import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { merchantOpeningHoursResponse } from "../mockjsonresponses/merchantOpeningHoursResponse.response";

export function merchantOpeningHours(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    let cleanUrl = url.split('?')[0];

    if (cleanUrl.includes(apiPathConstants.openingHours) && method === 'GET') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: { ...merchantOpeningHoursResponse }
            }));
            resp.complete();
        })
    }
    if (cleanUrl.includes(apiPathConstants.openingHours) && method === 'POST') {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200
            }));
            resp.complete();
        })
    }
}