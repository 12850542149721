import { ExecutionResult } from 'graphql';
import { UnmatchedMerchantsResponse } from '../../components/chainstores/chainstores.component';

export let unmatchedMerchantsResponse: ExecutionResult<UnmatchedMerchantsResponse> = {
  data: {
    unmatchedMerchants: [
      {
        lwcId: 1000603540,
        status: 'Initiated',
        closedConfidenceLevel: 'Monitor',
        message: 'Chain Stores message',
      },
      {
        lwcId: 1000605195,
        status: 'Closed',
        closedConfidenceLevel: 'Monitor1',
        message: 'Chain Stores message1',
      },
      {
        lwcId: 1000605194,
        status: 'Closed',
        closedConfidenceLevel: 'Monitor2',
        message: 'Chain Stores message2',
      },
      {
        lwcId: 1000606452,
        status: 'Initiated3',
        closedConfidenceLevel: 'Monitor3',
        message: 'Chain Stores message3',
      },
      {
        lwcId: 1000605193,
        status: 'Open',
        closedConfidenceLevel: 'Monitor4',
        message: 'Chain Stores message4',
      },
      {
        lwcId: 1001105467,
        status: 'Initiated',
        closedConfidenceLevel: 'Monitor5',
        message: 'Chain Stores message5',
      }
    ]
  }
}
