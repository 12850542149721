import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { MasterCardLookupService } from '../../services/mastercardlookup.service';
import { Component, OnInit, Injector } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { MasterCardLookupState } from './mastercardlookupstate';

@Component({
  selector: 'app-mastercardlookup',
  templateUrl: './mastercardlookup.component.html',
  styleUrls: ['./mastercardlookup.component.scss']
})
export class MasterCardLookupComponent extends BaseStateComponent<MasterCardLookupState> implements OnInit {

  constructor(private masterCardLookupService: MasterCardLookupService, private notificationService: NotificationService,
    private loadingService: LoadingService, injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.setCleanState();

    this.restoreState();
  }

  search() {
    this.loadingService.setLoading();

    this.masterCardLookupService.getMastercard(this.state.cal, this.state.noToReturn, this.state.forceApiSearch, this.state.typeOfSearch)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(m => {
        this.state.mastercardResults = m;
        this.notificationService.notifySuccess();
      }, this.onSearchError);
  }

  onSearchError = () => {
    this.state.mastercardResults = null;
  }

  isSearchButtonDisabled(): boolean {
    return this.state.cal.length < 5 || this.state.noToReturn <= 0;
  }

  setCleanState() {
    this.state = {
      cal: '',
      noToReturn: 10,
      typeOfSearch: 0,
      forceApiSearch: false,
      mastercardResults: null
    };
  }
}
