import { ValueCheckerService } from './../../core/services/valuechecker.service';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { Chain } from '../../models/merchantreference';
import { ChainType } from '../../components/indexmerchant/chaintype';
import { PickChainService } from '../../services/pickchain.service';
import { DataSource, SearchSelectBase, OptionEntry } from '@oasisdigital/angular-material-search-select';
import { SearchSelectService } from '../../services/searchselect.service';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { emptyOptionEntry } from '../../core/constants/constants';
import { chainTypeStringValues } from '../../core/constants/chains';

@Component({
  selector: 'app-chain-select',
  templateUrl: './chainselect.component.html',
  styleUrls: ['./chainselect.component.scss']
})
export class ChainSelectComponent extends BaseComponent implements OnInit {
  @Input() width: string;
  @Input() value: OptionEntry;
  @Input() includesNotAChain = false;
  @Input() tooltip: string = null;

  get chainTypesToInclude() {
    return this.chainTypesToIncludeInternal;
  }

  @Input()
  set chainTypesToInclude(chainTypesToInclude: Array<string | 0>) {
    this.chainTypesToIncludeInternal = chainTypesToInclude ?? [];
    if (this.chains) {
      this.applyChainsToInclude(this.chains);
    }
  }

  chainCtrl: FormControl = new FormControl();
  chainsDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  @ViewChild('searchSelect', {static: true}) private searchSelect: SearchSelectBase;
  @Output() valueChange = new EventEmitter<string>();

  private chainTypesToIncludeInternal: Array<string | 0> = [];
  private chains: Chain[];

  constructor(injector: Injector, private merchantlookupService: MerchantlookupService, private pickChainService: PickChainService,
    private searchSelectService: SearchSelectService, private valueCheckerService: ValueCheckerService) {
    super(injector);
  }

  ngOnInit() {
    this.getChains();

    if (this.value) {
      this.setValue(this.value);
    }

    this.onValueChanges();
  }

  getValue(): OptionEntry {
    if (!this.searchSelect.searchControl.value) {
      return emptyOptionEntry;
    }
    return this.searchSelect.searchControl.value;
  }

  setValue(value: {}) {
    this.searchSelect.searchControl.setValue(value);
  }

  setDataSource(referenceData: Chain[]) {
    this.chainsDataSource = this.pickChainService.getChainsDataSource(referenceData);
  }

  private getChains() {
    this.merchantlookupService.getChains()
      .subscribe(r => {
        this.chains = this.onGetChains(r);
        this.applyChainsToInclude(this.chains);
      });
  }

  private onGetChains(referenceData: Chain[]) {
    if (this.includesNotAChain) {
      let notAChain: Chain = {
        ChainName: 'NotAChain',
        ChainType: chainTypeStringValues.get(ChainType.NotAChain)
      };

      referenceData.unshift(notAChain);
    }

    return referenceData;
  }

  private applyChainsToInclude(chains: Chain[]) {
    if (this.chainTypesToInclude.length > 0) {
      chains = chains.filter((t) => this.chainTypesToInclude.includes(t.ChainType));
    }

    this.setDataSource(chains);
  }

  private onValueChanges() {
    this.searchSelect.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!this.valueCheckerService.isNullOrUndefined(value) && typeof value === 'object') {
        let searchSelectValue: OptionEntry = value;
        this.valueChange.emit(searchSelectValue.value);
      } else {
        this.valueChange.emit(value);
      }
    });
  }
}
