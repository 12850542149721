import { ValueCheckerService } from './../../core/services/valuechecker.service';
import { BaseStateComponent } from '../basestate/basestate.component';
import { QuarantinedCalsState } from './quarantinedcalsstate';
import { QuarantinedService } from '../../services/quarantined.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { OnInit, Injector, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../../users/applicationuser.service';
import { ApplicationUser } from '../../users/applicationuser';
import { SearchSelectService } from '../../services/searchselect.service';
import { DataSource, SearchSelectBase } from '@oasisdigital/angular-material-search-select';
import { FormControl } from '@angular/forms';
import { NameValue } from '../../core/models/namevalue';
import { AllocateCal, AusQuarantinedCalDisplay } from './ausquarantinedcals';
import { BatchRequestService, BatchRequestStatus, BatchRequestConfig } from '../../services/batchsearchrequest.service';
import { ConcurrentRequest } from '../batchmerchantsearch/merchantbatchsearchrequest';
import { EnrichMerchantRequest } from '../../models/enrichmerchantrequest';
import { AUS_Indexing_Result } from '../../models/ausindexingresult';
import { EnrichMerchantService } from '../../services/enrichmerchant.service';
import { BatchSearchResult } from '../batchmerchantsearch/batchsearchresult';
import { OverallResult } from '../../enums/enums';
import { UserStatusType } from '../../users/userenums';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NotificationService } from '../../core/uiservices/notification.service';

@Component({
  selector: 'app-quarantinedcals',
  templateUrl: './quarantinedcals.component.html',
  styleUrls: ['./quarantinedcals.component.scss']
})
export class QuarantinedCalsComponent extends BaseStateComponent<QuarantinedCalsState> implements OnInit {
  namesDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  allocateDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  indexerNames: NameValue<number>[] = [];
  namesCtrl = new FormControl();
  allocateCtrl = new FormControl();
  enableIndexerFilter: boolean = false;
  selected: any = [];
  allocateToSelected: boolean = true;
  filterIndexerId?: number = null;
  allocatedToIndexerId: number = null;
  private applicationUsers: ApplicationUser[];
  enrichMerchantRequests: Partial<EnrichMerchantRequest>[] = [];
  failedRequests: ConcurrentRequest<EnrichMerchantRequest>[] = [];
  searchWasPerformed = false;
  enrichResult: OverallResult = null;

  searchCountResults: any;

  enrichBatchRequestStatus: BatchRequestStatus<AUS_Indexing_Result> = {
    areMerchantsLoading: false,
    searchInProgressCount: 0,
    searchCompletedCount: 0,
    searchFailedCount: 0,
    batchSearhResults: []
  };

  private qurantinedSizeProvider = (r: EnrichMerchantRequest) => 1;
  @ViewChild('datatable') ngxDatatable: DatatableComponent;
  @ViewChild('filterByIndexerSelect', { static: true }) filterByIndexerSelect: SearchSelectBase;
  @ViewChild('allocateToSelect', { static: true }) allocateToSelect: SearchSelectBase;
  isEnrichSelectedButtonDisabled: boolean = false;

  constructor(injector: Injector, private quarantinedService: QuarantinedService, private router: Router,
    private csvComponentService: CsvComponentService, private dateTimeService: DateTimeService,
    private loadingService: LoadingService, private userService: UserService, private searchSelectService: SearchSelectService,
    private batchRequestService: BatchRequestService, private enrichMerchantService: EnrichMerchantService,
    private notificationService: NotificationService, private valueCheckerService: ValueCheckerService) {
    super(injector)
    this.searchCountResults = [];
  }

  ngOnInit() {
    this.state = {
      displayResults: [],
      filtererdDisplayResults: []
    };

    this.restoreState();
    this.userService.getApplicationUsersCached().subscribe(users => {
      this.applicationUsers = users.filter(u => u.Status === UserStatusType.Active);

      if (this.state.displayResults.length === 0) {
        this.loadQuarantinedCals().subscribe(() => {
          this.configureIndexerNameFilter();
          this.configureAllocateTo();
        });
      } else {
        this.setupDisplayData();
        this.configureIndexerNameFilter();
        this.configureAllocateTo();
      }
    });
  }

  loadQuarantinedCals() {
    this.loadingService.setLoading();

    return this.quarantinedService.getQuarantinedCals()
      .pipe(finalize(() => this.loadingService.clearLoading()),
        tap((res) => {
          this.state.filtererdDisplayResults = this.state.displayResults = res;
          this.setupDisplayData();
        }));
  }

  applyFilters() {
    if (!this.enableIndexerFilter) {
      this.state.filtererdDisplayResults = this.state.displayResults;
    }
    else {
      if (this.filterIndexerId == -1) {
        this.state.filtererdDisplayResults = this.state.displayResults.filter(t => t.AllocatedToIndexerId == null);
      }
      else if (this.filterIndexerId) {
        this.state.filtererdDisplayResults = this.state.displayResults.filter(t => t.AllocatedToIndexerId == this.filterIndexerId);
      }
      else {
        this.state.filtererdDisplayResults = this.state.displayResults;
      }
    }
    if (this.enrichResult === OverallResult.Success) {
      this.state.filtererdDisplayResults = this.state.displayResults
        .filter(t => t.EnrichResult != this.enrichResult || !this.enrichResult);
    }
    this.selected = [];
  }

  sendForm() {
    this.loadingService.setLoading();
    let allocateCals: AllocateCal[] = [];
    for (let value of this.selected) {
      allocateCals.push({
        CardAcceptorLocatorsID: value.CardAcceptorLocatorsID,
        AllocatedToIndexerId: this.allocatedToIndexerId
      });
    }
    this.quarantinedService.allocateCals(allocateCals)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((res) => {
        this.loadQuarantinedCals().subscribe(() => {
          this.applyFilters();
        });
      });
  }

  goToEnrichMerchant(lwcId: number) {
    this.router.navigate(['/enrichmerchant', { lwcId: lwcId, cleanState: true }]);
  }

  getCsvOptions() {
    let options = this.csvComponentService.getCsvOptions(this.state.displayResults[0], 'QuarantinedCals.csv');

    return options;
  }

  formatDate(date: string) {
    let formatted = this.dateTimeService.formatWithHours(date);

    return formatted;
  }

  isStreetViewAvailable(val: boolean) {
    if (val) {
      return 'Yes'
    } else {
      return 'No'
    }
  }

  setLocatedIn(name: string, id: number) {
    if (name && id) {
      return `${id} - ${name}`;
    }

    if (name) {
      return name;
    }

    if (id) {
      return id.toString();
    }
  }

  onSelect(selected: any) {
    this.selected = selected.selected;
  }

  enableIndexerFilterCheckboxSelected() {
    this.applyFilters();
  }

  enrichSelectedCals() {
    this.isEnrichSelectedButtonDisabled = true;
    this.enrichMerchantRequests = this.quarantinedService.prepareEnrichMerchantRequests(this.selected);
    this.enrichBatchRequestStatus.searchInProgressCount = 0;
    this.enrichBatchRequestStatus.searchCompletedCount = 0;
    this.enrichBatchRequestStatus.searchFailedCount = 0;
    this.enrich();
  }

  enrich() {
    let retryRequests: ConcurrentRequest<EnrichMerchantRequest>[] = [];
    let successRequests: ConcurrentRequest<EnrichMerchantRequest>[] = [];
    this.enrichBatchRequestStatus.batchSearhResults = [];
    const config: BatchRequestConfig<EnrichMerchantRequest, AUS_Indexing_Result> = {
      searchBatchRequest: this.enrichMerchantService.enrich,
      searchBatchSize: 1,
      concurrentRequests: 1
    };
    this.searchWasPerformed = true;
    let batchRequests = <EnrichMerchantRequest[]>this.enrichMerchantRequests;
    this.batchRequestService.doBatchRequest<EnrichMerchantRequest, AUS_Indexing_Result>(batchRequests,
      batchRequests, batchRequests, retryRequests, successRequests, this.failedRequests,
      this.qurantinedSizeProvider, this.enrichBatchRequestStatus, config)
      .subscribe((batchRequestStatus) => {
        this.enrichBatchRequestStatus = batchRequestStatus;
        if (this.enrichBatchRequestStatus.searchInProgressCount === 0) {
          this.fillOverallResultForDisplayData(batchRequestStatus.batchSearhResults);
          this.setBuildMerchantRecordOutcome(batchRequestStatus.batchSearhResults);
          this.applyFilters();
          this.isEnrichSelectedButtonDisabled = false;
        }

        // Enforce ngOnChange
        this.enrichBatchRequestStatus.batchSearhResults = batchRequestStatus.batchSearhResults.slice(0);
      });
  }

  getRowClass(row: AusQuarantinedCalDisplay): string {
    if (row.EnrichResult === OverallResult.Incomplete) {
      return 'warningRow';
    }
    if (row.EnrichResult === OverallResult.Success) {
      return 'successRow';
    }
    if (row.EnrichResult === OverallResult.Warning) {
      return 'warningRow';
    }
    if (row.EnrichResult === OverallResult.Error) {
      return 'dangerRow';
    }
    if (row.EnrichResult === OverallResult.IndexingFailed) {
      return 'dangerRow';
    }
    if (row.EnrichResult === OverallResult.AlreadyIndexed) {
      return 'warningRow';
    }
  }

  selectFirstFiftyRows() {
    this.ngxDatatable.onHeaderSelect(true);
    this.selected = this.selected.slice(0, 50);
  }

  selectFirstHundredRows() {
    this.ngxDatatable.onHeaderSelect(true);
    this.selected = this.selected.slice(0, 100);
  }

  refreshQuarantinedCals() {
    this.loadQuarantinedCals().subscribe(() => {
      this.notificationService.notifySuccess('Quarantined CALs refreshed');
    });
  }

  private fillOverallResultForDisplayData(batchSearchResults: BatchSearchResult<AUS_Indexing_Result>[]) {
    for (let displayData of this.state.displayResults) {
      let result = batchSearchResults.find(r => r.response && r.response.LWC_ID === displayData.LWC_ID);
      if (result) {
        this.enrichResult = displayData.EnrichResult = result.response.BuildMerchantRecordOutcome;
      }
    }
  }

  private setBuildMerchantRecordOutcome(batchSearchResults: BatchSearchResult<AUS_Indexing_Result>[]) {
    for (let qurantinedCal of this.state.displayResults) {
      qurantinedCal.EnrichResultTooltip = '';
      for (let batchSearchResult of batchSearchResults) {
        if (batchSearchResult.response.LWC_ID !== qurantinedCal.LWC_ID) {
          continue;
        }
        qurantinedCal.EnrichResultTooltip = this.enrichMerchantService.getResultText(batchSearchResult.response);
      }
    }
  }

  private setupDisplayData() {
    this.indexerNames = [];
    for (let qurantinedCal of this.state.displayResults) {
      qurantinedCal.IndexerName = this.getIndexerName(qurantinedCal.AllocatedToIndexerId);
    }
    for (let user of this.applicationUsers) {
      this.indexerNames.push({ name: user.FirstName + ' ' + user.LastName, value: user.Id });
    }
    this.sorteIndexerNamesdAlphabetically();
    this.indexerNames.unshift({ name: 'Unallocated', value: -1 });
  }

  private getIndexerName(userId: number) {
    let name = 'LWC System';
    let user = this.applicationUsers.find(u => u.Id === userId);

    if (user) {
      name = user.FirstName + " " + user.LastName;
    }

    return name;
  }

  private configureIndexerNameFilter() {

    this.namesDataSource = this.quarantinedService.getIndexerNamesDataSource(this.indexerNames);
    this.namesCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(indexerId => {
      if (indexerId) {
        this.filterIndexerId = indexerId;
        this.applyFilters();
      }
    });

    if (!this.valueCheckerService.isNullOrUndefined(this.filterByIndexerSelect)) {
      this.filterByIndexerSelect.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(indexerId => {
        if (indexerId) {
          this.filterIndexerId = indexerId?.value;
          this.applyFilters();
        }
      });
    }
  }

  private configureAllocateTo() {
    let names = this.indexerNames.slice(1);
    this.allocateDataSource = this.quarantinedService.getIndexerNamesDataSource(names);
    this.allocateCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(allocatedToIndexerId => {
      if (allocatedToIndexerId) {
        this.allocatedToIndexerId = allocatedToIndexerId;
        if (this.allocatedToIndexerId) {
          this.allocateToSelected = false;
        }
        else {
          this.allocateToSelected = true;
        }
      }
    });

    if (!this.valueCheckerService.isNullOrUndefined(this.allocateToSelect)) {
      this.allocateToSelect.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(allocatedToIndexerId => {
        if (allocatedToIndexerId) {
          this.allocatedToIndexerId = allocatedToIndexerId?.value;
          if (this.allocatedToIndexerId) {
            this.allocateToSelected = false;
          }
          else {
            this.allocateToSelected = true;
          }
        }
      });
    }
  }

  private sorteIndexerNamesdAlphabetically() {
    this.indexerNames.sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;

      return 0;
    });
  }

  getSearchcount() {
    let cal: any = {};
    let cals: any = [];
    for (let i of this.selected) {
      cals.push(i.OriginalSearchString);
    }
    cal = { 'cals': cals };
    this.loadingService.setLoading();
    this.quarantinedService.getSearchcount(cal).pipe(finalize(() => this.loadingService.clearLoading())).subscribe((response) => {
      this.loadingService.clearLoading();
      this.searchCountResults = response;
    })
  }
}
