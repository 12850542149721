import { Injectable } from '@angular/core';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { MerchantValidationRecord } from '../components/merchantvalidation/merchantvalidationrecord';
import { apiPathConstants } from '../core/api/apipathconstants';


@Injectable({
  providedIn: 'root',
})

export class MerchantValidationService {

  constructor(private apiClient: ApiClient, private apiService: ApiService) { }
  
  loadValidations(lwcId: number) {
    return this.apiClient.get<MerchantValidationRecord[]>(this.apiService
      .buildUrl(apiPathConstants.merchantIndexMerchantValidations, lwcId.toString()));
  }

  saveValidations(validations: object) {
    return this.apiClient.post<void>(this.apiService
      .buildUrl(apiPathConstants.merchantIndexMerchantValidations), validations);
  }
}
