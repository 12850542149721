import { apiPathConstants } from './../core/api/apipathconstants';
import { Injectable } from "@angular/core";
import { ApiClient } from "../core/api/apiclient";
import { ApiService } from "../core/api/api.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BulkLogoEditServie {
  constructor(private apiClient: ApiClient, private apiService: ApiService) {
  }

  loadMerchants(batchSize: number, dataScore: number): Observable<number[]> {
    return this.apiClient.get<number[]>
      (this.apiService.buildApiUrlWithParameters(
        apiPathConstants.missingLogoList,
        {
          name: 'max', value: batchSize.toString()
        },
        {
          name: 'richness', value: dataScore.toString()
        }
      ));
  }
}
