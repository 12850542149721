import { NgModule } from '@angular/core';
import { NouisliderModule } from 'ng2-nouislider';
import { ClearInputDirective } from './directives/clearinput.directive';
import { NumberOnlyDirective } from './directives/number.directive';
import { HotkeyDirective } from './directives/hotkey.directive';
import { HotkeyTooltipComponent } from './hotkeytooltip/hotkeytooltip.component';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { CategoryComponent } from './category/category.component';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LookupOptionsComponent } from './lookupoptions/lookupoptions.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChainSelectComponent } from './chainselect/chainselect.component';
import { SearchSelectModule } from '@oasisdigital/angular-material-search-select';
import { BaseComponent } from './base/base.component';
import { FullPathCategorySelectComponent } from './fullpathcategoryselect/fullpathcategoryselect.component';
import { ProgressSpinnerComponent } from './progressspinner/progressspinner.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { StringEnumRequiredDirective } from './directives/stringenumrequired.directive';
import { CustomSpinnerDirective } from './directives/customspinner.directive';
import { InTableSearchSelect } from './directives/intablesearchselect.directive';

@NgModule({
    declarations: [
        NumberOnlyDirective,
        ClearInputDirective,
        HotkeyDirective,
        StringEnumRequiredDirective,
        HotkeyTooltipComponent,
        CategoryComponent,
        LookupOptionsComponent,
        ChainSelectComponent,
        BaseComponent,
        FullPathCategorySelectComponent,
        ProgressSpinnerComponent,
        CustomSpinnerDirective,
        InTableSearchSelect
    ],
    imports: [
        CommonModule,
        NouisliderModule,
        QuillModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        SearchSelectModule,
        OverlayModule
    ],
    exports: [
        NouisliderModule,
        NumberOnlyDirective,
        ClearInputDirective,
        HotkeyDirective,
        StringEnumRequiredDirective,
        HotkeyTooltipComponent,
        QuillModule,
        CategoryComponent,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        LookupOptionsComponent,
        SearchSelectModule,
        ChainSelectComponent,
        FullPathCategorySelectComponent,
        OverlayModule,
        CustomSpinnerDirective,
        ProgressSpinnerComponent,
        InTableSearchSelect
    ],
    entryComponents: [HotkeyTooltipComponent, ProgressSpinnerComponent]
})
export class SharedModule { }
