import { Component, NgZone } from '@angular/core';
import { MenuItemType } from '../../enums/enums';
import { MenuItemService } from './menuitem/menuitem.service';
import { MenuItem } from './menuitem/menuitem';
import { HighlightService } from '../../core/uiservices/highlight.service';
import { ThemeManagerService } from '../../services/theme-manager.service';

const SMALL_WIDTH_BREAKPOINT = 720;

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  currentFilter: string;
  menuItems = this.sidenavService.getMenuItems();
  filteredMenuItems = this.menuItems;
  menuItemType = MenuItemType;
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  lwcLogo:string='assets/img/Experian_BM_Wht.png'

  constructor(zone: NgZone, private sidenavService: MenuItemService, private highlightService: HighlightService,private themeService: ThemeManagerService) {
    this.mediaMatcher.addListener(() =>
      zone.run(() => this.mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)));
  }

  isScreenSmall(): boolean {
    return this.mediaMatcher.matches;
  }

  handleClearInput() {
    this.currentFilter = '';
    this.filteredMenuItems = this.sidenavService.getMenuItems();
  }

  filter(event: KeyboardEvent) {
    this.currentFilter = (<HTMLInputElement>event.target).value.toLowerCase();
    this.filteredMenuItems = this.filterMenuItems(this.menuItems);

    if (this.currentFilter) {
      this.highlightMenuItems(this.filteredMenuItems);
    } else {
      this.filteredMenuItems = this.sidenavService.getMenuItems();
    }
  }

  filterMenuItems(menuItems: MenuItem[]) {
    if (!this.currentFilter) {
      return menuItems;
    }

    let filtered = menuItems.filter(item =>
      item.type === MenuItemType.Item && item.name.toLowerCase().indexOf(this.currentFilter) !== -1
    );

    for (let menuItem of menuItems) {
      if (menuItem.subItems && menuItem.subItems.length > 0) {
        filtered = filtered.concat(this.filterMenuItems(menuItem.subItems));
      }
    }

    return filtered;
  }

  private highlightMenuItems(menuItems: MenuItem[]) {
    for (let menuItem of menuItems) {
      menuItem.displayName = this.highlightService.highlight(menuItem.name, this.currentFilter);
    }
  }
}
