import { ComponentStateService } from '../../services/componentstate.service';
import { Component,  Injector, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';

@Component({
  selector: 'app-base',
  templateUrl: './basestate.component.html',
  styleUrls: ['./basestate.component.scss']
})
export class BaseStateComponent<T> extends BaseComponent implements OnDestroy {
  protected componentStateService: ComponentStateService;
  state: T;
  private stateKey: string = this.constructor.name;

  constructor(injector: Injector) {
    super(injector);
    this.componentStateService = injector.get(ComponentStateService);
  }

  ngOnDestroy(): void {
    this.componentStateService.setState(this.stateKey, this.state);
  }

  protected restoreState() {
    this.state = this.componentStateService.getState<T>(this.stateKey) || this.state;
  }
}
