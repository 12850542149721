import { emptyString } from './../core/constants/constants';
import { ValueCheckerService } from './../core/services/valuechecker.service';
import { StringService } from './../core/formatting/string.service';
import { hotChocolate, apiUsagesQuery, apiUsagesStatsForUsagePlanIdQuery } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { ManageApiUsagesResponse, ApiUsagesStatsForUsagePlanIdResponse } from '../components/manageapiusage/manageapiusage.component';
import { LoadingService } from '../core/uiservices/loading.service';

@Injectable({
  providedIn: "root"
})
export class ManageApiUsageService {
  constructor(private apollo: Apollo, private loadingService: LoadingService, private stringService: StringService,
    private valueCheckerService: ValueCheckerService) { }

  getApiUsages(planName: string, apiKeyName: string,filterByUsedQuota:number, take: number, skip: number,orderBy: string, orderDirection: string) {
    this.loadingService.setLoading();
    let query = apiUsagesQuery;
    let optionalFilters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(apiKeyName)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ apiKeyName: {startsWith: "{0}"} },`,apiKeyName.trim());
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(planName)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ planName: {eq: "{0}"} }`, planName.trim());
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(filterByUsedQuota)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ calculateUsedQuotaPercentange: {gte: {0}} }`, filterByUsedQuota.toString());
    }
    if (optionalFilters === emptyString) {
      query = query.replace("where: { and: [ {0} ] },", emptyString);
    }
    return this.apollo
      .use(hotChocolate)
      .watchQuery<ManageApiUsagesResponse>({
        query: gql`${this.stringService.format(query, optionalFilters, skip.toString(), take.toString(), orderBy, orderDirection.toUpperCase())}`
      })
      .valueChanges;
  }

  getApiUsagesStatsForUsagePlanId(usagePlanId: string) {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<ApiUsagesStatsForUsagePlanIdResponse>({
        query: gql`${this.stringService.format(apiUsagesStatsForUsagePlanIdQuery, usagePlanId)}`
      })
      .valueChanges;
  }
}
