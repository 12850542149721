export let merchantResponse: any = [
  {
    'LWC_ID': 1,
    'search_time_ms': 626,
    'total_credits_used': 10,
    'fields': 'All',
    'search_results': [{
      'lwc_search_id': '00000000-0000-0000-0000-000000000000',
      'cal': 'IGA THIRROUL',
      'number_of_results': 1,
      'highest_score': 92,
      'utc_expiry_time': '2018-05-08T22:08:19.1281315Z',
      'merchants': [{
        'search_results_id': '00000000-0000-0000-0000-000000000000',
        'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
        'rank': 1,
        'score': 92,
        'match_feedback_requested': false,
        'type_of_match': 'KNOWN',
        'merchant_details': {
          'id': 1000621674,
          'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
          'data_enrichment_score': 90,
          'merchant_primary_name': 'FREDERICKS IGA KIAMA DOWNS',
          'primary_address': {
            'single_line_address': '275 Lawrence Hargrave Drive, Thirroul NSW 2515 AU',
            'address_line_1': '275 Lawrence Hargrave Dr',
            'address_line_2': null,
            'state': 'NSW',
            'postcode': '2515',
            'suburb': 'Thirroul',
            'longitude': 150.923524,
            'latitude': -34.315095,
            'lat_lon_precision': 9,
            'mapable': true,
            'street_view_available': true,
            'address_located_in': null
          },
          'verification': {
            'is_verified': true,
            'last_verified_on': '2017-12-04T23:51:00'
          },
          'primary_contacts': [{
            'type_of_contact': 'URL',
            'value': 'http://www.iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }, {
            'type_of_contact': 'PHONE',
            'value': '(02) 4267 4853',
            'display_value': '(02) 4267 4853',
            'label': 'PHONE'
          }],
          'suspicious_merchant_score': 50,
          'secondary_contacts': [{
            'type_of_contact': 'FACEBOOK',
            'value': 'http://facebook.com/igaaustralia',
            'display_value': 'facebook.com',
            'label': 'FACEBOOK'
          }, {
            'type_of_contact': 'TWITTER',
            'value': 'http://twitter.com/igaAustralia',
            'display_value': 'twitter.com',
            'label': 'TWITTER'
          }, {
            'type_of_contact': 'URL',
            'value': 'http://iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }],
          'secondary_addresses': null,
          'image_gallery': null,
          'primary_category': {
            'category_name': 'Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': {
              'category_name': 'Grocery',
              'id': 2,
              'full_category_path': 'Grocery',
              'parent': null
            },
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          },
          'other_categories_list': [{
            'category_name': 'PUBLISHING',
            'id': 5007015,
            'full_category_path': 'PUBLISHING',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Food',
            'id': 3,
            'full_category_path': 'Food',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Grocery >> Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          }, {
            'category_name': 'GROCERY STORES SUPERMARKETS',
            'id': 2001,
            'full_category_path': 'GROCERY STORES SUPERMARKETS',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }],
          'primary_merchant_description': 'IGA is a chain of independently owned and operated supermarkets in Australia',
          'merchant_descriptors': ['Cornetts Beachmere',
            // tslint:disable-next-line:max-line-length
            'Welcome to IGA Supermarkets. Find your nearest store browse our catalogue specials try our recipes and get involved in helping your local community.',
            'IGA Supermarkets | Independent Grocers of Australia',
            // tslint:disable-next-line:max-line-length
            'This is the official Twitter account of IGA Supermarkets Australia. Share your local stories ideas and inspirations using the hashtag #howthelocalslikeit'],
          'is_permanently_closed': false,
          'mobile_merchant': false,
          'internet_merchant': false,
          'merchant_logo': {
            'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-logo-image.png',
            'height': 128,
            'width': 128
          },
          'website_screen_shot': {
            'thumbnail': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-thumbnail.jpg',
              'height': 65,
              'width': 100
            },
            'banner': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-banner.jpg',
              'height': 300,
              'width': 100
            },
            'main': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-image.jpg',
              'height': 1024,
              'width': 1280
            }
          },
          'also_known_as': ['IGA KIAMA DOWNS', 'IGA LIQUOR KIAMA DOWNS', 'NEXUS RETAIL PTY LIMITED'],
          'associated_with': [{
            'associates_name': 'IGA (HEAD OFFICE)',
            'associates_id': '7678875a-8ee2-4aec-bc41-4bdd34626441',
            'type_of_association': 'Franchisor'
          }],
          'last_updated': '4/23/2018',
          'opening_hours': null,
          'legal_business_info': {
            'abn': '71155695935',
            'acn': '155695935',
            'date_established': '2012',
            'entity_type': 'PRV',
            'gst': true,
            'current_merchant_status': 'Active',
            'is_franchise': true,
            'is_branch': false,
            'is_head_office': false,
            'chain_name': 'IGA'
          },
          'overall_rating': null,
          'ratings': null,
          'payment_options': ['Visa', 'Mastercard', 'EFTPOS'],
          'transaction_query_process': null,
          'insurance_information': null
        }
      }],
      'user_message': 'The search for IGA THIRROUL returned 1 merchant',
      'system_message': null,
      'error_code': 0,
      'lwc_attempting_to_index': false,
      'cal_search_time_ms': 310,
      'credits_used': 10
    }]
  },
  {
    'LWC_ID': 1,
    'search_time_ms': 626,
    'total_credits_used': 10,
    'fields': 'All',
    'search_results': [{
      'lwc_search_id': '00000000-0000-0000-0000-000000000000',
      'cal': 'IGA THIRROUL',
      'number_of_results': 1,
      'highest_score': 92,
      'utc_expiry_time': '2018-05-08T22:08:19.1281315Z',
      'merchants': [{
        'search_results_id': '00000000-0000-0000-0000-000000000000',
        'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
        'rank': 1,
        'score': 92,
        'match_feedback_requested': false,
        'type_of_match': 'KNOWN',
        'merchant_details': {
          'id': 1000621674,
          'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
          'data_enrichment_score': 90,
          'merchant_primary_name': 'FREDERICKS IGA KIAMA DOWNS',
          'primary_address': {
            'single_line_address': '275 Lawrence Hargrave Drive, Thirroul NSW 2515 AU',
            'address_line_1': '275 Lawrence Hargrave Dr',
            'address_line_2': null,
            'state': 'NSW',
            'postcode': '2515',
            'suburb': 'Thirroul',
            'longitude': 150.923524,
            'latitude': -34.315095,
            'lat_lon_precision': 9,
            'mapable': true,
            'street_view_available': true,
            'address_located_in': null
          },
          'verification': {
            'is_verified': true,
            'last_verified_on': '2017-12-04T23:51:00'
          },
          'primary_contacts': [{
            'type_of_contact': 'URL',
            'value': 'http://www.iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }, {
            'type_of_contact': 'PHONE',
            'value': '(02) 4267 4853',
            'display_value': '(02) 4267 4853',
            'label': 'PHONE'
          }],
          'suspicious_merchant_score': 50,
          'secondary_contacts': [{
            'type_of_contact': 'FACEBOOK',
            'value': 'http://facebook.com/igaaustralia',
            'display_value': 'facebook.com',
            'label': 'FACEBOOK'
          }, {
            'type_of_contact': 'TWITTER',
            'value': 'http://twitter.com/igaAustralia',
            'display_value': 'twitter.com',
            'label': 'TWITTER'
          }, {
            'type_of_contact': 'URL',
            'value': 'http://iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }],
          'secondary_addresses': null,
          'image_gallery': null,
          'primary_category': {
            'category_name': 'Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': {
              'category_name': 'Grocery',
              'id': 2,
              'full_category_path': 'Grocery',
              'parent': null
            },
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          },
          'other_categories_list': [{
            'category_name': 'PUBLISHING',
            'id': 5007015,
            'full_category_path': 'PUBLISHING',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Food',
            'id': 3,
            'full_category_path': 'Food',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Grocery >> Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          }, {
            'category_name': 'GROCERY STORES SUPERMARKETS',
            'id': 2001,
            'full_category_path': 'GROCERY STORES SUPERMARKETS',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }],
          'primary_merchant_description': 'IGA is a chain of independently owned and operated supermarkets in Australia',
          'merchant_descriptors': ['Cornetts Beachmere',
            // tslint:disable-next-line:max-line-length
            'Welcome to IGA Supermarkets. Find your nearest store browse our catalogue specials try our recipes and get involved in helping your local community.',
            'IGA Supermarkets | Independent Grocers of Australia',
            // tslint:disable-next-line:max-line-length
            'This is the official Twitter account of IGA Supermarkets Australia. Share your local stories ideas and inspirations using the hashtag #howthelocalslikeit'],
          'is_permanently_closed': false,
          'mobile_merchant': false,
          'internet_merchant': false,
          'merchant_logo': {
            'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-logo-image.png',
            'height': 128,
            'width': 128
          },
          'website_screen_shot': {
            'thumbnail': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-thumbnail.jpg',
              'height': 65,
              'width': 100
            },
            'banner': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-banner.jpg',
              'height': 300,
              'width': 100
            },
            'main': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-image.jpg',
              'height': 1024,
              'width': 1280
            }
          },
          'also_known_as': ['IGA KIAMA DOWNS', 'IGA LIQUOR KIAMA DOWNS', 'NEXUS RETAIL PTY LIMITED'],
          'associated_with': [{
            'associates_name': 'IGA (HEAD OFFICE)',
            'associates_id': '7678875a-8ee2-4aec-bc41-4bdd34626441',
            'type_of_association': 'Franchisor'
          }],
          'last_updated': '4/23/2018',
          'opening_hours': null,
          'legal_business_info': {
            'abn': '71155695935',
            'acn': '155695935',
            'date_established': '2012',
            'entity_type': 'PRV',
            'gst': true,
            'current_merchant_status': 'Active',
            'is_franchise': true,
            'is_branch': false,
            'is_head_office': false,
            'chain_name': 'IGA'
          },
          'overall_rating': null,
          'ratings': null,
          'payment_options': ['Visa', 'Mastercard', 'EFTPOS'],
          'transaction_query_process': null,
          'insurance_information': null
        }
      }],
      'user_message': 'The search for IGA THIRROUL returned 1 merchant',
      'system_message': null,
      'error_code': 0,
      'lwc_attempting_to_index': false,
      'cal_search_time_ms': 310,
      'credits_used': 10
    }]
  },
  {
    'LWC_ID': 1,
    'search_time_ms': 626,
    'total_credits_used': 10,
    'fields': 'All',
    'search_results': [{
      'lwc_search_id': '00000000-0000-0000-0000-000000000000',
      'cal': 'IGA THIRROUL',
      'number_of_results': 1,
      'highest_score': 92,
      'utc_expiry_time': '2018-05-08T22:08:19.1281315Z',
      'merchants': [{
        'search_results_id': '00000000-0000-0000-0000-000000000000',
        'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
        'rank': 1,
        'score': 92,
        'match_feedback_requested': false,
        'type_of_match': 'KNOWN',
        'merchant_details': {
          'id': 1000621674,
          'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
          'data_enrichment_score': 90,
          'merchant_primary_name': 'FREDERICKS IGA KIAMA DOWNS',
          'primary_address': {
            'single_line_address': '275 Lawrence Hargrave Drive, Thirroul NSW 2515 AU',
            'address_line_1': '275 Lawrence Hargrave Dr',
            'address_line_2': null,
            'state': 'NSW',
            'postcode': '2515',
            'suburb': 'Thirroul',
            'longitude': 150.923524,
            'latitude': -34.315095,
            'lat_lon_precision': 9,
            'mapable': true,
            'street_view_available': true,
            'address_located_in': null
          },
          'verification': {
            'is_verified': true,
            'last_verified_on': '2017-12-04T23:51:00'
          },
          'primary_contacts': [{
            'type_of_contact': 'URL',
            'value': 'http://www.iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }, {
            'type_of_contact': 'PHONE',
            'value': '(02) 4267 4853',
            'display_value': '(02) 4267 4853',
            'label': 'PHONE'
          }],
          'suspicious_merchant_score': 50,
          'secondary_contacts': [{
            'type_of_contact': 'FACEBOOK',
            'value': 'http://facebook.com/igaaustralia',
            'display_value': 'facebook.com',
            'label': 'FACEBOOK'
          }, {
            'type_of_contact': 'TWITTER',
            'value': 'http://twitter.com/igaAustralia',
            'display_value': 'twitter.com',
            'label': 'TWITTER'
          }, {
            'type_of_contact': 'URL',
            'value': 'http://iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }],
          'secondary_addresses': null,
          'image_gallery': null,
          'primary_category': {
            'category_name': 'Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': {
              'category_name': 'Grocery',
              'id': 2,
              'full_category_path': 'Grocery',
              'parent': null
            },
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          },
          'other_categories_list': [{
            'category_name': 'PUBLISHING',
            'id': 5007015,
            'full_category_path': 'PUBLISHING',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Food',
            'id': 3,
            'full_category_path': 'Food',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Grocery >> Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          }, {
            'category_name': 'GROCERY STORES SUPERMARKETS',
            'id': 2001,
            'full_category_path': 'GROCERY STORES SUPERMARKETS',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }],
          'primary_merchant_description': 'IGA is a chain of independently owned and operated supermarkets in Australia',
          'merchant_descriptors': ['Cornetts Beachmere',
            // tslint:disable-next-line:max-line-length
            'Welcome to IGA Supermarkets. Find your nearest store browse our catalogue specials try our recipes and get involved in helping your local community.',
            'IGA Supermarkets | Independent Grocers of Australia',
            // tslint:disable-next-line:max-line-length
            'This is the official Twitter account of IGA Supermarkets Australia. Share your local stories ideas and inspirations using the hashtag #howthelocalslikeit'],
          'is_permanently_closed': false,
          'mobile_merchant': false,
          'internet_merchant': false,
          'merchant_logo': {
            'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-logo-image.png',
            'height': 128,
            'width': 128
          },
          'website_screen_shot': {
            'thumbnail': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-thumbnail.jpg',
              'height': 65,
              'width': 100
            },
            'banner': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-banner.jpg',
              'height': 300,
              'width': 100
            },
            'main': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-image.jpg',
              'height': 1024,
              'width': 1280
            }
          },
          'also_known_as': ['IGA KIAMA DOWNS', 'IGA LIQUOR KIAMA DOWNS', 'NEXUS RETAIL PTY LIMITED'],
          'associated_with': [{
            'associates_name': 'IGA (HEAD OFFICE)',
            'associates_id': '7678875a-8ee2-4aec-bc41-4bdd34626441',
            'type_of_association': 'Franchisor'
          }],
          'last_updated': '4/23/2018',
          'opening_hours': null,
          'legal_business_info': {
            'abn': '71155695935',
            'acn': '155695935',
            'date_established': '2012',
            'entity_type': 'PRV',
            'gst': true,
            'current_merchant_status': 'Active',
            'is_franchise': true,
            'is_branch': false,
            'is_head_office': false,
            'chain_name': 'IGA'
          },
          'overall_rating': null,
          'ratings': null,
          'payment_options': ['Visa', 'Mastercard', 'EFTPOS'],
          'transaction_query_process': null,
          'insurance_information': null
        }
      }],
      'user_message': 'The search for IGA THIRROUL returned 1 merchant',
      'system_message': null,
      'error_code': 0,
      'lwc_attempting_to_index': false,
      'cal_search_time_ms': 310,
      'credits_used': 10
    }]
  },
  {
    'LWC_ID': 1,
    'search_time_ms': 626,
    'total_credits_used': 10,
    'fields': 'All',
    'search_results': [{
      'lwc_search_id': '00000000-0000-0000-0000-000000000000',
      'cal': 'IGA THIRROUL',
      'number_of_results': 1,
      'highest_score': 92,
      'utc_expiry_time': '2018-05-08T22:08:19.1281315Z',
      'merchants': [{
        'search_results_id': '00000000-0000-0000-0000-000000000000',
        'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
        'rank': 1,
        'score': 92,
        'match_feedback_requested': false,
        'type_of_match': 'KNOWN',
        'merchant_details': {
          'id': 1000621674,
          'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
          'data_enrichment_score': 90,
          'merchant_primary_name': 'FREDERICKS IGA KIAMA DOWNS',
          'primary_address': {
            'single_line_address': '275 Lawrence Hargrave Drive, Thirroul NSW 2515 AU',
            'address_line_1': '275 Lawrence Hargrave Dr',
            'address_line_2': null,
            'state': 'NSW',
            'postcode': '2515',
            'suburb': 'Thirroul',
            'longitude': 150.923524,
            'latitude': -34.315095,
            'lat_lon_precision': 9,
            'mapable': true,
            'street_view_available': true,
            'address_located_in': null
          },
          'verification': {
            'is_verified': true,
            'last_verified_on': '2017-12-04T23:51:00'
          },
          'primary_contacts': [{
            'type_of_contact': 'URL',
            'value': 'http://www.iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }, {
            'type_of_contact': 'PHONE',
            'value': '(02) 4267 4853',
            'display_value': '(02) 4267 4853',
            'label': 'PHONE'
          }],
          'suspicious_merchant_score': 50,
          'secondary_contacts': [{
            'type_of_contact': 'FACEBOOK',
            'value': 'http://facebook.com/igaaustralia',
            'display_value': 'facebook.com',
            'label': 'FACEBOOK'
          }, {
            'type_of_contact': 'TWITTER',
            'value': 'http://twitter.com/igaAustralia',
            'display_value': 'twitter.com',
            'label': 'TWITTER'
          }, {
            'type_of_contact': 'URL',
            'value': 'http://iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }],
          'secondary_addresses': null,
          'image_gallery': null,
          'primary_category': {
            'category_name': 'Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': {
              'category_name': 'Grocery',
              'id': 2,
              'full_category_path': 'Grocery',
              'parent': null
            },
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          },
          'other_categories_list': [{
            'category_name': 'PUBLISHING',
            'id': 5007015,
            'full_category_path': 'PUBLISHING',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Food',
            'id': 3,
            'full_category_path': 'Food',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Grocery >> Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          }, {
            'category_name': 'GROCERY STORES SUPERMARKETS',
            'id': 2001,
            'full_category_path': 'GROCERY STORES SUPERMARKETS',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }],
          'primary_merchant_description': 'IGA is a chain of independently owned and operated supermarkets in Australia',
          'merchant_descriptors': ['Cornetts Beachmere',
            // tslint:disable-next-line:max-line-length
            'Welcome to IGA Supermarkets. Find your nearest store browse our catalogue specials try our recipes and get involved in helping your local community.',
            'IGA Supermarkets | Independent Grocers of Australia',
            // tslint:disable-next-line:max-line-length
            'This is the official Twitter account of IGA Supermarkets Australia. Share your local stories ideas and inspirations using the hashtag #howthelocalslikeit'],
          'is_permanently_closed': false,
          'mobile_merchant': false,
          'internet_merchant': false,
          'merchant_logo': {
            'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-logo-image.png',
            'height': 128,
            'width': 128
          },
          'website_screen_shot': {
            'thumbnail': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-thumbnail.jpg',
              'height': 65,
              'width': 100
            },
            'banner': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-banner.jpg',
              'height': 300,
              'width': 100
            },
            'main': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-image.jpg',
              'height': 1024,
              'width': 1280
            }
          },
          'also_known_as': ['IGA KIAMA DOWNS', 'IGA LIQUOR KIAMA DOWNS', 'NEXUS RETAIL PTY LIMITED'],
          'associated_with': [{
            'associates_name': 'IGA (HEAD OFFICE)',
            'associates_id': '7678875a-8ee2-4aec-bc41-4bdd34626441',
            'type_of_association': 'Franchisor'
          }],
          'last_updated': '4/23/2018',
          'opening_hours': null,
          'legal_business_info': {
            'abn': '71155695935',
            'acn': '155695935',
            'date_established': '2012',
            'entity_type': 'PRV',
            'gst': true,
            'current_merchant_status': 'Active',
            'is_franchise': true,
            'is_branch': false,
            'is_head_office': false,
            'chain_name': 'IGA'
          },
          'overall_rating': null,
          'ratings': null,
          'payment_options': ['Visa', 'Mastercard', 'EFTPOS'],
          'transaction_query_process': null,
          'insurance_information': null
        }
      }],
      'user_message': 'The search for IGA THIRROUL returned 1 merchant',
      'system_message': null,
      'error_code': 0,
      'lwc_attempting_to_index': false,
      'cal_search_time_ms': 310,
      'credits_used': 10
    }]
  },
  {
    'LWC_ID': 1,
    'search_time_ms': 626,
    'total_credits_used': 10,
    'fields': 'All',
    'search_results': [{
      'lwc_search_id': '00000000-0000-0000-0000-000000000000',
      'cal': 'IGA THIRROUL',
      'number_of_results': 1,
      'highest_score': 92,
      'utc_expiry_time': '2018-05-08T22:08:19.1281315Z',
      'merchants': [{
        'search_results_id': '00000000-0000-0000-0000-000000000000',
        'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
        'rank': 1,
        'score': 92,
        'match_feedback_requested': false,
        'type_of_match': 'KNOWN',
        'merchant_details': {
          'id': 1000621674,
          'lwc_guid': '76ef8e49-b45b-4a2c-a89e-7798e2e15301',
          'data_enrichment_score': 90,
          'merchant_primary_name': 'FREDERICKS IGA KIAMA DOWNS',
          'primary_address': {
            'single_line_address': '275 Lawrence Hargrave Drive, Thirroul NSW 2515 AU',
            'address_line_1': '275 Lawrence Hargrave Dr',
            'address_line_2': null,
            'state': 'NSW',
            'postcode': '2515',
            'suburb': 'Thirroul',
            'longitude': 150.923524,
            'latitude': -34.315095,
            'lat_lon_precision': 9,
            'mapable': true,
            'street_view_available': true,
            'address_located_in': null
          },
          'verification': {
            'is_verified': true,
            'last_verified_on': '2017-12-04T23:51:00'
          },
          'primary_contacts': [{
            'type_of_contact': 'URL',
            'value': 'http://www.iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }, {
            'type_of_contact': 'PHONE',
            'value': '(02) 4267 4853',
            'display_value': '(02) 4267 4853',
            'label': 'PHONE'
          }],
          'suspicious_merchant_score': 50,
          'secondary_contacts': [{
            'type_of_contact': 'FACEBOOK',
            'value': 'http://facebook.com/igaaustralia',
            'display_value': 'facebook.com',
            'label': 'FACEBOOK'
          }, {
            'type_of_contact': 'TWITTER',
            'value': 'http://twitter.com/igaAustralia',
            'display_value': 'twitter.com',
            'label': 'TWITTER'
          }, {
            'type_of_contact': 'URL',
            'value': 'http://iga.com.au',
            'display_value': 'iga.com.au',
            'label': 'WEB'
          }],
          'secondary_addresses': null,
          'image_gallery': null,
          'primary_category': {
            'category_name': 'Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': {
              'category_name': 'Grocery',
              'id': 2,
              'full_category_path': 'Grocery',
              'parent': null
            },
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          },
          'other_categories_list': [{
            'category_name': 'PUBLISHING',
            'id': 5007015,
            'full_category_path': 'PUBLISHING',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Food',
            'id': 3,
            'full_category_path': 'Food',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }, {
            'category_name': 'Grocery >> Supermarket',
            'id': 2001,
            'full_category_path': 'Grocery >> Supermarket',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': true,
            'lwc_category_icon': null
          }, {
            'category_name': 'GROCERY STORES SUPERMARKETS',
            'id': 2001,
            'full_category_path': 'GROCERY STORES SUPERMARKETS',
            'parent': null,
            'is_sensitive': false,
            'is_lwc_category': false,
            'lwc_category_icon': null
          }],
          'primary_merchant_description': 'IGA is a chain of independently owned and operated supermarkets in Australia',
          'merchant_descriptors': ['Cornetts Beachmere',
            // tslint:disable-next-line:max-line-length
            'Welcome to IGA Supermarkets. Find your nearest store browse our catalogue specials try our recipes and get involved in helping your local community.',
            'IGA Supermarkets | Independent Grocers of Australia',
            // tslint:disable-next-line:max-line-length
            'This is the official Twitter account of IGA Supermarkets Australia. Share your local stories ideas and inspirations using the hashtag #howthelocalslikeit'],
          'is_permanently_closed': false,
          'mobile_merchant': false,
          'internet_merchant': false,
          'merchant_logo': {
            'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-logo-image.png',
            'height': 128,
            'width': 128
          },
          'website_screen_shot': {
            'thumbnail': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-thumbnail.jpg',
              'height': 65,
              'width': 100
            },
            'banner': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-banner.jpg',
              'height': 300,
              'width': 100
            },
            'main': {
              'url': 'https://images.lookwhoscharging.com/1000621674/FREDERICKS-IGA-KIAMA-DOWNS-site-image.jpg',
              'height': 1024,
              'width': 1280
            }
          },
          'also_known_as': ['IGA KIAMA DOWNS', 'IGA LIQUOR KIAMA DOWNS', 'NEXUS RETAIL PTY LIMITED'],
          'associated_with': [{
            'associates_name': 'IGA (HEAD OFFICE)',
            'associates_id': '7678875a-8ee2-4aec-bc41-4bdd34626441',
            'type_of_association': 'Franchisor'
          }],
          'last_updated': '4/23/2018',
          'opening_hours': null,
          'legal_business_info': {
            'abn': '71155695935',
            'acn': '155695935',
            'date_established': '2012',
            'entity_type': 'PRV',
            'gst': true,
            'current_merchant_status': 'Active',
            'is_franchise': true,
            'is_branch': false,
            'is_head_office': false,
            'chain_name': 'IGA'
          },
          'overall_rating': null,
          'ratings': null,
          'payment_options': ['Visa', 'Mastercard', 'EFTPOS'],
          'transaction_query_process': null,
          'insurance_information': null
        }
      }],
      'user_message': 'The search for IGA THIRROUL returned 1 merchant',
      'system_message': null,
      'error_code': 0,
      'lwc_attempting_to_index': false,
      'cal_search_time_ms': 310,
      'credits_used': 10
    }]
  }
];

export let merchantResponse1: any = {
  LWC_ID: 1001198954,
  CreatedDate: "2019-05-21T10:17:00",
  UpdatedDate: "2019-05-21T10:38:00",
  EntityType: "PRV",
  ABN: "46121451723",
  ACN: "121451723",
  CurrentBusinessStatus: "Active",
  OnlineOnly: false,
  MobileBusiness: false,
  QueryTransactionProcess: null,
  URLToDisputeProcessPage: null,
  ABNRegDOE: "2006-08-29T00:00:00",
  ABNCanDOE: null,
  GSTRegDOE: "2006-08-29T00:00:00",
  GSTCanDOE: null,
  GSTRegistered: 1,
  OpeningHours: "{\"is_always_open\:null,\"sunday\:{\"status\:\"open\",\"times\:[{\"open\:\"11:00\",\"close\:\"17:00\"}]},\"monday\:{\"status\:\"open\",\"times\:[{\"open\:\"09:00\",\"close\:\"17:30\"}]},\"tuesday\:{\"status\:\"open\",\"times\:[{\"open\:\"09:00\",\"close\:\"17:30\"}]},\"wednesday\:{\"status\:\"open\",\"times\:[{\"open\:\"09:00\",\"close\:\"17:30\"}]},\"thursday\:{\"status\:\"open\",\"times\:[{\"open\:\"09:00\",\"close\:\"17:30\"}]},\"friday\:{\"status\:\"open\",\"times\:[{\"open\:\"09:00\",\"close\:\"17:30\"}]},\"saturday\:{\"status\:\"open\",\"times\:[{\"open\:\"09:00\",\"close\:\"17:00\"}]}}",
  YearBusinessEstablished: 2006,
  DataCompletnessScore: 100,
  IsHeadOffice: false,
  IsBranchOffice: true,
  IsFranchise: false,
  IsProduct: false,
  IndexingTimeSec: 40,
  ChainName: "Travel Money Oz",
  ChainIdentifier: "LONDON COURT ARCADE",
  HideFromWebsite: false,
  LWC_GUID: "e3908768-2345-4ad4-9730-d3a2b1932ca0",
  MerchantType: "Branch",
  MerchantPresence: "Bricks & Mortar",
  RecordLocked: false,
  CurrentBusinessStatusDateTime: null,
  AUS_Indexed_AssociatedWith: [
    {
      AUS_Indexed_AssociatedWithID: 3025663,
      LWC_ID: 1001198954,
      AssociatedWithLWC_ID: 1001011898,
      Relationship: "Headquarters",
      Source: "LWC_Chain_Data",
      SourceID: "",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: null,
      IsActive: true,
      IsActiveComment: null
    }
  ],
  AUS_Indexed_Addresses: [
    {
      IndexedAddressesID: 1772437,
      LWC_ID: 1001198954,
      AddressLine1: "647-653 Hay St",
      AddressLine2: null,
      AddressSuburb: "Perth",
      AddressStateCd: "WA",
      AddressPostcode: "6000",
      AddressCountryCd: "AUS",
      Latitude: -31.954822,
      Longitude: 115.858847,
      geoCodeGranularity: "ESTABLISHMENT",
      Mapable: true,
      StreetViewAvailable: true,
      IsPrimary: true,
      Source: "LWC_User_Data",
      SourceID: null,
      SourceDateTime: "2019-05-21T10:17:00",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      IsActive: true,
      IsActiveComment: null,
      FormattedAddressString: "Shop 24, London Court Arcade, 647-653 Hay St, Perth WA 6000, Australia",
      LatLonPrecision: 9,
      IsLocatorAddress: true,
      LocatedIn_ID: 1144,
      AUS_Indexed_Addresses_LocatedIn_Details: null
    }
  ],
  AUS_Indexed_BusinessDescriptions: [
    {
      AUS_Indexed_BusinessDesctiptionID: 1416879,
      LWC_ID: 1001198954,
      Description: "At Travel Money Oz we are all about helping you make the most of your travel money. Whether you are travelling for business or leisure sending funds to a loved one travelling the world looking for affordable travel insurance or a reliable travel money card - we aim to take the hassle out of organising your travel money needs",
      IsDefault: true,
      Source: "LWC_Chain_Data",
      SourceID: "Travel Money Oz chain info",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null
    }
  ],
  AUS_Indexed_CardAcceptorLocators_Known: [
    {
      CardAcceptorLocatorsID: 906182,
      LWC_ID: 1001198954,
      OriginalSearchString: "TRVLMONE PERTH",
      CardAcceptor: "TRVLMONE",
      FormattedCardAcceptor: "TRVLMONE",
      Suburb: "PERTH",
      State: "TAS",
      Country: "AUS",
      TerminalID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      Source: "LWC_User_D",
      SourceID: null,
      TelephoneNumber: null,
      StandardisedTransactionDescription: "TRVLMONEPERTH",
      WebAddress: null,
      Branch: null,
      IsBPayCAL: false,
      IsEFTPOSCAL: false,
      DespacedTransactionDescription: "TRVLMONEPERTH",
      IsDebitCAL: false,
      LWC_GUID: "e3908768-2345-4ad4-9730-d3a2b1932ca0",
      BPayBillerCode: null,
      CALValidated: false,
      CALValidatedOn: null,
      CALValidatedByName: null,
      CALValidatedComment: null,
      IsQuarantined: false
    },
    {
      CardAcceptorLocatorsID: 906183,
      LWC_ID: 1001198954,
      OriginalSearchString: "479600 702200TRVLMONE PERTH",
      CardAcceptor: "479600 702200TRVLMONE",
      FormattedCardAcceptor: "479600702200TRVLMONE",
      Suburb: "PERTH",
      State: "TAS",
      Country: "AUS",
      TerminalID: null,
      CreateDateTime: "2019-05-21T10:38:00",
      UpdateDateTime: null,
      Source: "LWC_User_D",
      SourceID: null,
      TelephoneNumber: null,
      StandardisedTransactionDescription: "479600702200TRVLMONEPERTH",
      WebAddress: null,
      Branch: "702200",
      IsBPayCAL: false,
      IsEFTPOSCAL: false,
      DespacedTransactionDescription: "479600702200TRVLMONEPERTH",
      IsDebitCAL: false,
      LWC_GUID: "e3908768-2345-4ad4-9730-d3a2b1932ca0",
      BPayBillerCode: null,
      CALValidated: false,
      CALValidatedOn: null,
      CALValidatedByName: null,
      CALValidatedComment: null,
      IsQuarantined: false
    }
  ],
  AUS_Indexed_Categories: [
    {
      AUS_Indexed_CategoriesID: 4288299,
      LWC_ID: 1001198954,
      CategoryName: "Services >> Financial Services",
      IsDefault: true,
      Source: "LWC_Chain_Data",
      SourceID: "Travel Money Oz chain info",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      CategorySourceIdentifier: "5015",
      LWC_CategoryID: 5015,
      FlaggedAsSensitive: false
    },
    {
      AUS_Indexed_CategoriesID: 4288300,
      LWC_ID: 1001198954,
      CategoryName: "Internet",
      IsDefault: false,
      Source: "Clearbit",
      SourceID: "b7ccabdc-33ce-43bc-869a-26eebf6096ee",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-11T01:00:00",
      IsActive: true,
      IsActiveComment: null,
      CategorySourceIdentifier: "ClearbitSubIndustry",
      LWC_CategoryID: 0,
      FlaggedAsSensitive: null
    },
    {
      AUS_Indexed_CategoriesID: 4288301,
      LWC_ID: 1001198954,
      CategoryName: "Finance",
      IsDefault: false,
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      CategorySourceIdentifier: "finance",
      LWC_CategoryID: 5015,
      FlaggedAsSensitive: null
    }
  ],
  AUS_Indexed_ContactMethods: [
    {
      AUS_Indexed_ContactMethodID: 4478407,
      LWC_ID: 1001198954,
      ContactType: "PHONE",
      DisplayValue: "1300 459 119",
      URL: null,
      Label: "PHONE",
      Source: "LWC_User_Data",
      SourceID: "",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: true,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478408,
      LWC_ID: 1001198954,
      ContactType: "URL",
      DisplayValue: "travelmoneyoz.com",
      URL: "http://www.travelmoneyoz.com",
      Label: "WEB",
      Source: "LWC_Chain_Data",
      SourceID: "",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: "2019-05-21T10:17:00",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: false,
      IsActiveComment: "Replaced with http://www.travelmoneyoz.com/stores/",
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478409,
      LWC_ID: 1001198954,
      ContactType: "PHONE",
      DisplayValue: "1300 739 509",
      URL: null,
      Label: "PHONE",
      Source: "FacebookPlace",
      SourceID: "1053515034710033",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478410,
      LWC_ID: 1001198954,
      ContactType: "URL",
      DisplayValue: "travelmoneyoz.com",
      URL: "http://www.travelmoneyoz.com/stores/perth/travel-money-oz-london-court",
      Label: "WEB",
      Source: "FacebookPlace",
      SourceID: "1053515034710033",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: true,
      ContactValidationDateTime: "2019-05-21T10:17:00",
      ContactValidationComment: "Screenshot captured successfully",
      ContactValidationRetryAttempt: 0,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478411,
      LWC_ID: 1001198954,
      ContactType: "FACEBOOK",
      DisplayValue: "facebook.com/pages/travel money...",
      URL: "https://www.facebook.com/travelmoneyoz.londoncourt",
      Label: "FACEBOOK",
      Source: "FacebookPlace",
      SourceID: "1053515034710033",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478412,
      LWC_ID: 1001198954,
      ContactType: "INTERNATIONALPHONE",
      DisplayValue: "+61 7 3199 5250",
      URL: null,
      Label: "PHONE",
      Source: "Clearbit",
      SourceID: "b7ccabdc-33ce-43bc-869a-26eebf6096ee",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-11T01:00:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478413,
      LWC_ID: 1001198954,
      ContactType: "EMAIL",
      DisplayValue: "customerfeedback@travelmoneyoz.com",
      URL: null,
      Label: "EMAIL",
      Source: "Clearbit",
      SourceID: "b7ccabdc-33ce-43bc-869a-26eebf6096ee",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-11T01:00:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: true,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478414,
      LWC_ID: 1001198954,
      ContactType: "LINKEDIN",
      DisplayValue: "linkedin.com",
      URL: "http://linkedin.com/company/oz-travel",
      Label: "LINKEDIN",
      Source: "Clearbit",
      SourceID: "b7ccabdc-33ce-43bc-869a-26eebf6096ee",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-11T01:00:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478415,
      LWC_ID: 1001198954,
      ContactType: "PHONE",
      DisplayValue: "1300 614 717",
      URL: null,
      Label: "PHONE",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478416,
      LWC_ID: 1001198954,
      ContactType: "INTERNATIONALPHONE",
      DisplayValue: "+611 3 0061 4717",
      URL: null,
      Label: "PHONE",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    },
    {
      AUS_Indexed_ContactMethodID: 4478417,
      LWC_ID: 1001198954,
      ContactType: "URL",
      DisplayValue: "travelmoneyoz.com",
      URL: "https://www.travelmoneyoz.com/stores/perth/tm-london-court",
      Label: "WEB",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: false,
      ContactValidationDateTime: null,
      ContactValidationComment: null,
      ContactValidationRetryAttempt: null,
      HideRecordFromAPI: false
    }
  ],
  AUS_Indexed_DataSources: [
    {
      AUS_Indexed_DataSourceID: 2845002,
      LWC_ID: 1001198954,
      DataSource: "LWC_Chain_Data",
      DataSourcesRecordID: "Travel Money Oz",
      Score: 1.0,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      Comment: "LWC Chain Data sourced from chain Travel Money Oz",
      IsActive: true,
      IsActiveComment: null
    },
    {
      AUS_Indexed_DataSourceID: 2845003,
      LWC_ID: 1001198954,
      DataSource: "FacebookPlace",
      DataSourcesRecordID: "1053515034710033",
      Score: 1.0,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      Comment: "Chain Match. Search=0.4s; Match=0.1s; FacebookID=1053515034710033 is not linked to an existing indexed Merchants; FacebookID=1053515034710033 is not linked to an existing indexed Merchants;",
      IsActive: true,
      IsActiveComment: null
    },
    {
      AUS_Indexed_DataSourceID: 2845004,
      LWC_ID: 1001198954,
      DataSource: "Clearbit",
      DataSourcesRecordID: "b7ccabdc-33ce-43bc-869a-26eebf6096ee",
      Score: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      Comment: null,
      IsActive: true,
      IsActiveComment: null
    },
    {
      AUS_Indexed_DataSourceID: 2845005,
      LWC_ID: 1001198954,
      DataSource: "GooglePlaces",
      DataSourcesRecordID: "ChIJmautEda6MioR1x4UTbm_np8",
      Score: 1.0,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      Comment: "Chain Match. Search=1.1s; Match=0.5s; No match with existing indexed merchants;",
      IsActive: true,
      IsActiveComment: null
    },
    {
      AUS_Indexed_DataSourceID: 2845006,
      LWC_ID: 1001198954,
      DataSource: "MerchantWebsite",
      DataSourcesRecordID: "http://www.travelmoneyoz.com/stores/perth/travel-m",
      Score: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      Comment: "Took a snapshot of the website",
      IsActive: true,
      IsActiveComment: null
    }
  ],
  AUS_Indexed_Images: [
    {
      AUS_Indexed_ImageID: 4000295,
      LWC_ID: 1001198954,
      Width: 128,
      Height: 128,
      IsLogo: true,
      LWCPathToThumbnail: null,
      SourcePathToThumbnail: null,
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-logo-image.png",
      SourcePathToFullImage: "https://images.lookwhoscharging.com/chainlogos/travelmoneyoz.png",
      Source: "LWC_Chain_Data",
      SourceID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000296,
      LWC_ID: 1001198954,
      Width: 128,
      Height: 128,
      IsLogo: false,
      LWCPathToThumbnail: null,
      SourcePathToThumbnail: null,
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-cirl-image.png",
      SourcePathToFullImage: "https://images.lookwhoscharging.com/chainlogos/travelmoneyoz.c.png",
      Source: "LWC_Chain_Data",
      SourceID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: true
    },
    {
      AUS_Indexed_ImageID: 4000297,
      LWC_ID: 1001198954,
      Width: 384,
      Height: 128,
      IsLogo: false,
      LWCPathToThumbnail: null,
      SourcePathToThumbnail: null,
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-3x1l-image.png",
      SourcePathToFullImage: "https://images.lookwhoscharging.com/chainlogos/travelmoneyoz.3x1.png",
      Source: "LWC_Chain_Data",
      SourceID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: null,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: true,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000298,
      LWC_ID: 1001198954,
      Width: 720,
      Height: 266,
      IsLogo: false,
      LWCPathToThumbnail: "travelmoneyozlondoncourtarcade-9389-thumbnail.jpg",
      SourcePathToThumbnail: null,
      LWCPathToFullImage: "travelmoneyozlondoncourtarcade-9389-image.jpg",
      SourcePathToFullImage: "https://scontent.xx.fbcdn.net/v/t1.0-9/s720x720/27858259_1544469955648342_3238378010484818650_n.jpg?_nc_cat=109&_nc_ht=scontent.xx&oh=74228c24c33e1256910320d0a447c76e&oe=5D5F1E51",
      Source: "FacebookPlace",
      SourceID: "1053515034710033",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "1544469955648342",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000299,
      LWC_ID: 1001198954,
      Width: 600,
      Height: 800,
      IsLogo: false,
      LWCPathToThumbnail: "Travel-Money-Oz-London-Court-Arcade-eubq-thumbnail.jpg",
      SourcePathToThumbnail: "https://lh3.googleusercontent.com/p/AF1QipOl9HabPK-_D8g4qcNTpHdVmyANip6nyPP1XjJ6=s1600-w100-h65",
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-eubq-image.jpg",
      SourcePathToFullImage: "https://lh3.googleusercontent.com/p/AF1QipOl9HabPK-_D8g4qcNTpHdVmyANip6nyPP1XjJ6=s1600-w600-h800",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "CmRaAAAAfeMRCl9IxiStCcOmmmRvcEA10KKk9bBREbtuisBu6A2gf2ri6Tq9idmPY2LPqP6S3jx7nEjgncw7cAmJjXnqo8yMdXb3sWWtQ4PSTqswSIWJPjeMga2t8srLzcwNOV3mEhDyzpQaUC2SIU4ZnNSug7dKGhRowMY21g_UG96il2znaOJHkKEuBQ",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000300,
      LWC_ID: 1001198954,
      Width: 720,
      Height: 960,
      IsLogo: false,
      LWCPathToThumbnail: "Travel-Money-Oz-London-Court-Arcade-mkuq-thumbnail.jpg",
      SourcePathToThumbnail: "https://lh3.googleusercontent.com/p/AF1QipOUCwaDMoOdOtxSC3PXDZkA9_NxHpmLQ_po7lhe=s1600-w100-h65",
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-mkuq-image.jpg",
      SourcePathToFullImage: "https://lh3.googleusercontent.com/p/AF1QipOUCwaDMoOdOtxSC3PXDZkA9_NxHpmLQ_po7lhe=s1600-w720-h960",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "CmRaAAAAfC1mIfQezNAb0MMq9w20GAz64IeDehAJ8ytilTIHnqPHRRIq40L7vA1w9Ngh_2wNmVcjzp20AftEuuzksRzC2v_pQHFyFNIgAk9GzHN3dqBkPHxxhkFp8knS-driFu-7EhAfBUqA4z03SUo388CbNvjkGhS3Upl79oZhmJYAqyklr6OzmVMKUQ",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000301,
      LWC_ID: 1001198954,
      Width: 600,
      Height: 800,
      IsLogo: false,
      LWCPathToThumbnail: "Travel-Money-Oz-London-Court-Arcade-hhvw-thumbnail.jpg",
      SourcePathToThumbnail: "https://lh3.googleusercontent.com/p/AF1QipOi__YuRJToLR7Je1N570KpDVH7hCFwGY52o2UT=s1600-w100-h65",
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-hhvw-image.jpg",
      SourcePathToFullImage: "https://lh3.googleusercontent.com/p/AF1QipOi__YuRJToLR7Je1N570KpDVH7hCFwGY52o2UT=s1600-w600-h800",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "CmRaAAAAa55N6BrXpB_RfQ6IQY-YMEGIv0qS0CWaL_Sz-1IMme_fqwymM91pDgE0v9cpwxSm_8bg5UdCkgPB_Vu0u1Ahz7oWqS4MN4ApOQJcgzTLhBT8Z0HWVGlsa0b_6HhojmjoEhDUbiu5smCFV0lssRxlwyxWGhRLSjkr1AUnmQkoyn7xb3NzqDHhVw",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000302,
      LWC_ID: 1001198954,
      Width: 600,
      Height: 800,
      IsLogo: false,
      LWCPathToThumbnail: "Travel-Money-Oz-London-Court-Arcade-orfg-thumbnail.jpg",
      SourcePathToThumbnail: "https://lh3.googleusercontent.com/p/AF1QipO4BXqyA7ddLhnwgknC0CLmvkslbZbJxksYG2Id=s1600-w100-h65",
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-orfg-image.jpg",
      SourcePathToFullImage: "https://lh3.googleusercontent.com/p/AF1QipO4BXqyA7ddLhnwgknC0CLmvkslbZbJxksYG2Id=s1600-w600-h800",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "CmRaAAAAi1Yor4fmRcTEPRJb9_VQZfoJVqd1qVEpYz5Ca_IXMQAeoIG8F8NJ8IKf_0Wtlukjb-poLKerYfYIvkH0zkv6W6uxKTg1wXJCbyJyMLluBiq9MIpJg7TfRPYAk6_aposeEhDA_bewznVCCx3GU0UFZYdlGhRMOhGWLJ-zf6Dvv6Y5FVVVR6ORfg",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000303,
      LWC_ID: 1001198954,
      Width: 600,
      Height: 300,
      IsLogo: false,
      LWCPathToThumbnail: "Travel-Money-Oz-London-Court-Arcade-eaaw-thumbnail.jpg",
      SourcePathToThumbnail: "https://lh3.googleusercontent.com/p/AF1QipME2Q133_-Pfqa5H48aLwUVb24HdIdav_cugTGB=s1600-w100-h65",
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-eaaw-image.jpg",
      SourcePathToFullImage: "https://lh3.googleusercontent.com/p/AF1QipME2Q133_-Pfqa5H48aLwUVb24HdIdav_cugTGB=s1600-w600-h300",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "CmRaAAAAfTJm5UnKSa5VGcZSGgoyE986cHHD8uLtdV_kn7c1AgzvNhdP9HJ_xJFgDHTnzWgVsBlUCsUVi53_hwaJZS6q5l4srqdeNPNoLWDBxgRt_KlNDUrKE7g0TFt9iQ_J-a5TEhDY7sGekyRbQT77NPY8OyhNGhQLA0ULmyAxcCAshOAt7OhQhVeAAw",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000304,
      LWC_ID: 1001198954,
      Width: 301,
      Height: 301,
      IsLogo: false,
      LWCPathToThumbnail: "Travel-Money-Oz-London-Court-Arcade-_fgg-thumbnail.jpg",
      SourcePathToThumbnail: "https://lh3.googleusercontent.com/p/AF1QipNX238yA198MD1W1j08IHYj_UQx3GH3KDA4JL1t=s1600-w100-h65",
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-_fgg-image.jpg",
      SourcePathToFullImage: "https://lh3.googleusercontent.com/p/AF1QipNX238yA198MD1W1j08IHYj_UQx3GH3KDA4JL1t=s1600-w301-h301",
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "CmRaAAAApk9iMGcnwgTaR3G3ZgvNauHcgM3opLWcybi0wKbGEc9wN3MqHJAwvmxpFmb8gAiw1lArldTBSS-18Ec0QApWToRehW6X01lriZeVTXhK4wrGq8UwIwJudWW53ueI7DzLEhD-7qkO-iIa9me_zW6dxVKtGhShmBqFUWswyK6zTtKEP8UDVe_FGg",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: false,
      LWCPathToBanner: null,
      Is3to1Logo: false,
      IsCircularLogo: false
    },
    {
      AUS_Indexed_ImageID: 4000305,
      LWC_ID: 1001198954,
      Width: 1480,
      Height: 2239,
      IsLogo: false,
      LWCPathToThumbnail: "Travel-Money-Oz-London-Court-Arcade-site-thumbnail.jpg",
      SourcePathToThumbnail: null,
      LWCPathToFullImage: "Travel-Money-Oz-London-Court-Arcade-site-image.jpg",
      SourcePathToFullImage: null,
      Source: "MerchantWebsite",
      SourceID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      SourceImageID: "",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null,
      IsWebsiteScreenshot: true,
      LWCPathToBanner: "Travel-Money-Oz-London-Court-Arcade-site-banner.jpg",
      Is3to1Logo: false,
      IsCircularLogo: false
    }
  ],
  AUS_Indexed_MerchantNames: [
    {
      MerchantNamesID: 5251673,
      BusinessNameID: 32458713,
      LWC_ID: 1001198954,
      MerchantName: "Travel Money Oz (London Court Arcade)",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      Source: "LWC_User_Data",
      SourceID: null,
      SourceDateTime: "2019-05-21T10:07:00",
      IsActive: true,
      IsActiveComment: null,
      IsPrimary: true,
      ReformattedMerchantName: "TRAVEL MONEY OZ LONDON COURT ARCADE",
      MerchantNameKeywords: "TRAVEL,MONEY,OZ,LONDON,COURT,ARCADE",
      NameAddedDuringIndexing: false,
      IsValidated: false,
      ValidatedOn: null,
      ValidatedByName: null,
      ValidatedComment: null
    }
  ],
  AUS_Indexed_PaymentMethod: [
    {
      AUS_Indexed_PaymentMethodID: 2633676,
      LWC_ID: 1001198954,
      PaymentMethod: "Visa",
      Source: "LWC_System_Data",
      SourceID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: "2019-05-21T10:17:00",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null
    },
    {
      AUS_Indexed_PaymentMethodID: 2633677,
      LWC_ID: 1001198954,
      PaymentMethod: "Mastercard",
      Source: "LWC_System_Data",
      SourceID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: "2019-05-21T10:17:00",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null
    },
    {
      AUS_Indexed_PaymentMethodID: 2633678,
      LWC_ID: 1001198954,
      PaymentMethod: "EFTPOS",
      Source: "LWC_System_Data",
      SourceID: null,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: "2019-05-21T10:17:00",
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null
    }
  ],
  AUS_Indexed_Reviews: [
    {
      AUS_Indexed_ReviewID: 379799,
      LWC_ID: 1001198954,
      Score: 5.0,
      Source: "FacebookPlace",
      SourceID: "1053515034710033",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      NumberOfReviews: 1,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null
    },
    {
      AUS_Indexed_ReviewID: 379800,
      LWC_ID: 1001198954,
      Score: 5.0,
      Source: "GooglePlaces",
      SourceID: "ChIJmautEda6MioR1x4UTbm_np8",
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: null,
      NumberOfReviews: 2,
      SourceDateTime: "2019-05-21T10:17:00",
      IsActive: true,
      IsActiveComment: null
    }
  ],
  AUS_Indexed_CardAcceptorLocators_Predicted: [
    {
      AUS_Predicted_ID: 1042600,
      LWC_ID: 1001198954,
      CreateDateTime: "2019-05-21T10:17:00",
      UpdateDateTime: "2019-05-21T10:38:00",
      CardAcceptors: "TRAVEL MONEY OZ LONDON COURT ARCADE",
      Latitude: -31.954822,
      Longitude: 115.858847,
      Suburb: "PERTH",
      State: "WA",
      Branch: null,
      IsHotel: false,
      IsCompany: false,
      IsRestaurant: false,
      IsPharmacy: false,
      Currency: "$",
      OtherKeywords: "SERVICES >> FINANCIAL SERVICES|INTERNET|FINANCE",
      PhoneNumber: "1300 459 119|1300 739 509|1300 614 717",
      IsPtyLTD: false,
      WebAddress: "www.travelmoneyoz.com"
    }
  ],
  MerchantIndexings: [
    {
      MerchantIndexingID: 2129645,
      LWC_ID: 1001198954,
      IndexingType: "NewlyIndexedMerchant",
      IndexingTrigger: "IndexingClient",
      IndexingResult: "Warning",
      IndexingResultComment: "Warning: Indexed Merchant LWC_ID: 1001198954 Name: Travel Money Oz (London Court Arcade) Score: 100 in: 38.6s; Enriching 1001198954; The chain has been set to Travel Money Oz; Facebook data has been enriched to merchant; Google data has been enriched to merchant; CAL set for TRVLMONE PERTH;  in 5s; Default category set to Services >> Financial Services; Located In Information set;",
      IndexingScore: 100,
      IndexingTimeMS: 37828,
      CreateDateTime: "2019-05-21T10:17:00",
      NameOfIndexer: "Daisy Lleno",
      IDOfIndexer: 49
    },
    {
      MerchantIndexingID: 2129647,
      LWC_ID: 1001198954,
      IndexingType: "EnrichedExistingMerchant",
      IndexingTrigger: "IndexingClient",
      IndexingResult: "Warning",
      IndexingResultComment: "Warning: 1001198954 enriched as Travel Money Oz (London Court Arcade) Score: 100 in 2s; Enriching 1001198954; Problem with Setting the CAL. Message=CAL matches with a different indexed merchant record with a high score so can't index this CAL to this merchant. CAL '479600 702200TRVLMONE PERTH WA' has been matched to Travel Money (Hay Street Mall), LWC_ID 1000327345, the distance between them is 132.348305989958 which is within the threshold of 319.014236159878. Check to see if this is a duplicate merchant; CAL=479600 702200TRVLMONE PERTH WA; Default category set to Services >> Financial Services;",
      IndexingScore: 100,
      IndexingTimeMS: 1955,
      CreateDateTime: "2019-05-21T10:36:00",
      NameOfIndexer: "Daisy Lleno",
      IDOfIndexer: 49
    },
    {
      MerchantIndexingID: 2129648,
      LWC_ID: 1001198954,
      IndexingType: "EnrichedExistingMerchant",
      IndexingTrigger: "IndexingClient",
      IndexingResult: "Warning",
      IndexingResultComment: "Warning: 1001198954 enriched as Travel Money Oz (London Court Arcade) Score: 100 in 2s; Enriching 1001198954; CAL set for 479600 702200TRVLMONE PERTH; Default category set to Services >> Financial Services; Warnings during Validation (The CAL '479600 702200TRVLMONE PERTH' seems to indicate that this is a school but the name does not contain School). Check the Validation report for more information;",
      IndexingScore: 100,
      IndexingTimeMS: 1922,
      CreateDateTime: "2019-05-21T10:38:00",
      NameOfIndexer: "Daisy Lleno",
      IDOfIndexer: 49
    }
  ],
  AUS_Indexed_MIDTID: [
    {
      TIDMID_id: 16407,
      LWC_ID: 1001198954,
      MID: "",
      TID: "73998995",
      CreateDateTime: "2019-05-21T10:38:00",
      Source: "LWC_System_Data",
      Comments: null,
      UpdateDateTime: null,
      AID: 456471,
      CardAcceptorLocatorsID: 906183,
      IsActive: true
    },
    {
      TIDMID_id: 16408,
      LWC_ID: 1001198954,
      MID: "",
      TID: "73998995",
      CreateDateTime: "2019-05-21T10:38:00",
      Source: "LWC_System_Data",
      Comments: "No existing records found so added this one",
      UpdateDateTime: null,
      AID: 1529,
      CardAcceptorLocatorsID: 906183,
      IsActive: true
    },
    {
      TIDMID_id: 16409,
      LWC_ID: 1001198954,
      MID: "",
      TID: "64939347",
      CreateDateTime: "2019-05-21T10:38:00",
      Source: "LWC_System_Data",
      Comments: "No existing records found so added this one",
      UpdateDateTime: null,
      AID: 1529,
      CardAcceptorLocatorsID: 906183,
      IsActive: true
    },
    {
      TIDMID_id: 16410,
      LWC_ID: 1001198954,
      MID: "",
      TID: "64939347",
      CreateDateTime: "2019-05-21T10:38:00",
      Source: "LWC_System_Data",
      Comments: "No existing records found so added this one",
      UpdateDateTime: null,
      AID: 456471,
      CardAcceptorLocatorsID: 906183,
      IsActive: true
    }
  ]
};
