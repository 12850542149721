import { PurgeTaskResult, PurgeResult } from "../../models/purgeresult";

export const deleteMerchantNamesResponse: PurgeResult = {
  LwcId: 123456,
  RemovedFromIndexDB: PurgeTaskResult.Failure,
  RemovedFromPredictedSearchDB: PurgeTaskResult.Failure,
  RemovedFromSuggestionSearchDB: PurgeTaskResult.Failure,
  RemovedFromNoSQLDBs: PurgeTaskResult.NotRequired,
  RemovedFromImageRepository: PurgeTaskResult.Failure,
  RemovedFromElasticSearch: PurgeTaskResult.Success,
  RemovedFromIndexSourceDB: PurgeTaskResult.Success,
  Message: 'Merchant Names message',
  Success: false
}
