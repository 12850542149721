import { ExecutionResult } from 'graphql';
import { ValidationStatsResponse, ValidationStatsByValidationResponse, ValidationStatsByIndexerResponse } from '../../components/merchantvalidationstats/merchantvalidationstats.component';
import { merchantValidationTypeStringValues, MerchantValidationTypes } from '../../enums/enums';

export let validationStatsByValidationResponse: ExecutionResult<ValidationStatsByValidationResponse> = {
  data: {
    items: [
      {
        validationId: 67,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MULTIPLE_BRANCH_IDENTIFIERS),
        count: 19008
      },
      {
        validationId: 11,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NON_ACTIVE_BUSINESS_STATUS),
        count: 8521
      },
      {
        validationId: 36,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        count: 7155
      },
      {
        validationId: 35,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_CONTACT_METHODS),
        count: 6948
      },
      {
        validationId: 61,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        count: 4201
      },
      {
        validationId: 39,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        count: 3662
      },
      {
        validationId: 42,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        count: 2981
      },
      {
        validationId: 9,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT),
        count: 2539
      },
      {
        validationId: 62,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.CARD_ACCEPTOR_TOO_SHORT),
        count: 1962
      },
      {
        validationId: 49,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.BRANCH_FRANCHISE_MULTIPLE_POSSIBLE_CHAINS),
        count: 1628
      }
    ],
    totalCount: 500
  }
};

export let validationStatsByValidationIdResponse: ExecutionResult<ValidationStatsResponse> = {
  data: {
    items: [
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.BRANCH_FRANCHISE_MULTIPLE_POSSIBLE_CHAINS),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.CARD_ACCEPTOR_TOO_SHORT),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.CONTACT_METHODS_NO_SCREENSHOT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.DUPLICATE_MERCHANT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.EMAIL_WARNING),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.GST_REGISTRATION),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.IS_BRANCH_BUT_CAL_DOESNT_MATCH_CHAIN_PATTERN),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.LOW_DATA_COMPLETENESS_SCORE_SET),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_NAME_CATEGORY_NOT_MACHED),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_NAME_CONTAIN_LOCATION),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_NAME_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_PRESENCE),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MISSING_CHAIN_LOGOS),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MOBILE_NUMBER_AS_PRIMARY),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MULTIPLE_BRANCH_IDENTIFIERS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NON_ACTIVE_BUSINESS_STATUS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_CONTACT_METHODS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },

      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.BRANCH_FRANCHISE_MULTIPLE_POSSIBLE_CHAINS),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_NAME_PROBLEM),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_NAME_CONTAIN_LOCATION),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_NAME_CATEGORY_NOT_MACHED),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.LOW_DATA_COMPLETENESS_SCORE_SET),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.IS_BRANCH_BUT_CAL_DOESNT_MATCH_CHAIN_PATTERN),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.GST_REGISTRATION),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },

      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.EMAIL_WARNING),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.DUPLICATE_MERCHANT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.CONTACT_METHODS_NO_SCREENSHOT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.CARD_ACCEPTOR_TOO_SHORT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.BRANCH_FRANCHISE_MULTIPLE_POSSIBLE_CHAINS),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },

      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },

      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },

      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_PRIMARY_NUMBER_SET),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_CONTACT_METHODS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_CONTACT_METHODS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_CONTACT_METHODS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_CONTACT_METHODS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_CONTACT_METHODS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },

      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NO_ACTIVE_CATEGORIES),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NON_ACTIVE_BUSINESS_STATUS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NON_ACTIVE_BUSINESS_STATUS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.NON_ACTIVE_BUSINESS_STATUS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MULTIPLE_BRANCH_IDENTIFIERS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },

      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MULTIPLE_BRANCH_IDENTIFIERS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MOBILE_NUMBER_AS_PRIMARY),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MOBILE_NUMBER_AS_PRIMARY),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_PRESENCE),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_PRESENCE),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_PRESENCE),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MERCHANT_NAME_PROBLEM),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      }
    ],
    totalCount: 500
  }
};

export let validationStatsByIndexerResponse: ExecutionResult<ValidationStatsByIndexerResponse> = {
  data: {
    items: [
      {
        indexerName: "Cheryl Barba",
        indexerId: 23,
        count: 23767
      },
      {
        indexerName: "Marivic Torres",
        indexerId: 42,
        count: 23752
      },
      {
        indexerName: "Kathreen Martinez",
        indexerId: 53,
        count: 19915
      },
      {
        indexerName: "Mary Pangan",
        indexerId: 54,
        count: 16014
      },
      {
        indexerName: "Edrian San Felipe",
        indexerId: 74,
        count: 7744
      },
    ],
    totalCount: 500
  }
};

export let allValidationStatsDetailResponse: ExecutionResult<ValidationStatsResponse> = {
  data: {
    items: [
      {
        validationId: null,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.CARD_ACCEPTOR_TOO_SHORT),
        comment: "The CAL 'PARADISE POINT9597956PARADISE POIN04 AU' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-09-01T06:51:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
      {
        validationId: 58,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MOBILE_NUMBER_AS_PRIMARY),
        comment: "The CAL 'REDFERN CONVENIENCE ST' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T04:43:00.000+10:00",
        updatedDate: "2021-08-24T23:49:00.000+10:00",
        warningError: "Warning"
      },
      {
        validationId: 20,
        validation: merchantValidationTypeStringValues.get(MerchantValidationTypes.MISSING_CHAIN_LOGOS),
        comment: "The CAL 'DUNSBOROUGH D5206396 DUNSBOROUGH WA' seems to have a card acceptor and locator which is the same. This causes problems with the search engine. Please check that they are correct CALs",
        overriddenComment: null,
        isOverridden: false,
        nameOfIndexer: null,
        createdDate: "2021-08-23T02:56:00.000+10:00",
        updatedDate: null,
        warningError: "Warning"
      },
    ],
    totalCount: 12
  }
}
