export interface MerchantNamesResponse {
  MerchantName: string;
  IsPrimary: boolean;
  Source: string;
  Suburb: string;
  State: string;
  PostCode: string;
}

export interface MerchantWordSuggestionResponse {
  OriginalMerchantName: string;
  Query: string;
  MerchantWordOperations: MerchantWordOperation[];
}

export interface MerchantWordOperation {
  Word: string;
  Operation: EnumMerchantWordOperations;
}

export enum EnumMerchantWordOperations {
  Optional = 0,
  Must = 1
}

export interface MerchantNameSearchResponse {
  FixedCal: string;
  CountOfOptionalWords: number;
}
