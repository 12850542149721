import { CALStatuses, ODataFilterType, MatchConfidence, ChainDensity, LookupType, APIClients, LookupOptionType } from '../../enums/enums';
import { NameValue } from '../models/namevalue';
import { OptionEntry } from '@oasisdigital/angular-material-search-select';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

export let ChainTypes = {
  headOffice: 'HeadOffice',
  branch: 'Branch',
  franchise: 'Franchise',
  notAChain: 'NotAChain',
  unknown: 'Unknown',
  both: 'Both'
};

export let calStatusesType: NameValue<CALStatuses>[] = [
  {value: CALStatuses.NeedsVerifying, name: 'Needs Verifying'},
  {value: CALStatuses.NeedsReVerifying, name: 'Needs ReVerifying'},
  {value: CALStatuses.BeingAutoIndexed, name: 'Being Auto Indexed'},
  {value: CALStatuses.LockedByIndexer, name: 'Locked By Indexer'},
  {value: CALStatuses.FailedReview, name: 'Failed Review'},
  {value: CALStatuses.Unallocated, name: 'Unallocated'},
  {value: CALStatuses.Indexed, name: 'Indexed'},
  {value: CALStatuses.InternationalCAL, name: 'International CAL'},
  {value: CALStatuses.Verified, name: 'Verified'},
  {value: CALStatuses.SharedCAL, name: 'Shared CAL'},
  {value: CALStatuses.BankMessage, name: 'Bank Message'},
  {value: CALStatuses.Purged, name: 'Purged'},
  {value: CALStatuses.TrickyCAL, name: 'Tricky CAL'},
  {value: CALStatuses.Allocated, name: 'Allocated'}
];

export let equalsStartsContainsFilterTypes: NameValue<ODataFilterType>[] = [
  {value: ODataFilterType.Equals, name: 'Equals'},
  {value: ODataFilterType.Contains, name: 'Contains'},
  {value: ODataFilterType.StartsWith, name: 'StartsWith'}
];

export let equalsStartsContainsFilterTypesGraphQLValues = new Map<string, string>([
  ["Equals", "eq"],
  ["Contains", "contains"],
  ["StartsWith", "startsWith"],
]);

export let RouteConstants = {
  login: 'login',
  forgotPassword: 'forgotpassword',
  resetPassword: 'reset-password',
  editChain: 'editchain',
  manageChains: 'managechains',
  editInternationalChain: 'editinternationalchain',
  manageInternationalChains: 'manageinternationalchains',
  editMerchantEvent: 'editmerchantevent',
  merchantEvents: 'merchantevents',
  editApiClient: 'editapiclient',
  apiClients: 'apiclients',
  categoryStandardiser: 'categorystandardiser',
  manageLocations: 'managelocations',
  editLocation: 'editlocation',
  managedatascraping: 'managedatascraping',
  scrapingDataSources: 'scrapingdatasources',
  scrapedData: 'scrapeddata',
  merchantlogo: 'merchantlogo',
  editCategoryMappingRule: 'editcategorymappingrule',
  categoryMappingRules: 'categorymappingrules',
  groupOfSimilarCals: 'groupofsimilarcals',
  cardAcceptorLocators: 'cardacceptorlocators',
  mergeMerchants: 'mergemerchants'
};

export let unsavedChangesMessage = 'Do you want to leave changes unsaved?';

export let matchConfidenceNames: NameValue<MatchConfidence>[] = [
  { value: MatchConfidence.High, name: 'High' },
  { value: MatchConfidence.Medium, name: 'Medium' },
  { value: MatchConfidence.Low, name: 'Low' }
];

export let chainDensityNames: NameValue<ChainDensity>[] = [
  { value: ChainDensity.Low, name: 'Low' },
  { value: ChainDensity.Medium, name: 'Medium' },
  { value: ChainDensity.High, name: 'High' },
  { value: ChainDensity.VeryHigh, name: 'VeryHigh' }
];

export let lookupOptionTypeStringValues = new Map<LookupOptionType, string>([
  [LookupOptionType.TransactionDescriptionLookupOption, 'TransactionDescriptionLookupOption'],
  [LookupOptionType.ChainNameLookupOption, 'ChainNameLookupOption']
]);

export let lookupTypeMap = new Map<LookupType, string>([
  [LookupType.Contains, 'Contains'],
  [LookupType.ContainsAll, 'ContainsAll'],
  [LookupType.MustInclude, 'MustInclude'],
  [LookupType.NotIncluded, 'NotIncluded'],
  [LookupType.StartsWith, 'StartsWith'],
  [LookupType.StartsWithEndsWith, 'StartsWithEndsWith'],
  [LookupType.StartsWithAllowNoSpaces, 'StartsWithAllowNoSpaces'],
  [LookupType.WithinText, 'WithinText'],
  [LookupType.WholeText, 'WholeText'],
  [LookupType.StartsWithEndsWithAllowNoSpaces, 'StartsWithEndsWithAllowNoSpaces'],
  [LookupType.WithinTextMultiple, 'WithinTextMultiple'],
  [LookupType.ContainsRegex, 'ContainsRegex'],
  [LookupType.Custom, 'Custom'],
  [LookupType.EndsWith, "EndsWith"]
]);

export let apiClientNames: NameValue<APIClients>[] = [
  { value: APIClients.LWCWebsite, name: 'LWCWebsite' },
  { value: APIClients.NAB, name: 'NAB' },
  { value: APIClients.ANZ, name: 'ANZ' },
  { value: APIClients.CBA, name: 'CBA' },
];

export let emptyOptionEntry: OptionEntry = {
  value: '',
  display: '',
  details: ''
}

export const chartDefaultColorScheme = [
  '#ff8a80',
  '#82b1ff',
  '#ccff90',
  '#ffe57f',
  '#ff80ab',
  '#80d8ff',
  '#ffd180',
  '#ea80fc',
  '#84ffff',
  '#f4ff81'
]

export const commaSpace: string = ", ";

export const comma: string = ",";

export const caret: string = "^";

export const emptyString: string = "";

export const questionMark: string = "?";

export const whiteSpace: string = " ";

export const slash: string = "/";

export const timePickerTheme: NgxMaterialTimepickerTheme = {
  container: {
    bodyBackgroundColor: '#fff',
    buttonColor: '#3f51b5'
  },
  dial: {
    dialBackgroundColor: '#3f51b5',
  },
  clockFace: {
    clockFaceBackgroundColor: '#fff',
    clockHandColor: '#3f51b5',
    clockFaceTimeInactiveColor: '#3f51b5'
  }
};

export const personalDetailsRemovedMessage: string = "[Personal Details Removed]";

export const limitOnTheNumberOfExportedRecords: string = "Too many records to export. Please limit the date range to reduce the number of records.";

export const positiveNumberWithPlusInStart = new RegExp(/^\+\d+$/);

export let tickIcon: string = "✓";

export let percentIcon: string = "%";

export let redCloseIcon: string = "❌";
