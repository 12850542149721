import { ExecutionResult } from "graphql";
import { ChainAudit } from "../../components/managechains/chainaudit/chainauditdto";

export let chainAuditResponse: ExecutionResult<ChainAudit[]> = {
  data: [
    {
      chainName: 'iFLY',
      operationType: 'Edit',
      operationTrigger: 'IndexManagerClient',
      createDateTime: '2008-12-27 19:53:20.297',
      nameOfIndexer: 'Peter',
      patternsAudit: [
        {
          patternType: 'ChainNameLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWith'
        },
        {
          patternType: 'TransactionDescriptionLookupOptions',
          searchString: 'IFLY',
          lookupType: 'StartsWith'
        }
      ]
    },
    {
      chainName: 'iFLY',
      operationType: 'Add',
      operationTrigger: 'IndexManagerClient',
      createDateTime: '2019-11-01 01:27:04.347',
      nameOfIndexer: 'Oliver',
      patternsAudit: [
        {
          patternType: 'TransactionDescriptionLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWith'
        },
        {
          patternType: 'ChainNameLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWith'
        }
      ]
    },
    {
      chainName: 'Just Jeans',
      operationType: 'Remove',
      operationTrigger: 'IndexManagerClient',
      createDateTime: '2010-10-02 05:21:33.100',
      nameOfIndexer: 'LWC System',
      patternsAudit: [
        {
          patternType: 'TransactionDescriptionLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWithEndsWithAllowNoSpaces'
        },
        {
          patternType: 'ChainNameLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWith'
        }
      ]
    },
    {
      chainName: 'Direct Chemist Outlet',
      operationType: 'Cancel',
      operationTrigger: 'IndexManagerClient',
      createDateTime: '2002-10-02 04:18:25.100',
      nameOfIndexer: 'LWC System',
      patternsAudit: [
        {
          patternType: 'TransactionDescriptionLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWithEndsWithAllowNoSpaces'
        },
        {
          patternType: 'ChainNameLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWith'
        }
      ]
    },
    {
      chainName: 'KFC',
      operationType: 'Add',
      operationTrigger: 'IndexManagerClient',
      createDateTime: '2002-09-02 04:18:25.100',
      nameOfIndexer: 'LWC System',
      patternsAudit: [
        {
          patternType: 'TransactionDescriptionLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWithEndsWithAllowNoSpaces'
        },
        {
          patternType: 'ChainNameLookupOptions',
          searchString: 'iFLY',
          lookupType: 'StartsWith'
        }
      ]
    }
  ]
};
