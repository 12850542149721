import { HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { HttpMethod } from "../../core/coreenums/coreenums";
import { blobCacheSourceTypesResponse, blobCacheStatusResponse } from "../mockjsonresponses/cacherefresh.response";

export function cacheRefreshBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesBlobCacheSourceTypes = false;
  if (request.body && request.body.query) {
    includesBlobCacheSourceTypes = request.body.query.includes('blobCacheSourceTypes');
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesBlobCacheSourceTypes) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...blobCacheSourceTypesResponse }
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.blobCache) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: blobCacheStatusResponse
      }));
      resp.complete();
    });
  }
}
