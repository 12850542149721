import { Tag } from "../../components/tags/tags.models";

export let tagsResponse: Tag[] = [
  {
    id: 1000603540,
    tagLabel: 'Buy Now Pay Later',
    apiClientTag: [],
    description: "Test"
  },
  {
    id: 1001102450,
    tagLabel: 'Pay Starter',
    apiClientTag: [
      {
        apiClientId: 12,
        tagId: 7
      }
    ],
    description: "Test 1"
  },
  {
    id: 1000603090,
    tagLabel: 'Accent Day',
    apiClientTag: [
      {
        apiClientId: 1,
        tagId: 7
      },
      {
        apiClientId: 5,
        tagId: 87
      }
    ],
    description: "Test 2"
  },
  {
    id: 1001105450,
    tagLabel: 'Fridaysio',
    apiClientTag: [
      {
        apiClientId: 15,
        tagId: 7
      },
      {
        apiClientId: 5,
        tagId: 7
      },
      {
        apiClientId: 1,
        tagId: 7
      }
    ]
  },
  {
    id: 1000605190,
    tagLabel: 'Light Coding',
    apiClientTag: [
      {
        apiClientId: 1,
        tagId: 7
      },
      {
        apiClientId: 5,
        tagId: 7
      }
    ]
  },
  {
    id: 1000005000,
    tagLabel: 'Pay Day Loans',
    apiClientTag: [
      {
        apiClientId: 12,
        tagId: 7
      },
      {
        apiClientId: 4,
        tagId: 74
      },
      {
        apiClientId: 8,
        tagId: 78
      }
    ]
  },
  {
    id: 1000003230,
    tagLabel: 'Short Term Rental',
    apiClientTag: [
      {
        apiClientId: 12,
        tagId: 13
      }
    ]
  },
  {
    id: 1003455000,
    tagLabel: 'Third Party Payroll Services',
    apiClientTag: [
      {
        apiClientId: 8,
        tagId: 23
      }
    ]
  },
  {
    id: 1003495000,
    tagLabel: 'Self Managed Super Fund',
    apiClientTag: [
      {
        apiClientId: 1,
        tagId: 23
      }
    ]
  },
  {
    id: 1003855000,
    tagLabel: 'Workers Compensation',
    apiClientTag: [
      {
        apiClientId: 1,
        tagId: 253
      }
    ]
  },
  {
    id: 1000455000,
    tagLabel: 'Subscription',
    apiClientTag: [
      {
        apiClientId: 5,
        tagId: 323
      }
    ]
  },
]
