import { Injectable } from '@angular/core';


@Injectable()
export class CalService {
  constructor() { }

  getReformatedCal(cal: string): string {
    if (!cal) {
      return cal;
    }

    cal = cal.replace(/^['"]/, '');
    cal = cal.replace(/['"]$/, '');
    cal = cal.replace('É', 'E');
    cal = cal.trim().toUpperCase();
    cal = cal.replace(/\s\s+/g, ' ');
    
    return cal;
  }
}
