import { Directive, ElementRef, HostListener, Input } from '@angular/core';

declare type NumberType = 'positiveNumber' | 'currency' | 'positiveAndNegativeNumber';
const positiveNumberRegex = new RegExp(/^[0-9]+$/);
const currencyRegex = new RegExp(/^\d+[.]?\d{0,2}$/g);
const positiveAndNegativeNumberRegex = new RegExp(/^-$|^-?\d+[.]?\d{0,2}$/g);

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  private regex: RegExp;
  private specialKeys: Array<string> = ['Enter', 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Shift', 'Control'];
  @Input('appNumberOnly') inputType: NumberType;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    switch (this.inputType) {
      case 'currency':
        this.regex = currencyRegex;
        break;
      case 'positiveNumber':
        this.regex = positiveNumberRegex;
        break;
      case 'positiveAndNegativeNumber':
        this.regex = positiveAndNegativeNumberRegex;
        break;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    if (this.specialKeys.indexOf(event.key) !== -1 || event.ctrlKey) {
      return;
    }

    let currentСharacter: string = this.elementRef.nativeElement.value;
    let inputCharacter: string = currentСharacter.concat(event.key);

    if (inputCharacter && !String(inputCharacter).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: unknown) {
    // older browsers fix
    let clipboardEvent = <ClipboardEvent>event;

    if (clipboardEvent.clipboardData) {
      let content = clipboardEvent.clipboardData.getData('text/plain');
      let targetValue: string = (<HTMLInputElement>clipboardEvent.target).value;
      let inputValue = targetValue.concat(content);

      if (!inputValue.match(this.regex)) {
        clipboardEvent.preventDefault();
      }
    }
  }
}
