import { CategoryType } from "../../enums/enums";
import { InternationalChainDto } from "../../models/internationalchaindto";

export let internationalChainResponse: InternationalChainDto[] = [
    {
        ChainNameLookupOptions: [],
        Country: 'China',
        DefaultUrl: 'http://payeasenet.com',
        Description: '',
        InternationalChainName: 'PayEase',
        LWC_Guid: '4ead3b7e-ed00-4747-aa48-80306b1fa40e',
        LastUpdated: '',
        Location: 'China and Santa Clara, California',
        StandardCategory: {
            CategoryLevel: 1,
            CategoryID: 2,
            IsSensitive: false,
            CategoryName: 'Grocery',
            CategoryType: CategoryType.Expense,
            EmojiUnicode: 'U+1F34E',
            MatchPriority: 1
        },
        TransactionDescriptionLookupOptions: []
    },
    {
        ChainNameLookupOptions: [],
        Country: 'Israel',
        DefaultUrl: 'http://wix.com',
        Description: '',
        InternationalChainName: 'Wix.com',
        LWC_Guid: '114e88f2-7e06-4461-a20b-1e0e2226b66c',
        LastUpdated: '',
        Location: 'Tel Aviv, Israel',
        StandardCategory: {
            CategoryLevel: 2,
            CategoryID: 2002,
            IsSensitive: false,
            CategoryName: 'Food Market',
            CategoryType: CategoryType.Expense,
            EmojiUnicode: 'U+1F349',
            MatchPriority: 1
        },
        TransactionDescriptionLookupOptions: []
    },
    {
        ChainNameLookupOptions: [],
        Country: 'USA',
        DefaultUrl: 'https://sitelock.com',
        Description: '',
        InternationalChainName: 'sitelock.com',
        LWC_Guid: '5e111817-280d-4222-9ded-382be681af38',
        LastUpdated: '',
        Location: '8701 East Hartford Drive, Scottsdale, AZ 85255 USA',
        StandardCategory: {
            CategoryLevel: 2,
            CategoryID: 1002,
            IsSensitive: false,
            CategoryName: 'Toys/Hobby',
            CategoryType: CategoryType.Expense,
            EmojiUnicode: 'U+1F3B2',
            MatchPriority: 1
        },
        TransactionDescriptionLookupOptions: []
    },
    {
        ChainNameLookupOptions: [],
        Country: 'USA',
        DefaultUrl: 'https://zinio.com',
        Description: '',
        InternationalChainName: 'ZINIO',
        LWC_Guid: 'c0a3e23a-1ffd-4f18-b8f5-1b3e289e679a',
        LastUpdated: '',
        Location: '575 Lexington Ave, 17th Floor, New York, NY 10022 USA',
        StandardCategory: {
            CategoryLevel: 3,
            CategoryID: 1002004,
            IsSensitive: false,
            CategoryName: 'Art/Craft',
            CategoryType: CategoryType.Expense,
            EmojiUnicode: 'U+1F3A8',
            MatchPriority: 1
        },
        TransactionDescriptionLookupOptions: []
    },
    {
        ChainNameLookupOptions: [],
        Country: 'USA',
        DefaultUrl: 'https://2co.com',
        Description: '',
        InternationalChainName: 'ZCheckout.com',
        LWC_Guid: '4cdebdf2-bf00-4737-a752-89c2e44b9f44',
        LastUpdated: '',
        Location: '855 Grandview Avenue, Columbus OH 43215 USA',
        StandardCategory: {
            CategoryLevel: 1,
            CategoryID: 2,
            IsSensitive: false,
            CategoryName: 'Grocery',
            CategoryType: CategoryType.Expense,
            EmojiUnicode: 'U+1F34E',
            MatchPriority: 1
        },
        TransactionDescriptionLookupOptions: []
    },
    {
        ChainNameLookupOptions: [],
        Country: 'UK',
        DefaultUrl: 'http://zinio.com',
        Description: '',
        InternationalChainName: 'moshimonsters.com',
        LWC_Guid: '380041d5-d638-47b2-98cc-ac53b87d6003',
        LastUpdated: '',
        Location: '15 Bonhill Street, London EC2A 4DN, UK',
        StandardCategory: {
            CategoryLevel: 2,
            CategoryID: 3002,
            IsSensitive: false,
            CategoryName: 'Asian',
            CategoryType: CategoryType.Expense,
            EmojiUnicode: 'U+1F962',
            MatchPriority: 1
        },
        TransactionDescriptionLookupOptions: []
    },
    {
        ChainNameLookupOptions: [{
            SearchString: 'Regional Express',
            MatchConfidence: 0,
            LookupType: 0,
            LastUpdated: '2018-07-05T22:15:03.120'
        }],
        Country: 'China',
        DefaultUrl: 'http://freetradepay.com',
        Description: 'Some description Some description Some description Some description Some description Some description Some description Some description Some description Some description',
        InternationalChainName: 'Shenzhen FHTPay Information Network Co, Limited',
        LWC_Guid: 'b9bcb960-3bbc-4742-a295-4a49c889dd24',
        LastUpdated: '',
        Location: '2401D of Bak Technology Building, No.9 Keyan Road, Science and Technology Park Nanshan District, Shenzhen City, China',
        StandardCategory: {
            CategoryLevel: 3,
            CategoryID: 1002004,
            IsSensitive: false,
            CategoryName: 'Art/Craft',
            CategoryType: CategoryType.Expense,
            EmojiUnicode: 'U+1F3A8',
            MatchPriority: 1
        },
        TransactionDescriptionLookupOptions: [{
            SearchString: 'REGIONAL EXPRESS',
            MatchConfidence: 1,
            LookupType: 0,
            LastUpdated: '2018-07-05T22:15:03.120'
        },
        {
            SearchString: 'REGIONAL EXP',
            MatchConfidence: 0,
            LookupType: 1,
            LastUpdated: '2018-07-05T22:15:03.120'
        },
        {
            SearchString: 'REGIONAL EXPRE',
            MatchConfidence: 2,
            LookupType: 2,
            LastUpdated: '2018-07-05T22:15:03.120'
        },
        {
            SearchString: 'REGIONAL EXPRES',
            MatchConfidence: 1,
            LookupType: 1,
            LastUpdated: '2018-07-05T22:15:03.120'
        }]
    }
]