import { Injectable } from "@angular/core";

@Injectable()
export class QuillEditorService {
  constructor() { }

  isEmpty(text: string, html: string) {
    return (!text || !text.match(/\S+/g)) && (!html || !html.match(/jpg|gif|png|image|src|img|(http(s?):)+/g));
  }
}
