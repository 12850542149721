import { UserService } from './../../users/applicationuser.service';
import { whiteSpace, emptyString } from "./../../core/constants/constants";
import { Component, ElementRef, Injector, OnInit, ViewChild } from "@angular/core";
import { BaseStateComponent } from "../basestate/basestate.component";
import { CategoryStandardiserState } from "./categorystandardiser.state";
import { LoadingService } from "../../core/uiservices/loading.service";
import { CategoryStandardiserDraft, CategoryStandardiserDraftDisplay, CategoryStandardiserTree, CategoryUpdateToDraft } from "./categorystandardiserdto";
import { finalize, map, startWith } from "rxjs/operators";
import { CategoryStandardiserService } from "./categorystandardiserservice";
import { CategoryService } from "../../services/category.service";
import { AuthService } from '../../services/auth.service';
import { RoleType } from '../../users/userenums';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { ApplicationUser } from '../../users/applicationuser';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { CategoryTreeService } from '../categorytrees/categorytree.service';
import { CategoryFlatNode, CategoryStandardiser, CategoryTreeConfig, Standardiser } from '../categorytrees/categorytrees.models';
import { FormControl } from '@angular/forms';
import { CategoryMappingRulesService } from '../../services/categorymappingrules.service';
import { Observable } from 'rxjs';
import { NotificationService } from '../../core/uiservices/notification.service';
import { ExternalCategory } from '../categorymappingrules/categorymapping.models';
import { CategoryType } from '../../enums/enums';
import { SelectedCategory } from '../../shared/category/selectedcategory';
import { cloneDeep } from 'lodash';
import { propertyOf } from '../../core/services/reflection.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { CategoryStandardiserCsv } from '../../models/categorystandardiser';

@Component({
  selector: 'app-categorystandardiser',
  templateUrl: './categorystandardiser.component.html',
  styleUrls: ['./categorystandardiser.component.scss']
})
export class CategoryStandardiserComponent extends BaseStateComponent<CategoryStandardiserState> implements OnInit {
  externalCategories: string[];
  categoriesStandardiserDrafts: CategoryStandardiserDraftDisplay[] = [];
  updateOrRejectCategoryStandardiserDrafts: CategoryStandardiserDraftDisplay[] = [];
  updateOrRejectCategoryStandardiserDraftsResponse: string[] = [];
  isAdminRole = this.authService.hasRole(RoleType[RoleType.IndexManagerAdmin]);
  columnMode = ColumnMode;
  selectionType = SelectionType;
  categoryStandardiserTree: CategoryStandardiserTree;
  dataSource: string;
  chooseVersion: boolean = true;
  categoryTreeConfig: CategoryTreeConfig;
  standardiserCategoryControl = new FormControl();
  filteredExternalCategories: Observable<ExternalCategory[]>;
  selectedNodeStandardisers = new Map<Standardiser, boolean>();
  categoryTypes: CategoryType[] = [];
  categoryStandardiser: CategoryStandardiser;
  expandedLwcTree: boolean = false;
  expandedExternalTree: boolean = false;
  externalCategoryName: string = emptyString;

  selectedDataSource: boolean;

  private selectExternalCategories: ExternalCategory[] = [];
  private isExternalCategorySelected: boolean = false;
  private selectedLwcCategoryId: number;
  private lwcCategoriesStandardiserTreeOriginal: CategoryStandardiser[] = [];
  private externalCategoriesStandardiserTreeOriginal: CategoryStandardiser[] = [];
  private dummyStandardiser: Partial<Standardiser>;
  private selectedCategoryName: string = emptyString;

  datatableHeaders = {
    l1CategoryName: "L1CategoryName",
    l2CategoryName: "L2CategoryName",
    l3CategoryName: "L3CategoryName",
    lWCCategoryID: "LWCCategoryID",
    externalCategoryName: "ExternalCategoryName",
    externalCategoryId: "ExternalCategoryId"
  };
  datatableKeys = {
    l1CategoryName: propertyOf<CategoryStandardiserCsv>('L1CategoryName'),
    l2CategoryName: propertyOf<CategoryStandardiserCsv>('L2CategoryName'),
    l3CategoryName: propertyOf<CategoryStandardiserCsv>('L3CategoryName'),
    lWCCategoryID: propertyOf<CategoryStandardiserCsv>('LWCCategoryID'),
    externalCategoryName: propertyOf<CategoryStandardiserCsv>('ExternalCategoryName'),
    externalCategoryId: propertyOf<CategoryStandardiserCsv>('ExternalCategoryId')

  };
  private optionDatatableHeadersAndKeys = {
    headers: [
      this.datatableHeaders.l1CategoryName,
      this.datatableHeaders.l2CategoryName,
      this.datatableHeaders.l3CategoryName,
      this.datatableHeaders.lWCCategoryID,
      this.datatableHeaders.externalCategoryName,
      this.datatableHeaders.externalCategoryId
    ],
    keys: [
      this.datatableKeys.l1CategoryName,
      this.datatableKeys.l2CategoryName,
      this.datatableKeys.l3CategoryName,
      this.datatableKeys.lWCCategoryID,
      this.datatableKeys.externalCategoryName,
      this.datatableKeys.externalCategoryId
    ]
  };
  private csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionDatatableHeadersAndKeys };
  @ViewChild('csvExport') csvExportComponent: CsvExportComponent;
  @ViewChild('informationContainer') informationContainer: ElementRef;
  @ViewChild('treeContainer') treeContainer: ElementRef;


  constructor(injector: Injector, private loadingService: LoadingService,
    private categoryStandardiserService: CategoryStandardiserService,
    private categoryService: CategoryService, private authService: AuthService,
    private dateTimeService: DateTimeService, private userServise: UserService,
    private valueCheckerService: ValueCheckerService, private categoriesTreeService: CategoryTreeService,
    private categoryMappingRuleService: CategoryMappingRulesService, private notificationService: NotificationService,
    private csvComponentService: CsvComponentService) {
    super(injector)
    this.selectedDataSource = false;
  }

  ngOnInit() {
    this.state = {
      categoryDataSource: null
    }
    this.restoreState();

    this.setCategoryStandardiserActiveDrafts();
    this.setExternalCategoriesDataSources();
  }

  updateOrRejectButtonClicked(isUpdate: boolean) {
    if (this.updateOrRejectCategoryStandardiserDrafts && this.updateOrRejectCategoryStandardiserDrafts.length > 0) {
      let categoryStandardiserDrafts: Partial<CategoryStandardiserDraft>[] = [];

      for (let categoryStandardiserDraft of this.updateOrRejectCategoryStandardiserDrafts) {
        let object: Partial<CategoryStandardiserDraft> = {
          Id: categoryStandardiserDraft.Id,
          CategoryStandardiserID: categoryStandardiserDraft.CategoryStandardiserID,
          CategoryDataSource: categoryStandardiserDraft.CategoryDataSource,
          CategoryDataSourceType: categoryStandardiserDraft.CategoryDataSourceType,
          LWCCategoryID: categoryStandardiserDraft.LWCCategoryID,
          ExternalCategoryType: categoryStandardiserDraft.ExternalCategoryType,
          OperationType: categoryStandardiserDraft.OperationType,
          IsActive: categoryStandardiserDraft.IsActive
        }
        categoryStandardiserDrafts.push(object);
      }

      let observable = isUpdate === true
        ? this.categoryService.updateCategoryStandardiserDrafts(categoryStandardiserDrafts)
        : this.categoryService.rejectCategoryStandardiserDrafts(categoryStandardiserDrafts);
      this.updateOrRejectCategoryStandardiserDrafts = [];

      observable.subscribe(response => {
        if (response) {
          this.updateOrRejectCategoryStandardiserDraftsResponse = response;
        }
        this.setCategoryStandardiserActiveDrafts();
        this.setCategoryStandardiserTree();
      });

    }
  }

  selectCategoryStandardiserDrafts(selected: { selected: CategoryStandardiserDraftDisplay[] }) {
    this.updateOrRejectCategoryStandardiserDrafts = [...selected.selected];
  }

  setCategoryStandardiserTree() {
    if (this.dataSource) {
      this.selectedDataSource = true;
      this.loadingService.setLoading();
      this.categoryStandardiserService.getCategoryStandardiserTree(this.dataSource, this.chooseVersion)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          this.categoryTreeConfig = {
            getIconTypeFunc: this.categoriesTreeService.getTypeOfIconForStandardiserCategory,
            filterFunc: this.categoriesTreeService.filterStandardiserCategories,
          }
          this.categoryStandardiserTree = response;
          this.lwcCategoriesStandardiserTreeOriginal = cloneDeep(response?.LWCCategories);
          this.externalCategoriesStandardiserTreeOriginal = cloneDeep(response?.ExternalCategories);
          this.clearInputCategoryName();
          this.setExternalCategories();
        });
    }
  }

  showInformationFromLwcCategory(event: CategoryFlatNode) {
    this.keepMiddleSectionVisible();
    this.clearExternalCategory();
    this.categoryStandardiser = event.category;

    this.clearInputCategoryName();
    this.setSelectStandardiser();
  }

  showInformationFromExternalCategory(event: CategoryFlatNode) {
    this.selectedLwcCategoryId = null;
    let isEdit: boolean = false;
    let selectStandardiser: Standardiser;
    this.keepMiddleSectionVisible();

    if (this.categoryStandardiser) {
      selectStandardiser = this.categoryStandardiser.Standardisers.find(Boolean) || this.dummyStandardiser as Standardiser;
      isEdit = this.selectedNodeStandardisers.get(selectStandardiser);
    }

    if (this.categoryStandardiser?.IsLWC && isEdit) {
      let externalCategory = this.selectExternalCategories.find(dataSource => dataSource.CategoryId === event.category.CategoryId);
      this.standardiserCategoryControl.setValue(externalCategory);
      this.isExternalCategorySelected = true;
    } else {
      this.clearInputCategoryName();
      this.categoryStandardiser = event.category;
      this.setSelectStandardiser();
    }
    this.setExternalStandardiserCategoryName();
  }

  getLableForCategory(standardiser: Standardiser, isFirst: boolean, onlyLwcOrExternalName: boolean = false) {
    let LwcLabel = onlyLwcOrExternalName ? "LWC: " : standardiser.LWCCategoryID + whiteSpace + standardiser.LWCCategoryName;
    let ExternalLabel = onlyLwcOrExternalName ? "External: " : standardiser.ExternalCategoryType + whiteSpace + standardiser.CategoryDataSourceType;

    if (this.categoryStandardiser?.IsLWC) {
      if (isFirst) {
        return LwcLabel
      } else {
        return ExternalLabel;
      }
    } else {
      if (isFirst) {
        return ExternalLabel;
      } else {
        return LwcLabel;
      }
    }
  }

  deleteCategoryStandardiserDeleteToDraft(node: Standardiser) {
    let deleteToDraft: CategoryUpdateToDraft = {
      Id: node.Id,
      CategoryStandardiserID: node.CategoryStandardiserID,
      CategoryDataSource: node.CategoryDataSource,
      CategoryDataSourceType: node.CategoryDataSourceType,
      LWCCategoryID: node.LWCCategoryID,
      ExternalCategoryType: node.ExternalCategoryType,
      IsActive: node.IsActive,
      LWCCategoryName: node.LWCCategoryName,
    };

    this.loadingService.setLoading();
    this.categoryStandardiserService
      .deleteCategoryStandardiserDeleteToDraft(deleteToDraft)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        if (response) {
          this.notificationService.notifySuccess(response);
          this.selectedNodeStandardisers.delete(node);
          this.clearExternalCategory();
        }
        this.setCategoryStandardiserActiveDrafts();
      });
  }

  createCategoryStandardiserUpdateToDraft(node: Standardiser) {
    let standardiserCategory = this.standardiserCategoryControl.value;
    if (this.selectedLwcCategoryId || standardiserCategory) {
      let updateToDraft: CategoryUpdateToDraft = {
        Id: node.Id,
        CategoryStandardiserID: node.CategoryStandardiserID,
        CategoryDataSource: node.CategoryDataSource,
        CategoryDataSourceType: node.CategoryDataSourceType,
        LWCCategoryID: this.selectedLwcCategoryId,
        ExternalCategoryType: node.ExternalCategoryType,
        IsActive: node.IsActive,
        LWCCategoryName: this.selectedCategoryName || node.LWCCategoryName,
      }

      if (this.categoryStandardiser.IsLWC) {
        updateToDraft.LWCCategoryID = node.LWCCategoryID;
        updateToDraft.CategoryDataSource = standardiserCategory.CategoryDataSource;
        updateToDraft.ExternalCategoryType = standardiserCategory.CategoryId;
        updateToDraft.CategoryDataSourceType = standardiserCategory.CategoryName;
      }

      this.selectedCategoryName = null;
      this.isExternalCategorySelected = false;
      this.selectedLwcCategoryId = null;
      this.loadingService.setLoading();
      this.categoryStandardiserService
        .createCategoryStandardiserUpdateToDraft(updateToDraft)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((response) => {
          if (response) {
            this.notificationService.notifySuccess(response);
            this.standardiserCategoryControl.reset();
            this.selectedNodeStandardisers.delete(node);
            this.setExternalStandardiserCategoryName();
          }
          this.setCategoryStandardiserActiveDrafts();
          this.setCategoryStandardiserTree();
        });
    }
    else {
      this.switchIsEdit(node, false);
    }
  }

  downloadCsvFile() {
    let data: any = [];
    let standard: any = [];
    for (let i of this.lwcCategoriesStandardiserTreeOriginal) {
      if (i.Children != null) {
        for (let j of i.Children) {
          if (j.Children != null) {
            for (let k of j.Children) {
              if (k.Children != null) {
                if (k.ParentId === j.CategoryId && j.ParentId === i.CategoryId) {
                  data.push({
                    L1CategoryName: i.CategoryName,
                    L2CategoryName: j.CategoryName,
                    L3CategoryName: k.CategoryName,
                    CategoryId: i.CategoryId
                  })
                }
              }
            }
          }
        }
      }
      if (i.Children != null) {
        for (let j of i.Standardisers) {
          standard.push({
            LWCCategoryID: j.LWCCategoryID,
            ExternalCategoryName: j.CategoryDataSourceType,
            ExternalCategoryId: j.ExternalCategoryType,
            LWCCategoryName: j.LWCCategoryName,
            CategoryDataSource: j.CategoryDataSource,
            LastUpdated: j.LastUpdated
          })
        }
      }
    }

    let arr = data.map((i: any) => {
      let b = standard.find((j: any) => j.LWCCategoryID.toString() === i.CategoryId)
      return { ...i, ...b }
    })

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let d = new Date();
    let mon = months[d.getMonth()];
    let yy = (d.getFullYear() + '').slice(-2);
    let name = 'LWC_' + this.dataSource + '_' + 'Mapping' + '_' + mon + '_' + yy;

    this.csvExportComponent.data = arr;
    this.csvExportComponent.filename = name;
    this.csvExportComponent.options = this.csvOptions;
    this.csvExportComponent.onDownload();
  }

  isCsvButtonDisabled() {
    return this.lwcCategoriesStandardiserTreeOriginal.length > 0;
  }

  checkIsDraft(standardiser: Standardiser) {
    return this.categoryStandardiser.IsLWC && standardiser.Id !== 0 ? true : false;
  }

  switchIsEdit(standardiser: Standardiser, isEdit: boolean) {
    this.setSelectStandardiser();
    if (standardiser.CategoryDataSourceType && standardiser.LWCCategoryName) {
      this.selectedNodeStandardisers.set(standardiser, isEdit);
    }

    this.clearExternalCategory();
    this.standardiserCategoryControl.reset();
  }

  setCategoryStandardiserName(externalCategory: ExternalCategory) {
    return externalCategory && externalCategory.FullCategoryPath ? externalCategory.FullCategoryPath : emptyString;
  }

  handleCategorySelect(category: SelectedCategory) {
    this.selectedLwcCategoryId = category.category ? category.category.CategoryID : null;
    this.selectedCategoryName = category.category ? category.category.CategoryName : null;
  }

  handleClearInputForLwcOrExternalCategoryStandardiser(value: string) {
    if (value === 'LWC') {
      this.categoryStandardiserTree.LWCCategories = cloneDeep(this.lwcCategoriesStandardiserTreeOriginal);
      this.expandedLwcTree = false;
    }
    if (value === 'External') {
      this.categoryStandardiserTree.ExternalCategories = cloneDeep(this.externalCategoriesStandardiserTreeOriginal);
      this.expandedExternalTree = false;
    }
  }

  filteringCategoryStandardiserTree(event: KeyboardEvent, value: string) {
    let filterValue = (<HTMLInputElement>event.target).value.trim() || emptyString;
    this.handleClearInputForLwcOrExternalCategoryStandardiser(value);

    if (!this.valueCheckerService.isEmptyNullOrUndefined(filterValue) && value === 'LWC') {
      this.categoryStandardiserTree.LWCCategories = this.setFilterCategoryStandardiserTree(filterValue, this.categoryStandardiserTree?.LWCCategories);
      this.expandedLwcTree = true;
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(filterValue) && value === 'External') {
      this.categoryStandardiserTree.ExternalCategories = this.setFilterCategoryStandardiserTree(filterValue, this.categoryStandardiserTree?.ExternalCategories);
      this.expandedExternalTree = true;
    }

  }

  setExternalStandardiserCategoryName() {
    if (!this.valueCheckerService.isNullOrUndefined(this.standardiserCategoryControl.value)) {
      this.externalCategoryName = this.standardiserCategoryControl.value.FullCategoryPath;
    } else {
      this.externalCategoryName = emptyString;
    }
  }

  showSaveButton() {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(this.standardiserCategoryControl?.value)) {
      if (typeof this.standardiserCategoryControl.value === 'string') {
        if (this.standardiserCategoryControl.value.trim().length !== 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else if (!this.valueCheckerService.isNullOrUndefined(this.selectedLwcCategoryId)) {
      return true;
    } else {
      return false;
    }
  }

  keepMiddleSectionVisible() {
    let padding = 15;
    let maxHeight = 400;
    let initailScroll = 100;
    let containerHeight = this.treeContainer.nativeElement.clientHeight;
    let scrollY = window.scrollY + this.informationContainer.nativeElement.offsetHeight + padding;
    let height = this.informationContainer.nativeElement.offsetHeight;
    let element = this.informationContainer.nativeElement;

    if (maxHeight <= height) {
      element.style.overflowY = 'scroll';
    } else {
      element.style.overflowY = 'initial';
    }

    if (containerHeight >= scrollY) {
      element.style.marginTop = window.scrollY.toString() + 'px';
    }

    if (window.scrollY <= initailScroll) {
      element.style.marginTop = 100 + 'px';
    }
  }

  private setCategoryStandardiserActiveDrafts() {
    if (this.isAdminRole) {
      this.loadingService.setLoading();
      this.categoryService.getCategoryStandardiserActivedDrafts()
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          if (response) {
            this.categoriesStandardiserDrafts = response;
            this.categoryStandardiserDraftSetDisplayValues(this.categoriesStandardiserDrafts);
            this.categoriesStandardiserDrafts = [...this.categoriesStandardiserDrafts];
          }
        });
    }
  }

  private setSelectedNode(standardiser: Standardiser) {
    if (standardiser.LWCCategoryName && standardiser.CategoryDataSourceType) {
      this.selectedNodeStandardisers.set(standardiser, false);
    } else {
      this.selectedNodeStandardisers.set(standardiser, true);
    }
  }

  private clearExternalCategory() {
    this.selectedLwcCategoryId = null;
    this.externalCategoryName = emptyString;
  }

  private setFilterCategoryStandardiserTree(filterValue: string, categoriesStandardise: CategoryStandardiser[]) {
    let filtered = cloneDeep(categoriesStandardise);

    for (let i = 0; i < filtered.length; i++) {
      let includes = this.filterCategory(filterValue.trim().toLowerCase(), filtered[i]);

      if (!includes) {
        if (filtered[i]?.Children && filtered[i]?.Children.length === 0) {
          filtered.splice(i, 1);
        } else {
          filtered[i].Children.splice(i, 1);
        }
        i--;
      }
    }

    return filtered;
  }

  private filterCategory(filterValue: string, category: CategoryStandardiser) {
    let keep = false;
    if (!category?.Children || category?.Children.length === 0) {
      if (category.CategoryName.toLowerCase().includes(filterValue)) {
        return true;
      } else {
        return false;
      }
    }

    for (let i = 0; i < category?.Children.length; i++) {
      let includes = this.filterCategory(filterValue, category?.Children[i])

      if (includes) {
        keep = true;
      } else {
        category.Children.splice(i, 1);
        i--;
      }
      if (category.CategoryName.toLowerCase().includes(filterValue) && (!category?.Children || category?.Children.length === 0)) {
        keep = true;
      }
    }

    return keep;
  }

  private setSelectStandardiser() {
    let externalNames: string[] = [];
    for (let externalCategory of this.selectExternalCategories) {
      externalNames.push(externalCategory.CategoryName)
    }

    if (this.categoryStandardiser?.Standardisers.length > 0) {
      for (let standardiser of this.categoryStandardiser.Standardisers) {
        if (this.categoryStandardiser.IsLWC) {
          for (let name of externalNames) {
            if (standardiser.CategoryDataSourceType === name) {
              this.setSelectedNode(standardiser);
              break;
            }
          }
          break;
        }
        this.setSelectedNode(standardiser);
      }
    }
    else if (this.categoryStandardiser?.IsLWC) {
      this.dummyStandardiser = {
        Id: this.categoryStandardiser.Id,
        CategoryDataSource: this.dataSource,
        LWCCategoryID: +this.categoryStandardiser.CategoryId,
        ExternalCategoryType: this.categoryStandardiser.CategoryId,
        LWCCategoryName: this.categoryStandardiser.CategoryName,
        IsActive: false,
        CategoryStandardiserID: 0,
        CategoryDataSourceType: null,
      }
      this.clearInputCategoryName()
      this.selectedNodeStandardisers.set(this.dummyStandardiser as Standardiser, true);
    }
  }

  private setExternalCategories() {
    this.loadingService.setLoading();
    this.categoryMappingRuleService
      .getExternalCategories(this.dataSource)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        if (response && response.length > 0) {
          this.selectExternalCategories = response;
          this.filteredExternalCategories =
            this.standardiserCategoryControl.valueChanges.pipe(
              startWith(null),
              map(filed =>
                filed
                  ? this.filterExternalCategories(filed.FullCategoryPath)
                  : this.selectExternalCategories.slice()
              )
            );
        }
      });
  }

  private filterExternalCategories(field: string) {
    return this.selectExternalCategories.filter((t) =>
      t.FullCategoryPath.toLowerCase().includes(field?.toLowerCase())
    );
  }

  private categoryStandardiserDraftSetDisplayValues(categoryStandardiserDrafts: CategoryStandardiserDraftDisplay[]) {
    this.loadingService.setLoading();
    this.userServise.getApplicationUsers()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        let apllicationUsers: ApplicationUser[] = response;
        for (let categoryStandardiserDraft of categoryStandardiserDrafts) {
          categoryStandardiserDraft.lastUpdatedDisplay = this.dateTimeService.format(categoryStandardiserDraft.LastUpdated, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
          let user = apllicationUsers.find((t) => t.Id === categoryStandardiserDraft.UpdatedBy);

          if (!this.valueCheckerService.isNullOrUndefined(user)) {
            categoryStandardiserDraft.updatedByDisplay = `${user.FirstName + ' ' + user.LastName}`;
          }
          else {
            categoryStandardiserDraft.updatedByDisplay = categoryStandardiserDraft.UpdatedBy.toString();
          }
        }
      });
  }

  private setExternalCategoriesDataSources() {
    this.loadingService.setLoading();
    this.categoryStandardiserService.getExternalCategoriesDataSources()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.externalCategories = response;
        if (!this.valueCheckerService.isNullOrUndefined(this.externalCategories)) {
          this.externalCategories.sort((a, b) => {
            return a < b ? -1 : 1;
          });
        }
      });
  }

  private clearInputCategoryName() {
    this.selectedNodeStandardisers = new Map<Standardiser, boolean>();
    this.standardiserCategoryControl.setValue(emptyString);
  }
}
