import { IndexMerchantCalType } from "../../components/indexmerchant/indexmerchantcaltype";
import { NameValue } from "../models/namevalue";

export let calTypes: NameValue<IndexMerchantCalType>[] = [
  { value: IndexMerchantCalType.Unknown, name: 'Unknown' },
  { value: IndexMerchantCalType.OnlineOnly, name: 'Online only' },
  { value: IndexMerchantCalType.MobileBusiness, name: 'Mobile business' },
  { value: IndexMerchantCalType.InternationalCal, name: 'International CAL' },
  { value: IndexMerchantCalType.SharedCal, name: 'Shared CAL' },
];
