import { Injectable } from '@angular/core';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { MoneyTrackerV4ResponseInternal, MoneyTrackerV4BankTransaction } from '../components/moneytrackersearch/moneytrackersearchdisplayresult';
import { apiPathConstants } from '../core/api/apipathconstants';

@Injectable({
  providedIn: 'root',
})

export class MoneyTrackerSearchService {

  constructor(private apiClient: ApiClient, private apiService: ApiService) { }
  
  enrich = (enrichMerchantRequest: MoneyTrackerV4BankTransaction[]) => {
    return this.apiClient.post<MoneyTrackerV4ResponseInternal>(this.apiService
      .buildUrl(apiPathConstants.moneytrackerEnrich), enrichMerchantRequest);
  }
}