import { WestfieldRetailesResponse } from '../../components/managewestfiledretailer/managewestfieldretailer.component';
import { ExecutionResult } from 'graphql';
import { UpdateRetailerWithLwcIdResponse } from '../../components/managewestfiledretailer/managewestfieldretailer.models';

export let westfieldRetailersResponse: ExecutionResult<WestfieldRetailesResponse> = {
  data: {
    items: [
      {
        locatedIn: 6,
        retailersId: 1,
        createDate: "2021-07-28T04:42:33.950+10:00",
        lwcId: 1000594691,
        chainId: 29,
        storesTitle: "BIG W 1",
        storesShortDescription: "BIG W at Westfield Warringah Mall",
        storesPhysicalAddress: "Level 1",
        storesShopNumber: "1-2000F1",
        storesLevel: null,
        closedForNoOfDaysPotentially: 15,
        sourceModifiedDate: "2021-08-03T06:46:32.400+10:00",
        modifiedBy: 1,
        storesTelephoneNumber: '02140948',
        comment: "Comment 1",
        retailerAudits: null
      },
      {
        locatedIn: 1,
        retailersId: 1,
        createDate: "2011-02-15T02:42:33.950+05:00",
        lwcId: null,
        chainId: 10,
        storesTitle: "BIG W 2",
        storesShortDescription: "BIG W at Westfield Warringah Mall",
        storesPhysicalAddress: "Level 1",
        storesShopNumber: "1-2000F1",
        storesLevel: null,
        closedForNoOfDaysPotentially: 5,
        sourceModifiedDate: "2015-01-03T08:46:32.400+10:00",
        modifiedBy: 2,
        storesTelephoneNumber: '08960948',
        comment: "Comment 2",
        retailerAudits: [
          {
            calculatedNoOfDays: 10,
            createDate: "2020-07-11T06:46:27.850+10:00",
            fieldName: "StoresTitle",
            fieldOldValue: "BIG W",
            fieldNewValue: "BIG W V1"
          },
          {
            calculatedNoOfDays: 50,
            createDate: "2011-08-01T06:46:27.850+10:00",
            fieldName: "StoresTitle",
            fieldOldValue: "BIG W v1",
            fieldNewValue: "BIG W V2"
          },
          {
            calculatedNoOfDays: 1,
            createDate: "2013-08-11T06:46:27.850+10:00",
            fieldName: "StoresTitle",
            fieldOldValue: "BIG W v2",
            fieldNewValue: "BIG W V3"
          }
        ]
      },
      {
        locatedIn: 3,
        retailersId: 1,
        createDate: "2001-09-10T19:42:33.950+10:00",
        lwcId: null,
        chainId: 15,
        storesTitle: "BIG W 3",
        storesShortDescription: "BIG W at Westfield Warringah Mall",
        storesPhysicalAddress: "Level 1",
        storesShopNumber: "1-2000F1",
        storesLevel: null,
        closedForNoOfDaysPotentially: 0,
        sourceModifiedDate: "2011-04-03T06:46:32.400+10:00",
        modifiedBy: 3,
        storesTelephoneNumber: '04350999',
        comment: "Comment 3",
        retailerAudits: [
          {
            calculatedNoOfDays: 32,
            createDate: "2002-02-11T06:46:27.850+10:00",
            fieldName: "StoresShopNumber",
            fieldOldValue: "BIG W",
            fieldNewValue: "BIG W V1"
          },
          {
            calculatedNoOfDays: 11,
            createDate: "2009-03-01T08:46:27.850+10:00",
            fieldName: "StoresShopNumber",
            fieldOldValue: "BIG W v1",
            fieldNewValue: "BIG W V2"
          },
          {
            calculatedNoOfDays: 1,
            createDate: null,
            fieldName: "StoresShopNumber",
            fieldOldValue: "BIG W v2",
            fieldNewValue: "BIG W V3"
          }
        ]
      },
      {
        locatedIn: 18,
        retailersId: 1,
        createDate: "1991-02-28T04:42:33.950+10:00",
        lwcId: 1000000691,
        chainId: 5,
        storesTitle: "BIG W 4",
        storesShortDescription: "BIG W at Westfield Warringah Mall",
        storesPhysicalAddress: "Level 4",
        storesShopNumber: "1-5000F1",
        storesLevel: null,
        closedForNoOfDaysPotentially: 15,
        sourceModifiedDate: "2008-08-03T06:46:32.400+10:00",
        modifiedBy: 1,
        storesTelephoneNumber: '01199948',
        comment: "Comment 4",
        retailerAudits: null
      },
    ],
    totalCount: 500
  }
};

export let updateRetailerWithLwcIdResponse: ExecutionResult<UpdateRetailerWithLwcIdResponse> = {
  data: {
    retailersId: 61,
    comment: "testv2",
    chainId: 1924,
    lwcId: 0
  }
};
