import { emptyString } from './../../core/constants/constants';
import { BaseStateComponent } from '../basestate/basestate.component';
import { AbnAcnLookupState } from './abnacnlookupstate';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { BusinessNameModel } from '../../models/businessname';
import { Component, OnInit, Injector } from '@angular/core';
import { AbnAcnLookupService } from '../../services/abnacnlookup.service';
import { BusinessAddressModel } from '../../models/businessaddress';
import { NotificationService } from '../../core/uiservices/notification.service';
import { DialogService } from '../../core/uiservices/dialog.service';
import { Router } from '@angular/router';
import { MerchantByAbnAcn } from '../../models/merchantlist';
import { FormatService } from '../../core/formatting/format.service';

@Component({
  selector: 'app-abnacnlookup',
  templateUrl: './abnacnlookup.component.html',
  styleUrls: ['./abnacnlookup.component.scss']
})
export class AbnAcnLookupComponent extends BaseStateComponent<AbnAcnLookupState> implements OnInit {
  openPanel :boolean=true
  constructor(private abnAcnLookupService: AbnAcnLookupService, private notificationService: NotificationService,
    private loadingService: LoadingService, private dateTimeService: DateTimeService, private inector: Injector,
    private dialogService: DialogService, private router: Router, private formatService: FormatService) {
    super(inector);
  }

  ngOnInit() {
    this.state = {
      abn: <number>null,
      acn: emptyString,
      businessNames: [],
      businessAddresses: [],
      merchantList: []
    };

    this.restoreState();
  }

  search() {
    this.loadingService.setLoading();
    if (this.isAbnValid()) {
      this.abnAcnLookupService.getBusinessNamesByAbn(this.state.abn).pipe(finalize(this.stopLoading))
        .subscribe(w => {
          this.notificationService.notifySuccess();
          this.onSearchNamesComplete(w);
        });
      this.abnAcnLookupService.getBusinessAddressesByAbn(this.state.abn).pipe(finalize(this.stopLoading))
        .subscribe(w => {
          this.notificationService.notifySuccess();
          this.onSearchAddressesComplete(w);
        });
      this.abnAcnLookupService.getMerchantListByAbn(this.state.abn.toString())
        .subscribe((response) => {
          this.handleMerchantListResponse(response);
        });
    } else if (this.isAcnValid()) {
      this.abnAcnLookupService.getBusinessNamesByAcn(this.state.acn).pipe(finalize(this.stopLoading))
        .subscribe(w => {
          this.notificationService.notifySuccess();
          this.onSearchNamesComplete(w);
        });
      this.abnAcnLookupService.getBusinessAddressesByAcn(this.state.acn).pipe(finalize(this.stopLoading))
        .subscribe(w => {
          this.onSearchAddressesComplete(w);
          this.notificationService.notifySuccess();
        });
      this.abnAcnLookupService.getMerchantListByAcn(this.state.acn)
        .subscribe((response) => {
          this.handleMerchantListResponse(response);
        });
    }
  }

  searchABR() {
    this.loadingService.setLoading();

    this.abnAcnLookupService.refreshABR(this.state.abn)
      .subscribe((result) => {
        this.onSearchNamesComplete(result);
        this.abnAcnLookupService.getBusinessAddressesByAbn(this.state.abn).pipe(finalize(this.stopLoading))
          .subscribe(w => {
            this.notificationService.notifySuccess();
            this.onSearchAddressesComplete(w);
          });
      }, this.stopLoading);
  }

  searchButtonDisabled(): boolean {
    if (!this.isAbnValid() && !this.isAcnValid()) {
      return true;
    }
  }

  purgeBusinessName(record: BusinessNameModel) {
    const dialogRef = this.dialogService.openConfirmDialog
      (`Purge all address data for business name Id: ${record.BusinessNameID}, are you sure?`);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();
        this.abnAcnLookupService.purgeBusinessName(record.BusinessNameID)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(data => {
          this.onSearchNamesComplete(data);
          this.notificationService.notifySuccess('Record purged successfully.');
        });
      }
    });
  }

  purgeBusinessAddress(record: BusinessAddressModel) {
    const dialogRef = this.dialogService.openConfirmDialog
      (`Purge address data for business address Id: ${record.BusinessAddressesID}, are you sure?`);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();
        this.abnAcnLookupService.purgeBusinessAddress(record.BusinessAddressesID)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(data => {
          this.onSearchAddressesComplete(data);
          this.notificationService.notifySuccess('Record purged successfully.');
        });
      }
    });
  }

  isAbnValid() {
    return this.state.abn > 0;
  }

  navigateToEnrichMerchant(lwcID: number) {
    this.router.navigate(['/enrichmerchant', {lwcId: lwcID}]);
  }

  private isAcnValid() {
    return parseInt(this.state.acn) > 0;
  }

  private onSearchNamesComplete(data: BusinessNameModel[]) {
    data.forEach(businessName => {
      businessName.CreatedDateTime = this.dateTimeService.formatWithHours(businessName.CreatedDateTime);
      businessName.UpdatedDateTime = this.dateTimeService.formatWithHours(businessName.UpdatedDateTime);
      businessName.CurrentStatusDate = this.dateTimeService.formatWithHours(businessName.CurrentStatusDate);
      businessName.LwcId = businessName.LwcId === 0 ? null : businessName.LwcId;
    });

    this.state.businessNames = data;
  }

  private onSearchAddressesComplete(data: BusinessAddressModel[]) {
    data.forEach(businessAddress => {
      businessAddress.LocationEndDate = this.dateTimeService.formatWithCurrent(businessAddress.LocationEndDate);
      businessAddress.LocationStartDate = this.dateTimeService.formatWithHours(businessAddress.LocationStartDate);
    });

    this.state.businessAddresses = data;
  }

  private stopLoading = () => this.loadingService.clearLoading();

  private handleMerchantListResponse(response: MerchantByAbnAcn[]) {
    if (response && response.length > 0) {
      this.state.merchantList = response;
      for (let merchant of this.state.merchantList) {
        merchant.UpdatedDateDisplay = merchant.UpdatedDate
          ? this.dateTimeService.formatWithHours(merchant.UpdatedDate)
          : null;
        merchant.StreetViewAvailableDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchant.StreetViewAvailable);
        merchant.RecordLockedDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchant.RecordLocked);
        merchant.IsQuarantinedDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchant.IsQuarantined);
      }
    }
    else {
      this.state.merchantList = [];
    }
  }
}
