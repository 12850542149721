export let categories = [
  {
    "Level": {
      "CategoryLevel": 1,
      "ID": 0,
      "Name": "Unclassified",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_0.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_0.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
      "ID": 1,
      "Name": "Shopping",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
      "ID": 2,
      "Name": "Grocery",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
       CategoryType: 0,
      "ID": 3,
      "Name": "Food/Drink",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
       CategoryType: 1,
      "ID": 4,
      "Name": "Drink-Prepared",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_4.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_4.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
       CategoryType: 0,
      "ID": 5,
      "Name": "Service",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
       CategoryType: 0,
      "ID": 6,
      "Name": "Entertainment",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
       CategoryType: 0,
      "ID": 7,
      "Name": "Travel",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
       CategoryType: 1,
      "ID": 8,
      "Name": "Health & Fitness",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 1,
       CategoryType: 2,
      "ID": 9,
      "Name": "Restricted",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
        "height": 128,
        "width": 128
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1001,
      "Name": "Clothing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1002,
      "Name": "Toys/Hobby",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1003,
      "Name": "Home",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1004,
      "Name": "Outdoor",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1005,
      "Name": "Furniture",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1006,
      "Name": "Consumer Electronics",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1007,
      "Name": "Department Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1008,
      "Name": "Hardware",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1009,
      "Name": "Office",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1009.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1009.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1010,
      "Name": "Jewellery",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1010.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1010.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1011,
      "Name": "Pet Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1011.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1011.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1012,
      "Name": "Educational/Technology",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1012.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1012.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1013,
      "Name": "Bookshop/News",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1013.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1013.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1014,
      "Name": "Second-hand",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1014.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1014.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1015,
      "Name": "Software",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1015.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1015.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 1999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 1,
        "Name": "Shopping",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 2001,
      "Name": "Supermarket",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 2,
        "Name": "Grocery",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 2002,
      "Name": "Food Market",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 2,
        "Name": "Grocery",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 2003,
      "Name": "Convenience Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 2,
        "Name": "Grocery",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 2999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 2,
        "Name": "Grocery",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 3001,
      "Name": "Fast Food",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 3,
        "Name": "Food/Drink",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 3002,
      "Name": "Asian",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 3,
        "Name": "Food/Drink",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 3003,
      "Name": "European",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 3,
        "Name": "Food/Drink",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 3004,
      "Name": "Dessert",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 3,
        "Name": "Food/Drink",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 3999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 3,
        "Name": "Food/Drink",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 4001,
      "Name": "Smoothie/Juice",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_4001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_4001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 4,
        "Name": "Drink-Prepared",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_4.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_4.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 4002,
      "Name": "Coffee/Tea",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_4002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_4002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 4,
        "Name": "Drink-Prepared",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_4.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_4.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5001,
      "Name": "Automotive",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5002,
      "Name": "Manufacturing/Engineering",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5003,
      "Name": "Telecommunication",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5004,
      "Name": "Government",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5005,
      "Name": "Educational",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5006,
      "Name": "Utilities",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5007,
      "Name": "Professional Service",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5008,
      "Name": "Trade",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5009,
      "Name": "Technical",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5010,
      "Name": "Payment Gateway",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5010.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5010.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5011,
      "Name": "Freight/Postage",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5011.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5011.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5012,
      "Name": "Religious",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5012.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5012.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5013,
      "Name": "Charity",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5013.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5013.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5014,
      "Name": "Pet",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5014.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5014.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 5999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 5,
        "Name": "Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 6001,
      "Name": "Venue",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 6,
        "Name": "Entertainment",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 6002,
      "Name": "Cultural",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 6,
        "Name": "Entertainment",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 6003,
      "Name": "Cinema",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 6,
        "Name": "Entertainment",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 6004,
      "Name": "Ticketing Agency",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 6,
        "Name": "Entertainment",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 6005,
      "Name": "Hire Company",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 6,
        "Name": "Entertainment",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 6999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 6,
        "Name": "Entertainment",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 7001,
      "Name": "Transport",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 7,
        "Name": "Travel",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 7002,
      "Name": "Travel Agent",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 7,
        "Name": "Travel",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 7003,
      "Name": "Accomodation",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 7,
        "Name": "Travel",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 7004,
      "Name": "Car Park",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 7,
        "Name": "Travel",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 7005,
      "Name": "Petrol Station",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 7,
        "Name": "Travel",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 7006,
      "Name": "Car Hire",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 7,
        "Name": "Travel",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 7999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 7,
        "Name": "Travel",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 8001,
      "Name": "Medical",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 8,
        "Name": "Health & Fitness",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 8002,
      "Name": "Beauty",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 8,
        "Name": "Health & Fitness",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 8003,
      "Name": "Fitness Club",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 8,
        "Name": "Health & Fitness",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 8004,
      "Name": "Specialist Sports Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 8,
        "Name": "Health & Fitness",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 8005,
      "Name": "Sporting Clubs",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 8,
        "Name": "Health & Fitness",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 9001,
      "Name": "Gambling",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 9,
        "Name": "Restricted",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 9002,
      "Name": "Liquor Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 9,
        "Name": "Restricted",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 9004,
      "Name": "Bar/Club",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 9,
        "Name": "Restricted",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 9005,
      "Name": "Tobacconist",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 9,
        "Name": "Restricted",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 9006,
      "Name": "Sensitive",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 9,
        "Name": "Restricted",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 2,
      "ID": 9999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 1,
        "ID": 9,
        "Name": "Restricted",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
          "height": 128,
          "width": 128
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001001,
      "Name": "Accesories",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001002,
      "Name": "Sleepwear/Underwear",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001003,
      "Name": "Underwear",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001004,
      "Name": "Surf",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001005,
      "Name": "Sport",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001006,
      "Name": "Shoes",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001007,
      "Name": "Tailor",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001008,
      "Name": "Rental",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001009,
      "Name": "Costume",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001009.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001009.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001010,
      "Name": "Dry Cleaning/Laundry",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001010.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001010.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001011,
      "Name": "Uniform",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001011.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001011.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001012,
      "Name": "Bridal/Formal",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001012.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001012.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001013,
      "Name": "Maternity",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001013.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001013.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001014,
      "Name": "Childrenswear",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001014.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001014.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001015,
      "Name": "Womens Clothing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001015.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001015.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001016,
      "Name": "Mens Clothing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001016.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001016.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1001999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1001,
        "Name": "Clothing",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1002001,
      "Name": "Hobby Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1002,
        "Name": "Toys/Hobby",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1002002,
      "Name": "Toy Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1002,
        "Name": "Toys/Hobby",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1002003,
      "Name": "Game",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1002,
        "Name": "Toys/Hobby",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1002004,
      "Name": "Art/Craft",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1002,
        "Name": "Toys/Hobby",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1003001,
      "Name": "Gift",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1003,
        "Name": "Home",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1003002,
      "Name": "Home Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1003,
        "Name": "Home",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1003003,
      "Name": "Haberdashery",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1003,
        "Name": "Home",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1003004,
      "Name": "Souvenirs",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1003,
        "Name": "Home",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1004001,
      "Name": "Fishing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1004,
        "Name": "Outdoor",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1004002,
      "Name": "Camping",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1004,
        "Name": "Outdoor",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1004003,
      "Name": "Garden",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1004,
        "Name": "Outdoor",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1004004,
      "Name": "Florist",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1004,
        "Name": "Outdoor",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1004005,
      "Name": "Nursery",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1004,
        "Name": "Outdoor",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1004006,
      "Name": "Equipment",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1004,
        "Name": "Outdoor",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1006001,
      "Name": "Computers",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1006,
        "Name": "Consumer Electronics",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1006002,
      "Name": "Gaming",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1006,
        "Name": "Consumer Electronics",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1006003,
      "Name": "Electronics",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1006,
        "Name": "Consumer Electronics",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1006004,
      "Name": "Whitegoods",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1006,
        "Name": "Consumer Electronics",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1006005,
      "Name": "Music",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1006,
        "Name": "Consumer Electronics",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1007001,
      "Name": "Discount Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1007001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1007001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1007,
        "Name": "Department Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1007002,
      "Name": "Outlets",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1007002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1007002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1007,
        "Name": "Department Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1007003,
      "Name": "Luxury",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1007003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1007003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1007,
        "Name": "Department Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1008001,
      "Name": "General",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1008,
        "Name": "Hardware",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1008002,
      "Name": "Trade Only",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1008,
        "Name": "Hardware",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1008003,
      "Name": "Equipment Rental",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1008,
        "Name": "Hardware",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1008004,
      "Name": "Paint",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1008,
        "Name": "Hardware",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1008005,
      "Name": "Materials",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1008,
        "Name": "Hardware",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1008006,
      "Name": "Electrical",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1008,
        "Name": "Hardware",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1008007,
      "Name": "Doors/Windows",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1008,
        "Name": "Hardware",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1012001,
      "Name": "Educational",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1012001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1012001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1012,
        "Name": "Educational/Technology",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1012.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1012.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1012002,
      "Name": "Technology",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1012002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1012002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1012,
        "Name": "Educational/Technology",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1012.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1012.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1013001,
      "Name": "Bookshop",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1013001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1013001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1013,
        "Name": "Bookshop/News",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1013.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1013.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1013002,
      "Name": "Newsagent",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1013002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1013002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1013,
        "Name": "Bookshop/News",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1013.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1013.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1014001,
      "Name": "Charity Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1014001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1014001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1014,
        "Name": "Second-hand",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1014.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1014.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1014002,
      "Name": "Mixed Goods",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1014002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1014002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1014,
        "Name": "Second-hand",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1014.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1014.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 1999001,
      "Name": "Travel Goods",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1999001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1999001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 1999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 1,
          "Name": "Shopping",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_1.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_1.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2001002,
      "Name": "Wholesale Club",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2001,
        "Name": "Supermarket",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2002001,
      "Name": "Butcher",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2002,
        "Name": "Food Market",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2002002,
      "Name": "Bakery",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2002,
        "Name": "Food Market",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2002003,
      "Name": "Fish Monger",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2002,
        "Name": "Food Market",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2002005,
      "Name": "Fruit/Veg Market",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2002,
        "Name": "Food Market",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2002006,
      "Name": "Deli",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2002,
        "Name": "Food Market",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2999001,
      "Name": "Specialty Food",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2999001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2999001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 2999002,
      "Name": "Food Manufacturer",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2999002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2999002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 2999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 2,
          "Name": "Grocery",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_2.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_2.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3001001,
      "Name": "Chicken",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3001,
        "Name": "Fast Food",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3001002,
      "Name": "Beef",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3001,
        "Name": "Fast Food",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3001003,
      "Name": "Pizza",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3001,
        "Name": "Fast Food",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3001004,
      "Name": "Fish & Chips",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3001,
        "Name": "Fast Food",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3001999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3001,
        "Name": "Fast Food",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3002001,
      "Name": "Indian",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3002,
        "Name": "Asian",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3002002,
      "Name": "Japanese/Sushi",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3002,
        "Name": "Asian",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3002003,
      "Name": "Chinese",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3002,
        "Name": "Asian",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3002004,
      "Name": "Thai",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3002,
        "Name": "Asian",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3002005,
      "Name": "Malaysian",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3002,
        "Name": "Asian",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3002999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3002,
        "Name": "Asian",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3003001,
      "Name": "Italian",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3003,
        "Name": "European",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3003002,
      "Name": "Spanish",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3003,
        "Name": "European",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3003003,
      "Name": "French",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3003,
        "Name": "European",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3003999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3003,
        "Name": "European",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3004001,
      "Name": "Ice Cream/Yoghurt",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3004001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3004001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3004,
        "Name": "Dessert",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3004002,
      "Name": "Yoghurt",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3004002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3004002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3004,
        "Name": "Dessert",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3004003,
      "Name": "Pastry",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3004003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3004003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3004,
        "Name": "Dessert",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999001,
      "Name": "Deli",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999002,
      "Name": "Cafe",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999003,
      "Name": "Caterers",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999004,
      "Name": "Club",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999005,
      "Name": "Mexican",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999006,
      "Name": "Seafood",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999007,
      "Name": "Steakhouse",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 3999008,
      "Name": "Smoothie/Juice",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 3999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 3,
          "Name": "Food/Drink",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_3.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_3.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5001001,
      "Name": "Wheels/Tyres",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5001,
        "Name": "Automotive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5001002,
      "Name": "Auto Sales",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5001,
        "Name": "Automotive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5001003,
      "Name": "Mechanic/Bodywork",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5001,
        "Name": "Automotive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5001004,
      "Name": "Auto Parts",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5001,
        "Name": "Automotive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5001005,
      "Name": "Car Wash",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5001,
        "Name": "Automotive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5001006,
      "Name": "Auto Electrical",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5001,
        "Name": "Automotive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5001999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5001,
        "Name": "Automotive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5002001,
      "Name": "Farm Equipment",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5002,
        "Name": "Manufacturing/Engineering",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5002002,
      "Name": "Plastics",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5002,
        "Name": "Manufacturing/Engineering",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5002003,
      "Name": "Air conditioning",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5002,
        "Name": "Manufacturing/Engineering",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5002999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5002,
        "Name": "Manufacturing/Engineering",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5003001,
      "Name": "Line Providers",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5003001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5003001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5003,
        "Name": "Telecommunication",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5004001,
      "Name": "Healthcare",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5004,
        "Name": "Government",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5004002,
      "Name": "Infrastructure",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5004,
        "Name": "Government",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5004003,
      "Name": "Administration",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5004,
        "Name": "Government",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5004004,
      "Name": "Federal",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5004,
        "Name": "Government",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5004005,
      "Name": "State",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5004,
        "Name": "Government",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5004006,
      "Name": "Local",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5004,
        "Name": "Government",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005001,
      "Name": "University",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005002,
      "Name": "Private School",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005003,
      "Name": "TAFE",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005004,
      "Name": "Library",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005005,
      "Name": "Child Care",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005006,
      "Name": "Tutors",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005007,
      "Name": "Driving",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005008,
      "Name": "Training",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005009,
      "Name": "High School",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005009.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005009.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005010,
      "Name": "Public/Primary",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005010.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005010.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005011,
      "Name": "Religous",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005011.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005011.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5005999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5005,
        "Name": "Educational",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5006001,
      "Name": "Water",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5006,
        "Name": "Utilities",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5006002,
      "Name": "Power",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5006,
        "Name": "Utilities",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5006003,
      "Name": "Gas",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5006,
        "Name": "Utilities",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5006004,
      "Name": "Waste Disposal",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5006,
        "Name": "Utilities",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5006999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5006,
        "Name": "Utilities",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007001,
      "Name": "Lawyer",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007002,
      "Name": "Accountant/Bookkeeper",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007004,
      "Name": "Insurance",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007005,
      "Name": "Graphic Design",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007006,
      "Name": "Real Estate",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007007,
      "Name": "Photographer",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007008,
      "Name": "Celebrant",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007009,
      "Name": "Credit Agency",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007009.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007009.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007010,
      "Name": "Employment/HR",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007010.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007010.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007011,
      "Name": "Consulting Agency",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007011.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007011.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007012,
      "Name": "Financial Services",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007012.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007012.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007013,
      "Name": "Vet",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007013.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007013.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007014,
      "Name": "Advertising",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007014.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007014.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007015,
      "Name": "Publishing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007015.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007015.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007016,
      "Name": "Security Service",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007016.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007016.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007017,
      "Name": "Architect",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007017.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007017.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5007999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5007,
        "Name": "Professional Service",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5007.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5007.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008001,
      "Name": "Plumbing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008002,
      "Name": "Electrician",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008003,
      "Name": "Painter/decorator",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008004,
      "Name": "Interior design",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008005,
      "Name": "Fire Maintenance",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008006,
      "Name": "Exterminator",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008007,
      "Name": "Repair Services",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008008,
      "Name": "Builder",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008009,
      "Name": "Carpentry",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008009.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008009.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008010,
      "Name": "Tiler",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008010.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008010.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008011,
      "Name": "Cleaning",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008011.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008011.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008012,
      "Name": "Locksmith",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008012.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008012.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008013,
      "Name": "Outdoor Maintenance",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008013.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008013.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008015,
      "Name": "Glass",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008015.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008015.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008016,
      "Name": "Kitchens/Bathrooms",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008016.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008016.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5008999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5008,
        "Name": "Trade",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5008.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5008.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5009001,
      "Name": "Security Services",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5009,
        "Name": "Technical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5009002,
      "Name": "Cable",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5009,
        "Name": "Technical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5009003,
      "Name": "Data Processing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5009,
        "Name": "Technical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5009004,
      "Name": "Network",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5009,
        "Name": "Technical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5009005,
      "Name": "Info Retrieval",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5009,
        "Name": "Technical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5009.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5009.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5011001,
      "Name": "Australia Post",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5011001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5011001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5011,
        "Name": "Freight/Postage",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5011.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5011.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5011002,
      "Name": "Couriers",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5011002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5011002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5011,
        "Name": "Freight/Postage",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5011.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5011.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5012001,
      "Name": "Church",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5012001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5012001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5012,
        "Name": "Religious",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5012.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5012.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5012002,
      "Name": "Mosque",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5012002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5012002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5012,
        "Name": "Religious",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5012.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5012.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5014001,
      "Name": "Pet Care",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5014001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5014001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5014,
        "Name": "Pet",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5014.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5014.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5014002,
      "Name": "Veterinary",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5014002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5014002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5014,
        "Name": "Pet",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5014.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5014.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5999001,
      "Name": "Storage",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5999001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5999001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 5999002,
      "Name": "Association/Club",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5999002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5999002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 5999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 5,
          "Name": "Service",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_5.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_5.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 6001001,
      "Name": "Sport",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6001001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6001001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 6001,
        "Name": "Venue",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 6,
          "Name": "Entertainment",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 6001002,
      "Name": "Amusement Park/Zoo/Theme Park",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 6001,
        "Name": "Venue",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 6,
          "Name": "Entertainment",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 6001003,
      "Name": "Entertainment Centre",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6001003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6001003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 6001,
        "Name": "Venue",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 6,
          "Name": "Entertainment",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 6002001,
      "Name": "Museum",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6002001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6002001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 6002,
        "Name": "Cultural",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 6,
          "Name": "Entertainment",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 6002002,
      "Name": "Art Gallery",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6002002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6002002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 6002,
        "Name": "Cultural",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 6,
          "Name": "Entertainment",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 6005001,
      "Name": "Special Occasion",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6005001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6005001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 6005,
        "Name": "Hire Company",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 6,
          "Name": "Entertainment",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 6999001,
      "Name": "Movie/Series Rental",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6999001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6999001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 6999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 6,
          "Name": "Entertainment",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_6.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_6.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7001001,
      "Name": "Public Transport",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7001,
        "Name": "Transport",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7001002,
      "Name": "Private Bus",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7001,
        "Name": "Transport",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7001003,
      "Name": "Airline",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7001,
        "Name": "Transport",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7001004,
      "Name": "Taxi",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7001,
        "Name": "Transport",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7001999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7001,
        "Name": "Transport",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7002001,
      "Name": "Tour Operator",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7002001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7002001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7002,
        "Name": "Travel Agent",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7003001,
      "Name": "Hotel/Motel",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7003,
        "Name": "Accomodation",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7003002,
      "Name": "Holiday Park",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7003,
        "Name": "Accomodation",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7003003,
      "Name": "Home Stay",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7003,
        "Name": "Accomodation",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7003004,
      "Name": "Backpackers",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7003,
        "Name": "Accomodation",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7004001,
      "Name": "Council",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7004001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7004001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7004,
        "Name": "Car Park",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7004002,
      "Name": "Private",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7004002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7004002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7004,
        "Name": "Car Park",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 7999001,
      "Name": "Luggage",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7999001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7999001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 7999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 7,
          "Name": "Travel",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_7.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_7.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001001,
      "Name": "Pharmacy",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001002,
      "Name": "Medical Practice/Hospital",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001003,
      "Name": "Specialist Doctor",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001004,
      "Name": "Optometrist",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001005,
      "Name": "Dentist",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001006,
      "Name": "Alternative Therapy",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001007,
      "Name": "Hearing/Speech",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001008,
      "Name": "Skin/Cosmetic Surgery/Laser",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001008.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001008.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001009,
      "Name": "Counselling",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001009.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001009.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001010,
      "Name": "Radiology/Imaging",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001010.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001010.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8001999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8001,
        "Name": "Medical",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8002001,
      "Name": "Hair",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8002,
        "Name": "Beauty",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8002002,
      "Name": "Nails",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8002,
        "Name": "Beauty",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8002003,
      "Name": "Massage",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8002,
        "Name": "Beauty",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8002004,
      "Name": "Makeup",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8002,
        "Name": "Beauty",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8002999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8002,
        "Name": "Beauty",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8002.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8002.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8003001,
      "Name": "Tennis",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8003,
        "Name": "Fitness Club",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8003002,
      "Name": "Squash",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8003,
        "Name": "Fitness Club",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8003003,
      "Name": "Martial Arts",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8003,
        "Name": "Fitness Club",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8003004,
      "Name": "Swimming Pool",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8003,
        "Name": "Fitness Club",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8003005,
      "Name": "Gym",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8003,
        "Name": "Fitness Club",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8003006,
      "Name": "Golf Course",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8003,
        "Name": "Fitness Club",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8003999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8003,
        "Name": "Fitness Club",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8003.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8003.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004001,
      "Name": "Bike",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004002,
      "Name": "Running",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004003,
      "Name": "Swimming",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004004,
      "Name": "Fishing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004005,
      "Name": "Diving",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004006,
      "Name": "Mountain Climbing",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004006.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004006.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004007,
      "Name": "Golf",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004007.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004007.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8004999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8004,
        "Name": "Specialist Sports Store",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8004.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8004.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8005001,
      "Name": "Racquet",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8005,
        "Name": "Sporting Clubs",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8005002,
      "Name": "Football",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8005,
        "Name": "Sporting Clubs",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8005003,
      "Name": "Golf",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8005,
        "Name": "Sporting Clubs",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 8005004,
      "Name": "Water Sport",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 8005,
        "Name": "Sporting Clubs",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8005.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8005.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 8,
          "Name": "Health & Fitness",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_8.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_8.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9001001,
      "Name": "Casino",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9001,
        "Name": "Gambling",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9001002,
      "Name": "Club",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9001,
        "Name": "Gambling",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9001003,
      "Name": "RSL",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9001,
        "Name": "Gambling",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9001004,
      "Name": "TAB",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9001,
        "Name": "Gambling",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9001005,
      "Name": "Online",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001005.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001005.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9001,
        "Name": "Gambling",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9001.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9001.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9006001,
      "Name": "Adult Store",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9006,
        "Name": "Sensitive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9006002,
      "Name": "Gentlemen's Club",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9006,
        "Name": "Sensitive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9006003,
      "Name": "Brothel",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006003.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006003.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9006,
        "Name": "Sensitive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9006004,
      "Name": "Escort Services",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006004.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006004.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9006,
        "Name": "Sensitive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9006999,
      "Name": "Other",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006999.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006999.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9006,
        "Name": "Sensitive",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9006.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9006.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9999001,
      "Name": "Dating Agency",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9999001.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9999001.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  },
  {
    "Level": {
      "CategoryLevel": 3,
      "ID": 9999002,
      "Name": "Funeral Service",
      "IsSensitive": false,
      "CategoryIcon": {
        "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9999002.png ",
        "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9999002.png ",
        "height": 128,
        "width": 128
      }
    },
    "Parent": {
      "Level": {
        "CategoryLevel": 2,
        "ID": 9999,
        "Name": "Other",
        "IsSensitive": false,
        "CategoryIcon": {
          "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9999.png ",
          "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9999.png ",
          "height": 128,
          "width": 128
        }
      },
      "Parent": {
        "Level": {
          "CategoryLevel": 1,
          "ID": 9,
          "Name": "Restricted",
          "IsSensitive": false,
          "CategoryIcon": {
            "Black_white_url": "https://images.lookwhoscharging.com/categoryicons/bw_9.png ",
            "Coloured_url": "https://images.lookwhoscharging.com/categoryicons/clr_9.png ",
            "height": 128,
            "width": 128
          }
        }
      }
    }
  }
]
