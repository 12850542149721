import { BaseStateComponent } from './../basestate/basestate.component';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { WhoislookupService } from '../../services/whoislookup.service';
import { WhoIsResponse } from '../../models/whoisresponse';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { WhoislookupState } from './whoislookupstate';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-whoislookup',
  templateUrl: './whoislookup.component.html',
  styleUrls: ['./whoislookup.component.scss']
})
export class WhoislookupComponent extends BaseStateComponent<WhoislookupState> implements OnInit, OnDestroy {
  urlFormControl = new FormControl('', {
    validators: Validators.required
  });

  matcher = new MyErrorStateMatcher();
  isCopied1: boolean;

  constructor(private service: WhoislookupService, private loadingService: LoadingService, injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      urlNotTracked: '',
      whoIsResponse: null
    };

    this.restoreState();

    this.urlFormControl.setValue(this.state.urlNotTracked);
  }

  ngOnDestroy() {
    this.state.urlNotTracked = this.urlFormControl.value;
    super.ngOnDestroy();
  }

  onSearch() {
    let url = this.getUrlProtocol(this.urlFormControl.value);

    this.getWhois(url);
  }

  getWhois(url: string): void {
    this.loadingService.setLoading();
    this.service.getWhois(url)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => this.onWhoIsResponse(w));
  }

  onWhoIsResponse(response: WhoIsResponse) {
    this.state.whoIsResponse = response;
  }

  getUrlProtocol(url: string) {
    url = url.toLowerCase();
    if (url.indexOf("http://") != 0 && url.indexOf("https://") != 0) {
      url = 'http://' + url;
    }

    return url;
  }
}



