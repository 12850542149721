import { Component, OnInit } from '@angular/core';
import { Tidmid, tidmidProps } from './tidmid';
import { TidmidService } from '../tidmid.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { StringService } from '../../core/formatting/string.service';
import { finalize } from 'rxjs/operators';
import { DialogService } from '../../core/uiservices/dialog.service';
import { PageInfo } from '../../shared/models/pageinfo';


@Component({
  selector: 'app-managetidmid',
  templateUrl: './managetidmid.component.html',
  styleUrls: ['./managetidmid.component.scss']
})
export class ManagetidmidComponent implements OnInit {
  tidmidRecords: Tidmid[];
  filteredTidmidRecords: Tidmid[];
  tableEditIndex?: number = null;
  originalRecord: Tidmid = null;
  createOrEditRecord: Partial<Tidmid> = null;
  pageIndex = 0;
  pageSize = 30;
  filter = '';
  isCreating = false;
  headers = {
    TIDMID_id: 'TIDMID ID',
    LWC_ID: 'LWC ID',
    MID: 'MID',
    TID: 'TID',
    CreateDateTime: 'Create Date',
    Source: 'Source',
    Comments: 'Comments',
    UpdateDateTime: 'Update Date'
  };
  tidmidProps = tidmidProps;

  constructor(private tidmidService: TidmidService, private notificationService: NotificationService,
    private loadingService: LoadingService, private datetimeService: DateTimeService, private stringService: StringService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.tidmidService.get().subscribe(tidmidRecords => {
      this.setTidmids(tidmidRecords);
    });
  }

  formatDatetime(datetime: string) {
    return this.datetimeService.format(datetime);
  }

  onFilterChange() {
    this.endCreateAndEdit();
    this.pageIndex = 0;
    this.filteredTidmidRecords = this.applyFilter(this.tidmidRecords);
  }

  updateRecord(property: string) {
    (<any>this.createOrEditRecord)[property] = (<HTMLInputElement>event.target).value;
  }

  isSameRecordExists(tidmid: Partial<Tidmid>) {
    let tidmids = this.tidmidRecords.filter(t => this.isIdFieldsSame(tidmid, t));
    return tidmids.length > 1;
  }

  isEditing(index: number) {
    return this.tableEditIndex === index;
  }

  startEditing(index: number) {
    this.endCreating();
    this.tableEditIndex = index;
    this.originalRecord = { ...this.filteredTidmidRecords[index] };
    this.createOrEditRecord = this.filteredTidmidRecords[index];
  }

  confirmEditOrCreate() {
    if (this.isCreating) {
      this.confirmCreate();
    } else {
      this.confirmEdit();
    }
  }

  confirmEdit() {
    if (this.isSameRecordExists(this.createOrEditRecord)) {
      this.notifyAboutSameRecord();
    } else if (this.isEditableFieldsSame(this.createOrEditRecord, this.originalRecord)) {
      this.endEdit();
    } else {
      this.loadingService.setLoading();
      let tidmid: Partial<Tidmid> = this.prepareTidmidForCreateOrEditRequest(this.createOrEditRecord);
      this.tidmidService.update(this.createOrEditRecord.TIDMID_id, tidmid).pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(tidmidResponse => {
          this.updateTidmids(tidmidResponse);
          this.endEdit();
        });
    }
  }

  endEdit() {
    this.tableEditIndex = null;
    this.createOrEditRecord = null;
    this.originalRecord = null;
  }

  endCreateAndEdit() {
    this.endCreating();
    this.endEdit();
  }

  cancelCreateAndEdit() {
    if (!this.isCreating) {
      let editedItemIndex = this.getTidmidIndex(this.createOrEditRecord);
      this.tidmidRecords[editedItemIndex] = this.originalRecord;
      this.setTidmids([...this.tidmidRecords]);
      this.endEdit();
    } else {
      this.endCreating();
    }
  }

  sanitizeValue(value: string | number) {
    return this.stringService.sanitizeValue(value);
  }

  deleteRecord(tidmid: Tidmid) {
    this.endCreateAndEdit();
    const dialogRef = this.dialogService.openDeleteConfirmDialog();

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoading();
        this.tidmidService.delete(tidmid)
          .pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(() => {
            this.tidmidRecords.splice(this.getTidmidIndex(tidmid), 1);
            this.setTidmids([...this.tidmidRecords]);
            this.notificationService.notifySuccess('Record deleted successfully.');
          });
      }
    });
  }

  startCreatingNewRecord() {
    this.endEdit();
    this.isCreating = true;
    this.pageIndex = 0;
    this.filter = '';
    let tidmid = this.createEmptyTidmid();
    this.createOrEditRecord = tidmid;
    this.tidmidRecords.unshift(tidmid);
    this.setTidmids([...this.tidmidRecords]);
  }

  isEditingOrCreating(index: number) {
    return this.isEditing(index) || (this.isCreating && index === 0);
  }

  confirmCreate() {
    if (this.isSameRecordExists(this.createOrEditRecord)) {
      this.notifyAboutSameRecord();
    } else if (this.isEditableFieldsSame(this.createOrEditRecord, this.createEmptyTidmid())) {
      this.notificationService.notifyError('Can\'t create empty record');
    } else {
      let tidmid: Partial<Tidmid> = this.prepareTidmidForCreateOrEditRequest(this.createOrEditRecord);
      this.loadingService.setLoading();
      this.tidmidService.create(tidmid).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(t => {
        this.endCreateAndEdit();
        this.tidmidRecords.unshift(t);
        this.setTidmids([...this.tidmidRecords]);
        this.notificationService.notifySuccess();
      });
    }
  }

  onPageChange(event: PageInfo) {
    this.pageIndex = event.offset;
  }

  private notifyAboutSameRecord() {
    this.notificationService.notifyError('Record with same LWC ID\\MID\\TID already exists');
  }

  private prepareTidmidForCreateOrEditRequest(tidmid: Partial<Tidmid>): Partial<Tidmid> {
    return {
      LWC_ID: tidmid.LWC_ID,
      MID: tidmid.MID,
      TID: tidmid.TID,
      Comments: tidmid.Comments
    };
  }

  private endCreating() {
    if (this.isCreating) {
      this.tidmidRecords.shift();
      this.setTidmids([...this.tidmidRecords]);
      this.createOrEditRecord = null;
    }
    this.isCreating = false;
  }

  private updateTidmids(tidmid: Tidmid) {
    let tidmidIndex = this.getTidmidIndex(tidmid);
    this.tidmidRecords[tidmidIndex] = tidmid;
    this.setTidmids([...this.tidmidRecords]);
  }

  private getTidmidIndex(tidmid: Partial<Tidmid>) {
    return this.tidmidRecords.findIndex(t => t.TIDMID_id === tidmid.TIDMID_id);
  }

  private setTidmids(tidmids: Tidmid[]) {
    this.tidmidRecords = tidmids;
    this.filteredTidmidRecords = this.applyFilter(tidmids);
  }

  private applyFilter(tidmids: Tidmid[]) {
    return tidmids.filter(record => {
      return (record.LWC_ID && record.LWC_ID.toString().toLowerCase().startsWith(this.filter)) ||
        (record.MID && record.MID.toLowerCase().indexOf(this.filter) !== -1) ||
        (record.TID && record.TID.toLowerCase().indexOf(this.filter) !== -1) || !this.filter;
    });
  }

  private createEmptyTidmid(): Tidmid {
    return {
      TIDMID_id: null,
      LWC_ID: null,
      MID: undefined,
      TID: undefined,
      CreateDateTime: '',
      Comments: '',
      Source: '',
      UpdateDateTime: ''
    };
  }

  private isEditableFieldsSame(left: Partial<Tidmid>, right: Partial<Tidmid>) {
    return this.isIdFieldsSame(left, right) &&
      this.stringService.sanitizeValue(left.Comments) === this.stringService.sanitizeValue(right.Comments);
  }

  private isIdFieldsSame(left: Partial<Tidmid>, right: Partial<Tidmid>) {
    return this.stringService.sanitizeValue(left.LWC_ID) === this.stringService.sanitizeValue(right.LWC_ID) &&
      this.stringService.sanitizeValue(left.MID) === this.stringService.sanitizeValue(right.MID) &&
      this.stringService.sanitizeValue(this.stringService.stripNewLine(left.TID)) ===
        this.stringService.sanitizeValue(this.stringService.stripNewLine(right.TID));
  }
}
