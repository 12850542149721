import { ApiClient } from "../core/api/apiclient";
import { apiPathConstants } from "../core/api/apipathconstants";
import { ApiService } from "../core/api/api.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ScrapedData } from "../components/scrapingdatasources/scrapingdatadto";

@Injectable()
export class SrapingDatasourcesService {

    private scraperApiParamName: string = 'scraperName';

    constructor(private apiClient: ApiClient, private apiService: ApiService) { }


    getDataSources(): Observable<string[]> {
        return this.apiClient.get<string[]>(this.apiService.buildUrl(apiPathConstants.dataSources));
    }

    getScrapingData(dataSourse: string): Observable<unknown> {
        return this.apiClient.get<unknown>(this.apiService.buildApiUrlWithParameters(apiPathConstants.screapeStatus,
            { name: this.scraperApiParamName, value: dataSourse }));
    }

    startNewScrape(apiParamValue: string) {
        return this.apiClient.post(this.apiService.buildApiUrlWithParameters(apiPathConstants.startNewScrape,
            { name: this.scraperApiParamName, value: apiParamValue }), null);
    }

    getScrapedData(source: string): Observable<ScrapedData[]> {
        return this.apiClient.get<ScrapedData[]>(this.apiService.buildUrl(apiPathConstants.sitesData + source));
    }
}