import { Injectable } from '@angular/core';

@Injectable()
export class ComponentStateService {
  private states = new Map<string, any>();

  getState<T>(key: string): T {
    return this.states.get(key) as T;
  }

  setState(key: string, value: any) {
    this.states.set(key, value);
  }

  clearState() {
    this.states.clear();
  }
}
