import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { calDetailsResponse, calSplitterResponse } from "../mockjsonresponses/calparserresponse";
import { HttpMethod } from "../../core/coreenums/coreenums";

export function calParserBackendSerice(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url;

    if (cleanUrl.includes(apiPathConstants.calhints) && method === HttpMethod.Post) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200
            }));
            resp.complete();
        });
    }
    if (cleanUrl.includes(apiPathConstants.getCalDetails + '?lwcId=') && method === HttpMethod.Get) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: [...calDetailsResponse]
            }));
            resp.complete();
        })
    }

    let includesCardAcceptorHint = false;
    if (request.body) {
      includesCardAcceptorHint = Object.keys(request.body).includes('CardAcceptorHint');
    }

    if (cleanUrl.includes(apiPathConstants.splitCal) && includesCardAcceptorHint && method === HttpMethod.Post) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: { ...calSplitterResponse }
            }));
            resp.complete();
        })
    }
}
