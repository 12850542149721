import { BaseStateComponent } from '../basestate/basestate.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CalService } from '../../core/formatting/cal.service';
import { IndexMerchantSharedCalRequest, IndexChainRequest } from '../../models/indexmerchantrequest';
import { ChainType } from './chaintype';
import { IndexMerchantantResultStatus, CategoryType, MerchantPresence } from '../../enums/enums';
import { IndexMerchantResult } from '../../models/indexmerchantresult';
import { IndexMerchantCalType } from './indexmerchantcaltype';
import { NotificationService } from '../../core/uiservices/notification.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { IndexMerchantRequest } from '../../models/indexmerchantrequest';
import { IndexMerchantService } from '../../services/indexmerchant.service';
import { DialogService } from '../../core/uiservices/dialog.service';
import { Component, OnInit, Injector, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IndexMerchantState } from './indexMerchantState';
import { NgForm, FormControl, NgModel } from '@angular/forms';
import { SelectedCategory } from '../../shared/category/selectedcategory';
import { ChainSelectComponent } from '../../shared/chainselect/chainselect.component';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { emptyOptionEntry, emptyString } from '../../core/constants/constants';
import { chainTypeStringValues, isItPartOfAChain } from '../../core/constants/chains';
import { calTypes } from '../../core/constants/cal';
import { cloneDeep } from 'lodash';
import * as deepDiff from 'deep-diff';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { JsonViewerCustomComponent } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewercustomcomponent';
import { ChainDto } from '../../models/ausindexingresult';
import { MapService } from '../../core/collections/map.service';
import { CategoryComponent } from '../../shared/category/category.component';
import { PickChainService } from '../../services/pickchain.service';
import { SegmentConfig } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/lwc-ngx-json-viewer.component';
import { BsbService } from '../../services/bsb.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DataSource, SearchSelectBase } from '@oasisdigital/angular-material-search-select';
import { SearchSelectService } from '../../services/searchselect.service';
import { ReferenceService } from '../../services/reference.service';
import { LocationService } from '../../core/services/location.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { RetrieveExperianAddressesService } from '../../services/retrieveexperianaddress.service';
import { Tag } from '../../models/tag';
import { TagsService } from '../../services/tags.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-indexmerchant',
  templateUrl: './indexmerchant.component.html',
  styleUrls: ['./indexmerchant.component.scss']
})
export class IndexMerchantComponent extends BaseStateComponent<IndexMerchantState> implements OnInit, AfterViewInit, OnDestroy {
  private previousFormValue: object = null;
  businessNameIdLabel = 'Business Name ID';
  calLabel = 'Card Acceptor/Locator';
  additionalInfoLabel = 'Address';
  indexButtonText = 'Index Merchant';
  isBusinessNameIdShown = true;
  isUrlShown = true;
  isPhoneShown = true;
  isPartOfChainSelectTheChainAndBranchNameShown = true;
  isEmailShown = true;
  isSharedCal = false;
  isCategorySelectShown = false;
  areOverrideCheckboxesShown = false;
  chainType = ChainType;
  chains: ChainDto[];
  isItPartOfAChain = isItPartOfAChain;
  calTypes = calTypes;
  selectedCategory: SelectedCategory;
  overrideSkippableExistanceChecks = false;
  overrideChainCheck = false;
  isMerchantLoading = false;
  categoryTypes: CategoryType[] = [CategoryType.Expense];
  jsonViewerCustomComponents: JsonViewerCustomComponent[];
  ignoredKeys: string[];
  jsonViewerCssClasses: {};
  chainTypesToInclude: Array<string | 0>;
  segmentConfigs: SegmentConfig[];
  bankBranchCategoryId: number = 5015008;
  locationsCtrl: FormControl;
  locationsDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  experianAddresses: string[] = [];
  internationalCal: number = IndexMerchantCalType.InternationalCal;
  allTags: Tag[] = [];

  @ViewChild('locationSearchSelect') locationSearchSelect: SearchSelectBase;
  @ViewChild('sharedCalChain') sharedCalChain: ChainSelectComponent;
  @ViewChild('chainSelect') chainSelect: ChainSelectComponent;
  @ViewChild('indexMerchantForm', { static: true }) indexMerchantForm: NgForm;
  @ViewChild('branchName') branchName: ElementRef<HTMLInputElement>;
  @ViewChild('categorySelect') categorySelect: CategoryComponent;
  @ViewChild('additionalInfo') additionalInfo: NgModel;

  constructor(private dialogService: DialogService,
    private indexMerchantService: IndexMerchantService, private loadingService: LoadingService,
    private notificationService: NotificationService, private calService: CalService,
    private activatedRoute: ActivatedRoute, private merchantlookupService: MerchantlookupService, injector: Injector,
    private router: Router, private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService,
    private mapService: MapService, private pickChainService: PickChainService, private changeDetectorRef: ChangeDetectorRef,
    private bsbService: BsbService, private searchSelectService: SearchSelectService, private referenceService: ReferenceService,
    private functionService: LocationService, private valueCheckerService: ValueCheckerService,
    private retrieveExperianAddressesService: RetrieveExperianAddressesService, private tagsService: TagsService
  ) {
    super(injector);
    this.locationsCtrl = new FormControl();
  }

  ngOnInit() {
    this.state = {
      businessNameID: <number>null,
      url: '',
      cal: '',
      phone: '',
      additionalInfo: '',
      branchName: '',
      chainType: <number>null,
      chainName: '',
      calType: IndexMerchantCalType.Unknown,
      emailAddress: '',
      messageForUser: '',
      sharedCalChainValue: emptyOptionEntry,
      chainValue: emptyOptionEntry,
      indexMerchantResult: null,
      sharedName: '',
      merchantLookupResponseFirstPart: null,
      merchantLookupResponseSecondPart: null,
      merchantNotFound: false,
      sharedLWCID: <number>null,
      lockSharedLWCID: false,
      categoryId: <number>null,
      bsbs: [],
      allBsbs: [],
      locationNotTracked: null,
      isStreetViewNotAvailable: false,
      businessDescription: '',
      tagIds: [],
      showAllCategories: false
    };

    this.jsonViewerCustomComponents = this.merchantSummaryJsonViewerService.getjsonViewerCustomComponents();
    this.ignoredKeys = this.merchantSummaryJsonViewerService.ignoredKeys;
    this.segmentConfigs = this.merchantSummaryJsonViewerService.getMerchantSummarySegmentConfigs();

    this.activatedRoute.params.subscribe(params => {
      if (params['businessNameID']) {
        this.resetForm(this.indexMerchantForm);
        this.state.businessNameID = parseInt(params['businessNameID']);
      }
      if (params['cal']) {
        this.resetForm(this.indexMerchantForm);
        this.state.cal = params['cal'];
      } else {
        this.restoreState();
      }
      this.setupPageBasedOnCalType();
    });

    this.setupPageBasedOnCalType();

    this.getChains();
    this.setupOverrideExistenceCheckboxHiding();
    this.chainTypesToInclude = this.pickChainService.getUsualChainTypesForDisplay();
    this.getLocations();
  }

  ngAfterViewInit() {
    if (this.categorySelect && this.state.categoryId) {
      this.categorySelect.setCategoryID(this.state.categoryId);
    }
    if (this.locationSearchSelect) {
      this.locationSearchSelect.searchControl.setValue(this.state.locationNotTracked);
      this.changeDetectorRef.detectChanges();
    }

    this.retrieveExperianAddressesService.retrieveExperianAddresses(this.additionalInfo, () => this.experianAddresses)
      .subscribe(response => {
        if (response && response.data.length > 0) {
          this.experianAddresses = response.data;
        }
        else {
          this.experianAddresses = [];
        }
      });
  }

  ngOnDestroy() {
    if (this.sharedCalChain) {
      this.state.sharedCalChainValue = this.sharedCalChain.getValue();
    }

    if (this.chainSelect) {
      this.state.chainValue = this.chainSelect.getValue();
    }
    if (!this.valueCheckerService.isNullOrUndefined(this.locationSearchSelect)) {
      this.state.locationNotTracked = this.locationSearchSelect.searchControl.value;
    }
    super.ngOnDestroy();
  }

  onChainValueChanges(value: string) {
    if (!this.valueCheckerService.isNullOrUndefined(this.chains)) {
      for (let chain of this.chains) {
        if (chain.ChainName === value) {
          this.state.branchName = `${value} ()`;
          this.setBranchNameCaretPosition();
          break;
        } else {
          this.state.branchName = emptyString;
        }
      }
    }
  }

  indexMerchant() {
    switch (this.state.calType) {
      case IndexMerchantCalType.InternationalCal:
        this.createInternationalCal();
        return;
      case IndexMerchantCalType.SharedCal:
        this.createSharedCal();
        return;
    }

    if (!this.isModelValidForIndexingFromBusinessNameId()) {
      return;
    }

    this.previousFormValue = cloneDeep(this.indexMerchantForm.value);

    if (this.state.chainType === ChainType.HeadOffice) {
      this.createHeadOffice();
    } else if (this.state.chainType === ChainType.Branch || this.state.chainType === ChainType.Franchise) {
      this.indexChain();
    } else {
      this.callIndexMerchantApi();
    }
  }

  indexMerchantDisabled() {
    return !(this.state.businessNameID || this.state.cal);
  }

  isPickAChainEnabled() {
    if (this.state.chainType === ChainType.Branch || this.state.chainType === ChainType.Franchise || this.state.chainType === ChainType.BankBranch) {
      return '';
    }

    return ' hidden';
  }

  getIndexMerchantResultClass(indexMerchantantResultStatus: IndexMerchantantResultStatus): string {
    let className = 'danger';

    switch (indexMerchantantResultStatus) {
      case IndexMerchantantResultStatus.Success: {
        className = 'success';
        break;
      }
      case IndexMerchantantResultStatus.Error: {
        className = 'danger';
        break;
      }
      case IndexMerchantantResultStatus.Warning: {
        className = 'warning';
        break;
      }
      case IndexMerchantantResultStatus.AlreadyIndexed: {
        className = 'warning';
        break;
      }
    }

    return className;
  }

  setupPageBasedOnCalType() {
    switch (this.state.calType) {
      case IndexMerchantCalType.InternationalCal:
        this.setTags();
        this.businessNameIdLabel = 'Merchant name';
        this.calLabel = 'International CAL';
        this.additionalInfoLabel = 'Address';
        this.indexButtonText = 'Create International CAL';
        this.isBusinessNameIdShown = true;
        this.isUrlShown = true;
        this.isPhoneShown = false;
        this.isPartOfChainSelectTheChainAndBranchNameShown = false;
        this.state.sharedLWCID = <number>null;
        this.isSharedCal = false;
        this.isCategorySelectShown = true;
        this.chainTypesToInclude = this.pickChainService.getUsualChainTypesForDisplay();
        break;

      case IndexMerchantCalType.SharedCal:
        this.businessNameIdLabel = 'Bank';
        this.calLabel = 'Shared CAL';
        this.additionalInfoLabel = 'Comment';
        this.indexButtonText = 'Create Shared CAL';
        this.isBusinessNameIdShown = false;
        this.isUrlShown = false;
        this.isPhoneShown = false;
        this.isPartOfChainSelectTheChainAndBranchNameShown = false;
        this.isEmailShown = false;
        this.isSharedCal = true;
        this.isCategorySelectShown = true;
        this.chainTypesToInclude = this.pickChainService.getChainTypesForSharedCal();
        if (this.state.sharedCalChainValue && !this.state.sharedCalChainValue.value) {
          this.state.lockSharedLWCID = false;
        }
        break;

      default:
        this.businessNameIdLabel = 'Business Name ID';
        this.calLabel = 'Card Acceptor/Locator';
        this.additionalInfoLabel = 'Address';
        this.indexButtonText = 'Index Merchant';
        this.isBusinessNameIdShown = true;
        this.isUrlShown = true;
        this.isPhoneShown = true;
        this.isPartOfChainSelectTheChainAndBranchNameShown = true;
        this.isEmailShown = true;
        this.state.sharedLWCID = <number>null;
        this.isSharedCal = false;
        this.isCategorySelectShown = false;
        this.selectedCategory = null;
        this.state.categoryId = null;
        this.chainTypesToInclude = this.pickChainService.getUsualChainTypesForDisplay();
    }
  }

  getPickChainSectionClass() {
    let className = 'fx-space-between-center';

    if (!this.isPartOfChainSelectTheChainAndBranchNameShown) {
      className = ' hidden';
    }

    return className;
  }

  reformatCal() {
    this.state.cal = this.calService.getReformatedCal(this.state.cal);
  }

  shouldBusinessNameIDBeNumbersOnly(): boolean {
    let isCalTypeInternationalCal = false;

    if (this.state.calType === IndexMerchantCalType.InternationalCal) {
      isCalTypeInternationalCal = true;
    }

    return !isCalTypeInternationalCal;
  }

  resetForm(form: NgForm) {
    form.resetForm();
    this.state.merchantLookupResponseFirstPart = null;
    this.state.merchantLookupResponseSecondPart = null;
    this.state.merchantNotFound = false;
    this.isMerchantLoading = false;
    this.state.indexMerchantResult = null;
    this.state.chainValue = emptyOptionEntry;
    this.state.sharedCalChainValue = emptyOptionEntry;
    this.state.sharedLWCID = <number>null;
    this.state.categoryId = <number>null;
    this.state.bsbs = [];
    this.state.allBsbs = [];
    this.state.isStreetViewNotAvailable = false;
    this.state.businessDescription = '';
    this.state.locationNotTracked = null;
    this.state.locatedInID = null;
    if (!this.valueCheckerService.isNullOrUndefined(this.locationSearchSelect)) {
      this.locationSearchSelect.searchControl.setValue('');
    }
    this.locationsCtrl.reset();

    if (this.chainSelect) {
      this.chainSelect.setValue('');
    }

    this.setupPageBasedOnCalType();
  }

  goToEnrichMerchant() {
    this.router.navigate(['/enrichmerchant', { lwcId: this.state.indexMerchantResult.LwcId, cleanState: true }]);
  }

  setBranchNameCaretPosition() {
    let elem = this.branchName.nativeElement;
    setTimeout(() => {
      let selectionIndex = elem.value.length - 1;
      elem.focus();
      elem.setSelectionRange(selectionIndex, selectionIndex);
    }, 50);
  }

  handleCategorySelect(selectedCategory: SelectedCategory) {
    if (selectedCategory.isSelected) {
      this.selectedCategory = selectedCategory;
      this.state.categoryId = selectedCategory.category.CategoryID;
    }
  }

  hideOverrideCheckboxes() {
    this.overrideSkippableExistanceChecks = false;
    this.overrideChainCheck = false;
    this.areOverrideCheckboxesShown = false;
  }

  chainSelected(chainName: string) {
    this.categorySelect.unlock();
    this.state.lockSharedLWCID = false;
    if (!chainName) {
      return;
    }
    let chain = this.chains.find((chain) => chain.ChainName == chainName);

    if (chain && chain.CategoryId) {
      this.categorySelect.setCategoryID(chain.CategoryId);
      this.categorySelect.lock();
    }
    if (chain && chain.HQ_LWC_ID) {
      this.state.lockSharedLWCID = true;
      this.state.sharedLWCID = chain.HQ_LWC_ID;
    }
  }

  removeBsb(bsb: string) {
    let indexOfBsb = this.state.bsbs.indexOf(bsb);
    this.state.bsbs.splice(indexOfBsb, 1);
  }

  selectBsb(event: MatAutocompleteSelectedEvent) {
    if (!this.state.bsbs.includes(event.option.viewValue)) {
      this.state.bsbs.push(event.option.viewValue);
    }
  }

  getDropDownForChainType() {
    if (this.state.chainType !== ChainType.HeadOffice && this.chains && this.chains.length > 0) {
      if (this.state.chainType === ChainType.BankBranch) {
        this.chainSelect?.setDataSource(this.chains.filter(t => t.CategoryId === this.bankBranchCategoryId));
        this.ensureBsbs();
      }
      else {
        this.chainSelect?.setDataSource(this.chains.filter(t => this.chainTypesToInclude.includes(t.ChainType)));
      }
    }
  }

  shouldShowTheLocations() {
    if (this.state.calType === IndexMerchantCalType.Unknown
      || this.state.calType === IndexMerchantCalType.OnlineOnly
      || this.state.calType === IndexMerchantCalType.MobileBusiness) {
      return true;
    }
    return false;
  }

  checkForwardSlash(value: any) {
    let url = value.trim();
    if (url.endsWith("/")) {
      this.state.url = url.substring(0, url.length - 1);
    } else {
      this.state.url = url;
    }
  }

  showAllCategories(event: MatCheckboxChange) {
    if (event.checked) {
      this.categorySelect.showAllCategories = true;
      this.categorySelect.filterCategories();
    }
    else {
      this.categorySelect.showAllCategories = false;
      this.categorySelect.filterCategories();
    }
  }


  private ensureBsbs() {
    if (this.state.allBsbs.length > 0) {
      return;
    }
    this.loadingService.setLoading();
    this.bsbService.getBsbs().pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.state.allBsbs = response;
        }
      });
  }

  private getChains() {
    this.merchantlookupService.getChains()
      .subscribe(r => this.chains = r);
  }

  private createInternationalCal() {
    if (!this.checkCal()) {
      return;
    }
    let merchantName = this.getBusinessNameId();
    let indexMerchantRequest: IndexMerchantRequest = {
      TransactionDescription: this.state.cal,
      MerchantName: merchantName ? merchantName.toString() : null,
      Address: this.state.additionalInfo,
      EmailAddress: this.state.emailAddress,
      URL: this.state.url,
      TagIds: this.state.tagIds
    };

    this.setCategoryIfSelected(indexMerchantRequest);

    let indexCALResultObservable: Observable<string> = this.getIndexCALResultObservable(indexMerchantRequest);

    indexCALResultObservable.subscribe(() => {
      this.notificationService.notifySuccess('International CAL successfully created');
    }, (response) => this.addErrorStatusIfExist('Problem creating International CAL. ', response));

  }

  private createSharedCal() {
    if (!this.checkCal()) {
      return;
    }
    let indexMerchantSharedCalRequest: IndexMerchantSharedCalRequest = {
      TransactionDescription: this.state.cal,
      Comments: this.state.additionalInfo
    };

    this.setSharedCalRequestNotMandatoryFields(indexMerchantSharedCalRequest);

    let indexCALResultObservable: Observable<string> =
      this.getIndexCALResultObservable(indexMerchantSharedCalRequest);
    indexCALResultObservable.subscribe(() => {
      this.state.indexMerchantResult = null;
      this.notificationService.notifySuccess('Shared CAL successfully created');
    }, (response) => this.addErrorStatusIfExist('Problem creating Shared CAL. ', response));
  }

  private createHeadOffice() {
    if (!this.state.chainName) {
      this.dialogService.openDialog('Chain Name must be set before a head office can be created');

      return;
    }

    if (this.chains.find(c => c.ChainName === this.state.chainName)) {
      this.dialogService.openDialog(`Chain ${this.state.chainName} is already setup`);

      return false;
    }

    const dialogRef = this.dialogService
      .openConfirmDialog(`You are creating a new head office for chain ${this.state.chainName}. Are you sure?`);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.indexChain();
      }
    });
  }

  private callIndexMerchantApi() {
    let indexMerchantRequest = this.getIndexMerchantRequest();

    this.loadingService.setLoading();

    let indexMerchantResultObservable: Observable<IndexMerchantResult> = this.getIndexMerchantResultObservable(indexMerchantRequest);
    indexMerchantResultObservable.subscribe(this.handleIndexMerchantResult, this.onIndexingFail);
  }

  private handleIndexMerchantResult = (result: IndexMerchantResult) => {
    if (result.LwcId) {
      this.isMerchantLoading = true;
      this.state.merchantLookupResponseFirstPart = null;
      this.state.merchantLookupResponseSecondPart = null;
      this.merchantlookupService.getCals(result.LwcId).pipe(finalize(() => this.isMerchantLoading = false)).subscribe((response) => {
        this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
        this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
        this.jsonViewerCssClasses = this.merchantSummaryJsonViewerService.getQuarantinedOrLockedCssClasses(response);
        this.state.merchantNotFound = (response.LWC_ID == null);
      });
    }

    this.state.indexMerchantResult = result;
    this.areOverrideCheckboxesShown = true;
    this.notificationService.notifySuccess('Success');
  }

  checkMerchantLookupResponse() {
    if (this.state.merchantLookupResponseFirstPart && !this.state.merchantNotFound) {
      return true;
    }
    return false;
  }

  private indexChain() {
    let indexChainRequest = this.getIndexMerchantRequest() as IndexChainRequest;

    if (this.state.chainType === ChainType.HeadOffice) {
      indexChainRequest.ChainName = this.state.chainName;
    } else {
      let chainTypeOfSelectedChain = this.getChainTypeOfSelectedChain();

      if (chainTypeOfSelectedChain === ChainType.Unknown || chainTypeOfSelectedChain === ChainType.NotAChain) {
        this.dialogService.openDialog(`Chain not recognised - ${this.chainSelect.getValue().value}`);

        return;
      } else {
        let isFranchiseMismatch = this.state.chainType === ChainType.Franchise && chainTypeOfSelectedChain !== ChainType.Franchise;
        let isBranchMismatch = this.state.chainType === ChainType.Branch && chainTypeOfSelectedChain !== ChainType.Branch;

        if (chainTypeOfSelectedChain !== ChainType.Both && chainTypeOfSelectedChain !== ChainType.StandardResponse && (isFranchiseMismatch || isBranchMismatch)) {
          this.dialogService.openDialog(`Chain type is incorrect. You specified - ${this.getChainTypeName(this.state.chainType)}
              but ${this.chainSelect.getValue().value} is of type ${this.getChainTypeName(chainTypeOfSelectedChain)}`);

          return;
        }
      }

      indexChainRequest.ChainName = this.chainSelect.getValue().value
      indexChainRequest.BranchName = this.state.branchName;
    }

    this.loadingService.setLoading();
    let indexMerchantResultObservable: Observable<IndexMerchantResult> = this.getIndexMerchantResultObservable(indexChainRequest);
    indexMerchantResultObservable.subscribe(this.handleIndexMerchantResult, this.onIndexingFail);
  }

  private getChainTypeName(chainType: number) {
    return chainTypeStringValues.get(chainType);
  }

  private getChainTypeOfSelectedChain() {
    let chainTypeStringEnum = this.chains.find(c => c.ChainName + ` [${c.ChainType}]` === this.chainSelect.getValue().display).ChainType;
    let result = this.mapService.getKyeByValue(chainTypeStringValues, chainTypeStringEnum);
    return result;
  }

  private getIndexMerchantRequest() {
    let locationSearch;
    if (!this.valueCheckerService.isNullOrUndefined(this.locationSearchSelect)
      && typeof this.locationSearchSelect.searchControl.value === 'object') {
      locationSearch = this.locationSearchSelect.searchControl.value;
    }
    let indexMerchantRequest: IndexMerchantRequest = {
      BusinessNameCatalogueID: <number>this.getBusinessNameId(),
      Address: this.state.additionalInfo,
      OverrideChainChecks: this.overrideChainCheck,
      ChainType: this.state.chainType,
      MerchantPhoneNumber: this.state.phone,
      TransactionDescription: this.state.cal ? this.state.cal : null,
      URL: this.state.url,
      EmailAddress: this.state.emailAddress,
      OnlineOnly: this.state.calType == IndexMerchantCalType.OnlineOnly,
      MobileBusiness: this.state.calType == IndexMerchantCalType.MobileBusiness,
      BSBs: this.state.bsbs.toString(),
      BusinessDescription: this.state.businessDescription,
      FlagStreetViewNotAvailable: this.state.isStreetViewNotAvailable,
      LocatedInID: locationSearch?.value ? locationSearch.value.LocatedIn_ID : null
    };

    if (this.state.calType === IndexMerchantCalType.MobileBusiness) {
      indexMerchantRequest.MerchantPresence = MerchantPresence.MobileMerchant;
    }

    if (this.state.calType === IndexMerchantCalType.OnlineOnly) {
      indexMerchantRequest.MerchantPresence = MerchantPresence.OnlineOnly;
    }

    if (this.overrideSkippableExistanceChecks) {
      indexMerchantRequest.OverrideSkippableExistanceChecks = true;
    }

    return indexMerchantRequest;
  }

  private checkCal() {
    if (this.state.cal) {
      return true;
    }

    let dialogMessage = '';

    switch (this.state.calType) {
      case IndexMerchantCalType.InternationalCal:
        dialogMessage = 'Please enter an International CAL';
        break;
      case IndexMerchantCalType.SharedCal:
        dialogMessage = 'Please enter a CAL which is shared between multiple merchants';
        break;
    }
    this.dialogService.openDialog(dialogMessage);

    return false;
  }

  private isModelValidForIndexingFromBusinessNameId() {
    if (!this.state.businessNameID && !this.state.cal) {
      this.dialogService.openDialog('Please enter either a Business Name ID or a CAL');

      return false;
    }

    if (this.state.chainType && this.state.chainType !== ChainType.NotAChain && this.state.chainType !== ChainType.HeadOffice && !this.chainSelect.getValue().value) {
      this.dialogService.openDialog('Please pick a chain');

      return false;
    }

    if (this.chainSelect && this.chainSelect.getValue() && this.state.branchName == `${this.chainSelect.getValue().value} ()`) {
      this.dialogService.openDialog('Please enter the branch name before indexing');

      return false;
    }

    return true;
  }

  private addErrorStatusIfExist = (errorMessage: string, response: any) => {
    if (response.Result && response.Result.ErrorMessage) {
      errorMessage += `Error = ${response.Result.ErrorMessage}`;
    }

    this.dialogService.
      openDialog(errorMessage);
  }

  private onIndexingFail = (response: any) => {
    this.areOverrideCheckboxesShown = true;
    let errorMessage = 'Invalid status returned from the Indexing API.';

    if (response.StatusCode) {
      errorMessage += ` Status code=${response.StatusCode.toString()}`;
    }

    if (response.error && response.error.error) {
      errorMessage += ` Error=${response.error.error}`;
    }
    this.dialogService.
      openDialog(errorMessage);
  }

  private getBusinessNameId(): number | string {
    return this.state.businessNameID ? this.state.businessNameID : 0;
  }

  private getIndexMerchantResultObservable(indexMerchant: IndexMerchantRequest) {
    let indexMerchantResult: Observable<IndexMerchantResult>;
    let isChainSelected = this.state.chainType && this.state.chainType !== ChainType.NotAChain
      && this.isPartOfChainSelectTheChainAndBranchNameShown;

    if (isChainSelected && this.state.businessNameID) {
      indexMerchantResult = this.indexMerchantService.indexChain(indexMerchant as IndexChainRequest);
    } else if (this.state.businessNameID) {
      indexMerchantResult = this.indexMerchantService.indexMerchantFromBusinessNameId(indexMerchant);
    } else if (this.state.cal) {
      indexMerchantResult = this.indexMerchantService.createCal(indexMerchant);
    }

    indexMerchantResult = indexMerchantResult.pipe(finalize(() => this.loadingService.clearLoading()));

    return indexMerchantResult;
  }

  private getIndexCALResultObservable(indexMerchant: IndexMerchantRequest) {
    let indexCALResult: Observable<string>;
    this.loadingService.setLoading();

    if (this.state.calType == IndexMerchantCalType.InternationalCal) {
      indexCALResult = this.indexMerchantService.indexInternationalCAL(indexMerchant);
    } else if (this.state.calType == IndexMerchantCalType.SharedCal) {
      indexCALResult = this.indexMerchantService.indexSharedCAL(indexMerchant);
    } else {
      this.loadingService.clearLoading();
      throw new Error('Unknown CAL Type selected');
    }

    indexCALResult = indexCALResult.pipe(finalize(() => this.loadingService.clearLoading()));

    return indexCALResult;
  }

  private setSharedCalRequestNotMandatoryFields(indexMerchantSharedCalRequest: IndexMerchantSharedCalRequest) {
    if (this.state.sharedName) {
      indexMerchantSharedCalRequest.SharedName = this.state.sharedName;
    }

    let selectedChain = this.sharedCalChain.getValue();
    if (selectedChain && selectedChain.value) {
      let chain = selectedChain.value;
      let foundChain = this.chains.find((c) => c.ChainName === chain);

      if (foundChain) {
        indexMerchantSharedCalRequest.ChainName = chain;
      }
    }

    indexMerchantSharedCalRequest.LwcID = this.state.sharedLWCID;

    this.setCategoryIfSelected(indexMerchantSharedCalRequest);
  }

  private setupOverrideExistenceCheckboxHiding() {
    this.indexMerchantForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {

      if (this.previousFormValue) {
        let diffResult = deepDiff.diff(this.previousFormValue, value, (path, key) => {
          return path.length === 0 && ['overrideSkippableExistanceChecks', 'overrideChainCheck'].includes(key);
        });

        if (diffResult) {
          this.hideOverrideCheckboxes();
        }
      }
    });
  }

  private setCategoryIfSelected(request: IndexMerchantRequest | IndexMerchantSharedCalRequest) {
    if (this.selectedCategory && this.selectedCategory.isSelected) {
      request.CategoryID = this.selectedCategory.category.CategoryID;
    }
  }

  private getLocations() {
    this.referenceService.getLocations()
      .subscribe(locations => {
        this.locationsDataSource = this.searchSelectService.getDataSource(locations, this.functionService.filterFn, this.functionService.mapFn);
      });
  }

  private setTags() {
    if (this.allTags.length === 0) {
      this.tagsService.getTags().subscribe((tags) => {
        if (tags && tags.length > 0) {
          for (let tag of tags) {
            this.allTags.push({ id: tag.id, label: tag.tagLabel });
          }
        }
      });
    }
  }
}
