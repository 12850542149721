import { bankAccountTransactionTypesQuery, hotChocolate } from '../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { BankAccountTransactionType } from '../models/ausindexingresult';

@Injectable({
  providedIn: "root"
})
export class BankAccountTransactionTypeService {
  constructor(private apollo: Apollo) { }

  getBankAccountTransactionTypes() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<BankAccountTransactionType[]>({
        query: bankAccountTransactionTypesQuery
      })
      .valueChanges;
  }
}
