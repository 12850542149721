import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { batchMerchantResponse } from '../mockjsonresponses/batchmerchantresponse';
import { ErrorResponseGeneratorService } from './errorresponsegeneratorservice';

let errorResponseGeneratorService = new ErrorResponseGeneratorService();

export function searchBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if ((cleanUrl.includes('searchv4/getmerchantbatchbycal'))
    && method === 'POST') {

      let shouldSendError = false;

      let batchMerchantResponse$ = new Observable<HttpEvent<any>>(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: {...batchMerchantResponse}
        }));
  
        resp.complete();
      });

    if (shouldSendError) {
      return errorResponseGeneratorService.sendErrorResponse(batchMerchantResponse$);
    }

    return batchMerchantResponse$;
  }
}