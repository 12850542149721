import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { HttpMethod } from "../../core/coreenums/coreenums";
import { apiUsagesStatsForUsagePlanIdResponse, manageApiUsagesResponse } from "../mockjsonresponses/manageapiusage.response";

export function manageApiUsageBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesApiUsages = false;
  let includesApiUsagesStatsForUsagePlanId = false;
  if (request.body && request.body.query) {
    includesApiUsages = request.body.query.includes("apiUsages");
    includesApiUsagesStatsForUsagePlanId = request.body.query.includes('{\n  data: apiUsagesStatsForUsagePlanId');
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesApiUsages && !includesApiUsagesStatsForUsagePlanId) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        body: { ...manageApiUsagesResponse },
        status: 200
      }));
      resp.complete();
    });
  }

  if (request.body && request.body.query) {
    includesApiUsagesStatsForUsagePlanId = request.body.query.includes('{\n  data: apiUsagesStatsForUsagePlanId');
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesApiUsagesStatsForUsagePlanId) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        body: { ...apiUsagesStatsForUsagePlanIdResponse },
        status: 200
      }));
      resp.complete();
    });
  }
}
