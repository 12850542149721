import { MerchantAudit } from '../../components/merchantaudit/merchantaudit.dto';
import { ExecutionResult } from "graphql";

export let merchantAuditResponse: ExecutionResult<MerchantAudit[]> = {
  data: [
      {
        lwcId: 1000947863,
        indexingType: "NewlyIndexedMerchant",
        indexingTrigger: "IndexingClient",
        createDateTime: "2017-07-30T03:14:00",
        nameOfIndexer: null,
        indexingResult: "Warning",
        indexingResultComment: "Warning:  LWC_ID = 1000649371 is allocated to GoogleID = ChIJybEw5cpC1moRFtDOajNMRFk and the distance between them is 66.2321366800619;  CAL Associated with purge candidate so can't auto purgeIndexed Merchant LWC_ID: 1000947863 Score: 85 in: 38.4900833 seconds;  Missing Data=PHONE | EMAIL",
        indexingScore: 85,
        indexingTimeMS: 37007
      },
      {
        lwcId: 1000947864,
        indexingType: "EnrichedExistingMerchant",
        indexingTrigger: "IndexingClient",
        createDateTime: "2017-07-30T03:13:00",
        nameOfIndexer: null,
        indexingResult: "Success",
        indexingResultComment: "Success:  1000947863 enriched with a score of 85 in 23s;  Missing Data=PHONE | EMAIL",
        indexingScore: 65,
        indexingTimeMS: 22949
      },
      {
        lwcId: 1000947866,
        indexingType: "EnrichedExistingMerchant",
        indexingTrigger: "AutoIndexer",
        createDateTime: "2017-05-22T22:30:00",
        nameOfIndexer: null,
        indexingResult: "Warning",
        indexingResultComment: "Warning: 1000947863 enriched with a score of 85 in 8s; Failed Existence Check: Merchant with Name=CHATIME MELBOURNE CENTRAL BusinessNameCatalogueID=29138580 already indexed with an LWC_ID=1000947863;  CAL 'CHATIME MELBOURNE' matched to Mastercard CAL 'CHATIME MELBOURNE VIC' in the LWC Mastercard Repository in 925 ms;  Added Mastercard Category Data to indexed Merchant in 0 ms in 0 ms;  Added Mastercard Category Data to indexed Merchant in 0 ms;  Missing Data=PHONE | EMAIL",
        indexingScore: 85,
        indexingTimeMS: 8334
      },
      {
        lwcId: 1000947880,
        indexingType: "EnrichedExistingMerchant",
        indexingTrigger: "IndexingClient",
        createDateTime: "2017-12-02T03:35:00",
        nameOfIndexer: 'LWC System',
        indexingResult: "Success",
        indexingResultComment: "Success: 1000947863 enriched with a score of 85 in 9s;  Missing Data=PHONE | EMAIL;",
        indexingScore: 85,
        indexingTimeMS: 8661
      },
      {
        lwcId: 1000947890,
        indexingType: "EnrichedExistingMerchant",
        indexingTrigger: "IndexingClient",
        createDateTime: "2020-11-27T18:40:00",
        nameOfIndexer: 'LWC_System_Data',
        indexingResult: "Warning",
        indexingResultComment: "Warning: 1000947863 enriched as CHATIME MELBOURNE CENTRAL Score: 85 in 2s; Enriching 1000947863; Default category set to Food/Drink >> Other >> Cafe; Warnings during Validation (The Merchant name for the Chain location is all capitals CHATIME MELBOURNE CENTRAL; The Merchant name for the Chain location is not in the correct format CHATIME MELBOURNE CENTRAL). Check the Validation report for more information; Missing Data=PHONE | EMAIL;",
        indexingScore: 85,
        indexingTimeMS: 1578
      },
      {
        lwcId: 1000947900,
        indexingType: "NewlyIndexedMerchant",
        indexingTrigger: "IndexingClient",
        createDateTime: "2019-11-27T18:40:00",
        nameOfIndexer: null,
        indexingResult: "Warning",
        indexingResultComment: "Warning: 1000947863 enriched as CHATIME MELBOURNE CENTRAL Score: 95 in 2s; Enriching 1000947863; Default category set to Food/Drink >> Other >> Cafe; Warnings during Validation (The Merchant name for the Chain location is all capitals CHATIME MELBOURNE CENTRAL; The Merchant name for the Chain location is not in the correct format CHATIME MELBOURNE CENTRAL). Check the Validation report for more information; Missing Data=PHONE;",
        indexingScore: 95,
        indexingTimeMS: 0
      },
    ]
};
