import { apiPathConstants } from './../../core/api/apipathconstants';
import { getlocatedinresponse } from '../mockjsonresponses/getlocatedinresponse';
import { categories } from '../mockjsonresponses/categories';
import { getEnrichMerchantResponse } from '../mockjsonresponses/getenrichmerchantresponse';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { ausIndexedComments, updatedAusIndexedComment } from '../mockjsonresponses/ausindexedcomments.response';

export function enrichMerchantBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes('merchant/enrich') && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...getEnrichMerchantResponse}
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('reference/categories') && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...categories]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('reference/locations') && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...getlocatedinresponse]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('reference/refreshcategories') && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...categories]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.indexingComment) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...ausIndexedComments]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.indexingComment) && method === HttpMethod.Delete) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.indexingComment) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...updatedAusIndexedComment }
      }));
      resp.complete();
    });
  }
}

