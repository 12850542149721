import { Component, Input, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ValidationStatsDetailDisplay } from '../merchantvalidationstats.models';

@Component({
  selector: 'app-validationstatsdetail',
  templateUrl: './validationstatsdetail.component.html',
  styleUrls: ['./validationstatsdetail.component.scss']
})
export class ValidationStatsDetailComponent implements OnInit {
  @Input() validationStatsDetail: {items: ValidationStatsDetailDisplay[]; totalCount: number;}
  columnMode = ColumnMode;
  message: string;
  validationStatsDetailDisplay: ValidationStatsDetailDisplay[] = [];
  showMessage: boolean = false;

  constructor() { }

  ngOnInit() {
    this.validationStatsDetailDisplay = this.validationStatsDetail.items.slice(0, 50);
    this.message = `Displaying first 50 / ${this.validationStatsDetail.totalCount} records. Export to Excel to see full details.`;
    if (this.validationStatsDetail.totalCount > 50) {
      this.showMessage = true;
    }
    else {
      this.showMessage = false;
    }
  }

}
