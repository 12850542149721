import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { AusQuarantinedMerchantDetails } from '../components/quarantinedmerchants/ausquarantinedmerchantdetails';
import { Observable } from 'rxjs';
import { apiPathConstants } from '../core/api/apipathconstants';
import { AusQuarantinedCal, AllocateCal } from '../components/quarantinedcals/ausquarantinedcals';
import { DataSource } from '@oasisdigital/angular-material-search-select';
import { SearchSelectService } from './searchselect.service';
import { NameValue } from '../core/models/namevalue';
import { EnrichMerchantRequest } from '../models/enrichmerchantrequest';
import { WithLwcId } from '../core/models/withlwcid';


@Injectable({
  providedIn: 'root',
})

export class QuarantinedService {

  constructor(private apiService: ApiService, private apiClient: ApiClient, private searchSelectService: SearchSelectService) { }

  getQuarantinedMerchants(): Observable<AusQuarantinedMerchantDetails[]> {
    return this.apiClient.get<AusQuarantinedMerchantDetails[]>(this.apiService
      .buildUrl(apiPathConstants.quarantinedMerchants))
  }

  getQuarantinedCals(): Observable<AusQuarantinedCal[]> {
    return this.apiClient.get<AusQuarantinedCal[]>(this.apiService
      .buildUrl(apiPathConstants.quarantinedCals))
  }

  getIndexerNamesDataSource(referenceData: NameValue<Number>[]): DataSource {
    if (!referenceData) {
      return;
    }
    let filterFn = (c: NameValue<Number>, term: string) => c.name.toLowerCase().includes(term);
    let mapFn = (nameValue: NameValue<Number>) => ({
      value: nameValue.value,
      display: nameValue.name,
      details: ''
    });

    return this.searchSelectService.getDataSource(referenceData, filterFn, mapFn);
  }

  allocateCals(cals: AllocateCal[]) {
    return this.apiClient.post<unknown>(this.apiService.buildUrl(apiPathConstants.quarantinedcals), cals);
  }

  prepareEnrichMerchantRequests(merchants: WithLwcId[]) {
    let requests: Partial<EnrichMerchantRequest>[] = [];
    for (let value of merchants) {
      requests.push({ LWC_ID: value.LWC_ID, setBsbs: false });
    }

    return requests;
  }

  getSearchcount(cals: any) {
    return this.apiClient.post(this.apiService.buildUrl(apiPathConstants.getSearchCount), cals);
  }
}
