import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { addressVerificationResponse } from '../mockjsonresponses/addressverification.response';

export function addressVerificationBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?');
  let includeAddressVerification = false;

  if (request.body && request.body.query) {
    includeAddressVerification = request.body.query.includes('addressVerification');
  }

  if (cleanUrl[0].includes(apiPathConstants.graphql) && method === 'POST' && includeAddressVerification) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: addressVerificationResponse
      }));

      resp.complete();
    });
  }

  if (cleanUrl[0].includes(apiPathConstants.eventingVerify) && cleanUrl[1] == 'property=address' && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));

      resp.complete();
    });
  }
}
