import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { apiPathConstants } from '../core/api/apipathconstants';
import { PastSearchesByFixedCalTransactionType } from '../components/pastsearch/pastsearch.response';
import { PastSearchesByCalRequest } from '../components/pastsearch/pastsearch.request';

@Injectable()
export class PastSearchService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) {}

  getPastSearches(pastSearchesRequest: PastSearchesByCalRequest) {
    return this.apiClient.post<PastSearchesByFixedCalTransactionType[]>(this.apiService
      .buildUrl(apiPathConstants.pastSearchesByCal), pastSearchesRequest);
  }
}
