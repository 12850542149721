import { MerchantNamesResponse, MerchantWordSuggestionResponse, EnumMerchantWordOperations, MerchantNameSearchResponse } from "../../components/merchantnamesearch/merchantnamesearch.response";

export let merchantNamesResponse: MerchantNamesResponse[] = [
  {
    MerchantName: 'Merchant Name 1',
    IsPrimary: true,
    Suburb: 'St Kilda West',
    Source: '',
    State: '',
    PostCode: ''
  },
  {
    MerchantName: 'Merchant Name 2',
    IsPrimary: false,
    Suburb: 'Spring Hil',
    Source: '',
    State: '',
    PostCode: ''
  },
  {
    MerchantName: 'Merchant Name 3',
    IsPrimary: false,
    Suburb: 'Indooroopilly',
    Source: '',
    State: '',
    PostCode: ''
  },
  {
    MerchantName: 'Merchant Name 4',
    IsPrimary: true,
    Suburb: 'Corinda',
    Source: '',
    State: '',
    PostCode: ''
  },
  {
    MerchantName: 'Merchant Name 5',
    IsPrimary: false,
    Suburb: 'Dutton Park',
    Source: '',
    State: '',
    PostCode: ''
  }
];

export let merchantWordResponse: MerchantWordSuggestionResponse = {
  OriginalMerchantName: '',
  Query: 'Query',
  MerchantWordOperations: [
    {
      Word: 'The',
      Operation: EnumMerchantWordOperations.Optional,
    },

    {
      Word: 'Trustee',
      Operation: EnumMerchantWordOperations.Optional,
    },
    {
      Word: 'For',
      Operation: EnumMerchantWordOperations.Optional,
    },
    {
      Word: 'INSTACO',
      Operation: EnumMerchantWordOperations.Optional,
    },
    {
      Word: 'GROUP',
      Operation: EnumMerchantWordOperations.Must,
    },
    {
      Word: 'Connecting a datepicker to an input',
      Operation: EnumMerchantWordOperations.Must,
    },
    {
      Word: 'The control\'s UI varies in',
      Operation: EnumMerchantWordOperations.Optional,
    }
  ]
};

export let merchantNameSearchResponse: MerchantNameSearchResponse[] = [
  {
    FixedCal: 'INSTACO PTY LTD COOPERS PLAIN',
    CountOfOptionalWords: 1
  },
  {
    FixedCal: 'WBC ATM INSTACOINS ESTONIA ST JULIANS EST USD',
    CountOfOptionalWords: 15
  },
  {
    FixedCal: 'INSTACOINS.COM +35627781035',
    CountOfOptionalWords: 10
  },
  {
    FixedCal: 'JULIANS EST USD',
    CountOfOptionalWords: 21
  },
  {
    FixedCal: 'PLAIN',
    CountOfOptionalWords: 8
  },
  {
    FixedCal: 'USD',
    CountOfOptionalWords: 0
  },
]
