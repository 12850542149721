export let getAddressesByAbnAcnResponse = [
  {
    BusinessAddressesID: 5623332,
    AddressSource: 'ABR',
    AddressSourceId: 1,
    ABN: 12345678912,
    ACN: 123456789,
    AddressLine1: '17 Johnson St',
    AddressLine2: '19 Mark St',
    AddressSuburb: 'Kiama Downs',
    LocationEndDate: '11/Mar/2019 10:46 AM',
    LocationStartDate: '11/Mar/2019 10:46 AM',
    AddressStateCd: 'TestCD',
    AddressPostcode: 142105,
    AddressCountryCd: 'TestCountryCD',
    Latitude: 22901923,
    Longitude: 98596348,
    DPID: 'test',
    PhoneNumber: 88005553535,
    MobileNumber: 990088006453,
    Email: 'test@example.com',
    LocationURL: 'abc.net.au',
    LatLonPrecision: 'test precision'
  },
  {
    BusinessAddressesID: 1623422,
    AddressSource: 'NBR',
    AddressSourceId: 3,
    ABN: 98765432112,
    ACN: 123654123,
    AddressLine1: '19 Mark St',
    AddressLine2: '17 Johnson St',
    AddressSuburb: 'Kiama Highs',
    LocationEndDate: '11/Mar/2019 10:46 AM',
    LocationStartDate: '11/Mar/2019 10:46 AM',
    AddressStateCd: 'TestCD',
    AddressPostcode: 142105,
    AddressCountryCd: 'TestCountryCD',
    Latitude: 22901923,
    Longitude: 98596348,
    DPID: 'test',
    PhoneNumber: 88005553535,
    MobileNumber: 990088006453,
    Email: 'test@example.com',
    LocationURL: 'abc.net.au',
    LatLonPrecision: 'test precision'
  }
];



