import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { deleteToDraftResponse, updateToDraftResponse, categoryDataSourceNamesResponse, categoryStandardiserDraftResponse, categoryStandardiserTreeResponse, updateOrRejectCategoryStandardiserDraftResponse } from "../mockjsonresponses/categorystandardiserresponce";
import { HttpMethod } from "../../core/coreenums/coreenums";
import { categoryDataSourcesResponse } from "../mockjsonresponses/categorymappingrulesresponse";

export function categoryStandardiserService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];
  if (cleanUrl.includes(apiPathConstants.getActivedDrafts) && method === HttpMethod.Get) {
      return new Observable(resp => {
          resp.next(new HttpResponse({
              status: 200,
              body: [...categoryStandardiserDraftResponse]
          }));
          resp.complete();
      });
  }

  if ((cleanUrl.includes(apiPathConstants.updateCategoryStandardiser)
        && !cleanUrl.includes(apiPathConstants.createCategoryStandardiserUpdateToDraft)
        || cleanUrl.includes(apiPathConstants.rejectCategoryStandardiser)) && method === HttpMethod.Post) {
      return new Observable(resp => {
          resp.next(new HttpResponse({
              status: 200,
              body: [...updateOrRejectCategoryStandardiserDraftResponse]
          }));
          resp.complete();
      });
  }



  if (cleanUrl.includes(apiPathConstants.createCategoryStandardiserUpdateToDraft) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: updateToDraftResponse
      }))
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.deleteCategoryStandardiserDeleteToDraft) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: deleteToDraftResponse
      }))
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.categoryDataSources) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...categoryDataSourceNamesResponse]
      }))
      resp.complete();
    });
  }

  if(cleanUrl.includes(apiPathConstants.externalCategoriesDataSource) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...categoryDataSourcesResponse]
      }))
      resp.complete();
    });
  }

  if(cleanUrl.includes(apiPathConstants.getCategoryStandardiserTree) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...categoryStandardiserTreeResponse}
      }))
      resp.complete();
    });
  }
}
