import { Component, OnInit, ViewChild } from '@angular/core';
import { NameValue } from '../../core/models/namevalue';
import { CategoryType, MerchantPresence, merchantPresenceStringValuesWithSpaces } from '../../enums/enums';
import { CategoriesComponentService } from '../../categories/categoriescomponent.service';
import { CategoryLevelNumber } from '../../categories/categorylevelnumber';
import { ExternalCategory, FlatCategory } from '../../categories/flatcategory';
import { NotificationService } from '../../core/uiservices/notification.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { CategoryService } from '../../services/category.service';
import { CategoryComponent } from '../../shared/category/category.component';
import { SelectedCategory } from '../../shared/category/selectedcategory';
import { StringEnumService } from '../../core/uiservices/stringenum.service';
import { merchantPresenceDisplayValues } from '../../enums/enumdisplayvalues';
import { PageInfo } from '../../shared/models/pageinfo';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Category, CategoryDraft, CategoryStandardiser, Csv, Data, DataSource, ExternalCategoryMappingData, ExternalCategoryNames, ExternalMappingRequest, Mapping, MappingObject } from '../../models/managecategory';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CategoryMappingDialogComponent } from './category-mapping-dialog/category-mapping-dialog.component';
import { LoginStateService } from '../../core/auth/loginstate.service';
import { RoleType } from '../../users/userenums';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { propertyOf } from '../../core/services/reflection.service';

@Component({
  selector: 'app-managecategories',
  templateUrl: './managecategories.component.html',
  styleUrls: ['./managecategories.component.scss']
})
export class ManageCategoriesComponent implements OnInit {
  categoryTypes: NameValue<CategoryType>[];
  categoryLevelNumber = CategoryLevelNumber;
  categoryLevel1: boolean;
  categoryLevel2: boolean;
  categoryLevel3: boolean;
  isEditing = false;
  isCreating = false;
  isCategoryLevelOneCreating = false;
  parentLevelCategories: FlatCategory[];
  categoryIdFirstHalf: string;
  originalCategory: FlatCategory;
  isAdmin: boolean;

  pageIndex: number;
  take: number;
  skip: number;
  orderBy: string;
  selected: any[] = [];
  categoryDrafts: Category[] = [];
  categorySandardiser: Mapping[] = [];
  CategoryDraftIds: any[] = [];
  categoryApproveObject: CategoryDraft;
  mappingApproveObject: MappingObject[];
  catgeoryStandardizedObject: CategoryStandardiser[];
  orderDirection: string;
  columnMode = ColumnMode;
  selectionType = SelectionType;
  comments: string;
  data: Category[] = [];
  form: Data;
  ids: number[] = [];
  mapping_ids: number[] = [];
  draft: boolean;
  externalCategories: ExternalCategory[];
  addExternalCategory: boolean;
  dataSources: DataSource[];
  ExternalCategoryNames: ExternalCategoryNames[];
  selectedDataSource: string;
  selectedExternalCategoryName: String;
  externalMappindCategoryData: ExternalMappingRequest;
  externalCategoryId: string;
  addButton: boolean = false;
  selectedExternalCategoryID: number;
  categories: FlatCategory[];
  chooseVersion: boolean;

  success: boolean;
  showDraft: boolean;
  dialogConfig = new MatDialogConfig();
  modalDialog: MatDialogRef<CategoryMappingDialogComponent, any> | undefined;

  datatableHeaders = {
    operationType: "Operation Type",
    categoryType: "Category Type",
    CategoryId: "Category Id",
    categoryName: "Category Name",
    categoryDataSource: "Category DataSource",
    externalCategoryId: "External Category Id",
    externalCategoryName: "External Category Name"
  };
  datatableKeys = {
    operationType: propertyOf<Csv>('OperationType'),
    categoryType: propertyOf<Csv>('CategoryType'),
    CategoryId: propertyOf<Csv>('CategoryID'),
    categoryName: propertyOf<Csv>('CategoryName'),
    categoryDataSource: propertyOf<Csv>('CategoryDataSource'),
    externalCategoryId: propertyOf<Csv>('ExternalCategoryId'),
    externalCategoryName: propertyOf<Csv>('ExternalCategoryName')
  };
  private optionDatatableHeadersAndKeys = {
    headers: [
      this.datatableHeaders.operationType,
      this.datatableHeaders.categoryType,
      this.datatableHeaders.CategoryId,
      this.datatableHeaders.categoryName,
      this.datatableHeaders.categoryDataSource,
      this.datatableHeaders.externalCategoryId,
      this.datatableHeaders.externalCategoryName
    ],
    keys: [
      this.datatableKeys.operationType,
      this.datatableKeys.categoryType,
      this.datatableKeys.CategoryId,
      this.datatableKeys.categoryName,
      this.datatableKeys.categoryDataSource,
      this.datatableKeys.externalCategoryId,
      this.datatableKeys.externalCategoryName
    ]
  };

  private csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionDatatableHeadersAndKeys };


  @ViewChild('csvExport') csvExportComponent: CsvExportComponent;
  @ViewChild('categoryComponent', { static: true }) categoryComponent: CategoryComponent;
  @ViewChild('myTable') table: DatatableComponent;

  merchantPresenceTypesArray = this.stringEnumService.getStringValueAndDisplayValueArray(merchantPresenceDisplayValues, merchantPresenceStringValuesWithSpaces);

  constructor(private categoriesComponentService: CategoriesComponentService, private categoryService: CategoryService,
    private notificationService: NotificationService, private loadingService: LoadingService, private stringEnumService: StringEnumService,
    public dialog: MatDialog, private loginStateService: LoginStateService, private csvComponentService: CsvComponentService) {
    this.pageIndex = 0;
    this.take = 25;
    this.skip = 0;
    this.isAdmin = false;
    this.selected = [];
    this.comments = '';
    this.draft = false;
    this.externalCategories = [];
    this.addExternalCategory = false;
    this.dataSources = [];
    this.ExternalCategoryNames = [];
    this.selectedDataSource = '';
    this.externalMappindCategoryData = null;
    this.addButton = false;
    this.selectedExternalCategoryID = 0;
    this.categories = [];
    this.catgeoryStandardizedObject = [];
    this.success = false;
    this.mappingApproveObject = [];
    this.externalCategoryId = '';
    this.categoryLevel1 = false;
    this.categoryLevel2 = false;
    this.chooseVersion = true;
    this.showDraft = true;
  }

  ngOnInit() {
    this.getApplicationUsers();
    this.categoryTypes = this.categoriesComponentService.categoryTypes;
    this.setCategoryStandardiserTree();
  }
  setCategoryStandardiserTree() {
    if (this.chooseVersion) {
      this.categoryService.getCategoryDraft().subscribe(data => {
        this.data = data;
        for (let i of this.data) {
          if (i.CategoryType == 'Expense' || i.CategoryType == 'Income') {
            i.CategoryType = 'Category';
          }
        }
      });
    }
    if (!this.chooseVersion) {
      this.categoryService.getCategoryStandardiserActivedDrafts().subscribe(data => {
        const b = <Array<any>>data;
        for (let i of b) {
          if (i.CategoryDataSourceType) {
            i.CategoryType = 'Mapping';
          }
          if (i.LWCCategoryID) {
            i.CategoryID = i.LWCCategoryID;
          }
          if (i.LWCCategoryName) {
            i.CategoryName = i.LWCCategoryName;
          }
          if (i.ExternalCategoryType) {
            i.ExternalCategoryId = i.ExternalCategoryType;
          }
          if (i.CategoryDataSourceType) {
            i.ExternalCategoryName = i.CategoryDataSourceType;
          }
        }
        const result: any[] = [];
        result.push(...b);
        this.data = result;
      });
    }
  }

  changeCategoryTypeAndResetCategories() {
    this.categoryComponent.clear();
    this.categoryComponent.filterCategories();
  }

  handleCategorySelect(selectedCategory: SelectedCategory) {
    if (selectedCategory.category != null) {
      if (selectedCategory.isSelected) {
        this.addButton = true;
        this.addExternalCategory = false;
        this.originalCategory = this.categoriesComponentService.getCategory(
          this.categoryComponent.categoryConfig, selectedCategory.category.CategoryID);
        this.externalCategories = this.originalCategory.ExternalCategories;
        if (selectedCategory.category.CategoryDraftID != 0) {
          this.draft = true;
        } else {
          this.draft = false;
        }
        for (let i of this.externalCategories) {
          if (i.Id == 0) {
            i.status = 'Published';
          } else {
            i.status = 'Draft';
          }
        }
      } else {
        this.setCategoryStandardiserTree();
        this.originalCategory = null;
        this.addButton = false;
      }
      this.isCategoryLevelOneCreating = false;
      this.cancelEditingOrCreating(false);
      if (selectedCategory.isSelected) {
        this.parentLevelCategories = this.categoriesComponentService.findParentCategories(
          this.categoryComponent.categoryConfig.categories, selectedCategory.category.CategoryLevel);
      }
    }
  }

  downloadCsvFile() {
    if (this.chooseVersion) {
      this.loadingService.setLoading();
      this.categoryService.getCategoryDraft().pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((response) => {
          if (response) {
            this.data = response;
            if (this.data)
              this.csvExportComponent.data = response;
            this.csvExportComponent.options = this.csvOptions;
            this.csvExportComponent.onDownload();
          }
        });
    }
    if (!this.chooseVersion) {
      this.categoryService.getCategoryStandardiserActivedDrafts().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(data => {
        const b = <Array<any>>data;
        for (let i of b) {
          if (i.CategoryDataSourceType) {
            i.CategoryType = 'Mapping';
          }
          if (i.LWCCategoryID) {
            i.CategoryID = i.LWCCategoryID;
          }
          if (i.LWCCategoryName) {
            i.CategoryName = i.LWCCategoryName;
          }
          if (i.ExternalCategoryType) {
            i.ExternalCategoryId = i.ExternalCategoryType;
          }
          if (i.CategoryDataSourceType) {
            i.ExternalCategoryName = i.CategoryDataSourceType;
          }
        }
        const result: any[] = [];
        result.push(...b);
        this.data = result;
      });
      this.csvExportComponent.data = this.data;
      this.csvExportComponent.options = this.csvOptions;
      this.csvExportComponent.onDownload();
    }
  }

  isCsvButtonDisabled() {
    return this.data.length > 0;
  }

  prepareUnicodeForDisplay() {
    const category = this.categoryComponent.categoryConfig.selectedCategoryCloned;
    return this.categoriesComponentService.getCategoryEmoji(category);
  }

  getApplicationUsers() {
    let isAdministrator = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin]);
    if (isAdministrator == false) {
      this.isAdmin = false;
    } else {
      this.isAdmin = true;
    }
  }


  edit() {
    this.isEditing = true;
    if (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel !== CategoryLevelNumber.One) {
      let categoryId = this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID.toString();
      //this.categoryIdSecondHalf = categoryId.substring(categoryId.length - 3);
      this.categoryIdFirstHalf = categoryId.substring(0, categoryId.length - 3);
    }
  }

  cancelEditingOrCreating(isCloningNeeded: boolean) {
    if (isCloningNeeded) {
      this.categoryComponent.categoryConfig.selectedCategoryCloned = { ...this.originalCategory };
    }
    this.isEditing = false;
    this.categoryIdFirstHalf = null;
    //this.categoryIdSecondHalf = null;
    if (this.isCreating && this.isCategoryLevelOneCreating) {
      this.categoryComponent.categoryConfig.selectedCategoryCloned = null;
      this.isCategoryLevelOneCreating = false;
    }
    if (this.isCreating) {
      this.isCreating = false;
    }
  }

  getExternalCategoryMapping() {
    this.addExternalCategory = true;
    this.loadingService.setLoading();
    this.categoryService.getDataSource()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.dataSources = response;
      });
  }
  changeCategory(value: string) {
    this.selectedDataSource = value;
    this.loadingService.setLoading();
    this.categoryService.getAllExternalCategories(this.selectedDataSource)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.ExternalCategoryNames = response;
      });
  }

  changeExternalCategory(id: string) {
    this.externalCategoryId = id;
  }

  saveExternalMappingCategory() {
    let external_name = this.ExternalCategoryNames.filter(x => x.CategoryId == this.externalCategoryId).map(x => x.CategoryName);
    this.externalMappindCategoryData = {
      Id: 0,
      CategoryStandardiserID: 0,
      CategoryDataSource: this.selectedDataSource,
      CategoryDataSourceType: external_name.toString(),
      LWCCategoryID: this.originalCategory.CategoryID,
      LWCCategoryName: this.originalCategory.CategoryName,
      ExternalCategoryType: this.externalCategoryId
    }
    this.loadingService.setLoading();
    this.categoryService.addExternalMapping(this.externalMappindCategoryData)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('External Category Added');
        this.loadingService.setLoading();
        this.getCategories();
        this.setCategoryStandardiserTree();
        this.categoryComponent.refreshCategories();
        this.loadingService.clearLoading();
      }, (error) => {
        this.notificationService.notifyError(error);
      });

    this.cancel();
  }

  getCategories() {
    this.loadingService.setLoading();
    this.categoryService.getCategories().subscribe(cat => {
      this.categories = cat;
      let v = this.categories.filter(x => x.CategoryID == this.originalCategory.CategoryID).map(x => x);
      if (v) {
        this.externalCategories = null;
        for (let i of v) {
          this.externalCategories = i.ExternalCategories;
        }
        for (let i of this.externalCategories) {
          if (i.Id == 0) {
            i.status = 'Published';
          } else {
            i.status = 'Draft';
          }
        }
      }
    });
    this.loadingService.clearLoading();
  }

  cancel() {
    this.selectedDataSource = '';
    this.selectedExternalCategoryName = '';
    this.addExternalCategory = false;
  }

  openDialog(value: ExternalCategoryMappingData, type: string) {
    if (type == 'edit') {
      this.dialogConfig.data = {
        dataType: 'edit',
        data: value,
      }
      this.dialogConfig.height = "350px";
      this.dialogConfig.width = "500px";
    }
    else if (type == 'delete') {
      this.dialogConfig.data = {
        dataType: 'delete',
        data: value,
      }
      this.dialogConfig.height = "315px";
      this.dialogConfig.width = "450px";
    }
    this.dialogConfig
    this.dialogConfig.id = "manage-client-dialog-component";
    this.dialog.open(CategoryMappingDialogComponent, this.dialogConfig).afterClosed().subscribe(x => {
      this.loadingService.setLoading();
      this.getCategories();
      this.setCategoryStandardiserTree();
      this.categoryComponent.refreshCategories();
      this.loadingService.clearLoading();

    });
  }

  isLevelTwoButtonDisabled() {
    if (this.draft == true) {
      return true;
    }
    if (!this.categoryComponent.categoryConfig.selectedCategoryPerLevel.get(CategoryLevelNumber.One)) {
      return true;
    }
    return false;
  }

  isLevelThreeButtonDisabled() {
    if (this.draft == true) {
      return true;
    }
    if (!this.categoryComponent.categoryConfig.selectedCategoryPerLevel.get(CategoryLevelNumber.Two)) {
      return true;
    }
    return false;
  }
  numberOfPageChanged(pageInfo: PageInfo) {
    this.pageIndex = pageInfo.offset;
    this.skip = pageInfo.offset * this.take;
  }

  onSort(event: any) {
    this.orderBy = event.sorts[0].prop;
    this.orderDirection = event.sorts[0].dir;
  }

  onSelect(value: any) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(value);
  }

  approve() {
    for (let i of this.selected) {
      if (i['CategoryDraftID']) {
        if (this.ids.includes(i.CategoryDraftID)) {
          return false;
        }
        else {
          this.ids.push(i.CategoryDraftID);
        }
        this.categoryApproveObject = ({
          'CategoryDraftIds': this.ids,
          'Comments': this.comments
        });
      } else {
        this.catgeoryStandardizedObject.push({
          OpeartionType: i.OperationType,
          CategoryStandardiserID: i.CategoryStandardiserID,
          CategoryDataSource: i.CategoryDataSource,
          CategoryDataSourceType: i.CategoryDataSourceType,
          LWCCategoryID: i.LWCCategoryID,
          ExternalCategoryType: i.ExternalCategoryType
        });
      }
    }
    if (this.ids.length > 0) {
      this.loadingService.setLoading();
      this.categoryService.approveCategoryDraft(this.categoryApproveObject)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(() => {
          this.success = true;
          this.selected = [];
          this.comments = '';
          this.ids = [];
        });
    } if (this.catgeoryStandardizedObject.length > 0) {
      this.loadingService.setLoading();
      this.categoryService.approveStandardizedMapping(this.catgeoryStandardizedObject)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(() => {
          this.success = true;
          this.selected = [];
          this.comments = '';
          this.ids = [];
          this.catgeoryStandardizedObject = [];
        });
    }
    setTimeout(() => {
      this.loadingService.setLoading();
      this.setCategoryStandardiserTree();
      this.categoryComponent.refreshCategories();
      this.loadingService.clearLoading();
    }, 1000);
  }

  reject() {
    for (let i of this.selected) {
      if (i['CategoryDraftID']) {
        if (this.ids.includes(i.CategoryDraftID)) {
          return false;
        }
        else {
          this.ids.push(i.CategoryDraftID);
        }
        this.categoryApproveObject = ({
          'CategoryDraftIds': this.ids,
          'Comments': this.comments
        });
      }
      if (i['CategoryType'] == "Mapping") {
        if (this.mappingApproveObject.includes(i.Id)) {
          return false;
        } else {
          this.mappingApproveObject.push({
            'Id': i.Id
          })
        }
      }
    }
    if (this.ids.length > 0) {
      this.loadingService.setLoading();
      this.categoryService.rejectCategoryDraft(this.categoryApproveObject)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(() => {
          this.success = true;
          this.selected = [];
          this.comments = '';
          this.ids = [];
        });
    }
    if (this.mappingApproveObject.length > 0) {
      this.loadingService.setLoading();
      this.categoryService.rejectStandardizedMapping(this.mappingApproveObject)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe((response) => {
          this.notificationService.notifySuccess(response.toString());
          this.success = true;
          this.selected = [];
          this.comments = '';
          this.mapping_ids = [];
        });
    }
    setTimeout(() => {
      this.loadingService.setLoading();
      this.setCategoryStandardiserTree();
      this.categoryComponent.refreshCategories();
      this.loadingService.clearLoading();
    }, 1000);
  }

  add(level: CategoryLevelNumber) {
    this.cancelEditingOrCreating(false);
    this.addButton = false;
    this.isEditing = true;
    this.isCreating = true;
    this.parentLevelCategories = this.categoriesComponentService.findParentCategories(this.categoryComponent.categoryConfig.categories,
      level);
    let defaultCategory = this.getDefaultCategory(level);
    switch (level) {
      case CategoryLevelNumber.One:
        this.categoryLevel1 = true;
        this.categoryComponent.clear();
        this.isCategoryLevelOneCreating = true;
        this.categoryComponent.categoryConfig.selectedCategoryCloned = defaultCategory;
        this.externalCategories = null;
        break;
      case CategoryLevelNumber.Two:
        if (this.categoryLevel1 == true) {
          this.categoryLevel2 = true;
        }
        this.handleLevel2Or3CategoryCreating(defaultCategory, CategoryLevelNumber.One);
        this.externalCategories = null;
        break;
      case CategoryLevelNumber.Three:
        if (this.categoryLevel1 == true && this.categoryLevel2 == true) {
          this.categoryLevel3 = true;
        }
        this.handleLevel2Or3CategoryCreating(defaultCategory, CategoryLevelNumber.Two);
        this.externalCategories = null;
        break;
    }
  }

  save() {
    if (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryDraftID) {
      this.form = ({
        'CategoryDraftID': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryDraftID,
        'CategoryID': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID,
        'CategoryLevel': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel,
        'CategoryName': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryName,
        'CategoryType': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryType,
        'DefaultMerchantPresence': this.categoryComponent.categoryConfig.selectedCategoryCloned.DefaultMerchantPresence,
        'EmojiUnicode': this.categoryComponent.categoryConfig.selectedCategoryCloned.EmojiUnicode,
        'IsSensitive': this.categoryComponent.categoryConfig.selectedCategoryCloned.IsSensitive,
        'OperationType': 'Upsert',
        'ParentCategoryID': this.categoryComponent.categoryConfig.selectedCategoryCloned.ParentCategoryID,
        'MatchPriority': this.categoryComponent.categoryConfig.selectedCategoryCloned.MatchPriority,
        'PluralisedCategoryName': this.categoryComponent.categoryConfig.selectedCategoryCloned.PluralisedCategoryName,
        'comments': this.categoryComponent.categoryConfig.selectedCategoryCloned.Comments,
      });
    } else {
      this.form = ({
        'CategoryDraftID': 0,
        'CategoryID': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID,
        'CategoryLevel': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel,
        'CategoryName': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryName,
        'CategoryType': this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryType,
        'DefaultMerchantPresence': this.categoryComponent.categoryConfig.selectedCategoryCloned.DefaultMerchantPresence,
        'EmojiUnicode': this.categoryComponent.categoryConfig.selectedCategoryCloned.EmojiUnicode,
        'IsSensitive': this.categoryComponent.categoryConfig.selectedCategoryCloned.IsSensitive,
        'OperationType': 'Upsert',
        'ParentCategoryID': this.categoryComponent.categoryConfig.selectedCategoryCloned.ParentCategoryID,
        'MatchPriority': this.categoryComponent.categoryConfig.selectedCategoryCloned.MatchPriority,
        'PluralisedCategoryName': this.categoryComponent.categoryConfig.selectedCategoryCloned.PluralisedCategoryName,
        'comments': this.categoryComponent.categoryConfig.selectedCategoryCloned.Comments,
      });
    }
    if (!this.isCreating) {
      if (this.validateCategoryId()) {
        this.loadingService.setLoading();
        this.categoryService.updateCategoryDraft(this.form)
          .pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(() => {
            let index = this.categoryComponent.categoryConfig.categories
              .findIndex(c => c.CategoryID == this.originalCategory.CategoryID);
            this.categoryComponent.categoryConfig.categories[index] =
              { ...this.form };
            this.putSavedCategoryIntoCategoryArrays();
            this.categoryComponent.filterCategories();
            this.cancelEditingOrCreating(false);
            this.notificationService.notifySuccess('Category saved');
            this.loadingService.setLoading();
            this.setCategoryStandardiserTree();
            this.categoryComponent.refreshCategories();
            this.loadingService.clearLoading();
          });
      }
    } else {
      if (this.validateCategoryId()) {
        this.loadingService.setLoading();
        this.categoryService.addCategoryDraft(this.form)
          .pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(() => {
            this.categoryComponent.categoryConfig.categories.push(this.form);
            this.changeCategoryTypeAndResetCategories();
            this.cancelEditingOrCreating(false);
            this.notificationService.notifySuccess('Category saved');
            this.loadingService.setLoading();
            this.setCategoryStandardiserTree();
            this.categoryComponent.refreshCategories();
            this.loadingService.clearLoading();
          });
      }
    }
  }

  shouldShowRadioGroupOnCreating() {
    return this.isCreating && this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel != CategoryLevelNumber.One;
  }

  private putSavedCategoryIntoCategoryArrays() {
    if (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel == CategoryLevelNumber.One) {
      this.updateLevelCategoryArray(CategoryLevelNumber.One);
    } else if (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel == CategoryLevelNumber.Two) {
      this.updateLevelCategoryArray(CategoryLevelNumber.Two);
    } else if (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel == CategoryLevelNumber.Three) {
      this.updateLevelCategoryArray(CategoryLevelNumber.Three);
    }
  }

  private updateLevelCategoryArray(categoryLevel: CategoryLevelNumber) {
    let index = this.categoryComponent.categoryConfig.categoriesPerLevel.get(categoryLevel)
      .findIndex(c => c.CategoryID == this.originalCategory.CategoryID);
    this.categoryComponent.categoryConfig.categoriesPerLevel.get(categoryLevel)[index] =
      { ...this.categoryComponent.categoryConfig.selectedCategoryCloned };
  }

  private validateCategoryId() {
    if (this.categoryIdFirstHalf) {
      if (this.categoryLevel1 == true) {
        if (this.categoryIdFirstHalf.length > 2 || this.categoryIdFirstHalf.length < 2) {
          this.notificationService.notifyError('Editable number must be in range from 01 to 99');

          return false;
        }
      }
      if (this.categoryLevel2 == true) {
        if (this.categoryIdFirstHalf.length > 4 || this.categoryIdFirstHalf.length < 4) {
          this.notificationService.notifyError('Editable number must be in range from 001 to 999');

          return false;
        }
      }

      if (this.categoryLevel3 == true) {
        if (this.categoryIdFirstHalf.length > 7 || this.categoryIdFirstHalf.length < 4) {
          this.notificationService.notifyError('Editable number must be in range from 0001 to 0999');

          return false;
        }
      }
      else if (isNaN(this.categoryIdFirstHalf as any)) {
        this.notificationService.notifyError('Invalid Category ID number');

        return false;
      }
      this.setCategoryId();
    }
    return this.validateFirstLevelCategoryId();
  }

  private setCategoryId() {
    let categoryId = parseInt(this.categoryIdFirstHalf);
    this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID = categoryId;
  }

  private validateFirstLevelCategoryId() {
    if (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID == null) {
      this.notificationService.notifyError('Please fill in Category ID');

      return false;
    }
    if (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryLevel == CategoryLevelNumber.One &&
      (this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID < 0 || this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID > 99)) {
      this.notificationService.notifyError('Category ID must be in range from 0 to 99');

      return false;
    }

    return this.isSameCategoryIdExists();
  }

  private isSameCategoryIdExists() {
    if (!this.isCreating && this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID == this.originalCategory.CategoryID) {
      return true;
    }

    let checkForSameId = this.categoryComponent.categoryConfig.categories.filter(c => c.CategoryID ===
      this.categoryComponent.categoryConfig.selectedCategoryCloned.CategoryID);

    if (checkForSameId.length > 0) {
      this.notificationService.notifyError('Category with the same ID already exists');
      return false;
    }
    return true;
  }

  private getDefaultCategory(level: CategoryLevelNumber): FlatCategory {
    return {
      CategoryID: null,
      CategoryLevel: level,
      CategoryName: '',
      EmojiUnicode: '',
      MatchPriority: null,
      IsSensitive: false,
      CategoryType: CategoryType.Expense,
      PluralisedCategoryName: '',
      DefaultMerchantPresence: merchantPresenceDisplayValues.get(MerchantPresence.Unspecified),
      CategoryDraftID: null,
      OperationType: '',
    };
  }

  private handleLevel2Or3CategoryCreating(defaultCategory: FlatCategory, categoryLevelNumber: CategoryLevelNumber) {
    this.categoryIdFirstHalf = this.categoryComponent.categoryConfig.selectedCategoryPerLevel.get(categoryLevelNumber).CategoryID.toString();
    defaultCategory.CategoryType = this.categoryComponent.categoryConfig.selectedCategoryPerLevel.get(categoryLevelNumber).CategoryType;
    defaultCategory.ParentCategoryID = this.categoryComponent.categoryConfig.selectedCategoryPerLevel.get(categoryLevelNumber).CategoryID;
    this.categoryComponent.categoryConfig.selectedCategoryCloned = defaultCategory;
  }
}
