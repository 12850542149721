import { ApiService } from '../core/api/api.service';
import { ApiClient } from '../core/api/apiclient';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocatedIn } from '../models/locatedin';
import { tap } from 'rxjs/operators';
import { CacheService } from '../core/cache/cache.service';

@Injectable()
export class ReferenceService {
  private locationApi = 'reference/locations';
  constructor(private apiClient: ApiClient, private apiService: ApiService, private cacheService: CacheService) { }

  getLocations(): Observable<LocatedIn[]> {
    let data = this.cacheService.get<LocatedIn[]>(this.locationApi);
    if (data !== null) {
      return of(data);
    }

    return this.apiClient.get<LocatedIn[]>(this.apiService.buildUrl(this.locationApi))
      .pipe(tap(result => this.cacheService.set(this.locationApi, result)));
  }
}
