import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { suggestionEditResponse, suggestionsResponse } from '../mockjsonresponses/suggestionsresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function suggestionsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesSuggestion = false;
  if (request.body && request.body.query) {
    includesSuggestion = request.body.query.includes(`{feedbackType: {eq: "Suggestion"}}`);
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesSuggestion) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...suggestionsResponse}
      }));
      resp.complete();
    })
  }

  let includesUpsertSuggestions = false;
  if (request.body && request.body.query) {
    includesUpsertSuggestions = request.body.query.includes('upsertsuggestion');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesUpsertSuggestions) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: suggestionEditResponse
      }));
      resp.complete();
    });
  }
}
