import { Component, OnInit, Output, EventEmitter, Injector, OnDestroy, ViewChild, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { ControlContainer, NgForm, FormControl } from '@angular/forms';
import { BaseStateComponent } from '../basestate/basestate.component';
import { ChainMerchantSearchState } from './chainmerchantsearchstate';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ChainSelectComponent } from '../../shared/chainselect/chainselect.component';
import { emptyOptionEntry } from '../../core/constants/constants';
import { PickChainService } from '../../services/pickchain.service';
import { MerchantExtractService } from '../../services/merchantextract.service';
import { map, takeUntil } from 'rxjs/operators';
import { ChainNameAndLocatedInId, MerchantShoppingCentre, SuburbList } from '../merchantextract/merchantdetails';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chainmerchantsearch',
  templateUrl: './chainmerchantsearch.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./chainmerchantsearch.component.scss']
})
export class ChainmerchantsearchComponent extends BaseStateComponent<ChainMerchantSearchState> implements OnInit, OnDestroy, AfterViewInit {
  chainTypesToInclude: Array<string | 0>;

  @Output() searchComplete: EventEmitter<any> = new EventEmitter();
  @Output() searchError: EventEmitter<any> = new EventEmitter();
  @Output() getMerchant: EventEmitter<ChainNameAndLocatedInId> = new EventEmitter();
  @ViewChild('chainSelect') chainSelect: ChainSelectComponent;
  @Input() isSummary: boolean = false;
  @Output() searchByChain = new EventEmitter<boolean>();
  booleanValue: boolean;

  merchantShoppingCentres: MerchantShoppingCentre[] = [];
  filteredMerchantShoppingCentres: MerchantShoppingCentre[] = [];
  locatedInNameControl = new FormControl();

  suburbControl = new FormControl('');
  suburbList: SuburbList[] = [];
  filteredSuburbList: Observable<any[]>;

  constructor(private service: MerchantlookupService, injector: Injector,
    private loadingService: LoadingService, private pickChainService: PickChainService,
    private merchantExtractService: MerchantExtractService, private changeDetectorRef: ChangeDetectorRef) {
    super(injector);
    this.booleanValue = false;
  }

  ngOnInit() {
    this.state = {
      chainsuburb: '',
      chainid: '',
      chainValue: emptyOptionEntry,
      locatedInName: null
    };

    this.restoreState();
    this.chainTypesToInclude = this.pickChainService.getUsualChainTypesForDisplay();
    this.setMerhantShoppingCenters();
    this.setChainSuburbList()
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
    this.filteredSuburbList = this.suburbControl.valueChanges.pipe(
      map((value: string) => this.suburbList.filter(option => option.value.toLowerCase().startsWith(value.toLowerCase())))
    );
  }

  ngOnDestroy() {
    this.state.chainValue = this.chainSelect.getValue();
    super.ngOnDestroy();
  }

  onChainValueChange(value: string) {
    if (value) {
      this.state.chainValue = {
        value: value,
        display: value,
        details: ''
      }
    } else {
      this.state.chainValue = emptyOptionEntry;
    }
  }

  onChainSearch() {
    if (this.state.chainsuburb !== '') {
      if (this.isSummary === false) {
        this.searchOnSuburb(this.chainSelect.getValue().value, this.state.chainsuburb);
      } else {
        this.searchMerchantSummaryOnSuburb(this.chainSelect.getValue().value, this.state.chainsuburb);
      }
    } else if (this.state.chainid !== '') {
      if (this.isSummary === false) {
        this.searchOnBranchId(this.chainSelect.getValue().value, this.state.chainid);
      } else {
        this.searchMerchantSummaryOnBranchId(this.chainSelect.getValue().value, this.state.chainid);
      }
    }
  }

  getMerchantDetails() {
    this.getMerchant.emit({
      chainName: this.state.chainValue.value,
      locatedIn_ID: this.filteredMerchantShoppingCentres[0].locatedIn_ID.toString()
    });
  }

  onHeadOfficeSearch() {
    this.loadingService.setLoading();
    this.service.searchDatabaseByChainHq(this.chainSelect.getValue().value)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => this.onComplete(w), (e) => this.searchError.emit(e));
    this.booleanValue = true;
    this.searchByChain.emit(this.booleanValue);
  }

  searchOnSuburb(chainName: string, suburb: string) {
    let sub;
    for (let i of this.suburbList) {
      if (i.viewValue.includes(suburb)) {
        sub = i.value;
        break;
      }
    }
    this.loadingService.setLoading();
    this.service.searchDatabaseByChainNameSuburb(chainName, sub)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => this.onComplete(w), (e) => this.searchError.emit(e));
  }

  searchMerchantSummaryOnSuburb(chainName: string, suburb: string) {
    this.loadingService.setLoading();
    this.service.searchSingleMerchantSummaryByChainNameSuburb(chainName, suburb)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => this.onComplete(w), (e) => this.searchError.emit(e));
    this.booleanValue = true;
    this.searchByChain.emit(this.booleanValue);
  }

  searchOnBranchId(chainName: string, id: string): void {
    this.loadingService.setLoading();

    this.service.searchDatabaseByChainNameBranchId(chainName, id)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => {
        this.onComplete(w);
      }, (e) => this.searchError.emit(e));
  }

  searchMerchantSummaryOnBranchId(chainName: string, id: string): void {
    this.loadingService.setLoading();

    this.service.searchMerchantSummaryByChainNameBranchId(chainName, id)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => {
        this.onComplete(w);
      }, (e) => this.searchError.emit(e));
    this.booleanValue = true;
    this.searchByChain.emit(this.booleanValue);
  }

  onComplete(result: string) {
    this.searchComplete.emit(result);
  }

  searchButtonDisabled(): boolean {
    if (this.filteredMerchantShoppingCentres.length === 1
      && this.filteredMerchantShoppingCentres[0].locatedIn_Name === this.state.locatedInName && this.state.chainValue.value) {
      return false;
    }
    if (!this.state.chainValue.value) return true;
    if (!this.state.chainsuburb && !this.state.chainid) return true;
  }

  headOfficeButtonDisabled(): boolean {
    return (!this.state.chainValue.value);
  }

  setMerhantShoppingCenters() {
    this.loadingService.setLoading();
    this.merchantExtractService.getMerchantShoppingCenters()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data.length > 0) {
          this.merchantShoppingCentres = [...this.filteredMerchantShoppingCentres] = response.data;
          this.locatedInNameValueChanges();
        }
      });
  }

  setChainSuburbList() {
    this.loadingService.setLoading();
    this.service.getSuburbList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data.length > 0) {
          for (let suburb of response.data) {
            this.suburbList.push({ viewValue: suburb.suburb + ' ' + suburb.postCode + ' ' + suburb.stateCode, value: suburb.suburb });
          }
          this.suburbList = [...new Set(this.suburbList)];
        }
      });
  }


  private locatedInNameValueChanges() {
    this.locatedInNameControl.valueChanges.pipe(
      map((shoppingCentre: string) =>
        shoppingCentre
          ? this.merchantShoppingCentres.filter(t => t.locatedIn_Name.toLowerCase().includes(shoppingCentre.toLowerCase()))
          : this.merchantShoppingCentres.slice()
      )
    ).subscribe((result) => {
      this.filteredMerchantShoppingCentres = result;
    });
  }

}
