import { propertyOf } from '../../core/services/reflection.service';

export interface CalHistory {
  Status: string;
  StatusDateTime: string;
  LWC_ID?: number;
  StatusID: number;
  TransactionDescription: string;
  IndexerName: string;
}

export interface CalHistoryFormatted extends CalHistory {
  StatusDateTimeFormatted: string;
}

export const calHistoryFormattedProps = {
  TransactionDescription: propertyOf<CalHistoryFormatted>('TransactionDescription'),
  StatusID: propertyOf<CalHistoryFormatted>('StatusID'),
  IndexerName: propertyOf<CalHistoryFormatted>('IndexerName'),
  StatusDateTime: propertyOf<CalHistoryFormatted>('StatusDateTime')
};
