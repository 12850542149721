import { Component, OnInit, Injector } from "@angular/core";
import { BaseStateComponent } from "../basestate/basestate.component";
import { CalParserState, CalHint, CalDetails } from "./calparserstate";
import { MerchantlookupService } from "../../services/merchantlookup.service";
import { finalize } from "rxjs/operators";
import { LoadingService } from "../../core/uiservices/loading.service";
import { MerchantSummaryJsonViewerService } from "../../services/merchantsummaryjsonviewer.service";
import { CalHintService } from "./calhintservice";
import { NotificationService } from "../../core/uiservices/notification.service";
import { SplitCalRequest } from "../../models/splitcal";

@Component({
    selector: 'app-calparser',
    templateUrl: './calparser.component.html',
    styleUrls: ['./calparser.component.scss']
})

export class CalParserComponent extends BaseStateComponent<CalParserState> implements OnInit {
    openPanel :boolean = true
    constructor(injector: Injector, private merchantlookupService: MerchantlookupService, private loadingService: LoadingService,
        private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService, private calHintService: CalHintService,
        private notificationService: NotificationService) {
        super(injector);
    }

    ngOnInit() {
        this.state = {
            LWC_ID: null,
            merchantLookupResponseFirstPart: null,
            merchantLookupResponseSecondPart: null,
            calsToUpdate: []
        }
        this.restoreState();
    }

    isSearchButtonDisabled() {
        return !this.state.LWC_ID;
    }

    search() {
        this.loadingService.setLoading();
        this.state.calsToUpdate = [];
        this.state.merchantLookupResponseFirstPart = null;
        this.state.merchantLookupResponseSecondPart = null;
        this.calHintService.getCalDetails(this.state.LWC_ID)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(response => {
                this.setCalDetails(response ? response : null);
            });
    }

    update(calDetails: CalDetails[]) {
        this.loadingService.setLoading();
        let calsToUpdate = calDetails.filter(t => t.cardAcceptor != "");
        let calHints: CalHint[] = [];
        for (let data of calsToUpdate) {
            calHints.push({ Cal: data.cal, Hint: data.cardAcceptor });
        }
        this.calHintService.updateCals({ LWC_ID: this.state.LWC_ID, CalsToUpdate: calHints })
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(() => {
                this.notificationService.notifySuccess('Cal update was successful');
            });
    }

    refresh() {
        this.search();
    }

    isUpdateButtonDisabled() {
        let nonEmptyCardAcceptorCals = this.state.calsToUpdate.filter(t => t.cardAcceptor != "");
        let cardAcceptorMatchesCal = nonEmptyCardAcceptorCals.every(t => this.calIncludesCardAcceptor(t.cal, t.cardAcceptor));
        if (this.state.calsToUpdate && this.state.calsToUpdate.length > 0 && this.state.LWC_ID && nonEmptyCardAcceptorCals.length > 0 && cardAcceptorMatchesCal) {
            return false;
        }
        return true;
    }

    cardAcceptorDoesNotMatchCorrespondingCal(cardAcceptor: string, cal: string): boolean {
        if (cardAcceptor === "") {
            return false;
        }

        if (this.calIncludesCardAcceptor(cal, cardAcceptor)) {
            return false;
        }

        return true;
    }

    calIncludesCardAcceptor(cal: string, cardAcceptor: string) {
        return cal.toLowerCase().includes(cardAcceptor.toLowerCase());
    }

    parse(cal: string, cardAcceptor: string, index: number) {
        this.loadingService.setLoading();
        let splitCalRequest: SplitCalRequest = {
          Cal: cal,
          CardAcceptorHint: cardAcceptor,
        };

        this.calHintService.getCalParserResult(splitCalRequest)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(response => {
                if (response) {
                    this.state.calsToUpdate[index].standardisedTransactionDescription = response.StandardisedTransactionDescription;
                    this.state.calsToUpdate[index].despacedTransactionDescription = response.DespacedTransactionDescription;
                    this.state.calsToUpdate[index].formattedCardAcceptor = response.FormattedCardAcceptor;
                    this.state.calsToUpdate[index].suburb = response.Suburb;
                }
            });
    }

    private setCalDetails(calDetails?: CalDetails[]) {
        this.loadingService.setLoading();
        this.merchantlookupService.getCals(this.state.LWC_ID)
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(response => {
                if (response) {
                    for (let i = 0; i < response.TransactionDescriptions.length; i++) {
                        if (calDetails && calDetails.length > 0) {
                            let calWithCorespondingCardAcceptor = calDetails.find(t => t.cal === response.TransactionDescriptions[i]);
                            if (calWithCorespondingCardAcceptor) {
                              this.state.calsToUpdate.push(
                                  {
                                      cal: calWithCorespondingCardAcceptor.cal,
                                      cardAcceptor: calWithCorespondingCardAcceptor && calWithCorespondingCardAcceptor.cardAcceptor ? calWithCorespondingCardAcceptor.cardAcceptor : '',
                                      standardisedTransactionDescription: calWithCorespondingCardAcceptor && calWithCorespondingCardAcceptor.standardisedTransactionDescription ? calWithCorespondingCardAcceptor.standardisedTransactionDescription : '',
                                      despacedTransactionDescription: calWithCorespondingCardAcceptor && calWithCorespondingCardAcceptor.despacedTransactionDescription ? calWithCorespondingCardAcceptor.despacedTransactionDescription : '',
                                      formattedCardAcceptor: calWithCorespondingCardAcceptor && calWithCorespondingCardAcceptor.formattedCardAcceptor ? calWithCorespondingCardAcceptor.formattedCardAcceptor : '',
                                      suburb: calWithCorespondingCardAcceptor && calWithCorespondingCardAcceptor.suburb ? calWithCorespondingCardAcceptor.suburb : ''
                                  }
                              );
                            }
                            else {
                              this.setEmptyCalDetailObject(response.TransactionDescriptions[i]);
                            }
                        }
                        else {
                           this.setEmptyCalDetailObject(response.TransactionDescriptions[i]);
                        }
                    }
                    this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
                    this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
                }
            });
    }

    setEmptyCalDetailObject(cal: string) {
      this.state.calsToUpdate.push(
        {
            cal: cal,
            cardAcceptor: '',
            standardisedTransactionDescription: '',
            despacedTransactionDescription: '',
            formattedCardAcceptor: '',
            suburb: ''
        }
    );
    }
}
