import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiClient } from './../core/api/apiclient';
import { Injectable } from "@angular/core";
import { ApiService } from '../core/api/api.service';
import { MerchantNamesResponse, MerchantWordSuggestionResponse, MerchantNameSearchResponse } from '../components/merchantnamesearch/merchantnamesearch.response';
import { MerchantWordSuggestionRequest, MerchantWordRequest } from '../components/merchantnamesearch/merchantnamessearch.request';

@Injectable({
  providedIn: "root"
})

export class MerchantNameSearchService {
  constructor(private apiClient: ApiClient, private apiService: ApiService) {
  }

  getMerchantNames(lwcId: number) {
    return this.apiClient.get<MerchantNamesResponse[]>(this.apiService.buildUrl(apiPathConstants.merchantName, lwcId.toString()));
  }

  getMerchantWordSuggestions(merchantWordSuggestionRequest: MerchantWordSuggestionRequest) {
    return this.apiClient.post<MerchantWordSuggestionResponse>(this.apiService.buildUrl(apiPathConstants.suggest), merchantWordSuggestionRequest);
  }

  getMerchantNameSearches(merchantWordRequest: MerchantWordRequest) {
    return this.apiClient.post<MerchantNameSearchResponse[]>(this.apiService.buildUrl(apiPathConstants.search), merchantWordRequest);
  }
}
