import { UnverifiedMerchantsResponse } from '../../components/operationalchecks/operationalchecks.component';
import { ExecutionResult } from 'graphql';

export let unverifiedMerchantsResponse: ExecutionResult<UnverifiedMerchantsResponse> = {
  data: {
    unverifiedMerchants: [
      {
        lwcId: 1000603540,
        status: 'Initiated',
        closedConfidenceLevel: 'Monitor2',
        message: 'Operational Checks',
      },
      {
        lwcId: 1000605194,
        status: 'Monitor',
        closedConfidenceLevel: 'Monitor1',
        message: 'Operational Checks1',
      },
      {
        lwcId: 1000605195,
        status: 'Closed',
        closedConfidenceLevel: 'Monitor2',
        message: 'Operational Checks2',
      },
      {
        lwcId: 1001105457,
        status: 'Closed',
        closedConfidenceLevel: 'Monitor4',
        message: 'Operational Checks3',
      },
      {
        lwcId: 1000606452,
        status: 'Open',
        closedConfidenceLevel: 'Monitor',
        message: 'Operational Checks4',
      }
    ]
  }
}

