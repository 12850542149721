import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Angular2CsvComponent } from 'angular2-csv';

@Component({
  selector: 'app-csv-export',
  templateUrl: './csvexport.component.html',
  styleUrls: ['./csvexport.component.scss']
})
export class CsvExportComponent extends Angular2CsvComponent {
  @Input() name: string = "Export to Excel";
  @Input() disabled: boolean = false;
  @Input() customDownload: boolean = false;
  @Output() download?: EventEmitter<void> = new EventEmitter();

  exportToExcelClicked() {
    if (!this.customDownload) {
      this.onDownload();
    }
    else {
      this.download.emit();
    }
  }
}
