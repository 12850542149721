import { CalService } from '../../core/formatting/cal.service';
import { LocatorType, PaymentMethod } from '../../models/calparserresult';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { CalSplitterService } from '../../services/calsplitter.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { Component, OnInit, Injector } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { CalSplitterState } from './calsplitterstate';
import { SplitCalRequest } from '../../models/splitcal';
import { StringService } from '../../core/formatting/string.service';
import { BankAccountTransactionType, TransactionBank } from '../../models/ausindexingresult';
import { TransactionService } from '../../services/transaction.service';
import { BankAccountTransactionTypeService } from '../../services/bankaccounttransactiontype.service';
import { PaymentService } from '../../services/payment.service';
import { NumberNameValue } from '../../core/types/numbernamevalue';

@Component({
  selector: 'app-calsplitter',
  templateUrl: './calsplitter.component.html',
  styleUrls: ['./calsplitter.component.scss']
})

export class CalSplitterComponent extends BaseStateComponent<CalSplitterState> implements OnInit {
  locatorType = LocatorType;
  formattedTransactionAmounts: string;
  bankAccountTransactionTypes: BankAccountTransactionType[] = [];
  bPayPaymentMethod: PaymentMethod = PaymentMethod.BPay;
  transactionBankLists: TransactionBank[] = [];
  paymentMethods: NumberNameValue[] = [];

  constructor(private calSplitterService: CalSplitterService, private notificationService: NotificationService,
    private loadingService: LoadingService, private calService: CalService, private injector: Injector,
    private stringService: StringService, private transactionService: TransactionService,
    private bankAccountTransactionTypeService: BankAccountTransactionTypeService,
    private paymentService: PaymentService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      cal: '',
      calParserResult: null,
      useBTPLite: false,
      bankAccountTransactionType: '',
      transactionBank: 0
    };

    this.restoreState();
    this.setTransactionTypes();
    this.setTransactionBankList();
    this.setPaymentMethods();
  }

  search() {
    this.loadingService.setLoading();
    let splitCalRequest: SplitCalRequest = {
      UseBtpLite: this.state.useBTPLite,
      Cal: this.state.cal,
      BankAccountTransactionType: (this.state.bankAccountTransactionType && this.state.bankAccountTransactionType.trim()) ? this.state.bankAccountTransactionType : "0",
      TransactionBank: this.state.transactionBank
    };

    this.calSplitterService.getCalParserResult(splitCalRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(s => {
        this.state.calParserResult = s;
        this.notificationService.notifySuccess();
        this.formatTransactionAmounts();
        this.setDisplayValues();
      });
  }

  isSearchButtonDisabled() {
    return this.state.cal.length < 5;
  }

  reformatCal() {
    this.state.cal = this.calService.getReformatedCal(this.state.cal);
  }


  private formatTransactionAmounts() {
    if (this.state.calParserResult.TransactionAmounts != null) {
      this.formattedTransactionAmounts = this.state.calParserResult.TransactionAmounts.map(t => t.Value + ' ' + t.Currency).join(', ');
    }
  }

  private setTransactionTypes() {
    this.loadingService.setLoading();
    this.bankAccountTransactionTypeService.getBankAccountTransactionTypes()
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.length > 0) {
          this.bankAccountTransactionTypes = response.data;
          this.bankAccountTransactionTypes.sort((a, b) => {
            return this.stringService.sortAlphabetically(a.name, b.name);
          });
        }
      });
  }

  private setTransactionBankList() {
    if (this.transactionBankLists.length === 0) {
      this.transactionService.getTransactionBankList()
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          this.transactionBankLists = response.data;
        });
    }
  }

  private setPaymentMethods() {
    this.paymentService.getPaymentMethods()
      .subscribe((response) => {
        if (response && response.data && response.data.length > 0) {
          this.paymentMethods = response.data;
        }
      });
  }

  private setDisplayValues() {
    let transactionType: NumberNameValue = this.bankAccountTransactionTypes.find(t => t.value === this.state.calParserResult.TransactionType);
    this.state.calParserResult.TransactionTypeDisplay = transactionType ? transactionType.name : null;
  }
}
