import { ChainDto } from "../../models/ausindexingresult";
import { MerchantPresence, merchantPresenceStringValues } from "../../enums/enums";

export let chainResponse: Partial<ChainDto>[] = [
  {
    ChainId: 992,
    HQ_LWC_ID: 1000603540,
    HQ_LWC_Guid: "bd19a059-abcb-4c59-83f4-ca0a07c93d4d",
    ChainName: "Regional Express",
    ChainType: "MerchantPattern",
    ChainNameLookupOptions: [],
    TransactionDescriptionLookupOptions: [
      {
        CategoryMatchPatternId: 20465,
        SearchString: "REGIONAL EXPRESS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-08T17:12:23.863"
      },
      {
        CategoryMatchPatternId: 20466,
        SearchString: "REGIONALEXP",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 1,
        MatchConfidence: 0,
        LastUpdated: "2019-05-08T17:12:23.863"
      },
      {
        CategoryMatchPatternId: 20467,
        SearchString: "REGIONAL EXPRE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-08T17:12:23.863"
      },
      {
        CategoryMatchPatternId: 20468,
        SearchString: "REGIONAL EXPRES",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-08T17:12:23.863"
      },
      {
        CategoryMatchPatternId: 20469,
        SearchString: "REGIONAL EXPRE ZL",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-08T17:12:23.863"
      },
      {
        CategoryMatchPatternId: 20470,
        SearchString: "REGIONAL EXP ZL",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-08T17:12:23.863"
      }
    ],
    CategoryId: 7001003,
    DefaultBusinessDescription: "Australia's largest independent regional airline, connecting 29 metropolitan and regional centres across New South Wales, Canberra, Victoria, Tasmania and Queensland.",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: 0,
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.Unspecified),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2019-01-27T03:48:09.327",
    Tags: [
      {
        id: 1030603540,
        label: 'Buy Now Pay Later',
      },
      {
        id: 1660605190,
        label: 'Fridaysio',
      },
      {
        id: 1891105450,
        label: 'Pay',
      }
    ]
  },
  {
    ChainId: 653,
    HQ_LWC_ID: 1000605194,
    HQ_LWC_Guid: "9596c76a-42aa-4cb3-9784-0993d4c49b94",
    ChainName: "Domino's Pizza",
    ChainType: "Both",
    ChainNameLookupOptions: [
      {
        CategoryMatchPatternId: 4808,
        SearchString: "DOMINOS PIZZA",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.603"
      },
      {
        CategoryMatchPatternId: 4809,
        SearchString: "DOMINOS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.603"
      },
      {
        CategoryMatchPatternId: 4810,
        SearchString: "DOMINOS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 4,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.603"
      },
      {
        CategoryMatchPatternId: 4811,
        SearchString: "CAFE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 3,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.603"
      },
      {
        CategoryMatchPatternId: 4812,
        SearchString: "DOMINOS PIZZA",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.603"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        CategoryMatchPatternId: 8953,
        SearchString: "DOMINOES PIZZA",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8954,
        SearchString: "DOMINO PIZZA",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8955,
        SearchString: "DOMINOS PIZZA",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8956,
        SearchString: "DOMINO",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8957,
        SearchString: "DOMINOS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8958,
        SearchString: "DOMINOS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 4,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8959,
        SearchString: "CAFE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 3,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8960,
        SearchString: "ESTORE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 4,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8961,
        SearchString: "DOMINO S",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8962,
        SearchString: "ESTOR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 4,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8963,
        SearchString: "DOMINOES",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8964,
        SearchString: "DOMINOPI",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8965,
        SearchString: "DOMINOS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        CategoryMatchPatternId: 8966,
        SearchString: "VAN DIEMEN PIZZA",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-01-16T16:25:24.493"
      }
    ],
    DefaultUrl: "http://dominos.com.au",
    CategoryId: 3001003,
    DefaultBusinessDescription: "Check out Domino's range of fresh hot pizzas & tasty sides. Pick up or delivery. Easy Online Ordering.",
    DefaultEmailAddressForChargebacks: "dominos.feedback@dominos.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [
      5811,
      5812,
      5814,
      5499,
      5311
    ],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.MobileMerchant),
    CountryCodeAlpha3: "NZL",
    LastUpdated: "2019-01-16T05:24:46.517",
    Tags: []
  },
  {
    ChainId: 178,
    HQ_LWC_ID: 1000605195,
    HQ_LWC_Guid: "0e87e5b5-745e-4901-a251-1922a2f6cd96",
    ChainName: "JAX Tyres",
    ChainType: "Both",
    ChainNameLookupOptions: [
      {
        SearchString: "JAX TYRES",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "JAX TYRES",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "JAX QUICK FIT TYRE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "JAXQUICKFIT TYRES",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "JAX QUICKFIT TYRE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "JAXCOM* TXN",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 1,
        MatchConfidence: 0,
        LastUpdated: "2018-12-04T16:06:53.427"
      }
    ],
    DefaultUrl: "https://www.jaxtyres.com.au",
    CategoryId: 5001001,
    DefaultBusinessDescription: "JAX Tyres is a privately owned Australian retailer specialising in the sales and service of tyres, wheels, brakes and suspension. The company operates through more than 80 franchised stores in Eastern Australia. JAX's independence enables choice to customers of popular global tyre and wheel brands such as BFGoodrich, Bridgestone, Continental, Dunlop, Goodyear, Hankook, Michelin and Pirelli.",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.Unspecified),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2018-12-04T05:06:32.81"
  },
  {
    ChainId: 4,
    HQ_LWC_ID: 1001105457,
    HQ_LWC_Guid: "b4bb9ca6-cf35-41e8-af18-5497d49555d2",
    ChainName: "JayJays",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "JAY JAYS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "JAY JAYS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "https://www.jayjays.com.au",
    CategoryId: 1001,
    DefaultBusinessDescription: "JayJays is the fashion destination for metropolitan girls who like to stay on top. For up-to-the-minute looks that are fast, fresh and ever evolving.",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [
      5691,
      5999,
      5311,
      5621,
      7399
    ],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.MobileMerchant),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2018-12-04T05:00:59.23"
  },
  {
    ChainId: 5,
    HQ_LWC_ID: 1001105458,
    HQ_LWC_Guid: "c6c23527-e00b-486e-bd06-59b22fad858d",
    ChainName: "Just Jeans",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "JUST JEANS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "JUST JEANS",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "https://www.justjeans.com.au",
    CategoryId: 1001,
    DefaultBusinessDescription: "Just Jeans is the home of denim, including Lee, Calvin Klein, True Religion and Levis Jeans.",
    DefaultEmailAddressForChargebacks: "customerservice@justjeans.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "High",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.Unspecified),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2018-07-25T02:47:00.687"
  },
  {
    ChainId: 6,
    HQ_LWC_ID: 1000600536,
    HQ_LWC_Guid: "5f8620d9-2b40-4889-84fd-2a24eee5f710",
    ChainName: "Peter Alexander",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "PETER ALEXANDER",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "PETER ALEXANDER",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "PETER ALEXADER",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "PETER ALEXANDR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "PETER ALXANDR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "https://www.peteralexander.com.au",
    CategoryId: 1001002,
    DefaultBusinessDescription: "Peter Alexander is Australias leading sleepwear designer brand. Shop womens pyjamas, mens sleepwear, and kids PJs online and bring the fun to bedtime.",
    DefaultEmailAddressForChargebacks: "custserv@peteralexander.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.Unspecified),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2019-05-08T21:13:58.597"
  },
  {
    ChainId: 7,
    HQ_LWC_ID: 1000606452,
    HQ_LWC_Guid: "bcfec8f4-baee-473d-a803-7934263ef500",
    ChainName: "Direct Chemist Outlet",
    ChainType: "Both",
    ChainNameLookupOptions: [
      {
        SearchString: "DIRECT CHEMIST OUTLET",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "DIRECT CHEMIST OUTLET",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "DCO",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 1,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "DIRECT CHEMIST",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "DIRECT CHEM",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 0,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://directchemistoutlet.com.au",
    CategoryId: 8001001,
    DefaultBusinessDescription: "Chemist Outlet is committed to offering affordable healthcare, professional service and a customer friendly environment. Chemist Outlet stocks a wide range of over the counter medicine and you can save up to 60 % off recommended retail prices of all your pharmacy needs.",
    DefaultEmailAddressForChargebacks: "info@directchemistoutlet.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [
      5912
    ],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.Unspecified),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2018-10-12T03:06:37.907"
  },
  {
    ChainId: 8,
    HQ_LWC_ID: 1000605193,
    HQ_LWC_Guid: "77ae5f7d-356e-4eae-86f3-ed36f448c243",
    ChainName: "Chemist Outlet",
    ChainType: "Both",
    ChainNameLookupOptions: [
      {
        SearchString: "CHEMIST OUTLET",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "DIRECT",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 3,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "CHEMIST OUTLET",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      },
      {
        SearchString: "DIRECT",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 3,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://chemistoutlet.com.au",
    CategoryId: 8001001,
    DefaultBusinessDescription: "Chemist Outlet is committed to offering affordable healthcare, professional service and a customer friendly environment. Chemist Outlet stocks a wide range of over the counter medicine and you can save up to 60 % off recommended retail prices of all your pharmacy needs.",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.Unspecified),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2019-05-08T21:13:58.597"
  },
  {
    ChainId: 9,
    HQ_LWC_ID: 1001105467,
    HQ_LWC_Guid: "27f53ee6-7642-4861-8565-553b93f2157b",
    ChainName: "Gyprock Trade",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "GYPROCK TRADE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "CSR GYPROCK TR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://www.gyprocktrade.com.au",
    CategoryId: 1008002,
    DefaultBusinessDescription: "Building or renovating a home? Gyprock can help with wall and ceiling solutions, DIY installation instructions or help finding a professional installer.",
    DefaultEmailAddressForChargebacks: "gtcsupport@csr.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.OnlineOnly),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2018-07-25T03:35:14.333"
  },
  {
    ChainId: 10,
    HQ_LWC_ID: 1001105467,
    HQ_LWC_Guid: "27f53ee6-7642-4861-8565-553b93f2157b",
    ChainName: "Mafia",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "GYPROCK TRADE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "CSR GYPROCK TR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://www.gyprocktrade.com.au",
    CategoryId: 5015008,
    DefaultBusinessDescription: "Building or renovating a home? Gyprock can help with wall and ceiling solutions, DIY installation instructions or help finding a professional installer.",
    DefaultEmailAddressForChargebacks: "gtcsupport@csr.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.OnlineOnly),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2018-07-25T03:35:14.333"
  },
  {
    ChainId: 11,
    HQ_LWC_ID: 1001106867,
    HQ_LWC_Guid: "27f53ee6-7642-4861-8565-553b93f2157b",
    ChainName: "KFC",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "GYPROCK TRADE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "CSR GYPROCK TR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://www.gyprocktrade.com.au",
    CategoryId: 5015008,
    DefaultBusinessDescription: "Building or renovating a home? Gyprock can help with wall and ceiling solutions, DIY installation instructions or help finding a professional installer.",
    DefaultEmailAddressForChargebacks: "gtcsupport@csr.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.OnlineOnly),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2018-07-25T03:35:14.333"
  },
  {
    ChainId: 12,
    HQ_LWC_ID: 1001105967,
    HQ_LWC_Guid: "27f53ee6-7642-4861-8565-553b93f2157b",
    ChainName: "Taco Bell",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "GYPROCK TRADE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "1995-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "CSR GYPROCK TR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2007-04-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://www.gyprocktrade.com.au",
    CategoryId: 5015008,
    DefaultBusinessDescription: "Building or renovating a home? Gyprock can help with wall and ceiling solutions, DIY installation instructions or help finding a professional installer.",
    DefaultEmailAddressForChargebacks: "gtcsupport@csr.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "High",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.OnlineOnly),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2008-07-25T03:35:14.333"
  },
  {
    ChainId: 13,
    HQ_LWC_ID: 5015008,
    HQ_LWC_Guid: "27f53ee6-7642-4861-8565-553b93f2157b",
    ChainName: "Subway",
    ChainType: "Branch",
    ChainNameLookupOptions: [
      {
        SearchString: "GYPROCK TRADE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2009-05-07T18:01:45.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "CSR GYPROCK TR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2019-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://www.gyprocktrade.com.au",
    CategoryId: 5405008,
    DefaultBusinessDescription: "Building or renovating a home? Gyprock can help with wall and ceiling solutions, DIY installation instructions or help finding a professional installer.",
    DefaultEmailAddressForChargebacks: "gtcsupport@csr.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "Medium",
    ChainDensity: "Medium",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.OnlineOnly),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2028-07-25T03:35:14.333",
    Tags: [
      {
        id: 1009403540,
        label: 'Buy Now',
      },
      {
        id: 1004205190,
        label: 'Fridaysio',
      },
      {
        id: 1001365450,
        label: 'Pay Starter',
      }
    ]
  },
  {
    ChainId: 48,
    HQ_LWC_ID: 109005008,
    HQ_LWC_Guid: "25d53ee6-7642-5861-8565-553b93f2157b",
    ChainName: "Subway",
    ChainType: "Salary",
    ChainNameLookupOptions: [
      {
        SearchString: "GYPROCK TRADE",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 2,
        MatchConfidence: 0,
        LastUpdated: "2000-05-05T18:01:42.607"
      }
    ],
    TransactionDescriptionLookupOptions: [
      {
        SearchString: "CSR GYPROCK TR",
        SearchStringsNoSpaces: [],
        SearchStrings: [],
        LookupType: 5,
        MatchConfidence: 2,
        LastUpdated: "2009-05-07T18:01:45.607"
      }
    ],
    DefaultUrl: "http://www.gyprocktrade.com.au",
    CategoryId: 5405008,
    DefaultBusinessDescription: "Building or renovating a home? Gyprock can help with wall and ceiling solutions, DIY installation instructions or help finding a professional installer.",
    DefaultEmailAddressForChargebacks: "gtcsupport@csr.com.au",
    DistinctNameForEachBranch: false,
    OperatesInStates: [],
    AcceptedMCCs: [],
    MatchConfidence: "Medium",
    ChainDensity: "High",
    MerchantPresence: merchantPresenceStringValues.get(MerchantPresence.OnlineOnly),
    CountryCodeAlpha3: "AUS",
    LastUpdated: "2008-06-25T03:35:14.333",
    Tags: [
      {
        id: 1009403540,
        label: 'Buy Now',
      }
    ]
  }
]
