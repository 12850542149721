
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[NumberOnly]'
})

export class NumberOnly {

  private readonly regEx = new RegExp('^[0-9]*$');

  constructor(private el: ElementRef) { }

  @Input() NumberOnly: boolean = true;
  @Input() maxlength: number = 6;

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    let e = <KeyboardEvent> event;
    if (this.NumberOnly) {
        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        (e.keyCode == 65 && e.ctrlKey === true) ||
        (e.keyCode == 67 && e.ctrlKey === true) ||
        (e.keyCode == 86 && e.ctrlKey === true) ||
        (e.keyCode == 88 && e.ctrlKey === true) ||
        (e.keyCode >= 35 && e.keyCode <= 39)) { 
          return;
        }

      if (!this.isValid(event.key))
         e.preventDefault();
      
      }
  }

  @HostListener('paste', ['$event']) onPaste(e: { clipboardData: { getData: (arg0: string) => any; }; }) {
    
    let pastedText = e.clipboardData.getData('text/plain');
    if (pastedText) {
      if (!this.isValid(pastedText)) {
        event.preventDefault();
      }
    }
  }

  private isValid(eligible: string): boolean {
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(eligible);
    return this.regEx.test(eligible) && !this.isOverSize(next);
  }

  private isOverSize(str: string): boolean {
    if (this.maxlength && str) {
      return str.length > this.maxlength; 
    }
    return false;
  }

}
