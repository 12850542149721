import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { MerchantEventsState } from './merchanteventsstate';
import { BaseODataFilter } from '../../core/api/odata/baseodatafilter';
import { ODataConfig } from '../../core/api/odata/odataconfig';
import { ODataOrderByDesc } from '../../core/api/odata/odataorderbydesc';
import { LoadingService } from '../../core/uiservices/loading.service';
import { MerchantEvent, merchantEventProps } from './merchantevent';
import { finalize, takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ODataService } from '../../core/api/odata/odata.service';
import { ContainsODataFilter } from '../../core/api/odata/containsodatafilter';
import { ODataFilter } from '../../core/api/odata/odatafilter';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { NgForm } from '@angular/forms';
import { RouteConstants } from '../../core/constants/constants';
import { MerchantEventState, merchantEventStateNames } from './state';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { Router } from '@angular/router';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { ODataOrderBy } from '../../core/api/odata/odataorderby';
import { ODataOrderByAsc } from '../../core/api/odata/odataorderbyasc';
import { CollectionTransformerService } from '../../core/collections/collectiontransformer.service';

@Component({
  selector: 'app-merchantevents',
  templateUrl: './merchantevents.component.html',
  styleUrls: ['./merchantevents.component.scss']
})
export class MerchantEventsComponent extends BaseStateComponent<MerchantEventsState> implements OnInit {
  rows: MerchantEvent[] = [];
  pageIndex = 0;
  pageSize = 50;
  totalElements = 0;
  orderBy: ODataOrderBy = new ODataOrderByDesc(merchantEventProps.EventDate);
  merchantEventStateNames = this.collectionTransformerService.mapToArray(merchantEventStateNames);
  @ViewChild('merchantEventsForm', {static: true}) merchantEventsForm: NgForm;

  constructor(injector: Injector, private loadingService: LoadingService, private oDataService: ODataService,
    private dateTimeService: DateTimeService, private valueCheckerService: ValueCheckerService,
    private router: Router, private collectionTransformerService: CollectionTransformerService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      tradingName: '',
      legalName: '',
      abn: '',
      eventType: '',
      eventSource: '',
      eventSourceId: '',
      state: MerchantEventState.Open
    }

    this.restoreState();

    this.getMerchantEvents();

    this.merchantEventsForm.valueChanges.pipe(
      debounceTime(1200),
      distinctUntilChanged(),
      takeUntil(this.destroy$))
      .subscribe((form: object) => {
        if (Object.keys(form).length && this.merchantEventsForm.dirty) {
          this.getMerchantEvents();
        }
      })
  }

  onPageChange(pageInfo: { offset: number }) {
    this.pageIndex = pageInfo.offset;
    this.getMerchantEvents();
  }

  onSortChange(event: { sorts: [{ dir: string, prop: string }] }) {
    if (event.sorts[0].dir === 'asc') {
      this.orderBy = new ODataOrderByAsc(event.sorts[0].prop);
    } else {
      this.orderBy = new ODataOrderByDesc(event.sorts[0].prop);
    }

    this.getMerchantEvents();
  }

  getMerchantEvents() {
    let oDataFilters: BaseODataFilter[] = this.getODataFilters();

    let oDataConfig: ODataConfig = {
      query: apiPathConstants.merchantEvent,
      top: this.pageSize,
      skip: this.pageSize * this.pageIndex,
      count: true,
      orderBy: this.orderBy,
      filters: oDataFilters
    };

    this.loadingService.setLoading();

    this.oDataService.get<MerchantEvent[]>(oDataConfig)
      .pipe(finalize(() => {
        this.loadingService.clearLoading();
      }))
      .subscribe(data => {
        this.rows = this.formatMerchantEvents(data.value);
        this.totalElements = data['@odata.count'];
      });
  }

  edit(merchantEventId: number) {
    this.router.navigate([RouteConstants.editMerchantEvent, merchantEventId]);
  }

  getStateName(state: MerchantEventState) {
    let merchantEventStateName = merchantEventStateNames.get(state);

    return merchantEventStateName ? merchantEventStateName : '';
  }

  private formatMerchantEvents(merchantEvents: MerchantEvent[]) {
    for (let merchantEvent of merchantEvents) {
      merchantEvent.EventDate = this.dateTimeService.formatWithHours(merchantEvent.EventDate);
      merchantEvent.Modified = this.dateTimeService.formatWithHours(merchantEvent.Modified);
    }

    return merchantEvents;
  }

  private getODataFilters() {
    let oDataFilters: BaseODataFilter[] = [];

    if (this.state.tradingName.length > 3) {
      oDataFilters.push(new ContainsODataFilter(merchantEventProps.TradingName, this.state.tradingName));
    }

    if (this.state.legalName.length > 3) {
      oDataFilters.push(new ContainsODataFilter(merchantEventProps.LegalName, this.state.legalName));
    }

    if (this.state.abn.length > 3) {
      oDataFilters.push(new ContainsODataFilter(merchantEventProps.Abn, this.state.abn));
    }

    if (this.state.eventType.length > 3) {
      oDataFilters.push(new ContainsODataFilter(merchantEventProps.EventType, this.state.eventType));
    }

    if (this.state.eventSource.length > 3) {
      oDataFilters.push(new ContainsODataFilter(merchantEventProps.EventSource, this.state.eventSource));
    }

    if (this.state.eventSourceId.length > 3) {
      oDataFilters.push(new ContainsODataFilter(merchantEventProps.EventSourceId, this.state.eventSourceId));
    }

    if (!this.valueCheckerService.isNullOrUndefined(this.state.state)) {
      oDataFilters.push(new ODataFilter(merchantEventProps.State, this.state.state.toString()));
    }

    return oDataFilters;
  }
}
