import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { getEnumValuesQuery, hotChocolate } from "../gqlconstants/gqlconstants";
import { EnumValuesResponse } from "../components/managemissingmerchants/managemissingmerchants.component";
import { StringService } from "../core/formatting/string.service";

@Injectable({
  providedIn: "root"
})
export class EnumValuesService {
  recordStatus: string = "RecordStatus";

  constructor(private apollo: Apollo, private stringService: StringService) {
  }

  getEnumValues() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<EnumValuesResponse>({
        query: gql`${this.stringService.format(getEnumValuesQuery, this.recordStatus)}`
      })
      .valueChanges;
  }
}
