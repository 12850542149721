import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiService } from './../core/api/api.service';
import { ApiClient } from './../core/api/apiclient';
import { blobCacheSourceTypesQuery, hotChocolate } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { BlobCacheSourceType } from '../components/cacherefresh/cacherefresh.models';

@Injectable({
  providedIn: "root"
})
export class CacheRefreshService {

  constructor(private apollo: Apollo, private apiClient: ApiClient, private apiService: ApiService) { }

  getBlobCacheSourceTypes() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<BlobCacheSourceType[]>({
        query: blobCacheSourceTypesQuery
      })
      .valueChanges;
  };

  getStatus(value: number) {
    return this.apiClient.post<string>(this.apiService.buildUrl(apiPathConstants.blobCache + '/' + value.toString()), null);
  }

  getAllStatus() {
    return this.apiClient.post<string>(this.apiService.buildUrl(apiPathConstants.blobCache), null);
  }
}
