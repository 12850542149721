import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { DialogService } from '../../core/uiservices/dialog.service';
import { unsavedChangesMessage } from '../../core/constants/constants';
import { tap } from 'rxjs/operators';
import { ManageInternationalChainsComponent } from './manageinternationalchains.component';
import { ManageInternationalChainsDataService } from './manageinternationalchainsdata.service';

@Injectable()
export class ManageInternationalChainsDeactivateGuard implements CanDeactivate<ManageInternationalChainsComponent> {

	constructor(private dialogService: DialogService, private manageInternationalChainsDataService: ManageInternationalChainsDataService) { }

	canDeactivate(component: ManageInternationalChainsComponent) {
		if (this.manageInternationalChainsDataService.isDirty && !component.isEditingOrCreating) {
			const dialogRef = this.dialogService.openConfirmDialog(unsavedChangesMessage)

			return dialogRef.afterClosed().pipe(tap(result => {
				if (result) {
					this.manageInternationalChainsDataService.chains = [];
					this.manageInternationalChainsDataService.clearChainUpdate();
				}
			}));
		}

		return true;
	}
}