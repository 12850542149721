import { Injectable } from "@angular/core";
import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";
import { CacheType, RedisLookupRequest, RedisLookupResult } from "../components/rediscachelookup/rediscachelookupdto";
import { apiPathConstants } from "../core/api/apipathconstants";
import { RadisDeleteSharedCalsRequest, RedisDeleteRequest } from "../components/rediscachelookup/rediscachelookup.models";
import { Apollo } from "apollo-angular";
import { cacheType, hotChocolate } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: 'root'
})
export class RedisService {
  constructor(private apiService: ApiService, private apiClient: ApiClient, private apollo: Apollo) { }

  lookup(redisCacheLookupRequest: RedisLookupRequest) {
    return this.apiClient.post<RedisLookupResult>(this.apiService
      .buildUrl('redis/lookup'), redisCacheLookupRequest)
  }

  delete(redisDeleteRequest: RedisDeleteRequest) {
    return this.apiClient.post<void>(this.apiService
      .buildUrl(apiPathConstants.redisDelete), redisDeleteRequest);
  }

  getCacheType() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<CacheType[]>({
        query: cacheType,
      })
      .valueChanges
  }

  deleteSharedCals(cals: RadisDeleteSharedCalsRequest) {
    return this.apiClient.post<void>(this.apiService.buildUrl(apiPathConstants.deleteSharedCals), cals);
  }
}
