import { HttpMethod } from './../../core/coreenums/coreenums';
import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { bsbsCleansedResponse, assignBsbToMerchantResponse } from '../mockjsonresponses/managebsbs.response';

export function manageBsbsBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesBsbCleansed = false;
  if (request.body && request.body.query) {
    includesBsbCleansed = request.body.query.includes("{\n  data: bsbCleansed");
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesBsbCleansed) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...bsbsCleansedResponse }
      }));
      resp.complete();
    });
  }

  let includesAssignBsbToMerchant = false;
  if (request.body && request.body.query) {
    includesAssignBsbToMerchant = request.body.query.includes("mutation {\n  assignBsbToMerchant");
  }
  if (cleanUrl.includes(apiPathConstants.hotChocolate) && method === HttpMethod.Post && includesAssignBsbToMerchant) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...assignBsbToMerchantResponse }
      }));
      resp.complete();
    });
  }
}
