import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthGuard {
  constructor(private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    localStorage.setItem('url', state.url);
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.authService.login();
    return false;
  }
}
