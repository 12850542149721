import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { CatalogSearchResult } from '../models/cataloguesearchresult';

@Injectable()
export class NameCatalogueService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) {}

  getCatalogueSearchResult(nameaddress: string, match: string) {
    return this.apiClient.get<CatalogSearchResult>(this.apiService
      .buildUrl('catalog/search', nameaddress, match));
  }

  getCatalogAddNewNameResult(name: string, abnacn: number) {
    return this.apiClient.post<[number, string]>(this.apiService
      .buildUrl('catalog/addname'), { name: name, abnacn: abnacn });
  }
}
