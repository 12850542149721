import { CanDeactivate } from '@angular/router';
import { CategoryAllocationPatternsComponent as CategoryAllocationPatternsComponent } from './categoryallocationpatterns.component';
import { Injectable } from '@angular/core';
import { DialogService } from '../../core/uiservices/dialog.service';
import { unsavedChangesMessage } from '../../core/constants/constants';

@Injectable()
export class CategoryAllocationPatternsDeactivateGuard implements CanDeactivate<CategoryAllocationPatternsComponent> {

    constructor(private dialogService: DialogService) { }

    canDeactivate(component: CategoryAllocationPatternsComponent) {
        if (component.isDirty) {
            const dialogRef = this.dialogService.openConfirmDialog(unsavedChangesMessage)

           return dialogRef.afterClosed();
        }

        return true;
    }
}
