export let whoisResponse: any = {
    'whoisRecord': {
        'domainName': null,
        'parseCode': 0,
        'audit': null,
        'registrarName': null,
        'registrarIANAID': null,
        'registryData': null,
        'domainNameExt': null
    },
    'ausRegistryDetails': {
        'Domain Name': 'abc.net.au',
        'Status': 'clientDeleteProhibited',
        'Status_3': 'clientUpdateProhibited',
        'Registrar Name': 'NetRegistry',
        'Registrant': 'Australian Broadcasting Corporation',
        'Registrant ID': 'OTHER N/A',
        'Eligibility Type': 'Other',
        'Eligibility Name': 'Australian Broadcasting Corporation',
        'Registrant Contact ID': 'MANA1026',
        'Registrant Contact Name': 'Domain Names Manager',
        'Registrant Contact Email': 'Visit whois.ausregistry.com.au for Web based WhoIs',
        'Tech Contact ID': 'C0834767-AR',
        'Tech Contact Name': 'unknown',
        'Tech Contact Email': 'Visit whois.ausregistry.com.au for Web based WhoIs',
        'Name Server': 'ns1.telstra.net',
        'Name Server_20': 'ns.abc.net.au',
        'Name Server IP': '203.2.218.207',
        'Name Server_22': 'eur5.akam.net',
        'Name Server_23': 'ns1-129.akam.net',
        'Name Server_24': 'ns1-31.akam.net',
        'Name Server_25': 'asia1.akam.net',
        'Name Server_26': 'usw1.akam.net',
        'Name Server_27': 'usw5.akam.net',
        'Name Server_28': 'eur3.akam.net',
        'Name Server_29': 'eur2.akam.net',
        'DNSSEC': 'unsigned'
    },
    'ABN': '787889821',
    'ACN': '567238934',
    'OrganisationalNames': ['Australian Broadcasting Corporation', 'Australian Broadcasting Corporation']
};
