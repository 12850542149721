import { ExecutionResult } from "graphql";

export let retrieveExperianAddressResponse: ExecutionResult<string[]> = {
  data: [
    "North Canberra Family Practice, 21 Benjamin Way, BELCONNEN ACT 2617",
    "North Canberra Family Practice, 1 Chandler Street, BELCONNEN ACT 2617",
    "North Canberra Family Practice, 5 Chandler Street, BELCONNEN ACT 2617",
    "North Canberra Family Practice, 5b Chandler Street, BELCONNEN ACT 2617",
    "North Canberra Family Practice, 153 Emu Bank, BELCONNEN ACT 2617"
  ]
};
