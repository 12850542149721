import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiService } from './../core/api/api.service';
import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { ApiClient } from '../core/api/apiclient';
import { BrokenLinksResponse } from '../components/brokenlinks/brokenlinks.response';
import { urlVerificationQuery, eventing } from '../gqlconstants/gqlconstants';

@Injectable({
  providedIn: 'root'
})

export class BrokenLinksService {
  private urlVerificationQuery = urlVerificationQuery;

  constructor(private apollo: Apollo, private apiService: ApiService,
     private apiClient: ApiClient) { }

  getBrokenLinks() {
    return this.apollo
      .use(eventing)
      .watchQuery<BrokenLinksResponse>({
        query: this.urlVerificationQuery,
      })
      .valueChanges;
  }

  startUrlScanner() {
    return this.apiClient.get<void>(this.apiService.buildApiUrlWithParameters(apiPathConstants.eventingVerify, { name: 'property', value: 'url' }));
  }
}
