import { Component, OnInit, Injector } from "@angular/core";
import { BaseStateComponent } from "../basestate/basestate.component";
import { ManageNamesState, PersonName, PersonNameDisplay } from "./managepersonnamesstate";
import { LoadingService } from "../../core/uiservices/loading.service";
import { finalize, takeUntil } from "rxjs/operators";
import { personNameTypeDisplayValues, PersonNameType } from "../../enums/enums";
import { NotificationService } from "../../core/uiservices/notification.service";
import { ApolloQueryResult } from "@apollo/client/core";
import { PersonNameService } from "../../services/managenames.service";

@Component({
    selector: 'app-managepersonnames',
    templateUrl: './managepersonnames.component.html',
    styleUrls: ['./managepersonnames.component.scss']
})
export class ManagePersonNamesComponent extends BaseStateComponent<ManageNamesState> implements OnInit {
    personNameType = PersonNameType;

    constructor(injector: Injector, private loadingService: LoadingService, private notificationService: NotificationService,
        private managePersonNamesService: PersonNameService) {
        super(injector);
    }

    ngOnInit() {
        this.state = {
            searchString: '',
            personNames: [],
            nameToAdd: '',
            nameTypeToAdd: PersonNameType.FirstName,
            transactionDescription: ''
        }
    }

    getPersonNames() {
        this.loadingService.setLoading();
        let resultObservable = this.managePersonNamesService.getPersonName(this.state.searchString);

        resultObservable.pipe(takeUntil(this.destroy$), finalize(() => this.loadingService.clearLoading()))
            .subscribe(this.handleSearchResult);
    }

    deletePersonName(row: PersonName) {
        this.loadingService.setLoading();
        let personName = {
          name: row.name,
          nameType: row.nameType
        }
        let resultObservable = this.managePersonNamesService.deletePersonName(personName);
        resultObservable.pipe(takeUntil(this.destroy$), finalize(() => this.loadingService.clearLoading())).subscribe(() => {
            this.loadingService.clearLoading();
            this.notificationService.notifySuccess(`Name ${row.name} deleted successfully`);
            let index = this.state.personNames.indexOf(row);
            this.state.personNames.splice(index, 1);
            this.state.personNames = [...this.state.personNames];
        });
    }

    addPersonName() {
        this.loadingService.setLoading();
        let resultObservable = this.managePersonNamesService.addPersonName(this.state.nameToAdd, this.state.nameTypeToAdd);
        resultObservable.pipe(takeUntil(this.destroy$), finalize(() => this.loadingService.clearLoading())).subscribe(() => {
            this.loadingService.clearLoading();
            let newPersonName: PersonNameDisplay = {
                name: this.state.nameToAdd,
                nameType: this.state.nameTypeToAdd,
                nameTypeDisplay: personNameTypeDisplayValues.get(this.state.nameTypeToAdd)
            }
            this.state.personNames.push(newPersonName);
            this.state.personNames = [...this.state.personNames];
            this.notificationService.notifySuccess(`Name ${this.state.nameToAdd} added succesfully`);
        });
    }

    getPersonNameBasedOnTransactionDescription() {
        this.loadingService.setLoading();
        let resultObservable = this.managePersonNamesService.testTransactionDescription(this.state.transactionDescription);
        resultObservable.pipe(takeUntil(this.destroy$), finalize(() => this.loadingService.clearLoading())).subscribe(this.handleSearchResult);
    }

    private handleSearchResult = (result: ApolloQueryResult<PersonName[]>) => {
        this.loadingService.clearLoading();
        this.state.personNames = [];
        if (!result.data) {
            return;
        }
        for (let personName of result.data) {
            let newPersonName: PersonNameDisplay = { ...personName };
            newPersonName.nameTypeDisplay = personNameTypeDisplayValues.get(personName.nameType);
            this.state.personNames.push(newPersonName);
        }
    }
}
