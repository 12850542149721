import { propertyOf } from '../core/services/reflection.service';

export interface CAL {
  iD_no: number;
  transactionDescription: string;
  currentStatusID?: number;
  numberOfSearches?: number;
  cALType?: number;
  iD_Indexer?: number;
  chain: string;
  link: string;
  lWC_ID?: number;
  createDateTime: string;
  currentStatusDateTime?: string;
  indexerName: string;
}

export interface CALsColumnsFormatted extends CAL {
  CALTypeText: string;
  CurrentStatusText: string;
  CreateDateTimeFormatted: string;
  CurrentStatusDateTimeFormatted: string;
}

export const calsProps = {
  Id_no: propertyOf<CAL>('iD_no'),
  TransactionDescription: propertyOf<CAL>('transactionDescription'),
  CurrentStatusID: propertyOf<CAL>('currentStatusID'),
  NumberOfSearches: propertyOf<CAL>('numberOfSearches'),
  CALType: propertyOf<CAL>('cALType'),
  Chain: propertyOf<CAL>('chain'),
  Link: propertyOf<CAL>('link'),
  LWC_ID: propertyOf<CAL>('lWC_ID'),
  CreateDateTime: propertyOf<CAL>('createDateTime'),
  CurrentStatusDateTime: propertyOf<CAL>('currentStatusDateTime')
};

export const calsColumnsFormattedProps = {
  IndexerName: propertyOf<CALsColumnsFormatted>('indexerName'),
  CALTypeText: propertyOf<CALsColumnsFormatted>('CALTypeText'),
  CurrentStatusText: propertyOf<CALsColumnsFormatted>('CurrentStatusText'),
  CreateDateTimeFormatted: propertyOf<CALsColumnsFormatted>('CreateDateTimeFormatted'),
  CurrentStatusDateTimeFormatted: propertyOf<CALsColumnsFormatted>('CurrentStatusDateTimeFormatted')
};

export interface GroupOfSimilarCals {
  transactionDescription: string,
  chain: string,
  group: string,
  count: number
}
