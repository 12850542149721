import { finalize } from 'rxjs/operators';
import { LoadingService } from './../../core/uiservices/loading.service';
import { Component, OnInit } from '@angular/core';
import { CacheRefreshService } from '../../services/cacherefresh.service';
import { BlobCacheSourceTypeDisplay } from './cacherefresh.models';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NotificationService } from '../../core/uiservices/notification.service';

@Component({
  selector: 'app-cacherefresh',
  templateUrl: './cacherefresh.component.html',
  styleUrls: ['./cacherefresh.component.scss']
})
export class CacheRefreshComponent implements OnInit {
  columnMode = ColumnMode;
  blobCacheSourceTypes: BlobCacheSourceTypeDisplay[] = [];
  statusForAllRecords: string;

  constructor(private loadingService: LoadingService, private cacheRefreshService: CacheRefreshService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.setBlobCacheSourceTypes();
  }

  setStatus(row: BlobCacheSourceTypeDisplay) {
    this.loadingService.setLoading();
    this.cacheRefreshService.getStatus(row.value)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        if (response) {
          row.status = response;
        }
      },
        error => {
          row.status = this.notificationService.buildErrorMessage(error);
        }
      );
  }

  setAllStatus() {
    this.loadingService.setLoading();
    this.cacheRefreshService.getAllStatus()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        if (response) {
          this.statusForAllRecords = response;
        }
      },
        error => {
          this.statusForAllRecords = this.notificationService.buildErrorMessage(error);
        }
      );
  }

  private setBlobCacheSourceTypes() {
    this.loadingService.setLoading();
    this.cacheRefreshService.getBlobCacheSourceTypes()
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.length > 0) {
          this.blobCacheSourceTypes = response.data;
        }
      });
  }
}
