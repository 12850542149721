import { Component, OnInit } from "@angular/core";
import { LoadingService } from "../../../core/uiservices/loading.service";
import { Router, ActivatedRoute } from "@angular/router";
import { finalize } from "rxjs/operators";
import { SrapingDatasourcesService } from "../../../services/scrapingdatasources.service";
import { ScrapedData } from "../scrapingdatadto";
import { DateTimeService } from "../../../core/uiservices/datetime.service";
import { RouteConstants } from "../../../core/constants/constants";

@Component({
    selector: 'app-scrapeddata',
    templateUrl: './scrapeddata.component.html',
    styleUrls: ['./scrapeddata.component.scss']
})

export class ScrapedDataComponent implements OnInit {
    scrapedData: ScrapedData[];
    sourceData: string;

    constructor(private loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute,
        private scrapingDataService: SrapingDatasourcesService, private dateTimeService: DateTimeService) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(data => {
            if (data.source) {
                this.loadingService.setLoading();
                this.sourceData = data.source;
                this.scrapingDataService.getScrapedData(data.source)
                    .pipe(finalize(() => this.loadingService.clearLoading()))
                    .subscribe(response => {
                        if (response) {
                            this.scrapedData = response;
                            for (let value of this.scrapedData) {
                                value.sourceDateTime = this.dateTimeService.formatWithHours(value.sourceDateTime);
                            }
                        }
                    });
            }
            else {
                this.router.navigate([RouteConstants.scrapingDataSources]);
            }
        })
    }

    goBack() {
        this.router.navigate([RouteConstants.scrapingDataSources]);
    }
}