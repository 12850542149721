import { Injectable } from '@angular/core';
import { RegionType } from '../../users/userenums';
import { NameValue } from '../models/namevalue';
import { Subject } from 'rxjs';
@Injectable()
export class RegionStateService {
    regionValueChanges = new Subject<RegionType>();
    private storage: Storage = window.localStorage;
    regionNames = new Map<RegionType, string>([
        [RegionType.AUS, 'AUS'],
        [RegionType.NZL, 'NZL']
      ]);

    constructor() { }

    setCurrentRegion(regionType: RegionType) {
        this.storage.setItem('currentRegion', regionType.toString());
        this.regionValueChanges.next(regionType);
    }

    getCurrentRegion() {
        let value = this.storage.getItem('currentRegion');
        if (value === null) {
            return null;
        }

        return <RegionType>parseInt(value, 10);
    }

    getRegionName(regionType: RegionType) {
        return this.regionNames.get(regionType);
    }

    getRegionNameValue(regionType: RegionType): NameValue<RegionType> {
        return {name: this.getRegionName(regionType), value: regionType};
    }

    getAllRegionsNameValue() {
        let regions: NameValue<RegionType>[] = [];

        this.regionNames.forEach((value, key) => {
            regions.push(this.getRegionNameValue(key));
          });

        return regions;
    }
}
