import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteConstants } from "../../../core/constants/constants";
import { DataScrapingDetails, DataScrapingPathConfiguration } from "../datascraping";
import { LoadingService } from "../../../core/uiservices/loading.service";
import { finalize } from "rxjs/operators";
import { cloneDeep } from 'lodash';
import { DataScrapingService } from "../../../services/datascraping.service";

@Component({
    selector: 'app-editdatascraping',
    templateUrl: './editdatascraping.component.html',
    styleUrls: ['./editdatascraping.component.scss']
})
export class EditDataScrapingComponent implements OnInit {
    manageDataScraping: DataScrapingDetails;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private manageDataScrapingService: DataScrapingService,
        private loadingService: LoadingService) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(chainId => {
            if (chainId['chainId']) {
                let lwcChainId = this.activatedRoute.snapshot.params['chainId'];
                this.manageDataScrapingService.getDataScrapingDetails(lwcChainId).subscribe(result => {
                    this.manageDataScraping = cloneDeep(result);
                });
            }
            else {
                this.router.navigate([RouteConstants.managedatascraping]);
            }
        })
    }

    createNewRecord() {
        let newRecord: DataScrapingPathConfiguration = {
            chainId: "",
            accessTypeClass: "",
            path: "",
            contentGetterClass: "",
            dataNode: ""
        };
        this.manageDataScraping.pathConfigurations.push(newRecord);
        this.manageDataScraping.pathConfigurations = [...this.manageDataScraping.pathConfigurations];
    }

    cancel() {
        this.router.navigate([RouteConstants.managedatascraping]);
    }

    save() {
        this.loadingService.setLoading();
        this.manageDataScrapingService.saveDataScrapingDetails(this.manageDataScraping).pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(() => {
                this.router.navigate([RouteConstants.managedatascraping]);
            })
    }

    deleteRow(row: DataScrapingPathConfiguration) {
        this.manageDataScraping.pathConfigurations.splice(this.manageDataScraping.pathConfigurations.indexOf(row), 1);
        this.manageDataScraping.pathConfigurations = [...this.manageDataScraping.pathConfigurations];
    }

}
