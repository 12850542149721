import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { JsonViewerCustomComponent } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewercustomcomponent';
import { MerchantSummaryFirstPart, MerchantSummarySecondPart } from '../mergemerchants/merchantsummary';
import { SegmentConfig } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/lwc-ngx-json-viewer.component';
import { Router } from '@angular/router';
import { RouteConstants } from '../../core/constants/constants';


@Component({
  selector: 'app-merchantsummaryjsonviewer',
  templateUrl: './merchantsummaryjsonviewer.component.html',
  styleUrls: ['./merchantsummaryjsonviewer.component.scss']
})

export class MerchantSummaryJsonViewerComponent implements OnChanges {

  jsonViewerCustomComponents: JsonViewerCustomComponent[];
  ignoredKeys: string[];
  jsonViewerCssClasses: {};
  color: string;
  private emptyLogo = 'assets/img/EmptyLogo.png';

  @Input() merchantLookupResponseFirstPart: MerchantSummaryFirstPart;
  @Input() merchantLookupResponseSecondPart: MerchantSummarySecondPart;
  @Input() segmentConfigs: SegmentConfig[] = [];

  circularLogo = this.emptyLogo;
  squareLogo = this.emptyLogo;
  threeToOneLogo = this.emptyLogo;
  squareLogoDark = this.emptyLogo;
  circularLogoDark = this.emptyLogo;
  threeToOneLogoDark = this.emptyLogo;

  openPanel: boolean;

  constructor(private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService, private router: Router) {
    this.openPanel = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['merchantLookupResponseFirstPart']) {
      this.customizeMerchantSummaryJsonViewer();
    }

  }

  checkMerchantLookupResponse() {
    if (this.merchantLookupResponseFirstPart) {
      return true;
    }
    return false;
  }

  setLogos() {
    this.circularLogo = this.merchantLookupResponseFirstPart.CircularLogo
      ? this.merchantLookupResponseFirstPart.CircularLogo
      : this.emptyLogo;

    this.threeToOneLogo = this.merchantLookupResponseFirstPart.ThreeToOneLogo
      ? this.merchantLookupResponseFirstPart.ThreeToOneLogo
      : this.emptyLogo;

    this.squareLogo = this.merchantLookupResponseFirstPart.SquareLogo
      ? this.merchantLookupResponseFirstPart.SquareLogo
      : this.emptyLogo;

    this.circularLogoDark = this.merchantLookupResponseFirstPart.CircularLogoDark
      ? this.merchantLookupResponseFirstPart.CircularLogoDark
      : this.emptyLogo;

    this.threeToOneLogoDark = this.merchantLookupResponseFirstPart.ThreeToOneLogoDark
      ? this.merchantLookupResponseFirstPart.ThreeToOneLogoDark
      : this.emptyLogo;

    this.squareLogoDark = this.merchantLookupResponseFirstPart.SquareLogoDark
      ? this.merchantLookupResponseFirstPart.SquareLogoDark
      : this.emptyLogo;
  }

  setColor(value: number) {
    if (value < 82) {
      this.color = "red";
    }
    if (value >= 82 && value < 90) {
      this.color = "orange";
    }
    if (value >= 90 && value < 100) {
      this.color = "lightGreen";
    }
    if (value >= 100) {
      this.color = "darkGreen";
    }
  }

  navigateToMerchantLogo() {
    this.router.navigate([RouteConstants.merchantlogo, { lwcId: this.merchantLookupResponseFirstPart.LWC_ID }]);
  }

  private customizeMerchantSummaryJsonViewer() {
    this.jsonViewerCssClasses = this.merchantSummaryJsonViewerService.getQuarantinedOrLockedCssClasses(this.merchantLookupResponseFirstPart);
    this.segmentConfigs = this.merchantSummaryJsonViewerService.getMerchantSummarySegmentConfigs();
    this.jsonViewerCustomComponents = this.merchantSummaryJsonViewerService.getjsonViewerCustomComponents();
    this.ignoredKeys = this.merchantSummaryJsonViewerService.ignoredKeys;
    this.setLogos();
    this.setColor(this.merchantLookupResponseSecondPart.DataCompletnessScore);
  }

}
