import { PaymentMethod } from "../models/calparserresult";

export enum SearchType {
  Exact = 1,
  Fuzzy = 2,
  Phrase = 3
}

export enum IndexMerchantantResultStatus {
        Incomplete = 0,
        Success = 1,
        Warning = 2,
        Error = 3,
        IndexingFailed = 4,
        AlreadyIndexed = 5
}

export enum OverallResult {
  Incomplete = 0,
  Success = 1,
  Warning = 2,
  Error = 3,
  IndexingFailed = 4,
  AlreadyIndexed = 5
}

export enum ValidateMerchantRecordResult {
  Passed = 0,
  Warning = 1,
  Error = 2,
  NotExecuted = 3
}

export enum HttpStatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404
}

export enum TypeOfCAL {
  Unknown = 0,
  SuspectedInternationalCAL = 1,
  ChainWithCALData = 2,
  ChainNoCALData = 3,
  NonChainCALData = 4,
  NonChainNoCALData = 5
}

export enum CALStatuses {
  NeedsVerifying = 1,
  NeedsReVerifying = 2,
  BeingAutoIndexed = 3,
  LockedByIndexer = 4,
  FailedReview = 5,
  Unallocated = 6,
  Indexed = 7,
  InternationalCAL = 8,
  Verified = 9,
  SharedCAL = 10,
  BankMessage = 11,
  Purged = 12,
  TrickyCAL = 13,
  Allocated = 14
}

export enum ODataFilterType {
  Equals = 1,
  Contains = 2,
  StartsWith = 3
}

export enum SearchResultsCodes {
  /// 0 - This is returned by version 1 and 2 of the API
  Unclassified = 0,
  /// 10000 - The CAL/TID/MID is matched to one merchant and the match confidence is high
  HighConfidenceMatch = 10000,
  /// 10001 - No Match with CAL/TID/MID
  NoResult = 10001,
  /// 10002 - The CAL/TID/MID is for a merchant located in a country we don’t fully index as yet
  InternationalCAL = 10002,
  /// 10003 - The CAL/TID/MID is incomplete
  PotentialInternationalCAL = 10003,
  /// 10004 - The CAL is too short. Must be 8 characters or more
  CALTooShort = 10004,
  /// 10005 - The CAL appears to be incorrect
  IncorrectCAL = 10005,
  /// 10006 - More than 20 merchant candidates have been returned for the CAL/TID/MID
  PotentialSharedCAL = 10006,
  /// 10007 - The CAL/TID/MID is for a bank message
  BankMessage = 10007,
  /// 10008 - The CAL/TID/MID is shared between multiple merchants
  SharedCAL = 10008,
  /// 10009 - The search timed out
  TimeOutExceeded = 10009,
  /// 10010 - The CAL/TID/MID is for an ATM
  ATM = 10010,
  /// 10011 - The CAL/TID/MID is matched to multiple merchants
  MatchedToMultipleMerchants = 10011,
  /// 10012 - The CAL/TID/MID is matched to 1 merchant but the confidence is low
  LowConfidenceMatch = 10012,
  /// 20001 - The CAL/TID/MID is currently being indexed. Please check back later
  BeingIndexed = 20001,
  /// 30001 - The merchant details for the matched merchant are not currently available
  MerchantDetailsUnavailable = 30001,
  /// 30002 - The merchant details for the matched merchant have been removed as they are sensitive
  SensitiveMerchantDetailsRemoved = 30002,
  /// 40001 - LWC is currently experiencing technical problems
  SystemException = 40001
}

export enum CategoryType {
  Expense = 0,
  Income = 1,
  OtherExpense = 2,
  OtherIncome = 3
}

export enum MatchConfidence {
  High = 0,
  Medium = 2,
  Low = 1
}

export let matchConfidenceStringValues = new Map<MatchConfidence, string>([
  [MatchConfidence.High, "High"],
  [MatchConfidence.Medium, "Medium"],
  [MatchConfidence.Low, "Low"],
]);

export enum LookupType {
  //PP*4806CODE is not a match for PP*
  StartsWith,
  //PP*4806CODE is a match for PP*
  StartsWithAllowNoSpaces,
  //The text can be anywhere in the string, with or without spaces, the ' are removed as well
  Contains,
  //This text cannot be in the string
  NotIncluded,
  //The text must be in the string with spaces on either side or at the end of the string
  WithinText,
  //The text must be in the string otherwise the lookup will be discounted
  MustInclude,
  //The first string must be at the start with a space and the 2nd string must be at the end
  // e.g. SEEK&&MELBOURNE - SEEK 123143143 MELBOURNE
  StartsWithEndsWith,
  // All the words must be in the string somewhere. Contains doesn't require spaces
  // e.g. 4029357733&&PAYPAL - 4029357733*PAYPAL*PAYPAL NR BEVERLEY
  ContainsAll,
  // The entire text must match "INTEREST" = "INTEREST"
  WholeText,
  // matches if there is no trailing space for example BRAD JONES&&PTY LTD
  StartsWithEndsWithAllowNoSpaces,
  WithinTextMultiple,
  // The text must contain an occurence of Regex pattern
  ContainsRegex,
  //The text will be evaluated by a custom method which should return true if the text matches.
  //The name of the method is specified in SearchString property
  Custom,
  // Search String is only combination of "+" and numbers like "+3837922384"
  EndsWith = 13
}

export let lookupTypeStringValuesWithSpaces = new Map<LookupType, string>([
  [LookupType.StartsWith , "Starts With"],
  [LookupType.StartsWithAllowNoSpaces , "Starts With Allow No Spaces"],
  [LookupType.Contains , "Contains"],
  [LookupType.NotIncluded , "Not Included"],
  [LookupType.WithinText , "Within Text"],
  [LookupType.MustInclude , "Must Include"],
  [LookupType.StartsWithEndsWith , "Starts With Ends With"],
  [LookupType.ContainsAll , "Contains All"],
  [LookupType.WholeText , "Whole Text"],
  [LookupType.StartsWithEndsWithAllowNoSpaces , "Starts With Ends With Allow No Spaces"],
  [LookupType.WithinTextMultiple , "Within Text Multiple"],
  [LookupType.ContainsRegex , "Contains Regex"],
  [LookupType.Custom , "Custom"],
]);

export enum LookupOptionType {
    TransactionDescriptionLookupOption = 0,
    ChainNameLookupOption = 1
}

export enum ChainDensity {
  Low = 100000,
  Medium = 20000,
  High = 1000,
  VeryHigh = 100
}

export enum ChainUpdateType {
  Added = 1,
  Updated = 2,
  Deleted = 3
}

export enum APIClients {
  LWCWebsite = 1,
  NAB = 2,
  ANZ = 3,
  CBA = 4
}

export enum MenuItemType {
  Section,
  Item,
  ExpansionPanel
}

export enum MerchantPresence {
  BricksMortar = 0,
  OnlineOnly = 1,
  MobileMerchant = 2,
  Unspecified = 3
}

export let merchantPresenceStringValuesWithSpaces = new Map<MerchantPresence, string>([
  [MerchantPresence.BricksMortar, 'Bricks & Mortar'],
  [MerchantPresence.MobileMerchant, 'Mobile Merchant'],
  [MerchantPresence.OnlineOnly, 'Online Only'],
  [MerchantPresence.Unspecified, 'Unspecified'],
]);

export let merchantPresenceStringValues = new Map<MerchantPresence, string>([
  [MerchantPresence.BricksMortar, 'BricksMortar'],
  [MerchantPresence.MobileMerchant, 'MobileMerchant'],
  [MerchantPresence.OnlineOnly, 'OnlineOnly'],
  [MerchantPresence.Unspecified, 'Unspecified'],
]);

export enum BankTransactionType {
    ATM = 1,
    BankFee = 2,
    MerchantExpense = 3
}

export enum MerchantValidationRecordSeverity {
  Error = 1,
  Warning = 2
}

export enum DateRangeOption {
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  Custom
}

export enum UnspecifiedTrueFalse {
  Unspecified,
  True,
  False
}

export enum PersonNameType {
  FirstName = 'FirstName',
  LastName = 'LastName'
}

export let personNameTypeDisplayValues = new Map<PersonNameType, string>([
  [PersonNameType.FirstName, 'FirstName'],
  [PersonNameType.LastName, 'LastName']
]);

export enum MergeMerchantsResultStatus {
  Success = 0,
  Warning = 1,
  Error = 2
}

export enum HttpMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE"
}

export enum GetCategoryByMappingMatchMethod {
  CategoryMappingRule,
  CategoryStandardiser
}

export let getCategoryByMappingRuleResponseDisplayValues = new Map<GetCategoryByMappingMatchMethod, string>([
  [GetCategoryByMappingMatchMethod.CategoryMappingRule, "Category Mapping Rule"],
  [GetCategoryByMappingMatchMethod.CategoryStandardiser, "Category Standardiser"]
]);

export enum BatchSplitOptions {
  UseBtpFull,
  UseBtpLite,
  UseBoth
};


export let paymentMethodDisplayValues = new Map<PaymentMethod, string>([
  [PaymentMethod.Unknown, "Unknown"],
  [PaymentMethod.BPay, "BPay"],
  [PaymentMethod.EFTPOS, "EFTPOS"],
  [PaymentMethod.NABVisaDebit, "NABVisaDebit"],
  [PaymentMethod.ANZVisaDebit, "ANZVisaDebit"],
  [PaymentMethod.NABVisaPlatinumDebit, "NABVisaPlatinumDebit"],
  [PaymentMethod.DEFT, "DEFT"],
  [PaymentMethod.NABVisaMiscDebit, "NABVisaMiscDebit"],
  [PaymentMethod.ATMUsingANZCard, "ATMUsingANZCard"],
  [PaymentMethod.CBAPTag, "CBAPTag"],
  [PaymentMethod.OtherExpense, "OtherExpense"],
  [PaymentMethod.OtherIncome, "OtherIncome"],
  [PaymentMethod.TapAndGo, "TapAndGo"],
  [PaymentMethod.CUAVisa, "CUAVisa"],
  [PaymentMethod.QTMBVisa, "QTMBVisa"],
  [PaymentMethod.INGVisa, "INGVisa"],
  [PaymentMethod.DirectDebit, "DirectDebit"],
  [PaymentMethod.STGeorgeVisa, "STGeorgeVisa"],
  [PaymentMethod.AUSTBankVisa, "AUSTBankVisa"],
  [PaymentMethod.BOQVisaDebit, "BOQVisaDebit"],
  [PaymentMethod.DirectCredit, "DirectCredit"],
  [PaymentMethod.InternetTransfer, "InternetTransfer"],
  [PaymentMethod.AMEX, "AMEX"],
  [PaymentMethod.Osko, "Osko"],
  [PaymentMethod.HandyWay, "HandyWay"],
  [PaymentMethod.Cirrus, "Cirrus"],
  [PaymentMethod.Maestro, "Maestro"],
  [PaymentMethod.Giro, "Giro"],
  [PaymentMethod.Visa, "Visa"],
  [PaymentMethod.Mastercard, "Mastercard"],
  [PaymentMethod.Card, "Card"],
  [PaymentMethod.ATM, "ATM"],
  [PaymentMethod.Salary, "Salary"],
  [PaymentMethod.Dividend,"Dividend"]
]);

export const roleNames = {
  indexManagerGlobalAdministrator: "IndexManagerGlobalAdministrator",
  indexManagerAdmin: "IndexManagerAdmin",
  indexManagerTeamLead: "IndexManagerTeamLead",
  indexManagerIndexer: "IndexManagerIndexer",
  indexManagerIndexerWithPurge: "IndexManagerIndexerWithPurge"
}

export enum MerchantStatus {
  Unassigned = 0,
  Assigned = 1,
  All = 2
}

export enum BsbStatus {
  Merged = 0,
  Closed = 1,
  Active = 2
}

export enum CriticalLevel {
  High = "HIGH",
  Mid = "MID",
}

export enum MerchantValidationStatsGroupBy {
  ValidationId,
  Indexer
};

export enum MerchantValidationTypes {
  EMAIL_WARNING,
  LOW_DATA_COMPLETENESS_SCORE_SET,
  NO_CONTACT_METHODS,
  MERCHANT_NAME_CONTAIN_LOCATION,
  DUPLICATE_MERCHANT,
  PRIMARY_ADDRESS_NO_STREET_ADDRESS1,
  NO_ACTIVE_CATEGORIES,
  MERCHANT_PRESENCE,
  MOBILE_NUMBER_AS_PRIMARY,
  GST_REGISTRATION,
  MERCHANT_NAME_CATEGORY_NOT_MACHED,
  MERCHANT_NAME_PROBLEM,
  BRANCH_FRANCHISE_MULTIPLE_POSSIBLE_CHAINS,
  MISSING_CHAIN_LOGOS,
  CONTACT_METHODS_NO_SCREENSHOT,
  IS_BRANCH_BUT_CAL_DOESNT_MATCH_CHAIN_PATTERN,
  PRIMARY_CATEGORY_PROBLEM,
  NON_ACTIVE_BUSINESS_STATUS,
  NO_PRIMARY_NUMBER_SET,
  CARD_ACCEPTOR_TOO_SHORT,
  MULTIPLE_BRANCH_IDENTIFIERS,
  QUARANTINED_CA_LOR_MERCHANT,
}

export enum ThreeWayCheckboxValue {
  None = 0,
  Unchecked = 1,
  Checked = 2
}

export let merchantValidationTypeStringValues = new Map <MerchantValidationTypes, string>([
  [MerchantValidationTypes.BRANCH_FRANCHISE_MULTIPLE_POSSIBLE_CHAINS, "BRANCH_FRANCHISE_MULTIPLE_POSSIBLE_CHAINS"],
  [MerchantValidationTypes.CARD_ACCEPTOR_TOO_SHORT, "CARD_ACCEPTOR_TOO_SHORT"],
  [MerchantValidationTypes.CONTACT_METHODS_NO_SCREENSHOT, "CONTACT_METHODS_NO_SCREENSHOT"],
  [MerchantValidationTypes.DUPLICATE_MERCHANT, "DUPLICATE_MERCHANT"],
  [MerchantValidationTypes.EMAIL_WARNING, "EMAIL_WARNING"],
  [MerchantValidationTypes.GST_REGISTRATION, "GST_REGISTRATION"],
  [MerchantValidationTypes.IS_BRANCH_BUT_CAL_DOESNT_MATCH_CHAIN_PATTERN, "IS_BRANCH_BUT_CAL_DOESNT_MATCH_CHAIN_PATTERN"],
  [MerchantValidationTypes.LOW_DATA_COMPLETENESS_SCORE_SET, "LOW_DATA_COMPLETENESS_SCORE_SET"],
  [MerchantValidationTypes.MERCHANT_NAME_CATEGORY_NOT_MACHED, "MERCHANT_NAME_CATEGORY_NOT_MACHED"],
  [MerchantValidationTypes.MERCHANT_NAME_CONTAIN_LOCATION, "MERCHANT_NAME_CONTAIN_LOCATION"],
  [MerchantValidationTypes.MERCHANT_NAME_PROBLEM, "MERCHANT_NAME_PROBLEM"],
  [MerchantValidationTypes.MERCHANT_PRESENCE, "MERCHANT_PRESENCE"],
  [MerchantValidationTypes.MISSING_CHAIN_LOGOS, "MISSING_CHAIN_LOGOS"],
  [MerchantValidationTypes.MOBILE_NUMBER_AS_PRIMARY, "MOBILE_NUMBER_AS_PRIMARY"],
  [MerchantValidationTypes.MULTIPLE_BRANCH_IDENTIFIERS, "MULTIPLE_BRANCH_IDENTIFIERS"],
  [MerchantValidationTypes.NON_ACTIVE_BUSINESS_STATUS, "NON_ACTIVE_BUSINESS_STATUS"],
  [MerchantValidationTypes.NO_ACTIVE_CATEGORIES, "NO_ACTIVE_CATEGORIES"],
  [MerchantValidationTypes.NO_CONTACT_METHODS, "NO_CONTACT_METHODS"],
  [MerchantValidationTypes.NO_PRIMARY_NUMBER_SET, "NO_PRIMARY_NUMBER_SET"],
  [MerchantValidationTypes.PRIMARY_ADDRESS_NO_STREET_ADDRESS1, "PRIMARY_ADDRESS_NO_STREET_ADDRESS1"],
  [MerchantValidationTypes.PRIMARY_CATEGORY_PROBLEM, "PRIMARY_CATEGORY_PROBLEM"],
  [MerchantValidationTypes.QUARANTINED_CA_LOR_MERCHANT, "QUARANTINED_CA_LOR_MERCHANT"],
]);

export enum NodeStandardiserStatus {
  NotMapped = 1,
  PartiallyMapped = 2,
  FullyMapped = 3
}
