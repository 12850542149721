import { Injectable } from "@angular/core";
import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";
import { apiPathConstants } from "../core/api/apipathconstants";
import { MatchCalsToChainsResponse, MatchCalsToChainRequest } from "../components/chainpatternmatcher/chainpatternmatcherstate.component";

@Injectable({
    providedIn: 'root'
})
export class ChainPatternMatcherService {

    constructor(private apiService: ApiService, private apiClient: ApiClient) { }

    getCalToChainMatch(cals: string[]) {
        let request: MatchCalsToChainRequest = {CALs: cals};
        return this.apiClient.post<MatchCalsToChainsResponse>(this.apiService.buildUrl(apiPathConstants.chainPatternMatcher), request);
    }
}