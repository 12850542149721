import { MerchantPresence } from "./enums";

export const merchantPresenceDisplayValues = new Map([
  [ MerchantPresence.BricksMortar, 'Bricks & Mortar' ],
  [ MerchantPresence.OnlineOnly, 'Online Only' ],
  [ MerchantPresence.MobileMerchant, 'Mobile Merchant' ],
  [ MerchantPresence.Unspecified, 'Unspecified' ]
])

export const merchantPresenceDisplayStringValues = new Map([
  [ MerchantPresence[MerchantPresence.BricksMortar], 'Bricks & Mortar' ],
  [ MerchantPresence[MerchantPresence.OnlineOnly], 'Online Only' ],
  [ MerchantPresence[MerchantPresence.MobileMerchant], 'Mobile Merchant' ],
  [ MerchantPresence[MerchantPresence.Unspecified], 'Unspecified' ]
])
