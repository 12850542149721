import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-description-dialog',
  templateUrl: './edit-description-dialog.component.html',
  styleUrls: ['./edit-description-dialog.component.scss']
})
export class EditDescriptionDialogComponent {

  Okay = new EventEmitter();
  ExistingDescription: string

  constructor(public dialog: MatDialogRef<EditDescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.description != null) {
      this.ExistingDescription = data.description;
    } else {
      this.ExistingDescription = '';
    }
  }

  closeDialog(): void {
    this.dialog.close();
  }

}




