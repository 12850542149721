import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector } from '@angular/core';
import { LoadingService } from '../../core/uiservices/loading.service';
import { ChainStoresState, UnmatchedMerchant, ChainCode } from './chainstores.state';
import { ChainStoresService } from '../../services/chainstores.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { DataScrapingService } from '../../services/datascraping.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';

export type UnmatchedMerchantsResponse = {
  unmatchedMerchants: UnmatchedMerchant[];
}

@Component({
  selector: 'app-chainstores',
  templateUrl: './chainstores.component.html',
  styleUrls: ['./chainstores.component.scss']
})
export class ChainStoresComponent extends BaseStateComponent<ChainStoresState> implements OnInit {
  chainCodes: ChainCode[] = [];
  unmatchedMerchants: UnmatchedMerchant[] = [];

  constructor(private loadingService: LoadingService, injector: Injector, private chainStoresService: ChainStoresService,
    private manageDataScrapingService: DataScrapingService, private valueCheckerService: ValueCheckerService) {
    super(injector)
  }

  ngOnInit() {
    this.state = {
      filteredUnmatchedMerchants: [],
      filterByStatus: '',
      filterByClosedConfidenceLevel: '',
      chainId: ''
    }
    this.restoreState();
    this.getChainCodes();
  }

  loadResults() {
    this.getUnmatchedMerchants();
  }

  handleClearInputForStatus() {
    this.state.filterByStatus = '';
    this.filterChainStores();
  }

  handleClearInputForClosedConfidenceLevel() {
    this.state.filterByClosedConfidenceLevel = '';
    this.filterChainStores();
  }

  setFilterByStatus(event: KeyboardEvent) {
    this.state.filterByStatus = (<HTMLInputElement>event.target).value;
    this.filterChainStores();
  }

  setFilterByClosedConfidenceLevel(event: KeyboardEvent) {
    this.state.filterByClosedConfidenceLevel = (<HTMLInputElement>event.target).value;
    this.filterChainStores();
  }

  private filterChainStores() {
    this.state.filteredUnmatchedMerchants = this.unmatchedMerchants.filter((unmatchedMerchant: UnmatchedMerchant) => {
      let isMatch = true;

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByStatus)) {
        isMatch = unmatchedMerchant.status.toLowerCase().includes(this.state.filterByStatus.toLowerCase());
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByClosedConfidenceLevel)) {
        isMatch = unmatchedMerchant.closedConfidenceLevel.toLowerCase().includes(this.state.filterByClosedConfidenceLevel.toLowerCase());
      }

      return isMatch;
    });
  }

  private getUnmatchedMerchants() {
    this.loadingService.setLoading();
    this.chainStoresService.getUnmatchedMerchants(this.state.chainId)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response) {
          this.state.filteredUnmatchedMerchants = this.unmatchedMerchants = response.data.unmatchedMerchants;
        }
      });
  }

  private getChainCodes() {
    this.loadingService.setLoading();
    this.manageDataScrapingService.getManageDataScraping().pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          for (let value of response) {
            this.chainCodes.push({ name: value.chainName, value: value.chainId });
          }
        }
      });
  }
}
