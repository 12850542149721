import { LocatedIn } from '../../models/locatedin';


export let getlocatedinresponse: Partial<LocatedIn>[] = [
  {
    LocatedIn_ID: 232456,
    LocatedIn_Name: '65 High St, Belmont 3216',
    StreetViewAvailable: true,
    AddressPostcode: '9635'
  },
  {
    LocatedIn_ID: 223566,
    LocatedIn_Name: '3 Low St, NeBelmont 3876',
    StreetViewAvailable: false,
    AddressPostcode: '1485'

  },
  {
    LocatedIn_ID: 223566,
    LocatedIn_Name: '5 Low St, NeBelmont 3876',
    StreetViewAvailable: true,
    AddressPostcode: '7564'
  },
  {
    LocatedIn_ID: 223566,
    LocatedIn_Name: '5 High St, NeBelmont 3876',
    StreetViewAvailable: false,
    AddressPostcode: '4688'
  }
];

export let getlocatedinresponsebypostcode: Partial<LocatedIn>[] = [
  {
    LocatedIn_ID: 567554,
    LocatedIn_Name: '41 High St, Welmont 426',
    StreetViewAvailable: true
  },
  {
    LocatedIn_ID: 897655,
    LocatedIn_Name: '35 Low St, Welmont 5176',
    StreetViewAvailable: false
  }
];
