import { hotChocolate, transactionBankListQuery } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { TransactionBank } from '../models/ausindexingresult';

@Injectable({
  providedIn: "root"
})
export class TransactionService {
  constructor(private apollo: Apollo) {
  }

  getTransactionBankList() {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<TransactionBank[]>({
        query: transactionBankListQuery
      })
      .valueChanges;
  }
}
