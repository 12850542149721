import { emptyString } from './../constants/constants';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Options } from 'angular2-csv';
import { DateTimeService } from '../uiservices/datetime.service';

@Injectable()
export class CsvComponentService {
    constructor(private dateTimeService: DateTimeService) { }

    getCsvComponentDefaultOptions() {
        let options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            filename: 'Export.csv',
            showLabels: true,
            useBom: false,
            removeNewLines: true,
            showTitle: false,
            title: emptyString
        };
        return options;
    }

    getCsvOptions(obj: {}, filename: string): Options {
        let columnNames = Object.keys(obj);
        let additionalCsvOptions = {
            headers: columnNames,
            keys: columnNames,
            showTitle: false,
            title: '',
            filename: this.getCsvFileName(filename)
        };
        let csvOptions = { ...this.getCsvComponentDefaultOptions(), ...additionalCsvOptions };

        return csvOptions;
    }

    private getCsvFileName(filename: string) {
        return filename + this.dateTimeService.formatForFileName(moment());
    }
}
