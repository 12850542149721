import { LoadingService } from '../../../core/uiservices/loading.service';
import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { CategoryMappingRuleDisplay, ExternalCategory } from '../categorymapping.models';
import { BaseComponent } from '../../../shared/base/base.component';
import { CategoryMappingRulesService } from '../../../services/categorymappingrules.service';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../core/constants/constants';
import { finalize, map, startWith } from 'rxjs/operators';
import { SelectedCategory } from '../../../shared/category/selectedcategory';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TagsService } from '../../../services/tags.service';
import { Tag } from '../../tags/tags.models';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ThreeWayCheckboxValue } from '../../../enums/enums';

@Component({
  selector: 'app-editcategorymappingrule',
  templateUrl: './editcategorymappingrule.component.html',
  styleUrls: ['./editcategorymappingrule.component.scss']
})
export class EditCategoryMappingRuleComponent extends BaseComponent implements OnInit {
  editCategoryMappingRule: CategoryMappingRuleDisplay;
  allTags: Tag[] = [];
  selectedInputTags: Tag[] = [];
  selectedOutputTags: Tag[] = [];
  externalCategoriesControl = new FormControl();
  filteredExternalCategories: Observable<ExternalCategory[]>;
  inputTagIdsName = 'inputTagIds';
  outputTagIdsName = 'outputTagIds';
  checkboxStatus = ThreeWayCheckboxValue.None;

  private externalCategories: ExternalCategory[] = [];

  constructor(injector: Injector, private loadingService: LoadingService, private router: Router,
    private categoryMappingRuleService: CategoryMappingRulesService, private cdRef: ChangeDetectorRef,
    private tagsService: TagsService) {
    super(injector)
  }

  ngOnInit() {
    this.editCategoryMappingRule = this.categoryMappingRuleService.getEditCategoryMappingRule();
    if (this.editCategoryMappingRule) {
      this.setTags();
      this.setExternalCategories();
      this.setCheckboxStatus();
    }
    if (!this.editCategoryMappingRule) {
      this.router.navigate([RouteConstants.categoryMappingRules]);
    }
  }

  addTag(event: MatAutocompleteSelectedEvent, inputOrOutputTagCase: string) {
    let tag: Tag = event.option.value;
    let indexOfTag = this.inputTagIdsName === inputOrOutputTagCase ? this.selectedInputTags.indexOf(tag) : this.selectedOutputTags.indexOf(tag);
    if (indexOfTag === -1) {
      if (inputOrOutputTagCase === this.inputTagIdsName) {
        this.selectedInputTags.push(tag);
        this.editCategoryMappingRule.TagIds.push(tag.id);
      }

      if (inputOrOutputTagCase === this.outputTagIdsName) {
        this.selectedOutputTags.push(tag);
        this.editCategoryMappingRule.OutputTagIds.push(tag.id);
      }
    }
  }

  removeTag(tag: Tag, inputOrOutputTagCase: string) {
    let indexOfTag = this.inputTagIdsName === inputOrOutputTagCase ? this.selectedInputTags.indexOf(tag) : this.selectedOutputTags.indexOf(tag);

    if (inputOrOutputTagCase === this.inputTagIdsName) {
      this.selectedInputTags.splice(indexOfTag, 1);
      this.editCategoryMappingRule.TagIds = [...this.editCategoryMappingRule.TagIds.filter(t => t !== tag.id)];
    }

    if (inputOrOutputTagCase === this.outputTagIdsName) {
      this.selectedOutputTags.splice(indexOfTag, 1);
      this.editCategoryMappingRule.OutputTagIds = [...this.editCategoryMappingRule.OutputTagIds.filter(t => t !== tag.id)];
    }
  }

  selectCategory(category: SelectedCategory) {
    if (category.isSelected) {
      this.editCategoryMappingRule.LwcCategoryId = category.category.CategoryID;
    }
    else {
      this.editCategoryMappingRule.LwcCategoryId = null;
      this.cdRef.detectChanges();
    }
  }

  cancel() {
    this.router.navigate([RouteConstants.categoryMappingRules]);
  }

  save() {
    this.loadingService.setLoading();
    let editCategoryMappingRule: CategoryMappingRuleDisplay = {
      Id: this.editCategoryMappingRule.Id,
      LwcCategoryId: this.editCategoryMappingRule.LwcCategoryId,
      IsRecurring: this.editCategoryMappingRule.IsRecurring ?? null,
      TagIds: this.editCategoryMappingRule.TagIds,
      MinAmountInclusive: this.editCategoryMappingRule.MinAmountInclusive,
      MaxAmountInclusive: this.editCategoryMappingRule.MaxAmountInclusive,
      NewCategoryId: this.editCategoryMappingRule.NewCategoryId,
      CategoryDataSource: this.editCategoryMappingRule.CategoryDataSource,
      OutputTagIds: this.editCategoryMappingRule.OutputTagIds,
    };

    this.categoryMappingRuleService.saveCategoryMappingRule(editCategoryMappingRule)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.router.navigate([RouteConstants.categoryMappingRules]);
      });
  }

  handleClearInputForExternalCategory() {
    this.editCategoryMappingRule.NewCategoryId = "";
  }

  externalCategorySelected(event: MatAutocompleteSelectedEvent) {
    this.editCategoryMappingRule.NewCategoryId = this.externalCategories.find(t => t.FullCategoryPath === event.option.value).CategoryId;
  }

  toggleIcon(checkboxStatus: number) {
    switch (checkboxStatus) {
      case ThreeWayCheckboxValue.Unchecked:
        this.editCategoryMappingRule.IsRecurring = null;
        break;
      case ThreeWayCheckboxValue.Checked:
        this.editCategoryMappingRule.IsRecurring = true;
        break;
      case ThreeWayCheckboxValue.None:
        this.editCategoryMappingRule.IsRecurring = false;
        break;
      default:
        this.editCategoryMappingRule.IsRecurring = null;
    }
  }

  private setCheckboxStatus() {
    switch (this.editCategoryMappingRule.IsRecurring) {
      case true:
        this.checkboxStatus = ThreeWayCheckboxValue.Checked;
        break;
      case false:
        this.checkboxStatus = ThreeWayCheckboxValue.None;
        break;
      case null:
        this.checkboxStatus = ThreeWayCheckboxValue.Unchecked;
        break;
      default:
        this.checkboxStatus = ThreeWayCheckboxValue.Unchecked;
    }
  }

  private setTags() {
    this.loadingService.setLoading();
    this.tagsService.getTags()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.allTags = response;
        for (let id of this.editCategoryMappingRule.TagIds) {
          let tag = this.allTags.find(t => t.id === id);
          if (tag) {
            this.selectedInputTags.push(tag);
          }
        }

        if (this.editCategoryMappingRule?.OutputTagIds) {
          for (let id of this.editCategoryMappingRule?.OutputTagIds) {
            let tag = this.allTags.find(t => t.id === id);
            if (tag) {
              this.selectedOutputTags.push(tag);
            }
          }
        } else {
          this.editCategoryMappingRule.OutputTagIds = [];
        }
      });
  }

  private setExternalCategories() {
    this.loadingService.setLoading();
    this.categoryMappingRuleService.getExternalCategories(this.editCategoryMappingRule.CategoryDataSource)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.length > 0) {
          this.externalCategories = response;
          this.filteredExternalCategories = this.externalCategoriesControl.valueChanges.pipe(
            startWith(null),
            map((field: string) => field ? this.filterExternalCategories(field) : this.externalCategories.slice()));
        }
      });
  }

  private filterExternalCategories(value: string) {
    return this.externalCategories.filter(t => t.FullCategoryPath.toLowerCase().includes(value.toLowerCase()));
  }

}
