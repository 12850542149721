import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { moneyTrackerV4ResponseInternal } from '../mockjsonresponses/moneytrackerresponse';
import { apiPathConstants } from '../../core/api/apipathconstants';

export function moneyTrackerSearchBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];
  if (cleanUrl.includes(apiPathConstants.moneytrackerEnrich) && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: moneyTrackerV4ResponseInternal
      }));
      resp.complete();
    })
  }
}