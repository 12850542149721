import { Injectable } from "@angular/core";
import { ApiClient } from "../core/api/apiclient";
import { ApiService } from "../core/api/api.service";
import { FlatCategory } from "../categories/flatcategory";
import { CacheService } from "../core/cache/cache.service";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { ValueCheckerService } from "../core/services/valuechecker.service";
import { apiPathConstants } from "../core/api/apipathconstants";
import { CategoryStandardiserDraft } from "../components/categorystandardiser/categorystandardiserdto";
import { Category, CategoryDraft, CategoryStandardiser, DataSource, ExternalCategoryNames, ExternalMappingRequest, MappingObject } from "../models/managecategory";

@Injectable()
export class CategoryService {
  private category = 'categorydraft/categories';
  constructor(private apiClient: ApiClient, private apiService: ApiService, private cacheService: CacheService,
    private valueCheckerService: ValueCheckerService) { }

  getFlatCategories(show_draft?: boolean) {
    let data = this.cacheService.get<FlatCategory[]>(this.category);

    if (!this.valueCheckerService.isNullOrUndefined(data)) {
      return of(data);
    }

    return this.apiClient.get<FlatCategory[]>(this.apiService.buildUrl(this.category + '/' + show_draft))
      .pipe(tap(result => this.cacheService.set(this.category, result)));;
  }

  getCategories() {
    return this.apiClient.get<FlatCategory[]>(this.apiService.buildUrl(this.category));
  }

  getCategoryStandardiserActivedDrafts() {
    return this.apiClient.get<CategoryStandardiserDraft[]>(this.apiService.buildUrl(apiPathConstants.getActivedDrafts));
  }

  updateCategoryStandardiserDrafts(categoryStandardiserDrafts: Partial<CategoryStandardiserDraft>[]) {
    return this.apiClient.post<string[]>(this.apiService.buildUrl(apiPathConstants.updateCategoryStandardiser), categoryStandardiserDrafts);
  }

  rejectCategoryStandardiserDrafts(categoryStandardiserDrafts: Partial<CategoryStandardiserDraft>[]) {
    return this.apiClient.post<string[]>(this.apiService.buildUrl(apiPathConstants.rejectCategoryStandardiser), categoryStandardiserDrafts);
  }

  getCategoryDraft() {
    return this.apiClient.get<Category[]>(this.apiService.buildUrl(apiPathConstants.getCategoryDraft));
  }

  addCategoryDraft(category: FlatCategory) {
    return this.apiClient.post<FlatCategory>(this.apiService.buildUrl(apiPathConstants.upsertCategoryDraft), category);
  }

  updateCategoryDraft(Category: FlatCategory) {
    return this.apiClient.post<FlatCategory>(this.apiService.buildUrl(apiPathConstants.upsertCategoryDraft), Category);
  }

  approveCategoryDraft(categorydraft: CategoryDraft) {
    return this.apiClient.post<any[]>(this.apiService.buildUrl(apiPathConstants.approveCategoryDraft), categorydraft);
  }

  rejectCategoryDraft(categorydraft: CategoryDraft) {
    return this.apiClient.post<any[]>(this.apiService.buildUrl(apiPathConstants.rejectCategoryDraft), categorydraft);
  }

  approveStandardizedMapping(categorystandardiser: CategoryStandardiser[]) {
    return this.apiClient.post<any[]>(this.apiService.buildUrl(apiPathConstants.approveStandardizer), categorystandardiser);
  }

  rejectStandardizedMapping(mappingStandardiser: MappingObject[]) {
    return this.apiClient.post<any[]>(this.apiService.buildUrl(apiPathConstants.rejectStandardizer), mappingStandardiser);
  }

  refreshCategoriesforCategories(show_draft?: boolean) {
    this.cacheService.clear(this.category);
    return this.getFlatCategories(show_draft);
  }

  refreshCategories() {
    this.cacheService.clear(this.category);
    return this.getFlatCategories();
  }

  getDataSource() {
    return this.apiClient.get<DataSource[]>(this.apiService.buildUrl(apiPathConstants.externalCategoryDataSource));
  }

  getAllExternalCategories(dataSource: string) {
    return this.apiClient.get<ExternalCategoryNames[]>(this.apiService.buildUrl(apiPathConstants.getAllExternalCategories + dataSource));
  }

  addExternalMapping(externalMappingData: ExternalMappingRequest) {
    return this.apiClient.post<ExternalMappingRequest>(this.apiService.buildUrl(apiPathConstants.addExternalMapping), externalMappingData);
  }

  deleteExternalCategoryMapping(data: any) {
    return this.apiClient.post<ExternalMappingRequest>(this.apiService.buildUrl(apiPathConstants.deleteExternalCategoryMapping), data);
  }
}

