import { hotChocolate, retailerCentersBasedOnCenterIdQuery, retailerCentersQuery, updateRetailerCenterWithLocationIdQuery } from '../gqlconstants/gqlconstants';
import { ValueCheckerService } from '../core/services/valuechecker.service';
import { emptyString, commaSpace } from '../core/constants/constants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { WestfieldLocationsResponse } from '../components/managewestfieldlocation/managewestfieldlocation.component';
import { StringService } from '../core/formatting/string.service';
import { UpdateRetailerCenterWithLocationId } from '../components/managewestfieldlocation/managewestfieldlocation.models';

@Injectable({
  providedIn: "root"
})
export class WestfieldLocationService {

  constructor(private apollo: Apollo, private valueCheckerService: ValueCheckerService, private stringService: StringService) {
  }

  getWestfieldShoppingCenters(centresSuburb: string, centresState: string, skip: number, take: number, closeDate: string) {
    let graphQLQuery = retailerCentersQuery;
    let filters = emptyString;
    let closeDateString = emptyString;
    let orderByForCloseDate = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(centresSuburb)) {
      filters = filters + this.stringService.format(`{ centresSuburb: {eq: "{0}"} }${commaSpace}`, centresSuburb);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(centresState)) {
      filters = filters + this.stringService.format(`{ centresState: {eq: "{0}"} }${commaSpace}`, centresState);
    }

    filters = filters + this.stringService.format('{ centresCountry: {eq: "{0}"} }', "Australia");

    if (!this.valueCheckerService.isEmptyNullOrUndefined(closeDate)) {
      closeDateString = this.stringService.format('closeDate: "{0}",', closeDate);
      orderByForCloseDate = 'order: {sourceModifiedDate: ASC}';
    }
    if (filters === emptyString) {
      graphQLQuery = graphQLQuery.replace(` where: { and: [ {1} ] },`, emptyString);
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<WestfieldLocationsResponse>({
        query: gql`${this.stringService.format(graphQLQuery, closeDateString, filters, skip.toString(), take.toString(), orderByForCloseDate)}`
      })
      .valueChanges;
  }

  updateRetailerCenterWithLocationId(centerId: number, comment: string = emptyString, locationId: number, modifiedBy: number) {
    return this.apollo
      .use(hotChocolate)
      .mutate<UpdateRetailerCenterWithLocationId>({
        mutation: gql`${this.stringService.format(
          updateRetailerCenterWithLocationIdQuery, centerId.toString(), comment, locationId.toString(), modifiedBy.toString())}`
      });
  }

  getRetailerCenterBasedOnCenterId(centresId: number) {
    let filter = this.stringService.format('{ centresId: {eq: {0}} }', centresId.toString());

    return this.apollo
      .use(hotChocolate)
      .watchQuery<WestfieldLocationsResponse>({
        query: gql`${this.stringService.format(retailerCentersBasedOnCenterIdQuery, filter, '0', '50')}`
      })
      .valueChanges;
  }
}
