import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { unmatchedMerchantsResponse } from "../mockjsonresponses/chainstoresresponse";

export function chainStoresBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includeUnmatchedMerchants = false;
  if (request.body && request.body.query) {
    includeUnmatchedMerchants = request.body.query.includes('unmatchedMerchants(chainCode: $chainCode)')
      && request.body.query.includes('closedConfidenceLevel')
      && Object.keys(request.body.variables).includes('chainCode');
  }

  if (cleanUrl.includes(apiPathConstants.eventing) && method === "POST" && includeUnmatchedMerchants) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...unmatchedMerchantsResponse }
      }));
      resp.complete();
    })
  }
}
