import { ExecutionResult } from 'graphql';
import { FetchRetailerResponse } from '../../retaileraudit/retaileraudit';

export let retailerAuditResponse: ExecutionResult<FetchRetailerResponse> = {
  data: {
    items: [
      {
        locatedIn: 833,
        retailersId: 1,
        createDate: "2021-07-28T04:42:33.95Z",
        lwcId: 1000594691,
        chainId: 29,
        storesTitle: "BIG W",
        storesShortDescription: "BIG W at Westfield Warringah Mall",
        storesPhysicalAddress: "Level 1",
        storesShopNumber: "1-2000F1",
        storesLevel: null,
        closedForNoOfDaysPotentially: 0,
        sourceModifiedDate: "2021-08-16T08:01:42.99Z",
        modifiedBy: 0,
        retailerAudits: [
          {
            calculatedNoOfDays: 36,
            createDate: "2021-07-11T06:46:27.85Z",
            fieldName: "StoresTitle",
            fieldOldValue: "BIG W",
            fieldNewValue: "BIG W V1"
          },
          {
            calculatedNoOfDays: 15,
            createDate: "2021-08-01T06:46:27.85Z",
            fieldName: "StoresTitle",
            fieldOldValue: "BIG W v1",
            fieldNewValue: "BIG W V2"
          },
          {
            calculatedNoOfDays: 5,
            createDate: "2021-08-11T06:46:27.85Z",
            fieldName: "StoresTitle",
            fieldOldValue: "BIG W v2",
            fieldNewValue: "BIG W V3"
          }
        ]
      }
    ],
    totalCount: 1
  }
};
