import { Injectable } from "@angular/core";

@Injectable()
export class HighlightService {

  constructor(){}

  highlight(text: string, valueToHighlight: string) {
    if(!valueToHighlight) {
      return text;
    }

    let regExp = new RegExp(valueToHighlight, 'gi');
    let match = text.match(regExp);

    if (!match) {
      return text;
    }

    let value = text.replace(regExp, `<span class="text-highlight">${match[0]}</span>`)
    return value;
  }
}
