import { getIndexMerchantResultResponse } from '../mockjsonresponses/getindexmerchantresultresponse';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function indexMerchantBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if ((cleanUrl.includes('internationalmerchant/internationalcals') ||
    cleanUrl.includes('index/merchantfromcal') ||
    cleanUrl.includes('index/merchantfrombusinessnameid') ||
    cleanUrl.includes('index/chain')
  ) && method === HttpMethod.Post) {

    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: cloneDeep(getIndexMerchantResultResponse)
      }));
      resp.complete();
    });
  }

  if ((cleanUrl.includes('index/internationalcal') ||
        cleanUrl.includes('index/sharedcal')
  ) && method === HttpMethod.Post) {

    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: 'Some result'
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('index/setrecordlockedstatus') && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: null
      }));
      resp.complete();
    });
  }
  if (cleanUrl.includes(apiPathConstants.matchWithIndexedMerchants) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    })
  }
  if (cleanUrl.includes(apiPathConstants.eventingCreateNew) &&  method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    })
  }
}
