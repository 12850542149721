import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[inTableSearchSelect]"
})
export class InTableSearchSelect implements AfterViewInit {

  constructor(private elem: ElementRef) {
  }

  ngAfterViewInit() {
    const element = this.elem.nativeElement;
    const matInput = element.querySelector(".mat-input-element");
    matInput.style.borderBottom = "none";
    matInput.style.height = "18px";
  }

}
