import { ODataResponse } from "../../core/api/odata/odataresponse";
import { MerchantEvent } from "../../components/merchantevents/merchantevent";
import { MerchantEventState } from "../../components/merchantevents/state";

export let merchantEventResponse: ODataResponse<MerchantEvent[]> = {
  "@odata.count": 3,
  value: [
    {
      TradingName: 'TestTradingName',
      LegalName: 'TestLegalName',
      Abn: 'TestABN',
      EventType: 'TestEventType',
      EventSource: 'TestEventSource',
      EventSourceId: 'TestEventSourceID',
      EventDate: '2017-12-17T11:59:00+11:00',
      Modified: '2017-12-17T11:59:00+11:00',
      State: MerchantEventState.Open,
      ModifiedIndexerName: 'Sergio',
      ModifiedBy: 34,
      MerchantEventId: 3
    },
    {
      TradingName: '2TestTradingName',
      LegalName: '2TestLegalName',
      Abn: '2TestABN',
      EventType: '2TestEventType',
      EventSource: '2TestEventSource',
      EventSourceId: '2TestEventSourceID',
      EventDate: '2017-12-17T08:59:00+11:00',
      Modified: '2017-12-17T08:59:00+11:00',
      State: MerchantEventState.Resolved,
      ModifiedIndexerName: 'Alex',
      ModifiedBy: 34,
      MerchantEventId: 5
    },
    {
      TradingName: '3TestTradingName',
      LegalName: '3TestLegalName',
      Abn: '3TestABN',
      EventType: '3TestEventType',
      EventSource: '3TestEventSource',
      EventSourceId: '3TestEventSourceID',
      EventDate: '2017-12-17T10:59:00+11:00',
      Modified: '2017-12-17T10:59:00+11:00',
      State: MerchantEventState.NoIssue,
      ModifiedIndexerName: 'Frank',
      ModifiedBy: 34,
      MerchantEventId: 7
    }
  ]
}
