import { Injectable } from '@angular/core';

@Injectable()
export class StringService {
  constructor() { }

  format(value: string, ...params: string[]) {
    let formatted = value;

    for (let i = 0; i < params.length; i++) {
      let regexp = new RegExp('\\{' + i + '\\}', 'gi');
      formatted = formatted.replace(regexp, params[i]);
    }

    return formatted;
  }

  stripNewLine(value: string) {
    if (typeof value !== 'undefined') {
      let regexp = new RegExp('\r?\n|\r', 'g');
      return value.replace(regexp, '');
    }
    return value;
  }

  sanitizeValue(value: {}) {
    if (typeof value === 'undefined' || value === null) {
      return '';
    } else {
      let valueString = value.toString();

      return valueString;
    }
  }

  isNonEmptyTrimmedStringExistInArray(value: string, array: string[]) {
    return (value || '').trim() && !array.includes(value.trim());
  }

  getListOfSubstringWithoutEmptyLastLine(line: string) {
    let listOfSubstrings = line.split('\n');

    if (listOfSubstrings[listOfSubstrings.length - 1] === '') {
      listOfSubstrings.pop();
    }
    return listOfSubstrings;
  }

  sortAlphabetically(firstValue: string, secondValue: string) {
    let a = firstValue.toUpperCase();
    let b = secondValue.toUpperCase();

    if (a < b) return -1;
    if(a > b) return 1;
  }
}
