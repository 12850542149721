import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { apiPathConstants } from '../core/api/apipathconstants';

@Injectable()
export class ApiInfoService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  getApiVersion() {
    return this.apiClient.get<string>(this.apiService
      .buildUrl('version'));
  }

  getInternalApiVersion() {
    return this.apiClient.get<string>(this.apiService
      .buildUrl(apiPathConstants.internalApiVersion));
  }
}
