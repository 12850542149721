import { PastSearchesByBatchResponse } from "../../components/batchpastsearches/batchpastsearches.response";

export let pastSearchesByBatchResponse: PastSearchesByBatchResponse[] = [
  {
    Cal: "BURLEIGH WATERS MIAMI QLD",
    Count: 1,
    BankAccountTransactionType: 'Unknown',
    SearchCountByDay: [
      {
        Date: '09.06.15',
        Count: 1000607893
      }
    ]
  },
  {
    Cal: "0RANDWICK PS 4250 RANDWICK",
    Count: 2,
    BankAccountTransactionType: 'DirectDebit',
    SearchCountByDay: [
      {
        Date: '10.03.20',
        Count: 80600258
      },
      {
        Date: '16.09.15',
        Count: 20603691
      }
    ]
  },
  {
    Cal: "ADAIRS BALGOWLAH",
    Count: 3,
    BankAccountTransactionType: 'DirectCredit',
    SearchCountByDay: [
      {
        Date: '03.06.18',
        Count: 1080407893
      },
      {
        Date: '10.01.20',
        Count: 80600258
      },
      {
        Date: '10.09.15',
        Count: 603691
      }
    ]
  },
  {
    Cal: "#641 WAIKIKI RETAIL HONOLULU",
    Count: 4,
    BankAccountTransactionType: 'BPay',
    SearchCountByDay: [
      {
        Date: '03.06.18',
        Count: 70607474
      },
      {
        Date: '09.03.20',
        Count: 55600400
      },
      {
        Date: '10.09.10',
        Count: 10603691
      }
    ]
  },
  {
    Cal: "NABINTNL TRN FEE REV -MC",
    Count: 5,
    BankAccountTransactionType: 'Salary',
    SearchCountByDay: [
      {
        Date: '03.06.18',
        Count: 800607893
      },
      {
        Date: '06.02.20',
        Count: 70600258
      },
      {
        Date: '10.09.15',
        Count: 13603691
      },
      {
        Date: '02.09.15',
        Count: 20603691
      }
    ]
  },
  {
    Cal: "ALDI STORES BELMONT",
    Count: 6,
    BankAccountTransactionType: 'Visa',
    SearchCountByDay: [
      {
        Date: '01.02.19',
        Count: 1000607893
      },
      {
        Date: '05.03.20',
        Count: 80600258
      },
      {
        Date: '10.09.15',
        Count: 20603691
      }
    ]
  },
  {
    Cal: "CBA ATM CAMP'TOWN ML 1 NSW 251793 AUS",
    Count: 7,
    BankAccountTransactionType: 'Mastercard',
    SearchCountByDay: [
      {
        Date: '03.06.18',
        Count: 1000607893
      }
    ]
  },
  {
    Cal: "CBA ATM 99 KING ST A VIC 302001 AUS",
    Count: 8,
    BankAccountTransactionType: 'InterestPaid',
    SearchCountByDay: [
      {
        Date: '15.06.18',
        Count: 1000607893
      },
      {
        Date: '07.03.20',
        Count: 80600258
      },
      {
        Date: '05.02.15',
        Count: 20603691
      }
    ]
  }
];
