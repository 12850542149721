import { Component, OnInit, Injector } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { QuarantinedMerchantsState } from './quarantinedmerchantsstate';
import { QuarantinedService } from '../../services/quarantined.service';
import { Router } from '@angular/router';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-quarantinedmerchants',
  templateUrl: './quarantinedmerchants.component.html',
  styleUrls: ['./quarantinedmerchants.component.scss']
})
export class QuarantinedMerchantsComponent extends BaseStateComponent<QuarantinedMerchantsState> implements OnInit {
  constructor(injector: Injector, private quarantinedService: QuarantinedService, private router: Router,
    private csvComponentService: CsvComponentService, private dateTimeService: DateTimeService,
    private loadingService: LoadingService) {
    super(injector)
   }

  ngOnInit() {
    this.state = {
      quarantinedMerchants: []
    };

    this.restoreState();

    if (this.state.quarantinedMerchants.length === 0) {
      this.loadQuarantinedMerchants();
    }
  }

  loadQuarantinedMerchants() {
    this.loadingService.setLoading();

    this.quarantinedService.getQuarantinedMerchants()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((res) => this.state.quarantinedMerchants = res);
  }

  goToEnrichMerchant(lwcId: number) {
    this.router.navigate(['/enrichmerchant', { lwcId: lwcId, cleanState: true}]);
  }

  getCsvOptions() {
    let options = this.csvComponentService.getCsvOptions(this.state.quarantinedMerchants[0], 'QuarantinedMerchants.csv');

    return options;
  }

  formatDate(date: string) {
    let formatted = this.dateTimeService.formatWithHours(date);

    return formatted;
  }

  isStreetViewAvailable(val: boolean) {
    if (val) {
      return 'Yes'
    } else {
      return 'No'
    }
  }

  setLocatedIn(name: string, id: number) {
    if (name && id) {
      return `${id} - ${name}`;
    }

    if (name) {
      return name;
    }

    if (id) {
      return id.toString();
    }
  }
}
