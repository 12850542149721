import { BatchSplitCalResponse } from "../../components/batchcalsplitter/batchcalsplittermodels";
import { Amount, Suburb, SuburbMatch, LocatorType, PaymentMethod, AdditionalDebitInformation, CashOut } from "../../models/calparserresult";

let amounts: Amount[] = [
  {
    Value: 462,
    Currency: 'EUR'
  },
  {
    Value: 692,
    Currency: 'USD'
  }
];

let amounts1: Amount[] = [
  {
    Value: 100,
    Currency: 'INR'
  },
  {
    Value: 85000,
    Currency: 'AUD'
  }
];

let amounts2: Amount[] = [
  {
    Value: 300,
    Currency: 'EUR'
  },
  {
    Value: 457,
    Currency: 'USD'
  }
];

let suburb: Suburb = {
  Name: 'test suburb',
  State: 'test state',
  Postcode: 'test post code',
  Country: 'test country',
  Lat: 33,
  Lon: 26,
  Area_Sq_KM: 6546456
};

let suburbMatch: SuburbMatch = {
  Suburb: suburb,
  Confidence: 5464543,
  OtherPossibleSuburbs: [
    'test OtherPossibleSuburbs1',
    'test OtherPossibleSuburbs2'
  ],
  StateCandidates: [
    'test state candidates1',
    'test state candidates2'
  ]
};

let additionalDebitInformation: AdditionalDebitInformation = {
  OriginalDebitCAL: 'test OriginalDebitCAL',
  TypeOfDebitCard: 'test TypeOfDebitCard',
  TransactionDate: new Date(),
  MerchantReferenceNumber: 'test MerchantReferenceNumber',
  Last4DigitsOfCard: 'test Last4DigitsOfCard',
  IsWordSplit: true
};

let cashOut: CashOut = {
  CashOutAmount: 865346564,
  CashOutCurrencyCode: 'test CashOutCurrencyCode'
};

export let batchSplitCalResponse: BatchSplitCalResponse = {
  BtpLiteTransactions: [
    {
      Branch: 'Dev',
      BankKeywords: 'test bnk keuwords1',
      TransactionDescription: 'ORIGIN ENERGY HOLDIN ADELAIDE',
      DespacedTransactionDescription: 'test despaced transaction description1',
      StandardisedTransactionDescription: 'test standardised transaction description1',
      CardAcceptor: 'test card acceptor1',
      CardAcceptorPredictor: 'test card acceptor predicator1',
      FormattedCardAcceptor: 'test formatted card acceptor1',
      Country: 'test country',
      TransactionAmounts: amounts,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: true,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference',
      PaymentGateway: 'test PaymentGateway',
      IsRestaurant: true,
      IsPharmacy: false,
      IsCompany: true,
      IsHotel: true,
      IsCarPark: true,
      WhatIsTheLocator: LocatorType.WebAddress,
      ExecutionTime: 4121212,
      CandidateSuburbs: ['test CandidateSuburbs1', 'test CandidateSuburbs1'],
      CandidateStates: ['test CandidateSuburbs1', 'test CandidateSuburbs1'],
      CandidateLatLon: [[1, 2], [1, 2]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.BPay,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 1545,
        BPayCustomerReferenceNumber: 15
      },
	    TransactionType: 1
    },
    {
      Branch: 'Origin',
      BankKeywords: 'test bnk keuwords2',
      TransactionDescription: 'MLC LIMITED MELBOURNE',
      DespacedTransactionDescription: 'test despaced transaction description2',
      StandardisedTransactionDescription: 'test standardised transaction description2',
      CardAcceptor: 'test card acceptor2',
      CardAcceptorPredictor: 'test card acceptor predicator2',
      FormattedCardAcceptor: 'test formatted card acceptor2',
      Country: 'test country',
      TransactionAmounts: amounts1,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: false,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference',
      PaymentGateway: 'test PaymentGateway',
      IsRestaurant: false,
      IsPharmacy: false,
      IsCompany: false,
      IsHotel: false,
      IsCarPark: false,
      WhatIsTheLocator: LocatorType.State,
      ExecutionTime: 65251,
      CandidateSuburbs: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateStates: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateLatLon: [[65, 40], [15, 20]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.AUSTBankVisa,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 1500,
        BPayCustomerReferenceNumber: 8
      },
	    TransactionType: 1
    },
    {
      Branch: 'Origin1',
      BankKeywords: 'test bnk keuwords2',
      TransactionDescription: 'MLC LIMITED MELBOURNE',
      DespacedTransactionDescription: 'test despaced transaction description2',
      StandardisedTransactionDescription: 'test standardised transaction description2',
      CardAcceptor: 'test card acceptor2',
      CardAcceptorPredictor: 'test card acceptor predicator2',
      FormattedCardAcceptor: 'test formatted card acceptor2',
      Country: 'test country',
      TransactionAmounts: amounts1,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: false,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference',
      PaymentGateway: 'test PaymentGateway',
      IsRestaurant: false,
      IsPharmacy: false,
      IsCompany: false,
      IsHotel: false,
      IsCarPark: false,
      WhatIsTheLocator: LocatorType.State,
      ExecutionTime: 65251,
      CandidateSuburbs: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateStates: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateLatLon: [[65, 40], [15, 20]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.AUSTBankVisa,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 1500,
        BPayCustomerReferenceNumber: 8
      },
	    TransactionType: 10
    },
    {
      Branch: 'Origin2',
      BankKeywords: 'test bnk keuwords2',
      TransactionDescription: 'MLC LIMITED MELBOURNE',
      DespacedTransactionDescription: 'test despaced transaction description2',
      StandardisedTransactionDescription: 'test standardised transaction description2',
      CardAcceptor: 'test card acceptor2',
      CardAcceptorPredictor: 'test card acceptor predicator2',
      FormattedCardAcceptor: 'test formatted card acceptor2',
      Country: 'test country',
      TransactionAmounts: amounts1,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: false,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference',
      PaymentGateway: 'test PaymentGateway',
      IsRestaurant: false,
      IsPharmacy: false,
      IsCompany: false,
      IsHotel: false,
      IsCarPark: false,
      WhatIsTheLocator: LocatorType.State,
      ExecutionTime: 65251,
      CandidateSuburbs: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateStates: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateLatLon: [[65, 40], [15, 20]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.AUSTBankVisa,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 1500,
        BPayCustomerReferenceNumber: 8
      },
      TransactionType: 10
    },
    {
      Branch: 'Origin3',
      BankKeywords: 'test bnk keuwords2',
      TransactionDescription: 'MLC LIMITED MELBOURNE',
      DespacedTransactionDescription: 'test despaced transaction description2',
      StandardisedTransactionDescription: 'test standardised transaction description2',
      CardAcceptor: 'test card acceptor2',
      CardAcceptorPredictor: 'test card acceptor predicator2',
      FormattedCardAcceptor: 'test formatted card acceptor2',
      Country: 'test country',
      TransactionAmounts: amounts1,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: false,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference',
      PaymentGateway: 'test PaymentGateway',
      IsRestaurant: false,
      IsPharmacy: false,
      IsCompany: false,
      IsHotel: false,
      IsCarPark: false,
      WhatIsTheLocator: LocatorType.State,
      ExecutionTime: 65251,
      CandidateSuburbs: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateStates: ['test CandidateSuburbs2', 'test CandidateSuburbs2'],
      CandidateLatLon: [[65, 40], [15, 20]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.AUSTBankVisa,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 1500,
        BPayCustomerReferenceNumber: 8
      },
      TransactionType: 10
    }
  ],
  DetailedTransactions: [
    {
      Branch: 'Tests',
      BankKeywords: 'test bnk keuwords3',
      TransactionDescription: 'NABINTNL TRN FEE REV -MC',
      DespacedTransactionDescription: 'test despaced transaction description3',
      StandardisedTransactionDescription: 'test standardised transaction description3',
      CardAcceptor: 'test card acceptor3',
      CardAcceptorPredictor: 'test card acceptor predicator3',
      FormattedCardAcceptor: 'test formatted card acceptor3',
      Country: 'test country',
      TransactionAmounts: amounts2,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: true,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference',
      PaymentGateway: 'test PaymentGateway',
      IsRestaurant: true,
      IsPharmacy: false,
      IsCompany: false,
      IsHotel: false,
      IsCarPark: true,
      WhatIsTheLocator: LocatorType.None,
      ExecutionTime: 156,
      CandidateSuburbs: ['test CandidateSuburbs3', 'test CandidateSuburbs3'],
      CandidateStates: ['test CandidateSuburbs3', 'test CandidateSuburbs3'],
      CandidateLatLon: [[60, 12], [10, 100]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.NABVisaDebit,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 4598,
        BPayCustomerReferenceNumber: 32
      },
      TransactionType: 10
    },
    {
      Branch: 'dev2',
      BankKeywords: 'test bnk keuwords4',
      TransactionDescription: 'TRVLMONEPERTH',
      DespacedTransactionDescription: 'test despaced transaction description4',
      StandardisedTransactionDescription: 'test standardised transaction description4',
      CardAcceptor: 'test card acceptor4',
      CardAcceptorPredictor: 'test card acceptor predicator4',
      FormattedCardAcceptor: 'test formatted card acceptor4',
      Country: 'test country',
      TransactionAmounts: null,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: false,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference',
      PaymentGateway: 'test PaymentGateway',
      IsRestaurant: false,
      IsPharmacy: true,
      IsCompany: false,
      IsHotel: false,
      IsCarPark: false,
      WhatIsTheLocator: LocatorType.State,
      ExecutionTime: 1500,
      CandidateSuburbs: ['test CandidateSuburbs4', 'test CandidateSuburbs4'],
      CandidateStates: ['test CandidateSuburbs4', 'test CandidateSuburbs4'],
      CandidateLatLon: [[65, 40], [15, 20]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.BPay,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 985,
        BPayCustomerReferenceNumber: 800
      },
	    TransactionType: 10
    },
    {
      Branch: 'dev3',
      BankKeywords: 'test bnk keuwords5',
      TransactionDescription: 'TRVLMONEPERTH',
      DespacedTransactionDescription: 'test despaced transaction description4',
      StandardisedTransactionDescription: 'test standardised transaction description4',
      CardAcceptor: 'test card acceptor5',
      CardAcceptorPredictor: 'test card acceptor predicator5',
      FormattedCardAcceptor: 'test formatted card acceptor5',
      Country: 'test country',
      TransactionAmounts: null,
      OtherKeywords: 'test other keywords',
      PhoneNumber: '8765435677899',
      IsPtyLtd: true,
      Suburb: 'test suburb',
      SuburbExt: suburb,
      SuburbMatch: suburbMatch,
      State: 'test state',
      StateConfidence: suburbMatch.Confidence,
      WebAddress: 'test web address',
      TransactionReference: 'test TransactionReference5',
      PaymentGateway: 'test PaymentGateway5',
      IsRestaurant: true,
      IsPharmacy: true,
      IsCompany: false,
      IsHotel: false,
      IsCarPark: false,
      WhatIsTheLocator: LocatorType.State,
      ExecutionTime: 892,
      CandidateSuburbs: ['test CandidateSuburbs5', 'test CandidateSuburbs5'],
      CandidateStates: ['test CandidateSuburbs5', 'test CandidateSuburbs5'],
      CandidateLatLon: [[65, 40], [15, 20]],
      DebitCardAdditionalInformation: additionalDebitInformation,
      CALPaymentMethod: PaymentMethod.BPay,
      CashOutInformation: cashOut,
      BPayAdditionalInformation: {
        BPayBillerCode: 12,
        BPayCustomerReferenceNumber: 78
      },
	    TransactionType: 10
    }
  ]
}
