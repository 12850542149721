import { Component, Input, ViewChild, TemplateRef, ViewContainerRef, Injector } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { OverlayService } from './overlay.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progressspinner.component.html',
  styleUrls: ['./progressspinner.component.scss']
})
export class ProgressSpinnerComponent extends BaseComponent {
  @Input() color?: ThemePalette;
  @Input() diameter?: number = 100;
  @Input() mode?: ProgressSpinnerMode = 'indeterminate';
  @Input() strokeWidth?: number;
  @Input() value?: number;
  @Input() backdropEnabled = true;
  @Input() positionGloballyCenter = true;

  @ViewChild('progressSpinnerRef')
  private progressSpinnerRef: TemplateRef<any>;
  private progressSpinnerOverlayConfig: OverlayConfig;
  private overlayRef: OverlayRef;
  private isLoading = false;

  constructor(injector: Injector, private vcRef: ViewContainerRef, private overlayService: OverlayService,
    private loadingService: LoadingService) {
    super(injector);
   }

  ngOnInit() {
    this.loadingService.loadingState$.pipe(takeUntil(this.destroy$)).subscribe(isloading => this.isLoading = isloading);

    this.progressSpinnerOverlayConfig = {
      hasBackdrop: this.backdropEnabled,
      backdropClass: 'spinner-overlay'
    };

    if (this.positionGloballyCenter) {
      this.progressSpinnerOverlayConfig.positionStrategy = this.overlayService.positionGloballyCenter();
    }

    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
  }

  ngDoCheck() {
    if (this.isLoading && !this.overlayRef.hasAttached()) {
      this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
    } else if (!this.isLoading && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
