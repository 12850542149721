import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { finalize, map, Observable, startWith } from 'rxjs';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { ApiClientPolicy, ClientApikey, clientNames, Item, UsagePlanItem } from '../../models/manageapikeys.models';
import { ManageapikeysService } from '../../services/manageapikeys.service';
import { ManageapiDialogComponent } from './manageapi-dialog/manageapi-dialog.component';
import { ClipboardService } from 'ngx-clipboard';
import { ApplicationUser } from '../../users/applicationuser';
import { LoginStateService } from '../../core/auth/loginstate.service';
import { RoleType } from '../../users/userenums';
import { MatRadioChange } from '@angular/material/radio';
import { NotificationService } from '../../core/uiservices/notification.service'; import { Router } from '@angular/router';
import { PageInfo } from '../../shared/models/pageinfo';

@Component({
  selector: 'app-manageapikeys',
  templateUrl: './manageapikeys.component.html',
  styleUrls: ['./manageapikeys.component.scss'],

})

export class ManageapikeysComponent implements OnInit {

  @ViewChild('myTable') table: DatatableComponent;
  selectedClientValue: any;
  clients: Item[];
  formControl = new FormControl();
  filteredClientName: Observable<clientNames[]>;
  orderBy: string;
  orderDirection: string;
  totalCount: number;
  pageIndex: number;
  take: number;
  skip: number;
  clientNameTake: number;
  isActive: boolean;
  columnMode = ColumnMode;
  usagePlan: UsagePlanItem[];
  policy: ApiClientPolicy[];
  clientsNames: clientNames[];
  applicationUsers: ApplicationUser[];
  isAdmin: boolean;
  syncInput: string;
  filterByApiName: string;
  usagePlanRadio: boolean;


  dialogConfig = new MatDialogConfig();
  modalDialog: MatDialogRef<ManageapiDialogComponent, any> | undefined;
  apikey: boolean;
  checked: boolean;
  isDisabled: boolean;

  constructor(private loadingService: LoadingService, private manageApikeysService: ManageapikeysService, public dialog: MatDialog,
    private dateTimeService: DateTimeService, private clipboardService: ClipboardService, private loginStateService: LoginStateService,
    private notificationService: NotificationService, private router: Router) {
    this.selectedClientValue = '';
    this.orderBy = 'clientName';
    this.orderDirection = 'asc';
    this.filterByApiName = '';
    this.isActive = false;
    this.clients = [];
    this.clientsNames = [];
    this.totalCount = 0;
    this.pageIndex = 0;
    this.take = 25;
    this.clientNameTake = 5000;
    this.skip = 0;
    this.isAdmin = false;
    this.apikey = true;
    this.syncInput = '';
    this.isDisabled = false;
    this.usagePlanRadio = false;

    this.getApplicationUsers();
    this.getUsagePlan();
    this.getClientNames();
  }

  ngOnInit(): void {
    if (!this.selectedClientValue) {
      this.getClients();
    }
    this.filteredClientName = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  getClients() {
    this.loadingService.setLoading();
    this.manageApikeysService.getClientData(this.isAdmin, this.selectedClientValue.trim(), this.filterByApiName.trim(), this.isActive, this.skip, this.take, this.orderBy, this.orderDirection).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.clients = response.data.items;
        this.totalCount = response.data.totalCount;
        for (let data of this.clients) {
          data.createdDate = this.dateTimeService.format(data.createdDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
          data.modifiedDate = this.dateTimeService.format(data.modifiedDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
          for (let i of data.clientAPIKeys) {
            i.expiryDate = this.dateTimeService.format(i.expiryDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
            i.usagePlanEnv = i.usagePlanEnv.toString().split(',');
            i.Active = i.isActive;
            if (i.isActive == true) {
              this.checked = true;
            } else {
              this.checked = false;
            }
          }
        }
      }
    });
  }

  getClientDetails() {
    this.getClients();
  }

  isEditingRow = (row: ClientApikey) => {
    return {
      'row-color': row.isDeleted == true
    }
  }

  private _filter(value: string): clientNames[] {
    const filterValue = value.toLowerCase();
    return this.clientsNames.filter(option => option.clientName.toLowerCase().includes(filterValue));
  }

  changeClientName(value: string) {
    if (value) {
      this.selectedClientValue = value;
    }
  }

  checkCheckBoxvalue(event: MatCheckboxChange) {
    if (event.checked === true) {
      this.isActive = true;
      this.getClients();
    }
    else {
      this.isActive = false;
      this.getClients();
    }
  }
  numberOfPageChanged(pageInfo: PageInfo) {
    this.pageIndex = pageInfo.offset;
    this.skip = pageInfo.offset * this.take;
    this.getClients();
  }

  onSort(event: any) {
    this.orderBy = event.sorts[0].prop;
    this.orderDirection = event.sorts[0].dir;
    this.getClients();
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  radioChange(event: MatRadioChange) {
    if (event.value == 'apiKey') {
      this.apikey = true;
      this.syncInput = '';
    }
    else {
      this.apikey = false;
      this.syncInput = '';
    }
  }

  radioChangeForGateway(event: MatRadioChange) {
    if (event.value == 'db') {
      this.usagePlanRadio = true;
    } else {
      this.usagePlanRadio = false;
    }
  }

  getApplicationUsers() {
    let isAdministrator = this.loginStateService.isInAnyRole([RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead]);
    if (isAdministrator == false) {
      this.isAdmin = false;
    } else {
      this.isAdmin = true;
    }
  }

  getClientNames() {
    this.loadingService.setLoading();
    this.manageApikeysService.getClientName(this.skip, this.clientNameTake).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.clientsNames = response.data.items;
      }
    });
  }

  getUsagePlan() {
    this.loadingService.setLoading();
    this.manageApikeysService.getUsagePlan().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.usagePlan = response.data.items;
      }
    })
  }

  copyContent(value: ClientApikey, e: Event) {
    if (value.isDeleted == true) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      if (value) {
        this.clipboardService.copyFromContent(value.apikey);
      }
    }
  }

  clone(value: ClientApikey, e: Event) {
    if (value.isDeleted == true) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let id = value.id;
      let createdBy = value.createdBy;
      this.manageApikeysService.cloneClientApiKey(id, createdBy).pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          this.loadingService.clearLoading();
          if (response) {
            let value = "Api Key Cloned :" + response.cloneClientApiKey;
            this.notificationService.notifySuccess(value);
            this.getClients();
            setTimeout(() => {
              this.getClonedData(response);
            }, 2000);
          }
        }, (error) => {
          let result = this.getErrorMessage(error.networkError.error);
          this.notificationService.notifyError(result);
        });
    }
  }

  test(value: ClientApikey, env: string, e: Event) {
    if (value.isDeleted == true) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let id = value.id;
      this.manageApikeysService.testapikey(id, env.toLocaleLowerCase()).pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          this.loadingService.clearLoading();
          if (response) {
            let value = "test :" + response.testApiKey;
            this.notificationService.notifySuccess(value);
          }
        }, (error) => {
          let result = this.getErrorMessage(error.networkError.error);
          this.notificationService.notifyError(result);
        });
    }
  }

  sync() {
    if (this.apikey == true) {
      let value = this.syncInput.trim();
      if (this.usagePlanRadio == false) {
        this.manageApikeysService.syncClientApiKey(value).pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(response => {
            this.loadingService.clearLoading();
            if (response) {
              this.notificationService.notifySyncResponse(response);
            }
          }, (error) => {
            let result = this.getErrorMessage(error.networkError.error);
            this.notificationService.notifyError(result);
          });
      }
      else {
        this.manageApikeysService.syncClientApiKeyDb(value).pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(response => {
            this.loadingService.clearLoading();
            if (response) {
              this.notificationService.notifySyncDbResponse(response);
            }
          }, (error) => {
            let result = this.getErrorMessage(error.networkError.error);
            this.notificationService.notifyError(result);
          });
      }
    }
    if (this.apikey == false) {
      let value = this.syncInput.trim();
      this.manageApikeysService.syncUsage(value).pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          this.loadingService.clearLoading();
          if (response) {
            this.notificationService.notifySuccess(response.syncUsagePlan);
          }
        }, (error) => {
          let result = this.getErrorMessage(error.networkError.error);
          this.notificationService.notifyError(result);
        })
    }
  }

  syncAll() {
    if (this.apikey == true) {
      let value = 'syncClientApiKey';
      this.manageApikeysService.syncAll(value).pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          this.loadingService.clearLoading();
          if (response) {
            this.notificationService.notifySuccess(response.syncClientApiKey);
          }
        }, (error) => {
          let result = this.getErrorMessage(error.networkError.error);
          this.notificationService.notifyError(result);
        })
    }
    if (this.apikey == false) {
      let value = 'syncUsagePlan';
      this.manageApikeysService.syncAll(value).pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(response => {
          this.loadingService.clearLoading();
          if (response) {
            this.notificationService.notifySuccess(response.syncUsagePlan);
          }
        }, (error) => {
          let result = this.getErrorMessage(error.networkError.error);
          this.notificationService.notifyError(result);
        })
    }
  }

  usage(value: ClientApikey, e: Event) {
    if (value.isDeleted == true) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let apikeyname = value.apikeyName.toLowerCase();
      this.router.navigate(['/manageapiusage'], { queryParams: { data: apikeyname } });
    }
  }

  refresh() {
    this.manageApikeysService.getStatus().pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response) {
          this.notificationService.notifySuccess(response);
        }
      }, (error) => {
        let result = this.getErrorMessage(error.networkError.error);
        this.notificationService.notifyError(result);
      });
  }

  getErrorMessage(error: any = null) {
    let errorMessage = error.error;
    let result = errorMessage.split(',')[1].trim();
    return result
  }
  openDialog(value: Item, type: string) {
    if (type == 'insert') {
      this.dialogConfig.data = {
        dataType: 'insert',
        data: value,
        clientNames: this.clientsNames,
        isAdmin: this.isAdmin
      }
    }
    else if (type == 'edit') {
      let filteredArray = this.clients
        .filter(client => client.clientAPIKeys
          .some(clientAPIKey => clientAPIKey.id === value.id)
        )
        .map(client => {
          let n = Object.assign({}, client, {
            'clientAPIKeys': client.clientAPIKeys.filter(
              clientAPIKey => clientAPIKey.id === value.id
            )
          })
          return n;
        })
      this.dialogConfig.data = {
        dataType: 'edit',
        data: filteredArray,
        clientNames: this.clientsNames,
        usagePlan: this.usagePlan,
        isAdmin: this.isAdmin
      }
    }
    this.dialogConfig.id = "manage-client-dialog-component";
    this.dialogConfig.height = "700px";
    this.dialogConfig.width = "1080px";
    this.dialog.open(ManageapiDialogComponent, this.dialogConfig).afterClosed().subscribe(x => {
      if (!this.selectedClientValue) {
        this.getClients();
      } else {
        this.getClients();
      }
    })
  }

  getClonedData(data: any) {
    let clonedData = this.clients
      .filter(client => client.clientAPIKeys
        .some(clientAPIKey => clientAPIKey.apikeyName == data.cloneClientApiKey)
      )
      .map(client => {
        let n = Object.assign({}, client, {
          'clientAPIKeys': client.clientAPIKeys.filter(
            clientAPIKey => clientAPIKey.apikeyName === data.cloneClientApiKey
          )
        })
        return n;
      })
    this.dialogConfig.data = {
      dataType: 'edit',
      data: clonedData,
      clientNames: this.clientsNames,
      usagePlan: this.usagePlan,
      isAdmin: this.isAdmin
    }
    this.dialogConfig.id = "manage-client-dialog-component";
    this.dialogConfig.height = "700px";
    this.dialogConfig.width = "1080px";
    this.dialog.open(ManageapiDialogComponent, this.dialogConfig).afterClosed().subscribe(x => {
      if (!this.selectedClientValue) {
        this.getClients();
      } else {
        this.getClients();
      }
    })

  };
}
