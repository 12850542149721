import { Injectable } from '@angular/core';
import { BaseFormatService } from './baseformat.service';
import { DateTimeService } from '../uiservices/datetime.service';

@Injectable()
export class FormatService {

  constructor(private baseFormatService: BaseFormatService, private dateTimeService: DateTimeService) { }

  formatDateWithHours<T>(array: T[], propName: string): T[] {
    return this.baseFormatService.formatPropertyInArray(array, propName,
      (input: string) => this.dateTimeService.formatWithHours(input));
  }

  formatTrueOrFalseToYesOrNo(value: boolean) {
    return value ? 'Yes' : 'No';
  }
}
