import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { AddressVerificationResponse } from "../components/addresscheck/addresscheck.component";
import { apiPathConstants } from "../core/api/apipathconstants";
import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";
import { addressVerificationQuery, eventing } from "../gqlconstants/gqlconstants";

@Injectable({
  providedIn: "root"
})

export class AddressVerificationService {
  private addressVerificationQuery = addressVerificationQuery;

  constructor(private apollo: Apollo, private apiService: ApiService, private apiClient: ApiClient) {
  }

  getAddressVerifications() {
    return this.apollo
      .use(eventing)
      .watchQuery<AddressVerificationResponse>({
        query: this.addressVerificationQuery,
      })
      .valueChanges;
  }

  startAddressCheck() {
    return this.apiClient.get<void>(this.apiService.buildApiUrlWithParameters(apiPathConstants.eventingVerify, { name: 'property', value: 'address' }));
  }
}
