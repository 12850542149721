import { commaSpace } from './../core/constants/constants';
import { Injectable } from '@angular/core';
import { ValueCheckerService } from '../core/services/valuechecker.service';
import { CALsSearcherCSVSchema, calsSearcherCsvSchemaProps } from '../components/batchmerchantsearch/calssearchercsvschema';
import { ContactTypes } from '../components/batchmerchantsearch/contacttypes';
import { SearchResultsCodes } from '../enums/enums';
import { APISearchResultV4, APISearchResultsV4 } from '../models/searchmodelsv4';

@Injectable()
export class MerchantSearchExporterService {
    calsSearcherCsvSchemaFields = [calsSearcherCsvSchemaProps.ABN, calsSearcherCsvSchemaProps.Address, calsSearcherCsvSchemaProps.CAL,
    calsSearcherCsvSchemaProps.Category, calsSearcherCsvSchemaProps.ChainName, calsSearcherCsvSchemaProps.Email,
    calsSearcherCsvSchemaProps.HasLogo, calsSearcherCsvSchemaProps.IsClosed, calsSearcherCsvSchemaProps.LastVerified,
    calsSearcherCsvSchemaProps.LocatedIn, calsSearcherCsvSchemaProps.LWC_Guid, calsSearcherCsvSchemaProps.LWC_ID,
    calsSearcherCsvSchemaProps.MerchantRichnessScore, calsSearcherCsvSchemaProps.MID, calsSearcherCsvSchemaProps.NumberOfResults,
    calsSearcherCsvSchemaProps.OpeningTimes, calsSearcherCsvSchemaProps.PhoneNumber, calsSearcherCsvSchemaProps.Result_Code,
    calsSearcherCsvSchemaProps.Suburb, calsSearcherCsvSchemaProps.TID, calsSearcherCsvSchemaProps.TopScore, calsSearcherCsvSchemaProps.TradingName,
    calsSearcherCsvSchemaProps.TypeOfMatch, calsSearcherCsvSchemaProps.URL, calsSearcherCsvSchemaProps.tagLabels];

    constructor(private valueCheckerService: ValueCheckerService) {
    }

  getExportObjects(searchResults: APISearchResultsV4, chosenFields: string[]) {
    let calsSearcherCsvSchemas: Partial<CALsSearcherCSVSchema>[] = [];

    for (let searchResult of searchResults.search_results) {
      let calsSearcherCsvSchema = this.getExportObjectsFromSingleSearchResultV4(searchResult, chosenFields);

      calsSearcherCsvSchemas.push(calsSearcherCsvSchema);
    }
    return calsSearcherCsvSchemas;
  }

  getExportObjectsFromSingleSearchResultV4(searchResult: APISearchResultV4, chosenFields: string[]) {

    let calsSearcherCsvSchema: Partial<CALsSearcherCSVSchema> = {};

    if (chosenFields.includes(calsSearcherCsvSchemaProps.CAL)) {
      calsSearcherCsvSchema.CAL = searchResult.cal;
    }

    if (chosenFields.includes(calsSearcherCsvSchemaProps.Result_Code)) {
      calsSearcherCsvSchema.Result_Code = searchResult.result_code;
    }

    if (chosenFields.includes(calsSearcherCsvSchemaProps.MID)) {
      calsSearcherCsvSchema.MID = searchResult.mid;
    }

    if (chosenFields.includes(calsSearcherCsvSchemaProps.TID)) {
      calsSearcherCsvSchema.TID = searchResult.tid;
    }

    if (chosenFields.includes(calsSearcherCsvSchemaProps.NumberOfResults)) {
      calsSearcherCsvSchema.NumberOfResults = searchResult.number_of_results;
    }

    if (chosenFields.includes(calsSearcherCsvSchemaProps.TopScore)) {
      calsSearcherCsvSchema.TopScore = searchResult.highest_score;
    }

    if (chosenFields.includes(calsSearcherCsvSchemaProps.tagLabels)) {
      if (!this.valueCheckerService.isEmptyNullOrUndefined(searchResult.merchant_search_results)
        && !this.valueCheckerService.isEmptyNullOrUndefined(searchResult.merchant_search_results[0].merchant_details)
        && !this.valueCheckerService.isEmptyNullOrUndefined(searchResult.merchant_search_results[0].merchant_details.merchant_tags)) {
        calsSearcherCsvSchema.tagLabels = searchResult.merchant_search_results[0].merchant_details.merchant_tags.map(t => t.label).join(commaSpace);
      }
      else {
        calsSearcherCsvSchema.tagLabels = null;
      }
    }

    if (searchResult.result_code == SearchResultsCodes.HighConfidenceMatch) {

      calsSearcherCsvSchema.resultCode = searchResult.result_code;
      let ausSearchResult = searchResult.merchant_search_results[0];

      if (this.valueCheckerService.isEmptyNullOrUndefined(ausSearchResult.merchant_details)) {
        calsSearcherCsvSchema.ABN = null;
        calsSearcherCsvSchema.Address = null;
        calsSearcherCsvSchema.Category = null;
        calsSearcherCsvSchema.ChainName = null;
        calsSearcherCsvSchema.Email = null;
        calsSearcherCsvSchema.HasLogo = null;
        calsSearcherCsvSchema.IsClosed = null;
        calsSearcherCsvSchema.LWC_Guid = null;
        calsSearcherCsvSchema.LastVerified = null;
        calsSearcherCsvSchema.LocatedIn = null;
        calsSearcherCsvSchema.MerchantRichnessScore = null;
        calsSearcherCsvSchema.OpeningTimes = null;
        calsSearcherCsvSchema.PhoneNumber = null;
        calsSearcherCsvSchema.Suburb = null;
        calsSearcherCsvSchema.TradingName = null;
        calsSearcherCsvSchema.URL = null;
        calsSearcherCsvSchema.merchantDetailsExist = false;
      }
      else {
        if (chosenFields.includes(calsSearcherCsvSchemaProps.ChainName)) {
          calsSearcherCsvSchema.ChainName = ausSearchResult.merchant_details.legal_business_info.chain_name;
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.IsClosed)) {
          calsSearcherCsvSchema.IsClosed = ausSearchResult.merchant_details.is_permanently_closed;
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.LocatedIn)) {
          if (ausSearchResult.merchant_details.primary_address.address_located_in) {
            calsSearcherCsvSchema.LocatedIn = ausSearchResult.merchant_details.primary_address.address_located_in.located_in_name;
          } else {
            calsSearcherCsvSchema.LocatedIn = null;
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.LWC_Guid)) {
          calsSearcherCsvSchema.LWC_Guid = ausSearchResult.merchant_details.lwc_guid;
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.MerchantRichnessScore)) {
          calsSearcherCsvSchema.MerchantRichnessScore = ausSearchResult.merchant_details.data_enrichment_score;
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.OpeningTimes)) {
          calsSearcherCsvSchema.OpeningTimes = JSON.stringify(ausSearchResult.merchant_details.opening_hours);
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.TradingName)) {
          calsSearcherCsvSchema.TradingName = ausSearchResult.merchant_details.merchant_primary_name;
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.ABN)) {
          let abn = ausSearchResult.merchant_details.legal_business_info.legal_registrations.find(r => r.legal_number_label === "ABN");
          if (abn) {
            calsSearcherCsvSchema.ABN = abn.legal_number;
          } else {
            let acn = ausSearchResult.merchant_details.legal_business_info.legal_registrations.find(r => r.legal_number_label === "ACN");
            if (acn) {
              calsSearcherCsvSchema.ABN = acn.legal_number;
            }
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.Address)) {
          if (ausSearchResult.merchant_details.primary_address) {
            calsSearcherCsvSchema.Address = ausSearchResult.merchant_details.primary_address.single_line_address;
          } else {
            calsSearcherCsvSchema.Address = null;
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.Category)) {
          if (ausSearchResult.merchant_details.primary_category) {
            calsSearcherCsvSchema.Category = ausSearchResult.merchant_details.primary_category.full_category_path;
          } else {
            calsSearcherCsvSchema.Category = null;
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.Email)) {
          if (ausSearchResult.merchant_details.primary_contacts) {
            let email = ausSearchResult.merchant_details.primary_contacts.find(c => c.type_of_contact == ContactTypes.Email);
            if (email) {
              calsSearcherCsvSchema.Email = email.value;
            } else {
              calsSearcherCsvSchema.Email = null;
            }
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.HasLogo)) {
          if (!this.valueCheckerService.isNullOrUndefined(ausSearchResult.merchant_details.merchant_logo)) {
            calsSearcherCsvSchema.HasLogo = true;
          } else {
            calsSearcherCsvSchema.HasLogo = false;
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.LastVerified)) {
          if (ausSearchResult.merchant_details.verification) {
            calsSearcherCsvSchema.LastVerified = ausSearchResult.merchant_details.verification.last_verified_on;
          } else {
            calsSearcherCsvSchema.LastVerified = null;
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.PhoneNumber)) {
          if (ausSearchResult.merchant_details.primary_contacts) {
            let phone = ausSearchResult.merchant_details.primary_contacts.find
              (c => c.type_of_contact == ContactTypes.Phone || c.type_of_contact == ContactTypes.Mobile);
            if (phone) {
              calsSearcherCsvSchema.PhoneNumber = phone.display_value;
            } else {
              calsSearcherCsvSchema.PhoneNumber = null;
            }
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.Suburb)) {
          if (ausSearchResult.merchant_details.primary_address) {
            calsSearcherCsvSchema.Suburb = ausSearchResult.merchant_details.primary_address.suburb;
          } else {
            calsSearcherCsvSchema.Suburb = null;
          }
        }

        if (chosenFields.includes(calsSearcherCsvSchemaProps.URL)) {
          if (ausSearchResult.merchant_details.primary_contacts) {
            let url = ausSearchResult.merchant_details.primary_contacts.find(
              c => c.type_of_contact == ContactTypes.WebAddress);
            if (url) {
              calsSearcherCsvSchema.URL = url.value;
            } else {
              calsSearcherCsvSchema.URL = null;
            }
          }
        }
      }
        } else if (searchResult.result_code == SearchResultsCodes.ATM) {

            let atmSearchResult = searchResult.atm_search_results[0];

            if (chosenFields.includes(calsSearcherCsvSchemaProps.LWC_ID)) {
                calsSearcherCsvSchema.LWC_ID = atmSearchResult.ATM_ID;
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.LastVerified)) {
                if (atmSearchResult.atm_details.verification) {
                    calsSearcherCsvSchema.LastVerified = atmSearchResult.atm_details.verification.last_verified_on;
                } else {
                    calsSearcherCsvSchema.LastVerified = null;
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.Address)) {
                if (atmSearchResult.atm_details.primary_address) {
                    calsSearcherCsvSchema.Address = atmSearchResult.atm_details.primary_address.single_line_address;
                } else {
                    calsSearcherCsvSchema.Address = null;
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.Category)) {
                if (atmSearchResult.atm_details.primary_category) {
                    calsSearcherCsvSchema.Category = atmSearchResult.atm_details.primary_category.full_category_path;
                } else {
                    calsSearcherCsvSchema.Category = null;
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.HasLogo)) {
                if (!this.valueCheckerService.isNullOrUndefined(atmSearchResult.atm_details.atm_logo)) {
                    calsSearcherCsvSchema.HasLogo = true;
                } else {
                    calsSearcherCsvSchema.HasLogo = false;
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.PhoneNumber)) {
                if (atmSearchResult.atm_details.primary_contacts) {
                    let phone = atmSearchResult.atm_details.primary_contacts.find
                        (c => c.type_of_contact == ContactTypes.Phone || c.type_of_contact == ContactTypes.Mobile);
                    if (phone) {
                        calsSearcherCsvSchema.PhoneNumber = phone.display_value;
                    } else {
                        calsSearcherCsvSchema.PhoneNumber = null;
                    }
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.Suburb)) {
                if (atmSearchResult.atm_details.primary_address) {
                    calsSearcherCsvSchema.Suburb = atmSearchResult.atm_details.primary_address.suburb;
                } else {
                    calsSearcherCsvSchema.Suburb = null;
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.URL)) {
                if (atmSearchResult.atm_details.primary_contacts) {
                    let url = atmSearchResult.atm_details.primary_contacts.find(
                        c => c.type_of_contact == ContactTypes.WebAddress);
                    if (url) {
                        calsSearcherCsvSchema.URL = url.value;
                    } else {
                        calsSearcherCsvSchema.URL = null;
                    }
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.TradingName)) {
                calsSearcherCsvSchema.TradingName = atmSearchResult.atm_details.atm_name;
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.ChainName)) {
                calsSearcherCsvSchema.ChainName = atmSearchResult.atm_details.atm_operator ? atmSearchResult.atm_details.atm_operator.associates_name : null;
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.LocatedIn)) {
                if (atmSearchResult.atm_details.primary_address.address_located_in) {
                    calsSearcherCsvSchema.LocatedIn = atmSearchResult.atm_details.primary_address.address_located_in.located_in_name;
                } else {
                    calsSearcherCsvSchema.LocatedIn = null;
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.Email)) {
                if (atmSearchResult.atm_details.primary_contacts) {
                    let email = atmSearchResult.atm_details.primary_contacts.find(c => c.type_of_contact == ContactTypes.Email);
                    if (email) {
                        calsSearcherCsvSchema.Email = email.value;
                    } else {
                        calsSearcherCsvSchema.Email = null;
                    }
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.LWC_Guid)) {
                calsSearcherCsvSchema.LWC_Guid = atmSearchResult.atm_details.lwc_guid;
            }
        } else if (searchResult.result_code == SearchResultsCodes.InternationalCAL && searchResult.merchant_search_results[0]) {

          calsSearcherCsvSchema.resultCode = searchResult.result_code;
          if (this.valueCheckerService.isEmptyNullOrUndefined(searchResult.merchant_search_results[0].merchant_details)) {
            calsSearcherCsvSchema.Address = null;
            calsSearcherCsvSchema.Category = null;
            calsSearcherCsvSchema.TradingName = null;
            calsSearcherCsvSchema.merchantDetailsExist = false;
          }
          else {
            if (chosenFields.includes(calsSearcherCsvSchemaProps.Address)) {
                calsSearcherCsvSchema.Address = searchResult.merchant_search_results[0].merchant_details.primary_address.single_line_address;
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.Category)) {
                if (searchResult.merchant_search_results[0].merchant_details.primary_category) {
                    calsSearcherCsvSchema.Category = searchResult.merchant_search_results[0].merchant_details.primary_category.full_category_path;
                } else {
                    calsSearcherCsvSchema.Category = null;
                }
            }

            if (chosenFields.includes(calsSearcherCsvSchemaProps.TradingName)) {
              calsSearcherCsvSchema.TradingName = searchResult.merchant_search_results[0].merchant_details.merchant_primary_name;
            }
          }
          if (chosenFields.includes(calsSearcherCsvSchemaProps.LWC_Guid)) {
              calsSearcherCsvSchema.LWC_Guid = searchResult.merchant_search_results[0].lwc_guid;
          }
        }
        if (searchResult.result_code == SearchResultsCodes.HighConfidenceMatch || searchResult.result_code == SearchResultsCodes.LowConfidenceMatch) {

          if (chosenFields.includes(calsSearcherCsvSchemaProps.TypeOfMatch)) {
            calsSearcherCsvSchema.TypeOfMatch = searchResult.merchant_search_results[0].type_of_match;
          }

          if (chosenFields.includes(calsSearcherCsvSchemaProps.LWC_ID)) {
            calsSearcherCsvSchema.LWC_ID = searchResult.merchant_search_results[0].LWC_ID;
          }

        }

        return calsSearcherCsvSchema;
    }
}
