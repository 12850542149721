import { emptyString } from '../core/constants/constants';
import { ValueCheckerService } from '../core/services/valuechecker.service';
import { StringService } from '../core/formatting/string.service';
import { hotChocolate, getClientNames, upcomingExpiry } from '../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { LoadingService } from '../core/uiservices/loading.service';
import { Data, UpcomingExpiryData } from '../models/upcomingexpiry';

@Injectable({
  providedIn: "root"
})
export class UpcomingExpiryService {
  constructor(private apollo: Apollo, private loadingService: LoadingService, private stringService: StringService,
    private valueCheckerService: ValueCheckerService) { }

  getUpcomingExpiryDetails(clientName: string, isActive: boolean, lte: number, skip: number, take: number, orderBy: string, orderDirection: string) {
    this.loadingService.setLoading();
    let query = upcomingExpiry

    let optionalFilters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(clientName)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ clientName: {contains: "{0}" } }`, clientName);
    }

    if (isActive != false) {
      if (!this.valueCheckerService.isEmptyNullOrUndefined(isActive)) {
        optionalFilters = optionalFilters + this.stringService.format(`{isActive:{eq: {0} } }`, isActive.toString());
      }
    }

    if (optionalFilters === emptyString) {
      query = query.replace("where: { and: [ {0} ] },", emptyString);
    }
    return this.apollo.use(hotChocolate).watchQuery<UpcomingExpiryData>({
      query: gql`${this.stringService.format(query, optionalFilters, lte.toString(), skip.toString(), take.toString(), orderBy, orderDirection.toUpperCase())}`
    }).valueChanges;
  }

  getClientName(skip: number, take: number) {
    this.loadingService.setLoading();
    let query = getClientNames;
    return this.apollo.use(hotChocolate).watchQuery<Data>({
      query: gql`${this.stringService.format(query, skip.toString(), take.toString())}`
    }).valueChanges;
  }

}
