import { MatSelectChange } from '@angular/material/select';
import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, HostListener, ViewChild, Injector } from '@angular/core';
import { BankAccountTransactionTypeRequest, CategoryAllocationPattern, CategoryAllocationPatternDisplay, TransactionBankRequest } from '../../categories/categoryallocationpattern';
import { MatchConfidence, LookupType, CategoryType } from '../../enums/enums';
import { DialogService } from '../../core/uiservices/dialog.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { NameValue } from '../../core/models/namevalue';
import { CategoriesComponentService } from '../../categories/categoriescomponent.service';
import { CategoryLevelNumber } from '../../categories/categorylevelnumber';
import { FlatCategory } from '../../categories/flatcategory';
import { SetCategoryAllocationPatternsRequest } from '../../categories/setcategoryallocationpatternsrequest';
import { CategoryAllocationPatternService } from '../../services/categoryallocationpattern.service';
import { CategoryComponent } from '../../shared/category/category.component';
import { matchConfidenceNames, lookupTypeMap } from '../../core/constants/constants';
import { SelectedCategory } from '../../shared/category/selectedcategory';
import { CategoryMapperService } from '../../categories/categorymapper.service';
import { CategoryService } from '../../services/category.service';
import { FullCategoryPath } from '../../categories/fullcategorypath';
import { TestCategoryAllocationPatternsRequest } from '../../categories/testcategoryallocationpatternsrequest';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { CategoryAllocationPatternMatch } from '../../categories/categoryallocationpatternmatch';
import { CollectionTransformerService } from '../../core/collections/collectiontransformer.service';
import { LookupOptionService } from '../../services/lookupoption.service';
import { CategoryAllocationPatternsState } from './categoryallocationpatternsstate';
import { TransactionService } from '../../services/transaction.service';
import { BankAccountTransactionType, TransactionBank } from '../../models/ausindexingresult';
import { BankAccountTransactionTypeService } from '../../services/bankaccounttransactiontype.service';

@Component({
  selector: 'app-categoryallocationpatterns',
  templateUrl: './categoryallocationpatterns.component.html',
  styleUrls: ['./categoryallocationpatterns.component.scss']
})
export class CategoryAllocationPatternsComponent extends BaseStateComponent<CategoryAllocationPatternsState> implements OnInit {
  allMatchPatterns: CategoryAllocationPattern[] = [];
  filteredCategories: FlatCategory[];
  matchConfidenceNames = matchConfidenceNames;
  lookupTypeNames = this.collectionTransformerService.mapToArray(lookupTypeMap).filter(l => l.key !== LookupType.Custom);
  bankAccountTransactionTypes: BankAccountTransactionType[];
  previousCategoryType: CategoryType = CategoryType.Expense;
  testPattern: string;
  testInput: string;
  testResult: string[] = [];
  categoryTypes: NameValue<CategoryType>[];
  categoryLevelNumber = CategoryLevelNumber;
  selectedCategory: SelectedCategory;
  fullPathCategories: FullCategoryPath[];
  isDirty = false;
  noMatchesFound = false;
  testAmountInput = 0;
  transactionBank3CharCode: string = "Unknown";
  bankAccountTransactionType: string = "Unknown";
  @ViewChild('categoryComponent', { static: true }) categoryComponent: CategoryComponent;
  transactionBankList: TransactionBank[] = [];
  testIsRecurring: boolean;
  description: string;

  constructor(injector: Injector, private dialogService: DialogService,
    private notificationService: NotificationService, private loadingService: LoadingService, private categoriesComponentService: CategoriesComponentService,
    private categoryMatchPatternService: CategoryAllocationPatternService, private categoryMapperService: CategoryMapperService,
    private categoryService: CategoryService, private valueCheckerService: ValueCheckerService,
    private collectionTransformerService: CollectionTransformerService, private lookupOptionService: LookupOptionService,
    private transactionService: TransactionService, private bankAccountTransactionTypeService: BankAccountTransactionTypeService) {
    super(injector);
    this.description = '';
  }

  ngOnInit() {
    this.state = {
      selectedMatchPatterns: []
    };
    this.restoreState();
    this.loadingService.setLoading();
    this.categoryService.getFlatCategories().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(categories => {
      this.fullPathCategories = this.categoryMapperService.mapToFullCategoryPath(categories);
    });

    this.categoryTypes = this.categoriesComponentService.categoryTypes;

    this.getAllMatchPatterns();
    this.setTransactionBankList();
    this.setBankAccountTransactionTypes();
  }

  @HostListener('window:beforeunload')
  openSaveConfirmDialog() {
    if (this.isDirty) {
      return false;
    }

    return null;
  }

  isLookupTypeCustom(row: CategoryAllocationPattern) {
    if (row.LookupType === LookupType.Custom) {
      return true;
    }
    return false;
  }

  getCategoryFullPath(id: number) {
    let category = this.fullPathCategories.find(c => c.id === id);
    if (category) {
      return category.name;
    }
  }

  isTestButtonDisabled() {
    if (this.testInput && this.testInput.trim() && this.state.selectedMatchPatterns.length > 0
      && !this.valueCheckerService.isEmptyNullOrUndefined(this.testAmountInput)
      && this.transactionBank3CharCode && this.bankAccountTransactionType) {
      return false;
    }

    return true;
  }

  createNewRecord() {
    if (this.selectedCategory && this.selectedCategory.isSelected) {
      let newRecord: CategoryAllocationPattern = {
        SearchString: '',
        LookupType: LookupType.StartsWith,
        MatchConfidence: MatchConfidence.High,
        Priority: 0,
        NewCategoryID: this.selectedCategory.category.CategoryID,
        ExistingCategoryID: -1
      }

      this.state.selectedMatchPatterns.unshift(newRecord);
      this.state.selectedMatchPatterns = [...this.state.selectedMatchPatterns];
      this.setDirty();
    } else {
      this.notificationService.notifyError("Category is not selected");
    }
  }

  deleteRecord(record: CategoryAllocationPattern) {
    let recordToDelete = this.state.selectedMatchPatterns.indexOf(record);
    const dialogRef = this.dialogService.openDeleteConfirmDialog();

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (recordToDelete >= 0) {
          this.state.selectedMatchPatterns.splice(recordToDelete, 1);
          this.state.selectedMatchPatterns = [...this.state.selectedMatchPatterns];
          this.setDirty();
          this.notificationService.notifySuccess('Record deleted successfully.');
        }
      }
    });
  }

  saveChanges() {
    let valid = this.state.selectedMatchPatterns.every(t => t.ExistingCategoryID !== -1 || !this.valueCheckerService.isEmptyNullOrUndefined(t.SearchString));
    if (!valid) {
      this.notificationService.notifyError("Rule must contain at least 'Category' or 'Search String'");
      return;
    }
    if (!this.lookupOptionService.validateLookupOptionsAndShowDialogIfItIsInvalid(this.state.selectedMatchPatterns)) {
      return;
    }
    let matchPatternsForSave: SetCategoryAllocationPatternsRequest = this.prepareSaveRequest();
    this.loadingService.setLoading();
    this.categoryMatchPatternService.saveMatchPatterns(matchPatternsForSave).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.setClean();
        this.getAllMatchPatterns();
        this.notificationService.notifySuccess('Action completed');
      });
  }

  setDirty() {
    this.isDirty = true;
    this.categoryComponent.confirmSelection = true;
  }

  setClean() {
    this.isDirty = false;
    this.categoryComponent.confirmSelection = false;
  }

  testMatchPattern() {
    let testCategoryAllocationPatternsRequest: TestCategoryAllocationPatternsRequest = {
      Amount: this.testAmountInput,
      TextToMatch: this.testInput,
      CategoryAllocationPatterns: this.getCategoryAllocationPatternsForRequest(),
      TransactionBank3CharCode: this.transactionBank3CharCode,
      BankAccountTransactionType: this.bankAccountTransactionType
    }

    if (this.testIsRecurring === false || this.testIsRecurring === true) {
      testCategoryAllocationPatternsRequest.IsRecurring = this.testIsRecurring;
    }
    this.loadingService.setLoading();

    this.categoryMatchPatternService.testMatchPattern(testCategoryAllocationPatternsRequest).pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(results => {
        if (results.length === 0) {
          this.noMatchesFound = true;
          this.testResult = [];

          return;
        }

        this.noMatchesFound = false;
        this.testPattern = this.testInput;
        this.testResult = [];

        this.createResultStrings(results);
      });
  }

  move(row: CategoryAllocationPattern, isUp: boolean) {
    let step: number = isUp ? -1 : 1;
    let index = this.state.selectedMatchPatterns.indexOf(row);
    let isWithinBounds = this.state.selectedMatchPatterns[index + step];
    if (isWithinBounds) {
      let temp = this.state.selectedMatchPatterns[index];
      this.state.selectedMatchPatterns[index] = this.state.selectedMatchPatterns[index + step];
      this.state.selectedMatchPatterns[index + step] = temp;
      this.setDirty();
      this.state.selectedMatchPatterns = [...this.state.selectedMatchPatterns];
    }
  }

  handleCategorySelect(selectedCategory: SelectedCategory) {
    this.selectedCategory = selectedCategory;
    if (selectedCategory.isSelected) {
      this.findMatchPattern(selectedCategory.category);
    } else {
      this.state.selectedMatchPatterns = [];
    }
    this.setClean();
  }

  setFullPathCategoryValue(event: number, row: CategoryAllocationPattern) {
    if (event) {
      row.ExistingCategoryID = event;
    } else {
      row.ExistingCategoryID = -1;
    }

    this.setDirty();
  }

  transactionBankSelected(event: MatSelectChange, row: CategoryAllocationPatternDisplay) {
    if (event.value.includes("Unknown")) {
      row.TransactionBank3CharCodesDisplay = ["Unknown"];
    }
    this.setDirty();
  }

  private getAllMatchPatterns() {
    this.categoryMatchPatternService.getMatchPatterns().subscribe(patterns => {
      this.allMatchPatterns = patterns;
    });
  }

  private createFullPathCategory(categoryId: number) {
    let category = this.categoriesComponentService.getCategory(this.categoryComponent.categoryConfig, categoryId);
    let resultString: string = '';
    if (category) {
      resultString = category.CategoryName;
      if (category.ParentCategoryID) {
        let parentCategory = this.categoriesComponentService.getCategory(this.categoryComponent.categoryConfig, category.ParentCategoryID);
        resultString = parentCategory.CategoryName + " >> " + resultString;
        if (parentCategory.ParentCategoryID) {
          let parentParentCategory = this.categoriesComponentService.getCategory(this.categoryComponent.categoryConfig, parentCategory.ParentCategoryID);
          resultString = parentParentCategory.CategoryName + " >> " + resultString;
        }
      }
    }
    return resultString;
  }

  private findMatchPattern = (option: FlatCategory) => {
    this.state.selectedMatchPatterns = this.allMatchPatterns.filter(p => p.NewCategoryID === option.CategoryID);
    this.setDisplayValues();
    this.sortSelectedMatchpatterns();
  }

  private sortSelectedMatchpatterns() {
    this.state.selectedMatchPatterns.sort((a, b) => a.Priority - b.Priority);
  }

  private prepareSaveRequest() {
    return {
      CategoryAllocationPatterns: this.getCategoryAllocationPatternsForRequest(),
      CategoryId: this.selectedCategory.category.CategoryID
    };
  }

  private createResultStrings(results: CategoryAllocationPatternMatch[]) {
    for (let result of results) {
      let fullPathCategory = this.createFullPathCategory(result.CategoryId);

      if (fullPathCategory) {
        let resultString = `${fullPathCategory} - (Matched as LookupType ${lookupTypeMap.get(result.LookupType)} for search string ${result.SearchString})`;
        this.testResult.push(resultString);
      }
    }
  }

  private setTransactionBankList() {
    this.transactionService.getTransactionBankList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.transactionBankList = response.data;
      });
  }

  private getCategoryAllocationPatternsForRequest() {
    let categoryAllocationPatterns: CategoryAllocationPattern[] = [];
    for (let pattern of this.state.selectedMatchPatterns) {
      let categoryAllocationPattern: CategoryAllocationPattern = {
        CategoryAllocationPatternID: pattern.CategoryAllocationPatternID,
        ExistingCategoryID: pattern.ExistingCategoryID,
        NewCategoryID: pattern.NewCategoryID,
        SearchString: pattern.SearchString,
        LookupType: pattern.LookupType,
        MatchConfidence: pattern.MatchConfidence,
        LastUpdated: pattern.LastUpdated,
        Priority: this.state.selectedMatchPatterns.indexOf(pattern) + 1,
        MinAmountInclusive: pattern.MinAmountInclusive,
        MaxAmountInclusive: pattern.MaxAmountInclusive,
        MinPersonNames: pattern.MinPersonNames,
        ReferenceCategory: pattern.ReferenceCategory,
        TransactionBanks: this.getTransactionBanks(pattern.TransactionBank3CharCodesDisplay),
        BankAccountTransactionTypes: this.getBankAccountTransactionTypes(pattern.BankAccountTransactionTypesDisplay),
        Description: pattern.Description
      }

      if (pattern?.IsRecurring === false || pattern?.IsRecurring === true) {
        categoryAllocationPattern.IsRecurring = pattern.IsRecurring;
      }
      categoryAllocationPatterns.push(categoryAllocationPattern);

    }
    return categoryAllocationPatterns;
  }

  private setDisplayValues() {
    for (let pattern of this.state.selectedMatchPatterns) {
      if (pattern.TransactionBanks?.length > 0) {
        pattern.TransactionBank3CharCodesDisplay = pattern.TransactionBanks.map(t => t.TransactionBank3CharCode);
      }
      if (pattern.BankAccountTransactionTypes?.length > 0) {
        pattern.BankAccountTransactionTypesDisplay = pattern.BankAccountTransactionTypes.map(t => t.BankAccountTransactionType);

      }
    }
  }

  private setBankAccountTransactionTypes() {
    this.loadingService.setLoading();
    this.bankAccountTransactionTypeService.getBankAccountTransactionTypes()
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.length > 0) {
          this.bankAccountTransactionTypes = response.data;
        }
      });
  }

  private getBankAccountTransactionTypes(bankAccountTransactionTypesDisplay: string[]) {
    let bankAccountTransactionTypes: BankAccountTransactionTypeRequest[] = [];

    if (!this.valueCheckerService.isEmptyNullOrUndefined(bankAccountTransactionTypesDisplay) && bankAccountTransactionTypesDisplay.length > 0) {
      for (let k = 0; k < bankAccountTransactionTypesDisplay.length; k++) {
        bankAccountTransactionTypes.push({
          Id: 0,
          CategoryAllocationPatternID: 0,
          BankAccountTransactionType: bankAccountTransactionTypesDisplay[k],
          LastUpdated: null,
          CategoryAllocationPattern: null
        });
      }
    }
    return bankAccountTransactionTypes;
  }

  private getTransactionBanks(transactionBank3CharCodesDisplay: string[]) {
    let transactionBanks: TransactionBankRequest[] = [];

    if (!this.valueCheckerService.isEmptyNullOrUndefined(transactionBank3CharCodesDisplay) && transactionBank3CharCodesDisplay.length > 0) {
      if (!transactionBank3CharCodesDisplay.includes("Unknown")) {
        for (let t = 0; t < transactionBank3CharCodesDisplay.length; t++) {
          transactionBanks.push({
            Id: 0,
            CategoryAllocationPatternID: 0,
            TransactionBank3CharCode: transactionBank3CharCodesDisplay[t],
            LastUpdated: null,
            CategoryAllocationPattern: null
          });
        }
      }
      else {
        return;
      }
    }
    return transactionBanks;
  }

  editDescription(description: string, row: CategoryAllocationPattern) {
    let index = this.state.selectedMatchPatterns.indexOf(row);
    const dialogRef = this.dialogService.openEditDialog(description);

    dialogRef.componentInstance.Okay.subscribe(result => {
      this.state.selectedMatchPatterns[index].Description = result;
    });
  }
}
