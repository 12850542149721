import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { finalize, map, Observable, startWith } from 'rxjs';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { ManageclientService } from '../../services/manageclient.service';
import { PageInfo } from '../../shared/models/pageinfo';
import { ApplicationUser } from '../../users/applicationuser';
import { UserService } from '../../users/applicationuser.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { ManageclientDialogComponent } from './manageclient-dialog/manageclient-dialog.component';
import { ManageClient } from '../../models/manageclient.models';
import { clientNames } from '../../models/manageapikeys.models';
import { HmacKey } from '../apiclients/apiclients.models';
import { ApiClientDataService } from '../apiclients/apiclientsdata.service';


@Component({
  selector: 'app-manageclient',
  templateUrl: './manageclient.component.html',
  styleUrls: ['./manageclient.component.scss']
})
export class ManageclientComponent implements OnInit {
  @ViewChild('csvExport') csvExportComponent: CsvExportComponent;
  columnMode = ColumnMode;
  clients: ManageClient[] = [];
  clientNames: clientNames[];
  applicationUsers: ApplicationUser[];
  selectedClientValue: any;
  orderBy: string;
  orderDirection: string;

  totalCount: number = 0;
  pageIndex: number = 0;
  take: number = 25;
  skip: number = 0;
  clientNameTake: number;
  hmacKeys: HmacKey;
  hideHmacKeys: boolean = true;

  formControl = new FormControl();
  filteredClientName: Observable<clientNames[]>;
  private manageClientCsvFile: ManageClient[];

  dialogConfig = new MatDialogConfig();
  modalDialog: MatDialogRef<ManageclientDialogComponent, any> | undefined;

  private csvHeaders = {
    headers: [
      'clientName',
      'description',
      'clientCode',
      'fourCharCode',
      'isActive',
      'id',
      'createdByName',
      'apiClientTags',
      'apiClientCategory',
      'createdDate',
      'modifiedDate',
      'modifiedByName',
      'maxTransactionPerSearch',
      'maxLimitPerPageForMerchantLookup',
      'maxRecordsPagedForMerchantLookup',
      'maxBpayLookupTransactionsPerRequest',
      'maxMoneyTrackerTransactionsLimit',
      'defaultCountry',
      'allAvailableCountries'
    ],
    keys: [
      'clientName',
      'description',
      'clientCode',
      'fourCharCode',
      'isActive',
      'id',
      'createdByName',
      'apiClientTags',
      'apiClientCategory',
      'createdDate',
      'modifiedDate',
      'modifiedByName',
      'maxTransactionPerSearch',
      'maxLimitPerPageForMerchantLookup',
      'maxRecordsPagedForMerchantLookup',
      'maxBpayLookupTransactionsPerRequest',
      'maxMoneyTrackerTransactionsLimit',
      'defaultCountry',
      'allAvailableCountries'
    ]
  };

  csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.csvHeaders };

  constructor(private loadingService: LoadingService, private manageClientService: ManageclientService,
    private csvComponentService: CsvComponentService, public dialog: MatDialog, private dateTimeService: DateTimeService,
    private userService: UserService, private apiClientDataService: ApiClientDataService) {
    this.selectedClientValue = '';
    this.clientNames = [];
    this.orderBy = 'clientName';
    this.orderDirection = 'asc';
    this.clientNameTake = 5000;
  }

  ngOnInit(): void {
    this.getApplicationUsers();
    if (!this.selectedClientValue) {
      this.getClientNames();
    }
    this.filteredClientName = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

  }

  getClientNames() {
    this.loadingService.setLoading();
    this.manageClientService.getClientName(this.skip, this.clientNameTake).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.clientNames = response.data.items;
        this.totalCount = response.data.totalCount;
      }
      if (this.totalCount) {
        this.getClients();
      }
    });
  }

  getClients() {
    this.loadingService.setLoading();
    this.manageClientService.getOnBoardingData(this.selectedClientValue.trim(), this.skip, this.totalCount, this.orderBy, this.orderDirection).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.clients = response.data.items;
        this.totalCount = response.data.totalCount;
        if (this.clients) {
          for (let data of this.clients) {
            let d = this.applicationUsers.find(u => u.Id == data.createdBy);
            data.createdByName = d.FirstName + " " + d.LastName;
            data.createdDate = this.dateTimeService.format(data.createdDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
            data.modifiedDate = this.dateTimeService.format(data.modifiedDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
            let b = this.applicationUsers.find(u => u.Id == data.modifiedBy);
            if (b != null) {
              data.modifiedByName = b.FirstName + " " + b.LastName;
            }
          }
        }
      }
    });
  }

  downloadCsvFile() {
    this.loadingService.setLoading();
    this.manageClientService.getOnBoardingData(this.selectedClientValue.trim(), this.skip, this.totalCount, this.orderBy, this.orderDirection).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.manageClientCsvFile = response.data.items;
        for (let data of this.manageClientCsvFile) {
          let d = this.applicationUsers.find(u => u.Id == data.createdBy);
          data.createdByName = d.FirstName + " " + d.LastName;
          data.createdDate = this.dateTimeService.format(data.createdDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
          data.modifiedDate = this.dateTimeService.format(data.modifiedDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
          let b = this.applicationUsers.find(u => u.Id == data.modifiedBy);
          if (b != null) {
            data.modifiedByName = b.FirstName + " " + b.LastName;
          }
        }
        this.csvExportComponent.data = this.manageClientCsvFile;
        this.csvExportComponent.options = this.csvOptions;
        this.csvExportComponent.onDownload();
      }
    });
  }

  getClientDetails() {
    this.getClients();
  }

  getApplicationUsers() {
    this.userService.getApplicationUsers().subscribe(response => {
      this.applicationUsers = response;

    });
  }

  changeClientName(value: string) {
    if (value) {
      this.selectedClientValue = value;
    }
  }

  isCsvButtonDisabled() {
    return this.clientNames.length > 0;
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    this.pageIndex = pageInfo.offset;
    this.skip = pageInfo.offset * this.take;
    this.getClients();
  }

  private _filter(value: string): clientNames[] {
    const filterValue = value.toLowerCase();
    return this.clientNames.filter(option => option.clientName.toLowerCase().includes(filterValue));
  }

  generateHmacKeys() {
    this.apiClientDataService.getHmacKeys()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.data && response.data.hmackeys) {
          this.hmacKeys = response.data.hmackeys;
          this.hideHmacKeys = false;
        }
      });
  }

  openDialog() {
    if (this.edit) {
      this.dialogConfig.data = '';
      this.dialogConfig.id = "manage-client-dialog-component";
      this.dialogConfig.height = "600px";
      this.dialogConfig.width = "1000px";
      this.dialog.open(ManageclientDialogComponent, this.dialogConfig).afterClosed().subscribe(x => {
        if (!this.selectedClientValue) {
          this.getClients();
        }
      });
    }
  }

  edit(value: ManageClient) {
    this.dialogConfig.data = value;
    this.dialogConfig.id = "manage-client-dialog-component";
    this.dialogConfig.height = "600px";
    this.dialogConfig.width = "1000px";
    this.dialog.open(ManageclientDialogComponent, this.dialogConfig).afterClosed().subscribe(x => {
      if (this.selectedClientValue) {
        this.getClients();
      }
    });
  }

  onSort(event: any) {
    this.orderBy = event.sorts[0].prop;
    this.orderDirection = event.sorts[0].dir;
    this.getClients();
  }
}
