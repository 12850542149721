import { GroupOfSimilarCals } from "../../../models/CAL";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../../core/uiservices/loading.service";
import { CardAcceptorLocatorService } from "../../../services/cardacceptorlocator.service";
import { PageInfo } from "../../../shared/models/pageinfo";
import { finalize } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { RouteConstants } from "../../../core/constants/constants";

export type GroupOfSimilarCalsResponse = {
  items: GroupOfSimilarCals[];
  totalCount: number;
};

@Component({
  selector: "app-groupofsimilarcals",
  templateUrl: "./groupofsimilarcals.component.html",
  styleUrls: ["./groupofsimilarcals.component.scss"],
})
export class GroupOfSimilarCalsComponent implements OnInit {
  groupOfSimilarCals: GroupOfSimilarCals[] = [];
  totalElements: number;
  pageIndex = 0;
  skip: number = 0;
  take: number = 50;
  transactionDescription: string;

  constructor(private loadingService: LoadingService, private activatedRoute: ActivatedRoute,
    private router: Router, private cardAcceptorLocatorService: CardAcceptorLocatorService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      if (param['transactionDescription']) {
        this.transactionDescription = param['transactionDescription'];
        this.setGroupOfSimilarCals();
      }
      else {
        this.navigateToCardAcceptorLocators();
      }
    });
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    if (pageInfo.offset === this.pageIndex) {
      return;
    }
    else {
      this.pageIndex = pageInfo.offset;
      this.skip = this.pageIndex * pageInfo.pageSize;
      this.setGroupOfSimilarCals();
    }
  }

  navigateToCardAcceptorLocators() {
    this.router.navigate([RouteConstants.cardAcceptorLocators]);
  }

  private setGroupOfSimilarCals() {
    this.loadingService.setLoading();
    this.cardAcceptorLocatorService.getGroupOfSimilarCals(this.skip, this.transactionDescription)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.totalCount) {
          this.groupOfSimilarCals = response.data.items;
          this.totalElements = response.data.totalCount;
        }
        else {
          this.groupOfSimilarCals = [];
          this.totalElements = 0;
        }
      });
  }
}
