import { DateTimeService } from './../../core/uiservices/datetime.service';
import { LoadingService } from './../../core/uiservices/loading.service';
import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector, ViewChild, ElementRef } from '@angular/core';
import { MerchantLogoState } from './merchantogo.state';
import { MerchantLogoService } from '../../services/merchantlogoservice.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../core/uiservices/notification.service';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { ActivatedRoute } from '@angular/router';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { ApiClientConfig } from '../../core/api/apiclientconfig';

@Component({
  selector: 'app-merchantlogo',
  templateUrl: './merchantlogo.component.html',
  styleUrls: ['./merchantlogo.component.scss']
})

export class MerchantLogoComponent extends BaseStateComponent<MerchantLogoState> implements OnInit {
  customLogoUrl: string;
  customLogoSelected: boolean = false;
  setLogoButtonClicked: boolean = false;

  @ViewChild("customLogo") customLogo: ElementRef;

  private customLogoTypes: string[] = ['image/jpeg', 'image/png'];
  private customLogoFile: File;

  constructor(injector: Injector, private merchantLogoService: MerchantLogoService,
    private loadingService: LoadingService, private notificationService: NotificationService,
    private merchantlookupService: MerchantlookupService, private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService,
    private dateTimeService: DateTimeService, public dialog: MatDialog, private activatedRoute: ActivatedRoute,
    private valueCheckerService: ValueCheckerService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      LWC_ID: null,
      ignoreCache: false,
      logoResponse:
      {
        logos: [],
        screenShot: "",
      },
      imageName: "",
      merchantLookupResponseFirstPart: null,
      merchantLookupResponseSecondPart: null,
    };
    this.restoreState();

    let lwcId = parseInt(this.activatedRoute.snapshot.params["lwcId"]);
    if (!isNaN(lwcId)) {
      this.state.LWC_ID = lwcId;
      this.loadLogo();
    }
  }

  loadLogo() {
    this.loadingService.setLoading();
    this.setCleanState();
    this.merchantLogoService.loadLogo(this.state.LWC_ID, this.state.ignoreCache)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response && response.logos && response.logos.length > 0) {
          this.state.logoResponse = response;
          if (this.state.logoResponse.screenShotUpdated) {
            this.state.logoResponse.screenShotUpdated = this.dateTimeService.formatWithHours(this.state.logoResponse.screenShotUpdated);
          }
        }
        else {
          this.notificationService.notifySuccess('No logo found for merchant');
        }
        this.loadMerchantDetails();
      });  
  }

  setLogo() {
    this.setLogoButtonClicked = true;
    if (this.customLogoSelected === true) {
      this.setLogoFromManualUpload();
    }
    else {
      let indexBeforeImageName = this.state.imageName.lastIndexOf('/');
      let imageName = this.state.imageName.substring(indexBeforeImageName + 1);
      this.loadingService.setLoading();
      this.merchantLogoService.setLogo(this.state.LWC_ID, imageName)
        .pipe(finalize(() => this.loadingService.clearLoading()))
        .subscribe(() => {
          this.notificationService.notifySuccess('Image set as a logo successfully');
          this.loadMerchantDetails();
        });
    }
  }

  selectFromScreenshot() {
    let imageCropper = this.dialog.open(ImageCropperComponent, {
      width: 'auto',
      maxWidth: '1200px',
      height: 'auto',
      maxHeight: '600px',
      data: this.state.logoResponse.screenShot ? this.state.logoResponse.screenShot : 'assets/img/EmptyLogo.png',
    });
    imageCropper.afterClosed().subscribe(croppedImage => {
      if (croppedImage) {
        this.loadingService.setLoading();
        this.merchantLogoService.uploadLogo(this.state.LWC_ID, croppedImage)
          .pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(() => {
            this.notificationService.notifySuccess('Image uploaded successfully');
          });
      }
    });
  }

  uploadCustomLogo(files: FileList) {
    this.customLogoFile = files[0];
    this.customLogoSelected = false;
    let file = files[0];
    if (!file) {
      return;
    }
    if (!this.merchantLogoService.logoTypeValid(file, this.customLogoTypes)) {
      return;
    }
    this.setCustomLogoDataUrl(file, (logoUrl) => {
      this.customLogoUrl = logoUrl;
    });
  }

  customLogoChanged() {
    this.customLogoSelected = true;
    this.state.imageName = "";
  }

  logoChanged() {
    this.customLogoSelected = false;
  }

  private loadMerchantDetails() {
    this.loadingService.setLoading();
    this.merchantlookupService.getCals(this.state.LWC_ID)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(response);
          this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(response);
        }
      })
  }

  private setCleanState() {
    this.state.merchantLookupResponseFirstPart = null,
      this.state.merchantLookupResponseSecondPart = null,
      this.state.imageName = '',
      this.state.logoResponse = {
        logos: [],
        screenShot: "",
      }
  }

  private setLogoFromManualUpload() {
    if (this.valueCheckerService.isEmptyNullOrUndefined(this.state.LWC_ID)) {
      this.notificationService.notifyError("Please enter LWC ID");
      return;
    }

    let formData = new FormData();
    let config: ApiClientConfig = {
      skipContentType: true
    };

    formData.append('LogoFile', this.customLogoFile);
    formData.append('LwcId', this.state.LWC_ID.toString());
    this.loadingService.clearLoading();
    this.merchantLogoService.setLogoFromManualUpload(formData, config)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.resetCustomProperties();
        this.notificationService.notifySuccess("Logo uploaded successfully");
      },
        () => {
          this.resetCustomProperties();
        });
  }

  private setCustomLogoDataUrl(file: File, callbakFunc: (imageDataUrl: string) => void) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      callbakFunc(<string>reader.result);
    }
  }

  private resetCustomProperties() {
    this.customLogoFile = null;
    this.customLogoUrl = "";
    this.customLogoSelected = false;
    this.customLogo.nativeElement.value = "";
    this.setLogoButtonClicked = false;
  }
}
