import { StringService } from '../core/formatting/string.service';
import { fetchRetailersQuery, hotChocolate } from '../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { FetchRetailerResponse } from '../retaileraudit/retaileraudit';

@Injectable({
  providedIn: "root"
})
export class RetailerAuditService {
  constructor(private apollo: Apollo, private stringService: StringService) {
  }

  getRetailerAudits(lwcId: number, date: string) {
    return this.apollo
      .use(hotChocolate)
      .watchQuery<FetchRetailerResponse>({
        query: gql`${this.stringService.format(fetchRetailersQuery, date, lwcId.toString())}`
      })
      .valueChanges;
  }
}
