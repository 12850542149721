import { ApiClientConfig } from '../core/api/apiclientconfig';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { Merchantindex } from '../models/merchantindex';
import { AUS_Indexed } from '../models/AUS_Indexed';
import { Observable, of, Subject } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { CalRellocationRequest } from '../components/changecalallocation/calrellocationrequest';
import { CALRellocationResult } from '../components/changecalallocation/calrellocationresult';
import { ChainDto } from '../models/ausindexingresult';
import { InternationalChainDto } from '../models/internationalchaindto';
import { InternationalChainUpdate } from '../components/manageinternationalchains/internationalchainupdate';
import { MerchantDetailedResponse, MerchantSummary } from '../components/mergemerchants/merchantsummary';
import { UpsertChainRequest } from '../components/managechains/editchain/upsertchainrequest';
import { DeleteChainRequest } from '../components/managechains/deletechainrequest';
import { TestChainPatternsRequest } from '../chains/testchainpatternsrequest';
import { ChainPatternMatch } from '../chains/chainpatternmatch';
import { apiPathConstants } from '../core/api/apipathconstants';
import { ReviewChainPatternsRequest } from '../chains/reviewchainpatternsrequest';
import { ChainPatternKnownMatch } from '../chains/chainpatternknownmatch';
import { PurgeResult } from '../models/purgeresult';
import { MerchantDetails } from '../components/merchantextract/merchantdetails';
import { DeleteMerchantNamesRequest } from '../models/deletemerchantnamesrequest';
import { DeleteAssociatedWithRequest } from '../models/deleteassociatedwithrequest';
import { DeleteContactMethodRequest } from '../models/deletecontactmethodrequest';
import { InMemoryCacheService } from './inmemorycache.service';
import { MerchantDetailsResponse } from '../components/lwcmerchantsearch/lwcmerchantsearchstate';
import { hotChocolate, suburbList } from '../gqlconstants/gqlconstants';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { StringService } from '../core/formatting/string.service';


@Injectable()
export class MerchantlookupService {

  private API_MERCHANTINDEX = 'merchantindex';
  private API_MERCHANTLOOKUP = 'merchantlookup';
  private API_MERCHANTCHAIN = 'merchantchain';
  private chains = apiPathConstants.chains;
  private internationalChains = 'internationalchains';
  private maxNumberOfRecords = 10;
  private chainApi = this.chains;
  chainsRefreshing = new Subject<boolean>();

  constructor(private apiService: ApiService, private apiClient: ApiClient, private inMemoryCacheService: InMemoryCacheService,private apollo: Apollo, private stringService: StringService,) { }

  searchByCal(cal: string): Observable<Merchantindex> {
    return this.apiClient.get<Merchantindex>(this.apiService.buildUrl(apiPathConstants.mercantIndexV4, encodeURIComponent(cal)));
  }
  searchDatabaseByCal(cal: string): Observable<AUS_Indexed> {
    return this.apiClient.get<AUS_Indexed>(this.apiService.buildUrl(`${this.API_MERCHANTINDEX}/merchantdatabasedetails`, encodeURIComponent(cal)));
  }
  searchIndexByLwcId(lwc_id: any): Observable<MerchantDetails> {
    return this.apiClient.get<MerchantDetails>(
      this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantdetailsbylwcid`, lwc_id.toString()));
  }
  searchDatabaseByLwcId(lwc_id: number): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/databasedetailsbylwcid`, lwc_id.toString()));
  }
  searchDatabaseByAbn(abn: any): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantdetailsbyabn`, abn.toString()));
  }
  searchDatabaseByGuid(guid: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantdetailsbylwcguid`, guid));
  }
  searchByPrimaryName(primaryName: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantdetailsbyname`, primaryName));
  }
  searchDatabaseByChainNameBranchId(chainName: string, id: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTCHAIN}/bybranchid`, chainName, id));
  }

  searchCalByDataBaseDetails(cal: string): Observable<AUS_Indexed> {
    return this.apiClient.get<AUS_Indexed>(this.apiService.buildUrl(`${this.API_MERCHANTINDEX}/merchantdatabasedetails`, encodeURIComponent(cal)));
  }

  searchCalByWebsite(websiteName: string): Observable<AUS_Indexed> {
    return this.apiClient.get<AUS_Indexed>(this.apiService.buildUrl(`${this.API_MERCHANTINDEX}/v4/merchant`, websiteName));
  }


  searchMerchantSummaryByChainNameBranchId(chainName: string, id: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTCHAIN}/bybranchid`, chainName, id));
  }

  searchDatabaseByChainHq(chainName: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTCHAIN}/chainhq`, chainName));
  }

  searchDatabaseByChainNameSuburb(chainName: string, suburb: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTCHAIN}/bysuburb`, chainName, suburb));
  }

  searchByWebsite(websiteName: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantdetailsbywebsite`, websiteName));
  }

  searchSingleMerchantSummaryByChainNameSuburb(chainName: string, suburb: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTCHAIN}/bysuburb`, chainName, suburb));
  }

  searchMerchantSummaryByAbn(abn: any): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantsummarybyabn`, abn.toString(), `${this.maxNumberOfRecords}`));
  }

  searchMerchantSummaryByGuid(guid: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantsummarybylwcguid`, guid));
  }
  searchMerchantSummaryByPrimaryName(primaryName: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantsummarybyname`, primaryName, `${this.maxNumberOfRecords}`));
  }
  searchMerchantSummaryByWebsite(websiteName: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantsummarybywebsite`, websiteName, `${this.maxNumberOfRecords}`));
  }

  searchMerchantSummaryByDatabaseDetailsByLwcids(lwcids: number[]): Observable<string> {
    return this.apiClient.post<string>(this.apiService.buildUrl(apiPathConstants.merchantLookupMerchantSummary), lwcids);
  }

  searchAllMerchantSummaryByChainNameSuburb(chainName: string, suburb: string): Observable<string> {
    return this.apiClient.get<string>(this.apiService.buildUrl(`${this.API_MERCHANTLOOKUP}/merchantsummarysuburb`, chainName, suburb));
  }

  getChains(): Observable<ChainDto[]> {
    const config: ApiClientConfig = {
      errorMessage: 'Unable to load Chain file'
    };
    let data = this.inMemoryCacheService.get<ChainDto[]>(this.chainApi);

    if (data !== null) {
      return of(data);
    }
    this.chainsRefreshing.next(true);

    return this.apiClient.get<ChainDto[]>(this.apiService.buildUrl(this.chainApi, null), config)
      .pipe(tap(result => this.inMemoryCacheService.set(this.chainApi, result)), finalize(() => this.chainsRefreshing.next(false)));
  }

  getChainsFromCacheIncludingStale(): ChainDto[] {
    return this.inMemoryCacheService.get<ChainDto[]>(this.chainApi, true);
  }

  refreshChains() {
    return this.apiClient.get<ChainDto[]>(this.apiService.buildUrl(`${this.API_MERCHANTCHAIN}/refreshchains`))
      .pipe(tap(() => {
        this.apiClient.get<ChainDto[]>(this.apiService.buildUrl(this.chainApi))
          .subscribe(res => this.inMemoryCacheService.set(this.chainApi, res))
      }));
  }
  getCals(lwcId: any) {

      return this.apiClient.get<MerchantSummary>(this.apiService.buildUrl(apiPathConstants.merchantLookupMerchantSummary, lwcId.toString())); 
   
  }

  getCalsByMerchantDetails(lwcId: number) {
    return this.apiClient.get<MerchantDetailedResponse>(this.apiService.buildUrl(apiPathConstants.merchantdetailsbylwcid, lwcId.toString()));
  }

  getSimilarCals(lwcId: number) {
    return this.apiClient.get<string[]>(this.apiService.buildUrl(apiPathConstants.similarcals, lwcId.toString()));
  }

  getCalsBasedOnBsb(bsb: string) {
    return this.apiClient.get<MerchantSummary>(this.apiService.buildUrl(apiPathConstants.merchantLookupMerchantSummaryByBsb, bsb));
  }

  associateCals(cal: CalRellocationRequest) {
    return this.apiClient.put<CALRellocationResult[]>(this.apiService.buildUrl(`merchant/reallocatecals`), cal);
  }

  saveChain(upsertChainRequest: UpsertChainRequest) {
    return this.apiClient.put<ChainDto>(this.apiService.buildUrl(`${this.chains}`), upsertChainRequest);
  }

  deleteChain(deleteChainRequest: DeleteChainRequest) {
    return this.apiClient.delete(this.apiService.buildUrl(`${this.chains}`), deleteChainRequest);
  }

  getInternationalChains() {
    return this.apiClient.get<InternationalChainDto[]>(this.apiService.buildUrl(`${this.internationalChains}`));
  }

  saveInternationalChains(chainUpdate: InternationalChainUpdate[]) {
    return this.apiClient.put<InternationalChainDto[]>(this.apiService.buildUrl(`${this.internationalChains}`), chainUpdate);
  }

  testChainMatchPattern(testChainPatternsRequest: TestChainPatternsRequest) {
    return this.apiClient.post<ChainPatternMatch[]>(this.apiService
      .buildUrl(apiPathConstants.testChainRules), testChainPatternsRequest);
  }

  reviewChainRules(reviewChainPatternsRequest: ReviewChainPatternsRequest) {
    return this.apiClient.post<ChainPatternKnownMatch[]>(this.apiService
      .buildUrl(apiPathConstants.reviewChainRules), reviewChainPatternsRequest);
  }

  deleteMerchantNames(deleteMerchantNamesRequest: DeleteMerchantNamesRequest) {
    return this.apiClient.delete<PurgeResult>(this.apiService
      .buildUrl(apiPathConstants.merchantIndexOtherNames), deleteMerchantNamesRequest);
  }

  deleteAssociatedWithRequest(deleteAssociatedWithRequest: DeleteAssociatedWithRequest) {
    return this.apiClient.delete<PurgeResult>(this.apiService
      .buildUrl(apiPathConstants.merchantIndexAssociatedWith), deleteAssociatedWithRequest);
  }

  deleteMerchantContactMethod(deleteContactMethodRequest: DeleteContactMethodRequest) {
    return this.apiClient.delete<PurgeResult>(this.apiService
      .buildUrl(apiPathConstants.merchantIndexContactMethods), deleteContactMethodRequest);
  }

  getMerchantDetailsByLwcId(lwcId: number[]) {
    return this.apiClient.post<MerchantDetailsResponse[]>(this.apiService.buildUrl(apiPathConstants.merchantDetailsByLwcIds), lwcId);
  }

  getMerchantDetailsByBsbs(bsbs: string[]) {
    return this.apiClient.post<MerchantDetailsResponse[]>(this.apiService.buildUrl(apiPathConstants.merchantDetailsByBsbs), bsbs);
  }
  
  getSuburbList() {
    let graphQLQuery = suburbList;
    return this.apollo
      .use(hotChocolate)
      .watchQuery<any>({
        query: gql`${this.stringService.format(graphQLQuery)}`
      })
      .valueChanges;
  }
}
