import { MatchCalsToChainsResponse } from "../../components/chainpatternmatcher/chainpatternmatcherstate.component";
import { MatchConfidence, LookupOptionType } from "../../enums/enums";

export let chainpatternmatcherResponse: MatchCalsToChainsResponse = {
    Matches: [
        {
            CAL: 'COLES ONLINE 1928',
            Match: null
        },
        {
            CAL: 'WOOLWORTHS INSURANCE SYDNEY',
            Match: {
                ChainId: 1,
                MatchedPattern: {
                    SearchString: 'WOOLWORTHS INSURANCE',
                    SearchStringsNoSpaces: [],
                    SearchStrings: [],
                    LookupType: 2,
                    MatchConfidence: MatchConfidence.High,
                    LastUpdated: '2019-06-08T17:12:29.157',
                },
                MatchConfidence: MatchConfidence.High,
                LookupOptionType: LookupOptionType.TransactionDescriptionLookupOption
            }
        },
        {
            CAL: 'WOOLWORTHS INSURANCE SYDNEY',
            Match: {
                ChainId: 2,
                MatchedPattern: {
                    SearchString: 'WOOLWORTHS INSURANCE',
                    SearchStringsNoSpaces: [],
                    SearchStrings: [],
                    LookupType: 1,
                    MatchConfidence: MatchConfidence.High,
                    LastUpdated: '2019-09-08T17:09:29.47',
                },
                MatchConfidence: MatchConfidence.High,
                LookupOptionType: LookupOptionType.TransactionDescriptionLookupOption
            }
        }
    ]
}