import { ExecutionResult } from 'graphql';
import { BsbsCleansedResponse, AssignBsbToMerchantResponse } from '../../components/managebsbs/managebsbs.component';
import { emptyString } from '../../core/constants/constants';

export let bsbsCleansedResponse: ExecutionResult<BsbsCleansedResponse> = {
  data: {
    items: [
      {
        bsb: "012-251",
        lwc_ID: 0,
        merchantName: emptyString,
        nameOfIndexer: emptyString,
        mnemonic: "ANZ",
        name: "Merged",
        street: "Refer to BSB 012-006",
        suburb: "Sydney",
        state: "NSW",
        postcode: "2000",
        paymentsFlags: "PEH",
        mergedBsb: "012-006",
        bsbStatus: "Merged",
        institutionFullName: "Australia & New Zealand Banking Group Limited",
        updateDate: "2015-11-26T06:29:14.667Z"
      },
      {
        bsb: "012-340",
        lwc_ID: 145895000,
        merchantName: emptyString,
        nameOfIndexer: emptyString,
        mnemonic: "ANZ",
        name: "Merged",
        street: "merging with 012-301",
        suburb: "Marrickville",
        state: "NSW",
        postcode: "2204",
        paymentsFlags: "PEH",
        mergedBsb: null,
        bsbStatus: "Merged",
        institutionFullName: "Australia & New Zealand Banking Group Limited",
        updateDate: "2020-11-26T06:29:14.723Z"
      },
      {
        bsb: "012-434",
        lwc_ID: 154518488,
        merchantName: emptyString,
        nameOfIndexer: emptyString,
        mnemonic: "ANZ",
        name: "Closed",
        street: "Refer to BSB 012-256 08/07",
        suburb: "Ryde",
        state: "NSW",
        postcode: "2112",
        paymentsFlags: "PEH",
        mergedBsb: "012-256",
        bsbStatus: "Closed",
        institutionFullName: "Australia & New Zealand Banking Group Limited",
        updateDate: "2010-11-26T02:12:14.77Z"
      },
      {
        bsbStatus: "Closed",
        bsb: "012-434",
        institutionFullName: "Australia & New Zealand Banking Group Limited",
        lwc_ID: 1988485250,
        merchantName: emptyString,
        mergedBsb: "012-256",
        mnemonic: "ANZ",
        name: "Closed",
        nameOfIndexer: emptyString,
        paymentsFlags: "PEH",
        postcode: "2112",
        state: "NSW",
        street: "Refer to BSB 012-256 08/07",
        suburb: "Ryde",
        updateDate: emptyString
      }
    ],
    totalCount: 500
  }
};

export let assignBsbToMerchantResponse: ExecutionResult<AssignBsbToMerchantResponse> = {
  data: {
    assignBsbToMerchant: {
      lwc_ID: 0,
      bsb: "000-000",
      merchantName: "Test",
      nameOfIndexer: "Tester",
      result: {
        outcome: true,
        comments: "BSB not added. BSB 000-000 is not in BSB APCA Cleansed List;"
      }
    }
  }
};
