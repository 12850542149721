import { CalDetails } from "../../components/calparser/calparserstate";
import { CALParserResult } from "../../models/calparserresult";

export let calDetailsResponse: CalDetails[] = [
    {
        cal: "CHATIME UPPER LEVEL UPPER MOUNT GRAVATT QLD",
        cardAcceptor: "CHATIME UPPER LEVEL",
        standardisedTransactionDescription: "",
        despacedTransactionDescription: "",
        formattedCardAcceptor: "",
        suburb: ""
    },
    {
        cal: "CHATIME UPPER LEVEL UPPER MOUNT GAU",
        cardAcceptor: "CHATIME UPPER LEVEL UPPER MOUNT GAU",
        standardisedTransactionDescription: "",
        despacedTransactionDescription: "",
        formattedCardAcceptor: "",
        suburb: ""
    },
    {
        cal: "CHATIME GDN CITY UP LV +61292830880 AU",
        cardAcceptor: "CHATIME GDN CITY UP LV +61292830880",
        standardisedTransactionDescription: "",
        despacedTransactionDescription: "",
        formattedCardAcceptor: "",
        suburb: ""
    },
    {
        cal: "99 BIKES PTY LTD BROOKVALE",
        cardAcceptor: "99 BIKES PTY LTD",
        standardisedTransactionDescription: "",
        despacedTransactionDescription: "",
        formattedCardAcceptor: "",
        suburb: ""
    },
    {
        cal: "ADAIRS BALGOWLAH",
        cardAcceptor: "ADAIRS BALGOWLAH",
        standardisedTransactionDescription: "",
        despacedTransactionDescription: "",
        formattedCardAcceptor: "",
        suburb: ""
    },
    {
        cal: "#641 WAIKIKI RETAIL HONOLULU",
        cardAcceptor: "#641 WAIKIKI RETAIL HONOLULU",
        standardisedTransactionDescription: "",
        despacedTransactionDescription: "",
        formattedCardAcceptor: "",
        suburb: ""
    },
    {
        cal: "0402 WWW.USABOX.COM 305-406-1696",
        cardAcceptor: "0402 WWW.USABOX.COM 305-406",
        standardisedTransactionDescription: "0402 WWW.USABOX.COM 305-406",
        despacedTransactionDescription: "0402 WWW.USABOX.COM 305-406",
        formattedCardAcceptor: "0402 WWW.USABOX.COM 305-406",
        suburb: "Dutton Park"
    },
    {
        cal: "NABINTNL TRN FEE REV -MC",
        cardAcceptor: "NABINTNL TRN FEE REV -MC",
        standardisedTransactionDescription: "NABINTNL TRN FEE REV -MC",
        despacedTransactionDescription: "NABINTNL TRN FEE REV -MC",
        formattedCardAcceptor: "NABINTNL TRN FEE REV -MC",
        suburb: "Melbourne"
    },
    {
        cal: "INTERNET PAYMENT CREDIT ARD",
        cardAcceptor: "INTERNET",
        standardisedTransactionDescription: "",
        despacedTransactionDescription: "",
        formattedCardAcceptor: "",
        suburb: ""
    }
]

export let calSplitterResponse: Partial<CALParserResult> = {
    StandardisedTransactionDescription: "FITBIZ ST MARYS ST MARYS NSW",
    DespacedTransactionDescription: "FIREFLY MACKAY",
    FormattedCardAcceptor: "Formatted Card Acceptor",
    Suburb: "ALGESTER"
}