import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThreeWayCheckboxValue } from '../../../enums/enums';

@Component({
  selector: 'app-threestatetogglecheckbox',
  templateUrl: './threestatetogglecheckbox.component.html',
  styleUrls: ['./threestatetogglecheckbox.component.scss']
})
export class ThreeStateToggleCheckBoxComponent implements OnInit {
  @Input() tooltipNone: string;
  @Input() tooltipUnchecked: string;
  @Input() tooltipChecked: string;
  @Input() value = ThreeWayCheckboxValue.None;
  @Input() label: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  tooltip: string;

  constructor() { }

  ngOnInit() {
    this.getTypeOfIcon();
  }

  toggle(increment = 1) {
    this.value += increment;
    if (this.value > ThreeWayCheckboxValue.Checked) {
      this.value = ThreeWayCheckboxValue.None;
    }

    this.emitValueChanged();
    this.getTypeOfIcon();
  }

  getTypeOfIcon() {
    switch (this.value) {
      case ThreeWayCheckboxValue.None:
        this.tooltip = this.tooltipNone;
        return 'radio_button_unchecked';
      case ThreeWayCheckboxValue.Unchecked:
        this.tooltip = this.tooltipUnchecked;
        return 'radio_button_checked';
      case ThreeWayCheckboxValue.Checked:
        this.tooltip = this.tooltipChecked;
        return 'circle';
      default:
        return 'radio_button_unchecked';
    }
  }

  private emitValueChanged() {
    this.onChange.emit(this.value);
  }
}
