import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { DataSource, SearchSelectBase, OptionEntry } from '@oasisdigital/angular-material-search-select';
import { SearchSelectService } from '../../services/searchselect.service';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { FullCategoryPath } from '../../categories/fullcategorypath';

@Component({
  selector: 'full-path-category-select',
  templateUrl: './fullpathcategoryselect.component.html'
})
export class FullPathCategorySelectComponent extends BaseComponent implements OnInit {
  @Input() width: string = '100%';
  categoryCtrl: FormControl = new FormControl();
  categoriesDataSource: DataSource = this.searchSelectService.getEmptyDataSource();
  @Input() fullPathCategories: FullCategoryPath[];
  @ViewChild('searchSelect', {static: true}) private searchSelect: SearchSelectBase;
  @Input()
  set value(value: number) {
    let category = this.fullPathCategories.find(c => c.id == value);

    if (category) {
      let option: OptionEntry = {
        value: category.id,
        display: category.name,
        details: ''
      }
      this.searchSelect.searchControl.setValue(option);
    } else {
      this.searchSelect.searchControl.setValue(null);
    }
  }
  @Output() valueChange = new EventEmitter<number>();

  constructor(injector: Injector, private searchSelectService: SearchSelectService) {
    super(injector);
  }

  ngOnInit() {
    this.categoriesDataSource = this.getCategoryDataSource();

    this.onValueChanges();
  }

  private getCategoryDataSource() {
    let filterFn = (c: FullCategoryPath, term: string) => c.name.toLowerCase().includes(term);
    let mapFn = (category: FullCategoryPath) => ({
      value: category.id,
      display: category.name,
      details: ''
    });

    return this.searchSelectService.getDataSource(this.fullPathCategories, filterFn, mapFn);
  }

  private onValueChanges() {
    this.categoryCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(category => {
      this.valueChange.emit(category);
    });
  }
}
