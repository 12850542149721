export enum ContactTypes
{
    Phone = "PHONE",
    WebAddress = "URL",
    Mobile = "MOBILE",
    InternationalPhone = "INTERNATIONALPHONE",
    Facebook = "FACEBOOK",
    Twitter = "TWITTER",
    LinkedIn = "LINKEDIN",
    CrunchBase = "CRUNCHBASE",
    Email = "EMAIL",
    Zomato = "ZOMATO"
}