import { CALParserResult, Amount, Suburb, SuburbMatch, LocatorType,
  AdditionalDebitInformation, PaymentMethod, CashOut } from '../../models/calparserresult';

let amounts: Amount[] = [{
    Value: 462,
    Currency: 'EUR'
  },
  {
    Value: 692,
    Currency: 'USD'
  }
];

let suburb: Suburb = {
  Name: 'test suburb',
  State: 'test state',
  Postcode: 'test post code',
  Country: 'test country',
  Lat: 33,
  Lon: 26,
  Area_Sq_KM: 6546456
};

let suburbMatch: SuburbMatch = {
  Suburb: suburb,
  Confidence: 5464543,
  OtherPossibleSuburbs: [
      'test OtherPossibleSuburbs1',
      'test OtherPossibleSuburbs2'
  ],
  StateCandidates: [
    'test state candidates1',
    'test state candidates2'
  ]
};

let additionalDebitInformation: AdditionalDebitInformation = {
  OriginalDebitCAL: 'test OriginalDebitCAL',
  TypeOfDebitCard: 'test TypeOfDebitCard',
  TransactionDate: new Date(),
  MerchantReferenceNumber: 'test MerchantReferenceNumber',
  Last4DigitsOfCard: 'test Last4DigitsOfCard',
  IsWordSplit: true
};

let cashOut: CashOut = {
  CashOutAmount: 865346564,
  CashOutCurrencyCode: 'test CashOutCurrencyCode'
};

export let getCalParserResultResponse: CALParserResult = {
  Branch: 'test branch1',
  BankKeywords: 'test bnk keuwords1',
  TransactionDescription: 'test transaction discription1',
  DespacedTransactionDescription: 'test despaced transaction description1',
  StandardisedTransactionDescription: 'test standardised transaction description1',
  CardAcceptor: 'test card acceptor1',
  CardAcceptorPredictor: 'test card acceptor predicator1',
  FormattedCardAcceptor: 'test formatted card acceptor1',
  Country: 'test country',
  TransactionAmounts: amounts,
  OtherKeywords: 'test other keywords',
  PhoneNumber: '8765435677899',
  IsPtyLtd: true,
  Suburb: 'test suburb',
  SuburbExt: suburb,
  SuburbMatch: suburbMatch,
  State: 'test state',
  StateConfidence: suburbMatch.Confidence,
  WebAddress: 'test web address',
  TransactionReference: 'test TransactionReference',
  PaymentGateway: 'test PaymentGateway',
  IsRestaurant: true,
  IsPharmacy: false,
  IsCompany: true,
  IsHotel: true,
  IsCarPark: true,
  WhatIsTheLocator: LocatorType.WebAddress,
  ExecutionTime: 4121212,
  CandidateSuburbs: [ 'test CandidateSuburbs1', 'test CandidateSuburbs2'],
  CandidateStates: [ 'test CandidateSuburbs1', 'test CandidateSuburbs2'],
  CandidateLatLon: [[1, 2], [1, 2]],
  DebitCardAdditionalInformation: additionalDebitInformation,
  CALPaymentMethod: PaymentMethod.BPay,
  CashOutInformation: cashOut,
  BPayAdditionalInformation: {
    BPayBillerCode: 1545,
    BPayCustomerReferenceNumber: 15
  },
  TransactionType: 10
};

