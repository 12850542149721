import { BaseComponent } from './../../../shared/base/base.component';
import { commaSpace } from './../../../core/constants/constants';
import { ValueCheckerService } from './../../../core/services/valuechecker.service';
import { Component, Injector, ViewChild } from '@angular/core';
import { ApiClientDTOFormatted, ApiClientDTO, ApiClientPolicy } from '../apiclientdto';
import { ApiClientDataService } from '../apiclientsdata.service';
import { RouteConstants } from '../../../core/constants/constants';
import { Router } from '@angular/router';
import { NotificationService } from '../../../core/uiservices/notification.service';
import { NgForm, NgModel, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../../core/uiservices/loading.service';
import { ManageChainsDataService } from '../../managechains/managechainsdata.service';
import { countryCodes } from '../../../core/i18n/countrycodes';
import { MatOption } from '@angular/material/core';
import { ApplicationUser } from '../../../users/applicationuser';
import { UserService } from '../../../users/applicationuser.service';
import { AuthService } from '../../../services/auth.service';
import { DateTimeService } from '../../../core/uiservices/datetime.service';

@Component({
  selector: 'app-editapiclient',
  templateUrl: './editapiclient.component.html',
  styleUrls: ['./editapiclient.component.scss']
})

export class EditApiClientComponent extends BaseComponent {
  editApiClient: ApiClientDTOFormatted;
  countryCodes = this.manageChainsDataService.moveAustraliaAndNewZealandCountriesToTop(countryCodes);
  @ViewChild('editApiClientForm') editApiClientForm: NgForm;
  @ViewChild('selectAllEngines') selectAllEngines: MatOption;
  useTheseEnginesForm: FormGroup;
  applicationUsers: ApplicationUser[];
  clientCodeText: string = '';
  allApiClientPolicies: ApiClientPolicy[] = [];
  selectedApiClientPolicies: string[] = [];

  constructor(injector: Injector, private apiClientDataService: ApiClientDataService, private router: Router,
    private notificationService: NotificationService, private loadingService: LoadingService,
    private manageChainsDataService: ManageChainsDataService, private fb: FormBuilder,
    private userService: UserService, private  authService: AuthService,
    private dateTimeService: DateTimeService, private valueCheckerService: ValueCheckerService) {
      super(injector);
  }

  ngOnInit() {
    this.editApiClient = this.apiClientDataService.getEditData();
    this.userService.getApplicationUsers().subscribe(users => {
      this.applicationUsers = users;
    });
    if (!this.editApiClient) {
      this.router.navigate([RouteConstants.apiClients]);
    } else {
      if(this.editApiClient.clientCode) {
        this.clientCodeText = this.editApiClient.clientCode.toString();
      }
      this.useTheseEnginesForm = this.fb.group({
        useTheseEngines: new FormControl(this.editApiClient.useTheseEngines)
      });
      if (!this.valueCheckerService.isEmptyNullOrUndefined(this.editApiClient.policies)) {
        this.selectedApiClientPolicies = [...this.editApiClient.policies.split(commaSpace)];
      }
      this.setApiClientAllPolicies();
    }
  }

  save() {
    if (this.editApiClientForm.invalid) {
      this.notificationService.notifyError('Please fill in required fields');

      return;
    }
    this.loadingService.setLoading();
    let user = this.authService.getCurrentUser();
    let currentUser = this.applicationUsers.find(u => u.EmailId == user.userId);
    if(currentUser) {
      this.editApiClient.modifiedBy = currentUser.Id;

      if (!this.editApiClient.createdBy) {
        this.editApiClient.createdBy = this.editApiClient.modifiedBy;
      }
    }

    this.editApiClient.apiClientPolicies = [];
    for (let policy of this.selectedApiClientPolicies) {
      let newPolicy: ApiClientPolicy = {
        policy: policy,
        policyId: this.allApiClientPolicies.find(t => t.policy === policy).policyId
      };
      this.editApiClient.apiClientPolicies.push(newPolicy);
    }

    this.editApiClient.clientCode = this.getNormalInteger(this.clientCodeText);
    let editApiClient: Partial<ApiClientDTO> = {
      clientId: this.editApiClient.clientId,
      apikey: this.editApiClient.apikey,
      apikeyName: this.editApiClient.apikeyName,
      clientName: this.editApiClient.clientName,
      isSubscriber: this.editApiClient.isSubscriber,
      isActive: this.editApiClient.isActive,
      isReliableKey: this.editApiClient.isReliableKey,
      createDate: this.dateTimeService.format(this.editApiClient.createDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes),
      createdBy: this.editApiClient.createdBy,
      modifiedBy: this.editApiClient.modifiedBy,
      clientCode: this.editApiClient.clientCode,
      defaultSearchEngine: this.editApiClient.defaultSearchEngine,
      useTheseEngines: this.useTheseEnginesForm.controls.useTheseEngines.value,
      maxTransactionPerSearch: this.editApiClient.maxTransactionPerSearch,
      threeCharCode: this.editApiClient.threeCharCode,
      apiClientPolicies: this.editApiClient.apiClientPolicies
    };

    this.apiClientDataService.saveApiClient(editApiClient).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(() => {
      this.loadingService.clearLoading()
      this.router.navigate([RouteConstants.apiClients]);
    }, (err) => {
      this.notificationService.notifyError(err)
    });
  }

  cancel() {
    this.router.navigate([RouteConstants.apiClients]);
  }

  shouldShowClientCodeInvalid(clientCodeNgModel: NgModel) {
    if (!clientCodeNgModel.touched) {
      return false;
    }

    return this.isClientCodeInvalid();
  }

  isClientCodeInvalid() {
    if (!this.clientCodeText || !this.isNormalInteger(this.clientCodeText)) {
      return true;
    }
    return false;
  }

  selectOneEngine() {
    if (this.selectAllEngines.selected) {
      this.selectAllEngines.deselect();
    }
  }

  pickAllEngines() {
    if (this.selectAllEngines.selected) {
      this.useTheseEnginesForm.controls.useTheseEngines.patchValue(['ALL']);
    }
  }

  private isNormalInteger(str: string) {
    str = str.trim();
    if (!str) {
        return false;
    }
    str = str.replace(/^0+/, "") || "0";
    var n = this.getNormalInteger(str);
    return n !== Infinity && String(n) === str && n >= 0;
  }

  private getNormalInteger(str: string) {
    str = str.trim();

    str = str.replace(/^0+/, "") || "0";
    var n = Math.floor(Number(str));
    return n;
  }

  private setApiClientAllPolicies() {
    this.loadingService.setLoading();
    this.apiClientDataService.getApiClientAllPolicies()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        this.allApiClientPolicies = response.data;
      });
  }
}
