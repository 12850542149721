import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MerchantNameSearchState } from './merchantnamesearch.state';
import { LoadingService } from '../../core/uiservices/loading.service';
import { MerchantNameSearchService } from '../../services/merchantnamesearch.service';
import { finalize } from 'rxjs/operators';
import { MerchantWordSuggestionRequest, MerchantWordRequest } from './merchantnamessearch.request';
import { EnumMerchantWordOperations, MerchantWordOperation } from './merchantnamesearch.response';
import { MatCheckbox } from '@angular/material/checkbox';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-merchantnamesearch',
  templateUrl: './merchantnamesearch.component.html',
  styleUrls: ['./merchantnamesearch.component.scss']
})
export class MerchantNameSearchComponent extends BaseStateComponent<MerchantNameSearchState> implements OnInit, AfterViewChecked {
  merchantWordSuggestionRequest: MerchantWordSuggestionRequest = {
    MerchantName: '',
    Suburb: '',
    UseStemWords: false,
    RemovePunctuation: false,
    SearchEntirePhrase: false,
    RemoveCommonCalWords: false
  };

  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  minimumValueOfLimit = 0;
  maximumValueOfLimit = 1000;
  startYear: number = 2020;
  originalEditRow: MerchantWordOperation;

  merchantWordRequest: MerchantWordRequest = {
    WordOperations: [],
    Year: this.currentYear,
    StartMonth: this.currentMonth,
    EndMonth: this.currentMonth,
    Limit: 0,
  };
  years: number[] = Array(this.currentYear - this.startYear + 1).fill(0).map((_, idx) => this.startYear + idx);
  months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  currentDateForCompare = moment(new Date(this.currentYear, this.currentMonth -1)).format("YYYY/MM");
  currentDateForDisplay = moment(new Date(this.currentYear, this.currentMonth -1)).format("MM/YYYY");
  endDateGreateThanCurrentDate: boolean;
  startDateGreateThanCurrentDate: boolean;
  operations = [
    {
      key: 'Optional', value: 0,
    },
    {
      key: 'Must', value: 1
    }
  ];
  limitGreateThenMaximumValue: boolean;
  editingRowIndex: number = -1;
  merchantWordsOrPhrasesEditing: boolean = false;

  constructor(injector: Injector, private loadingService: LoadingService, private merchantNameSearchService: MerchantNameSearchService,
    private changeDetectorRef: ChangeDetectorRef, private csvComponentService: CsvComponentService,
    private valueCheckerService: ValueCheckerService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      lwcId: null,
      merchantNames: [],
      merchantWordSuggestionResponse: {
        OriginalMerchantName: null,
        Query: '',
        MerchantWordOperations: []
      },
      merchantNameSearchResponse: []
    };
    this.restoreState();
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  setMerchantNames() {
    this.loadingService.setLoading();
    this.merchantNameSearchService.getMerchantNames(this.state.lwcId)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.state.merchantNames = response;
        }
      });
  }

  suggest() {
    this.loadingService.setLoading();
    this.merchantNameSearchService.getMerchantWordSuggestions(this.merchantWordSuggestionRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.state.merchantWordSuggestionResponse = response;
        }
      });
  }

  removeRow(rowIndex: number, checkbox: MatCheckbox) {
    this.state.merchantWordSuggestionResponse.MerchantWordOperations.splice(rowIndex, 1);
    this.state.merchantWordSuggestionResponse.MerchantWordOperations = [...this.state.merchantWordSuggestionResponse.MerchantWordOperations];
    checkbox.checked = false;
  }

  addRow() {
    this.state.merchantWordSuggestionResponse.MerchantWordOperations.push({
      Word: "",
      Operation: EnumMerchantWordOperations.Must
    });
    this.state.merchantWordSuggestionResponse.MerchantWordOperations = [...this.state.merchantWordSuggestionResponse.MerchantWordOperations];
    this.editingRowIndex = this.state.merchantWordSuggestionResponse.MerchantWordOperations.length - 1;
  }

  editMerchantWordsOrPhrases(rowIndex: number, row: MerchantWordOperation) {
    this.originalEditRow = cloneDeep(row);
    this.editingRowIndex = rowIndex;
    this.merchantWordsOrPhrasesEditing = true;
  }

  searchButtonDisable() {
    this.limitGreateThenMaximumValue = this.merchantWordRequest.Limit > this.maximumValueOfLimit;

    return this.limitGreateThenMaximumValue
      || this.endDateGreateThanCurrentDate
      || this.startDateGreateThanCurrentDate
      || this.valueCheckerService.isEmptyNullOrUndefined(this.merchantWordRequest.EndMonth)
      || this.valueCheckerService.isEmptyNullOrUndefined(this.merchantWordRequest.StartMonth)
      || this.valueCheckerService.isEmptyNullOrUndefined(this.merchantWordRequest.Year);
  }

  cancel(rowIndex: number) {
    if (this.merchantWordsOrPhrasesEditing === true) {
      this.merchantWordsOrPhrasesEditing = false;
      this.state.merchantWordSuggestionResponse.MerchantWordOperations[rowIndex] = this.originalEditRow;
      this.state.merchantWordSuggestionResponse.MerchantWordOperations = [...this.state.merchantWordSuggestionResponse.MerchantWordOperations];
    }
    else {
      this.state.merchantWordSuggestionResponse.MerchantWordOperations.splice(rowIndex, 1);
      this.state.merchantWordSuggestionResponse.MerchantWordOperations = [...this.state.merchantWordSuggestionResponse.MerchantWordOperations];
    }
    this.editingRowIndex = -1;
  }

  save() {
    this.editingRowIndex = -1;
    this.merchantWordsOrPhrasesEditing = false;
  }

  search() {
    this.loadingService.setLoading();
    this.merchantWordRequest.WordOperations = this.state.merchantWordSuggestionResponse.MerchantWordOperations;
    this.merchantNameSearchService.getMerchantNameSearches(this.merchantWordRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.state.merchantNameSearchResponse = response;
        }
      });
  }

  getCsvOptions() {
    let obj = this.state.merchantNameSearchResponse[0];
    obj = { ...obj };
    let options = this.csvComponentService.getCsvOptions(obj, 'MerchantNameSearch.csv');
    return options;
  }

  checkLimit() {
    this.merchantWordRequest.Limit = this.valueCheckerService.isEmptyNullOrUndefined(this.merchantWordRequest.Limit) ? 0 : this.merchantWordRequest.Limit;
  }

  endDateMoreThanCurrentDate() {
    let date = moment(new Date(this.merchantWordRequest.Year, this.merchantWordRequest.EndMonth - 1)).format("YYYY/MM");
    this.endDateGreateThanCurrentDate = moment(date).isAfter(this.currentDateForCompare);
  }

  startDateMoreThanCurrentDate() {
    let date = moment(new Date(this.merchantWordRequest.Year, this.merchantWordRequest.StartMonth - 1)).format("YYYY/MM");
    this.startDateGreateThanCurrentDate = moment(date).isAfter(this.currentDateForCompare);
  }
}
