import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AboutDialogData } from './aboutdialogdata';

@Component({
  selector: 'app-aboutdialog',
  templateUrl: './aboutdialog.component.html',
  styleUrls: ['./aboutdialog.component.scss']
})
export class AboutDialogComponent {
  constructor(public dialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AboutDialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
