import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";
import { Injectable } from "@angular/core";
import { MergeMerchantsRequest } from "../components/mergemerchants/mergemerchantsrequest";
import { MergeMerchantsResult } from "../components/mergemerchants/mergemerchantsresult";

@Injectable()
export class MergeMerchantsService {
	constructor(private apiService: ApiService, private apiClient: ApiClient) { }

	mergeMerchants(data: MergeMerchantsRequest) {
		return this.apiClient.post<MergeMerchantsResult>(this.apiService.buildUrl('merchant/merge'), data);
	}
}
