import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { StringService } from "../core/formatting/string.service";
import { hotChocolate, retrieveExperianAddressQuery } from "../gqlconstants/gqlconstants";
import { Observable } from "rxjs";
import { ApolloQueryResult, NetworkStatus } from '@apollo/client/core';
import { NgModel } from "@angular/forms";
import { debounceTime, distinctUntilChanged, mergeMap } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class RetrieveExperianAddressesService {
  constructor(private apollo: Apollo, private stringService: StringService) { }

  getExperianAddresses(address: string, currentAddresses: string[]) {
    if (address && !currentAddresses.includes(address) && address.length > 5) {
      return this.apollo
        .use(hotChocolate)
        .watchQuery<string[]>({
          query: gql`${this.stringService.format(retrieveExperianAddressQuery, address)}`
        })
        .valueChanges;
    }
    else {
      let experianAddress: ApolloQueryResult<string[]> = {
        data: [],
        loading: false,
        networkStatus: NetworkStatus.ready,
      };
      return new Observable<ApolloQueryResult<string[]>>(resp => {
        resp.next(experianAddress);
      });
    }
  }

  retrieveExperianAddresses(model: NgModel, getCurrentAddresses: () => string[]) {
    return model.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        mergeMap((address: string) => {
          return this.getExperianAddresses(address, getCurrentAddresses())
        })
      );
  }
}

