import { DatatableComponent } from '@swimlane/ngx-datatable';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LoadingService } from './../../core/uiservices/loading.service';
import { BaseStateComponent } from '../basestate/basestate.component';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ManagerValidationStatsState } from './merchantvalidationstats.state';
import { DateRangeOption, MerchantValidationStatsGroupBy } from '../../enums/enums';
import { emptyString, timePickerTheme } from '../../core/constants/constants';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import * as moment from 'moment';
import { ValidationStatsByValidation, ValidationStatsDetail, ValidationStatsDetailDisplay, ValidationStatsByIndexer, ValidationStats, validationStatsDetailDisplayProps, validationStatsProps } from './merchantvalidationstats.models';
import { MerchantValidationStatsService } from '../../services/merchantvalidationstats.service';
import { PageInfo } from '../../shared/models/pageinfo';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { FormControl } from '@angular/forms';

export type ValidationStatsByValidationResponse = {
  items: ValidationStatsByValidation[];
  totalCount: number;
};

export type ValidationStatsResponse = {
  items: ValidationStatsDetail[];
  totalCount: number;
};

export type ValidationStatsByIndexerResponse = {
  items: ValidationStatsByIndexer[];
  totalCount: number;
};

@Component({
  selector: 'app-merchantvalidationstats',
  templateUrl: './merchantvalidationstats.component.html',
  styleUrls: ['./merchantvalidationstats.component.scss']
})
export class MerchantValidationStatsComponent extends BaseStateComponent<ManagerValidationStatsState> implements OnInit {
  dateRangeOption = DateRangeOption;
  groupBy = MerchantValidationStatsGroupBy;
  primaryTheme: NgxMaterialTimepickerTheme = timePickerTheme;
  validationStatsDetailResponse: {items: ValidationStatsDetailDisplay[]; totalCount: number;};
  validationStatsTableHeight: number;
  @ViewChild('validationStatsTable') validationStatsTable: DatatableComponent;
  @ViewChild('csvExportDetailsToExcel') csvExportDetailsToExcelComponent:  CsvExportComponent;
  validationStats: ValidationStats[] = [];
  dataForValidationStatsDetail: ValidationStatsDetailDisplay[] = [];
  count: number = 0;
  offset: number = 0;
  totalCountOfMerchantValidationDetails: number = 0;
  searchValidationStatsDetailByIndexer: boolean = false;
  filterByValidationId: string;
  filterByValidation: string;
  filterByIndexName: string;
  filterByIndexerId: string;
  filterByCount: string;
  filterByIndexerCount: string;
  @ViewChild('csvExportSummaryToExcel') csvExportSummaryToExcel: CsvExportComponent;

  headersForMerchantValidationStatsDetails = {
    validationId: 'Validation Id',
    validation: 'Validation',
    comment: 'Comment',
    overriddenComment: 'Overridden Comment',
    isOverridden: 'Is Overridden',
    nameOfIndexer: 'Name Of Indexer',
    createdDateDisplay: 'Created Date',
    updatedDateDisplay: 'Updated Date',
    warningError: 'Warning Error',
  };

  optionHeadersAndKeysForMerchantValidationStatsDetails = {
    headers : [
      this.headersForMerchantValidationStatsDetails.validationId,
      this.headersForMerchantValidationStatsDetails.validation,
      this.headersForMerchantValidationStatsDetails.comment,
      this.headersForMerchantValidationStatsDetails.overriddenComment,
      this.headersForMerchantValidationStatsDetails.isOverridden,
      this.headersForMerchantValidationStatsDetails.nameOfIndexer,
      this.headersForMerchantValidationStatsDetails.createdDateDisplay,
      this.headersForMerchantValidationStatsDetails.updatedDateDisplay,
      this.headersForMerchantValidationStatsDetails.warningError
    ],
      keys: [
        validationStatsDetailDisplayProps.validationId,
        validationStatsDetailDisplayProps.validation,
        validationStatsDetailDisplayProps.comment,
        validationStatsDetailDisplayProps.overriddenComment,
        validationStatsDetailDisplayProps.isOverridden,
        validationStatsDetailDisplayProps.nameOfIndexer,
        validationStatsDetailDisplayProps.createdDateDisplay,
        validationStatsDetailDisplayProps.updatedDateDisplay,
        validationStatsDetailDisplayProps.warningError
      ]
  };

  optionsForHeadersAndKeysForMerchantValidationStatsDetails = {...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionHeadersAndKeysForMerchantValidationStatsDetails};

  headersForValidationStatsByValidation = {
    validationId: 'Validation Id',
    validation: 'Validation',
    count: 'Count',
  };
  optionHeadersAndKeysForValidationStatsByValidation = {
    headers: [
      this.headersForValidationStatsByValidation.validationId,
      this.headersForValidationStatsByValidation.validation,
      this.headersForValidationStatsByValidation.count,
    ],
    keys: [
      validationStatsProps.validationId,
      validationStatsProps.validation,
      validationStatsProps.count,
    ]
  };
  optionsForHeadersAndKeysForValidationStatsByValidation = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionHeadersAndKeysForValidationStatsByValidation };

  headersForValidationStatsByIndexer = {
    indexerName: 'Indexer Name',
    indexerId: 'Indexer Id',
    count: 'Count'
  };
  optionHeadersAndKeysForValidationStatsByIndexer = {
    headers: [
      this.headersForValidationStatsByIndexer.indexerName,
      this.headersForValidationStatsByIndexer.indexerId,
      this.headersForValidationStatsByIndexer.count,
    ],
    keys: [
      validationStatsProps.indexerName,
      validationStatsProps.indexerId,
      validationStatsProps.count,
    ]
  };
  optionsForHeadersAndKeysForValidationStatsByIndexer = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionHeadersAndKeysForValidationStatsByIndexer };
  filterByValidationControl = new FormControl();
  filterByValidationIdControl = new FormControl();
  filterByIndexNameControl = new FormControl();
  filterByIndexerIdControl = new FormControl();
  filterByCountControl = new FormControl();
  filterByIndexerCountControl = new FormControl();

  private expandTooltip: string = 'Expand';
  private hideTooltip: string = 'Hide';
  private csvByIndexer = 'ByIndexer.csv';
  private csvByValidationId = 'ByValidationId.csv';

  constructor(injector: Injector, private dateTimeService: DateTimeService, private loadingService: LoadingService,
    private merchantValidationStatsService: MerchantValidationStatsService, private valueCheckerService: ValueCheckerService,
    private csvComponentService: CsvComponentService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      selectedDate: DateRangeOption.Yesterday,
      customStartDate: null,
      customStartTime: null,
      customEndDate: null,
      customEndTime: null,
      startDate: emptyString,
      endDate: emptyString,
      groupBy: MerchantValidationStatsGroupBy.ValidationId,
      skip: 0,
    };
    this.restoreState();
    this.setValidationStats();
    this.setFilterForValidationStats();
    this.setFilterForIndexerStats();
  }

  isStartDateIncorrect() {
    return this.dateTimeService.isStartLaterThanEnd(
      this.state.customStartDate,
      this.state.customEndDate,
      this.state.customStartTime,
      this.state.customEndTime);
  }

  setStartEndDate() {
    switch (this.state.selectedDate) {
      case DateRangeOption.Today:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().add(1, 'days').startOf('day'));
        break;
      case DateRangeOption.Yesterday:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().add(-1, 'days').startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().startOf('day'));
        break;
      case DateRangeOption.ThisWeek:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().startOf('isoWeek'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().endOf('isoWeek').add(1, 'days'));
        break;
      case DateRangeOption.LastWeek:
        this.state.startDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(-6).startOf('day'));
        this.state.endDate = this.dateTimeService.formatWith24Hours(moment().isoWeekday(0).add(1, 'days').endOf('day'));
        break;
      case DateRangeOption.Custom:
        if (this.state.customStartDate && this.state.customEndDate) {

        if (this.state.customStartTime === this.dateTimeService.twentyFourHours) {
        this.state.customStartTime = this.dateTimeService.zeroTime;
        }
        if (this.state.customEndTime === this.dateTimeService.twentyFourHours) {
        this.state.customEndTime = this.dateTimeService.zeroTime;
        }

        this.state.customStartDate = this.dateTimeService.setTime(this.state.customStartDate , this.state.customStartTime);
        this.state.customEndDate = this.dateTimeService.setTime(this.state.customEndDate , this.state.customEndTime);

        this.state.startDate = this.dateTimeService.formatWith24Hours(this.state.customStartDate);
        this.state.endDate = this.dateTimeService.formatWith24Hours(this.state.customEndDate);
        } else {
          this.state.customStartDate = moment().add(-1, 'days');
          this.state.customEndDate = moment().endOf('day');
          this.state.customStartTime = this.dateTimeService.twentyFourHours;
          this.state.customEndTime = this.dateTimeService.twentyFourHours;
        }
        break;
    }
  }

  setValidationStats() {
    if (this.state.groupBy === MerchantValidationStatsGroupBy.ValidationId) {
      this.setValidationStatsByValidation();
      this.searchValidationStatsDetailByIndexer = false;
    }
    else {
      this.setValidationStatsByIndexer();
      this.searchValidationStatsDetailByIndexer = true;
    }
  }

  numberOfPageTableChanged(pageInfo: PageInfo) {
    this.offset = pageInfo.offset;
    this.state.skip = pageInfo.offset * 50;
    this.setValidationStats();
  }

  setValidationStatsDetail(row: ValidationStats) {
    if (row.tooltipValidationStatsDetail === this.expandTooltip) {
      this.loadingService.setLoading();
      this.merchantValidationStatsService.getValidationStatsDetail(
        this.searchValidationStatsDetailByIndexer ? row.indexerName : row.validationId.toString(),
        this.dateTimeService.formatWith24Hours(moment(this.state.startDate)),
        this.dateTimeService.formatWith24Hours(moment(this.state.endDate)),
        this.searchValidationStatsDetailByIndexer
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.loadingService.clearLoading();

          if (response && response.data && response.data.items.length > 0) {
              this.validationStatsTable.rowDetail.collapseAllRows();
              this.validationStatsTable.rowDetail.toggleExpandRow(row);
              for(let t of this.validationStats) {
                t.tooltipValidationStatsDetail = this.expandTooltip;
              }
              row.tooltipValidationStatsDetail = this.hideTooltip;

            this.validationStatsTableHeight = response.data.items.length > 10
              ? 580
              : 180 + response.data.items.length * 40;

            if (response.data.totalCount > 50) {
              this.validationStatsTableHeight += 46;
            }

            this.validationStatsDetailResponse = response.data;

            this.setDisplayValues(this.validationStatsDetailResponse.items);

          }
        });
    }
    else {
        row.tooltipValidationStatsDetail = this.expandTooltip;
        this.validationStatsTable.rowDetail.toggleExpandRow(row);
    }
  }

  exportDetailsToExcelButtonClicked() {
    this.loadingService.setLoading();
    this.setStartEndDate();
    this.setValidationStatsDetailData();
  }

  exportSummaryToExcel() {
    let dataForExport: ValidationStats[] = [];
    this.setStartEndDate();
    if (this.searchValidationStatsDetailByIndexer) {
      this.loadingService.setLoading();
      this.getValidationStatsByIndexer(0, this.count)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.loadingService.clearLoading();
          if (response && response.data && response.data.items.length > 0) {
            dataForExport = response.data.items;
            this.setCsvExportForSummary(dataForExport);
          }
        });
    }
    else {
      this.loadingService.setLoading();
      this.getValidationStatsByValidation(0, this.count)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.loadingService.clearLoading();
          if (response && response.data && response.data.items.length > 0) {
            dataForExport = response.data.items;
            dataForExport = dataForExport.sort((a, b) => {
              return a.validation > b.validation ? -1 : 1;
            });
            this.setCsvExportForSummary(dataForExport);
          }
        });
    }
  }

  private setValidationStatsByValidation() {
    this.setStartEndDate();
    this.loadingService.setLoading();
    this.getValidationStatsByValidation(this.state.skip)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items.length > 0) {
          this.validationStats = response.data.items;
          this.count = response.data.totalCount;

          for (let t of this.validationStats) {
            t.tooltipValidationStatsDetail = this.expandTooltip;
          }
          this.validationStats = response.data.items.sort((a, b) => {
            return a.validation > b.validation ? -1 : 1;
          });
        }
        else {
          this.validationStats = [];
          this.count = 0;
        }
      });
  }

  private setValidationStatsByIndexer() {
    this.setStartEndDate();
    this.loadingService.setLoading();
    this.getValidationStatsByIndexer(this.state.skip)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items.length > 0) {
          this.validationStats = response.data.items;
          this.count = response.data.totalCount;

          for (let t of this.validationStats) {
            t.tooltipValidationStatsDetail = this.expandTooltip;
          }
        }
        else {
          this.validationStats = [];
          this.count = 0;
        }
      });
  }

  private setDisplayValues(validationStatsDetail: ValidationStatsDetailDisplay[]) {
    for (let validationStats of validationStatsDetail) {
      validationStats.createdDateDisplay = this.dateTimeService.formatYYYYMMDD(validationStats.createdDate);
      validationStats.updatedDateDisplay = this.dateTimeService.formatYYYYMMDD(validationStats.updatedDate);
    }
  }

  private setCsvExportForSummary(data: ValidationStats[]) {
    this.csvExportSummaryToExcel.data = data;
    this.csvExportSummaryToExcel.options = this.searchValidationStatsDetailByIndexer
      ? this.optionsForHeadersAndKeysForValidationStatsByIndexer
      : this.optionsForHeadersAndKeysForValidationStatsByValidation;

    this.csvExportSummaryToExcel.filename = `Summary${this.searchValidationStatsDetailByIndexer ? this.csvByIndexer : this.csvByValidationId}`;
    this.csvExportSummaryToExcel.onDownload();
  }

  private getValidationStatsByIndexer(skip: number, take: number = 50) {
    return this.merchantValidationStatsService.getValidationStatsByIndexer(
      this.dateTimeService.formatWith24Hours(moment(this.state.startDate)),
      this.dateTimeService.formatWith24Hours(moment(this.state.endDate)),
      skip,
      take,
      this.filterByIndexName,
      this.filterByIndexerId,
      this.filterByIndexerCount
    )
  }

  private getValidationStatsByValidation(skip: number, take: number = 50) {
    return this.merchantValidationStatsService.getValidationStatsByValidation(
      this.dateTimeService.formatWith24Hours(moment(this.state.startDate)),
      this.dateTimeService.formatWith24Hours(moment(this.state.endDate)),
      skip.toString(),
      take.toString(),
      this.filterByValidationId,
      this.filterByValidation,
      this.filterByCount
    )
  }

  private setValidationStatsDetailData() {
    let take: number;
    if(this.totalCountOfMerchantValidationDetails > 0 &&
       this.totalCountOfMerchantValidationDetails - this.dataForValidationStatsDetail.length < 50000) {
      take = this.totalCountOfMerchantValidationDetails - this.dataForValidationStatsDetail.length;
    }
    else {
      take = 50000;
    }

    this.merchantValidationStatsService.getAllValidationStatsDetail(
      this.dateTimeService.formatWith24Hours(moment(this.state.startDate)),
      this.dateTimeService.formatWith24Hours(moment(this.state.endDate)),
      this.dataForValidationStatsDetail.length,
      take,
      this.filterByValidationId,
      this.filterByValidation,
      this.filterByIndexName
      )
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items.length > 0) {
          this.dataForValidationStatsDetail = this.dataForValidationStatsDetail.concat(response.data.items);
          this.totalCountOfMerchantValidationDetails = response.data.totalCount;

          this.setDisplayValues(this.dataForValidationStatsDetail);

          if (this.searchValidationStatsDetailByIndexer) {
            this.dataForValidationStatsDetail = this.dataForValidationStatsDetail.filter(t => t.validationId != null);
          }

          this.exportDetailsToExcel();
        }
      });
  }

  private exportDetailsToExcel() {
    if (this.totalCountOfMerchantValidationDetails != this.dataForValidationStatsDetail.length) {
      this.setValidationStatsDetailData();
    } else {
      this.csvExportDetailsToExcelComponent.data = this.dataForValidationStatsDetail;
      this.csvExportDetailsToExcelComponent.options = this.optionsForHeadersAndKeysForMerchantValidationStatsDetails;
      this.csvExportDetailsToExcelComponent.onDownload();
      this.totalCountOfMerchantValidationDetails = 0;
      this.dataForValidationStatsDetail = [];
    }
  }

  private setFilterForValidationStats() {
    this.filterByValidationControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.valueCheckerService.isNullOrUndefined(this.filterByValidation)) {
          this.setValidationStatsByValidation();
        }
      });

    this.filterByValidationIdControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.valueCheckerService.isNullOrUndefined(this.filterByValidationId)) {
          this.setValidationStatsByValidation();
        }
      });

    this.filterByCountControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.valueCheckerService.isNullOrUndefined(this.filterByCount)) {
            this.setValidationStatsByValidation();
        }
      });
  }

  private setFilterForIndexerStats() {
    this.filterByIndexNameControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.valueCheckerService.isNullOrUndefined(this.filterByIndexName)) {
          this.setValidationStatsByIndexer();
        }
      });

    this.filterByIndexerIdControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.valueCheckerService.isNullOrUndefined(this.filterByIndexerId)) {
          this.setValidationStatsByIndexer();
        }
      });

    this.filterByIndexerCountControl.valueChanges
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.valueCheckerService.isNullOrUndefined(this.filterByIndexerCount)) {
            this.setValidationStatsByIndexer();
        }
      });
  }
}
