import { Component, OnInit, Injector } from '@angular/core';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { ChainDto } from '../../models/ausindexingresult';
import { matchConfidenceNames, chainDensityNames, RouteConstants } from '../../core/constants/constants';
import { cloneDeep } from 'lodash';
import { ManageChainsDataService } from './managechainsdata.service';
import { Router } from '@angular/router';
import { DialogService } from '../../core/uiservices/dialog.service';
import { NotificationService } from '../../core/uiservices/notification.service';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize } from 'rxjs/operators';
import { DeleteChainRequest } from './deletechainrequest';
import { CategoryService } from '../../services/category.service';
import { FullCategoryPath } from '../../categories/fullcategorypath';
import { CategoryMapperService } from '../../categories/categorymapper.service';
import { StringEnumService } from '../../core/uiservices/stringenum.service';
import { ComparerService } from '../../core/services/comparer.service';
import { chainTypeStringValues } from '../../core/constants/chains';
import { ChainType } from '../indexmerchant/chaintype';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { BaseStateComponent } from '../basestate/basestate.component';
import { ManageChainsState } from './managechainsstate';
import { MerchantPresence } from '../../enums/enums';
import { countryCodes } from '../../core/i18n/countrycodes';
import { ManageChainDtoFormatted } from './managechaindtoformatted';
import { FlatCategory } from '../../categories/flatcategory';

@Component({
  selector: 'app-managechains',
  templateUrl: './managechains.component.html',
  styleUrls: ['./managechains.component.scss']
})
export class ManageChainsComponent extends BaseStateComponent<ManageChainsState> implements OnInit {
  isEditingOrCreating = false;
  filteredChains: ManageChainDtoFormatted[] = [];
  chainsFormatted: ManageChainDtoFormatted[] = [];
  matchConfidenceNames = matchConfidenceNames;
  chainDensityNames = chainDensityNames;
  fullPathCategories: FullCategoryPath[];
  chainTypeNameArrayForDropdown = this.manageChainsDataService.getFilteredChainTypeStringValueArray();
  countryCodes = this.manageChainsDataService.moveAustraliaAndNewZealandCountriesToTop(countryCodes);
  categories: FlatCategory[] = [];


  constructor(private merchantLookupService: MerchantlookupService, private manageChainsDataService: ManageChainsDataService,
    private router: Router, private dialogService: DialogService, private notificationService: NotificationService,
    private loadingService: LoadingService, private categoryService: CategoryService,
    private categoryMapperService: CategoryMapperService, private stringEnumService: StringEnumService,
    private comparerService: ComparerService, private valueCheckerService: ValueCheckerService,
    injector: Injector) {
      super(injector)
     }

  ngOnInit() {
    this.state = {
      filterForChainName: '',
      filterForChainType: null,
      filterForCountryCode: ''
    };

    this.loadingService.setLoading();

    this.categoryService.getFlatCategories().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(categories => {
      this.categories = categories;
      this.fullPathCategories = this.categoryMapperService.mapToFullCategoryPath(categories);

      if (this.manageChainsDataService.chains.length == 0) {
        this.loadingService.setLoading();

        this.merchantLookupService.getChains().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(chains => {
          this.setChains(chains);
        });
      } else {
        this.setFormattedChains(this.manageChainsDataService.chains);
      }
    });

    this.restoreState();
    this.filterChains();
  }

  handleClearInput() {
    this.state.filterForChainName = '';
    this.state.filterForChainType = null;
    this.state.filterForCountryCode = '';

    this.filteredChains = this.chainsFormatted;
  }

  edit(chainDto: ChainDto) {
    this.manageChainsDataService.setEditData(cloneDeep(chainDto));
    this.isEditingOrCreating = true;
    this.router.navigate([RouteConstants.editChain]);
  }

  deleteRecord(chainDto: ChainDto) {
    const dialogRef = this.dialogService.openDeleteConfirmDialog();
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let deleteChainRequest: DeleteChainRequest = {
          OperationTrigger: 'IndexManagerClient',
          ChainId: chainDto.ChainId
        }
        this.loadingService.setLoading();
        this.merchantLookupService.deleteChain(deleteChainRequest).pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(() => {
            this.manageChainsDataService.chains.splice(this.manageChainsDataService.chains.indexOf(chainDto), 1);
            this.filterChains();
          })
      }
    });
  }

  createNewRecord() {
    let newRecord: ChainDto = {
      AcceptedMCCs: [],
      ChainDensity: 0,
      ChainId: <number>null,
      ChainName: '',
      ChainNameLookupOptions: [],
      ChainType: chainTypeStringValues.get(ChainType.Both),
      DefaultBusinessDescription: '',
      DefaultEmailAddressForChargebacks: '',
      DefaultIsInternetOnlyBusiness: false,
      DefaultUrl: '',
      DistinctNameForEachBranch: false,
      HQ_LWC_ID: <number>null,
      MatchConfidence: 'High',
      OperatesInStates: [],
      CategoryId: <number>null,
      TransactionDescriptionLookupOptions: [],
      MerchantPresence: MerchantPresence.BricksMortar,
      CountryCodeAlpha3: '',
      URLToStoreLocator: '',
      Tags: []
    };
    this.manageChainsDataService.setEditData(newRecord);
    this.isEditingOrCreating = true;
    this.router.navigate([RouteConstants.editChain]);
  }

  refreshChains() {
    this.loadingService.setLoading();
    this.merchantLookupService.refreshChains().pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((chains) => {
        this.setChains(chains);

        this.notificationService.notifySuccess('Chains refreshed');
      }
      )
  }

  getFullPathCategory(categoryId: number) {
    if(!this.fullPathCategories) {
      return '';
    }

    let fullPathCategory = this.fullPathCategories.find(c => c.id === categoryId);

    return fullPathCategory ? fullPathCategory.name : '';
  }

  getStringEnumDisplayValue(value: string | 0) {
    return this.stringEnumService.getDisplayValue(value);
  }

  stringComparator = (propA: string, propB: string) => {
    return this.comparerService.compareString(propA, propB);
  }

  numberComparator = (propA: number, propB: number) => {
    return this.comparerService.compareNumber(propA, propB);
  }

  booleanComparator = (propA: boolean, propB: boolean) => {
    return this.comparerService.compareBoolean(propA, propB);
  }

  filterByChainName(event: KeyboardEvent) {
    this.state.filterForChainName = (<HTMLInputElement>event.target).value.toLowerCase();

    this.filterChains();
  }

  private mapManageChainDtoFormatted(chains: ChainDto[]) {
    let mappedChains: ManageChainDtoFormatted[] = <ManageChainDtoFormatted[]>[...chains];

    for (let chain of mappedChains) {
      let countryCode = countryCodes.find(countryCode =>
        countryCode.alpha3 === chain.CountryCodeAlpha3);

      if (countryCode) {
        chain.countryName = countryCode.name;
      }
      if (chain.CategoryId) {

        let category = this.categories.find(category => {
          return category.CategoryID === chain.CategoryId;
        });

        if (category) {
          chain.categoryName = category.CategoryName;
        }

        chain.fullCategoryPath = this.getFullPathCategory(chain.CategoryId);

      }

    }

    return mappedChains;
  }

  filterChains() {
    this.filteredChains = this.chainsFormatted.filter((chain: ManageChainDtoFormatted) => {
      let isMatch = true;

      if (!this.valueCheckerService.isNullOrUndefined(this.state.filterForChainName)) {
        isMatch = chain.ChainName.toLowerCase().includes(this.state.filterForChainName);
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterForCountryCode)) {
        if (chain.CountryCodeAlpha3) {
          isMatch = chain.CountryCodeAlpha3.toLowerCase() === this.state.filterForCountryCode.toLowerCase();
        } else {
          isMatch = false;
        }
      }

      if (isMatch && this.state.filterForChainType) {
        isMatch = (chain.ChainType as ChainType) === this.state.filterForChainType;
      }

      return isMatch;
    });
  }

  private setChains(chainsDto: ChainDto[]) {
    this.manageChainsDataService.filterOutEmptyOperatesInStatesForChains(chainsDto);

    this.manageChainsDataService.chains = chainsDto
      .filter(chain => !this.manageChainsDataService.chainTypeStringValuesNotToShow.includes(chain.ChainType));

    this.setFormattedChains(this.manageChainsDataService.chains)

    this.filterChains();
  }

  private setFormattedChains(chainsDto: ChainDto[]) {
    this.chainsFormatted = this.mapManageChainDtoFormatted(chainsDto);
    this.filteredChains = this.chainsFormatted;
  }

}
