import { User } from "../models/user";
import { AdalService } from "adal-angular4";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { RoleType } from "../users/userenums";

@Injectable()
export class AuthService {
    constructor(private adalService: AdalService) { }

    getCurrentUser(): User {
        return {
            userId: this.adalService.userInfo.profile.unique_name,
            name: this.adalService.userInfo.profile.name,
            userName: this.adalService.userInfo.userName,
            email: this.adalService.userInfo.profile.email
        };
    }

    login() {
        this.adalService.login();
    }

    addRole(roleName: RoleType) {
       this.adalService.userInfo.profile.roles.push(roleName);
    }

    hasRole(roleName: string): boolean {
        return this.adalService.userInfo.profile.roles.includes(roleName);
    }

    isAuthenticated(): boolean {
        return this.adalService.userInfo.authenticated;
    }

    init() {
        this.adalService.init(environment.adalConfig);
    }

    handleWindowCallback() {
        this.adalService.handleWindowCallback();
    }

    logOut() {
        this.adalService.logOut();
    }

    getToken(): string {
        return this.adalService.userInfo.token;
    }
}