import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { getMissingMerchantsQuery, getUpdateMissingMerchantQuery, hotChocolate } from "../gqlconstants/gqlconstants";
import { MissingMerchantsResponse, UpdateMissingMerchantResponse } from "../components/managemissingmerchants/managemissingmerchants.component";
import { UpdateMissingMerchantVariables } from "../components/managemissingmerchants/managemissingmerchants.models";
import { StringService } from "../core/formatting/string.service";
import { emptyString } from "../core/constants/constants";

@Injectable({
  providedIn: "root"
})
export class ManageMissingMerchantService {
  constructor(private apollo: Apollo, private stringService: StringService) {
  }

  getMissingMerchants(merchantLookupRequestGuid: string, notificationSentUtc: string, notificationId: string, statusFilter: string, endDate: string, startDate: string) {
    let query = statusFilter === "ALL"
    ? getMissingMerchantsQuery.replace('{ status:   {eq:{3} }  } ,', '')
    : getMissingMerchantsQuery;
    let optionalFilters: string = emptyString;

    if (merchantLookupRequestGuid !== emptyString) {
      optionalFilters = optionalFilters + this.stringService.format('{ merchantLookupRequestGuid: {eq: "{0}"} },', merchantLookupRequestGuid);
    }
    if (notificationSentUtc !== emptyString) {
      optionalFilters = optionalFilters + this.stringService.format('{ notificationSentUtc: {eq: "{0}"} },', notificationSentUtc);
    }
    if (notificationId !== emptyString) {
      optionalFilters = optionalFilters + this.stringService.format('{ notificationId: {eq: "{0}"} },', notificationId);
    }
    if (statusFilter !== "ALL") {
      optionalFilters = optionalFilters + this.stringService.format('{ status:   {eq:{0} }  },', statusFilter);
    }
    if (optionalFilters === emptyString) {
      query = query.replace('{0},', '');
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<MissingMerchantsResponse>({
        query: gql`${this.stringService.format(query, optionalFilters, endDate, startDate)}`
      })
      .valueChanges;
  }

  updateMissingMerchant(updateMissingMerchantVariables: UpdateMissingMerchantVariables) {
    return this.apollo
      .use(hotChocolate)
      .mutate<UpdateMissingMerchantResponse>({
        mutation: getUpdateMissingMerchantQuery,
        variables: updateMissingMerchantVariables
      });
  }
}
