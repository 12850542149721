import { emptyString } from './../../core/constants/constants';
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { whiteSpace } from "../../core/constants/constants";
import { CategoryFlatNode, CategoryTreeModel, CategoryTreeConfig } from "./categorytrees.models";
import { ValueCheckerService } from '../../core/services/valuechecker.service';

@Component({
  selector: 'app-categorytrees',
  templateUrl: './categorytrees.component.html',
  styleUrls: ['./categorytrees.component.scss']
})
export class CategoryTrees {
  @Input() categories: CategoryTreeModel[];
  @Input() nameOfTree: string;
  @Input() showIconStatus: boolean = false;
  @Input() config: CategoryTreeConfig = {};
  @Input() expanded: boolean = false;
  @Output() selectedNode = new EventEmitter<CategoryTreeModel>();
  @ViewChild('tree') tree: MatTree<Element>;

  constructor(private valueCheckerService: ValueCheckerService) {}

  private transformer = (node: CategoryTreeModel, level: number) => {
    return {
      expandable: !!node.Children && node.Children.length > 0,
      name: node.CategoryId + whiteSpace + node.CategoryName,
      level: level,
      category: node
    };
  }
  private treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.Children
  );

  treeControl = new FlatTreeControl<CategoryFlatNode>(
    node => node.level, node => node.expandable
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: CategoryFlatNode) => node.expandable;

  ngOnChanges() {
    if (!this.valueCheckerService.isNullOrUndefined(this.categories)) {
      this.dataSource.data = this.categories;
    }
    this.expandIfNeeded(this.expanded);
  }

  ngOnInit() {
    if(!this.config?.getIconTypeFunc) {
      this.showIconStatus = false;
    }
  }

  getTypeOfIcon(node: CategoryFlatNode) {
    return this.config?.getIconTypeFunc(node) ?? emptyString;
  }

  setFilterCategories(event: MatCheckboxChange) {
    if(this.config.filterFunc) {
      this.config.filterFunc(event, this.categories, this.dataSource);
    }
  }

  expandIfNeeded(expand: boolean) {
    if (this.tree && expand) {
      this.tree.treeControl.expandAll();
    }
  }
}
