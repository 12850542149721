import { Injectable } from '@angular/core';
import { ChainDto } from '../../models/ausindexingresult';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { chainTypeStringValues } from '../../core/constants/chains';
import { ChainType } from '../indexmerchant/chaintype';
import { CollectionTransformerService } from '../../core/collections/collectiontransformer.service';
import { countryCodes, CountryCode } from '../../core/i18n/countrycodes';
import { RegionType } from '../../users/userenums';

@Injectable()
export class ManageChainsDataService {
  private editData: ChainDto;
  chains: ChainDto[] = [];
  countryCodes: CountryCode[] = [...countryCodes]

  chainTypeStringValuesNotToShow: Array<string | 0> = [
    chainTypeStringValues.get(ChainType.Unknown),
    chainTypeStringValues.get(ChainType.HeadOffice),
    chainTypeStringValues.get(ChainType.NotAChain),
    chainTypeStringValues.get(ChainType.Product)
  ];

  nzlchainTypeStringValuesToShow: Array<string | 0> = [
    chainTypeStringValues.get(ChainType.MerchantPattern),
    chainTypeStringValues.get(ChainType.Franchise),
    chainTypeStringValues.get(ChainType.Branch),
    chainTypeStringValues.get(ChainType.Both),
    chainTypeStringValues.get(ChainType.BankMessagePattern)
  ];

  constructor(private valueCheckerService: ValueCheckerService,
    private collectionTransformerService: CollectionTransformerService) { }

  getEditData() {
    return this.editData;
  }

  setEditData(chainDto: ChainDto) {
    this.editData = chainDto;
  }

  clearEditData() {
    this.editData = null;
  }

  filterOutEmptyOperatesInStatesForChains(chains: ChainDto[]) {
    for (let chain of chains) {
      this.filterOutEmptyOperatesInStates(chain);
    }
  }

  filterOutEmptyOperatesInStates(chain: ChainDto) {
    if (!this.valueCheckerService.isNullOrUndefined(chain.OperatesInStates)) {
      chain.OperatesInStates = chain.OperatesInStates.filter(state => state !== "");
    }
  }

  getFilteredChainTypeStringValueArray(countryCodeAlpha3?: RegionType) {
    if (countryCodeAlpha3 === RegionType.NZL)
    {
      return this.collectionTransformerService.mapToArray(chainTypeStringValues)
      .filter(chainType => this.nzlchainTypeStringValuesToShow.includes(chainType.value));
    }

    return this.collectionTransformerService.mapToArray(chainTypeStringValues)
      .filter(chainType => !this.chainTypeStringValuesNotToShow.includes(chainType.value));
  }

  moveAustraliaAndNewZealandCountriesToTop(countryCodes: CountryCode[]) {
    let countryNamesToMove = ["Australia", "New Zealand"];

    let countryCodesToUnshift = countryCodes.filter(code => countryNamesToMove.includes(code.name));

    countryCodes = countryCodes.filter(code => {
      return !countryCodesToUnshift.find(codeToUnshift => codeToUnshift.name == code.name);
    });

    countryCodes = countryCodesToUnshift.concat(countryCodes);

    return countryCodes;
  }
}
