import { Injectable } from "@angular/core";
import { ApiClient } from "../core/api/apiclient";
import { ApiService } from "../core/api/api.service";
import { apiPathConstants } from "../core/api/apipathconstants";
import { PastSearchesRequest } from "../components/batchpastsearches/batchpastsearches.request";
import { PastSearchesByBatchResponse } from "../components/batchpastsearches/batchpastsearches.response";

@Injectable({
    providedIn: 'root'
})
export class BatchPastSearchesService {
    constructor(private apiClient: ApiClient, private apiService: ApiService) { }

    getCalsForBatchPastSearches(pastSearchRequest: PastSearchesRequest) {
        return this.apiClient.post<PastSearchesByBatchResponse[]>(this.apiService.buildUrl(apiPathConstants.pastSearchesByBatch), pastSearchRequest);
    }
}
