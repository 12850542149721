import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as momentTimezone from 'moment-timezone';

@Injectable()
export class DateTimeService {
  constructor() { }

  dateFormatWithTime = 'DD/MMM/YYYY hh:mm A';
  dateTimeFormatYYYYMMDDDashes = 'YYYY-MM-DD';
  dateTimeFormatYYYYMMDDhmmaDashes = 'YYYY-MM-DD h:mm a';
  dateTimeFormatYYYYMMDDhmmDashes = 'YYYY-MM-DD HH:mm';
  dateTimeFormatDDMMYYYYDashes = 'DD-MM-YYYY';
  twentyFourHours: string = '24:00';
  zeroTime: string = '00:00';

  format(datetime: string, format: string = null) {
    if(!format){
      format = 'DD/MM/YYYY';
    }

    let d = moment.utc(datetime, 'DD/MM/YYYY', true);
    if (d.isValid()) {
      return d.format(format);
    }

    let d1 = moment.utc(datetime, 'DD-MMM-YY', true);
    if (d1.isValid()) {
      return d1.format(format);
    }

    let d2 = moment.utc(datetime, 'YYYY-MM-DD', true);
    if (d2.isValid()) {
      return d2.format(format);
    }

    let d3 = moment.utc(datetime);
    if (d3.isValid()) {
      return d3.format(format);
    }
    else {
      return '';
    }
  }

  getTodayFormatted(format: string = null) {
    if(!format){
      format = 'DD/MM/YYYY';
    }
    let d = moment.utc();
    if (d.isValid()) {
      return d.format(format);
    } else {
      return '';
    }
  }

  formatWithHours(datetime: string) {
    let d = moment.utc(datetime);
    if (d.isValid()) {
      return d.format(this.dateFormatWithTime);
    } else {
      return datetime;
    }
  }

  formatWithCurrent(datetime: string) {
    let d = moment.utc(datetime);
    if (d.isValid()) {
      return d.format(this.dateFormatWithTime);
    } else {
      return 'Current';
    }
  }

  formatYYYYMMDD(date: string) {
    return this.format(date, 'YYYY-MM-DD');
  }

  formatForODataFilter(date: Moment) {
    return date.format('YYYY-MM-DD');
  }

  formatWith24Hours(date: Moment) {
    return date.format('YYYY-MM-DD HH:mm');
  }

  formatYYYYMMDDHHmmss(date: string) {
    return this.format(date, 'YYYY-MM-DD HH:mm:ss');
  }

  formatForFileName(date: Moment) {
    return date.format('DD-MM-YYYY-hh-mm');
  }

  formatForSydney(datetime: string) {
    let d = momentTimezone.tz(datetime, 'Australia/Sydney');

    if (d.isValid()) {
      return d.format(this.dateFormatWithTime);
    } else {
      return '';
    }
  }

  setTime(date: moment.Moment, timeString: string) {
    let time = timeString.split(':');
    let dateNoTime = moment(date).set({ hours: 0, minutes: 0, second: 0 });

    return moment(dateNoTime).hours(+time[0]).minutes(+time[1]);
  }

  isFirstDateBiggerOrEqual(firstDate: moment.Moment, secondDate: moment.Moment, firstTime: string, secondTime: string) {
    let firstDateTime = this.setTime(firstDate, firstTime);
    let secondDateTime = this.setTime(secondDate, secondTime);
    let startDate = this.formatWith24Hours(firstDateTime);
    let endDate = this.formatWith24Hours(secondDateTime);

    if (moment(startDate).isAfter(endDate) || moment(startDate).isSame(endDate)) {
      return true;
    }
    return false;
  }

  compareDatesAsc(firstDate: string, secondDate: string) {
    if (moment(firstDate).isBefore(secondDate)) {
      return -1;
    }
      return 1;
  }

  getSubtractDate(days: number, format: string) {
    return moment().subtract(days, 'days').format(format);
  }

  isStartLaterThanEnd(customStartDate: Moment, customEndDate: Moment, customStartTime: string, customEndTime: string) {
    if (customStartDate && customEndDate && customStartTime && customEndTime) {
      return this.isFirstDateBiggerOrEqual(customStartDate, customEndDate, customStartTime, customEndTime);
    }
    return false;
  }
}
