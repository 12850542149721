import { BusinessNameModel } from "../../models/businessname";

export let getNamesByAbnAcnResponse: BusinessNameModel[] =  [
  {
    BusinessNameID : 345689257,
    BusinessName: 'Alex',
    ABN: '12345678912',
    ACN: '123456789',
    NameSource: 'ASICBusinessName',
    CurrentStatus: 'Inactive',
    CurrentStatusDate: '1/1/2017 12:00:00 AM',
    CreatedDateTime: '11/Mar/2019 10:20 AM',
    UpdatedDateTime: '11/Mar/2019 10:20 AM',
    NameSourceId: '835060',
    ReformattedBusinessName: 'Test name',
    BusinessNameKeywords: 'Test keywords',
    LwcId: null,
    PrimaryName: ""
  },
  {
    BusinessNameID : 345689256,
    BusinessName: 'Andrew',
    ABN: '65478521463',
    ACN: '357951456',
    NameSource: 'ASICBusinessName',
    CurrentStatus: 'Active',
    CurrentStatusDate: '1/1/2018 12:00:00 AM',
    CreatedDateTime: '11/Mar/2019 10:20 AM',
    UpdatedDateTime: '11/Mar/2019 10:20 AM',
    NameSourceId: '359687',
    ReformattedBusinessName: 'Test name',
    BusinessNameKeywords: 'Test keywords',
    LwcId: 0,
    PrimaryName: ""
  },
  {
    BusinessNameID : 287593621,
    BusinessName: 'Tony',
    ABN: '65478521463',
    ACN: '357951456',
    NameSource: 'ASICBusinessName',
    CurrentStatus: 'Active',
    CurrentStatusDate: '1/1/2018 12:00:00 AM',
    CreatedDateTime: '11/Mar/2019 10:20 AM',
    UpdatedDateTime: '11/Mar/2019 10:20 AM',
    NameSourceId: '359687',
    ReformattedBusinessName: 'Test name',
    BusinessNameKeywords: 'Test keywords',
    LwcId: 1000000008,
    PrimaryName: "Narrabeen Sports & Exercise Medicine Centre"
  }
];
