import { MatchConfidence, LookupOptionType, LookupType } from "../../enums/enums";
import { ChainPatternMatch } from "../../chains/chainpatternmatch";
import { ChainPatternKnownMatch } from "../../chains/chainpatternknownmatch";

export let chainTestResult: ChainPatternMatch[] = [
  {
    ChainId: 35,
    MatchedPattern: {
      SearchString: 'Test string',
      LookupType: LookupType.WholeText,
      MatchConfidence: MatchConfidence.High,
      LastUpdated: "2019-5-20T17:08:21.623"
    },
    MatchConfidence: MatchConfidence.High,
    LookupOptionType: LookupOptionType.TransactionDescriptionLookupOption
  },
  {
    ChainId: 36,
    MatchedPattern: {
      SearchString: 'Some search string',
      LookupType: LookupType.Contains,
      MatchConfidence: MatchConfidence.Low,
      LastUpdated: "2019-5-20T17:08:21.637"
    },
    MatchConfidence: MatchConfidence.Low,
    LookupOptionType: LookupOptionType.ChainNameLookupOption
  },
  {
    ChainId: 37,
    MatchedPattern: {
      SearchString: 'Search string',
      LookupType: LookupType.ContainsAll,
      MatchConfidence: MatchConfidence.Medium,
      LastUpdated: "2019-5-20T17:08:22.9"
    },
    MatchConfidence: MatchConfidence.Medium,
    LookupOptionType: LookupOptionType.TransactionDescriptionLookupOption
  }
]

export let chainPatternKnownMatch: ChainPatternKnownMatch[] = [
  {
    MatchedText: 'Chain Pattern Known first Matched Text',
    ChainId: 35,
    MatchedPattern: {
      SearchString: 'Test string',
      LookupType: LookupType.Custom,
      MatchConfidence: MatchConfidence.High,
      LastUpdated: "2019-5-22T10:40:21.623"
    },
    MatchConfidence: MatchConfidence.High,
    LookupOptionType: LookupOptionType.TransactionDescriptionLookupOption
  },
  {
    MatchedText: 'Chain Pattern Known second Matched Text',
    ChainId: 36,
    MatchedPattern: {
      SearchString: 'Some search string',
      LookupType: LookupType.ContainsAll,
      MatchConfidence: MatchConfidence.Medium,
      LastUpdated: "2019-5-22T10:40:21.637"
    },
    MatchConfidence: MatchConfidence.Medium,
    LookupOptionType: LookupOptionType.ChainNameLookupOption
  },
  {
    MatchedText: 'Chain Pattern Known third Matched Text',
    ChainId: 37,
    MatchedPattern: {
      SearchString: 'Search string',
      LookupType: LookupType.StartsWithEndsWith,
      MatchConfidence: MatchConfidence.Low,
      LastUpdated: "2019-5-22T10:40:21.9"
    },
    MatchConfidence: MatchConfidence.Low,
    LookupOptionType: LookupOptionType.TransactionDescriptionLookupOption
  }
]
