import { Injectable } from '@angular/core';

@Injectable()
export class CollectionTransformerService {
  mapToArray<T1, T2>(mapForConverting: Map<T1, T2>) {
    let array: Array<{ key: T1, value: T2}> = [];

    mapForConverting.forEach((value, key) => {
      array.push({ key: key, value: value });
    });

    return array;
  }
}
