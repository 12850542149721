import { Observable } from "rxjs";

let counter = 0;

export class ErrorResponseGeneratorService {
    public sendErrorResponse<T>(responseToSend$: Observable<T>) {
      counter ++;
      
      if (counter % 2) {
        return new Observable<T>(resp => {
          resp.error();
    
          resp.complete();
        });
      }
    
      return responseToSend$;
    }
  }