import { KeyValue } from './../../models/whoisresponse';
import { TypeOfCAL, ODataFilterType, CALStatuses, roleNames } from '../../enums/enums';
import { CAL, CALsColumnsFormatted, calsColumnsFormattedProps, calsProps } from '../../models/CAL';
import { debounceTime, distinctUntilChanged, finalize, takeUntil, startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Injector, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { BaseStateComponent } from '../basestate/basestate.component';
import { CardAcceptorLocatorState } from './cardacceptorlocatorstate';
import { LoadingService } from '../../core/uiservices/loading.service';
import { calStatusesType, equalsStartsContainsFilterTypes, emptyString, equalsStartsContainsFilterTypesGraphQLValues, RouteConstants, limitOnTheNumberOfExportedRecords } from '../../core/constants/constants';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { CardAcceptorLocatorService } from '../../services/cardacceptorlocator.service';
import { PageInfo } from '../../shared/models/pageinfo';
import { NameValue } from '../../core/models/namevalue';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../users/applicationuser.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { ApolloQueryResult } from '@apollo/client/core';
import { NotificationService } from '../../core/uiservices/notification.service';

export type CalItemsResponse = {
  items: CAL[];
  totalCount: number;
};

export type AllocateItemsToIndexerResponse = {
  items: CAL[];
};

export type NumberOfSearchesResponse = {
  items: [{ numberOfSearches: number }];
}

@Component({
  selector: 'app-cardacceptorlocator',
  templateUrl: './cardacceptorlocator.component.html',
  styleUrls: ['./cardacceptorlocator.component.scss']
})
export class CardAcceptorLocatorComponent extends BaseStateComponent<CardAcceptorLocatorState> implements OnInit {
  indexerCtrl = new FormControl();
  cardAcceptorLocatorCtrl = new FormControl();
  adminIndexerCtrl = new FormControl();
  pageIndex = 0;
  pageSize = 50;
  skip: number = 0;
  totalElements = 0;
  rows: CALsColumnsFormatted[] = [];
  calStatusesArray: NameValue<CALStatuses>[] = [];
  calFilterTypes: NameValue<ODataFilterType>[] = [];
  calStatusesMap = calStatusesType;
  calTypesArray: NameValue<TypeOfCAL>[] = [];
  calTypesMap: NameValue<TypeOfCAL>[] = [
    {value: TypeOfCAL.Unknown, name: 'Unknown'},
    {value: TypeOfCAL.SuspectedInternationalCAL, name: 'Suspected International CAL'},
    {value: TypeOfCAL.ChainWithCALData, name: 'Chain With CAL Data'},
    {value: TypeOfCAL.ChainNoCALData, name: 'Chain No CAL Data'},
    {value: TypeOfCAL.NonChainCALData, name: 'Non Chain CAL Data'},
    {value: TypeOfCAL.NonChainNoCALData, name: 'Non Chain No CAL Data'}
  ];
  @ViewChild('calStatusFilter') calStatusFilter: MatSelect;
  @ViewChild('calTypeFilter') calTypeFilter: MatSelect;
  @ViewChild('csvExport') csvExportComponent:  CsvExportComponent;
  headers = {
    Id_No: 'ID_no',
    TransactionDescription: 'CAL',
    CurrentStatusID: 'CAL Status',
    NumberOfSearches: 'Searches',
    CALTypeText: 'CAL Type',
    IndexerName: 'Indexer',
    Chain: 'Chain',
    Link: 'Search',
    LwcId: 'LWC ID',
    CreateDateTimeFormatted: 'Listed',
    CurrentStatusDateTimeFormatted: 'Updated'
  };
  optionHeadersAndKeys = {
    headers: [this.headers.Id_No, this.headers.TransactionDescription, this.headers.CurrentStatusID, this.headers.NumberOfSearches,
      this.headers.CALTypeText, this.headers.CreateDateTimeFormatted, this.headers.CurrentStatusDateTimeFormatted,
      this.headers.IndexerName, this.headers.Chain, this.headers.LwcId, this.headers.Link],
      keys: [calsProps.Id_no, calsProps.TransactionDescription, calsColumnsFormattedProps.CurrentStatusText,
      calsProps.NumberOfSearches, calsColumnsFormattedProps.CALTypeText, calsColumnsFormattedProps.CreateDateTimeFormatted,
      calsColumnsFormattedProps.CurrentStatusDateTimeFormatted, calsColumnsFormattedProps.IndexerName,
      calsProps.Chain, calsProps.LWC_ID, calsProps.Link]
  };
  options = {...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.optionHeadersAndKeys};
  filteredIndexerNames: string[] = [];
  filteredIndexerNamesForAdmin: string[] = [];
  isAdminOrTeamLead: boolean = false;
  indexIdAssignedByAdmin: string;
  maxNumberOfSearches: number = 5000;

  private currentUserId: string;
  private originalListOfIndexerNames: string[] = [];
  private listOfFullNamesAndIndexs: KeyValue[] = [];
  private minimumNumberOfSearch: number = 100;

  constructor(private dateTimeService: DateTimeService, private loadingService: LoadingService, injector: Injector,
    private csvComponentService: CsvComponentService, private cardAcceptorLocatorService: CardAcceptorLocatorService,
    private valueCheckerService: ValueCheckerService, private router: Router, private authService: AuthService, private userService: UserService,
    private notificationService: NotificationService, private cdRef:ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      calStatus: [CALStatuses.Unallocated],
      calType: [],
      indexer: emptyString,
      calFilterType: ODataFilterType.StartsWith,
      cal: emptyString,
      startCreateDateTime: null,
      endCreateDateTime: null,
      numberOfSearches: [0, 5000],
      indexerAssignedByAdmin: emptyString
    };

    this.restoreState();
    this.setNumberOfSearches();
    this.setCurrentUserId();
    this.setAdminRoleIfNeeded();
    this.setApplicationUsers();

    this.calTypesArray = this.calTypesMap;
    this.calStatusesArray = this.calStatusesMap;
    this.calFilterTypes = equalsStartsContainsFilterTypes;
  }

  ngAfterViewInit() {
    this.indexerCtrl.setValue(this.state.indexer);
    this.cardAcceptorLocatorCtrl.setValue(this.state.cal);
    this.configureDebouncedValueChanges();
    this.cdRef.detectChanges();
  }

  refreshNumberOfSearchesSlider() {
    this.state.numberOfSearches = [...this.state.numberOfSearches];
    this.setCALs();
  }

  configureDebouncedValueChanges() {
    this.indexerCtrl.valueChanges
      .pipe(
        startWith(null),
        map((indexerName: string) => indexerName
          ? this.getFilteredIndexerNamesAndSetIndexer(indexerName, false)
          : this.originalListOfIndexerNames.map(t => t))
      )
      .subscribe((value) => {
        if (this.filteredIndexerNames.length === this.originalListOfIndexerNames.length && this.state.indexer === emptyString) {
          return;
        }
        this.filteredIndexerNames = value;

        if (this.filteredIndexerNames.length === 1 && this.filteredIndexerNames[0] === this.state.indexer || this.state.indexer === emptyString) {
          this.setCALs();
        }
      });

      this.cardAcceptorLocatorCtrl.valueChanges
        .pipe(
          debounceTime(1200),
          distinctUntilChanged()
        ).subscribe(() => {
          this.setCALs();
        });

    this.adminIndexerCtrl.valueChanges
      .pipe(
        startWith(null),
        map((indexerName: string) => indexerName
          ? this.getFilteredIndexerNamesAndSetIndexer(indexerName, true)
          : this.originalListOfIndexerNames.map(t => t))
      )
      .subscribe((value) => {
        this.filteredIndexerNamesForAdmin = value;
        if(this.filteredIndexerNamesForAdmin.length === 1 && this.filteredIndexerNamesForAdmin[0] === this.state.indexerAssignedByAdmin) {
          let fullNameAndIndex =  this.listOfFullNamesAndIndexs.find(t => t.key === this.state.indexerAssignedByAdmin);
          this.indexIdAssignedByAdmin = fullNameAndIndex !== undefined ? fullNameAndIndex.value.toString() : null;
        }
      });
  }

  setAllocateCALsToIndexer(indexIdAssignedByAdmin: number = null) {
    let calIds: number[] = this.rows.map(t => t.iD_no);
    let indexId: string = indexIdAssignedByAdmin !== null ? indexIdAssignedByAdmin.toString() : this.currentUserId;

    if (this.valueCheckerService.isEmptyNullOrUndefined(indexId)) {
      return;
    }
    this.cardAcceptorLocatorService.getAllocateCalsToIndexer(indexId, calIds)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadingService.clearLoading();
        this.setCALs();
      });
  }

  setCALs() {
    if (this.calStatusFilter) {
      this.calStatusFilter.close();
    }
    if (this.calTypeFilter) {
      this.calTypeFilter.close();
    }
    this.getCALs()
      .subscribe(response => {
        this.loadingService.clearLoading();
        this.setRowsAndTotalElements(response);
      });
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    if (this.pageIndex === pageInfo.offset) {
      return;
    }
    this.pageIndex = pageInfo.offset;
    this.skip = pageInfo ? this.pageIndex * pageInfo.pageSize : this.skip;
    this.setCALs();
  }

  navigateToGroupOfSimilarCals(transactionDescription: string) {
    this.router.navigate([RouteConstants.groupOfSimilarCals, {transactionDescription: transactionDescription}]);
  }

  prefixFilterChanged() {
    if (this.valueCheckerService.isEmptyNullOrUndefined(this.state.cal)) {
      return;
    }
    else {
      this.setCALs();
    }
  }

  isAssignToButtonDisabled() {
    return !(this.filteredIndexerNamesForAdmin.length === 1 && this.filteredIndexerNamesForAdmin[0] === this.state.indexerAssignedByAdmin);
  }

  downloadCsvFile() {
    if (this.totalElements > 5000) {
      this.notificationService.notifyError(limitOnTheNumberOfExportedRecords);
      return;
    }
    let calsForCsvFile: CALsColumnsFormatted[] = [];
    let skip: number = 0;
    this.getCALs(null, skip, this.totalElements)
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.totalCount) {
          calsForCsvFile = this.formatCALs(response.data.items);
          this.csvExportComponent.data = calsForCsvFile;
          this.csvExportComponent.options = this.options;
          this.csvExportComponent.onDownload();
        }
      });
  }

  private getStartDate() {
    return this.valueCheckerService.isEmptyNullOrUndefined(this.state.startCreateDateTime)
      ? null
      : this.dateTimeService.formatForODataFilter(this.state.startCreateDateTime);
  }

  private getEndDate() {
    return this.valueCheckerService.isEmptyNullOrUndefined(this.state.endCreateDateTime)
      ? null
      : this.dateTimeService.formatForODataFilter(this.state.endCreateDateTime);
  }

  private getPrefix() {
    let filterType: string = equalsStartsContainsFilterTypes.find(t => t.value === this.state.calFilterType).name;
    let prefix: string = equalsStartsContainsFilterTypesGraphQLValues.get(filterType);
    return prefix;
  }

  private getIdIndexer() {
    let row = this.listOfFullNamesAndIndexs.find(t => t.key === this.state.indexer);
    let iD_Indexer: string = row !== undefined ? row.value : undefined;
    return iD_Indexer;
  }

  private setRowsAndTotalElements(response: ApolloQueryResult<CalItemsResponse>) {
    if (response && response.data && response.data.items && response.data.totalCount) {
      this.rows = this.formatCALs(response.data.items);
      this.totalElements = response.data.totalCount;
    }
    else {
      this.rows = [];
      this.totalElements = 0;
    }
  }

  private formatCALs(cals: CAL[]) {
    let calsFormatted = cals as CALsColumnsFormatted[];
    for (let calFormatted of calsFormatted) {
      this.formatCAL(calFormatted);
    }

    return calsFormatted;
  }

  private formatCAL(cal: CALsColumnsFormatted) {
    let calType = this.calTypesMap.find(t => t.value === cal.cALType);
    cal.CALTypeText = calType !== undefined
      ? calType.name
      : emptyString;

    let calStatuses = this.calStatusesMap.find(t => t.value === cal.currentStatusID);
    cal.CurrentStatusText = calStatuses !== undefined
      ? calStatuses.name
      : emptyString;

      cal.CreateDateTimeFormatted = cal.createDateTime
      ? this.dateTimeService.format(cal.createDateTime.toString())
      : null;

      cal.CurrentStatusDateTimeFormatted = cal.currentStatusDateTime
      ? this.dateTimeService.format(cal.currentStatusDateTime.toString())
      : null;
  }

  private getFilteredIndexerNamesAndSetIndexer(indexerName: string, isAdmin: boolean) {
    isAdmin === false ? this.state.indexer = indexerName : this.state.indexerAssignedByAdmin = indexerName;
    return this.originalListOfIndexerNames.filter(t => t.toLowerCase().includes(indexerName.toLowerCase()));
  }

  private setCurrentUserId() {
    let currentUser = this.authService.getCurrentUser();
    this.userService.getApplicationUsersCached()
      .subscribe((response) => {
        if (response) {
          let user = response.find(t => t.EmailId === currentUser.userId);
          if (user !== undefined) {
            this.currentUserId = user.Id.toString();
          }
        }
      });
  }

  private setAdminRoleIfNeeded() {
    this.isAdminOrTeamLead = this.authService.hasRole(roleNames.indexManagerAdmin) || this.authService.hasRole(roleNames.indexManagerTeamLead);
  }

  private resetListsOfIndexerNames() {
    this.listOfFullNamesAndIndexs = [];
    this.filteredIndexerNames = [];
    this.filteredIndexerNamesForAdmin= [];
    this.originalListOfIndexerNames= [];
  }

  private setApplicationUsers() {
    this.resetListsOfIndexerNames();
    this.userService.getApplicationUsersCached().subscribe(users => {
      for (let user of users) {
        if (user.FirstName && user.LastName) {
          let fullName = user.FirstName + " " + user.LastName;
          this.listOfFullNamesAndIndexs.push({key: fullName, value: user.Id.toString()});
          this.filteredIndexerNames.push(fullName);
          this.filteredIndexerNamesForAdmin.push(fullName);
          this.originalListOfIndexerNames.push(fullName);
        }
      }
    });
  }

  private getCALs(maxNumberOfSearches?: number[], skip?: number, take?: number) {
    let numberOfSearches: number[] = maxNumberOfSearches ? maxNumberOfSearches : this.state.numberOfSearches;
    let numberOfCalsForSkip: number = skip ? skip : this.skip;
    let totalElements: number = take ? take : this.pageSize;

    return this.cardAcceptorLocatorService.getCALs(this.state.calStatus, this.getIdIndexer(), this.getPrefix(), this.state.cal, numberOfSearches, this.getEndDate(), this.getStartDate(), this.state.calType, numberOfCalsForSkip, totalElements)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$));
  }

  private setNumberOfSearches() {
    this.cardAcceptorLocatorService.getNumberOfSearches(this.state.calStatus[0])
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        this.maxNumberOfSearches = response.data.items[0].numberOfSearches + 1;
        this.state.numberOfSearches[0] = this.minimumNumberOfSearch;
        this.state.numberOfSearches[1] = this.maxNumberOfSearches;
        this.state.numberOfSearches = [...this.state.numberOfSearches];
        let largestNumberOfSearches: number[] = [];
        largestNumberOfSearches.push(this.minimumNumberOfSearch, this.maxNumberOfSearches);

        this.getCALs(largestNumberOfSearches)
          .subscribe(response => {
            this.loadingService.clearLoading();
            this.setRowsAndTotalElements(response);
          });
      });
  }
}
