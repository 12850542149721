import { apiPathConstants } from './../core/api/apipathconstants';
import { Injectable } from "@angular/core";
import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})

export class BsbService {
  bsbValidFormat: RegExp = /(\d{3})-(\d{3})/;

  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  getBsbs(): Observable<string[]> {
    return this.apiClient.get<string[]>(this.apiService.buildUrl(apiPathConstants.bsb));
  }

  isValidValueForBsb(value: string) {
    if (this.bsbValidFormat.test(value) && value.length === 7) {
      return true;
    }
    else {
      return false;
    }
  }
}
