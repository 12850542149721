import { UpdateRetailerCenterWithLocationId } from '../../components/managewestfieldlocation/managewestfieldlocation.models';
import { ExecutionResult } from "graphql";
import { WestfieldLocationsResponse } from "../../components/managewestfieldlocation/managewestfieldlocation.component";

export let westfieldLocationsResponse: ExecutionResult<WestfieldLocationsResponse> = {
  data: {
    items: [
      {
        centresTitle: "Warringah Mall",
        centresCountry: "Australia",
        centresId: 19,
        centresSlug: "warringahmall",
        centresPhysicalAddress: "Cnr Old Pittwater Road and Condamine Street",
        centresState: "NSW",
        centresSuburb: "Haymarket",
        createDate: "2021-07-28T04:42:31.370+10:00",
        dataSource: "scentregroup",
        closedForNoOfDaysPotentially: 0,
        isActive: true,
        locatedIn: 1,
        modifiedDate: "2021-07-29T00:37:43.610+10:00",
        noOfTryToMatch: 1,
        distance: 1.631806,
        latitude: -33.770355,
        longitude: 151.266104,
        sourceModifiedDate: "2021-07-29T04:31:19.643+10:00",
        modifiedBy: 97,
        comment: "Quarantined as this is an Auto Indexed record"
      },
      {
        centresTitle: "Warringah Mall 1",
        centresCountry: "Canada",
        centresId: 20,
        centresSlug: "warringahmall",
        centresPhysicalAddress: "Cnr Old Pittwater Road and Condamine Street",
        centresState: "NSW",
        centresSuburb: "Brookvale",
        createDate: "2015-07-28T04:42:31.370+10:00",
        dataSource: "scentregroup",
        closedForNoOfDaysPotentially: 2,
        isActive: true,
        locatedIn: null,
        modifiedDate: "2021-07-29T00:37:43.610+10:00",
        noOfTryToMatch: 1,
        distance: 1.631806,
        latitude: -33.770355,
        longitude: 151.266104,
        sourceModifiedDate: "2011-07-29T04:31:19.643+10:00",
        modifiedBy: 3,
        comment: "Success:  10009563 enriched with a score of 100"
      },
      {
        centresTitle: "Warringah Mall 3",
        centresCountry: "Afghanistan",
        centresId: 45,
        centresSlug: "warringahmall",
        centresPhysicalAddress: "Cnr Old Pittwater Road and Condamine Street",
        centresState: "NSW",
        centresSuburb: "Eveleigh",
        createDate: "2015-07-28T04:42:31.370+10:00",
        dataSource: "scentregroup",
        closedForNoOfDaysPotentially: 15,
        isActive: true,
        locatedIn: 6,
        modifiedDate: "2001-07-29T00:37:43.610+10:00",
        noOfTryToMatch: 5,
        distance: 1.981806,
        latitude: -33.785355,
        longitude: 151.66104,
        sourceModifiedDate: "2014-07-29T04:31:19.643+10:00",
        modifiedBy: 1,
        comment: "Warning: 7895959 enriched with a score of 78 in 3s"
      },
      {
        centresTitle: "Warringah Mall 4",
        centresCountry: "Andorra",
        centresId: 78,
        centresSlug: "warringahmall",
        centresPhysicalAddress: "Cnr Old Pittwater Road and Condamine Street",
        centresState: "NSW",
        centresSuburb: "Chippendale",
        createDate: "2012-07-28T04:42:31.370+10:00",
        dataSource: "scentregroup",
        closedForNoOfDaysPotentially: 15,
        isActive: false,
        locatedIn: null,
        modifiedDate: "2001-07-29T00:37:43.610+10:00",
        noOfTryToMatch: 8,
        distance: 8.981806,
        latitude: 10.785355,
        longitude: 50.66104,
        sourceModifiedDate: "2002-07-29T04:31:19.643+10:00",
        modifiedBy: 2,
        comment: "Default category set to Food/Drink >> Other"
      }
    ],
    totalCount: 500
  }
};

export let updatedRetailerCenterWithLocationIdResponse: ExecutionResult<UpdateRetailerCenterWithLocationId> = {
  data: {
      centresId: 61,
      comment: null,
      locatedIn: 60
  }
};

export let retailerCenterBasedOnCenterId: ExecutionResult<WestfieldLocationsResponse> = {
  data: {
    items: [
      {
        centresTitle: "Warringah Mall",
        centresCountry: "Australia",
        centresId: 19,
        centresSlug: "warringahmall",
        centresPhysicalAddress: "Cnr Old Pittwater Road and Condamine Street",
        centresState: "NSW",
        centresSuburb: "Brookvale",
        createDate: "2021-07-28T04:42:31.370+10:00",
        dataSource: "scentregroup",
        closedForNoOfDaysPotentially: 0,
        isActive: true,
        locatedIn: 833,
        modifiedBy: 0,
        postCode: "2100",
        modifiedDate: "2021-08-11T00:51:42.597+10:00",
        noOfTryToMatch: 1,
        distance: 1.631806,
        latitude: -33.770355,
        longitude: 151.266104,
        sourceModifiedDate: "2021-08-16T01:11:16.297+10:00",
        status: "NEW"
      }
    ],
    totalCount: 1
  }
};
