import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { BaseStateComponent } from '../basestate/basestate.component';
import { MoneyTrackerSearchState } from './moneytrackersearchstate';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { v4 as uuidv4 } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import { MoneyTrackerSearchService } from '../../services/moneytrackersearch.service';
import {
  MoneyTrackerV4BankTransaction,
  MoneyTrackerV4ResponseInternal,
} from './moneytrackersearchdisplayresult';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { NameValue } from '../../core/models/namevalue';
import { chartDefaultColorScheme } from '../../core/constants/constants';
import { ConcurrentRequest } from '../batchmerchantsearch/merchantbatchsearchrequest';
import {
  BatchRequestService,
  BatchRequestStatus,
  BatchRequestConfig,
} from '../../services/batchsearchrequest.service';
import { BatchSearchResult } from '../batchmerchantsearch/batchsearchresult';
import * as moment from 'moment';
import { DialogService } from '../../core/uiservices/dialog.service';
import { cloneDeep } from 'lodash';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-moneytrackersearch',
  templateUrl: './moneytrackersearch.component.html',
  styleUrls: ['./moneytrackersearch.component.scss'],
})
export class MoneyTrackerSearchComponent
  extends BaseStateComponent<MoneyTrackerSearchState>
  implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  headers = {
    CustomerId: 'Customer Id',
    AccountId: "Account Id",
    DateTime: 'Date',
    Recurrence: 'Recurrence',
    TransactionDescription: 'Transaction description',
    Amount: 'Amount',
    LwcId: 'Lwc ID',
    CategoryId: 'Category ID',
    ResultCode: 'Result Code',
    LwcGuid: 'Lwc GUID',
    ChainName: 'Chain name',
    CategoryFullPath: 'Category full path',
    MerchantName: 'Merchant name',
    MerchantCategory: 'Merchant category',
    TransactionCorrelationGuid: "Transaction Correlation Guid"
  };
  optionHeadersAndKeys = {
    headers: [this.headers.CustomerId, this.headers.AccountId , this.headers.DateTime,  this.headers.Recurrence,
      this.headers.TransactionDescription, this.headers.Amount, this.headers.LwcId, this.headers.CategoryId,
      this.headers.ResultCode, this.headers.LwcGuid, this.headers.ChainName, this.headers.CategoryFullPath,
      this.headers.MerchantName, this.headers.MerchantCategory, this.headers.TransactionCorrelationGuid],
      keys: ["customer_correlation_id", "account_correlation_id", "date_time", "recurrence_frequency",
      "transaction_desc", "amount", "lwc_id", "category_id", "result_code", "lwc_guid", "chain_name",
      "category_full_path", "merchant_name", "merchant_category", "transaction_correlation_guid"]
  };
  chartTransactionResultsData: NameValue<number>[] = [];

  chartRowHeight = 10;
  chartViewHeight = 0;
  chartViewWidth = 700;

  colorScheme = {
    domain: chartDefaultColorScheme,
  };
  private batchSizeProvider = (r: MoneyTrackerV4BankTransaction[]) => r.length;
  failedRequests: ConcurrentRequest<MoneyTrackerV4BankTransaction[]>[] = [];
  batchRequestStatus: BatchRequestStatus<MoneyTrackerV4ResponseInternal> = {
    areMerchantsLoading: false,
    searchInProgressCount: 0,
    searchCompletedCount: 0,
    searchFailedCount: 0,
    batchSearhResults: [],
  };
  searchWasPerformed: boolean = false;
  recordUpdated: boolean = false;
  indexPosition: number = 0;
  indexValue:string='';
  
  constructor(
    injector: Injector,
    private moneyTrackerSearchService: MoneyTrackerSearchService,
    private dateTimeService: DateTimeService,
    private csvComponentService: CsvComponentService,
    private batchRequestService: BatchRequestService,
    private dialogService: DialogService,
    private valueCheckerService: ValueCheckerService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      addRowsText: '',
      displayResults: [],
      moneyTrackerSearchEnrichModels: [],
    };

    this.restoreState();
  }

  onChooseFile(files: FileList): void {
    let file = files[0];
    if (!file) {
      return;
    }
    let fr = new FileReader();
    fr.onload = (_event) => {
      let arrayOfStrings = fr.result.toString().split('\n');
      if (arrayOfStrings.length > 5000) {
        arrayOfStrings = arrayOfStrings.slice(0, 5001);
      }
      let models = this.getEnrichModelsFromText(arrayOfStrings, true);
      this.addModelsToDatatable(models);
    };
    fr.readAsText(file);
  }

  addRows() {
    if (this.state.addRowsText == "") {
      this.addModelsToDatatable(
        this.getEmptyMoneyTrackerSearchEnrichModelInArray()
      );
    } else {
      let lines: string[] = this.state.addRowsText.split('\n');
      let models = this.getEnrichModelsFromText(lines, false);
      this.addModelsToDatatable(models);
    }
    this.state.addRowsText = '';
  }

  generateOptionalData() {
    for (let value of this.state.moneyTrackerSearchEnrichModels) {
      if (!value.transaction_correlation_guid) {
        value.transaction_correlation_guid = uuidv4();
        this.recordUpdated = false;
      }
      if (!value.amount) {
        value.amount = -100;
      }
      if (!value.date_time) {
        value.date_time = this.dateTimeService.getTodayFormatted(
          this.dateTimeService.dateTimeFormatYYYYMMDDDashes
        );
      }
      if (!value.customer_correlation_id) {
        value.customer_correlation_id = 'SampleCustomer';
      }
      if (!value.account_correlation_id) {
        value.account_correlation_id = 'SampleAccount';
      }
    }
    this.findMissingGuid();
  }

  findMissingGuid() {
    for (let value of this.state.moneyTrackerSearchEnrichModels) {
      if (!this.uuidValidAndNotEmpty(value.transaction_correlation_guid)) {
        this.indexPosition =
          this.state.moneyTrackerSearchEnrichModels.indexOf(value);
          this.indexValue = value.transaction_correlation_guid;
          this.recordUpdated = true;
      }
    }
  }

  search() {
    this.searchWasPerformed = true;
    this.state.displayResults = [];
    let moneyTrackerRequests: MoneyTrackerV4BankTransaction[][] =
      this.getEnrichRequestModels();
    let retryRequests: ConcurrentRequest<MoneyTrackerV4BankTransaction[]>[] =
      [];
    let successRequests: ConcurrentRequest<MoneyTrackerV4BankTransaction[]>[] =
      [];
    this.batchRequestStatus.areMerchantsLoading = true;
    this.batchRequestStatus.batchSearhResults = [];
    this.batchRequestStatus.searchInProgressCount = moneyTrackerRequests.length;
    this.batchRequestStatus.searchCompletedCount = 0;
    this.batchRequestStatus.searchFailedCount = 0;
    const config: BatchRequestConfig<
      MoneyTrackerV4BankTransaction[],
      MoneyTrackerV4ResponseInternal
    > = {
      searchBatchRequest: this.moneyTrackerSearchService.enrich,
      searchBatchSize: 200,
      concurrentRequests: 1,
    };
    this.batchRequestService
      .doBatchRequest<
        MoneyTrackerV4BankTransaction[],
        MoneyTrackerV4ResponseInternal
      >(
        moneyTrackerRequests,
        moneyTrackerRequests,
        moneyTrackerRequests,
        retryRequests,
        successRequests,
        this.failedRequests,
        this.batchSizeProvider,
        this.batchRequestStatus,
        config
      )
      .subscribe((batchREquestStatus) => {
        let requestResults = batchREquestStatus.batchSearhResults;
        if (batchREquestStatus.searchInProgressCount === 0) {
          this.handleEnrichResponse(requestResults);
        }
      });
  }

  ifChartShouldDisplay() {
    return this.chartTransactionResultsData.length > 0;
  }

  getChartStyles() {
    return {
      'height': this.chartViewHeight + 'px',
      'margin-bottom': 10 + 'px',
      'margin-left': 10 + 'px'
    };
  }

  sortByAccounts() {
    this.state.displayResults.sort(function (a, b) {
      if (a.customer_correlation_id < b.customer_correlation_id) return -1;
      if (a.customer_correlation_id > b.customer_correlation_id) return 1;

      if (a.account_correlation_id < b.account_correlation_id) return -1;
      if (a.account_correlation_id > b.account_correlation_id) return 1;

      let date1 = moment(a.date_time, 'YYYY-MM-DD');
      let date2 = moment(b.date_time, 'YYYY-MM-DD');

      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
    });
  }

  getCsvOptions() {
    let options = this.csvComponentService.getCsvOptions(
      this.state.displayResults[0],
      'MoneyTrackerResults'
    );

    return { ...options, ...this.optionHeadersAndKeys };
  }

  clearEnrichGrid(selectedCsvFile: any) {
    selectedCsvFile.value = "";
    this.state.moneyTrackerSearchEnrichModels = [];
    this.batchRequestStatus.batchSearhResults = [];
    this.batchRequestStatus.searchInProgressCount = 0;
    this.batchRequestStatus.searchCompletedCount = 0;
    this.batchRequestStatus.searchFailedCount = 0;
    this.searchWasPerformed = false;
  }

  deleteRow(moneyTrackerV4BankTransaction: MoneyTrackerV4BankTransaction) {
    const dialogRef = this.dialogService.openDeleteConfirmDialog();
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.state.moneyTrackerSearchEnrichModels.splice(
          this.state.moneyTrackerSearchEnrichModels.indexOf(
            moneyTrackerV4BankTransaction
          ),
          1
        );
        this.state.moneyTrackerSearchEnrichModels = [
          ...this.state.moneyTrackerSearchEnrichModels,
        ];
      }
    });
  }

  clearResultsGrid() {
    this.state.displayResults = [];
    this.searchWasPerformed = false;
  }

  searchButtonDisabled() {
    for (let value of this.state.moneyTrackerSearchEnrichModels) {
      if (
        this.valueCheckerService.isEmptyNullOrUndefined(
          value.transaction_desc
        ) ||
        this.valueCheckerService.isEmptyNullOrUndefined(value.amount) ||
        this.valueCheckerService.isEmptyNullOrUndefined(value.date_time) ||
        !this.uuidValidAndNotEmpty(value.transaction_correlation_guid)
      ) {
        return true;
      }
    }
    return false;
  }

  uuidValidAndNotEmpty(transaction_Correlation_Guid: string) {
    return (
      transaction_Correlation_Guid !== "" &&
      uuidValidate(transaction_Correlation_Guid)
    );
  }

  private getEnrichRequestModels() {
    let moneyTrackerRequest: MoneyTrackerV4BankTransaction[][] = [];
    const lenghtOfInternalArray = 200;
    for (let value of this.state.moneyTrackerSearchEnrichModels) {
      if (!value.transaction_correlation_guid) {
        value.transaction_correlation_guid = uuidv4();
      }
    }
    let copyOfArray = [...this.state.moneyTrackerSearchEnrichModels];
    while (copyOfArray.length != 0) {
      moneyTrackerRequest.push(copyOfArray.splice(0, lenghtOfInternalArray));
    }
    return moneyTrackerRequest;
  }

  private handleEnrichResponse(
    requestResults: BatchSearchResult<MoneyTrackerV4ResponseInternal>[]
  ) {
    let countOfUpdates: number;
    let copyOfMoneyTrackerSearchEnrichModels: MoneyTrackerV4BankTransaction[] =
      [];
    copyOfMoneyTrackerSearchEnrichModels = cloneDeep(
      this.state.moneyTrackerSearchEnrichModels
    );
    for (let customerTransaction of copyOfMoneyTrackerSearchEnrichModels) {
      for (let requestResult of requestResults) {
        let searchResult = requestResult.response.enriched_transactions.find(
          (t) =>
            t.transaction_correlation_guid.toLowerCase() ===
            customerTransaction.transaction_correlation_guid.toLowerCase()
        );
        if (searchResult) {
          ++countOfUpdates;
          if (searchResult.merchant_lwc_guid) {
            let merchantForThisTransaction =
              requestResult.response.merchant_details.find(
                (m) =>
                  m.lwc_guid.toLowerCase() ==
                  searchResult.merchant_lwc_guid.toLowerCase()
              );
            if (merchantForThisTransaction) {
              customerTransaction.merchant_name =
                merchantForThisTransaction.merchant_primary_name
                  ? merchantForThisTransaction.merchant_primary_name.slice(
                    0,
                    100
                  )
                  : null;
              customerTransaction.lwc_guid = merchantForThisTransaction.lwc_guid
                ? merchantForThisTransaction.lwc_guid
                : null;
              customerTransaction.lwc_id = merchantForThisTransaction.id
                ? merchantForThisTransaction.id
                : null;
              if (merchantForThisTransaction.legal_business_info) {
                customerTransaction.chain_name = merchantForThisTransaction
                  .legal_business_info.chain_name
                  ? merchantForThisTransaction.legal_business_info.chain_name
                  : null;
              }
              if (merchantForThisTransaction.primary_category) {
                customerTransaction.merchant_category =
                  merchantForThisTransaction.primary_category.category_name
                    ? merchantForThisTransaction.primary_category.category_name
                    : null;
              }
            }
          } else if (searchResult.atm_lwc_guid) {
            let merchantForThisTransaction =
              requestResult.response.atm_details.find(
                (m) =>
                  m.lwc_guid.toLowerCase() ==
                  searchResult.atm_lwc_guid.toLowerCase()
              );
            if (merchantForThisTransaction) {
              customerTransaction.merchant_name =
                merchantForThisTransaction.atm_name
                  ? merchantForThisTransaction.atm_name.slice(0, 100)
                  : null;
              customerTransaction.lwc_guid = merchantForThisTransaction.lwc_guid
                ? merchantForThisTransaction.lwc_guid
                : null;
              customerTransaction.lwc_id = merchantForThisTransaction.id
                ? merchantForThisTransaction.id
                : null;
              if (merchantForThisTransaction.primary_category) {
                customerTransaction.merchant_category =
                  merchantForThisTransaction.primary_category.category_name
                    ? merchantForThisTransaction.primary_category.category_name
                    : null;
              }
            }
          } else if (searchResult.bank_fee_lwc_guid) {
            let merchantForThisTransaction =
              requestResult.response.bank_fee_details.find(
                (m) =>
                  m.lwc_guid.toLowerCase() ==
                  searchResult.bank_fee_lwc_guid.toLowerCase()
              );
            if (merchantForThisTransaction) {
              if (merchantForThisTransaction.associated_with) {
                customerTransaction.merchant_name = merchantForThisTransaction
                  .associated_with.associates_name
                  ? merchantForThisTransaction.associated_with.associates_name
                  : null;
              }
              customerTransaction.lwc_guid = merchantForThisTransaction.lwc_guid
                ? merchantForThisTransaction.lwc_guid
                : null;
              customerTransaction.lwc_id =
                merchantForThisTransaction.BankMessage_LWC_ID
                  ? merchantForThisTransaction.BankMessage_LWC_ID
                  : null;
              if (merchantForThisTransaction.primary_category) {
                customerTransaction.merchant_category =
                  merchantForThisTransaction.primary_category.category_name
                    ? merchantForThisTransaction.primary_category.category_name
                    : null;
              }
            }
          }

          let categoryForThisTransaction =
            requestResult.response.category_details.find(
              (c) =>
                c.category_id ==
                searchResult.categorised_transactions_internal[0].category_id
            );
          if (categoryForThisTransaction) {
            customerTransaction.category_id =
              categoryForThisTransaction.category_id;
            customerTransaction.category_full_path =
              categoryForThisTransaction.full_category_path;
          }
          customerTransaction.recurrence_frequency =
            searchResult.recurrence_frequency;
          customerTransaction.result_code = searchResult.result_code;
          this.state.displayResults.push(customerTransaction);
          break;
        }
      }
    }
  }

  private getEnrichModelsFromText(lines: string[], isCsv: boolean = false) {
    if (lines.length === 0) {
      return [];
    }

    let lineColumns: string[][] = [];
    for (let i = 0; i < lines.length; i++) {
      if (!lines[i]) {
        continue;
      }
      lineColumns[i] = lines[i]
        .split(",")
        .map((l) => l.replace(/(\r\n|\n|\r)/gm, ""));
    }
    let transactionDescriptionIndex = isCsv
      ? lineColumns[0].indexOf("Transaction Description")
      : 0;
    let customerIdIndex = isCsv ? lineColumns[0].indexOf("Customer Id") : 1;
    let accountIdIndex = isCsv ? lineColumns[0].indexOf("Account Id") : 2;
    let dateIndex = isCsv ? lineColumns[0].indexOf("Date") : 3;
    let amountIndex = isCsv ? lineColumns[0].indexOf("Amount") : 4;
    let transactionCorrelationIdIndex = isCsv
      ? lineColumns[0].indexOf("TRANSACTION_CORRELATION_GUID")
      : 5;

    let models: MoneyTrackerV4BankTransaction[] = [];

    for (let i = 0; i < lineColumns.length; i++) {
      let enrichModel: MoneyTrackerV4BankTransaction = {};

      if (isCsv && i === 0) {
        continue;
      }

      if (customerIdIndex > -1) {
        enrichModel.customer_correlation_id = lineColumns[i][customerIdIndex];
      }
      if (accountIdIndex > -1) {
        enrichModel.account_correlation_id = lineColumns[i][accountIdIndex];
      }
      if (dateIndex > -1) {
        enrichModel.date_time = this.dateTimeService.formatYYYYMMDD(
          lineColumns[i][dateIndex]
        );
      }
      if (transactionDescriptionIndex > -1) {
        enrichModel.transaction_desc =
          lineColumns[i][transactionDescriptionIndex];
      }
      if (amountIndex > -1) {
        enrichModel.amount = +lineColumns[i][amountIndex];
      }
      if (transactionCorrelationIdIndex > -1) {
        enrichModel.transaction_correlation_guid =
          lineColumns[i][transactionCorrelationIdIndex];
      }
      models.push(enrichModel);
    }

    return models;
  }

  private addModelsToDatatable(models: MoneyTrackerV4BankTransaction[]) {
    for (let i = 0; i < models.length; i++) {
      if (this.state.moneyTrackerSearchEnrichModels.length >= 5000) {
        break;
      }
      this.state.moneyTrackerSearchEnrichModels.push(models[i]);
    }
    this.state.moneyTrackerSearchEnrichModels = [
      ...this.state.moneyTrackerSearchEnrichModels,
    ];
  }

  private getEmptyMoneyTrackerSearchEnrichModelInArray() {
    let array: MoneyTrackerV4BankTransaction[] = [];
    let object: MoneyTrackerV4BankTransaction = {};
    array.push(object);
    return array;
  }
}
