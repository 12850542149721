import { retailerAuditResponse } from './../mockjsonresponses/retaileraudit.response';
import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function retailerAuditBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesAuditCreateDate = false;
  let includesStoresTelephoneNumber = false;
  if (request.body && request.body.query) {
    includesAuditCreateDate = request.body.query.includes('auditCreateDate');
    includesStoresTelephoneNumber = request.body.query.includes('storesTelephoneNumber');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesAuditCreateDate && !includesStoresTelephoneNumber) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...retailerAuditResponse }
      }));
      resp.complete();
    });
  }
}
