import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/uiservices/loading.service';
import { Component, OnInit, Injector, Output, EventEmitter, Input } from '@angular/core';
import { MerchantlookupService } from '../../services/merchantlookup.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { BaseStateComponent } from '../basestate/basestate.component';
import { MerchantLookupState } from './merchantlookupstate';
import { JsonViewerCustomComponent } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewercustomcomponent';
import { JsonViewerIconComponent } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/jsonviewericon/jsonviewericon.component';
import { Router } from '@angular/router';
import { Segment } from '../../modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer/lwc-ngx-json-viewer.component';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { MerchantExtractService } from '../../services/merchantextract.service';
import { MerchantDetailsByChainNameAndShoppingCentreDisplay, ChainNameAndLocatedInId } from '../merchantextract/merchantdetails';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { FormatService } from '../../core/formatting/format.service';
import { MerchantSummaryJsonViewerService } from '../../services/merchantsummaryjsonviewer.service';
import { MerchantSummaryFirstPart, MerchantSummarySecondPart } from '../mergemerchants/merchantsummary';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-merchantlookup',
  templateUrl: './merchantlookup.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./merchantlookup.component.scss']
})
export class MerchantlookupComponent extends BaseStateComponent<MerchantLookupState> implements OnInit {
  @Output() isSummary: EventEmitter<any> = new EventEmitter();

  rbDataSource = [
    { name: 'Database', value: false },
    { name: 'Website', value: true }
  ];
  jsonViewerCustomComponents: JsonViewerCustomComponent[];
  merchantDetailsByChainNameAndShoppingCentres: MerchantDetailsByChainNameAndShoppingCentreDisplay[] = [];


  data: any
  multiData: boolean;
  summaryCheckBox: boolean;
  isSummaryData: boolean;
  title: boolean;
  openPanel: boolean = true;
  searchByCal: boolean;
  searchByChain: boolean;
  constructor(private merchantLookupService: MerchantlookupService, private loadingService: LoadingService,
    injector: Injector, private router: Router, private valueCheckerService: ValueCheckerService,
    private merchantExtractService: MerchantExtractService, private dateTimeService: DateTimeService,
    private formatService: FormatService, private merchantSummaryJsonViewerService: MerchantSummaryJsonViewerService) {
    super(injector);

    this.data = [];
    this.multiData = false;
    this.summaryCheckBox = false;
    this.isSummaryData = false;
    this.title = false;
    this.searchByCal = false;
    this.searchByChain = false;
  }

  ngOnInit() {
    this.state = {
      cal: '',
      lwcid: null,
      lwcguid: '',
      abn: null,
      selectedlwcsource: false,
      selectedchain: '',
      chainsuburb: '',
      chainid: null,
      merchantResponse: null,
      merchantLookupResponseFirstPart: null,
      merchantLookupResponseSecondPart: null,
      data: null
    };
    this.restoreState();

    this.jsonViewerCustomComponents = [{
      key: 'LWC_ID',
      component: JsonViewerIconComponent,
      componentConfigurator: (c: JsonViewerIconComponent, segment: Segment) => {
        c.tooltip = 'Enrich Merchant';
        c.click.subscribe(() => {
          this.router.navigate(['/enrichmerchant', { lwcId: segment.value, cleanState: true }]);
        });
      },
    }];
  }

  onSearch() {
    this.searchCals(this.state.cal);
  }

  searchCals(cal: string): void {
    this.loadingService.setLoading();
    if (this.summaryCheckBox === false) {
      if (!this.state.selectedlwcsource) {
        let observable = this.merchantLookupService.searchDatabaseByCal(cal);
        observable.pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(w => this.onSearchComplete(w), this.onSearchError);
      } else if (this.state.selectedlwcsource) {
        let observable = this.merchantLookupService.searchByCal(cal);
        observable.pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(w => this.onSearchComplete(w), this.onSearchError);
      }
    } else {
      if (!this.state.selectedlwcsource) {
        this.searchByCal = true;
        let observable = this.merchantLookupService.searchCalByDataBaseDetails(cal);
        observable.pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(w => this.onSearchComplete(w), this.onSearchError);
      } else if (this.state.selectedlwcsource) {
        this.searchByCal = true;
        let observable = this.merchantLookupService.searchCalByWebsite(cal);
        observable.pipe(finalize(() => this.loadingService.clearLoading()))
          .subscribe(w => this.onSearchComplete(w), this.onSearchError);
      }
    }
  }

  calculateNumberOfResults() {
    if (!this.valueCheckerService.isNullOrUndefined(this.state.merchantResponse.length)) {
      return this.state.merchantResponse.length;
    }

    return 1;
  }

  onSearchComplete(event: any) {
    if (this.summaryCheckBox === false) {
      this.title = true;
      this.state.merchantResponse = event;
      this.merchantDetailsByChainNameAndShoppingCentres = [];
    } else {
      this.data = event;
      if (this.searchByChain == true) {
        this.getMerchantSummaryForChain(this.data);
      }
      if (this.searchByCal == true) {
        this.getMerchantSummaryForDatabaseDetails(this.data);
      }
      else {
        if (this.data.length >= 2) {
          this.title = true;
          this.multiData = true;
          this.merchantDetailsByChainNameAndShoppingCentres = [];
          this.state.merchantResponse = this.data;
          this.calculateNumberOfResults();
        } else {
          this.title = true;
          this.multiData = false;
          this.merchantDetailsByChainNameAndShoppingCentres = [];
          this.state.merchantResponse = this.data;
          this.calculateNumberOfResults();
          if (this.data.length >= 1) {
            for (let i of this.data) {
              this.state.merchantLookupResponseFirstPart = this.merchantResponseFirstPart(i);
              this.state.merchantLookupResponseSecondPart = this.merchantResponseSecondPart(i);
            }
          } else {
            this.state.merchantLookupResponseFirstPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(event);
            this.state.merchantLookupResponseSecondPart = this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(event);
          }
        }
      }
    }
  }

  onSearchError = () => {
    this.state.merchantResponse = null;
  }

  searchButtonDisabled(): boolean {
    return (this.state.cal === '');
  }

  setMerchantDetailsByChainNameAndShoppingCentres(data: ChainNameAndLocatedInId) {
    this.loadingService.setLoading();
    this.merchantExtractService.getMerchantDetailsByChainNameAndShoppingCentres(data.chainName, data.locatedIn_ID)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.loadingService.clearLoading();
        if (response && response.length > 0) {
          this.title = false;
          this.state.merchantResponse = null;
          this.merchantDetailsByChainNameAndShoppingCentres = response;
          this.handleMerchantDetailsByChainNameAndShoppingCentres();
        }
        else {
          this.merchantDetailsByChainNameAndShoppingCentres = [];
        }
      });
  }

  private handleMerchantDetailsByChainNameAndShoppingCentres() {
    for (let merchantDetails of this.merchantDetailsByChainNameAndShoppingCentres) {
      merchantDetails.UpdatedDateDisplay = merchantDetails.UpdatedDate
        ? this.dateTimeService.formatWithHours(merchantDetails.UpdatedDate)
        : null;
      merchantDetails.StreetViewAvailableDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchantDetails.StreetViewAvailable);
      merchantDetails.RecordLockedDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchantDetails.RecordLocked);
      merchantDetails.IsQuarantinedDisplay = this.formatService.formatTrueOrFalseToYesOrNo(merchantDetails.IsQuarantined);
    }
  }

  merchantResponseFirstPart(item: MerchantSummaryFirstPart) {
    return this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseFirstPart(item);
  }

  merchantResponseSecondPart(item: MerchantSummarySecondPart) {
    return this.merchantSummaryJsonViewerService.getStateMerchantLookupResponseSecondPart(item);
  }

  checkSummaryChange(value: MatCheckbox) {
    if (value.checked === true) {
      this.summaryCheckBox = true;
    } else {
      this.summaryCheckBox = false;
    }
  }

  onclear(event: any) {
    if (event === true) {
      this.state.merchantResponse = null;
      this.state.merchantLookupResponseFirstPart = null;
    }
  }

  getMerchantSummaryForDatabaseDetails(data: any) {
    this.searchByCal = false;
    let merchant_search_results: any = [];
    let lwcids: number[] = [];
    if (data.merchant_search_results) {
      merchant_search_results.push(data.merchant_search_results);
      for (let i of merchant_search_results[0]) {
        lwcids.push(i.LWC_ID);
      }
    } else {
      lwcids.push(data[0].LWC_ID);
    }
    lwcids = Object.assign({ 'LwcIds': lwcids });
    this.loadingService.setLoading();
    let observable = this.merchantLookupService.searchMerchantSummaryByDatabaseDetailsByLwcids(lwcids);
    observable.pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => this.onSearchComplete(w), this.onSearchError);
  }

  getMerchantSummaryForChain(data: any) {
    let lwcids: number[] = [];
    this.searchByChain = false;
    for (let i of data) {
      lwcids.push(i.LWC_ID);
    }
    lwcids = Object.assign({ 'LwcIds': lwcids });
    this.loadingService.setLoading();
    let observable = this.merchantLookupService.searchMerchantSummaryByDatabaseDetailsByLwcids(lwcids);
    observable.pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(w => this.onSearchComplete(w), this.onSearchError);
  }

  SearchBychain(event: any) {
    this.searchByChain = event;
  }
}
