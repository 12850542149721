import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { whoislookupBackendService } from './mockbackendservices/whoislookup.backend.service';
import { merchantlookupBackendService } from './mockbackendservices/merchantlookup.backend.service';
import { abnAcnLookupBackendService } from './mockbackendservices/abnacnlookup.backend.service';
import { masterCardBackendService } from './mockbackendservices/mastercardlookup.backend.service';
import { purgeMerchantBackendService } from './mockbackendservices/purgemerchant.backend.service';
import { calSplitterBackendService } from './mockbackendservices/calsplitter.backend.service';
import { pastSearchBackendService } from './mockbackendservices/pastsearch.backend.service';
import { namesCatalogueBackendService } from './mockbackendservices/namecatalogue.backend.service';
import { indexMerchantBackendService } from './mockbackendservices/indexmerchant.backend.service';
import { enrichMerchantBackendService } from './mockbackendservices/enrichmerchant.backend.service';
import { applicationUserBackendService } from './mockbackendservices/applicationuser.backend.service';
import { calBackendService } from './mockbackendservices/cal.backend.service';
import { calHistoryBackendService } from './mockbackendservices/calhistory.backend.service';
import { apiInfoBackendService } from './mockbackendservices/apiinfo.backend.service';
import { tidmidBackendService } from './mockbackendservices/tidmid.backend.service';
import { searchBackendService } from './mockbackendservices/search.backend.service';
import { categoryBackendService } from './mockbackendservices/category.backend.service';
import { missedCalsBackendService } from './mockbackendservices/missedcals.backend.service';
import { merchantExtractBackendService } from './mockbackendservices/merchantextract.backend.service';
import { mergeMerchantsBackendService } from './mockbackendservices/mergemerchants.backend.service';
import { merchantEventBackendService } from './mockbackendservices/merchantevents.backend.service';
import { moneyTrackerSearchBackendService } from './mockbackendservices/moneytrackersearch.backend.service';
import { merchantValidationBackendService } from './mockbackendservices/merchantvalidation.backend.service';
import { quarantinedMerchantsBackendService } from './mockbackendservices/quarantinedmerchants.backend.service';
import { quarantinedCalsBackendService } from './mockbackendservices/quarantinedcals.backend.service';
import { apiClientsBackendService } from './mockbackendservices/apiclients.backend.service';
import { suggestionsBackendService } from './mockbackendservices/suggestions.backend.service';
import { searchfeedbackBackendService } from './mockbackendservices/searchfeedback.backend.service';
import { apiClientsRefreshBackendService } from './mockbackendservices/apiclientrefresh.backend.service';
import { redisCacheLookupBackendService } from './mockbackendservices/rediscachelookup.backend.service';
import { merchantChainLogosBackendService } from './mockbackendservices/merchantchainlogos.backend.service';
import { chainAuditBackendService } from './mockbackendservices/chainaudit.backend.service';
import { categoryStandardiserService } from './mockbackendservices/categorystandardiser.backend.service';
import { chainPatternMatcherBackendService } from './mockbackendservices/chainpatternmatcher.backend.service';
import { manageLocationsBackendService } from './mockbackendservices/managelocations.backend.service';
import { manageNamesService } from './mockbackendservices/managenames.backend.service';
import { batchPastSearchesBackendService } from './mockbackendservices/batchpastsearches.backend.service';
import { eventingBackendService } from './mockbackendservices/eventing.backend.service';
import { scrapingDataSourcesService } from './mockbackendservices/scrapingdatasources.backend.service';
import { calParserBackendSerice } from './mockbackendservices/calparser.backend.service';
import { merchantOpeningHours } from './mockbackendservices/merchantopeninghours.backend.service';
import { brokenLinksBackendService } from './mockbackendservices/brokenlinks.backend.service';
import { merchantLogoBackendService } from './mockbackendservices/merchantlogo.backend.service';
import { bulkLogoEditBackendService } from './mockbackendservices/bulklogoedit.backend.service';
import { tagsBackendService } from './mockbackendservices/tags.backend.service';
import { chainStoresBackendService } from './mockbackendservices/chainstores.backend.service';
import { operationalChecksBackendService } from './mockbackendservices/operationalchecks.backend.service';
import { bsbBackendService } from './mockbackendservices/bsb.backend.service';
import { addressVerificationBackendService } from './mockbackendservices/addressverification.backend.service';
import { merchantAuditBackendService } from './mockbackendservices/merchantaudit.backend.service';
import { merchantNameSearchBackendService } from './mockbackendservices/merchantnamesearch.backend.service';
import { categoryMappingRulesService } from './mockbackendservices/categorymappingrules.backend.service';
import { subscriptionsByMerchantBackendService } from './mockbackendservices/subscriptionsbymerchant.backend.service';
import { tagAllocationPatternsBackendService } from './mockbackendservices/tagallocationpatterns.backend.service';
import { batchCalSplitterBackendService } from './mockbackendservices/batchcalsplitter.backend.service';
import { enumValuesBackendService } from './mockbackendservices/enumvalues.backend.service';
import { cardAcceptorLocatorBackendService } from './mockbackendservices/cardacceptorlocator.backend.service';
import { retrieveExperianAddressBackendService } from './mockbackendservices/retrieveexperianaddress.backend.service';
import { manageBsbsBackendService } from './mockbackendservices/managebsbs.backend.service';
import { manageApiUsageBackendService } from './mockbackendservices/manageapiusage.backend.service';
import { transactionBackendService } from './mockbackendservices/transaction.backend.service';
import { westfieldLocationBackendService } from './mockbackendservices/westfieldlocation.backend.service';
import { suburbStateCodeBackendService } from './mockbackendservices/suburbstatecode.backend.service';
import { westfieldRetailerBackendService } from './mockbackendservices/westfieldretailer.backend.service';
import { retailerAuditBackendService } from './mockbackendservices/retaileraudit.backend.service';
import { bankAccountTransactionTypeBackendService } from './mockbackendservices/bankaccounttransactiontype.backend.service';
import { paymentBackendService } from './mockbackendservices/payment.backend.service';
import { merchantValidationStatsBackendService } from './mockbackendservices/merchantvalidationstats.backend.service';
import { cacheRefreshBackendService } from './mockbackendservices/cacherefresh.backed.service';
import { bPayLookupBackendService } from './mockbackendservices/bpaylookup.backend.service';
import { manageExternalCategoriesBackendService } from './mockbackendservices/manageexternalcategories.backend.service';

@Injectable()
export class MockBackendInterceptor implements HttpInterceptor {

  private savedRequests: HttpRequest<any>[] = [];

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:prefer-const
    let url: string = request.url;
    // tslint:disable-next-line:prefer-const
    let method: string = request.method;
    this.saveRequest(request);

    return redisCacheLookupBackendService(url, method, request) ||
      merchantValidationBackendService(url, method, request) ||
      moneyTrackerSearchBackendService(url, method, request) ||
      whoislookupBackendService(url, method, request) ||
      eventingBackendService(url, method, request) ||
      merchantlookupBackendService(url, method, request) ||
      abnAcnLookupBackendService(url, method, request) ||
      masterCardBackendService(url, method, request) ||
      purgeMerchantBackendService(url, method, request) ||
      calParserBackendSerice(url, method, request) ||
      calSplitterBackendService(url, method, request) ||
      pastSearchBackendService(url, method, request) ||
      namesCatalogueBackendService(url, method, request) ||
      indexMerchantBackendService(url, method, request) ||
      enrichMerchantBackendService(url, method, request) ||
      applicationUserBackendService(url, method, request) ||
      calHistoryBackendService(url, method, request) ||
      calBackendService(url, method, request) ||
      apiInfoBackendService(url, method, request) ||
      tidmidBackendService(url, method, request) ||
      searchBackendService(url, method, request) ||
      categoryStandardiserService(url, method, request) ||
      categoryMappingRulesService(url, method, request) ||
      categoryBackendService(url, method, request) ||
      missedCalsBackendService(url, method, request) ||
      merchantExtractBackendService(url, method, request) ||
      mergeMerchantsBackendService(url, method, request) ||
      merchantEventBackendService(url, method, request) ||
      quarantinedMerchantsBackendService(url, method, request) ||
      quarantinedCalsBackendService(url, method, request) ||
      apiClientsBackendService(url, method, request) ||
      apiClientsRefreshBackendService(url, method, request) ||
      suggestionsBackendService(url, method, request) ||
      searchfeedbackBackendService(url, method, request) ||
      merchantChainLogosBackendService(url, method, request) ||
      chainAuditBackendService(url, method, request) ||
      chainPatternMatcherBackendService(url, method, request) ||
      manageLocationsBackendService(url, method, request) ||
      manageNamesService(url, method, request) ||
      batchPastSearchesBackendService(url, method, request) ||
      scrapingDataSourcesService(url, method, request) ||
      merchantOpeningHours(url, method, request) ||
      brokenLinksBackendService(url, method, request) ||
      merchantLogoBackendService(url, method, request) ||
      bulkLogoEditBackendService(url, method, request) ||
      tagAllocationPatternsBackendService(url, method, request) ||
      tagsBackendService(url, method, request) ||
      chainStoresBackendService(url, method, request) ||
      operationalChecksBackendService(url, method, request) ||
      bsbBackendService(url, method, request) ||
      addressVerificationBackendService(url, method, request) ||
      merchantAuditBackendService(url, method, request) ||
      merchantNameSearchBackendService(url, method, request) ||
      subscriptionsByMerchantBackendService(url, method, request) ||
      batchCalSplitterBackendService(url, method, request) ||
      enumValuesBackendService(url, method, request) ||
      cardAcceptorLocatorBackendService(url, method, request) ||
      retrieveExperianAddressBackendService(url, method, request) ||
      manageBsbsBackendService(url, method, request) ||
      manageApiUsageBackendService(url, method, request) ||
      transactionBackendService(url, method, request) ||
      westfieldLocationBackendService(url, method, request) ||
      suburbStateCodeBackendService(url, method, request) ||
      westfieldRetailerBackendService(url, method, request) ||
      retailerAuditBackendService(url, method, request) ||
      bankAccountTransactionTypeBackendService(url, method, request) ||
      paymentBackendService(url, method, request) ||
      merchantValidationStatsBackendService(url, method, request) ||
      cacheRefreshBackendService(url, method, request) ||
      bPayLookupBackendService(url, method, request) ||
      manageExternalCategoriesBackendService(url, method, request) ||
      next.handle(request); // fallback in case url isn't caught
  }

  saveRequest(request: HttpRequest<any>) {
    if (!(<any>window)._savedRequests) {

      (<any>window)._savedRequests = this.savedRequests;
    }

    this.savedRequests.push(request);
  }
}
