import { ExecutionResult } from 'graphql';
import { MerchantSubscriptionLookupResponse } from '../../components/subscriptionsbymerchant/subscriptionsbymerchant.component';

export let merchantSubscriptionLookupResponse: ExecutionResult<MerchantSubscriptionLookupResponse> = {
  data: {
    merchantsubscriptionlookup: [
      {
        LWC_Guid: 1500000,
        SubscribedClients: "Isabella",
        MatchedOnId: "true",
        MatchedType: "StartsWith",
        FixedCal: "SUBWAY DFO BRISBANE BRISB ANE AIR QLD",
        Cal: "0RANDWICK PS 4250 RANDWICK",
        BankAccountTransactionType: "Transaction Amounts:800 CAD",
        direct_debit_code: "789900",
        bpay_biller_code: "8954000",
        aid: "XXX1",
        mid: "XXX1",
        tid: "XXX1"
      },
      {
        LWC_Guid: 8950000,
        SubscribedClients: "Mia",
        MatchedOnId: "false",
        MatchedType: "ContainsAll",
        FixedCal: "JULIANS EST USD",
        Cal: "BURLEIGH WATERS MIAMI QLD",
        BankAccountTransactionType: "Transaction Amounts:33 JPY",
        direct_debit_code: "15000",
        bpay_biller_code: "500000",
        aid: "XXX2",
        mid: "XXX2",
        tid: "XXX2"
      },
      {
        LWC_Guid: 8950000,
        SubscribedClients: "Oscar",
        MatchedOnId: "false",
        MatchedType: "WithinTextMultiple",
        FixedCal: "INSTACO PTY LTD COOPERS PLAIN",
        Cal: "NABINTNL TRN FEE REV -MC",
        BankAccountTransactionType: "Transaction Amounts:65 JPY",
        direct_debit_code: "15000",
        bpay_biller_code: "500000",
        aid: "XXX3",
        mid: "XXX3",
        tid: "XXX3"
      },
      {
        LWC_Guid: 6350000,
        SubscribedClients: "Noah",
        MatchedOnId: "false",
        MatchedType: "WholeText",
        FixedCal: "JULIANS EST USD",
        Cal: "ADAIRS BALGOWLAH",
        BankAccountTransactionType: "Transaction Amounts:85 JPY",
        direct_debit_code: "18000",
        bpay_biller_code: "3200000",
        aid: "XXX4",
        mid: "XXX4",
        tid: "XXX4"
      },
      {
        LWC_Guid: 6350000,
        SubscribedClients: "Noah",
        MatchedOnId: "false",
        MatchedType: "WholeText",
        FixedCal: "JULIANS EST USD",
        Cal: "99 BIKES PTY LTD BROOKVALE",
        BankAccountTransactionType: "Transaction Amounts:89 JPY",
        direct_debit_code: "180",
        bpay_biller_code: "30",
        aid: "XXX5",
        mid: "XXX5",
        tid: "XXX5"
      },
      {
        LWC_Guid: 890000,
        SubscribedClients: "Noah",
        MatchedOnId: "false",
        MatchedType: "WholeText",
        FixedCal: "JULIANS EST USD",
        Cal: "BLS*PREZI INC 415-3988012",
        BankAccountTransactionType: "Transaction Amounts:5 JPY",
        direct_debit_code: "180",
        bpay_biller_code: "3000",
        aid: "XXX6",
        mid: "XXX6",
        tid: "XXX6"
      }
    ]
  }
}
