import { ODataResponse } from './odataresponse';
import { ApiParameter } from '../apiparameter';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ODataConfig } from './odataconfig';
import { ApiClient } from '../apiclient';
import { Observable } from 'rxjs';
import { oDataConstants } from './odataconstants';

@Injectable()
export class ODataService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) {
  }

  get<T>(oDataConfig: ODataConfig): Observable<ODataResponse<T>> {
    let url = this.buildUrl(oDataConfig);

    return this.apiClient.get<ODataResponse<T>>(url);
  }

  buildUrl(oDataConfig: ODataConfig): string {
    let route = oDataConfig.query;
    let oDataParams = this.getFilters(oDataConfig);

    if (oDataConfig.skip) {
      let skipParam: ApiParameter = { name: '$skip', value: oDataConfig.skip.toString() };

      oDataParams.push(skipParam);
    }

    if (oDataConfig.top) {
      let topParam: ApiParameter = { name: '$top', value: oDataConfig.top.toString() };

      oDataParams.push(topParam);
    }

    if (oDataConfig.count) {
      let countParam: ApiParameter = { name: '$count', value: 'true' };

      oDataParams.push(countParam);
    }

    if (oDataConfig.orderBy) {
      let orderByParam: ApiParameter = { name: '$orderby', value: oDataConfig.orderBy.getValue() };

      oDataParams.push(orderByParam);
    }

    return this.apiService.buildUrlWithParameters(oDataConstants.oDataApiPrefix, route, ...oDataParams);
  }

  private getFilters(oDataConfig: ODataConfig): ApiParameter[] {
    let params: ApiParameter[] = [];

    if (oDataConfig.filters && oDataConfig.filters.length > 0) {
      let filterParam: ApiParameter = { name: '$filter', value: '' };
      let filterCount = oDataConfig.filters.length;

      for (let i = 0; i < filterCount; i++) {
        let filter = oDataConfig.filters[i].getFilter();
        filterParam.value += filter;

        if (filterCount - 1 > i) {
          filterParam.value += ' and ';
        }
      }

      params.push(filterParam);
    }

    return params;
  }
}
