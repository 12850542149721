import { ApiClientConfig } from './apiclientconfig';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../services/errorhandler';

@Injectable()
export class ApiClient {
  constructor(private httpClient: HttpClient, private errorHandler: ErrorHandler) { }

  get<T>(url: string, apiClientConfig?: ApiClientConfig): Observable<T> {
    let observable = this.httpClient.get<T>(url, this.getHttpClientOptions(url));

    if (!apiClientConfig || !apiClientConfig.disableDefaultErrorHandling) {
      observable = observable.pipe(catchError<T, Observable<T>>(this.errorHandler.getApiErrorHandler(apiClientConfig)));
    }

    return observable;
  }

  post<T>(url: string, data: object, apiClientConfig?: ApiClientConfig): Observable<T> {
    let observable = this.httpClient.post<T>(url, data, this.getHttpClientOptions(url, null, apiClientConfig));

      if (!apiClientConfig || !apiClientConfig.disableDefaultErrorHandling) {
        observable = observable.pipe(catchError<T, Observable<T>>(this.errorHandler.getApiErrorHandler(apiClientConfig)));
      }
     return observable;
  }

  put<T>(url: string, data?: object): Observable<T> {
    return this.httpClient
      .put<T>(url, data, this.getHttpClientOptions(url))
      .pipe(catchError<T, Observable<T>>(this.errorHandler.getApiErrorHandler()));
  }

  delete<T>(url: string, data?: object): Observable<T> {
    return this.httpClient
      .delete<T>(url, this.getHttpClientOptions(url, { body: data }))
      .pipe(catchError<T, Observable<T>>(this.errorHandler.getApiErrorHandler()));
  }

  private getHttpClientOptions(url: string, httpClientOptions?: { headers?: HttpHeaders, body?: object; }, 
    apiClientConfig?: ApiClientConfig) {
    const options = httpClientOptions ? httpClientOptions : {};
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }

    if (!options.headers.has('content-type')) {
      if(apiClientConfig && apiClientConfig.skipContentType) {
        // Don't add content type header
      } else {
        options.headers = options.headers.append('content-type', 'application/json');
      }
    }

    return options;
  }
}
