import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { merchantLogoResponse } from '../mockjsonresponses/merchantlogo.response';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function merchantLogoBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?');

  if (cleanUrl.length > 1 && cleanUrl[0].includes(apiPathConstants.logo) && cleanUrl[1].includes('forceSearch=') && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...merchantLogoResponse}
      }));
      resp.complete();
    });
  }

  if(url.includes(apiPathConstants.logo) && method === HttpMethod.Put) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
      }));
      resp.complete();
    });
  }

  if (url.includes(apiPathConstants.logo + '/') && method === HttpMethod.Post && request.body && Object.keys(request.body).includes('imgBase64')) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
      }));
      resp.complete();
    });
  }

  if (url.includes(apiPathConstants.setLogoFromManualUpload) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  }
}
