import { apiPathConstants } from './../core/api/apipathconstants';
import { PurgeResult } from '../models/purgeresult';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PurgeMerchantService {
  private API_PURGE_MERCHANT = 'purge/merchant';
  private API_PURGE_SENSIS_DATA = 'purge/sensis';
  private API_PURGE_GOOGLE_PLACES_DATA = 'purge/googleplaces';
  private API_PURGE_CLEARBIT_DATA = 'purge/clearbit';
  private API_PURGE_LOGO_DATA = 'purge/logo';
  private API_PURGE_CAL = 'purge/cal';
  private API_PURGE_INTERNATIONAL_CAL = 'purge/internationalcal';
  private API_PURGE_SHARED_CAL = 'purge/sharedcal';
  private API_PURGE_URL = 'purge/url';
  private API_PURGE_EMAIL = 'purge/email';
  private API_PURGE_PHONE = 'purge/phone';

  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  private purgeMessage = 'From Index Manager client';

  purgeIndex(lwcID: number) {
    return this.apiClient.get<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_MERCHANT, lwcID.toString(), this.purgeMessage));
  }

  purgeSensis(lwcID: number) {
    return this.apiClient.get<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_SENSIS_DATA, lwcID.toString(), this.purgeMessage));
  }

  purgeGooglePlacesData(lwcID: number) {
    return this.apiClient.get<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_GOOGLE_PLACES_DATA, lwcID.toString(), this.purgeMessage));
  }

  purgeClearbitData(lwcID: number) {
    return this.apiClient.get<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_CLEARBIT_DATA, lwcID.toString(), this.purgeMessage));
  }

  purgeLogoData(lwcID: number) {
    return this.apiClient.get<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_LOGO_DATA, lwcID.toString(), this.purgeMessage));
  }

  purgeDarkModeLogos(lwcID: number) {
    return this.apiClient.get<PurgeResult>(this.apiService
      .buildUrl(apiPathConstants.darkLogo, lwcID.toString(), this.purgeMessage));
  }

  purgeUrl(lwcID: number) {
    return this.apiClient.delete<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_URL, lwcID.toString(), this.purgeMessage));
  }

  purgeCal(lwcID: number, cal: string) {
    return this.apiClient.get<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_CAL, lwcID.toString(), encodeURIComponent(cal), this.purgeMessage));
  }

  purgeInternationalCal(cal: string) {
    return this.apiClient.get<string>(this.apiService
      .buildUrl(this.API_PURGE_INTERNATIONAL_CAL, encodeURIComponent(cal)));
  }

  purgeSharedCal(cal: string) {
    return this.apiClient.get<string>(this.apiService
      .buildUrl(this.API_PURGE_SHARED_CAL, encodeURIComponent(cal)));
  }

  purgeEmail(lwcID: number) {
    return this.apiClient.delete<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_EMAIL, lwcID.toString(), this.purgeMessage));
  }

  purgePhone(lwcID: number) {
    return this.apiClient.delete<PurgeResult>(this.apiService
      .buildUrl(this.API_PURGE_PHONE, lwcID.toString(), this.purgeMessage));
  }
  }
