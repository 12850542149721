import { Component, OnInit, Injector } from "@angular/core";
import { BaseStateComponent } from "../basestate/basestate.component";
import { LoadingService } from "../../core/uiservices/loading.service";
import { ManageLocationsState } from "./managelocationsstate";
import { LocatedInDto } from "./managelocationsdto";
import { finalize } from "rxjs/operators";
import { ManageLocationService } from "../../services/managelocations.service";
import { Router } from "@angular/router";
import { RouteConstants } from "../../core/constants/constants";
import { cloneDeep } from 'lodash';
import { ValueCheckerService } from "../../core/services/valuechecker.service";
import { FormatService } from "../../core/formatting/format.service";

@Component({
    selector: 'app-managelocations',
    templateUrl: './managelocations.component.html',
    styleUrls: ['./managelocations.component.scss']
})

export class ManageLocationsComponent extends BaseStateComponent<ManageLocationsState> implements OnInit {

  constructor(private loadingService: LoadingService, injector: Injector, private manageLocationsService: ManageLocationService,
     private router: Router, private valueCheckerService: ValueCheckerService,
     private formatService: FormatService) {
    super(injector);
  }

    ngOnInit() {
        this.state = {
            displayResults: [],
            filteredDisplayResults: [],
            filterByName: '',
            filterBySuburb: ''
        }
        this.setManageLocations();
    }

    private setManageLocations() {
        this.manageLocationsService.getManageLocations()
            .pipe(finalize(() => this.loadingService.clearLoading()))
            .subscribe(responce => {
              this.state.filteredDisplayResults = this.state.displayResults = responce.data;
                this.loadingService.clearLoading();
            });
    }

    getYesNo(val: boolean) {
        return this.formatService.formatTrueOrFalseToYesOrNo(val);
    }

    editLocation(manageLocations: LocatedInDto) {
        this.manageLocationsService.setLocatedIn(cloneDeep(manageLocations));
        this.router.navigate([RouteConstants.editLocation]);
    }

    refreshLocations() {
        this.setManageLocations();
    }

    createNewLocation() {
        let newLocation: LocatedInDto = {
            formatted_phone_number: '',
            openingTimes: '',
            googlePlaceID: '',
            isStadium: false,
            isHospital: false,
            isUni: false,
            isShoppingCentre: false,
            isAirport: false,
            isTrainStation: false,
            centreURL: '',
            latLonPrecision: null,
            formattedAddressString: '',
            streetViewAvailable: true,
            mapable: null,
            geoCodeGranularity: '',
            latitude: null,
            addressCountryCd: '',
            addressPostcode: '',
            addressStateCd: '',
            addressSuburb: '',
            addressLine2: '',
            addressLine1: '',
            locatedIn_Name: '',
            locatedIn_ID: null,
            longitude: null
        };
        this.manageLocationsService.setLocatedIn(newLocation);
        this.router.navigate([RouteConstants.editLocation]);
    }

  handleClearInputForName() {
    this.state.filterByName = '';
    this.filterLocations();
  }

  handleClearInputForSuburb() {
    this.state.filterBySuburb = '';
    this.filterLocations();
  }

  setFilterByName(event: KeyboardEvent) {
    this.state.filterByName = (<HTMLInputElement>event.target).value;
    this.filterLocations();
  }

  setFilterBySuburb(event: KeyboardEvent) {
    this.state.filterBySuburb = (<HTMLInputElement>event.target).value;
    this.filterLocations();
  }

  private filterLocations() {
    this.state.filteredDisplayResults = this.state.displayResults.filter((location: LocatedInDto) => {
      let isMatch = true;

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterByName)) {
        isMatch = location.locatedIn_Name.toLowerCase().includes(this.state.filterByName.toLowerCase());
      }

      if (isMatch && !this.valueCheckerService.isEmptyNullOrUndefined(this.state.filterBySuburb)) {
        isMatch = location.addressSuburb.toLowerCase().includes(this.state.filterBySuburb.toLowerCase());
      }

      return isMatch;
    });
  }
}
