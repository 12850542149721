import { Injectable } from "@angular/core";
import { LocatedIn } from "../../models/locatedin";

@Injectable({
  providedIn: 'root'
})

export class LocationService {
  constructor() { }

  filterFn = (item: LocatedIn, term: string) => {
    return item.LocatedIn_Name.toLowerCase().includes(term)
      || item.AddressPostcode.includes(term);
  };

  mapFn = (item: LocatedIn) => {
    let display = item.LocatedIn_Name;
    if (item.AddressPostcode) {
      display = item.AddressPostcode + ' ' + display;
    }
    return {
      value: item,
      display: display,
      details: ''
    };
  };

}

