import { Injectable } from "@angular/core";
import { LookupType } from "../enums/enums";
import { DialogService } from "../core/uiservices/dialog.service";
import { LookupOption } from "../core/models/lookupoption";
import { ValueCheckerService } from "../core/services/valuechecker.service";
import { positiveNumberWithPlusInStart } from "../core/constants/constants";
import { NotificationService } from "../core/uiservices/notification.service";

@Injectable()
export class LookupOptionService {
  constructor(private dialogService: DialogService, private valueCheckerService: ValueCheckerService,
    private notificationService: NotificationService) { }

  validateLookupOptionsAndShowDialogIfItIsInvalid(lookupOptions: LookupOption[]) {
    if (this.valueCheckerService.isNullOrUndefined(lookupOptions)) {
      return true;
    }

    for (let lookupOption of lookupOptions) {
      if (lookupOption.LookupType === LookupType.StartsWithEndsWith ||
        lookupOption.LookupType === LookupType.StartsWithEndsWithAllowNoSpaces) {
        let invalidForStartsWith = (lookupOption.SearchString.match(/&&/g) || []).length !== 1;

        if (invalidForStartsWith) {
          let lookupTypeName = lookupOption.LookupType === LookupType.StartsWithEndsWith ? 'StartsWithEndsWith' : 'StartsWithEndsWithAllowNoSpaces';
          this.dialogService.openDialog(lookupTypeName + ' requires a start word and end word separated by &&');

          return false;
        }
      }
      else if (lookupOption.LookupType === LookupType.ContainsAll) {
        let invalidForContainsAll = (lookupOption.SearchString.match(/&&/g) || []).length < 1;

        if (invalidForContainsAll) {
          this.dialogService.openDialog('ContainsAll requires at least 2 words separated by &&');

          return false;
        }
      }
      else if (lookupOption.LookupType === LookupType.EndsWith) {
        if (positiveNumberWithPlusInStart.test(lookupOption.SearchString)) {
          continue;
        }
        else {
          this.notificationService.notifyError(`Only the combination of "+" and number can be used for Lookup Type "EndsWith".`);
          return;
        }
      }
    }

    return true;
  }
}
