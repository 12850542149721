import { ApiClient } from '../core/api/apiclient';
import { Injectable } from '@angular/core';
import { WhoIsResponse } from '../models/whoisresponse';
import { Observable, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../core/api/api.service';

@Injectable()
export class WhoislookupService {


  constructor(private apiClient: ApiClient, private apiService: ApiService) { }

  getWhois(url: string): Observable<WhoIsResponse> {
    return this.apiClient.get<WhoIsResponse>(this.safeWhoIsUrl(url))
      .pipe(catchError(this.errorHandler));
  }

  private safeWhoIsUrl(url: string): string {
    return this.apiService.buildApiUrlWithParameters('whois', {name: 'url', value: url});
  }

  errorHandler(error: Error): Observable<any> {
    return empty();
  }
}
