import { apiPathConstants } from '../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpMethod } from '../../core/coreenums/coreenums';
import { merchantSubscriptionLookupResponse } from '../mockjsonresponses/merchantsubscriptionlookupresponse';

export function subscriptionsByMerchantBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>>{
  const cleanUrl = url.split('?')[0];

  let includesMerchantSubscriptionLookup = false;
  if(request.body && request.body.query) {
    includesMerchantSubscriptionLookup = request.body.query.includes("merchantsubscriptionlookup");
  }

  if (cleanUrl.includes(apiPathConstants.graphql) && includesMerchantSubscriptionLookup && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...merchantSubscriptionLookupResponse}
      }));
      resp.complete();
    });
  }
}
