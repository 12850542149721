import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-jsonviewer-button',
  templateUrl: './jsonviewericon.component.html',
  styleUrls: ['./jsonviewericon.component.scss']
})
export class JsonViewerIconComponent implements OnInit {
  @Input()
  tooltip: string = '';
  @Input()
  icon: string = 'playlist_add';
  @Input()
  hidden = false; 

  @Output()
  click = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.click.emit();
  }
}
