import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, map, Observable, ObservableInput, startWith } from 'rxjs';
import { DateTimeService } from '../../../core/uiservices/datetime.service';
import { LoadingService } from '../../../core/uiservices/loading.service';
import { NotificationService } from '../../../core/uiservices/notification.service';
import { ApiClientPolicy, ClientAPI, ClientApikey, clientNames, DialogData, Item, policy, UpsertClientResponseData, UsagePlanItem } from '../../../models/manageapikeys.models';
import { AuthService } from '../../../services/auth.service';
import { ManageapikeysService } from '../../../services/manageapikeys.service';
import { ApplicationUser } from '../../../users/applicationuser';
import { UserService } from '../../../users/applicationuser.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-manageapi-dialog',
  templateUrl: './manageapi-dialog.component.html',
  styleUrls: ['./manageapi-dialog.component.scss']
})
export class ManageapiDialogComponent implements OnInit {

  @ViewChild('policyInput') policyInput: ElementRef<HTMLInputElement>;

  apiKeyForm !: FormGroup;

  formControl = new FormControl();

  usagePlan: UsagePlanItem[];
  policy: ApiClientPolicy[];
  applicationUsers: ApplicationUser[];
  filteredClientName: Observable<clientNames[]>;
  clientsNames: clientNames[];

  selectedClientValue: string;
  selectedClientNumber: number;
  selectedPlanValue: string;
  selectedPlanNumber: number;
  selectedPolicy: string[];
  id: number;

  policyObject: policy[];
  uniquePolicy: policy[];

  filteredPolicy: ObservableInput<ApiClientPolicy[]>
  filteredUsagePlan: Observable<UsagePlanItem[]>

  formData: ClientAPI;
  textarea: any;
  client_data: any;
  plan_data: any;
  message: boolean;
  key_text: boolean;
  key_data: any[];
  key_value: string;
  value: any;
  update: boolean;
  data_value: Item[];
  createdBy: number;
  selectedItem: string[];
  skip: number;
  take: number;
  child_data: ClientApikey[];
  error: string;
  isAdmin: boolean;
  auditbox: boolean;
  defaultCountry: string;
  countryList: string[];
  allAvailableCountryList: string[];
  selectedAvailableCountryCodes: string[]

  constructor(private manageApikeysService: ManageapikeysService, private loadingService: LoadingService,
    private userService: UserService, private authService: AuthService, private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private dateTimeService: DateTimeService, public dialog: MatDialog,
    public datepipe: DatePipe) {
    this.usagePlan = [];
    this.policy = [];
    this.selectedPlanValue = '';
    this.selectedPolicy = [];
    this.selectedItem = [];
    this.policyObject = [];
    this.clientsNames = [];
    this.selectedClientValue = ''
    this.id = 0;
    this.message = false;
    this.key_text = false;
    this.update = false;
    this.skip = 0;
    this.take = 5000;
    this.isAdmin = false;
    this.getClients();
    this.getUsagePlan();
    this.getPolicy();
    this.auditbox = false;
    this.defaultCountry = null;
    this.countryList = [];
    this.allAvailableCountryList = ["AUS", "NZL"]
    this.selectedAvailableCountryCodes = []
  }

  ngOnInit(): void {
    this.getApplicationUsers();
    this.createApiForm();
    this.createTextArea(this.data);
    if (this.data.dataType == 'edit') {
      this.clientsNames = this.data.clientNames;
      this.usagePlan = this.data.usagePlan;
      if (this.data.isAdmin == true) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
      this.selectedAvailableCountryCodes = this.data.data[0].clientAPIKeys[0].allAvailableCountries
      if (this.data.data[0].clientAPIKeys[0].defaultCountry != '') {
        this.defaultCountry = this.data.data[0].clientAPIKeys[0].defaultCountry
      } else {
        this.defaultCountry = null
      }

      if (this.data.data[0].clientAPIKeys[0].allAvailableCountries != null) {
        this.selectedAvailableCountryCodes = this.data.data[0].clientAPIKeys[0].allAvailableCountries;
        //this.allAvailableCountryList = this.data.data[0].clientAPIKeys[0].allAvailableCountries;
        //let selectedCountryIndex = this.data.data[0].clientAPIKeys[0].allAvailableCountries.findIndex(item => item == this.data.data[0].clientAPIKeys[0].defaultCountry);
        //this.countryList = [this.data.data[0].clientAPIKeys[0].allAvailableCountries[selectedCountryIndex]];
        this.countryList = this.data.data[0].clientAPIKeys[0].allAvailableCountries;
      } else {
        this.selectedAvailableCountryCodes = [];
        //this.allAvailableCountryList = [];
      }
      this.one();
    } else {
      this.value = this.data.data;
      this.clientsNames = this.data.clientNames;
      if (this.data.isAdmin == true) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }

      this.changeClientName(this.value.clientName);
      this.apiKeyForm.controls['expiryDate'].setValue(this.formatDate(new Date()));
    }
  }

  createApiForm() {
    this.apiKeyForm = new FormGroup({
      apiClientId: new FormControl(0),
      id: new FormControl(0),
      usagePlanId: new FormControl(''),
      policy: new FormControl('', Validators.required),
      apikeyName: new FormControl('', Validators.required),
      description: new FormControl(''),
      note: new FormControl(''),
      fourCharCode: new FormControl(''),
      isActive: new FormControl(true),
      isSubscription: new FormControl(true),
      isReliableKey: new FormControl(false),
      isRotating: new FormControl(true),
      expiryDate: new FormControl(),
      createdBy: new FormControl(0),
      isApiGatewayClient: new FormControl(true),
      maxTransactionPerSearch: new FormControl(null, [Validators.maxLength(8)]),
      maxLimitPerPageForMerchantLookup: new FormControl(null, [Validators.maxLength(8)]),
      maxRecordsPagedForMerchantLookup: new FormControl(null, [Validators.maxLength(8)]),
      maxBpayLookupTransactionsPerRequest: new FormControl(null, [Validators.maxLength(8)]),
      maxMoneyTrackerTransactionsLimit: new FormControl(null, [Validators.maxLength(8)]),
      defaultCountry: new FormControl(null),
      allAvailableCountries: new FormControl(''),
    })
  }

  one() {
    if (this.data.dataType == 'edit') {
      this.data_value = this.data.data;
      for (let i of this.data_value) {
        this.child_data = i.clientAPIKeys;
        this.changeClientName(i.clientName);
        if (i.createdBy >= 0) {
          this.createdBy = i.createdBy;
        }
      }
      this.updateForm();
    }
  }

  updateForm() {
    this.update = true;
    if (this.child_data != null) {
      for (let i of this.child_data) {
        this.apiKeyForm.controls['apikeyName'].setValue(i.apikeyName);
        this.apiKeyForm.controls['isActive'].setValue(i.isActive);
        this.apiKeyForm.controls['fourCharCode'].setValue(i.fourCharCode);
        this.apiKeyForm.controls['isRotating'].setValue(i.isRotating);
        this.apiKeyForm.controls['isReliableKey'].setValue(i.isReliableKey);
        this.apiKeyForm.controls['id'].setValue(i.id);
        this.apiKeyForm.controls['description'].setValue(i.description);
        this.apiKeyForm.controls['isApiGatewayClient'].setValue(i.isApiGatewayClient);
        this.apiKeyForm.controls['isSubscription'].setValue(i.isSubscription);
        this.apiKeyForm.controls['note'].setValue(i.note);
        this.apiKeyForm.controls['apiClientId'].setValue(this.selectedClientValue);
        this.apiKeyForm.controls['maxTransactionPerSearch'].setValue(i.maxTransactionPerSearch);
        this.apiKeyForm.controls['maxLimitPerPageForMerchantLookup'].setValue(i.maxLimitPerPageForMerchantLookup);
        this.apiKeyForm.controls['maxRecordsPagedForMerchantLookup'].setValue(i.maxRecordsPagedForMerchantLookup);
        this.apiKeyForm.controls['maxBpayLookupTransactionsPerRequest'].setValue(i.maxBpayLookupTransactionsPerRequest);
        this.apiKeyForm.controls['maxMoneyTrackerTransactionsLimit'].setValue(i.maxMoneyTrackerTransactionsLimit);
        this.apiKeyForm.controls['defaultCountry'].setValue(i.defaultCountry);
        this.apiKeyForm.controls['allAvailableCountries'].setValue(i.allAvailableCountries);
        this.key_value = i.apikey;
        if (i.expiryDate == '') {
          this.apiKeyForm.controls['expiryDate'].setValidators(Validators.required);
        } else {
          this.apiKeyForm.controls['expiryDate'].setValue(i.expiryDate);
        }
        if (this.key_value) {
          this.key_text = true;
        }
        if (i.apiPolicy != '') {
          let policyValues = i.apiPolicy;
          let policySplit = policyValues.split(',');
          this.selectedItem = this.selectedPolicy = policySplit;
        } else {
          this.selectedItem = this.selectedPolicy = [];
        }
        if (i.usagePlanId != 0) {
          this.selectedPlanNumber = i.usagePlanId;
          const planValue = this.usagePlan.filter(x => {
            if (x.id === this.selectedPlanNumber) {
              return x;
            }
          }).map(x => x.name);
          this.selectedPlanValue = planValue.toString();
          this.changePlanName(this.selectedPlanValue);
          this.apiKeyForm.controls['usagePlanId'].setValue(this.selectedPlanValue);
        }

      }
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.apiKeyForm.controls;
  }

  getClients() {
    this.loadingService.setLoading();
    this.manageApikeysService.getClientName(this.skip, this.take).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.clientsNames = response.data.items;
        this.filteredClientName = this.apiKeyForm.controls['apiClientId'].valueChanges.pipe(
          startWith(''),
          map(value => this.clientFilter(value || '')),
        );
      }
    });
  }
  getUsagePlan() {
    this.loadingService.setLoading();
    this.manageApikeysService.getUsagePlan().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.usagePlan = response.data.items;
        this.filteredUsagePlan = this.apiKeyForm.controls['usagePlanId'].valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      }
    })
  }

  getPolicy() {
    this.loadingService.setLoading();
    this.manageApikeysService.getApiClientAllPolicies().pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
      this.loadingService.clearLoading();
      if (response) {
        this.policy = response.data;
        this.filteredPolicy = this.apiKeyForm.controls['policy'].valueChanges.pipe(
          startWith(''),
          map(value => this.policyFilter(value || '')),
        );
      }
    })
  }

  getApplicationUsers() {
    this.userService.getApplicationUsers().subscribe(response => {
      this.applicationUsers = response;
      let user = this.authService.getCurrentUser();
      let u = this.applicationUsers.find(u => u.EmailId.toLowerCase() == user.userId.toLowerCase());
      this.id = u.Id;
    });
  }

  createTextArea(data: DialogData) {
    if (data.dataType == 'insert') {
      this.auditbox = false;
    }
    else if (data.dataType == 'edit') {
      this.auditbox = true;
      this.userService.getApplicationUsers().subscribe(response => {
        this.applicationUsers = response;
        let value = data.data;
        let createdBy = '';
        let createdDate = '';
        let modifiedBy = '';
        let modifiedDate = '';
        let modifiedtextarea = '';
        let createdtextarea = '';
        for (let i of value) {
          let value = i.clientAPIKeys;
          for (let i of value) {
            if (i.createdBy != null && i.createdDate != null) {
              createdBy = this.applicationUsers.filter(x => x.Id == i.createdBy).map(x => x.FirstName + " " + x.LastName).toString();
              createdDate = this.dateTimeService.format(i.createdDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
              createdtextarea = "Created By: " + createdBy + '\n' + "Created date: " + createdDate;
            } else if (i.createdBy == null && i.createdDate == null) {
              createdtextarea = '';
            }
            if (i.modifiedBy != null && i.modifiedDate != null) {
              modifiedBy = this.applicationUsers.filter(x => x.Id == i.modifiedBy).map(x => x.FirstName + " " + x.LastName).toString();
              modifiedDate = this.dateTimeService.format(i.modifiedDate, this.dateTimeService.dateTimeFormatYYYYMMDDDashes);
              modifiedtextarea = "Modified By: " + modifiedBy + '\n' + "Modified date: " + modifiedDate;
            } else if (i.modifiedBy == null && i.modifiedDate == null) {
              modifiedtextarea = ''
            }
          }
        }
        this.textarea = createdtextarea + '\n' + modifiedtextarea;
      });
    }
  }

  changePlanName(value: string) {
    if (value) {
      this.selectedPlanValue = value;
      let data = this.usagePlan.filter(a => a.name == value).map(x => "Plan Desc: " + x.description + ',' + " Quota Limit: " + x.quotaLimit + ',' + " Quota Period: " + x.quotaPeriod + "\n" + "Environment: " + x.environmentArr);
      this.plan_data = data;
    }
  }

  changeClientName(value: string) {
    if (value) {
      this.selectedClientValue = value;
      let data = this.clientsNames.filter(a => a.clientName == value).map(x => "Client Code: " + x.clientCode + '\n' + "Four Char code: " + x.fourCharCode);
      this.client_data = data;
    }
  }

  private clientFilter(value: string): clientNames[] {
    return this.clientsNames.filter(option => option.clientName.toLowerCase().includes(value));
  }

  private _filter(value: string): UsagePlanItem[] {
    return this.usagePlan.filter(option => option.name.toLowerCase().includes(value));
  }

  private policyFilter(value: string): ApiClientPolicy[] {
    return this.policy.filter(option => option.policy.toLowerCase().includes(value));
  }

  getPolicyById() {
    let policy = new Set();

    const policyValue = this.policy.filter(x => {
      for (let i of this.selectedPolicy) {
        if (x.policy == i) {
          return x
        }
      }
    }).map(x => x.policyId)

    for (let i of policyValue) {
      this.policyObject.push({ id: i })
    }

    this.uniquePolicy = this.policyObject.filter(ele => {
      let i = policy.has(ele.id);
      policy.add(ele.id);
      if (!i) {
        return true;
      } else {
        return false;
      }
    })
  }

  private formatDate(date: Date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year + 1, month, day].join('-')
  }

  getClientbyId() {
    const value = this.clientsNames.filter(x => {
      if (x.clientName === this.selectedClientValue) {
        return x;
      }
    }).map(x => x.id);

    this.selectedClientNumber = Number(value);
  }

  getUsagePlanById() {
    const value = this.usagePlan.filter(x => {
      if (x.name === this.selectedPlanValue) {
        return x;
      }
    }).map(x => x.id);
    this.selectedPlanNumber = Number(value);
  }

  onSubmit() {
    if (this.apiKeyForm.valid) {
      this.getPolicyById();
      this.getUsagePlanById();
      this.getClientbyId();

      this.apiKeyForm.controls['policy'].setValue(this.uniquePolicy);
      this.apiKeyForm.controls['usagePlanId'].setValue(this.selectedPlanNumber);
      this.apiKeyForm.controls['apiClientId'].setValue(this.selectedClientNumber);
      this.apiKeyForm.controls['createdBy'].setValue(this.id);
      this.apiKeyForm.controls['allAvailableCountries'].setValue(this.selectedAvailableCountryCodes);
      this.formData = this.apiKeyForm.value;
      this.getDataByName();
      this.loadingService.setLoading();
      this.manageApikeysService.addClientsApiKey(this.formData).pipe(finalize(() => this.loadingService.clearLoading())).subscribe(response => {
        this.loadingService.clearLoading();
        if (response) {
          this.defaultCountry = response.upsertClientApiKey.defaultCountry
          this.getDataByName();
          this.getApiKey(response);
          this.notificationService.checkSystemMessage(response);
          setTimeout(() => {
            this.dialog.closeAll();
          }, 5000);
        }
      }, (error) => {
        let result = this.getErrorMessage(error.networkError.error);
        this.getDataByName();
        this.notificationService.notifyError(result);
        this.bindErrorMessage(result);
      });
    }
  }

  getErrorMessage(error: any = null) {
    let errorMessage = error.error;
    let result = errorMessage.split(',')[1].trim();
    return result
  }

  getDataByName() {
    const planValue = this.usagePlan.filter(x => {
      if (x.id === this.selectedPlanNumber) {
        return x;
      }
    }).map(x => x.name);

    this.selectedPlanValue = planValue.toString();

    const clientValue = this.clientsNames.filter(x => {
      if (x.id === this.selectedClientNumber) {
        return x;
      }
    }).map(x => x.clientName);


    const policyValue = this.policy.filter(x => {
      for (let i of this.uniquePolicy) {
        if (x.policyId == i.id) {
          return x
        }
      }
    }).map(x => x.policy);

    this.selectedPolicy = policyValue;
    this.selectedClientValue = clientValue.toString();
    this.apiKeyForm.controls['usagePlanId'].setValue(this.selectedPlanValue);
    this.apiKeyForm.controls['apiClientId'].setValue(this.selectedClientValue);
    this.apiKeyForm.controls['policy'].setValue(this.selectedPolicy);

  }

  bindErrorMessage(error: any = null) {
    if (error.includes('policy')) {
      this.apiKeyForm.controls['policy'].setErrors(error);
    }
    if (error.includes('FourCharCode')) {
      this.apiKeyForm.controls['fourCharCode'].setErrors(error);
    }
    if (error.includes('ApikeyName')) {
      this.apiKeyForm.controls['apikeyName'].setErrors(error);
      this.error = error;
    }
  }

  getApiKey(response: UpsertClientResponseData) {
    if (this.data.dataType == 'insert') {
      this.message = true;
    }
    this.key_value = response.upsertClientApiKey.apikey;
  }

  selectedCountries(countries: MatSelectChange) {
    if (countries.value.length > 0) {
      this.selectedAvailableCountryCodes = countries.value
      this.countryList = countries.value
      this.apiKeyForm.controls['allAvailableCountries'].setValue(this.countryList);
      for (let i = 0; i < countries.value.length; i++) {
        if (countries.value[i].includes(this.defaultCountry)) {
          this.apiKeyForm.controls['defaultCountry'].setValue(this.defaultCountry);
        }
        else {
          this.apiKeyForm.controls['defaultCountry'].setValue(null);
        }
      }
    } else {
      this.selectedAvailableCountryCodes = []
      this.countryList = []
      this.defaultCountry = null
      this.apiKeyForm.controls['defaultCountry'].setValue(this.defaultCountry);
    }
  }
  selectedDefaultCountry(country: MatSelectChange) {
    if (country.value != '') {
      this.defaultCountry = country.value
      this.apiKeyForm.controls['defaultCountry'].setValue(country.value);
      this.selectedAvailableCountryCodes = this.apiKeyForm.controls['allAvailableCountries'].value
    } else {
      this.defaultCountry = null
      this.selectedAvailableCountryCodes = []
      this.apiKeyForm.controls['defaultCountry'].setValue(this.defaultCountry);
      this.selectedAvailableCountryCodes = this.apiKeyForm.controls['allAvailableCountries'].value
    }
  }
}
