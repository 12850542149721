import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getCalHistoryResponse } from '../mockjsonresponses/getcalhistoryresponse';

export function calHistoryBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  // alhistory to not match other 'cal' route. calhistory
  if (cleanUrl.includes('alhistory') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: getCalHistoryResponse
      }));
      resp.complete();
    });
  }
}
