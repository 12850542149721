import { Component, OnInit, Output, EventEmitter, Input, Injector } from '@angular/core';
import { FlatCategory } from '../../categories/flatcategory';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CategoryConfig } from '../../categories/categoryconfig';
import { CategoriesComponentService } from '../../categories/categoriescomponent.service';
import { CategoryLevelNumber } from '../../categories/categorylevelnumber';
import { CategoryService } from '../../services/category.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { SelectedCategory } from './selectedcategory';
import { DialogService } from '../../core/uiservices/dialog.service';
import { FormControl } from '@angular/forms';
import { unsavedChangesMessage } from '../../core/constants/constants';
import { CategoryType } from '../../enums/enums';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends BaseComponent implements OnInit {
  categoryConfig: CategoryConfig;
  categoryLevelNumber = CategoryLevelNumber;
  confirmSelection = false;
  readonly: boolean = false;
  draft_checkbox: boolean;
  show_draft: boolean;

  @Input() categoryTypes: CategoryType[] = [];
  @Input() categoryID: number;
  @Input() isCompact: boolean = false;
  @Input() variable: SelectedCategory;
  private _showAllCategories: boolean = false;
  @Input() draftShow: boolean;
  @Input() set showAllCategories(value: boolean) {
    this._showAllCategories = value;
    if (this.categoryConfig) {
      this.filterCategories();
    }
    if (!this._showAllCategories && this.categoryConfig) {
      this.clearSelectedCategoryOnShowAllChangeIfNeeded();
    }
  };
  get showAllCategories() {
    return this._showAllCategories;
  };

  @Output() categorySelected = new EventEmitter<SelectedCategory>();

  constructor(injector: Injector, private categoriesComponentService: CategoriesComponentService, private categoryService: CategoryService,
    private valueCheckerService: ValueCheckerService, private dialogService: DialogService) {
    super(injector);
    this.draft_checkbox = false;
  }

  ngOnInit() {
    if (this.draftShow === true) {
      this.show_draft = true;
      this.draft_checkbox = true;
      this.refreshCategories();
    } else {
      this.show_draft = false;
      this.draft_checkbox = false;
      this.refreshCategories();
    }
  }

  refreshCategories() {
    this.categoryConfig = this.categoriesComponentService.getDefaultFlatCategoryConfig();
    this.categoryService.refreshCategoriesforCategories(this.show_draft).subscribe(cat => {
      this.categoryConfig.categories = cat;
      this.filterCategories();
      this.setupCategoryFilteringForAllLevelsAndSetCategoryID();
    });
  }

  draftChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.show_draft = true;
      this.refreshCategories();
    } else {
      this.show_draft = false;
      this.refreshCategories();
    }
  }

  getInputStyles() {
    return this.isCompact === true ? { 'height': '21px', "border-bottom": 'none' } : null;
  }

  setCategoryID(categoryID: number) {
    this.clear();
    this.categoryID = categoryID;
    if (!this.valueCheckerService.isNullOrUndefined(this.categoryID) && this.categoryID >= 0) {
      this.categoriesComponentService.setCategory(this.categoryConfig, this.categoryID);
      this.categorySelected.emit(this.getSelectedCategory());
    }
  }

  lock() {
    this.readonly = true;
  }

  unlock() {
    this.readonly = false;
  }

  handleCategory1Selection(option: FlatCategory, event?: MatOptionSelectionChange) {
    this.handleCategorySelection(option, this.categoryConfig.categoriesCtrls.get(CategoryLevelNumber.One),
      this.categoryConfig.selectedCategoryPerLevel.get(CategoryLevelNumber.One), this.handleLevel1Select, event);
  }

  handleCategory2Selection(option: FlatCategory, event?: MatOptionSelectionChange) {
    this.handleCategorySelection(option, this.categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Two),
      this.categoryConfig.selectedCategoryPerLevel.get(CategoryLevelNumber.Two), this.handleLevel2Select, event);
  }

  handleCategory3Selection(option: FlatCategory, event?: MatOptionSelectionChange) {
    this.handleCategorySelection(option, this.categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Three),
      this.categoryConfig.selectedCategoryPerLevel.get(CategoryLevelNumber.Three), this.handleLevel3Select, event);
  }

  handleCategorySelection(option: FlatCategory, categoriesCtrl: FormControl,
    currentCategory: FlatCategory, handleCategories: (option: FlatCategory) => void,
    event?: MatOptionSelectionChange) {
    if (!event || event.isUserInput) {
      if (this.confirmSelection) {
        const dialogRef = this.dialogService.openConfirmDialog(unsavedChangesMessage)

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            handleCategories(option);
            this.confirmSelection = false;
          } else {
            if (currentCategory) {
              categoriesCtrl.setValue(currentCategory.CategoryName);
            }
          }
        });
      } else {
        handleCategories(option);
      }
    }
  }

  handleLevel1Select = (option: FlatCategory, event?: MatOptionSelectionChange) => {
    if (!event || event.isUserInput) {
      this.categoriesComponentService.handleFlatCategorySelect(this.categoryConfig, CategoryLevelNumber.Two, option);
      this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.One, option);
      this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Two, null);
      this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, null);
      this.emitSelectedCategory();
    }
  }

  handleLevel2Select = (option: FlatCategory, event?: MatOptionSelectionChange) => {
    if (!event || event.isUserInput) {
      this.categoriesComponentService.handleFlatCategorySelect(this.categoryConfig, CategoryLevelNumber.Three, option);
      this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Two, option);
      this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, null);
      this.emitSelectedCategory();
    }
  }

  handleLevel3Select = (option: FlatCategory, event?: MatOptionSelectionChange) => {
    if (!event || event.isUserInput) {
      this.categoryConfig.selectedCategoryCloned = { ...option };
      this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, option);
      this.emitSelectedCategory();
    }
  }

  handleClearInput(categoryLevelNumber: CategoryLevelNumber) {
    switch (categoryLevelNumber) {
      case CategoryLevelNumber.One:
        this.categoryConfig.selectedCategoryCloned = null;
        this.categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Two).setValue('');
        this.categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Three).setValue('');
        this.categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Two, false);
        this.categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Three, false);
        this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.One, null);
        this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Two, null);
        this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, null);
        this.emitSelectedCategory();
        break;
      case CategoryLevelNumber.Two:
        let categoryFromFirstControl = this.getPreviousControlCategory(CategoryLevelNumber.One);
        this.categoryConfig.selectedCategoryCloned = { ...categoryFromFirstControl }
        this.categoryConfig.categoriesCtrls.get(CategoryLevelNumber.Three).setValue('');
        this.categoryConfig.shouldDisplayCategories.set(CategoryLevelNumber.Three, false);
        this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Two, null);
        this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, null);
        break;
      case CategoryLevelNumber.Three:
        let categoryFromSecondControl = this.getPreviousControlCategory(CategoryLevelNumber.Two);
        this.categoryConfig.selectedCategoryCloned = { ...categoryFromSecondControl };
        this.categoryConfig.selectedCategoryPerLevel.set(CategoryLevelNumber.Three, null);
        break;
    }
    this.emitSelectedCategory();
  }

  filterCategories() {
    if (this.showAllCategories) {
      this.categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.One, this.categoriesComponentService.getLevelOneCategories(this.categoryConfig.categories));
    }
    else {
      this.categoryConfig.categoriesPerLevel.set(CategoryLevelNumber.One,
        this.categoriesComponentService.getLevelOneCategories(this.categoryConfig.categories, this.categoryTypes));
    }
    this.setupCategoryFilteringForAllLevelsAndSetCategoryID();
  }

  getSelectedCategoryEmoji(categoryLevelNumber: CategoryLevelNumber) {
    let category = this.categoryConfig.selectedCategoryPerLevel.get(categoryLevelNumber);
    return this.categoriesComponentService.getCategoryEmoji(category);
  }

  clear() {
    this.categoriesComponentService.resetAll(this.categoryConfig);
  }

  private clearSelectedCategoryOnShowAllChangeIfNeeded() {
    let selectedCategoryPerLevelOneCategoryType = this.categoryConfig?.selectedCategoryPerLevel.get(this.categoryLevelNumber.One)?.CategoryType;
    if (this.valueCheckerService.isNumber(selectedCategoryPerLevelOneCategoryType) && !this.categoryTypes.includes(selectedCategoryPerLevelOneCategoryType)) {
      this.clear();
      this.emitSelectedCategory();
    }
  }

  private emitSelectedCategory() {
    this.categorySelected.emit(this.getSelectedCategory());
  }

  private getPreviousControlCategory(categoryLevelNumber: CategoryLevelNumber): FlatCategory {
    let previousControlValue: string = this.categoryConfig.categoriesCtrls.get(categoryLevelNumber).value;
    let category: FlatCategory = this.categoryConfig.categories.find(c => c.CategoryName == previousControlValue);
    if (category) {
      return category;
    } else {
      return null;
    }
  }

  private getSelectedCategory(): SelectedCategory {
    let selectedCategory: SelectedCategory = {
      category: this.categoryConfig.selectedCategoryCloned,
      isSelected: this.categoryConfig.selectedCategoryCloned ? true : false
    }
    return selectedCategory;
  }

  private setupCategoryFiltering(categoryLevelNumber: CategoryLevelNumber) {
    let categoryCtrl = this.categoryConfig.categoriesCtrls.get(categoryLevelNumber);
    categoryCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this.categoryConfig.categoriesPerLevel.get(categoryLevelNumber)
        .filter(category => category.CategoryName.toLowerCase().includes(value))),
      takeUntil(this.destroy$))
      .subscribe(value => this.categoryConfig.filteredCategoriesPerLevel.set(categoryLevelNumber, value));
  }

  private setupCategoryFilteringForAllLevelsAndSetCategoryID() {
    this.setupCategoryFiltering(CategoryLevelNumber.One);
    this.setupCategoryFiltering(CategoryLevelNumber.Two);
    this.setupCategoryFiltering(CategoryLevelNumber.Three);
    this.setCategoryID(this.categoryID);
  }
}
