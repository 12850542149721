import { Observable } from 'rxjs';
import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { updatedManageExternalCategoriesResponse, manageExternalCategoriesResponse } from '../mockjsonresponses/manageexternalcategories.response';
import { HttpMethod } from '../../core/coreenums/coreenums';

export function manageExternalCategoriesBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if(cleanUrl.includes(apiPathConstants.getExternalCategoriesTree) && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...manageExternalCategoriesResponse]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.updateExternalCategories) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...updatedManageExternalCategoriesResponse]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes(apiPathConstants.deleteExternalCategories) && method === HttpMethod.Delete) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...updatedManageExternalCategoriesResponse]
      }));
      resp.complete();
    });
  }
}
