import { ValueCheckerService } from './../../core/services/valuechecker.service';
import { emptyString } from "./../../core/constants/constants";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoadingService } from "../../core/uiservices/loading.service";
import { SuburbStateCodeService } from "../../services/suburbstatecode.service";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { SuburbAndStateCode } from "./suburbstatecode.models";
import { FormControl } from "@angular/forms";

export type StateCodesReponse = {
  items: SuburbAndStateCode[];
};

export type SuburbsResponse = {
  items: SuburbAndStateCode[];
  totalCount: number
};

@Component({
  selector: "app-suburbstatecode",
  templateUrl: "./suburbstatecode.component.html",
  styleUrls: ["./suburbstatecode.component.scss"],
})
export class SuburbStateCodeComponent implements OnInit {
  stateCodes: string[] = [];
  suburbs: string[] = [];
  suburbFilterControl = new FormControl();
  filteredStateCodes: string[] = [];
  stateCodeControl = new FormControl();
  @Input() suburbAndStateCode: SuburbAndStateCode = {
    stateCode: emptyString,
    suburb: emptyString
  };
  @Output() selectedSuburbAndStateCode: EventEmitter<SuburbAndStateCode> = new EventEmitter();

  constructor(private loadingService: LoadingService, private suburbStateCodeService: SuburbStateCodeService,
    private valueCheckerService: ValueCheckerService) {
  }

  ngOnInit() {
    this.setStateCodes();
  }

  private suburbChanged() {
    this.suburbFilterControl.valueChanges.pipe(
      debounceTime(1200),
      distinctUntilChanged()
      )
      .subscribe((value) => {
        if (this.valueCheckerService.isEmptyNullOrUndefined(value)) {
          this.suburbs = [];
        }
        if (!this.valueCheckerService.isEmptyNullOrUndefined(value) && this.suburbs.length === 0) {
          this.setSuburbs();
        }
        if (!this.valueCheckerService.isEmptyNullOrUndefined(value) && this.suburbs.length > 0 && this.suburbs.includes(value)) {
          this.suburbs = [];
        }
      });
  }

  stateCodeOrSuburbChanged() {
    this.selectedSuburbAndStateCode.emit({
      suburb: this.suburbAndStateCode.suburb,
      stateCode: this.suburbAndStateCode.stateCode
    });
  }

  private setStateCodes() {
    if (this.stateCodes.length === 0) {
      this.loadingService.setLoading();
      this.suburbStateCodeService.getStateCodes()
        .subscribe((response) => {
          this.loadingService.clearLoading();
          this.stateCodes = [... this.filteredStateCodes] = response.data.items
            .map((t) => t.stateCode)
            .filter((value, index, array) => array.indexOf(value) === index);
          this.stateCodeChanged();
          this.suburbChanged();
        });
    }
  }

  private setSuburbs() {
    this.suburbStateCodeService.getSuburbs(this.suburbAndStateCode.suburb, this.suburbAndStateCode.stateCode)
      .subscribe(response => {
        this.suburbs = response.data.items.map((t) => t.suburb);
      });
  }

  private stateCodeChanged() {
    this.stateCodeControl.valueChanges.pipe(
      map((code: string) =>
        this.valueCheckerService.isEmptyNullOrUndefined(code)
          ? this.stateCodes
          : this.stateCodes.filter(t => t.toLowerCase().includes(code.toLowerCase()))
      )
    ).subscribe(result => {
      this.filteredStateCodes = result;
    });
  }
}
