import { emptyString } from './../../core/constants/constants';
import { NotificationService } from './../../core/uiservices/notification.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { delay, finalize, map, takeUntil } from 'rxjs/operators';
import { LoadingService } from './../../core/uiservices/loading.service';
import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { BsbCleansed, BsbCleansedDisplay } from './managebsbs.models';
import { ManageBsbsState } from './managebsbs.state';
import { ManageBsbsService } from '../../services/managebsbs.service';
import { PageInfo } from '../../shared/models/pageinfo';
import { DateTimeService } from '../../core/uiservices/datetime.service';
import { MerchantStatus, BsbStatus } from '../../enums/enums';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../users/applicationuser.service';
import { ValueCheckerService } from '../../core/services/valuechecker.service';
import { CsvExportComponent } from '../csvexport/csvexport.component';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { combineLatest, forkJoin } from 'rxjs';

export type BsbsCleansedResponse = {
  items: BsbCleansed[];
  totalCount: number;
};

export type AssignBsbToMerchantResponse = {
  assignBsbToMerchant: Partial<BsbCleansed>;
}

@Component({
  selector: 'app-managebsbs',
  templateUrl: './managebsbs.component.html',
  styleUrls: ['./managebsbs.component.scss']
})
export class ManageBsbsComponent extends BaseStateComponent<ManageBsbsState> implements OnInit {
  @ViewChild('csvExport') csvExportComponent: CsvExportComponent;
  columMode = ColumnMode;
  totalCount: number = 0;
  take: number = 50;
  pageIndex: number = 0;
  merchantStatuses = [
    { key: "Unassigned", value: MerchantStatus.Unassigned },
    { key: "Assigned", value: MerchantStatus.Assigned },
    { key: "All", value: MerchantStatus.All }
  ];
  bsbsStatuses = [
    { key: "Merged", value: BsbStatus.Merged },
    { key: "Closed", value: BsbStatus.Closed },
    { key: "Active", value: BsbStatus.Active }
  ];
  editingRowIndex: number = -1;
  private skip: number = 0;
  private currentUserId: string;
  private currentUserFullName: string;
  isExportFlag: boolean;
  data: Array<any> = [];

  private csvHeaders = {
    headers: [
      'Bsb',
      'LWC_ID',
      'Merchant Name',
      'Name Of Indexer',
      'Mnemonic',
      'Name',
      'Street',
      'Suburb',
      'State',
      'Postcode',
      'Payments Flags',
      'Merged Bsb',
      'Bsb Status',
      'Institution Full Name',
      'UpdateDate'
    ],
    keys: [
      'bsb',
      'lwc_ID',
      'merchantName',
      'nameOfIndexer',
      'mnemonic',
      'name',
      'street',
      'suburb',
      'state',
      'postcode',
      'paymentsFlags',
      'mergedBsb',
      'bsbStatus',
      'institutionFullName',
      'updateDate'
    ]
  };

  csvOptions = { ...this.csvComponentService.getCsvComponentDefaultOptions(), ...this.csvHeaders };


  constructor(injector: Injector, private loadingService: LoadingService, private manageBsbsService: ManageBsbsService,
    private dateTimeService: DateTimeService, private authService: AuthService, private userService: UserService,
    private notificationService: NotificationService, private valueCheckerService: ValueCheckerService,
    private csvComponentService: CsvComponentService) {
    super(injector);
    this.isExportFlag = false;
  }

  ngOnInit() {
    this.state = {
      bsbsCleansed: [],
      merchantStatus: MerchantStatus.Unassigned,
      bsbStatus: BsbStatus.Active
    };
    this.restoreState();
    this.setBsbsCleansed();
    this.setCurrentUserIdAndFullName();
  }

  numberOfPageChanged(pageInfo: PageInfo) {
    this.skip = pageInfo.offset * this.take;
    this.pageIndex = pageInfo.offset;
    this.setBsbsCleansed();
  }

  setBsbsCleansed() {
    this.loadingService.setLoading();
    this.manageBsbsService.getBsbsCleansed(this.state.merchantStatus, this.state.bsbStatus, this.skip, this.take)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.items && response.data.totalCount) {
          this.state.bsbsCleansed = response.data.items;
          if (this.state.bsbsCleansed) {
            this.isExportFlag = true;
          }
          this.setDisplayValues(this.state.bsbsCleansed);
          this.totalCount = response.data.totalCount;
        }
        else {
          this.state.bsbsCleansed = [];
          this.totalCount = 0;
          this.isExportFlag = false;
        }
      });
  }

  assignBsbToMerchant(row: BsbCleansedDisplay) {
    this.loadingService.setLoading();
    this.manageBsbsService.assignBsbToMerchant(row.lwc_ID.toString(), row.bsb, emptyString, this.currentUserId, this.currentUserFullName)
      .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.assignBsbToMerchant) {
          if (response.data.assignBsbToMerchant.result.outcome === true) {
            row.lwc_ID = response.data.assignBsbToMerchant.lwc_ID;
            row.merchantName = response.data.assignBsbToMerchant.merchantName;
            row.nameOfIndexer = response.data.assignBsbToMerchant.nameOfIndexer;
            this.setBsbsCleansed();
          }
          if (response.data.assignBsbToMerchant.result.outcome === false) {
            this.notificationService.notifyError(response.data.assignBsbToMerchant.result.comments);
          }
        }
        this.editingRowIndex = -1;
      });
  }

  edit(rowIndex: number) {
    this.editingRowIndex = rowIndex;
  }

  cancel(rowIndex: number) {
    this.state.bsbsCleansed[rowIndex].lwc_ID = 0;
    this.editingRowIndex = -1;
  }

  isSaveButtonDisabled(row: BsbCleansedDisplay) {
    return this.valueCheckerService.isEmptyNullOrUndefined(row.lwc_ID) || row.lwc_ID === 0;
  }

  private setDisplayValues(bsbsCleansed: BsbCleansedDisplay[]) {
    for (let bsbCleansed of bsbsCleansed) {
      bsbCleansed.updateDateDisplay = bsbCleansed.updateDate
        ? this.dateTimeService.format(bsbCleansed.updateDate.toString())
        : null;
      bsbCleansed.lwcIdDisplay = bsbCleansed.lwc_ID === 0
        ? null
        : bsbCleansed.lwc_ID;
    }
  }

  private setCurrentUserIdAndFullName() {
    let currentUser = this.authService.getCurrentUser();
    this.userService.getApplicationUsersCached()
      .subscribe((response) => {
        if (response) {
          let user = response.find(t => t.EmailId === currentUser.userId);
          if (user !== undefined) {
            this.currentUserId = user.Id.toString();
            this.currentUserFullName = user.FirstName + " " + user.LastName;
          }
        }
      });
  }

  getBsbCleasned() {

  }

  downloadCsvFile() {
    let totalRounds: number;
    let data: any[] = [];
    const totalData: any = [];
    const finalData: any = [];
    this.loadingService.setLoading();
    if (this.totalCount >= 5000) {
      totalRounds = Number(Math.floor(this.totalCount / 5000));
      this.skip = 0;
      this.take = 5000;
    }
    for (let i = 0; i <= totalRounds; i++) {
      combineLatest(this.manageBsbsService.getBsbsCleansed(this.state.merchantStatus, this.state.bsbStatus, this.skip, this.take))
        .subscribe(async (response) => {
          this.loadingService.clearLoading();
          await data.push(response);
        });
      if (i === 0) {
        this.skip = 5000;
        this.take = 5000;
      } else {
        this.skip = this.skip + 5000;
        this.take = 5000;
      }
    }
    this.loadingService.setLoading();
    setTimeout(() => {
      this.data = data;
      for (let i of this.data) {
        totalData.push(i[0].data.items);
      }
      for (let j of totalData) {
        let b = j;
        for (let c of b) {
          finalData.push(c);
        }
      }
      this.csvExportComponent.data = finalData;
      this.csvExportComponent.options = this.csvOptions;
      this.csvExportComponent.onDownload();
    }, 5000);
    this.loadingService.clearLoading();
    this.loadingService.clearLoading();
  }

  getCsvFileName() {
    return 'Manage Bsbs';
  }
}
