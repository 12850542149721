import { commaSpace } from './../core/constants/constants';
import { fetchAllValidationStatsDetailQuery, fetchValidationStatsByIndexerQuery, fetchValidationStatsByValidationIdQuery, fetchValidationStatsByValidationQuery, hotChocolate } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { ValidationStatsResponse, ValidationStatsByValidationResponse, ValidationStatsByIndexerResponse } from '../components/merchantvalidationstats/merchantvalidationstats.component';
import { StringService } from '../core/formatting/string.service';
import { emptyString } from '../core/constants/constants';
import { ValueCheckerService } from '../core/services/valuechecker.service';

@Injectable({
  providedIn: "root"
})
export class MerchantValidationStatsService {

  constructor(private apollo: Apollo, private stringService: StringService,
    private valueCheckerService: ValueCheckerService) { }

  getValidationStatsByValidation(startDate: string, endDate: string, skip: string, take: string, validationId: string,
    validation: string, count: string) {

    let graphQLQuery = fetchValidationStatsByValidationQuery;
    let filters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(validationId)) {
      filters = filters + this.stringService.format(`{ validationId: { eq: {0}} }${commaSpace}`, validationId);
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(validation)) {
      filters = filters + this.stringService.format(` { validation: { contains: "{0}"} }${commaSpace}`, validation.trim().toLowerCase());
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(count)) {
      filters = filters + this.stringService.format(` { count: { lte: {0}} }${commaSpace}`, count);
    }

    if (filters === emptyString) {
      graphQLQuery = graphQLQuery.replace("where: { and: [ {0} ] },", emptyString);
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<ValidationStatsByValidationResponse>({
        query: gql`${this.stringService.format(graphQLQuery, filters, startDate, endDate, skip, take)}`
      })
      .valueChanges;
  }

  getValidationStatsDetail(
    validationIdOrIndexerName: string,
    startDate: string,
    endDate: string,
    searchValidationStatsDetailByIndexer: boolean,
    skip: number = 0,
    take: number = 50) {

    let query = fetchValidationStatsByValidationIdQuery;
    if (searchValidationStatsDetailByIndexer) {
      query = query.replace('validationId: {eq: {0}}', 'nameOfIndexer: {contains: "{0}"}');
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<ValidationStatsResponse>({
        query: gql`${this.stringService.format(query, validationIdOrIndexerName.toLowerCase(), startDate, endDate, skip.toString(), take.toString())}`
      })
      .valueChanges;
  }

  getValidationStatsByIndexer(startDate: string, endDate: string, skip: number, take: number, indexerName: string,
    indexerId: string, count: string) {

    let graphQLQuery = fetchValidationStatsByIndexerQuery;
    let filters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(indexerName)) {
      filters = filters + this.stringService.format(`{ indexerName: { contains: "{0}"} }${commaSpace}`, indexerName.trim().toLowerCase());
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(indexerId)) {
      filters = filters + this.stringService.format(`{ indexerId: { eq: {0}} }${commaSpace}`, indexerId);
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(count)) {
      filters = filters + this.stringService.format(`{ count: { lte: {0}} }${commaSpace}`, count);
    }

    if (filters === emptyString) {
      graphQLQuery = graphQLQuery.replace("where: { and: [ {0} ] },", emptyString);
    }

    return this.apollo
    .use(hotChocolate)
    .watchQuery<ValidationStatsByIndexerResponse>({
      query: gql`${this.stringService.format(graphQLQuery, filters, startDate, endDate, skip.toString(), take.toString())}`
    })
    .valueChanges;
  }

  getAllValidationStatsDetail(startDate: string, endDate: string, skip: number, take: number, filterByValidationId: string,
    filterByValidation: string, filterByIndexName: string) {

    let graphQLQuery = fetchAllValidationStatsDetailQuery;
    let filters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(filterByValidationId)) {
      filters = filters + this.stringService.format(`{ validationId: { eq: {0}} }${commaSpace}`, filterByValidationId);
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(filterByValidation)) {
      filters = filters + this.stringService.format(`{ validation: { contains: "{0}"} }${commaSpace}`, filterByValidation.trim().toLowerCase());
    }

    if (!this.valueCheckerService.isEmptyNullOrUndefined(filterByIndexName)) {
      filters = filters + this.stringService.format(` { nameOfIndexer: { eq: "{0}"} }${commaSpace}`, filterByIndexName.trim().toLowerCase());
    }

    if (filters === emptyString) {
      graphQLQuery = graphQLQuery.replace('{0}', emptyString);
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<ValidationStatsResponse>({
        query: gql`${this.stringService.format(graphQLQuery, filters, startDate, endDate, skip.toString(), take.toString())}`
      })
      .valueChanges;
  }
}
