import { EnrichMerchantRequest } from '../models/enrichmerchantrequest';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { AUS_Indexing_Result } from '../models/ausindexingresult';
import { StringNameValue } from '../core/types/stringnamevalue';
import { SearchSelectService } from './searchselect.service';
import { DataSource } from '@oasisdigital/angular-material-search-select';
import { MerchantSummarySecondPart } from '../components/mergemerchants/merchantsummary';
import { EnrichMerchantState } from '../components/enrichmerchant/enrichmerchantstate';
import { OverallResult } from '../enums/enums';
import { apiPathConstants } from '../core/api/apipathconstants';
import { EnrichMerchantEventingRequest } from '../components/scrapingdatasources/scrapingdatadto';
import { AusIndexedComments } from '../components/enrichmerchant/enrichmerchant.models';

@Injectable()
export class EnrichMerchantService {
  private templates: StringNameValue[] = [
    {
      name: "FAQ page and contact details",
      value: "Customer service contact options are available using the link provided. <br />" + "1) Use the FAQ to find answers to your query. <br />" +
        "2) If unsuccessful call customer service on [phone] of via at [email]."
    },
    {
      name: "Contact form and contact details",
      value: "Customer service contact options are available using the link provided. <br />" + "1) Follow the support link and select the option that best suits your query and follow the prompts/fill out details. <br />" +
        "2) Alternatively, contact customer support on [phone] or via email at [email]."
    },
    {
      name: "Contact form requires log-in",
      value: "Customer service contact options are available using the link provided. <br />" + "1) Follow the support link and select the option that best suits your query and follow the prompts/fill out details. <br />" +
        "2) Alternatively, contact customer support on [phone] or via email at [email]."
    },
    {
      name: "No FAQ or contact form",
      value: "Customer service contact options are available using the link provided. Contact can be made via email at [email] or by calling [phone]."
    },
    {
      name: "Contact form PLUS phone number of email found",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful or if you made your purchase online, please follow these steps: <br />" + "1) Fill out the contact from via the support link provided. <br />" +
        "2) Alternatively, contact customer support on [phone] or via email at [email]."
    },
    {
      name: "Contact Form PLUS other contact details like phone number of email found-(No online ordering)",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful, please follow these steps: <br />" + "1) Fill out the contact from via the support link provided. <br />" +
        "2) Alternatively, contact customer service via email at [email] of call on [phone]."
    },
    {
      name: "Contact Form-No other details found(No online ordering)",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful, please fill out the contact form via the support link provided."
    },
    {
      name: "Only contact us form-no other contact",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful or if you made your purchase online, please fill out the contact form via the support link provided."
    },
    {
      name: "Only FAQ page provided & other contacts",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful, contact customer service via email at [email] or call on [phone]."
    },
    {
      name: "Only FAQ page provided",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful, or if you made your purchase online, please review the FAQ to find the answers to your query, via the support link."
    },
    {
      name: "Only FAQ page provided-(No Online Ordering)",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful, please review the FAQ to find answers to your query, via the support link."
    },
    {
      name: "No Contact Form only contact details(No online ordering)",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful, contact customer service via email at [email] or call on [phone]."
    },
    {
      name: "No Contact Form only contact details",
      value: "Please contact your purchase store using the details provided. If contact is unsuccessful, or if you made your puchase online, contact customer service via email at [email] or call on [phone]."
    },
    {
      name: "Different contacts for different locations/no HQ contacts",
      value: "Please contact your purchase store using the details provided via the support link."
    }
  ];

  constructor(private apiService: ApiService, private apiClient: ApiClient, private searchSelectService: SearchSelectService) { }

  enrich = (enrichMerchantRequest: Partial<EnrichMerchantRequest>) => {
    return this.apiClient.post<AUS_Indexing_Result>(this.apiService
      .buildUrl('merchant/enrich'), enrichMerchantRequest);
  }

  getQueryTransactionProcessTemplates() {
    return this.templates;
  }

  getTeplatesDatasource(referenceData: StringNameValue[]): DataSource {
    let filterFn = (c: StringNameValue, term: string) => c.name.toLowerCase().includes(term);
    let mapFn = (nameValue: StringNameValue) => ({
      value: nameValue,
      display: nameValue.name,
      details: ''
    });

    return this.searchSelectService.getDataSource(referenceData, filterFn, mapFn);
  }

  previewQTPTempate(secondPart: MerchantSummarySecondPart, preview: string) {
    if (secondPart) {
      if (secondPart.PhoneNumber) {
        preview = preview.replace('[phone]', secondPart.PhoneNumber);
      }
      if (secondPart.Email) {
        preview = preview.replace('[email]', secondPart.Email);
      }
    }
    return preview;
  }

  prepareEnrichMerchantRequest(cals: string[], lwcId: number) {
    let requests: Partial<EnrichMerchantState>[] = [];
    for (let cal of cals) {
      requests.push({ TransactionDescription: cal, LWC_ID: lwcId });
    }
    return requests;
  }

  getResultText(indexingResult: AUS_Indexing_Result): string {
    let outcome: string = '';
    if (indexingResult.BuildMerchantRecordOutcome === OverallResult.Incomplete) {
      outcome = "Incomplete"
    }
    if (indexingResult.BuildMerchantRecordOutcome === OverallResult.AlreadyIndexed) {
      outcome = "Already Indexed"
    }
    if (indexingResult.BuildMerchantRecordOutcome === OverallResult.IndexingFailed) {
      outcome = "Indexing Failed"
    }
    if (indexingResult.BuildMerchantRecordOutcome === OverallResult.Error) {
      outcome = "Error"
    }
    if (indexingResult.BuildMerchantRecordOutcome === OverallResult.Warning) {
      outcome = "Warning"
    }
    if (indexingResult.BuildMerchantRecordOutcome === OverallResult.Success) {
      outcome = "Success"
    }
    return `Result: ${outcome}; Score: ${indexingResult.DataRichnessScore}; Time: ${indexingResult.ExecutionTimeInSeconds} sec. - ${indexingResult.Comment}`;
  }

  enrichMerchant(enrichedMercant: EnrichMerchantEventingRequest) {
    return this.apiClient.post(this.apiService.buildUrl(apiPathConstants.eventingEnrichMerchant), enrichedMercant);
  }

  getAusIndexedComments(lwcId: number) {
    return this.apiClient.get<AusIndexedComments[]>(this.apiService.buildUrl(apiPathConstants.indexingComment, lwcId.toString()));
  }

  deleteAusIndexedComment(lwcId: number, ausIndexedCommentID: number) {
    return this.apiClient.delete<void>(this.apiService.buildUrl(apiPathConstants.indexingComment, lwcId.toString(), ausIndexedCommentID.toString()));
  }

  saveAusIndexedComment(body: Partial<AusIndexedComments>, lwcId: number) {
    return this.apiClient.post<AusIndexedComments>(this.apiService.buildUrl(apiPathConstants.indexingComment, lwcId.toString()), body);
  }
}
