import { ExecutionResult } from 'graphql';
import { BankAccountTransactionType } from '../../models/ausindexingresult';

export let bankAccountTransactionTypesResponse: ExecutionResult<BankAccountTransactionType[]> = {
  data: [
    {
      name: "Unknown",
      value: 0
    },
    {
      name: "DirectDebit",
      value: 1
    },
    {
      name: "DirectCredit",
      value: 2
    },
    {
      name: "BPay",
      value: 3
    },
    {
      name: "Salary",
      value: 4
    },
    {
      name: "Dividend",
      value: 5
    },
    {
      name: "InterestPaid",
      value: 6
    },
    {
      name: "InterestCharged",
      value: 7
    },
    {
      name: "Atm",
      value: 8
    },
    {
      name: "Transfer",
      value: 9
    },
    {
      name: "Fees",
      value: 10
    },
    {
      name: "Card",
      value: 11
    }
  ]
};
