import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getBsbsResponse } from '../mockjsonresponses/bsbsresponse';

export function bsbBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes(apiPathConstants.bsb) && method === "GET") {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: [...getBsbsResponse]
      }));
      resp.complete();
    });
  }

}
