import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { batchSplitCalResponse } from '../mockjsonresponses/batchsplitcalresponse';

export function batchCalSplitterBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  let cleanUrl = url.split('?')[0];

  if (cleanUrl.includes(apiPathConstants.batchsplitcal) && method === HttpMethod.Post) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: {...batchSplitCalResponse}
      }));
      resp.complete();
    });
  }
}
