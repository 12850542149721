import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { chainAuditResponse } from "../mockjsonresponses/chainauditresponse";
import { HttpMethod } from "../../core/coreenums/coreenums";


export function chainAuditBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const cleanUrl = url.split('?')[0];
    let includeChainAudit = false;

    if(request.body && request.body.query) {
        includeChainAudit = request.body.query.includes('chainAudit');
    }
    if(cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includeChainAudit) {
        return new Observable(resp => {
            resp.next(new HttpResponse({
                status: 200,
                body: {...chainAuditResponse}
            }));
             resp.complete();
        });
    }
}
