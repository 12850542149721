import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getNamesByAbnAcnResponse } from '../mockjsonresponses/getnamesbyabnacnresponse';
import { getAddressesByAbnAcnResponse } from '../mockjsonresponses/getaddressesbyabnacnresponse';
import { HttpMethod } from '../../core/coreenums/coreenums';
import { merchantsByAbnOrAcnResponse } from '../mockjsonresponses/merchantsbyabnoracnresponse';

export function abnAcnLookupBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if ((cleanUrl.includes('abnacnlookup/getnamesbyabn') || cleanUrl.includes('abnacnlookup/getnamesbyacn') ||
    cleanUrl.includes('abnacnlookup/refreshabr'))
    && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: getNamesByAbnAcnResponse
      }));
      resp.complete();
    });
  }

  if ((cleanUrl.includes('abnacnlookup/getaddressbyabn') || cleanUrl.includes('abnacnlookup/getaddressbyacn'))
    && method === HttpMethod.Get) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: getAddressesByAbnAcnResponse
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('api/purge/businessNameId/')
    && method === HttpMethod.Delete) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: getNamesByAbnAcnResponse
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('api/purge/businessAddressId/')
    && method === HttpMethod.Delete) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: getAddressesByAbnAcnResponse
      }));
      resp.complete();
    });
}

  if (cleanUrl.includes(apiPathConstants.getMerchantsByAbn) || cleanUrl.includes(apiPathConstants.getMerchantsByAcn) && method === HttpMethod.Get) {
      return new Observable(resp => {
        resp.next(new HttpResponse({
            status: 200,
            body: [ ...merchantsByAbnOrAcnResponse ]
        }));
        resp.complete();
      });
  }
}
