export enum MerchantEventState {
  Open,
  Resolved,
  NoIssue
}

export let merchantEventStateNames = new Map<MerchantEventState, string>([
  [MerchantEventState.Open,'Open'],
  [MerchantEventState.Resolved,'Resolved'],
  [MerchantEventState.NoIssue,'No issue'],
]);
