import { ImageCropperModule } from 'ngx-image-cropper';
import { ComponentStateService } from './services/componentstate.service';
import { DateTimeService } from './core/uiservices/datetime.service';
import { EditUserComponent } from './users/edituser/edituser.component';
import { UserService } from './users/applicationuser.service';
import { ManageUsersComponent } from './users/manageusers/manageusers.component';
import { PickChainService } from './services/pickchain.service';
import { ReferenceService } from './services/reference.service';
import { EnrichMerchantService } from './services/enrichmerchant.service';
import { EnrichMerchantComponent } from './components/enrichmerchant/enrichmerchant.component';
import { IndexMerchantService } from './services/indexmerchant.service';
import { LwcNgxJsonViewerModule } from './modules/lwc-ngx-json-viewer/lwc-ngx-json-viewer.module';
import { ChainmerchantsearchComponent } from './components/chainmerchantsearch/chainmerchantsearch.component';
import { LwcmerchantsearchComponent } from './components/lwcmerchantsearch/lwcmerchantsearch.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NameCatalogueService } from './services/namecatalogue.service';
import { PastSearchService } from './services/pastsearch.service';
import { PurgeMerchantService } from './services/purgemerchant.service';
import { MasterCardLookupService } from './services/mastercardlookup.service';
import { AbnAcnLookupService } from './services/abnacnlookup.service';
import { WhoislookupService } from './services/whoislookup.service';
import { MerchantlookupService } from './services/merchantlookup.service';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NameCatalogueComponent } from './components/namecatalogue/namecatalogue.component';
import { PastSearchComponent } from './components/pastsearch/pastsearch.component';
import { CalSplitterComponent } from './components/calsplitter/calsplitter.component';
import { PurgeMerchantComponent } from './components/purgemerchant/purgemerchant.component';
import { IndexMerchantComponent } from './components/indexmerchant/indexmerchant.component';
import { MasterCardLookupComponent } from './components/mastercardlookup/mastercardlookup.component';
import { AbnAcnLookupComponent } from './components/abnacnlookup/abnacnlookup.component';
import { WhoislookupComponent } from './components/whoislookup/whoislookup.component';
import { MerchantlookupComponent } from './components/merchantlookup/merchantlookup.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { ConfirmDialogComponent } from './shared/confirmdialog/confirmdialog.component';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';


import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalSplitterService } from './services/calsplitter.service';
import { SecureComponent } from './components/secure/secure.component';
import { MockBackendInterceptor } from './dev/mockhttpinterceptor';
import { CardAcceptorLocatorComponent } from './components/cardacceptorlocators/cardacceptorlocator.component';
import { Angular2CsvModule } from 'angular2-csv';
import { CsvExportComponent } from './components/csvexport/csvexport.component';
import { BaseStateComponent } from './components/basestate/basestate.component';
import { CalHistoryComponent } from './components/calhistory/calhistory.component';
import { CALService } from './services/cal.service';
import { SearchSelectService } from './services/searchselect.service';
import { AuthInterceptor } from './core/auth/AuthInterceptor';
import { AuthGuard } from './core/auth/authguard';
import { SharedModule } from './shared/shared.module';
import { ApiInfoService } from './services/apiinfo.service';
import { AboutDialogComponent } from './aboutdialog/aboutdialog.component';
import { CacheService } from './core/cache/cache.service';
import { HotkeyModule } from 'angular2-hotkeys';
import { ManagetidmidComponent } from './tidmid/managetidmid/managetidmid.component';
import { TidmidService } from './tidmid/tidmid.service';
import { ChangePasswordComponent } from './users/changepassword/changepassword.component';
import { RoleGuard } from './core/auth/roleguard';
import { ChangeCalAllocationComponent } from './components/changecalallocation/changecalallocation.component';
import { RegionStateService } from './core/cache/regionstate.service';
import { RoleType } from './users/userenums';
import { BatchMerchantSearchComponent } from './components/batchmerchantsearch/batchmerchantsearch.component';
import { BatchRequestService } from './services/batchsearchrequest.service';
import { MerchantSearchExporterService } from './services/merchantsearchexporter.service';
import { CategoryAllocationPatternsComponent } from './components/categoryallocationpatterns/categoryallocationpatterns.component';
import { CategoryAllocationPatternsDeactivateGuard } from './components/categoryallocationpatterns/categoryallocationpatternsdeactivateguard';
import { ManageCategoriesComponent } from './components/managecategories/managecategories.component';
import { CategoriesComponentService } from './categories/categoriescomponent.service';
import { CategoryService } from './services/category.service';
import { CategoryAllocationPatternService } from './services/categoryallocationpattern.service';
import { MissedCalsComponent } from './components/missedcals/missedcals.component';
import { MissedCalsService } from './services/missedcals.service';
import { ManageChainsComponent } from './components/managechains/managechains.component';
import { EditChainComponent } from './components/managechains/editchain/editchain.component';
import { ManageChainsDataService } from './components/managechains/managechainsdata.service';
import { ManageInternationalChainsComponent } from './components/manageinternationalchains/manageinternationalchains.component';
import { EditInternationalChainComponent } from './components/manageinternationalchains/editinternationalchain/editinternationalchain.component';
import { ManageInternationalChainsDataService } from './components/manageinternationalchains/manageinternationalchainsdata.service';
import { ManageInternationalChainsDeactivateGuard } from './components/manageinternationalchains/manageinternationalchainsdeactivateguard';
import { MerchantExtractComponent } from './components/merchantextract/merchantextract.component';
import { MerchantExtractService } from './services/merchantextract.service';
import { MenuItemComponent } from './components/sidenav/menuitem/menuitem.component';
import { MenuItemService } from './components/sidenav/menuitem/menuitem.service';
import { HighlightService } from './core/uiservices/highlight.service';
import { AdalService } from 'adal-angular4';
import { AuthCallbackComponent } from './components/public/authcallback/authcallback.component';
import { MergeMerchantsComponent } from './components/mergemerchants/mergemerchants.component';
import { MergeMerchantsService } from './services/mergemerchants.service';
import { IconResultService } from './core/uiservices/iconresult.service';
import { MerchantEventsComponent } from './components/merchantevents/merchantevents.component';
import { MerchantEventService } from './services/merchantevent.service';
import { EditMerchantEventComponent } from './components/merchantevents/editmerchantevent/editmerchantevent.component';
import { OverlayService } from './shared/progressspinner/overlay.service';
import { LookupOptionService } from './services/lookupoption.service';
import { ComparerService } from './core/services/comparer.service';
import { MerchantPurgeResultComponent } from './components/purgemerchant/merchantpurgeresult/merchantpurgeresult.component';
import { MoneyTrackerSearchComponent } from './components/moneytrackersearch/moneytrackersearch.component';
import { MerchantSummaryJsonViewerService } from './services/merchantsummaryjsonviewer.service';
import { MerchantvalidationComponent } from './components/merchantvalidation/merchantvalidation.component';
import { MerchantSummaryJsonViewerComponent } from './components/merchantsummaryjsonviewer/merchantsummaryjsonviewer.component';
import { QuarantinedMerchantsComponent } from './components/quarantinedmerchants/quarantinedmerchants.component';
import { QuarantinedCalsComponent } from './components/quarantinedcals/quarantinedcals.component';
import { ApiClientsComponent } from './components/apiclients/apiclients.component';
import { GraphQLModule } from './graphql.module';
import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SearchFeedBackComponet } from './components/searchfeedback/searchfeedback.component';
import { ApiClientDataService } from './components/apiclients/apiclientsdata.service';
import { EditApiClientComponent } from './components/apiclients/editapiclient/editapiclient.component';
import { RedisCacheLookupComponet } from './components/rediscachelookup/rediscachelookup.component';
import { MerchantChainLogosComponent } from './components/merchantchainlogos/merchantchainlogos.component';
import { ChainAuditComponent } from './components/managechains/chainaudit/chainaudit.component';
import { CategoryStandardiserComponent } from './components/categorystandardiser/categorystandardiser.component';
import { CategoryStandardiserService } from './components/categorystandardiser/categorystandardiserservice';
import { ChainPatternMatcherComponent } from './components/chainpatternmatcher/chainpatternmatcher.component';
import { AuthService } from './services/auth.service';
import { AuthServiceMock } from './dev/auth/auth.service.mock';
import { ManageLocationsComponent } from './components/managelocations/managelocations.component';
import { ManageLocationService } from './services/managelocations.service';
import { EditLocationComponent } from './components/managelocations/editlocation/editlocation.component';
import { BatchEnrichResultComponent } from './components/batchenrichresult/batchenrichresult.component';
import { ManagePersonNamesComponent } from './components/managepersonnames/managepersonnames.component';
import { BatchPastSearchesComponent } from './components/batchpastsearches/batchpastsearches.component';
import { ManageDataScrapingComponent } from './components/managedatascraping/managedatascraping.component';
import { EditDataScrapingComponent } from './components/managedatascraping/editdatascraping/editdatascraping.component';
import { WebScrapingDetailComponent } from './components/managedatascraping/viewdata/webscrapingdetail.component';
import { ScrapingDataSourcesComponent } from './components/scrapingdatasources/scrapingdatasources.component';
import { SrapingDatasourcesService } from './services/scrapingdatasources.service';
import { CalParserComponent } from './components/calparser/calparser.component';
import { ScrapedDataComponent } from './components/scrapingdatasources/scrapeddata/scrapeddata.component';
import { MerchantOpeningHoursComponent } from './components/merchantopeninghours/merchantopeninghours.component';
import { MerchantOpeningHoursService } from './services/merchantopeninghours.service';
import { UnmatchedMerchantsComponent } from './components/managedatascraping/unmatchedmerchants/unmatchedmerchants.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BrokenLinksComponent } from './components/brokenlinks/brokenlinks.component';
import { MerchantLogoComponent } from './components/merchantlogo/merchantlogo.component';
import { BulkLogoEditComponent } from './components/bulklogoedit/bulklogoedit.component';
import { ImageComponent } from './components/image/image.component';
import { TagsComponent } from './components/tags/tags.component';
import { ChainStoresComponent } from './components/chainstores/chainstores.component';
import { OperationalChecksComponent } from './components/operationalchecks/operationalchecks.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { AddressCheckComponent } from './components/addresscheck/addresscheck.component';
import { MerchantAuditComponent } from './components/merchantaudit/merchantaudit.component';
import { MerchantNameSearchComponent } from './components/merchantnamesearch/merchantnamesearch.component';
import { CategoryMappingRulesComponent } from './components/categorymappingrules/categorymappingrules.component';
import { EditCategoryMappingRuleComponent } from './components/categorymappingrules/editcategorymappingrule/editcategorymappingrule.component';
import { SubscriptionsByMerchantComponent } from './components/subscriptionsbymerchant/subscriptionsbymerchant.component';
import { TagAllocationPatternsComponent } from './components/tagallocationpatterns/tagallocationpatterns.component';
import { BatchCalSplitterComponent } from './components/batchcalsplitter/batchcalsplitter.component';
import { ManageMissingMerchantsComponent } from './components/managemissingmerchants/managemissingmerchants.component';
import { GroupOfSimilarCalsComponent } from './components/cardacceptorlocators/groupofsimilarcals/groupofsimilarcals.component';
import { ManageBsbsComponent } from './components/managebsbs/managebsbs.component';
import { ManageApiUsageComponent } from './components/manageapiusage/manageapiusage.component';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ManageWestfieldLocation } from './components/managewestfieldlocation/managewestfieldlocation.component';
import { SuburbStateCodeComponent } from './components/suburbstatecode/suburbstatecode.component';
import { ManageWestfieldRetailerComponent } from './components/managewestfiledretailer/managewestfieldretailer.component';
import { MerchantValidationStatsComponent } from './components/merchantvalidationstats/merchantvalidationstats.component';
import { ValidationStatsDetailComponent } from './components/merchantvalidationstats/validationstatsdetail/validationstatsdetail.component';
import { CacheRefreshComponent } from './components/cacherefresh/cacherefresh.component';
import { CategoryTrees } from './components/categorytrees/categorytrees.component';
import { BPayLookup } from './components/bpaylookup/bpaylookup.component';
import { ManageExternalCategories } from './components/manageexternalcategories/manageexternalcategories.component';
import { ThreeStateToggleCheckBoxComponent } from './components/threewaytogglecheckbox/threewaytogglecheckbox/threestatetogglecheckbox.component';
import { ManageclientComponent } from './components/manageclient/manageclient.component';
import { ManageclientDialogComponent } from './components/manageclient/manageclient-dialog/manageclient-dialog.component';
import { NumberOnly } from './shared/directives/numberOnly.directive';
import { ManageapikeysComponent } from './components/manageapikeys/manageapikeys.component';
import { ManageapiDialogComponent } from './components/manageapikeys/manageapi-dialog/manageapi-dialog.component';
import { DatePipe } from '@angular/common';
import { UpcomingexpiryComponent } from './components/upcomingexpiry/upcomingexpiry.component';
import { CategoryMappingDialogComponent } from './components/managecategories/category-mapping-dialog/category-mapping-dialog.component';
import { ElasticSearchComponent } from './components/elastic-search/elastic-search.component';
import { EditDescriptionDialogComponent } from './components/categoryallocationpatterns/edit-description-dialog/edit-description-dialog.component';
import { ThemeManagerService } from './services/theme-manager.service';
import { MatDialogModule } from '@angular/material/dialog';

const secureRoutes: Routes = [
  { path: 'merchantlookup', component: MerchantlookupComponent },
  { path: 'whoislookup', component: WhoislookupComponent },
  { path: 'abnacnlookup', component: AbnAcnLookupComponent },
  { path: 'mastercardlookup', component: MasterCardLookupComponent },
  { path: 'indexmerchant', component: IndexMerchantComponent },
  { path: 'indexmerchant/:cal', component: IndexMerchantComponent },
  { path: 'purgemerchant', component: PurgeMerchantComponent },
  { path: 'calsplitter', component: CalSplitterComponent },
  { path: 'pastsearches', component: PastSearchComponent },
  { path: 'namecatalogue', component: NameCatalogueComponent },
  { path: 'enrichmerchant', component: EnrichMerchantComponent },
  { path: 'merchantvalidation', component: MerchantvalidationComponent },
  {
    path: 'manageusers', component: ManageUsersComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin] }
  },
  {
    path: 'edituser/:emailId', component: EditUserComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin] }
  },
  {
    path: 'edituser', component: EditUserComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin] }
  },
  {
    path: 'changepassword/:emailId', component: ChangePasswordComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin] }
  },
  {
    path: 'managetidmid', component: ManagetidmidComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin] }
  },
  {
    path: 'manageclient', component: ManageclientComponent,
    canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'cardacceptorlocators', component: CardAcceptorLocatorComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  { path: 'mergemerchants', component: MergeMerchantsComponent },
  { path: 'quarantinedmerchants', component: QuarantinedMerchantsComponent },
  { path: 'quarantinedcals', component: QuarantinedCalsComponent },
  {
    path: 'calhistory', component: CalHistoryComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'categoryallocationpatterns', component: CategoryAllocationPatternsComponent, canActivate: [RoleGuard], canDeactivate: [CategoryAllocationPatternsDeactivateGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'managecategories', component: ManageCategoriesComponent,
    //canActivate: [RoleGuard], data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'batchmerchantsearch', component: BatchMerchantSearchComponent,
    canActivate: [RoleGuard], data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'missedcals', component: MissedCalsComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'managechains', component: ManageChainsComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'editchain', component: EditChainComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'editchain/:chainId', component: EditChainComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'editapiclient', component: EditApiClientComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'manageinternationalchains', component: ManageInternationalChainsComponent, canActivate: [RoleGuard], canDeactivate: [ManageInternationalChainsDeactivateGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'editinternationalchain', component: EditInternationalChainComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'merchantextract', component: MerchantExtractComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'merchantevents', component: MerchantEventsComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'editmerchantevent/:merchantEventId', component: EditMerchantEventComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'moneytrackersearch', component: MoneyTrackerSearchComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'apiclients', component: ApiClientsComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'categorystandardiser', component: CategoryStandardiserComponent
  },
  {
    path: 'suggestions', component: SuggestionsComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'searchfeedback', component: SearchFeedBackComponet, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'rediscachelookup', component: RedisCacheLookupComponet
  },
  {
    path: 'merchantchainlogos', component: MerchantChainLogosComponent
  },
  {
    path: 'chainpatternmatcher', component: ChainPatternMatcherComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'managelocations', component: ManageLocationsComponent,
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'editlocation', component: EditLocationComponent,
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'managepersonnames', component: ManagePersonNamesComponent,
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'batchpastsearches', component: BatchPastSearchesComponent
  },
  {
    path: 'managedatascraping', component: ManageDataScrapingComponent
  },
  {
    path: 'editdatascraping/:chainId', component: EditDataScrapingComponent
  },
  {
    path: 'webscrapingdetail/:chainCode', component: WebScrapingDetailComponent
  },
  {
    path: 'scrapingdatasources', component: ScrapingDataSourcesComponent
  },
  {
    path: 'calparser', component: CalParserComponent
  },
  {
    path: 'scrapeddata/:source', component: ScrapedDataComponent
  },
  {
    path: 'merchantopeninghours', component: MerchantOpeningHoursComponent
  },
  {
    path: 'unmatchedmerchants/:chainId', component: UnmatchedMerchantsComponent
  },
  {
    path: 'brokenlinks', component: BrokenLinksComponent
  },
  {
    path: 'merchantlogo', component: MerchantLogoComponent
  },
  {
    path: 'bulklogoedit', component: BulkLogoEditComponent
  },
  {
    path: 'tags', component: TagsComponent
  },
  {
    path: 'chainstores', component: ChainStoresComponent
  },
  {
    path: 'operationalchecks', component: OperationalChecksComponent
  },
  {
    path: 'addresscheck', component: AddressCheckComponent
  },
  {
    path: 'merchantnamesearch', component: MerchantNameSearchComponent
  },
  {
    path: 'categorymappingrules', component: CategoryMappingRulesComponent
  },
  {
    path: 'editcategorymappingrule', component: EditCategoryMappingRuleComponent
  },
  {
    path: 'subscriptionsbymerchant', component: SubscriptionsByMerchantComponent
  },
  {
    path: 'tagallocationpatterns', component: TagAllocationPatternsComponent
  },
  {
    path: 'batchcalsplitter', component: BatchCalSplitterComponent
  },
  {
    path: 'managemissingmerchants', component: ManageMissingMerchantsComponent
  },
  {
    path: 'groupofsimilarcals', component: GroupOfSimilarCalsComponent
  },
  {
    path: 'managebsbs', component: ManageBsbsComponent
  },
  {
    path: 'manageapiusage', component: ManageApiUsageComponent
  },
  {
    path: 'managewestfieldlocation', component: ManageWestfieldLocation
  },
  {
    path: 'suburbstatecode', component: SuburbStateCodeComponent
  },
  {
    path: 'managewestfieldretailer', component: ManageWestfieldRetailerComponent
  },
  {
    path: 'merchantvalidationstats', component: MerchantValidationStatsComponent
  },
  {
    path: 'referencedatacacherefresh', component: CacheRefreshComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin] }
  },
  {
    path: 'bpaylookup', component: BPayLookup
  },
  {
    path: 'manageexternalcategories', component: ManageExternalCategories
  },
  {
    path: 'manageclient', component: ManageclientComponent,
    canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'manageapikeys', component: ManageapikeysComponent,
    canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
  {
    path: 'upcomingexpiry', component: UpcomingexpiryComponent
  },
  {
    path: 'elasticsearchcalsrefresh', component: ElasticSearchComponent, canActivate: [RoleGuard],
    data: { roles: [RoleType.IndexManagerAdmin, RoleType.IndexManagerTeamLead] }
  },
];

const routes: Routes = [
  { path: '', redirectTo: '/merchantlookup', pathMatch: 'full', },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: '', component: SecureComponent, canActivate: [AuthGuard], children: secureRoutes },
  { path: '**', redirectTo: '' }
];

let providers: any[] = [
  { provide: LOCALE_ID, useValue: 'en-AU' },
  { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  MerchantlookupService,
  WhoislookupService,
  AbnAcnLookupService,
  MasterCardLookupService,
  PurgeMerchantService,
  CalSplitterService,
  PastSearchService,
  NameCatalogueService,
  IndexMerchantService,
  EnrichMerchantService,
  ReferenceService,
  PickChainService,
  SearchSelectService,
  UserService,
  DateTimeService,
  CALService,
  ComponentStateService,
  ApiInfoService,
  AuthGuard,
  RoleGuard,
  CacheService,
  TidmidService,
  RegionStateService,
  BatchRequestService,
  MerchantSearchExporterService,
  CategoryAllocationPatternsDeactivateGuard,
  CategoriesComponentService,
  CategoryService,
  CategoryAllocationPatternService,
  MissedCalsService,
  ManageChainsDataService,
  ManageInternationalChainsDataService,
  ManageInternationalChainsDeactivateGuard,
  MerchantExtractService,
  MenuItemService,
  HighlightService,
  AdalService,
  MergeMerchantsService,
  IconResultService,
  MerchantEventService,
  OverlayService,
  LookupOptionService,
  ComparerService,
  MerchantSummaryJsonViewerService,
  ApiClientDataService,
  CategoryStandardiserService,
  ManageLocationService,
  SrapingDatasourcesService,
  MerchantOpeningHoursService,
  DatePipe,
  ThemeManagerService
];

providers.push(
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
);
// use mock backend if env variable is set
if (environment.useMockBackend) {
  providers.push(
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockBackendInterceptor,
      multi: true
    }
  );
  providers.push({ provide: AuthService, useClass: AuthServiceMock })
} else {
  providers.push({ provide: AuthService, useClass: AuthService })
}

@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    ConfirmDialogComponent,
    ToolbarComponent,
    SidenavComponent,
    MerchantlookupComponent,
    WhoislookupComponent,
    LwcmerchantsearchComponent,
    ChainmerchantsearchComponent,
    AbnAcnLookupComponent,
    MasterCardLookupComponent,
    IndexMerchantComponent,
    PurgeMerchantComponent,
    CalSplitterComponent,
    PastSearchComponent,
    NameCatalogueComponent,
    EnrichMerchantComponent,
    ManageUsersComponent,
    EditUserComponent,
    SecureComponent,
    CardAcceptorLocatorComponent,
    CsvExportComponent,
    BaseStateComponent,
    CalHistoryComponent,
    AboutDialogComponent,
    ManagetidmidComponent,
    ChangePasswordComponent,
    ChangeCalAllocationComponent,
    BatchMerchantSearchComponent,
    CategoryAllocationPatternsComponent,
    ManageCategoriesComponent,
    MissedCalsComponent,
    ManageChainsComponent,
    EditChainComponent,
    ChainAuditComponent,
    ManageInternationalChainsComponent,
    EditInternationalChainComponent,
    MerchantExtractComponent,
    MenuItemComponent,
    AuthCallbackComponent,
    MergeMerchantsComponent,
    MerchantEventsComponent,
    EditMerchantEventComponent,
    MerchantPurgeResultComponent,
    MoneyTrackerSearchComponent,
    MerchantvalidationComponent,
    MerchantSummaryJsonViewerComponent,
    QuarantinedMerchantsComponent,
    QuarantinedCalsComponent,
    ApiClientsComponent,
    SuggestionsComponent,
    SearchFeedBackComponet,
    EditApiClientComponent,
    RedisCacheLookupComponet,
    MerchantChainLogosComponent,
    CategoryStandardiserComponent,
    ChainPatternMatcherComponent,
    ManageLocationsComponent,
    EditLocationComponent,
    BatchEnrichResultComponent,
    ManagePersonNamesComponent,
    BatchPastSearchesComponent,
    ManageDataScrapingComponent,
    EditDataScrapingComponent,
    WebScrapingDetailComponent,
    ScrapingDataSourcesComponent,
    CalParserComponent,
    ScrapedDataComponent,
    MerchantOpeningHoursComponent,
    UnmatchedMerchantsComponent,
    PaginationComponent,
    BrokenLinksComponent,
    MerchantLogoComponent,
    BulkLogoEditComponent,
    ImageComponent,
    TagsComponent,
    ChainStoresComponent,
    OperationalChecksComponent,
    ImageCropperComponent,
    AddressCheckComponent,
    MerchantAuditComponent,
    MerchantNameSearchComponent,
    CategoryMappingRulesComponent,
    EditCategoryMappingRuleComponent,
    SubscriptionsByMerchantComponent,
    TagAllocationPatternsComponent,
    BatchCalSplitterComponent,
    ManageMissingMerchantsComponent,
    GroupOfSimilarCalsComponent,
    ManageBsbsComponent,
    ManageApiUsageComponent,
    ManageWestfieldLocation,
    SuburbStateCodeComponent,
    ManageWestfieldRetailerComponent,
    MerchantValidationStatsComponent,
    ValidationStatsDetailComponent,
    CacheRefreshComponent,
    CategoryTrees,
    BPayLookup,
    ManageExternalCategories,
    ThreeStateToggleCheckBoxComponent,
    ManageclientComponent,
    ManageclientDialogComponent,
    NumberOnly,
    ManageapikeysComponent,
    ManageapiDialogComponent,
    UpcomingexpiryComponent,
    CategoryMappingDialogComponent,
    ElasticSearchComponent,
    EditDescriptionDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    ClipboardModule,
    LwcNgxJsonViewerModule,
    CoreModule,
    MatSlideToggleModule,
    MatIconModule,
    Angular2CsvModule,
    SharedModule,
    HotkeyModule.forRoot(),
    MatExpansionModule,
    GraphQLModule,
    NgxChartsModule,
    NgxMaterialTimepickerModule,
    MatProgressBarModule,
    MatSliderModule,
    ImageCropperModule,
    MatDialogModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: providers,
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent, ConfirmDialogComponent,
    AboutDialogComponent, ImageComponent, ImageCropperComponent]
})
export class AppModule {

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }
  appInsights: ApplicationInsights;
}

