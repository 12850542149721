import { ChainType } from "../../components/indexmerchant/chaintype";

export let chainTypeStringValues = new Map<ChainType, string>([
  [ChainType.Unknown, 'Unknown'],
  [ChainType.HeadOffice, 'HeadOffice'],
  [ChainType.Branch, 'Branch'],
  [ChainType.Franchise, 'Franchise'],
  [ChainType.Both, 'Both'],
  [ChainType.StandardResponse, 'StandardResponse'],
  [ChainType.NotAChain, 'NotAChain'],
  [ChainType.MerchantPattern, 'MerchantPattern'],
  [ChainType.ATMPattern, 'ATMPattern'],
  [ChainType.Product, 'Product'],
  [ChainType.BankMessagePattern, 'BankMessagePattern'],
  [ChainType.BankBranch, 'BankBranch'],
  [ChainType.Salary, "Salary"],
  [ChainType.InvalidCalPattern, "InvalidCalPattern"]
]);

export let isItPartOfAChain = [
  { value: <number>null, text: '' },
  { value: ChainType.HeadOffice, text: 'Head office' },
  { value: ChainType.Branch, text: 'Branch' },
  { value: ChainType.Franchise, text: 'Franchise' },
  { value: ChainType.NotAChain, text: 'Not A Chain' },
  { value: ChainType.BankBranch, text: 'Bank Branch'},
];
