import { ApiService } from "../core/api/api.service";
import { ApiClient } from "../core/api/apiclient";
import { Injectable } from "@angular/core";
import { CalCount } from "../components/missedcals/calcount";
import { APIClients } from "../enums/enums";
import { apiPathConstants } from "../core/api/apipathconstants";

@Injectable()
export class MissedCalsService {

	constructor(private apiService: ApiService, private apiClient: ApiClient) { }

	searchMissedCals(maxResults: number, hours: number, apiClientFilter: APIClients) {
		return this.apiClient.get<CalCount[]>(this.apiService.buildUrl(apiPathConstants.missedCals, maxResults.toString(),
			hours.toString(), apiClientFilter ? apiClientFilter.toString() : null));
	}
}