import { ApiClient } from '../core/api/apiclient';
import { MastercardResult } from '../models/mastercardresult';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';


@Injectable()
export class MasterCardLookupService {
  private API_MASTERCARD = 'mastercard/search';

  constructor(private apiService: ApiService, private apiClient: ApiClient) {}

  getMastercard(cal: string, candidatestortn: number, forcemastercardapisearch: boolean, withintextsearch: number) {
    return this.apiClient.get<MastercardResult[]>(this.apiService
      .buildUrl(this.API_MASTERCARD, encodeURIComponent(cal), candidatestortn.toString(), forcemastercardapisearch ? "True":"False", withintextsearch == 0 ? "False":"True"));
  }
}
