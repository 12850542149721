import { commaSpace, comma, caret } from './../../core/constants/constants';
import { ValueCheckerService } from './../../core/services/valuechecker.service';
import { BaseStateComponent } from './../basestate/basestate.component';
import { Component, OnInit, Injector } from '@angular/core';
import { BatchCalSplitterState } from './batchcalsplitterstate';
import { BatchSplitOptions, paymentMethodDisplayValues } from '../../enums/enums';
import { LoadingService } from '../../core/uiservices/loading.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { BatchSplitCalRequest, CalInputModel, BatchSplitCalResponse, BatchSplitCalResponseDisplay } from './batchcalsplittermodels';
import { BatchCalSplitterService } from '../../services/batchcalsplitter.service';
import { PaymentMethod, LocatorType, batchSplitCalResponseDisplayProps } from '../../models/calparserresult';
import { CsvComponentService } from '../../core/export/csvcomponent.service';
import { TransactionService } from '../../services/transaction.service';
import { BankAccountTransactionType, TransactionBank } from '../../models/ausindexingresult';
import { BankAccountTransactionTypeService } from '../../services/bankaccounttransactiontype.service';
import { NumberNameValue } from '../../core/types/numbernamevalue';
import { NotificationService } from '../../core/uiservices/notification.service';

@Component({
  selector: 'app-batchcalsplitter',
  templateUrl: './batchcalsplitter.component.html',
  styleUrls: ['./batchcalsplitter.component.scss']
})
export class BatchCalSplitterComponent extends BaseStateComponent<BatchCalSplitterState> implements OnInit {
  locatorType = LocatorType;
  bPayPaymentMethod: PaymentMethod = PaymentMethod.BPay;
  calParserResult: BatchSplitCalResponseDisplay[] = [];
  transactionBankLists: TransactionBank[] = [];
  bankAccountTransactionTypes: BankAccountTransactionType[] = [];

  private defaultBankAccountTransactionType: string = "Unknown";
  private batchSplitCalResponse: BatchSplitCalResponse = null;
  private headers = {
    TransactionDescription: "Transaction desc",
    StandardisedTransactionDescription: "Std Trans Desc",
    CardAcceptor: "Card Acceptor",
    FormattedCardAcceptor: "Form Card Acceptor",
    CardAcceptorPredictor: "Pred Card Acceptor",
    IsPtyLtd: "PTY LTD",
    IsCompany: "Company",
    Branch: "Branch",
    Suburb: "Suburb",
    LonDisplay: "Longitude",
    LatDisplay: "Latitude",
    StateDisplay: "State",
    SuburbConfidenceDisplay: "Suburb Confidence",
    StateConfidenceDisplay: "State Confidence",
    Country: "Country",
    WebAddress: "Web Address",
    PhoneNumber: "Phone Number",
    TransactionReference: "Transaction Reference",
    FormattedTransactionAmountsDisplay: "Transaction Amounts",
    BankKeywords: "Bank Keywords",
    PaymentGateway: "Payment Gateway",
    IsRestaurant: "Restaurant",
    IsPharmacy: "Pharmacy",
    IsHotel: "Hotel",
    IsCarPark: "Car park",
    OtherKeywords: "Other Keywords",
    BPayBillerCodeDisplay: "BPay Biller Code",
    BPayCustomerReferenceNumberDisplay: "BPay Customer Reference Number",
    WhatIsTheLocatorDisplay: "What Is TheLocator",
    TransactionTypeDisplay: "Bank Account Transaction Type",
  };

  private optionHeadersAndKeys = {
    headers: [
      this.headers.TransactionDescription,
      this.headers.StandardisedTransactionDescription,
      this.headers.CardAcceptor,
      this.headers.FormattedCardAcceptor,
      this.headers.CardAcceptorPredictor,
      this.headers.IsPtyLtd,
      this.headers.IsCompany,
      this.headers.Branch,
      this.headers.Suburb,
      this.headers.LonDisplay,
      this.headers.LatDisplay,
      this.headers.StateDisplay,
      this.headers.SuburbConfidenceDisplay,
      this.headers.StateConfidenceDisplay,
      this.headers.Country,
      this.headers.WebAddress,
      this.headers.PhoneNumber,
      this.headers.TransactionReference,
      this.headers.FormattedTransactionAmountsDisplay,
      this.headers.BankKeywords,
      this.headers.PaymentGateway,
      this.headers.IsRestaurant,
      this.headers.IsPharmacy,
      this.headers.IsHotel,
      this.headers.IsCarPark,
      this.headers.OtherKeywords,
      this.headers.BPayBillerCodeDisplay,
      this.headers.BPayCustomerReferenceNumberDisplay,
      this.headers.WhatIsTheLocatorDisplay,
      this.headers.TransactionTypeDisplay,
    ],
    keys: [
      batchSplitCalResponseDisplayProps.TransactionDescription,
      batchSplitCalResponseDisplayProps.StandardisedTransactionDescription,
      batchSplitCalResponseDisplayProps.CardAcceptor,
      batchSplitCalResponseDisplayProps.FormattedCardAcceptor,
      batchSplitCalResponseDisplayProps.CardAcceptorPredictor,
      batchSplitCalResponseDisplayProps.IsPtyLtd,
      batchSplitCalResponseDisplayProps.IsCompany,
      batchSplitCalResponseDisplayProps.Branch,
      batchSplitCalResponseDisplayProps.Suburb,
      batchSplitCalResponseDisplayProps.LonDisplay,
      batchSplitCalResponseDisplayProps.LatDisplay,
      batchSplitCalResponseDisplayProps.StateDisplay,
      batchSplitCalResponseDisplayProps.SuburbConfidenceDisplay,
      batchSplitCalResponseDisplayProps.StateConfidenceDisplay,
      batchSplitCalResponseDisplayProps.Country,
      batchSplitCalResponseDisplayProps.WebAddress,
      batchSplitCalResponseDisplayProps.PhoneNumber,
      batchSplitCalResponseDisplayProps.TransactionReference,
      batchSplitCalResponseDisplayProps.FormattedTransactionAmountsDisplay,
      batchSplitCalResponseDisplayProps.BankKeywords,
      batchSplitCalResponseDisplayProps.PaymentGateway,
      batchSplitCalResponseDisplayProps.IsRestaurant,
      batchSplitCalResponseDisplayProps.IsPharmacy,
      batchSplitCalResponseDisplayProps.IsHotel,
      batchSplitCalResponseDisplayProps.IsCarPark,
      batchSplitCalResponseDisplayProps.OtherKeywords,
      batchSplitCalResponseDisplayProps.BPayBillerCodeDisplay,
      batchSplitCalResponseDisplayProps.BPayCustomerReferenceNumberDisplay,
      batchSplitCalResponseDisplayProps.WhatIsTheLocatorDisplay,
      batchSplitCalResponseDisplayProps.TransactionTypeDisplay
    ]
  };

  constructor(injector: Injector, private loadingService: LoadingService, private valueCheckerService: ValueCheckerService,
    private batchCalSplitterService: BatchCalSplitterService, private csvComponentService: CsvComponentService,
    private transactionService: TransactionService,
    private bankAccountTransactionTypeService: BankAccountTransactionTypeService,
    private notificationService: NotificationService) {
    super(injector);
  }

  ngOnInit() {
    this.state = {
      cals: "",
      batchSplitOption: BatchSplitOptions.UseBtpFull,
      useCaretForSeparates: false,
      transactionBankId: null
    };
    this.restoreState();
    this.setTransactionBankLists();
    this.setTransactionTypes();
  }

  isSearchButtonDisabled() {
    return this.valueCheckerService.isEmptyNullOrUndefined(this.state.cals) || this.state.cals.trim().length < 5;
  }

  search() {
    let batchSplitCalRequest: BatchSplitCalRequest = {
      SplitOptions: this.state.batchSplitOption,
      CalInputModels: this.getCalInputModels(this.state.useCaretForSeparates),
      TransactionBank: this.state.transactionBankId
    };
    this.loadingService.setLoading();
    this.batchCalSplitterService.getBatchSplitCals(batchSplitCalRequest)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(response => {
        if (response) {
          this.calParserResult = [];
          this.batchSplitCalResponse = response;
          for (let detailedTransaction of this.batchSplitCalResponse.DetailedTransactions) {
            detailedTransaction.isBtpFull = true;
          }

          this.setDisplayValues(response.BtpLiteTransactions);
          this.setDisplayValues(response.DetailedTransactions);
          this.setDatatableRows();
        }
      });
  }

  getRowClass(row: BatchSplitCalResponseDisplay) {
    return row.isBtpFull === true
      ? "datatable-row-alternative-background-color"
      : "datatable-row-main-background-color";
  };

  getCsvOptions() {
    let obj = this.calParserResult[0];
    obj = { ...obj };
    let options = this.csvComponentService.getCsvOptions(obj, 'BatchCalSplitter.csv');
    return { ...options, ...this.optionHeadersAndKeys };
  }

  private getCalInputModels(useCaretForSeparates: boolean) {
    let calInputModels: CalInputModel[] = [];
    let calsAndBankAccountTransactionTypes = this.state.cals.split('\n');

    for (let calAndType of calsAndBankAccountTransactionTypes) {
      let indexOfCommaOrCaret = useCaretForSeparates === false ? calAndType.indexOf(comma) : calAndType.indexOf(caret);
      let cal: string;
      let bankAccountTransactionType: string;

      if (calAndType.length === 0) {
        continue;
      }
      if (indexOfCommaOrCaret !== -1) {
        cal = calAndType.slice(0, indexOfCommaOrCaret).trim();
        bankAccountTransactionType = this.getValidBankAccountTransactionType(calAndType.slice(++indexOfCommaOrCaret).trim(), (!useCaretForSeparates && calAndType.indexOf(comma) !== -1));
      }
      else {
        cal = calAndType.slice(0).trim();
        bankAccountTransactionType = this.defaultBankAccountTransactionType;
      }
      calInputModels.push({ Cal: cal, BankAccountTransactionType: bankAccountTransactionType });
    }

    return calInputModels;
  }

  private setDisplayValues(transactions: BatchSplitCalResponseDisplay[]) {
    if (transactions.length > 0) {
      for (let transaction of transactions) {
        if (!this.valueCheckerService.isEmptyNullOrUndefined(transaction.TransactionAmounts)) {
          transaction.formattedTransactionAmountsDisplay = transaction.TransactionAmounts.map(t => t.Value + ' ' + t.Currency).join(commaSpace);
        }
        if (!this.valueCheckerService.isEmptyNullOrUndefined(transaction.TransactionType)) {
          let transactionType: NumberNameValue = this.bankAccountTransactionTypes.find(t => t.value === transaction.TransactionType);
          transaction.transactionTypeDisplay = transactionType ? transactionType.name : null;
        }
        transaction.lonDisplay = !this.valueCheckerService.isEmptyNullOrUndefined(transaction.SuburbExt)
          ? transaction.SuburbExt?.Lon
          : null;

        transaction.latDisplay = !this.valueCheckerService.isEmptyNullOrUndefined(transaction.SuburbExt)
          ? transaction.SuburbExt?.Lat
          : null;

        transaction.stateDisplay = this.valueCheckerService.isEmptyNullOrUndefined(transaction?.SuburbExt)
          ? transaction?.State
          : transaction.SuburbExt?.State;

        transaction.suburbConfidenceDisplay = this.valueCheckerService.isEmptyNullOrUndefined(transaction.SuburbMatch)
          ? null
          : transaction.SuburbMatch?.Confidence;

        transaction.stateConfidenceDisplay = this.valueCheckerService.isEmptyNullOrUndefined(transaction?.Suburb)
          ? transaction?.StateConfidence
          : "High";

        transaction.bPayBillerCodeDisplay = transaction.CALPaymentMethod === this.bPayPaymentMethod && transaction.BPayAdditionalInformation
          ? transaction.BPayAdditionalInformation.BPayBillerCode
          : null;

        transaction.bPayCustomerReferenceNumberDisplay = transaction.CALPaymentMethod === this.bPayPaymentMethod && transaction.BPayAdditionalInformation
          ? transaction.BPayAdditionalInformation.BPayCustomerReferenceNumber
          : null;

        transaction.whatIsTheLocatorDisplay = transaction.WhatIsTheLocator === this.locatorType.None
          ? "Search String Parsed. Missing Locator Information"
          : "Search String Parsed";
      }
    }
  }

  private setDatatableRows() {
    switch (this.state.batchSplitOption) {
      case BatchSplitOptions.UseBtpFull:
        this.calParserResult = this.batchSplitCalResponse.DetailedTransactions;
        break;
      case BatchSplitOptions.UseBtpLite:
        this.calParserResult = this.batchSplitCalResponse.BtpLiteTransactions;
        break;
      case BatchSplitOptions.UseBoth:
        this.mergeObjectsInSequence();
        break;
    }
  }

  private mergeObjectsInSequence() {
    let btpLiteTransactionsLength: number = this.batchSplitCalResponse.BtpLiteTransactions.length
    let detailedTransactionsLength: number = this.batchSplitCalResponse.DetailedTransactions.length;
    let largestArrayLength = Math.max(btpLiteTransactionsLength, detailedTransactionsLength);

    for (let i = 0; i < largestArrayLength; i++) {
      if (this.batchSplitCalResponse.BtpLiteTransactions[i]) {
        this.calParserResult.push(this.batchSplitCalResponse.BtpLiteTransactions[i]);
      }
      if (this.batchSplitCalResponse.DetailedTransactions[i]) {
        this.calParserResult.push(this.batchSplitCalResponse.DetailedTransactions[i]);
      }
    }
  }

  private setTransactionBankLists() {
    if (this.transactionBankLists.length === 0) {
      this.loadingService.setLoading();
      this.transactionService.getTransactionBankList()
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          this.loadingService.clearLoading();
          this.transactionBankLists = response.data;
          if (this.valueCheckerService.isEmptyNullOrUndefined(this.state.transactionBankId)) {
            this.state.transactionBankId = this.transactionBankLists.find(t => t.name === "Unknown").id;
          }
        });
    }
  }

  private setTransactionTypes() {
    this.loadingService.setLoading();
    this.bankAccountTransactionTypeService.getBankAccountTransactionTypes()
      .subscribe(response => {
        this.loadingService.clearLoading();
        if (response && response.data && response.data.length > 0) {
          this.bankAccountTransactionTypes = response.data;
        }
      });
  }

  private getValidBankAccountTransactionType(bankAccountTransactionType: string, useCommaForSeparates: boolean) {
    if (!this.valueCheckerService.isEmptyNullOrUndefined(bankAccountTransactionType)) {
      let transactionType: NumberNameValue = this.bankAccountTransactionTypes.find(t => t.name.toLowerCase() === bankAccountTransactionType.trim().toLowerCase());
      if (transactionType) {
        bankAccountTransactionType = transactionType.name;
      } else {
        // set unmatched BATT to default
        bankAccountTransactionType = this.defaultBankAccountTransactionType;
        // was split by comma, show warning message
        if (useCommaForSeparates) {
          this.notificationService.notify("Comma was not used as a separator as Bank Account Transaction Type wasn't detected.");
        }
      }
    }
    return bankAccountTransactionType;
  }
}
