import { PastSearchesByFixedCalTransactionType } from "../../components/pastsearch/pastsearch.response";

export let getPastSearchesByCalResponse: PastSearchesByFixedCalTransactionType[] = [
  {
    Id: 1001198954,
    FixedCal: 'BURLEIGH WATERS MIAMI QLD',
    BankAccountTransactionTypeParsed: 'Transaction Amounts:250 EUR',
    Count: 359922.68,
    LastSearched: '2008-06-14T09:10:08.03',
    SearchVolumeLog: 59922.68045,
    MinutesSinceLastTransaction: 64122.2215151,
    UpdateDate: '2028-07-19T05:15:08.03'
  },
  {
    Id: 1001050737,
    FixedCal: '0RANDWICK PS 4250 RANDWICK',
    BankAccountTransactionTypeParsed: 'Transaction Amounts:20.99 EUR',
    Count: 959922.68,
    LastSearched: '2028-09-09T08:49:06.03',
    SearchVolumeLog: 70022.609456,
    MinutesSinceLastTransaction: 50022.181,
    UpdateDate: '2015-02-15T05:10:06.03'
  },
  {
    Id: 1000834671,
    FixedCal: '0RANDWICK PS 4250 RANDWICK',
    BankAccountTransactionTypeParsed: 'Transaction Amounts:856 EUR',
    Count: 859922.68,
    LastSearched: '2068-06-09T08:15:08.03',
    SearchVolumeLog: 50022.228978,
    MinutesSinceLastTransaction: 90422.58485481593484,
    UpdateDate: '2008-05-09T08:29:08.03'
  },
  {
    Id: 1000982652,
    FixedCal: 'SUBWAY DFO BRISBANE BRISB ANE AIR QLD',
    BankAccountTransactionTypeParsed: 'Transaction Amounts:45.66 SGD'
  },
  {
    Id: 1001060018,
    FixedCal: 'WESTPACCONVENFEE-WCRC WELLINGTON',
    BankAccountTransactionTypeParsed: 'Transaction Amounts:20.45 USD'
  }
];
