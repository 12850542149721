import { HttpMethod } from './../../core/coreenums/coreenums';
import { apiPathConstants } from './../../core/api/apipathconstants';
import { HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { merchantAuditResponse } from '../mockjsonresponses/merchantaudit.response';

export function merchantAuditBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];
  let includeMerchantAudit = false;

  if (request.body && request.body.query) {
    includeMerchantAudit = request.body.query.includes('{\n  data: merchantAudit');
  }

  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includeMerchantAudit) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: merchantAuditResponse
      }));
      resp.complete();
    });
  }
}
