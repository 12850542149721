import { HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { HttpMethod } from "../../core/coreenums/coreenums";
import { suburbsReponse, suburbStateCodesResponse } from "../mockjsonresponses/suburbstatecode.reponse";

export function suburbStateCodeBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  let includesCountryCodeEqualAus = false;
  if (request.body && request.body.query) {
    includesCountryCodeEqualAus = request.body.query.includes('{countryCode: {eq: \"AUS\"}}');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesCountryCodeEqualAus) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...suburbStateCodesResponse }
      }));
      resp.complete();
    });
  }

  let includesStateCode = false;
  if (request.body && request.body.query) {
    includesStateCode = request.body.query.includes('{\n  data: fetchSharedData');
  }
  if (cleanUrl.includes(apiPathConstants.graphql) && method === HttpMethod.Post && includesStateCode) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: { ...suburbsReponse }
      }));
      resp.complete();
    });
  }
}
