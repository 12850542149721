import { ExecutionResult } from "graphql";
import { CacheType, RedisLookupResult } from "../../components/rediscachelookup/rediscachelookupdto";

export let redisCacheLookupResponse: RedisLookupResult = { Result: '{\"property1\": \"test\", \"property2\": \"some value\" }'};

export let cacheTypesResponse: ExecutionResult<CacheType[]> = {
    data: [
        { 
            name: "SharedCAL" 
        }, 
        { 
            name: "BPAYCAL" 
        }, 
        { 
            name: "BPAYBillerCode" 
        }, 
        { 
            name: "DirectDebitCAL" 
        }, 
        { 
            name: "DirectEntryCode" 
        }, 
        { 
            name: "DirectCreditCAL" 
        }, 
        { 
            name: "SalaryCAL" 
        }, 
        { 
            name: "SharedSalaryCAL" 
        }, 
        { 
            name: "InternationalCAL" 
        }, 
        { 
            name: "ATMCAL" 
        }, 
        { 
            name: "NPPCAL" 
        }, 
        { 
            name: "BSB" 
        } 
    ]
}
