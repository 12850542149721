import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { categoryAllocationPatterns, categoryAllocationTestResult } from '../mockjsonresponses/categoryallocationpatternresponse';
import { categories } from '../mockjsonresponses/flatcategoriesresponse';

export function categoryBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];
  if (cleanUrl.includes('categoryallocationpattern') && method === 'GET') {

    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: categoryAllocationPatterns
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('categoryallocationpattern/testpatternrules') && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...categoryAllocationTestResult]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('categoryallocationpattern') && method === 'POST') {

    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200
      }));
      resp.complete();
    });
  }


  if (cleanUrl.includes('category') && !cleanUrl.includes('externalcategory') && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200,
          body: [...categories]
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('category') && method === 'PUT') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('category') && !cleanUrl.includes('deleteexternalcategories') && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200
      }));
      resp.complete();
    });
  }

  if (cleanUrl.includes('category') && !cleanUrl.includes('updateexternalcategories') && method === 'POST') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
          status: 200
      }));
      resp.complete();
    });
  }
}
