import { APISearchResultV4, TransactionQueryProcessTextItemType, APISearchResultsV4 } from '../../models/searchmodelsv4';

let searchResult1: Partial<APISearchResultV4> = {
  cal: 'BURLEIGH WATERS MIAMI QLD',
  fixedcal: null,
  tid: null,
  mid: null,
  customer_correlation_id: null,
  number_of_results: 1,
  highest_score: 99,
  utc_expiry_time: '2018-10-26T09:10:42.4853041Z',
  merchant_search_results: [
    {
      search_results_guid: 'f4933e2d-4e0e-4886-bff1-8828e5d5b729',
      LWC_ID: 1000110544,
      lwc_guid: 'abebdcb9-a64e-420b-af05-96ed26c7ca3f',
      rank: 1,
      score: 99,
      match_feedback_requested: false,
      type_of_match: 'KNOWN',
      merchant_details: {
        id: 1000110544,
        lwc_guid: 'abebdcb9-a64e-420b-af05-96ed26c7ca3f',
        data_enrichment_score: 98,
        merchant_primary_name: 'Wild Earth',
        transaction_query_process_html: 'transaction_query_process_html',
        additional_details: {
          bpay_biller_codes: [
            1, 626, 456496, 1515
          ]
        },
        transaction_query_process_json: {
          paragraphs: [
            {
              items: [
                {
                  value: 'value',
                  type: TransactionQueryProcessTextItemType.email,
                  display_value: 'display_value',

                }
              ]
            }
          ]
        },
        primary_address: {
          single_line_address: '25 Central Drive, Burleigh Heads QLD 4220 AUS',
          address_line_1: '25 Central Drive',
          address_line_2: null,
          state: 'QLD',
          postcode: '4220',
          suburb: 'Burleigh Heads',
          country_name: 'Country Name',
          longitude: 153.424022,
          latitude: -28.105459,
          lat_lon_precision: 8,
          mapable: true,
          street_view_available: false,
          address_located_in: null,
          address_identifiers: {
            address_identifier_name: "Indentifier Name",
            address_identifier_value: "Identifier Value"
          },
        },
        verification: {
          is_verified: true,
          last_verified_on: '2018-09-18T02:50:00'
        },
        primary_contacts: [
          {
            type_of_contact: 'EMAIL',
            value: 'service@wildearth.com.au',
            display_value: 'service@wildearth.com.au',
            label: 'EMAIL'
          },
          {
            type_of_contact: 'PHONE',
            value: '(07) 5593 4180',
            display_value: '(07) 5593 4180',
            label: 'PHONE'
          },
          {
            type_of_contact: 'URL',
            value: 'http://www.wildearth.com.au',
            display_value: 'wildearth.com.au',
            label: 'WEB'
          }
        ],
        suspicious_merchant_score: 50,
        secondary_contacts: [
          {
            type_of_contact: 'FAX',
            value: '(07) 5593 6532',
            display_value: '(07) 5593 6532',
            label: 'FAX'
          },
          {
            type_of_contact: 'EMAIL',
            value: 'sales@wildearth.com.au',
            display_value: 'sales@wildearth.com.au',
            label: 'EMAIL'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+61 7 5593 4180',
            display_value: '+61 7 5593 4180',
            label: 'PHONE'
          },
          {
            type_of_contact: 'FACEBOOK',
            value: 'http://facebook.com/163102547873',
            display_value: 'facebook.com',
            label: 'FACEBOOK'
          },
          {
            type_of_contact: 'LINKEDIN',
            value: 'http://linkedin.com/company/wild-earth',
            display_value: 'linkedin.com',
            label: 'LINKEDIN'
          },
          {
            type_of_contact: 'TWITTER',
            value: 'http://twitter.com/WildEarthAust',
            display_value: 'twitter.com',
            label: 'TWITTER'
          },
          {
            type_of_contact: 'CRUNCHBASE',
            value: 'http://crunchbase.com/organization/wild-earth',
            display_value: 'crunchbase.com',
            label: 'CRUNCHBASE'
          }
        ],
        secondary_addresses: null,
        image_gallery: [
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-zbbq-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-zbbq-image.jpg',
            image_title: 'Wild Earth',
            image_height: 270,
            image_width: 750
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-dkfw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-dkfw-image.jpg',
            image_title: 'Wild Earth',
            image_height: 344,
            image_width: 640
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-j0iw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-j0iw-image.jpg',
            image_title: 'Wild Earth',
            image_height: 519,
            image_width: 780
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-atqq-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-atqq-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-8frw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-8frw-image.jpg',
            image_title: 'Wild Earth',
            image_height: 983,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-jybg-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-jybg-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-9rqg-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-9rqg-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-j9sg-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-j9sg-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1053,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-i8fa-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-i8fa-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-l0sa-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-l0sa-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1099,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-aafw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-aafw-image.jpg',
            image_title: 'Wild Earth',
            image_height: 270,
            image_width: 750
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-qu6q-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-qu6q-image.jpg',
            image_title: 'Wild Earth',
            image_height: 344,
            image_width: 640
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-udjw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-udjw-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-ckha-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-ckha-image.jpg',
            image_title: 'Wild Earth',
            image_height: 519,
            image_width: 780
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-z9aa-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-z9aa-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-ikfa-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-ikfa-image.jpg',
            image_title: 'Wild Earth',
            image_height: 983,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-yscq-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-yscq-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1053,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth--eiw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth--eiw-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-gcpq-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-gcpq-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1536,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-4zjw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/Wild-Earth-4zjw-image.jpg',
            image_title: 'Wild Earth',
            image_height: 1099,
            image_width: 2048
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-8820-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-8820-image.jpg',
            image_title: 'Wild Earth',
            image_height: null,
            image_width: null
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-6213-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-6213-image.jpg',
            image_title: 'Wild Earth',
            image_height: null,
            image_width: null
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-5087-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-5087-image.jpg',
            image_title: 'Wild Earth',
            image_height: null,
            image_width: null
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-7265-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-7265-image.jpg',
            image_title: 'Wild Earth',
            image_height: null,
            image_width: null
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-2072-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000110544/WildEarth-2072-image.jpg',
            image_title: 'Wild Earth',
            image_height: null,
            image_width: null
          }
        ],
        primary_category: {
          category_name: 'Camping',
          id: 1004002,
          full_category_path: 'Shopping >> Outdoor >> Camping',
          parent: {
            category_name: 'Outdoor',
            id: 1004,
            full_category_path: 'Shopping >> Outdoor',
            parent: {
              category_name: 'Shopping',
              id: 1,
              full_category_path: 'Shopping',
              parent: null
            }
          },
          is_sensitive: false,
          is_lwc_category: true,
          lwc_category_icon: {
            Black_white_url: 'https://images.lookwhoscharging.com/categoryicons/bw_1004002.png',
            Coloured_url: 'https://images.lookwhoscharging.com/categoryicons/clr_1004002.png',
            height: 128,
            width: 128
          },
          is_substituted_category: false,
          lwc_category_icon_circular: {
            Black_white_url: 'black-white url',
            Coloured_url: 'coloured url',
            height: 158,
            width: 258
          },
          category_emoji: 'Check Mark',
          recategorisation_info: {
            rule_id: 45,
            rule_date_time: '25/03/1988'
          }
        },
        other_categories_list: [
          {
            category_name: 'APPAREL, ACCESSORIES & LUXURY GOODS',
            id: 1001,
            full_category_path: 'APPAREL, ACCESSORIES & LUXURY GOODS',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            lwc_category_icon_circular: {
              Black_white_url: 'black-white Url',
              Coloured_url: 'Colouered url',
              height: 4500,
              width: 10000
            },
            category_emoji: 'Pleading Face',
            recategorisation_info: {
              rule_id: 454,
              rule_date_time: '25/06/2000'
            }
          },
          {
            category_name: 'E-COMMERCE',
            id: null,
            full_category_path: 'E-COMMERCE',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            lwc_category_icon_circular: {
              Black_white_url: 'black-white Url',
              Coloured_url: 'Colouered url',
              height: 200,
              width: 10000
            },
            category_emoji: 'Pleading Face',
            recategorisation_info: {
              rule_id: 784,
              rule_date_time: '25/06/1565'
            }
          },
          {
            category_name: 'APPAREL & FASHION',
            id: null,
            full_category_path: 'APPAREL & FASHION',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            lwc_category_icon_circular: {
              Black_white_url: 'black-white Url',
              Coloured_url: 'Colouered url',
              height: 78,
              width: 9895
            },
            category_emoji: 'Pleading Face',
            recategorisation_info: {
              rule_id: 5,
              rule_date_time: '15/03/2020'
            }
          },
          {
            category_name: 'Camping Equipment--Retail',
            id: null,
            full_category_path: 'Camping Equipment--Retail',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            lwc_category_icon_circular: {
              Black_white_url: 'black-white Url',
              Coloured_url: 'Colouered url',
              height: 89,
              width: 5656
            },
            category_emoji: 'Pleading Face',
            recategorisation_info: {
              rule_id: 600,
              rule_date_time: '25/09/2005'
            }
          }
        ],
        primary_merchant_description: 'Outdoor sports store',
        merchant_descriptors: null,
        is_permanently_closed: false,
        merchant_logo: {
          url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-logo-image.png',
          height: null,
          width: null
        },
        merchant_logo_3x1: null,
        merchant_logo_circular: null,
        website_screen_shot: {
          thumbnail: {
            url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-site-thumbnail.jpg',
            height: 65,
            width: 100
          },
          banner: {
            url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-site-banner.jpg',
            height: 300,
            width: 100
          },
          main: {
            url: 'https://images.lookwhoscharging.com/1000110544/WILD-EARTH-site-image.jpg',
            height: 1080,
            width: 1920
          }
        },
        also_known_as: [
          'HIKING SHOP',
          'SAHARA SWAGS',
          'SWAG SHOP',
          'WILD EARTH PTY LTD'
        ],
        associated_with: null,
        last_updated: '9/18/2018',
        opening_hours: {
          is_always_open: false,
          sunday: {
            status: 'closed',
            times: []
          },
          monday: {
            status: 'open',
            times: [
              {
                open: '09:00',
                close: '17:00'
              }
            ]
          },
          tuesday: {
            status: 'open',
            times: [
              {
                open: '09:00',
                close: '17:00'
              }
            ]
          },
          wednesday: {
            status: 'open',
            times: [
              {
                open: '09:00',
                close: '17:00'
              }
            ]
          },
          thursday: {
            status: 'open',
            times: [
              {
                open: '09:00',
                close: '17:00'
              }
            ]
          },
          friday: {
            status: 'open',
            times: [
              {
                open: '09:00',
                close: '17:00'
              }
            ]
          },
          saturday: {
            status: 'open',
            times: [
              {
                open: '09:00',
                close: '17:00'
              }
            ]
          }
        },
        legal_business_info: {
          date_established: null,
          entity_type: 'PRV',
          current_merchant_status: 'Active',
          chain_name: null,
          merchant_type: '4879',
          merchant_presence: 'merchant_presence',
          legal_registrations: [
            {
              legal_number: '1234567',
              legal_number_label: 'ABN'
            }
          ],
          chain_lwc_guid: 'chain_lwc_guid'
        },
        overall_rating: {
          overall_rating_score: 4.5,
          total_number_of_reviews: 8
        },
        ratings: [
          {
            reviewer: 'GooglePlaces',
            score: 4.5,
            number_of_reviews: 8
          }
        ],
        payment_options: [
          'American Express',
          'Cash',
          'Direct Debit',
          'EFT',
          'Mastercard',
          'Money Order',
          'Visa',
          'EFTPOS'
        ],
        transaction_query_process: {
          transaction_query_process_markdown: 'transaction query process markdown',
          transaction_query_process_link: 'Transaction Processing and Query Optimization'
        },
      }
    }
  ],
  user_message: 'The search for \'BURLEIGH WATERS MIAMI QLD\' returned 1 merchant',
  system_message: 'The search for \'BURLEIGH WATERS MIAMI QLD\' returned 1 merchant',
  result_code: 10000,
  lwc_attempting_to_index: false,
  credits_used: 10,
  transaction_tags: "Pay Day Loans"
}
let searchResult2: Partial<APISearchResultV4> = {
  cal: '0RANDWICK PS 4250 RANDWICK',
  fixedcal: null,
  tid: null,
  mid: null,
  number_of_results: 1,
  highest_score: 99,
  utc_expiry_time: '2018-10-26T09:10:42.4853041Z',
  merchant_search_results: [
    {
      search_results_guid: '09c9bdfb-339c-4d19-822e-435787aa7233',
      LWC_ID: 1000832935,
      lwc_guid: 'b588eb7e-4148-4b93-b52e-47a49c47afef',
      rank: 1,
      score: 99,
      match_feedback_requested: false,
      type_of_match: 'KNOWN',
      merchant_details: {
        id: 1000832935,
        transaction_query_process_html: 'transaction_query_process_html',
        lwc_guid: 'b588eb7e-4148-4b93-b52e-47a49c47afef',
        data_enrichment_score: 85,
        merchant_primary_name: 'Randwick Public School',
        primary_address: {
          single_line_address: '1 Cowper St, Randwick NSW 2031, Australia',
          address_line_1: '1 Cowper St',
          address_line_2: null,
          state: 'NSW',
          postcode: '2031',
          suburb: 'Randwick',
          longitude: 151.235361,
          latitude: -33.907706,
          lat_lon_precision: 9,
          country_name: 'Country Name',
          mapable: true,
          street_view_available: true,
          address_located_in: null,
          address_identifiers: {
            address_identifier_name: "Indentifier Name",
            address_identifier_value: "Identifier Value"
          },
        },
        transaction_query_process_json: {
          paragraphs: [
            {
              items: [
                {
                  value: 'value',
                  type: TransactionQueryProcessTextItemType.email,
                  display_value: 'display_value',

                }
              ]
            }
          ]
        },
        verification: {
          is_verified: true,
          last_verified_on: '2018-10-03T04:34:00'
        },
        additional_details: {
          bpay_biller_codes: [
            1, 626, 456496, 1515
          ]
        },
        primary_contacts: [
          {
            type_of_contact: 'PHONE',
            value: '(02) 9398 6022',
            display_value: '(02) 9398 6022',
            label: 'PHONE'
          },
          {
            type_of_contact: 'URL',
            value: 'http://www.randwickpublicschool.com.au',
            display_value: 'randwickpublicschool.com.au',
            label: 'WEB'
          },
          {
            type_of_contact: 'EMAIL',
            value: 'randwick-p.school@det.nsw.edu.au',
            display_value: 'randwick-p.school@det.nsw.edu.au',
            label: 'EMAIL'
          }
        ],
        suspicious_merchant_score: 50,
        secondary_contacts: [
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+61 2 9398 6022',
            display_value: '+61 2 9398 6022',
            label: 'PHONE'
          }
        ],
        secondary_addresses: null,
        image_gallery: null,
        primary_category: {
          category_name: 'Public/Primary',
          id: 5005010,
          full_category_path: 'Service >> Educational >> Public/Primary',
          parent: {
            category_name: 'Educational',
            id: 5005,
            full_category_path: 'Service >> Educational',
            parent: {
              category_name: 'Service',
              id: 5,
              full_category_path: 'Service',
              parent: null
            },
          },
          category_emoji: 'Smiling Face with Hearts',
          lwc_category_icon_circular: {
            Black_white_url: 'black-white url',
            Coloured_url: 'coloured url',
            height: 158,
            width: 258
          },
          recategorisation_info: {
            rule_id: 156,
            rule_date_time: '25/08/2012'
          },
          is_substituted_category: true,
          is_sensitive: false,
          is_lwc_category: true,
          lwc_category_icon: {
            Black_white_url: 'https://images.lookwhoscharging.com/categoryicons/bw_5005010.png',
            Coloured_url: 'https://images.lookwhoscharging.com/categoryicons/clr_5005010.png',
            height: 128,
            width: 128
          }
        },
        other_categories_list: [
          {
            category_name: 'Service >> Educational >> Private School',
            id: 5005002,
            full_category_path: 'Service >> Educational >> Private School',
            parent: null,
            is_sensitive: false,
            is_lwc_category: true,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'ASSOCIATIONS-CIVIC SOCIAL AND FRATERNAL',
            id: 5999002,
            full_category_path: 'ASSOCIATIONS-CIVIC SOCIAL AND FRATERNAL',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'RELIGIOUS, CIVIC AND PROFESSIONAL ORGANIZATIONS',
            id: 5012,
            full_category_path: 'RELIGIOUS, CIVIC AND PROFESSIONAL ORGANIZATIONS',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'OTHER SERVICES',
            id: 5,
            full_category_path: 'OTHER SERVICES',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'ASSOCIATIONS CIVIC SOCIAL AND FRATERNAL',
            id: 5999002,
            full_category_path: 'ASSOCIATIONS CIVIC SOCIAL AND FRATERNAL',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'SCHOOLS ELEMENTARY AND SECONDARY',
            id: 5005,
            full_category_path: 'SCHOOLS ELEMENTARY AND SECONDARY',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'ELEMENTARY, MIDDLE, HIGH SCHOOLS',
            id: 5005,
            full_category_path: 'ELEMENTARY, MIDDLE, HIGH SCHOOLS',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'EDUCATION SERVICES',
            id: 5005,
            full_category_path: 'EDUCATION SERVICES',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'SCHOOLS--PUBLIC (STATE) - NSW ONLY',
            id: 5005010,
            full_category_path: 'SCHOOLS--PUBLIC (STATE) - NSW ONLY',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          }
        ],
        primary_merchant_description: 'Randwick Public School provides a broad high quality education for a diverse community of students and families where excellence is sought and achieved through clear purpose strategic goals explicit teaching and consistent endeavour by all',
        merchant_descriptors: null,
        is_permanently_closed: false,
        merchant_logo: null,
        merchant_logo_3x1: null,
        merchant_logo_circular: null,
        website_screen_shot: {
          thumbnail: {
            url: 'https://images.lookwhoscharging.com/1000832935/RANDWICK-PUBLIC-SCHOOL-site-thumbnail.jpg',
            height: 65,
            width: 100
          },
          banner: {
            url: 'https://images.lookwhoscharging.com/1000832935/RANDWICK-PUBLIC-SCHOOL-site-banner.jpg',
            height: 300,
            width: 100
          },
          main: {
            url: 'https://images.lookwhoscharging.com/1000832935/RANDWICK-PUBLIC-SCHOOL-site-image.jpg',
            height: 1024,
            width: 1280
          }
        },
        also_known_as: null,
        associated_with: null,
        last_updated: '10/3/2018',
        opening_hours: null,
        legal_business_info: {
          date_established: '1999',
          entity_type: 'SGE',
          current_merchant_status: 'Active',
          chain_name: null,
          merchant_type: '4879',
          merchant_presence: 'merchant_presence',
          legal_registrations: [
            {
              legal_number: '4848899',
              legal_number_label: 'ABN'
            }
          ],
          chain_lwc_guid: 'chain_lwc_guid'
        },
        overall_rating: {
          overall_rating_score: 4.1,
          total_number_of_reviews: 5
        },
        ratings: [
          {
            reviewer: 'GooglePlaces',
            score: 4.1,
            number_of_reviews: 5
          }
        ],
        payment_options: [
          'Visa',
          'Mastercard',
          'EFTPOS'
        ],
        transaction_query_process: null,
        merchant_tags: [
          {id: 15, label: "Fridaysio"},
          {id: 26, label: "Light Coding"},
          {id: 5, label: "Pay Day Loans"}
        ]
      }
    }
  ],
  user_message: 'The search for \'0RANDWICK PS 4250 RANDWICK\' returned 1 merchant',
  system_message: 'The search for \'0RANDWICK PS 4250 RANDWICK\' returned 1 merchant',
  result_code: 10000,
  lwc_attempting_to_index: false,
  credits_used: 10,
  transaction_tags: "Light Coding"
}

let searchResult3: Partial<APISearchResultV4> = {
  cal: 'VFS SERVICES AUSTRAL MELBOURNE',
  fixedcal: null,
  tid: null,
  mid: null,
  number_of_results: 1,
  highest_score: 81,
  utc_expiry_time: '2018-10-26T09:10:42.4853041Z',
  merchant_search_results: [
    {
      search_results_guid: '4ccbda24-30eb-4a89-8e76-e8fd63832d0e',
      LWC_ID: 1000756974,
      lwc_guid: 'b8992559-da5f-41d1-88b1-5f2125cd78b9',
      rank: 1,
      score: 96,
      match_feedback_requested: false,
      type_of_match: 'KNOWN',
      merchant_details: {
        id: 1000756974,
        transaction_query_process_html: 'transaction_query_process_html',
        lwc_guid: 'b8992559-da5f-41d1-88b1-5f2125cd78b9',
        transaction_query_process_json: {
          paragraphs: [
            {
              items: [
                {
                  value: 'value',
                  type: TransactionQueryProcessTextItemType.email,
                  display_value: 'display_value',

                }
              ]
            }
          ]
        },
        additional_details: {
          bpay_biller_codes: [
            1, 78, 265, 145
          ]
        },
        data_enrichment_score: 100,
        merchant_primary_name: 'VFS Global (Melbourne)',
        primary_address: {
          single_line_address: 'Majorca Building, Suite 4 level 12/55 Swanston St, Melbourne VIC 3004, Australia',
          address_line_1: '55 Swanston St',
          address_line_2: null,
          state: 'VIC',
          postcode: '3004',
          suburb: 'Melbourne',
          longitude: 144.9666,
          latitude: -37.8164,
          lat_lon_precision: 9,
          mapable: true,
          street_view_available: false,
          address_located_in: null,
          country_name: 'country_name',
          address_identifiers: {
            address_identifier_name: "Indentifier Name",
            address_identifier_value: "Identifier Value"
          }
        },
        verification: {
          is_verified: true,
          last_verified_on: '2018-10-24T06:30:00'
        },
        primary_contacts: [
          {
            type_of_contact: 'URL',
            value: 'http://vfsglobal.com/australia',
            display_value: 'vfsglobal.com',
            label: 'WEB'
          },
          {
            type_of_contact: 'PHONE',
            value: '(03) 8593 9124',
            display_value: '(03) 8593 9124',
            label: 'PHONE'
          },
          {
            type_of_contact: 'EMAIL',
            value: 'visainfo.inau@vfshelpline.com',
            display_value: 'visainfo.inau@vfshelpline.com',
            label: 'EMAIL'
          }
        ],
        suspicious_merchant_score: 50,
        secondary_contacts: [
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+6 6 2632 1541',
            display_value: '+6 6 2632 1541',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+6 6 2118 7010',
            display_value: '+6 6 2118 7010',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+6 6 2118 7002',
            display_value: '+6 6 2118 7002',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+861 0 8400 4023',
            display_value: '+861 0 8400 4023',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+12 4 0454 7720',
            display_value: '+12 4 0454 7720',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+237 2 2220 7733',
            display_value: '+237 2 2220 7733',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+237 6 7814 7219',
            display_value: '+237 6 7814 7219',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+97 1 4256 7077',
            display_value: '+97 1 4256 7077',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+912 2 6728 9000',
            display_value: '+912 2 6728 9000',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+912 2 6728 9292',
            display_value: '+912 2 6728 9292',
            label: 'PHONE'
          },
          {
            type_of_contact: 'LINKEDIN',
            value: 'http://linkedin.com/company/vfs-global-services',
            display_value: 'linkedin.com',
            label: 'LINKEDIN'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+61 3 8593 9124',
            display_value: '+61 3 8593 9124',
            label: 'PHONE'
          },
          {
            type_of_contact: 'INTERNATIONALPHONE',
            value: '+61 2 9037 2579',
            display_value: '+61 2 9037 2579',
            label: 'PHONE'
          },
          {
            type_of_contact: 'FACEBOOK',
            value: 'https://www.facebook.com/pages/vfsglobal-melbourne/525288907648024',
            display_value: 'facebook.com',
            label: 'FACEBOOK'
          },
          {
            type_of_contact: 'EMAIL',
            value: 'acco@vfsglobal.com',
            display_value: 'acco@vfsglobal.com',
            label: 'EMAIL'
          },
          {
            type_of_contact: 'EMAIL',
            value: 'generalcounsel@vfsglobal.com',
            display_value: 'generalcounsel@vfsglobal.com',
            label: 'EMAIL'
          },
          {
            type_of_contact: 'EMAIL',
            value: 'communications@vfsglobal.com',
            display_value: 'communications@vfsglobal.com',
            label: 'EMAIL'
          },
          {
            type_of_contact: 'EMAIL',
            value: 'dpo@vfsglobal.com',
            display_value: 'dpo@vfsglobal.com',
            label: 'EMAIL'
          },
          {
            type_of_contact: 'FACEBOOK',
            value: 'http://facebook.com/vfsglobalonline',
            display_value: 'facebook.com',
            label: 'FACEBOOK'
          },
          {
            type_of_contact: 'TWITTER',
            value: 'http://twitter.com/vfsglobal',
            display_value: 'twitter.com',
            label: 'TWITTER'
          },
          {
            type_of_contact: 'CRUNCHBASE',
            value: 'http://crunchbase.com/organization/vfs-global-group',
            display_value: 'crunchbase.com',
            label: 'CRUNCHBASE'
          }
        ],
        secondary_addresses: null,
        image_gallery: [
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-qjow-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-qjow-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 3024,
            image_width: 4032
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-9vhw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-9vhw-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 3024,
            image_width: 4032
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-4kpa-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-4kpa-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 3024,
            image_width: 4032
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-ajnw-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-ajnw-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 4640,
            image_width: 2610
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-vilg-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-vilg-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 4048,
            image_width: 3036
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-9eba-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-9eba-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 4032,
            image_width: 3024
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-kxnq-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-kxnq-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 4640,
            image_width: 2610
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-g37q-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-g37q-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 4032,
            image_width: 3024
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-tfoa-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-tfoa-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 4032,
            image_width: 2268
          },
          {
            thumbnail_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-0cva-thumbnail.jpg',
            large_url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-0cva-image.jpg',
            image_title: 'VFS Global (Melbourne)',
            image_height: 3840,
            image_width: 2160
          }
        ],
        primary_category: {
          category_name: 'Government',
          id: 5004,
          full_category_path: 'Service >> Government',
          parent: {
            category_name: 'Service',
            id: 5,
            full_category_path: 'Service',
            parent: null
          },
          is_sensitive: false,
          is_lwc_category: true,
          lwc_category_icon: {
            Black_white_url: 'https://images.lookwhoscharging.com/categoryicons/bw_5004.png',
            Coloured_url: 'https://images.lookwhoscharging.com/categoryicons/clr_5004.png',
            height: 128,
            width: 128
          },
          is_substituted_category: true,
          lwc_category_icon_circular: {
            Black_white_url: 'black-white url',
            Coloured_url: 'coloured url',
            height: 48949,
            width: 1515
          },
          category_emoji: 'Check Mark',
          recategorisation_info: {
            rule_id: 45,
            rule_date_time: '10/03/1964'
          }
        },
        other_categories_list: [
          {
            category_name: 'Corporate & Business',
            id: 5007,
            full_category_path: 'Corporate & Business',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'Internet Software & Services',
            id: 1015,
            full_category_path: 'Internet Software & Services',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'Business Service',
            id: 5007,
            full_category_path: 'Business Service',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'Travel Agency',
            id: 7002,
            full_category_path: 'Travel Agency',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'GOVERNMENT SERVICES-NOT ELSEWHERE CLASSIFIED',
            id: 5004,
            full_category_path: 'GOVERNMENT SERVICES-NOT ELSEWHERE CLASSIFIED',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'PUBLIC ADMINISTRATION',
            id: 5004006,
            full_category_path: 'PUBLIC ADMINISTRATION',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          },
          {
            category_name: 'GOVERNMENT SERVICES NOT ELSEWHERE CLASSIFIED',
            id: 5004,
            full_category_path: 'GOVERNMENT SERVICES NOT ELSEWHERE CLASSIFIED',
            parent: null,
            is_sensitive: false,
            is_lwc_category: false,
            lwc_category_icon: null,
            is_substituted_category: true,
            category_emoji: 'Smiling Face with Smiling Eyes',
            lwc_category_icon_circular: {
              Black_white_url: 'black-white url',
              Coloured_url: 'coloured url',
              height: 158,
              width: 258
            },
            recategorisation_info: {
              rule_id: 6545,
              rule_date_time: '17/02/2015'
            }
          }
        ],
        primary_merchant_description: 'VFS Global is a commercial entity working in partnership with The Commonwealth of Australia to provide Australian visa application services in Philippines',
        merchant_descriptors: null,
        is_permanently_closed: false,
        merchant_logo: {
          url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-logo-image.png',
          height: 128,
          width: 128
        },
        merchant_logo_3x1: null,
        merchant_logo_circular: null,
        website_screen_shot: {
          thumbnail: {
            url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-site-thumbnail.jpg',
            height: 65,
            width: 100
          },
          banner: {
            url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-site-banner.jpg',
            height: 300,
            width: 100
          },
          main: {
            url: 'https://images.lookwhoscharging.com/1000756974/VFS-Global-Melbourne-site-image.jpg',
            height: 1024,
            width: 1280
          }
        },
        also_known_as: null,
        associated_with: [
          {
            associates_name: 'VFS Global (Sydney Head Office)',
            associates_id: 'f2814b40-38c1-453f-9be5-968075093fee',
            type_of_association: 'Headquarters'
          }
        ],
        last_updated: '10/24/2018',
        opening_hours: {
          is_always_open: false,
          sunday: {
            status: 'closed',
            times: null
          },
          monday: {
            status: 'open',
            times: [
              {
                open: '08:30',
                close: '15:00'
              }
            ]
          },
          tuesday: {
            status: 'open',
            times: [
              {
                open: '08:30',
                close: '15:00'
              }
            ]
          },
          wednesday: {
            status: 'open',
            times: [
              {
                open: '08:30',
                close: '15:00'
              }
            ]
          },
          thursday: {
            status: 'open',
            times: [
              {
                open: '08:30',
                close: '15:00'
              }
            ]
          },
          friday: {
            status: 'open',
            times: [
              {
                open: '08:30',
                close: '15:00'
              }
            ]
          },
          saturday: {
            status: 'closed',
            times: null
          }
        },
        legal_business_info: {
          date_established: '2007',
          entity_type: 'PRV',
          current_merchant_status: 'Active',
          chain_name: null,
          merchant_type: '4879',
          merchant_presence: 'merchant_presence',
          legal_registrations: [
            {
              legal_number: '6318487',
              legal_number_label: 'ABN'
            }
          ],
          chain_lwc_guid: 'chain_lwc_guid'
        },
        overall_rating: {
          overall_rating_score: 5.6,
          total_number_of_reviews: 12
        },
        ratings: [
          {
            reviewer: 'FacebookPlace',
            score: 2,
            number_of_reviews: 7
          },
          {
            reviewer: 'GooglePlaces',
            score: 3.6,
            number_of_reviews: 5
          }
        ],
        payment_options: [
          'Visa',
          'Mastercard',
          'EFTPOS'
        ],
        transaction_query_process: null,
        merchant_tags: [
          {id: 5, label: "Pay Day Loans"}
        ]
      }
    }
  ],
  user_message: 'The search for \'VFS SERVICES AUSTRAL MELBOURNE\' returned 1 merchant',
  system_message: 'The search for \'VFS SERVICES AUSTRAL MELBOURNE\' returned 1 merchant',
  result_code: 10000,
  lwc_attempting_to_index: false,
  credits_used: 10
}

let searchResult4: Partial<APISearchResultV4> = {
  cal: 'METRO PETROLEUM SOUTHP SOUTHPORT',
  fixedcal: null,
  tid: null,
  mid: null,
  number_of_results: 1,
  highest_score: 81,
  utc_expiry_time: '2018-10-26T09:10:42.4853041Z',
  merchant_search_results: [
    {
      search_results_guid: '4ccbda24-30eb-4a89-8e76-e8fd63832d0e',
      LWC_ID: 1000756974,
      lwc_guid: 'b8992559-da5f-41d1-88b1-5f2125cd78b9',
      rank: 1,
      score: 96,
      match_feedback_requested: false,
      type_of_match: 'KNOWN',
      merchant_details: null
    }
  ],
  user_message: 'The search for \'METRO PETROLEUM SOUTHP SOUTHPORT \' returned 1 merchant',
  system_message: 'The search for \'METRO PETROLEUM SOUTHP SOUTHPORT \' returned 1 merchant',
  result_code: 10000,
  lwc_attempting_to_index: false,
  credits_used: 10
}

export let batchMerchantResponse: Partial<APISearchResultsV4> = {
  transactions_count: 50,
  search_time_ms: 8748,
  total_credits_used: 500,
  fields: 'All',
  search_results: [
    { ...<APISearchResultV4>searchResult1 },
    { ...<APISearchResultV4>searchResult2 },
    { ...<APISearchResultV4>searchResult3 },
    { ...<APISearchResultV4>searchResult4 },
  ]
};
