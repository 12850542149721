import { StringService } from './../core/formatting/string.service';
import { hotChocolate, searchFeedbacksQuery, updateSearchFeedbackQuery } from './../gqlconstants/gqlconstants';
import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { UnspecifiedTrueFalse } from '../enums/enums';
import { emptyString, commaSpace, personalDetailsRemovedMessage } from '../core/constants/constants';
import { ValueCheckerService } from '../core/services/valuechecker.service';
import { FeedbackDTO } from '../shared/models/feedbackdto';

export type SearchFeedbackResponse = {
  items: FeedbackDTO[];
  totalCount: number;
};

export type UpdateSearchFeedbacksResponse = {
  updateSearchFeedback: FeedbackDTO[];
};

@Injectable({
  providedIn: "root"
})
export class SearchFeedbackService {
  constructor(private apollo: Apollo, private stringService: StringService, private valueCheckerService: ValueCheckerService) { }

  getSuggestions(endDate: string, startDate: string, mlUseful: UnspecifiedTrueFalse, clientName: string, cal: string, searchFeedbackId: number, isIndexed: UnspecifiedTrueFalse, skip: number, take: number, feedbackType: string) {
    let query = searchFeedbacksQuery;
    let optionalFilters = emptyString;

    if (!this.valueCheckerService.isEmptyNullOrUndefined(startDate)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ createDate: { gte: "{0}"}}${commaSpace}`, startDate);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(endDate)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ createDate :{ lte: "{0}"}}${commaSpace}`, endDate);
    }
    if (mlUseful !== UnspecifiedTrueFalse.Unspecified) {
      optionalFilters = optionalFilters + this.stringService.format(`{ mLUseful :{ eq: {0}}}${commaSpace}`, mlUseful === UnspecifiedTrueFalse.True ? "true" : "false");
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(clientName)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ clientName: {eq: "{0}"}}${commaSpace}`, clientName);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(cal)) {
      optionalFilters = optionalFilters + this.stringService.format(`{ cal: {contains:"{0}"}}${commaSpace}`, cal);
    }
    if (!this.valueCheckerService.isEmptyNullOrUndefined(searchFeedbackId)) {
      optionalFilters = optionalFilters + this.stringService.format(`{searchFeedbackId: { gt: {0}}}${commaSpace}`, searchFeedbackId.toString());
    }
    if (isIndexed !== UnspecifiedTrueFalse.Unspecified) {
      optionalFilters = optionalFilters + this.stringService.format(`{ isIndexed :{ eq: {0}}}${commaSpace}`, isIndexed === UnspecifiedTrueFalse.True ? "true" : "false");
    }
    if (optionalFilters === emptyString) {
      query = query.replace("{0}", emptyString);
    }

    return this.apollo
      .use(hotChocolate)
      .watchQuery<SearchFeedbackResponse>({
        query: gql`${this.stringService.format(query, optionalFilters, feedbackType, skip.toString(), take.toString())}`
      })
      .valueChanges;
  }

  getUpdateSearchFeedbacks(ids: number[], userComment: string, lwcComment: string = emptyString, humanClassification: string = emptyString) {
    userComment = this.valueCheckerService.isEmptyNullOrUndefined(userComment) ? emptyString : `userComment: "${userComment}"${commaSpace}`;
    return this.apollo
      .use(hotChocolate)
      .mutate<UpdateSearchFeedbacksResponse>({
        mutation: gql`${this.stringService.format(updateSearchFeedbackQuery, ids.toString(), userComment, lwcComment, humanClassification)}`
      });
  }

  restorePersonalDetailsIfNeeded(copyOfSelectedRecords: FeedbackDTO[], selectedRecords: FeedbackDTO[], displayResults: FeedbackDTO[]) {
    if (copyOfSelectedRecords.length > 0) {
      let idsOfChangedRecords = copyOfSelectedRecords.map(t => t.searchFeedbackId);
      let idsOfSelectedAndChangedRecords = selectedRecords.filter(t => t.userComment === personalDetailsRemovedMessage).map(t => t.searchFeedbackId);
      let idsOfRecordsWhoseShouldBeReturned: number[] = idsOfChangedRecords.filter(t => !idsOfSelectedAndChangedRecords.includes(t));

      if (idsOfRecordsWhoseShouldBeReturned.length > 0) {
        for (let id of idsOfRecordsWhoseShouldBeReturned) {
          let record = displayResults.find(t => t.searchFeedbackId === id);
          let originalRecord = copyOfSelectedRecords.find(t => t.searchFeedbackId === id);
          record.userComment = originalRecord.userComment;
          record.searchFeedbackHumanClassification = originalRecord.searchFeedbackHumanClassification;
          record.lwcComment = originalRecord.lwcComment;
        }
      }
    }
  }
}
