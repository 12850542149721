import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { LoadingService } from '../../../core/uiservices/loading.service';
import { NotificationService } from '../../../core/uiservices/notification.service';
import { DataSource, DialogData, ExternalCategoryNames, ExternalMappingRequest } from '../../../models/managecategory';
import { CategoryService } from '../../../services/category.service';

@Component({
  selector: 'app-category-mapping-dialog',
  templateUrl: './category-mapping-dialog.component.html',
  styleUrls: ['./category-mapping-dialog.component.scss']
})
export class CategoryMappingDialogComponent implements OnInit {
  dataSources: DataSource[];
  selectedDataSource: string;
  ExternalCategoryNames: ExternalCategoryNames[];
  selectedExternalCategoryName: any;
  deleteObject: any = null;
  edit: boolean;
  externalCategoryId: string;

  externalMappindCategoryData: ExternalMappingRequest;
  rowData: any[];
  delete_data: string;

  constructor(private categoryService: CategoryService, private loadingService: LoadingService,
    public dialogRef: MatDialogRef<CategoryMappingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private notificationService: NotificationService, public dialog: MatDialog) {
    this.dataSources = [];
    this.ExternalCategoryNames = [];
    this.selectedDataSource = '';
    this.selectedExternalCategoryName = 0;
    this.externalMappindCategoryData = null;
    this.edit = false;
    this.rowData = [];
    this.externalCategoryId = '';
  }

  ngOnInit(): void {
    this.rowData.push(this.data.data);
    if (this.data.dataType == 'edit') {
      this.edit = true;

      for (let i of this.rowData) {
        this.selectedDataSource = i.CategoryDataSource;
        this.selectedExternalCategoryName = i.ExternalCategoryId;
      }
      this.getExternalCategoryMapping();
    }
    if (this.data.dataType == 'delete') {
      this.rowData.push(this.data.data);
      for (let i of this.rowData) {
        this.delete_data = "Data Source :" + i.CategoryDataSource + "\n" + "External Category Id :" + i.ExternalCategoryId + "\n" + "External Category Name :" + i.ExternalCategoryName;
      }
      this.edit = false;
    }
  }

  getExternalCategoryMapping() {
    this.loadingService.setLoading();
    this.categoryService.getDataSource()
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.dataSources = response;
      });
    this.changeCategoryName(this.selectedDataSource);
  }

  changeCategoryName(value: string) {
    this.selectedDataSource = value;
    this.loadingService.setLoading();
    this.categoryService.getAllExternalCategories(this.selectedDataSource)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.ExternalCategoryNames = response;
      });
  }

  changeValue(value: string) {
    this.selectedExternalCategoryName = value;
  }

  changeExternalCategory(id: string) {
    this.externalCategoryId = id;
  }

  updateExternalMappingCategory() {
    let external_name = this.ExternalCategoryNames.filter(x => x.CategoryId == this.externalCategoryId).map(x => x.CategoryName);
    for (let i of this.rowData) {
      this.externalMappindCategoryData = {
        Id: i.Id,
        CategoryStandardiserID: i.CategoryStandardiserID,
        CategoryDataSource: this.selectedDataSource,
        CategoryDataSourceType: external_name.toString(),
        LWCCategoryID: i.LWCCategoryID,
        LWCCategoryName: i.LWCCategoryName,
        ExternalCategoryType: this.externalCategoryId
      }
    }
    this.loadingService.setLoading();
    this.categoryService.addExternalMapping(this.externalMappindCategoryData)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        this.notificationService.notifySuccess('External Category Updated');
        setTimeout(() => {
          this.dialog.closeAll();
        }, 1000);
      }, (error) => {
        this.notificationService.notifyError(error);
        setTimeout(() => {
          this.dialog.closeAll();
        }, 1000);
      });
  }

  deleteExternalMappingCategory() {
    for (let i of this.rowData) {
      this.deleteObject = { 'Id': i.Id, 'CategoryStandardiserID': i.CategoryStandardiserID };
    }
    this.loadingService.setLoading();
    this.categoryService.deleteExternalCategoryMapping(this.deleteObject)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('External Category deleted');
        setTimeout(() => {
          this.dialog.closeAll();
        }, 1000);
      }, (error) => {
        this.notificationService.notifyError(error);
        setTimeout(() => {
          this.dialog.closeAll();
        }, 1000);
      });
  }
}
