import { getApplicationUsersResponse } from '../mockjsonresponses/applicationusers';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
export function applicationUserBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if ((cleanUrl.includes('authentication/users'))
    && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: getApplicationUsersResponse
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('authentication/user/'))
    && (method === 'GET')) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: getApplicationUsersResponse[2]
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('authentication/user'))
    && (method === 'POST')) {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 201,
        body: getApplicationUsersResponse[0]
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('authentication/user'))
    && method === 'PUT') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: getApplicationUsersResponse[0]
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('authentication/user'))
    && method === 'DELETE') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  } else if ((cleanUrl.includes('authentication/changepassword'))
    && method === 'PUT') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200
      }));
      resp.complete();
    });
  }
}
