import { apiPathConstants } from './../core/api/apipathconstants';
import { ApiService } from './../core/api/api.service';
import { Injectable } from "@angular/core";
import { ApiClient } from '../core/api/apiclient';
import { BatchSplitCalRequest, BatchSplitCalResponse } from '../components/batchcalsplitter/batchcalsplittermodels';

@Injectable({
  providedIn: "root"
})
export class BatchCalSplitterService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) {
  };

  getBatchSplitCals(batchSplitCalRequest: BatchSplitCalRequest) {
    return this.apiClient.post<BatchSplitCalResponse>(this.apiService.buildUrl(apiPathConstants.batchsplitcal), batchSplitCalRequest);
  }
}
