import { IndexChainRequest } from '../models/indexmerchantrequest';
import { IndexMerchantResult } from '../models/indexmerchantresult';
import { ApiClient } from '../core/api/apiclient';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { IndexMerchantRequest } from '../models/indexmerchantrequest';
import { apiPathConstants } from '../core/api/apipathconstants';
import { EventingCreateNew } from '../components/managedatascraping/datascraping';

@Injectable()
export class IndexMerchantService {
  constructor(private apiService: ApiService, private apiClient: ApiClient) { }

  createCal(indexMerchant: IndexMerchantRequest) {
    return this.apiClient.post<IndexMerchantResult>(this.apiService
      .buildUrl('index/merchantfromcal'), indexMerchant);
  }

   indexMerchantFromBusinessNameId(indexMerchantRequest: IndexMerchantRequest) {
    return this.apiClient.post<IndexMerchantResult>(this.apiService
      .buildUrl('index/merchantfrombusinessnameid'), indexMerchantRequest);
  }

  indexChain(indexChainRquest: IndexChainRequest) {
    return this.apiClient.post<IndexMerchantResult>(this.apiService
      .buildUrl('index/chain'), indexChainRquest);
  }

  indexInternationalCAL(indexMerchantRequest: IndexMerchantRequest) {
    return this.apiClient.post<string>(this.apiService
      .buildUrl('index/internationalcal'), indexMerchantRequest);
  }

  indexSharedCAL(indexMerchantRequest: IndexMerchantRequest) {
    return this.apiClient.post<string>(this.apiService
      .buildUrl('index/sharedcal'), indexMerchantRequest);
  }

  changeLockState(lwcId: number, state: number) {
    return this.apiClient.post<void>(this.apiService
      .buildUrl('index/setrecordlockedstatus', lwcId.toString(),
      state.toString()), {});
  }

  matchWithIndexedMerchants(chainCode: string) {
    return this.apiClient.post<void>(this.apiService
      .buildUrl(apiPathConstants.matchWithIndexedMerchants + '/' + chainCode), null);
  }

  eventingCreateNew(eventingCreateNew: EventingCreateNew) {
    return this.apiClient.post<void>(this.apiService.buildUrl(apiPathConstants.eventingCreateNew), eventingCreateNew);
  }

}
