import { NotificationService } from './../../../core/uiservices/notification.service';
import { OnInit, Component, Injector } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RouteConstants } from "../../../core/constants/constants";
import { finalize, takeUntil } from "rxjs/operators";
import { LoadingService } from "../../../core/uiservices/loading.service";
import { WebScrapingDetail, WebScrapingDetailDisplay } from "../datascraping";
import { EnrichMerchantService } from "../../../services/enrichmerchant.service";
import { BaseComponent } from "../../../shared/base/base.component";
import { IndexingAuditResponse } from "../../scrapingdatasources/scrapingdatadto";
import { DateTimeService } from "../../../core/uiservices/datetime.service";
import { IndexingAuditService } from "../../../services/indexingaudit.service";
import { IndexMerchantService } from "../../../services/indexmerchant.service";
import { DataScrapingService } from "../../../services/datascraping.service";

export type WebScrapingDetailResponse = {
  stores: WebScrapingDetail[];
}

@Component({
  selector: 'app-webscrapingdetail',
  templateUrl: './webscrapingdetail.component.html',
  styleUrls: ['./webscrapingdetail.component.scss']
})

export class WebScrapingDetailComponent extends BaseComponent implements OnInit {
  webScrapingDetail: WebScrapingDetailDisplay[] = [];
  arrayOfMatchedOn = ["Empty", "Unmatched", "PhoneNumber", "Proximity", "BusinessName"];
  matchedOn: string = "Empty";
  filteredWebScrapingDetail: WebScrapingDetailDisplay[] = [];
  chainCode: string;

  constructor(injector: Injector, private router: Router, private activatedRoute: ActivatedRoute, private manageDataScrapingService: DataScrapingService,
    private loadingService: LoadingService, private enrichMerchantService: EnrichMerchantService, private dateTimeService: DateTimeService,
    private indexingAuditService: IndexingAuditService, private indexMerchantService: IndexMerchantService, private notificationService: NotificationService) {
    super(injector)
  }

  goBack() {
    this.router.navigate([RouteConstants.managedatascraping]);
  }

  ngOnInit() {
    this.getWebScrapingDetail();
  }

  enrichMerchant(lwcId: string, id: number) {
    this.loadingService.setLoading();
    this.enrichMerchantService.enrichMerchant({ lwcId: lwcId, scrapedItemId: id, processType: "Chain" })
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => { });
  }

  refreshData() {
    this.getWebScrapingDetail();
  }

  filter() {
    this.filteredWebScrapingDetail = this.webScrapingDetail.filter(
      (scrapingDetail) => {
        let isMatch = true;
        if (this.matchedOn !== "Empty") {
          if (this.matchedOn === "Unmatched" && !scrapingDetail.matchedOn) {
            isMatch = true;
          }
          else if (scrapingDetail.matchedOn && this.matchedOn === scrapingDetail.matchedOn) {
            isMatch = true;
          }
          else {
            isMatch = false;
          }
        }

        return isMatch;
      }
    );
  }

  showIndexingAudit(webScraping: WebScrapingDetailDisplay) {
    this.loadingService.setLoading();
    this.indexingAuditService.getIndexingAudit(webScraping.id)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe((response) => {
        if(response) {
          this.formatIndexingAudit(response, webScraping);
        }
      });
  };

  matchWithIndexedMerchants() {
    this.loadingService.setLoading();
    this.indexMerchantService.matchWithIndexedMerchants(this.chainCode)
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() =>  {});
  }

  closeIndexingAudit(webScraping: WebScrapingDetailDisplay) {
    webScraping.indexingAudit = null;
  }

  disableScrapingDetail(webScrapingDetail: WebScrapingDetailDisplay, webScrapingProperty: string) {
    let scrapingDetail = this.filteredWebScrapingDetail.find(t => t.id == webScrapingDetail.id);
    if (scrapingDetail) {
      switch (webScrapingProperty) {
        case 'openingHours': {
          scrapingDetail.showOpeningHours = !scrapingDetail.showOpeningHours
            ? true
            : !scrapingDetail.showOpeningHours;
          break;
        }
        case 'businessNameCatalogResult': {
          scrapingDetail.showBusinessNameCatalogResult = !scrapingDetail.showBusinessNameCatalogResult
            ? true
            : !scrapingDetail.showBusinessNameCatalogResult;
          break;
        }
        case 'geoCodingResult': {
          scrapingDetail.showGeoCodingResult = !scrapingDetail.showGeoCodingResult
            ? true
            : !scrapingDetail.showGeoCodingResult;
          break;
        }
      }
    }
  }

  onChangePage(webScrapingData: WebScrapingDetailDisplay[]) {
    this.filteredWebScrapingDetail = webScrapingData;
  }

  private formatIndexingAudit(response: IndexingAuditResponse[], webScraping: WebScrapingDetailDisplay) {
    webScraping.indexingAudit = response;

    for (let indexAuditResponse of response) {
      indexAuditResponse.createDateTime = this.dateTimeService.formatWithHours(indexAuditResponse.createDateTime);
    }
  }

  indexMerchantWithNewName(id: number) {
    this.loadingService.setLoading();
    this.indexMerchantService.eventingCreateNew({ ScrapedItemId: id, ProcessType: "chain", UseExisting: false })
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('Successfully Indexed Merchant With New Name');
       });
  }

  indexMerchantWithExistingName(id: number) {
    this.loadingService.setLoading();
    this.indexMerchantService.eventingCreateNew({ ScrapedItemId: id, ProcessType: "chain", UseExisting: true })
      .pipe(finalize(() => this.loadingService.clearLoading()))
      .subscribe(() => {
        this.notificationService.notifySuccess('Successfully Indexed Merchant With Existing Name');
      });
  }

  private getWebScrapingDetail() {
    this.activatedRoute.params.subscribe(params => {
      if (params['chainCode']) {
        this.chainCode = params['chainCode'];
        this.loadingService.setLoading();
        this.manageDataScrapingService.getViewData(params['chainCode'])
          .pipe(finalize(() => this.loadingService.clearLoading()), takeUntil(this.destroy$))
          .subscribe(webScrapingDetail => {
            this.loadingService.clearLoading();
            if (webScrapingDetail && webScrapingDetail.data && webScrapingDetail.data.stores) {
              this.webScrapingDetail = <WebScrapingDetailDisplay[]>webScrapingDetail.data.stores;
              for (let webscraping of this.webScrapingDetail) {
                webscraping.openingHoursParsed = webscraping.openingHours ? JSON.parse(webscraping.openingHours) : null;
                webscraping.catalogSearchResultParsed = webscraping.catalogSearchResult ? JSON.parse(webscraping.catalogSearchResult) : null;
              }
              this.filter();
            }
          });
      }
      else {
        this.router.navigate([RouteConstants.managedatascraping]);
      }
    })
  }
}
