import { Injectable } from "@angular/core";
import { ApiService } from "../../core/api/api.service";
import { ApiClient } from "../../core/api/apiclient";
import { apiPathConstants } from "../../core/api/apipathconstants";
import { CalParserRequest, CalDetails } from "./calparserstate";
import { Observable } from "rxjs";
import { CALParserResult } from "../../models/calparserresult";
import { SplitCalRequest } from "../../models/splitcal";

@Injectable({
  providedIn: "root"
})
export class CalHintService {

  constructor(private apiService: ApiService, private apiClient: ApiClient) {
  }

  getCalDetails(lwcId: number): Observable<CalDetails[]> {
    return this.apiClient.get<CalDetails[]>(this.apiService.buildApiUrlWithParameters(apiPathConstants.getCalDetails,
      { name: 'lwcId', value: lwcId.toString() }));
  }

  getCalParserResult(splitCalRequest: SplitCalRequest): Observable<CALParserResult> {
      return this.apiClient.post<CALParserResult>(this.apiService.buildUrl(apiPathConstants.splitCal), splitCalRequest);
  }

  updateCals(request: CalParserRequest) {
    return this.apiClient.post<void>(this.apiService.buildUrl(apiPathConstants.calhints), request);
  }
}
