import { UserStatusType, RegionType } from '../../users/userenums';
import { ApplicationUser } from '../../users/applicationuser';

export let getApplicationUsersResponse: ApplicationUser[] = [
  {
    Id: 3,
    Status: UserStatusType.Active,
    EmailId: 'hammad.arif3@lookwhoscharging.com',
    DateCreated: '2018-07-19T08:29:08.03',
    DateModified: null,
    Regions: [RegionType.AUS, RegionType.NZL],
    FirstName: 'Frank',
    LastName: 'Galager'
  },
  {
    Id: 1,
    Status: UserStatusType.InActive,
    EmailId: 'hammad.arif2@lookwhoscharging.com',
    DateCreated: '2018-07-22T02:46:36.48',
    DateModified: '2018-07-22T02:46:50.13',
    Regions: [RegionType.AUS],
    FirstName: 'Wilson',
    LastName: 'Fisk'
  },
  {
    Id: 2,
    Status: UserStatusType.Active,
    EmailId: 'robert1987@example.com',
    DateCreated: '2018-07-10T02:46:36.48',
    DateModified: '2018-07-11T07:38:50.13',
    Regions: [RegionType.NZL],
    FirstName: 'Peter',
    LastName: 'Rouse'
  }
];

