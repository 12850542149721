import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getCALresponse } from '../mockjsonresponses/getcalresponse';

export function calBackendService(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const cleanUrl = url.split('?')[0];

  if (cleanUrl.includes('odata') && cleanUrl.includes('/cal')
    && method === 'GET') {
    return new Observable(resp => {
      resp.next(new HttpResponse({
        status: 200,
        body: getCALresponse
      }));
      resp.complete();
    });
  }

}
