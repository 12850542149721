import { MerchantValidationRecord } from "../../components/merchantvalidation/merchantvalidationrecord";

export let merchantValidationResponse: MerchantValidationRecord[] = [
    {
        UpdateDateTime: "2019-07-08T04:14:00",
        IsActive: false,
        AppliesToEntityRecordID: null,
        OverriddenComment: null,
        OverriddenDateTime: null,
        OverriddenbyNameofIndexer: null,
        OverriddenbyIDOfIndexer: null,
        IsOverridden: true,
        Comment: "The CAL '7-ELEVEN 1124 CROYDON EAS CROYDON EAST' seems to indicate that this is a school but the name does not contain School",
        Severity: 1,
        ValidationID: 2,
        LWC_ID: 1001202727,
        MerchantIndexingID: 2172213,
        CreateDateTime: "2019-07-08T04:13:00",
        ValidationOutputID: 724
    },
    {
        UpdateDateTime: null,
        IsActive: true,
        AppliesToEntityRecordID: null,
        OverriddenComment: null,
        OverriddenDateTime: null,
        OverriddenbyNameofIndexer: null,
        OverriddenbyIDOfIndexer: null,
        IsOverridden: true,
        Comment: "CAL's with multiple branch identifiers exist 1124 | 1124 CROYDON EAS",
        Severity: 1,
        ValidationID: 3,
        LWC_ID: 1001202727,
        MerchantIndexingID: 2172213,
        CreateDateTime: "2019-07-08T04:14:00",
        ValidationOutputID: 725
    },
    {
        UpdateDateTime: null,
        IsActive: true,
        AppliesToEntityRecordID: null,
        OverriddenComment: null,
        OverriddenDateTime: null,
        OverriddenbyNameofIndexer: null,
        OverriddenbyIDOfIndexer: null,
        IsOverridden: false,
        Comment: "CAL's with multiple branch identifiers exist 1124 | 1124 CROYDON EAS",
        Severity: 2,
        ValidationID: 4,
        LWC_ID: 1001202727,
        MerchantIndexingID: 2172213,
        CreateDateTime: "2019-07-08T04:14:00",
        ValidationOutputID: 725
    },
    {
        UpdateDateTime: null,
        IsActive: true,
        AppliesToEntityRecordID: null,
        OverriddenComment: null,
        OverriddenDateTime: null,
        OverriddenbyNameofIndexer: null,
        OverriddenbyIDOfIndexer: null,
        IsOverridden: false,
        Comment: "This Merchant is potentially a Duplicate with LWC_ID=1000067662. Duplicate Detection comment= something. Please check both records and either Merge them or Override the warning.",
        Severity: 2,
        ValidationID: 67,
        LWC_ID: 1001202727,
        MerchantIndexingID: 2172213,
        CreateDateTime: "2019-07-08T04:14:00",
        ValidationOutputID: 725
    }
]
