import { ApiClientConfig } from '../core/api/apiclientconfig';
import { UserStatusType } from './userenums';
import { ApplicationUser, UpdateUser, CreateUser } from './applicationuser';
import { Observable, of } from 'rxjs';
import { ApiService } from '../core/api/api.service';
import { ApiClient } from '../core/api/apiclient';
import { Injectable } from '@angular/core';
import { ChangePasswordModel } from './changepassword/changepasswordmodel';
import { CacheService } from '../core/cache/cache.service';
import { ValueCheckerService } from '../core/services/valuechecker.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserService {
  private applicationUsersCacheKey = 'applicationUsers';
  private userCacheDuration = 60 * 60;

  constructor(private apiService: ApiService, private apiClient: ApiClient, private cacheService: CacheService, private valueCheckerService: ValueCheckerService) { }

  getApplicationUsers(): Observable<ApplicationUser[]> {
    return this.apiClient.get<ApplicationUser[]>(this.apiService.buildUrl('authentication/users'))
      .pipe(tap(result => this.cacheService.set(this.applicationUsersCacheKey, result, this.userCacheDuration)));
  }

  getApplicationUsersCached(): Observable<ApplicationUser[]> {
    let data = this.cacheService.get<ApplicationUser[]>(this.applicationUsersCacheKey);

    if (!this.valueCheckerService.isNullOrUndefined(data)) {
      return of(data);
    }

    return this.getApplicationUsers();
  }

  getById(emailId: string, disableDefaultErrorHandling?: boolean): Observable<ApplicationUser> {
    let apiClientConfig: ApiClientConfig = {
      disableDefaultErrorHandling: disableDefaultErrorHandling
    };

    return this.apiClient.get<ApplicationUser>(this.apiService
      .buildUrl('authentication/user', emailId), apiClientConfig);
  }

  createUser(applicationUser: CreateUser): Observable<ApplicationUser> {
    return this.apiClient.post<ApplicationUser>(this.apiService.buildUrl('authentication/user'), applicationUser);
  }

  editUser(applicationUser: UpdateUser): Observable<ApplicationUser> {
    return this.apiClient.put<ApplicationUser>(this.apiService.buildUrl('authentication/user'), applicationUser);
  }

  deleteUser(emailId: string): Observable<void> {
    return this.apiClient.delete<void>(this.apiService.buildUrl('authentication/user', emailId));
  }

  isUserActive(applicationUserStatus: UserStatusType): boolean {
    return Number(applicationUserStatus) === UserStatusType.Active;
  }

  changePassword(changePasswordModel: ChangePasswordModel) {
    return this.apiClient.put<void>(this.apiService.buildUrl('authentication/changepassword'), changePasswordModel);
  }
}
