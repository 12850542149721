import { Component, Input } from "@angular/core";
import { AUS_Indexing_Result } from "../../models/ausindexingresult";
import { OverallResult } from "../../enums/enums";
import { BatchSearchResult } from "../batchmerchantsearch/batchsearchresult";

@Component({
    selector: 'app-batchenrichresult',
    templateUrl: './batchenrichresult.component.html',
    styleUrls: ['./batchenrichresult.component.scss']
})
export class BatchEnrichResultComponent {

    @Input() batchSearchResults: BatchSearchResult<AUS_Indexing_Result>[] = [];
    @Input() searchInProgress: boolean = false;
    overallResultAlreadyIndexed: number;
    overallResultIndexingFailed: number;
    overallResultError: number;
    overallResultWarning: number;
    overallResultSuccess: number;

    ngOnInit() {
        this.setBuildMerchantRecordOutcome(this.batchSearchResults);
    }

    ngOnChanges() {
        this.setBuildMerchantRecordOutcome(this.batchSearchResults);
    }

    private setBuildMerchantRecordOutcome(results: BatchSearchResult<AUS_Indexing_Result>[]) {
        this.overallResultAlreadyIndexed = 0;
        this.overallResultIndexingFailed = 0;
        this.overallResultError = 0;
        this.overallResultWarning = 0;
        this.overallResultSuccess = 0;
        for (let batchSearchResult of results) {
            if (batchSearchResult.response.BuildMerchantRecordOutcome === OverallResult.AlreadyIndexed) {
                this.overallResultAlreadyIndexed++;
            }
            if (batchSearchResult.response.BuildMerchantRecordOutcome === OverallResult.IndexingFailed) {
                this.overallResultIndexingFailed++;
            }
            if (batchSearchResult.response.BuildMerchantRecordOutcome === OverallResult.Error) {
                this.overallResultError++;
            }
            if (batchSearchResult.response.BuildMerchantRecordOutcome === OverallResult.Warning) {
                this.overallResultWarning++;
            }
            if (batchSearchResult.response.BuildMerchantRecordOutcome === OverallResult.Success) {
                this.overallResultSuccess++;
            }
        }
    }
}